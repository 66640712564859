import React, { Fragment, Component } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import PmaApprovalTimeline from "./PmaApprovalTimeline"
import PmaApprovalsList from "./PmaApprovalsList"
import Tabs from "../tabs/Tabs"
import Tab from "../tabs/Tab"
import Box from "../Box"


export default class PmaApprovalsTimelineContainer extends Component {
  constructor(props) {
    super(props)
  }
  
  render() {
    console.log(this.props.chartData)
    return(
      <Fragment>
        <PmaApprovalTimeline
          key={`pma-approvals-timeline-chart-${this.props.chartData.length}`}
          title='PMA Approval Timeline'
          chartId='pma-approvals-timeline-chart'
          chartData={this.props.chartData}
          currentTime={this.props.currentTime}
        />
        
        <Tabs centered value={0} className='bb b--black-10'>
          <Tab label='Data' />
        </Tabs>
        
        <Box mt={3}>
          <PmaApprovalsList 
            pmaApprovals={this.props.pmaApprovals}
            sector={this.props.sector}
            product={this.props.product}
          />
        </Box>
      </Fragment>
    )
  }
}
