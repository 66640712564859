import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Typography from "@material-ui/core/Typography"
import List from "../../lists/List"
import SectorListItem from "../../sectors/SectorListItem";

class SectorForm extends Component {
  constructor(props) {
    super(props)
  }
  
  render() {
    return(
      <Fragment>
        <Typography variant="h6" component="h6" className='mb1 mt1 mr1'>
          Sectors
        </Typography>
        
        <List>
          {this.props.sectors.map((sector) =>
            <SectorListItem 
              key={sector.id}
              sector={sector}
              checked={this.props.targetMarketSectors.filter((targetMarketSector) => targetMarketSector.sector_id == sector.id)[0] ? true : false}
              handleCheckboxSelect={this.props.handleCheckboxSelect}
            />
          )}
        </List>
      </Fragment>
    )
  }
}

export default SectorForm
