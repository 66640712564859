import React from 'react'
import ReactRailsUJS from 'react_ujs'
import { MuiThemeProvider } from "@material-ui/core/styles"
import { StylesProvider } from '@material-ui/styles'
import theme from "./theme"
import { BrowserRouter as Router } from "react-router-dom";
import * as Sentry from '@sentry/browser';
Sentry.init({dsn: "https://8b2cb93c4c9d437aa744020219d5c262@o381181.ingest.sentry.io/5208188"});

function ThemeProvider({ component, ...props }) {
  const Component = ReactRailsUJS.getConstructor(component)
  return (
    <StylesProvider injectFirst>
      <MuiThemeProvider theme={theme}>
        <Router>
          <Component
            currentUser={props.current_user}
            {...props}
          />
        </Router>
      </MuiThemeProvider>
    </StylesProvider>
  )
}

export default ThemeProvider
