import React, { Component, Fragment } from "react"
import PropTypes from "prop-types"
import Box from "../../Box"
import Paper from "../../Paper"
import { Document, Page } from 'react-pdf';
import Tabs from "../../tabs/Tabs"
import Tab from "../../tabs/Tab"
import Highlighter from "react-highlight-words";
import Link from "../../Link"

export default class SourceMaterialsPanel extends Component {
  constructor(props) {
    super(props)
    this.onDocumentLoadSuccess = this.onDocumentLoadSuccess.bind(this)
    this.state = { numPages: null, pageNumber: 1 }
  }

  onDocumentLoadSuccess(pdfDocument) {
    const { numPages } = pdfDocument;
    this.setState({
      numPages: numPages
    })
  }

  render() {
    let pdfFile = this.props.files.filter(file => file.key.includes(".pdf") ? file : null)[0]

    return(
      <Fragment>
        <Paper fullHeight>

          <Box>
            <Tabs variant="fullWidth" value={this.props.activeIndex} onChange={this.props.handleSourceMaterialIndexUpdate} className='bb b--black-10' aria-label="source materials tabs">
              <Tab label='Text' />
              <Tab label='PDF' />
              <Tab label='Images' />
            </Tabs>
          </Box>

          <Box mt={3} height="90%" className='overflow-auto'>
            <Box height="80%">
              <Box mb={2}>
                <Link href={this.props.abstract.url} target="_blank" className='underline'>
                  {this.props.abstract.url}
                </Link>
                {this.props.abstract.media_url ?
                  <Box>
                    <span className='pr1'>MEDIA URL:</span>
                    <Link href={this.props.abstract.media_url} target="_blank" className='underline'>
                      {this.props.abstract.media_url}
                    </Link>
                  </Box>
                : ""}
              </Box>

              {this.props.activeIndex == 1 ?

                pdfFile ?
                  // <iframe src={`https://view.officeapps.live.com/op/embed.aspx?src=${pdfFile.url}`} width='100%' height='600px' frameborder='0' />
                  <Document file={pdfFile.url} onLoadSuccess={this.onDocumentLoadSuccess}>
                    {Array.from(
                      new Array(this.state.numPages),
                      (el, index) => (
                        <Page
                          key={`page_${index + 1}`}
                          pageNumber={index + 1}
                        />
                      ),
                    )}
                  </Document>

                : "No PDF found"

              : ''}

              {this.props.activeIndex == 2 ?
                this.props.files ?
                  <Fragment>
                    {this.props.files.map((file) =>
                      <Fragment>
                        <img src={file.url}  />

                        <div>
                          {this.props.abstractTexts.filter(abstractText => abstractText.source == file.key).map((abstractText) => abstractText.text)}
                        </div>
                      </Fragment>
                    )}
                  </Fragment>
                : "No images found"
              : ""}


              {this.props.activeIndex == 0 ?
                this.props.abstractTexts.map((abstractText) =>
                  <Box my={3}>
                    {this.props.searchWords ?
                      <Highlighter
                        searchWords={this.props.searchWords}
                        autoEscape={true}
                        textToHighlight={abstractText.text}
                      />
                    : abstractText.text}
                  </Box>
                )
              : ''}

            </Box>
          </Box>
        </Paper>
      </Fragment>
    )
  }
}
