import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import TrialTypeRow from "./TrialTypeRow"
import { List, ListItem, ListItemText } from "../../lists"
import { Table, TableRow, TableCell, TableBody, TableHead } from "../../tables"
import Typography from '@material-ui/core/Typography';
import Select from "../../Select"
import Flex from "../../Flex"
import Box from "../../Box"
import Snackbar from "../../Snackbar"
import update from "immutability-helper"
import ExpandLess from '../../ExpandLess';
import ExpandMore from '../../ExpandMore';
import Button from "../../buttons/Button"
import Collapse from "@material-ui/core/Collapse"

class TypeForm extends Component {
  constructor(props) {
    super(props)
    this.handleTypeChange = this.handleTypeChange.bind(this)
    this.handleDeletion = this.handleDeletion.bind(this)
    this.handleFavoriteClick = this.handleFavoriteClick.bind(this)
    this.handleProductSectionClick = this.handleProductSectionClick.bind(this)
    this.state = { trialTypes: this.props.trialTypes, productOpen: true, showSnackbar: false, snackbarText: '' }
  }

  handleProductSectionClick(e) {
    this.setState({
      productOpen: !this.state.productOpen
    })
  }

  handleTypeChange(name, value) {
    if (this.props.source) {
      $.post("/admin/trial_types", { trial_type: { trial_id: this.props.trial.id, clinical_type_id: value, created_by: this.props.currentUser.id, source_id: this.props.source.source_id }}, (data) => {
        let trialTypes = update(this.state.trialTypes, {
          $push: [data.trial_type]
        });
        this.setState({
          trialTypes: trialTypes,
          showSnackbar: true,
          snackbarText: 'The type has been added and set as the current type'
        })
      }, 'json').fail(() => {
        this.setState({
          showSnackbar: true,
          snackbarText: `The type could not be added. Please try again.`
        })
      }).always(() => {
        setTimeout(() => {
          this.setState({
            showSnackbar: false,
            snackbarText: ''
          })
        }, 4000)
      })
    } else {
      this.setState({
        showSnackbar: true,
        snackbarText: 'You must choose a source before adding a type'
      })
      setTimeout(() => {
        this.setState({
          showSnackbar: false,
          snackbarText: ''
        })
      }, 4000)
    }
  }

  handleFavoriteClick(trialType) {
    $.ajax({
      type: 'PUT',
      url: `/admin/trial_types/${trialType.id}`,
      data: {
        trial_type: {
          active: !trialType.active, updated_by: this.props.currentUser.id
        }
      },
      dataType: 'json',
      success: (data) => {
        let trialTypeIds = this.state.trialTypes.map((trialTypeHash) => trialTypeHash.trial_type.id)
        let index = trialTypeIds.indexOf(trialType.id)
        let trialTypes = update(this.state.trialTypes, { [index]: { trial_type: { active: { $set: data.trial_type.active }} }})
        this.setState({
          trialTypes: trialTypes
        })
      },
      error: (data) => {
        this.setState({
          showSnackbar: true,
          snackbarText: `The type could not be removed. Please try again.`
        })
      },
      complete: () => {
        this.setState({
          showSnackbar: false,
          snackbarText: ''
        })
      }
    })
  }

  handleDeletion(trialType) {
    $.ajax({
      type: 'PUT',
      url: `/admin/trial_types/${trialType.id}`,
      data: {
        trial_type: {
          deleted: true, deleted_by: this.props.currentUser.id
        }
      },
      dataType: 'json',
      success: (data) => {
        this.setState({
          trialTypes: data.trial_types,
          showSnackbar: true,
          snackbarText: 'The type was removed'
        })
      },
      error: (data) => {
        this.setState({
          showSnackbar: true,
          snackbarText: `The type could not be removed. Please try again.`
        })
      },
      complete: () => {
        this.setState({
          showSnackbar: false,
          snackbarText: ''
        })
      }
    })
  }

  render() {
    console.log(this.props.trialTypes)
    return(
      <Fragment>

        <Flex alignItems='baseline' mt={4}>

          <Box width={1/2}>
            <Typography variant="h6" component="h6" className='mb1'>
              New Trial Type
            </Typography>

            <Box width={1/2}>
              <Select
                className='w-50'
                entities={this.props.types}
                displayEmpty={true}
                label='Types'
                handleChange={this.handleTypeChange}
              />
            </Box>
          </Box>

        </Flex>

        <Box mt={4}>

          <Typography variant="h6" component="h6" className='mb2'>
            Types
          </Typography>

          <Box>
            <Table>

              <TableHead>
                <TableRow hover={false}>
                  <TableCell>Type</TableCell>
                  <TableCell>Source</TableCell>
                  <TableCell>User</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {this.state.trialTypes.length > 0 ? this.state.trialTypes.map((trialType) =>
                  <TrialTypeRow
                    key={trialType.trial_type.id}
                    trialType={trialType}
                    handleFavoriteClick={this.handleFavoriteClick}
                    handleDeletion={this.handleDeletion}
                  />
                ) : <TableRow><TableCell colSpan={5}>No trial types. Add one above.</TableCell></TableRow>}
              </TableBody>

            </Table>
          </Box>

        </Box>

        <Snackbar
          open={this.state.showSnackbar}
          message={this.state.snackbarText}
        />
      </Fragment>
    )
  }
}

export default TypeForm
