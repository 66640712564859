import React, { Component, Fragment } from "react"
import PropTypes from "prop-types"
import ReactDOM from "react-dom"
import Paper from "../Paper"
import Chart from "../charts/Chart"
import Timeline from "../charts/Timeline"
import StatusChartContainer from "./StatusChartContainer"
import StatusChartDataTable from "./StatusChartDataTable"
import Tabs from "../tabs/Tabs"
import Tab from "../tabs/Tab"
import Flex from "../Flex"
import Box from "../Box"
import Typography from "@material-ui/core/Typography"

export default class StatusContainer extends Component {
  constructor(props) {
    super(props)
    this.handleActiveIndexClick = this.handleActiveIndexClick.bind(this)
    this.state = { activeIndex: 0 }
  }

  handleActiveIndexClick(e, newValue) {
    this.setState({
      activeIndex: newValue
    })
  }

  render() {
    return (
      <Fragment>

        <Paper fullHeight={true}>

          {this.props.trial_statuses.length > 0 ?
            <Fragment>
              <StatusChartContainer
                key='status-timeline-chart'
                chartData={this.props.chart_data}
                currentTime={this.props.current_time}
                completionDate={this.props.completion_date}
                completionDateForChart={this.props.completion_date_for_chart}
                startDate={this.props.start_date}
                startDateForChart={this.props.start_date_for_chart}
                trialDate={this.props.trial_dates}
              />

              <Box width={1} textAlign='center'>
                <Box my={4}>
                  <Tabs centered value={this.state.activeIndex} onChange={this.handleActiveIndexClick} aria-label="status tabs" className='bb b--black-10'>
                    <Tab label='Data' />
                  </Tabs>
                </Box>

                <StatusChartDataTable
                  trialStatuses={this.props.trial_status_data}
                />

              </Box>
            </Fragment>
          :
            <Flex justifyContent='center' height="50%">
              <Box alignSelf='center' maxWidth="50%">
                <Box>
                  <Typography variant="body1">
                    No statuses found for this trial
                  </Typography>
                </Box>
              </Box>
            </Flex>
          }

        </Paper>
      </Fragment>
    );
  }
}
