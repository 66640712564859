import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import Flex from "../../Flex"
import Box from "../../Box"
import Link from "../../Link"
import TextInput from "../../TextInput"
import RaisedButton from "../../buttons/RaisedButton"
import Select from "../../Select"
import DatePicker from "../../DatePicker"

export default class Filters extends Component {
  constructor(props) {
    super(props)
    this.handleStartDateChange = this.handleStartDateChange.bind(this)
    this.handleEndDateChange = this.handleEndDateChange.bind(this)
  }

  handleStartDateChange(date) {
    this.props.handleFilterChange('start_date', date)
  }

  handleEndDateChange(date) {
    this.props.handleFilterChange('end_date', date)
  }

  render() {
    return(
      <Flex alignItems='center'>
        <Box mr={3}>
          <Select
            label='Relevance'
            name='relevant'
            value={this.props.filterValues.relevant}
            entities={this.props.filterOptions.relevantOptions}
            handleChange={this.props.handleFilterChange}
          />
        </Box>

        <Box mr={3}>
          <Select
            label='Priority'
            name='priority'
            value={this.props.filterValues.priority}
            entities={this.props.filterOptions.priorityOptions}
            handleChange={this.props.handleFilterChange}
          />
        </Box>

        <Box mr={3}>
          <DatePicker
            name='startDate'
            label='Start Date'
            handleChange={this.handleStartDateChange}
            value={this.props.filterValues.start_date}
          />
        </Box>

        <Box>
          <DatePicker
            name='endDate'
            label='End Date'
            handleChange={this.handleEndDateChange}
            value={this.props.filterValues.end_date}
          />
        </Box>

        <Box ml='auto'>
          <Flex alignItems='center'>
            <Box mr={2}>
              <TextInput
                name='search_query'
                label='Search'
                placeholder='Search by NCT ID'
                value={this.props.query}
                onChange={this.props.handleChange}
                className='w-100'
                margin='dense'
              />
            </Box>

            <Box>
              <RaisedButton handleClick={this.props.handleSearchClick}>
                Search
              </RaisedButton>
            </Box>
          </Flex>
        </Box>

      </Flex>
    )
  }
}
