import React, { Fragment } from "react"
import Box from "../../Box"
import TextInput from "../../TextInput"
import ButtonLayout from "../ButtonLayout"
import RaisedButton from "../../buttons/RaisedButton"
import SectorListItem from "../../sectors/SectorListItem";
import List from "../../lists/List";
import Typography from '@material-ui/core/Typography';

export default function NewTrialForm(props) {

  return(
    <Fragment>
      <Box mb={3}>
        <TextInput
          name='full_name'
          label='Full Name'
          value={props.full_name}
          onChange={props.handleNameChange}
          fullWidth
        />
      </Box>

      <Box mb={3}>
        <TextInput
          name='short_name'
          label='Short Name'
          value={props.short_name}
          onChange={props.handleNameChange}
          fullWidth
        />
      </Box>

      <Box mb={3}>
        <TextInput
          name='acronym'
          label='Acronym'
          value={props.acronym}
          onChange={props.handleNameChange}
          fullWidth
        />
      </Box>

      <Box mb={3}>
        <TextInput
          name='nct_id'
          label='NCT ID'
          value={props.nct_id}
          onChange={props.handleChange}
          fullWidth
        />
      </Box>

      <Box>
        <Typography variant="h6" component="h6">
          Sectors
        </Typography>

        <List>
          {props.sectors.map((sector) =>
            <SectorListItem
              key={sector.id}
              sector={sector}
              checked={props.sector_ids.filter((sectorId) => sectorId == sector.id)[0] ? true : false}
              handleCheckboxSelect={() => { props.handleCheckboxSelect(sector) }}
            />
          )}
        </List>
      </Box>

      <ButtonLayout>
        <RaisedButton handleClick={props.handleSaveClick}>
          Create Trial
        </RaisedButton>
      </ButtonLayout>
    </Fragment>
  )

}
