import React, { Component } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import AlertDisplay from "./AlertDisplay"

class FinancingAlerts extends Component {
  constructor(props) {
    super(props)
  }
  
  render() {
    const groupedAlerts = groupBy(this.props.alerts, 'company')
    const alerts = Object.keys(groupedAlerts).map((product) =>
      <div key={product} className='mv4'>
        <div>{product}</div>
        {groupedAlerts[product].map((alert) =>
          <AlertDisplay key={alert.id} alert={alert} />
        )}
      </div>
    )

    return(
      <div>
        {alerts}
      </div>
    )
  }
}

export default FinancingAlerts
