import React from "react"
import TargetMarketListItem from "./TargetMarketListItem"

export default function TargetMarketListItemContainer(props) {
  
  let productTargetMarket = ""
  
  if (props.activeRegion == 1000000) {
    productTargetMarket = props.productTargetMarkets.filter(productTargetMarket => productTargetMarket.target_market_id == props.targetMarket.id && productTargetMarket.investigational)[0]
  } else {
    productTargetMarket = props.productTargetMarkets.filter(productTargetMarket => productTargetMarket.target_market_id == props.targetMarket.id && productTargetMarket.world_region_id == props.activeRegion)[0]
  }
  
  let checked = false
  
  if (productTargetMarket) {
    if (props.activeRegion == 1000000) {
      checked = productTargetMarket.investigational ? true : false
    } else {
      checked = productTargetMarket.world_region_id == props.activeRegion ? true : false
    }
  }
  
  return (
    <React.Fragment>
      <TargetMarketListItem 
        targetMarket={props.targetMarket}
        productTargetMarket={productTargetMarket}
        activeRegion={props.activeRegion}
        checked={checked}
        handleCheckboxSelect={props.handleCheckboxSelect}
        handleCheckboxDelete={props.handleCheckboxDelete}
      />
    </React.Fragment>
  )
}
