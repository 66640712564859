import React, { Fragment, Component } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Paper from "../../Paper"
import SourceForm from "../SourceForm"
import PersonForm from "./PersonForm"
import FormTabs from "./FormTabs"
import Box from "../../Box"
import Flex from "../../Flex"
import Chip from '@material-ui/core/Chip';
import Snackbar from "../../Snackbar"
import update from "immutability-helper"
import NewInvestigatorsList from "./NewInvestigatorsList"
import Typography from "@material-ui/core/Typography"
import ButtonLayout from "../ButtonLayout"
import { RaisedButton } from "../../buttons"

export default class NewContainer extends Component {
  constructor(props) {
    super(props)
    this.handleActiveIndexUpdate = this.handleActiveIndexUpdate.bind(this)
    this.handleSourceChange = this.handleSourceChange.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handlePersonClick = this.handlePersonClick.bind(this)
    this.handleSaveClick = this.handleSaveClick.bind(this)
    this.handleNameChange = this.handleNameChange.bind(this)
    this.handleNewPersonClick = this.handleNewPersonClick.bind(this)
    this.state = { investigator: this.props.investigator, suggestions: [], query: '', activeIndex: 0, source: null, chosenPeople: [], firstName: '', lastName: '' }
  }

  handleActiveIndexUpdate(e, newValue) {
    this.setState({
      activeIndex: newValue
    })
  }

  handleNameChange(e) {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  handleNewPersonClick(e) {
    $.ajax({
      type: 'POST',
      url: `/admin/trials/${this.props.trial.id}/investigators/create_person_and_investigator`,
      data: { name: `${this.state.firstName} ${this.state.lastName}`, source_id: this.state.source.source_id, created_by: this.props.current_user.id },
      dataType: 'json',
      success: (data) => {

        this.setState({
          showSnackbar: true,
          snackbarText: 'Investigators have been added',
          chosenPeople: [],
          firstName: '',
          lastName: ''
        })
      },
      error: (data) => {
        this.setState({
          showSnackbar: true,
          snackbarText: 'There was an error adding the investigator. Please try again.'
        })
      },
      complete: (data) => {
        setTimeout(() => {
          this.setState({
            showSnackbar: false,
            snackbarText: ''
          })
        }, 4000)
      }
    })
  }

  handleChange(e) {
    const value = e.target.value

    if (value == '' || value == null) {
      this.setState({
        suggestions: []
      })
    } else {
      $.ajax({
        type: "GET",
        url: "/admin/people/search",
        data: {
          query: value
        },
        dataType: 'json',
        success: (data) => {
          this.setState({
            suggestions: data.people,
            query: value
          })
        }
      })
    }
  }

  handlePersonClick(person) {
    const chosenPeople = update(this.state.chosenPeople, {
      $push: [person]
    })

    this.setState({
      chosenPeople: chosenPeople,
      query: ''
    })
  }

  handleSaveClick(e) {
    if (this.state.source) {
      let person_ids = this.state.chosenPeople.map(person => person.person_id)
      $.ajax({
        type: 'POST',
        url: `/admin/trials/${this.props.trial.id}/investigators/new_investigators`,
        data: {
          person_ids: person_ids, source_id: this.state.source.source_id, created_by: this.props.current_user.id
        },
        dataType: 'json',
        success: (data) => {
          this.setState({
            showSnackbar: true,
            snackbarText: 'Investigators have been added',
            chosenPeople: []
          })
        },
        error: (data) => {
          this.setState({
            showSnackbar: true,
            snackbarText: 'The investigators could not be added. Please try again.'
          })
        },
        complete: (data) => {
          setTimeout(() => {
            this.setState({
              showSnackbar: false,
              snackbarText: ''
            })
          }, 4000)
        }
      })
    } else {
      this.setState({
        showSnackbar: true,
        snackbarText: 'Please choose a source'
      })

      setTimeout(() => {
        this.setState({
          showSnackbar: false,
          snackbarText: ''
        })
      }, 4000)
    }
  }

  handleSourceChange(entity, sourceType) {
    if (sourceType == 'recently_used') {
      this.setState({
        source: entity
      })
    } else {
      $.get("/admin/sources/find_source", { source_type: sourceType, entity_id: entity.id }, (data) => {
        if (data.source) {
          let investigator = update(this.state.investigator, {
            source_id: { $set: data.source.source_id }
          })
          this.setState({
            source: data.source,
            investigator: investigator
          })
        } else {
          $.post("/admin/sources/filter", { source: { kind: sourceType, entity_id: entity.id } }, (data) => {
            let investigator = update(this.state.investigator, {
              source_id: { $set: data.source.source_id },
              user_id: { $set: this.props.current_user.id }
            })
            this.setState({
              source: data.source,
              investigator: investigator
            })
          })
        }
      }, 'json')
    }
  }

  render() {
    return (
      <Fragment>

        <Paper>

          <Box mb={3}>
            <FormTabs
              activeIndex={this.state.activeIndex}
              handleActiveIndexUpdate={this.handleActiveIndexUpdate}
            />
          </Box>

          <Flex justifyContent="center" mb={3}>
            <Box>
              {this.state.source ?
                <Chip
                  label={this.state.source.source_title}
                />
              : <Box>Please choose a source</Box>}
            </Box>
          </Flex>

          {this.state.activeIndex == 0 ?
            <SourceForm
              trial={this.state.trial}
              source={this.state.source}
              sources={this.props.sources}
              sourceTypes={this.props.source_types}
              sourceType={this.props.source_type}
              handleSourceChange={this.handleSourceChange}
            />
          : ''}

          {this.state.activeIndex == 1 ?
            <Fragment>
              <PersonForm
                trial={this.props.trial}
                query={this.state.query}
                suggestions={this.state.suggestions}
                handlePersonClick={this.handlePersonClick}
                handleChange={this.handleChange}
                firstName={this.state.firstName}
                lastName={this.state.lastName}
                handleNameChange={this.handleNameChange}
                handleNewPersonClick={this.handleNewPersonClick}
              />

              <Box my={3}>
                <Typography variant="h6" component="h6">
                  New Investigators
                </Typography>
                {this.state.chosenPeople.length > 0 ?
                  <NewInvestigatorsList
                    chosenPeople={this.state.chosenPeople}
                  />
                  :
                  <div>No new investigators yet. Choose one from above.</div>
                }
              </Box>

              <ButtonLayout>
                <RaisedButton disabled={this.state.chosenPeople.length > 0 ? false : true} handleClick={this.handleSaveClick}>
                  Add Investigators
                </RaisedButton>
              </ButtonLayout>
            </Fragment>
          : ''}

        </Paper>

        <Snackbar
          open={this.state.showSnackbar}
          message={this.state.snackbarText}
        />
      </Fragment>
    )
  }
}
