import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Paper from "../../Paper"
import Flex from "../../Flex"
import Box from "../../Box"
import StatSection from "../../dashboard/StatSection"
import Typography from "@material-ui/core/Typography"
import { List, ListItem, ListItemText } from "../../lists"


export default class Dashboard extends Component {
  constructor(props) {
    super(props)
  }
  
  render() {
    return(
      <Fragment>
        
        <Flex align='items-center' className='flex-stretch'>
          <Box className='w-33 mr3'>
            <StatSection 
              statContent={this.props.products}
              statDetail='Products'
            />
          </Box>
          
          <Box className='w-33 mr3'>
            <StatSection 
              statContent={this.props.new_products.length}
              statDetail='New Products'
              secondaryContent='This month'
            />
          </Box>
          
          <Box className='w-33'>
            <StatSection 
              statContent={this.props.products_to_review.length}
              statDetail='Products to review'
            />
          </Box>
        </Flex>
        
        <Flex my={4}>
          <Box width={1}>
            <Paper>
              <Typography variant="h6" component="h6" className='mv1'>
                Tasks
              </Typography>
              
              <div>
                <List>
                  {this.props.user_tasks.length > 0 ? this.props.user_tasks.map((task) => 
                    <ListItem key={task.id}>
                      <ListItemText primary={task.name} />
                      <ListItemText 
                        secondary={humanDateFormat(task.due_on)} 
                      />
                    </ListItem>
                  ) : <ListItem>
                    <ListItemText primary='No tasks found' />
                  </ListItem>}
                </List>
              </div>
            </Paper>
          </Box>
        </Flex>
        
        <Flex alignItems='center'>
          <Box mr={3} className='w-50-ns w-100'>
            
            <Paper>
              <Typography variant="h6" component="h6" className='mv0'>
                New Products
              </Typography>
              
              <div>
                <List>
                  {this.props.new_products.map((product) =>
                    <ListItem key={product.id}>
                      <ListItemText primary={product.short_name} />
                      <ListItemText secondary={humanDateFormat(product.created_at)} />
                    </ListItem>
                  )}
                </List>
              </div>
            
            </Paper>
          </Box>
          
          <Box className='w-50-ns w-100'>
            <Paper>
              <Typography variant="h6" component="h6" className='mv0'>
                Products to Review
              </Typography>
              
              <div>
                <List>
                  {this.props.products_to_review.map((product) =>
                    <ListItem key={product.id}>
                      <ListItemText primary={product.short_name} />
                    </ListItem>
                  )}
                </List>
              </div>
            </Paper>
          </Box>
        </Flex>
        
      </Fragment>
    )
  }
}
