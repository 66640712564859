import React, { Fragment } from "react"
import Paper from "../../Paper"
import Flex from "../../Flex"
import Box from "../../Box"
import List from "../../lists/List";
import TrialListItem from "./TrialListItem"
import SearchIcon from '@material-ui/icons/Search'
import InputAdornment from '@material-ui/core/InputAdornment';
import { RaisedButton } from "../../buttons"
import TextInput from "../../TextInput"

export default function TrialsList(props) {
  const publicationTrialIds = props.publicationTrials.map((publicationTrial) => publicationTrial.trial_id)
  const trialIdArray = [...publicationTrialIds, ...props.activeTrials]
  return (
    <Fragment>

      <Flex mt={1} alignItems='center'>
        <Box width={1}>
          <TextInput
            name='trialSearch'
            label='Search'
            value={props.trialSearch}
            onChange={props.handleTrialSearchChange}
            className='w-100'
            InputProps={{
              startAdornment: <InputAdornment position="start"><SearchIcon /></InputAdornment>,
            }}
            helperText='Search by name or NCT id'
          />
        </Box>

        <Box ml={3}>
          <RaisedButton onClick={props.handleTrialSearchClick}>
            Search
          </RaisedButton>
        </Box>
      </Flex>

      <List>
        {(props.searchedTrials.length > 0 ? props.searchedTrials : props.trials).map((trial) =>
          <TrialListItem
            key={trial.trial_id}
            trial={trial}
            checked={trialIdArray.indexOf(trial.trial_id) == -1 ? false : true}
            handleCheckboxSelect={props.handleCheckboxSelect}
          />
        )}
      </List>

    </Fragment>
  )
}
