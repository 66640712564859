import React, { Fragment } from "react"
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';

export default function Associator(props) {
  return (
    <Fragment>
      {props.filterPanel}

      <Box mt={4} mx="auto">
        { props.loading ? <CircularProgress /> : '' }
      </Box>

      <Grid container spacing={3}>

        <Grid item xs={5}>

          <Box>
            {props.firstPanel}
          </Box>
        </Grid>

        {props.secondPanel ? <Grid item xs={2}>
          <Box>
            {props.secondPanel}

            <Box mt={4}>
              { props.associationLoading ? <CircularProgress /> : '' }
            </Box>
          </Box>
        </Grid> : ''}

        <Grid item xs={5}>
          <Box>
            {props.thirdPanel}
          </Box>
        </Grid>

      </Grid>
    </Fragment>
  )
}
