import React, { Component, Fragment } from "react"
import CKEditor from 'ckeditor4-react';
import Box from "../../Box"
import { RaisedButton } from "../../buttons"


export default class TextFieldForm extends Component {
  constructor(props) {
    super(props)
    this.handleConclusionSaveClick = this.handleConclusionSaveClick.bind(this)
    this.handleTextChange = this.handleTextChange.bind(this)
    this.state = { conclusion: '' }
  }

  componentDidMount() {
    if (this.props.abstractConclusion) {
      this.setState({
        conclusion: this.props.abstractConclusion.conclusion
      })
    }
  }

  handleTextChange(e) {
    const text = e.editor.getData()

    this.setState({
      conclusion: text
    })
  }

  handleConclusionSaveClick(e) {
    this.props.handleConclusionSaveClick(this.state.conclusion)
  }

  render() {
    return(
      <Fragment>
        <Box mt={2} mb={6}>

          <Box mb={2}>
            <CKEditor
              data={this.state.conclusion}
              onChange={this.handleTextChange}
            />
          </Box>

          <Box mt={5}>
            <RaisedButton handleClick={this.handleConclusionSaveClick}>
              Save Conclusion
            </RaisedButton>
          </Box>
        </Box>
      </Fragment>
    )
  }
}
