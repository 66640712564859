import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Chart from "../charts/Chart"

export default class StatusChartContainer extends Component {
  constructor(props) {
    super(props)
  }

  formatData() {
    const chartData = this.props.chartData.map((trialStatus) => {
      let date = parseFloat(trialStatus.date)
      return({ x: date, name: trialStatus.status, y: 50, color: 'black' })
    })

    return([{ name: 'Status', data: chartData, visible: true }])
  }

  setXAxisMin() {
    let enrollmentDataArray = [this.props.startDateForChart]
    let maxDate = ""
    let minDate = ""
    if (this.props.chartData.length > 0) {
      this.props.chartData.map((trialStatus) => {
        enrollmentDataArray.push(trialStatus.date)
      })
      maxDate = Math.max.apply(Math, enrollmentDataArray)
      minDate = Math.min.apply(Math, enrollmentDataArray)
    }
    let padding = (maxDate - minDate) * 0.05
    return(minDate - padding)

    // return(this.props.startDateForChart)
  }

  setXAxisMax() {
    let enrollmentDataArray = [this.props.completionDateForChart]
    let maxDate = ""
    let minDate = ""
    if (this.props.chartData.length > 0) {
      this.props.chartData.map((trialStatus) => {
        enrollmentDataArray.push(trialStatus.date)
      })
      maxDate = Math.max.apply(Math, enrollmentDataArray)
      minDate = Math.min.apply(Math, enrollmentDataArray)
    }
    let padding = (maxDate - minDate) * 0.05
    return(maxDate + padding)
  }

  setXAxisPlotLine() {
    let statuses = this.props.chartData.map(trialStatus => trialStatus.status)
    if (!statuses.includes("Completed")) {
      return([{ color: '#B57E7E', value: this.props.completionDateForChart, width: 2, zIndex: 1, id: 'completion-date-line', label: { text: this.setCompletionDateText() }}])
    }
  }

  setCompletionDateText() {
    if (this.props.trialDate.completion_date_type == 'Anticipated') {
      return("Completion date - Anticipated")
    } else {
      return("Completion date")
    }
  }

  setxAxisPlotBand() {
    let plotBands = this.props.chartData.map((trialStatus, index) => {
      return({ from: trialStatus.date, to: (this.props.chartData[index+1] ? this.props.chartData[index+1].date : this.props.currentTime), color: this.setPLotBandColor(trialStatus.status), label: { text: trialStatus.status, style: { color: 'black' }, y: 180 } })
    })

    return(plotBands)
  }

  setPLotBandColor(statusName) {
    let bandColor = ""
    switch (statusName) {
      case 'Plans released', 'Not yet recruiting':
        bandColor = '#FFFF94'
        break;
      case 'Underway', 'Recruiting':
        bandColor = '#a6f1a6'
        break;
      case 'Suspended', 'Withdrawn', 'Terminated', 'Trial halted':
        bandColor = '#FF4D4D'
        break;
      case 'Active, not recruiting':
        bandColor = '#64a8ff'
        break;
      case 'Completed':
        bandColor = ''
        break;
    }

    return(bandColor)
  }

  render() {
    return(
      <Fragment>

        <Chart
          chartId='status-chart'
          chartOptions={{type: 'line', height: 300}}
          plotOptions={{line: { lineWidth: 0, marker: { enabled: false }}}}
          title='Trial Status Timeline'
          formattedData={this.formatData()}
          xAxisPlotBand={this.setxAxisPlotBand()}
          // xAxisMin={this.setXAxisMin()}
          xAxisPlotLine={this.setXAxisPlotLine()}
          xAxisMax={this.setXAxisMax()}
          legend={false}
          xAxisTitle='Status Report Dates'
          xAxisType='datetime'
          yAxisVisible={false}
          startOnTick={true}
          endOnTick={true}
          tooltip={{headerFormat: '<span style="font-size:10px">{point.x:%B %d, %Y}</span><br/>', pointFormat: '<span style="color:{point.color}">\u25CF</span> Status: <b>{point.name}</b><br/>'}}
        />

      </Fragment>
    )
  }
}
