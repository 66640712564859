import React, { Fragment } from "react"
import { Table, TableHead, TableRow, TableCell, TableBody, TableHeadSort } from "../tables"
import Link from "../Link"
import Flex from "../Flex"
import Box from "../Box"
import CloseIcon from "@material-ui/icons/Close"
import IconButton from "../IconButton"
import Typography from "@material-ui/core/Typography"

export default function DataCompareRow(props) {

  function handleEntityRemovalClick(e) {
    props.handleEntityRemovalClick(props.entity)
  }

  let currentDate = props.entity.completion_date ? new Date(props.entity.completion_date) : new Date
  let firstReceivedDate = new Date(props.entity.start_date || props.entity.first_received_date)
  let dateDifference = currentDate.getTime() - firstReceivedDate.getTime()
  let durationInDays = dateDifference / (1000 * 60 * 60 * 24);

  return(
    <TableRow>
      <TableCell borderRight className='w-15'>
        <Fragment>
          <Flex alignItems='center'>
            <Box>
              <Link href={`/sectors/${props.sector.abbr}/trials/${props.entity.trial_id}`}>
                <Box>
                  {props.entity.acronym || props.entity.short_name}
                </Box>
              </Link>

              <Box>
                <Typography variant="caption">
                  {props.entity.company_id ? 
                    <Link href={`/sectors/${props.sector.abbr}/companies/${props.entity.company_id}`}>
                      {props.entity.sponsor_name}
                    </Link>
                  :
                    props.entity.sponsor_name
                    
                  }
                </Typography>
              </Box>
            </Box>

            {props.activeEntity && props.activeEntity.id == props.entity.trial_id ?
              ""
            :
              <Box ml='auto'>
                <IconButton onClick={handleEntityRemovalClick} size='small'>
                  <CloseIcon fontSize='small' />
                </IconButton>
              </Box>
            }
          </Flex>
        </Fragment>
      </TableCell>
      <TableCell borderRight>
        {props.entity.grouped_types ? props.entity.grouped_types.split("; ").map((type) =>
          <div key={type}>{type}</div>
        ) : ""}
      </TableCell>
      <TableCell borderRight>
        {props.entity.status}
      </TableCell>
      <TableCell borderRight>
        {dateFormat(props.entity.first_received_date)}
      </TableCell>
      <TableCell borderRight>
        {props.entity.start_date ? dateFormat(props.entity.start_date) : "N/A"}
      </TableCell>
      <TableCell borderRight>
        {props.entity.primary_completion_date ? dateFormat(props.entity.primary_completion_date) : "N/A"}
      </TableCell>
      <TableCell borderRight>
        {props.entity.completion_date ?
          <Fragment>
            <span className='pr2'>
              {dateFormat(props.entity.completion_date)}
            </span>
            <span className='i'>
              {props.entity.completion_date_type ? `(${props.entity.completion_date_type})` : ''}
            </span>
          </Fragment>
        : "N/A"}
      </TableCell>
      <TableCell borderRight>
        {`${Math.round(durationInDays)} days${props.entity.completion_date ? '' : ' to date'}`}
      </TableCell>
    </TableRow>
  )
}
