import React, { Fragment } from "react"
import Typography from "@material-ui/core/Typography"
import { List, ListItem, ListItemText } from "../../../lists"
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction"
import DeleteButton from "../../DeleteButton"
import Box from "../../../Box"

export default function PmaListItem(props) {

  function handleDeleteClick() {
    props.handleDeleteClick(props.productPma)
  }

  let pmaText = props.productPma.supplement_number ? `${props.productPma.pma} ${props.productPma.supplement_number}` : props.productPma.pma

  let sector = props.productPma.sector_id ? props.sectors.filter(sector => sector.value == props.productPma.sector_id)[0] : null

  return(
    <Fragment>
      <ListItem divider>
        <ListItemText
          primary={pmaText}
          secondary={sector ? sector.name : ""}
        />

        <ListItemSecondaryAction>
          <DeleteButton onClick={handleDeleteClick} />
        </ListItemSecondaryAction>

      </ListItem>

    </Fragment>
  )
}
