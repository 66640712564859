import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import ExpandLess from '../ExpandLess';
import ExpandMore from '../ExpandMore';
import Collapse from '@material-ui/core/Collapse';
import { Table, TableHead, TableBody, TableRow, TableCell } from "../tables"
import Flex from "../Flex"
import Box from "../Box"
import Typography from "@material-ui/core/Typography"
import Button from "../buttons/Button"

export default class EnrollmentDataTable extends Component {
  constructor(props) {
    super(props)
    this.handleGroupClick = this.handleGroupClick.bind(this)
    this.state = { open: true }
  }

  handleGroupClick(e) {
    const openState = !this.state.open

    this.setState({
      open: openState
    })
  }

  render() {
    return(
      <Fragment>
        <TableRow hover={false}>
          <TableCell colSpan={4} className='pv2'>
            <Button onClick={this.handleGroupClick} color='default' lowercase={true} className='f5'>
              {this.state.open ? <ExpandLess /> : <ExpandMore />}
              {this.props.header}
            </Button>
          </TableCell>
        </TableRow>

        {/* <Collapse in={this.state.open} timeout="auto" unmountOnExit component="tr"> */}
          {this.state.open ? this.props.enrollmentKindHash.map((enrollmentHash) =>
            <TableRow key={enrollmentHash.id}>
              <TableCell>{enrollmentHash.trial_enrollment.enrollment}</TableCell>
              <TableCell>{enrollmentHash.status ? enrollmentHash.status.name : 'NA'}</TableCell>
              <TableCell>{enrollmentHash.source_object.source_title}</TableCell>
              <TableCell>{enrollmentHash.date ? enrollmentHash.date : humanDateFormat(enrollmentHash.source_object.source_date)}</TableCell>
            </TableRow>
          ) : ''}
        {/* </Collapse> */}
      </Fragment>
    )
  }
}
