import React, { Component } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Link from "../Link"
import { TableRow, TableCell } from "../tables"
import FavoriteIcon from "@material-ui/icons/Favorite"
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder"
import Flex from "../Flex"
import Box from "../Box"
import IconButton from "../IconButton"

export default class ListRecord extends Component {
  constructor(props) {
    super(props)
    this.handleFavoriteClick = this.handleFavoriteClick.bind(this)
    this.showIcons = this.showIcons.bind(this)
    this.hideIcons = this.hideIcons.bind(this)
    this.removeProductHover = this.removeProductHover.bind(this)
    this.state = { showIcons: false, hoverCompany: false }
  }

  showIcons(e) {
    this.setState({
      showIcons: true,
      hoverCompany: true
    })
  }

  hideIcons(e) {
    this.setState({
      showIcons: false,
      hoverCompany: false
    })
  }

  removeProductHover(e) {
    this.setState({
      hoverCompany: false
    })
  }

  handleFavoriteClick(e) {
    this.props.handleFavoriteClick(this.props.company)
  }

  render () {
    const companyUrl = `/sectors/${this.props.sector.abbr}/companies/${this.props.company.id}`

    const companyFavorited = this.props.company.user_company_id && !this.props.company.user_company_deleted

    return(
      <TableRow onMouseEnter={this.showIcons} onMouseLeave={this.hideIcons}>
        <TableCell>
          <Link href={companyUrl}>
          {this.props.company.full_name}
        </Link>
        </TableCell>
        <TableCell>{dateFormat(this.props.company.updated_at)}</TableCell>
        <TableCell align='right' className={this.state.showIcons ? 'dn' : 'w-10'}>
          {this.props.company.n_products}
        </TableCell>

        <TableCell padding='checkbox' className={this.state.showIcons ? 'w-10' : 'dn'} align='right'>

          <Flex alignItems='center' justifyContent='end'>
            <Box>
              <IconButton onClick={this.handleFavoriteClick} tooltip='Favorite' size='small'>
                {companyFavorited ? <FavoriteIcon fontSize='small' color='primary' /> : <FavoriteBorderIcon fontSize='small' />}
              </IconButton>
            </Box>

            {/* <Box>
              <MuiIconButton onClick={this.handleProductCheckboxClick} edge="end" size='small'>
                {selected ? <CheckBoxIcon color='primary' fontSize='small' /> : <CheckBoxOutlineBlankIcon fontSize='small' />}
              </MuiIconButton>
            </Box> */}
          </Flex>


        </TableCell>
      </TableRow>
    )
  }
}

ListRecord.propTypes = {
  company: PropTypes.object
};
