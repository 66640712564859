import React, { Fragment } from "react"
import { Table, TableHead, TableRow, TableCell, TableBody, TableHeadSort } from "../tables"
import Link from "../Link"
import Flex from "../Flex"
import Box from "../Box"
import CloseIcon from "@material-ui/icons/Close"
import IconButton from "../IconButton"
import Tooltip from '@material-ui/core/Tooltip';

export default function DataCompareRow(props) {

  function handleEntityRemovalClick(e) {
    props.handleEntityRemovalClick(props.entity)
  }

  let currentDate = props.completion_date ? new Date(props.completion_date) : new Date
  let firstReceivedDate = new Date(props.entity.first_received_date)
  let dateDifference = currentDate.getTime() - firstReceivedDate.getTime()
  let durationInDays = dateDifference / (1000 * 60 * 60 * 24);

  let recruitingDateComparison = props.entity.active_not_recruiting_date ? new Date(props.entity.active_not_recruiting_date) : new Date
  let recruitingDateDifference = recruitingDateComparison.getTime() - new Date(props.entity.recruiting_date).getTime()
  let daysInRecruiting = recruitingDateDifference / (1000 * 60 * 60 * 24);

  let anrDateDifference = new Date(props.entity.primary_completion_date).getTime() - recruitingDateComparison.getTime()
  let daysInAnr = anrDateDifference / (1000 * 60 * 60 * 24);

  let primaryCompletionDateDifference = new Date(props.entity.completion_date).getTime() - new Date(props.entity.primary_completion_date).getTime()
  let daysInAnalysis = primaryCompletionDateDifference / (1000 * 60 * 60 * 24);

  return(
    <TableRow>
      <TableCell borderRight className='w-15'>
        <Fragment>
          <Flex alignItems='center'>
            <Box>
              <Link href={`/sectors/${props.sector.abbr}/trials/${props.entity.trial_id}`}>
                <Box>
                  {props.entity.acronym || props.entity.short_name}
                </Box>
              </Link>
            </Box>

            {props.activeEntity && props.activeEntity.id == props.entity.trial_id ?
              ""
            :
              <Box ml='auto'>
                <IconButton onClick={handleEntityRemovalClick} size='small'>
                  <CloseIcon fontSize='small' />
                </IconButton>
              </Box>
            }
          </Flex>
        </Fragment>
      </TableCell>
      <TableCell borderRight>
        {dateFormat(props.entity.first_received_date)}
      </TableCell>
      <TableCell borderRight>
        {dateFormat(props.entity.start_date)}
      </TableCell>
      <TableCell borderRight>
        {props.entity.recruiting_date ?
          <Fragment>
            <Box>
              {`${Math.round(daysInRecruiting)} days`}
            </Box>
          </Fragment>
        : 'N/A'}
      </TableCell>
      <TableCell borderRight>
        {props.entity.active_not_recruiting_date ?
          <Fragment>
            <Box>
              {`${Math.round(daysInAnr)} days`}
            </Box>
          </Fragment>
        : 'N/A'}
      </TableCell>
      <TableCell borderRight>
        {`${Math.round(daysInAnalysis)} days`}
      </TableCell>
      <TableCell borderRight>
        <Fragment>
          <span className='pr2'>
            {dateFormat(props.entity.completion_date)}
          </span>
          <span className='i'>
            {props.entity.completion_date_type ? `(${props.entity.completion_date_type})` : ''}
          </span>
        </Fragment>
      </TableCell>
      <TableCell borderRight>
        {`${Math.round(durationInDays)} days`}
      </TableCell>
      <TableCell borderRight>
        {props.entity.target_enrollment}
      </TableCell>
      <TableCell borderRight>
        {props.entity.current_enrollment}
      </TableCell>
      <TableCell borderRight>
        {props.entity.status}
      </TableCell>
    </TableRow>
  )
}
