import React, { Component, Fragment } from "react"
import ControlPanel from "../associator/ControlPanel"
import Snackbar from "../../Snackbar"
import update from "immutability-helper"
import Associator from "../Associator"
import ProductsPanel from "./ProductsPanel"
import TrialPanel from "./TrialPanel"
import SectorCheckboxList from "../associator/SectorCheckboxList"


export default class AssociatorContainer extends Component {
  constructor(props) {
    super(props)
    this.handleActiveIndexUpdate = this.handleActiveIndexUpdate.bind(this)
    this.handleCheckboxSelect = this.handleCheckboxSelect.bind(this)
    this.handleAssociateClick = this.handleAssociateClick.bind(this)
    this.handleUnassociateClick = this.handleUnassociateClick.bind(this)
    this.handleRemoveClick = this.handleRemoveClick.bind(this)
    this.handleProductSearchChange = this.handleProductSearchChange.bind(this)
    this.handleReviewedClick = this.handleReviewedClick.bind(this)
    this.handleSectorCheckboxSelect = this.handleSectorCheckboxSelect.bind(this)
    this.handleProductSearchClick = this.handleProductSearchClick.bind(this)
    this.handleInvestigatedSwitchChange = this.handleInvestigatedSwitchChange.bind(this)
    this.state = { trial: this.props.trial, products: this.props.products, activeProducts: [], trialProducts: this.props.trial_products, trialSectors: this.props.trial_sectors, activeIndex: 0, loading: false, showSnackbar: false, snackbarText: '', reviewed: true, query: '' }
  }

  componentDidMount() {
    if (this.props.trial_products) {
      let productIds = this.props.trial_products.map(trialProduct => trialProduct.product_id)

      this.setState({
        activeProducts: productIds
      })
    }
  }

  handleInvestigatedSwitchChange(trialProduct) {
    let investigatedValue = trialProduct.investigated_device ? false : true
    
    $.ajax({
      type: "PUT",
      url: `/admin/trial_products/${trialProduct.id}`,
      data: {
        trial_product: {
          investigated_device: investigatedValue
        }
      },
      dataType: 'json',
      success: (data) => {
        let index = this.state.trialProducts.indexOf(trialProduct)

        let trialProducts = update(this.state.trialProducts, {
          [index]: { investigated_device: { $set: data.trial_product.investigated_device }}
        })

        this.setState({
          trialProducts: trialProducts
        })
        
      }
    })
  }

  handleSectorCheckboxSelect(sector, checked) {

    let trialSector = this.state.trialSectors.filter(trialSector => trialSector.sector_id == sector.id)[0]

    if (trialSector) {
      $.ajax({
        type: 'PUT',
        url: `/admin/trial_sectors/${trialSector.id}`,
        data: {
          trial_sector: {
            deleted: true, deleted_by: 1
          }
        },
        dataType: 'JSON',
        success: (data) => {
          let index = this.state.trialSectors.indexOf(sector)

          let trialSectors = update(this.state.trialSectors, {
            $splice: [[index, 1]]
          })

          this.setState({
            trialSectors: trialSectors,
            showSnackbar: true,
            snackbarText: 'The sector was removed'
          })
        },
        error: (data) => {
          this.setState({
            showSnackbar: true,
            snackbarText: 'The sector could not be removed'
          })
        },
        complete: (data) => {
          setTimeout(() => {
            this.setState({
              showSnackbar: false,
              snackbarText: ''
            })
          }, 4000)
        }
      })
    } else {
      $.ajax({
        type: 'POST',
        url: `/admin/trial_sectors`,
        data: {
          trial_sector: {
            trial_id: this.props.trial.id, sector_id: sector.id, created_by: this.props.current_user.id
          }
        },
        dataType: 'json',
        success: (data) => {
          let trialSectors = update(this.state.trialSectors, {
            $push: [data.trial_sector]
          })

          this.setState({
            trialSectors: trialSectors,
            showSnackbar: true,
            snackbarText: 'The sector was added'
          })
        },
        error: (data) => {
          this.setState({
            showSnackbar: true,
            snackbarText: 'The sector could not be added'
          })
        },
        complete: (data) => {
          setTimeout(() => {
            this.setState({
              showSnackbar: false,
              snackbarText: ''
            })
          }, 4000)
        }
      })
    }
  }

  handleProductSearchChange(e) {
    this.setState({
      query: e.target.value
    })
  }

  handleProductSearchClick(e) {
    $.ajax({
      type: 'GET',
      url: `/admin/products/search`,
      data: {
        query: this.state.query
      },
      dataType: 'json',
      success: (data) => {
        this.setState({
          products: data.products
        })
      }
    })
  }

  handleActiveIndexUpdate(e, newValue) {
    this.setState({
      activeIndex: newValue
    })
  }

  handleRemoveClick(e) {
    $.ajax({
      type: "PUT",
      url: `/admin/trials/${this.props.trial.id}`,
      data: {
        trial: {
          deleted: true, deleted_by: this.props.current_user.id
        }
      },
      dataType: 'json',
      success: (data) => {

      }
    })
  }

  handleCheckboxSelect(productId, type) {
    console.log(productId)
    const index = this.state[type].indexOf(productId)
    const activeEntities = index == -1 ? update(this.state[type], { $push: [productId] }) : update(this.state[type], { $splice: [[index, 1]] })

    this.setState({
      [type]: activeEntities
    })
  }

  handleReviewedClick(e) {
    $.ajax({
      type: 'POST',
      url: '/admin/trial_associator_statuses',
      data: {
        trial_associator_status: {
          trial_id: this.state.trial.id, status: true
        }
      },
      dataType: 'json',
      success: (data) => {
        this.setState({
          reviewed: true,
          showSnackbar: true,
          snackbarText: "The trial has been marked as reviewed"
        })
      },
      error: (data) => {
        this.setState({
          showSnackbar: true,
          snackbarText: 'There was an issue setting the status. Please try again'
        })
      },
      complete: (data) => {
        setTimeout(() => {
          this.setState({
            showSnackbar: false,
            snackbarText: ''
          })
        }, 4000)
      }
    })
  }

  handleAssociateClick(e) {
    $.ajax({
      type: 'POST',
      url: `/admin/trial_products/bulk_associate`,
      data: {
        product_ids: this.state.activeProducts, trial_id: this.props.trial.id, created_by: this.props.current_user.id
      },
      dataType: 'json',
      success: (data) => {
        this.setState({
          trialProducts: data.trial_products,
          showSnackbar: true,
          snackbarText: 'Products have been been associated'
        })
      },
      error: (data) => {
        this.setState({
          showSnackbar: true,
          snackbarText: 'Something went wrong. Please try again.'
        })
      },
      complete: (data) => {
        setTimeout(() => {
          this.setState({
            showSnackbar: false,
            snackbarText: ''
          })
        }, 4000)
      }
    })
  }

  handleUnassociateClick(trialProduct) {
    $.ajax({
      type: 'PUT',
      url: `/admin/trial_products/${trialProduct.id}`,
      data: {
        trial_product: {
          deleted: true, deleted_by: this.props.current_user.id
        }
      },
      dataType: 'json',
      success: (data) => {

        let index = this.state.trialProducts.indexOf(trialProduct.id)
        let trialProducts = update(this.state.trialProducts, {
          $splice: [[index, 1]]
        })

        this.setState({
          trialProducts: trialProducts,
          showSnackbar: true,
          snackbarText: 'The product has been unassociated'
        })
      },
      error: (data) => {
        this.setState({
          showSnackbar: true,
          snackbarText: 'Something went wrong. Please try again.'
        })
      },
      complete: (data) => {
        setTimeout(() => {
          this.setState({
            showSnackbar: false,
            snackbarText: ''
          })
        }, 4000)
      }
    })
  }

  render() {
    return(
      <Fragment>

        <Associator
          firstPanel={
            <TrialPanel
              trial={this.props.trial}
              trialDataView={this.props.trial_data_view}
              predictedProducts={this.props.predicted_products}
              trialInterventions={this.props.trial_interventions}
              trialArms={this.props.trial_arms}
            />
          }
          secondPanel={
            <Fragment>
              <ControlPanel
                handleAssociateClick={this.handleAssociateClick}
                handleReviewedClick={this.handleReviewedClick}
              />

              <SectorCheckboxList
                sectors={this.props.sectors}
                entitySectors={this.state.trialSectors}
                predictedSectors={this.props.trial_sector_predictions}
                handleSectorCheckboxSelect={this.handleSectorCheckboxSelect}
              />
            </Fragment>
          }
          thirdPanel={
            <ProductsPanel
              predictedProducts={this.props.predicted_products}
              products={this.state.products}
              trialProducts={this.state.trialProducts}
              activeProducts={this.state.activeProducts}
              productSearch={this.state.productSearch}
              activeIndex={this.state.activeIndex}
              query={this.state.query}
              handleCheckboxSelect={this.handleCheckboxSelect}
              handleUnassociateClick={this.handleUnassociateClick}
              handleActiveIndexUpdate={this.handleActiveIndexUpdate}
              handleProductSearchChange={this.handleProductSearchChange}
              handleProductSearchClick={this.handleProductSearchClick}
              handleInvestigatedSwitchChange={this.handleInvestigatedSwitchChange}
            />
          }
          associationLoading={this.state.associationLoading}
        />

        <Snackbar
          open={this.state.showSnackbar}
          handleUndoClick={this.handleUndoClick}
          message={this.state.snackbarText}
        />
      </Fragment>
    )
  }
}
