import React from "react"
import DeleteButton from "../DeleteButton"
import { ListItem, ListItemText } from "../../lists"
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction"

export default function AssociatedProductListItem(props) {

  function handleUnassociateClick(e) {
    props.handleUnassociateClick(props.abstractProduct)
  }

  return (
    <ListItem button key={props.abstractProduct.id} role={undefined}>

      <ListItemText primary={props.abstractProduct.product.short_name} />

      <ListItemSecondaryAction>
        <DeleteButton onClick={handleUnassociateClick} />
      </ListItemSecondaryAction>

    </ListItem>
  )
}
