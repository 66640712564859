import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Paper from "../../Paper"
import CharacteristicGroupsTable from "./CharacteristicGroupsTable"
import update from "immutability-helper"
import Snackbar from "../../Snackbar"

export default class CharacteristicGroups extends Component {
  constructor(props) {
    super(props)
    this.handleDeleteClick = this.handleDeleteClick.bind(this)
    this.handleUndoClick = this.handleUndoClick.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.state = { characteristicGroups: this.props.characteristic_groups, showSnackbar: false, snackbarText: '', lastDeleted: '' }
  }
  
  handleClose(e) {
    this.setState({
      showSnackbar: false,
      snackbarText: ''
    })
  }
  
  handleUndoClick(event, reason) {
    if (this.state.lastDeleted) {
      $.ajax({
        type: 'PUT',
        url: `/admin/characteristic_groups/${this.state.lastDeleted.id}`,
        data: {
          characteristic_group: {
            deleted: null, deleted_by: null
          }
        },
        dataType: 'json',
        success: (data) => {
          const characteristicGroups = update(this.state.characteristicGroups, { $push: [this.state.lastDeleted] })
          this.setState({
            characteristicGroups: characteristicGroups,
            showSnackbar: true,
            snackbarText: 'Characteristic Group was undeleted',
            lastDeleted: null
          })
        },
        error: (data) => {
          this.setState({
            showSnackbar: true,
            snackbarText: data.responseText
          })
        },
        completed: (data) => {
          setTimeout(() => {
            this.setState({
              showSnackbar: false,
              snackbarText: ''
            })
          }, 4000)
        }
      })
    }
  }
  
  handleDeleteClick(characteristicGroup) {
    $.ajax({
      type: 'PUT',
      url: `/admin/characteristic_groups/${characteristicGroup.id}`,
      data: {
        characteristic_group: {
          deleted: true, deleted_by: this.props.current_user.id
        }
      },
      dataType: 'json',
      success: (data) => {
        let characteristicGroupIds = this.state.characteristicGroups.map((characteristicGroup) => characteristicGroup.id )
        let index = characteristicGroupIds.indexOf(characteristicGroup.id)
        let characteristicGroups = update(this.state.characteristicGroups, { $splice: [[index, 1]]})
        
        this.setState({
          characteristicGroups: characteristicGroups,
          showSnackbar: true,
          snackbarText: 'The characteristic group was successfully deleted',
          lastDeleted: characteristicGroup
        })
      },
      error: (data) => {
        this.setState({
          showSnackbar: true,
          snackbarText: `The characteristic group could not be deleted. ${data.responseText}`
        })
      },
      complete: (data) => {
        setTimeout(() => {
          this.setState({
            showSnackbar: false,
            snackbarText: ''
          })
        }, 4000)
      }
    })
  }
  
  render() {
    return(
      <Fragment>
        
        <Paper>
          <CharacteristicGroupsTable 
            characteristicGroups={this.state.characteristicGroups}
            handleDeleteClick={this.handleDeleteClick}
          />
        </Paper>
        
        
        <Snackbar 
          open={this.state.showSnackbar}
          handleUndoClick={this.handleUndoClick}
          handleClose={this.handleClose}
          message={this.state.snackbarText} 
        /> 
        
      </Fragment>
    )
  }
}
