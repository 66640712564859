import React, { Fragment } from "react"
import ResultItemContainer from "../search/ResultItemContainer"
import Box from "../Box"
import Flex from "../Flex"
import Typography from "@material-ui/core/Typography"
import Tooltip from "@material-ui/core/Tooltip"

export default function SearchItem(props) {

  let url = props.admin ? `/admin/fda_k_clearances/${props.result.id}/associator` : props.result.url

  function renderSearchDetails(result) {
    return (
      <Flex>
        <Box mr={2}>
          <Typography variant="body2" color="textSecondary">
            {result.applicant}
          </Typography>
        </Box>
        <Box>
          {result.fda_device_classifications ? renderProductCodes(result.fda_device_classifications) : ""}
        </Box>
      </Flex>
    );
  }

  function renderProductCodes(productCodes) {
    return (
      <Fragment>
        {productCodes.map((productCode) =>
          <Box key={productCode.code} mr={2}>
            <Typography variant="body2" color="textSecondary">
              <Tooltip title={productCode.device_name} placement="top">
                <span>{productCode.code}</span>
              </Tooltip>
            </Typography>
          </Box>
        )}
      </Fragment>
    );
  }

  return(
    <ResultItemContainer 
      searchItemType={props.result.record_type}
      searchTitle={`${props.result.k_number} - ${props.result.device_name}`}
      date={props.result.decision_date}
      // searchDescription={props.result.applicant}
      url={url}
      target="_blank"
      searchDetails={renderSearchDetails(props.result)}
    />
  )
}