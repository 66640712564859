import React from "react"
import ResultItemContainer from "../search/ResultItemContainer"
import Typography from "@material-ui/core/Typography"
import Flex from "../Flex"
import Box from "../Box"

export default function SearchItem(props) {

  let url = props.admin ? `/admin/ct_gov_trials/${props.result.id}` : `https://clinicaltrials.gov/ct2/show/${props.result.nct_id}`

  function renderSearchDetails(result) {
    return(
      <Flex>
        <Box mr={2}>
          {renderSearchDetailValue(result.nct_id)}
        </Box>
        <Box>
          {renderSearchDetailValue(result.sponsor)}
        </Box>
        <Box>
          {renderSearchDetailValue(result.overall_status)}
        </Box>
      </Flex>
    )
  }

  function renderSearchDetailValue(searchDetailValue) {
    return (
      <Typography variant="body2" color="textSecondary">
        {searchDetailValue}
      </Typography>
    );
  }

  return(
    <ResultItemContainer 
      searchItemType={props.result.record_type}
      searchTitle={props.result.brief_title}
      date={props.result.last_changed_date}
      searchDescription={props.result.brief_summary}
      url={url}
      searchDetails={renderSearchDetails(props.result)}
    />
  )
}