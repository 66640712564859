import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import PublicationCard from "./PublicationCard.jsx"
import EntityLayout from "../EntityLayout"
import Paper from "../Paper"
import Flex from "../Flex"
import Box from "../Box"
import Typography from "@material-ui/core/Typography"
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { List, ListItem, ListItemText, ListItemLink } from "../lists"
import ListSubheader from '@material-ui/core/ListSubheader';
import Link from "../Link"


export default class Profile extends Component {
  render() {
    return(
      <Fragment>

        <EntityLayout>

          <Box className='w-100 w-60-ns mb3 mb0-ns'>

            <Paper>
              <Box mx={3}>
                <Box mb={2}>
                  <Link href={this.props.manuscript.url} target="_blank">
                    <Typography variant="h6">
                      {this.props.manuscript.title}
                    </Typography>
                  </Link>
                </Box>

                <Flex mb={2} alignItems='center'>
                  <Box>
                    <Link href={`/sectors/${this.props.sector.abbr}/publications?journal_ids=${this.props.manuscript.journal.id}`}>
                      {this.props.manuscript.journal.short_name}
                    </Link>
                  </Box>

                  <Box ml='auto'>
                    {humanDateFormat(this.props.manuscript.published_date)}
                  </Box>
                </Flex>

                {this.props.manuscript_authors.length > 0 ? <Box mt={3}>
                  {this.props.manuscript_authors.map((person) =>
                    <Box key={person.id} display="inline">
                      <Link href={`/sectors/${this.props.sector.abbr}/people/${person.id}`}>
                        {`${person.names[0].first_name} ${person.names[0].last_name}`}
                      </Link>
                    </Box>
                  ).reduce((prev, curr) => [prev, ', ', curr])}
                </Box> : ''}

                <Box mt={3}>
                  <Typography variant="h6">Abstract</Typography>

                  <Box mt={2}>
                    {this.props.manuscript_texts.map((manuscriptText) =>
                      <Box my={2}>
                        <Box borderBottom={1} borderColor='#e0e0e0' fontWeight={600}>
                          {manuscriptText.abstract_field_name ? capitalizeFirstLetter(manuscriptText.abstract_field_name.toLowerCase()) : 'Abstract'}
                        </Box>

                        <Box mt={1}>
                          <p>
                            {manuscriptText.abstract_field_text}
                          </p>
                        </Box>
                      </Box>
                    )}
                  </Box>
                </Box>
              </Box>
            </Paper>

            {this.props.current_user.user_company_id == 1 ?
              <ExpansionPanel defaultExpanded={true}>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} ariacontrols='manuscript-keyword-panel'>
                  <Typography className="f4">
                    Keywords
                  </Typography>
                </ExpansionPanelSummary>

                <ExpansionPanelDetails>
                  <Typography>
                    {this.props.manuscript_keywords.map((manuscriptKeyword) =>
                      <Box key={manuscriptKeyword.id}>
                        {manuscriptKeyword.name}
                      </Box>
                    )}
                  </Typography>
                </ExpansionPanelDetails>

              </ExpansionPanel>
            : ''}

            {this.props.current_user.user_company_id == 1 ?
              <ExpansionPanel defaultExpanded={true}>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} ariacontrols='manuscript-keyword-panel'>
                  <Typography className="f4">
                    Mesh terms
                  </Typography>
                </ExpansionPanelSummary>

                <ExpansionPanelDetails>
                  <Typography>
                    {this.props.manuscript_mesh_terms.map((manuscriptMeshTerm) =>
                      <Box key={manuscriptMeshTerm.id}>
                        {manuscriptMeshTerm.name}
                      </Box>
                    )}
                  </Typography>
                </ExpansionPanelDetails>

              </ExpansionPanel>
            : ''}

            {/* {this.props.manuscript_texts.map((manuscriptText, index) =>
              <ExpansionPanel defaultExpanded={index === 0} key={(manuscriptText.nlm_category_name || manuscriptText.abstract_field_name)}>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} ariacontrols={`${(manuscriptText.nlm_category_name || manuscriptText.abstract_field_name)}-panel`} id={`${(manuscriptText.nlm_category_name || manuscriptText.abstract_field_name)}-header`}>
                  <Typography className="f4">
                    {manuscriptText.abstract_field_name ? manuscriptText.abstract_field_name : 'Abstract' }
                  </Typography>
                </ExpansionPanelSummary>

                <ExpansionPanelDetails>
                  <Typography>
                    {manuscriptText.abstract_field_text}
                  </Typography>
                </ExpansionPanelDetails>

              </ExpansionPanel>
            )}

            {this.props.manuscript_authors.length > 0 ? <ExpansionPanel defaultExpanded={false}>
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} ariacontrols={`authors-panel`} id={`authors-header`}>
                <Typography className="f4">
                  Authors
                </Typography>
              </ExpansionPanelSummary>

              <ExpansionPanelDetails>
                <Typography className='w-100'>
                  <List>
                    {this.props.manuscript_authors.map((person) =>
                      <ListItemLink href={`/sectors/${this.props.sector.abbr}/people/${person.id}`}>

                        <ListItemText
                          primary={person.names[0].text}
                        />

                      </ListItemLink>
                    )}
                  </List>
                </Typography>
              </ExpansionPanelDetails>

            </ExpansionPanel> : ''} */}
          </Box>

          <Box className='w-100 w-33-ns ml-auto'>
            <PublicationCard
              manuscript={this.props.manuscript}
              products={this.props.products}
              companies={this.props.companies}
              trials={this.props.trials}
              targetSites={this.props.target_sites}
              therapyTypes={this.props.therapy_types}
              sector={this.props.sector}
            />
          </Box>

        </EntityLayout>
      </Fragment>
    );
  }
}
