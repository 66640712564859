import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import TextInput from "../../TextInput"
import { Button } from "../../buttons"
import Typography from '@material-ui/core/Typography';
import Flex from "../../Flex"
import Box from "../../Box"
import update from "immutability-helper"
import DeleteButton from "../DeleteButton"
import Select from "../../Select"

class SearchTermRecord extends Component {
  constructor(props) {
    super(props)
    this.handleOperatorChange = this.handleOperatorChange.bind(this)
    this.handleDeleteClick = this.handleDeleteClick.bind(this)
    this.handleTermChange = this.handleTermChange.bind(this)
  }

  handleOperatorChange(name, value) {
    this.props.handleOperatorChange(this.props.productSearchTerm, name, value)
  }

  handleDeleteClick(e) {
    this.props.handleDeleteClick(this.props.productSearchTerm)
  }

  handleTermChange(e) {
    this.props.handleTermChange(this.props.productSearchTerm, e.target.name, e.target.value)
  }


  render() {
    return(
      <Fragment>
          <Flex mb={3} alignItems='center' key={this.props.productSearchTerm.id}>
            <Box pr={3} width={1/3}>
              <TextInput
                value={this.props.productSearchTerm.search_term}
                handleChange={this.handleTermChange}
                fullWidth
              />
            </Box>
            <Box pr={3} width={1/4}>
              <Select
                label='Operator'
                entities={[{name: 'OR', value: 'OR'}, { name: 'AND', value: 'AND' }]}
                handleChange={this.handleOperatorChange}
                includeBlank={true}
                name='operator'
                value={this.props.productSearchTerm.operator}
              />
            </Box>
            <Box>
              <DeleteButton onClick={this.handleDeleteClick} />
            </Box>
          </Flex>

      </Fragment>
    )
  }
}

export default SearchTermRecord
