import React from "react"
import Paper from "../../Paper"
import Box from "../../Box"
import { withSnackbar } from "../../SnackbarContainer"
import TextInput from "../../TextInput"
import Select from "../../Select"
import update from "immutability-helper"
import ButtonLayout from "../ButtonLayout"
import { RaisedButton } from "../../buttons"
import DatePicker from "../../DatePicker"

class NewContainer extends React.Component {
  constructor(props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
    this.handleDateChange = this.handleDateChange.bind(this)
    this.handleSaveClick = this.handleSaveClick.bind(this)
    this.handleDropdownChange = this.handleDropdownChange.bind(this)
    this.state = { abstract: this.props.abstract }
  }

  handleDateChange(date) {
    let abstract = update(this.state.abstract, {
      date: { $set: date }
    })

    this.setState({
      abstract: abstract
    })
  }

  handleChange(e) {
    let abstract = update(this.state.abstract, {
      [e.target.name]: { $set: e.target.value }
    })

    this.setState({
      abstract: abstract
    })
  }

  handleDropdownChange(name, value) {
    let abstract = update(this.state.abstract, {
      [name]: { $set: value }
    })

    this.setState({
      abstract: abstract
    })
  }

  handleSaveClick(e) {
    $.ajax({
      type: "POST",
      url: "/admin/abstracts",
      data: {
        abstract: this.state.abstract
      },
      dataType: 'json',
      success: (data) => {
        window.location.href = `/admin/abstracts/${data.abstract.id}`
      },
      error: (data) => {
        this.props.snackbarShowMessage("There was an error saving the abstract. Please try again.")
      }
    })
  }

  render() {
    return(
      <Paper minFullHeight>

        <Box mb={3}>
          <TextInput
            name='title'
            label="Title"
            value={this.state.abstract.title}
            onChange={this.handleChange}
            fullWidth
          />
        </Box>

        <Box mb={3}>
          <DatePicker
            name='date'
            label='Date'
            handleChange={this.handleDateChange}
            value={this.state.abstract.date}
          />
        </Box>

        <Box mb={3}>
          <Select
            name="science_meeting_occurrence_id"
            label="Meeting"
            entities={this.props.meetings}
            value={this.state.abstract.science_meeting_occurrence_id}
            handleChange={this.handleDropdownChange}
          />
        </Box>

        <Box mb={3}>
          <Select
            name="abstract_type_id"
            label="Abstract type"
            entities={this.props.abstract_types}
            value={this.state.abstract.abstract_type_id}
            handleChange={this.handleDropdownChange}
          />
        </Box>

        <Box mb={3}>
          <TextInput
            name='url'
            label="URL"
            value={this.state.abstract.url}
            onChange={this.handleChange}
            fullWidth
          />
        </Box>

        <Box mb={4}>
          <TextInput
            name='presenter'
            label="Presenter"
            value={this.state.abstract.presenter}
            onChange={this.handleChange}
            fullWidth
          />
        </Box>

        <ButtonLayout>
          <RaisedButton onClick={this.handleSaveClick}>
            Save Abstract
          </RaisedButton>
        </ButtonLayout>


      </Paper>
    )
  }
}

export default withSnackbar(NewContainer)
