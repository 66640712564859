import React from "react"
import PropTypes from "prop-types"
import { ListItem, ListItemText } from "../../lists"
import Checkbox from "../../Checkbox"
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction"
import Link from "../../Link"


export default function ProductListItem(props)  {
  
  function handleCheckboxSelect(e) {
    props.handleCheckboxSelect(props.product.id, 'activeProducts')
  }
  
  return(
    <React.Fragment>
      <ListItem button key={props.product.id} role={undefined}>
        
        {props.forSelection ? <Checkbox 
          checked={props.checked} 
          onClick={handleCheckboxSelect}
        /> : ''}
        
        <ListItemText 
          primary={
            <Link href={`/admin/products/${props.product.id}/edit`} target="_blank">
              {props.product.short_name} 
            </Link>
          }
          secondary={props.product.search_term}
        />
        
        {props.hits ? <ListItemSecondaryAction>
          {props.hits}
        </ListItemSecondaryAction> : ''}
        
      </ListItem>
    </React.Fragment>
  )
}

ProductListItem.defaultProps = {
  forSelection: true
}
