import React, { Fragment } from "react"
import Box from "../Box"

function Content(props) {

  return (
    <Fragment>

      <Box >
        {props.content}
      </Box>

    </Fragment>
  )
}

export default Content
