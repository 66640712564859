import React, { Component, Fragment} from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Paper from "../../../Paper"
import Flex from "../../../Flex"
import Box from "../../../Box"
import { Table, TableRow, TableCell, TableBody, TableHead } from "../../../tables"
import Typography from "@material-ui/core/Typography"
import update from "immutability-helper"
import { withSnackbar } from "../../../SnackbarContainer"
import ReviewerRow from "./ReviewerRow"

class Reviewer extends Component {
  constructor(props) {
    super(props)
    this.handleCompanySearchChange = this.handleCompanySearchChange.bind(this)
    this.handleSponsorCheckboxClick = this.handleSponsorCheckboxClick.bind(this)
    this.state = { sponsors: this.props.sponsors, checkedSponsor: null, companyQuery: "", company_id: null, loading: false }
  }

  handleSponsorCheckboxClick(sponsor) {
    this.setState({
      checkedSponsor: sponsor.id
    })
  }

  handleCompanySearchChange(entityOptionValue, sponsorId) {
    this.setState({
      company_id: entityOptionValue,
      loading: true
    }, () => {
      $.ajax({
        type: 'PUT',
        url: `/admin/trial_sponsors/update_sponsor`,
        data: {
          company_id: entityOptionValue, sponsor_id: sponsorId
        },
        dataType: 'json',
        success: (data) => {
          let index = this.state.sponsors.map(sponsor => sponsor.id).indexOf(sponsorId)

          let sponsors = update(this.state.sponsors, {
            $splice: [[index, 1]]
          })

          this.setState({
            sponsors: sponsors,
            loading: false
          })

          this.props.snackbarShowMessage("Sponsor has been updated")
        },
        error: (data) => {
          this.setState({
            loading: false
          })
          this.props.snackbarShowMessage("Sponsor could not be updated. Please try again.", "error")
        }
      })
    })
  }

  render() {
    return(
      <Fragment>

        <Paper fullHeight>

          <Box height="100%">
            <Box display="flex" justifyContent='right'>
              {`${this.state.sponsors.length} sponsors`}
            </Box>

            <Box height="90%" overflow="auto">

              <Table stickyHeader={true}>
                <TableHead>
                  <TableRow hover={false}>
                    <TableCell className='bg-white'></TableCell>
                    <TableCell className='bg-white'>Sponsor</TableCell>
                    <TableCell className='bg-white'>Company</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {this.state.sponsors.length > 0 ? this.state.sponsors.map((sponsor) =>
                    <ReviewerRow
                      sponsor={sponsor}
                      companies={this.props.companies}
                      companyQuery={this.state.companyQuery}
                      checkedSponsor={this.state.checkedSponsor}
                      loading={this.state.loading}
                      handleCompanySearchChange={this.handleCompanySearchChange}
                      handleSponsorCheckboxClick={this.handleSponsorCheckboxClick}
                    />
                  ) :
                    <TableRow>
                      <TableCell colSpan={4}>No trials to review. Check back later.</TableCell>
                    </TableRow>
                  }
                </TableBody>
              </Table>
            </Box>
          </Box>
        </Paper>

      </Fragment>
    )
  }
}


export default withSnackbar(Reviewer)
