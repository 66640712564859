import React, { Fragment } from "react"

export default function TargetMarketDisplay(props) {
  return (
    <Fragment>
      {props.targetMarkets.map((targetMarket) =>
        <div key={targetMarket.id}>
          {targetMarket.name}
        </div>
      )}
    </Fragment>
  )
}
