import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Paper from "../../Paper"
import Flex from "../../Flex"
import Box from "../../Box"
import EntityCard from "../EntityCard"
import Highlighter from "react-highlight-words";
import Link from "../../Link"

export default class PublicationPanel extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    let authors = this.props.authors ? this.props.authors : this.props.publication.authors
    let publicationText = this.props.publicationText ? this.props.publicationText : this.props.publication.text
    return(
      <Fragment>

        <Flex flexDirection='column'>
          <Box width={1} mb={1}>
            <label className='b'>Abstract</label>
          </Box>
          <Box>
            {this.props.searchWords ?
              <Highlighter
                searchWords={this.props.searchWords}
                autoEscape={true}
                textToHighlight={publicationText}
              />
            : publicationText}
          </Box>
        </Flex>

      </Fragment>
    )
  }
}
