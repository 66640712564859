import React, { Fragment } from "react"
import Select from "../Select"
import Flex from "../Flex"
import Box from "../Box"

export default function TimelineFilters(props) {
  return (
    <Fragment>
      
      <Flex alignItems='center'>
        <Box width={1/5} mr={2}>
          <Select 
            name='world_region_id'
            label='Region'
            entities={props.worldRegions}
            value={props.worldRegion}
            handleChange={props.handleFilterChange}
          />
        </Box>
        
        <Box width={1/5} mr={1}>
          <Select 
            name='attribute'
            label='Attributes'
            entities={props.attributes}
            value={props.attribute}
            handleChange={props.handleFilterChange}
          />
        </Box>
        
        {props.attribute == 'characteristics' ?
          <Box width={1/5}>
            <Select 
              name='characteristic_group_id'
              label='Characteristic Group'
              entities={props.characteristicGroups}
              value={props.characteristicGroup}
              handleChange={props.handleCharacteristicGroupChange}
            />
          </Box> : ''
        }
      </Flex>
      
    </Fragment>
  )
}
