import React, { Component, Fragment } from "react"
import PropTypes from "prop-types"
import { List } from "../lists"
import UserListItem from "./UserListItem"

export default class CollectionShareForm extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    return(
      <Fragment>
        <List>
          {this.props.users.map((user) =>

            <UserListItem
              key={user.id}
              user={user}
              userCollectionMembers={this.props.userCollectionMembers}
              handleShareCheckboxClick={this.props.handleShareCheckboxClick}
            />

          )}
        </List>
      </Fragment>
    )
  }
}
