import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import List from "../lists/List";
import NestedListItemLink from "../lists/NestedListItemLink"
import ListItemLink from "../lists/ListItemLink"
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ExpandLess from '../ExpandLess';
import ExpandMore from '../ExpandMore';
import Collapse from '@material-ui/core/Collapse';

export default class NestedCompanyList extends Component {
  constructor(props) {
    super(props)
    this.state = { open: false }
  }
  
  UNSAFE_componentWillMount() {
    if (this.props.activeGroup == 'Companies') {
      this.setState({
        open: true
      })
    }
  }
  
  render() {
    return(
      <Fragment>
        
        <ListItem button onClick={() => this.setState({open: !this.state.open})}>
          <ListItemText primary="Companies" />
          {this.state.open ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        
        <Collapse in={this.state.open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            
            <NestedListItemLink href={`/admin/companies/dashboard`}>
              <ListItemText primary='Dashboard' className={this.props.activeItem == 'Dashboard' && this.props.activeGroup == 'Companies' ? 'orange' : ''} />
            </NestedListItemLink>
            
            <NestedListItemLink href={`/admin/companies`}>
              <ListItemText primary='List' className={this.props.activeItem == 'List' && this.props.activeGroup == 'Companies' ? 'orange' : ''} />
            </NestedListItemLink>
            
            <NestedListItemLink href={`/admin/companies/new`}>
              <ListItemText primary='New Company' className={this.props.activeItem == 'New Company' && this.props.activeGroup == 'Companies' ? 'orange' : ''} />
            </NestedListItemLink>
            
            <NestedListItemLink href={`/admin/profile_field_names`}>
              <ListItemText primary='Profile Field Names' className={this.props.activeItem == 'Profile Field Names' && this.props.activeGroup == 'Companies' ? 'orange' : ''} />
            </NestedListItemLink>
            
          </List>
        </Collapse>
        
      </Fragment>
    )
  }
}
