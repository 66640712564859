import React, { Component, Fragment } from "react"
import Flex from "../../Flex"
import Box from "../../Box"
import update from "immutability-helper"
import DisplayContainer from "../product_characteristics/DisplayContainer"
import CharacteristicGroupDisplay from "./CharacteristicGroupDisplay"
import { Button } from "../../buttons"
import Snackbar from "../../Snackbar"
import CircularProgress from '@material-ui/core/CircularProgress';
import CharacteristicStatusTabs from "./CharacteristicStatusTabs"
import TherapyTypeForm from "./TherapyTypeForm"
import TechnologyGroupForm from "./TechnologyGroupForm"
import MethodTypeForm from "./MethodTypeForm"
import InlineSearchContainer from "../search/InlineSearchContainer"

export default class CharacteristicsForm extends Component {
  constructor(props) {
    super(props)
    this.handleCheckboxSelect = this.handleCheckboxSelect.bind(this)
    this.handleIndexUpdate = this.handleIndexUpdate.bind(this)
    this.handleRegionChange = this.handleRegionChange.bind(this)
    this.newProductCharacteristic = this.newProductCharacteristic.bind(this)
    this.removeProductCharacteristic = this.removeProductCharacteristic.bind(this)
    this.handleSearchClick = this.handleSearchClick.bind(this)
    this.handleClearSearchClick = this.handleClearSearchClick.bind(this)
    this.state = { orginialCharacteristicGroups: null, productCharacteristics: null, productTechnologyGroups: null, productTherapyTypes: null, allOpen: false, showSnackbar: false, snackbarText: '', activeIndex: 0, loading: true, query: "", technologyGroups: this.props.technologyGroups, therapyTypes: this.props.therapyTypes, originalTechnologyGroups: null, originalTherapyTypes: null }
  }

  componentDidMount() {
    $.get(`/admin/products/${this.props.product.id}/characteristics`, { product_id: this.props.product.id, by_region: this.props.byRegion }, (data) => {
      this.setState({
        productCharacteristics: data.product_characteristics, characteristicGroups: data.characteristic_groups, orginialCharacteristicGroups: data.characteristic_groups, productTherapyTypes: data.product_therapy_types, productTechnologyGroups: data.product_technology_groups, loading: false, originalTechnologyGroups: data.technology_groups, originalTherapyTypes: data.therapy_types, productMethods: data.product_methods, methods: data.methods
      })
    }, "json")
  }

  handleSearchClick(query) {
    $.ajax({
      type: 'GET',
      url: `/admin/products/search_characteristics`,
      data: {
        query: query
      },
      dataType: 'json',
      success: (data) => {
        this.setState({
          characteristicGroups: data.characteristic_groups,
          therapyTypes: data.therapy_types,
          technologyGroups: data.technology_groups
        })
      }
    })
  }

  handleClearSearchClick(e) {
    this.setState({
      characteristicGroups: this.state.orginialCharacteristicGroups,
      therapyTypes: this.state.originalTherapyTypes,
      technologyGroups: this.state.originalTechnologyGroups
    })
  }

  handleIndexUpdate(e, newValue) {
    this.setState({
      activeIndex: newValue
    })
  }

  handleRegionChange(e) {
    const value = e.target.value

    const index = this.state.activeRegions.indexOf(value)

    const activeRegions = index == -1 ? update(this.state.activeRegions, { $push: [value] }) : update(this.state.activeRegions, { $splice: [[index, 1]] })

    this.setState({
      activeRegions: activeRegions
    })
  }

  handleCheckboxSelect(characteristicValue, characteristicGroup, checked, productCharacteristic) {
    if (checked) {

      this.removeProductCharacteristic(productCharacteristic)

    } else {
      if (this.props.source) {

        this.newProductCharacteristic(characteristicGroup, characteristicValue)

      } else {
        this.setState({
          showSnackbar: true,
          snackbarText: `You must choose a source before adding a characteristic`
        })
        setTimeout(() => {
          this.setState({
            showSnackbar: false,
            snackbarText: ''
          })
        }, 4000)
      }
    }
  }

  removeProductCharacteristic(productCharacteristic) {
    $.ajax({
      type: 'PUT',
      url: `/admin/product_characteristics/${productCharacteristic.id}`,
      data: {
        product_characteristic: {
          deleted: true, deleted_by: this.props.currentUser.id
        }
      },
      dataType: 'json',
      success: (data) => {
        const productCharacteristicIndex = this.state.productCharacteristics.indexOf(productCharacteristic)

        const productCharacteristics = update(this.state.productCharacteristics, {
          $splice: [[productCharacteristicIndex, 1]]
        })

        this.setState({
          productCharacteristics: productCharacteristics,
          showSnackbar: true,
          snackbarText: 'Characteristic was removed'
        })
      },
      error: (data) => {
        this.setState({
          showSnackbar: true,
          snackbarText: 'The characteristic could not be removed'
        })
      },
      complete: (data) => {
        setTimeout(() => {
          this.setState({
            showSnackbar: false,
            snackbarText: ''
          })
        })
      }
    })
  }

  newProductCharacteristic(characteristicGroup, characteristicValue) {
    let mainParams = { product_id: this.props.product.id, characteristic_group_id: characteristicGroup.id, characteristic_value_id: characteristicValue.id, created_by: this.props.currentUser.id, source_id: this.props.source.source_id, investigational: (this.state.activeIndex == 1000000 ? true : null) }

    $.post("/admin/product_characteristics", { product_characteristic: mainParams }, (data) => {

      const productCharacteristics = update(this.state.productCharacteristics, {
        $push: [data.product_characteristic]
      })

      this.setState({
        productCharacteristics: productCharacteristics,
        showSnackbar: true,
        snackbarText: 'Characteristic was added'
      })
    }, 'json').fail((data) => {
      this.setState({
        showSnackbar: true,
        snackbarText: `Characteristic could not be added. ${data.repsonseText}`
      })
    }).always((data) => {
      setTimeout(() => {
        this.setState({
          showSnackbar: false,
          snackbarText: ''
        })
      }, 4000)
    })
  }



  render() {
    return(
      <Fragment>
        <Box height="80%">

          <Box>
            <Flex alignItems='baseline'>
              {/* <Box>
                <Typography variant="h6" component="h6">
                  Product Characteristics
                </Typography>
              </Box> */}

              <Box>
               <InlineSearchContainer
                 searchLabel="Search Characteristics"
                 query={this.state.query}
                 handleSearchClick={this.handleSearchClick}
                 handleClearSearchClick={this.handleClearSearchClick}
               />
              </Box>

              <Box ml="auto">
                <Button tag='a' href={`/admin/characteristic_groups/new`} target="_blank">
                  Add Characteristic Group
                </Button>
              </Box>
            </Flex>

            <Box my={3}>

              <Box position='sticky'>
                <CharacteristicStatusTabs
                  activeIndex={this.state.activeIndex}
                  handleIndexUpdate={this.handleIndexUpdate}
                />
              </Box>
            </Box>
          </Box>

          <Box overflow='auto' height="90%">

            {this.state.loading ?
              <Box>
                <Box width="10%" mx="auto">
                  <CircularProgress />
                </Box>
              </Box>
            :
              <Fragment>
                {this.state.therapyTypes.length > 0 ? 
                  <TherapyTypeForm
                    product={this.props.product}
                    productTherapyTypes={this.state.productTherapyTypes}
                    therapyTypes={this.state.therapyTypes}
                    source={this.props.source}
                    activeRegion={this.state.activeIndex}
                    currentUser={this.props.currentUser}
                  />
                : ""}

                {this.state.technologyGroups.length > 0 ? 
                  <TechnologyGroupForm
                    product={this.props.product}
                    productTechnologyGroups={this.state.productTechnologyGroups}
                    technologyGroups={this.state.technologyGroups}
                    source={this.props.source}
                    activeRegion={this.state.activeIndex}
                    currentUser={this.props.currentUser}
                  />
                : ""}

                {this.state.methods.length > 0 ?
                  <MethodTypeForm
                    product={this.props.product}
                    productMethods={this.state.productMethods}
                    methods={this.state.methods}
                    source={this.props.source}
                    activeRegion={this.state.activeIndex}
                    currentUser={this.props.currentUser}
                  />
                : ""}

                {this.state.characteristicGroups.map((characteristicGroup) =>
                  <CharacteristicGroupDisplay
                    key={characteristicGroup.id}
                    displayCharacteristics={
                      <DisplayContainer
                        characteristicGroup={characteristicGroup}
                        productCharacteristics={this.state.productCharacteristics}
                        activeRegionIndex={this.state.activeIndex}
                        handleCheckboxSelect={this.handleCheckboxSelect}
                      />
                    }
                    allOpen={this.state.allOpen}
                    characteristicGroup={characteristicGroup}
                    product={this.props.product}
                  />
                )}
              </Fragment>
            }
          </Box>

        </Box>

        <Snackbar
          open={this.state.showSnackbar}
          message={this.state.snackbarText}
        />
      </Fragment>
    )
  }
}
