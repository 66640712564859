import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Paper from "../../Paper"
import Flex from "../../Flex"
import Box from "../../Box"
import EntityCard from "../EntityCard"
import Highlighter from "react-highlight-words";
import Link from "../../Link"
import Typography from "@material-ui/core/Typography"
import FdaLink from "./FdaLink"

export default function RecallCard(props) {

  return(
    <Fragment>

      <Paper fullHeight={true}>

        <Box height="100%" overflow="auto">

          <Flex justifyContent="right">
            <Box>
              <FdaLink 
                fdaRecall={props.fdaRecall}
              />
            </Box>
          </Flex>

          <Flex flexDirection='column' mb={2}>
            <Box width={1} mb={1}>
              <label className='b'>Firm</label>
            </Box>
            <Box width={1}>
              {props.fdaRecall.recalling_firm || "N/A"}
            </Box>
          </Flex>

          <Flex flexDirection='column' mb={2}>
            <Box width={1} mb={1}>
              <label className='b'>Product</label>
            </Box>
            <Box width={1}>
              {props.fdaRecall.product || "N/A"}
            </Box>
          </Flex>

          <Flex flexDirection='column' mb={2}>
            <Box width={1} mb={1}>
              <label className='b'>Product Classification</label>
            </Box>
            <Box width={1}>
              ${props.fdaRecall.product_classification || "N/A"}
            </Box>
          </Flex>

          <Flex flexDirection='column' mb={2}>
            <Box width={1} mb={1}>
              <label className='b'>Recall Status</label>
            </Box>
            <Box width={1}>
              ${props.fdaRecall.recall_status || "N/A"}
            </Box>
          </Flex>

          <Flex flexDirection='column' mb={2}>
            <Box width={1} mb={1}>
              <label className='b'>PMA</label>
            </Box>
            <Box width={1}>
              {props.fdaRecall.pma_number || "N/A"}
            </Box>
          </Flex>

          <Flex flexDirection='column' mb={2}>
            <Box width={1} mb={1}>
              <label className='b'>510(k)</label>
            </Box>
            <Box width={1}>
              {props.fdaRecall.k_number || "N/A"}
            </Box>
          </Flex>

          <Flex flexDirection='column' mb={2}>
            <Box width={1} mb={1}>
              <label className='b'>Date Initiated by Firm</label>
            </Box>
            <Box width={1}>
              {props.fdaRecall.date_initiated_by_firm || "N/A"}
            </Box>
          </Flex>

          <Flex flexDirection='column' mb={2}>
            <Box width={1} mb={1}>
              <label className='b'>Date Posted</label>
            </Box>
            <Box width={1}>
              {props.fdaRecall.date_posted || "N/A"}
            </Box>
          </Flex>

          <Flex flexDirection='column' mb={2}>
            <Box width={1} mb={1}>
              <label className='b'>Reason for Recall</label>
            </Box>
            <Box width={1}>
              {props.fdaRecall.manufacturer_reason_for_recall || "N/A"}
            </Box>
          </Flex>

          <Flex flexDirection='column' mb={2}>
            <Box width={1} mb={1}>
              <label className='b'>Action</label>
            </Box>
            <Box width={1}>
              {props.fdaRecall.action || "N/A"}
            </Box>
          </Flex>
        </Box>

      </Paper>

    </Fragment>
  )
}
