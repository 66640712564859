import React, { propTypes } from "react"
import Select from "../Select"

export default function Sector(props) {


  return(
    <Select
      label='Sector'
      name='sector_id'
      includeBlank={props.includeBlank}
      value={props.sector_id}
      entities={props.sectors}
      handleChange={props.handleSectorChange}
      blankOption={props.blankOption}
    />
  )
}

Sector.defaultProps = {
  includeBlank: true
}
