import React, { Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Flex from "../Flex"
import Box from "../Box"
import FilterChip from '../filters/FilterChip';

export default function FilterChipsContainer(props) {
  // const selectedCollections = props.filterOptions.collections.filter(collection => props.filterValues.collection_ids.includes(collection.value) ? collection : null)
  //
  // console.log(props.filterValues)
  // console.log(props.filterOptions)
  // const selectedCompanies = props.filterOptions.companies.filter(company => props.filterValues.company_ids.includes(company.value) ? company : null)
  // console.log(selectedCompanies)
  //

  let targetSiteOptionValues = props.filterOptions.targetSites.map(targetSite => targetSite.value)
  const selectedTargetSites = props.chosen.targetSites.filter(targetSite => props.filterValues.target_site_ids.includes(targetSite.id) ? targetSite : null)

  let targetMarketOptionValues = props.filterOptions.targetMarkets.map(targetMarketOption => targetMarketOption.value)
  const selectedTargetMarkets = props.chosen.targetMarkets.filter(targetMarket => props.filterValues.target_market_ids.includes(targetMarket.id) ? targetMarket : null)

  let therapyTypeOptionValues = props.filterOptions.therapyTypes.map(therapyTypeOption => therapyTypeOption.value)
  const selectedTherapyTypes = props.chosen.therapyTypes.filter(therapyType => props.filterValues.therapy_type_ids.includes(therapyType.id) ? therapyType : null)

  const selectedYears = props.filterOptions.years.filter(year => props.filterValues.year_ids.includes(year.value) ? year : null)
  return(
    <Fragment>

      {selectedYears.map((activeFilter) =>
        <FilterChip
          key={activeFilter.value}
          name={activeFilter.name}
          value={activeFilter.value}
          type='year_ids'
          handleDelete={props.handleDelete}
        />
      )}

      {selectedTargetSites.map((activeFilter) =>
        <FilterChip
          key={activeFilter.value}
          className={targetSiteOptionValues.includes(activeFilter.id) ? '' : 'o-60'}
          name={activeFilter.name}
          value={activeFilter.value}
          type='target_site_ids'
          handleDelete={props.handleDelete}
        />
      )}

      {selectedTargetMarkets.map((activeFilter) =>
        <FilterChip
          key={`${activeFilter.value} - target_market_ids`}
          className={targetMarketOptionValues.includes(activeFilter.id) ? '' : 'o-60'}
          name={activeFilter.name}
          value={activeFilter.id}
          type='target_market_ids'
          handleDelete={props.handleDelete}
        />
      )}

      {selectedTherapyTypes.map((activeFilter) =>
        <FilterChip
          key={activeFilter.id}
          className={therapyTypeOptionValues.includes(activeFilter.id) ? '' : 'o-60'}
          name={activeFilter.name}
          value={activeFilter.id}
          type='therapy_type_ids'
          handleDelete={props.handleDelete}
        />
      )}
      {/* {selectedCompanies.map((activeFilter) =>
        <FilterChip
          key={activeFilter.value}
          activeFilter={activeFilter}
          type='company_ids'
          handleDelete={props.handleDelete}
        />
      )}
      {selectedTargetSites.map((activeFilter) =>
        <FilterChip
          key={activeFilter.value}
          activeFilter={activeFilter}
          type='target_site_ids'
          handleDelete={props.handleDelete}
        />
      )}
      {selectedTargetMarkets.map((activeFilter) =>
        <FilterChip
          key={`${activeFilter.value} - target_market_ids`}
          activeFilter={activeFilter}
          type='target_market_ids'
          handleDelete={props.handleDelete}
        />
      )}
      {selectedTherapyTypes.map((activeFilter) =>
        <FilterChip
          key={activeFilter.value}
          activeFilter={activeFilter}
          type='therapy_type_ids'
          handleDelete={props.handleDelete}
        />
      )}
      {selectedTechnologyGroups.map((activeFilter) =>
        <FilterChip
          key={activeFilter.value}
          activeFilter={activeFilter}
          type='technology_group_ids'
          handleDelete={props.handleDelete}
        />
      )} */}

    </Fragment>
  )
}
