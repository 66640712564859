import React, { useState } from "react"
import { Table, TableHead, TableRow, TableCell, TableBody, TableHeadSort } from "../../tables"
import Typography from "@material-ui/core/Typography"
import ResultRow from "./ResultRow"
import Checkbox from "../../Checkbox"
import Box from "../../Box"
import IndeterminateCheckBoxIcon from '@material-ui/icons/IndeterminateCheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';

export default function ResultsTable(props) {
  const [order, setOrder] = useState('desc')
  const [orderBy, setOrderBy] = useState('created_at')

  function desc(a, b, orderBy) {
    let bOrderKey = b[orderBy]
    let aOrderKey = a[orderBy]
    if (bOrderKey < aOrderKey) {
      return -1;
    }
    if (bOrderKey > aOrderKey) {
      return 1;
    }
    return 0;
  }

  function stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = cmp(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
  }

  function getSorting(order, orderBy) {
    return order === 'desc' ? (a, b) => this.desc(a, b, orderBy) : (a, b) => -this.desc(a, b, orderBy);
  }

  function handleRequestSort(event, property) {
    const isDesc = orderBy === property && order === 'desc';

    setOrder(isDesc ? 'asc' : 'desc')
    setOrderBy(property)
  };


  let headers = [{ label: 'Name', name: 'result_name' }, { label: 'Value', name: 'result_value' }, { label: 'Source', name: 'source' }, { label: 'Created By', name: 'created_by', align: 'right' }, { label: '', name: '', noSort: true, align: 'right' }, { label: '', name: '', noSort: true, align: 'right' }]

  // stableSort(props.trialResults, getSorting(order, orderBy))
  //   .map((trialResult) =>

  return(
    <Box overflow='auto' height="80%">

      <Table size='small' aria-label='results table' stickyHeader={true} id='results-table'>

        <TableHeadSort
          headers={headers}
          order={order}
          orderBy={orderBy}
          onRequestSort={handleRequestSort}
        />

        <TableBody>
          {props.trialResults.length > 0 ?
            props.trialResults.map((trialResult) =>
              <ResultRow
                key={trialResult.id}
                result={trialResult}
                handleDeleteClick={props.handleDeleteClick}
              />
            )
          :
            <TableRow>
              <TableCell colSpan={5}>
                <Typography>
                  No results found.
                </Typography>
              </TableCell>
            </TableRow>
          }
        </TableBody>
      </Table>
    </Box>
  )
}
