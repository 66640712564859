import React, { Fragment, useState } from "react"
import { List, ListItemLink, ListItemText } from "../../lists"
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ExpandLess from '../../ExpandLess';
import ExpandMore from '../../ExpandMore';
import Collapse from '@material-ui/core/Collapse';
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction"
import { FixedSizeList } from 'react-window';

export default function SearchResultsList(props) {
  const [open, setOpen] = useState(false)

  return(
    <List>
      {props.abstracts.length > 0 ?
        props.abstracts.map((abstract) =>
          <ListItemLink key={abstract.id} divider button href={`/sectors/Peripheral/abstracts/${abstract.id}`} target="_blank">
            <ListItemText primary={abstract.title} />
          </ListItemLink>
      ) : 'No abstracts found'}
    </List>
  )
}
