import React from 'react';
import Typography from "@material-ui/core/Typography"
import Button from "@material-ui/core/Button"

export default function ApplicantDisplay(props) {
  const { applicant, handleApplicantClick } = props;

  function handleClick(e) {
    e.preventDefault();
    handleApplicantClick(applicant);
  }

  function renderButton(applicant) {
    return (
      <Button onClick={handleClick} size='small'>
        <Typography variant="body2" color="textSecondary">
          {applicant}
        </Typography>
      </Button>
    )
  }

  function renderSpan(applicant) {
    return (
      <Typography variant="body2" color="textSecondary">
        {applicant}
      </Typography>
    )
  }

  return(
    <React.Fragment >
      {handleApplicantClick ? renderButton(applicant) : renderSpan(applicant)}
    </React.Fragment>
  )
}