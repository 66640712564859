import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import List from "../../lists/List"
import SectorListItem from "../../sectors/SectorListItem";
import Typography from '@material-ui/core/Typography';
import update from "immutability-helper"

class SectorForm extends Component {
  constructor(props) {
    super(props)
    this.handleCheckboxSelect = this.handleCheckboxSelect.bind(this)
    this.handleDeleteClick = this.handleDeleteClick.bind(this)
    this.state = { productSectors: this.props.productSectors, sectorValue: '' }
  }

  handleCheckboxSelect(sector, checked) {
    if (checked) {
      let productSector = this.state.productSectors.filter(productSector => productSector.sector_id == sector.id)[0]
      $.ajax({
        type: "PUT",
        url: `/admin/product_sectors/${productSector.id}`,
        data: {
          product_sector: {
            deleted: 1
          }
        },
        dataType: "json",
        success: (data) => {
          let productSectorIds = this.state.productSectors.map(productSector => productSector.sector_id)

          let index = productSectorIds.indexOf(sector.id)

          let productSectors = update(this.state.productSectors, {
            $splice: [[index, 1]]
          })

          this.setState({
            productSectors: productSectors
          })
        }
      })
    } else {
      $.post("/admin/product_sectors", { product_sector: { product_id: this.props.product.id, sector_id: sector.id, created_by: this.props.currentUser.id }}, (data) => {
        this.setState({
          productSectors: data.product_sectors,
          sectorValue: ''
        })
      }, 'json')
    }
  }

  handleDeleteClick(productSector) {
    $.ajax({
      type: 'PUT',
      url: `/admin/product_sectors/${productSector.id}`,
      data: {
        product_sector: {
          deleted: 1
        }
      },
      dataType: 'json',
      success: (data) => {
        let productSectorIds = this.state.productSectors.map((productSector) => productSector.id)
        let index = productSectorIds.indexOf(data.product_sector.id)

        let productSectors = update(this.state.productSectors, { $splice: [[index, 1]] } )
        this.setState({
          productSectors: productSectors
        })
      }
    })
  }

  render() {
    return(
      <Fragment>

        <Typography variant="h6" component="h6">
          Sectors
        </Typography>

        <List>
          {this.props.sectors.map((sector) =>
            <SectorListItem
              key={sector.id}
              sector={sector}
              checked={this.state.productSectors.filter((productSector) => productSector.sector_id == sector.id)[0] ? true : false}
              handleCheckboxSelect={this.handleCheckboxSelect}
            />
          )}
        </List>

      </Fragment>
    )
  }
}

export default SectorForm
