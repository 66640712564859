import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import { Table, TableBody, TableHeadSort } from "../tables"
import DataCompareRow from "../trials/NewDataCompareRow"
import { RaisedButton } from "../buttons"
import Box from "../Box"

export default class NewDataView extends Component {
  constructor(props) {
    super(props)
    this.handleRequestSort = this.handleRequestSort.bind(this)
    this.desc = this.desc.bind(this)
    this.stableSort = this.stableSort.bind(this)
    this.getSorting = this.getSorting.bind(this)
    this.state = { order: 'desc', orderBy: 'completion_date' }
  }

  desc(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = cmp(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
  }

  getSorting(order, orderBy) {
    return order === 'desc' ? (a, b) => this.desc(a, b, orderBy) : (a, b) => -this.desc(a, b, orderBy);
  }

  handleRequestSort(event, property) {
    const isDesc = this.state.orderBy === property && this.state.order === 'desc';

    this.setState({
      order: isDesc ? 'asc' : 'desc',
      orderBy: property
    })
  };

  render() {
    return(
      <Box overflow='auto' height="80%">

        <Table aria-label='compare table' stickyHeader={true}>

          <TableHeadSort
            headers={[{label: <RaisedButton onClick={this.props.handleAddEntityClick}>Add Trial</RaisedButton>, name: '', noSort: true }, { name: 'first_received_date', label: 'First received date' }, { name: 'start_date', label: 'Start date' }, { name: 'recruiting_date', label: 'Recruiting' }, { name: 'active_not_recruiting_date', label: 'Active, Not Recruiting' }, { name: 'primary_completion_date', label: 'Primary completion date' }, { name: 'completion_date', label: 'Completion date' }, { name: 'duration', label: 'Duration' }, { name: 'target_enrollment', label: 'Target Enrollment' }, { name: 'current_enrollment', label: 'Current Enrollment' }, { name: 'status', label: 'Current Status' }]}
            borderRight={true}
            order={this.state.order}
            orderBy={this.state.orderBy}
            onRequestSort={this.handleRequestSort}
          />

          <TableBody>
            {this.props.entities.length > 0 ?
              this.stableSort(this.props.entities, this.getSorting(this.state.order, this.state.orderBy)).map((entity) =>
                <DataCompareRow
                  key={entity.id}
                  entity={entity}
                  sector={this.props.sector}
                  activeEntity={this.props.activeEntity}
                  recruiting={this.props.recruiting}
                  handleEntityRemovalClick={this.props.handleEntityRemovalClick}
                />
              )
            : ''}
          </TableBody>

        </Table>

      </Box>
    )
  }
}
