import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Form from "./Form"
import KClearanceTable from "./Table"
import { withSnackbar } from "../../../SnackbarContainer"
import update from "immutability-helper"
import Box from "../../../Box"

class FormContainer extends Component {
  constructor(props) {
    super(props)
    this.handleKClearanceSave = this.handleKClearanceSave.bind(this)
    this.handleDeleteClick = this.handleDeleteClick.bind(this)
    this.handleSectorChange = this.handleSectorChange.bind(this)
    this.handleKNumberChange = this.handleKNumberChange.bind(this)
    this.handleSectorDelete = this.handleSectorDelete.bind(this)
    this.state = { kNumber: '', sector_id: null, productKClearances: this.props.productKClearances }
  }

  handleKNumberChange(e) {
    let value = e.target.value

    this.setState({
      kNumber: value
    })
  }

  handleSectorChange(name, value) {
    this.setState({
      [name]: value
    })
  }

  handleSectorDelete(kClearanceSectorId) {
    $.ajax({
      type: "PUT",
      url: `/admin/product_fda_k_clearance_sectors/${kClearanceSectorId}`,
      data: {
        product_id: this.props.product.id, product_fda_k_clearance_sector: {
          deleted: true, deleted_by: this.props.user.id
        }
      },
      dataType: 'json',
      success: (data) => {
        this.setState({
          productKClearances: data.product_k_clearances
        })
      },
      error: (data) => {
        this.props.snackbarShowMessage("510(k) Clearance could not be removed. Please try again.", "error")
      }
    })
  }

  handleDeleteClick(productKClearance) {
    $.ajax({
      type: 'PUT',
      url: `/admin/product_fda_k_clearances/${productKClearance.product_k_clearance_id}`,
      data: {
        product_id: this.props.product.id, product_fda_k_clearance: {
          deleted: true
        }
      },
      dataType: 'json',
      success: (data) => {

        this.setState({
          productKClearances: data.product_k_clearances
        })

        this.props.snackbarShowMessage("KClearance removed")
      },
      error: (data) => {
        this.props.snackbarShowMessage("KClearance could not be removed. Please try again.", "error")
      }
    })
  }

  handleKClearanceSave(e) {
    let params = { product_id: this.props.product.id}

    $.ajax({
      type: 'POST',
      url: `/admin/product_fda_k_clearances`,
      data: {
        product_id: this.props.product.id, k_number: this.state.kNumber, sector_id: this.state.sector_id
      },
      dataType: 'json',
      success: (data) => {
        if (data.no_clearance_message) {
          this.props.snackbarShowMessage(data.no_clearance_message)
        } else {
          let fdaKClearance = update(data.fda_k_clearance, {
            $merge: { sector_id: data.product_k_clearance_sector ? data.product_k_clearance_sector.sector_id : null }
          })

          console.log(fdaKClearance)

          let productKClearances = update(this.state.productKClearances, {
            $push: [fdaKClearance]
          })

          this.setState({
            productKClearances: productKClearances,
            KClearance: null,
            supplement_number: null,
            sector_id: null
          })

          this.props.snackbarShowMessage("510(k) Clearance added")
        }
      },
      error: (data) => {
        this.props.snackbarShowMessage("510(k) Clearance could not be added. Please try again.", "error")
      }
    })
  }

  render() {
    return(
      <Fragment>
        <Box height="100%">
          <Form
            kNumber={this.state.kNumber}
            sectors={this.props.sectors}
            handleKNumberChange={this.handleKNumberChange}
            handleKClearanceSave={this.handleKClearanceSave}
            handleSectorChange={this.handleSectorChange}
          />

          <KClearanceTable
            productKClearances={this.state.productKClearances}
            sectors={this.props.sectors}
            handleDeleteClick={this.handleDeleteClick}
            handleSectorDelete={this.handleSectorDelete}
          />
        </Box>

      </Fragment>
    )
  }
}


export default withSnackbar(FormContainer)
