import React, { Component, Fragment, useState } from "react"
import { makeStyles } from '@material-ui/core/styles';
import { List, ListItem, ListItemText } from "../lists"
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import DeleteButton from "../admin/DeleteButton"
import ExpandLess from '../ExpandLess';
import ExpandMore from '../ExpandMore';
import Collapse from "@material-ui/core/Collapse"

export default function CollectionProductListItem(props) {

  return(
    <Fragment>
      <ListItem divider key={props.userCollectionProduct.product.id}>

        <ListItemText
          primary={props.userCollectionProduct.product.short_name}
        />

        {props.userCollection.user_id == props.user.id ? 
          <ListItemSecondaryAction>
            <DeleteButton onClick={() => props.handleCollectionDeleteClick(props.userCollectionProduct)} />
          </ListItemSecondaryAction>
        : ""}
      </ListItem>
    </Fragment>
  )
}
