import React from "react"
import Box from "../Box"

export default function Header(props) {

  return(
    <Box mb={1}>
      <Box>
        <span
          dangerouslySetInnerHTML={{__html: props.alert.header_text }}
        />
      </Box>
      {props.alert.header_secondary ?
        <Box>
          <Box mt={1}>
            <span
              dangerouslySetInnerHTML={{__html: props.alert.header_secondary }}
            />
          </Box>
        </Box>
      : ""}
    </Box>
  )
}
