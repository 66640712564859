import React from "react"
import ResultItemContainer from "./ResultItemContainer"
import Typography from "@material-ui/core/Typography"
import LinkToAbstract from "../abstracts/LinkToAbstract"

export default function NewsItem(props) {

  let url = props.admin ? `/admin/news/${props.result.id}/associator` : props.result.url

  function renderSearchDetails(result) {
    return(renderMeetingName(result.publication_name))
  }

  function renderMeetingName(publicationName) {
    return (
      <Typography variant="body2" color="textSecondary">
        {publicationName}
      </Typography>
    );
  }

  return(
    <ResultItemContainer 
      searchItemType={props.result.record_type}
      searchTitle={props.result.title}
      date={props.result.date}
      searchDescription={props.result.description || props.result.text}
      url={url}
      target="_blank"
      searchDetails={renderSearchDetails(props.result)}
    />
  )
}