import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Paper from "../../Paper"
import Flex from "../../Flex"
import Box from "../../Box";
import TextInput from "../../TextInput"
import Select from "../../Select"
import Checkbox from "../../Checkbox"
import { RaisedButton } from "../../buttons"
import update from "immutability-helper"
import Snackbar from "../../Snackbar"
import ButtonLayout from "../ButtonLayout"

export default class NewContainer extends Component {
  constructor(props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
    this.handleRegionChange = this.handleRegionChange.bind(this)
    this.handleMetricChange = this.handleMetricChange.bind(this)
    this.handleSectorChange = this.handleSectorChange.bind(this)
    this.handleSaveClick = this.handleSaveClick.bind(this)
    this.state = { characteristicGroup: this.props.characteristic_group, characteristicGroupSector: this.props.characteristic_group_sector, characteristicGroupMetric: this.props.characteristic_group_metric, regional: false, snackbarText: '', showSnackbar: false }
  }
  
  componentDidMount() {
    const characteristicGroup = update(this.state.characteristicGroup, { 
      created_by: { $set: this.props.current_user.id }
    })
    
    // const characteristicGroupSector = update(this.state.characteristicGroupSector, {
    //   created_by: { $set: this.props.current_user.id },
    //   sector_id: { $set: 1 }
    // })
    // 
    // const characteristicGroupMetric = update(this.state.characteristicGroupMetric, {
    //   created_by: { $set: this.props.current_user.id }
    // })
    
    this.setState({
      characteristicGroup: characteristicGroup
    })
  }
  
  handleSectorChange(name, value) {
    const characteristicGroupSector = update(this.state.characteristicGroupSector, {
      [name]: { $set: value }
    })
    
    this.setState({
      characteristicGroupSector: characteristicGroupSector
    })
  }
  
  handleMetricChange(name, value) {
    const characteristicGroupMetric = update(this.state.characteristicGroupMetric, {
      [name]: { $set: value }
    })
    
    this.setState({
      characteristicGroupMetric: characteristicGroupMetric
    })
  }
  
  handleRegionChange(e) {
    const name = e.target.name
    const checked = e.target.checked
    
    this.setState({
      regional: checked
    })
  }
  
  handleChange(e) {
    const name = e.target.name
    const value = e.target.value
    
    const characteristicGroup = update(this.state.characteristicGroup, { 
      [name]: { $set: value }
    })
    
    this.setState({
      characteristicGroup: characteristicGroup
    })
  }
  
  handleSaveClick(e) {
    const url = this.props.redirect_url ? `/admin/characteristic_groups?r=${this.props.redirect_url}` : "/admin/characteristic_groups"
    
    const characteristicGroup = update(this.state.characteristicGroup, {
      $merge: { 
        characteristic_group_sectors_attributes: { 
          0: this.state.characteristicGroupSector
        },
        characteristic_group_metrics_attributes: {
          0: this.state.characteristicGroupMetric
        },
        characteristic_group_region_attributes: { regional: this.state.regional, created_by: this.props.current_user.id }
      }
    })
    
    $.post(url, { characteristic_group: characteristicGroup }, (data) => {
      window.location.href = url
    }, "json").fail((data) => {
      this.setState({
        showSnackbar: true,
        snackbarText: `Characteristic group could not be saved. ${data.responseText}`
      })
    }).always((data) => {
      setTimeout(() => {
        this.setState({
          showSnackbar: false,
          snackbarText: ''
        })
      }, 4000)
    })
  }
  
  render() {
    return(
      <Fragment>
        
        <Paper>
          
          <Flex mb={3} alignItems='center'>
            <Box mr={3}>
              <Select 
                name='sector_id'
                value={this.state.characteristicGroupSector.sector_id}
                label='Sectors'
                entities={this.props.sectors}
                handleChange={this.handleSectorChange}
              />
            </Box>
            
            <Box mr={3}>
              <Select 
                name='metric_id'
                value={this.state.characteristicGroupMetric.metric_id}
                label='Metrics'
                entities={this.props.metrics}
                handleChange={this.handleMetricChange}
              />
            </Box>
            
            <Box mr={3}>
              <Checkbox 
                name='regional'
                label='Regional'
                value={this.state.regional}
                onChange={this.handleRegionChange}
              />
            </Box>
          </Flex>
          
          <TextInput
            id='outlined-name' 
            name='name' 
            label='Name' 
            value={this.state.characteristicGroup.name} 
            onChange={this.handleChange} 
            variant='outlined'
            margin='normal'
            fullWidth={true}
          />
          
          <ButtonLayout>
            <RaisedButton handleClick={this.handleSaveClick}>
              Save Characteristic Group
            </RaisedButton>
          </ButtonLayout>
          
        </Paper>
        
        <Snackbar 
          open={this.state.showSnackbar}
          handleClose={this.handleClose}
          message={this.state.snackbarText} 
        /> 
        
      </Fragment>
    )
  }
}
