import React from "react"
import MuiTabs from '@material-ui/core/Tabs';
import { makeStyles } from '@material-ui/core/styles';
import clsx from "clsx"

const useStyles = makeStyles(theme => ({
  small: {
    minHeight: '40px',
    height: '40px'
  }
}));



export default function Tabs(props) {
  const { small, textColor = "secondary", indicatorColor = "secondary", children, ...rest } = props
  
  const classes = useStyles(props);
  
  return (
    <MuiTabs textColor={textColor} indicatorColor={indicatorColor} className={clsx('', { [classes.small]: small })} {...rest}>
      {children}
    </MuiTabs>
  )
}
