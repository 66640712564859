import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Paper from "../../Paper"
import Flex from "../../Flex"
import Box from "../../Box"
import EntityCard from "../EntityCard"
import Highlighter from "react-highlight-words";
import Link from "../../Link"
import Typography from "@material-ui/core/Typography"
import BfarmLink from "./BfarmLink"

export default function RecallCard(props) {

  return(
    <Fragment>

      <Paper fullHeight={true}>

        <Box height="100%" overflow="auto">

          <Flex justifyContent="right">
            <Box>
              <BfarmLink 
                url={props.bfarmRecall.url}
              />
            </Box>
          </Flex>

          <Flex flexDirection='column' mb={2}>
            <Box width={1} mb={1}>
              <label className='b'>Title</label>
            </Box>
            <Box width={1}>
              {props.bfarmRecall.title}
            </Box>
          </Flex>

          <Flex flexDirection='column' mb={2}>
            <Box width={1} mb={1}>
              <label className='b'>Date</label>
            </Box>
            <Box width={1}>
              {props.bfarmRecall.date || "N/A"}
            </Box>
          </Flex>

          <Flex flexDirection='column' mb={2}>
            <Box width={1} mb={1}>
              <label className='b'>Description</label>
            </Box>
            <Box width={1}>
              {props.bfarmRecall.description}
            </Box>
          </Flex>

          <Flex flexDirection='column' mb={2}>
            <Box width={1} mb={1}>
              <label className='b'>Text</label>
            </Box>
            <Box width={1}>
              ${props.text || "N/A"}
            </Box>
          </Flex>
        </Box>

      </Paper>

    </Fragment>
  )
}
