import React, { Component, Fragment } from "react"
import Box from "../Box"
import { Table, TableRow, TableCell, TableBody } from "../tables"
import ListRecord from "./ListRecord"
import TableHeadSort from "../tables/TableHeadSort"
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TablePaginationActions from "../tables/TablePaginationActions"
import LinearProgress from '@material-ui/core/LinearProgress';

export default class AbstractsTable extends Component {
  constructor(props) {
    super(props)
  }


  render() {
    return(
      <Fragment>

        <Box overflow='auto' height='80%'>
          <Table size='small' aria-label='abstracts table' stickyHeader={true}>

            <TableHeadSort
              headers={[{ name: 'title', label: 'Title' }, { name: 'presenter', label: 'Presenter' }, { name: 'meeting_name', label: 'Meeting' }, { name: 'date', label: 'Date' }, { name: 'url', label: 'URL' }]}
              order={this.props.order}
              orderBy={this.props.orderBy}
              onRequestSort={this.props.handleRequestSort}
            />

            <TableBody>
              <React.Fragment>
                {this.props.loading ?
                  <TableRow hover={false}>
                    <TableCell noBorder colSpan={5}>
                      <LinearProgress />
                    </TableCell>
                  </TableRow>
                : ""}
                {this.props.abstracts.map((abstract) =>
                  <ListRecord
                    key={abstract.id}
                    abstract={abstract}
                    sector={this.props.sector}
                  />
                )}
              </React.Fragment>
            </TableBody>

            <TableFooter>
              <TablePagination
                colSpan={6}
                count={this.props.abstractsCount}
                rowsPerPage={100}
                page={this.props.page}
                SelectProps={{
                  inputProps: { 'aria-label': 'rows per page' },
                  native: true,
                }}
                onChangePage={this.props.handleChangePage}
                onChangeRowsPerPage={this.props.handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableFooter>

          </Table>
        </Box>

      </Fragment>
    )
  }
}
