import React, { Component } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Highcharts from "highcharts"
import mapFactory from 'highcharts/modules/map';
import worldMap from '../../../assets/javascripts/highcharts/world.js'
mapFactory(Highcharts);

export default class Map extends Component {
  constructor(props) {
    super(props)
  }
  
  componentDidMount() {
    Highcharts.mapChart("map-container", {
      credits:{
        enabled: false
      },
      title: {
        text: "Sites By Country"
      },
      legend: {
        enabled: false
      },
      colorAxis: {
        min: 0,
        minColor: '#005645',
        maxColor: '#005645'
      },
      series: [{
        data: this.props.data,
        mapData: worldMap,
        joinBy: ['iso-a2', 'code'],
        dataLabels: {
          enabled: true,
          color: '#FFFFFF',
          format: '{point.value}'
        },
        name: 'Sites',
        states: {
          hover: {
            color: '#a4edba'
          }
        }
      }]
    })
  }
  
  render () {
    return(
      <div id='map-container'></div>
    );
  }
}

// $.each @props.data, (i) ->
//   @drilldown = @drilldown
//   @value = @value
// 
// Highcharts.mapChart "map-container",
//   chart:
//     events:
//       drilldown: (e) =>
//         @handleDrilldown e
//       
//       drillup: (e) =>
//         chart = e.target
//         chart.setTitle null, text: "Sites"
//         @props.handleDrilldown null
//         
//   title: 
//     text: "Sites"
//   # mapNavigation: 
//   #   enabled: true
//   legend:
//     layout: 'vertical'
//     align: 'right'
//     verticalAlign: 'middle'
//   colorAxis:
//     min: 0
//     minColor: '#E6E7E8'
//     maxColor: '#005645'
//   series:[
//     data: @props.data
//     mapData: Highcharts.maps['custom/world']
//     joinBy: ['iso-a2', 'code']
//     name: 'Sites'
//     states: 
//       hover:
//         color: '#a4edba']
//   drilldown:
//     activeDataLabelStyle:
//         color: '#FFFFFF'
//         textDecoration: 'none'
//         textOutline: '1px #000000'
//     drillUpButton: 
//         relativeTo: 'spacingBox'
//         position:
//             x: 0,
//             y: 30
