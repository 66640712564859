import React, { Fragment } from "react"
import { ListItem, ListItemText } from "../../lists"
import Checkbox from "../../Checkbox"

export default function SectorCheckbox(props) {

  return(
    <Fragment>

      <ListItem button key={props.sector.id} role={undefined} onClick={() => { props.handleCheckboxSelect(props.sector) }} selected={props.predicted ? true : false}>
        <Checkbox
          disabled={props.disabled}
          checked={props.checked}
        />

        <ListItemText
          primary={props.sector.abbr}
          secondary={props.predicted ? (props.predicted.normalized_score || props.predicted.score || "") : ""}
        />
      </ListItem>

    </Fragment>
  )
}
