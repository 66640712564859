import React from "react"
import Box from "../Box"
import Typography from "@material-ui/core/Typography"
import { ListItemLink, ListItemText } from "../lists"

export default function DefaultResultItem(props) {

  function setUrl(result) {
    if (result.search_url) {
      return(result.search_url)
    } else if (result.record_type == 'Presentation' || result.record_type == 'Poster') {
      return(`/sectors/${props.sector.abbr}/abstracts/${result.record_id}`)
    } else if (result.record_type == "NIH Grant") {
      return(`/sectors/${props.sector.abbr}/nih_project_grants/${result.record_id}`)
    } else if (result.record_type == "Patent") {
      return(`/sectors/${props.sector.abbr}/patents/${result.record_id}`)
    } else if (result.device_approval) {
      return(`/sectors/${props.sector.abbr}/device_approvals/${result.record_id}`)
    } else {
      return(`/sectors/${props.sector.abbr}/${result.record_type.toLowerCase()}s/${result.id}`)
    }
  }

  return(
    <ListItemLink key={props.result.record_id} href={setUrl(props.result)} alignItems='flex-start' divider={true} target={props.result.search_url ? "_blank" : ""}>
      <ListItemText>
        <Typography variant="caption" color="textSecondary">
          {props.result.record_type}
        </Typography>

        <Box pb={1}>
          <Typography variant="body1">
            {props.result.search_title}
          </Typography>
        </Box>


        <Box maxWidth="40em">
          <Typography variant="body2" color="textSecondary">
            <div
              dangerouslySetInnerHTML={{__html: props.result.date ? `${humanDateFormat(props.result.date)} - ${props.result.search_description}` : props.result.search_description }}
            />
          </Typography>
        </Box>

        {props.result.search_details ? 
          <Box mt={1}>
            <Typography variant="body2" color="textPrimary">
              <div
                dangerouslySetInnerHTML={{__html: props.result.search_details }}
              />
            </Typography>
          </Box> 
        : ""}
      </ListItemText>
    </ListItemLink>
  )
}