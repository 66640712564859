import React, { Fragment } from "react"
import Select from "../Select"
import Flex from "../Flex"
import Box from "../Box"
import Grid from '@material-ui/core/Grid';


export default function AdverseEventFilters(props) {
  let xsSize = 4
  let lgSize = 2
  return(
    <Grid container spacing={1} alignItems='stretch'>
      <Grid item xs={xsSize} lg={lgSize}>
        <Box>
          <Select
            label='Collections'
            name='collection_id'
            includeBlank={true}
            value={props.collectionId}
            entities={props.collections}
            handleChange={props.handleFilterChange}
          />
        </Box>
      </Grid>

      {props.sector.monitor_product ?
        ''
      :
        <Fragment>
          <Grid item xs={xsSize} lg={lgSize}>
            <Box>
              <Select
                label='Therapy'
                name='therapy_type_id'
                value={props.therapyTypeId}
                entities={props.therapyTypes}
                handleChange={props.handleFilterChange}
                includeBlank={true}
              />
            </Box>
          </Grid>

          {props.sector.market_id != 3 ?
            <Grid item xs={xsSize} lg={lgSize}>
              <Box>
                <Select
                  label='Target Sites'
                  name='target_site_id'
                  value={props.targetSiteId}
                  entities={props.targetSites}
                  handleChange={props.handleFilterChange}
                  includeBlank={true}
                />
              </Box>
            </Grid>
          : ""}
        </Fragment>
      }

      <Grid item xs={xsSize} lg={lgSize}>
        <Box>
          <Select
            name='device_problem_code'
            label='Device Problem'
            includeBlank={true}
            entities={props.deviceProblems}
            value={props.deviceProblemCode}
            handleChange={props.handleFilterChange}
          />
        </Box>
      </Grid>

      {/* <Grid item xs={4} lg={2}>
        <Box>
          <Select
            name='report_type'
            label='Report Type'
            includeBlank={true}
            entities={props.reportTypes}
            value={props.reportType}
            handleChange={props.handleFilterChange}
          />
        </Box>
      </Grid> */}

      <Grid item xs={xsSize} lg={lgSize}>
        <Box>
          <Select
            name='event_type'
            label='Event Type'
            includeBlank={true}
            entities={props.eventTypes}
            value={props.eventType}
            handleChange={props.handleFilterChange}
          />
        </Box>
      </Grid>


      <Grid item xs={xsSize} lg={lgSize}>
        <Box>
          <Select
            name='reporter_country_code'
            label='Country Code'
            includeBlank={true}
            entities={props.reporterCountryCodes}
            value={props.reporterCountryCode}
            handleChange={props.handleFilterChange}
          />
        </Box>
      </Grid>

      {/* <Grid item xs={xsSize} lg={lgSize}>
        <Box>
          <form onSubmit={props.handleSearchClick}>
            <TextInput
              name='query'
              placeholder='Search'
              size='small'
              value={props.query ? props.query : ''}
              onChange={props.handleChange}
              className='w-100'
              InputProps={{
                startAdornment: <InputAdornment position="start"><SearchIcon /></InputAdornment>,
                endAdornment: <InputAdornment position="end"><IconButton onClick={props.handleClearSearchClick} size='small'><ClearIcon fontSize='small' /></IconButton></InputAdornment>
              }}
            />
          </form>
        </Box>
      </Grid> */}
    </Grid>
  )
}
