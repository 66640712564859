import React, { Component } from "react"
import { Card, CardSection, CardSectionHeader } from "../cards"
import Box from "../Box"
import Flex from "../Flex"
import Link from "../Link"
import { AbstractsCTA } from "../buttons"
import Typography from "@material-ui/core/Typography"

export default class AbstractCard extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <Card background='bg-abstracts-card'>
        <CardSection noBorder>
          {this.props.abstract.title}

          <h4>Details</h4>

          {this.props.meetingName ? <Box mb={2}>
            <div className='b'>Meeting</div>
            <div>{this.props.meetingName}</div>
          </Box> : ''}

          {this.props.abstract.presenter && this.props.person ? <Box mb={2}>
            <div className='b'>Presenter</div>
            <Box>
              <Link href={`/sectors/${this.props.sector.abbr}/people/${this.props.person.id}`}>
                {this.props.person.name.text}
              </Link>
            </Box>
          </Box> : ''}

          {this.props.abstract.date ? <Box mb={2}>
            <div className='b'>Date</div>
            <div>{humanDateFormat(this.props.abstract.date)}</div>
          </Box> : ''}

          {this.props.abstract.abstract_type ? <Box mb={2}>
            <div className='b'>Type</div>
            <div>{this.props.abstract.abstract_type.name}</div>
          </Box> : ''}

          {this.props.abstract.session_title ? <Box mb={2}>
            <div className='b'>Session</div>
            <div>{this.props.abstract.session_title}</div>
          </Box> : ''}

          {this.props.abstract.url ?
            <Flex flexDirection='column' textAlign='center' mt={5}>

              <Box mb={2}>
                <AbstractsCTA href={`/sectors/${this.props.sector.abbr}/abstracts/${this.props.abstract.id}/view_source`} target="_blank">
                  View Presentation
                </AbstractsCTA>
              </Box>

              <Box>
                <Typography variant="caption" component="span">
                  <Box>
                    Access slides or video content on the conference site
                  </Box>
                  <Box>
                    (separate login may be required)
                  </Box>
                </Typography>
              </Box>
            </Flex>
          : ''}


        </CardSection>
      </Card>
    )
  }
}
