import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Link from "../Link"
import { Table, TableBody, TableRow, TableCell } from "../tables"
import IconButton from "../IconButton"
import FavoriteIcon from "@material-ui/icons/Favorite"
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder"
import TargetMarketDisplay from "./TargetMarketDisplay"
import TherapyTypeDisplay from "./TherapyTypeDisplay"
import Typography from "@material-ui/core/Typography"
import Flex from "../Flex"
import Box from "../Box"
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Checkbox from '@material-ui/core/Checkbox';
import MuiIconButton from '@material-ui/core/IconButton';
import ExpandMore from "@material-ui/icons/ExpandMore"
import ExpandLess from "@material-ui/icons/ExpandLess"
import Collapse from "@material-ui/core/Collapse"

export default class ProductRow extends Component {
  constructor(props) {
    super(props)
    this.handleProductClick = this.handleProductClick.bind(this)
    this.handleProductCheckboxClick = this.handleProductCheckboxClick.bind(this)
    this.handleComponentProductCheckboxClick = this.handleComponentProductCheckboxClick.bind(this)
    this.handleFavoriteClick = this.handleFavoriteClick.bind(this)
    this.showIcons = this.showIcons.bind(this)
    this.hideIcons = this.hideIcons.bind(this)
    this.removeProductHover = this.removeProductHover.bind(this)
    this.handleExpandClick = this.handleExpandClick.bind(this)
    this.state = { showIcons: false, hoverProduct: false, showChildProducts: false, selected: false }
  }

  showIcons(e) {
    this.setState({
      showIcons: true,
      hoverProduct: true
    })
  }

  hideIcons(e) {
    this.setState({
      showIcons: false,
      hoverProduct: false
    })
  }

  removeProductHover(e) {
    this.setState({
      hoverProduct: false
    })
  }

  handleProductClick(e) {
    e.preventDefault()
    window.opener.add_product(this.props.product.id)
  }

  handleComponentProductCheckboxClick(e) {
    this.props.handleProductCheckboxClick(this.props.product.id)
  }

  handleProductCheckboxClick(e) {
    this.props.handleProductCheckboxClick(this.props.product.id)
  }

  handleFavoriteClick(e) {
    this.props.handleFavoriteClick(this.props.product)
  }

  handleExpandClick(e) {
    this.setState({
      showChildProducts: !this.state.showChildProducts,
      selected: !this.state.selected,
      showIcons: !this.state.selected
    })
  }

  render () {
     // component={Link} href={`/sectors/${this.props.sector.abbr}/products/${this.props.product.id}`}
     const selected = this.props.checkedProducts.includes(this.props.product.id)
     // || this.state.selected
     const productFavorited = this.props.product.user_product_id
     // this.props.userProducts.filter(userProduct => userProduct.product_id == this.props.product.id)
    return(
      <Fragment>
        <TableRow onMouseEnter={this.state.selected ? '' : this.showIcons} onMouseLeave={this.state.selected ? '' : this.hideIcons} selected={selected || this.state.showChildProducts}>

          <TableCell padding='checkbox' className={selected ? '' : 'o-50'}>
            <MuiIconButton onClick={this.handleProductCheckboxClick} edge="start">
              {selected ? <CheckBoxIcon color='primary' style={{height: '22px', width: '22px'}} /> : <CheckBoxOutlineBlankIcon style={{height: '22px', width: '22px'}} />}
            </MuiIconButton>
            {/* <Checkbox
              onChange={this.handleProductCheckboxClick}
              checked={selected}
            /> */}
          </TableCell>

          <TableCell>
            <Flex alignItems='center'>
              <Box mr={3}>
                {this.props.choose ?
                  <Box className='pointer' onClick={this.handleProductCheckboxClick}>
                    {this.props.product.short_name}
                  </Box>
                :
                  <Link href={`/sectors/${this.props.sector.abbr}/products/${this.props.product.id}`}>
                    <Box>
                      {this.props.product.short_name}
                    </Box>
                  </Link>
                }
              </Box>

              <Box>
                {this.state.showIcons ?
                  this.props.childProducts && this.props.childProducts.length > 0 ?
                    <IconButton onClick={this.handleExpandClick} size='small' tooltip={this.state.showChildProducts ? 'Minimize' : 'Expand'} style={{height: "20px", width: "20px"}}>
                      {this.state.showChildProducts ? <ExpandLess style={{height: "20px", width: "22px"}} /> : <ExpandMore style={{height: "20px", width: "20px"}} />}
                    </IconButton>
                  : ''
                : '' }
              </Box>
            </Flex>
          </TableCell>

          <TableCell>
            <Link href={`/sectors/${this.props.sector.abbr}/companies/${this.props.product.company_id}`}>
              <Typography variant="body2">
                {this.props.product.company_name}
              </Typography>
            </Link>
          </TableCell>

          {this.props.user.user_company_id == 1 ? 
            <TableCell>
              {this.props.product.status_view_name}
            </TableCell>
          : ""}

          <TableCell>
            <Typography variant="body2">
              {this.props.product.last_updated_at ? humanDateFormat(this.props.product.last_updated_at) : humanDateFormat(this.props.product.updated_at)}
            </Typography>
          </TableCell>

          {this.props.chosenAttributes.includes('Target markets') ? <TableCell>
            <TargetMarketDisplay targetMarkets={this.props.product.target_markets} />
          </TableCell> : '' }
          {this.props.chosenAttributes.includes('Therapy types') ?
            <TableCell>
              <TherapyTypeDisplay therapyTypes={this.props.product.therapy_types} />
            </TableCell>
          : ''}

          <TableCell align='right' className={this.state.showIcons ? 'dn' : 'w-10'}>
            {this.props.product.n_trials}
          </TableCell>

          <TableCell padding='checkbox' className={this.state.showIcons ? 'w-10' : 'dn'} align='right'>

            <Flex alignItems='center' justifyContent='end'>

              <Box>
                <IconButton onClick={this.handleFavoriteClick} tooltip='Favorite' size='small'>
                  {productFavorited ? <FavoriteIcon fontSize='small' color='primary' /> : <FavoriteBorderIcon fontSize='small' />}
                </IconButton>
              </Box>
            </Flex>


          </TableCell>
        </TableRow>

        {this.state.showChildProducts ?
          <Fragment>
            {this.props.childProducts ? this.props.childProducts.map((product) =>
              <TableRow key={product.id} selected={selected || this.state.showChildProducts}>
                {/* <TableCell padding='checkbox' /> */}
                <TableCell padding='checkbox' className={this.props.checkedProducts.includes(product.id) || selected ? '' : 'o-50'}>
                  <MuiIconButton onClick={() => this.props.handleProductCheckboxClick(product.id)} edge="start">
                    {this.props.checkedProducts.includes(product.id) || selected ? <CheckBoxIcon color='primary' style={{height: '22px', width: '22px'}} /> : <CheckBoxOutlineBlankIcon style={{height: '22px', width: '22px'}} />}
                  </MuiIconButton>
                </TableCell>
                <TableCell colSpan={2}>
                  <Link href={`/sectors/${this.props.sector.abbr}/products/${product.id}`}>
                    <Box>
                      {product.short_name}
                    </Box>
                  </Link>
                </TableCell>

                <TableCell>
                  <Typography variant="body2">
                    {humanDateFormat(product.updated_at)}
                  </Typography>
                </TableCell>

                <TableCell padding='checkbox' className={this.state.showIcons ? 'w-10' : 'dn'} align='right'>

                  <Flex alignItems='center' justifyContent='end'>

                    <Box>
                      <IconButton onClick={this.handleFavoriteClick} tooltip='Favorite' size='small'>
                        {productFavorited ? <FavoriteIcon fontSize='small' color='primary' /> : <FavoriteBorderIcon fontSize='small' />}
                      </IconButton>
                    </Box>
                  </Flex>


                </TableCell>
              </TableRow>
            ) : ''}
          </Fragment>
        : ''}
      </Fragment>
    )
  }
}

ProductRow.propTypes = {
  product: PropTypes.object
};
