import React from "react";
import Link from "../Link";

export default function DoiLink(props) {
  let url = `https://doi.org/${props.doi}`;
  return (
    <Link href={url} target="_blank">
      {props.doi}
    </Link>
  );
}