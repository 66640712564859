import React, { Component, Fragment } from 'react'
import { withSnackbar } from '../../SnackbarContainer'
import Associator from '../Associator'
import ControlPanel from '../associator/ControlPanel'
import ProductsCheckboxList from '../products/CheckboxList'
import EntitiesPanel from '../associator/EntitiesPanel'
import InlineSearchContainer from '../search/InlineSearchContainer'
import update from "immutability-helper"
import EntityPanelContainer from "../associator/EntityPanelContainer"
import AssociatedList from '../associator/AssociatedList'
import ArtgPanel from './ArtgPanel'
import SectorCheckboxList from '../associator/SectorCheckboxList'

class AssociatorContainer extends Component {
  constructor(props) {
    super(props)
    this.handleAssociateClick = this.handleAssociateClick.bind(this)
    this.handleCheckboxSelect = this.handleCheckboxSelect.bind(this)
    this.handleClearSearchClick = this.handleClearSearchClick.bind(this)
    this.handleSearchClick = this.handleSearchClick.bind(this)
    this.handleProductDeleteClick = this.handleProductDeleteClick.bind(this)
    this.handleActiveIndexUpdate = this.handleActiveIndexUpdate.bind(this)
    this.handleSectorCheckboxSelect = this.handleSectorCheckboxSelect.bind(this)
    this.handleReviewedClick = this.handleReviewedClick.bind(this)
    this.state = { artgApproval: this.props.artg_approval, products: this.props.products, associatedProducts: this.props.associated_products, artgApprovalSectors: this.props.artg_approval_sectors, query: "", activeProducts: [], activeIndex: 'products', sectorIds: [] }
  }

  componentDidMount() {
    this.setState({
      activeIndex: this.props.associated_products.length > 0 ? 'associated' : 'products'
    })
  }

  handleReviewedClick(e) {
    e.preventDefault()
    
    $.ajax({
      type: "PUT",
      url: `/admin/artg_approvals/${this.state.artgApproval.id}`,
      data: {
        artg_approval: {
          reviewed: true, reviewed_by: this.props.user.id
        }
      },
      dataType: 'json',
      success: (data) => {
        this.setState({
          artgApproval: data.artg_approval
        })

        this.props.snackbarShowMessage("ARTG approval marked as reviewed")
      },
      error: (data) => {
        this.props.snackbarShowMessage(data.errors)
      }
    })
  }

  handleSectorCheckboxSelect(sector, checked) {
    let artgApprovalSector = this.state.artgApprovalSectors.filter((artgApprovalSector) => artgApprovalSector.sector_id == sector.id)[0]

    if (artgApprovalSector) {
      
      $.ajax({
        type: "PUT",
        url: `/admin/artg_approval_sectors/${artgApprovalSector.id}`,
        data: {
          artg_approval_sector: {
            deleted: true, deleted_by: this.props.current_user.id
          }
        },
        dataType: 'json',
        success: (data) => {
          let index = this.state.artgApprovalSectors.indexOf(artgApprovalSector)

          let artgApprovalSectors = update(this.state.artgApprovalSectors, {
            $splice: [[index, 1]]
          })

          this.setState({
            artgApprovalSectors: artgApprovalSectors
          })

          this.props.snackbarShowMessage("Sector has been removed")
        }
      })
    } else {
      $.ajax({
        type: "POST",
        url: `/admin/artg_approval_sectors`,
        data: {
          artg_approval_sector: {
            artg_approval_id: this.state.artgApproval.id, sector_id: sector.id
          }
        },
        dataType: 'json',
        success: (data) => {
          let artgApprovalSectors = update(this.state.artgApprovalSectors, {
            $push: [data.artg_approval_sector]
          })

          this.setState({
            artgApprovalSectors: artgApprovalSectors
          })

          this.props.snackbarShowMessage("Sector has been added")
        }
      })
    }
  }

  handleClearSearchClick(e) {
    this.setState({
      products: this.props.products
    })
  }

  handleActiveIndexUpdate(e, newValue) {
    this.setState({
      activeIndex: newValue
    })
  }

  handleAssociateClick(e) {
    $.ajax({
      type: "POST",
      url: "/admin/artg_approval_products",
      data: {
        artg_approval_product: { product_id: this.state.activeProducts[0], artg_approval_id: this.props.artg_approval.id }
      },
      dataType: 'json',
      success: (data) => {
        this.getAssociatedProducts()
      }
    })
  }

  getAssociatedProducts(e) {
    $.ajax({
      type: "GET",
      url: `/admin/artg_approvals/${this.props.artg_approval.id}/associator`,
      dataType: 'json',
      success: (data) => {
        this.setState({
          associatedProducts: data.associated_products,
          activeProducts: [],
          artgApprovalSectors: data.artg_approval_sectors
        })

        this.props.snackbarShowMessage("Product has been associated")
      }
    })
  }

  handleProductDeleteClick(entityProduct) {
    $.ajax({
      type: "PUT",
      url: `/admin/artg_approval_products/${entityProduct.entity_product_id}`,
      data: {
        artg_approval_product: {
          deleted: true, deleted_by: this.props.user.id
        }
      },
      dataType: 'json',
      success: (data) => {
        let index = this.state.associatedProducts.indexOf(entityProduct)

        let associatedProducts = update(this.state.associatedProducts, {
          $splice: [[index ,1]]
        })

        this.props.snackbarShowMessage("Product association has been removed")

        this.setState({
          associatedProducts: associatedProducts
        })

      }
    })
  }

  handleCheckboxSelect(productId, type) {
    let index = this.state.activeProducts.indexOf(productId)

    this.setState({
      activeProducts: [productId]
    })
  }

  handleSearchClick(query) {
    $.ajax({
      type: "GET",
      url: "/admin/products/search",
      data: {
        query: query
      },
      dataType: 'json',
      success: (data) => {
        this.setState({
          products: data.products,
          query: query
        })
      },
      error: (data) => {
        this.props.snackbarShowMessage("There was an error searching for the product. Please refresh the page and try again.")
      }
    })
  }


  render() {
    return (
      <Fragment>
        <Associator 
          firstPanel={
            <ArtgPanel 
              artgApproval={this.props.artg_approval}
            />
          }

          secondPanel={
            <Fragment>
              <ControlPanel
                reviewed={this.state.artgApproval.reviewed}
                handleAssociateClick={this.handleAssociateClick}
                handleReviewedClick={this.handleReviewedClick}
              />

              <SectorCheckboxList
                sectors={this.props.sectors}
                entitySectors={this.state.artgApprovalSectors}
                predictedSectors={this.props.predicted_sectors}
                handleSectorCheckboxSelect={this.handleSectorCheckboxSelect}
              />
            </Fragment>
          }
          thirdPanel={
            <EntitiesPanel 
              activeIndex={this.state.activeIndex}
              handleActiveIndexUpdate={this.handleActiveIndexUpdate}
              associated={
                <AssociatedList 
                  entityProducts={this.state.associatedProducts}
                  handleProductDeleteClick={this.handleProductDeleteClick}
                />
              }
              products={
                <EntityPanelContainer 
                  search={
                    <InlineSearchContainer
                      searchLabel="Search products" 
                      helperText="Search by name"
                      query={this.state.query}
                      handleSearchClick={this.handleSearchClick}
                      handleClearSearchClick={this.handleClearSearchClick}
                    />
                  }
                  entitiesList={
                    <ProductsCheckboxList 
                      products={this.state.products}
                      activeProducts={this.state.activeProducts}
                      entityProducts={this.state.associatedProducts}
                      handleCheckboxSelect={this.handleCheckboxSelect}
                    />
                  }
                />
              }
            />
          }
        />
      </Fragment>
    )
  }
}

export default withSnackbar(AssociatorContainer)