import React, { Component } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"

class TrialEnrollmentContainer extends Component {
  constructor(props) {
    super(props)
  }
  
  render() {
    // trial: @props.trial, chartData: @props.chart_data, chartDates: @props.chart_dates, trialHistory: @props.trial_history, enrollment: @props.enrollment, currentEnrollment: @props.current_enrollment, targetEnrollment: @props.target_enrollment, sectorId: @props.sector_id, averageEnrollment: @props.average_enrollment, showAverage: false, hideError: @hideError, showData: @state.showData, activeTab: @state.activeTab, handleDataClick: @handleDataClick, handleAddAvgData: @handleAddAvgData, extendTargetEnrollment: @props.extend_target_enrollment, enrollmentTypes: @props.enrollment_types
    return(
      <Enrollment {...this.props} />
    )
  }
}

export default TrialEnrollmentContainer
