import React from "react"
import ResultItemContainer from "./ResultItemContainer"
import Typography from "@material-ui/core/Typography"
import Flex from "../Flex"
import Box from "../Box"

export default function TrialItem(props) {

  let url = props.admin ? `/admin/trials/${props.result.trial_id}/edit` : `/sectors/${props.sector.abbr}/trials/${props.result.trial_id}`

  function renderSearchDetails(result) {
    return(
      <Flex>
        <Box mr={2}>
          {renderSearchDetailValue(result.nct_id)}
        </Box>
        {result.regulatory_id ? 
          <Box mr={2}>
            {renderSearchDetailValue(result.regulatory_id)}
          </Box>
        : ""}
        <Box>
          {renderSearchDetailValue(result.status)}
        </Box>
      </Flex>
    )
  }

  function renderSearchDetailValue(searchDetailValue) {
    return (
      <Typography variant="body2" color="textSecondary">
        {searchDetailValue}
      </Typography>
    );
  }

  return(
    <ResultItemContainer 
      searchItemType={props.result.record_type}
      searchTitle={props.result.acronym || props.result.short_name}
      date={props.result.last_updated}
      searchDescription={props.result.brief_summary}
      url={url}
      searchDetails={renderSearchDetails(props.result)}
    />
  )
}