import React from "react"
import MuiTableRow from '@material-ui/core/TableRow';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    "&:hover & td.primary-data a": {
      color: 'orange'
    }
  }
}));


export default function TableRow(props) {
  const { hover = true, children, ...rest } = props
  
  const classes = useStyles();
  
  return (
    <MuiTableRow hover={hover} className={classes.root} {...rest}>
      {children}
    </MuiTableRow>
  )
}
