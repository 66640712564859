import React, { Component } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import ListRecord from "../abstracts/ListRecord"
import Paper from "../Paper"
import { Table, TableHead, TableBody, TableRow, TableCell } from "../tables"
import Filters from "../abstracts/Filters"

class Abstracts extends Component {
  constructor(props) {
    super(props)
    this.handleClick = this.handleTabClick.bind(this)
    this.state = { activeTab: 'Listings' }
  }

  handleTabClick(e) {
    e.preventDefault()
  }

  render () {
    return (
      <Paper>
        <div>
          {/* <div className='mb3 ml2'>
            {this.props.filters ? this.props.filters : <Filters />}
          </div> */}

          <Table className='mvm collapse mh2'>
            <TableHead>
              <TableRow>
                <TableCell>Title</TableCell>
                <TableCell>Presenter</TableCell>
                {this.props.meeting ? '' : <TableCell>Meeting</TableCell>}
                <TableCell>Type</TableCell>
                <TableCell>Date</TableCell>
                <TableCell>Slides</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.props.abstracts.map((abstract) =>
                <ListRecord key={abstract.id} abstract={abstract} sector={this.props.sector} {...this.props} />
              )}
            </TableBody>
          </Table>
        </div>
      </Paper>
    );
  }
}

Abstracts.propTypes = {
  meetings: PropTypes.array,
  abstracts: PropTypes.array,
  sector: PropTypes.object
}

export default Abstracts
