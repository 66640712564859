import React, {Component} from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Chip from '@material-ui/core/Chip';
import DoneIcon from "@material-ui/icons/Done"

export default function CompareAttributeChip(props) {
  let selected = props.fields.includes(props.field)

  return (
    <Chip 
      label={props.field} 
      deleteIcon={selected ? <DoneIcon /> : ''}
      onDelete={selected ? props.handleChipSelection : ''}
      onClick={props.handleChipSelection} 
    />
  )
}
