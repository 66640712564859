import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import Paper from "../../Paper"
import Flex from "../../Flex"
import Box from "../../Box"
import IndexFilters from "./IndexFilters"
import RecallsTable from "./RecallsTable"
import { withSnackbar } from '../../SnackbarContainer'
import ListingsCountDisplay from '../../ListingsCountDisplay'
import update from "immutability-helper"
import Typography from '@material-ui/core/Typography'

class IndexContainer extends Component {
  constructor(props) {
    super(props)
    this.handleSearchClick= this.handleSearchClick.bind(this)
    this.getBfarmRecalls = this.getBfarmRecalls.bind(this)
    this.handlePageChange = this.handlePageChange.bind(this)
    this.handleClearSearchClick = this.handleClearSearchClick.bind(this)
    this.handleFilterChange = this.handleFilterChange.bind(this)
    this.handleDeleteClick = this.handleDeleteClick.bind(this)
    this.state = { bfarmRecalls: this.props.bfarm_recalls, bfarmRecallsCount: this.props.bfarm_recalls_count, filterValues: { query: this.props.query, page: this.props.page, product_id: this.props.product_id }, filterOptions: { products: this.props.products } }
  }

  handleDeleteClick(fdaRecall) {
    $.ajax({
      type: "PUT",
      url: `/admin/bfarm_recalls/${fdaRecall.id}`,
      data: {
        bfarm_recall: {
          hide: true
        }
      },
      dataType: 'json',
      success: (data) => {
        let index = this.state.bfarmRecalls.indexOf(fdaRecall)

        let bfarmRecalls = update(this.state.bfarmRecalls, {
          $splice: [[index, 1]]
        })

        this.setState({
          bfarmRecalls: bfarmRecalls
        })

        this.props.snackbarShowMessage("Recall was hidden")
      }
    })
  }

  handleFilterChange(name, value) {
    let params = Object.assign({}, this.state.filterValues)

    // params["order_by"] = this.state.orderBy
    // params["order"] = this.state.order
    params[name] = value

    this.getBfarmRecalls(params)
  }

  handlePageChange(e, newPage) {
    let params = Object.assign({}, this.state.filterValues)

    // params["order_by"] = this.state.orderBy
    // params["order"] = this.state.order
    params["page"] = newPage

    this.getBfarmRecalls(params)
  }

  handleSearchClick(query) {
    let params = Object.assign({}, this.state.filterValues)

    params["query"] = query

    this.getBfarmRecalls(params)
  }

  handleClearSearchClick(e) {
    let params = Object.assign({}, this.state.filterValues)

    params["query"] = null

    this.getBfarmRecalls(params)
  }

  getBfarmRecalls(params) {
    $.ajax({
      type: "GET",
      url: "/admin/bfarm_recalls",
      data: params,
      dataType: 'json',
      success: (data) => {
        this.setState({
          bfarmRecalls: data.bfarm_recalls,
          bfarmRecallsCount: data.bfarm_recalls_count,
          filterValues: params
        })
      }
    })
  }


  render() {
    return(
      <Fragment>
        <Paper fullHeight>
          <Box height="100%">
            
            <Flex mb={2} alignItems='center'>
              <Box>
                <IndexFilters 
                  filterOptions={this.state.filterOptions}
                  filterValues={this.state.filterValues}
                  handleFilterChange={this.handleFilterChange}
                  handleSearchClick={this.handleSearchClick}
                />
              </Box>

              <Box ml='auto'>
                <Typography variant="body2">
                  {`${this.state.bfarmRecallsCount} recalls`}
                </Typography>
              </Box>
            </Flex>

            <Box overflow="auto" height="80%">
              <RecallsTable 
                recalls={this.state.bfarmRecalls}
                recallsCount={this.state.bfarmRecallsCount}
                page={this.state.filterValues.page}
              />
            </Box>
          </Box>
        </Paper>
      </Fragment>
    )
  }
}

export default withSnackbar(IndexContainer)