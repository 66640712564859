import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import EntityAlertsDisplay from "../alerts/EntityAlertsDisplay"
import EntityAlertFilters from "../alerts/EntityAlertFilters"
import { withSnackbar } from "../SnackbarContainer"

class Alerts extends Component {
  constructor(props) {
    super(props)
    this.handleFilterChange = this.handleFilterChange.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.getAlerts = this.getAlerts.bind(this)
    this.state = { alerts: this.props.alerts, year: this.props.year, group_id: this.props.group_id, pages: this.props.pages, page: 1, alertsCount: this.props.alerts_count, showSnackbar: false, snackbarText: '' }
  }

  handleFilterChange(name, value) {
    let params = { group_id: this.state.group_id, year: this.state.year, page: this.state.page }

    params[name] = value

    this.getAlerts(params)
  }

  getAlerts(params) {
    $.ajax({
      type: 'GET',
      url: `/sectors/${this.props.sector.abbr}/trials/${this.props.trial.id}/alerts`,
      data: params,
      dataType: 'json',
      success: (data) => {
        this.setState({
          alerts: data.alerts,
          pages: data.pages,
          alertsCount: data.alerts_count,
          group_id: params.group_id,
          year: params.year,
          page: params.page
        })
      },
      error: (data) => {
        this.props.snackbarShowMessage('There was an error fetching the alerts. Please try again.', "error")
      }
    })
  }

  handleChange(event, value) {
    let params = { group_id: this.state.group_id, year: this.state.year, page: this.state.page }

    params['page'] = value

    $.ajax({
      type: 'GET',
      url: `/sectors/${this.props.sector.abbr}/trials/${this.props.trial.id}/alerts`,
      data: params,
      dataType: 'json',
      success: (data) => {
        this.setState({
          alerts: data.alerts,
          alertsCount: data.alerts_count,
          group_id: params.group_id,
          year: params.year,
          page: params.page
        })
      },
      error: (data) => {
        this.props.snackbarShowMessage('There was an error fetching the alerts. Please try again.', "error")
      }
    })
  }

  render() {
    return(
      <EntityAlertsDisplay
        alerts={this.state.alerts}
        entity={this.props.trial}
        sector={this.props.sector}
        handleChange={this.handleChange}
        filters={
          <EntityAlertFilters
            groupId={this.state.group_id}
            year={this.state.year}
            alert_groups={this.props.alert_groups}
            years={this.props.years}
            handleFilterChange={this.handleFilterChange}
          />
        }
      />
    )
  }
}

Alerts.propTypes = {
  trial: PropTypes.object
}


export default withSnackbar(Alerts)
