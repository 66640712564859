import React, { Component, Fragment } from "react"
import Flex from "../../Flex"
import Box from "../../Box"
import { Button } from "../../buttons"
import { List, ListItem, ListItemText } from "../../lists"
import ExpandLess from '../../ExpandLess';
import ExpandMore from '../../ExpandMore';
import Collapse from '@material-ui/core/Collapse';

export default class CharacteristicGroupDisplay extends Component {
  constructor(props) {
    super(props)
    this.state = { open: true }
  }

  // componentDidMount() {
  //   if (!this.props.allOpen) {
  //     this.setState({
  //       open: false
  //     })
  //   }
  // }

  render() {
    const groupDisplay = `${this.props.characteristicGroup.region} ${this.props.characteristicGroup.name}`
    return(
      <Fragment>
        <List>

          <ListItem button onClick={() => this.setState({open: !this.state.open})}>
            {this.state.open ? <ExpandLess /> : <ExpandMore />}
            <ListItemText primary={groupDisplay} />
          </ListItem>

          <Collapse in={this.state.open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>

              {this.props.displayCharacteristics}

              <Flex>
                <Box></Box>
                <Box className='ml-auto'>
                  <Button tag='a' href={`/admin/characteristic_values/new?characteristic_group_id=${this.props.characteristicGroup.id}&r=/admin/products/${this.props.product.id}/edit?active_index=2`}>
                    Add Value
                  </Button>
                </Box>
              </Flex>

            </List>
          </Collapse>
        </List>
      </Fragment>
    )
  }
}
