import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import InfoIcon from '@material-ui/icons/Info';
import Typography from '@material-ui/core/Typography';
import { List, ListItem, ListItemText } from '../lists';

const Tips = (props) => {

  return (
    <div>
      {/* <IconButton onClick={handleClickOpen} color="default" size='small'>
        <InfoIcon fontSize='small' />
      </IconButton> */}

      <Dialog onClose={props.handleClose} aria-labelledby="customized-dialog-title" open={props.open}>
        <DialogTitle id="customized-dialog-title" onClose={props.handleClose}>
          Search Tips
        </DialogTitle>
        <DialogContent dividers>
          <List>
            <ListItem>
              <ListItemText>
                <Typography gutterBottom>
                  <b>General Searching:</b> Just type the keywords you're looking for, and the search will find the best matches.
                </Typography>

                <Typography variant='body2' color="textSecondary">
                  Example: <code>mitral valve</code> finds all the items in which the words <code>mitral</code> and <code>valve</code> appears.
                </Typography>
              </ListItemText> 
            </ListItem>

            <ListItem>
              <ListItemText>
                <Typography gutterBottom>
                  <b>Boolean Searching:</b> Use <b>AND</b>, <b>OR</b>, <b>NOT</b> to combine or exclude keywords.
                </Typography>
                  
                <Typography variant='body2' color="textSecondary">
                  Example: <code>mitral OR valve</code> finds items containing either <code>mitral</code> or <code>valve</code>.
                </Typography>
              </ListItemText>
            </ListItem>

            <ListItem>
              <ListItemText>
                <Typography gutterBottom>
                  <b>Exact Phrases:</b> Use double quotes to search for exact phrases.
                </Typography>
                  
                <Typography variant='body2' color="textSecondary">
                  Example: <code>"mitral valve"</code> finds items with the exact phrase <code>mitral valve</code>.
                </Typography>
              </ListItemText>
            </ListItem>

            <ListItem>
              <ListItemText>
                <Typography gutterBottom>
                  <b>Grouping Terms:</b> Use parentheses to group terms and control the logic of the search.
                </Typography>
                  
                <Typography variant='body2' color="textSecondary">
                  Example: <code>(heart OR cardiac) AND surgery</code> finds items with either <code>heart</code> or <code>cardiac</code> that also contain <code>surgery</code>.
                </Typography>
              </ListItemText>
            </ListItem>
          </List>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default Tips;
