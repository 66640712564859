import React from "react"
import Link from "../../../Link"
import { TableCell } from "../../../tables"

export default function NumberDisplay(props) {

  let url = `https://www.accessdata.fda.gov/scripts/cdrh/cfdocs/cfpma/pma.cfm?id=${props.fdaPmaApproval.pma_number}${props.fdaPmaApproval.supplement_number}`

  return(
    <Link target="_blank" href={url}>
      {props.fdaPmaApproval.supplement_number ? `${props.fdaPmaApproval.pma_number} ${props.fdaPmaApproval.supplement_number}` : props.fdaPmaApproval.pma_number}
    </Link>
  )
}
