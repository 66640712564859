import React, { Fragment } from "react"
import Flex from "../Flex"
import Box from "../Box"
import Select from "../Select"

export default function EntityAlertsDisplay(props) {


  return(
    <Fragment>

      <Flex mb={3}>
        <Box mr={3}>
          <Select
            label='Group'
            name='group_id'
            includeBlank
            blankOption='All'
            value={props.group_id}
            entities={props.alert_groups}
            handleChange={props.handleFilterChange}
          />
        </Box>

        <Box>
          <Select
            label='Year'
            name='year'
            includeBlank
            blankOption='All'
            value={props.year}
            entities={props.years}
            handleChange={props.handleFilterChange}
          />
        </Box>
      </Flex>

    </Fragment>
  )
}
