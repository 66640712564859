import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import { TableRow, TableCell } from "../tables"
import Link from "../Link"
import Box from "../Box"

export default class PipelineProductRow extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    const status = this.props.region == 1 ? this.props.product.us_status : this.props.product.eu_status

    const targetSites = this.props.region == 1 ? this.props.product.us_target_sites : this.props.product.eu_target_sites

    const investigationalTargetSites = this.props.product.investigational_target_sites

    const targetMarkets = this.props.sector.market_id == 3 ? this.props.product.target_markets : (this.props.region == 1 ? this.props.product.us_target_markets : this.props.product.eu_target_markets)

    const investigationalTargetMarkets = this.props.product.investigational_target_markets

    const therapyTypes = this.props.sector.market_id == 3 ? this.props.product.therapy_types : null

    return(
      <TableRow>

        <TableCell>
          <Link href={`/sectors/${this.props.sector.abbr}/products/${this.props.product.id}`}>
            {this.props.product.short_name}
          </Link>
        </TableCell>

        {this.props.sector.market_id != 3 ?
          <TableCell>
            {targetSites.map((targetSite) =>
              <Box key={targetSite.id}>
                {targetSite.name}
              </Box>
            )}

          </TableCell>
        : ""}

        {this.props.sector.market_id != 3 ?
          <TableCell>
            {investigationalTargetSites.map((targetSite) =>
              <Box key={targetSite.id}>
                {targetSite.name}
              </Box>
            )}
          </TableCell>
        : ""}

        <TableCell>
          {targetMarkets.map((targetMarket) =>
            <Box key={targetMarket.id}>
              {targetMarket.name}
            </Box>
          )}
        </TableCell>

        {this.props.sector.market_id != 3 ?
          <TableCell>
            {investigationalTargetMarkets.map((targetMarket) =>
              <Box key={targetMarket.id}>
                {targetMarket.name}
              </Box>
            )}
          </TableCell>
        : ""}

        {this.props.sector.market_id == 3 ?
          <TableCell>
            {therapyTypes.map((therapyType) =>
              <Box key={therapyType.id}>
                {therapyType.name}
              </Box>
            )}
          </TableCell>
        : ""}

        <TableCell>
          <Fragment>
            <Box>
              {this.props.product.us_status ? this.props.product.us_status.name : ""}
            </Box>
            <Box>
              {this.props.product.eu_status ? this.props.product.eu_status.name : ""}
            </Box>
          </Fragment>
        </TableCell>

        {/* <TableCell>
          {this.props.product.eu_status.map((euStatus) =>
            <Box key={euStatus.id}>
              {euStatus.name}
            </Box>
          )}
        </TableCell> */}
      </TableRow>
    )
  }
}
