import React, { Fragment } from "react"
import PropTypes from "prop-types"
import ReactDOM from "react-dom"
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from "@material-ui/core/Typography"
import { List, ListItem, ListItemLink, ListItemText } from "../lists"
import ListSubheader from "@material-ui/core/ListSubheader"
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction"
import Flex from "../Flex"
import Box from "../Box"
import Link from "../Link"


export default function AssociationsContainer(props) {
  let yearArrays = Object.values(props.abstracts)
  let totalAbstracts = [].concat(...yearArrays);
  let totalTrials = [].concat(...Object.values(props.trials))
  let totalManuscripts = [].concat(...Object.values(props.manuscripts))
  let totalPatents = [].concat(...Object.values(props.patents))
  let totalGrants = [].concat(...Object.values(props.nihGrants))

  return (
    <Fragment>

      <ExpansionPanel defaultExpanded={false}>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} ariacontrols={`abstracts-content`} id={`abstracts-header`}>
          <Flex alignItems='center' width={1}>
            <Box>
              <Typography className="f4">
                Presentations
              </Typography>
            </Box>

            <Box ml='auto'>
              <Typography variant='caption'>
                ({totalAbstracts.length})
              </Typography>
            </Box>
          </Flex>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Typography>
            <List>
              {Object.keys(props.abstracts).reverse().map((year) =>
                <Fragment>
                  <ListSubheader key={year} disableGutters>{year}</ListSubheader>
                  <Fragment>
                    {props.abstracts[year].map((abstract) =>
                      <ListItemLink key={abstract.id} href={`/sectors/${props.sector.abbr}/abstracts/${abstract.id}`} target="_blank">
                        <ListItemText
                          className='mr3'
                          primary={abstract.title}
                        />

                        <ListItemText
                          className='flex-none'
                          secondary={abstract.science_meeting.short_name}
                        />

                      </ListItemLink>
                    )}
                  </Fragment>
                </Fragment>
              )}
            </List>
          </Typography>
        </ExpansionPanelDetails>
      </ExpansionPanel>

      <ExpansionPanel defaultExpanded={false}>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} ariacontrols={`trials-content`} id={`trials-header`}>
          <Flex alignItems='center' width={1}>
            <Box>
              <Typography className="f4">
                Trials
              </Typography>
            </Box>

            <Box ml='auto'>
              <Typography variant='caption'>
                ({totalTrials.length})
              </Typography>
            </Box>
          </Flex>

        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Typography>
            <List>
              {Object.keys(props.trials).reverse().map((year) =>
                <Fragment>
                  <ListSubheader key={year} disableGutters>{year}</ListSubheader>
                  {props.trials[year].map((trial) =>
                    <ListItemLink key={trial.id} href={`/sectors/${props.sector.abbr}/trials/${trial.id}`} target="_blank">
                      <ListItemText
                        primary={trial.data_view_short_name || trial.data_view_acronym}
                        secondary={trial.sponsor_name}
                      />
                    </ListItemLink>
                  )}
                </Fragment>
              )}
            </List>
          </Typography>
        </ExpansionPanelDetails>
      </ExpansionPanel>

      <ExpansionPanel defaultExpanded={false}>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} ariacontrols={`products-content`} id={`products-header`}>
          <Flex alignItems='center' width={1}>
            <Box>
              <Typography className="f4">
                Products
              </Typography>
            </Box>

            <Box ml='auto'>
              <Typography variant='caption'>
                ({props.products.length})
              </Typography>
            </Box>
          </Flex>
        </ExpansionPanelSummary>

        <ExpansionPanelDetails>
          <Typography>
            <List>
              {props.products.map((product) =>
                <ListItem key={product.id} button>
                  <ListItemText
                    primary={
                      <Link href={`/sectors/${props.sector.abbr}/products/${product.id}`}>
                        {product.short_name}
                      </Link>
                    }
                    secondary={
                      <Link href={`/sectors/${props.sector.abbr}/trials/${product.trial_id}`} textColor="gray">
                        {product.acronym || product.trial_short_name}
                      </Link>
                    }
                  />
                </ListItem>
              )}
            </List>
          </Typography>
        </ExpansionPanelDetails>
      </ExpansionPanel>

      <ExpansionPanel defaultExpanded={false}>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} ariacontrols={`manuscripts-content`} id={`manuscripts-header`}>
          <Flex alignItems='center' width={1}>
            <Box>
              <Typography className="f4">
                Manuscripts
              </Typography>
            </Box>

            <Box ml='auto'>
              <Typography variant='caption'>
                ({totalManuscripts.length})
              </Typography>
            </Box>
          </Flex>

        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Typography>
            <List>
              {Object.keys(props.manuscripts).reverse().map((year) =>
                <Fragment>
                  <ListSubheader key={year} disableGutters>
                    {year}
                  </ListSubheader>

                  {props.manuscripts[year].map((manuscript) =>
                    <ListItemLink key={manuscript.id} href={`/sectors/${props.sector.abbr}/publications/${manuscript.id}`} target="_blank">
                      <ListItemText
                        primary={manuscript.title}
                      />
                    </ListItemLink>
                  )}
                </Fragment>
              )}
            </List>
          </Typography>
        </ExpansionPanelDetails>
      </ExpansionPanel>

      <ExpansionPanel defaultExpanded={false}>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} ariacontrols={`abstracts-content`} id={`abstracts-header`}>
          <Flex alignItems='center' width={1}>
            <Box>
              <Typography className="f4">
                Patents
              </Typography>
            </Box>

            <Box ml='auto'>
              <Typography variant='caption'>
                ({totalPatents.length})
              </Typography>
            </Box>
          </Flex>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Typography>
            <List>
              {Object.keys(props.patents).reverse().map((year) =>
                <Fragment>
                  <ListSubheader key={year} disableGutters>{year}</ListSubheader>
                  <Fragment>
                    {props.patents[year].map((patent) =>
                      <ListItemLink key={patent.id} href={`/sectors/${props.sector.abbr}/patents/${patent.id}`} target="_blank">
                        <ListItemText
                          className='mr3'
                          primary={patent.title}
                        />

                        <ListItemText
                          className='flex-none'
                          secondary={`${patent.country}${patent.html_patent_no}${patent.patent_kind}`}
                        />

                      </ListItemLink>
                    )}
                  </Fragment>
                </Fragment>
              )}
            </List>
          </Typography>
        </ExpansionPanelDetails>
      </ExpansionPanel>

      <ExpansionPanel defaultExpanded={false}>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} ariacontrols={`nih-grants-content`} id={`nih-grants-header`}>
          <Flex alignItems='center' width={1}>
            <Box>
              <Typography className="f4">
                NIH Grants
              </Typography>
            </Box>

            <Box ml='auto'>
              <Typography variant='caption'>
                ({totalGrants.length})
              </Typography>
            </Box>
          </Flex>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Typography>
            <List>
              {Object.keys(props.nihGrants).reverse().map((year) =>
                <Fragment>
                  <ListSubheader key={year} disableGutters>{year}</ListSubheader>
                  <Fragment>
                    {props.nihGrants[year].map((nihGrant) =>
                      <ListItemLink key={nihGrant.id} href={nihGrant.project_detail_url} target="_blank">
                        <ListItemText
                          className='mr3'
                          primary={nihGrant.project_title}
                        />

                      </ListItemLink>
                    )}
                  </Fragment>
                </Fragment>
              )}
            </List>
          </Typography>
        </ExpansionPanelDetails>
      </ExpansionPanel>

    </Fragment>
  );
}
