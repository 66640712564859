import React from "react"
import { Table, TableHead, TableRow, TableCell, TableBody } from "../tables"
import Link from "../Link"

export default function CompetitorRow(props) {
  const usStatus = props.product.regulatory_statuses.filter(productRegulatoryStatus => productRegulatoryStatus.world_regions[0].name == 'US' ? productRegulatoryStatus : null)[0]
  const euStatus = props.product.regulatory_statuses.filter(productRegulatoryStatus => productRegulatoryStatus.world_regions[0].name == 'EU' ? productRegulatoryStatus : null)[0]
  return (
    <TableRow>
      <TableCell borderRight={true} borderLeft={true}>
        <Link href={`/sectors/${props.sector.abbr}/companies/${props.product.company.id}`}>
          {props.product.company.short_name}
        </Link>
      </TableCell>
      
      <TableCell borderRight={true}>
        <Link href={`/sectors/${props.sector.abbr}/products/${props.product.product_id}`}>
          {props.product.short_name}
        </Link>
      </TableCell>
      
      {/* <TableCell>{props.product.method_type_name}</TableCell> */}
      {/* {props.sector.id == 7 ? <TableCell>{props.product.sectspec1}</TableCell> : ''} */}
      
      <TableCell borderRight={true}>
        {props.product.target_markets.map((targetMarket) =>
          <div key={targetMarket.id} className={props.targetMarket == targetMarket.id ? 'b' : ''}>
            {targetMarket.name}
          </div>
        )}
      </TableCell>
      
      <TableCell borderRight={true}>
        {props.product.technology_groups.map((technologyGroup) =>
          <div key={technologyGroup.id}>
            {technologyGroup.name}
          </div>
        )}
      </TableCell>
      {/* {props.sector.id != 9 ? <TableCell>{props.product.regulatory_approach}</TableCell> : ''} */}
      <TableCell borderRight={true}>{usStatus ? usStatus.name : ''}</TableCell>
      
      <TableCell borderRight={true}>{euStatus ? euStatus.name : ''}</TableCell>
    </TableRow>
  )
}
