import React from "react"
import { Table, TableRow, TableCell, TableHead, TableBody } from "../../tables"
import Link from "../../Link"


export default function UpdatedModule(props) {
  return(
    <Table>
      <TableBody>
        {props.trials.map((trial) =>
          <TableRow key={trial.trial_id}>
            <TableCell>
              <Link href={`/admin/trials/${trial.trial_id}/edit`}>
                {trial.acronym || trial.short_name}
              </Link>
            </TableCell>
            <TableCell>{humanDateFormat(trial.last_updated)}</TableCell>
            <TableCell>
              {/* {trial.user_id == 1 ? 'SYS' : displayInitials(trial.first_name, trial.last_name)} */}
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  )
}
