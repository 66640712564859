import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import StudiesTable from "./StudiesTable"
import StudiesAnalytics from "./StudiesAnalytics"
import Paper from "../Paper"
import Link from "../Link"
import Flex from "../Flex"
import Box from "../Box"
import Typography from "@material-ui/core/Typography"
import IconButton from "../IconButton"
import BarChartIcon from '@material-ui/icons/BarChart';
import ListIcon from '@material-ui/icons/List';
import CompareIcon from '@material-ui/icons/Compare';

export default class Studies extends Component {
  constructor(props) {
    super(props)
    this.handleFilterClick = this.handleFilterClick.bind(this)
    this.handleListViewClick = this.handleListViewClick.bind(this)
    this.handleChartViewClick = this.handleChartViewClick.bind(this)
    this.handleChangePage = this.handleChangePage.bind(this)
    this.handleRequestSort = this.handleRequestSort.bind(this)
    this.loadTrials = this.loadTrials.bind(this)
    this.desc = this.desc.bind(this)
    this.stableSort = this.stableSort.bind(this)
    this.getSorting = this.getSorting.bind(this)
    this.state = { trials: this.props.trials, trialsCount: this.props.trials_count, page: 0, rowsPerPage: 100, studyType: 'Clinical Trials', view: 'list', order: 'desc', orderBy: 'updated_at' }
  }

  handleListViewClick(e) {
    this.setState({
      view: 'list'
    })
  }

  handleChartViewClick(e) {
    this.setState({
      view: 'chart'
    })
  }

  handleChangePage(e, newPage) {
    let params = {}// Object.assign({}, this.state.filterValues)

    params["order_by"] = this.state.orderBy
    params["order"] = this.state.order
    params["page"] = newPage

    this.loadTrials(params)
  }

  handleFilterClick(e) {
    e.preventDefault()
    let filterValue = e.target.text
    this.setState({
      studyType: filterValue
    })
  }

  loadTrials(params) {
    $.ajax({
      type: 'GET',
      url: `/sectors/${this.props.sector.abbr}/products/${this.props.product.id}/studies`,
      data: params,
      dataType: 'json',
      beforeSend: (data) => {
        this.setState({
          loading: true
        })
      },
      success: (data) => {
        this.setState({
          trials: data.trials,
          trialsCount: data.trials_count,
          loading: false,
          page: params['page']
        })
      },
      error: (data) => {
        this.setState({
          showSnackbar: true,
          snackbarText: 'An error has occurred while trying to load the data. Please try again.'
        })
      },
      complete: (data) => {
        setTimeout(() => {
          this.setState({
            showSnackbar: false,
            snackbarText: ''
          })
        })
      }
    })
  }

  desc(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = cmp(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
  }

  getSorting(order, orderBy) {
    return order === 'desc' ? (a, b) => this.desc(a, b, orderBy) : (a, b) => -this.desc(a, b, orderBy);
  }

  handleRequestSort(event, property) {
    let params = {}// Object.assign({}, this.state.filterValues)

    params["order_by"] = property

    let isDesc = this.state.orderBy === property && this.state.order === 'desc';

    params["order"] = isDesc ? 'asc' : 'desc'

    $.ajax({
      type: 'GET',
      url: `/sectors/${this.props.sector.abbr}/products/${this.props.product.id}/order_product_studies`,
      data: params,
      dataType: 'json',
      beforeSend: (data) => {
        this.setState({
          loading: true
        })
      },
      success: (data) => {
        this.setState({
          order: params["order"],
          orderBy: params["order_by"],
          trials: data.trials,
          loading: false
        })
      },
      error: (data) => {
        console.log(data.responseText)
        this.setState({
          showSnackbar: true,
          snackbarText: 'There was an error. Please try again.',
          loading: false
        })
      },
      complete: (data) => {
        setTimeout(() => {
          this.setState({
            showSnackbar: false,
            snackbarText: '',
            loading: false
          })
        }, 5000)
      }
    })
  };

  render() {
    return(
      <div>

        <Paper fullHeight={this.state.view == 'list' ? true : false}>

          <Box height="100%">
            <Flex alignItems='center' mb={3}>
              <Box>
                <Typography variant="h6" component="h6">
                  Clinical trials
                </Typography>
              </Box>

              <Box ml="auto">
                <IconButton onClick={this.handleListViewClick} color={this.state.view == 'list' ? 'primary' : 'default'}>
                  <ListIcon />
                </IconButton>

                <IconButton onClick={this.handleChartViewClick} color={this.state.view == 'chart' ? 'primary' : 'default'} tooltip='Chart'>
                  <BarChartIcon />
                </IconButton>

                <IconButton component='a' target="_blank" href={`/sectors/${this.props.sector.abbr}/trials/compare_trials?trial_ids=${this.props.trials.map(trial => trial.trial_id)}`} tooltip='Compare trials'>
                  <CompareIcon />
                </IconButton>
              </Box>
            </Flex>

            {this.state.view == 'list' ?
              this.props.trials.length > 0 ?
                <Fragment>
                  <Box textAlign='right'>
                    <Typography variant="body2">
                      {`${this.state.trialsCount} trials`}
                    </Typography>
                  </Box>

                  <StudiesTable
                    trials={this.state.trials}
                    trialsCount={this.state.trialsCount}
                    page={this.state.page}
                    order={this.state.order}
                    orderBy={this.state.orderBy}
                    sector={this.props.sector}
                    handleRequestSort={this.handleRequestSort}
                    handleChangePage={this.handleChangePage}
                  />

                </Fragment>
              :
                <Flex justifyContent='center' height="50%">
                  <Box alignSelf='center' maxWidth="50%">
                    <Box>
                      <Typography variant="body1">
                        No trials found for this product
                        </Typography>
                    </Box>
                  </Box>
                </Flex>
            :
              <StudiesAnalytics
                // products={this.props.products}
                product={this.props.product}
                sector={this.props.sector}
                // chartData={this.props.chart_data}
                // categories={this.props.categories}
                // trialsData={this.props.trials_data}
              />
            }
          </Box>

        </Paper>
      </div>
    )
  }
}
