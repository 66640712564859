import React, { Component } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Highcharts from "highcharts"
import timelineFactory from 'highcharts/modules/timeline';
import Exporting from 'highcharts/modules/exporting';
timelineFactory(Highcharts)
// Initialize exporting module.
Exporting(Highcharts);

class Timeline extends Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    let customTooltip = this.props.tooltipEnabled ? { enabled: true } : { enabled: false }
    // let customTooltip = this.props.tooltipEnabled ? { headerFormat: this.props.tooltip.headerFormat, pointFormat: this.props.tooltip.pointFormat, footerFormat: this.props.tooltip.footerFormat } : { enabled: true }
    Highcharts.setOptions({
      lang: {
        noData: "No data to display",
        thousandsSep: ','
      }
    })
    Highcharts.chart(`${this.props.chartId}`, {
    chart: {
        zoomType: 'x',
        type: 'timeline'
    },
    credits: {
      enabled: false
    },
    noData:{
      style:{
        fontWeight: 'bold'
      }
    },
    xAxis: {
        type: 'datetime',
        visible: false,
        max: this.props.xAxisMax,
        min: this.props.xAxisMin
    },
    yAxis: {
        gridLineWidth: 1,
        title: null,
        labels: {
            enabled: false
        }
    },
    legend: {
        enabled: false
    },
    title: {
        text: this.props.title
    },
    subtitle: {
        text: this.props.subtitle
    },
    tooltip: customTooltip,
    exporting: {
      filename: `${this.props.exportingTitle ? this.props.exportingTitle : this.props.title}_timeline`,
      credits: { enabled: true, text: '\u00A9 MarketMonitors' }
    },
    series: [{
        dataLabels: {
          connectorColor: 'silver',
          connectorWidth: 2,
            allowOverlap: false,
            format: '<span style="color:{point.color}">● </span><span style="font-weight: bold;" > ' +
                '{point.label}</span><br/>{point.x:%B %e, %Y}'
        },
        marker: {
            symbol: 'circle'
        },
        data: this.props.formattedData
      }]
    });
  }

  render () {
    return(
      <div id={this.props.chartId}></div>
    )
  }
}

Timeline.defaultProps = {
  tooltipEnabled: true
}

export default Timeline
