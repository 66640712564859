import React from "react"
import ExpandMore from "../../ExpandMore"
import ExpandLess from "../../ExpandLess"
import { ListItem, ListItemText } from "../../lists"
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import Box from "../../Box"
import { TabPanel } from "../../tabs"
import EnrollmentExtractorForm from "./EnrollmentExtractorForm"
import DateForm from "./DateForm"
import CriteriaForm from "./CriteriaForm"
import EndpointForm from "./EndpointForm"
import StatusForm from "./StatusForm"
import TypeForm from "./TypeForm"
import TextFieldForm from "./TextFieldForm"
import ConclusionForm from "./ConclusionForm"



export default function AttributeListItem(props) {

  function handleClick(e) {
    props.handleActiveIndexUpdate(props.index)
  }

  console.log(props.activeIndex)
  return (
    <React.Fragment>
      <ListItem button onClick={handleClick} clasName='bb b--black-10'>
        <ListItemIcon>
          <CheckCircleOutlineIcon />
        </ListItemIcon>

        <ListItemText primary={props.dataAttr} />

        <ListItemSecondaryAction>
          {props.activeIndex == props.index ? <ExpandLess /> : <ExpandMore />}
        </ListItemSecondaryAction>
      </ListItem>

      <Box mt={2}>
        <Box>

          <TabPanel value={props.activeIndex} index={0}>
            <StatusForm
              trialStatus={props.trialStatus}
              statuses={props.statuses}
              handleStatusSaveClick={props.handleStatusSaveClick}
            />
          </TabPanel>

          <TabPanel value={props.activeIndex} index={1}>
            <TypeForm
              trialType={props.trialType}
              types={props.types}
              handleTypeSaveClick={props.handleTypeSaveClick}
            />
          </TabPanel>

          <TabPanel value={props.activeIndex} index={2}>
            <EnrollmentExtractorForm
              handleEnrollmentSaveClick={props.handleEnrollmentSaveClick}
              trialTargetEnrollment={props.trialTargetEnrollment}
              trialCurrentEnrollment={props.trialCurrentEnrollment}
            />
          </TabPanel>

          <TabPanel value={props.activeIndex} index={3}>
            <DateForm
              trialDates={props.trialDates}
              handleDateSaveClick={props.handleDateSaveClick}
            />
          </TabPanel>

          <TabPanel value={props.activeIndex} index={4}>
            <CriteriaForm
              trialCriterias={props.trialCriterias}
              handleCriteriaSaveClick={props.handleCriteriaSaveClick}
            />
          </TabPanel>

          <TabPanel value={props.activeIndex} index={5}>
            <EndpointForm
              trialEndpoints={props.trialEndpoints}
              handleEndpointSaveClick={props.handleEndpointSaveClick}
            />
          </TabPanel>

          <TabPanel value={props.activeIndex} index={6}>
            <TextFieldForm
              trialTextFields={props.trialTextFields}
              handleTrialTextFieldSaveClick={props.handleTrialTextFieldSaveClick}
            />
          </TabPanel>

          <TabPanel value={props.activeIndex} index={7}>
            <ConclusionForm
              abstractConclusion={props.abstractConclusion}
              handleConclusionSaveClick={props.handleConclusionSaveClick}
            />
          </TabPanel>

        </Box>
      </Box>

    </React.Fragment>
  )
}
