import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Paper from "../Paper"
import Box from "../Box"
import EntityAlertFilters from "./EntityAlertFilters"
import EntityAlertsDisplay from "./EntityAlertsDisplay"
import { withSnackbar } from "../SnackbarContainer"
import EmptyDataMessageContainer from "../EmptyDataMessageContainer"

class EntityAlertsContainer extends Component {
  constructor(props) {
    super(props)
    this.handleFilterChange = this.handleFilterChange.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.getAlerts = this.getAlerts.bind(this)
    this.state = { alerts: this.props.alerts, year: this.props.year, group_id: this.props.group_id, pages: this.props.pages, page: 1, alertsCount: this.props.alerts_count, alertGroups: this.props.alert_groups, years: this.props.years }
  }

  handleFilterChange(name, value) {
    let params = { group_id: this.state.group_id, year: this.state.year, page: this.state.page }

    params[name] = value

    this.getAlerts(params)
  }

  getAlerts(params) {
    $.ajax({
      type: 'GET',
      url: `/sectors/${this.props.sector.abbr}/${this.props.entity_type}/${this.props.entity.id}/alerts`,
      data: params,
      dataType: 'json',
      success: (data) => {
        this.setState({
          alerts: data.alerts,
          pages: data.pages,
          alertsCount: data.alerts_count,
          group_id: params.group_id,
          year: params.year,
          page: params.page,
          years: data.years,
          alertGroups: data.alert_groups
        })
      },
      error: (data) => {
        this.props.snackbarShowMessage('There was an error fetching the alerts. Please try again.', "error")
      }
    })
  }

  handleChange(event, value) {
    let params = { group_id: this.state.group_id, year: this.state.year, page: this.state.page }

    params['page'] = value

    $.ajax({
      type: 'GET',
      url: `/sectors/${this.props.sector.abbr}/${this.props.entity_type}/${this.props.entity.id}/alerts`,
      data: params,
      dataType: 'json',
      success: (data) => {
        this.setState({
          alerts: data.alerts,
          alertsCount: data.alerts_count,
          group_id: params.group_id,
          year: params.year,
          page: params.page
        })
      },
      error: (data) => {
        this.props.snackbarShowMessage('There was an error fetching the alerts. Please try again.', "error")
      }
    })
  }

  render() {
    return(
      <Fragment>

        <Paper fullHeight>
          <Box height="100%">
            <EntityAlertFilters
              groupId={this.state.group_id}
              year={this.state.year}
              alert_groups={this.state.alertGroups}
              years={this.state.years}
              handleFilterChange={this.handleFilterChange}
            />

            {this.state.alerts.length > 0 ?
              <EntityAlertsDisplay
                alerts={this.state.alerts}
                entity={this.props.entity}
                sector={this.props.sector}
                page={this.state.page}
                pages={this.state.pages}
                alertsCount={this.state.alertsCount}
                handleChange={this.handleChange}
              />
            :
              <EmptyDataMessageContainer
                headline='No alerts for this abstract'
              />
            }

          </Box>
        </Paper>

      </Fragment>
    )
  }
}


export default withSnackbar(EntityAlertsContainer)
