import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Paper from "../../Paper"
import { Table, TableHead, TableRow, TableCell, TableBody, TableHeadSort } from "../../tables"
import KClearanceRow from "./KClearanceRow"
import Snackbar from "../../Snackbar"
import update from "immutability-helper"
import Flex from "../../Flex"
import Box from "../../Box"
import Typography from "@material-ui/core/Typography"

export default class FdaKClearances extends Component {
  constructor(props) {
    super(props)
    this.handleRequestSort = this.handleRequestSort.bind(this)
    this.desc = this.desc.bind(this)
    this.stableSort = this.stableSort.bind(this)
    this.getSorting = this.getSorting.bind(this)
    this.handleProductSwitch = this.handleProductSwitch.bind(this)
    this.state = { order: 'desc', orderBy: 'device_name' }
  }

  handleProductSwitch(kClearance) {
    $.ajax({
      type: 'POST',
      url: '/admin/products/create_from_k_clearance',
      data: {
        device_name: kClearance.device_name, applicant: kClearance.applicant, k_number: kClearance.k_number, k_clearance_id: kClearance.id
      },
      dataType: 'json',
      success: (data) => {

      }
    })
  }

  desc(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = cmp(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
  }

  getSorting(order, orderBy) {
    return order === 'desc' ? (a, b) => this.desc(a, b, orderBy) : (a, b) => -this.desc(a, b, orderBy);
  }

  handleRequestSort(event, property) {
    const isDesc = this.state.orderBy === property && this.state.order === 'desc';

    this.setState({
      order: isDesc ? 'asc' : 'desc',
      orderBy: property
    })
  };

  render() {
    let headers = [{ label: '510(k) #', name: 'k_number' }, { label: 'Company', name: 'applicant' }, { label: 'Device', name: 'device_name' }, { label: 'Product', name: '' }]
    return(
      <Fragment>

        <Paper fullHeight={true}>

          <Flex alignItems='center' mb={2}>
            <Box></Box>

            <Box ml='auto'>
              <Typography variant="body2">
                {`${this.props.k_clearances.length} FDA 510(k) clearances`}
              </Typography>
            </Box>
          </Flex>

          <Box overflow='auto' height="90%">
            <Table size='small' aria-label='k clearances table' stickyHeader={true}>

              <TableHeadSort
                headers={headers}
                order={this.state.order}
                orderBy={this.state.orderBy}
                onRequestSort={this.handleRequestSort}
              />

              <TableBody>
                {this.stableSort(this.props.k_clearances, this.getSorting(this.state.order, this.state.orderBy))
                  .map((kClearance) =>
                    <KClearanceRow
                      key={kClearance.id}
                      kClearance={kClearance}
                      products={this.props.products}
                    />
                )}
              </TableBody>

            </Table>
          </Box>
        </Paper>

      </Fragment>
    )
  }
}
