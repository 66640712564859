import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import ProfileForm from "./ProfileForm"
import Paper from "../../Paper"
import { withSnackbar } from "../../SnackbarContainer"
import update from "immutability-helper"


class EditContainer extends Component {
  constructor(props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
    this.handleSaveClick = this.handleSaveClick.bind(this)
    this.handleCheckboxSelect = this.handleCheckboxSelect.bind(this)
    this.state = { targetSite: this.props.target_site, targetSiteSectors: this.props.target_site_sectors, activeTab: 'New Target Site' }
  }

  componentDidMount() {
    let targetSite = update(this.state.targetSite, {
      updated_by: { $set: this.props.current_user.id }
    })

    this.setState({
      targetSite: targetSite
    })
  }

  handleTabClick(e) {
    e.preventDefault()
    this.setState({
      activeTab: e.target.text
    })
  }

  handleCheckboxSelect(sector, checked) {
    console.log(sector)
    console.log(checked)
    if (checked) {
      let targetSiteSector = this.state.targetSiteSectors.filter((targetSiteSector) => targetSiteSector.sector_id == sector.id)[0]
      $.ajax({
        type: 'PUT',
        url: `/admin/target_site_sectors/${targetSiteSector.id}`,
        data: {
          target_site_sector: {
            deleted: true, deleted_by: this.props.current_user.id
          }
        },
        dataType: 'json',
        success: (data) => {
          let index = this.state.targetSiteSectors.indexOf(targetSiteSector)
          let targetSiteSectors = update(this.state.targetSiteSectors, {
            $splice: [[index,1]]
          })

          this.setState({
            targetSiteSectors: targetSiteSectors
          })

          this.props.snackbarShowMessage("Sector was removed")
        },
        error: (data) => {
          this.props.snackbarShowMessage("Sector could not be removed", "error")
        }
      })
    } else {
      $.ajax({
        type: 'POST',
        url: `/admin/target_site_sectors`,
        data: {
          target_site_sector: {
            target_site_id: this.state.targetSite.id, sector_id: sector.id, created_by: this.props.current_user.id
          }
        },
        dataType: 'json',
        success: (data) => {
          let targetSiteSectors = update(this.state.targetSiteSectors, {
            $push: [data.target_site_sector]
          })

          this.setState({
            targetSiteSectors: targetSiteSectors
          })

          this.props.snackbarShowMessage("Sector was added")
        },
        error: (data) => {
          this.props.snackbarShowMessage("Sector could not be added", "error")
        }
      })
    }
  }

  handleChange(e) {
    const name = e.currentTarget.name
    const value = e.currentTarget.value

    let targetSite = update(this.state.targetSite, {
      [name]: { $set: value }
    })

    this.setState({
      targetSite: targetSite
    })

  }

  handleSaveClick(e) {
    const url = `/admin/target_sites/${this.state.targetSite.id}`
    $.ajax({
      type: 'PUT',
      url: url,
      data: {
        target_site: this.state.targetSite
      },
      dataType: 'json',
      success: (data) => {
        this.props.snackbarShowMessage('Target site was updated')
      },
      error: (data) => {
        this.props.snackbarShowMessage('Target site could not be updated. Please try again.')
      }
    })
  }

  render() {
    return(
      <Fragment>

        <Paper minHeight>

          <ProfileForm
            targetSite={this.state.targetSite}
            entitySectorIds={this.state.targetSiteSectors.map(targetSiteSector => targetSiteSector.sector_id)}
            sectors={this.props.sectors}
            handleChange={this.handleChange}
            handleSaveClick={this.handleSaveClick}
            handleCheckboxSelect={this.handleCheckboxSelect}
          />

        </Paper>

      </Fragment>
    )
  }
}


export default withSnackbar(EditContainer)
