import React from "react"
import PropTypes from "prop-types"
import Paper from "../../Paper"
import Box from "../../Box"
import Form from "./Form"
import DialogSourceForm from "../sources/DialogForm"
import update from "immutability-helper"
import { withSnackbar } from "../../SnackbarContainer"


class FormContainer extends React.Component {
  constructor(props) {
    super(props)
    this.handleDropdownChange = this.handleDropdownChange.bind(this)
    this.handleSaveClick = this.handleSaveClick.bind(this)
    this.handleDateChange = this.handleDateChange.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleChooseSourceClick = this.handleChooseSourceClick.bind(this)
    this.handleSourceChange = this.handleSourceChange.bind(this)
    this.handleSourceDeleteClick = this.handleSourceDeleteClick.bind(this)
    this.handleCompanyChange = this.handleCompanyChange.bind(this)
    this.handleProductChange = this.handleProductChange.bind(this)
    this.handleTrialChange = this.handleTrialChange.bind(this)
    this.handleSectorChange = this.handleSectorChange.bind(this)
    this.state = { alert: this.props.alert, source: this.props.source, alertLink: this.props.alert_link, openDialog: false, trials: this.props.trials, products: this.props.products, companies: this.props.companies }
  }

  handleSaveClick(e) {
    let alert = update(this.state.alert, {
      $merge: {
        alert_links_attributes: {
          '0': this.state.alertLink
        }
      }
    })

    $.ajax({
      type: this.props.save_type,
      url: this.props.alert_save_url,
      data: {
        alert: alert
      },
      dataType: 'json',
      success: (data) => {
        this.props.snackbarShowMessage('Alert was saved successfully');
        window.location.href = `/admin/alerts/${data.alert.id}/edit`
      },
      error: (data) => {
        this.props.snackbarShowMessage('There was a problem creating the alert. Please try again.', 'error');
      }
    })
  }

  handleCompanyChange(e, entityOption) {
    let alertLink = update(this.state.alertLink, {
      company_id: { $set: entityOption.value }
    })

    this.setState({
      alertLink: alertLink
    })
  }

  handleProductChange(e, entityOption) {
    let alertLink = update(this.state.alertLink, {
      product_id: { $set: entityOption.value }
    })

    this.setState({
      alertLink: alertLink
    })
  }

  handleTrialChange(e, entityOption) {
    let alertLink = update(this.state.alertLink, {
      trial_id: { $set: entityOption.value }
    })

    this.setState({
      alertLink: alertLink
    })
  }

  handleSourceDeleteClick(e) {
    let alert = update(this.state.alert, {
      source_id: { $set: null }
    })

    this.setState({
      alert: alert
    })
  }

  handleChooseSourceClick(e) {
    this.setState({
      openDialog: !this.state.openDialog
    })
  }

  handleSourceChange(entity, sourceType) {
    if (sourceType == 'recently_used') {
      this.setState({
        source: entity,
        openDialog: false
      })
    } else {
      $.get("/admin/sources/find_source", { source_type: sourceType, entity_id: entity.id }, (data) => {
        if (data.source) {
          let alert = update(this.state.alert, {
            source_id: { $set: data.source.source_id }
          })
          this.setState({
            source: data.source,
            alert: alert,
            openDialog: false
          })
        } else {
          $.post("/admin/sources/filter", { source: { kind: sourceType, entity_id: entity.id } }, (data) => {
            let alert = update(this.state.alert, {
              source_id: { $set: data.source.source_id }
            })
            this.setState({
              source: data.source,
              alert: alert,
              openDialog: false
            })
          })
        }
      }, 'json')
    }
  }

  handleChange(evt) {
    let text = evt.editor.getData()

    let alert = update(this.state.alert, {
      body: { $set: text }
    })

    this.setState({
      alert: alert
    })
  }

  handleDateChange(value, date) {
    let alert = update(this.state.alert, {
      date: { $set: value }
    })

    this.setState({
      alert: alert
    })
  }

  handleSectorChange(name, value) {
    $.ajax({
      type: 'GET',
      url: this.props.alert_url,
      data: {
        sector_id: value
      },
      dataType: 'json',
      success: (data) => {
        let alert = update(this.state.alert, {
          sector_id: { $set: value }
        })

        this.setState({
          companies: data.companies,
          products: data.products,
          trials: data.trials,
          alert: alert
        })
      }
    })
  }

  handleDropdownChange(name, value) {
    let alert = update(this.state.alert, {
      [name]: { $set: value }
    })

    this.setState({
      alert: alert
    })
  }

  render () {
    return (
      <React.Fragment>

        <Paper fullHeight>
          <DialogSourceForm
            source={this.state.source}
            source_id={this.state.alert.source_id}
            openDialog={this.state.openDialog}
            sources={this.props.sources}
            source_types={this.props.source_types}
            handleSourceChange={this.handleSourceChange}
            handleClose={this.handleClose}
            handleSourceDeleteClick={this.handleSourceDeleteClick}
            handleChooseSourceClick={this.handleChooseSourceClick}
          />

          <Form
            alert={this.state.alert}
            sectors={this.props.sectors}
            alert_groups={this.props.alert_groups}
            companies={this.props.companies}
            products={this.props.products}
            trials={this.props.trials}
            handleSectorChange={this.handleSectorChange}
            handleDropdownChange={this.handleDropdownChange}
            handleDateChange={this.handleDateChange}
            handleCompanyChange={this.handleCompanyChange}
            handleProductChange={this.handleProductChange}
            handleTrialChange={this.handleTrialChange}
            handleChange={this.handleChange}
            handleSaveClick={this.handleSaveClick}
          />
        </Paper>

      </React.Fragment>
    );
  }
}


export default withSnackbar(FormContainer)
