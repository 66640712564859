import React, { Component, Fragment } from "react"
import PropTypes from "prop-types"
import Select from "../Select"
import Box from "../Box"
import Grid from '@material-ui/core/Grid';
import Flex from "../Flex"

class Filters extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    const selectedYears = this.props.filterOptions.years.filter(year => this.props.filterValues.year_ids.includes(year.value) ? year : null)

    const selectedTargetSites = this.props.filterOptions.targetSites.filter(targetSite => this.props.filterValues.target_site_ids.includes(targetSite.value) ? targetSite : null)

    const selectedTargetMarkets = this.props.filterOptions.targetMarkets.filter(targetMarket => this.props.filterValues.target_market_ids.includes(targetMarket.value) ? targetMarket : null)

    const selectedTherapyTypes = this.props.filterOptions.therapyTypes.filter(therapyType => this.props.filterValues.therapy_type_ids.includes(therapyType.value) ? therapyType : null)
    return (
      <Grid container spacing={2} alignItems='stretch'>

        <Grid item xs lg={2}>
          <Box>
            <Select
              multiple
              label='Years'
              name='year_ids'
              value={this.props.filterValues.year_ids}
              entities={this.props.filterOptions.years}
              handleChange={this.props.handleFilterChange}
              includeBlank={true}
              renderValue={selected => (
                <Flex flexWrap='wrap'>
                  {`${selectedYears.length} chosen`}
                </Flex>
              )}
            />
          </Box>
        </Grid>

        {!this.props.sector.monitor_product ?
          <Fragment>
            <Grid item xs lg={2}>
              <Box>
                <Select
                  multiple
                  label='Target Sites'
                  name='target_site_ids'
                  value={this.props.filterValues.target_site_ids}
                  entities={this.props.filterOptions.targetSites}
                  handleChange={this.props.handleFilterChange}
                  includeBlank={true}
                  renderValue={selected => (
                    <Flex flexWrap='wrap'>
                      {`${selectedTargetSites.length} chosen`}
                    </Flex>
                  )}
                />
              </Box>
            </Grid>

            <Grid item xs lg={2}>
              <Box>
                <Select
                  multiple
                  label='Target Market'
                  name='target_market_ids'
                  value={this.props.filterValues.target_market_ids}
                  entities={this.props.filterOptions.targetMarkets}
                  handleChange={this.props.handleFilterChange}
                  includeBlank={true}
                  renderValue={selected => (
                    <Flex flexWrap='wrap'>
                      {`${selectedTargetMarkets.length} chosen`}
                    </Flex>
                  )}
                />
              </Box>
            </Grid>

            <Grid item xs lg={2}>
              <Box>
                <Select
                  multiple
                  label='Therapy'
                  name='therapy_type_ids'
                  value={this.props.filterValues.therapy_type_ids}
                  entities={this.props.filterOptions.therapyTypes}
                  handleChange={this.props.handleFilterChange}
                  includeBlank={true}
                  renderValue={selected => (
                    <Flex flexWrap='wrap'>
                      {`${selectedTherapyTypes.length} chosen`}
                    </Flex>
                  )}
                />
              </Box>
            </Grid>
          </Fragment>
        : ''}
      </Grid>
    )
  }
}

export default Filters
