import React from "react"
import ResultItemContainer from "./ResultItemContainer"
import Button from "../buttons/Button"
import Typography from "@material-ui/core/Typography"
import SearchIcon from '@material-ui/icons/Search'
import IconButton from '../IconButton';

export default function ProductItem(props) {

  let url = props.admin ? `/admin/products/${props.result.id}/edit` : `/sectors/${props.sector.abbr}/products/${props.result.id}`

  function renderCompany(companyName) {
    return (
      <Typography variant="body2" color="textSecondary">
        {companyName}
      </Typography>
    );
  }

  function renderSecondaryAction(url) {
    return(
      <IconButton edge="end" href={`${url}/search?query=${props.query}`} size='small' color='inherit' tooltip={`Search in ${props.result.short_name}`}>
        <SearchIcon />
      </IconButton>
    )
  }

  return(
    <ResultItemContainer 
      searchItemType={props.result.record_type}
      searchTitle={props.result.short_name}
      searchDescription={props.result.description}
      url={url}
      searchDetails={renderCompany(props.result.company_name)}
      secondaryAction={renderSecondaryAction(url)}
    />
  )
}