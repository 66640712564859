import React, { Component } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Paper from "../Paper"
import RaisedButton from "../buttons/RaisedButton"
import Link from "../Link"
import Footer from "../Footer"
import Box from "../Box"
import ProductStatusMockup from "./ProductStatusMockup"
import TrialCompletionDateMockup from "./TrialCompletionDateMockup"
import Typography from "@material-ui/core/Typography"
import PieChart from "../charts/PieChart"
import sourcesImage from "../../../assets/images/sources_image.png"

export default class Home extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <div className='w-100'>
        <div>
          <div className='w-90-m w-100 center z-5'>
            <div className='dtc-m dtc-l v-mid w-100 w-50-m w-50-l ph4'>
              <Box mb={3}>
                <Typography variant="h6">
                  <span className='orange'>PV</span>
                  <span className='black'>PIPELINE</span>
                </Typography>
              </Box>
              <Typography className='mv3 fw5'>
                The R&D Database of Peripheral Markets
              </Typography>
              <div className='pb3 pb4-m pb4-l'>
                <Typography className='mt0 fw5 lh-copy f4 measure pr5-l mb4'>
                  Seven inter-connected databases that organize the key dimensions of new medical technology development: companies, products, people, trials, meetings, patents, and publications
                  <ul>
                    <li>
                      Track product progress and compare against competitors
                    </li>
                    <li>
                      Timely updates on trials with the ability to compare against similar trials
                    </li>
                    <li>
                      Complete coverage from a multitude of sources
                    </li>
                    <li>
                      Daily alerts so you don't miss any market developments
                    </li>
                    <li>And much more...</li>
                  </ul>
                </Typography>
                <div className='flex-ns items-center'>
                  <div className='w-40 w-100-xs'>
                    <RaisedButton href="/signup" className='w-100' style={{height: '48px'}}>
                      Sign Up
                    </RaisedButton>
                  </div>
                  {/* <div className='mt3 ml3-m ml3-l mt0-ns flex-none'>
                    or
                    <Link className='ml1' handleClick={this.handleDemoClick}>Request a demo</Link>
                  </div> */}
                </div>
              </div>
            </div>
            <div className='dn dtc-m dtc-l v-mid pl4 pr3 w-45'>
              {/* <img src={physicianNetworkImage} alt="image"  /> */}
            </div>
          </div>
        </div>
        <div className='bg-lightest-gray dn-xs'>
          <div className='mh4'>
            <div className='tc pt4 nt2'>
              {/* f5 f3-m f3-l */}
              <Typography className='fw5 mt0 mb1'>
                From the team at cvPipeline, the leading R&D pipeline data provider to the industry for over 10 years.
              </Typography>
            </div>
            <div className='flex wrap items-baseline justify-between tc pt4'>
              {/* <span style={{width: '175px', height: '60px'}}><img src={logos.edwards} alt="company-logo" /></span> */}
              {/* <span style={{width: '175px', height: '60px'}}><img src={logos.abbott} alt="company-logo" /></span> */}
              {/* <span className='dib-m dn' style={{width: '175px', height: '60px'}}><img src={logos.bostonSci} alt="company-logo" /></span> */}
              {/* <span style={{width: '175px', height: '60px'}}><img src={logos.medtronic} alt="company-logo" /></span> */}
              {/* <span className='dib-m dn'><img src={logos.biotronik} alt="company-logo"  /></span> */}
            </div>
          </div>
        </div>
        <div className='w-100 bg-white'>
          <div className='w-90 center pv4'>
            <div className='mb5-m mb5-l mb3'>
              {/* f3 f2-m f2-l  */}
              <Typography className='fw5 mt0 mb1 tc'>
                Track the progress of products through the regulatory pipeline
              </Typography>

              <div className='tc'>
                <Typography className='lh-copy f4 mt0 tc'>
                  Our service can help you to not only keep track of product developments but also allow for rich comparisons between competitors
                </Typography>
              </div>
            </div>

            <div className='dtc-m dtc-l v-mid-m v-mid-l w-100 w-40-m w-40-l ph4 pv3 tl-m tl-l tc'>

              <div className='measure dib'>
                <Typography className='fw4 lh-copy f4'>
                  <span className='fw9 mmi-blue'>Regulatory status </span>
                  {/* - See a forecast of upcoming studies before they are announced, while you can still become a participating center. */}
                  - View the history of statuses in a timeline to better understand the trajectory of the product and compare statuses across products
                </Typography>
                <Typography className='fw4 lh-copy f4'>
                  <span className='fw9 mmi-blue'>Attribute status </span>
                  {/* - Our data can show you which investigators and centers the sponsor has worked with previously, and who has been involved as SAB members, conference presenters, or journal authors. */}
                  - Understand which attributes of a product are approved versus which are appearing in investigational studies
                </Typography>
                <Typography className='fw4 lh-copy f4'>
                  <span className='fw9 mmi-blue'>FDA </span>
                  {/* - Position your center's strengths and experiece for the product's feature that will be the focus of the study. */}
                  - We track PMA approvals, adverse events and recalls so you don't have to
                </Typography>
              </div>
            </div>

            {/* dn dtc-m dtc-l */}
            <div className='dtc-m dtc-l db dn-xs v-mid pl4 pr3 w-60-m w-60-l w-100'>
              <ProductStatusMockup products={this.props.products} />
            </div>

          </div>
        </div>
        <div className='w-100 bg-near-white'>
          <div className='w-90-m w-100 center pv4'>
            <div className='dtc-m dtc-l v-mid w-100 w-50-m w-40-l ph4 pv3'>

              <div className='mb5-m mb5-l mb3'>
                {/* f3 f2-m f2-l  */}
                <Typography className='fw5 mt0 mb3 tc'>
                  Trials
                </Typography>

                <div className='tc'>
                  <Typography className='measure lh-copy mt0 tc f4'>
                    Wether submitted to clinicaltrials.gov, presented at the latest meeting or issued through a press release, we keep track of trials data for you.
                  </Typography>
                </div>

              </div>
              {/* dn dtc-m dtc-l */}
              {/* dtc-m dtc-l db dn-xs v-mid pl4 pr3 w-60-m w-60-l w-100 */}
              <div className='dtc-m dtc-l db dn-xs v-mid pl4-ns pr3 w-60-m w-60-l w-100'>
                <TrialCompletionDateMockup
                  dateChartData={this.props.date_chart_data}
                />
              </div>

              <div className='dtc-m dtc-l v-mid w-100 w-40-m w-40-l ph4-m ph4-l ph2 pv3 tl-m tl-l tc'>
                <div className='measure dib'>
                  <Typography className='fw4 lh-copy f4'>
                    <span className='fw9 mmi-blue'>Metrics </span>
                    - Our metrics dashboard allows you to view, and compare, the most important data for a trial all in one place
                  </Typography>
                  <Typography className='fw4 lh-copy f4'>
                    <span className='fw9 mmi-blue'>Enrollment </span>
                    - View the history of enrollment to better understand the trajectory of the trial
                  </Typography>
                  <Typography className='fw4 lh-copy f4'>
                    <span className='fw9 mmi-blue'>Sites and investigators </span>
                    - Easily view the latest, and historical, data for where the trial is taking place and by whom
                  </Typography>
                </div>
              </div>

            </div>
          </div>
        </div>
        <div className='w-100 bg-white'>
          <div className='w-80-m w-100 center pv5'>

            <div className='mb4-m mb4-l mb3'>
              <Typography className='fw5 mt0 mb3 tc'>
                Coverage areas
              </Typography>

              <div className='tc'>
                <Typography className='lh-copy f4 mt0'>
                  We cover a range of peripheral therapies
                </Typography>
              </div>
            </div>

            <Box>
              <PieChart
                chartId='peripheral-coverage-area-chart'
                formattedData={[{ name: 'Coverage Areas', colorByPoint: true, data: this.props.coverage_areas_data }]}
                title=''
              />
            </Box>

          </div>
        </div>
        <div className='w-100 bg-near-white'>
          <div className='w-80-m w-100 center pv5 ph4'>

            <div className='mb4'>
              {/* f3 f2-m f2-l */}
              <Typography className='fw5 mt0 mb3 tc'>
                Daily Alerts
              </Typography>

              <div className='tc'>
                <div className='measure'>
                  <Typography className='lh-copy f4 mt0'>
                    You'll be notified of changes through our daily alert emails.
                  </Typography>
                </div>
              </div>
            </div>

            <div className='dn dtc-m dtc-l v-mid pl4 pr3 w-45'>
              <Link>
                {/* <img src={surveyImage} alt="image" width={"413"} height={"349"}  /> */}
              </Link>
            </div>
          </div>
        </div>

        <div className='w-100 bg-white'>
          <div className='w-90-m w-100 center pv5 ph4'>

            <div className='mb4'>
              {/* f3 f2-m f2-l */}
              <Typography className='fw5 mt0 mb3 tc'>
                Integrating thousands of different sources of information into one uniform, intuitive system
              </Typography>
            </div>

            <div className='w-100 ph4 pb3 tc'>
              <img src={sourcesImage} alt='sources' />
            </div>
          </div>
        </div>


        {/* <div className='w-100 bg-white pb5'>
          <div className='w-90 center pt5 pb3'>
            <div className='dtc-m dtc-l v-mid w-100 w-50-m w-50-l ph4 pv3'>
              <Headline3 className='f3 f2-m f2-l fw5 mv0 mb3-m mb3-l'>
                CvPipeline SAB
              </Headline3>
              <div className='measure-wide'>
                <Body1 className='fw4 lh-copy f5'>
                  Our SAB include some of the top physicians in the cardiovascular space
                </Body1>
              </div>
            </div>
          </div>
          <div>
            <Testimonial image={physicians.serruys} text='CvPipeline is an excellent service that I use a lot. Some other great testimonial sentences from Dr. Serruys' personName='Patrick Serruys' personJob='Physician' />

            <Testimonial image={physicians.virmani} text='CvPipeline is an excellent service that I use a lot. Some other great testimonial sentences from Dr. Virmani' personName='Renu Virmani' personJob='Physician' />

            <Testimonial image={physicians.piazza} text='CvPipeline is an excellent service that I use a lot. Some other great testimonial sentences from Dr. Piazza' personName='Nicolo Piazza' personJob='Physician' />
          </div>
        </div> */}
        <footer className='bt b--black-40'>
          <Footer year={this.props.year} />
        </footer>
      </div>
    )
  }
}
