import React, { Fragment } from "react"
import Flex from "../Flex"
import Box from "../Box"
import Typography from "@material-ui/core/Typography"
import ResultItemContainer from "../search/ResultItemContainer"


export default function SearchItem(props) {
  
  function renderSearchDetails(result) {
    return (
      <Flex alignItems='baseline'>
        <Box mr={2}>
          <Typography variant="body2">
            {`${result.country}${result.xml_patent_no}${result.kind}`}
          </Typography>
        </Box>
        <Box>
          <Typography variant="body2">
            {result.company_name}
          </Typography>
        </Box>
      </Flex>
    );
  }

  return(
    <ResultItemContainer 
      searchItemType={props.result.record_type}
      searchTitle={props.result.title}
      date={props.result.effective_date_str}
      searchDescription={props.result.abstract}
      url={`/sectors/${props.sector.abbr}/patents/${props.result.patent_id}`}
      target="_blank"
      searchDetails={renderSearchDetails(props.result)}
    />
  )
}
