import React, { Fragment } from "react"
import { TableRow, TableCell } from "../../../tables"
import Link from "../../../Link"


export default function SearchResultRow(props) {
  let patent = props.patent.length ? props.patent[0] : props.patent

  console.log(props.patent)
  return(
    <TableRow>
      <TableCell className="w-10">
        <Link href={`/admin/patents/${patent.id}/associator`} target="_blank">
          {patent.html_patent_no}
        </Link>
      </TableCell>
      <TableCell className="w-25">
        <Link href={`/admin/patents/${patent.id}/associator`} target="_blank">
          {patent.title}
        </Link>
      </TableCell>
      <TableCell>
        {patent.applicants}
      </TableCell>
      <TableCell>
        {patent.assignee}
      </TableCell>
      <TableCell>
        {patent.company_name}
      </TableCell>
      <TableCell>
        {typeof(props.patent) == 'array' ? patent[1]._score : "N/A"}
      </TableCell>
    </TableRow>
  )
}
