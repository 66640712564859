import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import update from "immutability-helper"
import StatusGroupDisplay from "./StatusGroupDisplay"

class StatusDisplayContainer extends Component {
  constructor(props) {
    super(props)
  }

  render() {

    return(
      <Fragment>
        <div>

          <StatusGroupDisplay
            statuses={this.props.productRegulatoryStatuses}
            header='All'
            handleDeleteClick={this.props.handleDeleteClick}
            handleActiveClick={this.props.handleActiveClick}
            handleSectorDelete={this.props.handleSectorDelete}
            open={true}
          />

        </div>
      </Fragment>
    )
  }
}

export default StatusDisplayContainer
