import React from "react"
import { TableRow, TableCell } from "../../tables"
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

export default function AlertGroupRow(props) {
  
  function handleClick(e) {
    props.handleDeleteClick(props.alertGroup)
  }
  
  const user = props.alertGroup.creator
  return (
    <TableRow>
      <TableCell>
        {props.alertGroup.name}
      </TableCell>
      <TableCell>
        {user ? displayInitials(user.first_name, user.last_name) : 'N/A'}
      </TableCell>
      <TableCell>
        <IconButton component="a" href={`/admin/alert_groups/${props.alertGroup.id}/edit`}>
          <EditIcon />
        </IconButton>
      </TableCell>
      <TableCell>
        <IconButton>
          <DeleteIcon edge="end" onClick={handleClick} />
        </IconButton>
      </TableCell>
    </TableRow>
  )
}
