import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import { TableRow, TableCell } from "../../tables"
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';

class TrialSponsorRow extends Component {
  constructor(props) {
    super(props)
    this.handleDeleteClick = this.handleDeleteClick.bind(this)
  }

  handleDeleteClick(e) {
    this.props.handleDeleteClick(this.props.trialSponsorHash)
  }

  render() {
    const user = this.props.trialSponsorHash.creator
    return(
      <TableRow>

        <TableCell>
          {this.props.trialSponsorHash.company ? this.props.trialSponsorHash.company.full_name : this.props.trialSponsorHash.sponsor.name}
        </TableCell>

        <TableCell>
          {this.props.trialSponsorHash.source_object ? this.props.trialSponsorHash.source_object.source_title : "N/A"}
        </TableCell>

        <TableCell>
          {user ? displayInitials(user.first_name, user.last_name) : 'N/A'}
        </TableCell>

        <TableCell>
          <IconButton onClick={this.handleDeleteClick}>
            <DeleteIcon />
          </IconButton>
        </TableCell>

      </TableRow>
    )
  }
}

export default TrialSponsorRow
