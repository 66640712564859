import React, { Fragment } from "react"
import PropTypes from "prop-types"
import { Table, TableHead, TableRow, TableCell, TableBody } from "../../../tables"
import DeleteButton from "../../DeleteButton"

export default function AliasesTable(props) {

  return(
    <Table stickyHeader={true} size="small">
      <TableHead>
        <TableRow>
          <TableCell>Name</TableCell>
          <TableCell></TableCell>
        </TableRow>
      </TableHead>

      <TableBody>
        {props.companyAliases.map((companyAlias) =>
          <TableRow key={companyAlias.id}>
            <TableCell>
              {companyAlias.name}
            </TableCell>
            <TableCell>
              <DeleteButton onClick={() => { props.handleDeleteClick(companyAlias) }} />
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  )
}
