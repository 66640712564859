import React, { Fragment } from "react"
import Paper from "../../Paper"
import List from "../../lists/List";
import TrialListItem from "./TrialListItem"
import ListSubheader from '@material-ui/core/ListSubheader';

export default function TrialsList(props) {
  return (
    <Fragment>

      {props.abstractTrials.length > 0 ? <List>
        <ListSubheader>Trials</ListSubheader>
        {props.abstractTrials.map((abstractTrial) =>
          <TrialListItem
            key={abstractTrial.id}
            abstractTrial={abstractTrial}
            trial={abstractTrial.trial}
            forSelection={false}
            handleTrialUnassociateClick={props.handleTrialUnassociateClick}
          />
        )}
      </List> : ''}

    </Fragment>
  )
}
