import React from "react"
import ResultItemContainer from "./ResultItemContainer"
import Typography from "@material-ui/core/Typography"

export default function PublicationItem(props) {

  let url = props.admin ? `/admin/publications/${props.result.id}` : `/sectors/${props.sector.abbr}/publications/${props.result.manuscript_id}`

  function renderSearchDetails(result) {
    return (
      <Typography variant="body2" color="textSecondary">
        {result.journal_name}
      </Typography>
    );
  }

  return(
    <ResultItemContainer 
      searchItemType={props.result.record_type}
      searchTitle={props.result.title}
      date={props.result.published_date}
      searchDescription={props.result.text}
      url={url}
      searchDetails={renderSearchDetails(props.result)}
    />
  )
}