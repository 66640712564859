import React from "react"
import { Table, TableRow, TableCell, TableHead, TableBody } from "../../tables"
import Link from "../../Link"


export default function NewTrialsModule(props) {
  return(
    <Table>
      <TableBody>
        {props.newTrials.map((trial) =>
          <TableRow key={trial.trial_id}>
            <TableCell>
              <Link href={`/admin/trials/${trial.trial_id}/edit`}>
                {trial.acronym || trial.short_name || trial.full_name}
              </Link>
            </TableCell>
            <TableCell>{humanDateFormat(trial.created_at)}</TableCell>
            <TableCell>{trial.user_id == 1 ? 'SYS' : displayInitials(trial.first_name, trial.last_name)}</TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  )
}
