import React from "react"
import MuiExpandMore from '@material-ui/icons/ExpandMore';

export default function ExpandMore(props) {
  return (
    <MuiExpandMore 
      color={props.color || 'action'} 
      {...props} 
    />
  )
}
