import React from "react"
import FdaPmaApprovalItem from "../fda_k_clearances/DisplayItem"

export default function RegulatoryItem(props) {

  return(
    <FdaPmaApprovalItem
      url={`/sectors/${props.sector.abbr}/fda_k_clearances/${props.result.id}`}
      {...props}
    />
  )
}