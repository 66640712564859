import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import TextInput from "../../TextInput"
import { Button, RaisedButton } from "../../buttons"
import Typography from '@material-ui/core/Typography';
import Flex from "../../Flex"
import Box from "../../Box"
import { List, ListItem, ListItemText } from "../../lists"
import Checkbox from "../../Checkbox"

export default class SearchTermForm extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    return(
      <Fragment>

        <Flex alignItems='center'>
          <Box width={1}>
            <Box>
              <Box width={3/4} pr={2}>
                <TextInput
                  name='search_term'
                  label='Search term'
                  multiline={this.props.multiline}
                  value={this.props.searchTerm ? this.props.searchTerm.search_term : ""}
                  onChange={this.props.handleTermChange}
                  fullWidth
                />
              </Box>

              <Box>
                <Flex alignItems='center'>
                  {this.props.searchTerm && this.props.searchTerm.caseSensitive ?
                    <Box>
                      <Checkbox
                        name='case_sensitive'
                        label='Case sensitive'
                        checked={this.props.searchTerm.caseSensitive}
                        onClick={this.props.handleCheckboxClick}
                      />
                    </Box>
                  : ''}

                  {this.props.searchTerm && this.props.searchTerm.root_name ?
                    <Box>
                      <Checkbox
                        name='root_name'
                        label='Root name'
                        checked={this.props.searchTerm.root_name}
                        onClick={this.props.handleCheckboxClick}
                      />
                    </Box>
                  : ''}
                </Flex>
              </Box>

              <Flex mt={3} width={1/2}>
                <Box mr={3} width={1/2}>
                  <RaisedButton onClick={this.props.handleSaveClick}>
                    Save
                  </RaisedButton>
                </Box>

                <Box ml={3} width={1/2}>
                  <RaisedButton onClick={this.props.handleTestClick} color='default'>
                    Test
                  </RaisedButton>
                </Box>

              </Flex>
            </Box>
          </Box>
        </Flex>

      </Fragment>
    )
  }
}
