import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Paper from "../../Paper"
import Flex from "../../Flex"
import Box from "../../Box"
import TextInput from "../../TextInput"
import ButtonLayout from "../ButtonLayout"
import { RaisedButton } from "../../buttons"
import update from "immutability-helper"
import Snackbar from "../../Snackbar"

export default class EditContainer extends Component {
  constructor(props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
    this.handleSaveClick = this.handleSaveClick.bind(this)
    this.state = { internalDocument: this.props.internal_document, showSnackbar: false, snackbarText: '' }
  }
  
  handleChange(e) {
    const name = e.currentTarget.name
    const value = e.currentTarget.value
    const internalDocument = update(this.state.internalDocument, { [name]: { $set: value } })
    
    this.setState({
      internalDocument: internalDocument
    })
  }
  
  handleSaveClick(e) {
    $.ajax({
      type: 'PUT',
      url: `/admin/internal_documents/${this.state.internalDocument.id}`,
      data: {
        internal_document: this.state.internalDocument
      },
      error: (data) => {
        this.setState({
          showSnackbar: true,
          snackbarText: `The internal document could not be updated. ${data.responseText}`
        })
      },
      complete: (data) => {
        setTimeout(() => {
          this.setState({
            showSnackbar: false,
            snackbarText: ''
          })
        }, 4000)
      }
    })
  }
  
  render() {
    return(
      <Fragment>
        
        <Paper>
          
          <Box width={1} my={3}>
            <TextInput 
              name='name' 
              label='Document' 
              value={this.state.internalDocument.name} 
              onChange={this.handleChange} 
              fullWidth
            />
          </Box>
          
          <ButtonLayout>
            <RaisedButton handleClick={this.handleSaveClick}>
              Save Internal Document
            </RaisedButton>
          </ButtonLayout>
          
          
        </Paper>
        
        <Snackbar 
          open={this.state.showSnackbar}
          message={this.state.snackbarText} 
        />
        
      </Fragment>
    )
  }
}
