import React, { Fragment } from "react"
import PropTypes from "prop-types"
import { TableHead, TableRow } from "."
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TableCellSort from "./TableCellSort"


export default function TableHeadSort(props) {

  const { order, orderBy, onRequestSort, headers, ...rest } = props;

  return(
    <TableHead>
      <TableRow hover={false}>
        {headers.map((header) =>
          <Fragment>
            <TableCellSort
              key={header.name}
              attribute={header}
              order={order}
              orderBy={orderBy}
              onRequestSort={onRequestSort}
              buttonComponent={header.buttonComponent}
              {...rest}
            />
          </Fragment>
        )}
      </TableRow>
    </TableHead>
  )
}
