import React, { Component, Fragment } from "react"
import Paper from "../Paper"
import Flex from "../Flex"
import Box from "../Box"
import FiltersContainer from "./FiltersContainer"
import Tweets from "./Tweets"
import Pagination from '@material-ui/lab/Pagination';
import { withRouter } from "react-router-dom";
import Typography from "@material-ui/core/Typography"
import { Button } from "../buttons"
import Dialog from "../Dialog"
import AlertOptionForm from "./AlertOptionForm"
import { withSnackbar } from "../SnackbarContainer"

class TweetsContainer extends Component {
  constructor(props) {
    super(props)
    this.handleFilterChange = this.handleFilterChange.bind(this)
    this.handleSearchClick = this.handleSearchClick.bind(this)
    this.handleClearSearchClick = this.handleClearSearchClick.bind(this)
    this.handlePageChange = this.handlePageChange.bind(this)
    this.handleSaveSearchClick = this.handleSaveSearchClick.bind(this)
    this.handleCloseDialog = this.handleCloseDialog.bind(this)
    this.handleAddClick = this.handleAddClick.bind(this)
    this.state = { tweets: this.props.tweets, tweetCount: this.props.tweet_count, userTwitterSearchTerm: null, filterValues: { query: this.props.query, page: this.props.page }, loading: false, weeklyAlertCount: null, openDialog: false }
  }

  handleAddClick(e) {
    $.ajax({
      type: "PUT",
      url: `/sectors/${this.props.sector.abbr}/user_twitter_search_terms/${this.state.userTwitterSearchTerm.id}`,
      data: {
        user_twitter_search_term: {
          alertable: true
        }
      },
      dataType: 'json',
      success: (data) => {
        this.props.snackbarShowMessage("The search term has been made alertable. You can turn this off at any time by going to the settings page.")

        this.setState({
          userTwitterSearchTerm: null,
          openDialog: false
        })
      },
      error: (data) => {
        this.props.snackbarShowMessage("The search term could not be made alertable. Please try again.", "error")
      }
    })
  }

  handleCloseDialog(e) {
    this.setState({
      openDialog: false,
      weeklyAlertCount: null
    })
  }

  handleSaveSearchClick(e) {
    let weeklyAlertCount = Math.round(this.state.tweetCount / 52)

    $.ajax({
      type: "POST",
      url: `/sectors/${this.props.sector.abbr}/user_twitter_search_terms`,
      data: {
        user_twitter_search_term: {
          user_id: this.props.user.id, search_term: this.state.filterValues.query
        }
      },
      dataType: 'json',
      success: (data) => {
        this.setState({
          userTwitterSearchTerm: data.user_twitter_search_term,
          openDialog: true,
          weeklyAlertCount: weeklyAlertCount
        })
      }
    })
  }

  handlePageChange(e, newPage) {
    let params = Object.assign({}, this.state.filterValues)

    params["page"] = newPage

    this.getTweets(params)
  }

  handleClearSearchClick(e) {
    let params = Object.assign({}, this.state.filterValues)

    params["query"] = null

    this.getTweets(params)
  }

  handleSearchClick(query) {
    let params = Object.assign({}, this.state.filterValues)

    params["query"] = query

    this.getTweets(params)
  }

  handleFilterChange(name, value) {
    let params = Object.assign({}, this.state.filterValues)

    params[name] = value

    this.getTweets(params)
  }

  getTweets(params) {
    $.ajax({
      type: "GET",
      url: this.props.url,
      data: params,
      dataType: 'json',
      success: (data) => {

        let filterKeys = Object.keys(params)

        let queryParams = filterKeys.map((filterKey) =>
          (typeof(params[filterKey]) == "string" || params[filterKey] == null) ? (params[filterKey] ? `${filterKey}=${params[filterKey]}` : null) : (params[filterKey].length > 0 ? params[filterKey].map((filterValue) => `${filterKey}[]=${filterValue}`) : null)
        ).filter(param => param)

        let flattenedQueryParams = [].concat.apply([], queryParams);
        // this.props.match.path
        let encodedUrl = `${this.props.url}?${encodeURI(flattenedQueryParams.join("&"))}`.replace("#", '%23');

        this.props.history.push(encodedUrl);

        this.setState({
          tweets: data.tweets,
          tweetCount: data.tweet_count,
          filterValues: params,
          loading: false
        })
      }
    })
  }

  render() {
    let numPages = Math.round(this.state.tweetCount / 100)

    let pages = numPages == 0 ? 1 : numPages
    return(
      <Paper fullHeight>

        {this.state.openDialog ?
          <Dialog
            openDialog={this.state.openDialog}
            dialogTitle='Make Alertable'
            handleAddClick={this.handleAddClick}
            handleClose={this.handleCloseDialog}
            withButtons
            confirmText="Confirm"
            dialogContent={
              <AlertOptionForm
                searchTerm={this.state.filterValues.query}
                weeklyAlertCount={this.state.weeklyAlertCount}
              />
            }
          />
        : ''}

        <Box height="100%">
          <Box>
            <FiltersContainer
              query={this.state.filterValues.query}
              handleSearchClick={this.handleSearchClick}
              handleClearSearchClick={this.handleClearSearchClick}
            />
          </Box>

          <Flex alignItems='center' justifyContent='right'>
            <Box>
              <Typography variant="body2">
                {`${this.state.tweetCount} tweets`}
              </Typography>
            </Box>

            {/* {this.state.filterValues.query ?
              <Box>
                <Button onClick={this.handleSaveSearchClick}>
                  Save Search
                </Button>
              </Box>
            : ""} */}
          </Flex>

          {this.state.tweets.length > 0 ?
            <Fragment>
              <Box height="85%" overflow="auto">
                <Tweets
                  tweets={this.state.tweets}
                />
              </Box>

              <Flex mt={2} justifyContent='center'>
                <Pagination
                  page={this.state.filterValues.page}
                  count={pages}
                  onChange={this.handlePageChange}
                  showFirstButton
                  showLastButton
                />
              </Flex>
            </Fragment>
          :
            <Flex justifyContent='center' height="50%">
              <Box alignSelf='center' maxWidth="50%">
                <Box>
                  <Typography variant="body1">
                    No tweets found for this product
                  </Typography>
                </Box>
              </Box>
            </Flex>
          }


        </Box>
      </Paper>
    )
  }
}

export default withRouter(withSnackbar(TweetsContainer))
