import React, { Component, Fragment } from "react"
import ControlPanel from "../associator/ControlPanel"
import EntityPanel from "./EntityPanel"
import Snackbar from "../../Snackbar"
import update from "immutability-helper"
import Associator from "../Associator"
import EntitiesPanel from "../associator/EntitiesPanel"
import CompaniesList from "../companies/associator/EntitiesList"
import AssociatedList from "../associator/AssociatedList"


export default class AssociatorContainer extends Component {
  constructor(props) {
    super(props)
    this.handleAssociateClick = this.handleAssociateClick.bind(this)
    this.handleUnassociateClick = this.handleUnassociateClick.bind(this)
    this.handleCheckboxSelect = this.handleCheckboxSelect.bind(this)
    this.handleEntityIndexUpdate = this.handleEntityIndexUpdate.bind(this)
    this.handleCompanySearchChange = this.handleCompanySearchChange.bind(this)
    this.handleCompanySearchClick = this.handleCompanySearchClick.bind(this)
    this.state = { sbirGrantCompanies: this.props.sbir_grant_companies, activeCompanies: [], entityIndex: 'companies', searchedCompanies: [], companyQuery: '', disableAssociate: true }
  }

  handleCompanySearchChange(e) {
    this.setState({
      companyQuery: e.target.value
    })
  }

  handleCompanySearchClick(e) {
    $.ajax({
      type: 'GET',
      url: '/admin/companies/search',
      data: {
        query: this.state.companyQuery
      },
      dataType: 'json',
      success: (data) => {
        this.setState({
          searchedCompanies: data.companies
        })
      }
    })
  }

  handleEntityIndexUpdate(e, newValue) {
    this.setState({
      entityIndex: newValue
    })
  }

  handleCheckboxSelect(companyId, stateType) {

    this.setState({
      [stateType]: [companyId],
      disableAssociate: false
    })
  }

  handleUnassociateClick(e) {
    $.ajax({
      type: 'PUT',
      url: `/admin/sbir_grant_companies/${this.state.activeCompanies[0]}`,
      data: {
        sbir_grant_company: {
          deleted: true, deleted_by: this.props.user.id
        }
      },
      dataType: 'json',
      success: (data) => {
        this.setState({
          activeCompanies: [],
          showSnackbar: true,
          snackbarText: "Company was unassociated"
        })
      },
      error: (data) => {
        this.setState({
          showSnackbar: false,
          snackbarText: 'Company could not be unassociated. Please try again'
        })
      },
      complete: (data) => {
        setTimeout(() => {
          this.setState({
            showSnackbar: false,
            snackbarText: ''
          })
        })
      }
    })
  }

  handleAssociateClick(e) {
    $.ajax({
      type: 'POST',
      url: '/admin/sbir_grant_companies',
      data: {
        sbir_grant_company: {
          sbir_grant_id: this.props.sbir_grant.id, company_id: this.state.activeCompanies[0], created_by: this.props.user.id
        }
      },
      dataType: 'json',
      success: (data) => {
        this.setState({
          sbirGrantCompany: data.sbir_grant_company,
          sbir_grant_companies: data.sbir_grant_companies,
          showSnackbar: true,
          snackbarText: 'Company was associated'
        })
      },
      error: (data) => {
        this.setState({
          showSnackbar: false,
          snackbarText: 'Company could not be associated'
        })
      },
      complete: (data) => {
        setTimeout(() => {
          this.setState({
            showSnackbar: false,
            snackbarText: ''
          })
        })
      }
    })
  }

  render() {
    return(
      <Fragment>

        <Associator
          loading={this.state.loading}
          firstPanel={
            <EntityPanel
              sbirGrant={this.props.sbir_grant}
            />

          }
          secondPanel={
            <Fragment>
              <ControlPanel
                disableAssociate={this.state.disableAssociate}
                handleAssociateClick={this.handleAssociateClick}
              />
            </Fragment>
          }
          thirdPanel={
            <EntitiesPanel
              activeIndex={this.state.entityIndex}
              handleActiveIndexUpdate={this.handleEntityIndexUpdate}
              companies={
                <CompaniesList 
                  activeCompanies={this.state.activeCompanies}
                  companies={this.props.companies}
                  entityCompanies={this.state.sbirGrantCompanies}
                  searchedCompanies={this.state.searchedCompanies}
                  query={this.state.companyQuery}
                  handleSearchChange={this.handleCompanySearchChange}
                  handleSearchClick={this.handleCompanySearchClick}
                  handleCheckboxSelect={this.handleCheckboxSelect}
                />
              }
              associated={
                <AssociatedList
                  entityCompanies={this.state.sbirGrantCompanies}
                  handleCompanyDeleteClick={this.handleUnassociateClick}
                />
              }
            />
          }
          associationLoading={this.state.associationLoading}
        />

        <Snackbar
          open={this.state.showSnackbar}
          message={this.state.snackbarText}
        />
      </Fragment>
    )
  }
}
