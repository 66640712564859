import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Paper from "../Paper"
import Flex from "../Flex"
import Box from "../Box"
import Typography from "@material-ui/core/Typography"
import Link from "../Link"
import IconButton from "../IconButton"
import PublicIcon from '@material-ui/icons/Public';
import ListIcon from '@material-ui/icons/List';
import BarChartIcon from '@material-ui/icons/BarChart';
import SitesDataTable from "./SitesDataTable"
import Sites from "./Sites"
import Select from "../Select"
import FilterChip from '../filters/FilterChip';
import update from 'immutability-helper'
import Switch from "../Switch"
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Snackbar from "../Snackbar"
import SitesListContainer from "./SitesListContainer"
import SitesChartContainer from "./SitesChartContainer"


export default class SitesContainer extends Component {
  constructor(props) {
    super(props)
    this.handleListViewClick = this.handleListViewClick.bind(this)
    this.handleChartViewClick = this.handleChartViewClick.bind(this)
    this.handleFilterChange = this.handleFilterChange.bind(this)
    this.getSites = this.getSites.bind(this)
    this.handleDelete = this.handleDelete.bind(this)
    this.handleStatusChange = this.handleStatusChange.bind(this)
    this.state = { view: 'list', sites: this.props.sites, currentSites: this.props.current_sites, countries: this.props.countries, country_ids: [], show_current: this.props.show_current, showSnackbar: false, snackbarText: '' }
  }

  handleDelete(value, type) {
    const index = this.state.country_ids.indexOf(value)

    const countryIds = update(this.state.country_ids, {
      $splice: [[index, 1]]
    })

    let params = { status: this.state.status }
    params["country_ids"] = countryIds

    this.getSites(params)
  }

  handleStatusChange(e) {
    // let params = {}
    // params["show_removed"] = e.target.checked
    // params["country_ids"] = this.state.country_ids

    // this.getSites(params)
    console.log(e.target.checked)
    this.setState({
      show_current: e.target.checked
    })
  }

  handleFilterChange(name, value) {
    let params = { show_current: this.state.show_current }
    if (name == 'country_ids') {
      params["country_ids"] = value
    }

    this.getSites(params)
  }

  getSites(params) {
    $.ajax({
      type: "GET",
      url: `/sectors/${this.props.sector.abbr}/trials/${this.props.trial.id}/sites`,
      data: params,
      dataType: 'json',
      success: (data) => {
        console.log(params)
        this.setState({
          sites: data.sites,
          currentSites: data.current_sites,
          country_ids: params.country_ids
        })
      },
      error: (data) => {
        this.setState({
          showSnackbar: true,
          snackbarText: 'There was an error. Please try again.'
        })
      },
      complete: (data) => {
        setTimeout(() => {
          this.setState({
            showSnackbar: false,
            snackbarText: ''
          })
        }, 4000)
      }
    })
  }

  handleListViewClick(e) {
    this.setState({
      view: 'list'
    })
  }

  handleChartViewClick(e) {
    this.setState({
      view: 'chart'
    })
  }

  render() {
    const selectedCountries = this.state.countries.filter(country => this.state.country_ids.includes(country.value) ? country : null)

    let countryValues = this.state.countries.map(country => country.value)
    const chosenCountries = this.state.countries.filter(country => this.state.country_ids.includes(country.value) ? country : null)
    return(
      <Fragment>
        <Paper fullHeight={this.state.view == 'list' ? true : false}>

          <Flex alignItems='center' mb={3}>
            {this.state.view == 'list' ? <Box width="13%" mr={2}>
              <Select
                multiple
                label='Countries'
                name='country_ids'
                value={this.state.country_ids}
                entities={this.state.countries}
                handleChange={this.handleFilterChange}
                renderValue={selected => (
                  <Flex flexWrap='wrap'>
                    {`${selectedCountries.length} chosen`}
                  </Flex>
                )}
              />
            </Box> : ''}

            {this.state.view == 'list' ? <Box ml={3}>
              <FormControlLabel
                control={
                  <Switch
                    checked={this.state.show_current}
                    handleChange={this.handleStatusChange}
                  />
                }
                label="Show current sites only"
              />
            </Box> : ''}

            <Flex ml='auto' alignItems='center'>
              <Box>
                <IconButton onClick={this.handleListViewClick} color={this.state.view == 'list' ? 'primary' : 'default'}>
                  <ListIcon />
                </IconButton>
              </Box>

              <Box>
                <IconButton onClick={this.handleChartViewClick} color={this.state.view == 'chart' ? 'primary' : 'default'} tooltip='Chart'>
                  <BarChartIcon />
                </IconButton>
              </Box>
            </Flex>
          </Flex>

          <Box height="100%">

            <Flex my={2} minHeight='28px' alignItems='center' flexWrap='wrap'>
              {chosenCountries.map((activeFilter) =>
                <FilterChip
                  key={activeFilter.value}
                  name={activeFilter.name}
                  value={activeFilter.value}
                  className={countryValues.includes(activeFilter.value) ? '' : 'o-60'}
                  type='country_ids'
                  handleDelete={this.handleDelete}
                />
              )}

              {this.state.view == 'list' ? <Box ml='auto'>
                <Typography variant="body2">
                  {`${(this.state.show_current ? this.state.currentSites : this.state.sites).length} sites`}
                </Typography>
              </Box> : ''}
            </Flex>

            <Box height="100%">
              {this.state.view == 'list' ?
                <SitesListContainer
                  sites={this.state.sites}
                  currentSites={this.state.currentSites}
                  showCurrent={this.state.show_current}
                />
              :
                <SitesChartContainer
                  sitesChartData={this.props.sites_chart_data}
                  sites={this.state.sites}
                  currentSites={this.state.currentSites}
                  enrollmentStartDate={this.props.enrollment_start_date}
                  enrollmentStartDateForChart={this.props.enrollment_start_date_for_chart}
                  enrollmentEndDateForChart={this.props.enrollment_end_date_for_chart}
                  enrollmentEndDate={this.props.enrollment_end_date}
                  showEnrollmentDates={true}
                  showRemoved={this.state.show_removed}
                />
              }
            </Box>
          </Box>

        </Paper>

        <Snackbar
          open={this.state.showSnackbar}
          message={this.state.snackbarText}
        />
      </Fragment>
    )
  }
}
