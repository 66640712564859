import React, { Component, Fragment } from "react"
import Select from "../../Select"
import TextInput from "../../TextInput"
import ButtonLayout from "../ButtonLayout"
import { RaisedButton } from "../../buttons"
import Flex from "../../Flex"
import Box from '../../Box';
import Radio from '@material-ui/core/Radio';
import RadioGroup from "../../RadioGroup"
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

export default class AssociatorFilterPanel extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    return(
      <Fragment>

        <Flex alignItems='center'>

          <Box mr={3}>
            <Select
              value={this.props.filterValues.sector_id}
              name='sector_id'
              label='Sector'
              entities={this.props.sectors}
              includeBlank
              handleChange={this.props.handleSectorChange}
            />
          </Box>

          {/* <Box mr={3}>
            <Select
              value={this.props.filterValues.relevant}
              name='relevant'
              label='Relevant'
              entities={this.props.filterOptions.relevantOptions}
              handleChange={this.props.handleDropdownChange}
            />
          </Box> */}

          {/* <Box mr={3}>
            <Select
              value={this.props.filterValues.priority_id}
              name='priority_id'
              label='Priority'
              entities={this.props.priorities}
              displayEmpty={false}
              handleChange={this.props.handleDropdownChange}
            />
          </Box> */}

          {/* <Box mr={3}>
            <FormControl component="fieldset">
              <FormLabel component="legend">From</FormLabel>
              <RadioGroup aria-label="days-since" name="daysSince" value={parseInt(this.props.filterValues.timeSince)} onChange={this.props.handleDaysChange}>
                <FormControlLabel value={7} control={<Radio />} label="1 Week" />
                <FormControlLabel value={14} control={<Radio />} label="2 Weeks" />
                <FormControlLabel value={21} control={<Radio />} label="3 Weeks" />
                <FormControlLabel value={200} control={<Radio />} label="6 Months" />
              </RadioGroup>
            </FormControl>
          </Box> */}

          <Box width={1/4}>
            <TextInput
              name='number'
              label='Publication Search'
              value={this.props.query}
              onChange={this.props.handleChange}
              className='w-100'
              fullWidth
            />
          </Box>

        </Flex>

        <ButtonLayout>
          <RaisedButton handleClick={this.props.handleFindPublicationsClick}>
            Find Publications
          </RaisedButton>
        </ButtonLayout>

      </Fragment>
    )
  }
}
