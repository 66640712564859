import React, { Fragment, useState } from "react"
import Paper from "../../Paper"
import Flex from "../../Flex"
import Box from "../../Box"
import Typography from "@material-ui/core/Typography"
import Select from "../../Select"
import NewsList from "./NewsList"
import update from "immutability-helper"
import TweetsList from "../../twitter/Tweets"

export default function Tweets(props) {
  const [tweets, setTweets] = useState(props.tweets)

  function handleDeleteClick(productTweet) {
    $.ajax({
      type: 'PUT',
      url: `/admin/product_tweets/${productTweet.product_tweet_id}`,
      data: {
        product_tweet: {
          deleted: true
        }
      },
      dataType: 'json',
      success: (data) => {
        let index = tweets.indexOf(productTweet)

        let items = update(tweets, {
          $splice: [[index,1]]
        })

        setTweets(items)
      }
    })
  }

  return(
    <Paper fullHeight>
      <Box height="100%">
        <Flex alignItems='center' mb={3}>
          <Box ml='auto'>
            {`${tweets.length} tweets`}
          </Box>
        </Flex>

        <Box overflow='auto' height="90%">
          {tweets.length > 0 ?
            <TweetsList
              tweets={tweets}
              handleDeleteClick={handleDeleteClick}
            />
          :
            <Flex justifyContent='center' height="50%">
              <Box alignSelf='center' maxWidth="50%">
                <Box>
                  <Typography variant="body1">
                    {`No tweets found for this product`}
                  </Typography>
                </Box>
              </Box>
            </Flex>
          }
        </Box>
      </Box>
    </Paper>
  )
}
