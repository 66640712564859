import React, { Component, Fragment } from "react"
import Paper from "../Paper"
import Box from "../Box"
import { withRouter } from "react-router-dom"
import { withSnackbar } from "../SnackbarContainer"
import SearchFilters from "./SearchFilters"
import InlineSearchContainer from '../admin/search/InlineSearchContainer'
import { Grid, LinearProgress, Tooltip, IconButton } from "@material-ui/core"
import GetAppIcon from '@material-ui/icons/GetApp'
// import ExportDialog from "./ExportDialog"
import EmptyDataDisplay from "../EmptyDataDisplay"
import SearchIcon from '@material-ui/icons/Search'
import { Pagination } from "@material-ui/lab"
import AbstractsResults from "./IndexResults"

class Abstracts extends Component {
  constructor(props) {
    super(props)
    this.handleFilterChange = this.handleFilterChange.bind(this)
    this.handlePageChange = this.handlePageChange.bind(this)
    this.getAbstracts = this.getAbstracts.bind(this)
    this.handleClearSearchClick = this.handleClearSearchClick.bind(this)
    this.handleSearchClick = this.handleSearchClick.bind(this)
    this.handleExportClick = this.handleExportClick.bind(this)
    this.handleExportClose = this.handleExportClose.bind(this)
    this.handleItemSelect = this.handleItemSelect.bind(this)
    this.handleExport = this.handleExport.bind(this)
    this.handlePresenterClick = this.handlePresenterClick.bind(this)
    this.handleMeetingClick = this.handleMeetingClick.bind(this)
    this.handleCheckAllClick = this.handleCheckAllClick.bind(this)
    this.state = { abstracts: this.props.abstracts, abstractsCount: this.props.abstracts_count, filterOptions: { products: this.props.product_options, meetings: this.props.meeting_options, presenters: this.props.presenter_options, trials: this.props.trial_options }, filterValues: { company_ids: [], products: this.props.products, trials: this.props.trials, presenters: this.props.presenters, years: [], meetings: this.props.meetings, query: null, page: this.props.page, order_by: this.props.order_by }, showSnackbar: false, snackbarText: '', loading: false, exportDialogOpen: false, selectedItems: [] }
  }

  handlePresenterClick(presenterName) {
    let params = Object.assign({}, this.state.filterValues)
    
    params["presenters"] = [presenterName]

    this.getAbstracts(params)
  }

  handleMeetingClick(meetingId) {
    let params = Object.assign({}, this.state.filterValues)

    params["meetings"] = [meetingId]

    this.getAbstracts(params)
  }

  handleClearSearchClick(e) {
    let params = Object.assign({}, this.state.filterValues)
    
    params["query"] = null

    this.getAbstracts(params)
  }

  handleCheckAllClick(filterOptions, filterName) {
    let params = Object.assign({}, this.state.filterValues)

    if (filterOptions.length == 0) {
      params[filterName] = []
    } else {
      params[filterName] = filterOptions.map((option) => (option.value || option.name))
    }

    this.getAbstracts(params)
  }

  handleFilterChange(value, filter) {
    let params = Object.assign({}, this.state.filterValues)

    if (filter == 'years') {
      params["begin_year"] = value[0]
      params["end_year"] = value[1]
    } else if (filter == 'order_by') {
      params["order_by"] = value
    } else if (params[filter].includes(value)) {
      params[filter] = params[filter].filter((item) => item != value)
    } else {
      params[filter].push(value)
    }

    this.getAbstracts(params)
  }

  handleOpen(event) {
    this.setState({
      anchorEl: event.currentTarget
    })
  };

  handleClose() {
    this.setState({
      anchorEl: null
    })
  };

  handlePageChange(e, page) {
    let params = Object.assign({}, this.state.filterValues)

    params["page"] = page

    this.getAbstracts(params)
  }

  handleSearchChange(e) {
    let filterValues = update(this.state.filterValues, {
      query: { $set: e.target.value }
    })

    this.setState({
      filterValues: filterValues
    })
  }

  handleSearchClick(query) {
    let params = Object.assign({}, this.state.filterValues)

    params["query"] = query

    this.getAbstracts(params)
  }

  handleExportClick = () => {
    this.setState({ exportDialogOpen: true });
  };

  handleExportClose = () => {
    this.setState({ exportDialogOpen: false });
  };

  handleItemSelect = (itemId) => {
    this.setState(prevState => ({
      selectedItems: prevState.selectedItems.includes(itemId)
        ? prevState.selectedItems.filter(id => id !== itemId)
        : [...prevState.selectedItems, itemId]
    }));
  };

  handleExport = (format, exportOption, selectedColumns) => {
    const { filterValues, selectedItems } = this.state;
    
    let params = Object.assign({}, this.state.filterValues)

    params["file_format"] = format
    params["data_source"] = 'abstracts'
    params["columns"] = selectedColumns
  
    if (exportOption === 'selected' && selectedItems.length > 0) {
      params["selected"] = selectedItems
    } else if (exportOption === 'current_page') {
      params["page"] = filterValues.page
    } else {
      params["export_all"] = true
    }
  
    $.ajax({
      url: `/sectors/${this.props.sector.abbr}/abstracts/export`,
      method: 'POST',
      data: params,
      xhrFields: {
        responseType: 'blob'  // Set the response type to blob
      },
      beforeSend: (xhr) => {
        this.setState({ loading: true });
        xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
      },
      success: (blob, status, xhr) => {
        const filename = xhr.getResponseHeader('X-Filename') || 'export.csv';
        
        // Create a link element and trigger download
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = filename;
        link.click();

        // Clean up
        window.URL.revokeObjectURL(link.href);
        // this.props.snackbarShowMessage('Export completed. Your file is downloading.', 'success', 5000);
      },
      error: (jqXHR, textStatus, errorThrown) => {
        console.error('Export error:', textStatus, errorThrown);
        this.props.snackbarShowMessage('Failed to start export job', 'error');
      },
      complete: () => {
        this.setState({ loading: false });
        this.handleExportClose();
      }
    });
  };

  getAbstracts(params) {
    $.ajax({
      type: 'GET',
      url: `/sectors/${this.props.sector.abbr}/abstracts`,
      data: params,
      dataType: 'json',
      beforeSend: (data) => {
        this.setState({
          loading: true
        })
      },
      success: (data) => {
        let filterOptions = { products: data.product_options, meetings: data.meeting_options, years: data.year_options, presenters: data.presenter_options, trials: data.trial_options }

        // let filterKeys = Object.keys(params)

        // let orderIndex = filterKeys.indexOf("order")
        // let sortByIndex = filterKeys.indexOf("sort_by")

        // filterKeys.splice(orderIndex,1)
        // filterKeys.splice(sortByIndex,1)

        // let queryParams = filterKeys.map((filterKey) => params[filterKey] && params[filterKey].length > 0 ? params[filterKey].map((filterValue) => `${filterKey}[]=${filterValue}`) : null).filter(param => param)

        // let url = `/sectors/${this.props.sector.abbr}/abstracts`

        // let flattenedQueryParams = [].concat.apply([], queryParams);
        // this.props.history.push(`${url}?${flattenedQueryParams.join("&")}`);

        this.setState({
          abstracts: data.abstracts,
          abstractsCount: data.abstracts_count,
          filterValues: params,
          loading: false,
          filterOptions
        })
      },
      error: (data) => {
        console.log(data.responseText)
        this.setState({
          showSnackbar: true,
          snackbarText: 'There was an error. Please try again.',
          loading: false
        })
      }
    })
  }

  render() {
    const { abstracts, abstractsCount, loading } = this.state

    return (
      <Fragment>
        <Paper minHeight="500px" minFullHeight={abstractsCount != 0} fullHeight={abstractsCount === 0}>
          <Box height="100%" style={{overflowY: "auto", overflowX: "hidden" }}>
            <Box width={1/2} mx='auto' pt={1}>
              <InlineSearchContainer
                searchLabel="Search abstracts" 
                query={this.state.filterValues.query}
                handleSearchClick={this.handleSearchClick}
                handleClearSearchClick={this.handleClearSearchClick}
              />
            </Box>

            {/* <Box display="flex" justifyContent='right'>
              <Tooltip title="Export">
                <IconButton onClick={this.handleExportClick} disabled={this.state.loading} size="small">
                  <GetAppIcon />
                </IconButton>
              </Tooltip>
            </Box> */}

            {/* <ExportDialog
              open={this.state.exportDialogOpen}
              onClose={this.handleExportClose}
              onExport={this.handleExport}
              hasSelectedItems={this.state.selectedItems.length > 0}
            /> */}

            <Grid container spacing={3}>
              <Grid item xs={4} sm={4} md={3} xl={2}>
                <SearchFilters
                  filterOptions={this.state.filterOptions}
                  filterValues={this.state.filterValues}
                  handleFilterChange={this.handleFilterChange}
                  handleCheckAllClick={this.handleCheckAllClick}
                />
              </Grid>

              <Grid item xs={12} sm={8} md={9} xl={10}>
                {abstractsCount === 0 ?
                  <EmptyDataDisplay 
                    message="No results found. Please try adjusting your search or filters."
                    icon={SearchIcon}
                  />
                : 
                  <Fragment>
                    {loading && <div style={{marginTop: '4px'}}><LinearProgress /></div>}
                    <AbstractsResults
                      abstracts={abstracts}
                      resultsCount={abstractsCount}
                      sector={this.props.sector}
                      query={this.state.filterValues.query}
                      onItemSelect={this.handleItemSelect}
                      selectedItems={this.state.selectedItems}
                      handleMeetingClick={this.handleMeetingClick}
                    />
                  </Fragment>
                }
              </Grid>
            </Grid>

            <Box display="flex" justifyContent='center' width={9/10}>
              <Pagination
                page={this.state.page}
                count={abstractsCount > 20 ? Math.round(abstractsCount/20) : 1}
                onChange={this.handlePageChange}
                showFirstButton
                showLastButton
              />
            </Box>
          </Box>
        </Paper>
      </Fragment>
    )
  }
}

export default withRouter(withSnackbar(Abstracts))