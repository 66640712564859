import React from "react"
import Box from "../Box"
import Typography from "@material-ui/core/Typography"

export default function AlertOptionForm(props) {
  return(
    <Box>
      <Box mb={2}>
        <Typography>
          Would you like to be alerted when a new tweet hits on this search term?
        </Typography>
      </Box>

      <Typography variant="body2">
        {`On average, this would result in ${props.weeklyAlertCount} ${props.weeklyAlertCount > 1 ? 'alerts' : 'alert'} per week`}
      </Typography>

      <Box mt={2}>
        <Typography variant="caption">
          Keep in mind that you will receive alerts when a tweet can be associated with a product in your sector
        </Typography>
      </Box>
    </Box>
  )
}
