import React, { Component } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import { TableRow, TableCell } from "../tables"
import Link from "../Link"
import Box from "../Box"

export default class PatentListRecord extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    return(
      <TableRow component={Link} href={`/sectors/${this.props.sector.abbr}/patents/${this.props.patent.id}`} target="_blank">

        <TableCell>
          {this.props.patent.html_patent_no}
        </TableCell>

        <TableCell className='primary-data'>
          <Link href={`/sectors/${this.props.sector.abbr}/patents/${this.props.patent.id}`} target="_blank">
            <Box>
              {this.props.patent.patent_title}
            </Box>
          </Link>
        </TableCell>

        <TableCell>
          {this.props.patent.effective_date_str}
        </TableCell>

      </TableRow>
    )
  }
}

PatentListRecord.propTypes = {
  patent: PropTypes.object
}
