import React from "react"
import Box from "../Box"
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

export default function Autocomplete({ suggestions, onSelect, show, setShowSuggestions }) {
  const suggestionsRef = React.useRef(null);

  React.useEffect(() => {
    function handleClickOutside(event) {
      if (suggestionsRef.current && !suggestionsRef.current.contains(event.target)) {
        setShowSuggestions(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleSelect = (option, isSearch = false) => {
    onSelect(option, isSearch);
  };

  if (!show || !suggestions || Object.keys(suggestions).length === 0) {
    return null;
  }

  return (
    <Paper ref={suggestionsRef}>
      <Box
        position="absolute"
        width={1/2}
        mt={1}
        className="bg-white"
        boxShadow="0 2px 4px rgba(0,0,0,0.2)"
        borderRadius={1}
        zIndex={1000}
      >
        <List>
          {Object.entries(suggestions).map(([key, suggestions]) => (
            <React.Fragment key={key}>
              <ListSubheader>{key}</ListSubheader>
              {suggestions.map((option, index) => (
                <ListItem 
                  key={option ? `${key}-${option.id}` : `${key}-${index}`} 
                  button 
                  component="a"
                  href={`${window.location.pathname}/view_entity?entity_type=${key}&entity_id=${option.id}`}
                >
                  <ListItemText 
                    primary={option.label} 
                    secondary={option.secondary_label}
                  />
                  <ListItemSecondaryAction>
                    <IconButton 
                      edge="end" 
                      onClick={(e) => {
                        e.stopPropagation();
                        handleSelect(option, true);
                      }}
                    >
                      <SearchIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </React.Fragment>
          ))}
        </List>
      </Box>
    </Paper>
  );
}
