import React, { Fragment } from "react"
import Flex from "../../Flex"
import Box from "../../Box"
import ButtonLayout from "../ButtonLayout"
import { RaisedButton } from "../../buttons"
import DatePicker from "../../DatePicker"
import Select from "../../Select"
import TextInput from "../../TextInput"
import SearchDropdown from "../../filters/SearchDropdown"

export default function Form(props) {
  return (
    <Fragment>

      <Box mb={4}>
        <Flex alignItems='center'>
          <Box width={1/3}>
            <SearchDropdown
              name='science_meeting_id'
              label="Meeting"
              options={props.scienceMeetings}
              handleEntityChange={props.handleMeetingChange}
            />
          </Box>

          <Box mx={2}>
            OR
          </Box>

          <Box width={1/2}>
            <TextInput
              label='New Meeting Name'
              name='meetingName'
              value={props.meetingName}
              onChange={e => props.handleNewMeetingChange(e.target.name, e.target.value)}
              className='w-100'
            />
          </Box>
        </Flex>
      </Box>

      <Box mb={4}>
        <Select
          name='year'
          value={props.meeting.year}
          label='Year'
          entities={props.years}
          handleChange={props.handleChange}
        />
      </Box>

      <Box my={3}>
        <TextInput
          label='URL'
          name='url'
          value={props.meeting.url}
          onChange={e => props.handleChange(e.target.name, e.target.value)}
          className='w-100'
        />
      </Box>

      <Box ml="auto">
        <DatePicker
          name='start_date'
          label='Start Date'
          handleChange={date => props.handleDateChange('start_date', date)}
          value={props.meeting.start_date}
        />
      </Box>

      <Box ml="auto">
        <DatePicker
          name='end_date'
          label='End Date'
          handleChange={date => props.handleDateChange('end_date', date)}
          value={props.meeting.end_date}
        />
      </Box>

      <ButtonLayout>
        <RaisedButton handleClick={props.handleSaveClick}>
          Save New Meeting
        </RaisedButton>
      </ButtonLayout>
    </Fragment>
  )
}
