import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Paper from "../../Paper"
import Flex from "../../Flex"
import Box from "../../Box"
import { Table, TableBody, TableRow, TableHead, TableCell } from "../../tables"
import AbstractTypePresentations from "./AbstractTypePresentations"
import Typography from "@material-ui/core/Typography"
import update from "immutability-helper"
import Snackbar from "../../Snackbar"

export default class StagedAssociator extends Component {
  constructor(props) {
    super(props)
    this.handleDeleteClick = this.handleDeleteClick.bind(this)
    this.state = { abstracts: this.props.abstracts }
  }

  handleDeleteClick(abstract) {
    if (abstract.abstract_associator_status_id) {
      $.ajax({
        type: "DELETE",
        url: `/admin/abstracts/${abstract.id}/delete_associator_status`,
        data: {
          user_id: this.props.current_user.id
        },
        dataType: 'json',
        success: (data) => {
          let index = this.state.abstracts.indexOf(abstract)

          let abstracts = update(this.state.abstracts, {
            $splice: [[index,1]]
          })

          this.setState({
            abstracts: abstracts,
            showSnackbar: true,
            snackbarText: 'Abstract has been removed'
          })
        },
        error: (data) => {
          this.setState({
            abstracts: abstracts,
            showSnackbar: true,
            snackbarText: 'There was an error removing the abstract. Please try again.'
          })
        },
        complete: (data) => {
          setTimeout(() => {
            this.setState({
              showSnackbar: false,
              snackbarText: ''
            })
          }, 4000)
        }
      })
    } else {
      $.ajax({
        type: "PUT",
        url: `/admin/abstracts/${abstract.id}/delete_predicted_trials`,
        data: {
          user_id: this.props.current_user.id
        },
        dataType: 'json',
        success: (data) => {
          let index = this.state.abstracts.indexOf(abstract)

          let abstracts = update(this.state.abstracts, {
            $splice: [[index,1]]
          })

          this.setState({
            abstracts: abstracts,
            showSnackbar: true,
            snackbarText: 'Abstract has been removed'
          })
        },
        error: (data) => {
          this.setState({
            abstracts: abstracts,
            showSnackbar: true,
            snackbarText: 'There was an error removing the abstract. Please try again.'
          })
        },
        complete: (data) => {
          setTimeout(() => {
            this.setState({
              showSnackbar: false,
              snackbarText: ''
            })
          }, 4000)
        }
      })
    }
  }

  render() {
    return(
      <Fragment>

        <Paper fullHeight>

          <Flex justifyContent='right'>
            <Typography variant="body2">
              {`${this.state.abstracts.length} Presentations`}
            </Typography>
          </Flex>

          <Box overflow='auto' maxHeight='90%' mt={3}>
            <Table size='small' aria-label='staged associator table'>
              <TableHead>
                <TableRow hover={false}>
                  <TableCell>#</TableCell>
                  <TableCell>Title</TableCell>
                  <TableCell align='right'>Score</TableCell>
                  <TableCell>Media</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {/* {this.props.abstracts.map((abstractType) => */}
                <AbstractTypePresentations
                  abstracts={this.state.abstracts}
                  handleDeleteClick={this.handleDeleteClick}
                />
                {/* )} */}
              </TableBody>
            </Table>
          </Box>

        </Paper>

        <Snackbar
          open={this.state.showSnackbar}
          message={this.state.snackbarText}
        />

      </Fragment>
    )
  }
}
