import React, { Component } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Box from "./Box"

class SidebarSection extends Component {
  constructor(props) {
    super(props);
    this.state = { showContent: true }
  }

  componentDidMount() {
    if (this.props.children) {
      this.setState({ showContent: true })
    } else if (this.props.content == null) {
      this.setState({ showContent: false })
    } else if (typeof(this.props.content) == 'object') {
      if (this.props.content.length > 0) {
        this.setState({ showContent: true })
      } else {
        this.setState({ showContent: false })
      }
    } else {
      if (this.props.content) {
        this.setState({ showContent: true })
      } else {
        this.setState({ showContent: false })
      }
    }
  }

  render() {
    return (
      this.state.showContent ? <div>
        <Box mb={2}>
          <div className='f6 b'>{this.props.header}</div>
          <div>{this.props.children ? this.props.children : this.props.content}</div>
        </Box>
      </div>: ''
    );
  }
}

export default SidebarSection
