import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Paper from "../../Paper"
import Flex from "../../Flex"
import Box from "../../Box";
import Select from "../../Select"
import TextInput from "../../TextInput"
import ButtonLayout from "../ButtonLayout"
import { RaisedButton } from "../../buttons"
import update from "immutability-helper"
import Snackbar from "../../Snackbar"
import InputLabel from '@material-ui/core/InputLabel';
import DatePicker from "../../DatePicker"

export default class NewContainer extends Component {
  constructor(props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
    this.handleDateChange = this.handleDateChange.bind(this)
    this.handleSaveClick = this.handleSaveClick.bind(this)
    this.handleWebsiteKindChange = this.handleWebsiteKindChange.bind(this)
    this.state = { website: this.props.website, showSnackbar: false, snackbarText: '' }
  }
  
  componentDidMount() {
    const website = update(this.state.website, { 
      created_by: { $set: this.props.current_user.id }
    })
    
    this.setState({
      website: website
    })
  }
  
  handleChange(e) {
    const name = e.currentTarget.name
    const value = e.currentTarget.value
    
    const website = update(this.state.website, { 
      [name]: { $set: value }
    })
    
    this.setState({
      website: website
    })
  }
  
  handleWebsiteKindChange(name, value) {
    const website = update(this.state.website, { 
      [name]: { $set: value }
    })
    
    this.setState({
      website: website
    })
  }
  
  handleDateChange(date) {
    const website = update(this.state.website, {
      date: { $set: date }
    })
    
    this.setState({
      website: website
    })
  }
  
  handleSaveClick(e) {
    const url = this.props.redirect_url ? `/admin/websites?r=${this.props.redirect_url}` : "/admin/websites"
    $.post(url, { website: this.state.website }, (data) => {
      window.location.href = url
    }, 'json').fail((data) => {
      this.setState({
        showSnackbar: true,
        snackbarText: `The website could not be created. ${data.responseText}.`
      })
    }).always(() => {
      setTimeout(() => {
        this.setState({
          showSnackbar: false,
          snackbarText: ''
        })
      }, 4000)
    })
  }
  
  render() {
    return(
      <Fragment>
        
        <Paper>
          
          <Flex alignItems='start'>
            
            <Box mr={3} width={1/5}>
              <Select 
                value={this.state.website.website_kind_id || ''} 
                name='website_kind_id'
                label='Kind'
                entities={this.props.website_kinds}
                handleChange={this.handleWebsiteKindChange} 
              />
            </Box>
            
            <Box ml='auto'>
              <DatePicker
                name='date' 
                label='Date'
                handleChange={this.handleDateChange}
                value={this.state.website.date} 
              />
            </Box>
            
          </Flex>
          
          <Box my={3}>
            <TextInput 
              name='title' 
              label='Title' 
              value={this.state.website.title || ''} 
              onChange={this.handleChange} 
              fullWidth={true} 
            />
          </Box>
          
          <Box my={3} width={1}>
            <TextInput 
              name='name' 
              label='Website' 
              multiline={true}
              rowsMax={4}
              value={this.state.website.name || ''} 
              onChange={this.handleChange} 
              fullWidth={true}
            />
          </Box>
          
          <ButtonLayout>
            <RaisedButton handleClick={this.handleSaveClick}>
              Save Website
            </RaisedButton>
          </ButtonLayout>
          
          
        </Paper>
        
        <Snackbar 
          open={this.state.showSnackbar}
          message={this.state.snackbarText} 
        />
      </Fragment>
    )
  }
}
