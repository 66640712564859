import React, { Fragment } from "react"
import Flex from "../../../Flex"
import Box from "../../../Box"
import TextInput from "../../../TextInput"
import { RaisedButton } from "../../../buttons"

export default function Form(props) {

  return(
    <Box>

      <Flex width={1/2}>
        <Box width={3/4} mr={3}>
          <TextInput
            name="name"
            value={props.name}
            onChange={props.handleNameChange}
            placeholder="Type alias here..."
            fullWidth
          />
        </Box>

        <Box width={1/4}>
          <RaisedButton onClick={props.handleAddAliasClick}>
            Add Alias
          </RaisedButton>
        </Box>
      </Flex>

    </Box>
  )
}
