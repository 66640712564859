import React, { Component } from "react"
import PropTypes from "prop-types"
import ReactDOM from "react-dom"
import { Table, TableRow, TableCell, TableHead, TableBody } from "../tables"
import GroupedEntities from "./GroupedEntities"

export default function ActivityDataTable(props) {

  const firstHeaderHash = { 'person_abstract': 'Type', 'person_trial': 'NCT ID', 'person_manuscript': 'Date' }
  const secondHeaderHash = { 'person_abstract': 'Abstract', 'person_trial': 'Trial', 'person_manuscript': 'Manuscript' }

  return (
    <React.Fragment>
      <Table size='small'>
        <TableHead>
          <TableRow hover={false}>
            <TableCell>{firstHeaderHash[props.chartEntityType]}</TableCell>
            <TableCell>{secondHeaderHash[props.chartEntityType]}</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {Object.keys(props.networkData).map((meeting) =>
            <GroupedEntities
              key={meeting}
              header={meeting}
              entities={props.networkData[meeting]}
              sector={props.sector}
              chartEntityType={props.chartEntityType}
            />
          )}
        </TableBody>
      </Table>
    </React.Fragment>
  );
}
