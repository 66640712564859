import React, { Component } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Header from "../Header.jsx"

class SectionHeader extends Component {
  constructor(props) {
    super(props)
  }
  
  render() {
    const alertsRootUrl = `/sectors/${this.props.sector.abbr}/alerts`
    let tabs = [['Groups', alertsRootUrl], ['Companies', `${alertsRootUrl}/companies`], ['Products', `${alertsRootUrl}/products`], ['Trials', `${alertsRootUrl}/trials`], ['Meetings', `${alertsRootUrl}/meetings`]]
    return (
      <Header 
        tabs={tabs} 
        header='Alerts' 
        activeTab={this.props.activeTab} 
        handleClick={this.props.handleClick} 
        background='bg-gray-cyan' 
      />
    );
  }
}

export default SectionHeader
