import React, { Fragment } from "react"
import PropTypes from "prop-types"
import Box from "../../Box"
import Chip from '@material-ui/core/Chip';
import CloseIcon from '@material-ui/icons/Close';
import Grid from '@material-ui/core/Grid';
import Filter from "../../filters/Filter"

export default function Filters(props) {

  const selectedOptions = props.filterOptions.products.filter(entity => props.filterValues.product_ids.includes(entity.value) ? entity : null)

  let xsSize = 5

  return(

    <Grid container spacing={2} alignItems='center'>

      <Grid item xs={2}>
        <Box>
          <Filter
            label='Products'
            name='product_ids'
            value={props.filterValues.product_ids}
            entities={props.filterOptions.products}
            handleChange={props.handleFilterChange}
          />
        </Box>
      </Grid>

      <Grid item xs={2}>
        <Box>
          <Filter
            label='Companies'
            name='sponsor_ids'
            value={props.filterValues.sponsor_ids}
            entities={props.filterOptions.sponsors}
            handleChange={props.handleFilterChange}
          />
        </Box>
      </Grid>

      <Grid item xs={2}>
        <Box>
          <Filter
            label='Type'
            name='clinical_type_ids'
            value={props.filterValues.clinical_type_ids}
            entities={props.filterOptions.clinicalTypes}
            handleChange={props.handleFilterChange}
          />
        </Box>
      </Grid>

      <Grid item xs={2}>
        <Box>
          <Filter
            label='Role'
            name='roles'
            value={props.filterValues.roles}
            entities={props.filterOptions.roles}
            handleChange={props.handleFilterChange}
          />
        </Box>
      </Grid>

      <Grid item xs={2}>
        <Box>
          <Filter
            label='Status'
            name='clinical_status_ids'
            value={props.filterValues.clinical_status_ids}
            entities={props.filterOptions.clinicalStatuses}
            handleChange={props.handleFilterChange}
          />
        </Box>
      </Grid>
    </Grid>
  )
}
