import React from "react"
import PropTypes from "prop-types"
import UserApp from "./UserApp"
import Box from "./Box"
import withWidth, { isWidthUp, isWidthDown } from '@material-ui/core/withWidth';

class MtPipeline extends React.Component {
  constructor(props) {
    super(props)
    this.handleDrawerClose = this.handleDrawerClose.bind(this)
    this.handleDrawerOpen = this.handleDrawerOpen.bind(this)
    this.handleFavoriteClick = this.handleFavoriteClick.bind(this)
    this.handleSearchClick = this.handleSearchClick.bind(this)
    this.handleSearchChange = this.handleSearchChange.bind(this)
    this.handleSearchSubmit = this.handleSearchSubmit.bind(this)
    this.handleSectorChange = this.handleSectorChange.bind(this)
    this.state = { showSidebar: true, smallShowSidebar: false, userEntity: this.props.user_entity, showSnackbar: false, snackbarText: '', showSearchInput: false, search: '', sector: this.props.sector }
  }

  componentDidMount() {
    if (isWidthDown('md', this.props.width)) {
      this.setState({
        showSidebar: false
      })
    }
  }

  handleDrawerClose(e) {
    this.setState({
      showSidebar: false,
      smallShowSidebar: false
    })
    $('#content').addClass('ph3-important')
  }

  handleDrawerOpen(e) {
    this.setState({
      showSidebar: true,
      smallShowSidebar: true
    })
    $('#content').removeClass('ph3-important')
  }

  handleSearchClick(e) {
    let showSearchInput = this.state.showSearchInput
    this.setState({
      showSearchInput: !showSearchInput
    })
  }

  handleSectorChange(name, value) {

  }


  handleSearchChange(e) {
    this.setState({
      search: e.target.value
    })
  }


  handleFavoriteClick(e) {
    const entity = this.props.active_group.toLowerCase()
    const modelEntityName = `user_${entity}`
    // const modelId = `${modelEntityName.replace('s', '')}_id`
    const modelId = `${entity.replace('s', '')}_id`

    if (this.state.userEntity) {
      $.ajax({
        type: 'PUT',
        url: `/sectors/${this.props.sector.abbr}/${modelEntityName}/${this.state.userEntity.id}`,
        data: {
          [`user_${entity.replace('s', '')}`]: {
            deleted: true
          }
        },
        dataType: 'json',
        success: (data) => {
          this.setState({
            userEntity: null
          })
        },
        error: (data) => {
          this.setState({
            showSnackbar: true,
            snackbarText: 'There was an error removing the item from your favorites. Please try again.'
          })
        },
        complete: (data) => {
          setTimeout(() => {
            this.setState({
              showSnackbar: false,
              snackbarText: ''
            })
          }, 4000)
        }
      })
    } else {
      $.ajax({
        type: 'POST',
        url: `/sectors/${this.props.sector.abbr}/${modelEntityName}`,
        data: {
          [`user_${entity.replace('s', '')}`]: {
            user_id: this.props.current_user.id, [modelId]: this.props.entity.id
          }
        },
        dataType: 'json',
        success: (data) => {
          this.setState({
            userEntity: data
          })
        },
        error: (data) => {
          this.setState({
            showSnackbar: true,
            snackbarText: 'There was an error adding the entity to your favorites. Please try again.'
          })
        },
        complete: (data) => {
          setTimeout(() => {
            this.setState({
              showSnackbar: false,
              snackbarText: ''
            })
          }, 4000)
        }
      })
    }
  }

  handleSearchSubmit(e) {
    e.preventDefault()
    window.location.href = `/sectors/${this.props.sector.abbr}/search?query=${this.state.search}`
  }

  render () {
    let height = this.props.tabs ? '134px' : '108px'
    return (
      <Box id='header-container' style={{minHeight: height}}>
        <UserApp
          showSidebar={this.state.showSidebar}
          handleDrawerClose={this.handleDrawerClose}
          handleDrawerOpen={this.handleDrawerOpen}
          title={this.props.title}
          search={this.state.search}
          activeItem={this.props.title}
          activeGroup={this.props.active_group}
          sectors={this.props.sectors}
          sector={this.props.sector}
          market={this.props.market}
          currentUser={this.props.current_user}
          userSectors={this.props.user_sectors}
          tabs={this.props.tabs}
          subTabs={this.props.sub_tabs}
          activeTab={this.props.active_tab}
          userEntity={this.state.userEntity}
          entity={this.props.entity}
          company={this.props.company}
          parentProduct={this.props.parent_product}
          productFamily={this.props.product_family}
          showSearchInput={this.state.showSearchInput}
          handleFavoriteClick={this.handleFavoriteClick}
          handleSearchClick={this.handleSearchClick}
          handleSearchChange={this.handleSearchChange}
          handleSearchSubmit={this.handleSearchSubmit}
          handleSectorChange={this.handleSectorChange}
        />
      </Box>
    )
  }
}

MtPipeline.propTypes = {
  title: PropTypes.string
}

export default withWidth()(MtPipeline)
