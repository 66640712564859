import React from "react"
import Paper from "../Paper"
import Flex from "../Flex"
import Box from "../Box"
import Typography from "@material-ui/core/Typography"
import StatementGroup from "../company_statements/StatementGroup"

function Statements(props) {
  return(
    <React.Fragment>
      <Paper fullHeight>
        <Box height="100%">
          <Box overflow="auto" height="90%">
            {props.statements.length > 0 ? props.statements.map((statementArray, index) =>

              <StatementGroup
                key={statementArray[0]}
                open={index === 0}
                sector={props.sector}
                source={statementArray[0]}
                statementArray={statementArray}
                companyStatements={statementArray[1]}
              />

              ) :
              <Flex justifyContent='center' height="50%">
                <Box alignSelf='center' maxWidth="50%">
                  <Box>
                    <Typography variant="body1">
                      No statements found for this company
                    </Typography>
                  </Box>
                </Box>
              </Flex>
            }
          </Box>
        </Box>
      </Paper>
    </React.Fragment>
  )
}

export default Statements
