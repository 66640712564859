import React, { Fragment, Component } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Index from "./Index"
import Search from "./Search"
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

export default class IndexContainer extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    return(
      <Fragment>
        <Switch>
          <Route
            path={`/sectors/${this.props.sector.abbr}/news`}
            children={<Search {...this.props} />}
          />
        </Switch>
      </Fragment>
    )
  }
}
