import React from "react"
import PropTypes from "prop-types"
import Paper from "../../Paper"
import Box from "../../Box"
import { withSnackbar } from '../../SnackbarContainer'
// import IndexFilters from "./IndexFilters"
import ListingsCountDisplay from '../../ListingsCountDisplay'
import update from "immutability-helper"
import ApprovalsTable from "./ApprovalsTable"
import InlineSearchContainer from '../search/InlineSearchContainer'
import Select from '../../Select'
import Flex from "../../Flex"


class IndexContainer extends React.Component {
  constructor(props) {
    super(props)
    this.handleSearchClick= this.handleSearchClick.bind(this)
    this.getDeviceApprovals = this.getDeviceApprovals.bind(this)
    this.handlePageChange = this.handlePageChange.bind(this)
    this.handleClearSearchClick = this.handleClearSearchClick.bind(this)
    this.handleFilterChange = this.handleFilterChange.bind(this)
    this.handleDeleteClick = this.handleDeleteClick.bind(this)
    this.handleSearchSubmit = this.handleSearchSubmit.bind(this)
    this.state = { deviceApprovals: this.props.device_approvals, deviceApprovalsCount: this.props.device_approvals_count, filterValues: { query: this.props.query, country_id: this.props.country_id } }
  }

  handleSearchSubmit(query) {
    let params = Object.assign({}, this.state.filterValues)

    params["query"] = query

    this.getDeviceApprovals(params)
  }

  getDeviceApprovals(params) {
    $.ajax({
      type: "GET",
      url: "/admin/device_approvals",
      data: params,
      dataType: 'json',
      success: (data) => {
        this.setState({
          deviceApprovals: data.device_approvals,
          deviceApprovalsCount: data.device_approvals_count,
          filterValues: params
        })
      },
      error: (data) => {
        console.log(data)
        this.props.snackbarShowMessage("Devices could not be loaded. Please try again.", "error")
      }
    })
  }

  handleDeleteClick(deviceApproval) {
    $.ajax({
      type: "PUT",
      url: `/admin/device_approvals/${deviceApproval.id}/remove_products`,
      data: {
        device_approval: {
          hide: true
        }
      },
      dataType: 'json',
      success: (data) => {
        let index = this.state.deviceApprovals.indexOf(deviceApproval)

        let deviceApprovals = update(this.state.deviceApprovals, {
          $splice: [[index, 1]]
        })

        this.setState({
          deviceApprovals: deviceApprovals
        })

        this.props.snackbarShowMessage("Device approval was removed")
      }
    })
  }

  handleFilterChange(name, value) {
    let params = Object.assign({}, this.state.filterValues)

    params[name] = value

    this.getDeviceApprovals(params)
  }

  handlePageChange(e, newPage) {
    let params = Object.assign({}, this.state.filterValues)

    // params["order_by"] = this.state.orderBy
    // params["order"] = this.state.order
    params["page"] = newPage

    this.getDeviceApprovals(params)
  }

  handleSearchClick(query) {
    let params = Object.assign({}, this.state.filterValues)

    params["query"] = query

    this.getDeviceApprovals(params)
  }

  handleClearSearchClick(e) {
    let params = Object.assign({}, this.state.filterValues)

    params["query"] = null

    this.getDeviceApprovals(params)
  }


  render() {
    return (
      <React.Fragment>
        <Paper fullHeight>
          <Box height="100%">
            
            <Flex mb={2}>
              
              <Box width={1/4} mr={2}>
                <Select 
                  name='country_id'
                  label='Country'
                  value={this.state.filterValues.country_id}
                  entities={this.props.countries}
                  handleChange={this.handleFilterChange}
                />
              </Box>

              <Box width={1/2}>
                <InlineSearchContainer
                  searchLabel="Search Devices" 
                  query={this.state.filterValues.query}
                  handleSearchClick={this.handleSearchSubmit}
                />
              </Box>

            </Flex>

            <ListingsCountDisplay 
              display={`${this.state.deviceApprovalsCount} Device Approvals`}
            />

            <Box overflow="auto" height="80%">

              <ApprovalsTable 
                deviceApprovals={this.state.deviceApprovals}
                page={this.state.filterValues.page}
                deviceApprovalsCount={this.state.deviceApprovalsCount}
                handlePageChange={this.handlePageChange}
                handleDeleteClick={this.handleDeleteClick}
              />

            </Box>
          </Box>
        </Paper>
      </React.Fragment>
    );
  }
}

IndexContainer.propTypes = {
  deviceApprovals: PropTypes.array
};

export default withSnackbar(IndexContainer)
