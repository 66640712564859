import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Paper from "../../Paper"
import update from "immutability-helper"
import Box from "../../Box"
import { Table, TableHead, TableRow, TableCell, TableBody } from "../../tables"
import Link from "../../Link"
import { Button } from "../../buttons"

export default class Index extends Component {
  constructor(props) {
    super(props)
    this.handleDeleteClick = this.handleDeleteClick.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.state = { showSnackbar: false, snackbarText: '', lastDeleted: '' }
  }

  handleClose(e) {
    this.setState({
      showSnackbar: false,
      snackbarText: ''
    })
  }



  handleDeleteClick(characteristicGroup) {
    $.ajax({
      type: 'PUT',
      url: `/admin/characteristic_groups/${characteristicGroup.id}`,
      data: {
        characteristic_group: {
          deleted: true, deleted_by: this.props.current_user.id
        }
      },
      dataType: 'json',
      success: (data) => {
        let characteristicGroupIds = this.state.characteristicGroups.map((characteristicGroup) => characteristicGroup.id )
        let index = characteristicGroupIds.indexOf(characteristicGroup.id)
        let characteristicGroups = update(this.state.characteristicGroups, { $splice: [[index, 1]]})

        this.setState({
          characteristicGroups: characteristicGroups,
          showSnackbar: true,
          snackbarText: 'The characteristic group was successfully deleted',
          lastDeleted: characteristicGroup
        })
      },
      error: (data) => {
        this.setState({
          showSnackbar: true,
          snackbarText: `The characteristic group could not be deleted. ${data.responseText}`
        })
      },
      complete: (data) => {
        setTimeout(() => {
          this.setState({
            showSnackbar: false,
            snackbarText: ''
          })
        }, 4000)
      }
    })
  }

  render() {
    return(
      <Fragment>

        <Paper fullHeight>
          <Box height="100%">

            <Box>
              <Button href={`/admin/search_keywords/new`} component="a">
                Add Keyword
              </Button>
            </Box>

            <Box overflow="auto" height="90%">
              <Table stickyHeader size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Keyword</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {this.props.search_keywords.map((searchKeyword) =>
                    <TableRow key={searchKeyword.id}>
                      <TableCell>
                        <Link href={`/admin/search_keywords/${searchKeyword.id}/edit`}>
                          {searchKeyword.keyword}
                        </Link>
                      </TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </Box>
          </Box>
        </Paper>

      </Fragment>
    )
  }
}
