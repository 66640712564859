import React, { Component } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Link from "../Link"
import { TableRow, TableCell } from "../tables"
import Box from "../Box"

export default function ListRecord(props){
  return(
    <TableRow>

      <TableCell>
        <Link href={`/sectors/${props.sector.abbr}/publications/${props.publication.manuscript_id}`}>
          <Box>
            {props.publication.title}
          </Box>
        </Link>
      </TableCell>

      <TableCell>
        <Link href={`/sectors/${props.sector.abbr}/journals/${props.publication.journal_id}`}>
          <Box>
            {props.publication.sql_journal_name || props.publication.journal_name}
          </Box>
        </Link>
      </TableCell>

      <TableCell>
        <Link href={`/sectors/${props.sector.abbr}/people/${props.publication.person_id}`}>
          <Box>
            {props.publication.lead_author_name}
          </Box>
        </Link>
      </TableCell>

      <TableCell>
        {humanDateFormat(props.publication.published_date)}
      </TableCell>

    </TableRow>
  )
}
