import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import List from "../../lists/List";
import NestedListItemLink from "../../lists/NestedListItemLink"
import ListItemLink from "../../lists/ListItemLink"
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ExpandLess from '../../ExpandLess';
import ExpandMore from '../../ExpandMore';
import Collapse from '@material-ui/core/Collapse';


export default class NavigationList extends Component {
  constructor(props) {
    super(props)
    this.state = { open: false }
  }
  
  UNSAFE_componentWillMount() {
    if (this.props.activeGroup == 'Sources') {
      this.setState({
        open: true
      })
    }
  }
  
  render() {
    return(
      <Fragment>
        
        <ListItem button onClick={() => this.setState({open: !this.state.open})}>
          <ListItemText primary="Sources" />
          {this.state.open ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        
        <Collapse in={this.state.open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            
            <NestedListItemLink href={`/admin/websites`}>
              <ListItemText primary='Websites' className={this.props.activeItem == 'Websites' && this.props.activeGroup == 'Sources' ? 'orange' : ''} />
            </NestedListItemLink>
            
            {/* <NestedListItemLink href={`/admin/press_releases`}>
              <ListItemText primary='Press Releases' className={this.props.activeItem == 'Press Releases' && this.props.activeGroup == 'Sources' ? 'orange' : ''} />
            </NestedListItemLink> */}
            
            <NestedListItemLink href={`/admin/investor_presentations`}>
              <ListItemText primary='Investor Presentations' className={this.props.activeItem == 'Investor Presentations' && this.props.activeGroup == 'Sources' ? 'orange' : ''} />
            </NestedListItemLink>
            
            <NestedListItemLink href={`/admin/internal_documents`}>
              <ListItemText primary='Internal Documents' className={this.props.activeItem == 'Internal Documents' && this.props.activeGroup == 'Sources' ? 'orange' : ''} />
            </NestedListItemLink>
            
            <NestedListItemLink href={`/admin/fda_documents`}>
              <ListItemText primary='FDA Documents' className={this.props.activeItem == 'FDA Documents' && this.props.activeGroup == 'Sources' ? 'orange' : ''} />
            </NestedListItemLink>
            
            {/* <NestedListItemLink href={`/admin/news_articles`}>
              <ListItemText primary='News Articles' className={this.props.activeItem == 'News Articles' && this.props.activeGroup == 'Sources' ? 'orange' : ''} />
            </NestedListItemLink> */}
            
            <NestedListItemLink href={`/admin/regulatory_agency_documents`}>
              <ListItemText primary='Regulatory agency documents' className={this.props.activeItem == 'Regulatory agency documents' && this.props.activeGroup == 'Sources' ? 'orange' : ''} />
            </NestedListItemLink>
            
          </List>
        </Collapse>

      </Fragment>
    )
  }
}
