import React, { Fragment } from "react"
import { Table, TableHead, TableBody, TableRow, TableCell } from "../../tables"
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TablePaginationActions from "../../tables/TablePaginationActions"
import Row from "./Row"

export default function NewsList(props) {
  return(

    <Table size='small'>
      <TableHead>
        <TableRow>
          <TableCell></TableCell>
          <TableCell>Type</TableCell>
          <TableCell>Title</TableCell>
          <TableCell>Date</TableCell>
          <TableCell>Publication</TableCell>
          <TableCell>Created</TableCell>
          <TableCell></TableCell>
        </TableRow>
      </TableHead>

      <TableBody>
        {props.newsItems.map((newsItem) => {
          let newsItemData = newsItem?._source || newsItem
          return (
            <Row
              key={newsItemData.id}
              newsItem={newsItemData}
              checkedItems={props.checkedItems}
              handleNewsCheckboxClick={props.handleNewsCheckboxClick}
              handleDeleteClick={props.handleDeleteClick}
            />
          )
        })}
      </TableBody>
      <TableFooter>
        <TablePagination
          colSpan={7}
          count={props.newsCount}
          rowsPerPage={100}
          page={props.page}
          SelectProps={{
            inputProps: { 'aria-label': 'rows per page' },
            native: true,
          }}
          onChangePage={props.handlePageChange}
          onChangeRowsPerPage={props.handleChangeRowsPerPage}
          ActionsComponent={TablePaginationActions}
        />
      </TableFooter>
    </Table>
  )
}
