import React, { Fragment, Component } from "react"
import Paper from "../../Paper"
import UpdateContainer from "./UpdateContainer"
import Typography from "@material-ui/core/Typography"
import Button from "../../buttons/Button"
import Flex from "../../Flex"
import Box from "../../Box"
import update from "immutability-helper"
import Snackbar from "../../Snackbar"

export default class TrialUpdates extends Component {
  constructor(props) {
    super(props)
    this.handleDeleteClick = this.handleDeleteClick.bind(this)
    this.handleUndoClick = this.handleUndoClick.bind(this)
    this.state = { updates: this.props.updates, lastDeleted: '', showSnackbar: false, snackbarText: '' }
  }

  handleDeleteClick(trialUpdate) {
    $.ajax({
      type: 'PUT',
      url: `/admin/trials/${this.props.trial.id}/updates/${trialUpdate.id}`,
      data: {
        trial_update: {
          deleted: true, deleted_by: this.props.current_user.id
        }
      },
      dataType: 'json',
      success: (data) => {

        this.setState({
          updates: data.updates,
          showSnackbar: true,
          snackbarText: 'Update was removed',
          lastDeleted: trialUpdate
        })
      },
      error: (data) => {
        this.setState({
          showSnackbar: true,
          snackbarText: 'Update could not be removed. Please try again.'
        })
      },
      complete: (data) => {
        setTimeout(() => {
          this.setState({
            showSnackbar: false,
            snackbarText: ''
          })
        }, 5000)
      }
    })
  }

  handleUndoClick(event, reason) {
    if (this.state.lastDeleted) {
      $.ajax({
        type: 'PUT',
        url: `/admin/trials/${this.props.trial.id}/updates/${this.state.lastDeleted.id}`,
        data: {
          trial_update: {
            deleted: null, deleted_by: null
          }
        },
        dataType: 'json',
        success: (data) => {
          const trialUpdates = update(this.state.updates, {
            [this.state.lastDeleted.source_id]: { $push: [this.state.lastDeleted] }
          })

          this.setState({
            updates: trialUpdates,
            lastDeleted: null
          })
        },
        error: (data) => {
          this.setState({
            showSnackbar: true,
            snackbarText: data.responseText
          })
        },
        complete: (data) => {
          setTimeout(() => {
            this.setState({
              showSnackbar: false,
              snackbarText: ''
            })
          }, 4000)
        }
      })
    }
  }

  render() {
    return (
      <Fragment>

        <Paper>
          <Flex mb={3}>
            <Box>
              <Typography variant="h6" component="h6">
                Updates
              </Typography>
            </Box>

            <Box ml='auto'>
              <Button tag="a" href={`/admin/trials/${this.props.trial.id}/updates/new`}>
                New Update
              </Button>
            </Box>

          </Flex>


          {this.state.updates.length > 0 ? this.state.updates.map((updateArray) =>

            <UpdateContainer
              key={updateArray[0]}
              trial={this.props.trial}
              updates={updateArray[1]}
              handleDeleteClick={this.handleDeleteClick}
            />

          ) :
            <Flex justifyContent='center' height="50%">
              <Box alignSelf='center' maxWidth="50%">
                <Box>
                  <Typography variant="body1">
                    No updates found for this trial
                  </Typography>
                </Box>
              </Box>
            </Flex>
          }
        </Paper>

        <Snackbar
          open={this.state.showSnackbar}
          message={this.state.snackbarText}
          handleUndoClick={this.handleUndoClick}
        />
      </Fragment>
    )
  }
}
