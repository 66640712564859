import React, { Component } from "react"
import PropTypes from "prop-types"
import Select from "../Select"
import Flex from "../Flex"
import Box from "../Box"
import Chip from '@material-ui/core/Chip';

export default class Filters extends Component {
  constructor(props) {
    super(props)
  }
  
  render() {
    // const selectedCollections = this.props.filterOptions.collections.filter(collection => this.props.filterValues.collection_ids.includes(collection.value) ? collection : null)
    // const selectedTargetMarkets = this.props.filterOptions.targetMarkets.filter(targetMarket => this.props.filterValues.target_market_ids.includes(targetMarket.value) ? targetMarket : null)
    // 
    // const selectedTargetSites = this.props.filterOptions.targetSites.filter(targetSite => this.props.filterValues.target_site_ids.includes(targetSite.value) ? targetSite : null)
    
    // const selectedTherapyTypes = this.props.filterOptions.therapyTypes.filter(therapyType => this.props.filterValues.therapy_type_ids.includes(therapyType.value) ? therapyType : null)
    
    // const selectedTechnologyGroups = this.props.filterOptions.technologyGroups.filter(technologyGroup => this.props.filterValues.technology_group_ids.includes(technologyGroup.value) ? technologyGroup : null)
    return (
      <Flex alignItems='center'>
        {/* <Box width="12%" mr={2}>
          <Select
            multiple
            label='Collections' 
            name='collection_ids'
            value={this.props.filterValues.collection_ids} 
            entities={this.props.filterOptions.collections}
            handleChange={this.props.handleFilterChange}
            renderValue={selected => (
              <Flex flexWrap='wrap'>
                {`${selectedCollections.length} chosen`}
              </Flex>
            )}
          />
        </Box> */}
        
        <Box width="12%" mr={2}>
          <Select 
            label='Type' 
            name='type'
            value={this.props.filterValues.type} 
            entities={this.props.filterOptions.typeOptions}
            handleChange={this.props.handleFilterChange}
            includeBlank={true}
          />
        </Box>
        
        {/* <Box width="12%" mr={2}>
          <Select 
            multiple
            label='Target Sites' 
            name='target_site_ids'
            value={this.props.filterValues.target_site_ids} 
            entities={this.props.filterOptions.targetSites}
            handleChange={this.props.handleFilterChange}
            includeBlank={true}
            renderValue={selected => (
              <Flex flexWrap='wrap'>
                {`${selectedTargetSites.length} chosen`}
              </Flex>
            )}
          />
        </Box> */}
        
        {/* <Box width="13%" mr={2}>
          <Select 
            multiple
            label='Target Market'
            name='target_market_ids' 
            value={this.props.filterValues.target_market_ids} 
            entities={this.props.filterOptions.targetMarkets}
            handleChange={this.props.handleFilterChange}
            includeBlank={true}
            renderValue={selected => (
              <Flex flexWrap='wrap'>
                {`${selectedTargetMarkets.length} chosen`}
              </Flex>
            )}
          />
        </Box> */}
        
        {/* <Box width="12%" mr={2}>
          <Select 
            multiple
            label='Therapy' 
            name='therapy_type_ids'
            value={this.props.filterValues.therapy_type_ids} 
            entities={this.props.filterOptions.therapyTypes}
            handleChange={this.props.handleFilterChange}
            includeBlank={true}
            renderValue={selected => (
              <Flex flexWrap='wrap'>
                {selectedTherapyTypes.length}
              </Flex>
            )}
          />
        </Box> */}
        
        {/* <Box width="12%" mr={2}>
          <Select 
            multiple
            label='Technology' 
            name='technology_group_ids'
            value={this.props.filterValues.technology_group_ids} 
            entities={this.props.filterOptions.technologyGroups}
            handleChange={this.props.handleFilterChange}
            includeBlank={true}
            renderValue={selected => (
              <Flex flexWrap='wrap'>
                {`${selectedTechnologyGroups.length} chosen`}
              </Flex>
            )}
          />
        </Box> */}
        
        {/* <Box width="12%" mr={1}>
          <Select 
            label='Characteristic Group' 
            name='characteristic_group_id'
            value={this.props.filterValues.characteristic_group_id} 
            entities={this.props.filterOptions.characteristicGroups}
            handleChange={this.props.handleFilterChange}
            includeBlank={true}
          />
        </Box>
        
        <Box width="12%">
          <Select 
            label='Characteristic Value' 
            name='characteristic_value_id'
            value={this.props.filterValues.characteristic_value_id} 
            entities={this.props.filterOptions.characteristicValues}
            handleChange={this.props.handleFilterChange}
            includeBlank={true}
          />
        </Box> */}
        
        {/* {this.props.sector.id != 7 ? '' : <Box className='ml2 mr1'>
          <SelectFilter 
            label='Polymer Type' 
            name='polymer_type'
            value={this.props.filterValues.polymer_type} 
            entities={this.props.filterOptions.polymerTypes}
            handleChange={this.props.handleFilterChange}
          />
        </Box>} */}
        
      </Flex>
    )
  }
}
