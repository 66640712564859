// ExportDialog.jsx
import React, { useState } from 'react';
import { 
  Dialog, DialogTitle, DialogContent, DialogActions, 
  Button, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio,
  Checkbox, FormGroup
} from '@material-ui/core';

const BASE_EXPORTABLE_FIELDS = [
  { value: 'short_name', label: 'Product Name' },
  { value: 'company_name', label: 'Company Name' },
  { value: 'status', label: 'Status' },
  { value: 'target_market', label: 'Target Markets' },
  { value: 'therapy_type', label: 'Therapy Types' }
];

const REMAINING_FIELDS = [
  { value: 'n_trials', label: 'Number of Trials' },
  { value: 'last_updated_at', label: 'Last Activity' }
];

const TARGET_SITE_FIELD = { value: 'target_site', label: 'Target Sites' };

const ExportDialog = ({ open, onClose, onExport, hasSelectedItems, sector }) => {
  const EXPORTABLE_FIELDS = React.useMemo(() => {
    return sector.abbr === 'Peripheral' 
      ? [...BASE_EXPORTABLE_FIELDS, TARGET_SITE_FIELD, ...REMAINING_FIELDS]
      : [...BASE_EXPORTABLE_FIELDS, ...REMAINING_FIELDS];
  }, [sector.abbr]);

  const [format, setFormat] = useState('csv');
  const [exportOption, setExportOption] = useState('all');
  const [selectedColumns, setSelectedColumns] = useState(EXPORTABLE_FIELDS.map(field => field.value));

  const handleFormatChange = (event) => setFormat(event.target.value);
  const handleExportOptionChange = (event) => setExportOption(event.target.value);
  
  const handleColumnToggle = (column) => {
    setSelectedColumns(prev => 
      prev.includes(column) 
        ? prev.filter(c => c !== column)
        : [...prev, column]
    );
  };

  const handleExport = () => {
    onExport(format, exportOption, selectedColumns);
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Export Results</DialogTitle>
      <DialogContent>
        <FormControl component="fieldset">
          <FormLabel component="legend">Format</FormLabel>
          <RadioGroup value={format} onChange={handleFormatChange}>
            <FormControlLabel value="csv" control={<Radio />} label="CSV" />
          </RadioGroup>
        </FormControl>

        <FormControl component="fieldset">
          <FormLabel component="legend">Option</FormLabel>
          <RadioGroup value={exportOption} onChange={handleExportOptionChange}>
            <FormControlLabel value="all" control={<Radio />} label="All Results" />
            {hasSelectedItems && (
              <FormControlLabel value="selected" control={<Radio />} label="Selected Items" />
            )}
          </RadioGroup>
        </FormControl>

        <FormControl component="fieldset">
          <FormLabel component="legend">Columns to Export</FormLabel>
          <FormGroup>
            {EXPORTABLE_FIELDS.map(field => (
              <FormControlLabel
                key={field.value}
                control={
                  <Checkbox 
                    checked={selectedColumns.includes(field.value)}
                    onChange={() => handleColumnToggle(field.value)}
                  />
                }
                label={field.label}
              />
            ))}
          </FormGroup>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">Cancel</Button>
        <Button onClick={handleExport} color="primary" variant="contained">
          Export
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ExportDialog;