import React from "react"
import ReactDOM from "react-dom"
import SearchInput from "../SearchInput"
import TrialAutocomplete from "../../../trials/TrialAutocomplete"

export default function Form(props) {
  return(
    <React.Fragment>
      <TrialAutocomplete
        trials={props.trials}
        handleSearchChange={props.handleTrialChange}
      />
    </React.Fragment>
  )
}
