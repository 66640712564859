import React, { Component, Fragment } from "react"
import { TableRow, TableCell } from "../../tables"
import IconButton from "../../IconButton"
import EditIcon from "@material-ui/icons/Edit"
import DeleteIcon from '@material-ui/icons/Delete';
import Link from "../../Link"

export default class StatusRow extends Component {
  constructor(props) {
    super(props)
    this.handleDeleteClick = this.handleDeleteClick.bind(this)
  }
  
  handleDeleteClick(e) {
    e.preventDefault()
    this.props.handleDeleteClick(this.props.status)
  }
  
  render() {
    const statusUrl = `/admin/regulatory_statuses/${this.props.status.id}/edit`
    const user = this.props.status.creator
    return(
      <Fragment>
        <TableRow>
          <TableCell>
            <Link href={statusUrl}>{this.props.status.name}</Link>
          </TableCell>
          <TableCell>
            {this.props.status.world_regions.length > 0 ? this.props.status.world_regions.map((region) =>
              <div key={region.id}>
                {region.name}
              </div>
            ) : 'None'}
          </TableCell>
          <TableCell>
            {this.props.status.approval_status ? 'YES' : 'NO'}
          </TableCell>
          <TableCell>
            {user ? displayInitials(user.first_name, user.last_name) : 'N/A'}
          </TableCell>
          <TableCell>
            <IconButton component="a" href={statusUrl}>
              <EditIcon />
            </IconButton>
          </TableCell>
          <TableCell>
            <IconButton onClick={this.handleDeleteClick}>
              <DeleteIcon />
            </IconButton>
          </TableCell>
        </TableRow>
      </Fragment>
    )
  }
}
