import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Paper from "../../Paper"
import { Table, TableHead, TableRow, TableCell, TableBody, TableHeadSort } from "../../tables"
import AdverseEventRow from "./AdverseEventRow"
import Snackbar from "../../Snackbar"
import update from "immutability-helper"
import Flex from "../../Flex"
import Box from "../../Box"
import Typography from "@material-ui/core/Typography"
import ReviewerFilters from "./ReviewerFilters"

export default class Reviewer extends Component {
  constructor(props) {
    super(props)
    this.handleDeleteClick = this.handleDeleteClick.bind(this)
    this.getAdverseEvents = this.getAdverseEvents.bind(this)
    this.handleFilterChange = this.handleFilterChange.bind(this)
    this.handleDateChange = this.handleDateChange.bind(this)
    this.handleRequestSort = this.handleRequestSort.bind(this)
    this.desc = this.desc.bind(this)
    this.stableSort = this.stableSort.bind(this)
    this.getSorting = this.getSorting.bind(this)
    this.state = { adverseEvents: this.props.adverse_events, date: this.props.date, sector_id: this.props.sector_id, product_id: this.props.product_id, order: 'desc', orderBy: 'date_received' }
  }

  handleDeleteClick(adverseEvent) {
    $.ajax({
      type: 'PUT',
      url: `/admin/product_fda_adverse_events/${adverseEvent.product_fda_adverse_events_id}`,
      data: {
        product_fda_adverse_event: {
          deleted: true, deleted_by: this.props.current_user.id
        }
      },
      dataType: 'json',
      success: (data) => {
        let index = this.state.adverseEvents.indexOf(adverseEvent)

        let adverseEvents = update(this.state.adverseEvents, {
          $splice: [[index, 1]]
        })

        this.setState({
          adverseEvents: adverseEvents,
          showSnackbar: true,
          snackbarText: 'Product association has been removed'
        })
      },
      error: (data) => {
        this.setState({
          showSnackbar: true,
          snackbarText: 'Product association could not be removed. Please try again.'
        })
      },
      complete: (data) => {
        setTimeout(() => {
          this.setState({
            showSnackbar: false,
            snackbarText: ''
          })
        }, 4000)
      }
    })
  }

  handleDateChange(date) {
    let params = { sector_id: this.state.sector_id, product_id: this.state.product_id, date: this.state.date }

    params['date'] = date

    this.getAdverseEvents(params)
  }

  handleFilterChange(name, value) {
    let params = { sector_id: this.state.sector_id, product_id: this.state.product_id, date: this.state.date }

    params[name] = value

    this.getAdverseEvents(params)
  }

  getAdverseEvents(params) {
    $.ajax({
      type: 'GET',
      url: `/admin/adverse_events`,
      data: params,
      dataType: 'json',
      success: (data) => {
        this.setState({
          adverseEvents: data.adverse_events,
          params
        })
      },
      error: (data) => {
        this.setState({
          showSnackbar: true,
          snackbarText: 'Adverse events could not be found. Please try again.'
        })
      },
      complete: (data) => {
        setTimeout(() => {
          this.setState({
            showSnackbar: false,
            snackbarText: ''
          })
        }, 4000)
      }
    })
  }

  desc(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = cmp(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
  }

  getSorting(order, orderBy) {
    return order === 'desc' ? (a, b) => this.desc(a, b, orderBy) : (a, b) => -this.desc(a, b, orderBy);
  }

  handleRequestSort(event, property) {
    const isDesc = this.state.orderBy === property && this.state.order === 'desc';

    this.setState({
      order: isDesc ? 'asc' : 'desc',
      orderBy: property
    })
  };

  render() {
    let headers = [{ label: 'Report Key', name: 'mdr_report_key' }, { label: 'PMA', name: 'pma_number' }, { label: '510(k) Number', name: 'k_number' }, { label: 'Device', name: 'brand_name' }, { label: 'Product', name: 'short_name' }, { label: 'Date', name: 'date_received' }, { label: 'Delete', name: '' }]
    return(
      <Fragment>

        <Paper fullHeight={true}>

          <Flex alignItems='center' mb={2}>
            <ReviewerFilters
              sector_id={this.state.sector_id}
              product_id={this.state.product_id}
              date={this.state.date}
              products={this.props.products}
              sectors={this.props.sectors}
              handleFilterChange={this.handleFilterChange}
              handleDateChange={this.handleDateChange}
            />

            <Box ml='auto'>
              <Typography variant="body2">
                {`${this.state.adverseEvents.length} Adverse Events`}
              </Typography>
            </Box>
          </Flex>

          <Box overflow='auto' height="90%">
            <Table size='small' aria-label='adverse events table' stickyHeader={true}>

              <TableHeadSort
                headers={headers}
                order={this.state.order}
                orderBy={this.state.orderBy}
                onRequestSort={this.handleRequestSort}
              />

              <TableBody>
                {this.stableSort(this.state.adverseEvents, this.getSorting(this.state.order, this.state.orderBy))
                  .map((adverseEvent) =>
                    <AdverseEventRow
                      key={adverseEvent.id}
                      adverseEvent={adverseEvent}
                      handleDeleteClick={this.handleDeleteClick}
                    />
                )}
              </TableBody>

            </Table>
          </Box>
        </Paper>

        <Snackbar
          open={this.state.showSnackbar}
          message={this.state.snackbarText}
        />

      </Fragment>
    )
  }
}
