import React from "react"
import { Table, TableRow, TableCell, TableHead, TableBody } from "../../tables"
import Link from "../../Link"


export default function NewPublicationsModule(props) {
  return(
    <Table>
      <TableBody>
        {props.newPublications.map((publication) =>
          <TableRow key={publication.id}>
            <TableCell>
              <Link href={`/admin/publications/${publication.id}`}>
                {publication.title}
              </Link>
            </TableCell>
            <TableCell>{humanDateFormat(publication.created_at)}</TableCell>
            {/* <TableCell>{displayInitials(publication.first_name, publication.last_name)}</TableCell> */}
          </TableRow>
        )}
      </TableBody>
    </Table>
  )
}
