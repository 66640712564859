import React from 'react'
import { Table, TableBody, TableHeadSort, TableRow, TableCell } from '../tables'
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TablePaginationActions from "../tables/TablePaginationActions"
import ApprovalsRow from './ApprovalsRow'
import LinearProgress from '@material-ui/core/LinearProgress';

export default function ApprovalsTable(props) {
  let headers = [{ label: 'Agency', name: 'country_abbr' }, { label: 'Device', name: 'device_name' }, { label: "Manufacturer", name: "manufacturer" }, { label: 'Date', name: 'date' }]

  return (
    <Table stickyHeader size="small">
      <TableHeadSort 
        headers={headers}
        order={props.orderDirection}
        orderBy={props.orderBy}
        onRequestSort={props.handleRequestSort}
      />

      <TableBody>
        <React.Fragment>
          {props.loading ?
            <TableRow hover={false}>
              <TableCell noBorder colSpan={5}>
                <LinearProgress />
              </TableCell>
            </TableRow>
          : ""}
        </React.Fragment>
        {props.deviceApprovals.map((approval) =>
          <ApprovalsRow 
            key={approval.id}
            approval={approval}
            sector={props.sector}
            handleDeleteClick={props.handleDeleteClick}
          />
        )}
      </TableBody>
      {props.handlePageChange ? 
        <TableFooter>
          <TablePagination
            colSpan={7}
            count={props.deviceApprovalsCount}
            rowsPerPage={100}
            page={props.page}
            SelectProps={{
              inputProps: { 'aria-label': 'rows per page' },
              native: true,
            }}
            onChangePage={props.handlePageChange}
            onChangeRowsPerPage={props.handleChangeRowsPerPage}
            ActionsComponent={TablePaginationActions}
          />
        </TableFooter>
      : ""}
    </Table>
  )
}