import React, { Fragment } from "react"
import PropTypes from "prop-types"
import Flex from "../Flex"
import Box from "../Box"
import FilterChip from '../filters/FilterChip';


export default function FilterValueDisplay(props) {
  
  const selectedProducts = props.products.filter(product => props.productIds.includes(product.value) ? product : null)
  
  return(
    <Fragment>
      <Flex flexWrap='wrap' mb={2} mt={2}>
        {selectedProducts.map((activeFilter) =>
          <FilterChip 
            key={activeFilter.value}
            activeFilter={activeFilter}
            type='product_ids'
            handleDelete={props.handleDelete}
          />
        )}
      </Flex>
    </Fragment>
  )
}
