import React, { Fragment } from "react"
import { ListItem, ListItemText } from "../../../lists"
import Checkbox from "../../../Checkbox"


export default function EntityListItem(props) {
  
  function handleCheckboxSelect(e) {
    props.handleCheckboxSelect(props.company.id, 'activeCompanies')
  }
  
  return (
    <Fragment>
      <ListItem button key={props.company.id} onClick={handleCheckboxSelect} role={undefined}>
        
        <Checkbox 
          checked={props.checked} 
        />
        
        <ListItemText primary={props.company.short_name} />
        
      </ListItem>
    </Fragment>
  )
}
