import React from "react"
import PropTypes from "prop-types"
import Paper from "../../Paper"
import Flex from "../../Flex"
import Box from "../../Box"
import ReactS3Uploader from 'react-s3-uploader'
import LinearProgress from '@material-ui/core/LinearProgress';
import Snackbar from "../../Snackbar"
import update from "immutability-helper"
import Divider from '@material-ui/core/Divider';
import Link from "../../Link"

class Uploader extends React.Component {
  constructor(props) {
    super(props)
    this.onUploadProgress = this.onUploadProgress.bind(this)
    this.onUploadFinish = this.onUploadFinish.bind(this)
    this.onUploadError = this.onUploadError.bind(this)
    this.onUploadStart = this.onUploadStart.bind(this)
    this.state = { files: this.props.files, progress: null, showSnackbar: false, snackbarText: "", uploadFiles: [] }
  }

  onUploadStart(file, next) {
    console.log('Pre-process: ',file.name);

    let files = update(this.state.uploadFiles, {
      $push: [file]
    })

    this.setState({
      uploadFiles: files
    })

    next(file);
  }

  onUploadProgress(percent, message) {
    console.log('Upload progress: ' + percent + '% ' + message);

    this.setState({
      progress: percent
    })
  }

  onUploadFinish(signResult) {
    $.ajax({
      type: 'GET',
      url: `/admin/abstracts/${this.props.abstract.id}/s3_files`,
      dataType: 'json',
      success: (data) => {
        this.setState({
          files: data.files,
          progress: null
        })
      }
    })
  }

  onUploadError(message) {
    let errorMessage = `Upload error: ${message}`

    this.setState({
      showSnackbar: true,
      snackbarText: errorMessage
    })
  }


  render () {

    return (
      <React.Fragment>

        <Paper minFullHeight>
          <Flex alignItems='center'>
            <Box>
              <h2>Upload</h2>
            </Box>

            {this.props.abstract.media_url || this.props.abstract.url ?
              <Box ml={3}>
                <Link href={this.props.abstract.media_url || this.props.abstract.url} target="_blank">
                  {this.props.abstract.media_url || this.props.abstract.url}
                </Link>
              </Box>
            : ""}
          </Flex>

          <ReactS3Uploader
            signingUrl={`/admin/abstracts/${this.props.abstract.id}/abstract_s3_url`}
            signingUrlMethod="GET"
            accept="pdf/*"
            preprocess={this.onUploadStart}
            onProgress={this.onUploadProgress}
            onFinish={this.onUploadFinish}
            onError={this.onUploadError}
            s3path={this.props.s3_path}
            signingUrlWithCredentials={ true }
            uploadRequestHeaders={{}}
            scrubFilename={(filename) => filename.replace(/[^\w\d_\-.]+/ig, '')}
            inputRef={cmp => this.uploadInput = cmp}
            autoUpload={true}
            multiple={true}
          />

          <Box width={1}>
            {this.state.progress ?
              this.state.uploadFiles.map((file) =>
                <Box mb={2} key={file.name}>
                  <span>{file.name}</span>
                  <LinearProgress variant="determinate" value={this.state.progress} />
                </Box>
              )
            : ""}
          </Box>

          <Box mt={5}>
            <Flex alignItems='center'>
              <Box>
                <h2>Files</h2>
              </Box>

              <Box ml={2}>
                {`${this.state.files.length}`}
              </Box>
            </Flex>

            {this.state.files.map((file) =>
              <React.Fragment key={file.key}>
                <Box mb={3}>
                  <Box width={1/2}>
                    <img src={file.url} />
                    <Box>{file.key}</Box>
                  </Box>
                  <Divider />
                </Box>
              </React.Fragment>
            )}
          </Box>

        </Paper>

        <Snackbar
          open={this.state.showSnackbar}
          message={this.state.snackbarText}
        />


      </React.Fragment>
    );
  }
}

export default Uploader
