import React from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import NewContainerTabs from "./NewContainerTabs"
import SourceForm from "../SourceForm"
// import SectorForm from "./SectorForm"
import ProfileForm from "./ProfileForm"
import Header from "../../Header"
import Paper from "../../Paper"
import Snackbar from "../../Snackbar"
import Chip from '@material-ui/core/Chip';
import update from "immutability-helper"
import Box from "../../Box"
import NewTrialForm from "./NewTrialForm"

class NewContainer extends React.Component {
  constructor(props) {
    super(props)
    this.handleSourceChange = this.handleSourceChange.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleSwitchChange = this.handleSwitchChange.bind(this)
    this.handleSaveClick = this.handleSaveClick.bind(this)
    this.handleActiveIndexUpdate = this.handleActiveIndexUpdate.bind(this)
    this.handleNameChange = this.handleNameChange.bind(this)
    this.handleCheckboxSelect = this.handleCheckboxSelect.bind(this)
    this.state = { trial: this.props.trial, full_name: null, short_name: null, acronym: null, activeIndex: 0, source: null, showSnackbar: false, snackbarText: '', sector_ids: [], nct_id: '' }
  }

  componentDidMount() {
    let trial = update(this.state.trial, {
      user_id: { $set: this.props.current_user.id },
      source_id: { $set: this.props.source ? this.props.source.source_id : null }
    })

    this.setState({
      trial: trial,
      source: this.props.source ? this.props.source : null,
      activeIndex: this.props.source ? 1 : 0
    })
  }

  handleActiveIndexUpdate(e, newValue) {
    this.setState({
      activeIndex: newValue
    })
  }

  handleSwitchChange(e) {
    const name = e.target.name
    const value = e.target.checked

    let trial = update(this.state.trial, {
      [name]: { $set: value }
    })

    this.setState({
      trial: trial
    })
  }

  handleChange(e) {
    const name = e.target.name
    const value = e.target.value

    let trial = update(this.state.trial, {
      [name]: { $set: value }
    })

    this.setState({
      trial: trial
    })

  }

  handleCheckboxSelect(sector) {
    let index = this.state.sector_ids.indexOf(sector.id)
    let sector_ids = ""
    if (index == -1) {
      sector_ids = update(this.state.sector_ids, {
        $push: [sector.id]
      })
    } else {
      sector_ids = update(this.state.sector_ids, {
        $splice: [[index, sector.id]]
      })
    }

    this.setState({
      sector_ids: sector_ids
    })
  }

  handleNameChange(e) {
    const name = e.target.name
    const value = e.target.value

    this.setState({
      [name]: value
    })
  }

  handleSaveClick(e) {
    console.log(this.state.source)
    console.log(this.state.trial)
    if (this.state.trial.source_id) {

      let trial_names_attributes = {}
      let names = ["full_name", "short_name", "acronym"]

      names.map((name, index) =>
        this.state[name] ? trial_names_attributes[index] = { created_by: this.props.current_user.id, name: name, name_value: this.state[name], source_id: this.state.trial.source_id } : ''
      )

      let trial_sectors_attributes = {}
      this.state.sector_ids.map((sector_id, index) =>
        trial_sectors_attributes[index] = { created_by: this.props.current_user.id, sector_id: sector_id }
      )

      let trial = update(this.state.trial, {
        $merge: {
          trial_names_attributes: trial_names_attributes,
          trial_sectors_attributes: trial_sectors_attributes
        }
      })

      $.ajax({
        type: 'POST',
        url: "/admin/trials",
        data: {
          trial: {
            user_id: this.state.trial.user_id, source_id: this.state.trial.source_id, trial_names_attributes: trial_names_attributes, trial_sectors_attributes: trial_sectors_attributes
          }
        },
        dataType: 'json',
        success: (data) => {
          window.location = `/admin/trials/${data.trial.id}/edit`
        },
        error: (data) => {
          console.log(data.responseText)
        }
      })
    } else {
      this.setState({
        showSnackbar: true,
        snackbarText: 'You must choose a source before creating the trial'
      })
      setTimeout(() => {
        this.setState({
          showSnackbar: false,
          snackbarText: ''
        })
      }, 4000)
    }
  }

  handleSourceChange(entity, sourceType) {
    if (sourceType == 'recently_used') {
      this.setState({
        source: entity
      })
    } else {
      $.get("/admin/sources/find_source", { source_type: sourceType, entity_id: entity.id }, (data) => {
        if (data.source) {
          let trial = update(this.state.trial, {
            source_id: { $set: data.source.source_id }
          })
          this.setState({
            source: data.source,
            trial: trial
          })
        } else {
          $.post("/admin/sources/filter", { source: { kind: sourceType, entity_id: entity.id } }, (data) => {
            let trial = update(this.state.trial, {
              source_id: { $set: data.source.source_id }
            })
            this.setState({
              source: data.source,
              trial: trial
            })
          })
        }
      }, 'json')
    }
  }

  render () {
    return (
      <React.Fragment>

        <Paper minFullHeight>

          <div className='w-100 tc mb4'>
            <NewContainerTabs
              activeIndex={this.state.activeIndex}
              handleActiveIndexUpdate={this.handleActiveIndexUpdate}
            />
          </div>

          <Box display="flex" justifyContent="center" mb={3}>
            <Box>
              {this.state.source ?
                <Chip
                  label={this.state.source.source_title}
                />
              : <Box>Please choose a source</Box>}
            </Box>
          </Box>

          {this.state.activeIndex == 0 ?
            <SourceForm
              trial={this.props.trial}
              source={this.state.source}
              sources={this.props.recent_sources}
              sourceTypes={this.props.source_types}
              sourceType={this.props.source_type}
              redirectUrl='/admin/trials/new'
              handleSourceChange={this.handleSourceChange}
            />
          : ''}

          {this.state.activeIndex == 1 ?
            <NewTrialForm
              trial={this.state.trial}
              full_name={this.state.full_name}
              short_name={this.state.short_name}
              sector_ids={this.state.sector_ids}
              acronym={this.state.acronym}
              sectors={this.props.sectors}
              handleChange={this.handleChange}
              handleNameChange={this.handleNameChange}
              handleSwitchChange={this.handleSwitchChange}
              handleSaveClick={this.handleSaveClick}
              handleCheckboxSelect={this.handleCheckboxSelect}
            />
          : ''}

        </Paper>

        <Snackbar
          open={this.state.showSnackbar}
          message={this.state.snackbarText}
        />

      </React.Fragment>
    );
  }
}

export default NewContainer
