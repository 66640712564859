import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import TextInput from "../../TextInput"
import ButtonLayout from "../ButtonLayout"
import RaisedButton from "../../buttons/RaisedButton"


class ProfileForm extends Component {
  constructor(props) {
    super(props)
  }
  
  render() {
    return(
      <Fragment>
        
        <div className='mv4'>
          <TextInput 
            name='name' 
            label='Name' 
            value={this.props.profileFieldName.name} 
            onChange={this.props.handleChange} 
            className='w-100' 
          />
        </div>
        
        <ButtonLayout>
          <RaisedButton className='w-25' handleClick={this.props.handleSaveClick}>
            Save Profile Field Name
          </RaisedButton>
        </ButtonLayout>
        
      </Fragment>
    )
  }
}


export default ProfileForm
