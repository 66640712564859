import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import { TableRow, TableCell } from "../../tables"
import IconButton from "../../IconButton"
import DeleteButton from "../DeleteButton"
import FavoriteIcon from "@material-ui/icons/Favorite"
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder"
import FilterChip from "../../filters/FilterChip"
import Box from "../../Box"

export default function ProductStatusRow(props) {

  function handleDeleteClick(e) {
    props.handleDeleteClick(props.productStatuses[0])
  }

  function handleActiveClick(e) {
    props.handleActiveClick(props.productStatuses[0])
  }

  console.log(props.productStatuses[0].source_data.source_title)
  return(
    <TableRow>

      <TableCell>
        <IconButton onClick={handleActiveClick}>
          {props.productStatuses[0].active ? <FavoriteIcon /> : <FavoriteBorderIcon />}
        </IconButton>
      </TableCell>

      <TableCell>
        {props.productStatuses[0].region}
      </TableCell>

      <TableCell>
        {props.productStatuses[0].status}
      </TableCell>

      <TableCell>
        {props.productStatuses.map((productStatus) => {
          return(
            <Box mb={1}>
              <FilterChip
                name={productStatus.sector}
                handleDelete={() => { props.handleSectorDelete(productStatus.regulatory_status_sector_id) }}
              />
            </Box>
          )
        })}
      </TableCell>

      <TableCell>
        {props.productStatuses[0].source_data ? props.productStatuses[0].source_data.source_title : "N/A"}
      </TableCell>

      <TableCell>
        {dateFormat(props.productStatuses[0].date)}
      </TableCell>

      <TableCell>
        {dateFormat(props.productStatuses[0].created_at)}
      </TableCell>

      <TableCell>
        {props.productStatuses[0].first_name ? displayInitials(props.productStatuses[0].first_name, props.productStatuses[0].last_name) : 'N/A'}
      </TableCell>

      <TableCell>
        <DeleteButton onClick={handleDeleteClick} />
      </TableCell>

    </TableRow>
  )
}
