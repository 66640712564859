import React, { Component } from "react"
import PropTypes from "prop-types"
import ReactDOM from "react-dom"
import Link from "../Link"
import { Table, TableRow, TableCell, TableHeader, TableBody } from "../tables"
import Button from "../buttons/Button"
import ExpandLess from '../ExpandLess';
import ExpandMore from '../ExpandMore';


export default class YearsEntities extends Component {
  constructor(props) {
    super(props)
    this.state = { open: true }
  }

  render () {
    return (
      <React.Fragment>

        <TableRow hover={false}>
          <TableCell colSpan={4} className='pv2'>
            <Button onClick={() => this.setState({ open: !this.state.open })} color='default' lowercase={true} className='f5'>
              {this.state.open ? <ExpandLess /> : <ExpandMore />}
              {this.props.year}
            </Button>
          </TableCell>
        </TableRow>

        {/* <Collapse in={this.state.open} timeout="auto" unmountOnExit component="tr"> */}
          {this.state.open ? this.props.data.map((entity) =>
            <TableRow key={entity.id}>
              <TableCell>{entity.entity_info}</TableCell>
              <TableCell>
                {entity.name || (entity.acronym ? entity.acronym : entity.short_name)}
              </TableCell>
            </TableRow>
          ) : ''}
        {/* </Collapse> */}
      </React.Fragment>
    );
  }
}
