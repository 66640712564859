import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Typography from "@material-ui/core/Typography"
import Filters from "./Filters"
import CompareAttributeChip from "../CompareAttributeChip"
import CompareEntities from "./CompareEntities"
import DataView from "./DataView"
import Flex from "../Flex"
import Box from "../Box"
import Select from "../Select"
import { CSVLink, CSVDownload } from "react-csv";
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import IconButton from "../IconButton"

export default class CompareEntitiesContainer extends Component {
  constructor(props) {
    super(props)
    this.handleTuneClick = this.handleTuneClick.bind(this)
    this.handleCsvClick = this.handleCsvClick.bind(this)
    this.state = { showChips: false }
  }

  handleTuneClick(e) {
    e.preventDefault()
    this.setState({
      showChips: !this.state.showChips
    })
  }

  handleCsvClick(event, done) {
    $.get(`/sectors/${this.props.sector.abbr}/log_csv_download?for=${this.props.entityType}_compare`).then(() => {
      done(); // REQUIRED to invoke the logic of component
    });
  }

  render() {
    let csvHeaders = [{ key: 'name', label: this.props.entityType }]

    this.props.fields.map((field) => {
      csvHeaders.push({ key: field, label: field })
    })

    let csvData = []
    this.props.entities.map((entity) => {
      let csvDataHash = { name: entity.name }

      csvHeaders.map((csvHeader) => {
        if (csvHeader.key != "name") {
          csvDataHash[csvHeader.key] = entity.fields[csvHeader.key] ? entity.fields[csvHeader.key].map(fieldVal => fieldVal.text ? (typeof(fieldVal.text) == "string" ? fieldVal.text.replace(/<[^>]*>/g, '') : fieldVal.text) : fieldVal.value).join("; ") : ""
        }
      })

      csvData.push(csvDataHash)
    })

    let chosenFieldsLabel = this.props.fields.length == this.props.attributeOptions.length ? 'All' : `${this.props.fields.length} chosen`

    let attributeStatusTypeValue = this.props.entityType == 'products' ? this.props.filterValues.attribute_status_type == "true" || this.props.filterValues.attribute_status_type == true ? true : false : null

    const selectedAttributeStatusType = this.props.entityType == 'products' ? this.props.attributeStatusTypes.filter(attributeStatusType => attributeStatusTypeValue == attributeStatusType.value ? attributeStatusType : null) : ''

    const selectedWorldRegion = this.props.entityType == 'products' ? this.props.worldRegions.filter(worldRegion => this.props.filterValues.world_region_id == worldRegion.value ? worldRegion : null) : ''

    const inInvestigationalView = this.props.entityType == 'products' ? this.props.filterValues.attribute_status_type == false || this.props.filterValues.attribute_status_type == "false" ? false : true : ''

    return(
      <React.Fragment>
        <Box mb={3}>
          <Flex alignItems='center'>
            {this.props.entityType == 'products' ?
              <Fragment>

                {this.props.sector.market_id != 3 ?
                  <Box width="12%">
                    <Select
                      label='Status'
                      name='attribute_status_type'
                      value={this.props.filterValues.attribute_status_type}
                      entities={this.props.attributeStatusTypes}
                      handleChange={this.props.handleFilterChange}
                    />
                  </Box>
                : ""}

                {this.props.worldRegions.length >= 1 ?
                  <Box width="12%" mx={2}>
                    <Select
                      label='Region'
                      name='world_region_id'
                      // disabled={inInvestigationalView}
                      value={this.props.filterValues.world_region_id}
                      entities={this.props.worldRegions}
                      handleChange={this.props.handleFilterChange}
                      // renderValue={selected => (
                      //   <Flex flexWrap='wrap'>
                      //     {inInvestigationalView ? 'NA' : selectedWorldRegion[0].name}
                      //   </Flex>
                      // )}
                    />
                  </Box>
                : <Box mx={2}/>}
              </Fragment>
            : ''}

            <Box width="12%">
              <Select
                multiple
                label='Attributes'
                name='attribute'
                value={this.props.fields}
                entities={this.props.attributeOptions}
                handleChange={this.props.handleChipSelection}
                renderValue={selected => (
                  <Flex flexWrap='wrap'>
                    {chosenFieldsLabel}
                  </Flex>
                )}
              />
            </Box>

            {this.props.sector.market_id != 3 ?
              <Box ml='auto'>
              <span className='green'>Text</span> = Investigational
            </Box> : ""}

            <Box ml='auto'>
              <CSVLink
                data={csvData}
                headers={csvHeaders}
                filename={`market-monitors-${this.props.entityType}-comparison.csv`}
                asyncOnClick={true}
                onClick={this.handleCsvClick}
              >
                <IconButton tooltip='Download CSV file' size='small'>
                  <CloudDownloadIcon style={{height: '22px', width: '22px'}} />
                </IconButton>
              </CSVLink>
            </Box>
          </Flex>

          {/* {this.state.showChips ?
            <Flex flexWrap='wrap'>
              {this.props.allFields.map((field) =>
                <Box key={field} mx={1} mb={1}>
                  <CompareAttributeChip
                    fields={this.props.fields}
                    field={field}
                    handleChipSelection={this.props.handleChipSelection}
                  />
                </Box>
              )}
            </Flex>
          : ''} */}
        </Box>


        <CompareEntities
          entities={this.props.entities}
          activeEntity={this.props.activeEntity}
          sector={this.props.sector}
          entityType={this.props.entityType}
          allFields={this.props.allFields}
          fields={this.props.fields}
          humanizedFields={this.props.humanizedFields}
          rootUrl={this.props.rootUrl}
          handleAddEntityClick={this.props.handleAddEntityClick}
          handleEntityRemovalClick={this.props.handleEntityRemovalClick}
        />

      </React.Fragment>
    )
  }
}
