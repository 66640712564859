import React, { Component, Fragment } from "react"
import Paper from "../../../Paper"
import Flex from "../../../Flex"
import Box from "../../../Box"
import { Table, TableHead, TableBody, TableCell, TableRow } from "../../../tables"
import Link from "../../../Link"
import Select from "../../../Select"
import { withSnackbar } from "../../../SnackbarContainer"
import LinearProgress from "@material-ui/core/LinearProgress"
import InlineSearchContainer from "../../search/InlineSearchContainer"

class StagedReviewer extends Component {
  constructor(props) {
    super(props)
    this.handleSectorChange = this.handleSectorChange.bind(this)
    this.handleSearchClick = this.handleSearchClick.bind(this)
    this.handleClearSearchClick = this.handleClearSearchClick.bind(this)
    this.getTrials = this.getTrials.bind(this)
    this.state = { trials: this.props.trials, trialsCount: this.props.trials_count, sector_id: this.props.sector_id, query: "" }
  }

  handleSearchClick(query) {
    $.ajax({
      type: "GET",
      url: `/admin/trials/product_reviewer`,
      data: {
        query: query
      },
      dataType: "json",
      success: (data) => {
        this.setState({
          trials: data.trials,
          trialsCount: data.trials_count,
          query: query
        })
      },
      error: (data) => {
        this.props.snackbarShowMessage("There was an error when searching trials. Please try again.")
      }
    })
  }

  handleClearSearchClick(e) {
    let params = { sector_id: this.state.sector_id }

    this.getTrials(params)
  }

  handleSectorChange(name, value) {
    let params = { sector_id: value }
    
    this.getTrials(params)
  }

  getTrials(params) {
    $.ajax({
      type: "GET",
      url: "/admin/trials/product_reviewer",
      data: params,
      dataType: 'json',
      beforeSend: (data) => {
        this.setState({
          loading: true
        })
      },
      success: (data) => {
        this.setState({
          trials: data.trials,
          trialsCount: data.trials_count,
          loading: false,
          sector_id: data.sector_id
        })
      },
      error: (data) => {
        this.setState({
          loading: false
        })

        this.props.snackbarShowMessage("There was an error getting trials. Please try again.")
      }
    })
  }

  render() {
    return(
      <Fragment>
        <Paper fullHeight>

          <Flex alignItems='center' mb={2}>
            <Box>
              <Select
                includeBlank
                label='Sector'
                name='sector_id'
                value={this.state.sector_id}
                entities={this.props.sectors}
                handleChange={this.handleSectorChange}
              />
            </Box>

            <Box ml={3}>
              <InlineSearchContainer
                searchLabel="Search trials" 
                helperText=""
                query={this.state.query}
                handleSearchClick={this.handleSearchClick}
                handleClearSearchClick={this.handleClearSearchClick}
              />
            </Box>

            <Box ml='auto'>
              {`${this.state.trialsCount} trials`}
            </Box>
          </Flex>

          <Box height="90%" overflow='auto'>
            <Table size='small' aria-label='trial product review table' stickyHeader={true}>
              <TableHead>
                <TableRow>
                  <TableCell>Trial</TableCell>
                  <TableCell>NCT ID</TableCell>
                  <TableCell>Products</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                <Fragment>
                  {this.state.loading ?
                    <TableRow hover={false}>
                      <TableCell noBorder colSpan={5}>
                        <LinearProgress />
                      </TableCell>
                    </TableRow>
                  : ""}
                  {this.state.trials.map((trial) =>
                    <TableRow key={trial.id}>
                      <TableCell>
                        <Link target="_blank" href={`/admin/trials/${trial.trial_id}/associator`}>
                          {trial.acronym || trial.short_name}
                        </Link>
                      </TableCell>
                      <TableCell>{trial.nct_id}</TableCell>
                      <TableCell>{trial.product_count}</TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  )}
                </Fragment>
              </TableBody>
            </Table>
          </Box>

        </Paper>
      </Fragment>
    )
  }
}


export default withSnackbar(StagedReviewer)
