import React, { Component, Fragment } from "react"
import PropTypes from "prop-types"
import ReactDOM from "react-dom"
import Typography from "@material-ui/core/Typography"
import Flex from "../Flex"
import Box from "../Box"
import Paper from "../Paper"
import { Table, TableRow, TableCell, TableHead, TableBody } from "../tables"

export default class EnrollmentProfile extends Component {
  constructor(props) {
    super(props)
  }

  render () {
    const days = diffDays(this.props.enrollmentStartDate, this.props.enrollmentEndDate)
    const [currentEnrollment] = this.props.enrollmentNumbers.slice(-1)
    const rate = days / currentEnrollment
    return (
      <Fragment>
        <Box mx="auto" width={{ xs: 1, lg: 1/2}}>
          <Box py={2} borderTop={1} borderLeft={1} borderRight={1} borderColor='#e0e0e0'>
            <Typography variant="body1">
              Enrollment Profile
            </Typography>
          </Box>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell borderRight={true} borderLeft={true} borderTop={true}>
                  Enrollment start date
                </TableCell>
                <TableCell borderRight={true} borderTop={true}>
                  {humanDateFormat(this.props.enrollmentStartDate)}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell borderRight={true} borderLeft={true}>
                  Enrollment end date
                </TableCell>
                <TableCell borderRight={true}>
                  {humanDateFormat(this.props.enrollmentEndDate)}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell borderRight={true} borderLeft={true}>
                  Status
                </TableCell>
                <TableCell borderRight={true}>
                  {this.props.status ? `${this.props.status.status} (${this.props.status.date_display ? humanDateFormat(this.props.status.date_display) : 'NA'})` : 'NA'}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell borderRight={true} borderLeft={true}>
                  Duration
                </TableCell>
                <TableCell borderRight={true}>
                  {days} Days
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell borderRight={true} borderLeft={true}>
                  Rate
                </TableCell>
                <TableCell borderRight={true}>
                  {Math.round(rate)} days per patient enrolled
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Box>
      </Fragment>
    );
  }
}
