import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Paper from "../../Paper"
import SourceForm from "../SourceForm"
import NewProductForm from "./NewProductForm"
import Chip from '@material-ui/core/Chip';
import Snackbar from "../../Snackbar"
import update from "immutability-helper"
import Tabs from '../../tabs/Tabs';
import Tab from '../../tabs/Tab';
import Box from "../../Box"


class NewProductContainer extends Component {
  constructor(props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
    this.handleSaveClick = this.handleSaveClick.bind(this)
    this.handleSourceChange = this.handleSourceChange.bind(this)
    this.handleActiveIndexUpdate = this.handleActiveIndexUpdate.bind(this)
    this.handleCompanyChange = this.handleCompanyChange.bind(this)
    this.handleCheckboxSelect = this.handleCheckboxSelect.bind(this)
    this.state = { product: this.props.product, source: null, activeIndex: 0, showSnackbar: false, snackbarText: '', productSectors: [] }
  }

  componentDidMount() {
    let product = update(this.state.product, { user_id: { $set: this.props.current_user.id }})
    this.setState({
      product: product
    })
  }

  handleCheckboxSelect(sector, checked) {
    let sectors = update(this.state.productSectors, {
      $push: [sector.id]
    })

    console.log(sectors)

    this.setState({
      productSectors: sectors
    })
  }

  handleActiveIndexUpdate(e, newValue) {
    this.setState({
      activeIndex: newValue
    })
  }

  handleCompanyChange(name, value) {
    let product = update(this.state.product, { [name]: { $set: value }})

    this.setState({
      product: product
    })
  }

  handleChange(e) {
    const name = e.target.name
    const value = e.target.value

    let product = update(this.state.product, { [name]: { $set: value } })
    this.setState({
      product: product
    })

  }

  handleSaveClick(e) {
    if (this.state.product.source_id) {
      let productSectorObject = {}

      this.state.productSectors.map((productSector, index) => productSectorObject[index] = { sector_id: productSector, created_by: this.props.current_user.id } )

      $.post("/admin/products", { product: { full_name: this.state.product.full_name, short_name: this.state.product.short_name, root_name: this.state.product.root_name, company_id: this.state.product.company_id, user_id: this.props.current_user.id, source_id: this.state.product.source_id, pma_number: this.state.product.pma_number ? this.state.product.pma_number : null, product_sectors_attributes: productSectorObject } }, (data) => {
        window.location.href = `/admin/products/${data.product.id}/edit`
      }, 'json').fail((data) => {
        console.log(data.responseText)
        console.log(data)
        this.setState({
          showSnackbar: true,
          snackbarText: `Product could not be saved. ${data.responseText}.`
        })
      }, 'html')
    } else {
      this.setState({
        showSnackbar: true,
        snackbarText: 'You must choose a source before saving the product'
      })
      setTimeout(() => {
        this.setState({
          showSnackbar: false,
          snackbarText: ''
        })
      }, 4000)
    }
  }

  handleSourceChange(entity, sourceType) {
    if (sourceType == 'recently_used') {
      this.setState({
        source: entity
      })
    } else {
      $.get("/admin/sources/find_source", { source_type: sourceType, entity_id: entity.id }, (data) => {
        if (data.source) {
          let product = update(this.state.product, {
            source_id: { $set: data.source.source_id }
          })
          this.setState({
            source: data.source,
            product: product
          })
        } else {
          $.post("/admin/sources/filter", { source: { kind: sourceType, entity_id: entity.id } }, (data) => {
            let product = update(this.state.product, {
              source_id: { $set: data.source.source_id }
            })
            this.setState({
              source: data.source,
              product: product
            })
          })
        }
      }, 'json')
    }
  }

  render() {
    return(
      <Fragment>

        <Paper minFullHeight>

          <Box mb={4} width={1}>
            <Tabs variant="fullWidth" value={this.state.activeIndex} onChange={this.handleActiveIndexUpdate} className='bb b--black-10' aria-label="nav tabs">
              <Tab label='Source' />
              <Tab label='Profile' />
            </Tabs>
          </Box>

          <Box display="flex" justifyContent="center" mb={3}>
            <Box>
              {this.state.source ?
                <Chip
                  label={this.state.source.source_title}
                />
              : <Box>Please choose a source</Box>}
            </Box>
          </Box>

          {this.state.activeIndex == 0 ?
            <SourceForm
              source={this.state.source}
              sources={null}
              sourceTypes={this.props.source_types}
              sourceType={this.props.source_type}
              redirectUrl='/admin/products/new'
              handleSourceChange={this.handleSourceChange}
            />
          : ''}

          {this.state.activeIndex == 1 ?
            <NewProductForm
              product={this.state.product}
              companies={this.props.companies}
              sectors={this.props.sectors}
              productSectors={this.state.productSectors}
              handleChange={this.handleChange}
              handleSaveClick={this.handleSaveClick}
              handleCompanyChange={this.handleCompanyChange}
              handleCheckboxSelect={this.handleCheckboxSelect}
            />
          : ''}

        </Paper>

        <Snackbar
          open={this.state.showSnackbar}
          message={this.state.snackbarText}
        />
      </Fragment>
    )
  }
}


export default NewProductContainer
