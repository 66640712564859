import React from "react"
import MuiTab from '@material-ui/core/Tab';
import { makeStyles } from '@material-ui/core/styles';
import clsx from "clsx"

const useStyles = makeStyles(theme => ({
  root: {
    minWidth: '72px'
  },
  small: {
    minHeight: '40px',
    height: '40px'
  }
}));


export default function Tab(props) {
  const { small, ...rest } = props
  
  const classes = useStyles(props);
  
  return (
    <MuiTab 
      className={clsx(classes.root, { [classes.small]: small })} 
      {...rest} 
    />
  )
}
