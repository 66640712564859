import React from "react"
import { Table, TableHead, TableBody, TableCell, TableRow } from "../../tables"
import Typography from '@material-ui/core/Typography';
import TrialProductRow from "./TrialProductRow"
import Box from "../../Box"

export default function ProductTrials(props) {
  return (
    <Box mt={4}>
      <Typography variant="h6" component="h6">
        Products
      </Typography>

      <Table className='mh2'>

        <TableHead>
          <TableRow>
            <TableCell>Product</TableCell>
            <TableCell>Source</TableCell>
            <TableCell>Type</TableCell>
            <TableCell>User</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {props.trialProducts.length > 0 ? props.trialProducts.map((trialProductHash) =>
            <TrialProductRow
              key={trialProductHash.trial_product.id}
              trialProductHash={trialProductHash}
              handleDeleteClick={props.handleDeleteClick}
            />
          ) : <TableRow><TableCell colSpan={4}>No products yet. Add one above.</TableCell></TableRow>}
        </TableBody>

      </Table>
    </Box>
  )
}
