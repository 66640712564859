import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import update from "immutability-helper"
import Form from "../people/Form"
import Paper from "../../Paper"
import Flex from "../../Flex"
import Box from "../../Box"
import Select from "../../Select"
import TextInput from "../../TextInput"
import Typography from "@material-ui/core/Typography"
import ButtonLayout from "../ButtonLayout"
import { RaisedButton, Button } from "../../buttons"


export default class NewContainer extends Component {
  constructor(props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
    this.handleNameSearchChange = this.handleNameSearchChange.bind(this)
    this.handleRoleChange = this.handleRoleChange.bind(this)
    this.handleSaveClick = this.handleSaveClick.bind(this)
    this.handlePersonClick = this.handlePersonClick.bind(this)
    this.handleNameChange = this.handleNameChange.bind(this)
    this.handleNewPersonClick = this.handleNewPersonClick.bind(this)
    this.handleRemovePersonClick = this.handleRemovePersonClick.bind(this)
    this.state = { companyPerson: this.props.company_person, showSnackbar: false, snackbarText: '', openDialog: false, suggestions: [], query: '', chosenPerson: null, firstName: '', lastName: '' }
  }

  handleChange(e) {
    let companyPerson = update(this.state.companyPerson, {
      [e.target.name]: { $set: e.target.value }
    })

    this.setState({
      companyPerson: companyPerson
    })
  }

  handleRemovePersonClick(e) {
    this.setState({
      chosenPerson: null
    })
  }

  handlePersonClick(person) {
    console.log(person)

    let companyPerson = update(this.state.companyPerson, {
      person_id: { $set: person.person_id }
    })

    this.setState({
      companyPerson: companyPerson,
      query: '',
      chosenPerson: person
    })

  }

  handleNameSearchChange(e) {
    const value = e.target.value

    console.log(value)

    if (value == '' || value == null) {
      this.setState({
        suggestions: []
      })
    } else {
      $.ajax({
        type: "GET",
        url: "/admin/people/search",
        data: {
          query: value
        },
        dataType: 'json',
        success: (data) => {
          this.setState({
            suggestions: data.people,
            query: value
          })
        }
      })
    }
  }

  handleRoleChange(name, value) {
    let companyPerson = update(this.state.companyPerson, {
      role: { $set: value }
    })

    this.setState({
      companyPerson: companyPerson
    })
  }

  handleNameChange(e) {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  handleNewPersonClick(e) {
    $.ajax({
      type: 'POST',
      url: `/admin/people/create_record`,
      data: { first_name: this.state.firstName, last_name: this.state.lastName, created_by: this.props.current_user.id },
      dataType: 'json',
      success: (data) => {

        let companyPerson = update(this.state.companyPerson, {
          person_id: { $set: data.person_name.person_id }
        })

        this.setState({
          showSnackbar: true,
          snackbarText: 'Person has been added',
          chosenPerson: data.person_name,
          firstName: '',
          lastName: '',
          companyPerson: companyPerson
        })
      },
      error: (data) => {
        this.setState({
          showSnackbar: true,
          snackbarText: 'There was an error adding the person. Please try again.'
        })
      },
      complete: (data) => {
        setTimeout(() => {
          this.setState({
            showSnackbar: false,
            snackbarText: ''
          })
        }, 4000)
      }
    })
  }

  handleSaveClick() {
    $.ajax({
      type: 'POST',
      url: `/admin/companies/${this.props.company.id}/people`,
      data: {
        company_person: this.state.companyPerson
      },
      dataType: 'json',
      success: (data) => {
        window.location.href = `/admin/companies/${this.props.company.id}/people`
      },
      error: (data) => {
        this.setState({
          showSnackbar: true,
          snackbarText: 'There was an error creating the statement. Make sure a source has been selected'
        })
      },
      complete: (data) => {
        setTimeout(() => {
          this.setState({
            showSnackbar: false,
            snackbarText: ''
          })
        }, 4000)
      }
    })
  }

  render() {
    return(
      <Paper fullHeight>
        <Flex width={1} mb={5} alignItems='center' justifyContent='space-between'>
          <Box width={1/3}>
            <TextInput
              name='title'
              label='Title'
              value={this.state.companyPerson.title}
              onChange={this.handleChange}
              fullWidth
            />
          </Box>

          <Box width={1/3} mx={3}>
            <TextInput
              name='previous_affiliation'
              label='Previous Affiliation'
              value={this.state.companyPerson.previous_affiliation}
              onChange={this.handleChange}
              fullWidth
            />
          </Box>

          <Box width={1/3}>
            <Select
              entities={this.props.roles}
              label='Role'
              name='role'
              value={this.state.companyPerson.role}
              handleChange={this.handleRoleChange}
            />
          </Box>
        </Flex>

        <Box mt={3}>
          {this.state.chosenPerson ?
            <Box>
              <Flex>
                <Box>
                  <Typography variant="h6">
                    Person
                  </Typography>
                </Box>

                <Box ml={3}>
                  <Button onClick={this.handleRemovePersonClick}>
                    Remove
                  </Button>
                </Box>


              </Flex>

              <Box mt={2}>
                {this.state.chosenPerson.text}
              </Box>
            </Box>
          :
            <Form
              query={this.state.query}
              suggestions={this.state.suggestions}
              handlePersonClick={this.handlePersonClick}
              handleChange={this.handleNameSearchChange}
              firstName={this.state.firstName}
              lastName={this.state.lastName}
              handleNameChange={this.handleNameChange}
              handleNewPersonClick={this.handleNewPersonClick}
            />
          }
        </Box>

        <ButtonLayout>
          <RaisedButton handleClick={this.handleSaveClick}>
            Save Company Person
          </RaisedButton>
        </ButtonLayout>
      </Paper>
    )
  }
}
