import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Flex from "../../Flex"
import Box from "../../Box"
import Typography from '@material-ui/core/Typography';
import update from "immutability-helper"
import AttributeOptionDisplay from "./AttributeOptionDisplay"
import TargetMarketListItemContainer from "./TargetMarketListItemContainer"
import TargetMarketListItem from "./TargetMarketListItem"
import { Button } from "../../buttons"
import { List, ListItem, ListItemText } from "../../lists"
import ExpandLess from '../../ExpandLess';
import ExpandMore from '../../ExpandMore';
import Collapse from '@material-ui/core/Collapse';
import Snackbar from "../../Snackbar"

export default class TargetMarketForm extends Component {
  constructor(props) {
    super(props)
    this.newTargetMarket = this.newTargetMarket.bind(this)
    this.handleCheckboxSelect = this.handleCheckboxSelect.bind(this)
    this.handleCheckboxDelete = this.handleCheckboxDelete.bind(this)
    this.state = { productTargetMarkets: this.props.productTargetMarkets, open: false }
  }

  handleCheckboxSelect(targetMarket, productTargetMarket) {

    if (this.props.source) {

      this.newTargetMarket(targetMarket, productTargetMarket)

    } else {
      this.setState({
        showSnackbar: true,
        snackbarText: 'You must choose a source'
      })
    }
  }

  newTargetMarket(targetMarket, productTargetMarket) {
    let mainParams = { product_id: this.props.product.id, target_market_id: targetMarket.id, created_by: this.props.currentUser.id, source_id: this.props.source.source_id, world_region_id: (this.props.activeRegion == 1000000 ? null : this.props.activeRegion), investigational: (this.props.activeRegion == 1000000 ? true : null) }

    $.post("/admin/product_target_markets", { product_target_market: mainParams }, (data) => {
      const productTargetMarket = data.product_target_market

      const productTargetMarkets = update(this.state.productTargetMarkets, {
        $push: [productTargetMarket]
      })

      this.setState({
        productTargetMarkets: productTargetMarkets,
        showSnackbar: true,
        snackbarText: 'Target market was added'
      })
    }, 'json').fail((data) => {
      console.log(data.responseText)
      this.setState({
        showSnackbar: true,
        snackbarText: `Target market could not be added`
      })
    }).always((data) => {
      setTimeout(() => {
        this.setState({
          showSnackbar: false,
          snackbarText: ''
        })
      }, 4000)
    })
  }

  handleCheckboxDelete(productTargetMarket) {
    $.ajax({
      type: 'PUT',
      url: `/admin/product_target_markets/${productTargetMarket.id}`,
      data: {
        product_target_market: {
          deleted: true, deleted_by: this.props.currentUser.id
        }
      },
      dataType: 'json',
      success: (data) => {
        const index = this.state.productTargetMarkets.indexOf(productTargetMarket)

        const productTargetMarkets = update(this.state.productTargetMarkets, {
          $splice: [[index,1]]
        })

        this.setState({
          productTargetMarkets: productTargetMarkets,
          showSnackbar: true,
          snackbarText: 'Target market has been removed'
        })
      },
      error: (data) => {
        this.setState({
          showSnackbar: true,
          snackbarText: 'The target market could not be removed'
        })
      },
      complete: (data) => {
        setTimeout(() => {
          this.setState({
            showSnackbar: true,
            snackbarText: ''
          })
        }, 5000)
      }
    })
  }

  render() {
    console.log(this.state.productTargetMarkets)
    return(
      <Fragment>

        <List>

          <ListItem button onClick={() => this.setState({open: !this.state.open})}>
            {this.state.open ? <ExpandLess /> : <ExpandMore />}
            <ListItemText primary='Target markets' />
          </ListItem>


          <Collapse in={this.state.open} timeout="auto" unmountOnExit>
            {this.state.productTargetMarkets ?
              <List component="div" disablePadding>
                {this.props.targetMarkets.map((targetMarket) =>
                  <TargetMarketListItemContainer
                    key={targetMarket.id}
                    targetMarket={targetMarket}
                    activeRegion={this.props.activeRegion}
                    productTargetMarkets={this.state.productTargetMarkets}
                    handleCheckboxSelect={this.handleCheckboxSelect}
                    handleCheckboxDelete={this.handleCheckboxDelete}
                  />
                )}
              </List>
            : ''}
          </Collapse>



        </List>

        <Snackbar
          open={this.state.showSnackbar}
          message={this.state.snackbarText}
        />

      </Fragment>
    )
  }
}
