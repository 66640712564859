import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Paper from "../../Paper"
import Flex from "../../Flex"
import Box from "../../Box"
import EntityCard from "../EntityCard"

export default class PublicationCard extends Component {
  constructor(props) {
    super(props)
  }
  
  render() {
    return(
      <Fragment>
        <EntityCard className='mb4 pointer w-90 center' handleClick={this.props.handleProductCardClick} active={this.props.active} entity={this.props.product}>
          
          <Flex mb={2}>
            <Box width={1/4} mr={2}>
              <label className='b'>Product</label>
            </Box>
            
            <Box width={3/4} ml='auto'>
              {this.props.product.short_name}
            </Box>
          </Flex>
          
        </EntityCard>
      </Fragment>
    )
  }
}
