import React from "react"
import { TableRow, TableCell } from "../../tables"
import Link from "../../Link"
import DeleteButton from "../DeleteButton"
import Box from "../../Box"
import FdaLink from "./FdaLink"

export default function Row(props) {

  function handleDeleteClick(e) {
    props.handleDeleteClick(props.fdaKClearance)
  }

  return(
    <TableRow>
      <TableCell>
        <FdaLink 
          fdaKClearance={props.fdaKClearance}
          linkText={props.fdaKClearance.k_number}
        />
      </TableCell>
      <TableCell>
        <Link href={`/admin/fda_k_clearances/${props.fdaKClearance.id}/associator`}>
          <Box>
            {props.fdaKClearance.device_name}
          </Box>
        </Link>
      </TableCell>
      <TableCell>
        {props.fdaKClearance.applicant}
      </TableCell>
      <TableCell>
        {props.fdaKClearance.product_code}
      </TableCell>
      <TableCell>
        {props.fdaKClearance.created_at.split("T")[0]}
      </TableCell>
      <TableCell>
        <DeleteButton 
          onClick={handleDeleteClick}
        />
      </TableCell>
    </TableRow>
  )
}
