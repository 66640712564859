import React, { Fragment } from 'react';
import ReactDOM from "react-dom"
import PropTypes from 'prop-types';
import MuiAppBar from '@material-ui/core/AppBar';
import Link from "./Link"
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { Button, OutlinedButton } from "./buttons"
import Flex from "./Flex"
import Box from "./Box"
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Popover from '@material-ui/core/Popover';

const drawerWidth = 180;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    minHeight: '128px',
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    color: 'rgba(0, 0, 0, 0.87)',
    backgroundColor: '#fff'
  },
  title: {
    flexGrow: 0.5,
  },
  logo: {
    flexGrow: 0.5,
  }
}));

export default function AppBar(props) {
const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const classes = useStyles();

  return (
    <Fragment>

      <MuiAppBar position="fixed" id="app-bar" color='white'>
        <Toolbar variant='dense' component={Box} px={2} disableGutters minHeight='51.43px'>

          {/* <div className={classes.title}>
          </div> */}

          <Box ml={4}>
            <Link href='/' className='f4'>
              <Typography className='mmi-blue' variant="h6">
                MarketMonitors
              </Typography>
              {/* <span className='black'>Monitors</span> */}
            </Link>
          </Box>

          <Box ml='auto'>
            <Flex alignItems='center'>
              {/* <Box>
                <Box onMouseEnter={handleClick}>
                  Products
                </Box>
                <Menu
                  id="simple-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  <MenuItem component='a' href='/pvpipeline'>PVPipeline</MenuItem>
                  <MenuItem component='a' href='https://cvpipeline.com' target="_blank">CVPipeline</MenuItem>
                  <MenuItem onClick={handleClose}>AORTICPipeline</MenuItem>
                </Menu>
              </Box> */}

              {/* <Box>
                <Link href='/about'>
                  About
                </Link>
              </Box> */}

              <Box>
                <Link href='/contact_us'>
                  Contact
                </Link>
              </Box>

              <Box ml={3}>
                <Button href='/login'>
                  Login
                </Button>
              </Box>
            </Flex>
          </Box>

        </Toolbar>

      </MuiAppBar>

    </Fragment>
  );
}
