import React, { Fragment, Component } from "react"
import Paper from "../../Paper"
import TextInput from "../../TextInput"
import { RaisedButton } from "../../buttons"
import { withSnackbar } from "../../SnackbarContainer"
import ButtonLayout from "../ButtonLayout"
import Flex from "../../Flex"
import Box from "../../Box"
import Select from "../../Select"
import Checkbox from "../../Checkbox"
import update from "immutability-helper"

class NewContainer extends Component {
  constructor(props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
    this.handleSaveClick = this.handleSaveClick.bind(this)
    this.handleMarketChange = this.handleMarketChange.bind(this)
    this.handleCheckboxClick = this.handleCheckboxClick.bind(this)
    this.state = { sector: this.props.sector }
  }

  handleChange(e) {
    let name = e.target.name
    let value = e.target.value

    let sector = update(this.state.sector, {
      [name]: { $set: value }
    })

    this.setState({
      sector: sector
    })
  }

  handleMarketChange(name, value) {
    let sector = update(this.state.sector, {
      market_id: { $set: value }
    })

    this.setState({
      sector: sector
    })
  }

  handleCheckboxClick(e) {
    let name = e.target.name
    let value = e.target.checked

    let sector = update(this.state.sector, {
      [name]: { $set: value }
    })

    this.setState({
      sector: sector
    })
  }

  handleSaveClick(e) {
    $.ajax({
      type: 'POST',
      url: `/admin/sectors`,
      data: {
        sector: this.state.sector
      },
      dataType: 'json',
      success: (data) => {
        window.location.href = "/admin/sectors"
      },
      error: (data) => {
        this.props.snackbarShowMessage("There was an error creating the sector. Please try again.", "error")
      }
    })
  }

  render() {
    return(
      <Paper fullHeight>

        <Flex alignItems='center' width={1/2} mb={3}>
          <Box width={3/4} mr={3}>
            <Select
              label='Market'
              name='market_id'
              value={this.state.sector.market_id}
              entities={this.props.markets}
              handleChange={this.handleMarketChange}
              includeBlank={true}
            />
          </Box>

          <Box mr={2}>
            <Checkbox
              name='live'
              label='Live'
              checked={this.state.sector.live}
              onClick={this.handleCheckboxClick}
            />
          </Box>

          <Box>
            <Checkbox
              name='monitor_product'
              label='Monitor'
              checked={this.state.sector.monitor_product}
              onClick={this.handleCheckboxClick}
            />
          </Box>
        </Flex>

        <Flex alignItems='center'>
          <Box width={1/2} mr={2}>
            <TextInput
              id='name'
              name='name'
              label='Name'
              value={this.state.sector.name}
              onChange={this.handleChange}
              variant='outlined'
              margin='normal'
              fullWidth={true}
            />
          </Box>

          <Box width={1/2}>
            <TextInput
              name='abbr'
              label='Abbreviation'
              value={this.state.sector.abbr}
              onChange={this.handleChange}
              variant='outlined'
              margin='normal'
              fullWidth={true}
            />
          </Box>
        </Flex>

        <ButtonLayout>
          <RaisedButton handleClick={this.handleSaveClick}>
            Save Sector
          </RaisedButton>
        </ButtonLayout>

      </Paper>
    )
  }
}

export default withSnackbar(NewContainer)
