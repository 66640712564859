import React, { Component } from 'react'
import PropTypes from 'prop-types'
import InlineSearchContainer from "../search/InlineSearchContainer"
import Flex from '../../Flex'
import Box from '../../Box'
import Select from "../../Select"

export default class IndexFilters extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <Flex>
        <Box mr={3} width={1/5}>
          <Select
            label='Product Code'
            name='product_codes'
            value={this.props.filterValues.product_code}
            entities={this.props.filterOptions.product_codes}
            handleChange={this.props.handleFilterChange}
            includeBlank={true}
          />
        </Box>

        <Box mr={3} width={1/5}>
          <Select
            label='Association Type'
            name='association_type'
            value={this.props.filterValues.association_type}
            entities={this.props.filterOptions.association_types}
            handleChange={this.props.handleFilterChange}
            includeBlank={true}
          />
        </Box>

        <Box width={1/3}>
          <InlineSearchContainer
            searchLabel={this.props.searchLabel}
            helperText={this.props.helperText}
            query={this.props.filterValues.query}
            handleSearchClick={this.props.handleSearchClick}
            handleClearSearchClick={this.props.handleClearSearchClick}
          />
        </Box>
      </Flex>
    )
  }
}
