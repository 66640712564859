import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Typography from "@material-ui/core/Typography"


export default class Dashboard extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    return(
      <Fragment>


        <Typography>
          COMING SOON
        </Typography>
      </Fragment>
    )
  }
}
