import React from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Typography from "@material-ui/core/Typography"
import Flex from "../Flex"
import Box from "../Box"
import Paper from "../Paper"
import { Table, TableRow, TableHead, TableCell, TableBody } from "../tables"
import Link from "../Link"
import Tabs from "../tabs/Tabs"
import Tab from "../tabs/Tab"
import FdaLink from "../pma_approvals/FdaLink"

export default class PmaApproval extends React.Component {
  constructor(props) {
    super(props)
    this.handleActiveIndexUpdate = this.handleActiveIndexUpdate.bind(this)
    this.desc = this.desc.bind(this)
    this.stableSort = this.stableSort.bind(this)
    this.getSorting = this.getSorting.bind(this)
    this.state = { activeIndex: this.props.pma_approval.id, order: 'asc', orderBy: 'supplement_number' }
  }

  // componentDidMount() {
  //   let pmaApprovalIds = this.props.pma_approvals.map(pmaApproval => pmaApproval.id)
  //   let index = pmaApprovalIds.indexOf(this.props.pma_approval.id)
  //
  //   this.setState({
  //     activeIndex: index
  //   })
  // }

  desc(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = cmp(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
  }

  getSorting(order, orderBy) {
    return order === 'desc' ? (a, b) => this.desc(a, b, orderBy) : (a, b) => -this.desc(a, b, orderBy);
  }

  handleActiveIndexUpdate(e, newValue) {
    this.setState({
      activeIndex: newValue
    })
  }

  render() {
    return (
      <React.Fragment>

        <Paper>

          <Box mb={2} fontSize={14}>
            <Link href={`/sectors/${this.props.sector.abbr}/products/${this.props.product.id}/pma_approvals`}>
              Back to list
            </Link>
          </Box>

          {this.props.pma_approvals.length > 0 ?
            <Tabs variant="scrollable" scrollButtons="auto" value={this.state.activeIndex} className='bb b--black-10' aria-label="nav tabs">
              {this.stableSort(this.props.pma_approvals, this.getSorting(this.state.order, this.state.orderBy)).map((pmaApproval) =>
                <Tab
                  key={pmaApproval.id}
                  component="a"
                  value={pmaApproval.id}
                  href={`/sectors/${this.props.sector.abbr}/products/${this.props.product.id}/pma_approvals/${pmaApproval.id}`}
                  label={pmaApproval.supplement_number || pmaApproval.pma_number}
                />
              )}
            </Tabs>
          : ''}

          <Box mt={3}>
            <Table>
              <TableHead>
                <TableRow hover={false}>
                  <TableCell borderRight={true}>
                    <FdaLink 
                      pmaApproval={this.props.pma_approval} 
                    />
                  </TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {this.props.pma_approval_attributes.map((pmaApprovalAttributeHash) =>
                  <TableRow key={pmaApprovalAttributeHash.attr_value}>
                    <TableCell borderRight={true} fontWeight="bold" className='w-25'>
                      {pmaApprovalAttributeHash.header}
                    </TableCell>
                    <TableCell>
                      {pmaApprovalAttributeHash.date ? humanDateFormat(this.props.pma_approval[pmaApprovalAttributeHash.attr_value]) : this.props.pma_approval[pmaApprovalAttributeHash.attr_value] }
                    </TableCell>
                  </TableRow>
                )}
                {this.props.supplements.length > 0 ? <TableRow>
                  <TableCell borderRight={true} fontWeight="bold" className='w-25'>
                    Supplements
                  </TableCell>
                  <TableCell>
                    <Flex flexWrap='wrap'>
                      {this.props.supplements.map((supplement) =>
                        <Box key={supplement.id} mr={2}>
                          <Link href={`/sectors/${this.props.sector.abbr}/products/${this.props.product.id}/pma_approvals/${supplement.id}`}>
                            {supplement.supplement_number}
                          </Link>
                        </Box>
                      )}
                    </Flex>
                  </TableCell>
                </TableRow> : ''}
              </TableBody>
            </Table>
          </Box>

        </Paper>
      </React.Fragment>
    )
  }
}
