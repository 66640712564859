import React from 'react'
import { Table, TableBody, TableCell, TableRow, TableHead } from '../../../tables'
import PredictedRow from './PredictedRow'

export default function PredictionsTable(props) {
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell />
          <TableCell className='bg-white'>510(k) #</TableCell>
          <TableCell className='bg-white'>Device</TableCell>
          <TableCell className='bg-white'>Applicant</TableCell>
          <TableCell className='bg-white'>Product Code</TableCell>
          <TableCell className='bg-white'>Date Received</TableCell>
          <TableCell className='bg-white'></TableCell>
        </TableRow>
      </TableHead>

      <TableBody>
        {props.kClearancePredictions.map((kClearance) =>
          <PredictedRow 
            key={kClearance.id}
            kClearance={kClearance}
            checkedKClearances={props.checkedKClearances}
            handleDeleteClick={props.handleDeleteClick}
            handleKClearanceCheckboxClick={props.handleKClearanceCheckboxClick}
          />
        )}
      </TableBody>
    </Table>
  )
}