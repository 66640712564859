import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import { Table, TableHead, TableRow, TableCell, TableBody, TableHeadSort } from "../../tables"
import SessionPresentations from "./SessionPresentations"
import Box from "../../Box"
import IndeterminateCheckBoxIcon from '@material-ui/icons/IndeterminateCheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import IconButton from "../../IconButton"
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Checkbox from '@material-ui/core/Checkbox';
import TriageRow from "./TriageRow"

export default class TriageTable extends Component {
  constructor(props) {
    super(props)
    this.handleRequestSort = this.handleRequestSort.bind(this)
    this.desc = this.desc.bind(this)
    this.stableSort = this.stableSort.bind(this)
    this.getSorting = this.getSorting.bind(this)
    this.state = { order: 'asc', orderBy: 'date' }
  }

  desc(a, b, orderBy) {
    let bOrderKey = b[orderBy]
    let aOrderKey = a[orderBy]
    if (bOrderKey < aOrderKey) {
      return -1;
    }
    if (bOrderKey > aOrderKey) {
      return 1;
    }
    return 0;
  }

  stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = cmp(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
  }

  getSorting(order, orderBy) {
    return order === 'desc' ? (a, b) => this.desc(a, b, orderBy) : (a, b) => -this.desc(a, b, orderBy);
  }

  handleRequestSort(event, property) {
    const isDesc = this.state.orderBy === property && this.state.order === 'desc';

    this.setState({
      order: isDesc ? 'asc' : 'desc',
      orderBy: property
    })
  };

  render() {
    let headers = [{ label: <IconButton onClick={this.props.handleBulkAbstractCheck} tooltip='Select' edge="start">{this.props.checkedAbstracts.length > 0 ? <IndeterminateCheckBoxIcon style={{height: '22px', width: '22px'}} /> : <CheckBoxOutlineBlankIcon style={{height: '22px', width: '22px'}} />}</IconButton>, name: '', noSort: true, checkbox: true, id: 'abstract-checkbox' }, { label: '#', name: 'number' }, { label: 'Date', name: 'date' }, { label: 'Time', name: 'start_time' }, { label: 'Title', name: 'title' }, { label: 'Presenter', name: 'presenter' }, { label: 'PDF', name: 'pdf' }, { label: 'Priority', name: 'priority_id'}, { label: 'Reporter', name: 'reporter_user_id' } ]
    return(
      <Fragment>

        <Box overflow='auto' maxHeight='85%'>
          <Table size='small' aria-label='triage table' stickyHeader={true}>

            <TableHeadSort
              headers={headers}
              order={this.state.order}
              orderBy={this.state.orderBy}
              onRequestSort={this.handleRequestSort}
            />

            <TableBody>
              {this.props.abstracts.length > 0 ?
                this.stableSort(this.props.abstracts, this.getSorting(this.state.order, this.state.orderBy)).map((abstract) =>
                <TriageRow
                  key={abstract.id}
                  abstract={abstract}
                  checkedAbstracts={this.props.checkedAbstracts}
                  handleAbstractCheckboxClick={this.props.handleAbstractCheckboxClick}
                />
              ) : ''}
            </TableBody>

          </Table>
        </Box>

      </Fragment>
    )
  }
}
