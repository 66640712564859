import React, { Component, Fragment } from "react"
import { TableRow, TableCell } from "../../tables"
import EditButton from "../EditButton"
import DeleteButton from "../DeleteButton"

export default function ResultRow(props) {

  function handleDeleteClick(e) {
    props.handleDeleteClick(props.result)
  }

  return(
    <Fragment>
      <TableRow>
        <TableCell>
          {props.result.result_name}
        </TableCell>
        <TableCell>
          {props.result.result_value}
        </TableCell>
        <TableCell>
          {props.result.source_id}
        </TableCell>
        <TableCell align='right'>
          {props.result.created_by}
        </TableCell>
        <TableCell align='right'>
          <EditButton component='a' href={`/admin/trials/${props.result.trial_id}/results/${props.result.id}/edit`} />
        </TableCell>
        <TableCell align='right'>
          <DeleteButton onClick={handleDeleteClick} />
        </TableCell>
      </TableRow>
    </Fragment>
  )
}
