import React, { Component, Fragment } from "react"
import { ListItem, ListItemText } from "./lists"
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ExpandLess from './ExpandLess';
import ExpandMore from './ExpandMore';
import Collapse from '@material-ui/core/Collapse';

class Expandable extends Component {
  constructor(props) {
    super(props)
    this.handleClick = this.handleClick.bind(this)
    this.state = { open: '' }
  }

  componentDidMount() {
    if (this.props.open) {
      this.setState({
        open: this.props.open
      })
    }
  }

  handleClick(e) {
    e.preventDefault()
    this.setState({
      open: !this.state.open
    })
  }

  render() {
    return(
      <Fragment className={this.state.open ? 'mb3' : ''}>
        <ListItem style={this.props.style} divider button onClick={() => this.setState({open: !this.state.open})}>
          <ListItemIcon>
            {this.state.open ? <ExpandLess /> : <ExpandMore />}
          </ListItemIcon>

          <ListItemText
            primary={this.props.header}
          />

          {/* {this.props.secondary ?
            <ListItemText
              secondary={this.props.secondary}
            />
          : ''} */}
        </ListItem>

        <Collapse in={this.state.open} timeout="auto" unmountOnExit>
          {this.props.children}
        </Collapse>
      </Fragment>
    )
  }
}

Expandable.defaultProps = {
  open: true
}

export default Expandable
