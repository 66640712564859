import React, { Fragment } from "react"
import Typography from "@material-ui/core/Typography"
import { ListItemLink, ListItemText } from "../lists"
import Box from "../Box"
import Flex from "../Flex"
import ProductCodeContainer from "../fda_device_classifications/ProductCodeContainer"
import ApplicantDisplay from "../ApplicantDisplay"

export default function DisplayItem(props) {

  let url = ""// props.admin ? `/admin/fda_k_clearances/${props.result.id}/associator` : props.result.url

  if (props.url) {
    url = props.url
  } else if (props.admin) {
    url = `/admin/fda_k_clearances/${props.result.id}/associator`
  } else {
    url = props.result.url
  }

  function renderSearchDetails(result) {
    return (
      <Flex alignItems='baseline'>
        <Box mr={2}>
          <ApplicantDisplay
            applicant={result.applicant}
            handleApplicantClick={props.handleApplicantClick}
          />
        </Box>
        <Box>
          {result.fda_device_classifications ? <ProductCodeContainer productCodes={result.fda_device_classifications} handleProductCodeClick={props.handleProductCodeClick} /> : ""}
        </Box>
      </Flex>
    );
  }

  return(
    <ListItemLink href={url} alignItems='flex-start' divider={true} target={props.target}>
      <ListItemText>
        <Typography variant="caption" color="textSecondary">
          {props.result.record_type}
        </Typography>

        <Box>
          <Typography variant="body1">
            {`${props.result.k_number} - ${props.result.device_name}`}
          </Typography>
        </Box>

        <Box mt={1}>
          {renderSearchDetails(props.result)}
        </Box> 
      </ListItemText>
    </ListItemLink>
  )
}

DisplayItem.defaultProps = {
  target: "_blank"
}