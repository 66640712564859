import React from "react"
import PropTypes from "prop-types"
import Paper from "../../Paper"
import Box from "../../Box"
import DevicesTable from "./DevicesTable"
import IndexFilters from "./IndexFilters"
import ListingsCountDisplay from '../../ListingsCountDisplay'


class IndexContainer extends React.Component {
  constructor(props) {
    super(props)
    this.handleSearchClick= this.handleSearchClick.bind(this)
    this.getNihDevices = this.getNihDevices.bind(this)
    this.handlePageChange = this.handlePageChange.bind(this)
    this.handleClearSearchClick = this.handleClearSearchClick.bind(this)
    this.handleFilterChange = this.handleFilterChange.bind(this)
    this.state = { nihDevices: this.props.nih_devices, nihDevicesCount: this.props.nih_devices_count, filterValues: { query: this.props.query, page: this.props.page, product_code: this.props.product_code, association_type: this.props.association_type, company_id: this.props.company_id, date: this.props.date }, filterOptions: { product_codes: this.props.product_codes, companies: this.props.companies, dates: this.props.dates } }
  }

  handleFilterChange(name, value) {
    let params = Object.assign({}, this.state.filterValues)

    params[name] = value

    this.getNihDevices(params)
  }

  handlePageChange(e, newPage) {
    let params = Object.assign({}, this.state.filterValues)

    params["page"] = newPage

    this.getNihDevices(params)
  }

  handleSearchClick(query) {
    let params = Object.assign({}, this.state.filterValues)

    params["query"] = query

    this.getNihDevices(params)
  }

  handleClearSearchClick(e) {
    let params = Object.assign({}, this.state.filterValues)

    params["query"] = null

    this.getNihDevices(params)
  }

  getNihDevices(params) {
    $.ajax({
      type: "GET",
      url: "/admin/nih_devices",
      data: params,
      dataType: 'json',
      success: (data) => {
        this.setState({
          nihDevices: data.nih_devices,
          nihDevicesCount: data.nih_devices_count,
          filterValues: params
        })
      }
    })
  }




  render () {
    return (
      <Paper fullHeight>
        <Box height="100%">

          <IndexFilters 
            filterOptions={this.state.filterOptions}
            filterValues={this.state.filterValues}
            handleSearchClick={this.handleSearchClick}
            handleClearSearchClick={this.handleClearSearchClick}
            handleFilterChange={this.handleFilterChange}
          />

          <ListingsCountDisplay 
            display={`${this.state.nihDevicesCount} NIH Devices`}
          />

          <Box height="90%" overflow='auto'>
            <DevicesTable 
              nihDevices={this.state.nihDevices}
              nihDevicesCount={this.state.nihDevicesCount}
              page={this.state.filterValues.page}
              handlePageChange={this.handlePageChange}
            />
          </Box>
        </Box>
      </Paper>
    );
  }
}

IndexContainer.propTypes = {
  nihDevices: PropTypes.array
};


export default IndexContainer
