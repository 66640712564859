import React from "react"
import Flex from "../Flex"
import Box from "../Box"
import Typography from "@material-ui/core/Typography"
import { List, ListItemLink, ListItemText } from "../lists"

export default function DashboardModuleList(props) {
  return(
    <React.Fragment>
      {props.publications.length > 0 ?
        <List dense={props.dense ? props.dense : false}>
          {props.publications.map((manuscript) =>
            <ListItemLink divider key={manuscript.id} href={`/sectors/${props.sector.abbr}/publications/${manuscript.id}`} target="_blank">
              <ListItemText
                primary={manuscript.title}
                secondary={manuscript.manuscript_journal_name}
              />
              <ListItemText secondary={manuscript.published_date ? manuscript.published_date.split("T")[0] : 'N/A'} className='tr' />
            </ListItemLink>
          )}
        </List>
      :
        <Flex justifyContent='center' height="50%">
          <Box alignSelf='center' maxWidth="50%">
            <Box>
              <Typography variant="body1">
                {props.emptyMessage ? props.emptyMessage : "No publications available"}
              </Typography>
            </Box>
          </Box>
        </Flex>
      }
    </React.Fragment>
  )
}