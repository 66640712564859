import React from "react";
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import MuiIconButton from '@material-ui/core/IconButton';
import Box from "../Box";
import Flex from "../Flex";
import SecondaryText from "../SecondaryText";

export default function FilterOption(props) {

  function handleFilterChange(e) {
    props.handleFilterChange(props.filterName, props.filterValue)
  }

  return(
    <div className='ph2'>
      <Flex alignItems='center'>
        <Box>
          <MuiIconButton onClick={handleFilterChange} edge="start">
            {props.checked ? <CheckBoxIcon color='primary' style={{height: '20px', width: '20px'}} /> : <CheckBoxOutlineBlankIcon style={{height: '20px', width: '20px'}} />}
          </MuiIconButton>
        </Box>

        <Box className='truncate f6' width={3/4}>
          {props.filterObject.name}
        </Box>

        <Box ml='auto'>
          <SecondaryText>
            {props.filterObject.count}
          </SecondaryText>
        </Box>
      </Flex>
    </div>
  )
}
