import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Paper from "../Paper"
import UpdateContainer from "./UpdateContainer"
import Flex from "../Flex"
import Box from "../Box"
import Select from "../Select"
import Typography from "@material-ui/core/Typography"
import FilterChip from '../filters/FilterChip';
import update from "immutability-helper"

class Updates extends Component {
  constructor(props) {
    super(props)
    this.handleFilterChange = this.handleFilterChange.bind(this)
    this.handleDelete = this.handleDelete.bind(this)
    this.getUpdates = this.getUpdates.bind(this)
    this.state = { updates: this.props.updates, filterValues: { changed_attribute_values: [], source_type_values: [] }, filterOptions: { changedAttributes: this.props.changed_attributes, sourceTypes: this.props.source_types } }
  }

  componentDidMount() {
    if (this.props.changed_attribute_values) {
      let filterValues = update(this.state.filterValues, {
        changed_attribute_values: {
          $push: [this.props.changed_attribute_values]
        }
      })


      this.setState({
        filterValues: filterValues
      })
    }
  }

  handleFilterChange(name, value) {
    let params = Object.assign({}, this.state.filterValues)

    if (value.includes(null)) {
      params[name] = []
    } else {
      params[name] = value
    }

    this.getUpdates(params)
  }

  handleDelete(value, type) {
    const index = this.state.filterValues[type].indexOf(value)

    const filterValues = update(this.state.filterValues, {
      [type]: { $splice: [[index, 1]] }
    })

    this.getUpdates(filterValues)
  }

  getUpdates(filterValues) {
    $.ajax({
      type: 'GET',
      url: `/sectors/${this.props.sector.abbr}/trials/${this.props.trial.id}/updates`,
      data: filterValues,
      dataType: 'json',
      success: (data) => {
        let filterOptions = { sourceTypes: data.source_types, changedAttributes: data.changed_attributes }

        this.setState({
          updates: data.updates,
          filterValues: filterValues,
          filterOptions
        })
      }
    })
  }

  render() {
    const selectedAttributes = this.state.filterOptions.changedAttributes.filter(changedAttribute => this.state.filterValues.changed_attribute_values.includes(changedAttribute.value) ? changedAttribute : null)
    const selectedSourceTypes = this.state.filterOptions.sourceTypes.filter(sourceType => this.state.filterValues.source_type_values.includes(sourceType.value) ? sourceType: null)
    return(
      <Fragment>

        <Paper minFullHeight>

          <Flex alignItems='center' mb={3}>
            <Box width="12%" mr={3}>
              <Select
                multiple
                label='Trial Attributes'
                name='changed_attribute_values'
                value={this.state.filterValues.changed_attribute_values}
                entities={this.state.filterOptions.changedAttributes}
                handleChange={this.handleFilterChange}
                renderValue={selected => (
                  <Flex>
                    <Box>{`${selectedAttributes.length} chosen`}</Box>
                  </Flex>
                )}
              />
            </Box>

            <Box width="12%">
              <Select
                multiple
                label='Source Type'
                name='source_type_values'
                value={this.state.filterValues.source_type_values}
                entities={this.state.filterOptions.sourceTypes}
                handleChange={this.handleFilterChange}
                renderValue={selected => (
                  <Flex>
                    <Box>{`${selectedSourceTypes.length} chosen`}</Box>
                  </Flex>
                )}
              />
            </Box>
          </Flex>

          <Flex mb={2}>
            {selectedAttributes.map((activeFilter) =>
              <FilterChip
                key={activeFilter.value}
                name={activeFilter.name}
                value={activeFilter.value}
                type='changed_attribute_values'
                handleDelete={this.handleDelete}
              />
            )}

            {selectedSourceTypes.map((activeFilter) =>
              <FilterChip
                key={activeFilter.value}
                name={activeFilter.name}
                value={activeFilter.value}
                type='source_type_values'
                handleDelete={this.handleDelete}
              />
            )}
          </Flex>

          {this.state.updates.length > 0 ? this.state.updates.map((updateArray) =>

            <UpdateContainer
              key={updateArray[0]}
              sector={this.props.sector}
              source={updateArray[0]}
              trial={this.props.trial}
              updateArray={updateArray}
              updates={updateArray[1]}
            />

          ) :
            <Flex justifyContent='center' height="50%">
              <Box alignSelf='center' maxWidth="50%">
                <Box>
                  <Typography variant="body1">
                    No updates found for this trial
                  </Typography>
                </Box>
              </Box>
            </Flex>
          }
        </Paper>

      </Fragment>
    )
  }
}

export default Updates
