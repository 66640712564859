import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Paper from "../../Paper"
import Flex from "../../Flex"
import Box from "../../Box"
import EntityCard from "../EntityCard"

export default class PublicationCard extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    console.log(this.props.publication)
    return(
      <Fragment>
        <EntityCard className='mb4 pointer w-90 center' handleClick={this.props.handlePublicationCardClick} active={this.props.active} entity={this.props.publication}>

          <Flex mb={2}>
            <Box width={1/4} mr={2}>
              <label className='b'>Journal</label>
            </Box>

            <Box width={3/4} ml='auto'>
              {this.props.publication.journal.short_name}
            </Box>
          </Flex>

          <Flex className='mb2'>
            <Box className='w-25 mr2'>
              <label className='b'>Citation</label>
            </Box>
            <Box className='w-75'>
              {this.props.publication.citation}
            </Box>
          </Flex>

          <Flex mb={2}>
            <Box width={1/4} mr={2}>
              <label className='b'>Title</label>
            </Box>
            <Box width={3/4} ml='auto'>
              {this.props.publication.title}
            </Box>
          </Flex>

          <Flex className='mb2'>
            <Box className='w-25 mr2'>
              <label className='b'>Authors</label>
            </Box>
            <Box className='w-75'>
              Authors here
            </Box>
          </Flex>

          <Flex className='mb2'>
            <Box className='w-25 mr2'>
              <label className='b'>Abstract</label>
            </Box>
            <Box className='w-75'>
              {this.props.publication.abstract}
            </Box>
          </Flex>

          <Flex className='mb2'>
            <Box className='w-25 mr2'>
              <label className='b'>Rank</label>
            </Box>
            <Box className='w-75'>
              {this.props.publication.rank ? 'N/A' : this.props.publication.rank}
            </Box>
          </Flex>

        </EntityCard>
      </Fragment>
    )
  }
}
