import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import { TableRow, TableCell } from "../../tables"
import IconButton from '@material-ui/core/IconButton';
import FavoriteIcon from '@material-ui/icons/Favorite';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import DeleteIcon from '@material-ui/icons/Delete';

class TrialStatusRow extends Component {
  constructor(props) {
    super(props)
    this.handleDeleteClick = this.handleDeleteClick.bind(this)
  }
  
  handleDeleteClick(e) {
    this.props.handleDeletion(this.props.trialStatus.trial_status)
  }
  
  render() {
    const user = this.props.trialStatus.creator
    return(
      <TableRow>
        
        <TableCell>
          <IconButton onClick={this.props.handleFavoriteClick}>
            {this.props.trialStatus.trial_status.active ? <FavoriteIcon /> : <FavoriteBorderIcon />}
          </IconButton>
        </TableCell>
        
        <TableCell>
          {this.props.trialStatus.clinical_status}
        </TableCell>
        
        <TableCell>
          {this.props.trialStatus.source_object.source_title}
        </TableCell>
        
        <TableCell>
          {user ? displayInitials(user.first_name, user.last_name) : 'N/A'}
        </TableCell>
        
        <TableCell>
          <IconButton onClick={this.handleDeleteClick}>
            <DeleteIcon />
          </IconButton>
        </TableCell>
        
      </TableRow>
    )
  }
}

export default TrialStatusRow
