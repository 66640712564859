import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import EntityLayout from "../EntityLayout"
import Flex from "../Flex"
import Box from "../Box"
import Link from "../Link"
import TrialCard from "./TrialCard"
import Paper from "../Paper"
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Button from "../buttons/Button"
import Typography from "@material-ui/core/Typography"
import Badge from '@material-ui/core/Badge';


export default function Profile(props) {
  return(
    <Fragment>

      <EntityLayout>

        <Box className='w-100 w-60-ns mb3 mb0-ns'>
          {props.display_values.map((displayValue, index) =>
            <ExpansionPanel key={displayValue.name} defaultExpanded={props.attribute ? props.attribute == displayValue.attribute : index === 0}>
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} ariacontrols={`${displayValue.name}-content`} id={`${displayValue.name}-header`}>
                <Typography className="f4">
                  {displayValue.name}
                </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <Box width={1}>
                  <Typography>
                    <div dangerouslySetInnerHTML={{__html: displayValue.value}} />
                  </Typography>
                  <Flex alignItems='center'>
                    {displayValue.source ?
                      <Box>
                        <Typography variant="caption">
                          (<Link href={displayValue.source.source_url} target="_blank">
                            {displayValue.source.kind == 'Abstract' ? displayValue.source.source_details : displayValue.source.source_title}
                          </Link>)
                        </Typography>
                      </Box>
                    : '' }

                    {displayValue.history_url ?
                      <Box ml='auto'>
                        <Button component="a" href={displayValue.history_url} target="_blank">
                          View History
                        </Button>
                      </Box>
                    : ''}

                  </Flex>
                </Box>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          )}
        </Box>

        <Box className='w-100 w-33-ns ml-auto'>
          <TrialCard {...props} />
        </Box>

      </EntityLayout>

    </Fragment>
  );
}

Profile.propTypes = {
  trial: PropTypes.object
}
