import React, { Component, Fragment } from "react"
import PropTypes from "prop-types"
import ReactDOM from "react-dom"
import Paper from "../Paper"

class TimelineItem extends Component {
  render () {
    return (
      <Fragment>
        <Paper>
          
        </Paper>
      </Fragment>
    );
  }
}

export default TimelineItem
