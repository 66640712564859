import React, { Fragment } from "react"
import { ListItemLink, ListItemText } from "../lists"
import Box from "../Box"
import Typography from "@material-ui/core/Typography"

export default function NewsListItem(props) {
  return(
    <ListItemLink divider href={`/sectors/${props.sector.abbr}/news/${props.newsItem.id}/url_redirect`} target="_blank">
      <ListItemText>

        <Box pb={0.5}>
          <img width="16px" height="16px" src={`https://www.google.com/s2/favicons?domain=${props.newsItem.url}`} className='mr2' />
          
          <Typography variant="caption" color="textSecondary">
            {props.newsItem.publication_name}
          </Typography>
        </Box>

        <Box pb={0.5} width="60em">
          <Typography variant="body1">
            {props.newsItem.title}
          </Typography>
        </Box>

        <Box width="40em">
          <Typography variant="body2" color="textSecondary">
            {props.newsItem.description}
          </Typography>
        </Box>


      </ListItemText>

      <ListItemText
        secondary={props.newsItem.date ? props.newsItem.date.split("T")[0] : 'N/A'}
        className='tr'
      />
    </ListItemLink>
  )
}
