// https://material-ui.com/api/button/
import React from "react"
import MuiButton from '@material-ui/core/Button';
import { withStyles, createMuiTheme, ThemeProvider, makeStyles } from '@material-ui/core/styles';

const theme = createMuiTheme({
  palette: {
    primary: {
      contrastText: "#fff",
      main: "#5a65cd",
      light: "#dbe2fb",
      color: "#fff"
    },
  },
});

const useStyles = makeStyles(theme => ({
  root: {
    color: '#fff !important'
  }
}));


export default function AbstractsCTA(props) {
  const classes = useStyles(props);

  const { color='primary', ...rest } = props

  return (
    <ThemeProvider theme={theme}>
      <MuiButton variant="contained" color="primary" className={classes.root} {...rest}>
        {props.children}
      </MuiButton>
    </ThemeProvider>
  )
}
