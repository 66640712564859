import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

function LoadingContainer({ isLoading, ...props }) {
  if (!isLoading) return props.children
  return <div><CircularProgress /></div>;
}


export default LoadingContainer;
