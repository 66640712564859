import React from "react"
import IconButton from "../IconButton"
import LaunchIcon from '@material-ui/icons/Launch';

export default function ViewInTrials(props) {


  return(
    <React.Fragment>
      {props.checkedProducts.length > 0
      ?
        <IconButton tooltip='View Trials' component='a' href={`/sectors/${props.sector.abbr}/trials?${props.checkedProducts.map(checkedProduct => `product_ids[]=${checkedProduct}`).join("&")}`} size='small'>
          <LaunchIcon style={{height: '22px', width: '22px'}} />
        </IconButton>
      :
        <IconButton tooltip='View Trials' onClick={() => { props.snackbarShowMessage("To view trials, you must first choose some products using the checkboxes", "info") }} size='small'>
          <LaunchIcon style={{height: '22px', width: '22px'}} />
        </IconButton>
      }
    </React.Fragment>

  )
}
