import React, { Fragment } from "react"
import Flex from "../../Flex"
import Box from "../../Box"
import TextInput from "../../TextInput"
import ButtonLayout from "../ButtonLayout"
import { RaisedButton } from "../../buttons"
import DatePicker from "../../DatePicker"

export default function Form(props) {
  return (
    <Fragment>
      <Flex alignItems='center'>
        
        <Box width={1/2}>
          <TextInput 
            name='url'
            label='URL'
            value={props.fdaDocument.url}
            onChange={props.handleChange}
            fullWidth
          />
        </Box>
        
        <Box ml='auto'>
          <DatePicker
            name='date' 
            label='Date'
            handleChange={props.handleDateChange}
            value={props.fdaDocument.date} 
          />
        </Box>
        
      </Flex>
      
      <Box width={1} my={3}>
        <TextInput 
          name='title' 
          label='Title' 
          multiline={true}
          rows={4}
          value={props.fdaDocument.title} 
          onChange={props.handleChange} 
          fullWidth
        />
      </Box>
      
      <ButtonLayout>
        <RaisedButton handleClick={props.handleSaveClick}>
          Save FDA Document
        </RaisedButton>
      </ButtonLayout>
      
    </Fragment>
  )
}
