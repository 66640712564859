import React, { Component } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Paper from "../Paper"

class Card extends Component {
  render() {
    let classes = `mb2 ${this.props.background} ${(this.props.classStyles || this.props.classStyle) ? (this.props.classStyles || this.props.classStyle) : ''}`
    let paperClassName = `br1 ${this.props.background}`
    return (
      <div className={classes} onMouseEnter={this.props.handleMouseEnter} onMouseLeave={this.props.handleMouseLeave} onClick={this.props.handleClick}>
        <Paper elevation={this.props.elevation} className={paperClassName}>
          {this.props.children}
        </Paper>
      </div>
    )
  }
}

Card.propTypes = {
  background: PropTypes.string
}

Card.defaultProps = {
  elevation: 2,
  background: 'bg-white'
}

export default Card
