import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Paper from "../Paper"
import Flex from "../Flex"
import Box from "../Box"
import Grid from '@material-ui/core/Grid';
import Typography from "@material-ui/core/Typography"
import Select from "../Select"
import SitesChart from "./SitesChart"
import InvestigatorsChart from "./InvestigatorsChart"
import TimelineChartContainer from "./TimelineChartContainer"
import EnrollmentChart from "./EnrollmentChart"
import DateChart from "../DateChart"
import Snackbar from "../Snackbar"
import Button from "../buttons/Button"
import TrialAutocomplete from "./TrialAutocomplete"
import FilterChip from '../filters/FilterChip';
import TableContainer from "./TableContainer"
import Dialog from "../Dialog"
import update from "immutability-helper"

export default class Metrics extends Component {
  constructor(props) {
    super(props)
    this.handleFilterChange = this.handleFilterChange.bind(this)
    this.handleAddTrialClick = this.handleAddTrialClick.bind(this)
    this.handleSearchChange = this.handleSearchChange.bind(this)
    this.handleDelete = this.handleDelete.bind(this)
    this.handleTrialCheckboxClick = this.handleTrialCheckboxClick.bind(this)
    this.handleAddClick = this.handleAddClick.bind(this)
    this.handleCloseDialog = this.handleCloseDialog.bind(this)
    this.state = { sitesChartData: this.props.sites_chart_data, sitesCategories: this.props.sites_categories, investigatorsChartData: this.props.investigators_chart_data, investigatorCategories: this.props.investigator_categories, timelineChartData: this.props.timeline_chart_data, dateChartData: this.props.date_chart_data, primaryCompletionDateChartData: this.props.primary_completion_date_chart_data, enrollmentChartData: this.props.enrollment_chart_data, compareEnrollmentChartData: this.props.compare_enrollment_chart_data, compareEnrollmentCategories: this.props.compare_enrollment_categories, enrollmentCategories: this.props.enrollment_categories, compareTargetEnrollments: this.props.compare_target_enrollments, targetEnrollments: this.props.target_enrollments, timelineAttribute: this.props.timeline_attribute, trial_id: '', timelineChartReloadKey: Math.random(), enrollmentChartReloadKey: Math.random(), sitesChartReloadKey: Math.random(), investigatorsChartReloadKey: Math.random(), showSnackbar: false, snackbarText: '', openDialog: false, compareTrial: null, compareTimelineChartData: '', compareTimelineChartReloadKey: Math.random(), newTrials: [], compareEnrollmentStartDateForChart: '', compareEnrollmentEndDateForChart: '' }
  }

  handleDelete(e) {
    $.ajax({
      type: 'GET',
      url: `/sectors/${this.props.sector.abbr}/trials/${this.props.trial.id}/metrics`,
      data: {
        compare_trial: null
      },
      dataType: 'json',
      success: (data) => {
        this.setState({
          sitesChartData: data.sites_chart_data,
          sitesCategories: data.sites_categories,
          enrollmentChartData: data.enrollment_chart_data,
          timelineChartData: data.timeline_chart_data,
          dateChartData: data.date_chart_data,
          investigatorsChartData: data.investigators_chart_data,
          investigatorCategories: data.investigator_categories,
          timelineChartReloadKey: Math.random(),
          enrollmentChartReloadKey: Math.random(),
          sitesChartReloadKey: Math.random(),
          investigatorsChartReloadKey: Math.random(),
          compareTrial: data.compare_trial,
          compareTimelineChartData: data.compare_timeline_chart_data,
          compareTimelineChartReloadKey: Math.random(),
          primaryCompletionDateChartData: data.primary_completion_date_chart_data
        })
      },
      error: (data) => {
        this.setState({
          showSnackbar: true,
          snackbarText: 'There was an error. Please try again.'
        })
      },
      complete: (data) => {
        setTimeout(() => {
          this.setState({
            showSnackbar: false,
            snackbarText: ''
          })
        }, 4000)
      }
    })
  }

  handleSearchChange(e, selected) {
    $.ajax({
      type: 'GET',
      url: `/sectors/${this.props.sector.abbr}/trials/${this.props.trial.id}/metrics`,
      data: {
        compare_trial_id: selected.value
      },
      dataType: 'json',
      success: (data) => {
        this.setState({
          sitesChartData: data.sites_chart_data,
          sitesCategories: data.sites_categories,
          enrollmentChartData: data.enrollment_chart_data,
          timelineChartData: data.timeline_chart_data,
          investigatorsChartData: data.investigators_chart_data,
          investigatorCategories: data.investigator_categories,
          timelineChartReloadKey: Math.random(),
          enrollmentChartReloadKey: Math.random(),
          sitesChartReloadKey: Math.random(),
          investigatorsChartReloadKey: Math.random(),
          compareTrial: data.compare_trial,
          compareTimelineChartData: data.compare_timeline_chart_data,
          compareTimelineChartReloadKey: Math.random(),
          dateChartData: data.date_chart_data,
          compareEnrollmentChartData: data.compare_enrollment_chart_data,
          compareEnrollmentCategories: data.compare_enrollment_categories,
          compareTargetEnrollments: data.compare_target_enrollments,
          primaryCompletionDateChartData: data.primary_completion_date_chart_data,
          compareEnrollmentStartDateForChart: data.compare_enrollment_start_date_for_chart,
          compareEnrollmentEndDateForChart: data.compare_enrollment_end_date_for_chart
        })
      }
    })
  }

  handleFilterChange(name, value) {
    $.ajax({
      type: 'GET',
      url: `/sectors/${this.props.sector.abbr}/trials/${this.props.trial.id}/date_change_data`,
      data: {
        attribute: value, compare_trial_id: (this.state.compareTrial ? this.state.compareTrial.id : null)
      },
      dataType: 'json',
      success: (data) => {
        this.setState({
          dateChartData: data.date_chart_data,
          timelineAttribute: value,
          compareTimelineChartReloadKey: Math.random()
        })
      },
      error: (data) => {
        this.setState({
          showSnackbar: true,
          snackbarText: 'There was an error. Please try again.'
        })
      },
      complete: (data) => {
        setTimeout(() => {
          this.setState({
            showSnackbar: false,
            snackbarText: ''
          })
        }, 4000)
      }
    })
  }

  handleAddTrialClick(e) {
    this.setState({
      openDialog: true
    })
  }

  handleCloseDialog(e) {
    this.setState({
      openDialog: false
    })
  }

  handleAddClick(e) {
    $.ajax({
      type: 'GET',
      url: `/sectors/${this.props.sector.abbr}/trials/${this.props.trial.id}/metrics`,
      data: {
        compare_trial_id: this.state.newTrials[0]
      },
      dataType: 'json',
      success: (data) => {
        this.setState({
          sitesChartData: data.sites_chart_data,
          sitesCategories: data.sites_categories,
          enrollmentChartData: data.enrollment_chart_data,
          timelineChartData: data.timeline_chart_data,
          investigatorsChartData: data.investigators_chart_data,
          investigatorCategories: data.investigator_categories,
          timelineChartReloadKey: Math.random(),
          enrollmentChartReloadKey: Math.random(),
          sitesChartReloadKey: Math.random(),
          investigatorsChartReloadKey: Math.random(),
          compareTrial: data.compare_trial,
          compareTimelineChartData: data.compare_timeline_chart_data,
          compareTimelineChartReloadKey: Math.random(),
          dateChartData: data.date_chart_data,
          compareEnrollmentChartData: data.compare_enrollment_chart_data,
          compareEnrollmentCategories: data.compare_enrollment_categories,
          compareTargetEnrollments: data.compare_target_enrollments,
          primaryCompletionDateChartData: data.primary_completion_date_chart_data,
          openDialog: false,
          compareEnrollmentEndDateForChart: data.compare_enrollment_end_date_for_chart,
          compareEnrollmentStartDateForChart: data.compare_enrollment_start_date_for_chart
        })
      }
    })
  }

  handleTrialCheckboxClick(trialId) {
    const index = this.state.newTrials.indexOf(trialId)

    let newTrials = []

    newTrials.push(trialId)

    this.setState({
      newTrials: newTrials
    })
  }

  render() {
    console.log(this.props.timeline_attributes)
    let attributeDisplay = this.props.timeline_attributes ? this.props.timeline_attributes.filter(timelineAttributeOption => timelineAttributeOption.value == this.state.timelineAttribute) : null
    return(
      <Fragment>

        {this.state.openDialog ?
          <Dialog
            openDialog={this.state.openDialog}
            dialogTitle='Choose trials'
            handleAddClick={this.handleAddClick}
            handleClose={this.handleCloseDialog}
            dialogContent={
              <TableContainer
                height="100%"
                sector={this.props.sector}
                choose={false}
                checkedTrials={this.state.newTrials}
                handleTrialCheckboxClick={this.handleTrialCheckboxClick}
                handleBulkTrialCheckboxClick={this.handleBulkTrialCheckboxClick}
              />
            }
          />
        : ''}

        {this.props.trial.nct_id == null ?
          <Paper minFullHeight>
            <Flex justifyContent='center' height="50%">
              <Box alignSelf='center' maxWidth="50%">
                <Box>
                  <Typography variant="body1">
                    The feature is still in development for a subset of trials due to the amount and age of our data. It will be functional for all trials shortly...
                  </Typography>
                </Box>
              </Box>
            </Flex>
          </Paper>
        :
          <Fragment>
            <Grid container spacing={3} component={Box} pb={4}>
              <Grid item xs={12}>
                <Paper>

                  <Box>
                    <Typography variant="h6">
                      Add a trial to compare
                    </Typography>
                  </Box>

                  <Flex alignItems='center'>
                    <Box mr={3}>
                      <Button onClick={this.handleAddTrialClick}>
                        Add Trial
                      </Button>
                    </Box>

                    {/* <Box mr={3}>
                      <Select
                        label='My Trials'
                        name='trial_id'
                        value={this.state.trial_id}
                        entities={this.props.user_trials}
                        handleChange={this.handleFilterChange}
                      />
                    </Box> */}
                    <Box mr={3}>
                      or
                    </Box>

                    <Box>
                      <TrialAutocomplete
                        trials={this.props.trials}
                        handleSearchChange={this.handleSearchChange}
                      />
                    </Box>
                  </Flex>

                  <Flex justifyContent='center' mt={3}>
                    <Box>
                      {this.state.compareTrial ?
                        <FilterChip
                          key={this.state.compareTrial.id}
                          name={this.state.compareTrial.acronym || this.state.compareTrial.short_name}
                          value={this.state.compareTrial.id}
                          handleDelete={this.handleDelete}
                        />
                      : ''}
                    </Box>
                  </Flex>

                </Paper>
              </Grid>
            </Grid>

            <Grid container spacing={3} component={Box} pb={4}>

              <Grid item xs={12} lg={6}>
                <Paper className='h-100'>
                  <Typography variant="h6">
                    Site Recruitment
                  </Typography>

                  {this.state.sitesChartData ?
                    <Box mt={2}>
                      <SitesChart
                        key={this.state.sitesChartReloadKey}
                        sitesChartData={this.state.sitesChartData}
                        categories={this.state.sitesCategories}
                        enrollmentStartDate={this.props.enrollment_start_date}
                        enrollmentStartDateForChart={this.props.enrollment_start_date_for_chart}
                        enrollmentEndDateForChart={this.props.enrollment_end_date_for_chart}
                        enrollmentEndDate={this.props.enrollment_end_date}
                        showEnrollmentDates={false}
                        title=''
                        xAxisType='category'
                        xAxisTitle='Days elapsed'
                        dataLabels={this.state.sitesCategories.length > 1 ? false : true}
                      />
                    </Box>
                  :
                    <Box height="100%">
                      <Flex justifyContent='center' height="50%">
                        <Box alignSelf='center' maxWidth="50%">
                          <Box>
                            <Typography variant="body1">
                              No sites found
                            </Typography>
                          </Box>
                        </Box>
                      </Flex>
                    </Box>
                  }
                </Paper>
              </Grid>

              <Grid item xs={12} lg={6}>
                <Paper className='h-100'>
                  <Typography variant="h6">
                    Investigator Recruitment
                  </Typography>

                  <Box mt={2} height="100%">
                    {this.state.investigatorsChartData ?
                      <InvestigatorsChart
                        key={this.state.investigatorsChartReloadKey}
                        investigatorsChartData={this.state.investigatorsChartData}
                        enrollmentStartDate={this.props.enrollment_start_date}
                        enrollmentStartDateForChart={this.props.enrollment_start_date_for_chart}
                        enrollmentEndDateForChart={this.props.enrollment_end_date_for_chart}
                        enrollmentEndDate={this.props.enrollment_end_date}
                        title=''
                        categories={this.state.investigatorCategories}
                        xAxisType='category'
                        xAxisTitle='Days elapsed'
                        dataLabels={this.state.investigatorCategories.length > 1 ? false : true}
                      />
                    :
                      <Box height="100%">
                        <Flex justifyContent='center' height="50%">
                          <Box alignSelf='center' maxWidth="50%">
                            <Box>
                              <Typography variant="body1">
                                No investigators found
                              </Typography>
                            </Box>
                          </Box>
                        </Flex>
                      </Box>
                    }
                  </Box>
                </Paper>
              </Grid>

            </Grid>

            <Grid container spacing={3} component={Box} pb={4}>
              <Grid item xs={12} lg={6}>
                <Paper className='h-100'>

                <Box mb={2}>
                  <Typography variant="h6">
                    Primary Completion Date Changes
                  </Typography>
                </Box>

                <Box>
                  <DateChart
                    key={`primary-completion-date-${this.state.compareTimelineChartReloadKey}`}
                    chartId='primary-completion-date-chart'
                    dateChartData={this.state.primaryCompletionDateChartData}
                    attribute={'Primary completion date'}
                  />
                </Box>

                </Paper>
              </Grid>

              <Grid item xs={12} lg={6}>
                <Paper>
                  <Flex alignItems='center' mb={2}>
                    <Box>
                      <Typography variant="h6">
                        Completion Date Changes
                      </Typography>
                    </Box>

                    {/* <Box ml='auto'>
                      <Select
                        label='Attributes'
                        name='attribute'
                        value={this.state.timelineAttribute}
                        entities={this.props.timeline_attributes}
                        handleChange={this.handleFilterChange}
                      />
                    </Box> */}
                  </Flex>

                  <Box>
                    <DateChart
                      key={this.state.compareTimelineChartReloadKey}
                      chartId='completion-date-chart'
                      dateChartData={this.state.dateChartData}
                      attribute={attributeDisplay ? attributeDisplay[0].name : ""}
                    />
                  </Box>

                </Paper>
              </Grid>


            </Grid>

            <Grid container spacing={3} component={Box} pb={4}>
              <Grid item xs={12} lg={6}>
                <Paper className='h-100'>

                  <Box height="100%">
                    {this.state.enrollmentChartData.length > 0 ?
                      <EnrollmentChart
                        key={`enrollment-chart-${this.state.enrollmentChartReloadKey}`}
                        chartData={this.state.enrollmentChartData}
                        categories={this.state.enrollmentCategories}
                        targetEnrollment={this.state.targetEnrollments}
                        enrollmentStartDate={this.props.enrollment_start_date_for_chart}
                        enrollmentEndDate={this.props.enrollment_end_date_for_chart}
                        title={`${this.props.trial_data_view.acronym || this.props.trial_data_view.short_name} Enrollment`}
                        showEnrollmentDates={true}
                        showTargetEnrollment={false}
                      />
                    :
                      <Box height="100%">
                        <Flex justifyContent='center' height="50%">
                          <Box alignSelf='center' maxWidth="50%">
                            <Box>
                              <Typography variant="body1">
                                No enrollment found
                              </Typography>
                            </Box>
                          </Box>
                        </Flex>
                      </Box>
                    }
                  </Box>

                </Paper>
              </Grid>

              <Grid item xs={12} lg={6}>
                <Paper className='h-100'>

                  {this.state.compareTrial ? <Box>
                    <EnrollmentChart
                      key={`compare-enrollment-chart-${this.state.enrollmentChartReloadKey}`}
                      chartId='compare-enrollment-chart'
                      chartData={this.state.compareEnrollmentChartData}
                      categories={this.state.compareEnrollmentCategories}
                      targetEnrollment={this.props.compareTargetEnrollments}
                      enrollmentStartDate={this.state.compareEnrollmentStartDateForChart}
                      enrollmentEndDate={this.state.compareEnrollmentEndDateForChart}
                      title={`${this.state.compareTrial.acronym || this.state.compareTrial.short_name} Enrollment`}
                      showEnrollmentDates={true}
                      showTargetEnrollment={false}
                    />
                  </Box>
                :
                  <Box height="100%">
                    <Flex justifyContent='center' height="50%">
                      <Box alignSelf='center' maxWidth="50%">
                        <Box>
                          <Typography variant="body1">
                            Add a trial to compare enrollment
                          </Typography>
                        </Box>
                      </Box>
                    </Flex>
                  </Box>
                }

                </Paper>
              </Grid>
            </Grid>
          </Fragment>
        }



        {/* <Grid item xs={6}>
          <Paper className='h-100'>
            <Flex alignItems='center' mb={2}>
              <Box>
                <Typography variant="h6">
                  Timeline
                </Typography>
              </Box>

              <Box ml='auto'>
                <Select
                  label='Attributes'
                  name='attribute'
                  value={this.state.timelineAttribute}
                  entities={this.props.timeline_attributes}
                  handleChange={this.handleFilterChange}
                />
              </Box>
            </Flex>

            <Box>
              <Typography variant="h6">
                {this.props.trial.short_name}
              </Typography>

              <TimelineChartContainer
                key={this.state.timelineChartReloadKey}
                chartId='trial-timeline-attribute-chart'
                chartData={this.state.timelineChartData}
                currentTime={this.props.current_time}
                title=''
              />
            </Box>

            {this.state.compareTrial ?
              <Box mt={3}>
                <Typography variant="h6">
                  {this.state.compareTrial.short_name}
                </Typography>

                <Box>
                  <TimelineChartContainer
                    key={this.state.compareTimelineChartReloadKey}
                    chartId='compare-trial-timeline-attribute-chart'
                    chartData={this.state.compareTimelineChartData}
                    currentTime={this.props.current_time}
                    title=''
                  />
                </Box>
              </Box>
            : ''}

          </Paper>
        </Grid> */}

        <Snackbar
          open={this.state.showSnackbar}
          message={this.state.snackbarText}
        />

      </Fragment>
    )
  }
}
