import React, { Fragment } from "react"
import TextInput from "../../TextInput"
import RaisedButton from "../../buttons/RaisedButton"
import ButtonLayout from "../ButtonLayout"


export default function Form(props) {
  return(
    <Fragment>

      <div className='mv4'>
        <TextInput
          name='keyword'
          label='keyword'
          value={props.searchKeyword.keyword}
          onChange={props.handleChange}
          className='w-100'
        />
      </div>

      <ButtonLayout>
        <RaisedButton handleClick={props.handleSaveClick}>
          Save Keyword
        </RaisedButton>
      </ButtonLayout>

    </Fragment>
  )
}
