import React, { Fragment } from "react"
import Typography from "@material-ui/core/Typography"
import { List } from "../../../lists"
import Box from "../../../Box"
import PmaListItem from "./PmaListItem"

export default function PmaList(props) {

  return(
    <Fragment>

      <Box width={1/2}>
        <Typography variant="h6">
          PMA Numbers
        </Typography>


        <List>
          {props.productPmas.map((productPma) =>
            <PmaListItem
              key={productPma.id}
              productPma={productPma}
              sectors={props.sectors}
              handleDeleteClick={props.handleDeleteClick}
            />
          )}
        </List>
      </Box>

    </Fragment>
  )
}
