import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import { Table, TableHead, TableRow, TableCell, TableBody, EmptyDataTableRow } from "../../tables"
import Paper from "../../Paper"
import TechnologyGroupRow from "./TechnologyGroupRow"
import Snackbar from "../../Snackbar"
import update from "immutability-helper"

export default class TechnologyGroups extends Component {
  constructor(props) {
    super(props)
    this.handleDeleteClick = this.handleDeleteClick.bind(this)
    this.state = { activeTab: 'List', technologyGroups: this.props.technology_groups, showSnackbar: false, snackbarText: '' }
  }
  
  handleDeleteClick(technologyGroup) {
    $.ajax({
      type: 'PUT',
      url: `/admin/technology_groups/${technologyGroup.id}`,
      data: {
        technology_group: {
          deleted: true, deleted_by: this.props.current_user.id
        }
      },
      dataType: 'json',
      success: (data) => {
        let technologyGroupIds = this.state.technologyGroups.map((technologyGroup) => technologyGroup.id )
        let index = technologyGroupIds.indexOf(technologyGroup.id)
        let technologyGroups = update(this.state.technologyGroups, { $splice: [[index, 1]]})
        
        this.setState({
          technologyGroups: technologyGroups,
          showSnackbar: true,
          snackbarText: 'The technology group was successfully deleted'
        })
      },
      error: (data) => {
        this.setState({
          showSnackbar: true,
          snackbarText: 'The technology group could not be deleted. Please try again.'
        })
      },
      complete: (data) => {
        setTimeout(() => {
          this.setState({
            showSnackbar: false,
            snackbarText: ''
          })
        }, 4000)
      }
    })
  }
  
  render() {
    return(
      <Fragment>
        
        <Paper>
          
          <Table>
            <TableHead>
              <TableCell>Name</TableCell>
              <TableCell>Sector</TableCell>
              <TableCell>Created by</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableHead>
            
            <TableBody>
              {this.state.technologyGroups.map((technologyGroup) =>
                <TechnologyGroupRow
                  key={technologyGroup.id}
                  technologyGroup={technologyGroup}
                  handleDeleteClick={this.handleDeleteClick}
                />
              )}
            </TableBody>
            
          </Table>
        </Paper>
        
        <Snackbar
          open={this.state.showSnackbar}
          message={this.state.snackbarText} 
        />
        
      </Fragment>
    )
  }
}
