import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Paper from "../../Paper"
import { Table, TableHead, TableRow, TableCell, TableBody, EmptyDataTableRow } from "../../tables"
import AlertGroupRow from "./AlertGroupRow"
import update from "immutability-helper"
import Snackbar from "../../Snackbar"

export default class AlertGroups extends Component {
  constructor(props) {
    super(props)
    this.handleDeleteClick = this.handleDeleteClick.bind(this)
    this.handleUndoClick = this.handleUndoClick.bind(this)
    this.state = { alertGroups: this.props.alert_groups, showSnackbar: false, snackbarText: '', lastDeleted: null }
  }
  
  handleDeleteClick(alertGroup) {
    $.ajax({
      type: 'PUT',
      url: `/admin/alert_groups/${alertGroup.id}`,
      data: {
        alert_group: {
          deleted: true, deleted_by: this.props.current_user.id
        }
      },
      dataType: 'json',
      success: (data) => {
        const index = this.state.alertGroups.indexOf(alertGroup)
        const alertGroups = update(this.state.alertGroups, {
          $splice: [[index, 1]]
        })
        
        this.setState({
          alertGroups: alertGroups,
          showSnackbar: true,
          snackbarText: 'Alert group was deleted',
          lastDeleted: alertGroup
        })
      },
      error: (data) => {
        this.setState({
          showSnackbar: true,
          snackbarText: 'Alert group could not be deleted'
        })
      },
      complete: (data) => {
        setTimeout(() => {
          this.setState({
            showSnackbar: false,
            snackbarText: ''
          })
        }, 5000)
      }
    })
  }
  
  handleUndoClick(e) {
    $.ajax({
      type: 'PUT',
      url: `/admin/alert_groups/${this.state.lastDeleted.id}`,
      data: {
        alert_group: {
          deleted: null, deleted_by: null
        }
      },
      dataType: 'json',
      success: (data) => {
        const alertGroups = update(this.state.alertGroups, {
          $push: [this.state.lastDeleted]
        })
        
        this.setState({
          alertGroups: alertGroups,
          showSnackbar: true,
          snackbarText: 'Alert group has been undeleted'
        })
      },
      error: (data) => {
        this.setState({
          showSnackbar: true,
          snackbarText: 'Deletion could not be undone'
        })
      },
      complete: (data) => {
        setTimeout(() => {
          this.setState({
            showSnackbar: false,
            snackbarText: ''
          })
        }, 5000)
      }
    })
  }
  
  render() {
    return (
      <Fragment>

        <Paper>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Created</TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.state.alertGroups.map((alertGroup) =>
                <AlertGroupRow 
                  key={alertGroup.id}
                  alertGroup={alertGroup}
                  handleDeleteClick={this.handleDeleteClick}
                />
              )}
            </TableBody>
          </Table>
        </Paper>
        
        <Snackbar 
          open={this.state.showSnackbar}
          handleUndoClick={this.handleUndoClick}
          handleClose={this.handleClose}
          message={this.state.snackbarText} 
        /> 
      </Fragment>
    )
  }
}
