import React, { useState } from "react"
import PropTypes from "prop-types"
import ReactDOM from "react-dom"
import Link from "../Link"
import { Table, TableRow, TableCell, TableHeader, TableBody } from "../tables"
import Button from "../buttons/Button"
import ExpandLess from '../ExpandLess';
import ExpandMore from '../ExpandMore';
import Box from "../Box"


export default function GroupedEntities(props) {
  const [open, setOpen] = useState(true);

  console.log(props.entities)

  const urlHash = { 'person_abstract': `/sectors/${props.sector.abbr}/abstracts`, 'person_trial': `/sectors/${props.sector.abbr}/trials`, 'person_manuscript': `/sectors/${props.sector.abbr}/manuscripts` }

  return(
    <React.Fragment>

      <TableRow hover={false}>
        <TableCell colSpan={4} className='pv2'>
          <Button onClick={() => setOpen(!open)} color='default' lowercase={true} className='f5'>
            {open ? <ExpandLess /> : <ExpandMore />}
            {props.header}
          </Button>
        </TableCell>
      </TableRow>

      {open ? props.entities.map((entity) =>
        <TableRow key={entity.id}>
          <TableCell>{entity.network_entity_info || entity.entity_info}</TableCell>
          <TableCell>
            <Link href={`${urlHash[props.chartEntityType]}/${entity.entity_id}`} target="_blank">
              <Box>
                {entity.name || entity.name_value}
              </Box>
            </Link>
          </TableCell>
        </TableRow>
      ) : ''}
    </React.Fragment>
  );
}
