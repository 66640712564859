import React from "react"
import Link from "../Link"
import LinkIcon from '@material-ui/icons/Link';

export default function LinkToAbstract({sectorAbbr, abstractId}) {
  return(
    <Link href={`/sectors/${sectorAbbr}/abstracts/${abstractId}/view_source`} target="_blank">
      <LinkIcon />
    </Link>
  )
}