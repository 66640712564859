import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Paper from "../../Paper"
import { Table, TableRow, TableCell, TableHead, TableBody } from "../../tables"
import TargetSiteRow from "./TargetSiteRow"
import update from "immutability-helper"
import Snackbar from "../../Snackbar"

export default class TargetSites extends Component {
  constructor(props) {
    super(props)
    this.handleDeleteClick = this.handleDeleteClick.bind(this)
    this.state = { targetSites: this.props.target_sites, showSnackbar: false, snackbarText: '' }
  }
  
  handleDeleteClick(targetSite) {
    console.log("hello")
    $.ajax({
      type: 'PUT',
      url: `/admin/target_sites/${targetSite.id}`,
      data: {
        target_site: {
          deleted: true, deleted_by: this.props.current_user.id
        }
      },
      dataType: 'json',
      success: (data) => {
        const index = this.state.targetSites.indexOf(targetSite)
        
        const targetSites = update(this.state.targetSites, {
          $splice: [[index,1]]
        })
        
        this.setState({
          targetSites: targetSites,
          showSnackbar: true,
          snackbarText: 'Target site was removed'
        })
      },
      error: (data) => {
        this.setState({
          showSnackbar: true,
          snackbarText: 'Target site could not be removed. Please try again.'
        })
      },
      complete: (data) => {
        setTimeout(() => {
          this.setState({
            showSnackbar: false,
            snackbarText: ''
          })
        }, 4000)
      }
    })
  }
  
  render() {
    return(
      <Fragment>
        
        <Paper>
          
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Created By</TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            
            <TableBody>
              {this.state.targetSites.map((targetSite) =>
                <TargetSiteRow 
                  key={targetSite.id}
                  targetSite={targetSite}
                  handleDeleteClick={this.handleDeleteClick}
                />
              )}
            </TableBody>
          </Table>
        </Paper>
        
        <Snackbar 
          open={this.state.showSnackbar}
          message={this.state.snackbarText} 
        />
        
      </Fragment>
    )
  }
}
