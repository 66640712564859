import React from 'react'
import Link from '../../Link'

function BfarmLink(props) {
  return (
    <Link href={props.url} target="_blank">
      {props.linkText || "View on BFARM site"}
    </Link>
  )
}

export default BfarmLink