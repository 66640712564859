import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from "@material-ui/core/Typography"
import Link from "../Link"
import Flex from "../Flex"
import Box from "../Box"
import ImageDisplay from "../ImageDisplay"
import DeviceImage from "../../../assets/images/products/device_images/absolute_pro.png"

export default class ProfileFieldsContainer extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    return(
      <Fragment>
        {this.props.profileFields.map((profileField, index) =>
          <ExpansionPanel key={profileField.id} defaultExpanded={index === 0}>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} ariacontrols={`${profileField.name}-content`} id={`${profileField.name}-header`}>
              <Typography className="f4">
                {profileField.name}
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Typography>
                <div dangerouslySetInnerHTML={{__html: profileField.content}} />
                {profileField.source ?
                  <Typography variant="caption">
                    (<Link href={profileField.source.source_url} target="_blank">
                      {profileField.source.source_title}
                    </Link>)
                  </Typography>
                : ''}
              </Typography>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        )}
      </Fragment>
    )
  }
}
