import React, { Fragment } from "react"
import Flex from "../Flex"
import Box from "../Box"
import Paper from "../Paper"
import { List, ListItem, ListItemText } from "../lists"
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListSubheader from "@material-ui/core/ListSubheader"
import Switch from "../Switch"
import Typography from "@material-ui/core/Typography"
import AlertGroupListItem from "./AlertGroupListItem"

export default function AlertsCustomizationForm(props) {

  function handlePreferenceSwitchChange(e) {
    props.handlePreferenceSwitchChange(e.target.value, props.alertPreference)
  }

  return(
    <Fragment>
      <Box mt={3}>

        <Box mb={2}>
          <Typography variant="body1">
            Control when you receive alerts and the type of updates that are included
          </Typography>
        </Box>

        <Box width={[1, 1, 1/2]}>

          <Box mb={2}>
            <Typography variant="h6">
              Alert Timing
            </Typography>
            <List>
              <ListItem button>
                <ListItemText
                  primary='Daily'
                  secondary="Receive alerts each day as they are created."
                />

                <ListItemSecondaryAction>
                  <Switch
                    edge="end"
                    checked={props.alertPreference.daily ? true : false}
                    handleChange={handlePreferenceSwitchChange}
                  />
                </ListItemSecondaryAction>
              </ListItem>

              <ListItem button>
                <ListItemText
                  primary='Weekly'
                  secondary="Receive a weekly compilation of the daily alerts."
                />

                <ListItemSecondaryAction>
                  <Switch
                    edge="end"
                    checked={props.alertPreference.weekly ? true : false}
                    handleChange={handlePreferenceSwitchChange}
                  />
                </ListItemSecondaryAction>
              </ListItem>
            </List>
          </Box>

          <Box>
            <Typography variant="h6">
              Alert Topics
            </Typography>
            <List>
              {Object.keys(props.alertGroups).map((entityType) =>
                <Fragment>
                  <ListSubheader disableSticky disableGutters className="black mt3">
                    {capitalizeFirstLetter(entityType)}
                  </ListSubheader>
                  {props.alertGroups[entityType].map((alertGroup) =>
                    <AlertGroupListItem
                      key={alertGroup.id}
                      alertGroup={alertGroup}
                      userAlertGroup={props.userAlertGroups.filter(userAlertGroup => userAlertGroup.alert_group_id == alertGroup.id)[0]}
                      checked={props.userAlertGroups.map(userAlertGroup => userAlertGroup.alert_group_id).includes(alertGroup.id)}
                      handleGroupSwitchChange={props.handleGroupSwitchChange}
                    />
                  )}
                </Fragment>
              )}
            </List>
          </Box>
        </Box>
      </Box>
    </Fragment>
  )
}
