import React, { Fragment } from "react"
import CharacteristicValueItemContainer from "../characteristic_values/CharacteristicValueItemContainer"


export default function DisplayContainer(props) {

  let values = ""

  if (props.characteristicGroup.characteristic_values[0] && props.characteristicGroup.characteristic_values[0].metric_id) {
    values = props.characteristicGroup.characteristic_values.sort((a, b) => parseInt(a.name) - parseInt(b.name))
  } else {
    values = props.characteristicGroup.characteristic_values
  }

  return (
    <Fragment>
      {values.map((characteristicValue) =>
        <CharacteristicValueItemContainer
          key={characteristicValue.id}
          characteristicValue={characteristicValue}
          characteristicGroup={props.characteristicGroup}
          activeRegion={props.activeRegionIndex}
          productCharacteristics={props.productCharacteristics}
          handleCheckboxSelect={props.handleCheckboxSelect}
        />)}
    </Fragment>
  )
}
