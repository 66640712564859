import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import Box from "../../Box"
import TextInput from "../../TextInput"
import Flex from "../../Flex"
import { RaisedButton } from "../../buttons"
import Select from "../../Select"
import DatePicker from "../../DatePicker"


export default class StatusForm extends Component {
  constructor(props) {
    super(props)
    this.handleTypeSaveClick = this.handleTypeSaveClick.bind(this)
    this.handleTypeChange = this.handleTypeChange.bind(this)
    this.state = { clinical_type_id: '', date: '' }
  }

  componentDidMount() {
    console.log(this.props.trialType)
    if (this.props.trialType) {
      this.setState({
        clinical_type_id: this.props.trialType.clinical_type_id,
        date: this.props.trialType.date
      })
    }
  }

  handleTypeSaveClick(e) {
    this.props.handleTypeSaveClick(this.state.clinical_type_id)
  }

  handleTypeChange(name, value) {
    this.setState({
      clinical_type_id: parseInt(value)
    })
  }

  render() {
    return(
      <Fragment>
        <Box mt={2}>

          <Box mb={4}>
            <Select
              entities={this.props.types}
              value={this.state.clinical_type_id}
              label='Types'
              displayEmpty={true}
              handleChange={this.handleTypeChange}
            />
          </Box>


          <Box>
            <RaisedButton handleClick={this.handleTypeSaveClick}>
              Save type
            </RaisedButton>
          </Box>
        </Box>
      </Fragment>
    )
  }
}
