import React, { Fragment } from "react"
import Box from "../../Box"
import TextInput from "../../TextInput"
import Radio from '@material-ui/core/Radio';
import RadioGroup from "../../RadioGroup"
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Flex from "../../Flex"
import { RaisedButton } from "../../buttons"
import DatePicker from "../../DatePicker"

export default class EnrollmentExtractorForm extends React.Component {
  constructor(props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
    this.handleEnrollmentTypeChange = this.handleEnrollmentTypeChange.bind(this)
    this.handleEnrollmentSaveClick = this.handleEnrollmentSaveClick.bind(this)
    this.state = { enrollment_type: 'target_enrollment', date: this.props.date, enrollment: '' }
  }

  componentDidMount(e) {
    if (this.props.trialTargetEnrollment) {
      this.setState({
        enrollment: this.props.trialTargetEnrollment.enrollment,
        date: this.props.trialTargetEnrollment.date
      })
    }
  }

  handleEnrollmentSaveClick(e) {
    this.props.handleEnrollmentSaveClick(this.state.enrollment_type, this.state.date, this.state.enrollment)
  }

  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  handleEnrollmentTypeChange(e) {
    let value = e.target.value
    let enrollment = ""
    let date = ""

    if (value == 'target_enrollment') {
      enrollment = this.props.trialTargetEnrollment ? this.props.trialTargetEnrollment.enrollment : ''
      date = this.props.trialTargetEnrollment ? this.props.trialTargetEnrollment.date : ''
    } else {
      enrollment = this.props.trialCurrentEnrollment ? this.props.trialCurrentEnrollment.enrollment : ''
      date = this.props.trialCurrentEnrollment ? this.props.trialCurrentEnrollment.date : ''
    }

    this.setState({
      [e.target.name]: e.target.value,
      enrollment: enrollment,
      date: date
    })
  }

  render() {
    return(
      <Fragment>
        <Box mt={2} mb={6}>

          <Flex justifyContent='center' mb={2}>
            <RadioGroup aria-label="enrollment-type" name="enrollment_type" value={this.state.enrollment_type} onChange={this.handleEnrollmentTypeChange}>
              <FormControlLabel value='target_enrollment' control={<Radio />} label="Target" />
              <FormControlLabel value='current_enrollment' control={<Radio />} label="Current" />
            </RadioGroup>
          </Flex>

          <Box mb={2}>
            <TextInput
              name='enrollment'
              label='Enrollment'
              value={this.state.enrollment}
              onChange={this.handleChange}
              helperText='Only integers allowed'
              dense={true}
              fullWidth
            />
          </Box>

          <Box>
            <DatePicker
              name='date'
              label='Date'
              value={this.state.date}
              handleChange={(date) => { this.setState({ date: date }) }}
            />
          </Box>

          <Box mt={4}>
            <RaisedButton handleClick={this.handleEnrollmentSaveClick}>
              {`Save ${this.state.enrollment_type.replace("_", " ")}`}
            </RaisedButton>
          </Box>
        </Box>
      </Fragment>
    )
  }
}
