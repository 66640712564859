import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Paper from "../../Paper"
import Flex from "../../Flex"
import Box from "../../Box"
import TextInput from "../../TextInput"
import Select from "../../Select"
import ButtonLayout from "../ButtonLayout"
import { RaisedButton } from "../../buttons"
import update from "immutability-helper"
import Snackbar from "../../Snackbar"
import Checkbox from "../../Checkbox"

export default class NewContainer extends Component {
  constructor(props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
    this.handleCharacteristicGroupChange = this.handleCharacteristicGroupChange.bind(this)
    this.handleMetricChange = this.handleMetricChange.bind(this)
    this.handleSaveClick = this.handleSaveClick.bind(this)
    this.handleBulkChange = this.handleBulkChange.bind(this)
    this.state = { characteristicValue: this.props.characteristic_value, groupMetrics: '', snackbarText: '', showSnackbar: false, bulkEntry: false }
  }

  componentDidMount() {
    const characteristicValue = update(this.state.characteristicValue, {
      created_by: { $set: this.props.current_user.id },
      characteristic_group_id: { $set: (this.props.characteristic_group ? this.props.characteristic_group.id : null)}
    })

    this.setState({
      characteristicValue: characteristicValue
    })
  }

  handleBulkChange(e) {
    this.setState({
      bulkEntry: !this.state.bulkEntry
    })
  }

  handleCharacteristicGroupChange(name, value) {
    const characteristicValue = update(this.state.characteristicValue, {
      [name]: { $set: value }
    })

    $.ajax({
      type: 'GET',
      url: `/admin/characteristic_groups/${value}/metrics`,
      dataType: 'json',
      success: (data) => {
        this.setState({
          groupMetrics: data.metrics,
          characteristicValue: characteristicValue
        })
      }
    })
  }

  handleMetricChange(name, value) {
    const characteristicValue = update(this.state.characteristicValue, {
      metric_id: { $set: value }
    })

    this.setState({
      characteristicValue: characteristicValue
    })
  }

  handleChange(e) {
    const name = e.target.name
    const value = e.target.value

    const characteristicValue = update(this.state.characteristicValue, { [name]: { $set: value }})
    this.setState({
      characteristicValue: characteristicValue
    })
  }

  handleSaveClick(e) {
    // const url = this.props.redirect_url ? `/admin/characteristic_values?r=${this.props.redirect_url}` : `/admin/characteristic_values?characteristic_group_id=${this.state.characteristicValue.characteristic_group_id}`

    let url = this.state.bulkEntry ? `/admin/characteristic_values/bulk_entry?characteristic_group_id=${this.state.characteristicValue.characteristic_group_id}` : `/admin/characteristic_values?characteristic_group_id=${this.state.characteristicValue.characteristic_group_id}`

    $.post(url, { characteristic_value: this.state.characteristicValue }, () => {
      window.location.href = `/admin/characteristic_values?characteristic_group_id=${this.state.characteristicValue.characteristic_group_id}`
    }, "json").fail((data) => {
      this.setState({
        showSnackbar: true,
        snackbarText: `Characteristic value could not be saved. ${data.responseText}`
      })
    }).complete((data) => {
      setTimeout(() => {
        this.setState({
          showSnackbar: false,
          snackbarText: ''
        })
      }, 4000)
    })
  }

  render() {
    return(
      <Fragment>

        <Paper fullHeight>

          <Flex alignItems='center'>
            <Box mb={3} width={1/2}>
              <Select
                value={this.state.characteristicValue.characteristic_group_id}
                name='characteristic_group_id'
                label='Characteristic Group'
                entities={this.props.characteristic_groups}
                displayEmpty={false}
                handleChange={this.handleCharacteristicGroupChange}
              />
            </Box>

            <Box ml='auto'>
              <Checkbox
                edge="start"
                onChange={this.handleBulkChange}
                checked={this.state.bulkEntry}
                tabIndex={-1}
                label='Bulk Entry'
                disableRipple
              />
            </Box>
          </Flex>

          <Flex alignItems='center' mb={5}>
            <Box width={3/4}>
              <TextInput
                name='name'
                label='Name'
                value={this.state.characteristicValue.name}
                placeholder='2.0'
                onChange={this.handleChange}
                fullWidth
              />
            </Box>

            <Box width={1/4} ml={3}>
              <Select
                value={this.state.characteristicValue.metric_id}
                name='metric_id'
                label='Metric'
                entities={this.state.groupMetrics}
                displayEmpty={true}
                handleChange={this.handleMetricChange}
              />
            </Box>
          </Flex>

          <ButtonLayout>
            <RaisedButton handleClick={this.handleSaveClick}>
              Save Characteristic Value
            </RaisedButton>
          </ButtonLayout>

        </Paper>

        <Snackbar
          open={this.state.showSnackbar}
          message={this.state.snackbarText}
        />

      </Fragment>
    )
  }
}
