import React, {Component} from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Typography from '@material-ui/core/Typography';

class EmptyMessage extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    return(
      <div className='v-mid ph3 ph4-l tc'>
        <Typography variant="h6" className='mb2'>{`No ${this.props.entityType} to compare`}</Typography>
        <Typography>
          Choose a few products from the list page to start comparing.
        </Typography>
      </div>
    )
  }
}

export default EmptyMessage
