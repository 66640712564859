import React, { Fragment, Component } from "react"
import Paper from "../Paper"
import { Table, TableHead, TableCell, TableRow, TableBody, TableHeadSort } from "../tables"
import StatusMarker from "../products/StatusMarker"
import Box from "../Box"
import Flex from "../Flex"
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Typography from "@material-ui/core/Typography"


export default class ProductStatusMockup extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    return(
      <Fragment>
        <Paper elevation={6}>

            <Table size='small' aria-label='products status table' stickyHeader={true} id='products-status-table'>

              {/* <TableHeadSort
                headers={headers}
                order={this.state.order}
                orderBy={this.state.orderBy}
                onRequestSort={this.handleRequestSort}
                borderRight={true}
              /> */}

              <TableHead hover={false}>
                <TableCell className='bg-white' borderRight style={{width: '25%'}}>
                  Product
                </TableCell>
                <TableCell className='bg-white' borderRight style={{width: '12%'}}>
                  Early Development
                </TableCell>
                <TableCell className='bg-white' borderRight style={{width: '12%'}}>
                  FIM
                </TableCell>
                <TableCell className='bg-white' borderRight style={{width: '12%'}}>
                  Feasibility
                </TableCell>
                <TableCell className='bg-white' borderRight style={{width: '12%'}}>
                  Approval clinicals
                </TableCell>
                <TableCell className='bg-white' borderRight style={{width: '12%'}}>
                  Approved
                </TableCell>
              </TableHead>

              <TableBody>
                {this.props.products.map((product) =>
                  <TableRow key={product.id} hover={false}>
                    <TableCell borderRight>
                      <Flex alignItems='center'>
                        <Box>
                          {product.short_name}
                        </Box>
                        {/* <Box className='ml2'>
                          <Typography variant="caption">
                            {`(${product.company_name})`}
                          </Typography>
                        </Box> */}
                      </Flex>
                    </TableCell>
                    <TableCell borderRight>
                      <StatusMarker
                        statusRecord={product}
                        stageNumber={0}
                      />
                    </TableCell>
                    <TableCell borderRight>
                      <StatusMarker
                        statusRecord={product}
                        stageNumber={1}
                      />
                    </TableCell>
                    <TableCell borderRight>
                      <StatusMarker
                        statusRecord={product}
                        stageNumber={2}
                      />
                    </TableCell>
                    <TableCell borderRight>
                      <StatusMarker
                        statusRecord={product}
                        stageNumber={3}
                      />
                    </TableCell>
                    <TableCell borderRight>
                      <StatusMarker
                        statusRecord={product}
                        stageNumber={4}
                      />
                    </TableCell>
                    {/* <TableCell borderRight>
                      <StatusMarker
                        statusRecord={product}
                        stageNumber={6}
                      />
                    </TableCell> */}
                  </TableRow>
                )}
              </TableBody>
            </Table>
        </Paper>
      </Fragment>
    )
  }
}
