import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Paper from "../../Paper"
import Box from "../../Box"
import Flex from "../../Flex"
import Select from "../../Select"
import CKEditor from 'ckeditor4-react';
import { Button, RaisedButton } from "../../buttons"
import Grid from '@material-ui/core/Grid';
import Chip from '@material-ui/core/Chip';
import Dialog from "../../Dialog"
import SourceForm from "../SourceForm"
import update from "immutability-helper"
import Snackbar from "../../Snackbar"
import DatePicker from "../../DatePicker"

export default class AlertEditor extends Component {
  constructor(props) {
    super(props)
    this.handleSourceDeleteClick = this.handleSourceDeleteClick.bind(this)
    this.handleChooseSourceClick = this.handleChooseSourceClick.bind(this)
    this.handleSourceChange = this.handleSourceChange.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.handleHeaderChange = this.handleHeaderChange.bind(this)
    this.handleSectorChange = this.handleSectorChange.bind(this)
    this.state = { alertLinks: null, alertSectors: null, alertLinkOptions: [], source: null, openDialog: false, showSnackbar: false, snackbarText: '' }
  }

  componentDidMount() {
    $.ajax({
      type: "GET",
      url: `/admin/alerts/${this.props.alert.id}`,
      dataType: 'json',
      success: (data) => {
        this.setState({
          alertLinks: data.alert_links,
          alertLinkOptions: data.alert_link_options
        })
      }
    })
  }

  handleSectorChange(name, value) {
    let alert = update(this.state.alert, {
      sector_id: { $set: value }
    })

    this.setState({
      alert: alert
    })
  }

  handleHeaderChange(name, value) {
    $.ajax({
      type: "PUT",
      url: `/admin/alerts/${this.props.alert.id}/update_header`,
      data: {
        alert_link_id: value, primary: true
      },
      dataType: 'json',
      success: (data) => {
        this.setState({
          alertLinks: data.alert_links
        })
      }
    })
  }

  handleClose(e) {
    this.setState({
      openDialog: false
    })
  }

  handleChooseSourceClick(e) {
    this.setState({
      openDialog: true
    })
  }

  handleSourceChange(entity, sourceType) {
    if (sourceType == 'recently_used') {
      let profileField = update(this.state.alert, {
        source_id: { $set: entity.id }
      })
      this.setState({
        source: entity,
        alert: alert,
        openDialog: false
      })
    } else {
      $.get("/admin/sources/find_source", { source_type: sourceType, entity_id: entity.id }, (data) => {
        if (data.source) {
          let alert = update(this.state.alert, {
            source_id: { $set: data.source.source_id }
          })
          this.setState({
            source: data.source,
            alert: alert,
            openDialog: false
          })
        } else {
          $.post("/admin/sources/filter", { source: { kind: sourceType, entity_id: entity.id } }, (data) => {
            let alert = update(this.state.alert, {
              source_id: { $set: data.source.source_id }
            })
            this.setState({
              source: data.source,
              alert: alert,
              openDialog: false
            })
          })
        }
      }, 'json')
    }
  }

  handleSourceDeleteClick(e) {
    this.setState({
      openDialog: true
    })
  }

  render() {
    return (
      <Fragment>
        <Grid container direction="row" justify="center" alignItems="center" spacing={6}>

          <Grid item>
            <Paper>

              <Box mb={2}>
                <Select
                  value={this.props.alert.alert_group_id}
                  name='alert_group_id'
                  label='Alert group'
                  entities={this.props.alertGroups}
                  handleChange={this.props.handleSelectChange}
                />
              </Box>

              <Box>
                <DatePicker
                  name='date'
                  label='Date'
                  handleChange={(value, date) => { this.props.handleDateChange(value, date) }}
                  value={this.props.alert.date}
                />
              </Box>

              <Box mt={2}>
                <Select
                  value={this.props.alert.sector_id}
                  name='sector_id'
                  label='Sectors'
                  entities={this.props.sectors}
                  handleChange={this.props.handleSectorChange}
                />
              </Box>

              {/* {(this.props.alert.alert_group_id == 22 || this.props.alert.alert_group_id == 43) && this.state.alertLinks ?
                <Box mt={2}>
                  <Select
                    value={this.state.alertLinks ? this.state.alertLinks.filter(alertLink => alertLink.primary)[0].id : ""}
                    name='primary'
                    label='Header'
                    entities={this.state.alertLinkOptions}
                    handleChange={this.handleHeaderChange}
                  />
                </Box>
              : ""} */}
            </Paper>
          </Grid>

          <Grid item>
            <Box>
              <CKEditor
                data={this.props.alert.body}
                onChange={this.props.handleChange}
              />
            </Box>
          </Grid>
        </Grid>

        <Box mt={4} width={1/4} mx='auto'>
          <RaisedButton handleClick={this.props.handleFinishedEditingClick}>
            Save
          </RaisedButton>
        </Box>
      </Fragment>
    )
  }
}
