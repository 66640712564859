import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Flex from "../../Flex"
import Box from "../../Box"
import TextInput from "../../TextInput"
import Link from "../../Link"
import Switch from "../../Switch";
import ButtonLayout from "../ButtonLayout"
import RaisedButton from "../../buttons/RaisedButton"
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CKEditor from 'ckeditor4-react';
import { Tabs, Tab } from "../../tabs"
import update from "immutability-helper"
import Snackbar from "../../Snackbar"

export default class ProfileFormContainer extends Component {
  constructor(props) {
    super(props)
    this.handleSaveClick = this.handleSaveClick.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleActiveIndexUpdate = this.handleActiveIndexUpdate.bind(this)
    this.state = { activeIndex: 'brief_summary', brief_summary: '', detailed_description: '', inclusion_criteria: '', exclusion_criteria: '', primary_outcome: '', secondary_outcome: '' }
  }

  componentDidMount() {
    $.ajax({
      type: 'GET',
      url: `/admin/trials/${this.props.trial.id}/edit_profile`,
      data: {
        source_id: this.props.source ? this.props.source.source_id : null
      },
      dataType: 'json',
      success: (data) => {

        this.setState({
          brief_summary: data.brief_summary,
          detailed_description: data.detailed_description,
          inclusion_criteria: data.inclusion_criteria,
          exclusion_criteria: data.exclusion_criteria,
          primary_outcome: data.primary_outcome,
          secondary_outcome: data.secondary_outcome
        })
      }
    })
  }

  handleSaveClick(e) {
    let modelHash = { 'brief_summary': 'trial_text_fields', 'detailed_description': 'trial_text_fields', 'inclusion_criteria': 'trial_criterias', 'exclusion_criteria': 'trial_criterias', 'primary_outcome': 'trial_primary_outcomes', 'secondary_outcome': 'trial_secondary_outcomes' }

    if (this.props.source.source_id == this.state[this.state.activeIndex].source_id) {
      $.ajax({
        type: 'PUT',
        url: `/admin/${modelHash[this.state.activeIndex]}/${this.state[this.state.activeIndex].id}`,
        data: {
          [modelHash[this.state.activeIndex].replace(/s$/, "")]: this.state[this.state.activeIndex]
        },
        dataType: 'json',
        success: (data) => {
          this.setState({
            showSnackbar: true,
            snackbarText: 'Field was updated',
            [this.state.activeIndex]: this.state[this.state.activeIndex]
          })
        },
        error: (data) => {
          this.setState({
            showSnackbar: true,
            snackbarText: 'Field was not updated. There was an error. Please message Stephen on slack in #bug-reports'
          })
        },
        complete: (data) => {
          setTimeout(() => {
            this.setState({
              showSnackbar: false,
              snackbarText: ''
            })
          }, 4000)
        }
      })
    } else {
      let activeModel = update(this.state[this.state.activeIndex], {
        source_id: { $set: this.props.source.source_id}
      })
      $.ajax({
        type: 'POST',
        url: `/admin/${modelHash[this.state.activeIndex]}`,
        data: {
          [modelHash[this.state.activeIndex].replace(/s$/, "")]: activeModel
        },
        dataType: 'json',
        success: (data) => {
          this.setState({
            showSnackbar: true,
            snackbarText: 'Field was added',
            [this.state.activeIndex]: activeModel
          })
        },
        error: (data) => {
          this.setState({
            showSnackbar: true,
            snackbarText: 'Field was not created. There was an error. Please message Stephen on slack in #bug-reports'
          })
        },
        complete: (data) => {
          setTimeout(() => {
            this.setState({
              showSnackbar: false,
              snackbarText: ''
            })
          }, 4000)
        }
      })
    }
    // $.ajax({
    //
    // })
  }

  handleActiveIndexUpdate(e, newValue) {
    this.setState({
      activeIndex: newValue
    })
  }

  handleChange(e) {
    let valueHash = { 'inclusion_criteria': 'criteria_value', 'exclusion_criteria': 'criteria_value', 'brief_summary': 'text_field_value', 'detailed_description': 'text_field_value', 'primary_outcome': 'text', 'secondary_outcome': 'text' }

    let text = e.editor.getData()

    let entityState = update(this.state[this.state.activeIndex], {
      [valueHash[this.state.activeIndex]]: { $set: text }
    })

    this.setState({
      [this.state.activeIndex]: entityState
    })
  }

  render() {
    let valueHash = { 'inclusion_criteria': 'criteria_value', 'exclusion_criteria': 'criteria_value', 'brief_summary': 'text_field_value', 'detailed_description': 'text_field_value', 'primary_outcome': 'text', 'secondary_outcome': 'text' }
    return (
      <Fragment>
        <Tabs variant="fullWidth" value={this.state.activeIndex} onChange={this.handleActiveIndexUpdate} className='bb b--black-10' aria-label="nav tabs">
          <Tab label='Inclusion Criteria' value='inclusion_criteria' />
          <Tab label='Exclusion criteria' value='exclusion_criteria' />
          <Tab label='Brief summary' value='brief_summary' />
          <Tab label='Detailed description' value='detailed_description' />
          <Tab label='Primary outcome' value='primary_outcome' />
          <Tab label='Secondary outcome' value='secondary_outcome' />
        </Tabs>

        <Box mt={2} mb={6}>

          <Box mb={2}>
            <CKEditor
              name={valueHash[this.state.activeIndex]}
              data={this.state[this.state.activeIndex][valueHash[this.state.activeIndex]]}
              onChange={this.handleChange}
            />
          </Box>

          <ButtonLayout>
            <RaisedButton handleClick={this.handleSaveClick}>
              Save Field
            </RaisedButton>
          </ButtonLayout>
        </Box>

        <Snackbar
          open={this.state.showSnackbar}
          message={this.state.snackbarText}
        />
      </Fragment>
    )
  }
}
