import React, { Fragment } from "react"
import Flex from "../../Flex"
import Box from "../../Box"
import Select from "../../Select"


export default function Filters(props) {
  let lgSize = 2

  return(
    <Flex alignItems='center'>

      <Box mr={lgSize} width={1/4}>
        <Select
          label='Sponsor'
          name='sponsored_only'
          value={props.filterValues.sponsored_only}
          entities={[{ name: 'All', value: false }, { name: props.company.short_name, value: true }]}
          handleChange={props.handleFilterChange}
        />
      </Box>

      <Box mr={lgSize} width={1/4}>
        <Select
          label='Products'
          name='product_id'
          value={props.filterValues.product_id}
          entities={props.products}
          handleChange={props.handleFilterChange}
          includeBlank={true}
          blankOption="All"
        />
      </Box>

      <Box mr={lgSize} width={1/4}>
        <Select
          label='Type'
          name='clinical_type_id'
          value={props.filterValues.clinical_type_id}
          entities={props.clinicalTypes}
          handleChange={props.handleFilterChange}
          includeBlank={true}
          blankOption="All"
        />
      </Box>

    </Flex>
  )
}
