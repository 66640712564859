import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import List from "../../lists/List";
import NestedListItemLink from "../../lists/NestedListItemLink"
import ListItemLink from "../../lists/ListItemLink"
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from "../../lists/ListItemText";
import ExpandLess from '../../ExpandLess';
import ExpandMore from '../../ExpandMore';
import Collapse from '@material-ui/core/Collapse';

export default class NavigationList extends Component {
  constructor(props) {
    super(props)
    this.state = { open: false }
  }
  
  componentDidMount() {
    if (this.props.activeGroup == 'Meetings') {
      this.setState({
        open: true
      })
    }
  }
  
  render() {
    return(
      <Fragment>
        
        <ListItem button onClick={() => this.setState({open: !this.state.open})}>
          <ListItemText primary="Meetings" />
          {this.state.open ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        
        <Collapse in={this.state.open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            
            <NestedListItemLink href="/admin/meetings/dashboard" active={this.props.activeGroup == 'Meetings' && this.props.activeItem == 'Dashboard'}>
              <ListItemText primary="Dashboard" />
            </NestedListItemLink>
            
            <NestedListItemLink href="/admin/meetings" active={this.props.activeGroup == 'Meetings' && this.props.activeItem == 'List'}>
              <ListItemText primary="List" />
            </NestedListItemLink>
            
            <NestedListItemLink href="/admin/meetings/new" active={this.props.activeGroup == 'Meetings' && this.props.activeItem == 'New Abstract'}>
              <ListItemText primary="New Meeting" />
            </NestedListItemLink>
            
          </List>
        </Collapse>
        
      </Fragment>
    )
  }
}
