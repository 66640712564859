import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Paper from "../../Paper"
import { List, ListItem, ListItemText } from "../../lists"
import IconButton from "../../IconButton"
import Flex from "../../Flex"
import Box from "../../Box"
import { Button } from "../../buttons"
import Typography from "@material-ui/core/Typography"
import update from "immutability-helper"
import FundingEventRow from "./FundingEventRow"


export default class FundingEvents extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    return(
      <Fragment>
        <Paper minFullHeight={true}>

          <Flex align='items-center'>
            <Box>
              <Typography>
                Funding Events
              </Typography>
            </Box>

            <Box className='ml-auto'>
              <Button href={`/admin/companies/${this.props.company.id}/funding_events/new`}>
                New Funding Event
              </Button>
            </Box>
          </Flex>

          <List>
            {this.props.funding_events.length > 0 ?
              this.props.funding_events.map((fundingEvent) =>
                <FundingEventRow
                  company={this.props.company}
                  fundingEvent={fundingEvent}
                />
              )
            :
              <ListItem>
                <ListItemText primary='No funding events found' />
              </ListItem>
            }
          </List>

        </Paper>
      </Fragment>
    )
  }
}
