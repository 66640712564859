import React, { Fragment } from "react"
import { Table, TableHead, TableBody, TableCell, TableRow } from "../tables"
import Link from "../Link"
import Box from "../Box"
import NumberDisplay from "./NumberDisplay"

export default function FamilyList(props) {
  return(
    <Fragment>
      <Table>
        <TableHead>
          <TableRow hover={false}>
            <TableCell>#</TableCell>
            <TableCell>Title</TableCell>
            <TableCell>Date</TableCell>
            <TableCell>Applicant</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {props.patents.map((patent) =>
            <TableRow key={patent.id} selected={props.patent.id == patent.id}>
              <TableCell>
                <NumberDisplay patent={patent} />
              </TableCell>
              <TableCell className='primary-data'>
                <Link href={`/sectors/${props.sector.abbr}/patents/${patent.id}`} target="_blank">
                  <Box>
                    {patent.title}
                  </Box>
                </Link>
              </TableCell>
              <TableCell>
                {patent.date}
              </TableCell>
              <TableCell>
                {patent.company_name ? 
                  <Link href={`/sectors/${props.sector.abbr}/companies/${patent.company_id}`} target="_blank">
                    {patent.company_name}
                  </Link>
                : patent.orgname}
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </Fragment>
  )
}
