import React from "react"
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import InputAdornment from '@material-ui/core/InputAdornment';
import ClearIcon from '@material-ui/icons/Clear';
import IconButton from '../IconButton'

export default function CountryAutoComplete(props) {

  function handleAutocompleteChange(e, selected) {
    props.handleAutocompleteChange(e, selected, 'country_id')
  }


  return (
    <Autocomplete
      id='country_id'
      options={props.countries}
      name='country_id'
      defaultValue={props.countryValue}
      value={props.countryValue}
      getOptionLabel={(option) => option.name}
      selectOnFocus
      onChange={handleAutocompleteChange}
      renderInput={(params) => <TextField {...params} name='country_name' label='Country' variant="outlined"  />}
    />
  )
}