import React, { Component, Fragment } from "react"
import ProductsList from "../products/ProductsList"
import TrialsList from "../trials/associator/EntitiesList"
import ControlPanel from "../associator/ControlPanel"
import PublicationPanel from "./PublicationPanel"
import Snackbar from "../../Snackbar"
import update from "immutability-helper"
import Associator from "../Associator"
import EntitiesPanel from "./EntitiesPanel"
import AssociatedList from "./AssociatedList"


export default class AssociatorContainer extends Component {
  constructor(props) {
    super(props)
    this.handleActiveIndexUpdate = this.handleActiveIndexUpdate.bind(this)
    this.handleCheckboxSelect = this.handleCheckboxSelect.bind(this)
    this.handleUndoClick = this.handleUndoClick.bind(this)
    this.handleProductDeleteClick = this.handleProductDeleteClick.bind(this)
    this.handleTrialDeleteClick = this.handleTrialDeleteClick.bind(this)
    this.handleTrialSearchChange = this.handleTrialSearchChange.bind(this)
    this.handleTrialSearchClick = this.handleTrialSearchClick.bind(this)
    this.handlePredictedIndexUpdate = this.handlePredictedIndexUpdate.bind(this)
    this.handleSectorCheckboxSelect = this.handleSectorCheckboxSelect.bind(this)
    this.handleProductSearchChange = this.handleProductSearchChange.bind(this)
    this.handleProductSearchClick = this.handleProductSearchClick.bind(this)
    this.handleReviewedClick = this.handleReviewedClick.bind(this)
    this.handlePredictionRemoval = this.handlePredictionRemoval.bind(this)
    this.handlePredictionAssociation = this.handlePredictionAssociation.bind(this)
    this.handleUndoAssociation = this.handleUndoAssociation.bind(this);
    this.handleUndoRemoval = this.handleUndoRemoval.bind(this);
    this.handleProductAssociation = this.handleProductAssociation.bind(this);
    this.handleProductRemoval = this.handleProductRemoval.bind(this);
    this.handleTrialAssociation = this.handleTrialAssociation.bind(this);
    this.handleTrialRemoval = this.handleTrialRemoval.bind(this);
    this.state = { publication: this.props.publication, publicationProducts: this.props.publication_products, publicationTrials: this.props.publication_trials, predictedTrials: this.props.predicted_trials, activeIndex: 0, activeProducts: [], activeTrials: [], associationLoading: false, showSnackbar: false, snackbarText: '', trialSearch: '', searchedTrials: '', searchedProducts: '', predictedIndex: 0, publicationSectors: this.props.publication_sectors, productQuery: '', reviewed: false, lastAction: null, lastActionData: null }
  }

  componentDidMount() {
    let reviewed = this.props.publication_review_status ? this.props.publication_review_status.status : false

    let predictedIndex = this.props.predicted_trials.length == 0 ? 0 : 1

    this.setState({
      reviewed: reviewed,
      predictedIndex
    })
  }

  handlePredictedIndexUpdate(e, newValue) {
    this.setState({
      predictedIndex: newValue
    })
  }

  handleTrialSearchChange(e) {
    console.log(e.target.value)
    if (e.target.value == '') {
      this.setState({
        trialSearch: '',
        searchedTrials: []
      })
    } else {
      this.setState({
        trialSearch: e.target.value
      })
    }
  }

  handleTrialSearchClick(e) {
    $.ajax({
      type: 'GET',
      url: `/admin/trials/search`,
      data: {
        q: this.state.trialSearch
      },
      dataType: 'json',
      success: (data) => {
        this.setState({
          searchedTrials: data.trials
        })
      }
    })
  }

  handleActiveIndexUpdate(e, newValue) {
    this.setState({
      activeIndex: newValue
    })
  }

  handleProductDeleteClick(publicationProduct) {
    $.ajax({
      type: "PUT",
      url: `/admin/publication_products/${publicationProduct.id}`,
      data: {
        publication_product: {
          deleted: true, deleted_by: this.props.current_user.id
        }
      },
      dataType: 'json',
      success: (data) => {
        let publicationProductIds = this.state.publicationProducts.map(publicationProduct => publicationProduct.id)
        let index = publicationProductIds.indexOf(data.publication_product.id)

        let publicationProducts = update(this.state.publicationProducts, {
          $splice: [[index, 1]]
        })
        this.setState({
          publicationProducts: publicationProducts,
          showSnackbar: true,
          snackbarText: 'Product has been removed'
        })
      },
      error: (data) => {
        this.setState({
          showSnackbar: true,
          snackbarText: 'There was an error removing the product'
        })
      },
      complete: (data) => {
        setTimeout(() => {
          this.setState({
            showSnackbar: false,
            snackbarText: ''
          })
        }, 4000)
      }
    })
  }

  handleTrialDeleteClick(publicationTrial) {
    $.ajax({
      type: "PUT",
      url: `/admin/publication_trials/${publicationTrial.manuscript_trials_id}`,
      data: {
        publication_trial: {
          deleted: true, deleted_by: this.props.current_user.id
        }
      },
      dataType: 'json',
      success: (data) => {
        let publicationTrialIds = this.state.publicationTrials.map(publicationTrial => publicationTrial.id)
        let index = publicationTrialIds.indexOf(data.publication_trial.id)

        let publicationTrials = update(this.state.publicationTrials, {
          $splice: [[index, 1]]
        })
        this.setState({
          publicationTrials: publicationTrials,
          showSnackbar: true,
          snackbarText: 'Trial has been removed'
        })
      },
      error: (data) => {
        this.setState({
          showSnackbar: true,
          snackbarText: 'There was an error removing the trial'
        })
      },
      complete: (data) => {
        setTimeout(() => {
          this.setState({
            showSnackbar: false,
            snackbarText: ''
          })
        }, 4000)
      }
    })
  }

  handlePredictionRemoval(manuscriptPredictedTrialId) {
    $.ajax({
      type: 'DELETE',
      url: `/admin/manuscript_predicted_trials/${manuscriptPredictedTrialId}`,
      dataType: 'json',
      success: (data) => {
        let removedTrial = this.props.predicted_trials.find(trial => trial.manuscript_predicted_trial_id === manuscriptPredictedTrialId);
        let predictedTrials = this.props.predicted_trials.filter(trial => trial.manuscript_predicted_trial_id !== manuscriptPredictedTrialId);

        this.setState({
          showSnackbar: true,
          snackbarText: 'Prediction has been removed',
          lastAction: 'remove',
          lastActionData: { manuscriptPredictedTrialId, removedTrial }
        })
      },
      error: (data) => {
        this.setState({
          showSnackbar: true,
          snackbarText: 'There was an error removing the prediction'
        })
      },
      complete: (data) => {
        setTimeout(() => {
          this.setState({
            showSnackbar: false,
            snackbarText: ''
          })
        }, 4000)
      }
    })
  }

  handleUndoAssociation() {
    if (this.state.lastAction !== 'associate') return;

    const { trialId, publicationTrial } = this.state.lastActionData;
    
    $.ajax({
      type: 'DELETE',
      url: `/admin/publication_trials/${publicationTrial.id}`,
      dataType: 'json',
      success: (data) => {
        let publicationTrials = this.state.publicationTrials.filter(trial => trial.id !== publicationTrial.id);
        let predictedTrials = [...this.state.predictedTrials, { trial_id: trialId, manuscript_predicted_trial_id: publicationTrial.manuscript_predicted_trial_id }];

        this.setState({
          publicationTrials,
          predictedTrials,
          showSnackbar: true,
          snackbarText: 'Association has been undone',
          lastAction: null,
          lastActionData: null
        });
      },
      error: (data) => {
        this.setState({
          showSnackbar: true,
          snackbarText: 'There was an error undoing the association'
        });
      }
    });
  }

  handleUndoRemoval() {
    if (this.state.lastAction !== 'remove') return;

    const { manuscriptPredictedTrialId, removedTrial } = this.state.lastActionData;
    
    $.ajax({
      type: 'POST',
      url: `/admin/manuscript_predicted_trials`,
      data: { manuscript_predicted_trial: removedTrial },
      dataType: 'json',
      success: (data) => {
        let predictedTrials = [...this.state.predictedTrials, data.manuscript_predicted_trial];

        this.setState({
          predictedTrials,
          showSnackbar: true,
          snackbarText: 'Removal has been undone',
          lastAction: null,
          lastActionData: null
        });
      },
      error: (data) => {
        this.setState({
          showSnackbar: true,
          snackbarText: 'There was an error undoing the removal'
        });
      }
    });
  }


  handleCheckboxSelect(entityId, entityType) {
    const index = this.state[entityType].indexOf(entityId)

    // const activeEntities = index == -1 ? update(this.state[entityType], { $push: [entityId] }) : update(this.state[entityType], { $splice: [[index, 1]] })

    if (entityType === 'activeProducts') {
      if (index == -1) {
        this.handleProductAssociation(entityId, entityType)
      } else {
        this.handleProductRemoval(entityId, entityType)
      }
    } else if (entityType === 'activeTrials') {
      if (index == -1) {
        this.handleTrialAssociation(entityId, entityType)
      } else {
        this.handleTrialRemoval(entityId, entityType)
      }
    } else {
      console.error('Invalid entity type')
    }
  }

  handleTrialAssociation(entityId, entityType) {
    $.ajax({
      type: 'POST',
      url: `/admin/publication_trials`,
      data: {
        publication_trial: {
          manuscript_id: this.props.publication.id, trial_id: entityId, created_by: this.props.current_user.id
        }
      },
      dataType: 'json',
      success: (data) => {
        let publicationTrials = update(this.state.publicationTrials, {
          $push: [data.publication_trial_with_trial_data]
        })

        let activeEntities = update(this.state[entityType], {
          $push: [entityId]
        });

        this.setState({
          [entityType]: activeEntities,
          publicationTrials: publicationTrials,
          showSnackbar: true,
          snackbarText: 'Trial has been associated',
          lastAction: 'associate',
          lastActionData: { trialId: entityId, publicationTrial: data.publication_trial_with_trial_data }
        })
      },
      error: (data) => {
        this.setState({
          showSnackbar: true,
          snackbarText: 'There was an error associating the trial'
        })
      },
      complete: (data) => {
        setTimeout(() => {
          this.setState({
            showSnackbar: false,
            snackbarText: ''
          })
        }, 4000)
      }
    })
  }

  handleTrialRemoval(entityId, entityType) {
    let publicationTrial = this.state.publicationTrials.filter((publicationTrial) => publicationTrial.trial_id == entityId)[0]

    $.ajax({
      type: 'PUT',
      url: `/admin/publication_trials/${publicationTrial.id}`,
      data: {
        publication_trial: {
          deleted: true, deleted_by: this.props.current_user.id
        }
      },
      dataType: 'json',
      success: (data) => {
        let index = this.state.publicationTrials.indexOf(publicationTrial)

        let publicationTrials = update(this.state.publicationTrials, {
          $splice: [[index, 1]]
        })

        let activeEntities = update(this.state[entityType], {
          $splice: [[index, 1]]
        })

        this.setState({
          [entityType]: activeEntities,
          publicationTrials: publicationTrials,
          showSnackbar: true,
          snackbarText: 'Trial has been removed',
          lastAction: 'remove',
          lastActionData: { publicationTrial: data.publication_trial }
        })
      },
      error: (data) => {
        this.setState({
          showSnackbar: true,
          snackbarText: 'There was an error removing the trial'
        })
      },
      complete: (data) => {
        setTimeout(() => {
          this.setState({
            showSnackbar: false,
            snackbarText: ''
          })
        }, 4000)
      }
    })
  }

  handleProductAssociation(entityId, entityType) {
    $.ajax({
      type: 'POST',
      url: `/admin/publication_products`,
      data: {
        publication_product: {
          manuscript_id: this.props.publication.id, product_id: entityId, created_by: this.props.current_user.id
        }
      },
      dataType: 'json',
      success: (data) => {
        let publicationProducts = update(this.state.publicationProducts, {
          $push: [data.publication_product_with_data]
        })

        let activeEntities = update(this.state[entityType], {
          $push: [entityId]
        });

        this.setState({
          [entityType]: activeEntities,
          publicationProducts: publicationProducts,
          showSnackbar: true,
          snackbarText: 'Product has been associated',
          lastAction: 'associate',
          lastActionData: { publicationProduct: data.publication_product_with_data }
        })
      },
      error: (data) => {
        this.setState({
          showSnackbar: true,
          snackbarText: 'There was an error associating the product'
        })
      },
      complete: (data) => {
        setTimeout(() => {
          this.setState({
            showSnackbar: false,
            snackbarText: ''
          })
        }, 4000)
      }
    })
  }

  handleProductRemoval(entityId, entityType) {
    let publicationProduct = this.state.publicationProducts.filter((publicationProduct) => publicationProduct.product_id == entityId)[0]

    $.ajax({
      type: 'PUT',
      url: `/admin/publication_products/${publicationProduct.id}`,
      data: {
        publication_product: {
          deleted: true, deleted_by: this.props.current_user.id
        }
      },
      dataType: 'json',
      success: (data) => {
        let index = this.state.publicationProducts.indexOf(publicationProduct)

        let publicationProducts = update(this.state.publicationProducts, {
          $splice: [[index, 1]]
        })

        let activeEntities = update(this.state[entityType], {
          $splice: [[index, 1]]
        })

        this.setState({
          [entityType]: activeEntities,
          publicationProducts: publicationProducts,
          showSnackbar: true,
          snackbarText: 'Product has been removed',
          lastAction: 'remove',
          lastActionData: { publicationProduct: data.publication_product }
        })
      },
      error: (data) => {
        this.setState({
          showSnackbar: true,
          snackbarText: 'There was an error removing the product'
        })
      },
      complete: (data) => {
        setTimeout(() => {
          this.setState({
            showSnackbar: false,
            snackbarText: ''
          })
        }, 4000)
      }
    })
  }

  handlePredictionAssociation(trialId) {
    $.ajax({
      type: 'POST',
      url: `/admin/publication_trials`,
      data: {
        publication_trial: {
          manuscript_id: this.props.publication.id, trial_id: trialId, created_by: this.props.current_user.id
        }
      },
      dataType: 'json',
      success: (data) => {
        let publicationTrials = update(this.state.publicationTrials, {
          $push: [data.publication_trial_with_trial_data]
        })
        // Remove the trial from the predicted trials
        let predictedTrials = this.props.predicted_trials.filter((predictedTrial) => predictedTrial.trial_id != trialId)
        console.log(predictedTrials)

        this.setState({
          publicationTrials: publicationTrials,
          predictedTrials: predictedTrials,
          showSnackbar: true,
          snackbarText: 'Trial has been associated',
          lastAction: 'associate',
          lastActionData: { trialId, publicationTrial: data.publication_trial }
        })
      },
      error: (data) => {
        this.setState({
          showSnackbar: true,
          snackbarText: 'There was an error associating the trial'
        })
      },
      complete: (data) => {
        setTimeout(() => {
          this.setState({
            showSnackbar: false,
            snackbarText: ''
          })
        }, 4000)
      }
    })
  }

  handleUndoClick(e) {
    $.ajax({
      type: 'PUT',
      url: `/admin/publication_associations/${this.state.lastPublicationCompany.id}`,
      data: {
        patent_company: {
          deleted: true, deleted_by: this.props.current_user.id
        }
      },
      dataType: 'json',
      success: (data) => {
        const publications = update(this.state.publications, {
          $push: [this.state.lastPublication]
        })
        this.setState({
          publications: publications,
          showSnackbar: true,
          snackbarText: 'Publication association was undone',
          lastPublicationCompany: null,
          lastPublication: null
        })
      },
      error: (data) => {
        this.setState({
          showSnackbar: true,
          snackbarText: 'Publication association could not be undone',
          associationLoading: false
        })
      },
      complete: (data) => {
        setTimeout(() => {
          this.setState({
            showSnackbar: false,
            snackbarText: ''
          })
        }, 5000)
      }
    })
  }

  handleReviewedClick(e) {
    if (this.props.publication_review_status) {
      let type = 'PUT'
      let url = `/admin/manuscript_review_statuses/${this.props.publication_review_status.id}`

      $.ajax({
        type: type,
        url: url,
        data: {
          manuscript_review_status: {
            manuscript_id: this.props.publication.id, status: true, user_id: this.props.current_user.id
          }
        },
        dataType: 'json',
        success: (data) => {
          this.setState({
            showSnackbar: true,
            snackbarText: 'Publication has been marked as reviewed',
            reviewed: true
          })
        },
        error: (data) => {
          this.setState({
            showSnackbar: true,
            snackbarText: 'There was an error. Please try again.'
          })
        },
        complete: (data) => {
          setTimeout(() => {
            this.setState({
              showSnackbar: false,
              snackbarText: ''
            })
          }, 4000)
        }
      })

    } else {
      $.ajax({
        type: 'POST',
        url: `/admin/manuscript_review_statuses`,
        data: {
          manuscript_review_status: {
            manuscript_id: this.props.publication.id, status: true, user_id: this.props.current_user.id
          }
        },
        dataType: 'json',
        success: (data) => {
          this.setState({
            showSnackbar: true,
            snackbarText: 'Publication has been marked as reviewed',
            reviewed: true
          })
        },
        error: (data) => {
          this.setState({
            showSnackbar: true,
            snackbarText: 'There was an error. Please try again.'
          })
        },
        complete: (data) => {
          setTimeout(() => {
            this.setState({
              showSnackbar: false,
              snackbarText: ''
            })
          }, 4000)
        }
      })
    }
  }

  handleSectorCheckboxSelect(sector, checked) {
    let publicationSector = this.state.publicationSectors.filter((publicationSector) => publicationSector.sector_id == sector.id)[0]

    if (checked) {
      $.ajax({
        type: 'PUT',
        url: `/admin/publication_sectors/${publicationSector.id}/remove_all_sectors`,
        data: {
          manuscript_id: this.props.publication.id, sector_id: sector.id, deleted_by: this.props.current_user.id
        },
        dataType: 'JSON',
        success: (data) => {
          this.setState({
            publicationSectors: data.publication_sectors,
            showSnackbar: true,
            snackbarText: 'The sector was removed'
          })
        },
        error: (data) => {
          this.setState({
            showSnackbar: true,
            snackbarText: 'The sector could not be removed'
          })
        },
        complete: (data) => {
          setTimeout(() => {
            this.setState({
              showSnackbar: false,
              snackbarText: ''
            })
          }, 4000)
        }
      })
    } else {
      $.ajax({
        type: 'POST',
        url: `/admin/publication_sectors`,
        data: {
          publication_sector: {
            manuscript_id: this.props.publication.id, sector_id: sector.id, created_by: this.props.current_user.id
          }
        },
        dataType: 'json',
        success: (data) => {
          let publicationSectors = update(this.state.publicationSectors, {
            $push: [data.publication_sector]
          })

          this.setState({
            publicationSectors: publicationSectors,
            showSnackbar: true,
            snackbarText: 'The sector was added'
          })
        },
        error: (data) => {
          this.setState({
            showSnackbar: true,
            snackbarText: 'The sector could not be added'
          })
        },
        complete: (data) => {
          setTimeout(() => {
            this.setState({
              showSnackbar: false,
              snackbarText: ''
            })
          }, 4000)
        }
      })
    }
  }

  handleProductSearchChange(e) {
    let query = e.target.value

    this.setState({
      productQuery: query
    })
  }

  handleProductSearchClick(e) {
    $.ajax({
      type: 'GET',
      url: `/admin/products/search`,
      data: {
        query: this.state.productQuery
      },
      dataType: 'json',
      success: (data) => {
        this.setState({
          searchedProducts: data.products
        })
      }
    })
  }

  render() {

    return(
      <Fragment>

        <Associator
          loading={this.state.loading}
          firstPanel={
            <PublicationPanel
              publication={this.props.publication}
              journal={this.props.journal}
              authors={this.props.authors}
              publicationText={this.props.publication_text}
              meshTerms={this.props.mesh_terms}
              keywords={this.props.keywords}
              searchWords={this.props.search_words}
            />
          }
          secondPanel={
            <ControlPanel
              reviewed={this.state.reviewed}
              sectors={this.props.sectors}
              entitySectors={this.state.publicationSectors}
              handleSectorCheckboxSelect={this.handleSectorCheckboxSelect}
              handleReviewedClick={this.handleReviewedClick}
            />
          }
          thirdPanel={
            <EntitiesPanel
              activeIndex={this.state.activeIndex}
              handleActiveIndexUpdate={this.handleActiveIndexUpdate}
              products={
                <ProductsList
                  activeProducts={this.state.activeProducts}
                  products={this.props.products}
                  searchedProducts={this.state.searchedProducts}
                  productQuery={this.state.productQuery}
                  handleProductSearchChange={this.handleProductSearchChange}
                  handleProductSearchClick={this.handleProductSearchClick}
                  handleCheckboxSelect={this.handleCheckboxSelect}
                />
              }
              trials={
                <TrialsList
                  query={this.state.trialSearch}
                  activeTrials={this.state.activeTrials}
                  trials={this.props.trials}
                  searchedTrials={this.state.searchedTrials}
                  entityTrials={this.state.publicationTrials}
                  handleCheckboxSelect={this.handleCheckboxSelect}
                  handleSearchChange={this.handleTrialSearchChange}
                  handleSearchClick={this.handleTrialSearchClick}
                />
              }
              associated={
                <AssociatedList
                  publicationProducts={this.state.publicationProducts}
                  publicationTrials={this.state.publicationTrials}
                  activeTrials={this.state.activeTrials}
                  predictedTrials={this.state.predictedTrials}
                  handlePredictionRemoval={this.handlePredictionRemoval}
                  handlePredictionAssociation={this.handlePredictionAssociation}
                  handleProductDeleteClick={this.handleProductDeleteClick}
                  handleTrialDeleteClick={this.handleTrialDeleteClick}
                  handleUndoAssociation={this.handleUndoAssociation}
                  handleUndoRemoval={this.handleUndoRemoval}
                />
              }
            />
          }
          associationLoading={this.state.associationLoading}
        />

        <Snackbar
          open={this.state.showSnackbar}
          handleUndoClick={this.state.lastAction === 'associate' ? this.handleUndoAssociation : this.handleUndoRemoval}
          message={this.state.snackbarText}
        />
      </Fragment>
    )
  }
}
