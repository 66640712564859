import React from "react"
import { TableRow, TableCell } from "../../tables"
import Link from "../../Link"

export default function DataTableRow(props) {
  return (
    <TableRow>
      <TableCell className='w-40'>
        {props.chartData.value}
      </TableCell>
      <TableCell className='w-40'>
        {props.chartData.source ?
          <Link href={props.chartData.trial_data ? `/sectors/${props.sector.abbr}/trials/${props.chartData.source.id}` : `/sectors/${props.sector.abbr}/sources/${props.chartData.source.source_id}`} target="_blank">
            {props.chartData.source.source_title}
          </Link>
        : 'N/A'}
      </TableCell>
      <TableCell>
        {props.chartData.human_date}
      </TableCell>
    </TableRow>
  )
}
