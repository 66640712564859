import React from "react"
import MuiPagination from '@material-ui/lab/Pagination';

export default function Pagination(props) {
  return(     
    <MuiPagination
      page={props.page}
      count={props.resultsCount > 20 ? Math.round(props.resultsCount/20) : 1}
      onChange={props.handlePageChange}
      showFirstButton
      showLastButton
    />
  )
}