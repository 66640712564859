import React, { Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Flex from "../Flex"
import Box from "../Box"
import FilterChip from '../filters/FilterChip';

export default function FilterChipsContainer(props) {

  let targetMarketOptionValues = props.filterOptions.targetMarkets.map(targetMarket => targetMarket.value)
  const selectedTargetMarkets = props.chosen.targetMarkets.filter(targetMarket => props.filterValues.target_market_ids.includes(targetMarket.id) ? targetMarket : null)

  let sponsorOptionValues = props.filterOptions.companies.map(company => company.value)
  const selectedSponsors = props.chosen.companies.filter(company => props.filterValues.sponsor_ids.includes(company.id) ? company : null)

  let productOptionValues = props.filterOptions.products.map(product => product.value)
  const selectedProducts = props.chosen.products.filter(product => props.filterValues.product_ids.includes(product.id) ? product : null)

  let therapyTypeOptionValues = props.filterOptions.therapyTypes.map(therapyType => therapyType.value)
  const selectedTherapyTypes = props.chosen.therapyTypes.filter(therapyType => props.filterValues.therapy_type_ids.includes(therapyType.id) ? therapyType : null)

  let clinicalStatusOptionValues = props.filterOptions.statuses.map(status => status.value)
  const selectedClinicalStatuses = props.chosen.statuses.filter(status => props.filterValues.status_ids.includes(status.id) ? status : null)

  let clinicalTypeOptionValues = props.filterOptions.clinicalTypes.map(clinicalType => clinicalType.value)
  const selectedClinicalTypes = props.chosen.clinicalTypes.filter(clinicalType => props.filterValues.clinical_type_ids.includes(clinicalType.id) ? clinicalType : null)

  let targetSiteOptionValues = props.filterOptions.targetSites.map(targetSite => targetSite.value)
  const selectedTargetSites = props.chosen.targetSites.filter(targetSite => props.filterValues.target_site_ids.includes(targetSite.id) ? targetSite : null)

  return(
    <Fragment>

      {selectedSponsors.map((activeFilter) =>
        <FilterChip
          key={activeFilter.id}
          name={activeFilter.short_name}
          value={activeFilter.id}
          className={sponsorOptionValues.includes(activeFilter.id) ? '' : 'o-60'}
          type='sponsor_ids'
          handleDelete={props.handleDelete}
        />
      )}
      {selectedProducts.map((activeFilter) =>
        <FilterChip
          key={activeFilter.id}
          name={activeFilter.short_name}
          value={activeFilter.id}
          className={productOptionValues.includes(activeFilter.id) ? '' : 'o-60'}
          type='product_ids'
          handleDelete={props.handleDelete}
        />
      )}
      {selectedTargetMarkets.map((activeFilter) =>
        <FilterChip
          key={`${activeFilter.id} - target_market_ids`}
          name={activeFilter.name}
          value={activeFilter.id}
          className={targetMarketOptionValues.includes(activeFilter.id) ? '' : 'o-60'}
          type='target_market_ids'
          handleDelete={props.handleDelete}
        />
      )}
      {selectedTherapyTypes.map((activeFilter) =>
        <FilterChip
          key={activeFilter.id}
          name={activeFilter.name}
          value={activeFilter.id}
          className={therapyTypeOptionValues.includes(activeFilter.id) ? '' : 'o-60'}
          type='therapy_type_ids'
          handleDelete={props.handleDelete}
        />
      )}
      {selectedTargetSites.map((activeFilter) =>
        <FilterChip
          key={activeFilter.id}
          name={activeFilter.name}
          value={activeFilter.id}
          className={targetSiteOptionValues.includes(activeFilter.id) ? '' : 'o-60'}
          type='target_site_ids'
          handleDelete={props.handleDelete}
        />
      )}
      {selectedClinicalStatuses.map((activeFilter) =>
        <FilterChip
          key={activeFilter.id}
          name={activeFilter.name}
          value={activeFilter.id}
          className={clinicalStatusOptionValues.includes(activeFilter.id) ? '' : 'o-60'}
          type='status_ids'
          handleDelete={props.handleDelete}
        />
      )}
      {selectedClinicalTypes.map((activeFilter) =>
        <FilterChip
          key={activeFilter.id}
          name={activeFilter.name}
          value={activeFilter.id}
          className={clinicalTypeOptionValues.includes(activeFilter.id) ? '' : 'o-60'}
          type='clinical_type_ids'
          handleDelete={props.handleDelete}
        />
      )}

      {props.filterValues.query && props.search ?
        <FilterChip
          name={props.filterValues.query}
          value={props.filterValues.query}
          type='query'
          handleDelete={props.handleDelete}
        />
      : ''}

    </Fragment>
  )
}
