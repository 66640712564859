import React from "react"
import Flex from "../Flex"
import Box from "../Box"
import { ListItem, ListItemText } from "../lists"
import Typography from "@material-ui/core/Typography"
import Link from "../Link"
import IconButton from "@material-ui/core/IconButton"
import LinkIcon from "@material-ui/icons/Link"
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction"
import LinkToAbstract from "./LinkToAbstract"

export default function AbstractItemDisplay(props) {
  let highlightResult = props.abstractParent.highlight ? props.abstractParent.highlight : null

  function handleMeetingClick(e) {
    e.preventDefault()
    props.handleMeetingClick(props.abstract.science_meeting_occurrence_id)
  }

  return(
    <ListItem divider>
      <ListItemText>
        <Box pb={0.5}>
          <Typography variant="body1">
            <Link href={`/sectors/${props.sector.abbr}/abstracts/${props.abstract.id}`} textColor="mmi-blue">
              {highlightResult && highlightResult.title ?
                <span
                  dangerouslySetInnerHTML={{__html: highlightResult.title }}
                />
              : props.abstract.title}
            </Link>
          </Typography>
        </Box>

        {/* <Box pb={0.5} width={3/4}>
          <Typography variant="body2">
            {highlightResult && highlightResult.text ?
              <span
                dangerouslySetInnerHTML={{__html: highlightResult.text }}
              />
            : props.abstract.text ? truncateString(props.abstract.text, 200) : ""}
          </Typography>
        </Box> */}

        <Flex>
          <Box mr={2}>
            <Typography variant="body2">
              {props.abstract.date}
            </Typography>
          </Box>

          <Box mr={2}>
            <Typography variant="body2">
              <Link href={`/sectors/${props.sector.abbr}/people/${props.abstract.id}`}>
              {highlightResult && highlightResult.presenter ?
                <span
                  dangerouslySetInnerHTML={{__html: highlightResult.presenter }}
                />
              : props.abstract.presenter}
              </Link>
            </Typography>
          </Box>

          <Box>
            <Typography variant="body2">
              <Link href="#" onClick={handleMeetingClick}>
              {highlightResult && highlightResult.meeting_name ?
                <span
                  dangerouslySetInnerHTML={{__html: highlightResult.meeting_name }}
                />
              : props.abstract.meeting_name}
              </Link>
            </Typography>
          </Box>
        </Flex>
      </ListItemText>
      {props.abstract.url ? 
        <ListItemSecondaryAction>
          <LinkToAbstract sectorAbbr={props.sector.abbr} abstractId={props.abstract.id} />
        </ListItemSecondaryAction>
      : ""}
    </ListItem>
  )
}

function truncateString(str, num) {
  if (str.length <= num) {
    return str
  }
  return str.slice(0, num) + '...'
}
