import React, { Fragment, useState } from "react"
import Box from "../../Box"
import { List, ListItem, ListItemText } from "../../lists"
import Collapse from "@material-ui/core/Collapse"
import ExpandLess from '../../ExpandLess';
import ExpandMore from '../../ExpandMore';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Checkbox from "../../Checkbox"
import update from "immutability-helper"

function GroupDisplay(props) {
  const [openGroup, setOpenGroup] = useState(false)

  function handleValueClick(name, value) {
    let values = ""
    let index = props.characteristicValueIds.indexOf(value)

    if (index == -1) {
      values = update(props.characteristicValueIds, {
        $push: [value]
      })
    } else {
      values = update(props.characteristicValueIds, {
        $splice: [[index,1]]
      })
    }

    props.handleCharacteristicValueChange(values)
  }

  let sortedValues = ""

  if (/(l|L)ength(s|$)/.test(props.groupName) || /(d|D)iameter/.test(props.groupName) || /size(s|$)/.test(props.groupName)) {
    sortedValues = props.characteristicValues.filter(characteristicValue => characteristicValue && characteristicValue.metric_name ? characteristicValue : '').sort((a, b) => parseInt(a.name) - parseInt(b.name))
  } else {
    sortedValues = sortByKey(props.characteristicValues, 'name')
  }

  return(
    <Fragment>
      <List>
        <ListItem divider button onClick={() => { setOpenGroup(!openGroup) }}>
          <ListItemIcon>
            {openGroup ? <ExpandLess /> : <ExpandMore />}
          </ListItemIcon>
          <ListItemText
            primary={props.groupName}
          />
        </ListItem>

        <Collapse in={openGroup} timeout="auto" unmountOnExit>
          {sortedValues.map((characteristicValue) =>
            <ListItem key={characteristicValue.id} className='pl4' divider button dense onClick={() => { handleValueClick("characteristic_value_ids", characteristicValue.id) }}>
              <ListItemIcon>
                <Checkbox
                  checked={props.characteristicValueIds.includes(characteristicValue.id) ? true : false}
                />
              </ListItemIcon>

              <ListItemText
                primary={characteristicValue.metric_name ? `${characteristicValue.name} ${characteristicValue.metric_name}` : characteristicValue.name}
              />
            </ListItem>
          )}
        </Collapse>

      </List>
    </Fragment>
  )
}

export default GroupDisplay
