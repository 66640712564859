import React, { Fragment } from "react"
import { TableRow, TableCell } from "../../tables"
import Box from "../../Box"
import Link from "../../Link"
import Tooltip from "@material-ui/core/Tooltip"
import Typography from "@material-ui/core/Typography"

export default function TrialProgressRow(props) {
  let currentDate = props.regulatoryTrial.completion_date ? new Date(props.regulatoryTrial.completion_date) : new Date
  let firstReceivedDate = new Date(props.regulatoryTrial.first_received_date)
  let dateDifference = currentDate.getTime() - firstReceivedDate.getTime()
  let durationInDays = dateDifference / (1000 * 60 * 60 * 24);

  let compareHref = `/sectors/${props.sector.abbr}/trials/compare_trials?trial_ids=${props.trialsForAverage.map(trial => trial.trial_id)}`
  return(
    <TableRow key={props.regulatoryTrial.id}>
      <TableCell borderRight borderLeft>
        <Fragment>
          <Link href={`/sectors/${props.sector.abbr}/trials/${props.regulatoryTrial.trial_id}`} target="_blank">
            {props.regulatoryTrial.acronym || props.regulatoryTrial.short_name}
          </Link>

          <Box>
            <Typography variant="caption">
              {props.regulatoryTrial.type_name}
            </Typography>
          </Box>
        </Fragment>

      </TableCell>
      <TableCell borderRight>
        {props.regulatoryTrial.status}
      </TableCell>
      <TableCell borderRight>
        {dateFormat(props.regulatoryTrial.first_received_date)}
      </TableCell>
      <TableCell borderRight>
        <Fragment>
          <span className='pr2'>
            {dateFormat(props.regulatoryTrial.completion_date)}
          </span>
          <span className='i'>
            {props.regulatoryTrial.completion_date_type ? `(${props.regulatoryTrial.completion_date_type})` : ''}
          </span>
        </Fragment>
      </TableCell>
      <TableCell borderRight>
        {`${Math.round(durationInDays)} days`}
      </TableCell>
      <TableCell borderRight>
        <Tooltip title="Compare trials used to build average">
          <Link href={compareHref} target="_blank">
            {`${Math.round(props.averageIdeDuration)} days`}
          </Link>
        </Tooltip>
      </TableCell>
    </TableRow>
  )
}
