import React from "react"
import { Tabs, Tab } from "../tabs"

export default function SearchTabs(props) {
  return(
    <Tabs variant='scrollable' scrollButtons='auto' value={props.activeIndex} onChange={props.handleActiveIndexClick} className='bb b--black-10'>
      <Tab label='All' value="All" />
      {props.tabs.map((tab) =>
        <Tab label={tab.name} value={tab.value} />
      )}
    </Tabs>
  )
}