import React from "react"
import Paper from "../Paper"
import Flex from "../Flex"
import Box from "../Box"
import Typography from "@material-ui/core/Typography"
import { Tabs, Tab } from '../tabs';
import { List, ListItem, ListItemText, ListItemLink } from "../lists"

export default function TopUpdates(props) {

  return(
    <Paper className='h-100'>

      <Flex alignItems='center' borderBottom={1} borderColor="#e5e5e5">
        <Box>
          <Typography variant="h6">
            Top updates
          </Typography>
        </Box>

        <Box ml='auto'>
          <Tabs value={props.tabIndex} onChange={props.handleTabIndexChange} aria-label="updates date tabs">
            <Tab label="1 Week" />
            <Tab label="1 Month" />
            <Tab label="3 Months" />
          </Tabs>
        </Box>
      </Flex>

      <Box mt={2} height="90%">
        {props.updates.length > 0 ?
          <List>
            {props.updates.map((update) =>
              <ListItemLink key={update.id} my={2} href={`/sectors/${props.sector.abbr}/trials/${update.id}/updates`} divider>
                <ListItemText
                  primary={update.data_view_acronym || update.data_view_short_name}
                />

                <Typography variant="body1" color='black'>
                  {`${update.change_count} new ${update.change_count == 1 ? 'update' : 'updates'}`}
                </Typography>
              </ListItemLink>
            )}
          </List>
        :
          <Flex alignItems='center' justifyContent='center' height="90%">
            <Box textAlign='center' maxWidth="50%">
              No updates found for this time period
            </Box>
          </Flex>
        }
      </Box>

    </Paper>
  )
}
