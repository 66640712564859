import React, { Component } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Chart from "../charts/Chart"

export default class InvestigatorsChart extends Component {
  constructor(props) {
    super(props)
  }

  setVisible(array) {
    switch (array[0]) {
      case 'Recruiting':
        return(true)
        break;
      case 'Post Recruiting':
        return(this.props.investigatorsChartData[0].length > 1 ? false : true)
        break;
      default:
        return(true)
    }
  }

  determinePlotLine() {
    if (this.props.showTargetEnrollment && this.props.targetEnrollment) {
      return([{ color: '#FF0000', value: this.props.targetEnrollment.enrollment, width: 2, zIndex: 1, id: 'target-enrollment-line', label: { text: `Target Enrollment - ${this.props.targetEnrollment.enrollment}` } }])
    }
  }

  setXAxisPlotLine() {
    if (this.props.showEnrollmentDates) {
      return([{ color: '#B57E7E', value: this.props.enrollmentStartDateForChart, width: 2, zIndex: 1, id: 'enrollment-start-date-line', label: { text: 'Enrollment start date' }}, { color: '#B57E7E', value: this.props.enrollmentEndDateForChart, width: 2, zIndex: 1, id: 'enrollment-end-date-line', label: { text: 'Completion date' }}])
    }
  }

  setxAxisPlotBand() {
    let plotBands = this.props.statusChartData.map((trialStatus, index) => {
      return({ from: trialStatus.date, to: (this.props.statusChartData[index+1] ? this.props.statusChartData[index+1].date : this.props.currentTime), color: this.setPLotBandColor(trialStatus.status), label: { text: trialStatus.status, style: { color: 'black' }, y: 40 } })
    })

    return(plotBands)
  }

  setPLotBandColor(statusName) {
    let bandColor = ""
    switch (statusName) {
      case 'Not yet recruiting':
        bandColor = '#FFFF94'
        break;
      case 'Underway', 'Recruiting':
        bandColor = '#a6f1a6'
        break;
      case 'Suspended', 'Withdrawn', 'Terminated', 'Trial halted':
        bandColor = '#FF4D4D'
        break;
      case 'Active, not recruiting':
        bandColor = '#64a8ff'
        break;
      case 'Completed':
        bandColor = ''
        break;
    }

    return(bandColor)
  }

  setMarkerDisplay(array) {
    if (array[0] == 'Target enrollment') {
      return(false)
    } else {
      return(true)
    }
  }

  setDataLabelDisplay(array) {
    if (this.props.hideDataLabels) {
      return(false)
    } else {
      if (array[0] == 'Target enrollment') {
        return(false)
      } else {
        return(true)
      }
    }
  }

  setXaxisMin() {
    if (this.props.investigatorsChartData.length == 0) {
      null
    } else {
      let dateValArray = []

      this.props.investigatorsChartData.map(array =>
        dateValArray.push(array.x)
      )

      if (!this.props.showEnrollmentDates) {
        return(Math.min.apply(Math, dateValArray) - ((Math.max.apply(Math, dateValArray) - Math.min.apply(Math, dateValArray)) * 0.05))
      } else {
        let minEnrollmentDate = Math.min.apply(Math, dateValArray)
        let maxEnrollmentDate = Math.max.apply(Math, dateValArray)


        if (minEnrollmentDate < this.props.enrollmentStartDateForChart) {

          let padding = (maxEnrollmentDate - minEnrollmentDate) * 0.05

          return(minEnrollmentDate - padding)

        } else {

          let lastWithEndDate = [maxEnrollmentDate, this.props.enrollmentEndDateForChart]

          let padding = (Math.max.apply(Math, lastWithEndDate) - Math.min.apply(Math, lastWithEndDate)) * 0.05

          return(this.props.enrollmentStartDateForChart - padding)
        }

      }
    }
  }

  setXaxisMax() {
    let enrollmentDateValArray = []

    this.props.investigatorsChartData.map(enrollmentArray =>
      enrollmentDateValArray.push(enrollmentArray.x)
    )

    if (!this.props.showEnrollmentDates) {

      return(((Math.max.apply(Math, enrollmentDateValArray) - Math.max.apply(Math, enrollmentDateValArray)) * 0.05) + Math.max.apply(Math, enrollmentDateValArray))

    } else {

      let maxEnrollmentDate = Math.max.apply(Math, enrollmentDateValArray)
      let lastWithEndDate = [maxEnrollmentDate, this.props.enrollmentEndDateForChart]

      let padding = (Math.max.apply(Math, lastWithEndDate) - Math.min.apply(Math, lastWithEndDate)) * 0.05

      return(padding + Math.max.apply(Math, lastWithEndDate))
    }
  }

  setYaxisMax() {
    if (this.props.investigatorsChartData.length == 0) {
      null
    } else {
      let enrollmentValArray = []
      let [first, ...enrollmentVals] = this.props.chartData[0]

      enrollmentVals.map(enrollmentArray =>
        enrollmentValArray.push(enrollmentArray[1])
      )

      if (this.props.targetEnrollment) {
        enrollmentValArray.push(this.props.targetEnrollment.enrollment)
      }

      let maxEnrollment = Math.max.apply(Math, enrollmentValArray)

      return(maxEnrollment + 5)
    }
  }

  setYaxisMin() {
    if (this.props.investigatorsChartData.length == 0) {
      return(null)
    } else {
      let enrollmentValArray = []
      let [first, ...enrollmentVals] = this.props.investigatorsChartData[0]

      enrollmentVals.map(enrollmentArray =>
        enrollmentValArray.push(enrollmentArray[1])
      )

      if (this.props.targetEnrollment) {
        enrollmentValArray.push(this.props.targetEnrollment.enrollment)
      }

      let minEnrollment = Math.min.apply(Math, enrollmentValArray)

      if (enrollmentValArray.length == 1) {
        return(enrollmentValArray[0] - 0.25 * enrollmentValArray[0])
      } else {
        return(null)
      }
    }

  }

  formatData() {
    let data = ""
    if (this.props.investigatorsChartData) {
      data = this.props.investigatorsChartData.map((array) => {
        let newArr = new Array(array)
        let flatArr = Array.prototype.concat.apply([], newArr)
        let dataArr = flatArr.splice(1)

        return({
          name: array[0],
          visible: this.setVisible(array),
          marker: {
            enabled: this.props.dataLabels
          },
          dataLabels: {
            enabled: this.props.dataLabels
          },
          data: dataArr
        })
      })
    } else {
      data = []
    }

    return(data)
  }

  render() {
    return(
      <Chart
        chartId='investigators-chart'
        chartOptions={{type: 'spline', height: 300}}
        legend={true}
        plotOptions={{spline: { dataLabels: { enabled: this.props.dataLabels }, marker: { enabled: this.props.dataLabels }}}}
        xAxisType={this.props.xAxisType}
        xAxisTitle={this.props.xAxisTitle}
        categories={this.props.categories}
        // xAxisPlotLine={this.setXAxisPlotLine()}
        // xAxisMin={this.setXaxisMin()}
        // xAxisMax={this.setXaxisMax()}
        // xAxisPlotBand={this.setxAxisPlotBand()}
        // yAxisMax={this.setYaxisMax()}
        // yAxisMin={this.setYaxisMin()}
        formattedData={this.formatData()}
        simple={false}
        startOnTick={true}
        endOnTick={true}
        title={this.props.title}
        yAxisTitle='# of investigators'
      />
    )
  }
}


InvestigatorsChart.defaultProps = {
  title: 'Investigators',
  xAxisTitle: 'Days elapsed',
  xAxisType: 'datetime',
  dataLabels: true
}
