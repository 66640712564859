import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import Box from "../../Box"
import TextInput from "../../TextInput"
import Flex from "../../Flex"
import { RaisedButton } from "../../buttons"
import Select from "../../Select"
import DatePicker from "../../DatePicker"


export default class StatusForm extends Component {
  constructor(props) {
    super(props)
    this.handleStatusSaveClick = this.handleStatusSaveClick.bind(this)
    this.handleStatusChange = this.handleStatusChange.bind(this)
    this.handleStatusDeleteClick = this.handleStatusDeleteClick.bind(this)
    this.state = { clinical_status_id: '', date: '' }
  }

  componentDidMount() {
    if (this.props.trialStatus) {
      this.setState({
        clinical_status_id: this.props.trialStatus.clinical_status_id,
        date: this.props.trialStatus.date
      })
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.trialStatus == null) {
      this.setState({
        clinical_status_id: '',
        date: ''
      })
    }
  }

  handleStatusSaveClick(e) {
    this.props.handleStatusSaveClick(this.state.clinical_status_id)
  }

  handleStatusDeleteClick(e) {
    this.props.handleStatusDeleteClick(this.state.clinical_status_id)
  }

  handleStatusChange(name, value) {
    this.setState({
      clinical_status_id: parseInt(value)
    })
  }

  render() {
    return(
      <Fragment>
        <Box mt={2} mb={6}>

          <Box mb={3}>
            <Select
              entities={this.props.statuses}
              value={this.state.clinical_status_id}
              label='Statuses'
              displayEmpty={true}
              handleChange={this.handleStatusChange}
            />
          </Box>

          <Box>
            <DatePicker
              name='date'
              label='Date'
              value={this.state.date}
              handleChange={(date) => { this.setState({ date: date }) }}
            />
          </Box>


          <Box mt={4}>
            <RaisedButton handleClick={this.handleStatusSaveClick}>
              Save status
            </RaisedButton>
          </Box>

          {this.props.currentUser.id == 3 ?
            <Box mt={3} color='error.main'>
              <RaisedButton color='inherit' handleClick={this.handleStatusDeleteClick}>
                Delete Status
              </RaisedButton>
            </Box>
          : ''}
        </Box>
      </Fragment>
    )
  }
}
