import React, { Fragment } from "react"
import TweetRow from "./TweetRow"
import { Table, TableHead, TableBody, TableRow, TableCell } from "../../tables"
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TablePaginationActions from "../../tables/TablePaginationActions"

export default function TweetsTable(props) {
  return(
    <Fragment>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>User</TableCell>
            <TableCell>Tweet</TableCell>
            <TableCell>Date</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {props.tweets.map((tweet) =>
            <TweetRow
              key={tweet._id || tweet.id}
              tweet={tweet._source ? tweet._source : tweet}
              existingAlertTweets={props.existingAlertTweets}
              handleAlertClick={props.handleAlertClick}
            />
          )}
        </TableBody>
        <TableFooter>
          <TablePagination
            colSpan={5}
            count={props.count}
            rowsPerPage={100}
            page={props.page}
            SelectProps={{
              inputProps: { 'aria-label': 'rows per page' },
              native: true,
            }}
            onChangePage={props.handleChangePage}
            onChangeRowsPerPage={props.handleChangeRowsPerPage}
            ActionsComponent={TablePaginationActions}
          />
        </TableFooter>
      </Table>

    </Fragment>
  )
}
