import React from "react"
import { makeStyles } from '@material-ui/core/styles';
import MuiRadioGroup from '@material-ui/core/RadioGroup';

const useStyles = makeStyles(theme => ({
  radioGroup: {
    flexDirection: 'row'
  }
}));

export default function RadioGroup(props) {
  const classes = useStyles();
  return (
    <MuiRadioGroup className={classes.radioGroup} {...props}>
      {props.children}
    </MuiRadioGroup>
  )
}
