import React, { Component } from "react"
import PropTypes from "prop-types"
import Select from "../../Select"
import Flex from "../../Flex"
import Box from "../../Box"
import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';
import DatePicker from "../../DatePicker"

export default function ReviewerFilters(props) {
  return (
    <Grid container spacing={3} alignItems='stretch'>

      <Grid item xs={4} lg={2}>
        <Box>
          <Select
            label='Sectors'
            name='sector_id'
            includeBlank={true}
            value={props.sector_id || ''}
            entities={props.sectors}
            handleChange={props.handleFilterChange}
          />
        </Box>
      </Grid>

      {props.products ?
        <Grid item xs={4} lg={2}>
          <Box>
            <Select
              label='Product'
              name='product_id'
              includeBlank={true}
              value={props.product_id || ''}
              entities={props.products}
              handleChange={props.handleFilterChange}
            />
          </Box>
        </Grid>
      : ""}

      <Grid item xs={4} lg={2}>
        <Box>
          <DatePicker
            name='date'
            label='Date'
            value={props.date}
            handleChange={props.handleDateChange}
          />
        </Box>
      </Grid>

    </Grid>
  )
}
