import React from "react"
import Paper from "../../Paper"
import Box from "../../Box"
import Tabs from "../../tabs/Tabs"
import Tab from "../../tabs/Tab"
import TabPanel from "../../tabs/TabPanel"
import ProductsCheckboxList from '../products/CheckboxList'


export default function EntitiesPanel(props) {
  return (
    <React.Fragment>
      <Paper fullHeight>

        <Box height="100%">
          <Tabs variant="fullWidth" value={props.predictedActiveIndex} onChange={props.handlePredictedIndexUpdate} className='bb b--black-10' aria-label="entities tabs">
            {props.products ? <Tab label='Products' value='products' /> : ""}
            {props.trials ? <Tab label='Trials' value='trials' /> : ""}
            {props.companies ? <Tab label='Companies' value='companies' /> : ""}
          </Tabs>

          <Box mt={3} height="90%" overflow='auto'>
            <TabPanel value={props.predictedActiveIndex} index='products'>
              <ProductsCheckboxList 
                products={props.products}
                activeProducts={props.activeProducts}
                entityProducts={props.entityProducts}
                handleCheckboxSelect={props.handleCheckboxSelect}
              />
            </TabPanel>
            <TabPanel value={props.activeIndex} index='companies'>
              {props.companies}
            </TabPanel>
            <TabPanel value={props.activeIndex} index='trials'>
              {props.trials}
            </TabPanel>
          </Box>
        </Box>

      </Paper>
    </React.Fragment>
  )
}
