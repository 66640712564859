import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import ListItem from "../lists/ListItem"
import ListItemText from "../lists/ListItemText"
import Checkbox from "../Checkbox"

export default class SectorListItem extends Component {
  constructor(props) {
    super(props)
    this.handleCheckboxSelect = this.handleCheckboxSelect.bind(this)
  }
  
  handleCheckboxSelect(e) {
    this.props.handleCheckboxSelect(this.props.sector, this.props.checked)
  }
  
  render() {
    return(
      <ListItem button key={this.props.sector.id} role={undefined} onClick={this.handleCheckboxSelect}>
        <Checkbox 
          disabled={this.props.disabled}
          checked={this.props.checked} 
        />
        
        <ListItemText primary={this.props.sector.abbr} />
      </ListItem>
    )
  }
}
