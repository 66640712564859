// https://material-ui.com/api/button/
import React from "react"
import RaisedButton from './RaisedButton';
import { withStyles, createMuiTheme, ThemeProvider, makeStyles } from '@material-ui/core/styles';

const theme = createMuiTheme({
  palette: {
    primary: {
      light: "#e57373",
      main: "#f44336",
      dark: "#d32f2f",
      contrastText: "#fff"
    },
  },
});

const useStyles = makeStyles(theme => ({
  root: {
    color: '#fff !important'
  }
}));


export default function WarningButton(props) {
  const classes = useStyles(props);

  const { color='primary', ...rest } = props

  return (
    <ThemeProvider theme={theme}>
      <RaisedButton color="primary" className={classes.root} {...rest}>
        {props.children}
      </RaisedButton>
    </ThemeProvider>
  )
}
