import React, { Component, Fragment } from 'react'
import { withSnackbar } from '../../SnackbarContainer'
import Associator from '../Associator'
import ControlPanel from '../associator/ControlPanel'
import ProductsCheckboxList from '../products/CheckboxList'
import RecallCard from './RecallCard'
import EntitiesPanel from '../associator/EntitiesPanel'
import InlineSearchContainer from '../search/InlineSearchContainer'
import EntityPanelContainer from "../associator/EntityPanelContainer"
import AssociatedList from '../associator/AssociatedList'

class AssociatorContainer extends Component {
  constructor(props) {
    super(props)
    this.handleAssociateClick = this.handleAssociateClick.bind(this)
    this.handleCheckboxSelect = this.handleCheckboxSelect.bind(this)
    this.handleClearSearchClick = this.handleClearSearchClick.bind(this)
    this.handleSearchClick = this.handleSearchClick.bind(this)
    this.handleProductDeleteClick = this.handleProductDeleteClick.bind(this)
    this.handleActiveIndexUpdate = this.handleActiveIndexUpdate.bind(this)
    this.state = { products: this.props.products, associatedProducts: this.props.associated_products, query: "", activeProducts: [], activeIndex: 'products', predictedActiveIndex: 'products' }
  }

  handleClearSearchClick(e) {
    this.setState({
      products: this.props.products
    })
  }

  handleActiveIndexUpdate(e, newValue) {
    this.setState({
      activeIndex: newValue
    })
  }

  handleAssociateClick(e) {
    $.ajax({
      type: "POST",
      url: "/admin/product_bfarm_recalls",
      data: {
        product_bfarm_recall: {
          product_id: this.state.activeProducts[0], bfarm_recall_id: this.props.bfarm_recall.id
        }
      },
      dataType: 'json',
      success: (data) => {
        this.getAssociatedProducts()
      }
    })
  }

  getAssociatedProducts(e) {
    $.ajax({
      type: "GET",
      url: `/admin/bfarm_recalls/${this.props.bfarm_recall.id}/associator`,
      dataType: 'json',
      success: (data) => {
        this.setState({
          associatedProducts: data.associated_products,
          predictedProducts: data.predicted_products,
          products: data.products,
          activeProducts: []
        })

        this.props.snackbarShowMessage("Product has been associated")
      }
    })
  }

  handleProductDeleteClick(product) {
    $.ajax({
      type: "PUT",
      url: `/admin/product_bfarm_recalls/${product.entity_product_id}`,
      data: {
        product_bfarm_recall: {
          deleted: true, deleted_by: this.props.user.id
        }
      },
      dataType: 'json',
      success: (data) => {
        this.getAssociatedProducts()
      }
    })
  }

  handleCheckboxSelect(productId) {
    let index = this.state.activeProducts.indexOf(productId)

    let productIds = ""

    // if (index == -1) {
    //   productIds = update(this.state[type], {
    //     $push: [product.id]
    //   })
    // } else {
    //   productIds = update(this.state[type], {
    //     $splice: [[index, 1]]
    //   })
    // }

    this.setState({
      activeProducts: [productId]
    })
  }

  handleSearchClick(query) {
    $.ajax({
      type: "GET",
      url: "/admin/products/search",
      data: {
        query: query
      },
      dataType: 'json',
      success: (data) => {
        this.setState({
          products: data.products,
          query: query
        })
      },
      error: (data) => {
        this.props.snackbarShowMessage("There was an error searching for the product. Please refresh the page and try again.")
      }
    })
  }


  render() {
    // predicted={
    //   <PredictedPanel
    //     products={this.props.predicted_products}
    //     activeProducts={this.state.activeProducts}
    //     entityProducts={this.state.associatedProducts}
    //     predictedActiveIndex={this.state.predictedActiveIndex}
    //     handleCheckboxSelect={this.handleCheckboxSelect}
    //   />
    // }
    return (
      <Fragment>
        <Associator 
          firstPanel={
            <RecallCard 
              bfarmRecall={this.props.bfarm_recall}
              text={this.props.text}
            />
          }

          secondPanel={
            <ControlPanel 
              handleAssociateClick={this.handleAssociateClick}
            />
          }
          thirdPanel={
            <EntitiesPanel 
              activeIndex={this.state.activeIndex}
              handleActiveIndexUpdate={this.handleActiveIndexUpdate}
              associated={
                <AssociatedList 
                  entityProducts={this.state.associatedProducts}
                  handleProductDeleteClick={this.handleProductDeleteClick}
                />
              }
              products={
                <EntityPanelContainer 
                  search={
                    <InlineSearchContainer
                      searchLabel="Search products" 
                      helperText="Search by name"
                      query={this.state.query}
                      handleSearchClick={this.handleSearchClick}
                      handleClearSearchClick={this.handleClearSearchClick}
                    />
                  }
                  entitiesList={
                    <ProductsCheckboxList 
                      products={this.state.products}
                      predictedProducts={this.props.predicted_products}
                      activeProducts={this.state.activeProducts}
                      entityProducts={this.state.associatedProducts}
                      handleCheckboxSelect={this.handleCheckboxSelect}
                      handleProductDeleteClick={this.handleProductDeleteClick}
                    />
                  }
                />
              }
            />
          }
        />
      </Fragment>
    )
  }
}

export default withSnackbar(AssociatorContainer)