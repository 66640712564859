import React, { Component, Fragment } from "react"
import PropTypes from "prop-types"
import ListRecord from "./ListRecord"
import { Table, TableHead, TableRow, TableCell, TableBody } from "../../tables"

export default class CompaniesTable extends Component {
  constructor(props) {
    super(props)
  }
  
  render() {
    return(
      <Table className='mvm mh2 w-100'>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell># of Products</TableCell>
            <TableCell>Date Added</TableCell>
            <TableCell>Created By</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        
        <TableBody>
          {this.props.companies.map((company) =>
            <ListRecord 
              key={company.id} 
              company={company} 
            />
          )}
        </TableBody>
      </Table>
    )
  }
}
