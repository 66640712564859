import React from "react"
import Box from "../Box"
import { List, } from "../lists"
import FdaAdverseEventItem from "../fda_adverse_events/RegulatoryItem"

export default function AdverseEventResults(props) {

  return(
    <React.Fragment>
      <Box height="100%">
        <List>
          {props.adverseEvents.map((result) =>
            <FdaAdverseEventItem 
              key={result._id} 
              result={result._source ? result._source : result} 
              sector={props.sector} 
              handleProductCodeClick={props.handleProductCodeClick}
              handleApplicantClick={props.handleApplicantClick}
              handleEventTypeClick={props.handleEventTypeClick}
            />
          )}
        </List>
      </Box>
    </React.Fragment>
  )
}