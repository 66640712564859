import React, { Component, Fragment } from "react"
import Paper from "../../Paper"
import Box from "../../Box"
import update from "immutability-helper"
import EditButton from "../EditButton"
import DeleteButton from "../DeleteButton"
import { Table, TableRow, TableCell, TableBody } from "../../tables"
import TableHeadSort from "../../tables/TableHeadSort"
import Select from "../../Select"

export default class ProductSearchTerms extends Component {
  constructor(props) {
    super(props)
    this.handleSectorChange = this.handleSectorChange.bind(this)
    this.handleDeleteClick = this.handleDeleteClick.bind(this)
    this.handleRequestSort = this.handleRequestSort.bind(this)
    this.desc = this.desc.bind(this)
    this.stableSort = this.stableSort.bind(this)
    this.getSorting = this.getSorting.bind(this)
    this.state = { productSearchTerms: this.props.product_search_terms, sector_id: this.props.sector_id, order: 'asc', orderBy: 'product_name' }
  }

  handleSectorChange(name, value) {
    let sectorId = value
    $.ajax({
      type: 'GET',
      url: `/admin/product_search_terms`,
      data: {
        sector_id: value
      },
      dataType: 'json',
      success: (data) => {
        this.setState({
          sector_id: sectorId,
          productSearchTerms: data.product_search_terms
        })
      }
    })
  }

  desc(a, b, orderBy) {
    let bOrderKey = b[orderBy]
    let aOrderKey = a[orderBy]
    if (bOrderKey < aOrderKey) {
      return -1;
    }
    if (bOrderKey > aOrderKey) {
      return 1;
    }
    return 0;
  }

  stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = cmp(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
  }

  getSorting(order, orderBy) {
    return order === 'desc' ? (a, b) => this.desc(a, b, orderBy) : (a, b) => -this.desc(a, b, orderBy);
  }

  handleRequestSort(event, property) {
    const isDesc = this.state.orderBy === property && this.state.order === 'desc';

    this.setState({
      order: isDesc ? 'asc' : 'desc',
      orderBy: property
    })
  };

  handleDeleteClick(productSearchTerm) {
    $.ajax({
      type: 'PUT',
      url: `/admin/product_search_terms/${productSearchTerm.id}`,
      data: {
        product_search_term: {
          deleted: true, deleted_by: this.props.user.id
        }
      },
      dataType: 'json',
      success: (data) => {
        let index = this.state.productSearchTerms.indexOf(productSearchTerm)
        let productSearchTerms = update(this.state.productSearchTerms, {
          $splice: [[index,1]]
        })

        this.setState({
          productSearchTerms: productSearchTerms
        })
      }
    })
  }

  render() {
    let headers = [{ label: 'Search term', name: 'search_term' }, { label: 'Product', name: 'product_name' }, { label: 'Case sensitive', name: 'case_sensitive' }, { label: '', name: '' }, { label: '', name: ''}]
    return(
      <Fragment>

        <Paper fullHeight>
          <Box height="100%">

            <Box width={1/4} mb={3}>
              <Select
                label='Sector'
                name='sector_id'
                value={this.state.sector_id}
                entities={this.props.sectors}
                handleChange={this.handleSectorChange}
              />
            </Box>

            <Box overflow='auto' height="80%">
              <Table size='small' aria-label='products table' stickyHeader={true} id='products-table'>
                <TableHeadSort
                  headers={headers}
                  order={this.state.order}
                  orderBy={this.state.orderBy}
                  onRequestSort={this.handleRequestSort}
                />

                <TableBody>
                  {this.state.productSearchTerms.map((productSearchTerm) =>
                    <TableRow key={productSearchTerm.id}>
                      <TableCell>{productSearchTerm.search_term}</TableCell>
                      <TableCell>{productSearchTerm.product_name}</TableCell>
                      <TableCell>{productSearchTerm.case_sensitive ? "YES" : "NO"}</TableCell>
                      <TableCell>
                        <EditButton component="a" href={`/admin/products/${productSearchTerm.product_id}/product_search_terms/${productSearchTerm.id}/edit`} target="_blank" />
                      </TableCell>
                      <TableCell>
                        <DeleteButton onClick={() => { this.handleDeleteClick(productSearchTerm) }} />
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </Box>
          </Box>
        </Paper>

      </Fragment>
    )
  }
}
