import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import { ListItem, ListItemText } from "../../lists"
import Checkbox from "../../Checkbox"


export default class CompanyListItem extends Component  {
  constructor(props) {
    super(props)
    this.handleCheckboxSelect = this.handleCheckboxSelect.bind(this)
  }
  
  handleCheckboxSelect(e) {
    this.props.handleCheckboxSelect(this.props.company)
  }
  
  render() {
    return(
      <Fragment>
        <ListItem button key={this.props.company.id} onClick={this.handleCheckboxSelect} role={undefined}>
          
          <Checkbox 
            checked={this.props.checked} 
          />
          
          <ListItemText primary={this.props.company.short_name} />
          
        </ListItem>
      </Fragment>
    )
  }
}
