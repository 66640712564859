import React from "react"
import { Table, TableHead, TableRow, TableCell, TableBody, EmptyDataTableRow } from "../../tables"
import CharacteristicGroupRow from "./CharacteristicGroupRow"

export default function CharacteristicGroupsTable(props) {
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Name</TableCell>
          <TableCell>Sector</TableCell>
          <TableCell>Created By</TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {props.characteristicGroups.length > 0 ? props.characteristicGroups.map((characteristicGroup) =>
          <CharacteristicGroupRow 
            key={characteristicGroup.id}
            characteristicGroup={characteristicGroup}
            handleDeleteClick={props.handleDeleteClick}
          />
        ) : <EmptyDataTableRow colSpan={5} message='No characteristic groups found. Create one and it will display here.' />}
      </TableBody>
    </Table>
  )
}
