import React from 'react';
import { 
  Dialog, 
  DialogTitle, 
  DialogContent, 
  DialogActions, 
  Button,
  makeStyles
} from '@material-ui/core';
import QueryBuilder from './QueryBuilder';

const useStyles = makeStyles((theme) => ({
  dialog: {
    '& .MuiDialogTitle-root': {
      backgroundColor: 'white',
      color: 'black',
    },
  },
  dialogContent: {
    paddingTop: theme.spacing(2),
  },
  dialogActions: {
    padding: theme.spacing(2),
  },
}));

const QueryBuilderDialog = ({ open, onClose, onSubmit, onReset, currentQuery, initialFields, handleQueryStringChange }) => {
  const classes = useStyles();

  const handleQuerySubmit = (fields, queryString) => {
    onSubmit(fields, queryString);
    onClose();
  };

  const handleQueryReset = () => {
    onReset();
    onClose();
  };

  return (
    <Dialog 
      open={open} 
      onClose={onClose} 
      maxWidth="md" 
      fullWidth
      className={classes.dialog}
    >
      <DialogTitle>Advanced Search</DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <QueryBuilder 
          initialQueryString={currentQuery}
          initialFields={initialFields} 
          handleQueryStringChange={handleQueryStringChange} 
          onSubmit={handleQuerySubmit} 
          onReset={onReset} 
        />
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default QueryBuilderDialog;