import React, { Component, Fragment } from "react"
import PropTypes from "prop-types"
import ReactDOM from "react-dom"
import Select from "../Select"
import Flex from "../Flex"
import Box from "../Box"

export default class Filters extends Component {
  constructor(props) {
    super(props)
  }

  render () {
    return (
      <Flex align='items-center'>

        <Box width="12%" mr={2}>
          <Select
            label='Date'
            name='begin_date'
            value={this.props.filterValues.begin_date}
            entities={this.props.filterOptions.dates}
            handleChange={this.props.handleFilterChange}
          />
        </Box>

        {this.props.activeTab == 'Meetings' ?
          <Fragment>
            <Box width="12%" mr={2}>
              <Select
                label='Meeting'
                name='science_meeting_occurrence_id'
                value={this.props.filterValues.science_meeting_occurrence_id}
                entities={this.props.filterOptions.meetings}
                handleChange={this.props.handleFilterChange}
                includeBlank
              />
            </Box>

            <Box width="20%" mr={2}>
              <Select
                label='Group'
                name='alert_group_id'
                value={this.props.filterValues.alert_group_id}
                entities={this.props.filterOptions.alertGroups}
                handleChange={this.props.handleFilterChange}
                includeBlank
              />
            </Box>
          </Fragment>
        : '' }

        {/* {this.props.activeTab == 'Meetings' ? <div className='dib pr3'>
          <SelectFilter
            label='Year'
            name='science_meeting_occurrence_id'
            value={this.props.filterValues.science_meeting_occurrence_id.toString()}
            entities={this.props.filterOptions.meetingOccurrences}
            handleChange={this.props.handleFilterChange}
          />
        </div> : ''} */}

      </Flex>
    );
  }
}
