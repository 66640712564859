import React, { Fragment } from "react"
import UpdateForm from "./UpdateForm"
import Paper from "../../Paper"
import Box from "../../Box"
import update from "immutability-helper"
import Typography from "@material-ui/core/Typography"
import { withSnackbar } from "../../SnackbarContainer"

class NewContainer extends React.Component {
  constructor(props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
    this.handleSourceChange = this.handleSourceChange.bind(this)
    this.handleSectionChange = this.handleSectionChange.bind(this)
    this.handleChooseSourceClick = this.handleChooseSourceClick.bind(this)
    this.handleSourceRemovalClick = this.handleSourceRemovalClick.bind(this)
    this.handleSaveClick = this.handleSaveClick.bind(this)
    this.state = { update: this.props.update, source: null, openDialog: false }
  }
  
  componentDidMount() {
    const trialUpdate = update(this.state.update, { 
      created_by: { $set: this.props.current_user.id },
      source_id: { $set: this.props.source ? this.props.source.source_id : null }
    })
    
    this.setState({
      update: trialUpdate,
      source: this.props.source
    })
  }
  
  handleSaveClick(e) {
    $.post(`/admin/trials/${this.props.trial.id}/updates`, { trial_update: this.state.update }, () => { window.location.href = `/admin/trials/${this.props.trial.id}/updates` }, 'html').fail((data) => {
      this.props.snackbarShowMessage('The update could not be saved. Make sure you have chosen a source.', "error")
    })
  }
  
  handleChange(e) {
    const text = e.editor.getData()
    
    const trialUpdate = update(this.state.update, { 
      content: { $set: text } 
    })
    
    this.setState({
      update: trialUpdate
    })
  }
  
  handleSectionChange(name, value) {
    let trialUpdate = update(this.state.update, { 
      trial_update_section_id: { $set: value } 
    })
    
    this.setState({
      update: trialUpdate
    })
  }
  
  handleSourceRemovalClick(e) {
    this.setState({
      source: null
    })
  }
  
  handleChooseSourceClick(e) {
    this.setState({
      openDialog: true
    })
  }
  
  handleSourceChange(entity, sourceType) {
    if (sourceType == 'recently_used') {
      let trialUpdate = update(this.state.update, { 
        source_id: { $set: entity.id }
      })
      this.setState({
        source: entity,
        update: trialUpdate,
        openDialog: false
      })
    } else {
      $.get("/admin/sources/find_source", { source_type: sourceType, entity_id: entity.id }, (data) => {
        if (data.source) {
          let trialUpdate = update(this.state.update, { 
            source_id: { $set: data.source.source_id }
          })
          this.setState({
            source: data.source,
            update: trialUpdate,
            openDialog: false
          })
        } else {
          $.post("/admin/sources/filter", { source: { kind: sourceType, entity_id: entity.id } }, (data) => {
            let trialUpdate = update(this.state.update, { 
              source_id: { $set: data.source.source_id }
            })
            this.setState({
              source: data.source,
              update: trialUpdate,
              openDialog: false
            })
          })
        }
      }, 'json')
    }
  }
  
  render() {
    return (
      <Fragment>
        
        <Paper>
          
          <Box mb={3}>
            <Typography variant="h6" component="h6">
              New Update
            </Typography>
          </Box>
          
          <UpdateForm 
            trial={this.props.trial}
            update={this.state.update}
            source={this.state.source}
            openDialog={this.state.openDialog}
            sections={this.props.sections}
            sourceTypes={this.props.source_types}
            sources={this.props.sources}
            handleChange={this.handleChange}
            handleChooseSourceClick={this.handleChooseSourceClick}
            handleSourceChange={this.handleSourceChange}
            handleSectionChange={this.handleSectionChange}
            handleSaveClick={this.handleSaveClick}
            handleSourceRemovalClick={this.handleSourceRemovalClick}
          />
          
        </Paper>
      </Fragment>
    )
  }
}

export default withSnackbar(NewContainer)