import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import { TableRow, TableCell } from "../../tables"
import Link from "../../Link"
import EditButton from "../EditButton"
import DeleteButton from "../DeleteButton"

export default function CharacteristicGroupRow(props) {
  const editUrl = `/admin/characteristic_groups/${props.characteristicGroup.id}/edit`
  return(
    <Fragment>
      <TableRow>
        <TableCell>
          <Link href={editUrl}>
            {props.characteristicGroup.name}
          </Link>
        </TableCell>
        <TableCell>
          {props.characteristicGroup.sectors.length > 0 ? props.characteristicGroup.sectors[0].abbr : 'N/A'}
        </TableCell>
        <TableCell>
          {props.characteristicGroup.creator ? displayInitials(props.characteristicGroup.creator.first_name, props.characteristicGroup.creator.last_name) : 'N/A'}
        </TableCell>
        <TableCell>
          <EditButton component="a" href={editUrl} />
        </TableCell>
        <TableCell>
          <DeleteButton onClick={() => props.handleDeleteClick(props.characteristicGroup)} />
        </TableCell>
      </TableRow>
    </Fragment>
  )
}
