import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Paper from "../Paper"
import ManuscriptsTable from "../publications/ManuscriptsTable"
import ChartContainer from "./ChartContainer"
import ChartDataTable from "./ChartDataTable"
import Link from "../Link"
import Select from "../Select"
import Flex from "../Flex"
import Box from "../Box"
import Typography from "@material-ui/core/Typography"
import BarChartIcon from '@material-ui/icons/BarChart';
import ListIcon from '@material-ui/icons/List';
import IconButton from "../IconButton"
import Tabs from "../tabs/Tabs"
import Tab from "../tabs/Tab"
import FilterChip from '../filters/FilterChip';
import update from "immutability-helper"
import { withSnackbar } from '../SnackbarContainer'


class EntityManuscriptsContainer extends Component {
  constructor(props) {
    super(props)
    this.handleListViewClick = this.handleListViewClick.bind(this)
    this.handleChartViewClick = this.handleChartViewClick.bind(this)
    this.handleActiveIndexUpdate = this.handleActiveIndexUpdate.bind(this)
    this.handleFilterChange = this.handleFilterChange.bind(this)
    this.getManuscripts = this.getManuscripts.bind(this)
    this.handleDelete = this.handleDelete.bind(this)
    this.handlePageChange = this.handlePageChange.bind(this)
    this.handleRequestSort = this.handleRequestSort.bind(this)
    this.state = { manuscripts: this.props.manuscripts, manuscriptsCount: this.props.manuscripts_count, entityType: this.props.entity_type, entity: this.props.entity, view: 'list', activeIndex: 0, filterValues: { journal_ids: [] }, page: this.props.page, order: 'desc', orderBy: 'published_date', showSnackbar: false, snackbarText: '' }
  }

  componentDidMount() {
    this.setState({
      rootUrl: `/sectors/${this.props.sector.abbr}/${this.state.entityType}/${this.props.entity.id}`
    })
  }

  handleRequestSort(event, property) {
    let params = Object.assign({}, this.state.filterValues)

    params["order_by"] = property

    let isDesc = this.state.orderBy === property && this.state.order === 'desc';

    params["order"] = isDesc ? 'asc' : 'desc'

    $.ajax({
      type: 'GET',
      url: `${this.state.rootUrl}/order_manuscripts`,
      data: params,
      dataType: 'json',
      beforeSend: (data) => {
        this.setState({
          loading: true
        })
      },
      success: (data) => {
        this.setState({
          order: params["order"],
          orderBy: params["order_by"],
          manuscripts: data.manuscripts,
          loading: false
        })
      },
      error: (data) => {
        console.log(data.responseText)
        this.props.snackbarShowMessage('There was an error. Please try again.', 'error')
        this.setState({
          loading: false
        })
      }
    })
  }

  handleListViewClick(e) {
    this.setState({
      view: 'list'
    })
  }

  handleChartViewClick(e) {
    $.ajax({
      type: "GET",
      url: `${this.state.rootUrl}/publication_chart_data`,
      dataType: "json",
      success: (data) => {
        this.setState({
          view: 'chart',
          chartData: data.chart_data,
          categories: data.categories,
          groupedManuscriptsData: data.grouped_manuscripts_data
        })
      },
      error: (data) => {
        this.props.snackbarShowMessage('There was an error fetching the data for the chart. Please try again.', 'error')
      }
    })
  }

  handleActiveIndexUpdate(e, newValue) {
    this.setState({
      activeIndex: newValue
    })
  }

  handleFilterChange(name, value) {
    let params = Object.assign({}, this.state.filterValues)

    if (value.includes(null)) {
      params[name] = []
    } else {
      params[name] = value
    }

    this.getManuscripts(params)
  }

  handleDelete(value, type) {
    const index = this.state.filterValues[type].indexOf(value)

    const filterValues = update(this.state.filterValues, {
      [type]: { $splice: [[index, 1]] }
    })

    this.getManuscripts(filterValues)
  }

  handlePageChange(page) {
    let params = Object.assign({}, this.state.filterValues)
    
    params['page'] = page

    this.getManuscripts(params)
  }

  getManuscripts(params) {
    $.ajax({
      type: 'GET',
      url: `${this.state.rootUrl}/manuscripts`,
      data: params,
      dataType: 'json',
      success: (data) => {
        this.setState({
          manuscripts: data.manuscripts,
          filterValues: params
        })
      },
      error: (data) => {
        this.props.snackbarShowMessage('There was an error fetching the data. Please try again.', 'error')
      }
    })
  }

  render() {
    const singularizedEntityType = this.state.entityType.slice(0, -1)

    const selectedJournals = this.props.journals.filter(journal => this.state.filterValues.journal_ids.includes(journal.value) ? journal : null)
    return(
      <Fragment>

        <Paper fullHeight={this.state.view == 'list' ? true : false}>

          <Box height="100%">

            <Flex mb={2}>
              {this.state.view == 'list' ? <Box width={1/5}>
                <Select
                  multiple
                  label='Journal'
                  name='journal_ids'
                  value={this.state.filterValues.journal_ids}
                  entities={this.props.journals}
                  handleChange={this.handleFilterChange}
                  includeBlank={true}
                  renderValue={selected => (
                    <Flex flexWrap='wrap'>
                      {`${selectedJournals.length} chosen`}
                    </Flex>
                  )}
                />
              </Box> : ''}

              {this.state.manuscripts.length > 0 ? <Box ml='auto'>
                <IconButton onClick={this.handleListViewClick} color={this.state.view == 'list' ? 'primary' : 'default'}>
                  <ListIcon />
                </IconButton>

                <IconButton onClick={this.handleChartViewClick} color={this.state.view == 'chart' ? 'primary' : 'default'} tooltip='Chart'>
                  <BarChartIcon />
                </IconButton>
              </Box> : ''}
            </Flex>

            {this.state.view == 'list' ? <Flex minHeight='28px' alignItems='center'>
              {selectedJournals.map((activeFilter) =>
                <FilterChip
                  key={activeFilter.value}
                  name={activeFilter.name}
                  value={activeFilter.value}
                  type='journal_ids'
                  handleDelete={this.handleDelete}
                />
              )}
            </Flex> : ''}

            {this.state.manuscripts.length > 0 ? ''
            :
              <Flex justifyContent='center' height="50%">
                <Box alignSelf='center' maxWidth="50%">
                  <Box>
                    <Typography variant="body1">
                      {`No publications found for this ${singularizedEntityType}.`}
                    </Typography>
                  </Box>
                </Box>
              </Flex>
            }

            {this.state.view == 'list' && this.state.manuscripts.length > 0 ?
              <Fragment>
                <Flex justifyContent='right' pb={2}>
                  <Typography variant="body2">
                    {`${this.state.manuscriptsCount} publication${this.state.manuscriptsCount !== 1 ? 's' : ''}`}
                  </Typography>
                </Flex>
                <ManuscriptsTable
                  manuscripts={this.state.manuscripts}
                  manuscriptsCount={this.state.manuscriptsCount}
                  sector={this.props.sector}
                  page={this.state.page}
                  order={this.state.order}
                  orderBy={this.state.orderBy}
                  handleChangePage={this.handlePageChange}
                  handleRequestSort={this.handleRequestSort}
                />
              </Fragment>
            : ''}

            {this.state.view == 'chart' ?
              <Box>
                <Box mb={2}>
                  <ChartContainer
                    chartTitle={`${this.props.entity_name || this.props.entity.short_name} Publications`}
                    chartData={this.state.chartData}
                    categories={this.state.categories}
                    groupedManuscriptsData={this.state.groupedManuscriptsData}
                  />
                </Box>

                <Box textAlign='center'>
                  <Tabs centered value={this.state.activeIndex} onChange={this.handleActiveIndexUpdate} className='bb b--black-10' aria-label="product publications chart tabs">
                    <Tab label='Data' />
                  </Tabs>
                </Box>

                <Box>
                  <ChartDataTable
                    groupedManuscriptsData={this.state.groupedManuscriptsData}
                    sector={this.props.sector}
                  />
                </Box>

              </Box>
            : ''}

          </Box>

        </Paper>

      </Fragment>
    )
  }
}

export default withSnackbar(EntityManuscriptsContainer)