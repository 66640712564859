import React, { Component, Fragment } from "react"
import Paper from "../Paper"
import Flex from "../Flex"
import Box from "../Box"
import Pagination from '@material-ui/lab/Pagination';
import Typography from "@material-ui/core/Typography"
import { Button } from "../buttons"
import { withSnackbar } from "../SnackbarContainer"
import update from "immutability-helper"
import { Table, TableRow, TableCell, TableBody } from "../tables"
import { List, ListItem, ListItemText } from "../lists"
import AccountListItem from "./AccountListItem"


class Accounts extends Component {
  constructor(props) {
    super(props)
    this.handleGroupSwitchChange = this.handleGroupSwitchChange.bind(this)
    this.state = { twitterAccounts: this.props.accounts, userTwitterAccounts: this.props.user_twitter_accounts }
  }

  handleGroupSwitchChange(userTwitterAccount, twitterAccount) {
    if (userTwitterAccount) {
      $.ajax({
        type: "DELETE",
        url: `/sectors/${this.props.sector.abbr}/user_twitter_accounts/${userTwitterAccount.id}`,
        dataType: 'json',
        success: (data) => {
          let index = this.state.userTwitterAccounts.indexOf(userTwitterAccount)

          let userTwitterAccounts = update(this.state.userTwitterAccounts, {
            $splice: [[index, 1]]
          })

          this.setState({
            userTwitterAccounts: userTwitterAccounts
          })

          this.props.snackbarShowMessage("The account has been unfollowed. You will no longer see tweets from this account.")
        },
        error: (data) => {
          this.props.snackbarShowMessage("There was an issue removing the account. Please try again.", "error")
        }
      })
    } else {
      $.ajax({
        type: "POST",
        url: `/sectors/${this.props.sector.abbr}/user_twitter_accounts`,
        data: {
          user_twitter_account: {
            user_id: this.props.user.id, twitter_account_id: twitterAccount.id
          }
        },
        dataType: 'json',
        success: (data) => {
          let userTwitterAccounts = update(this.state.userTwitterAccounts, {
            $push: [data.user_twitter_account]
          })

          this.setState({
            userTwitterAccounts: userTwitterAccounts
          })

          this.props.snackbarShowMessage("The account has been followed.")
        },
        error: (data) => {
          this.props.snackbarShowMessage("There was an issue following the account. Please try again.", "error")
        }
      })
    }
  }

  render() {
    return(
      <Paper fullHeight>
        <Box height="100%">

          <Box overflow="auto" height="90%">
            <List>
              {this.state.twitterAccounts.map((account) =>
                <AccountListItem
                  key={account.id}
                  account={account}
                  userTwitterAccounts={this.state.userTwitterAccounts}
                  handleGroupSwitchChange={this.handleGroupSwitchChange}
                />
              )}
            </List>
          </Box>
        </Box>
      </Paper>
    )
  }
}

export default withSnackbar(Accounts)
