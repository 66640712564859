import React, { Component, Fragment } from "react"
import PropTypes from "prop-types"
import Paper from "../Paper"
import TrialsTable from "./TrialsTable"
import Filters from "./Filters"
import Typography from "@material-ui/core/Typography"
import Flex from "../Flex"
import Box from "../Box"
import IconButton from "../IconButton"
import { Button, RaisedButton, Fab } from "../buttons"
import update from 'immutability-helper'
import BookmarkIcon from "@material-ui/icons/Bookmark"
import BookmarkBorderIcon from '@material-ui/icons/BookmarkBorder';
import FilterChip from '../filters/FilterChip';
import ExpandLess from '../ExpandLess';
import ExpandMore from '../ExpandMore';
import Collapse from '@material-ui/core/Collapse';
import FilterChipsContainer from "./FilterChipsContainer"
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import { withRouter } from "react-router-dom";
import Snackbar from "../Snackbar"
import LinearProgress from '@material-ui/core/LinearProgress';
import CompareIconDisplay from "./CompareIconDisplay"
import { withSnackbar } from "../SnackbarContainer"

class Listing extends Component {
  constructor(props) {
    super(props)
    this.handleFilterChange = this.handleFilterChange.bind(this)
    this.loadTrials = this.loadTrials.bind(this)
    this.handleBookmarkClick = this.handleBookmarkClick.bind(this)
    this.handleDelete = this.handleDelete.bind(this)
    this.getTrials = this.getTrials.bind(this)
    this.handleChangePage = this.handleChangePage.bind(this)
    this.handleFilterExpandClick = this.handleFilterExpandClick.bind(this)
    this.handleTrialCheckboxClick = this.handleTrialCheckboxClick.bind(this)
    this.handleBulkTrialCheck = this.handleBulkTrialCheck.bind(this)
    this.handleExpandClick = this.handleExpandClick.bind(this)
    this.handleDownloadClick = this.handleDownloadClick.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleSearchClick = this.handleSearchClick.bind(this)
    this.handleSearchCloseClick = this.handleSearchCloseClick.bind(this)
    this.handleSearchIconClick = this.handleSearchIconClick.bind(this)
    this.handleFavoriteClick = this.handleFavoriteClick.bind(this)
    this.handleClearFilterClick = this.handleClearFilterClick.bind(this)
    this.handleRequestSort = this.handleRequestSort.bind(this)
    this.state = { loading: false, error: false, hasMore: true, trials: this.props.trials, trialsCount: this.props.trials_count, trialsToCompare: [], filterOptions: { companies: this.props.companies, products: this.props.products, targetMarkets: this.props.target_markets, therapyTypes: this.props.therapy_types, clinicalTypes: this.props.clinical_types, statuses: this.props.statuses, enrollmentOptions: this.props.enrollment_options, targetSites: this.props.target_sites }, filterValues: { company_ids: [], sponsor_ids: [], product_ids: [], target_market_ids: [], therapy_type_ids: [], clinical_type_ids: [], status_ids: [], enrollment_numbers: [], target_site_ids: [], query: null }, chosen: { products: this.props.chosen_products, statuses: this.props.chosen_statuses, therapyTypes: this.props.chosen_therapy_types, targetMarkets: this.props.chosen_target_markets, clinicalTypes: this.props.chosen_clinical_types, companies: this.props.chosen_companies, targetSites: this.props.chosen_target_sites }, page: 0, rowsPerPage: 100, showFilters: false, trialsToCompare: [], expandedTrial: null, checkedTrials: [], search: false, query: '', order: 'desc', orderBy: "updated_at" }
  };

  componentDidMount() {
    let filterKeys = Object.keys(this.props.filter_values)
    let filterValueHash = { query: this.props.query }

    let parsedFilterValues = filterKeys.map((filterKey) =>
      this.props.filter_values[filterKey] == null ? filterValueHash[filterKey] = [] : filterValueHash[filterKey] = this.props.filter_values[filterKey].map((value) => parseInt(value))
    )

    let filterValues = update(this.state.filterValues, {
      $set: filterValueHash
    })

    this.setState({
      filterValues: filterValues,
      search: this.props.query ? true : false
    })
  }

  handleExpandClick(trialId) {
    this.setState({
      expandedTrial: trialId == this.state.expandedTrial ? null : trialId
    })
  }

  handleFilterExpandClick(e) {
    const newShowFilterState = !this.state.showFilters

    this.setState({
      showFilters: newShowFilterState
    })
  }

  handleDownloadClick(e) {
    console.log("helo")
  }

  handleClearFilterClick(e) {
    let filterValues = update(this.state.filterValues, {
      target_market_ids: { $set: [] },
      target_site_ids: { $set: [] },
      company_ids: { $set: [] },
      collection_ids: { $set: [] },
      therapy_type_ids: { $set: [] },
      company_ids: { $set: [] },
      sponsor_ids: { $set: [] },
      product_ids: { $set: [] },
      clinical_type_ids: { $set: [] },
      status_ids: { $set: [] },
      enrollment_numbers: { $set: [] },
      query: { $set: null }
    })

    this.setState({
      filterValues: filterValues
    }, () => {
      this.handleFilterChange('', '')
    })
  }

  handleBulkTrialCheck(e) {
    if (this.state.checkedTrials.length > 0) {
      this.setState({
        checkedTrials: []
      })
    } else {
      const trialIds = this.state.trials.map((trial) => trial.trial_id)

      this.setState({
        checkedTrials: trialIds
      })
    }
  }

  handleTrialCheckboxClick(trialId) {
    const index = this.state.checkedTrials.indexOf(trialId)


    const trials = index == -1 ?
      update(this.state.checkedTrials, {
        $push: [trialId]
      })
    :
      update(this.state.checkedTrials, {
        $splice: [[index,1]]
      })

    this.setState({
      checkedTrials: trials
    })
  }

  handleChangeRowsPerPage(e) {
    console.log(e)
  }

  handleChangePage(e, newPage) {
    let params = Object.assign({}, this.state.filterValues)

    params["order_by"] = this.state.orderBy
    params["order"] = this.state.order
    params["page"] = newPage

    this.loadTrials(params)
  }

  loadTrials(params) {
    $.ajax({
      type: 'GET',
      url: `/sectors/${this.props.sector.abbr}/trials/order_trials`,
      data: params,
      dataType: 'json',
      beforeSend: (smthing) => {
        this.setState({
          loading: true
        })
      },
      success: (data) => {
        this.setState({
          trials: data.trials,
          page: params["page"],
          loading: false
        })
      },
      error: (xhjr) => {
        this.setState({
          error: true
        })
      },
      complete: (smthing) => {
        this.setState({
          loading: false
        })
      }
    })
  }

  handleDelete(value, type) {
    if (type == 'query') {
      let filterValues = update(this.state.filterValues, {
        query: {
          $set: null
        }
      })

      this.getTrials(filterValues)
    } else {
      const index = this.state.filterValues[type].indexOf(value)

      const filterValues = update(this.state.filterValues, {
        [type]: { $splice: [[index, 1]] }
      })

      this.getTrials(filterValues)
    }
  }

  handleBookmarkClick(e) {

  }

  handleFavoriteClick(trial, userTrialId) {
    if (userTrialId) {
      $.ajax({
        type: 'PUT',
        url: `/sectors/${this.props.sector.abbr}/user_trials/${userTrialId}`,
        data: {
          user_trial: {
            deleted: true
          }
        },
        dataType: 'json',
        success: (data) => {
          let index = this.state.trials.indexOf(trial)
          let trials = update(this.state.trials, {
            [index]: {
              user_trial_id: { $set: null }
            }
          })

          this.setState({
            trials: trials
          })
        },
        error: (data) => {
          this.setState({
            showSnackbar: true,
            snackbarText: 'There was an error removing the trial from your favorites. Please try again.'
          })
        },
        complete: (data) => {
          setTimeout(() => {
            this.setState({
              showSnackbar: false,
              snackbarText: ''
            })
          }, 4000)
        }
      })
    } else {
      $.ajax({
        type: 'POST',
        url: `/sectors/${this.props.sector.abbr}/user_trials`,
        data: {
          user_trial: {
            user_id: this.props.current_user.id, trial_id: trial.trial_id
          }
        },
        dataType: 'json',
        success: (data) => {
          let index = this.state.trials.indexOf(trial)
          let trials = update(this.state.trials, {
            [index]: {
              user_trial_id: { $set: data.id }
            }
          })

          this.setState({
            trials: trials
          })
        },
        error: (data) => {
          this.setState({
            showSnackbar: true,
            snackbarText: 'There was an error adding the entity to your favorites. Please try again.'
          })
        },
        complete: (data) => {
          setTimeout(() => {
            this.setState({
              showSnackbar: false,
              snackbarText: ''
            })
          }, 4000)
        }
      })
    }
  }

  handleChange(e) {

    const value = e.target.value

    let filterValues = update(this.state.filterValues, {
      query: { $set: value }
    })

    this.setState({
      filterValues: filterValues,
      search: false
    })
  }

  handleSearchIconClick(e) {
    this.setState({
      search: true
    })
  }

  handleSearchCloseClick(e) {
    this.setState({
      search: false
    })
  }

  handleSearchClick(e) {
    e.preventDefault()
    $.ajax({
      type: 'GET',
      url: `/sectors/${this.props.sector.abbr}/trials/search`,
      data: {
        query: this.state.filterValues.query
      },
      dataType: 'json',
      beforeSend: () => {
        this.setState({
          loading: true
        })
      },
      success: (data) => {
        this.setState({
          trials: data.trials,
          trialsCount: data.trials_count,
          search: true,
          loading: false
        })
      },
      error: (error) => {
        console.error(error.message)
        this.setState({
          showSnackbar: true,
          snackbarText: "There was an error in the search. Please try again."
        })
      },
      complete: () => {
        setTimeout(() => {
          this.setState({
            showSnackbar: false,
            snackbarText: ''
          })
        }, 4000)
      }
    })
  }

  handleFilterChange(name, value) {
    let params = Object.assign({}, this.state.filterValues)

    if (value.includes(null)) {
      params[name] = []
    } else {
      params[name] = value
    }

    this.getTrials(params)
  }

  getTrials(params) {

    let url = this.props.my_trials ? `/sectors/${this.props.sector.abbr}/trials/my_trials` : `/sectors/${this.props.sector.abbr}/trials`

    let allParams = Object.assign({}, params)

    allParams["order_by"] = this.state.orderBy
    allParams["order"] = this.state.order

    $.ajax({
      type: 'GET',
      url: url,
      data: allParams,
      dataType: 'json',
      beforeSend: (data) => {
        this.setState({
          loading: true
        })
      },
      success: (data) => {
        let filterOptions = { companies: data.companies, products: data.products, targetMarkets: data.target_markets, therapyTypes: data.therapy_types, clinicalTypes: data.clinical_types, statuses: data.statuses, enrollmentOptions: data.enrollment_options, targetSites: data.target_sites }

        let chosen = { companies: data.chosen_companies, products: data.chosen_products, targetMarkets: data.chosen_target_markets, therapyTypes: data.chosen_therapy_types, clinicalTypes: data.chosen_clinical_types, statuses: data.chosen_statuses, targetSites: data.chosen_target_sites }

        let filterKeys = Object.keys(params)

        let queryParams = filterKeys.map((filterKey) => params[filterKey] && params[filterKey].length > 0 ? params[filterKey].map((filterValue) => `${filterKey}[]=${filterValue}`) : null).filter(param => param)

        let flattenedQueryParams = [].concat.apply([], queryParams);
        this.props.history.push(`${url}?${flattenedQueryParams.join("&")}`);

        this.setState({
          trials: data.trials,
          trialsCount: data.trials_count,
          filterValues: params,
          filterOptions,
          chosen,
          search: false,
          loading: false
        })
      },
      error: (data) => {
        console.log(data.responseText)
        this.setState({
          showSnackbar: true,
          snackbarText: "There was an error fetching the trials. We've been notified and are looking into it",
          loading: false
        })
      },
      complete: (data) => {
        setTimeout(() => {
          this.setState({
            showSnackbar: false,
            snackbarText: ''
          })
        }, 5000)
      }
    })
  }

  handleRequestSort(event, property) {
    let params = Object.assign({}, this.state.filterValues)

    params["order_by"] = property

    let isDesc = this.state.orderBy === property && this.state.order === 'desc';

    params["order"] = isDesc ? 'asc' : 'desc'

    $.ajax({
      type: 'GET',
      url: `/sectors/${this.props.sector.abbr}/trials/order_trials`,
      data: params,
      dataType: 'json',
      beforeSend: (data) => {
        this.setState({
          loading: true
        })
      },
      success: (data) => {
        this.setState({
          order: params["order"],
          orderBy: params["order_by"],
          trials: data.trials,
          loading: false
        })
      },
      error: (data) => {
        console.log(data.responseText)
        this.setState({
          showSnackbar: true,
          snackbarText: 'There was an error. Please try again.',
          loading: false
        })
      },
      complete: (data) => {
        setTimeout(() => {
          this.setState({
            showSnackbar: false,
            snackbarText: '',
            loading: false
          })
        }, 5000)
      }
    })
  }

  render () {
    // <Box ml={1}>
    //   <IconButton tooltip='Download' onClick={this.handleDownloadClick} size='small'>
    //     <CloudDownloadIcon style={{height: '22px', width: '22px'}} />
    //   </IconButton>
    // </Box>
    return (
      <Fragment>

        <Paper fullHeight>
          <Box height="100%">

            <Box mb={3}>
              <Filters
                filterOptions={this.state.filterOptions}
                filterValues={this.state.filterValues}
                search={this.state.search}
                sector={this.props.sector}
                currentUser={this.props.current_user}
                handleFilterChange={this.handleFilterChange}
                handleChange={this.handleChange}
                handleSearchClick={this.handleSearchClick}
                handleSearchIconClick={this.handleSearchIconClick}
                handleSearchCloseClick={this.handleSearchCloseClick}
              />
            </Box>

            <Flex flexWrap='wrap' my={2} minHeight='28px' alignItems='center'>

              <FilterChipsContainer
                filterOptions={this.state.filterOptions}
                filterValues={this.state.filterValues}
                chosen={this.state.chosen}
                search={this.state.search}
                handleDelete={this.handleDelete}
              />

              <Box ml='auto'>

                <Flex alignItems='center'>

                  {Object.keys(this.state.filterValues).filter(filterValueKey => this.state.filterValues[filterValueKey] ? this.state.filterValues[filterValueKey].length > 0 : null).length > 0 ?
                    <Box>
                      <Button onClick={this.handleClearFilterClick}>
                        Clear all
                      </Button>
                    </Box>
                  : ''}

                  <Box mr={2}>
                    <Flex justifyContent='right'>
                      <Box>
                        <CompareIconDisplay
                          checkedTrials={this.state.checkedTrials}
                          sector={this.props.sector}
                          snackbarShowMessage={this.props.snackbarShowMessage}
                        />
                      </Box>


                    </Flex>
                  </Box>

                </Flex>
              </Box>

            </Flex>

            <Flex justifyContent='right'>
              <Typography variant="body2">
                {`${this.state.trialsCount} trials`}
              </Typography>
            </Flex>

            <TrialsTable
              key={`trials-table-${this.state.order}-${this.state.orderBy}-${this.state.filterValues}`}
              restOfKey={`${this.state.order}-${this.state.orderBy}-${this.state.filterValues}`}
              trials={this.state.trials}
              trialsCount={this.state.trialsCount}
              page={this.state.page}
              sector={this.props.sector}
              loading={this.state.loading}
              order={this.state.order}
              orderBy={this.state.orderBy}
              forCompare={this.props.for_compare}
              checkedTrials={this.state.checkedTrials}
              expandedTrial={this.state.expandedTrial}
              handleTrialCheckboxClick={this.handleTrialCheckboxClick}
              handleBulkTrialCheck={this.handleBulkTrialCheck}
              handleExpandClick={this.handleExpandClick}
              handleChangePage={this.handleChangePage}
              handleFavoriteClick={this.handleFavoriteClick}
              handleChangeRowsPerPage={this.handleChangeRowsPerPage}
              handleRequestSort={this.handleRequestSort}
            />

          </Box>
        </Paper>

        <Snackbar
          open={this.state.showSnackbar}
          message={this.state.snackbarText}
        />
      </Fragment>
    );
  }
}

Listing.propTypes = {
  trials: PropTypes.array
};

export default withRouter(withSnackbar(Listing))
