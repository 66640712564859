import React from "react"
import DeleteButton from "../DeleteButton"
import { ListItem, ListItemText } from "../../lists"
import ListItemLink from "../../lists/ListItemLink"
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction"

export default function AssociatedProductListItem(props) {

  function handleUnassociateClick(e) {
    props.handleProductDeleteClick(props.entityProduct)
  }

  return (
    <ListItemLink href={`/admin/products/${props.entityProduct.product_id || props.entityProduct.id}/edit`} target="_blank">

    <ListItemText
      primary={props.product ? props.product.short_name : props.entityProduct.short_name}
      secondary={props.entityProduct.search_term ? props.entityProduct.search_term : ""}
    />

    <ListItemSecondaryAction>
      <DeleteButton onClick={handleUnassociateClick} />
    </ListItemSecondaryAction>

  </ListItemLink>
  )
}
