import React, { Component } from "react"
import PropTypes from "prop-types"
import ReactDOM from "react-dom"
import Link from "../Link"
import { TableRow, TableCell } from "../tables"
import Button from "../buttons/Button"
import ExpandLess from '../ExpandLess';
import ExpandMore from '../ExpandMore';


export default class GroupedDataTable extends Component {
  constructor(props) {
    super(props)
    this.state = { open: true }
  }
  
  render () {
    return (
      <React.Fragment>
        
        <TableRow hover={false}>
          <TableCell colSpan={4} className='pv2'>
            <Button onClick={() => this.setState({ open: !this.state.open })} color='default' lowercase={true} className='f5'>
              {this.state.open ? <ExpandLess /> : <ExpandMore />}
              {this.props.header}
            </Button>
          </TableCell>
        </TableRow>
        
        {/* <Collapse in={this.state.open} timeout="auto" unmountOnExit component="tr"> */}
          {this.state.open ? this.props.groupedAbstractsData.map((entity) =>
            <TableRow key={entity.id}>
              <TableCell>{entity.meeting_name_display || entity.meeting_name}</TableCell>
              <TableCell>
                <Link href={`/sectors/${this.props.sector.abbr}/abstracts/${entity.id}`} target="_blank">
                  {entity.title}
                </Link>
              </TableCell>
            </TableRow>
          ) : ''}
        {/* </Collapse> */}
      </React.Fragment>
    );
  }
}
