import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import TrialEnrollmentRow from "./TrialEnrollmentRow"
import Flex from "../../Flex"
import Box from "../../Box"
import TextInput from "../../TextInput"
import RaisedButton from "../../buttons/RaisedButton"
import Typography from '@material-ui/core/Typography';
import Snackbar from "../../Snackbar"
import { Table, TableRow, TableHead, TableCell, TableBody } from "../../tables"
import update from "immutability-helper"
import Select from "../../Select"

class EnrollmentForm extends Component {
  constructor(props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
    this.handleTypeChange = this.handleTypeChange.bind(this)
    this.handleSaveClick = this.handleSaveClick.bind(this)
    this.handleFavoriteClick = this.handleFavoriteClick.bind(this)
    this.handleDeleteClick = this.handleDeleteClick.bind(this)
    this.handleDateChange = this.handleDateChange.bind(this)
    this.state = { trialEnrollments: [], newEnrollment: '', enrollmentType: 'current_enrollment', date: '', showSnackbar: false, snackbarText: '' }
  }

  componentDidMount() {
    $.get(`/admin/trials/${this.props.trial.id}/enrollments`, {}, (data) => {
      this.setState({
        trialEnrollments: data.trial_enrollments,
        date: this.props.source ? this.props.source.source_date : ""
      })
    }, 'json')
  }

  handleTypeChange(name, value) {
    this.setState({
      enrollmentType: value
    })
  }

  handleChange(e) {
    const value = e.target.value
    this.setState({
      newEnrollment: value
    })
  }

  handleDateChange(e) {
    this.setState({
      date: e.target.value
    })
  }

  handleSaveClick(e) {
    if (this.props.source) {
      let url = this.state.enrollmentType == 'current_enrollment' ? "/admin/trial_current_enrollments" : "/admin/trial_target_enrollments"

      let attrs = { trial_id: this.props.trial.id, enrollment: this.state.newEnrollment, date: this.state.date, source_id: this.props.source.source_id, active: true, created_by: this.props.currentUser.id }

      let params = this.state.enrollmentType == 'current_enrollment' ? { trial_current_enrollment: attrs } : { trial_target_enrollment: attrs }

      $.post(url, params, (data) => {
        this.setState({
          trialEnrollments: data.trial_enrollments,
          newEnrollment: '',
          showSnackbar: true,
          snackbarText: 'Enrollment has been added'
        })
      }, 'json').fail(() => {
        this.setState({
          showSnackbar: true,
          snackbarText: `Enrollment could not be added. Please try again.`
        })
      }).always(() => {
        setTimeout(() => {
          this.setState({
            showSnackbar: false,
            snackbarText: ''
          })
        }, 4000)
      })
    } else {
      this.setState({
        showSnackbar: true,
        snackbarText: 'You must choose a source before adding enrollment'
      })
      setTimeout(() => {
        this.setState({
          showSnackbar: false,
          snackbarText: ''
        })
      }, 4000)
    }
  }

  handleFavoriteClick(trialEnrollment, enrollmentKind) {
    let url = enrollmentKind == 'Current enrollment' ? `/admin/trial_current_enrollments/${trialEnrollment.id}` : `/admin/trial_target_enrollments/${trialEnrollment.id}`

    let params = enrollmentKind == 'Current enrollment' ? { trial_current_enrollment: { active: !trialEnrollment.active, updated_by: this.props.currentUser.id }} : { trial_target_enrollment: { active: !trialEnrollment.active, updated_by: this.props.currentUser.id }}

    $.ajax({
      type: 'PUT',
      url: url,
      data: params,
      dataType: 'json',
      success: (data) => {
        this.setState({
          trialEnrollments: data.trial_enrollments,
          showSnackbar: true,
          snackbarText: `${!trialEnrollment.active ? 'The enrollment is no longer active' : 'The enrollment record is now active'}`
        })
      },
      error: (data) => {
        this.setState({
          showSnackbar: true,
          snackbarText: `Something went wrong. Please try again.`
        })
      },
      complete: () => {
        this.setState({
          showSnackbar: false,
          snackbarText: ''
        })
      }
    })
  }

  handleDeleteClick(trialEnrollment, enrollmentKind) {
    let url = enrollmentKind == 'Current enrollment' ? `/admin/trial_current_enrollments/${trialEnrollment.id}` : `/admin/trial_target_enrollments/${trialEnrollment.id}`

    let params = enrollmentKind == 'Current enrollment' ? { trial_current_enrollment: { deleted: true, updated_by: this.props.currentUser.id }} : { trial_target_enrollment: { deleted: true, deleted_by: this.props.currentUser.id }}

    $.ajax({
      type: 'PUT',
      url: url,
      data: params,
      dataType: 'json',
      success: (data) => {
        this.setState({
          trialEnrollments: data.trial_enrollments,
          showSnackbar: true,
          snackbarText: 'The enrollment record was removed'
        })
      },
      error: (data) => {
        this.setState({
          showSnackbar: true,
          snackbarText: `The enrollment record could not be removed. Please try again.`
        })
      },
      complete: () => {
        this.setState({
          showSnackbar: false,
          snackbarText: ''
        })
      }
    })
  }

  render() {
    return(
      <Fragment>
        <Typography variant="h6" component="h6" className='mb1 mt1 mr1'>
          New Current Enrollment
        </Typography>

        <Flex alignItems='center' justifyContent="center" className='w-100'>

          <Box width="20" mr={3}>
            <Select
              entities={[{ name: 'Current enrollment', value: 'current_enrollment' }, { name: 'Target enrollment', value: 'target_enrollment' } ]}
              value={this.state.enrollmentType}
              handleChange={this.handleTypeChange}
            />
          </Box>

          <Box width={1/4} mr={4}>
            <TextInput
              name='enrollment'
              label='Enrollment'
              value={this.state.newEnrollment}
              onChange={this.handleChange}
              fullWidth
            />
          </Box>

          <Box width={1/4}>
            <Box>
              <label>Date</label>
            </Box>
            <input
              type="date"
              onChange={this.handleDateChange}
              value={this.state.date}
            />
          </Box>

          <Box ml="auto" width={1/4}>
            <RaisedButton handleClick={this.handleSaveClick}>
              Save Enrollment
            </RaisedButton>
          </Box>

        </Flex>

        <Box mt={4}>
          <Typography variant="h6" component="h6" className='mb1 mt1 mr1'>
            Trial Enrollments
          </Typography>

          <Table>

            <TableHead>
              <TableRow>
                {/* <TableCell>Active</TableCell> */}
                <TableCell>Type</TableCell>
                <TableCell>Enrollment</TableCell>
                <TableCell>Date</TableCell>
                <TableCell>Source</TableCell>
                {/* <TableCell>User</TableCell> */}
                <TableCell></TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {this.state.trialEnrollments.length > 0 ? this.state.trialEnrollments.map((trialEnrollment) =>
                <TrialEnrollmentRow
                  key={trialEnrollment.trial_enrollment.id}
                  trialEnrollmentHash={trialEnrollment}
                  handleFavoriteClick={this.handleFavoriteClick}
                  handleDeleteClick={this.handleDeleteClick}
                  currentUser={this.props.currentUser}
                />
              ) : <TableRow><TableCell colSpan={6}>No enrollments yet. Add an enrollment above.</TableCell></TableRow>}
            </TableBody>

          </Table>

        </Box>

        <Snackbar
          open={this.state.showSnackbar}
          message={this.state.snackbarText}
        />

      </Fragment>
    )
  }
}

export default EnrollmentForm
