import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Flex from "../../Flex"
import Box from "../../Box"
import Typography from '@material-ui/core/Typography';
import update from "immutability-helper"
import TherapyTypeListItemContainer from "./TherapyTypeListItemContainer"
import { Button } from "../../buttons"
import { List, ListItem, ListItemText } from "../../lists"
import ExpandLess from '../../ExpandLess';
import ExpandMore from '../../ExpandMore';
import Collapse from '@material-ui/core/Collapse';
import { withSnackbar } from "../../SnackbarContainer"

class TherapyTypeForm extends Component {
  constructor(props) {
    super(props)
    this.newTherapyType = this.newTherapyType.bind(this)
    this.handleCheckboxSelect = this.handleCheckboxSelect.bind(this)
    this.handleCheckboxDelete = this.handleCheckboxDelete.bind(this)
    this.state = { productTherapyTypes: this.props.productTherapyTypes, open: false }
  }

  handleCheckboxSelect(therapyType, productTherapyType) {

    if (this.props.source) {
      this.newTherapyType(therapyType, productTherapyType)
    } else {
      this.setState({
        showSnackbar: true,
        snackbarText: 'You must choose a source'
      })
    }
  }

  newTherapyType(therapyType, productTherapyType) {

    let mainParams = { product_id: this.props.product.id, therapy_type_id: therapyType.id, created_by: this.props.currentUser.id, source_id: this.props.source.source_id, investigational: (this.props.activeRegion == 1000000 ? true : null) }

    $.post("/admin/product_therapy_types", { product_therapy_type: mainParams }, (data) => {
      const productTherapyType = data.product_therapy_type

      const productTherapyTypes = update(this.state.productTherapyTypes, {
        $push: [productTherapyType]
      })

      this.setState({
        productTherapyTypes: productTherapyTypes
      })
      this.props.snackbarShowMessage("Therapy type was added")
    }, 'json').fail((data) => {
      console.log(data.responseText)
      this.props.snackbarShowMessage("Therapy type was added")
    })
  }

  handleCheckboxDelete(productTherapyType) {
    let entityUrl = `/admin/product_therapy_types/${productTherapyType.id}`
    let entityParams = { product_therapy_type: { deleted: true, deleted_by: this.props.currentUser.id } }

    $.ajax({
      type: 'PUT',
      url: entityUrl,
      data: entityParams,
      dataType: 'json',
      success: (data) => {
        const productTherapyTypeIndex = this.state.productTherapyTypes.indexOf(productTherapyType)

        const productTherapyTypes = update(this.state.productTherapyTypes, {
          $splice: [[productTherapyTypeIndex, 1]]
        })

        this.setState({
          productTherapyTypes: productTherapyTypes,
          showSnackbar: true,
          snackbarText: `The therapy type was removed`
        })
      },
      error: (data) => {
        this.setState({
          showSnackbar: true,
          snackbarText: `The therapy type could not be removed. Please try again.`
        })
      },
      complete: (data) => {
        setTimeout(() => {
          this.setState({
            showSnackbar: false,
            snackbarText: ''
          })
        }, 4000)
      }
    })
  }

  render() {
    return(
      <Fragment>

        <List>

          <ListItem button onClick={() => this.setState({open: !this.state.open})}>
            {this.state.open ? <ExpandLess /> : <ExpandMore />}
            <ListItemText primary='Therapy types' />
          </ListItem>

          <Collapse in={this.state.open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {this.props.therapyTypes.map((therapyType) =>
                <TherapyTypeListItemContainer
                  key={therapyType.id}
                  therapyType={therapyType}
                  activeRegion={this.props.activeRegion}
                  productTherapyType={this.state.productTherapyTypes ? this.state.productTherapyTypes.filter(productTherapyType => productTherapyType.therapy_type_id == therapyType.id)[0] : null}
                  handleCheckboxSelect={this.handleCheckboxSelect}
                  handleCheckboxDelete={this.handleCheckboxDelete}
                />
              )}
            </List>
          </Collapse>

        </List>

      </Fragment>
    )
  }
}


export default withSnackbar(TherapyTypeForm)
