import React from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import { ListItem, ListItemText } from "../../lists"
import Checkbox from "../../Checkbox"
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction"
import DeleteButton from "../DeleteButton"
import Link from "../../Link"


export default function ProductListItem(props)  {
  
  function handleCheckboxSelect(e) {
    props.handleCheckboxSelect(props.product.id, 'activeProducts')
  }

  function handleUnassociateClick(e) {
    props.handleProductDeleteClick(props.product)
  }

  function setSecondaryText() {
    if(props.product.search_term) {
      return props.product.search_term
    } else if(props.predicted && !props.associated) {
      return 'predicted'
    } else if(props.associated) {
      return 'associated'
    } else {
      return ''
    }
  }
  
  return(
    <React.Fragment>
      <ListItem disabled={props.associated} selected={props.predicted && !props.associated ? true : false}>
        
        <Checkbox 
          onClick={handleCheckboxSelect}
          checked={props.checked || props.associated} 
        />
        
        <ListItemText 
          primary={
            <Link href={`/admin/products/${props.product.id}`} target="_blank">
              {props.product.short_name}
            </Link>
          } 
          secondary={setSecondaryText}
        />

        {props.associated ? 
          <ListItemSecondaryAction>
            <DeleteButton onClick={handleUnassociateClick} />
          </ListItemSecondaryAction>
        : ""}
        
      </ListItem>
    </React.Fragment>
  )
}
