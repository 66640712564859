import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Paper from "../../Paper"
import Flex from "../../Flex"
import Box from "../../Box"
import Select from "../../Select"
import { Button } from "../../buttons"
import { Table, TableHead, TableRow, TableCell, TableBody } from "../../tables"
import TypeForm from "./TypeForm"
import Typography from "@material-ui/core/Typography"
import Link from "../../Link"

export default class TypeUpdater extends Component {
  constructor(props) {
    super(props)
    this.handleReviewedClick = this.handleReviewedClick.bind(this)
    this.state = { reviewed: false }
  }

  handleReviewedClick(e) {
    $.ajax({
      type: 'POST',
      url: '/admin/trial_reviews',
      data: {
        trial_review: {
          trial_id: this.props.trial.id, reviewed: true, reviewed_by: this.props.current_user.id
        }
      },
      dataType: 'json',
      success: (data) => {
        this.setState({
          reviewed: true
        })
      }
    })
  }

  render() {
    return(
      <Fragment>
        <Paper>

          <Flex>
            <Box>
              <Link href='/admin/trials/reviewer'>
                Back to list
              </Link>
            </Box>

            <Box ml='auto'>
              <Button variant={this.state.reviewed ? 'outlined' : 'contained'} color={this.state.reviewed ? 'default' : 'primary'} onClick={this.handleReviewedClick}>
                {this.state.reviewed ? 'Reviewed' : 'Mark as Reviewed'}
              </Button>
            </Box>
          </Flex>

          <TypeForm
            trial={this.props.trial}
            trialTypes={this.props.trial_types}
            types={this.props.clinical_types}
            source={this.props.source}
            currentUser={this.props.current_user}
          />

          {/* <Box width={1/4}>
            <Box>
              Products
            </Box>
            <Table mt={1}>
              <TableHead>
                <TableRow hover={false}>
                  <TableCell>Product</TableCell>
                  <TableCell align='right'>Status</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {this.props.products.map((product) =>
                  <TableRow key={product.id}>
                    <TableCell>{product.short_name}</TableCell>
                    <TableCell align='right'>
                      {product.regulatory_statuses.map((regulatoryStatus) =>
                        <Box key={regulatoryStatus.id}>
                          {regulatoryStatus.name}
                        </Box>
                      )}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </Box> */}

          <Box pt={4}>
            <Box>
              <Typography variant="h6">
                Trial Data
              </Typography>

              <Table>
                <TableHead>
                  <TableRow hover={false}>
                    <TableCell borderRight={true}></TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  <TableRow>
                    <TableCell borderRight={true}>
                      NCT ID
                    </TableCell>
                    <TableCell>
                      <Link target="_blank" href={`https://clinicaltrials.gov/ct2/show/${this.props.trial.nct_id}`}>
                        {this.props.trial.nct_id}
                      </Link>
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell borderRight={true}>
                      Full Name
                    </TableCell>
                    <TableCell>
                      {this.props.trial_object.full_name}
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell borderRight={true}>
                      Name
                    </TableCell>
                    <TableCell>
                      {this.props.trial_object.short_name}
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell borderRight={true}>
                      Acronym
                    </TableCell>
                    <TableCell>
                      {this.props.trial_object.acronym}
                    </TableCell>
                  </TableRow>

                  {/* <TableRow>
                    <TableCell borderRight={true}>
                      Type
                    </TableCell>
                    <TableCell>
                      <Select
                        name='type_id'
                        entities={this.props.clinical_types}
                        label='Type'
                        value={this.state.type_id}
                        handleChange={this.handleChange}
                        className='w-50'
                      />
                    </TableCell>
                  </TableRow> */}

                  <TableRow>
                    <TableCell borderRight={true}>
                      Brief Summary
                    </TableCell>
                    <TableCell>
                      {this.props.trial_object.brief_summary}
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell borderRight={true}>
                      Locations
                    </TableCell>
                    <TableCell>
                      {this.props.locations}
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell borderRight={true}>
                      Status
                    </TableCell>
                    <TableCell>
                      {this.props.trial_status ? this.props.trial_status.name : ''}
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell borderRight={true}>
                      Sponsor
                    </TableCell>
                    <TableCell>
                      {this.props.trial_sponsor ? (this.props.trial_sponsor.company ? this.props.trial_sponsor.company.short_name : this.props.trial_sponsor.sponsor.name) : "N/A"}
                    </TableCell>
                  </TableRow>

                  {/* <TableRow>
                    <TableCell borderRight={true}>
                      Collaborators
                    </TableCell>
                    <TableCell>
                      {this.props.collaborators.length > 0 ? this.props.collaborators.map((collaborator) =>
                        <div key={collaborator.id}>
                          {collaborator.name}
                        </div>
                      ) : 'None'}
                    </TableCell>
                  </TableRow> */}

                  {/* <TableRow>
                    <TableCell borderRight={true}>
                      Conditions
                    </TableCell>
                    <TableCell>
                      {this.props.conditions.length > 0 ? this.props.conditions.map((condition) =>
                        <div key={condition.id}>
                          {condition.name}
                        </div>
                      ) : 'None'}
                    </TableCell>
                  </TableRow> */}

                  {/* <TableRow>
                    <TableCell borderRight={true}>
                      Interventions
                    </TableCell>
                    <TableCell>
                      {this.props.interventions.length > 0 ? this.props.interventions.map((intervention) =>
                        <div key={intervention.id}>
                          {intervention.name}
                        </div>
                      ) : 'None'}
                    </TableCell>
                  </TableRow> */}

                  <TableRow>
                    <TableCell borderRight={true}>
                      Inclusion Criteria
                    </TableCell>
                    <TableCell className='pre-line'>
                      {this.props.trial_object.inclusion_criteria}
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell borderRight={true}>
                      Exclusion Criteria
                    </TableCell>
                    <TableCell className='pre-line'>
                      {this.props.trial_object.exclusion_criteria}
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell borderRight={true}>
                      Primary Endpoints
                    </TableCell>
                    <TableCell className='pre-line'>
                      {this.props.trial_object.primary_endpoint}
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell borderRight={true}>
                      Secondary Endpoints
                    </TableCell>
                    <TableCell className='pre-line'>
                      {this.props.trial_object.secondary_endpoint}
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell borderRight={true}>
                      Detailed Description
                    </TableCell>
                    <TableCell className='pre-line'>
                      {this.props.trial_object.detailed_description}
                    </TableCell>
                  </TableRow>

                </TableBody>
              </Table>
            </Box>
          </Box>


        </Paper>
      </Fragment>
    )
  }
}
