import React from "react"
import IconButton from "../IconButton"
import EditIcon from "@material-ui/icons/Edit"

export default function EditButton(props) {
  return (
    <IconButton {...props}>
      <EditIcon fontSize='small' />
    </IconButton>
  )
}
