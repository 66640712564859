import React, { Fragment } from "react"
import Flex from "../Flex"
import Box from "../Box"
import FilterChip from '../filters/FilterChip';

export default function FilterChipsContainer(props) {

  let meetingOptionValues = props.filterOptions.meetings.map(meeting => meeting.value)
  const selectedMeetings = props.chosen.meetings.filter(meeting => props.filterValues.meeting_ids.includes(meeting.id) ? meeting : null)

  let productOptionValues = props.filterOptions.products.map(product => product.value)
  let selectedProducts = props.chosen.products.filter(product => props.filterValues.product_ids.includes(product.id) ? product : null)

  // let companyOptionValues = props.filterOptions.companies.map(company => company.value)
  // const selectedCompanies = props.chosen.companies.filter(company => props.filterValues.company_ids.includes(company.id) ? company : null)

  const selectedYears = props.filterOptions.meetingYears.filter(year => props.filterValues.years.includes(year.value) ? year : null)

  let trialOptionValues = props.filterOptions.trials.map(trial => trial.value)
  const selectedTrials = props.chosen.trials.filter(trial => props.filterValues.trial_ids.includes(trial.trial_id) ? trial : null)

  let targetSiteOptionValues = props.filterOptions.targetSites.map(targetSite => targetSite.value)
  const selectedTargetSites = props.chosen.targetSites.filter(targetSite => props.filterValues.target_site_ids.includes(targetSite.id) ? targetSite : null)

  let targetMarketOptionValues = props.filterOptions.targetMarkets.map(targetMarket => targetMarket.value)
  const selectedTargetMarkets = props.chosen.targetMarkets.filter(targetMarket => props.filterValues.target_market_ids.includes(targetMarket.id) ? targetMarket : null)

  let therapyTypeOptionValues = props.filterOptions.therapyTypes.map(therapyType => therapyType.value)
  const selectedTherapyTypes = props.chosen.therapyTypes.filter(therapyType => props.filterValues.therapy_type_ids.includes(therapyType.id) ? therapyType : null)

  return(
    <Fragment>

      <Flex flexWrap='wrap' mb={2} mt={2}>
        {selectedMeetings.map((activeFilter) =>
          <FilterChip
            key={activeFilter.value}
            name={activeFilter.short_name}
            value={activeFilter.id}
            className={meetingOptionValues.includes(activeFilter.id) ? '' : 'o-60'}
            type='meeting_ids'
            handleDelete={props.handleDelete}
          />
        )}
        {selectedYears.map((activeFilter) =>
          <FilterChip
            key={activeFilter.value}
            name={activeFilter.name}
            value={activeFilter.value}
            className={selectedYears.includes(activeFilter) ? '' : 'o-60'}
            type='years'
            handleDelete={props.handleDelete}
          />
        )}
        {selectedProducts.map((activeFilter) =>
          <FilterChip
            key={activeFilter.value}
            name={activeFilter.short_name}
            value={activeFilter.id}
            className={productOptionValues.includes(activeFilter.id) ? '' : 'o-60'}
            type='product_ids'
            handleDelete={props.handleDelete}
          />
        )}
        {selectedTrials.map((activeFilter) =>
          <FilterChip
            key={activeFilter.value}
            name={activeFilter.acronym || activeFilter.short_name}
            value={activeFilter.trial_id}
            className={trialOptionValues.includes(activeFilter.trial_id) ? '' : 'o-60'}
            type='trial_ids'
            handleDelete={props.handleDelete}
          />
        )}
        {selectedTargetSites.map((activeFilter) =>
          <FilterChip
            key={activeFilter.value}
            name={activeFilter.name}
            value={activeFilter.id}
            className={targetSiteOptionValues.includes(activeFilter.id) ? '' : 'o-60'}
            type='target_site_ids'
            handleDelete={props.handleDelete}
          />
        )}

        {selectedTargetMarkets.map((activeFilter) =>
          <FilterChip
            key={activeFilter.value}
            name={activeFilter.name}
            value={activeFilter.id}
            className={targetMarketOptionValues.includes(activeFilter.id) ? '' : 'o-60'}
            type='target_market_ids'
            handleDelete={props.handleDelete}
          />
        )}

        {selectedTherapyTypes.map((activeFilter) =>
          <FilterChip
            key={activeFilter.value}
            name={activeFilter.name}
            value={activeFilter.id}
            className={therapyTypeOptionValues.includes(activeFilter.id) ? '' : 'o-60'}
            type='therapy_type_ids'
            handleDelete={props.handleDelete}
          />
        )}

        {props.filterValues.query && props.search ?
          <FilterChip
            name={props.filterValues.query}
            value={props.filterValues.query}
            type='query'
            handleDelete={props.handleDelete}
          />
        : ''}
      </Flex>

    </Fragment>
  )
}
