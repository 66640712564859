import React from "react"
import ResultItemContainer from "./ResultItemContainer"
import Typography from "@material-ui/core/Typography"

export default function CompanyItem(props) {

  let url = props.admin ? `/admin/companies/${props.result.id}/edit` : `/sectors/${props.sector.abbr}/companies/${props.result.id}`

  function renderSearchDetails(result) {
    return(result.acquirer_name ? renderAcquirer(result.acquirer_name) : renderProducts(result.product_names))
  }

  function renderAcquirer(acquirer) {
    return (
      <Typography variant="body2" color="textSecondary">
        {`Acquired by ${acquirer}`}
      </Typography>
    );
  }

  function renderProducts(productNames) {
  
    // Determine if there are more than three products
    const moreThanThree = productNames.length > 3;

    // Get the first three product names and join them with commas
    const displayedProducts = productNames.slice(0, 3).join(', ');

    // Add an ellipsis if there are more than three products
    const productNamesString = moreThanThree ? `${displayedProducts}...` : displayedProducts;

    return (
      <Typography variant="body2" color="textSecondary">
        {productNamesString}
      </Typography>
    );
  }

  return(
    <ResultItemContainer 
      searchItemType={props.result.record_type}
      searchTitle={props.result.short_name || props.result.full_name}
      searchDescription={props.result.description}
      url={url}
      searchDetails={renderSearchDetails(props.result)}
    />
  )
}