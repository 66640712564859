import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Typography from "@material-ui/core/Typography"
import Flex from "../../Flex"
import Box from "../../Box"
import TextInput from "../../TextInput"
import Paper from "../../Paper"
import Link from "../../Link"
import Select from "../../Select"
import { RaisedButton, Button } from "../../buttons"
import Snackbar from "../../Snackbar"
import { Table, TableHead, TableRow, TableCell, TableBody } from "../../tables"
import update from "immutability-helper"
import ButtonLayout from "../ButtonLayout"
import Checkbox from "../../Checkbox"
import InlineProductForm from "../products/InlineAddForm"
import CtGovLink from "./CtGovLink"

export default class NewTrial extends Component {
  constructor(props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
    this.handleCreateClick = this.handleCreateClick.bind(this)
    this.handleHideClick = this.handleHideClick.bind(this)
    this.handleProductAddition = this.handleProductAddition.bind(this)
    this.state = { type_ids: [], sector_ids: [], trialProductsAttributes: [], showSnackbar: false, snackbarText: '' }
  }

  handleChange(name, value) {
    console.log(value)
    this.setState({
      [name]: value
    })
  }

  handleProductAddition(productId, investigatedDevice) {

    let trialProductsAttributes = ""
    let index = this.state.trialProductsAttributes.indexOf({ product_id: productId, investigated_device: investigatedDevice, created_by: this.props.current_user.id })

    if (index == -1) {
      trialProductsAttributes = update(this.state.trialProductsAttributes, {
        $push: [{ product_id: productId, investigated_device: investigatedDevice, created_by: this.props.current_user.id }]
      })
    } else {
      trialProductsAttributes = update(this.state.trialProductsAttributes, {
        $splice: [[index, 1]]
      })
    }


    this.setState({
      trialProductsAttributes: trialProductsAttributes
    })
  }

  handleHideClick(e) {
    $.ajax({
      type: 'PUT',
      url: `/admin/ct_gov_trials/${this.props.ctGovTrial.id}`,
      data: {
        ct_gov_trial: {
          hide_trial: 1, relevant: false
        }
      },
      dataType: 'json',
      success: (data) => {
        this.setState({
          showSnackbar: true,
          snackbarText: "The trial has been hidden"
        })
      },
      complete: (data) => {
        setTimeout(() => {
          this.setState({
            showSnackbar: false
          })
        }, 5000)
      }
    })
  }

  handleCreateClick(e) {
    if (this.state.type_id == '') {
      this.setState({
        showSnackbar: true,
        snackbarText: 'You have to add a type to create the trial'
      })

      setTimeout(() => {
        this.setState({
          showSnackbar: false
        })
      }, 5000)
    } else if (this.state.sector_id == '') {
      this.setState({
        showSnackbar: true,
        snackbarText: 'You have to add a sector to create the trial'
      })

      setTimeout(() => {
        this.setState({
          showSnackbar: false
        })
      }, 5000)
    } else {
      $.post("/admin/trials/create_from_ct_gov", { ct_gov_trial_id: this.props.ct_gov_trial.id, type_ids: this.state.type_ids, sector_ids: this.state.sector_ids, user_id: this.props.current_user.id, trial_products_attributes: this.state.trialProductsAttributes }, (data) => {
        let url = `/admin/trials/${data.trial.id}/edit`
        window.location.href = url
      }, 'json')
    }
  }

  render() {
    return(
      <Fragment>

        <Paper>

          <Table>
            <TableHead>
              <TableRow hover={false}>
                <TableCell borderRight={true}>
                  <CtGovLink 
                    nctId={this.props.ct_gov_trial.nct_id}
                  />
                </TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              <TableRow>
                <TableCell borderRight={true}>
                  NCT ID
                </TableCell>
                <TableCell>
                  {this.props.ct_gov_trial.nct_id}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell borderRight={true}>
                  Full Name
                </TableCell>
                <TableCell>
                  {this.props.ct_gov_trial.official_title}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell borderRight={true}>
                  Name
                </TableCell>
                <TableCell>
                  {this.props.ct_gov_trial.brief_title}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell borderRight={true}>
                  Acronym
                </TableCell>
                <TableCell>
                  {this.props.ct_gov_trial.acronym}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell borderRight={true}>
                  Predicted Products
                </TableCell>
                <TableCell>
                  {this.props.predicted_products ?
                    this.props.predicted_products.map((product) =>
                      <InlineProductForm
                        key={product.id}
                        product={product}
                        trialProductsAttributes={this.state.trialProductsAttributes}
                        handleProductAddition={this.handleProductAddition}
                      />
                    ) : "N/A"}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell borderRight={true}>
                  Type
                </TableCell>
                <TableCell>
                  <Select
                    multiple
                    name='type_ids'
                    entities={this.props.clinical_types}
                    label='Type'
                    value={this.state.type_ids}
                    handleChange={this.handleChange}
                    className='w-50'
                    renderValue={selected => (
                      <Flex flexWrap='wrap'>
                        {selected.map(value => this.props.clinical_types.filter(clinicalTypeHash => clinicalTypeHash.value == value).map(clinicalTypeHash => clinicalTypeHash.name)).join("; ")}
                      </Flex>
                    )}
                  />
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell borderRight={true}>
                  Sector
                </TableCell>
                <TableCell>
                  <Select
                    multiple
                    name='sector_ids'
                    entities={this.props.sectors}
                    label='Sector'
                    value={this.state.sector_ids}
                    handleChange={this.handleChange}
                    className='w-50'
                    renderValue={selected => (
                      <Flex flexWrap='wrap'>
                        {selected.map(value => this.props.sectors.filter(sectorHash => sectorHash.value == value).map(sectorHash => sectorHash.name)).join("; ")}
                      </Flex>
                    )} 
                  />
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell borderRight={true}>
                  Brief Summary
                </TableCell>
                <TableCell>
                  {this.props.ct_gov_trial.brief_summary}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell borderRight={true}>
                  Status
                </TableCell>
                <TableCell>
                  {this.props.ct_gov_trial.overall_status}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell borderRight={true}>
                  Sponsor
                </TableCell>
                <TableCell>
                  {this.props.ct_gov_trial.sponsor}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell borderRight={true}>
                  Collaborators
                </TableCell>
                <TableCell>
                  {this.props.collaborators.length > 0 ? this.props.collaborators.map((collaborator) =>
                    <div key={collaborator.id}>
                      {collaborator.name}
                    </div>
                  ) : 'None'}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell borderRight={true}>
                  Conditions
                </TableCell>
                <TableCell>
                  {this.props.conditions.length > 0 ? this.props.conditions.map((condition) =>
                    <div key={condition.id}>
                      {condition.name}
                    </div>
                  ) : 'None'}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell borderRight={true}>
                  Interventions
                </TableCell>
                <TableCell>
                  {this.props.interventions.length > 0 ? this.props.interventions.map((intervention) =>
                    <div key={intervention.id}>
                      {intervention.name}
                    </div>
                  ) : 'None'}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell borderRight={true}>
                  Inclusion Criteria
                </TableCell>
                <TableCell className='pre-line'>
                  {this.props.trial_criteria.inclusion_criteria}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell borderRight={true}>
                  Exclusion Criteria
                </TableCell>
                <TableCell className='pre-line'>
                  {this.props.trial_criteria.exclusion_criteria}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell borderRight={true}>
                  Primary Endpoints
                </TableCell>
                <TableCell className='pre-line'>
                  {this.props.primary_outcome}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell borderRight={true}>
                  Secondary Endpoints
                </TableCell>
                <TableCell className='pre-line'>
                  {this.props.secondary_outcome}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell borderRight={true}>
                  Detailed Description
                </TableCell>
                <TableCell className='pre-line'>
                  {this.props.ct_gov_trial.detailed_description}
                </TableCell>
              </TableRow>

            </TableBody>
          </Table>

          <ButtonLayout>
            <Flex justifyContent="space-between">
              <Box width={1/2}>
                <RaisedButton handleClick={this.handleCreateClick}>
                  Create Trial
                </RaisedButton>
              </Box>
              <Box width={1/3}>
                <RaisedButton color="default" handleClick={this.handleHideClick}>
                  Hide Trial
                </RaisedButton>
              </Box>
            </Flex>
          </ButtonLayout>

        </Paper>

        <Snackbar
          open={this.state.showSnackbar}
          message={this.state.snackbarText}
        />

      </Fragment>
    )
  }
}
