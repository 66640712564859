import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Flex from "../../Flex"
import Box from "../../Box"
import TherapyTypeForm from "./TherapyTypeForm"
import TechnologyGroupForm from "./TechnologyGroupForm"
import TargetMarketForm from "./TargetMarketForm"
import Grid from '@material-ui/core/Grid';
import Snackbar from "../../Snackbar"
import update from "immutability-helper"

class AttributesForm extends Component {
  constructor(props) {
    super(props)
    this.handleSnackbar = this.handleSnackbar.bind(this)
    this.handleCheckboxSelect = this.handleCheckboxSelect.bind(this)
    this.handleCheckboxDelete = this.handleCheckboxDelete.bind(this)
    this.getProductTherapyTypes = this.getProductTherapyTypes.bind(this)
    this.getProductTechnologyGroups = this.getProductTechnologyGroups.bind(this)
    this.state = { productTherapyTypes: [], productTechnologyGroups: [], productTargetMarkets: [], showSnackbar: false, snackbarText: '' }
  }
  
  componentDidMount() {
    this.getProductTherapyTypes()
    this.getProductTechnologyGroups()
    this.getProductTargetMarkets()
  }
  
  getProductTherapyTypes() {
    $.get("/admin/product_therapy_types", { product_id: this.props.product.id }, (data) => {
      this.setState({
        productTherapyTypes: data.product_therapy_types
      })
    }, "json")
  }
  
  getProductTechnologyGroups() {
    $.get("/admin/product_technology_groups", { product_id: this.props.product.id }, (data) => {
      this.setState({
        productTechnologyGroups: data.product_technology_groups
      })
    }, "json")
  }
  
  getProductTargetMarkets() {
    $.get("/admin/product_target_markets", { product_id: this.props.product.id }, (data) => {
      this.setState({
        productTargetMarkets: data.product_target_markets
      })
    }, "json")
  }
  
  handleCheckboxSelect(kind, entityUrl, entityParams) {
    if (this.props.source) {
      let successState = { showSnackbar: true, snackbarText: `The ${kind} was added` }
      $.post(entityUrl, entityParams, (data) => {
        if (kind == 'therapy type') {
          let productTherapyTypes = update(this.state.productTherapyTypes, { $push: [data.product_therapy_type] })
          successState['productTherapyTypes'] = productTherapyTypes
        } else if (kind == 'technology group') {
          
          let productTechnologyGroups = update(this.state.productTechnologyGroups, { $push: [data.product_technology_group] })
          successState['productTechnologyGroups'] = productTechnologyGroups
          
        } else {
          let productTargetMarkets = update(this.state.productTargetMarkets, { $push: [data.product_target_market] })
          successState['productTargetMarkets'] = productTargetMarkets
        }
        this.setState(successState)
      }, 'json').fail(() => {
        this.setState({
          showSnackbar: true,
          snackbarText: `The ${kind} could not be added. Please try again.`
        })
      }).always(() => {
        setTimeout(() => {
          this.setState({
            showSnackbar: false,
            snackbarText: ''
          })
        }, 4000)
      })
    } else {
      this.setState({
        showSnackbar: true,
        snackbarText: `You must choose a source before adding a ${kind}`
      })
      setTimeout(() => {
        this.setState({
          showSnackbar: false,
          snackbarText: ''
        })
      }, 4000)
    }
  }
  
  handleCheckboxDelete(kind, entity, entityUrl, entityParams) {
    $.ajax({
      type: 'PUT',
      url: entityUrl,
      data: entityParams,
      dataType: 'js',
      success: (data) => {
        let successState = { showSnackbar: true, snackbarText: `The ${kind} was removed` }
        if (kind == 'technology group') {
          
          let productTechnologyGroupIds = this.state.productTechnologyGroups.map((productTechnologyGroup) => productTechnologyGroup.id)
          let index = productTechnologyGroupIds.indexOf(entity.id)
          let productTechnologyGroups = update(this.state.productTechnologyGroups, { $splice: [[index, 1]] })
          successState['productTechnologyGroups'] = productTechnologyGroups
          
        } else if (kind == 'therapy type') {
          
          let productTherapyTypeIds = this.state.productTherapyTypes.map((productTherapyType) => productTherapyType.id)
          let index = productTherapyTypeIds.indexOf(entity.id)
          let productTherapyTypes = update(this.state.productTherapyTypes, { $splice: [[index, 1]] })
          successState['productTherapyTypes'] = productTherapyTypes
          
        } else {
          
          let productTargetMarketIds = this.state.productTargetMarkets.map((productTargetMarket) => productTargetMarket.id)
          let index = productTargetMarketIds.indexOf(entity.id)
          let productTargetMarkets = update(this.state.productTargetMarkets, { $splice: [[index, 1]] })
          successState['productTargetMarkets'] = productTargetMarkets
          
        }
        
        this.setState(successState)
      },
      error: (data) => {
        this.setState({
          showSnackbar: true,
          snackbarText: `The ${kind} could not be removed. Please try again.`
        })
      },
      complete: (data) => {
        setTimeout(() => {
          this.setState({
            showSnackbar: false,
            snackbarText: ''
          })
        }, 4000)
      }
    })
  }
  
  handleSnackbar(showSnackbar, snackbarText) {
    this.setState({
      showSnackbar: showSnackbar,
      snackbarText: snackbarText
    })
  }
  
  render() {
    return(
      <Fragment>
        <Grid container direction="row" justify="space-evenly" spacing={5}>
          
          <Grid item xs={12} sm={6} md={4}>
            <Box>
              <TechnologyGroupForm 
                product={this.props.product}
                technologyGroups={this.props.technologyGroups}
                productTechnologyGroups={this.state.productTechnologyGroups}
                source={this.props.source}
                handleCheckboxSelect={this.handleCheckboxSelect}
                handleCheckboxDelete={this.handleCheckboxDelete}
                currentUser={this.props.currentUser}
              />
            </Box>
          </Grid>
          
          <Grid item xs={4} sm={6} md={4}>
            <Box>
              <TherapyTypeForm 
                product={this.props.product}
                therapyTypes={this.props.therapyTypes}
                productTherapyTypes={this.state.productTherapyTypes}
                source={this.props.source}
                handleCheckboxSelect={this.handleCheckboxSelect}
                handleCheckboxDelete={this.handleCheckboxDelete}
                currentUser={this.props.currentUser}
              />
            </Box>
          </Grid>
          
          <Grid item xs={4} sm={6} md={4}>
            <Box>
              <TargetMarketForm 
                product={this.props.product}
                targetMarkets={this.props.targetMarkets}
                productTargetMarkets={this.state.productTargetMarkets}
                source={this.props.source}
                handleCheckboxSelect={this.handleCheckboxSelect}
                handleCheckboxDelete={this.handleCheckboxDelete}
                currentUser={this.props.currentUser}
              />
            </Box>
          </Grid>
        </Grid>
        
        <Snackbar 
          open={this.state.showSidebar}
          message={this.state.snackbarText} 
        />
      </Fragment>
    )
  }
}

export default AttributesForm
