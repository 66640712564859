import React from "react"
import MuiTableBody from '@material-ui/core/TableBody';
import theme from "../theme"

export default function TableBody(props) {
  return (
    <MuiTableBody {...props}>
      {props.children}
    </MuiTableBody>
  )
}
