import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Paper from "../../Paper"
import SourceForm from "../SourceForm"
import Flex from "../../Flex"
import ButtonLayout from "../ButtonLayout"
import Box from "../../Box"
import Chip from '@material-ui/core/Chip';
import CKEditor from 'ckeditor4-react';
import Dialog from "../../Dialog"
import { Button, RaisedButton } from "../../buttons"
import Select from "../../Select"



export default function Form(props) {

  return(
    <Paper minFullHeight>
      <Box>
        <Box my={2}>
          <Box>
            {props.source ?
              <Chip
                label={props.source.source_title}
                onDelete={props.handleSourceRemovalClick}
              />
            :
              <Button onClick={props.handleChooseSourceClick}>
                Choose Source
              </Button>
            }
          </Box>
        </Box>

        {props.openDialog ?
          <Dialog
            openDialog={props.openDialog}
            dialogTitle='Choose Source'
            withButtons={false}
            handleClick={props.handleAddClick}
            dialogContent={<SourceForm sources={props.sources} sourceTypes={props.sourceTypes} handleSourceChange={props.handleSourceChange} />}
          />
        : ''}

        <Box mt={2} width={1/4}>
          <Select
            label='Sector'
            name='sector_id'
            value={props.sectorId}
            entities={props.sectors}
            handleChange={props.handleSectorChange}
          />
        </Box>

        <Box my={4}>
          <CKEditor
            data={props.companyStatement.statement}
            onChange={props.handleChange}
          />
        </Box>

        <ButtonLayout>
          <RaisedButton handleClick={props.handleSaveClick}>
            Save Statement
          </RaisedButton>
        </ButtonLayout>
      </Box>
    </Paper>
  )
}
