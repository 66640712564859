import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Paper from "../../Paper"
import Form from "./Form"
import update from "immutability-helper"
import Snackbar from "../../Snackbar"

export default class NewContainer extends Component {
  constructor(props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
    this.handleCompanyChange = this.handleCompanyChange.bind(this)
    this.handleSaveClick = this.handleSaveClick.bind(this)
    this.handleDateChange = this.handleDateChange.bind(this)
    this.state = { pressRelease: this.props.press_release, showSnackbar: false, snackbarText: '' }
  }

  componentDidMount() {
    const pressRelease = update(this.state.pressRelease, { created_by: { $set: this.props.current_user.id } })
    this.setState({
      pressRelease: pressRelease
    })
  }

  handleDateChange(e) {
    const pressRelease = update(this.state.pressRelease, { date: { $set: e.target.value } })

    this.setState({
      pressRelease: pressRelease
    })
  };

  handleChange(e) {
    const name = e.currentTarget.name
    const value = e.currentTarget.value
    const pressRelease = update(this.state.pressRelease, { [name]: { $set: value } })
    this.setState({
      pressRelease: pressRelease
    })
  }

  handleCompanyChange(name, value) {
    const pressRelease = update(this.state.pressRelease, { [name]: { $set: value } })
    this.setState({
      pressRelease: pressRelease
    })
  }

  handleSaveClick(e) {
    const url = this.props.redirect_url ? `/admin/press_releases?r=${this.props.redirect_url}` : "/admin/press_releases"

    $.post(url, { press_release: this.state.pressRelease }, () => {
      window.location.href = url
    }, 'json').fail((data) => {
      this.setState({
        showSnackbar: true,
        snackbarText: `The press release could not be created. ${data.responseText}.`
      })
    }).always(() => {
      setTimeout(() => {
        this.setState({
          showSnackbar: false,
          snackbarText: ''
        })
      }, 4000)
    })
  }

  render() {
    return(
      <Fragment>

        <Paper>

          <Form
            pressRelease={this.state.pressRelease}
            companies={this.props.companies}
            handleChange={this.handleChange}
            handleSaveClick={this.handleSaveClick}
            handleDateChange={this.handleDateChange}
            handleCompanyChange={this.handleCompanyChange}
          />


        </Paper>

        <Snackbar
          open={this.state.showSnackbar}
          message={this.state.snackbarText}
        />
      </Fragment>
    )
  }
}
