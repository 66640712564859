import React, { Fragment } from "react"
import Box from "../../Box"
import Paper from "../../Paper"
import Link from "../../Link"
import Typography from "@material-ui/core/Typography"
import Flex from "../../Flex"
import SidebarSection from "../../SidebarSection"
import { List, ListItemLink, ListItemText } from "../../lists"
import PmaNumberDisplay from "../../pma_approvals/NumberDisplay"

export default function OverviewModule(props) {

  return(
    <Fragment>
      <Paper className='h-100'>
        <Typography variant="h6">
          Overview
        </Typography>

        <Box mt={3}>
          <Box mb={2}>
            {props.product.off_market ? 
              <div>
                <span className='b'>
                  No longer on market
                </span>
                {props.product.off_market_date ? 
                  <span className='pl2 f6 i'>
                    {`(${humanDateFormat(props.product.off_market_date)})`}
                  </span>
                : ""}
              </div>
            : ""}
          </Box>

          <SidebarSection header='Full Name' content={props.product.full_name} />

          {props.company ?
            <SidebarSection header='Company'>
              <Link href={`/sectors/${props.sector.abbr}/companies/${props.company.id}`}>
                 {props.company.short_name}
              </Link>
            </SidebarSection>
          : ""}

          {props.pmaApprovals.length > 0 ?
            <SidebarSection header='PMA Number'>
              {props.pmaApprovals.map((pmaApproval) =>
                <Box key={pmaApproval.id}>
                  <Link href={`/sectors/${props.sector.abbr}/products/${props.product.id}/pma_approvals/${pmaApproval.id}`}>
                    <PmaNumberDisplay 
                      pmaApproval={pmaApproval}
                    />
                  </Link>
                </Box>
              )}
            </SidebarSection>
          : ""}

          {props.productKClearances.length > 0 ?
            <SidebarSection header='510(k)'>
              {props.productKClearances.map((productKClearance) =>
                <Box key={productKClearance.k_number}>
                  <Link href={`https://www.accessdata.fda.gov/scripts/cdrh/cfdocs/cfpmn/pmn.cfm?ID=${productKClearance.k_number}`} target="_blank">
                    {productKClearance.k_number}
                  </Link>
                </Box>
              )}
            </SidebarSection>
          : ""}

          {props.overview_description ?
            <SidebarSection header='Description'>
              <div dangerouslySetInnerHTML={{__html: props.overview_description.content}} />
            </SidebarSection>
          : ""}
        </Box>

        {props.product.url ?
          <Box mt={3}>
            <Typography variant="Body1" className='b'>
              Related links
            </Typography>

            <Box mt={1}>
              <List dense={true}>
                <ListItemLink divider={true} href={props.product.url} target="_blank">
                  <ListItemText primary="Product Page URL" />
                </ListItemLink>
              </List>
            </Box>
          </Box>
        : ''}
      </Paper>

    </Fragment>
  );
}
