import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Paper from "../../Paper"
import Flex from "../../Flex"
import Box from "../../Box"
import EntityCard from "../EntityCard"
import Highlighter from "react-highlight-words";
import Link from "../../Link"
import Typography from "@material-ui/core/Typography"

export default class PatentPanel extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    return(
      <Fragment>

        <Paper fullHeight={true}>

          <Box height="100%" overflow="auto">
            <Flex flexDirection='column' mb={2}>
              <Box width={1} mb={1}>
                <label className='b'>Title</label>
              </Box>
              <Box width={1}>
                {this.props.searchTerms ?
                  <Highlighter
                    searchWords={this.props.searchTerms}
                    autoEscape={true}
                    textToHighlight={this.props.patent.title}
                  />
                : this.props.patent.title}
              </Box>
            </Flex>

            <Flex flexDirection='column' mb={2}>
              <Box width={1} mb={1}>
                <label className='b'>#</label>
              </Box>
              <Box width={1}>
                <Link href={this.props.patent.url} target="_blank">
                  {`${this.props.patent.country}${this.props.patent.html_patent_no}${this.props.patent.patent_kind} (view on patent site)`}
                </Link>
              </Box>
            </Flex>

            <Flex flexDirection='column' mb={2}>
              <Box width={1} mb={1}>
                <label className='b'>Kind</label>
              </Box>
              <Box width={1}>
                {this.props.patent.kind}
              </Box>
            </Flex>

            <Flex flexDirection='column' mb={2}>
              <Box width={1} mb={1}>
                <label className='b'>Date</label>
              </Box>
              <Box width={1}>
                {this.props.patent.date}
              </Box>
            </Flex>

            <Flex flexDirection='column' mb={2}>
              <Box width={1} mb={1}>
                <label className='b'>Assignee</label>
              </Box>
              <Box width={1}>
                {this.props.assignee}
              </Box>
            </Flex>

            <Flex flexDirection='column' mb={2}>
              <Box width={1} mb={1}>
                <label className='b'>Applicants</label>
              </Box>
              <Box width={1}>
                {this.props.applicants}
              </Box>
            </Flex>

            <Flex flexDirection='column' mb={2}>
              <Box width={1} mb={1}>
                <label className='b'>Classifications</label>
              </Box>
              <Box width={1}>
                {this.props.classifications ? this.props.classifications.map((classification) =>
                  <Box key={classification.id} mb={1}>
                    <Link href={`/admin/patents/db_search?patent_classification_definition_id=${classification.patent_classification_definition_id}`} target="_blank">
                      {classification.name}
                    </Link>
                    <Typography variant="body2" color="textSecondary">
                      {classification.definition}
                    </Typography>
                  </Box>
                ) : "N/A"}
              </Box>
            </Flex>

            <Flex flexDirection='column' mb={2}>
              <Box width={1} mb={1}>
                <label className='b'>Abstract</label>
              </Box>

              <Box width={1}>
                {this.props.searchTerms ?
                  <Highlighter
                    searchWords={this.props.searchTerms}
                    autoEscape={true}
                    textToHighlight={this.props.patent.abstract}
                  />
                : this.props.patent.abstract}
              </Box>
            </Flex>

            <Flex flexDirection='column'>
              <Box width={1} mb={1}>
                <label className='b'>Description</label>
              </Box>
              <Box>
                {this.props.searchTerms ?
                  <Highlighter
                    searchWords={this.props.searchTerms}
                    autoEscape={true}
                    textToHighlight={this.props.descriptionText}
                  />
                : this.props.descriptionText}
              </Box>
            </Flex>

            <Flex flexDirection='column'>
              <Box width={1} mb={1}>
                <label className='b'>Claims</label>
              </Box>
              <Box>
                {this.props.searchTerms ?
                  <Highlighter
                    searchWords={this.props.searchTerms}
                    autoEscape={true}
                    textToHighlight={this.props.claimText}
                  />
                : this.props.claimText}
              </Box>
            </Flex>

            {/* <Flex flexDirection='column' mb={2}>
              <Box width={1} mb={1}>
                <label className='b'>Authors</label>
              </Box>
              <Box>
                {this.props.authors.length > 0 ? this.props.authors.map((personName) =>
                  <Box key={personName.id}>{personName.text}</Box>
                ) : <Box fontStyle='italic'>None</Box>}
              </Box>
            </Flex>


            <Flex flexDirection='column' mb={2}>
              <Box width={1} mb={1}>
                <label className='b'>Keywords</label>
              </Box>
              <Box>
                {this.props.keywords.map((keyword) =>
                  <Box key={keyword.id}>
                    {keyword.name}
                  </Box>
                )}
              </Box>
            </Flex>

            <Flex flexDirection='column' mb={2}>
              <Box width={1} mb={1}>
                <label className='b'>Mesh terms</label>
              </Box>
              <Box>
                {this.props.meshTerms.map((meshTerm) =>
                  <Box key={meshTerm.id}>
                    {meshTerm.name}
                  </Box>
                )}
              </Box>
            </Flex> */}
          </Box>

        </Paper>

      </Fragment>
    )
  }
}
