import React from "react"
import Box from "../Box"
import Typography from "@material-ui/core/Typography"
import { ListItemLink, ListItemText } from "../lists"
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction"

export default function ResultItemContainer(props) {

  let searchDescription = props.searchDescription && props.searchDescription.length > 180 ? props.searchDescription.substring(0, 180) + "..." : props.searchDescription

  let descriptionWithDate;
  descriptionWithDate = props.date ? `${humanDateFormat(props.date)} - ` : ""
  descriptionWithDate += searchDescription ? `${searchDescription}` : "Description not available"

  return(
    <ListItemLink href={props.url} alignItems='flex-start' divider={true} target={props.target}>
      <ListItemText>
        <Typography variant="caption" color="textSecondary">
          {props.searchItemType}
        </Typography>

        <Box pb={1}>
          <Typography variant="body1">
            {props.searchTitle}
          </Typography>
        </Box>

        <Box maxWidth="40em">
          <Typography variant="body2" color="textSecondary">
            <div
              dangerouslySetInnerHTML={{__html: descriptionWithDate }}
            />
          </Typography>
        </Box>

        {props.searchDetails ? 
          <Box mt={1}>
            {props.searchDetails}
          </Box> 
        : ""}
      </ListItemText>

      {props.secondaryAction ?
        <ListItemSecondaryAction>
          {props.secondaryAction}
        </ListItemSecondaryAction>
      : ""}
    </ListItemLink>
  )
}