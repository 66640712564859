import React from 'react'
import { TableRow, TableCell } from '../../tables'
import Link from '../../Link'
import Box from '../../Box'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import MuiIconButton from '@material-ui/core/IconButton';


export default function TrialRow(props) {

  function handleTrialCheckboxClick(e) {
    props.handleTrialCheckboxClick(props.trial[0].trial_id)
  }

  let trial = props.trial[0]

  let products = groupBy(props.trial, "product_id")

  let clinicalTypes = groupBy(props.trial, "clinical_type_id")

  let roles = groupBy(props.trial, "role")

  let selectedTrial = props.checkedTrials.includes(trial.trial_id) ? true : false

  console.log(Object.keys(roles))
  
  return (
    <TableRow>
      <TableCell borderRight borderLeft padding='checkbox' className={`${selectedTrial ? '' : 'o-50'}`}>
        <MuiIconButton onClick={handleTrialCheckboxClick} edge="start" style={{height: '22px', width: '22px'}}>
          {selectedTrial ? <CheckBoxIcon color='primary' style={{height: '22px', width: '22px'}} /> : <CheckBoxOutlineBlankIcon style={{height: '22px', width: '22px'}} />}
        </MuiIconButton>
      </TableCell>
      <TableCell borderRight borderLeft>
        <Link href={`/sectors/${props.sector.abbr}/trials/${trial.trial_id}`}>
          <Box>
            {trial.acronym || trial.short_name}
          </Box>
        </Link>
      </TableCell>
      <TableCell borderRight>
        {trial.company_id ?
          <Link href={`/sectors/${props.sector.abbr}/companies/${trial.company_id}`}>
            <Box>
              {trial.sponsor_name}
            </Box>
          </Link>
        :
          <Box>
            {trial.sponsor_name}
          </Box>
        }
      </TableCell>
      <TableCell borderRight>
        {Object.keys(products).map((productId) =>
          <Box key={productId}>
            <Link href={`/sectors/${props.sector.abbr}/products/${productId}`}>
              {products[productId][0].product_name}
            </Link>
          </Box>
        )}
      </TableCell>
      <TableCell borderRight>
        {Object.keys(clinicalTypes).map((clinicalTypeId) =>
          <Box key={clinicalTypeId}>
            {clinicalTypes[clinicalTypeId][0].clinical_type_name}
          </Box>
        )}
      </TableCell>
      <TableCell borderRight>
        <Box>{trial.status}</Box>
      </TableCell>
      <TableCell borderRight>
        <Box>{trial.affiliation || trial.facility_name}</Box>
      </TableCell>
      <TableCell borderRight className='w-10'>
        {/* <Box>{trial.role || "N/A"}</Box> */}
        {Object.keys(roles).length > 0 ? Object.keys(roles).map((role) =>
          <Box key={role}>
            {roles[role][0].role}
          </Box>
        ) : "N/A"}
      </TableCell>
      {/* <TableCell borderRight>
        {props.dateColumn ? trial.date : trial.last_updated.split('T')[0]}
      </TableCell> */}
    </TableRow>
  )
}
