import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import SectorForm from "./SectorForm"
import ProfileForm from "./ProfileForm"
import Paper from "../../Paper"
import Snackbar from "../../Snackbar"
import TabSection from "../../TabSection"
import update from "immutability-helper"
import Tabs from '../../tabs/Tabs';
import Tab from '../../tabs/Tab';
import Box from "../../Box"


class EditContainer extends Component {
  constructor(props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
    this.handleCheckboxSelect = this.handleCheckboxSelect.bind(this)
    this.handleSaveClick = this.handleSaveClick.bind(this)
    this.handleActiveIndexUpdate = this.handleActiveIndexUpdate.bind(this)
    this.state = { therapyType: this.props.therapy_type, therapyTypeSectors: this.props.therapy_type_sectors, showSnackbar: false, snackbarText: '', activeIndex: 0 }
  }
  
  handleActiveIndexUpdate(e, newValue) {
    e.preventDefault()
    this.setState({
      activeIndex: newValue
    })
  }
  
  handleChange(e) {
    const name = e.currentTarget.name
    const value = e.currentTarget.value
    let therapyType = update(this.state.therapyType, { 
      [name]: { $set: value },
      updated_by: { $set: this.props.current_user.id }
    })
    this.setState({
      therapyType: therapyType
    })
    
  }
  
  handleSaveClick(e) {
    $.ajax({
      type: 'PUT',
      url: `/admin/therapy_types/${this.state.therapyType.id}`,
      data: {
        therapy_type: this.state.therapyType
      },
      dataType: 'json',
      success: (data) => {
        this.setState({
          therapyType: data.therapy_type,
          showSnackbar: true,
          snackbarText: 'Therapy type has been updated'
        })
      },
      error: (data) => {
        this.setState({
          showSnackbar: true,
          snackbarText: 'Therapy type could not be updated. Please try again.'
        })
      },
      complete: (data) => {
        setTimeout(() => {
          this.setState({
            showSnackbar: false,
            snackbarText: ''
          })
        }, 4000)
      }
    })
  }
  
  handleCheckboxSelect(sector, checked) {
    const therapyTypeSector = this.state.therapyTypeSectors.filter((therapyTypeSector) => therapyTypeSector.sector_id == sector.id)[0]
    
    if (checked) {
      $.ajax({
        type: 'PUT',
        url: `/admin/therapy_type_sectors/${therapyTypeSector.id}`,
        data: {
          therapy_type_sector: {
            deleted: true, updated_by: this.props.current_user.id
          }
        },
        dataType: 'json',
        success: (data) => {
          let index = this.state.therapyTypeSectors.indexOf(therapyTypeSector)
          let therapyTypeSectors = update(this.state.therapyTypeSectors, { $splice: [[index, 1]]})
          this.setState({
            therapyTypeSectors: therapyTypeSectors,
            showSnackbar: true,
            snackbarText: 'Sector has been removed'
          })
        },
        error: (data) => {
          this.setState({
            showSnackbar: true,
            snackbarText: 'The sector could not be removed. Please try again.'
          })
        },
        complete: (data) => {
          setTimeout(() => {
            this.setState({
              showSnackbar: false,
              snackbarText: ''
            })
          }, 4000)
        }
      })
    } else {
      $.post("/admin/therapy_type_sectors", { therapy_type_sector: { therapy_type_id: this.state.therapyType.id, sector_id: sector.id, created_by: this.props.current_user.id }}, (data) => {
        let therapyTypeSectors = update(this.state.therapyTypeSectors, {$push: [data.therapy_type_sector]})
        this.setState({
          therapyTypeSectors: therapyTypeSectors,
          showSnackbar: true,
          snackbarText: 'The sector has been added'
        })
      }, 'json').fail(() => {
        this.setState({
          showSnackbar: true,
          snackbarText: `The sector could not be added. Please try again.`
        })
      }).always(() => {
        setTimeout(() => {
          this.setState({
            showSnackbar: false,
            snackbarText: ''
          })
        }, 4000)
      })
    }
  }
  
  render() {
    return(
      <Fragment>
        
        <Paper>
          
          <Box mb={4}>
            <Tabs centered value={this.state.activeIndex} onChange={this.handleActiveIndexUpdate} className='bb b--black-10' aria-label="nav tabs">
              <Tab label='Profile' />
              <Tab label='Sectors' />
            </Tabs>
          </Box>
          
          {this.state.activeIndex == 0 ?
            <ProfileForm 
              therapyType={this.state.therapyType}
              handleChange={this.handleChange}
              handleSaveClick={this.handleSaveClick}
            />
          : ''}
          
          {this.state.activeIndex == 1 ?
            <SectorForm 
              sectors={this.props.sectors}
              therapyTypeSectors={this.state.therapyTypeSectors}
              handleCheckboxSelect={this.handleCheckboxSelect}
            />
          : ''}
        </Paper>
        
        <Snackbar 
          open={this.state.showSnackbar}
          message={this.state.snackbarText} 
        />
      </Fragment>
    )
  }
}


export default EditContainer
