import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemText from "../../lists/ListItemText"
import Checkbox from "../../Checkbox"

class AttributeOptionDisplay extends Component {
  constructor(props) {
    super(props)
    this.handleCheckboxSelect = this.handleCheckboxSelect.bind(this)
  }

  handleCheckboxSelect(e) {
    // let productAttribute = this.props.productAttributes.filter((productAttribute) => productAttribute[this.props.productAttributeId] == this.props.attribute.id)[0]

    if (this.props.checked) {
      this.props.handleCheckboxDelete(this.props.attribute.id)
    } else {
      this.props.handleCheckboxSelect(this.props.attribute.id)
    }
  }

  render() {
    // const attributeIds = this.props.productAttributes.map((productAttribute) => productAttribute[this.props.productAttributeId] )
    // let checked = attributeIds.includes(this.props.attribute.id)
    return(
      <Fragment>

        <ListItem button key={this.props.attribute.id} role={undefined} onClick={this.handleCheckboxSelect}>
          <ListItemIcon>
            <Checkbox
              checked={this.props.checked}
            />
          </ListItemIcon>

          <ListItemText primary={this.props.attribute.name} />
        </ListItem>

      </Fragment>
    )
  }
}

export default AttributeOptionDisplay
