import React from 'react'
import { Table, TableBody, TableHeadSort } from '../../tables'
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TablePaginationActions from "../../tables/TablePaginationActions"
import Row from './Row'

export default function ApprovalsTable(props) {
  let headers = [{ label: 'ID', name: 'regulatory_id' }, { label: 'Country', name: 'country_abbr' }, { label: 'Product', name: 'device_name' }, { label: "Manufacturer", name: "manufacturer" }, { label: 'Date', name: 'date' }, { label: "Hide", name: "" }]

  return (
    <Table stickyHeader size="small">
      <TableHeadSort 
        headers={headers}
        // order={this.state.order}
        // orderBy={this.state.orderBy}
        // onRequestSort={this.handleRequestSort}
      />

      <TableBody>
        {props.deviceApprovals.map((approval) =>
          <Row 
            key={approval.id}
            approval={approval}
            handleDeleteClick={props.handleDeleteClick}
          />
        )}
      </TableBody>
      <TableFooter>
        <TablePagination
          colSpan={7}
          count={props.deviceApprovalsCount}
          rowsPerPage={100}
          page={props.page}
          SelectProps={{
            inputProps: { 'aria-label': 'rows per page' },
            native: true,
          }}
          onChangePage={props.handlePageChange}
          onChangeRowsPerPage={props.handleChangeRowsPerPage}
          ActionsComponent={TablePaginationActions}
        />
      </TableFooter>
    </Table>
  )
}