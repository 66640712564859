import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Flex from "../../Flex"
import Box from "../../Box"

export default class PdfStatsModule extends Component {
  constructor(props) {
    super(props)
  }
  
  render() {
    return(
      <Fragment>
        <Box>
          <h3 className='normal fsl mb2'>PDF Stats</h3>
          
          <Box>
            
            <Box textAlign='center' mt={3}>
              <h4 className='b'>Total</h4>
              
              <Flex align='items-center'>
                <Box width={1/3}>
                  <label className='fsm gray'>PDF's</label>
                  <div className='heavy fsm'>{this.props.totalPdfs}</div>
                </Box>
              
                <Box width={1/3} mx={1}>
                  <label className='fsm gray'>Abstracts</label>
                  <div className='heavy fsm'>{this.props.totalAbstracts}</div>
                </Box>
              
                <Box width={1/3}>
                  <label className='fsm gray'>Percentage</label>
                  <div className='heavy fsm'>{((this.props.totalPdfs/this.props.totalAbstracts) * 100).toFixed()}%</div>
                </Box>
                
              </Flex>
            </Box>
            
            {/* <Flex>
              <Box width={[100, 100]}>
                <TabSectionNew>
                  {tabs}
                </TabSectionNew>
              </Box>
            </Flex> */}
            {/* <div className='mv4'>
              <div className='tc bb b--black-20'>
                <h4 className='b'>Relevant</h4>
                <Flex align='items-center' classStyle='mv3'>
                  <Box width={[33]}>
                    <label className='fsm gray'>PDF's</label>
                    <div className='heavy fsm'>{this.props.relevant_pdfs}</div>
                  </Box>
                  <Box width={[33]} classStyle='mh1'>
                    <label className='fsm gray'>Abstracts</label>
                    <div className='heavy fsm'>{this.props.relevant_abstracts}</div>
                  </Box>
                  <Box width={[33]}>
                    <label className='fsm gray'>Percentage</label>
                    <div className='heavy fsm'>{((this.props.relevant_pdfs/this.props.relevant_abstracts) * 100).toFixed()}%</div>
                  </Box>
                </Flex>
              </div>
              <div className='tc mt3'>
                <h4 className='b'>Total</h4>
                <Flex align='items-center' classStyle='mv3'>
                  <Box width={[33]}>
                    <label className='fsm gray'>PDF's</label>
                    <div className='heavy fsm'>{this.props.total_pdfs}</div>
                  </Box>
                  <Box width={[33]} classStyle='mh1'>
                    <label className='fsm gray'>Abstracts</label>
                    <div className='heavy fsm'>{this.props.abstractCount}</div>
                  </Box>
                  <Box width={[33]}>
                    <label className='fsm gray'>Percentage</label>
                    <div className='heavy fsm'>{((this.props.total_pdfs/this.props.abstractCount) * 100).toFixed()}%</div>
                  </Box>
                </Flex>
              </div>
            </div> */}

          </Box>
        </Box>
      </Fragment>
    )
  }
}
