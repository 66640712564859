import React from "react"
import Box from "../../Box"
import SectorCheckboxList from "../sectors/CheckboxList"
import Typography from "@material-ui/core/Typography"

export default function AlertOptionForm(props) {
  return(
    <Box>
      <Box mb={2}>
        <Typography>
          Add the sector for the alerts
        </Typography>
      </Box>

      <Box>
        <SectorCheckboxList
          sectors={props.sectors}
          sectorIds={props.sectorIds}
          handleCheckboxSelect={props.handleCheckboxSelect}
        />
      </Box>
    </Box>
  )
}
