import React, { Component, Fragment } from "react"
import Box from "../../Box"
import RegionTabs from "./RegionTabs"
import update from "immutability-helper"
import DisplayContainer from "../product_characteristics/DisplayContainer"
import CharacteristicGroupDisplay from "./CharacteristicGroupDisplay"
import Snackbar from "../../Snackbar"
import CircularProgress from '@material-ui/core/CircularProgress';
import TargetMarketForm from "./TargetMarketForm"
import TargetSiteForm from "./TargetSiteForm"
import InlineSearchContainer from "../search/InlineSearchContainer"

export default class RegionCharacteristicsForm extends Component {
  constructor(props) {
    super(props)
    this.handleCheckboxSelect = this.handleCheckboxSelect.bind(this)
    this.handleRegionIndexUpdate = this.handleRegionIndexUpdate.bind(this)
    this.handleRegionChange = this.handleRegionChange.bind(this)
    this.newProductCharacteristic = this.newProductCharacteristic.bind(this)
    this.removeProductCharacteristic = this.removeProductCharacteristic.bind(this)
    this.handleRangeClick = this.handleRangeClick.bind(this)
    this.handleDiscreteClick = this.handleDiscreteClick.bind(this)
    this.handleSearchClick = this.handleSearchClick.bind(this)
    this.handleClearSearchClick = this.handleClearSearchClick.bind(this)
    this.state = { orginialCharacteristicGroups: null, productCharacteristics: null, characteristicGroups: null, productTargetMarkets: null, productTargetSites: null, allOpen: true, showSnackbar: false, snackbarText: '', activeRegionIndex: 1, Length_range: true, Length_discrete: false, Diameter_range: true, Diameter_discrete: false, targetSites: this.props.targetSites, targetMarkets: this.props.targetMarkets, originalTargetSites: null, originalTargetMarkets: null }
  }

  componentDidMount() {
    $.get(`/admin/products/${this.props.product.id}/characteristics`, { product_id: this.props.product.id, by_region: this.props.byRegion }, (data) => {
      this.setState({
        productCharacteristics: data.product_characteristics, characteristicGroups: data.characteristic_groups, orginialCharacteristicGroups: data.characteristic_groups, productTargetSites: data.product_target_sites, productTargetMarkets: data.product_target_markets, originalTargetMarkets: data.target_markets, originalTargetSites: data.target_sites
      })
    }, "json")
  }

  handleSearchClick(query) {
    $.ajax({
      type: 'GET',
      url: `/admin/products/search_characteristics`,
      data: {
        query: query, by_region: true
      },
      dataType: 'json',
      success: (data) => {
        this.setState({
          characteristicGroups: data.characteristic_groups,
          targetSites: data.target_sites,
          targetMarkets: data.target_markets
        })
      }
    })
  }

  handleClearSearchClick(e) {
    this.setState({
      characteristicGroups: this.state.orginialCharacteristicGroups,
      targetSites: this.state.originalTargetSites,
      targetMarkets: this.state.originalTargetMarkets
    })
  }

  handleRegionIndexUpdate(e, newValue) {
    this.setState({
      activeRegionIndex: newValue
    })
  }

  handleRangeClick(e) {
    let otherState = e.target.name == 'Lengths_range' ? 'Lengths_discrete' : 'Diameters_discrete'
    this.setState({
      [e.target.name]: e.target.checked,
      [otherState]: !e.target.checked
    })
  }

  handleDiscreteClick(e) {
    let otherState = e.target.name == 'Lengths_discrete' ? 'Lengths_range' : 'Diameters_range'
    this.setState({
      [e.target.name]: e.target.checked,
      [otherState]: !e.target.checked
    })
  }

  handleRegionChange(e) {
    const value = e.target.value

    const index = this.state.activeRegions.indexOf(value)

    const activeRegions = index == -1 ? update(this.state.activeRegions, { $push: [value] }) : update(this.state.activeRegions, { $splice: [[index, 1]] })

    this.setState({
      activeRegions: activeRegions
    })
  }

  handleCheckboxSelect(characteristicValue, characteristicGroup, checked, productCharacteristic) {

    if (checked) {

      this.removeProductCharacteristic(productCharacteristic)

    } else {
      if (this.props.source) {
        this.newProductCharacteristic(characteristicGroup, characteristicValue)
      } else {
        this.setState({
          showSnackbar: true,
          snackbarText: `You must choose a source before adding a characteristic`
        })
        setTimeout(() => {
          this.setState({
            showSnackbar: false,
            snackbarText: ''
          })
        }, 4000)
      }
    }
  }

  removeProductCharacteristic(productCharacteristic) {
    $.ajax({
      type: 'PUT',
      url: `/admin/product_characteristics/${productCharacteristic.id}`,
      data: {
        product_characteristic: {
          deleted: true, deleted_by: this.props.currentUser.id
        }
      },
      dataType: 'json',
      success: (data) => {
        const index = this.state.productCharacteristics.indexOf(productCharacteristic)

        const productCharacteristics = update(this.state.productCharacteristics, {
          $splice: [[index,1]]
        })

        this.setState({
          productCharacteristics: productCharacteristics,
          showSnackbar: true,
          snackbarText: 'Characteristic has been removed'
        })
      },
      error: (data) => {
        this.setState({
          showSnackbar: true,
          snackbarText: 'Characteristic could not be removed'
        })
      },
      complete: (data) => {
        setTimeout(() => {
          this.setState({
            showSnackbar: false,
            snackbarText: ''
          })
        }, 5000)
      }
    })
  }

  newProductCharacteristic(characteristicGroup, characteristicValue) {
    let mainParams = { product_id: this.props.product.id, characteristic_group_id: characteristicGroup.id, characteristic_value_id: characteristicValue.id, created_by: this.props.currentUser.id, source_id: this.props.source.source_id, investigational: (this.state.activeRegionIndex == 1000000 ? true : null), world_region_id: (this.state.activeRegionIndex == 1000000 ? null : this.state.activeRegionIndex), range: (characteristicGroup.name == 'Length' || characteristicGroup.name == 'Diameter') && this.state.activeRegionIndex == 1000000 ? this.state[`${characteristicGroup.name}_range`] : null, discrete: (characteristicGroup.name == 'Lengths' || characteristicGroup.name == 'Diameters') && this.state.activeRegionIndex == 1000000 ? this.state[`${characteristicGroup.name}_discrete`] : null }

    $.post("/admin/product_characteristics", { product_characteristic: mainParams }, (data) => {

      const productCharacteristics = update(this.state.productCharacteristics, {
        $push: [data.product_characteristic]
      })

      this.setState({
        productCharacteristics: productCharacteristics,
        showSnackbar: true,
        snackbarText: 'Characteristic was added'
      })
    }, 'json').fail((data) => {
      this.setState({
        showSnackbar: true,
        snackbarText: `Characteristic could not be added. ${data.error_message}`
      })
    }).always((data) => {
      setTimeout(() => {
        this.setState({
          showSnackbar: false,
          snackbarText: ''
        })
      }, 4000)
    })
  }

  render() {
    return(
      <Fragment>
        <Box height="80%">

        <Box>
          <InlineSearchContainer
            searchLabel="Search Characteristics"
            query={this.state.query}
            handleSearchClick={this.handleSearchClick}
            handleClearSearchClick={this.handleClearSearchClick}
          />
        </Box>

          <Box>

            <Box position='sticky'>
              <RegionTabs
                activeRegionIndex={this.state.activeRegionIndex}
                worldRegions={this.props.worldRegions}
                handleRegionIndexUpdate={this.handleRegionIndexUpdate}
              />
            </Box>

          </Box>

          <Box overflow='auto' height="90%">
            {this.state.productCharacteristics ?
              <Fragment>
                {this.state.targetSites.length > 0 ? 
                  <TargetSiteForm
                    product={this.props.product}
                    productTargetSites={this.state.productTargetSites}
                    targetSites={this.state.targetSites}
                    source={this.props.source}
                    activeRegion={this.state.activeRegionIndex}
                    currentUser={this.props.currentUser}
                  />
                : ""}

                {this.state.targetMarkets.length > 0 ?
                  <TargetMarketForm
                    product={this.props.product}
                    productTargetMarkets={this.state.productTargetMarkets}
                    targetMarkets={this.state.targetMarkets}
                    source={this.props.source}
                    activeRegion={this.state.activeRegionIndex}
                    currentUser={this.props.currentUser}
                  />
                : ""}

                {this.state.characteristicGroups.map((characteristicGroup) =>
                  <CharacteristicGroupDisplay
                    key={characteristicGroup.id}
                    displayCharacteristics={
                      <DisplayContainer
                        characteristicGroup={characteristicGroup}
                        productCharacteristics={this.state.productCharacteristics}
                        activeRegionIndex={this.state.activeRegionIndex}
                        handleCheckboxSelect={this.handleCheckboxSelect}
                      />
                    }
                    allOpen={this.state.allOpen}
                    characteristicGroup={characteristicGroup}
                    product={this.props.product}
                    handleRangeClick={this.handleRangeClick}
                    Lengths_range={this.state.Lengths_range}
                    Diameters_range={this.state.Diameters_range}
                    handleDiscreteClick={this.handleDiscreteClick}
                    Lengths_discrete={this.state.Lengths_discrete}
                    Diameters_discrete={this.state.Diameters_discrete}
                    activeRegionIndex={this.state.activeRegionIndex}
                  />)}
              </Fragment> :
                <Box>
                  <Box width="10%" mx="auto">
                    <CircularProgress />
                  </Box>
                </Box>
              }
          </Box>
        </Box>

        <Snackbar
          open={this.state.showSnackbar}
          message={this.state.snackbarText}
        />
      </Fragment>
    )
  }
}
