import React from "react"
import Paper from "../Paper"
import Box from "../Box"
import { List, ListItem, ListItemText } from "../lists"
import Button from "../buttons/Button"
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction"
import Link from "../Link"
import Typography from "@material-ui/core/Typography"
import IconButton from "@material-ui/core/IconButton"
import PictureAsPdf from "@material-ui/icons/PictureAsPdf"
import DescriptionIcon from '@material-ui/icons/Description';
import RecordVoiceOverIcon from '@material-ui/icons/RecordVoiceOver';

function SecFilings(props) {
  return(
    <React.Fragment>
      <Paper fullHeight>
        <Box height="100%">
          
          <Link href={props.company.ir_url} target="_blank">
            View investor relations page
          </Link>

          <Box overflow="auto" height="90%">
            <List>
              {props.sec_filings.map((secFiling) =>
                <ListItem key={secFiling.id} divider>

                  <ListItemText>
                    <Typography variant="caption" color="textSecondary">
                      {`Form ${secFiling.filing_type}`}
                    </Typography>
                    <Typography variant="body1">
                      <Link href={secFiling.url} target="_blank">
                        {secFiling.title}
                      </Link>
                    </Typography>
                    <Typography variant="caption" color="textSecondary">
                      {humanDateFormat(secFiling.date)}
                    </Typography>
                  </ListItemText>

                  <ListItemSecondaryAction>
                    {secFiling.pdf ? 
                      <IconButton href={`/sectors/${props.sector.abbr}/sec_filings/${secFiling.id}/view_pdf`} target="_blank" tooltip="View PDF">
                        <PictureAsPdf />
                      </IconButton>
                    : null}
                    {secFiling.press_release_url ? 
                      <IconButton href={secFiling.press_release_url} target="_blank" tooltip="View Press Release">
                        <DescriptionIcon />
                      </IconButton>
                    : null}
                    {secFiling.transcript_id ?
                      <IconButton href={`/sectors/${props.sector.abbr}/sec_filings/${secFiling.id}/view_transcript`} target="_blank" tooltip="View Transcript">
                        <RecordVoiceOverIcon />
                      </IconButton>
                    : null}
                  </ListItemSecondaryAction>
                </ListItem>
              )}
            </List>
          </Box>
        </Box>
      </Paper>
    </React.Fragment>
  )
}

export default SecFilings
