import React, { Component, Fragment } from "react"
import Paper from "../../Paper"
import Typography from "@material-ui/core/Typography"
import Flex from "../../Flex"
import Box from '../../Box';
import Snackbar from "../../Snackbar"
import update from "immutability-helper"
import { RaisedButton } from "../../buttons"
import { Tabs, Tab } from "../../tabs"


export default class Classifier extends Component {
  constructor(props) {
    super(props)
    this.handleActiveIndexUpdate = this.handleActiveIndexUpdate.bind(this)
    this.handleCheckboxSelect = this.handleCheckboxSelect.bind(this)
    this.handleAssociateClick = this.handleAssociateClick.bind(this)
    this.handleUndoClick = this.handleUndoClick.bind(this)
    this.handleCompleteClick = this.handleCompleteClick.bind(this)
    this.handleProductDeleteClick = this.handleProductDeleteClick.bind(this)
    this.handleTrialDeleteClick = this.handleTrialDeleteClick.bind(this)
    this.handleTrialSearchChange = this.handleTrialSearchChange.bind(this)
    this.handleTrialSearchClick = this.handleTrialSearchClick.bind(this)
    this.handlePredictedIndexUpdate = this.handlePredictedIndexUpdate.bind(this)
    this.state = { publications: this.props.publications, showSnackbar: false, snackbarText: '' }
  }


  handlePredictedIndexUpdate(e, newValue) {
    this.setState({
      predictedIndex: newValue
    })
  }

  handleTrialSearchChange(e) {
    if (e.target.value == '') {
      this.setState({
        trialSearch: '',
        searchedTrials: []
      })
    } else {
      this.setState({
        [e.target.name]: e.target.value
      })
    }
  }

  handleTrialSearchClick(e) {
    $.ajax({
      type: 'GET',
      url: `/admin/trials/search`,
      data: {
        q: this.state.trialSearch
      },
      dataType: 'json',
      success: (data) => {
        this.setState({
          searchedTrials: data.trials
        })
      }
    })
  }

  handleActiveIndexUpdate(e, newValue) {
    this.setState({
      activeIndex: newValue
    })
  }

  handleProductDeleteClick(publicationProduct) {
    $.ajax({
      type: "PUT",
      url: `/admin/publication_products/${publicationProduct.id}`,
      data: {
        publication_product: {
          deleted: true, deleted_by: this.props.current_user.id
        }
      },
      dataType: 'json',
      success: (data) => {
        let publicationProductIds = this.state.publicationProducts.map(publicationProduct => publicationProduct.id)
        let index = publicationProductIds.indexOf(data.publication_product.id)

        let publicationProducts = update(this.state.publicationProducts, {
          $splice: [[index, 1]]
        })
        this.setState({
          publicationProducts: publicationProducts,
          showSnackbar: true,
          snackbarText: 'Product has been removed'
        })
      },
      error: (data) => {
        this.setState({
          showSnackbar: true,
          snackbarText: 'There was an error removing the product'
        })
      },
      complete: (data) => {
        setTimeout(() => {
          this.setState({
            showSnackbar: false,
            snackbarText: ''
          })
        }, 4000)
      }
    })
  }

  handleTrialDeleteClick(publicationTrial) {
    $.ajax({
      type: "PUT",
      url: `/admin/publication_trials/${publicationTrial.id}`,
      data: {
        publication_trial: {
          deleted: true, deleted_by: this.props.current_user.id
        }
      },
      dataType: 'json',
      success: (data) => {
        let publicationTrialIds = this.state.publicationTrials.map(publicationTrial => publicationTrial.id)
        let index = publicationTrialIds.indexOf(data.publication_trial.id)

        let publicationTrials = update(this.state.publicationTrials, {
          $splice: [[index, 1]]
        })
        this.setState({
          publicationTrials: publicationTrials,
          showSnackbar: true,
          snackbarText: 'Trial has been removed'
        })
      },
      error: (data) => {
        this.setState({
          showSnackbar: true,
          snackbarText: 'There was an error removing the trial'
        })
      },
      complete: (data) => {
        setTimeout(() => {
          this.setState({
            showSnackbar: false,
            snackbarText: ''
          })
        }, 4000)
      }
    })
  }

  handleFindPublicationsClick(e) {
    $.get("/admin/publications/publications_for_associator", { kind: this.state.kind, priority: this.state.priority, time_since: this.state.timeSince }, (data) => {
      this.setState({
        publications: data.publications
      })
    }, 'json').fail((data) => {
      this.setState({
        showSnackbar: true,
        snackbarText: 'Publications could not be found. Please try again.',
        loading: false
      })
    }).always((data) => {
      this.setState({
        loading: false
      })
    })
  }

  handleCheckboxSelect(entity, entityType) {
    console.log(entity)
    const index = this.state[entityType].indexOf(entity.id)

    const activeEntities = index == -1 ? update(this.state[entityType], { $push: [entity.id] }) : update(this.state[entityType], { $splice: [[index, 1]] })

    this.setState({
      [entityType]: activeEntities
    })
  }

  handleAssociateClick(e) {
    $.post(`/admin/publications/${this.state.publication.id}/associate`, { product_ids: this.state.activeProducts, trial_ids: this.state.activeTrials, created_by: this.props.current_user.id }, (data) => {

      this.setState({
        activeProducts: [],
        activeTrials: [],
        publicationProducts: data.publication_products,
        publicationTrials: data.publication_trials,
        showSnackbar: true,
        snackbarText: 'Publication was associated'
      })
    }, 'json').fail((data) => {
      this.setState({
        showSnackbar: true,
        snackbarText: 'Publication could not be associated',
        associationLoading: false
      })
    }).always((data) => {
      setTimeout(() => {
        this.setState({
          showSnackbar: false,
          snackbarText: ''
        })
      }, 5000)
    })
  }

  handleCompleteClick(e) {
    $.ajax({
      type: 'POST',
      url: "/admin/manuscript_associator_statuses",
      data: {
        manuscript_associator_status: {
          manuscript_id: this.props.publication.id, status: true, user_id: this.props.current_user.id
        }
      },
      dataType: 'json',
      success: (data) => {
        this.setState({
          showSnackbar: true,
          snackbarText: 'Publication has been marked as completed',
          completed: true
        })
      },
      error: (data) => {
        this.setState({
          showSnackbar: true,
          snackbarText: 'There was an error. Please try again.'
        })
      },
      complete: (data) => {
        setTimeout(() => {
          this.setState({
            showSnackbar: false,
            snackbarText: ''
          })
        }, 4000)
      }
    })
  }

  handleUndoClick(e) {
    $.ajax({
      type: 'PUT',
      url: `/admin/publication_associations/${this.state.lastPublicationCompany.id}`,
      data: {
        patent_company: {
          deleted: true, deleted_by: this.props.current_user.id
        }
      },
      dataType: 'json',
      success: (data) => {
        const publications = update(this.state.publications, {
          $push: [this.state.lastPublication]
        })
        this.setState({
          publications: publications,
          showSnackbar: true,
          snackbarText: 'Publication association was undone',
          lastPublicationCompany: null,
          lastPublication: null
        })
      },
      error: (data) => {
        this.setState({
          showSnackbar: true,
          snackbarText: 'Publication association could not be undone',
          associationLoading: false
        })
      },
      complete: (data) => {
        setTimeout(() => {
          this.setState({
            showSnackbar: false,
            snackbarText: ''
          })
        }, 5000)
      }
    })
  }

  render() {
    // let productSearchWords = this.props.predicted_products ? this.props.predicted_products.map(predictedProduct => predictedProduct.product ? predictedProduct.product.short_name : null) : null
    //
    // productSearchWords = productSearchWords == null ? this.props.search_terms : [...productSearchWords, ...this.props.search_terms]
    //
    // let trialSearchWords = this.props.predicted_trials ? this.props.predicted_trials.map(predictedTrial => predictedTrial.acronym ? predictedTrial.acronym : null) : null
    //
    //
    // let searchWords = this.state.predictedIndex == 0 ? productSearchWords : trialSearchWords

    return(
      <Fragment>

        <Box mb={4}>
          <Paper>
            <Tabs fullWidth value={2}>
              {this.props.sectors.map((sector) =>
                <Tab key={sector.id} value={sector.id} label={sector.abbr} />
              )}
            </Tabs>
          </Paper>
        </Box>

        <Box>
          <Flex alignItems='center'>
            <Box>
              Previous arrow
            </Box>

            <Box>
              <Paper fullHeight>
                <Box className='bb b--black-20'>
                  <Typography variant="h6">
                    {this.props.publications[0].title}
                  </Typography>
                </Box>

                <Box overflow='auto' height="70%" my={4}>
                  <Box width={2/3} mx='auto'>
                    {this.props.publications[0].text}
                  </Box>
                </Box>

                {/* <Flex>
                  <Box>
                    <Typography variant="body2">
                      {this.props.publications[0].mesh_terms}
                    </Typography>
                  </Box>
                </Flex> */}

                <Box>
                  <Flex justifyContent='center'>
                    <Box mr={3}>
                      <RaisedButton color='primary'>
                        Relevant
                      </RaisedButton>
                    </Box>

                    <Box>
                      <RaisedButton color='error'>
                        Not Relevant
                      </RaisedButton>
                    </Box>
                  </Flex>
                </Box>
              </Paper>
            </Box>

            <Box>
              Next Arrow
            </Box>
          </Flex>
        </Box>

        <Snackbar
          open={this.state.showSnackbar}
          handleUndoClick={this.handleUndoClick}
          message={this.state.snackbarText}
        />
      </Fragment>
    )
  }
}
