import React, { Component, Fragment } from "react"
import CKEditor from 'ckeditor4-react';
import Box from "../../Box"
import Radio from '@material-ui/core/Radio';
import RadioGroup from "../../RadioGroup"
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Flex from "../../Flex"
import { RaisedButton } from "../../buttons"


export default class CriteriaForm extends Component {
  constructor(props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
    this.handleCriteriaSaveClick = this.handleCriteriaSaveClick.bind(this)
    this.handleCriteriaChange = this.handleCriteriaChange.bind(this)
    this.handleCriteriaDeleteClick = this.handleCriteriaDeleteClick.bind(this)
    this.state = { criteria_name: 'inclusion_criteria', criteria: '' }
  }

  componentDidMount() {
    if (this.props.trialCriterias) {

      let trialCriteria = this.props.trialCriterias.filter(trialCriteria => trialCriteria.criteria_name == 'inclusion_criteria')[0]

      if (trialCriteria) {
        this.setState({
          criteria: trialCriteria.criteria_value
        })
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.trialCriterias != nextProps.trialCriterias) {

      let trialCriteria = nextProps.trialCriterias.filter(trialCriteria => trialCriteria.criteria_name == 'inclusion_criteria')[0]

      if (trialCriteria) {
        this.setState({
          criteria: trialCriteria.criteria_value
        })
      } else {
        this.setState({
          criteria: ''
        })
      }
    }
  }

  handleCriteriaChange(e) {
    const text = e.editor.getData()

    this.setState({
      criteria: text
    })
  }

  handleCriteriaDeleteClick(e) {
    this.props.handleCriteriaDeleteClick(this.state.criteria_name)
  }

  handleCriteriaSaveClick(e) {
    this.props.handleCriteriaSaveClick(this.state.criteria_name, this.state.criteria)
  }

  handleChange(e) {
    let value = e.target.value

    let trialCriteria = this.props.trialCriterias.filter(trialCriteria => trialCriteria.criteria_name == value)[0]

    this.setState({
      [e.target.name]: e.target.value,
      criteria: trialCriteria ? trialCriteria.criteria_value : ''
    })
  }

  render() {
    return(
      <Fragment>
        <Box mt={2} mb={6}>

          <Flex justifyContent='center' mb={2}>
            <RadioGroup aria-label="criteria-name" name="criteria_name" value={this.state.criteria_name} onChange={this.handleChange}>
              <FormControlLabel value='inclusion_criteria' control={<Radio />} label="Inclusion criteria" />
              <FormControlLabel value='exclusion_criteria' control={<Radio />} label="Exclusion criteria" />
            </RadioGroup>
          </Flex>

          <Box mb={2}>
            <CKEditor
              data={this.state.criteria}
              onChange={this.handleCriteriaChange}
            />
          </Box>

          <Box mt={5}>
            <RaisedButton handleClick={this.handleCriteriaSaveClick}>
              {`Save ${this.state.criteria_name.replace("_", " ")}`}
            </RaisedButton>
          </Box>

          {this.props.currentUser.id == 3 ?
            <Box mt={5} color='error.main'>
              <RaisedButton color='inherit' handleClick={this.handleCriteriaDeleteClick}>
                {`Delete ${this.state.criteria_name.replace("_", " ")}`}
              </RaisedButton>
            </Box>
          : ''}
        </Box>
      </Fragment>
    )
  }
}
