import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Chart from "../../charts/Chart"
import Timeline from "../../charts/Timeline"

export default function TimelineChartContainer(props) {

  function formatData() {
    let colors = ["#7cb5ec", "#434348", "#90ed7d", "#f7a35c", "#8085e9", "#f15c80", "#e4d354", "#2b908f", "#f45b5b", "#91e8e1"]
    let groupedChartData = groupBy(props.chartData, "id")
    let chartDataArray = []
    Object.keys(groupedChartData).map((chartDataKey, index) => {
      let color = colors[index]
      let chartDataValues = groupedChartData[chartDataKey]
      chartDataValues.map((chartData) => {
        let date = parseFloat(chartData.date)
        chartDataArray.push({ x: date, name: chartData.value, label: chartData.value, description: chartData.description ? chartData.description : "N/A", color: color })
      })

      return chartDataArray
    })
    // const chartData = props.chartData.map((chartData) => {
    //   let date = parseFloat(chartData.date)
    //   return({ x: date, name: chartData.value, label: chartData.value, description: chartData.description ? chartData.description : "N/A" })
    // })

    return(chartDataArray)
  }

  return (
    <Timeline
      chartId={props.chartId}
      chartOptions={{type: 'timeline', height: 300 }}
      title={props.title}
      formattedData={formatData()}
      yAxisVisible={false}
      xAxisVisible={false}
      // xAxisMax={props.currentTime}
      tooltip={{headerFormat: '<span>{point.label}</span><br/>', pointFormat: '<span style="color:{point.color}">\u25CF</span><span style="font-size:14px;margin-left:4px"><b>{point.label}</b></span><br/>', footerFormat: '<span style="font-size:11px">Source: {point.description}</span><br/>'}}
    />
  )
}
