import React, { Fragment, useState } from "react"
import Paper from "../Paper"
import Box from "../Box"
import { Table, TableHead, TableRow, TableBody, TableCell } from "../tables"
import TableHeadSort from "../tables/TableHeadSort"
import Link from "../Link"

export default function People(props) {
  const [order, setOrder] = useState('asc')
  const [orderBy, setOrderBy] = useState('text')

  function desc(a, b, orderBy) {
    let bOrderKey = b[orderBy]
    let aOrderKey = a[orderBy]

    if (bOrderKey < aOrderKey) {
      return -1;
    }
    if (bOrderKey > aOrderKey) {
      return 1;
    }
    return 0;
  }

  function stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = cmp(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
  }

  function getSorting(order, orderBy) {
    return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
  }

  function handleRequestSort(event, property) {
    const isDesc = orderBy === property && order === 'desc';

    setOrder(isDesc ? 'asc' : 'desc')
    setOrderBy(property)
  };

  let headers = [{ label: 'Person', name: 'text' }, { label: '# of studies', name: 'trial_count' }]
  return(
    <Paper fullHeight>
      <Box height="100%">

        <Box textAlign='right'>
          {`${props.people.length} people`}
        </Box>

        <Box overflow="auto" height="90%">
          <Table size='small'>

            <TableHeadSort
              headers={headers}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />

            <TableBody>
              {stableSort(props.people, getSorting(order, orderBy)).map((person) =>
                <TableRow key={person.id}>
                  <TableCell>
                    <Link href={`/sectors/${props.sector.abbr}/people/${person.id}`}>
                      {person.text}
                    </Link>
                  </TableCell>

                  <TableCell>
                    {person.trial_count}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </Box>
      </Box>
    </Paper>
  )
}
