import React from "react"
import Paper from "../../Paper"
import Box from "../../Box"
import Tabs from "../../tabs/Tabs"
import Tab from "../../tabs/Tab"
import TabPanel from "../../tabs/TabPanel"
import Badge from "@material-ui/core/Badge"


export default function EntitiesPanel(props) {
  return (
    <React.Fragment>
      <Paper fullHeight>

        <Box height="100%">
          <Tabs variant="fullWidth" value={props.activeIndex} onChange={props.handleActiveIndexUpdate} className='bb b--black-10' aria-label="entities tabs">
            <Tab label='Review' />
            <Tab label='Products' />
            <Tab label='Trials' />
          </Tabs>

          <Box mt={3} height="90%" className='overflow-auto'>
            <TabPanel value={props.activeIndex} index={0}>
              {props.associated}
            </TabPanel>
            <TabPanel value={props.activeIndex} index={1}>
              {props.products}
            </TabPanel>
            <TabPanel value={props.activeIndex} index={2}>
              {props.trials}
            </TabPanel>
          </Box>
        </Box>

      </Paper>
    </React.Fragment>
  )
}
