import React from "react"
import TargetSiteListItem from "./TargetSiteListItem"

export default function TargetSiteListItemContainer(props) {
  
  let productTargetSite = ""
  
  if (props.activeRegion == 1000000) {
    productTargetSite = props.productTargetSites.filter(productTargetSite => productTargetSite.target_site_id == props.targetSite.id && productTargetSite.investigational)[0]
  } else {
    productTargetSite = props.productTargetSites.filter(productTargetSite => productTargetSite.target_site_id == props.targetSite.id && productTargetSite.world_region_id == props.activeRegion)[0]
  }
  
  let checked = false
  
  if (productTargetSite) {
    if (props.activeRegion == 1000000) {
      checked = productTargetSite.investigational ? true : false
    } else {
      checked = productTargetSite.world_region_id == props.activeRegion ? true : false
    }
  }
  
  return (
    <React.Fragment>
      <TargetSiteListItem 
        targetSite={props.targetSite}
        productTargetSite={productTargetSite}
        activeRegion={props.activeRegion}
        checked={checked}
        handleCheckboxSelect={props.handleCheckboxSelect}
        handleCheckboxDelete={props.handleCheckboxDelete}
      />
    </React.Fragment>
  )
}
