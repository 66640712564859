import React, { Component, Fragment } from "react"
import Paper from "../Paper"
import Flex from "../Flex"
import Box from "../Box"
import Typography from "@material-ui/core/Typography"
import { withSnackbar } from "../SnackbarContainer"
import update from "immutability-helper"
import { withRouter } from "react-router-dom";
import FiltersContainer from "./FiltersContainer"
import IndexList from "./IndexList"
import Select from "../Select"
import LinearProgress from '@material-ui/core/LinearProgress';

class IndexDisplay extends Component {
  constructor(props) {
    super(props)
    this.handleFilterChange = this.handleFilterChange.bind(this)
    this.getPatents = this.getPatents.bind(this)
    this.handleDelete = this.handleDelete.bind(this)
    this.handleRequestSort = this.handleRequestSort.bind(this)
    this.orderPatents = this.orderPatents.bind(this)
    this.handleChangePage = this.handleChangePage.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleSearchClick = this.handleSearchClick.bind(this)
    this.handleClearSearchClick = this.handleClearSearchClick.bind(this)
    this.handleCsvClick = this.handleCsvClick.bind(this)
    this.state = { patents: this.props.patents, patentsCount: this.props.patents_count, loading: false, classification: this.props.classification, chartData: this.props.chart_data, categories: this.props.categories, companyData: this.props.company_data, filterOptions: { companies: this.props.companies, years: this.props.years, kinds: this.props.kinds, orderOptions: this.props.order_options }, filterValues: { company_id: this.props.company_id, order: 'desc', order_by: this.props.order_by, page: this.props.page, query: this.props.query, year: this.props.year, kind: this.props.kind }, loading: false }
  }

  handleChange(e) {
    let filterValues = update(this.state.filterValues, {
      query: { $set: e.target.value }
    })

    this.setState({
      filterValues: filterValues
    })
  }

  handleClearSearchClick(e) {
    let params = Object.assign({}, this.state.filterValues)

    params["query"] = null
    params["order_by"] = "date"

    this.getPatents(params)
  }

  handleSearchClick(query) {
    let params = Object.assign({}, this.state.filterValues)

    params["query"] = query
    params["order_by"] = "_score"

    this.getPatents(params)
  }

  handleRequestSort(name, value) {
    let params = Object.assign({}, this.state.filterValues)

    params["order_by"] = value

    let isDesc = this.state.filterValues.order_by === value && this.state.filterValues.order === 'desc';

    params["order"] = isDesc ? 'asc' : 'desc'

    this.getPatents(params)
  };

  orderPatents(params) {
    $.ajax({
      type: "GET",
      url: this.props.url,
      data: params,
      dataType: 'json',
      success: (data) => {
        this.setState({
          patents: data.patents,
          patentsCount: data.patents_count,
          filterValues: params
        })
      },
      error: (data) => {
        this.props.snackbarShowMessage("There was an issue ordering the patents. Please try again")
      }
    })
  }

  handleFilterChange(name, value) {
    let params = Object.assign({}, this.state.filterValues)

    params[name] = value

    this.getPatents(params)
  }

  handleDelete(value, type) {
    const index = this.state.filterValues[type].indexOf(value)

    const filterValues = update(this.state.filterValues, {
      [type]: { $splice: [[index, 1]] }
    })

    this.getPatents(filterValues)
  }

  handleChangePage(e, newPage) {
    let params = Object.assign({}, this.state.filterValues)

    params["order_by"] = this.state.order_by
    params["order"] = this.state.order
    params["page"] = newPage

    this.getPatents(params)
  }

  getPatents(params) {
    $.ajax({
      type: 'GET',
      url: this.props.url,
      data: params,
      dataType: 'json',
      beforeSend: (smthing) => {
        this.setState({
          loading: true
        })
      },
      success: (data) => {
        let filterOptions = { companies: data.companies, years: data.years, orderOptions: data.order_options, kinds: data.kinds }

        let url = this.props.url

        let filterKeys = Object.keys(params)

        let queryParams = filterKeys.map((filterKey) => params[filterKey] ? `${filterKey}=${params[filterKey]}` : null).filter(param => param)

        let flattenedQueryParams = [].concat.apply([], queryParams);
        // this.props.match.path
        this.props.history.push(`${url}?${flattenedQueryParams.join("&")}`);

        this.setState({
          patents: data.patents,
          patentsCount: data.patents_count,
          filterValues: params,
          loading: false,
          chartData: data.chart_data,
          categories: data.categories,
          filterOptions
        })
      },
      error: (data) => {
        this.setState({
          showSnackbar: true,
          snackbarText: 'There was a problem getting patents',
          loading: false
        })
      },
      complete: (data) => {
        setTimeout(() => {
          this.setState({
            showSnackbar: false,
            snackbarText: ''
          })
        }, 5000)
      }
    })
  }

  handleCsvClick(event, done) {
    $.get(`/sectors/${this.props.sector.abbr}/log_csv_download?for=patents`).then(() => {
      done(); // REQUIRED to invoke the logic of component
    });
  }

  render() {
    return(
      <Fragment>

        <Paper fullHeight>

          <Box height="100%">
            <Box>
              <FiltersContainer
                query={this.state.filterValues.query}
                classification={this.state.classification}
                handleSearchClick={this.handleSearchClick}
                handleClearSearchClick={this.handleClearSearchClick}
              />
            </Box>

            <Flex>

              <Box ml='auto'>
                <Typography variant="body2">
                  {`${this.state.patentsCount} patents`}
                </Typography>
              </Box>
            </Flex>

            <Flex>
              {this.state.filterOptions.companies ? 
                <Box width={1/5} mr={2}>
                  <Select
                    label='Companies'
                    name='company_id'
                    includeBlank
                    value={this.state.filterValues.company_id}
                    entities={this.state.filterOptions.companies}
                    handleChange={this.handleFilterChange}
                  />
                </Box>
              : ""}
              <Box width={1/5} mr={2}>
                <Select
                  label='Kind'
                  name='kind'
                  includeBlank
                  value={this.state.filterValues.kind}
                  entities={this.state.filterOptions.kinds}
                  handleChange={this.handleFilterChange}
                />
              </Box>
              <Box width={1/5} mr={2}>
                <Select
                  label='Year'
                  name='year'
                  includeBlank
                  value={this.state.filterValues.year}
                  entities={this.state.filterOptions.years}
                  handleChange={this.handleFilterChange}
                />
              </Box>
              <Box width={1/5}>
                <Select
                  label='Order'
                  name='order_by'
                  value={this.state.filterValues.order_by}
                  entities={this.state.filterOptions.orderOptions}
                  handleChange={this.handleRequestSort}
                />
              </Box>
            </Flex>

            <Box height="85%" overflow="auto">
              <Flex>
                <Box>
                  {this.state.loading ?
                    <Box>
                      <LinearProgress />
                    </Box>
                  : ""}
                  <IndexList
                    key={this.state.loading}
                    patents={this.state.patents}
                    patentsCount={this.state.patentsCount}
                    page={this.state.filterValues.page}
                    handleChangePage={this.handleChangePage}
                    handleRequestSort={this.handleRequestSort}
                    sector={this.props.sector}
                    loading={this.state.loading}
                  />
                </Box>

                {/* <Box width={1/4}>
                  <Paper elevation={3}>
                    <Flex flexDirection="column">
                      <Box mb={6}>
                        <Chart
                          // key={`product-adverse-events-chart-${props.chartReloadKey}`}
                          chartId="yearly-patents-chart"
                          chartOptions={{ type: 'column', height: '75%' }}
                          plotOptions={{ column: { dataLabels: { enabled: true } } }}
                          exportingChartOptions={{ credits: { enabled: true, text: '\u00A9 MarketMonitors' } }}
                          // legend={true}
                          data={this.state.chartData}
                          categories={this.state.categories}
                          title="Yearly patents"
                          // subtitle="2015 - 2022 YTD     |     Source: FDA MAUDE"
                          yAxisTitle='# of patents'
                          xAxisTitle='Year'
                          simple={true}
                        />
                      </Box>

                      <Box>
                        <Table size='small'>
                          <TableBody>
                            {this.state.companyData.map((companyData) =>
                              <TableRow key={companyData.name}>
                                <TableCell>
                                  {companyData.name}
                                </TableCell>
                                <TableCell>
                                  {companyData.count / companyData.total}
                                </TableCell>
                              </TableRow>
                            )}
                          </TableBody>
                        </Table>
                      </Box>
                    </Flex>
                  </Paper>
                </Box> */}
              </Flex>
            </Box>
          </Box>
        </Paper>
      </Fragment>
    )
  }
}

export default withRouter(withSnackbar(IndexDisplay))
