import React, { Component } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Paper from "./Paper"

class ExpansionPanelContainer extends Component {
  constructor(props) {
    super(props);
  }
  
  render () {
    return(
      <Paper withPadding={this.props.withPadding} className={this.props.className}>
        {this.props.children}
      </Paper>
    );
  }
}

ExpansionPanelContainer.propTypes = {
  withPadding: PropTypes.bool,
  className: PropTypes.string
}

ExpansionPanelContainer.defaultProps = {
  withPadding: true
}

export default ExpansionPanelContainer
