import React from 'react'
import { TableCell, TableRow } from '../tables'
import Link from "../Link"

export default function Row(props) {

  function handleDeleteClick(e) {
    props.handleDeleteClick(props.approval)
  }

  function handleApprovalCheckboxClick(e) {
    props.handleApprovalCheckboxClick(props.approval)
  }

  function handleReviewedClick(e) {
    props.handleReviewedClick(props.approval)
  }

  return (
    <TableRow>
      <TableCell>
        {props.approval.agency_acronym || props.approval.search_details ? `${props.approval.agency_acronym} (${props.approval.country_name})` : props.approval.country_name}
      </TableCell>
      <TableCell>
        <Link href={`/sectors/${props.sector.abbr}/device_approvals/${props.approval.id}`} target="_blank">
          {props.approval.device_name}
        </Link>
      </TableCell>
      <TableCell>{props.approval.manufacturer}</TableCell>
      <TableCell>{props.approval.date || props.approval.approval_date}</TableCell>
    </TableRow>
  )
}
