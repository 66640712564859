import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Paper from "../Paper"
import Grid from '@material-ui/core/Grid';
import Flex from "../Flex"
import Box from "../Box"
import StatSection from "../dashboard/StatSection"
import Typography from "@material-ui/core/Typography"
import Link from "../Link"
import { List, ListItem, ListItemText } from "../lists"
import { Table, TableRow, TableCell, TableHead, TableBody } from "../tables"
import Chart from "../charts/Chart"
import Filters from "./Filters"
import FilterChipsContainer from "./FilterChipsContainer"
import update from "immutability-helper"
import { withRouter } from "react-router-dom";

class Dashboard extends Component {
  constructor(props) {
    super(props)
    this.handleFilterChange = this.handleFilterChange.bind(this)
    this.handleDelete = this.handleDelete.bind(this)
    this.getDashboardData = this.getDashboardData.bind(this)
    this.state = { totalCompanies: this.props.total_companies, newCompanies: this.props.new_companies, publicCompanies: this.props.public_companies, privateCompanies: this.props.private_companies, companyProductsChartData: this.props.num_products_chart_data, companyProductsChartCategories: this.props.num_products_chart_categories, topSponsors: this.props.top_sponsors, filterOptions: { companies: this.props.companies, methodGroups: this.props.method_groups, targetMarkets: this.props.target_markets, targetSites: this.props.target_sites, therapyTypes: this.props.therapy_types, technologyGroups: this.props.technology_groups, regulatoryStatuses: this.props.regulatory_statuses, collections: this.props.collections, characteristicGroups: this.props.characteristic_groups, characteristicValues: this.props.characteristic_values }, filterValues: { company_ids: [], target_site_ids: [], target_market_ids: [], therapy_type_ids: [], technology_group_ids: [], regulatory_status_ids: [], collection_ids: [] }, chosen: { therapyTypes: this.props.chosen_therapy_types, targetMarkets: this.props.chosen_target_markets, regulatoryStatuses: this.props.chosen_regulatory_statuses, targetSites: this.props.chosen_target_sites, companies: this.props.chosen_companies, collections: this.props.chosen_collections } }
  }

  // componentDidMount() {
  //   let filterKeys = Object.keys(this.props.filter_values)
  //   let filterValueHash = {}
  //
  //   let parsedFilterValues = filterKeys.map((filterKey) =>
  //     this.props.filter_values[filterKey] == null ? filterValueHash[filterKey] = [] : filterValueHash[filterKey] = this.props.filter_values[filterKey].map((value) => parseInt(value))
  //   )
  //
  //   let filterValues = update(this.state.filterValues, {
  //     $set: filterValueHash
  //   })
  //
  //   this.setState({
  //     filterValues: filterValues
  //   })
  // }

  handleFilterChange(name, value) {
    let params = Object.assign({}, this.state.filterValues)

    if (value.includes(null)) {
      params[name] = []
    } else {
      params[name] = value
    }

    if (name == 'characteristic_group_id') {
      params['characteristic_value_id'] = ''
    }

    this.getDashboardData(params)
  }

  handleDelete(value, type) {
    const index = this.state.filterValues[type].indexOf(value)

    const filterValues = update(this.state.filterValues, {
      [type]: { $splice: [[index, 1]] }
    })

    this.getDashboardData(filterValues)
  }

  getDashboardData(params) {
    let url = `/sectors/${this.props.sector.abbr}/products/dashboard`
    $.ajax({
      type: 'GET',
      url: `/sectors/${this.props.sector.abbr}/products/dashboard`,
      data: params,
      dataType: 'json',
      success: (data) => {
        let filterOptions = { companies: data.companies, targetSites: data.target_sites, targetMarkets: data.target_markets, therapyTypes: data.therapy_types, technologyGroups: data.technology_groups, regulatoryStatuses: data.regulatory_statuses, collections: data.collections }

        let chosen = { therapyTypes: data.chosen_therapy_types, targetMarkets: data.chosen_target_markets, targetSites: data.chosen_target_sites, companies: data.chosen_companies, collections: data.chosen_collections, regulatoryStatuses: data.chosen_regulatory_statuses }

        let filterKeys = Object.keys(params)
        let queryParams = filterKeys.map((filterKey) => params[filterKey].length > 0 ? params[filterKey].map((filterValue) => `${filterKey}[]=${filterValue}`) : null).filter(param => param)

        let flattenedQueryParams = [].concat.apply([], queryParams);
        // this.props.match.path
        this.props.history.push(`${url}?${flattenedQueryParams.join("&")}`);

        this.setState({
          trialsChartData: data.trials_chart_data,
          trialCategories: data.trial_categories,
          productsChartData: data.products_chart_data,
          productCategories: data.product_categories,
          productsByStatus: data.products_by_status,
          topTrialProducts: data.top_trial_products,
          topPublicationProducts: data.top_publication_products,
          topPresentationProducts: data.top_presentation_products,
          productTargetSiteChartData: data.product_target_site_chart_data,
          productTargetSiteCategories: data.product_target_site_categories,
          productTargetMarketChartData: data.product_target_market_chart_data,
          productTargetMarketCategories: data.product_target_market_categories,
          trialTypes: data.trial_types,
          presentationChartData: data.presentation_chart_data,
          presentationCategories: data.presentation_categories,
          publicationsChartData: data.publications_chart_data,
          publicationCategories: data.publication_categories,
          fdaChartData: data.fda_chart_data,
          fdaChartCategories: data.fda_chart_categories,
          totalProducts: data.total_products,
          newProducts: data.new_products,
          totalStudies: data.total_studies,
          newStudies: data.new_studies,
          filterValues: params,
          chosen,
          filterOptions
        })
      },
      error: (data) => {
        console.log(data.responseText)
      }
    })
  }

  render() {
    const chartReloadKey = Math.random()
    console.log(this.props.companies)
    return(
      <Fragment>

        {/* <Grid container spacing={3} justifyContent="center" component={Box} pb={4}>

          <Grid item xs={12}>
            <Paper>
              <Box>
                <Filters
                  filterOptions={this.state.filterOptions}
                  filterValues={this.state.filterValues}
                  sector={this.props.sector}
                  handleFilterChange={this.handleFilterChange}
                />
              </Box>

              <Box mt={2}>
                <FilterChipsContainer
                  filterOptions={this.state.filterOptions}
                  filterValues={this.state.filterValues}
                  chosen={this.state.chosen}
                  handleDelete={this.handleDelete}
                />
              </Box>
            </Paper>
          </Grid>
        </Grid> */}

        <Grid container spacing={3} alignItems='stretch' component={Box} pb={3}>
          <Grid item xs={3}>
            <StatSection
              statContent={this.state.totalCompanies}
              statDetail='Companies'
              secondaryContent='In total'
            />
          </Grid>

          <Grid item xs={3}>
            <StatSection
              statContent={this.state.newCompanies}
              statDetail='New Products'
              secondaryContent='This month'
            />
          </Grid>

          <Grid item xs={3}>
            <StatSection
              statContent={this.state.publicCompanies}
              statDetail='Public'
              secondaryContent='In total'
            />
          </Grid>

          <Grid item xs={3}>
            <StatSection
              statContent={this.state.privateCompanies}
              statDetail='Private'
              secondaryContent='In total'
            />
          </Grid>
        </Grid>

        <Grid container spacing={3} alignItems='stretch' component={Box} pb={3}>
          <Grid item xs={8}>
            <Paper>
              <Typography variant="h6">
                Companies with the most products
              </Typography>

              <Box mt={2}>
                <Chart
                  key={`company-products-count-${chartReloadKey}`}
                  chartId='company-products-count-chart'
                  chartOptions={{ type: 'column', height: '35%' }}
                  exportingChartOptions={{ credits: { enabled: true, text: '\u00A9 pvpipeline' } }}
                  legend={true}
                  data={this.state.companyProductsChartData}
                  categories={this.state.companyProductsChartCategories}
                  title=''
                  yAxisTitle='# of products'
                  xAxisTitle='Company'
                  simple={true}
                />
              </Box>
            </Paper>
          </Grid>

          {/* <Grid item xs={4}>
            <Paper className='h-100'>
              <Typography variant="h6">
                Products by status
              </Typography>

              <Box mt={2}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Type</TableCell>
                      <TableCell align='right'># of Products</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Object.keys(this.state.productsByStatus).map((regultoryStatus) =>
                      <TableRow key={regultoryStatus}>
                        <TableCell>
                          {regultoryStatus}
                        </TableCell>
                        <TableCell align='right'>
                          {this.state.productsByStatus[regultoryStatus]}
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </Box>
            </Paper>
          </Grid> */}
        </Grid>

        <Grid container spacing={3} component={Box} pb={3} justifyContent='center'>
          <Grid item xs={8}>
            <Paper>
              <Typography variant="h6">
                Trending Companies
              </Typography>

              <Box mt={2}>
                SOME CHART HERE
              </Box>
            </Paper>
          </Grid>
        </Grid>

        <Grid container spacing={3} alignItems='stretch' component={Box} pb={3}>
          <Grid item xs={4}>
            <Paper className='h-100'>
              <Typography variant="h6">
                Top sponsors
              </Typography>

              <Box mt={2}>
                <Table>
                  <TableHead>
                    <TableRow hover={false}>
                      <TableCell>Company</TableCell>
                      <TableCell align='right'># of trials</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Object.keys(this.state.topSponsors).map((companyName) =>
                      <TableRow key={companyName}>
                        <TableCell>
                          <Link href={`/sectors/${this.props.sector.abbr}/companies/${this.props.companies[companyName][0].id}`}>
                            <Box>
                              {companyName}
                            </Box>
                          </Link>
                        </TableCell>
                        <TableCell align='right'>
                          {this.state.topSponsors[companyName]}
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </Box>
            </Paper>
          </Grid>

          {/* <Grid item xs={4}>
            <Paper className='h-100'>
              <Typography variant="h6">
                Top products by publication
              </Typography>

              <Box mt={2}>
                <Table>
                  <TableHead>
                    <TableRow hover={false}>
                      <TableCell>Product</TableCell>
                      <TableCell align='right'># of publications</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Object.keys(this.state.topPublicationProducts).map((product) =>
                      <TableRow key={product}>
                        <TableCell>
                          {product}
                        </TableCell>
                        <TableCell align='right'>
                          {this.state.topPublicationProducts[product]}
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </Box>
            </Paper>
          </Grid>

          <Grid item xs={4}>
            <Paper className='h-100'>
              <Typography variant="h6">
                Top products by presentations
              </Typography>

              <Box mt={2}>
                <Table>
                  <TableHead>
                    <TableRow hover={false}>
                      <TableCell>Product</TableCell>
                      <TableCell align='right'># of presentations</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Object.keys(this.state.topPresentationProducts).map((product) =>
                      <TableRow key={product}>
                        <TableCell>
                          {product}
                        </TableCell>
                        <TableCell align='right'>
                          {this.state.topPresentationProducts[product]}
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </Box>
            </Paper>
          </Grid> */}
        </Grid>

        {/* <Grid container spacing={3} alignItems='stretch' component={Box} pb={3}>
          <Grid item xs={6}>
            <Paper>
              <Typography variant="h6">
                Product target sites
              </Typography>

              <Box mt={2}>
                <Chart
                  key={`target-sites-chart-${chartReloadKey}`}
                  chartId='target-sites-count-chart'
                  chartOptions={{ type: 'pie', height: '35%' }}
                  exportingChartOptions={{ credits: { enabled: true, text: '\u00A9 pvpipeline' } }}
                  legend={true}
                  data={this.state.productTargetSiteChartData}
                  categories={this.state.productTargetSiteCategories}
                  title=''
                  simple={true}
                  yAxisTitle='% of products'
                  tooltip={{pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'}}
                />
              </Box>
            </Paper>
          </Grid>

          <Grid item xs={6}>
            <Paper>
              <Typography variant="h6">
                Product target markets
              </Typography>

              <Box mt={2}>
                <Chart
                  key={`target-market-chart-${chartReloadKey}`}
                  chartId='target-market-count-chart'
                  chartOptions={{ type: 'pie', height: '35%' }}
                  exportingChartOptions={{ credits: { enabled: true, text: '\u00A9 pvpipeline' } }}
                  legend={true}
                  data={this.state.productTargetMarketChartData}
                  categories={this.state.productTargetMarketCategories}
                  title=''
                  simple={true}
                />
              </Box>
            </Paper>
          </Grid>
        </Grid> */}

      </Fragment>
    )
  }
}

export default withRouter(Dashboard)
