import React, { Component, Fragment } from "react"
import Paper from "../../../Paper"
import Box from "../../../Box"
import Typography from "@material-ui/core/Typography"
import { withSnackbar } from "../../../SnackbarContainer"
import Form from "./Form"
import AcquiredCompaniesList from "./AcquiredCompaniesList"
import update from "immutability-helper"


class FamilyContainer extends Component {
  constructor(props) {
    super(props)
    this.handleCompanyChange = this.handleCompanyChange.bind(this)
    this.handleDeleteClick = this.handleDeleteClick.bind(this)
    this.handleSaveClick = this.handleSaveClick.bind(this)
    this.handleSectorChange = this.handleSectorChange.bind(this)
    this.handleDateChange = this.handleDateChange.bind(this)
    this.state = { companyFamilies: this.props.company_families, childCompanyId: null, sectorIds: [], date: null }
  }

  handleSaveClick(e) {
    $.ajax({
      type: 'POST',
      url: `/admin/companies/${this.props.company.id}/acquisitions/new_acquisition`,
      data: {
        parent_company_id: this.props.company.id, child_company_id: this.state.childCompanyId, sector_ids: this.state.sectorIds, date: this.state.date
      },
      dataType: 'json',
      success: (data) => {
        let index = this.state.companyFamilies.length
        let companyFamilies = update(this.state.companyFamilies, {
          $merge: {
            [index]: {
              id: data.company_family.id, parent_company_id: this.props.company.id, child_product_id: data.child_company.id, short_name: data.child_company.short_name
            }
          }
        })

        this.setState({
          companyFamilies: companyFamilies,
          sectorIds: [],
          childCompanyId: null,
          date: null
        })

        this.props.snackbarShowMessage("Acquired company has been added")
      },
      error: (data) => {
        this.props.snackbarShowMessage("There was an issue adding the company as acquired. Please try again", "error")
      }
    })
  }

  handleCompanyChange(e, optionHash, reason) {
    this.setState({
      childCompanyId: optionHash.value
    })
  }

  handleSectorChange(name, value) {
    let sectorIds = update(this.state.sectorIds, {
      $push: value
    })

    this.setState({
      sectorIds: sectorIds
    })
  }

  handleDateChange(e) {
    this.setState({
      date: e.target.value
    })
  }

  handleDeleteClick(acquiredCompany) {
    $.ajax({
      type: 'PUT',
      url: `/admin/companies/${this.props.company.id}/acquisitions/${acquiredCompany.id}`,
      data: {
        company_family: {
          deleted: true, deleted_by: this.props.current_user.id
        }
      },
      dataType: 'json',
      success: (data) => {
        let index = this.state.companyFamilies.indexOf(acquiredCompany)
        let companyFamilies = update(this.state.companyFamilies, {
          $splice: [[index, 1]]
        })

        this.setState({
          companyFamilies: companyFamilies
        })

        this.props.snackbarShowMessage("Company has been removed")
      },
      error: (data) => {
        this.props.snackbarShowMessage("There was an issue removing the company. Please try again", "error")
      }
    })
  }

  render() {
    return(
      <Paper minFullHeight>
        <Form
          companies={this.props.companies}
          sectors={this.props.sectors}
          sectorIds={this.state.sectorIds}
          date={this.state.date}
          handleCompanyChange={this.handleCompanyChange}
          handleSaveClick={this.handleSaveClick}
          handleSectorChange={this.handleSectorChange}
          handleDateChange={this.handleDateChange}
        />

        <Box mt={3}>
          <AcquiredCompaniesList
            companyFamilies={this.state.companyFamilies}
            handleDeleteClick={this.handleDeleteClick}
          />
        </Box>
      </Paper>
    )
  }
}


export default withSnackbar(FamilyContainer)
