import React from "react"
import ResultItemContainer from "./ResultItemContainer"
import Flex from "../Flex"
import Box from "../Box"
import Typography from "@material-ui/core/Typography"

export default function FdaPmaApprovalItem(props) {

  function renderSearchDetails(result) {
    return (
      <Flex>
        {result.product_codes.map((productCode) =>
          <Box key={productCode} mr={2}>
            <Typography variant="body2" color="textSecondary">
              {productCode}
            </Typography>
          </Box>
        )}
      </Flex>
    );
  }

  return(
    <ResultItemContainer 
      searchItemType={props.result.record_type}
      searchTitle={`${props.result.formatted_pma_number} - ${props.result.trade_name}`}
      date={props.result.date}
      searchDescription={props.result.ao_statement}
      url={props.result.url}
      target="_blank"
      searchDetails={renderSearchDetails(props.result)}
    />
  )
}