import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import { Table, TableHead, TableRow, TableCell, TableBody } from "../tables"

export default class StatusChartDataTable extends Component {
  constructor(props) {
    super(props)
  }
  
  render() {
    return(
      <Fragment>
        <Table className='w-100'>
          <TableHead>
            <TableRow hover={false}>
              <TableCell>Status</TableCell>
              <TableCell>Date</TableCell>
              <TableCell>Source</TableCell>
              <TableCell align='right'>Enrollment</TableCell>
            </TableRow>
          </TableHead>
          
          <TableBody>  
            {this.props.trialStatuses.map((trialStatus) =>
              <TableRow key={trialStatus.id}>
    
                <TableCell>
                  {trialStatus.status}
                </TableCell>
                
                <TableCell>
                  {trialStatus.date}
                </TableCell>
                
                <TableCell>
                  {trialStatus.source_object.source_title}
                </TableCell>
                
                <TableCell align='right'>
                  {trialStatus.enrollment}
                </TableCell>
                
              </TableRow>
            )}
          </TableBody>
        </Table>
      </Fragment>
    )
  }
}
