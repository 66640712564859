import React from "react"
import TherapyTypeListItem from "./TherapyTypeListItem"

export default function TherapyTypeListItemContainer(props) {

  let checked = false
  let productTherapyType = props.productTherapyType

  if (props.productTherapyType) {

    checked = props.activeRegion == 1000000 ? (productTherapyType.investigational ? true : false) : (productTherapyType.investigational ? false : true)
  }

  return (
    <React.Fragment>
      <TherapyTypeListItem
        therapyType={props.therapyType}
        productTherapyType={productTherapyType}
        activeRegion={props.activeRegion}
        checked={checked}
        handleCheckboxSelect={props.handleCheckboxSelect}
        handleCheckboxDelete={props.handleCheckboxDelete}
      />
    </React.Fragment>
  )
}
