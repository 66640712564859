import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Paper from "../Paper"
import RaisedButton from "../buttons/RaisedButton"
import PasswordInput from "../PasswordInput"
import Box from "../Box"
import Snackbar from "../Snackbar"

class EditPassword extends Component {
  constructor(props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
    this.handlePasswordChangeClick = this.handlePasswordChangeClick.bind(this)
    this.state = { password: '', password_confirmation: '', showSnackbar: false, snackbarText: '' }
  }

  handlePasswordChangeClick(e) {
    e.preventDefault()
    $.ajax({
      type: 'PUT',
      url: "/users/password",
      data: {
        user: {
          password: this.state.password, password_confirmation: this.state.password_confirmation
        }
      },
      success: (data) => {
        this.setState({
          showSnackbar: true,
          snackbarText: 'Password has been updated'
        })
      },
      error: (data) => {
        this.setState({
          showSnackbar: true,
          snackbarText: 'Password could not be updated'
        })
      },
      complete: (data) => {
        setTimeout(() => {
          this.setState({
            showSnackbar: false,
            snackbarText: ''
          })
        }, 4000)
      }
    })
  }

  handleChange(e) {
    let name = e.target.name
    let value = e.target.value
    this.setState({
      [name]: value
    })
  }

  render() {
    return(
      <Fragment>
        <Box mt={4}>
          <Box mb={4}>
            <PasswordInput
              value={this.state.password}
              handleChange={this.handleChange}
              label='New Password'
              name='password'
              helperText='Must be at least 8 characters long'
              errorMessage='Enter at least 8 characters'
              minLength={8}
              validation={true}
              isValidationMessage={true}
              persistent={true}
              className='w-100'
            />
          </Box>

          <Box mb={4}>
            <PasswordInput
              value={this.state.password_confirmation}
              label='Confirm new password'
              name='password_confirmation'
              handleChange={this.handleChange}
              helperText='Must be at least 8 characters long'
              errorMessage='Enter at least 8 characters'
              minLength={8}
              validation={true}
              isValidationMessage={true}
              persistent={false}
              className='w-100'
            />
          </Box>

          <Box width={1/2} mx='auto' mt={4}>
            <RaisedButton handleClick={this.handlePasswordChangeClick}>
              Change Password
            </RaisedButton>
          </Box>
        </Box>

        <Snackbar
          open={this.state.showSnackbar}
          message={this.state.snackbarText}
        />
      </Fragment>
    )
  }
}

export default EditPassword
