import React, { Component } from "react"
import PropTypes from "prop-types"
import Paper from "../Paper"
import Filters from "./Filters"
import Flex from "../Flex"
import Box from "../Box"
import FilterChipsContainer from "./FilterChipsContainer"
import PeopleTable from "./PeopleTable"
import Typography from "@material-ui/core/Typography"
import update from "immutability-helper"
import { withRouter } from "react-router-dom";
import { Button } from "../buttons"
import { withSnackbar } from "../SnackbarContainer"

class Listing extends Component {
  constructor(props) {
    super(props)
    this.handleRequestSort = this.handleRequestSort.bind(this)
    this.handleChangePage = this.handleChangePage.bind(this)
    this.loadPeople = this.loadPeople.bind(this)
    this.getPeople = this.getPeople.bind(this)
    this.handleFilterChange = this.handleFilterChange.bind(this)
    this.handleDelete = this.handleDelete.bind(this)
    this.handleClearFilterClick = this.handleClearFilterClick.bind(this)
    this.state = { people: this.props.people, personTrials: this.props.person_trials, personAbstracts: this.props.person_abstracts, personManuscripts: this.props.person_manuscripts, peopleCount: this.props.people_count, order: 'desc', orderBy: 'abstracts_count', page: 0, loading: false, filterOptions: { years: this.props.years, targetSites: this.props.target_sites, targetMarkets: this.props.target_markets, therapyTypes: this.props.therapy_types }, filterValues: { year_ids: [], role: this.props.role, target_site_ids: [], target_market_ids: [], therapy_type_ids: [], query: null }, chosen: { targetSites: this.props.chosen_target_sites, targetMarkets: this.props.chosen_target_markets, therapyTypes: this.props.chosen_therapy_types } }
  };

  componentDidMount() {
    let filterKeys = Object.keys(this.props.filter_values)
    let filterValueHash = {}

    let parsedFilterValues = filterKeys.map((filterKey) =>
      this.props.filter_values[filterKey] == null ? filterValueHash[filterKey] = [] : filterValueHash[filterKey] = this.props.filter_values[filterKey].map((value) => parseInt(value))
    )

    let filterValues = update(this.state.filterValues, {
      $set: filterValueHash
    })

    this.setState({
      filterValues: filterValues
    })
  }

  handleClearFilterClick(e) {
    let filterValues = update(this.state.filterValues, {
      target_market_ids: { $set: [] },
      target_site_ids: { $set: [] },
      therapy_type_ids: { $set: [] },
      year_ids: { $set: [] },
      query: { $set: null }
    })

    this.setState({
      filterValues: filterValues
    }, () => {
      this.handleFilterChange('', '')
    })
  }

  handleFilterChange(name, value) {
    let params = Object.assign({}, this.state.filterValues)

    const roleHash = { 'abstracts_count': 'abstract', 'trials_count': 'trial', 'manuscripts_count': 'manuscript' }

    if (value.includes(null)) {
      params[name] = []
    } else {
      params[name] = value
    }

    params['role'] = roleHash[this.state.orderBy]

    this.getPeople(params)
  }

  getPeople(params) {
    $.ajax({
      type: 'GET',
      url: `/sectors/${this.props.sector.abbr}/people`,
      data: params,
      dataType: 'json',
      beforeSend: (smthing) => {
        this.setState({
          loading: true
        })
      },
      success: (data) => {
        let filterOptions = { years: data.years, targetSites: data.target_sites, targetMarkets: data.target_markets, therapyTypes: data.therapy_types }

        let chosen = { targetSites: data.chosen_target_sites, targetMarkets: data.chosen_target_markets, therapyTypes: data.chosen_therapy_types }

        let filterKeys = Object.keys(params).filter(paramKey => paramKey !== 'role')

        let queryParams = filterKeys.map((filterKey) => params[filterKey] && params[filterKey].length > 0 ? params[filterKey].map((filterValue) => `${filterKey}[]=${filterValue}`) : null).filter(param => param)

        let url = `/sectors/${this.props.sector.abbr}/people`

        let flattenedQueryParams = [].concat.apply([], queryParams);
        this.props.history.push(`${url}?${flattenedQueryParams.join("&")}`);

        this.setState({
          people: data.people,
          peopleCount: data.people_count,
          personTrials: data.person_trials,
          personAbstracts: data.person_abstracts,
          personManuscripts: data.person_manuscripts,
          filterValues: params,
          filterOptions,
          chosen
        })
      },
      error: (xhjr) => {
        this.setState({
          loading: false
        })

        this.props.snackbarShowMessage("There was a problem fetching the data. Please try again.", "error")
      },
      complete: (smthing) => {
        this.setState({
          loading: false
        })
      }
    })
  }

  handleDelete(value, type) {
    const index = this.state.filterValues[type].indexOf(value)

    const filterValues = update(this.state.filterValues, {
      [type]: { $splice: [[index, 1]] }
    })

    this.getPeople(filterValues)
  }

  handleChangePage(e, newPage) {
    this.loadPeople(newPage)
  }

  loadPeople(newPage) {
    let pageParam = { page: newPage + 1 }
    let params = pageParam
    $.ajax({
      type: 'GET',
      url: `/sectors/${this.props.sector.abbr}/people`,
      data: params,
      dataType: 'json',
      beforeSend: (smthing) => {
        this.setState({
          loading: true
        })
      },
      success: (data) => {
        this.setState({
          people: data.people,
          personTrials: data.person_trials,
          personAbstracts: data.person_abstracts,
          personManuscripts: data.person_manuscripts,
          page: newPage
        })
      },
      error: (xhjr) => {
        this.setState({
          loading: false
        })

        this.props.snackbarShowMessage("There was a problem fetching the data. Please try again.", "error")
      },
      complete: (smthing) => {
        this.setState({
          loading: false
        })
      }
    })
  }

  handleRequestSort(event, property) {
    const roleHash = { 'abstracts_count': 'abstract', 'trials_count': 'trial', 'manuscripts_count': 'manuscript', 'text': 'text' }
    const role = roleHash[property]
    let isDesc = this.state.orderBy === property && this.state.order === 'desc';

    let params = Object.assign({}, this.state.filterValues)

    params['role'] = role
    params['order'] = isDesc ? 'asc' : 'desc'

    $.ajax({
      type: 'GET',
      url: `/sectors/${this.props.sector.abbr}/people`,
      data: params,
      dataType: 'json',
      beforeSend: (data) => {
        this.setState({
          loading: true
        })
      },
      success: (data) => {

        this.setState({
          people: data.people,
          personAbstracts: data.person_abstracts,
          personTrials: data.person_trials,
          personManuscripts: data.person_manuscripts,
          orderBy: property,
          order: params.order
        })
      },
      error: (data) => {
        this.setState({
          loading: false
        })

        this.props.snackbarShowMessage("There was a problem fetching the data. Please try again.", "error")
      },
      complete: (data) => {
        this.setState({
          loading: false
        })
      }
    })
  };

  render () {
    return (
      <div>

        <Paper fullHeight>

          <Box height="100%">

            <Box mb={2}>
              <Filters
                filterOptions={this.state.filterOptions}
                filterValues={this.state.filterValues}
                sector={this.props.sector}
                handleFilterChange={this.handleFilterChange}
              />
            </Box>

            <Flex alignItems='center' mb={2}>
              <FilterChipsContainer
                filterOptions={this.state.filterOptions}
                filterValues={this.state.filterValues}
                chosen={this.state.chosen}
                handleDelete={this.handleDelete}
              />

              <Box ml='auto'>
                <Flex alignItems='center'>
                  {Object.keys(this.state.filterValues).filter(filterValueKey => this.state.filterValues[filterValueKey] ? this.state.filterValues[filterValueKey].length > 0 : null).length > 0 ?
                    <Box mr={2}>
                      <Button onClick={this.handleClearFilterClick}>
                        Clear all
                      </Button>
                    </Box>
                  : ''}

                  <Box>
                    <Typography variant="body2">
                      {`${this.state.peopleCount} people`}
                    </Typography>
                  </Box>
                </Flex>
              </Box>
            </Flex>

            <PeopleTable
              people={this.state.people}
              personAbstracts={this.state.personAbstracts}
              personTrials={this.state.personTrials}
              personManuscripts={this.state.personManuscripts}
              peopleCount={this.state.peopleCount}
              page={this.state.page}
              orderBy={this.state.orderBy}
              order={this.state.order}
              loading={this.state.loading}
              sector={this.props.sector}
              handleChangePage={this.handleChangePage}
              stableSort={this.stableSort}
              getSorting={this.getSorting}
              handleRequestSort={this.handleRequestSort}
            />

          </Box>

        </Paper>
      </div>
    );
  }
}

Listing.propTypes = {
  people: PropTypes.array
};

export default withRouter(withSnackbar(Listing))
