import React, { Fragment, Component } from "react"
import { Table, TableRow, TableHead, TableCell, TableBody } from "../../tables"
import Typography from "@material-ui/core/Typography"
import update from "immutability-helper"
import IconButton from "../../IconButton"
import Link from "../../Link"
import ExpandMore from "@material-ui/icons/ExpandMore"
import ExpandLess from "@material-ui/icons/ExpandLess"
import Collapse from '@material-ui/core/Collapse';

export default class Row extends Component {
  constructor(props) {
    super(props)
    this.handleExpandClick = this.handleExpandClick.bind(this)
    this.state = { chartData: this.props.chart_data, view: 'table', showSubAcquistions: true }
  }

  componentDidMount() {
    this.setState({
      showSubAcquistions: this.props.child ? false : true
    })
  }

  handleExpandClick(e) {
    this.setState({
      showSubAcquistions: !this.state.showSubAcquistions
    })
  }



  render() {
    // let rowColor = this.props.child ? "bg-gray-blue" : ""
    let rowColor = ""
    let marginLeft = ""

    if (this.props.subChild) {
      rowColor = "bg-gray-blue-10"
      marginLeft = "ml5"
    } else if (this.props.child) {
      rowColor = "bg-gray-blue-30"
      marginLeft = "ml3"
    }
    return (
      <Fragment>
        <TableRow>
          <TableCell padding='checkbox' className={rowColor}>
            {this.props.acquisition.acquisitions.length > 0 ?
              <IconButton edge="start" onClick={this.handleExpandClick} size='small' tooltip={this.state.showSubAcquistions ? 'Minimize' : 'Expand'} style={{height: "20px", width: "20px"}}>
                {this.state.showSubAcquistions ? <ExpandLess style={{height: "20px", width: "22px"}} /> : <ExpandMore style={{height: "20px", width: "20px"}} />}
              </IconButton>
            : ""}
          </TableCell>
          <TableCell className={rowColor}>
            <Fragment>
              <Link href={`/sectors/${this.props.sector.abbr}/companies/${this.props.acquisition.id}`} target="_blank" className={marginLeft}>
                {this.props.acquisition.short_name}
              </Link>
            </Fragment>
          </TableCell>
          <TableCell className={rowColor}>
            {humanDateFormat(this.props.acquisition.date)}
          </TableCell>
        </TableRow>

        { this.props.acquisition.acquisitions && this.state.showSubAcquistions ?
          <Fragment>
            {this.props.acquisition.acquisitions.map((company) =>
              <Row
                key={company.id}
                acquisition={company}
                sector={this.props.sector}
                child={true}
                subChild={this.props.child ? true : false}
              />
            )}
          </Fragment>
        : ""}
      </Fragment>
    )
  }
}
