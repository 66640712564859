import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Chart from "../../charts/Chart"
// import Timeline from "../charts/Timeline"
import XRangeChart from "../../charts/XRangeChart"

export default function XRangeChartContainer(props) {

  function formatData() {
    const chartData = props.chartData.map((chartData, index) => {
      let date = parseFloat(chartData.x)
      let endDate = parseFloat(chartData.x2)
      return({ x: date, x2: endDate, y: index, name: chartData.description })
    })

    return(chartData)
  }

  return (
    <XRangeChart
      chartId='product-trials-timeline'
      categories={["Received", "Recruting", "Completion"]}
      data={formatData()}
    />

  )
}
