import React, { Fragment } from "react"
import { Table, TableHead, TableRow, TableCell, TableBody } from "../../tables"
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TablePaginationActions from "../../tables/TablePaginationActions"
import Link from "../../Link"

function GrantsTable(props) {

  return(
    <Fragment>
      <Table size="small" stickyHeader={true}>
        <TableHead>
          <TableRow>
            <TableCell>Award Title</TableCell>
            <TableCell>Firm</TableCell>
            <TableCell>Company</TableCell>
            <TableCell>Year</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>

        <TableBody>
          {props.sbirGrants.map((sbirGrant) =>
            <TableRow key={sbirGrant.id}>
              <TableCell>
                <Link href={`/admin/sbir_grants/${sbirGrant.id}/associator`} target="_blank">
                  {sbirGrant.award_title}
                </Link>
              </TableCell>
              <TableCell>{sbirGrant.firm}</TableCell>
              <TableCell>
                <Link href={`/admin/companies/${sbirGrant.company_id}/grants`} target="_blank">
                  {sbirGrant.short_name}
                </Link>
              </TableCell>
              <TableCell>{sbirGrant.award_year}</TableCell>
              <TableCell></TableCell>
            </TableRow>
          )}
        </TableBody>
        <TableFooter>
          <TablePagination
            colSpan={5}
            count={props.sbirGrantsCount}
            rowsPerPage={100}
            page={props.page}
            SelectProps={{
              inputProps: { 'aria-label': 'rows per page' },
              native: true,
            }}
            onChangePage={props.handleChangePage}
            onChangeRowsPerPage={props.handleChangeRowsPerPage}
            ActionsComponent={TablePaginationActions}
          />
        </TableFooter>
      </Table>
    </Fragment>
  )
}

export default GrantsTable
