import React, { Fragment, useState } from "react"
import { Tabs, Tab } from "../tabs"
import Flex from "../Flex"
import Box from "../Box"
import AdverseEventsChart from "./AdverseEventsChart"
import AdverseEvents from "./AdverseEvents"
import AdverseEventTypeChart from "./AdverseEventTypeChart"
import TimelineChart from "./adverse_events/TimelineChart"
import ReportTypeChart from "./ReportTypeChart"
import CountryCodeChart from "./CountryCodeChart"


export default function AdverseEventTrends(props) {
  const [activeIndex, setActiveIndex] = useState(0)

  return(
    <Fragment>
      {props.chartDisplay == 'monthly' ?
        <TimelineChart
          chartData={props.chartData}
          chartReloadKey={props.chartReloadKey}
          comparison={props.comparison}
          dateApproved={props.dateApproved}
          recallDates={props.recallDates}
        />
      :
        <AdverseEventsChart
          chartData={props.chartData}
          categories={props.categories}
          chartReloadKey={props.chartReloadKey}
          comparison={props.comparison}
          dateApproved={props.dateApproved}
          dateRecalled={props.dateRecalled}
        />
      }

      <Tabs centered value={activeIndex} onChange={(e, newValue) => { setActiveIndex(newValue) }} className='bb b--black-10'>
        <Tab label='Charts' />
        {props.comparison ? '' : <Tab label='Data' />}
      </Tabs>

      <Box mt={3}>
        {activeIndex == 0 ?
          <Fragment>
            <Flex justifyContent='space-evenly'>
              <Box>
                <AdverseEventTypeChart
                  eventTypeChartData={props.eventTypeChartData}
                  chartReloadKey={props.chartReloadKey}
                />
              </Box>

              <Box>
                <CountryCodeChart
                  countryCodeChartData={props.countryCodeChartData}
                  chartReloadKey={props.chartReloadKey}
                />
              </Box>
            </Flex>

            <Box mt={4}>
              <ReportTypeChart
                reportTypeChartData={props.reportTypeChartData}
                chartReloadKey={props.chartReloadKey}
              />
            </Box>
          </Fragment>
        : ''}

        {activeIndex == 1 ?
          <AdverseEvents
            adverseEvents={props.adverseEvents}
          />
        : ''}
      </Box>


    </Fragment>
  )
}
