import React, { Fragment, Component } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import UpdateForm from "./UpdateForm"
import Paper from "../../Paper"
import Flex from "../../Flex"
import Box from "../../Box"
import Snackbar from "../../Snackbar"
import update from "immutability-helper"
import Typography from "@material-ui/core/Typography"

export default class NewContainer extends Component {
  constructor(props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
    this.handleSourceChange = this.handleSourceChange.bind(this)
    this.handleSectionChange = this.handleSectionChange.bind(this)
    this.handleChooseSourceClick = this.handleChooseSourceClick.bind(this)
    this.handleSourceRemovalClick = this.handleSourceRemovalClick.bind(this)
    this.handleSaveClick = this.handleSaveClick.bind(this)
    this.state = { update: this.props.update, source: this.props.source, openDialog: false, showSnackbar: false, snackbarText: '' }
  }
  
  componentDidMount() {
    const trialUpdate = update(this.state.update, { 
      updated_by: { $set: this.props.current_user.id }
    })
    
    this.setState({
      update: trialUpdate
    })
  }
  
  handleSaveClick(e) {
    $.ajax({
      type: 'PUT',
      url: `/admin/trials/${this.props.trial.id}/updates/${this.props.update.id}`,
      data: {
        trial_update: this.state.update
      },
      error: (data) => {
        this.setState({
          showSnackbar: true,
          snackbarText: 'The update could not be saved. Please try again'
        })
      },
      complete: (data) => {
        setTimeout(() => {
          this.setState({
            showSnackbar: false,
            snackbarText: ''
          })
        }, 5000)
      }
    })
  }
  
  handleChange(e) {
    const text = e.editor.getData()
    
    const trialUpdate = update(this.state.update, { 
      content: { $set: text } 
    })
    
    this.setState({
      update: trialUpdate
    })
  }
  
  handleSectionChange(name, value) {
    let trialUpdate = update(this.state.update, { 
      trial_update_section_id: { $set: value } 
    })
    
    this.setState({
      update: trialUpdate
    })
  }
  
  handleSourceRemovalClick(e) {
    this.setState({
      source: null
    })
  }
  
  handleChooseSourceClick(e) {
    this.setState({
      openDialog: true
    })
  }
  
  handleSourceChange(entity, sourceType) {
    if (sourceType == 'recently_used') {
      let trialUpdate = update(this.state.update, { 
        source_id: { $set: entity.id }
      })
      this.setState({
        source: entity,
        update: trialUpdate,
        openDialog: false
      })
    } else {
      $.get("/admin/sources/find_source", { source_type: sourceType, entity_id: entity.id }, (data) => {
        if (data.source) {
          let trialUpdate = update(this.state.update, { 
            source_id: { $set: data.source.source_id }
          })
          this.setState({
            source: data.source,
            update: trialUpdate,
            openDialog: false
          })
        } else {
          $.post("/admin/sources/filter", { source: { kind: sourceType, entity_id: entity.id } }, (data) => {
            let trialUpdate = update(this.state.update, { 
              source_id: { $set: data.source.source_id }
            })
            this.setState({
              source: data.source,
              update: trialUpdate,
              openDialog: false
            })
          })
        }
      }, 'json')
    }
  }
  
  render() {
    return (
      <Fragment>
        
        <Paper>
          
          <Box mb={3}>
            <Typography variant="h6" component="h6">
              Edit Update
            </Typography>
          </Box>
          
          <UpdateForm 
            trial={this.props.trial}
            update={this.state.update}
            source={this.state.source}
            openDialog={this.state.openDialog}
            sections={this.props.sections}
            sourceTypes={this.props.source_types}
            sources={this.props.sources}
            handleChange={this.handleChange}
            handleChooseSourceClick={this.handleChooseSourceClick}
            handleSourceChange={this.handleSourceChange}
            handleSectionChange={this.handleSectionChange}
            handleSaveClick={this.handleSaveClick}
            handleSourceRemovalClick={this.handleSourceRemovalClick}
          />
          
        </Paper>
        
        <Snackbar 
          open={this.state.showSnackbar}
          message={this.state.snackbarText}
        />
      </Fragment>
    )
  }
}
