import React, { Fragment } from "react"
import Flex from "../../Flex"
import Box from "../../Box"
import TextInput from "../../TextInput"
import ButtonLayout from "../ButtonLayout"
import { RaisedButton } from "../../buttons"
import DatePicker from "../../DatePicker"
import Select from "../../Select"

export default function Form(props) {
  return (
    <Fragment>
      <Flex alignItems='start'>

        <Box width={1/2}>
          <TextInput
            name='url'
            label='URL'
            value={props.newsArticle.url}
            onChange={props.handleChange}
            fullWidth
          />
        </Box>

        <Box ml={4} width={1/4}>
          <TextInput
            name='publication'
            label='Publication'
            value={props.newsArticle.publication}
            onChange={props.handleChange}
            fullWidth
          />
        </Box>

        <Box ml="auto">
          <DatePicker
            name='date'
            label='Date'
            handleChange={props.handleDateChange}
            value={props.newsArticle.date}
          />
        </Box>

      </Flex>

      <Box width={1} my={3}>
        <TextInput
          name='title'
          label='Title'
          multiline={true}
          rows={4}
          value={props.newsArticle.title}
          onChange={props.handleChange}
          fullWidth
        />
      </Box>

      <ButtonLayout>
        <RaisedButton handleClick={props.handleSaveClick}>
          Save News Article
        </RaisedButton>
      </ButtonLayout>
    </Fragment>
  )
}
