import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Header from "../../Header"
import Flex from "../../Flex"
import Box from "../../Box"
import TextInput from "../../TextInput"
import Paper from "../../Paper"
import { Card, CardSection, CardSectionHeader } from "../../cards"
import SidebarSection from "../../SidebarSection"
import Link from "../../Link"
import Typography from "@material-ui/core/Typography"
import ButtonLayout from "../ButtonLayout"
import { RaisedButton } from "../../buttons"
import Snackbar from "../../Snackbar"

export default class Profile extends Component {
  constructor(props) {
    super(props)
    this.handleHideClick = this.handleHideClick.bind(this)
    this.state = { showSnackbar: false, snackbarText: '' }
  }

  handleHideClick(e) {
    $.ajax({
      type: 'PUT',
      url: `/admin/ct_gov_trials/${this.props.ct_gov_trial.id}`,
      data: {
        ct_gov_trial: {
          hide_trial: true
        }
      },
      error: (data) => {
        this.setState({
          showSnackbar: true,
          snackbarText: 'The trial could not be hidden. Please try again.'
        })
      },
      complete: (data) => {
        setTimeout(() => {
          this.setState({
            showSnackbar: false,
            snackbarText: ''
          })
        }, 4000)
      }
    })
  }

  render() {
    return(
      <Fragment>

        <Header
          header={this.props.ct_gov_trial.acronym || this.props.ct_gov_trial.brief_title}
          background='bg-washed-red'
        />


        <Card background='bg-white'>
          <div className='mb4'>
            <Link href='/admin/ct_gov_trials/reviewer'>
              Back to Reviewer
            </Link>
          </div>

          <ButtonLayout>
            <RaisedButton handleClick={this.handleHideClick}>
              Hide Trial
            </RaisedButton>
          </ButtonLayout>

          <CardSection>
            <SidebarSection header='Official title'>
              <div>
                {this.props.ct_gov_trial.official_title}
              </div>
            </SidebarSection>

            <SidebarSection header='Brief title'>
              <div>
                {this.props.ct_gov_trial.brief_title}
              </div>
            </SidebarSection>

            <SidebarSection header='Acronym'>
              <div>
                {this.props.ct_gov_trial.acronym ? this.props.ct_gov_trial.acronym : 'N/A'}
              </div>
            </SidebarSection>

            <SidebarSection header='Design'>
              <div>
                {this.props.ct_gov_trial.study_design}
              </div>
            </SidebarSection>

            <SidebarSection header='Status'>
              <div>
                {this.props.ct_gov_trial.overall_status}
              </div>
            </SidebarSection>

            <SidebarSection header='NCT ID'>
              <div>
                {this.props.ct_gov_trial.nct_id}
                {this.props.ct_gov_trial.first_received_date ? <span className='pl2 f6 i'>
                  ({humanDateFormat(this.props.ct_gov_trial.first_received_date)})
                </span> : ''}
              </div>
            </SidebarSection>

            <SidebarSection
              header='Number of Arms'
              content={this.props.ct_gov_trial.number_of_arms}
            />

            <SidebarSection
              header='Enrollment'
              content={this.props.ct_gov_trial.enrollment}
            />

            <SidebarSection
              header='Type'
              content={this.props.ct_gov_trial.study_type}
            />

            <SidebarSection
              header='Start date'
              content={this.props.ct_gov_trial.start_date ? this.props.ct_gov_trial.start_date : 'N/A'}
            />

            <SidebarSection
              header='Completion date'
              content={this.props.ct_gov_trial.completion_date ? this.props.ct_gov_trial.completion_date : 'N/A'}
            />

            <SidebarSection
              header='Primary completion date'
              content={this.props.ct_gov_trial.primary_completion_date ? this.props.ct_gov_trial.primary_completion_date : 'N/A'}
            />

          </CardSection>


          <CardSection>
            <CardSectionHeader>Brief Summary</CardSectionHeader>
            <Typography className='measure'>
              {this.props.ct_gov_trial.brief_summary}
            </Typography>
          </CardSection>

          <CardSection>
            <CardSectionHeader>Detailed description</CardSectionHeader>
            <Typography className='measure'>
              {this.props.ct_gov_trial.detailed_description}
            </Typography>
          </CardSection>

          <CardSection>
            <CardSectionHeader>Trial Criteria</CardSectionHeader>
            <Typography className='measure'>
              {this.props.ct_gov_trial.trial_criteria}
            </Typography>
          </CardSection>

          <CardSection>
            <CardSectionHeader>Sponsors</CardSectionHeader>
            <div>
              {this.props.ct_gov_trial.sponsor}
            </div>
          </CardSection>

          <CardSection>
            <CardSectionHeader>Collaborators</CardSectionHeader>
            {this.props.collaborators.length > 1 ? this.props.collaborators.map((collaborator) =>
              <div key={collaborator.id}>{collaborator.name}</div>
            ) : 'None'}
          </CardSection>

          <CardSection>
            <CardSectionHeader>Conditions</CardSectionHeader>
            {this.props.conditions.length > 1 ? this.props.conditions.map((condition) =>
              <div key={condition.id}>{condition.name}</div>
            ) : 'None'}
          </CardSection>

          <CardSection>
            <CardSectionHeader>Interventions</CardSectionHeader>
            {this.props.interventions.length > 1 ? this.props.interventions.map((intervention) =>
              <div key={intervention.id}>{intervention.name}</div>
            ) : 'None'}
          </CardSection>

          <CardSection>

            <CardSectionHeader>Locations</CardSectionHeader>
            {this.props.locations.length > 1 ? this.props.locations.map((location) =>
              <div key={location.id}>{location.facility_name}</div>
            ) : 'None'}
          </CardSection>

          <CardSection>
            <CardSectionHeader>Primary Outcome</CardSectionHeader>

            {this.props.outcome && this.props.outcome.primary_outcome ? <Typography>{this.props.outcome.primary_outcome}</Typography> : 'None'}
          </CardSection>

          <CardSection>
            <CardSectionHeader>Secondary Outcome</CardSectionHeader>

            {this.props.outcome && this.props.outcome.secondary_outcome ? <Typography>{this.props.outcome.secondary_outcome}</Typography> : 'None'}
          </CardSection>

          <CardSection>
            <CardSectionHeader>Other Outcome</CardSectionHeader>

            {this.props.outcome && this.props.outcome.other_outcome ? <Typography>{this.props.outcome.other_outcome}</Typography> : 'None'}
          </CardSection>


          <ButtonLayout>
            <RaisedButton handleClick={this.handleHideClick}>
              Hide Trial
            </RaisedButton>
          </ButtonLayout>

        </Card>

        <Snackbar
          open={this.state.showSnackbar}
          message={this.state.snackbarText}
        />

      </Fragment>
    )
  }
}

//
// <EntityLayout>
//
//   <Box className='w-100 w-60-ns mb3 mb0-ns'>
//     <ExpansionPanelContainer>
//       <Expandable header='Brief Summary' open={true}>
//         <div className='ph3' dangerouslySetInnerHTML={{__html: this.props.ct_gov_trial.brief_summary}} />
//       </Expandable>
//
//       <Expandable header='Trial Criteria' open={false}>
//         <div className='ph3' dangerouslySetInnerHTML={{__html: this.props.ct_gov_trial.trial_criteria}} />
//       </Expandable>
//
//       <Expandable header='Detailed Description' open={false}>
//         <div className='ph3' dangerouslySetInnerHTML={{__html: this.props.ct_gov_trial.detailed_description}} />
//       </Expandable>
//
//       <Expandable header='Interventions' open={false}>
//         <div className='ph3'>
//           {this.props.interventions.map((intervention) =>
//             <div key={intervention.id}>
//               {intervention.name}
//             </div>
//           )}
//         </div>
//       </Expandable>
//     </ExpansionPanelContainer>
//   </Box>
//
//   <Box className='w-100 w-33-ns ml-auto'>
//     <ProfileCard
//       ct_gov_trial={this.props.ct_gov_trial}
//       collaborators={this.props.collaborators}
//     />
//   </Box>
//
// </EntityLayout>



{/* <Paper>
  <Box className='w-100 mb3'>
    <TextInput
      name='nct_id'
      label='NCT ID'
      value={this.props.ct_gov_trial.nct_id}
      className='w-100'
    />
  </Box>
  <Box className='w-100 mb3'>
    <TextInput
      name='official_name'
      label='Official Title'
      value={this.props.ct_gov_trial.official_title}
      className='w-100'
    />
  </Box>
  <Box className='w-100 mb3'>
    <TextInput
      name='brief_title'
      label='Brief Title'
      value={this.props.ct_gov_trial.brief_title}
      className='w-100'
    />
  </Box>
  <Box className='w-100 mb3'>
    <TextInput
      name='status'
      label='Status'
      value={this.props.ct_gov_trial.overall_status}
      className='w-100'
    />
  </Box>
  <Box className='w-100 mb3'>
    <TextInput
      name='start_date'
      label='Start Date'
      value={this.props.ct_gov_trial.start_date}
      className='w-100'
    />
  </Box>
  <Box className='w-100 mb3'>
    <TextInput
      name='end_date'
      label='End Date'
      value={this.props.ct_gov_trial.completion_date}
      className='w-100'
    />
  </Box>
  <Box className='w-100 mb3'>
    <TextInput
      name='primary_completion_date'
      label='Primary completion date'
      value={this.props.ct_gov_trial.primary_completion_date}
      className='w-100'
    />
  </Box>
</Paper> */}
