import React from 'react';
import AbstractItemDisplay from './ItemDisplay';
import { List } from "../lists"
import Box from "../Box"

const AbstractsResults = ({ abstracts, resultsCount, sector, handleMeetingClick }) => {
  return (
    <React.Fragment>
      <Box height="100%">
        <List>
          {abstracts.map((abstract) => (
            <AbstractItemDisplay 
              key={abstract.id} 
              abstract={abstract._source} 
              abstractParent={abstract}
              sector={sector}
              handleMeetingClick={handleMeetingClick}
            />
          ))}
        </List>
      </Box>
    </React.Fragment>
  );
};

export default AbstractsResults;
