import React, { Fragment } from "react"
import AdminSearchResults from "./AdminSearchResults"
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

export default function AdminSearchContainer(props) {

  return(
    <Fragment>
      <Switch>
        <Route
          path={`/admin/search`}
          children={<AdminSearchResults {...props} />}
        />
      </Switch>
    </Fragment>
  )
}
