import React, { useState, useEffect } from 'react';
import { 
  Button, 
  TextField, 
  MenuItem, 
  FormControl, 
  InputLabel, 
  Select, 
  Box, 
  IconButton,
  Typography,
  Divider,
  makeStyles,
  Tooltip,
  Grid,
} from '@material-ui/core';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

const useStyles = makeStyles((theme) => ({
  fieldRow: {
    marginBottom: theme.spacing(2),
  },
  fieldSelect: {
    minWidth: 150,
  },
  queryInput: {
    width: '100%',
  },
  addButton: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  submitButton: {
    marginTop: theme.spacing(2),
  },
  resetButton: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(2),
  },
  helpIcon: {
    fontSize: '1rem',
    marginLeft: theme.spacing(1),
    color: theme.palette.text.secondary,
  },
  helperText: {
    marginTop: theme.spacing(0.5),
    minHeight: '1.25rem', // Ensure consistent height even when empty
  },
  operatorSelect: {
    minWidth: 100,
  },
  operatorRow: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: theme.spacing(2, 0),
  },
  queryBox: {
    width: '100%',
    minHeight: '100px',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

const fieldDescriptions = {
  title: "Search within the title of publications",
  authors: "Search for specific authors",
  mesh_terms: "Search using Medical Subject Headings",
  text: "Search within the full text of publications",
  published_date: "Search by date published (format: YYYY-MM-DD or YYYY-MM or >YYYY or YYYY-YYYY or YYYY-MM-DD - YYYY-MM-DD)",
};

const QueryBuilder = ({ onSubmit, onReset, initialFields, initialQueryString, handleQueryStringChange }) => {
  const classes = useStyles();
  const [fields, setFields] = useState(initialFields && initialFields.length > 0 
    ? initialFields.map(f => ({ ...f }))
    : []);
  const [currentField, setCurrentField] = useState({ field: '', query: '', operator: 'AND' });
  const [queryString, setQueryString] = useState(initialQueryString || '');
  const [queryStructure, setQueryStructure] = useState([]);
  const availableFields = [{ label: 'Title', value: 'title' }, { label: 'Author', value: 'authors'}, { label: "MeSH Terms", value: 'mesh_terms' }, { label: 'Text', value: 'text'}, { label: "Publication Date", value: 'published_date' }];

  useEffect(() => {
    if (initialQueryString) {
      setQueryString(initialQueryString);
    }
  }, [initialQueryString]);

  useEffect(() => {
    const buildNestedQuery = (terms) => {
      if (terms.length <= 1) return terms.join('');
      
      let result = [];
      let currentGroup = [];
      
      for (let i = 0; i < terms.length; i++) {
        if (terms[i] === 'AND' && currentGroup.length > 0) {
          result.push(`(${currentGroup.join(' OR ')})`);
          currentGroup = [];
        } else if (terms[i] !== 'OR') {
          currentGroup.push(terms[i]);
        }
      }
      
      if (currentGroup.length > 0) {
        result.push(`(${currentGroup.join(' OR ')})`);
      }
      
      return result.join(' AND ');
    };

    const newQueryString = buildNestedQuery(queryStructure);
    setQueryString(newQueryString);
    // onQueryStringChange(newQueryString);
  }, [queryStructure]);

  const handleFieldChange = (value) => {
    setCurrentField(prev => ({ ...prev, field: value }));
  };

  const handleOperatorChange = (value) => {
    setCurrentField(prev => ({ ...prev, operator: value }));
  };

  const handleQueryChange = (value) => {
    setCurrentField(prev => ({ ...prev, query: value }));
  };

  const handleAddToQueryBox = () => {
    if (currentField.field && currentField.query) {
      const newTerm = `${currentField.field}:"${currentField.query}"`;
      setQueryStructure(prevStructure => {
        if (prevStructure.length === 0) {
          return [newTerm];
        } else {
          return [...prevStructure, currentField.operator, newTerm];
        }
      });
      setFields(prevFields => [...prevFields, { ...currentField }]);
      setCurrentField({ field: '', query: '', operator: 'AND' });
    }
  };

  const handleSubmit = () => {
    onSubmit(fields, queryString);
  };

  const handleReset = () => {
    setFields([]);
    setCurrentField({ field: '', query: '', operator: 'AND' });
    setQueryString('');
    setQueryStructure([]);
    onReset();
  };

  const handleQueryStringChangeInternal = (value) => {
    setQueryString(value);
    handleQueryStringChange(value);
  };

  return (
    <div>
      <Typography variant="h6" gutterBottom>
        Build Your Query
      </Typography>
      <Divider style={{ marginBottom: '1rem' }} />
      <Box className={classes.fieldRow}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} sm={3}>
            <FormControl variant="outlined" className={classes.fieldSelect} fullWidth>
              <InputLabel>Field</InputLabel>
              <Select
                value={currentField.field}
                onChange={(e) => handleFieldChange(e.target.value)}
                label="Field"
              >
                {availableFields.map((fieldOption) => (
                  <MenuItem key={fieldOption.value} value={fieldOption.value}>
                    {fieldOption.label}
                    <Tooltip title={fieldDescriptions[fieldOption.value]}>
                      <HelpOutlineIcon className={classes.helpIcon} />
                    </Tooltip>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              className={classes.queryInput}
              label="Enter search term"
              variant="outlined"
              value={currentField.query}
              onChange={(e) => handleQueryChange(e.target.value)}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <FormControl variant="outlined" className={classes.operatorSelect} fullWidth>
              <InputLabel>Operator</InputLabel>
              <Select
                value={currentField.operator}
                onChange={(e) => handleOperatorChange(e.target.value)}
                label="Operator"
              >
                <MenuItem value="AND">AND</MenuItem>
                <MenuItem value="OR">OR</MenuItem>
                <MenuItem value="NOT">NOT</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={1}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleAddToQueryBox}
              fullWidth
            >
              Add
            </Button>
          </Grid>
        </Grid>
      </Box>
      <Typography variant="h6" gutterBottom>
        Query box
      </Typography>
      <TextField
        className={classes.queryBox}
        variant="outlined"
        multiline
        rows={4}
        value={queryString}
        onChange={(e) => handleQueryStringChangeInternal(e.target.value)}
        fullWidth
      />
      <Box mt={2}>
        <Button
          onClick={handleSubmit}
          variant="contained"
          color="primary"
          className={classes.submitButton}
        >
          Search
        </Button>
        <Button
          onClick={handleReset}
          variant="outlined"
          color="secondary"
          className={classes.resetButton}
        >
          Clear
        </Button>
      </Box>
    </div>
  );
};

export default QueryBuilder;