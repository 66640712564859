import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Paper from "../../Paper"
import { Table, TableHead, TableRow, TableCell, TableBody, EmptyDataTableRow } from "../../tables"
import PressReleaseRow from "./PressReleaseRow"
import update from "immutability-helper"
import { withSnackbar } from "../../SnackbarContainer"

class PressReleases extends Component {
  constructor(props) {
    super(props)
    this.handleDeleteClick = this.handleDeleteClick.bind(this)
    this.handleUndoClick = this.handleUndoClick.bind(this)
    this.state = { pressReleases: this.props.press_releases, lastDeleted: '' }
  }

  handleUndoClick(e) {
    if (this.state.lastDeleted) {
      $.ajax({
        type: 'PUT',
        url: `/admin/press_releases/${this.state.lastDeleted.id}`,
        data: {
          press_release: {
            deleted: null, deleted_by: null
          }
        },
        dataType: 'json',
        success: (data) => {
          const pressReleases = update(this.state.pressReleases, { $push: [this.state.lastDeleted] })
          this.setState({
            pressReleases: pressReleases,
            lastDeleted: null
          })
          this.props.snackbarShowMessage("Press release was undeleted")
        },
        error: (data) => {
          this.props.snackbarShowMessage("Deletion could not be undone. Please report issue in slack.", "error")
        }
      })
    }
  }

  handleDeleteClick(pressRelease) {
    $.ajax({
      type: 'PUT',
      url: `/admin/press_releases/${pressRelease.id}`,
      data: {
        press_release: {
          deleted: true, deleted_by: this.props.current_user
        }
      },
      dataType: 'json',
      success: (data) => {
        const index = this.state.pressReleases.indexOf(pressRelease)
        let pressReleases = update(this.state.pressReleases, { $splice: [[index,1]] })

        this.setState({
          pressReleases: pressReleases,
          lastDeleted: pressRelease
        })
        this.props.snackbarShowMessage("Press release was removed")
      },
      error: (data) => {
        this.props.snackbarShowMessage("Press release could not be removed. Please try again.", "error")
      }
    })
  }

  render() {
    return(
      <Fragment>

        <Paper>
          <Table className='w-100'>
            <TableHead>
              <TableRow>
                <TableCell>Press Release</TableCell>
                <TableCell>Company</TableCell>
                <TableCell>Date</TableCell>
                <TableCell>Created By</TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.state.pressReleases.length > 0 ? this.state.pressReleases.map((pressRelease) =>
                <PressReleaseRow
                  key={pressRelease.id}
                  pressRelease={pressRelease}
                  handleDeleteClick={this.handleDeleteClick}
                />
              ) : <EmptyDataTableRow message='No press releases yet. Create a press release and it will display here' colSpan={4} />}
            </TableBody>
          </Table>
        </Paper>
      </Fragment>
    )
  }
}

export default withSnackbar(PressReleases)
