import React, {Component} from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Link from "../Link"
import {Table, TableHead, TableRow, TableCell} from "../tables"

export default class CompareFieldCell extends Component {
  constructor(props) {
    super(props)
  }

  renderArray(value) {
    if (value) {
      return (
        value.map((val) =>
          <div key={`${val.id} - ${val.investigational}`} className={val.investigational ? 'green' : ''}>
            {val.value}
          </div>
        )
      )
    } else {
      return('')
    }
  }

  renderMarkup(value) {
    return {__html: `${value.text}`}
  }

  render() {
    let value = this.props.fields[this.props.field]
    let cellWidth = this.props.entitiesCount > 4 ? '18.750em' : `${100/this.props.entitiesCount}%`
    return(
      <TableCell borderRight={true} style={{width: `${cellWidth}`}}>
        {value && value[0] && value[0].text ? <div dangerouslySetInnerHTML={this.renderMarkup(value[0])} /> : this.renderArray(value)}
      </TableCell>
    )
  }
}
