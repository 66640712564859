import React from "react"
import Paper from "../../../Paper"
import Flex from "../../../Flex"
import Box from "../../../Box"
import PmaTable from "./PmaTable"
import PmaPredictionsTable from "./PmaPredictionsTable"
import update from "immutability-helper"
import { withSnackbar } from "../../../SnackbarContainer"
import { Tabs, Tab } from "../../../tabs"
import { RaisedButton } from "../../../buttons"
import Dialog from "../../../Dialog"
import SectorCheckboxList from "../../sectors/CheckboxList"


class Container extends React.Component {
  constructor(props) {
    super(props)
    this.handleDeleteClick = this.handleDeleteClick.bind(this)
    this.handleActiveIndexUpdate = this.handleActiveIndexUpdate.bind(this)
    this.handleAddPmaClick = this.handleAddPmaClick.bind(this)
    this.handlePmaApprovalCheckboxClick = this.handlePmaApprovalCheckboxClick.bind(this)
    this.handleAddAllClick = this.handleAddAllClick.bind(this)
    this.handleSectorClick = this.handleSectorClick.bind(this)
    this.handleAddClick = this.handleAddClick.bind(this)
    this.handleSectorDelete = this.handleSectorDelete.bind(this)
    this.handleAddAllPmaClick = this.handleAddAllPmaClick.bind(this)
    this.handleReviewedClick = this.handleReviewedClick.bind(this)
    this.handleDeletePredictionClick = this.handleDeletePredictionClick.bind(this)
    this.state = { pmaApprovals: this.props.pma_approvals, pmaPredictions: this.props.pma_predictions, view: "associations", activePma: null, checkedPmaApprovals: [], openSectorDialog: false, sectorIds: [] }
  }

  handleReviewedClick(e) {
    let ids = this.state.checkedPmaApprovals.length > 0 ? this.state.checkedPmaApprovals : this.state.pmaPredictions.map((pmaApproval) => pmaApproval.id)

    $.ajax({
      type: 'PUT',
      url: `/admin/product_fda_pma_approval_predictions/mark_as_reviewed`,
      data: {
        ids: ids, user_id: this.props.user.id, product_id: this.props.product.id
      },
      dataType: 'json',
      success: (data) => {
        this.setState({
          pmaPredictions: data.pma_predictions
        })
        this.props.snackbarShowMessage("Predictions have been marked as reviewed")
      },
      error: (data) => {
        this.props.snackbarShowMessage("Something went wrong marking predictions as reviewed. Please try again.", "error")
      }
    })
  }

  handleDeletePredictionClick(pmaApproval) {
    $.ajax({
      type: "PUT",
      url: `/admin/product_fda_pma_approval_predictions/${pmaApproval.product_fda_pma_approval_prediction_id}`,
      data: {
        product_fda_pma_approval_prediction: {
          deleted: true, deleted_by: this.props.user.id
        }
      },
      dataType: 'json',
      success: (data) => {
        let index = this.state.pmaPredictions.indexOf(pmaApproval)

        let pmaPredictions = update(this.state.pmaPredictions, {
          $splice: [[index, 1]]
        })

        this.setState({
          pmaPredictions: pmaPredictions
        })

        this.props.snackbarShowMessage("PMA prediction has been removed")
      },
      error: (data) => {
        this.props.snackbarShowMessage("The PMA prediction could not be removed. Refresh the page and try again.", "error")
      }
    })
  }

  handleSectorDelete(productFdaPmaApprovalSectorId) {
    $.ajax({
      type: "DELETE",
      url: `/admin/product_fda_pma_approval_sectors/${productFdaPmaApprovalSectorId}`,
      data: {
        with_approvals: true
      },
      dataType: 'json',
      success: (data) => {
        this.setState({
          pmaApproval: data.pma_approvals
        })

        this.props.snackbarShowMessage("Sector has been removed")
      },
      error: (data) => {
        this.props.snackbarShowMessage("Sector could not be removed", "error")
      }
    })
  }

  handleSectorClick(sector) {
    let index = this.state.sectorIds.indexOf(sector.id)

    let sectorIds = ""

    if (index == -1) {
      sectorIds = update(this.state.sectorIds, {
        $push: [sector.id]
      })
    } else {
      sectorIds = update(this.state.sectorIds, {
        $splice: [[index, 1]]
      })
    }

    this.setState({
      sectorIds: sectorIds
    })
  }

  handleAddAllPmaClick(e) {
    this.setState({
      openSectorDialog: true,
      checkedPmaApprovals: this.state.checkedPmaApprovals.length > 0 ? this.state.checkedPmaApprovals : this.state.pmaPredictions.map((pmaApproval) => pmaApproval.id)
    })
  }

  handleAddPmaClick(pmaApproval) {
    this.setState({
      openSectorDialog: true,
      activePma: pmaApproval
    })
  }

  handleActiveIndexUpdate(e, newValue) {
    this.setState({
      view: newValue
    })
  }

  handlePmaApprovalCheckboxClick(pmaApproval) {

    let index = this.state.checkedPmaApprovals.indexOf(pmaApproval.id)

    let pmaApprovalIds = ""
    if (index == -1) {
      pmaApprovalIds = update(this.state.checkedPmaApprovals, {
        $push: [pmaApproval.id]
      })
    } else {
      pmaApprovalIds = update(this.state.checkedPmaApprovals, {
        $splice: [[index, 1]]
      })
    }

    this.setState({
      checkedPmaApprovals: pmaApprovalIds
    })
  }


  handleAddAllClick(e) {

    $.ajax({
      type: "POST",
      url: `/admin/product_fda_pma_approvals/bulk_add`,
      data: {
        product_id: this.props.product.id, fda_pma_approval_ids: this.state.checkedPmaApprovals, created_by: this.props.user.id, sector_ids: this.state.sectorIds, with_approvals: true, with_predictions: true
      },
      dataType: 'json',
      success: (data) => {
        this.setState({
          pmaApprovals: data.pma_approvals,
          pmaPredictions: data.pma_predictions,
          openSectorDialog: false,
          checkedPmaApprovals: []
        })

        this.props.snackbarShowMessage("PMA approval has been added")
      },
      error: (data) => {
        this.props.snackbarShowMessage("The PMA approval could not be added. Refresh the page and try again.", "error")
      }
    })
  }

  handleAddClick(e) {
    $.ajax({
      type: "POST",
      url: `/admin/product_fda_pma_approvals`,
      data: {
        product_fda_pma_approval: {
          product_id: this.props.product.id, fda_pma_approval_id: this.state.activePma.id, created_by: this.props.user.id, sector_ids: this.state.sectorIds
        }, with_approvals: true
      },
      dataType: 'json',
      success: (data) => {
        let index = this.state.pmaPredictions.indexOf(this.state.activePma)

        let pmaPredictions = update(this.state.pmaPredictions, {
          $splice: [[index, 1]]
        })

        this.setState({
          pmaApprovals: data.pma_approvals,
          pmaPredictions: pmaPredictions,
          openSectorDialog: false,
          activePma: null
        })

        this.props.snackbarShowMessage("PMA approval has been added")
      },
      error: (data) => {
        this.props.snackbarShowMessage("The PMA approval could not be added. Refresh the page and try again.", "error")
      }
    })
  }

  handleDeleteClick(pmaApproval) {
    $.ajax({
      type: "PUT",
      url: `/admin/product_fda_pma_approvals/${pmaApproval.product_fda_pma_approval_id}`,
      data: {
        product_fda_pma_approval: {
          deleted: true, deleted_by: this.props.user.id
        }, with_approvals: true
      },
      dataType: 'json',
      success: (data) => {

        this.setState({
          pmaApprovals: data.pma_approvals
        })

        this.props.snackbarShowMessage("PMA approval has been removed")
      },
      error: (data) => {
        this.props.snackbarShowMessage("The PMA approval could not be removed. Refresh the page and try again.", "error")
      }
    })
  }

  render() {
    let pmaNumberDisplay = this.state.activePma ? (this.state.activePma.supplement_number ? `${this.state.activePma.pma_number} ${this.state.activePma.supplement_number}` : this.state.activePma.pma_number) : ""
    return(
      <React.Fragment>

        {this.state.openSectorDialog ?
          <Dialog
            openDialog={this.state.openSectorDialog}
            dialogTitle={`Add Sectors for ${this.state.checkedPmaApprovals.length > 0 ? `${this.state.checkedPmaApprovals.length} PMA Approvals` : pmaNumberDisplay}`}
            handleAddClick={this.state.checkedPmaApprovals.length > 0 ? this.handleAddAllClick : this.handleAddClick}
            handleClose={this.handleCloseDialog}
            dialogContent={
              <SectorCheckboxList
                sectors={this.props.sectors}
                sectorIds={this.state.sectorIds}
                suggestedSectors={this.props.product_sectors}
                handleCheckboxSelect={this.handleSectorClick}
              />
            }
          />
        : ''}

        <Paper fullHeight>

          <Box height="100%">

            <Box mb={2}>
              <Tabs variant="fullWidth" value={this.state.view} onChange={this.handleActiveIndexUpdate} className='bb b--black-10' aria-label="pma tabs">
                <Tab label='Associations' value='associations' />
                <Tab label='Predictions' value='predictions' />
              </Tabs>
            </Box>

            {this.state.view == "predictions" ? 
              <Flex mb={2}>
                <Box width={1/4}>
                  <RaisedButton onClick={this.handleAddAllPmaClick}>
                    Add all
                  </RaisedButton>
                </Box>

                <Box width={1/4} ml={3}>
                  <RaisedButton color="default" onClick={this.handleReviewedClick}>
                    Mark as reviewed
                  </RaisedButton>
                </Box>
              </Flex>
            : ""}

            <Box overflow="auto" height="90%">
              {this.state.view == "associations" ? 
                <PmaTable 
                  pmaApprovals={this.state.pmaApprovals}
                  checkedPmaApprovals={this.state.checkedPmaApprovals}
                  handleDeleteClick={this.handleDeleteClick}
                  handleSectorDelete={this.handleSectorDelete}
                  handlePmaApprovalCheckboxClick={this.handlePmaApprovalCheckboxClick}
                />
              : 
                <PmaPredictionsTable 
                  pmaApprovals={this.state.pmaPredictions}
                  checkedPmaApprovals={this.state.checkedPmaApprovals}
                  handleDeletePredictionClick={this.handleDeletePredictionClick}
                  handleAddPmaClick={this.handleAddPmaClick}
                  handleAddAllPmaClick={this.handleAddAllPmaClick}
                  handlePmaApprovalCheckboxClick={this.handlePmaApprovalCheckboxClick}
                />
              }
            </Box>
          </Box>
        </Paper>
      </React.Fragment>
    )
  }
}


export default withSnackbar(Container)
