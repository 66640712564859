import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import EntityLayout from "../../EntityLayout"
import Paper from "../../Paper"
import Flex from "../../Flex"
import Box from "../../Box"
import { List, ListItem, ListItemText, ListItemLink } from "../../lists"
import { Button } from "../../buttons"
import { Table, TableRow, TableHead, TableBody, TableCell } from "../../tables"
import ListSubheader from '@material-ui/core/ListSubheader';
import Typography from "@material-ui/core/Typography"
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Link from "../../Link"
import DeleteButton from "../DeleteButton"
import update from "immutability-helper"

export default class Profile extends Component {
  constructor(props) {
    super(props)
    this.handleDeletePublicationSector = this.handleDeletePublicationSector.bind(this)
    this.handleDeletePublicationProduct = this.handleDeletePublicationProduct.bind(this)
    this.handleDeletePublicationTrial = this.handleDeletePublicationTrial.bind(this)
    this.state = { publicationSectors: this.props.publication_sectors, publicationProducts: this.props.publication_products, publicationTrials: this.props.publication_trials }
  }

  handleDeletePublicationTrial(publicationTrial) {
    $.ajax({
      type: 'PUT',
      url: `/admin/publication_trials/${publicationTrial.id}`,
      data: {
        publication_trial: {
          deleted: true, deleted_by: this.props.current_user.id
        }
      },
      dataType: 'json',
      success: (data) => {
        let index = this.state.publicationTrials.indexOf(publicationTrial)

        let publicationTrials = update(this.state.publicationTrials, {
          $splice: [[index,1]]
        })

        this.setState({
          publicationTrials: publicationTrials
        })
      },
      error: (data) => {
        this.setState({
          showSnackbar: true,
          snackbarText: "The sector could not be removed. Please try again."
        })
      },
      complete: (data) => {
        setTimeout(() => {
          this.setState({
            showSnackbar: false,
            snackbarText: ''
          })
        }, 5000)
      }
    })
  }

  handleDeletePublicationProduct(publicationProduct) {
    $.ajax({
      type: 'PUT',
      url: `/admin/publication_products/${publicationProduct.id}`,
      data: {
        publication_product: {
          deleted: true, deleted_by: this.props.current_user.id
        }
      },
      dataType: 'json',
      success: (data) => {
        let index = this.state.publicationProducts.indexOf(publicationProduct)

        let publicationProducts = update(this.state.publicationProducts, {
          $splice: [[index,1]]
        })

        this.setState({
          publicationProducts: publicationProducts
        })
      },
      error: (data) => {
        this.setState({
          showSnackbar: true,
          snackbarText: "The sector could not be removed. Please try again."
        })
      },
      complete: (data) => {
        setTimeout(() => {
          this.setState({
            showSnackbar: false,
            snackbarText: ''
          })
        }, 5000)
      }
    })
  }

  handleDeletePublicationSector(publicationSector) {
    $.ajax({
      type: 'PUT',
      url: `/admin/publication_sectors/${publicationSector.id}`,
      data: {
        publication_sector: {
          deleted: true, deleted_by: this.props.current_user.id
        }
      },
      dataType: 'json',
      success: (data) => {
        let index = this.state.publicationSectors.indexOf(publicationSector)

        let publicationSectors = update(this.state.publicationSectors, {
          $splice: [[index,1]]
        })

        this.setState({
          publicationSectors: publicationSectors
        })
      },
      error: (data) => {
        this.setState({
          showSnackbar: true,
          snackbarText: "The sector could not be removed. Please try again."
        })
      },
      complete: (data) => {
        setTimeout(() => {
          this.setState({
            showSnackbar: false,
            snackbarText: ''
          })
        }, 5000)
      }
    })
  }

  render() {
    return(
      <div>

        <EntityLayout>
          <Box className='w-75'>

            <ExpansionPanel defaultExpanded={true}>
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} ariacontrols={`products-panel`} id={`products-header`}>
                <Flex>
                  <Box>
                    <Typography className="f4">
                      Sectors
                    </Typography>
                  </Box>

                  <Box ml={4}>
                    <Button onClick={() => { this.setState({ addingSector: true })}}>
                      Add Sector
                    </Button>
                  </Box>
                </Flex>
              </ExpansionPanelSummary>

              <ExpansionPanelDetails>
                {this.state.publicationSectors.length > 0 ?
                  <Table size='small'>
                    <TableHead>
                      <TableRow>
                        <TableCell>Sector</TableCell>
                        <TableCell>Search Term</TableCell>
                        <TableCell>Score</TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {this.state.publicationSectors.map((publicationSector) =>
                        <TableRow key={publicationSector.id}>
                          <TableCell>{publicationSector.abbr}</TableCell>
                          <TableCell>{publicationSector.search_term}</TableCell>
                          <TableCell>{publicationSector.normalized_score}</TableCell>
                          <TableCell>
                            <DeleteButton onClick={() => { this.handleDeletePublicationSector(publicationSector) }} />
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                :
                  <Box>No Sectors</Box>
                }
              </ExpansionPanelDetails>

            </ExpansionPanel>


            <ExpansionPanel defaultExpanded={true}>
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} ariacontrols={`products-panel`} id={`products-header`}>
                <Typography className="f4">
                  Products
                </Typography>
              </ExpansionPanelSummary>

              <ExpansionPanelDetails>
                {this.state.publicationProducts.length > 0 ?
                  <Table size='small'>
                    <TableHead>
                      <TableRow>
                        <TableCell>Product</TableCell>
                        <TableCell>Search Term</TableCell>
                        <TableCell>Score</TableCell>
                        <TableCell>User</TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {this.state.publicationProducts.map((publicationProduct) =>
                        <TableRow key={publicationProduct.id}>
                          <TableCell>{publicationProduct.short_name}</TableCell>
                          <TableCell>{publicationProduct.search_term}</TableCell>
                          <TableCell>{publicationProduct.normalized_score}</TableCell>
                          <TableCell>{publicationProduct.first_name ? displayInitials(publicationProduct.first_name, publicationProduct.last_name) : "automated"}</TableCell>
                          <TableCell>
                            <DeleteButton onClick={() => { this.handleDeletePublicationProduct(publicationProduct) }} />
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                : <Box>No Products</Box>}
              </ExpansionPanelDetails>

            </ExpansionPanel>

            <ExpansionPanel defaultExpanded={true}>
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} ariacontrols={`trials-panel`} id={`trials-header`}>
                <Typography className="f4">
                  Trials
                </Typography>
              </ExpansionPanelSummary>

              <ExpansionPanelDetails>
                {this.state.publicationTrials.length > 0 ?
                  <Table size='small'>
                    <TableHead>
                      <TableRow>
                        <TableCell>Product</TableCell>
                        <TableCell>Search Term</TableCell>
                        <TableCell>Score</TableCell>
                        <TableCell>User</TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {this.state.publicationTrials.map((publicationTrial) =>
                        <TableRow key={publicationTrial.id}>
                          <TableCell>{publicationTrial.short_name}</TableCell>
                          <TableCell>{publicationTrial.search_term}</TableCell>
                          <TableCell>{publicationTrial.normalized_score}</TableCell>
                          <TableCell>{publicationTrial.first_name ? displayInitials(publicationTrial.first_name, publicationTrial.last_name) : "automated"}</TableCell>
                          <TableCell>
                            <DeleteButton onClick={() => { this.handleDeletePublicationTrial(publicationTrial) }} />
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                :
                  <Box>No trials</Box>
                }
              </ExpansionPanelDetails>

            </ExpansionPanel>

            {this.props.publication_text ?
              <ExpansionPanel defaultExpanded={true}>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} ariacontrols={`text-panel`} id={`text-header`}>
                  <Typography className="f4">
                    Text
                  </Typography>
                </ExpansionPanelSummary>

                <ExpansionPanelDetails>
                  <Box>
                    <p>{this.props.publication_text}</p>
                  </Box>
                </ExpansionPanelDetails>

              </ExpansionPanel>
            : ''}

            {this.props.authors.length > 0 ?
              <ExpansionPanel defaultExpanded={true}>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} ariacontrols={`text-panel`} id={`text-header`}>
                  <Typography className="f4">
                    Authors
                  </Typography>
                </ExpansionPanelSummary>

                <ExpansionPanelDetails>
                  <Box>
                    {this.props.authors.map((personName) =>
                      <Box key={personName.id}>{personName.text}</Box>
                    )}
                  </Box>
                </ExpansionPanelDetails>

              </ExpansionPanel>
            : ''}

            {this.props.mesh_terms.length > 0 ?
              <ExpansionPanel defaultExpanded={true}>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} ariacontrols={`text-panel`} id={`text-header`}>
                  <Typography className="f4">
                    Keywords
                  </Typography>
                </ExpansionPanelSummary>

                <ExpansionPanelDetails>
                  <Box>
                    {this.props.keywords.map((keyword) =>
                      <div key={keyword.id}>{keyword.name}</div>
                    )}
                  </Box>
                </ExpansionPanelDetails>

              </ExpansionPanel>
            : ''}


            {this.props.mesh_terms.length > 0 ?
              <ExpansionPanel defaultExpanded={true}>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} ariacontrols={`text-panel`} id={`text-header`}>
                  <Typography className="f4">
                    Mesh Terms
                  </Typography>
                </ExpansionPanelSummary>

                <ExpansionPanelDetails>
                  <Box>
                    {this.props.mesh_terms.map((meshTerm) =>
                      <div key={meshTerm.id}>{meshTerm.name}</div>
                    )}
                  </Box>
                </ExpansionPanelDetails>

              </ExpansionPanel>
            : ''}
          </Box>

        </EntityLayout>
      </div>
    )
  }
}
