import React, { Fragment } from "react"
import Box from "../Box"
import { Table, TableHead, TableRow, TableCell, TableBody, TableHeadSort } from "../tables"
import PatentRow from "./PatentRow"
import SearchPatentRow from "./SearchPatentRow"
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TablePaginationActions from "../tables/TablePaginationActions"
import LinearProgress from '@material-ui/core/LinearProgress';


export default function PatentsTable(props) {

  return(
    <Table stickyHeader={true} size='small'>

      <TableHeadSort
        headers={[{ name: "html_patent_no", label: "#" }, { name: "title", label: "Patent" }, { name: "company_name", label: "Company" }, { name: "effective_date_str", label: "Date" }]}
        order={props.order}
        orderBy={props.orderBy}
        onRequestSort={props.handleRequestSort}
      />

      <TableBody>
        <Fragment>
          {props.loading ?
            <TableRow hover={false}>
              <TableCell noBorder colSpan={5}>
                <LinearProgress />
              </TableCell>
            </TableRow>
          : ""}
          {props.patents.map((patent) =>
            <PatentRow
              key={patent._id || patent.id}
              patent={patent._source ? patent._source : patent}
              sector={props.sector}
            />
          )}
        </Fragment>
      </TableBody>
      <TableFooter>
        <TablePagination
          colSpan={5}
          count={props.patentsCount}
          rowsPerPage={100}
          page={props.page}
          SelectProps={{
            inputProps: { 'aria-label': 'rows per page' },
            native: true,
          }}
          onChangePage={props.handleChangePage}
          onChangeRowsPerPage={props.handleChangeRowsPerPage}
          ActionsComponent={TablePaginationActions}
        />
      </TableFooter>
    </Table>
  )
}
