import React from "react"
import Select from "../Select"
import Flex from "../Flex"
import Box from "../Box"

export default function CompetitorFilters(props) {
  return (
    <Flex alignItems='center'>
      <Box width={1/5} mr={2}>
        <Select
          label='Regions' 
          name='world_region_id'
          value={props.worldRegion} 
          entities={props.worldRegions}
          handleChange={props.handleFilterChange}
        />
      </Box>
      
      <Box width={1/5}>
        <Select
          label='Target Markets' 
          name='target_market_id'
          value={props.targetMarket} 
          entities={props.targetMarkets}
          handleChange={props.handleFilterChange}
          includeBlank={true}
          blankOption='All'
        />
      </Box>
      
      <Box width={1/5} mx={2}>
        <Select
          label='Regulatory Statuses' 
          name='regulatory_status_id'
          value={props.regulatoryStatus} 
          entities={props.regulatoryStatuses}
          handleChange={props.handleFilterChange}
          includeBlank={true}
          blankOption='All'
        />
      </Box>
      
      
    </Flex>
  )
}
