import React from "react"
import ReactDOM from "react-dom"
import Typography from "@material-ui/core/Typography"
import Flex from "../Flex"
import Box from "../Box"
import Card from "../cards/Card"
import CardSection from "../cards/CardSection"
import SidebarSection from "../SidebarSection"


export default function PatentCard(props) {
  console.log(props.patentEntity)
  return(
    <Card>

      <Flex>
        <Box>
          <Typography variant="body1" component="body1">
            {props.patentEntity.patent_title}
          </Typography>
        </Box>
      </Flex>

      <Flex>
        <Box>
          {props.patent.kind}
        </Box>

        <Box ml='auto'>
          {`# ${props.patent.html_patent_no}`}
        </Box>
      </Flex>

      <Box mt={2}>
        {props.patent.id}
      </Box>

    </Card>
  )
}
