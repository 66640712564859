import React, { Component } from "react"
import PropTypes from "prop-types"
import ReactDOM from "react-dom"
import Paper from "../Paper"
import Flex from "../Flex"
import Box from "../Box"
import AssociationsContainer from "./AssociationsContainer"
import PersonCard from "./PersonCard"


export default class Profile extends Component {
  render() {
    return (
      <React.Fragment>

        <Flex>
          <Box width={[1, 3/5]}>
            <AssociationsContainer
              abstracts={this.props.abstracts}
              trials={this.props.trials}
              products={this.props.products}
              manuscripts={this.props.manuscripts}
              patents={this.props.patents}
              nihGrants={this.props.nih_grants}
              sector={this.props.sector}
            />
          </Box>

          <Box width={[1, '33%']} ml='auto'>
            <PersonCard
              person={this.props.person}
              topConference={this.props.top_conference}
              topJournal={this.props.top_journal}
              topSite={this.props.top_site}
            />
          </Box>
        </Flex>

      </React.Fragment>
    );
  }
}
