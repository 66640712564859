import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Paper from "../../Paper"
import Box from "../../Box"
import { RaisedButton, Button } from "../../buttons"
import { List } from "../../lists"
import SectorListItem from "../../sectors/SectorListItem"
import Chip from "@material-ui/core/Chip"
import DoneIcon from "@material-ui/icons/Done"

export default class ControlPanel extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    console.log(this.props.disableAssociate)
    return(
      <Fragment>

        <Paper>

          {this.props.handleAssociateClick ?
            <Box width={1} mb={2}>
              <RaisedButton handleClick={this.props.handleAssociateClick} disabled={this.props.disableAssociate}>
                Associate
              </RaisedButton>
            </Box>
          : ""}

          {this.props.handleCompleteClick ?
            <Box width={1}>

              <Button variant={this.props.completed ? 'contained' : 'outlined'} color={this.props.completed ? 'primary' : 'default'} onClick={this.props.handleCompleteClick}>
                {this.props.completed ? 'Completed' : 'Mark Complete'}
              </Button>

            </Box>
          : ''}


          {this.props.mentioned ? <Box width={1} my={3}>
            <RaisedButton color='default' handleClick={this.props.handleAssociateClick}>
              Mentioned
            </RaisedButton>
          </Box> : ''}

          {this.props.handleUnassociateClick ? <Box width={1}>
            <RaisedButton color='default' handleClick={this.props.handleUnassociateClick}>
              Unassociate
            </RaisedButton>
          </Box> : ''}

          {this.props.handleRemoveClick ?
            <Box width={1}>
              <RaisedButton color='default' handleClick={this.props.handleRemoveClick}>
                Remove
              </RaisedButton>
            </Box>
          : ''}

          {this.props.handleReviewedClick ?
            this.props.reviewed ?
              <Box display="flex" justifyContent='center'>
                <Chip
                  icon={<DoneIcon />}
                  label="Reviewed"
                  onDelete={this.handleReviewedDeleteClick}
                />
              </Box>
            :
              <Box width={1}>
                <RaisedButton handleClick={this.props.handleReviewedClick}>
                  Mark as Reviewed
                </RaisedButton>
              </Box>
          : ''}

          {this.props.handleAliasCreationClick ? 
            <Box mt={2} width={1}>
              <RaisedButton handleClick={this.props.handleAliasCreationClick} disabled={this.props.associatedCompany ? false : true}>
                Add Assignee as Alias
              </RaisedButton>
            </Box>
          : ''}

          {this.props.sectors ?
            <Box mt={4}>
              <List>
                {this.props.sectors.map((sector) =>
                  <SectorListItem
                    key={sector.id}
                    sector={sector}
                    checked={this.props.entitySectors.length > 0 ? (this.props.entitySectors.filter((entitySector) => entitySector.sector_id == sector.id)[0] ? true : false) : (this.props.checkedSectors && this.props.checkedSectors.length > 0 ? this.props.checkedSectors.filter((checkedSector) => checkedSector == sector.id)[0] : false)}
                    handleCheckboxSelect={this.props.handleSectorCheckboxSelect}
                  />
                )}
              </List>
            </Box>
          : ""}

        </Paper>

      </Fragment>
    )
  }
}


ControlPanel.defaultProps = {
  disableAssociate: false
}
