import React, { Fragment } from "react"
import Paper from "../../Paper"
import Box from "../../Box"
import Tabs from "../../tabs/Tabs"
import Tab from "../../tabs/Tab"
import PredictedProductsList from "./PredictedProductsList"
import ProductsList from "./ProductsList"
import AssociatedProductsList from "./AssociatedProductsList"
import TextInput from "../../TextInput"
import SearchForm from "../../search/Form"


export default function ProductsPanel(props) {
  return (
    <React.Fragment>
      <Paper fullHeight>

        <Box height="100%">
          <Tabs fullWidth value={props.activeIndex} onChange={props.handleActiveIndexUpdate} className='bb b--black-10' aria-label="entities tabs">
            <Tab label='Predicted' />
            <Tab label='Products' />
            <Tab label='Associated' />
          </Tabs>

          <Box mt={2} height="90%" overflow='auto'>
            <Box height="80%">

              {props.activeIndex == 0 ?
                <PredictedProductsList
                  predictedProducts={props.predictedProducts}
                  activeProducts={props.activeProducts}
                  trialProducts={props.trialProducts}
                  handleCheckboxSelect={props.handleCheckboxSelect}
                />
              : ''}

              {props.activeIndex == 1 ?

                <Fragment>
                  <SearchForm 
                    query={props.query}
                    placeholderText="Search products"
                    handleSearchChange={props.handleProductSearchChange}
                    handleSearchClick={props.handleProductSearchClick}
                  />

                  <ProductsList
                    products={props.products}
                    activeProducts={props.activeProducts}
                    trialProducts={props.trialProducts}
                    handleCheckboxSelect={props.handleCheckboxSelect}
                  />
                </Fragment>
              : ''}

            {props.activeIndex == 2 ?
              <AssociatedProductsList
                trialProducts={props.trialProducts}
                handleInvestigatedSwitchChange={props.handleInvestigatedSwitchChange}
                handleUnassociateClick={props.handleUnassociateClick}
              />
            : ''}

            </Box>
          </Box>
        </Box>

      </Paper>
    </React.Fragment>
  )
}
