import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Paper from "../../Paper"
import Flex from "../../Flex"
import Box from "../../Box"
import EntityCard from "../EntityCard"

export default class PatentCard extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    console.log(this.props.patent)
    return(
      <Fragment>
        <EntityCard className='mb4 pointer w-90 center' handleClick={this.props.handlePatentCardClick} active={this.props.active}>

          <Flex className='mb2'>
            <Box className='w-25 mr2'>
              <label className='b'>#</label>
            </Box>
            <Box className='w-75 ml-auto'>
              {this.props.patent.html_patent_no}
            </Box>
          </Flex>

          <Flex className='mb2'>
            <Box className='w-25 mr2'>
              <label className='b'>Title</label>
            </Box>
            <Box className='w-75 ml-auto'>
              {this.props.patent.title}
            </Box>
          </Flex>

          <Flex className='mb2'>
            <Box className='w-25 mr2'>
              <label className='b'>Assignee</label>
            </Box>
            <Box className='w-75'>
              {this.props.patent.assignee_name}
            </Box>
          </Flex>

          <Flex className='mb2'>
            <Box className='w-25 mr2'>
              <label className='b'>Applicant</label>
            </Box>
            <Box className='w-75'>
              {this.props.patent.orgname}
            </Box>
          </Flex>

          <Flex className='mb2'>
            <Box className='w-25 mr2'>
              <label className='b'>Date</label>
            </Box>
            <Box className='w-75'>
              {this.props.patent.effective_date_str}
            </Box>
          </Flex>

          <Flex className='mb2'>
            <Box className='w-25 mr2'>
              <label className='b'>Rank</label>
            </Box>
            <Box className='w-75'>
              {this.props.patent.rank ? 'N/A' : this.props.patent.rank}
            </Box>
          </Flex>

        </EntityCard>
      </Fragment>
    )
  }
}


{/* <Table>

  <TableBody>

    <TableRow>
      <TableCell className='br'>
        #
      </TableCell>
      <TableCell>
        {this.props.patent.html_patent_no}
      </TableCell>
    </TableRow>

    <TableRow>
      <TableCell className='br'>
        Title
      </TableCell>
      <TableCell>
        {this.props.patent.title}
      </TableCell>
    </TableRow>

    <TableRow>
      <TableCell className='br'>
        Assignee
      </TableCell>
      <TableCell>
        {this.props.patent.assignee}
      </TableCell>
    </TableRow>

    <TableRow>
      <TableCell className='br'>
        Applicant
      </TableCell>
      <TableCell>
        {this.props.patent.orgname}
      </TableCell>
    </TableRow>

  </TableBody>

</Table> */}
