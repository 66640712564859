import React from "react"
import Link from "../Link"
import { TableRow, TableCell } from "../tables"
import Box from "../Box"
import LinkIcon from '@material-ui/icons/Link';

export default function ListRecord(props) {
  let rootUrl = `/sectors/${props.sector.abbr}/abstracts`
  let meetingUrl = `${rootUrl}?meeting_ids[]=${props.abstract.science_meeting_id}&years[]=${props.abstract.smo_year}`
  return(
    <TableRow>
      
      <TableCell>
        <Link href={`${rootUrl}/${props.abstract.id}`}>
          <Box>
            {props.abstract.title}
          </Box>
        </Link>
      </TableCell>

      <TableCell>
        <Link href={`/sectors/${props.sector.abbr}/people/${props.abstract.people_id}`}>
          <Box>
            {props.abstract.presenter_name}
          </Box>
        </Link>
      </TableCell>

      <TableCell>
        <Link href={meetingUrl}>
          {props.abstract.meeting_name_display || props.abstract.meeting_name}
        </Link>
      </TableCell>

      {/* <TableCell>{props.abstract.type_name}</TableCell> */}

      <TableCell>
        {props.abstract.date ? dateFormat(props.abstract.date) : 'NA'}
      </TableCell>

      <TableCell>
        {props.abstract.url ? 
          <Link href={`/sectors/${props.sector.abbr}/abstracts/${props.abstract.id}/view_source`} target="_blank">
            <LinkIcon />
          </Link>
        : ''}
      </TableCell>

      {/* <TableCell align='right'>
        {props.abstract.url ?
          <IconButton key='launch' icon='launch' />
        : ''}
      </TableCell> */}

    </TableRow>
  )
}
