import React, { Fragment } from "react"
import Flex from "../Flex"
import Box from "../Box"
import { ListItem, ListItemText } from "../lists"
import Typography from "@material-ui/core/Typography"
import Link from "../Link"

export default function ItemDisplay(props) {
  let highlightResult = props.publicationParent.highlight ? props.publicationParent.highlight : null

  return(
    <ListItem divider>
      <ListItemText>
        <Box>
          <Typography variant="body1">
            <Link href={`/sectors/${props.sector.abbr}/publications/${props.publication.manuscript_id}`} textColor="mmi-blue">
              {highlightResult && highlightResult["title.analyzed"] ?
                <span
                  dangerouslySetInnerHTML={{__html: highlightResult["title.analyzed"] }}
                />
              : props.publication.title}
            </Link>
          </Typography>
        </Box>

        <Flex>

          <Box mr={2}>
            <Typography variant="caption" color="textSecondary">
              {props.publication.journal_name}
            </Typography>
          </Box>

          <Box mr={2}>
            <Typography variant="caption" color="textSecondary">
              {props.publication.published_date}
            </Typography>
          </Box>

          <Box mr={2}>
            <Typography variant="caption" color="textSecondary">
            </Typography>
          </Box>

        </Flex>

        <Box py={0.5} width={[1/2, 3/4]}>
          <Typography variant="body2">
            {highlightResult && highlightResult["text.analyzed"] ?
              <span
                dangerouslySetInnerHTML={{__html: highlightResult["text.analyzed"] }}
              />
            : props.publication.text ? truncateString(props.publication.text, 200) : ""}
          </Typography>
        </Box>

        <Box width={[1/2, 3/4]}>
          <Typography variant="caption" color="textSecondary">
            {props.publication.authors && props.publication.authors.length > 0 ? props.publication.authors.map((author, index) => {
              return(
                <Fragment key={author}>
                  {highlightResult && highlightResult["authors.analyzed"] ?
                    <span
                      dangerouslySetInnerHTML={{__html: highlightResult["authors.analyzed"] }}
                    />
                  : author}
                  {index < props.publication.authors.length - 1 ? "; " : ""}
                </Fragment>
              )
            }
            ) : ""}
          </Typography>
        </Box>

        {/* <Box>
          <Typography variant="body2">
            <Link href={`/sectors/${props.sector.abbr}/companies/${props.patent.company_id}`} textColor="mmi-blue">
              {highlightResult && highlightResult.company_name ?
                <span
                  dangerouslySetInnerHTML={{__html: highlightResult.company_name }}
                />
              : props.patent.company_name}
            </Link>
          </Typography>
        </Box> */}
      </ListItemText>
    </ListItem>
  )
}
