import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Paper from "../../Paper"
import update from "immutability-helper"
import Select from "../../Select"
import TextInput from "../../TextInput"
import DatePicker from "../../DatePicker"
import Flex from "../../Flex"
import Box from "../../Box"
import InputAdornment from '@material-ui/core/InputAdornment';
import ButtonLayout from "../ButtonLayout"
import { RaisedButton, Button } from "../../buttons"


export default class EditContainer extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    return(
      <Paper minFullHeight>

        <Flex width={1} mb={3}>
          <Box width={1/2} mr={4}>
            <Select
              entities={this.props.companies}
              label='Company'
              name='company_id'
              value={this.props.funding_event.company_id}
              handleChange={this.handleCompanyChange}
              disabled={true}
            />
          </Box>

          <Box width={1/2}>
            <Select
              entities={this.props.funding_event_kinds}
              label='Kind'
              name='kind'
              value={this.props.funding_event.funding_event_kind_id}
              handleChange={this.handleKindChange}
            />
          </Box>

        </Flex>

        <Flex width={1} mb={5} alignItems='center'>
          <Box width={1/3}>
            <TextInput
              name='amount'
              label='Amount'
              value={this.props.funding_event.amount}
              onChange={this.handleChange}
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
              }}
              fullWidth
            />
          </Box>

          <Box width={1/3}>
            <DatePicker
              name='date'
              label='Date'
              handleChange={this.handleDateChange}
              value={this.props.funding_event.date}
            />
          </Box>

          <Box width={1/3}>
            <DatePicker
              name='closed_date'
              label='Closed Date'
              handleChange={this.handleDateChange}
              value={this.props.funding_event.closed_date}
            />
          </Box>
        </Flex>


        <Box pb={5}>
          <Flex alignItems='center'>
            <Box>
              Investors
            </Box>

            <Box ml='auto'>
              <Button>Add new</Button>
            </Box>
          </Flex>

          {this.props.investments.map((investment) =>
            <Box width={1/2} mb={3} key={investment.id}>
              <Select
                entities={this.props.investors}
                label='Investor'
                name='investor_id'
                value={investment.investor_id}
                handleChange={this.handleCompanyChange}
                disabled={true}
              />
            </Box>
          )}
        </Box>

        <ButtonLayout>
          <RaisedButton handleClick={this.handleSaveClick}>
            Save Funding Event
          </RaisedButton>
        </ButtonLayout>
      </Paper>
    )
  }
}
