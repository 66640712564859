import React, { Component } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Card from "./Card"

class EntityCard extends Component {
  constructor(props) {
    super(props)
    this.handleMouseEnter = this.handleMouseEnter.bind(this)
    this.handleMouseLeave = this.handleMouseLeave.bind(this)
    this.handleClick = this.handleClick.bind(this)
    this.state = { hover: false, active: false }
  }
  
  UNSAFE_componentWillMount() {
    if (this.props.activeEntity && this.props.entity.id == this.props.activeEntity.id) {
      this.setState({active: true})
    } else {
      this.setState({active: false})
    }
  }
  
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.activeEntity && nextProps.entity.id == nextProps.activeEntity.id) {
      this.setState({active: true})
    } else {
      this.setState({active: false})
    }
  }
  
  handleMouseEnter(e) {
    this.setState({ hover: true })
  }
  
  handleMouseLeave(e) {
    this.setState({ hover: false })
  }
  
  handleClick(e) {
    this.props.handleClick(this.props.entity)
  }
  
  render() {
    let elevation = (this.state.hover || this.state.active) ? 8 : 1
    let classStyle = `pointer ${(this.props.classStyle || this.props.classStyles) ? (this.props.classStyle || this.props.classStyles) : ''}`
    return (
      <Card classStyles={classStyle} handleMouseEnter={this.handleMouseEnter} handleMouseLeave={this.handleMouseLeave} handleClick={this.handleClick} elevation={elevation}>
        {this.props.children}
      </Card>
    )
  }
}

export default EntityCard
