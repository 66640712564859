import React from "react"
import ResultItemContainer from "../search/ResultItemContainer"
import Typography from "@material-ui/core/Typography"
import Flex from "../Flex"
import Box from "../Box"

export default function SearchItem(props) {

  let url = props.admin ? `/admin/ictrp_trials/${props.result.id}` : props.result.url

  function renderSearchDetails(result) {
    return(
      <Flex>
        <Box mr={2}>
          {renderSearchDetailValue(result.regulatory_id)}
        </Box>
        <Box>
          {renderSearchDetailValue(result.recruitment_status)}
        </Box>
      </Flex>
    )
  }

  function renderSearchDetailValue(searchDetailValue) {
    return (
      <Typography variant="body2" color="textSecondary">
        {searchDetailValue}
      </Typography>
    );
  }

  return(
    <ResultItemContainer 
      searchItemType={props.result.record_type}
      searchTitle={props.result.public_title}
      date={props.result.last_changed_date}
      searchDescription={props.result.scientific_title}
      url={url}
      searchDetails={renderSearchDetails(props.result)}
    />
  )
}