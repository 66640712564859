import React, { Fragment } from "react"
import SectorCheckbox from "./SectorCheckbox"

export default function CheckboxList(props) {

  return(
    <Fragment>

      {props.sectors.map((sector) =>
        <SectorCheckbox
          key={sector.id}
          checked={props.sectorIds.includes(sector.id)}
          sector={sector}
          predicted={props.suggestedSectors ? props.suggestedSectors.filter((suggestedSector) => suggestedSector.sector_id == sector.id)[0] : null}
          handleCheckboxSelect={props.handleCheckboxSelect}
        />
      )}

    </Fragment>
  )
}
