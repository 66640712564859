import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Paper from "../../Paper"
import SourceForm from "../SourceForm"
import ProfileForm from "./ProfileForm"
import { Tabs, Tab } from '../../tabs';
import Snackbar from "../../Snackbar"
import Chip from '@material-ui/core/Chip';
import update from "immutability-helper"


export default class Index extends Component {
  constructor(props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
    this.handleSaveClick = this.handleSaveClick.bind(this)
    this.handleSourceChange = this.handleSourceChange.bind(this)
    this.handleActiveIndexUpdate = this.handleActiveIndexUpdate.bind(this)
    this.handleSwitchChange = this.handleSwitchChange.bind(this)
    this.state = { company: this.props.company, source: null, activeIndex: "profile", showSnackbar: false, snackbarText: '' }
  }

  componentDidMount() {
    let company = update(this.state.company, {
      user_id: { $set: this.props.current_user.id }
    })
    this.setState({
      company: company
    })
  }

  handleActiveIndexUpdate(e, newValue) {
    this.setState({
      activeIndex: newValue
    })
  }

  handleChange(e) {
    const name = e.currentTarget.name
    const value = e.currentTarget.value


    let company = update(this.state.company, { [name]: { $set: value } })

    this.setState({
      company: company
    })

  }

  handleSwitchChange(e) {
    const checked = e.target.checked
    const name = e.target.name

    let company = update(this.state.company, { [name]: { $set: checked }})

    this.setState({
      company: company
    })
  }

  handleSaveClick(e) {
    if (this.state.source) {
      $.ajax({
        type: 'POST',
        url: `/admin/companies`,
        data: {
          company: this.state.company
        },
        dataType: 'json',
        success: (data) => {
          window.location.href = `/admin/companies/${data.company.id}/edit`
        },
        error: (data) => {
          console.log(data)
          this.setState({
            showSnackbar: true,
            snackbarText: 'The company could not be created. Please try again.'
          })
        },
        complete: (data) => {
          setTimeout(() => {
            this.setState({
              showSnackbar: false,
              snackbarText: ''
            })
          }, 4000)
        }
      })
    } else {
      this.setState({
        showSnackbar: true,
        snackbarText: 'You must choose a source before saving the company'
      })
      setTimeout(() => {
        this.setState({
          showSnackbar: false,
          snackbarText: ''
        })
      }, 4000)
    }
  }

  handleSourceChange(entity, sourceType) {
    if (sourceType == 'recently_used') {
      this.setState({
        source: entity
      })
    } else {
      $.get("/admin/sources/find_source", { source_type: sourceType, entity_id: entity.id }, (data) => {
        if (data.source) {
          let company = update(this.state.company, {
            source_id: { $set: data.source.source_id }
          })
          this.setState({
            source: data.source,
            company: company
          })
        } else {
          $.post("/admin/sources/filter", { source: { kind: sourceType, entity_id: entity.id } }, (data) => {
            let company = update(this.state.company, {
              source_id: { $set: data.source.source_id }
            })
            this.setState({
              source: data.source,
              company: company
            })
          })
        }
      }, 'json')
    }
  }

  render() {
    return(
      <Fragment>

        <Paper>

          <div className='mb4'>
            <Tabs variant="fullWidth" value={this.state.activeIndex} onChange={this.handleActiveIndexUpdate} className='bb b--black-10' aria-label="nav tabs">
              <Tab label='Source' value='source' />
              <Tab label='Profile' value='profile' />
            </Tabs>
          </div>

          <div className='mb4 tc'>
            {this.state.source ?
              <Chip
                label={this.state.source.source_title}
              />
            : <div>Please choose a source</div>}
          </div>

          {this.state.activeIndex == 'source' ?
            <SourceForm
              company={this.state.company}
              source={this.state.source}
              sources={null}
              sourceTypes={this.props.source_types}
              sourceType={this.props.source_type}
              redirectUrl='/admin/companies/new'
              handleSourceChange={this.handleSourceChange}
            />
          : ''}

          {this.state.activeIndex == 'profile' ?
            <ProfileForm
              newCompany={true}
              company={this.state.company}
              source={this.state.source}
              handleChange={this.handleChange}
              handleSaveClick={this.handleSaveClick}
              handleSwitchChange={this.handleSwitchChange}
            />
          : ''}

        </Paper>

        <Snackbar
          open={this.state.showSnackbar}
          message={this.state.snackbarText}
        />

      </Fragment>
    )
  }
}
