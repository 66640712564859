// https://material-ui.com/api/button/
import React from "react"
import MuiButton from '@material-ui/core/Button';
import clsx from "clsx"
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  lowercase: {
    textTransform: 'none'
  },
  abstractsCTA: {
    backgroundColor: '#5a65cd'
  }
}));


export default function Button(props) {
  const classes = useStyles(props);

  const { lowercase, color='primary', className, ...rest } = props
  return (
    <MuiButton color={color} className={clsx(className, { [classes.lowercase]: lowercase })} {...rest}>
      {props.children}
    </MuiButton>
  )
}
