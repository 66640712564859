import React, { Component } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import AlertDisplay from "./AlertDisplay"
import Box from "../Box"
import Link from "../Link"

class TrialDevelopmentAlert extends Component {
  constructor(props) {
    super(props)
  }

  groupBy(list, props) {
    return list.reduce((a, b) => {
       (a[b[props]] = a[b[props]] || []).push(b);
       return a;
    }, {});
  }

  render() {
    const groupedAlerts = this.groupBy(this.props.alerts, 'trial')
    const alerts = Object.keys(groupedAlerts).map((trial) =>
      <div key={trial} className='mv4'>
        <Box>
          <Link className='blue mb1' href={`/sectors/${this.props.sector.abbr}/trials/${groupedAlerts[trial][0].trial_id}`}>
            {trial}
          </Link>
        </Box>
        {groupedAlerts[trial].map((alert) =>
          <AlertDisplay key={alert.id} alert={alert} />
        )}
      </div>
    )

    return(
      <div>
        {alerts}
      </div>
    )
  }
}

export default TrialDevelopmentAlert
