import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Paper from "../../Paper"
import { Table, TableHead, TableRow, TableCell, TableBody, TableHeadSort, EmptyDataTableRow } from "../../tables"
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TablePaginationActions from "../../tables/TablePaginationActions"
import NewTrialRow from "./NewTrialRow"
import Flex from "../../Flex"
import Box from "../../Box"
import Link from "../../Link"
import Filters from "./Filters"
import update from "immutability-helper"
import { Tabs, Tab } from "../../tabs"
import Select from "../../Select"

export default class NewTrials extends Component {
  constructor(props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
    this.handleSearchClick = this.handleSearchClick.bind(this)
    this.handleRequestSort = this.handleRequestSort.bind(this)
    this.handleHideClick = this.handleHideClick.bind(this)
    this.desc = this.desc.bind(this)
    this.stableSort = this.stableSort.bind(this)
    this.getSorting = this.getSorting.bind(this)
    this.handleFilterChange = this.handleFilterChange.bind(this)
    this.getNewTrials = this.getNewTrials.bind(this)
    this.handleActiveIndexUpdate = this.handleActiveIndexUpdate.bind(this)
    this.handleSectorChange = this.handleSectorChange.bind(this)
    this.state = { trials: this.props.trials, query: '', order: 'desc', orderBy: 'date', activeIndex: 0, filterOptions: { relevantOptions: this.props.relevant_options, priorityOptions: this.props.priority_options }, filterValues: { relevant: this.props.relevant, priority: this.props.priority, start_date: this.props.start_date, end_date: this.props.end_date, sector_id: this.props.sector_id, page: this.props.page } }
  }

  handleChangePage(e, newPage) {
    let params = Object.assign({}, this.state.filterValues)

    // params["order_by"] = this.state.orderBy
    // params["order"] = this.state.order
    params["page"] = newPage

    this.getNewTrials(params)
  }

  handleChange(e) {
    const name = e.target.name
    const value = e.target.value

    this.setState({
      query: value
    })
  }

  handleFilterChange(name, value) {
    let params = Object.assign({}, this.state.filterValues)

    params[name] = value

    params['view'] = this.state.activeIndex == 0 ? 'predicted_products' : 'all'

    this.getNewTrials(params)
  }

  handleSectorChange(name, value) {
    let params = Object.assign({}, this.state.filterValues)

    params['sector_id'] = value

    let viewHash = { 0: 'predicted_products', 1: 'high_priority', 2: 'all' }

    params['view'] = viewHash[this.state.activeIndex]

    console.log(params)

    this.getNewTrials(params)
  }

  getNewTrials(params) {
    $.ajax({
      type: 'GET',
      url: '/admin/ct_gov_trials/new_trials',
      data: params,
      dataType: 'json',
      success: (data) => {
        this.setState({
          trials: data.new_trials,
          filterValues: params
        })
      }
    })
  }

  handleSearchClick(e) {
    $.ajax({
      type: 'GET',
      url: `/admin/ct_gov_trials/search`,
      data: {
        query: this.state.query
      },
      dataType: 'json',
      success: (data) => {
        this.setState({
          trials: data.trials
        })
      },
      error: (data) => {
        this.setState({
          showSnackbar: true,
          snackbarText: 'Search did not work. Please write to #bug-reports.'
        })
      },
      complete: (data) => {
        setTimeout(() => {
          this.setState({
            showSnackbar: false,
            snackbarText: ''
          })
        })
      }
    })
  }

  handleHideClick(ctGovTrial) {
    $.ajax({
      type: 'PUT',
      url: `/admin/ct_gov_trials/${ctGovTrial.id}`,
      data: {
        ct_gov_trial: {
          hide_trial: 1, relevant: false
        }
      },
      dataType: 'json',
      success: (data) => {
        let index = this.state.trials.indexOf(ctGovTrial)

        let trials = update(this.state.trials, {
          $splice: [[index,1]]
        })

        this.setState({
          trials: trials
        })
      }
    })
  }

  handleActiveIndexUpdate(e, newValue) {
    let sectorId = this.state.activeIndex == 2 ? null : this.state.filterValues.sector_id
    let viewHash = { 0: 'high', 1: 'all', 2: 'hidden' }
    $.ajax({
      type: 'GET',
      url: `/admin/ct_gov_trials/new_trials`,
      data: {
        view: viewHash[newValue], sector_id: sectorId
      },
      dataType: 'json',
      success: (data) => {
        this.setState({
          trials: data.new_trials,
          activeIndex: newValue
        })
      }
    })
  }

  desc(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = cmp(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
  }

  getSorting(order, orderBy) {
    return order === 'desc' ? (a, b) => this.desc(a, b, orderBy) : (a, b) => -this.desc(a, b, orderBy);
  }

  handleRequestSort(event, property) {
    const isDesc = this.state.orderBy === property && this.state.order === 'desc';

    this.setState({
      order: isDesc ? 'asc' : 'desc',
      orderBy: property
    })
  };

  render() {
    let headers = [ {label: 'NCT ID', name: 'nct_id' }, { label: 'Trial', name: 'brief_title' }, { label: 'Sponsor', name: 'sponsor' }, { label: 'Collaborators', name: 'nct_collaborators' }, { label: 'Interventions', name: 'nct_interventions' }, { label: 'Date', name: 'date' }, { label: 'Rank', name: 'rank', align: 'right' }, { label: 'Hide', name: 'hide', align: 'right' } ]

    if (this.state.activeIndex == 0) {
      headers.splice(5, 0, { label: 'Product', name: 'product_name' })
    }
    return(
      <Fragment>

        <Paper fullHeight={true}>

          <Box mb={3}>
            <Tabs variant="fullWidth" value={this.state.activeIndex} onChange={this.handleActiveIndexUpdate} className='bb b--black-10' aria-label="nav tabs">
              <Tab label='High Priority' />
              <Tab label='All' />
              <Tab label='Hidden' />
            </Tabs>
          </Box>

          <Flex>
            {this.state.activeIndex == 0 ?
              <Box width={1/5}>
                <Select
                  includeBlank
                  label='Sector'
                  name='sector_id'
                  value={this.state.filterValues.sector_id}
                  entities={this.props.sectors}
                  handleChange={this.handleSectorChange}
                />
              </Box>
            :
              <Filters
                filterOptions={this.state.filterOptions}
                filterValues={this.state.filterValues}
                query={this.state.query}
                handleChange={this.handleChange}
                handleSearchClick={this.handleSearchClick}
                handleFilterChange={this.handleFilterChange}
              />
            }

            <Box ml='auto'>
              {`${this.state.trials.length} trials`}
            </Box>
          </Flex>

          <Box overflow='auto' height="80%" mt={3}>
            <Table size='small' stickyHeader={true}>

              <TableHeadSort
                headers={headers}
                order={this.state.order}
                orderBy={this.state.orderBy}
                onRequestSort={this.handleRequestSort}
              />

              <TableBody>
                {this.state.trials.length > 0 ? this.stableSort(this.state.trials, this.getSorting(this.state.order, this.state.orderBy))
                  .map((trial) =>
                    <NewTrialRow
                      key={trial.id}
                      trial={trial}
                      view={this.state.view}
                      sector_ids={this.state.filterValues.sector_ids}
                      activeIndex={this.state.activeIndex}
                      handleHideClick={this.handleHideClick}
                    />
                ) : <EmptyDataTableRow colSpan={6} message='No trials found. Try changing the filters' />}
              </TableBody>

              <TableFooter>
                <TablePagination
                  colSpan={7}
                  count={this.state.trials.length}
                  rowsPerPage={100}
                  page={this.state.page}
                  SelectProps={{
                    inputProps: { 'aria-label': 'rows per page' },
                    native: true,
                  }}
                  onChangePage={this.handleChangePage}
                  onChangeRowsPerPage={this.handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableFooter>

            </Table>
          </Box>

        </Paper>
      </Fragment>
    )
  }
}
