import React from "react"
import DeleteButton from "../DeleteButton"
import { ListItemText } from "../../lists"
import ListItemLink from "../../lists/ListItemLink"
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction"

export default function AssociatedProductListItem(props) {

  function handleUnassociateClick(e) {
    props.handleProductDeleteClick(props.publicationProduct)
  }

  return (
    <ListItemLink href={`/admin/products/${props.publicationProduct.product_id}/edit`} target="_blank">

      <ListItemText
        primary={props.publicationProduct.short_name}
        secondary={props.publicationProduct.search_term}
      />

      <ListItemSecondaryAction>
        <DeleteButton onClick={handleUnassociateClick} />
      </ListItemSecondaryAction>

    </ListItemLink>
  )
}
