import React, { Component } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Flex from "./Flex"
import Box from "./Box"

class EntityLayout extends Component {
  constructor(props) {
    super(props)
  }
  
  render() {
    let columnStyle = this.props.reverseColumn ? 'flex-column-reverse' : 'flex-column'
    let breakpoint = this.props.breakpoint ? this.props.breakpoint : 'ns'
    return(
      <Flex className={`${columnStyle} flex-row-${this.props.breakpoint}`}>
        {this.props.children}
      </Flex>
    )
  }  
}

EntityLayout.propTypes = {
  breakpoint: PropTypes.string,
  reverseColumn: PropTypes.bool
}

EntityLayout.defaultProps = {
  breakpoint: 'ns',
  reverseColumn: false
}

export default EntityLayout
