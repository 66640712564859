import React, { Component } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"

class TrialTypeListDisplay extends Component {
  constructor(props) {
    super(props)
  }
  
  render() {
    const types = this.props.clinicalTypes.map((type) =>
      <div key={type.id}>{type.name}</div>
    )
    return(
      <div>{types}</div>
    )
  }
}

export default TrialTypeListDisplay
