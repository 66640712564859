import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Paper from "../Paper"
import CompareEntitiesContainer from "../compare/CompareEntitiesContainer"
import Dialog from "../Dialog"
import TableContainer from "./TableContainer"
import update from 'immutability-helper'
import EmptyMessage from "../compare/EmptyMessage"
import { RaisedButton } from "../buttons"
import Flex from "../Flex"
import Box from "../Box"
import Typography from "@material-ui/core/Typography"
import { withRouter } from "react-router-dom";
import Snackbar from "../Snackbar"
import { Tabs, Tab } from "../tabs"
import DataView from "../compare/DataView"
import NewDataView from "../compare/NewDataView"

class CompareContainer extends Component {
  constructor(props) {
    super(props)
    this.handleAddTrialClick = this.handleAddTrialClick.bind(this)
    this.handleTrialCheckboxClick = this.handleTrialCheckboxClick.bind(this)
    this.handleChipSelection = this.handleChipSelection.bind(this)
    this.handleAddClick = this.handleAddClick.bind(this)
    this.handleTrialRemovalClick = this.handleTrialRemovalClick.bind(this)
    this.handleCloseDialog = this.handleCloseDialog.bind(this)
    this.handleBulkTrialCheckboxClick = this.handleBulkTrialCheckboxClick.bind(this)
    this.handleActiveIndexUpdate = this.handleActiveIndexUpdate.bind(this)
    this.state = { openDialog: false, trials: this.props.trials, fields: this.props.all_fields, newTrials: [], attributeOptions: this.props.attribute_options, showSnackbar: false, snackbarText: '', activeIndex: 'data' }
  }

  handleTrialRemovalClick(trial) {
    let index = this.state.trials.indexOf(trial)

    let trials = update(this.state.trials, {
      $splice: [[index, 1]]
    })

    let url = this.props.trial ? `/sectors/${this.props.sector.abbr}/trials/${this.props.trial.id}/compare` : `/sectors/${this.props.sector.abbr}/trials/compare_trials`

    let queryParams = trials.map((trial) =>
      this.props.trial && this.props.trial.id == trial.trial_id ? null : `trial_ids[]=${trial.id}`
    )

    let flattenedQueryParams = [].concat.apply([], queryParams);

    this.props.history.push(`${url}?${flattenedQueryParams.join("&")}`);

    this.setState({
      trials: trials
    })
  }

  handleBulkTrialCheckboxClick(trialIds) {
    this.setState({
      newTrials: trialIds
    })
  }

  handleTrialCheckboxClick(trialId) {
    const index = this.state.newTrials.indexOf(trialId)

    let trials = ""
    if (index == -1) {
      trials = update(this.state.newTrials, {
        $push: [trialId]
      })
    } else {
      trials = update(this.state.newTrials, {
        $splice: [[index, 1]]
      })
    }

    this.setState({
      newTrials: trials
    })
  }

  handleAddClick(e) {
    let trialIds = this.state.trials ? this.state.trials.map((trial) => trial.trial_id || trial.id) : []
    let allTrialIds = [...trialIds, ...this.state.newTrials]
    let view = this.state.activeIndex == 'data' ? 'data' : 'design'
    $.ajax({
      type: 'GET',
      url: `/sectors/${this.props.sector.abbr}/trials/trials_for_compare`,
      data: {
        trial_ids: allTrialIds, view: view
      },
      dataType: 'json',
      success: (data) => {

        let url = this.props.trial ? `/sectors/${this.props.sector.abbr}/trials/${this.props.trial.id}/compare` : `/sectors/${this.props.sector.abbr}/trials/compare_trials`

        let queryParams = allTrialIds.map((trialId) =>
          `trial_ids[]=${trialId}`
        )

        let flattenedQueryParams = [].concat.apply([], queryParams);

        this.props.history.push(`${url}?${flattenedQueryParams.join("&")}`);

        this.setState({
          trials: data.trials,
          openDialog: false
        })
      },
      error: (data) => {
        this.setState({
          showSnackbar: true,
          snackbarText: 'There was an error adding the trial. Please try again.'
        })
      },
      complete: (data) => {
        setTimeout(() => {
          this.setState({
            showSnackbar: false,
            snackbarText: ''
          })
        }, 4000)
      }
    })
  }

  handleAddTrialClick(e) {
    e.preventDefault()
    this.setState({
      openDialog: true
    })
  }

  handleActiveIndexUpdate(e, newValue) {
    // let view = newValue == 'design' ? 'design' : 'data'
    let trialIds = this.state.trials.map((trial) => trial.trial_id || trial.id)
    $.ajax({
      type: 'GET',
      url: `/sectors/${this.props.sector.abbr}/trials/trials_for_compare`,
      data: {
        trial_ids: trialIds, view: newValue
      },
      dataType: 'json',
      success: (data) => {
        this.setState({
          trials: data.trials,
          fields: data.all_fields,
          attributeOptions: data.attribute_options,
          activeIndex: newValue
        })
      }
    })
  }

  handleChipSelection(name, value) {
    this.setState({
      fields: value
    })
    // let selectedChipValue = e
    // let index = this.state.fields.indexOf(selectedChipValue)
    // let newFields = ""
    // if (index == -1) {
    //   newFields = update(this.state.fields, {$push: [selectedChipValue]})
    // } else {
    //   newFields = update(this.state.fields, {$splice: [[index,1]]})
    // }
    // this.setState({
    //   fields: newFields
    // })
  }

  handleCloseDialog(e) {
    this.setState({
      openDialog: false
    })
  }

  render() {
    return(
      <React.Fragment>

        {this.state.openDialog ?
          <Dialog
            openDialog={this.state.openDialog}
            dialogTitle='Choose trials'
            handleAddClick={this.handleAddClick}
            handleClose={this.handleCloseDialog}
            dialogContent={
              <TableContainer
                height="100%"
                sector={this.props.sector}
                choose={true}
                checkedTrials={this.state.newTrials}
                currentUser={this.props.current_user}
                handleTrialCheckboxClick={this.handleTrialCheckboxClick}
                handleBulkTrialCheckboxClick={this.handleBulkTrialCheckboxClick}
              />
            }
          />
        : ''}

        <Paper fullHeight={true}>
          <Box height="100%">

            <Box height="100%">
              { this.state.trials && this.state.trials.length > 0 ?
                <Fragment>
                  <Box mb={4}>
                    <Tabs centered value={this.state.activeIndex} onChange={this.handleActiveIndexUpdate} className='bb b--black-10' aria-label="nav tabs">
                      {this.props.current_user.user_company_id == 1 ? <Tab label="Data - MMI" value='data_mmi' /> : ""}
                      <Tab label="Data" value='data' />
                      <Tab label="Design" value='design' />
                    </Tabs>
                  </Box>

                  {this.state.activeIndex == 'data' ?
                    <DataView
                      entities={this.state.trials}
                      activeEntity={this.props.trial ? this.props.trial : null}
                      recruiting={this.props.recruiting}
                      sector={this.props.sector}
                      entityType='trials'
                      handleAddEntityClick={this.handleAddTrialClick}
                      handleEntityRemovalClick={this.handleTrialRemovalClick}
                    />
                  : ""}

                  {this.state.activeIndex == 'data_mmi' ?
                    <NewDataView
                      entities={this.state.trials}
                      activeEntity={this.props.trial ? this.props.trial : null}
                      recruiting={this.props.recruiting}
                      sector={this.props.sector}
                      entityType='trials'
                      handleAddEntityClick={this.handleAddTrialClick}
                      handleEntityRemovalClick={this.handleTrialRemovalClick}
                    />
                  : ""}

                  {this.state.activeIndex == 'design' ?
                    <CompareEntitiesContainer
                      entities={this.state.trials}
                      activeEntity={this.props.trial ? this.props.trial : null}
                      allFields={this.props.all_fields}
                      fields={this.state.fields}
                      sector={this.props.sector}
                      attributeOptions={this.state.attributeOptions}
                      handleAddEntityClick={this.handleAddTrialClick}
                      handleEntityRemovalClick={this.handleTrialRemovalClick}
                      entityType='trials'
                      showChips={this.state.showChips}
                      handleChipSelection={this.handleChipSelection}
                    />
                  : ""}

                </Fragment>
              :
                <Flex justifyContent='center' height="50%">
                  <Box alignSelf='center' maxWidth="50%">
                    <Box textAlign='center'>
                      <Typography variant="h6" component="h6">
                        Start comparing by clicking the button below and adding trials
                      </Typography>

                      <Typography variant="body1">
                        <Box mt={2}>
                          You can also compare by going through the product studies tab and clicking the compare icon
                        </Box>
                        <Box>
                          or
                        </Box>
                        <Box>
                          You can choose trials through the list tab and click the compare icon
                        </Box>
                      </Typography>

                      <Box mt={4} mx="auto" width={1/3}>
                        <RaisedButton onClick={this.handleAddTrialClick}>
                          Add Trials
                        </RaisedButton>
                      </Box>
                    </Box>
                  </Box>
                </Flex>
              }
            </Box>

          </Box>

        </Paper>
      </React.Fragment>
    )
  }
}

export default withRouter(CompareContainer)
