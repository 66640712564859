import React from 'react'
import { TableCell, TableRow } from '../../tables'
import DeleteButton from "../DeleteButton"
import Link from "../../Link"

export default function Row(props) {

  function handleDeleteClick(e) {
    props.handleDeleteClick(props.approval)
  }

  function handleApprovalCheckboxClick(e) {
    props.handleApprovalCheckboxClick(props.approval)
  }

  function handleReviewedClick(e) {
    props.handleReviewedClick(props.approval)
  }

  return (
    <TableRow selected={props.approval.reviewed}>
      <TableCell>
        <Link href={`/admin/device_approvals/${props.approval.id}/associator`} target="_blank">
          {props.approval.regulatory_id}
        </Link>
      </TableCell>
      <TableCell>
        {props.approval.country_abbr}
      </TableCell>
      <TableCell>
        <Link href={`/admin/device_approvals/${props.approval.id}/associator`} target="_blank">
          {props.approval.device_name}
        </Link>
      </TableCell>
      <TableCell>{props.approval.manufacturer}</TableCell>
      <TableCell>{props.approval.date || props.approval.approval_date}</TableCell>
      {/* <TableCell>
        <IconButton tooltip="Mark as reviewed">
          <CheckCircleOutline color={approval.reviewed ? 'primary' : 'default'} onClick={handleReviewedClick} />
        </IconButton>
      </TableCell> */}
      <TableCell>
        <DeleteButton tooltip="Delete the product association" onClick={handleDeleteClick} />
      </TableCell>
    </TableRow>
  )
}
