import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Paper from "../../Paper"
import { Table, TableHead, TableRow, TableCell, TableBody, EmptyDataTableRow } from "../../tables"
import SourceRow from "./SourceRow"

export default class Sources extends Component {
  constructor(props) {
    super(props)
  }
  
  render() {
    return(
      <Fragment>
        
        <Paper>
          <Table className='w-100'>
            <TableHead>
              <TableRow>
                <TableCell>Source</TableCell>
                <TableCell>Type</TableCell>
                <TableCell>Created By</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.props.sources.length > 0 ? this.props.sources.map((source) =>
                <SourceRow
                   key={source.id} 
                  source={source}
                />
              ) : 'No sources yet. Create a source and it display here'}
            </TableBody>
          </Table>
        </Paper>
      </Fragment>
    )
  }
}
