import React, { Fragment } from "react"
import Chart from "../charts/Chart"

export default function ChartContainer(props) {
  
  function setTooltip() {
    let tooltip = ""
    if (props.groupOption == 'Year') {
      tooltip = { headerFormat: '<b>{point.x}</b><br/>', pointFormat: 'Manuscripts: {point.y}' }
    } else {
      // <br/>Total: {point.stackTotal}
      tooltip = { headerFormat: '<b>{point.x}</b><br/>', pointFormat: '{series.name}: {point.y}' }
    }
    
    return(tooltip)
  }
  
  
  let chartKey = `publications-chart`
  console.log(props.chartTitle)
  return (
    <Fragment>
      <Chart 
        key={chartKey}
        chartId='product-publication-count-chart'
        chartOptions={{ type: 'column', height: '35%' }}
        plotOptions={{ column: { dataLabels: { enabled: true } } }}
        exportingChartOptions={{ credits: { enabled: true, text: '\u00A9 MarketMonitors' } }}
        data={props.chartData}
        categories={props.categories}
        title={props.chartTitle}
        yAxisTitle='# of publications'
        xAxisTitle='Year'
        tooltip={setTooltip()}
        simple={true}
        legend={false}
      />
    </Fragment>
  )
}
