import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import CKEditor from 'ckeditor4-react';
import Box from "../../Box"
import Radio from "@material-ui/core/Radio"
import RadioGroup from "../../RadioGroup"
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Flex from "../../Flex"
import { RaisedButton } from "../../buttons"


export default class TextFieldForm extends Component {
  constructor(props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
    this.handleTrialTextFieldSaveClick = this.handleTrialTextFieldSaveClick.bind(this)
    this.handleTextChange = this.handleTextChange.bind(this)
    this.state = { text_field_name: 'brief_summary', text: '' }
  }

  componentDidMount() {
    if (this.props.trialTextFields) {

      let trialTextField = this.props.trialTextFields.filter(trialTextField => trialTextField.text_field_name == 'brief_summary')[0]

      if (trialTextField) {
        this.setState({
          text: trialTextField.text_field_value
        })
      }
    }
  }

  handleTextChange(e) {
    const text = e.editor.getData()

    this.setState({
      text: text
    })
  }

  handleTrialTextFieldSaveClick(e) {
    this.props.handleTrialTextFieldSaveClick(this.state.text_field_name, this.state.text)
  }

  handleChange(e) {
    let value = e.target.value

    let trialTextField = this.props.trialTextFields.filter(trialTextField => trialTextField.text_field_name == value)[0]

    this.setState({
      [e.target.name]: e.target.value,
      text: trialTextField ? trialTextField.text_field_value : ''
    })
  }

  render() {
    return(
      <Fragment>
        <Box mt={2} mb={6}>

          <Flex justifyContent='center' mb={2}>
            <RadioGroup aria-label="text-field-name" name="text_field_name" value={this.state.text_field_name} onChange={this.handleChange}>
              <FormControlLabel value='brief_summary' control={<Radio />} label="Brief summary" />
              <FormControlLabel value='detailed_description' control={<Radio />} label="Detailed description" />
            </RadioGroup>
          </Flex>

          <Box mb={2}>
            <CKEditor
              data={this.state.text}
              onChange={this.handleTextChange}
            />
          </Box>

          <Box mt={5}>
            <RaisedButton handleClick={this.handleTrialTextFieldSaveClick}>
              {`Save ${this.state.text_field_name.replace("_", " ")}`}
            </RaisedButton>
          </Box>
        </Box>
      </Fragment>
    )
  }
}
