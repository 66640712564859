import React, { Component, Fragment } from "react"
import PropTypes from "prop-types"
import ListRecord from "./ListRecord"
import { Table, TableHead, TableBody, TableRow, TableCell, TableHeadSort } from "../tables"
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TablePaginationActions from "../tables/TablePaginationActions"
import Box from "../Box"
import LinearProgress from '@material-ui/core/LinearProgress';

export default class ManuscriptsTable extends Component {
  constructor(props) {
    super(props)
  }

  desc(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = cmp(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
  }

  getSorting(order, orderBy) {
    return order === 'desc' ? (a, b) => this.desc(a, b, orderBy) : (a, b) => -this.desc(a, b, orderBy);
  }

  handleRequestSort(event, property) {
    const isDesc = this.state.orderBy === property && this.state.order === 'desc';

    this.setState({
      order: isDesc ? 'asc' : 'desc',
      orderBy: property
    })
  };

  render() {
    return(
      <Fragment>

        <Box overflow='auto' height='80%'>
          <Table size='small' aria-label='manuscripts table' stickyHeader={true}>

            <TableHeadSort
              headers={[{ name: 'title', label: 'Publication' }, { name: 'sql_journal_name', label: 'Journal' }, { name: 'name', label: 'Author' }, { name: 'published_date', label: 'Date' }]}
              order={this.props.order}
              orderBy={this.props.orderBy}
              onRequestSort={this.props.handleRequestSort}
            />

            <TableBody>
              <React.Fragment>
                {this.props.loading ?
                  <TableRow hover={false}>
                    <TableCell noBorder colSpan={5}>
                      <LinearProgress />
                    </TableCell>
                  </TableRow>
                : ""}
                {this.props.manuscripts.map((publication) =>
                    <ListRecord
                      key={publication.manuscript_id}
                      publication={publication}
                      sector={this.props.sector}
                    />
                )}
              </React.Fragment>
            </TableBody>

            <TableFooter>
              <TablePagination
                colSpan={5}
                count={this.props.manuscriptsCount}
                rowsPerPage={100}
                page={this.props.page}
                SelectProps={{
                  inputProps: { 'aria-label': 'rows per page' },
                  native: true,
                }}
                onChangePage={this.props.handleChangePage}
                onChangeRowsPerPage={this.props.handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableFooter>
          </Table>

        </Box>
      </Fragment>
    )
  }
}
