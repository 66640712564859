import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Paper from "../../Paper"
import Typography from "@material-ui/core/Typography"
import Collapse from '@material-ui/core/Collapse';
import { ListItemText, ListItem } from "../../lists"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ExpandLess from '../../ExpandLess';
import ExpandMore from '../../ExpandMore';
import Flex from "../../Flex"
import Box from "../../Box"
import { Button } from "../../buttons"

class ContentDisplay extends Component {
  constructor(props) {
    super(props)
    this.handleDeleteClick = this.handleDeleteClick.bind(this)
    this.state = { open: false }
  }

  UNSAFE_componentWillMount() {
    // window.mdc.autoInit();
    // document.documentElement.addEventListener('click', (evt) => {
    //   const listItemEl = mdc.dom.ponyfill.closest(evt.target, '.mdc-list-item');
    //   const metaEl = mdc.dom.ponyfill.closest(evt.target, '.mdc-list-item__meta');
    //
    //   if (!listItemEl) {
    //     return;
    //   }
    //
    //   if (metaEl) {
    //     console.log('✘ Ignoring click on meta element');
    //     return;
    //   }
    // })
  }

  handleDeleteClick(e) {
    this.props.handleDeleteClick(this.props.profileFieldHash.profile_field)
  }

  renderMarkup(content) {
    return {__html: content}
  }

  render() {
    const editUrl = `/admin/products/${this.props.product.id}/profile_fields/new?old_profile_field_id=${this.props.profileFieldHash.profile_field.id}`
    const user = this.props.profileFieldHash.user
    return(
      <Fragment>

        <ListItem button onClick={() => this.setState({open: !this.state.open})}>
          <ListItemIcon>
            {this.state.open ? <ExpandLess /> : <ExpandMore />}
          </ListItemIcon>

          <ListItemText primary={this.props.profileFieldName} />

        </ListItem>

        {/* <ListItem onClick={() => this.setState({open: !this.state.open})}>
          <ListItemGraphic graphic={<IconButton icon={this.state.open ? 'expand_less' : 'expand_more'} />} />
          <ListItemText primaryText={this.props.profileFieldName} />
          <ListItemMeta
            meta={<IconButton icon='edit' isLink={true} href={editUrl} />}
          />
          <ListItemMeta meta={<IconButton icon='delete' handleClick={this.handleDeleteClick} />} className='ml2' />
        </ListItem> */}

        <Collapse in={this.state.open} timeout="auto" unmountOnExit>
          <Box mx={3} mt={1} p={3}>
            <div
              dangerouslySetInnerHTML={this.renderMarkup(this.props.profileFieldHash.profile_field.content)}
            />

            <Flex alignItems='center'>
              <Box>
                <Typography variant='caption'>
                  <span className='mr3'>
                    ({this.props.profileFieldHash.source_object ? this.props.profileFieldHash.source_object.source_title : "N/A"})
                  </span>
                  <span className='mr3'>
                    ({user ? displayInitials(user.first_name, user.last_name) : 'N/A'})
                  </span>
                </Typography>
              </Box>

              <Box ml="auto">
                <Button href={editUrl}>Edit</Button>
              </Box>
            </Flex>

          </Box>

        </Collapse>

      </Fragment>
    )
  }
}


export default ContentDisplay
