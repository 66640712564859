import React from "react"
import clsx from "clsx"
import { makeStyles } from '@material-ui/core/styles';
import MuiPaper from '@material-ui/core/Paper';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3, 2),
    backgroundColor: props => theme.palette[props.bgcolor]
  },
  fullHeight: {
    height: '80vh',
    [theme.breakpoints.down('md')]: {
      height: '100vh'
    }
  },
  minFullHeight: {
    minHeight: '80vh'
  },
  maxFullHeight: {
    height: '90vh'
  },
  active: {
    backgroundColor: theme.palette.primary.main
  },
  header: {
    padding: theme.spacing(0),
    marginBottom: theme.spacing(4)
  },
  sidebar: {
    padding: theme.spacing(0)
  }
}));

export default function Paper(props) {
  const classes = useStyles(props);
  return (
    <MuiPaper className={clsx(classes.root, { [classes.header]: props.header }, { [classes.sidebar]: props.sidebar }, { [classes.active]: props.active }, { [classes.fullHeight]: props.fullHeight }, { [props.className]: props.className }, { [classes.minFullHeight]: props.minFullHeight }, {[classes.maxFullHeight]: props.maxFullHeight})} elevation={props.elevation} component={props.component} square={props.square} style={props.style}>
      {props.children}
    </MuiPaper>
  )
}

Paper.defaultProps = {
  bgcolor: 'white'
}
