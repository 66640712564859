import React, { Component } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Button from "./Button"

class SaveButton extends Component {
  constructor(props) {
    super(props)
  }
  
  render() {
    return(
      <Button {...this.props}>
        Save
      </Button>
    )
  }
}

export default SaveButton
