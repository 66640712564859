import React, { Component } from "react"
import Paper from "../../Paper"
import Box from "../../Box"
import TweetsContainer from "../../twitter/TweetsContainer"

class Tweets extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    return(
      <TweetsContainer
        tweets={this.props.tweets}
        tweet_count={this.props.tweet_count}
        url={this.props.url}
        page={this.props.page}
        query={this.props.query}
      />
    )
  }
}

export default Tweets
