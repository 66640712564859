import React, { Fragment } from "react"
import Paper from "../../Paper"
import Box from "../../Box"
import Flex from "../../Flex"
import { Table, TableHead, TableBody, TableRow, TableCell } from "../../tables"
import Link from "../../Link"

function TrialReview(props) {
  return(
    <Paper fullHeight>
      <Box height="100%">

        <Box height="80%">
          <Table stickyHeader size="small">
            <TableHead>
              <TableRow>
                <TableCell>Trial</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props.trials.map((trial) =>
                <TableRow key={trial.id}>
                  <TableCell>
                    <Link href={`/admin/trials/${trial.trial_id}/associator`} target="_blank">
                      {trial.acronym || trial.short_name}
                    </Link>
                  </TableCell>
                  <TableCell></TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </Box>

      </Box>
    </Paper>
  )
}


export default TrialReview
