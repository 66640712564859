import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Paper from "../Paper"
import { withRouter } from "react-router-dom";
import { withSnackbar } from "../SnackbarContainer"
import SearchFilters from "./SearchFilters"
import List from "./List"
import Pagination from "../search/Pagination"
import Flex from "../Flex";
import Box from "../Box";
import update from 'immutability-helper';
import InlineSearchContainer from '../admin/search/InlineSearchContainer'
import { Grid } from "@material-ui/core";

class Search extends Component {
  constructor(props) {
    super(props)
    this.getNews = this.getNews.bind(this)
    this.handleSearchChange = this.handleSearchChange.bind(this)
    this.handleSearchClick = this.handleSearchClick.bind(this)
    this.handlePageChange = this.handlePageChange.bind(this)
    this.handleOpen = this.handleOpen.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.handleFilterChange = this.handleFilterChange.bind(this)
    this.handleClearSearchClick = this.handleClearSearchClick.bind(this)
    this.state = { news: this.props.news, newsCount: this.props.news_count, loading: false, publications: this.props.publications, filterValues: { publications: this.props.news_publication_name, year: this.props.year, order: 'desc', order_by: 'date', page: this.props.page, query: this.props.query } }
  }

  componentDidMount() {
    if (this.props.filter) {

      let index = this.props.filter

      this.setState({
        activeIndex: index,
        filter: this.props.filter
      })
    }
  }

  handleClearSearchClick(e) {
    let params = Object.assign({}, this.state.filterValues)
    params["query"] = ""

    this.getNews(params)
  }

  handleFilterChange(value, filter) {
    let params = Object.assign({}, this.state.filterValues)

    if (filter == 'years') {
      params["begin_year"] = value[0]
      params["end_year"] = value[1]
    } else if (params[filter].includes(value)) {
      params[filter] = params[filter].filter((item) => item != value)
    } else {
      params[filter].push(value)
    }

    this.getNews(params)
  }

  handleOpen(event) {
    this.setState({
      anchorEl: event.currentTarget
    })
  };

  handleClose() {
    this.setState({
      anchorEl: null
    })
  };

  handlePageChange(e, page) {
    let url = `/sectors/${this.props.sector.abbr}/news`

    let params = { page: page }

    this.getNews(params)
  }

  handleSearchChange(e) {
    let filterValues = update(this.state.filterValues, {
      query: { $set: e.target.value }
    })

    this.setState({
      filterValues: filterValues
    })
  }

  handleSearchClick(query) {
    let params = Object.assign({}, this.state.filterValues)

    params["query"] = query

    this.getNews(params)
  }

  getNews(params) {
    $.ajax({
      type: 'GET',
      url: `/sectors/${this.props.sector.abbr}/news`,
      data: params,
      dataType: 'json',
      beforeSend: (smthing) => {
        this.setState({
          loading: true
        })
      },
      success: (data) => {

        let url = `/sectors/${this.props.sector.abbr}/news`

        let filterKeys = Object.keys(params)

        let queryParams = filterKeys.map((filterKey) =>
          (typeof(params[filterKey]) == "string" || params[filterKey] == null) ? (params[filterKey] ? `${filterKey}=${params[filterKey]}` : null) : (params[filterKey].length > 0 ? params[filterKey].map((filterValue) => `${filterKey}[]=${filterValue}`) : null)
        ).filter(param => param)

        let flattenedQueryParams = [].concat.apply([], queryParams);
        // this.props.match.path
        this.props.history.push(`${url}?${flattenedQueryParams.join("&")}`);

        this.setState({
          news: data.news,
          newsCount: data.news_count,
          filterValues: params,
          loading: false,
          publications: data.publications
        })
      },
      error: (data) => {
        this.props.snackbarShowMessage("There was a problem fetching the articles. Please try again.", "error")
      }
    })
  }

  render() {
    return(
      <Fragment>
        <Paper minFullHeight>

          <Box height="100%">
            <Box width={1/2} mx='auto'>
              <InlineSearchContainer
                searchLabel="Search articles & press releases" 
                query={this.state.filterValues.query}
                handleSearchClick={this.handleSearchClick}
                handleClearSearchClick={this.handleClearSearchClick}
              />
            </Box>

            <Grid container spacing={3}>
              <Grid item xs={4} sm={4} md={3} xl={2}>
                <SearchFilters
                  publications={this.state.publications}
                  filterValues={this.state.filterValues}
                  handleFilterChange={this.handleFilterChange}
                />
              </Grid>

              <Grid item xs={12} sm={8} md={9} xl={10}>
                <List
                  key={this.state.loading}
                  news={this.state.news}
                  newsCount={this.state.newsCount}
                  order={this.state.filterValues.order}
                  orderBy={this.state.filterValues.order_by}
                  page={this.state.filterValues.page}
                  handleChangePage={this.handleChangePage}
                  handleRequestSort={this.handleRequestSort}
                  loading={this.state.loading}
                  query={this.state.query}
                  sector={this.props.sector}
                />
              </Grid>
            </Grid>

            <Pagination 
              page={this.state.page}
              resultsCount={this.state.newsCount}
              handlePageChange={this.handlePageChange}
            />

          </Box>

        </Paper>
      </Fragment>
    )
  }
}

export default withRouter(Search)
