import React, { Component, Fragment } from "react"
import PropTypes from "prop-types"
import ReactDOM from "react-dom"
import Paper from "../Paper"
import TextDiff from "../TextDiff"
import Flex from "../Flex"
import Box from "../Box"
import Link from "../Link"

export default class HistoryContainer extends Component {
  constructor(props) {
    super(props)
    this.handleFilterChange = this.handleFilterChange.bind(this)
    this.state = { attribute: this.props.attribute, oldText: this.props.old_text, currentText: this.props.current_text }
  }

  handleFilterChange(name, value) {
    $.getJSON(`/sectors/${this.props.sector.abbr}/products/${this.props.product.id}/history`, { attribute: value }, (data) => {
      this.setState({
        attribute: data.attribute,
        oldText: data.old_text,
        currentText: data.current_text
      })
    })
  }

  render() {
    return (
      <Fragment>

        <Paper>

          <Flex align='items-baseline'>
            <Box>
              <Link href={this.props.old_source.source_url} target="_blank">
                {this.props.old_source.source_title}
              </Link>
              <Caption className='ml1'>({this.props.old_source.date})</Caption>
            </Box>

            <Box className='ml-auto'>
              <Link href={this.props.current_source.source_url} target="_blank">
                {this.props.current_source.source_title}
              </Link>
              <Caption className='ml1'>({this.props.current_source.date})</Caption>
            </Box>
          </Flex>

          <TextDiff
            oldText={this.state.oldText}
            newText={this.state.currentText}
            splitView={true}
            hideLineNumbers={false}
          />

        </Paper>
      </Fragment>
    );
  }
}
