import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import List from "../lists/List";
import NestedListItemLink from "../lists/NestedListItemLink"
import ListItemLink from "../lists/ListItemLink"
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ExpandLess from '../ExpandLess';
import ExpandMore from '../ExpandMore';
import Collapse from '@material-ui/core/Collapse';

class NestedTrialList extends Component {
  constructor(props) {
    super(props)
    this.state = { open: false }
  }

  componentDidMount() {
    if (this.props.activeGroup == 'Trials') {
      this.setState({
        open: true
      })
    }
  }

  render() {
    return(
      <Fragment>

        <ListItem button onClick={() => this.setState({open: !this.state.open})}>
          <ListItemText primary="Trials" />
          {this.state.open ? <ExpandLess /> : <ExpandMore />}
        </ListItem>

        <Collapse in={this.state.open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>

            <NestedListItemLink href={`/admin/trials/dashboard`}>
              <ListItemText primary='Dashboard' className={this.props.activeItem == 'Dashboard' ? 'orange' : ''} />
            </NestedListItemLink>

            <NestedListItemLink href={`/admin/trials`}>
              <ListItemText primary='List' className={this.props.activeItem == 'List' ? 'orange' : ''} />
            </NestedListItemLink>

            <NestedListItemLink href={`/admin/trials/new`}>
              <ListItemText primary='New Trial' className={this.props.activeItem == 'New Trial' ? 'orange' : ''} />
            </NestedListItemLink>

            <NestedListItemLink href={`/admin/ct_gov_trials/dashboard`}>
              <ListItemText primary='Clinical Trials Gov' className={this.props.activeItem == 'Clinical Trials Gov' ? 'orange' : ''} />
            </NestedListItemLink>

            <NestedListItemLink href={`/admin/ictrp_trials`}>
              <ListItemText primary='ICTRP Trials' className={this.props.activeItem == 'ICTRP Trials' ? 'orange' : ''} />
            </NestedListItemLink>

            <NestedListItemLink href={`/admin/clinical_types`}>
              <ListItemText primary='Types' className={this.props.activeItem == 'Clinical Types' ? 'orange' : ''} />
            </NestedListItemLink>

            <NestedListItemLink href={`/admin/clinical_statuses`}>
              <ListItemText primary='Statuses' className={this.props.activeItem == 'Clinical Statuses' ? 'orange' : ''} />
            </NestedListItemLink>

            <NestedListItemLink href={`/admin/trials/reviewer`}>
              <ListItemText primary='Type Reviewer' className={this.props.activeItem == 'Type Reviewer' ? 'orange' : ''} />
            </NestedListItemLink>

            <NestedListItemLink href={`/admin/trials/sponsor_reviewer`}>
              <ListItemText primary='Sponsor Reviewer' className={this.props.activeItem == 'Sponsor Reviewer' ? 'orange' : ''} />
            </NestedListItemLink>

            <NestedListItemLink href={`/admin/trials/product_reviewer`}>
              <ListItemText primary='Product Reviewer' className={this.props.activeItem == 'Product Reviewer' ? 'orange' : ''} />
            </NestedListItemLink>

            <NestedListItemLink href={`/admin/trials/staged_associator`}>
              <ListItemText primary='Staged Associator' className={this.props.activeItem == 'Staged Associator' ? 'orange' : ''} />
            </NestedListItemLink>

            <NestedListItemLink href={`/admin/trial_search_terms`}>
              <ListItemText primary='Search terms' className={this.props.activeItem == 'Search terms' ? 'orange' : ''} />
            </NestedListItemLink>

          </List>
        </Collapse>

      </Fragment>
    )
  }
}

export default NestedTrialList
