import React, { Fragment } from "react"
import Flex from "../Flex"
import Box from "../Box"
import PatentCard from "./PatentCard"

export default function Family(props) {
  return(
    <Fragment>
      <Box>
        {props.parentPatents.map((patent) =>
          patent.entity ? <Box key={patent.id} width={1/2} mx='auto' mb={3}>
            <PatentCard 
              patent={patent}
              patentEntity={patent.entity}
            />
          </Box> : ''
        )}
      </Box>
      
      <Box>
        <Flex mt={4}>
          {props.childPatents.map((patent) =>
            patent.entity ? <Box key={patent.id}>
              <PatentCard 
                patent={patent}
                patentEntity={patent.entity}
              />
            </Box> : ''
          )}
        </Flex>
      </Box>
    </Fragment>
  )
}
