import React, { Fragment } from "react"
import Paper from "../../Paper"
import List from "../../lists/List";
import EnrollmentListItem from "./EnrollmentListItem"

export default function EnrollmentList(props) {
  console.log(props.suggestedEnrollments)
  return (
    <Fragment>
      
      <List>
        {props.suggestedEnrollments.map((enrollmentText) =>
          <EnrollmentListItem 
            key={enrollmentText.id}
            enrollmentText={enrollmentText}
            checked={false}
            forSelection={true}
            handleCheckboxSelect={props.handleCheckboxSelect}
          /> 
        )}
      </List>
      
    </Fragment>
  )
}
