import React, { Fragment, useState } from "react"
import { Table, TableHead, TableRow, TableCell, TableBody, TableHeadSort } from "../tables"
import CountrySites from "./CountrySites"
import Box from "../Box"

export default function SitesDataTable(props) {
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('country')


  function desc(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = cmp(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
  }

  function getSorting(order, orderBy) {
    return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
  }

  function handleRequestSort(event, property) {
    const isDesc = orderBy === property && order === 'desc';

    setOrder(isDesc ? 'asc' : 'desc')
    setOrderBy(property)
  };

  let headers = [{ label: 'Facility', name: 'facility' }, { label: 'Country', name: 'country' }, { label: 'State', name: 'state' }, { label: 'City', name: 'city' }, { label: "Investigators", name: 'investigator_count', align: 'right' }]
  return(
    <Fragment>
      <Box overflow='auto' height="80%">
        <Table size='small' stickyHeader={true} aria-label='Sites data table'>

          <TableHeadSort
            headers={headers}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
          />

          <TableBody>
            {stableSort(props.sites, getSorting(order, orderBy)).map((site) =>
              <CountrySites
                key={site.id}
                site={site}
              />
            )}
          </TableBody>

        </Table>
      </Box>

    </Fragment>
  )
}
