import React, { Fragment, Component } from "react"
import Paper from "../Paper"
import { Table, TableHead, TableCell, TableRow, TableBody, TableHeadSort } from "../tables"
import Box from "../Box"
import Flex from "../Flex"
import { withRouter } from "react-router-dom";
import Link from "../Link"
import Typography from "@material-ui/core/Typography"
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import LinearProgress from '@material-ui/core/LinearProgress';
import IconButton from "../IconButton"
import { CSVLink } from "react-csv";
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';

class RegulatoryStatuses extends Component {
  constructor(props) {
    super(props)
    this.state = {
      products: this.props.products,
      regulatoryStatuses: this.props.regulatory_statuses,
      loading: false,
      order: 'asc',
      orderBy: 'short_name'
    }
    
    this.handleRequestSort = this.handleRequestSort.bind(this)
    this.handleCsvClick = this.handleCsvClick.bind(this)
  }

  handleRequestSort(event, property) {
    const isDesc = this.state.orderBy === property && this.state.order === 'desc';
    this.setState({
      order: isDesc ? 'asc' : 'desc',
      orderBy: property
    })
  }

  getSortedProducts() {
    const productEntries = Object.entries(this.state.products);
    
    return productEntries.sort((a, b) => {
      const productA = a[1][0].short_name.toLowerCase();
      const productB = b[1][0].short_name.toLowerCase();
      
      if (this.state.order === 'asc') {
        return productA.localeCompare(productB);
      } else {
        return productB.localeCompare(productA);
      }
    });
  }

  handleCsvClick(event, done) {
    $.get(`/sectors/${this.props.sector.abbr}/log_csv_download`).then(() => {
      done();
    });
  }

  render() {
    const sortedProducts = this.getSortedProducts();

    const headers = [
      { label: 'Product', name: 'short_name', borderRight: true, align: 'left' },
      ...this.state.regulatoryStatuses.map(status => ({
        label: status.name,
        name: status.id.toString(),
        borderRight: true,
        align: 'center'
      }))
    ]

    const csvHeaders = [
      { label: 'Product', key: 'product' },
      ...this.state.regulatoryStatuses.map(status => ({
        label: status.name,
        key: `status_${status.id}`
      }))
    ]

    const csvData = sortedProducts.map(([productId, products]) => {
      const product = products[0]
      
      return {
        product: `${product.short_name} (${product.company_name})`,
        ...Object.fromEntries(
          this.state.regulatoryStatuses.map(status => [
            `status_${status.id}`,
            products.some(p => p.regulatory_status_id === status.id) ? "√" : ""
          ])
        )
      }
    })

    return(
      <Fragment>
        <Paper fullHeight>
          <Box mb={2}>
            <Flex>
              <Box>
                <Typography variant="h6">Regulatory Statuses</Typography>
              </Box>
              <Box ml="auto">
                <CSVLink
                  data={csvData}
                  headers={csvHeaders}
                  filename={`${this.props.sector.abbr}-regulatory-statuses.csv`}
                  asyncOnClick={true}
                  onClick={this.handleCsvClick}
                >
                  <IconButton tooltip='Download CSV file' size='small'>
                    <CloudDownloadIcon style={{height: '22px', width: '22px'}} />
                  </IconButton>
                </CSVLink>
              </Box>
            </Flex>
          </Box>

          <Box overflow='auto' height="80%">
            <Table size='small' stickyHeader={true}>
              <TableHeadSort
                headers={headers}
                order={this.state.order}
                orderBy={this.state.orderBy}
                onRequestSort={this.handleRequestSort}
                borderRight={true}
              />

              <TableBody>
                {this.state.loading ? (
                  <TableRow hover={false}>
                    <TableCell noBorder colSpan={headers.length}>
                      <LinearProgress />
                    </TableCell>
                  </TableRow>
                ) : (
                  sortedProducts.map(([productId, products]) => {
                    const product = products[0]
                    return (
                      <TableRow key={productId}>
                        <TableCell borderRight>
                          <Link href={`/sectors/${this.props.sector.abbr}/products/${product.id}`}>
                            {product.short_name}
                          </Link>
                          <span className='ml2'>
                            <Link href={`/sectors/${this.props.sector.abbr}/companies/${product.company_id}`}>
                              <Typography variant="caption">
                                {`(${product.company_name})`}
                              </Typography>
                            </Link>
                          </span>
                        </TableCell>
                        {this.state.regulatoryStatuses.map(status => (
                          <TableCell key={status.id} borderRight align="center">
                            {products.some(p => p.regulatory_status_id === status.id) && (
                              <CheckCircleIcon color="primary" />
                            )}
                          </TableCell>
                        ))}
                      </TableRow>
                    )
                  })
                )}
              </TableBody>
            </Table>
          </Box>
        </Paper>
      </Fragment>
    )
  }
}

export default withRouter(RegulatoryStatuses)