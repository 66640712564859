import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import { Tabs, Tab } from "../../tabs"

class EditContainerTabs extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    return(
      <Fragment>
        <Tabs variant="fullWidth" value={this.props.activeIndex} onChange={this.props.handleActiveIndexUpdate} className='bb b--black-10' aria-label="nav tabs">
          <Tab label='Source' value='source' />
          <Tab label='Profile' value='profile' />
          <Tab label='Text' value='text' />
          <Tab label='Products' value='products' />
          <Tab label='Dates' value='dates' />
          <Tab label='Enrollment' value='enrollment' />
          <Tab label='Types' value='types' />
          <Tab label='Status' value='status' />
          <Tab label='Sponsors' value='sponsors' />
          <Tab label='Collaborators' value='collaborators' />
          <Tab label='Sectors' value='sectors' />
        </Tabs>
      </Fragment>
    )
  }
}

export default EditContainerTabs
