import React, { Component } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
// import TabBar from '@material/react-tab-bar'
import Tab from "./Tab"

class TabSection extends Component {
  constructor(props) {
    super(props);
  }
  
  renderTabs() {
    const tabs = this.props.tabs.map((tab) =>
      <Tab key={tab[0]} text={tab[0]} href={tab[1]} activeTab={this.props.activeTab} handleClick={this.props.handleClick} withNotification={tab[2] ? true : false} />
    )
    return(tabs)
  }
  
  render() {
    return (
      <ul className='w-100 pl0 mb0 mt0 bb b--light-gray pt2' id='js-tab-section'>
        {this.props.children ? this.props.children : this.renderTabs()}
      </ul>
    )
  }
}

export default TabSection

// this.state = { activeIndex: this.props.activeIndex }
// return (
//   <TabBar 
//     activeIndex={this.state.activeIndex}
//     handleActiveIndexUpdate={this.props.handleIndexUpdate}
//     onClick={this.props.handleClick}
//     className={this.props.className}>
//     {this.props.children}
//   </TabBar>
// )
