import React from "react"
import MuiSwitch from '@material-ui/core/Switch';

export default function Switch(props) {
  return (
    <MuiSwitch
      name={props.name}
      checked={props.checked}
      onChange={props.handleChange}
      value={props.value}
      disabled={props.disabled}
    />
  )
}
