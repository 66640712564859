import React, { useState } from "react"
import Paper from "../Paper"
import Flex from "../Flex"
import Box from "../Box"
import { Table, TableCell, TableRow, TableBody, TableHead } from "../tables"
import { List, ListItem, ListItemText, ListItemLink } from "../lists"
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from "@material-ui/core/Typography"
import PatentSidebar from "./PatentSidebar"
import Link from "../Link"
import Collapse from "@material-ui/core/Collapse"
import { Button } from "../buttons"

export default function Profile(props) {
  const [open, setOpen] = useState(false)

  function groupBy(list, props) {
    return list.reduce((a, b) => {
       (a[b[props]] = a[b[props]] || []).push(b);
       return a;
    }, {});
  }

  let claims = groupBy(props.patent_claims, 'clm_id_ref')

  console.log(claims)

  return (
    <React.Fragment>

      <Flex>

        <Box width={[1/2, 1, 3/5]}>
          <ExpansionPanel defaultExpanded={true}>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} ariacontrols='patent-abstract' id='patent-abstract-header'>
              <Typography className="f4">
                Abstract
              </Typography>
            </ExpansionPanelSummary>

            <ExpansionPanelDetails>
              <Typography variant="body1">
                {props.patent.abstract}
              </Typography>
            </ExpansionPanelDetails>
          </ExpansionPanel>

          <ExpansionPanel defaultExpanded={true}>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} ariacontrols='patent-abstract' id='patent-abstract-header'>
              <Typography className="f4">
                Classifications
              </Typography>
            </ExpansionPanelSummary>

            <ExpansionPanelDetails>
              {props.classifications.length > 0 ?
                <Box width={1}>
                  <List>
                    <ListItem component={props.current_user.user_company_id == 1 ? 'a' : ''} key={props.classifications[0].id} divider={open ? true : false} href={`/sectors/${props.sector.abbr}/patents?classification_ids=${props.classifications[0].id}`}>
                      <ListItemText
                        primary={props.classifications[0].name}
                        secondary={props.classifications[0].definition}
                      />
                    </ListItem>
                  </List>
                  <Collapse in={open}>
                    <List>
                      {props.classifications.map((classification, index) =>
                        index != 0 ? <ListItemLink key={classification.id} divider href={`/sectors/${props.sector.abbr}/patents?classification_ids=${classification.id}`}>
                          <ListItemText
                            primary={classification.name}
                            secondary={classification.definition}
                          />
                        </ListItemLink> : ""
                      )}
                    </List>
                  </Collapse>
                  <Button onClick={() => { setOpen(!open) }}>
                    {open ? 'Hide classifications' : `View ${props.classifications.length - 1} more classifications`}
                  </Button>
                </Box>
              : "No classifications found"}
            </ExpansionPanelDetails>
          </ExpansionPanel>

          {props.patent_claims.length > 0 ? <ExpansionPanel defaultExpanded={true}>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} ariacontrols='patent-claims' id='patent-claims-header'>
              <Typography className="f4">
                Claims
              </Typography>
            </ExpansionPanelSummary>

            <ExpansionPanelDetails>
              <Box>
                {props.patent_claims.map((claim) =>
                  <Box mb={2} key={claim.id} className='mb2'>
                    <Typography variant={claim.clm_id_ref ? 'body2' : 'body1'} color={claim.clm_id_ref ? "textSecondary" : "default"}>
                      <span dangerouslySetInnerHTML={{__html: claim.claim }} />
                    </Typography>
                  </Box>
                )}
              </Box>
            </ExpansionPanelDetails>
          </ExpansionPanel> : ''}

          {props.patent_descriptions.length > 0 ? <ExpansionPanel defaultExpanded={false}>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} ariacontrols='patent-claims' id='patent-claims-header'>
              <Typography className="f4">
                Description
              </Typography>
            </ExpansionPanelSummary>

            <ExpansionPanelDetails>
              <Box>
                {props.patent_descriptions.map((description) =>
                  <div key={description.id} className='mb2'>
                    {description.description}
                  </div>
                )}
              </Box>
            </ExpansionPanelDetails>
          </ExpansionPanel> : ''}
        </Box>

        <Box width={[1, 1/3]} ml='auto'>
          <PatentSidebar
            patentAttributes={props.patent_attributes}
            equivalentPatents={props.patent_equivalents}
            inventors={props.inventors}
            patent={props.patent}
            company={props.company}
            sector={props.sector}
          />
        </Box>

      </Flex>

        {/* <Table>
          <TableHead>
            <TableRow hover={false}>
              <TableCell borderRight={true} />
              <TableCell />
            </TableRow>
          </TableHead>

          <TableBody>
            {props.patent_attributes.map((patentAttributeHash) =>
              <TableRow key={patentAttributeHash.header}>
                <TableCell borderRight={true}>
                  {patentAttributeHash.header}
                </TableCell>
                <TableCell>
                  {patentAttributeHash.value}
                </TableCell>
              </TableRow>
            )}

          </TableBody>
        </Table> */}

    </React.Fragment>
  )
}
