import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Paper from "../Paper"
import Flex from "../Flex"
import Box from "../Box"
import DateChart from "../DateChart"
import Typography from "@material-ui/core/Typography"

export default class TrialCompletionDateMockup extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    return(
      <Paper elevation={6}>
        <Flex alignItems='center' mb={2}>
          <Box>
            {/* <Typography variant="h6">
              Completion Date Changes
            </Typography> */}
          </Box>
        </Flex>

        <Box>
          <DateChart
            chartId='completion-date-chart'
            dateChartData={this.props.dateChartData}
            attribute=''
            legend={false}
            tooltip={false}
          />
        </Box>

      </Paper>
    )
  }
}
