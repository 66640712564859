import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import TextInput from "../../TextInput"
import update from "immutability-helper"
import Snackbar from "../../Snackbar"
import List from "../../lists/List"
import MetricListItem from "./MetricListItem";
import Typography from "@material-ui/core/Typography"
import Button from "../../buttons/Button"
import Flex from "../../Flex"
import Box from "../../Box"
import IconButton from "../../IconButton"
import RaisedButton from "../../buttons/RaisedButton"

export default class MetricsForm extends Component {
  constructor(props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
    this.handleCheckboxSelect = this.handleCheckboxSelect.bind(this)
    this.handleSaveClick = this.handleSaveClick.bind(this)
    this.handleAddMetricClick = this.handleAddMetricClick.bind(this)
    this.state = { characteristicGroupMetrics: this.props.characteristicGroupMetrics, metrics: this.props.metrics, newMetric: '', showAddMetricForm: false, showSnackbar: false, snackbarText: '', showHelpText: false }
  }
  
  handleAddMetricClick(e) {
    this.setState({
      showAddMetricForm: true
    })
  }
  
  handleChange(e) {
    const value = e.currentTarget.value
    
    this.setState({
      newMetric: value
    })
  }
  
  handleSaveClick(e) {
    $.post("/admin/metrics", { metric: { name: this.state.newMetric, created_by: this.props.currentUser.id }}, (data) => {
      this.setState({
        metrics: data.metrics,
        newMetric: '',
        showAddMetricForm: false
      })
    }, 'json').fail((data) => {
      this.setState({
        showSnackbar: true,
        snackbarText: `There was an error creating the new metric. ${data.responseText}`
      })
    }).always((data) => {
      setTimeout(() => {
        this.setState({
          showSnackbar: false,
          snackbarText: ''
        })
      }, 4000)
    })
  }
  
  handleCheckboxSelect(metric, checked, characteristicGroupMetric) {
    if (checked) {
      $.ajax({
        type: 'PUT',
        url: `/admin/characteristic_group_metrics/${characteristicGroupMetric.id}`,
        data: {
          characteristic_group_metric: {
            deleted: true, deleted_by: this.props.currentUser.id
          }
        },
        dataType: 'json',
        success: (data) => {
          const index = this.state.characteristicGroupMetrics.indexOf(characteristicGroupMetric)
          const characteristicGroupMetrics = update(this.state.characteristicGroupMetrics, { $splice: [[index, 1]] })
          
          this.setState({
            characteristicGroupMetrics: characteristicGroupMetrics,
            showSnackbar: true,
            snackbarText: 'Metric removed from this characteristic group'
          })
        },
        error: (data) => {
          this.setState({
            showSnackbar: true,
            snackbarText: 'The metric could not be removed from this characteristic group'
          })
        },
        complete: (data) => {
          setTimeout(() => {
            this.setState({
              showSnackbar: false,
              snackbarText: ''
            })
          }, 4000)
        }
      })
    } else {
      $.post("/admin/characteristic_group_metrics", { characteristic_group_metric: { characteristic_group_id: this.props.characteristicGroup.id, metric_id: metric.id, created_by: this.props.currentUser.id }}, (data) => {
        const characteristicGroupMetrics = update(this.state.characteristicGroupMetrics, { $push: [data.characteristic_group_metric] })
        
        console.log(characteristicGroupMetrics)
        
        this.setState({
          characteristicGroupMetrics: characteristicGroupMetrics,
          showSnackbar: true,
          snackbarText: 'Metric has been added'
        })
      }, 'json').fail((data) => {
        this.setState({
          showSnackbar: true, 
          snackbarText: `The metric could not be added. ${data.responseText}`
        })
      }).always((data) => {
        setTimeout(() => {
          this.setState({
            showSnackbar: false,
            snackbarText: ''
          })
        }, 4000)
      })
    }
  }
  
  handleHelpClick(e) {
    this.setState({
      showHelpText: true
    })
  }
  
  render() {
    return(
      <Fragment>
        <Flex alignItems='baseline'>
          <Box>
            <Typography variant="h6" component="h6">
              Metrics
            </Typography>
          </Box>
          
          <Box ml={2}>
            <Button onClick={this.handleAddMetricClick}>
              Add a metric
            </Button>
          </Box>
        
        </Flex>
        
        {/* <Box className='ml-auto'>
          <IconButton icon='help' handleClick={this.handleHelpClick} />
        </Box> */}
        
        <div>
          <div>
            <Typography variant="caption">
              All metrics are listed. Add a metric to the group by selecting the checkbox. 
            </Typography>
          </div>
          <div>
            <Typography variant="caption">
              Only add a new metric if it is not listed below.
            </Typography>
          </div>
        </div>
        
        {this.state.showAddMetricForm ? 
          <Flex alignItems='center'>
            <Box width={3/4}>
              <TextInput 
                id='outlined-metric'
                name='metric' 
                label='Metric' 
                value={this.state.newMetric} 
                onChange={this.handleChange} 
                variant='outlined'
                margin='normal'
                fullWidth
              />
            </Box>
            
            <Box width={1/4} ml={2}>
              <RaisedButton className='w-90' onClick={this.handleSaveClick}>
                Add metric
              </RaisedButton>
            </Box>
        
          </Flex> 
        : ''}
        
        {this.state.metrics.length > 0 ? <List>
          {this.state.metrics.map((metric) =>
            <MetricListItem 
              key={metric.id}
              metric={metric}
              characteristicGroupMetric={this.state.characteristicGroupMetrics.filter((characteristicGroupMetric) => characteristicGroupMetric.metric_id == metric.id)[0]}
              checked={this.state.characteristicGroupMetrics.filter((characteristicGroupMetric) => characteristicGroupMetric.metric_id == metric.id)[0] ? true : false}
              handleCheckboxSelect={this.handleCheckboxSelect}
            />
          )}
        </List> : "No metrics yet. Add one above and you'll see it appear here."}

        
        <Snackbar 
          open={this.state.showSnackbar}
          handleClose={this.handleClose}
          message={this.state.snackbarText} 
        /> 
        
      </Fragment>
    )
  }
}
