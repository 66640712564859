import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import IndexContainer from "./IndexContainer"
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

export default class ManuscriptsContainer extends Component {
  constructor(props) {
    super(props)
  }
  
  render () {
    return (
      <Fragment>
        <Switch>
          <Route 
            path={`/sectors/${this.props.sector.abbr}/publications`} 
            children={<IndexContainer {...this.props} />} 
          />
        </Switch>
      </Fragment>
    );
  }
}
