import React from 'react';
import Tooltip from "@material-ui/core/Tooltip"
import Typography from "@material-ui/core/Typography"
import Button from "@material-ui/core/Button"

export default function ProductCodeDisplay(props) {
  const { code, deviceName, handleProductCodeClick } = props;

  function handleClick(e) {
    e.preventDefault();
    props.handleProductCodeClick(code, deviceName);
  }

  function renderButton(code) {
    return (
      <Button onClick={handleClick} size='small'>
        <Typography variant="body2" color="textSecondary">
          {code}
        </Typography>
      </Button>
    )
  }

  function renderSpan(code) {
    return (
      <Typography variant="body2" color="textSecondary">
        {code}
      </Typography>
    )
  }

  return(
    <React.Fragment >
      <Tooltip title={deviceName} placement="top">
        {handleProductCodeClick ? renderButton(code) : renderSpan(code)}
      </Tooltip>
    </React.Fragment>
  )
}