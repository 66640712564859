import React, {Component} from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Paper from "../Paper"
import Box from "../Box"
import { Table, TableHead, TableBody, TableRow, TableCell } from "../tables"


class Sources extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    return(
      <section>

        <Paper fullHeight>
          <Box height="100%">
            <Box overflow="auto" height="90%">
              <Table size='small' stickyHeader={true} aria-label='product sources table'>
                <TableHead>
                  <TableRow>
                    <TableCell className='bg-white'>Title</TableCell>
                    <TableCell className='bg-white'>Kind</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.props.all_sources.map((source) =>
                    <TableRow key={source.id}>
                      <TableCell>{source.display_source}</TableCell>
                      <TableCell>{source.find_source_type}</TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </Box>
          </Box>
        </Paper>
      </section>
    )
  }
}

export default Sources
