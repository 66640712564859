import React from "react"
import Dialog from "../../Dialog"
import SourceForm from "../SourceForm"
import Box from "../../Box"
import Chip from '@material-ui/core/Chip';
import { Button } from "../../buttons"


export default function DialogForm(props) {
  return (
    <React.Fragment>

      <Box>
        {props.source_id ?
          <Chip
            label={props.source.kind}
            onDelete={props.handleSourceDeleteClick}
          />
        : <Button onClick={props.handleChooseSourceClick}>
          Choose Source
        </Button>}
      </Box>

      {props.openDialog ?
        <Dialog
          openDialog={props.openDialog}
          dialogTitle='Choose Source'
          withButtons={false}
          handleClick={props.handleAddClick}
          handleClose={props.handleClose}
          dialogContent={
            <SourceForm
              sources={props.sources}
              sourceTypes={props.source_types}
              handleSourceChange={props.handleSourceChange}
            />
          }
        /> : ''}

    </React.Fragment>
  );
}
