import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Paper from "../../Paper"
import Flex from "../../Flex"
import Box from "../../Box"
import EntityCard from "../EntityCard"
import Highlighter from "react-highlight-words";
import Link from "../../Link"

export default class NewsItemPanel extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    return(
      <Fragment>

        <Paper fullHeight={true}>

          <Box height="100%" overflow="auto">

          <Flex flexDirection='column' mb={2}>
              <Box width={1} mb={1}>
                <label className='b'>Type</label>
              </Box>

              <Box width={1}>
                {this.props.newsType}
              </Box>
            </Flex>
            
            <Flex flexDirection='column' mb={2}>
              <Box width={1} mb={1}>
                <label className='b'>Title</label>
              </Box>
              <Box width={1}>
                <Link href={this.props.newsItem.url} target="_blank">
                  {this.props.searchWords ?
                    <Highlighter
                      searchWords={this.props.searchWords}
                      autoEscape={true}
                      textToHighlight={this.props.newsItem.title}
                    />
                  : this.props.newsItem.title
                  }
                </Link>
              </Box>
            </Flex>

            <Flex flexDirection='column' mb={2}>
              <Box width={1} mb={1}>
                <label className='b'>Publication</label>
              </Box>

              <Box width={1}>
                {this.props.publication}
              </Box>
            </Flex>

            <Flex flexDirection='column' mb={2}>
              <Box width={1} mb={1}>
                <label className='b'>Date</label>
              </Box>
              <Box>
                {this.props.newsItem.date ? dateFormat(this.props.newsItem.date) : "N/A"}
              </Box>
            </Flex>

            <Flex flexDirection='column'>
              <Box width={1} mb={1}>
                <label className='b'>Text</label>
              </Box>
              <Box>
                {this.props.searchWords ?
                  <Highlighter
                    searchWords={this.props.searchWords}
                    autoEscape={true}
                    textToHighlight={this.props.newsItemText}
                  />
                : (this.props.newsItemText ? this.props.newsItemText : "No text found")}
              </Box>
            </Flex>



          </Box>

        </Paper>

      </Fragment>
    )
  }
}
