import React, { Component, Fragment } from "react"
import Abstracts from "./Abstracts"
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

export default class AbstractsContainer extends Component {
  constructor(props) {
    super(props)
  }
  
  render () {
    return (
      <Fragment>
        <Switch>
          <Route 
            path={`/sectors/${this.props.sector.abbr}/abstracts`} 
            children={<Abstracts {...this.props} />} 
          />
        </Switch>
      </Fragment>
    );
  }
}
