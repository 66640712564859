import React, { Component, Fragment } from "react"
import PropTypes from "prop-types"
import Paper from "../Paper"
import Flex from "../Flex"
import Box from "../Box"
import { List } from "../lists"
import UserCollectionListItem from "./UserCollectionListItem"
import Snackbar from "../Snackbar"
import update from "immutability-helper"
import Typography from "@material-ui/core/Typography"

export default class MyCollectionsContainer extends Component {
  constructor(props) {
    super(props)
    this.handleCollectionDeleteClick = this.handleCollectionDeleteClick.bind(this)
    this.handleUnfollowClick = this.handleUnfollowClick.bind(this)
    this.state = { userCollections: this.props.user_collections, showSnackbar: false, snackbarText: '' }
  }

  handleUnfollowClick(userCollection) {
    $.ajax({
      type: 'PUT',
      url: `/sectors/${this.props.sector.abbr}/user_collection_members/${userCollection.user_collection_member_id}`,
      data: {
        user_collection_member: {
          deleted: true, deleted_by: this.props.user.id
        }
      },
      dataType: 'json',
      success: (data) => {
        let index = this.state.userCollections.indexOf(userCollection)
        let userCollections = update(this.state.userCollections, {
          $splice: [[index,1]]
        })

        this.setState({
          userCollections: userCollections,
          showSnackbar: true,
          snackbarText: 'You have unfollowed the collection'
        })
      },
      error: (data) => {
        this.setState({
          showSnackbar: true,
          snackbarText: 'The collection could not be unfollowed'
        })
      },
      complete: (data) => {
        setTimeout(() => {
          this.setState({
            showSnackbar: false,
            snackbarText: ''
          })
        }, 4000)
      }
    })
  }

  handleCollectionDeleteClick(userCollection) {
    $.ajax({
      type: 'PUT',
      url: `/sectors/${this.props.sector.abbr}/user_collections/${userCollection.id}`,
      data: {
        user_collection: {
          deleted: true
        }
      },
      dataType: 'json',
      success: (data) => {
        let index = this.state.userCollections.indexOf(userCollection)
        let userCollections = update(this.state.userCollections, {
          $splice: [[index,1]]
        })

        this.setState({
          userCollections: userCollections,
          showSnackbar: true,
          snackbarText: 'The collection has been removed'
        })
      },
      error: (data) => {
        this.setState({
          showSnackbar: true,
          snackbarText: 'The collection could not be removed'
        })
      },
      complete: (data) => {
        setTimeout(() => {
          this.setState({
            showSnackbar: false,
            snackbarText: ''
          })
        }, 4000)
      }
    })
  }

  render() {
    return(
      <Fragment>
        <Paper fullHeight>
          <Box height="100%">
            <Box height="90%" overflow='auto'>

              {this.state.userCollections.length > 0 ?
                <Box width={1/2}>
                  <List>
                    {this.state.userCollections.map((userCollection) =>
                      <UserCollectionListItem
                        userCollection={userCollection}
                        sector={this.props.sector}
                        user={this.props.user}
                        handleCollectionDeleteClick={this.handleCollectionDeleteClick}
                        handleUnfollowClick={this.handleUnfollowClick}
                      />
                    )}
                  </List>
                </Box>
              :
                <Flex justifyContent='center' height="50%">
                  <Box alignSelf='center' maxWidth="50%">
                    <Box textAlign='center'>
                      <Typography variant="h6" component="h6">
                        No collections found
                      </Typography>

                      <Typography variant="body1">
                        <Box>
                          You can create collections by going to the List tab and
                        </Box>
                        <Box>
                          choosing the products you wish to add and clicking the bookmark icon
                        </Box>
                      </Typography>
                    </Box>
                  </Box>
                </Flex>
              }
            </Box>
          </Box>
        </Paper>

        <Snackbar
          open={this.state.showSnackbar}
          message={this.state.snackbarText}
        />
      </Fragment>
    )
  }
}
