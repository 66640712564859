import React, { Component, Fragment } from "react"
import ProfileFieldsContainer from "./ProfileFieldsContainer"
import Box from "../Box"
import Paper from "../Paper"
import Grid from '@material-ui/core/Grid';
import Link from "../Link"
import Typography from "@material-ui/core/Typography"
import { List, ListItemText, ListItemLink } from "../lists"
import Flex from "../Flex"
import { Button } from "../buttons"
import { Table, TableHead, TableBody, TableRow, TableCell } from "../tables"
import ArticlesList from "../articles/ArticlesList"
import Chart from "../charts/Chart"
import OverviewModule from "./profile/OverviewModule"

export default class ProfileDashboard extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    return(
      <Fragment>

        <Grid container component={Box} pb={3} spacing={3}>
          <Grid item xs={12} lg={6}>
            <OverviewModule
              company={this.props.company}
              overview_description={this.props.overview_description}
              companySectors={this.props.company_sectors}
              sector={this.props.sector}
            />
          </Grid>

          <Grid item xs={12} lg={6} container>
            <Grid item container direction="column" spacing={2}>
              <Grid item className='h-40'>
                <Paper className='h-100'>
                  <Typography variant="h6">
                    Products
                  </Typography>

                  <Box mt={1} height={1}>
                    <Box>
                      <List>
                        {this.props.products.map((product) =>
                          <ListItemLink dense divider key={product.id} href={`/sectors/${this.props.sector.abbr}/products/${product.id}`}>
                            <ListItemText primary={product.short_name} />
                          </ListItemLink>
                        )}
                      </List>
                    </Box>
                  </Box>
                </Paper>
              </Grid>

              <Grid item className='h-30'>
                <Box height={1}>
                  <Paper height="100%">
                    <Typography variant="h6">
                      Our Data
                    </Typography>

                    <Box mt={2}>
                      <List dense>

                        <ListItemLink href={`/sectors/${this.props.sector.abbr}/companies/${this.props.company.id}/pipeline`} divider={true}>
                          <ListItemText
                            primary={`${this.props.products.length} Products`}
                          />
                        </ListItemLink>

                        <ListItemLink href={`/sectors/${this.props.sector.abbr}/companies/${this.props.company.id}/trials`} divider={true}>
                          <ListItemText primary={`${this.props.trials_count} Trials`} />
                        </ListItemLink>

                        <ListItemLink href={`/sectors/${this.props.sector.abbr}/companies/${this.props.company.id}/patents`} divider={true}>
                          <ListItemText
                            primary={`${this.props.patents_count} Patents`}
                          />
                        </ListItemLink>

                        {/* <ListItemLink href={`/sectors/${this.props.sector.abbr}/products/${this.props.product.id}/adverse_events`} divider={true}>
                          <ListItemText
                            primary={`${this.props.adverse_events_count} Adverse Events`}
                          />
                        </ListItemLink> */}

                      </List>
                    </Box>
                  </Paper>
                </Box>


              </Grid>

            </Grid>
          </Grid>
        </Grid>

        <Grid container spacing={3} alignItems='stretch' component={Box} pb={3}>
          <Grid item xs={12} lg={12}>
            <Paper className='h-100'>
              <Flex alignItems='center'>
                <Box>
                  <Typography variant="h6">
                    Recently Updated Trials
                  </Typography>
                </Box>

                <Box ml='auto'>
                  <Button href={`/sectors/${this.props.sector.abbr}/companies/${this.props.company.id}/trials`} component='a'>
                    View All Trials
                  </Button>
                </Box>
              </Flex>

              <Box mt={2} height={1}>
                {this.props.trials.length > 0 ?
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Trial</TableCell>
                        <TableCell>Sponsor</TableCell>
                        <TableCell>Type</TableCell>
                        <TableCell>Last Updated</TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {this.props.trials.map((trial) =>
                        <TableRow key={trial.trial_id}>
                          <TableCell>
                            <Link href={`/sectors/${this.props.sector.abbr}/trials/${trial.trial_id}`}>
                              {trial.acronym || trial.short_name}
                            </Link>
                          </TableCell>
                          <TableCell>
                            {trial.company_id ?
                              <Link href={`/sectors/${this.props.sector.abbr}/companies/${trial.company_id}`}>
                                {trial.sponsor_name}
                              </Link>
                            :
                              trial.sponsor_name
                            }
                          </TableCell>
                          <TableCell>
                            {trial.grouped_types ?
                              trial.grouped_types.split(";").map((type) =>
                                <div>
                                  {type}
                                </div>
                              )
                            : ''}
                          </TableCell>
                          <TableCell>
                            {humanDateFormat(trial.last_updated)}
                          </TableCell>

                          <TableCell>
                            <Button href={`/sectors/${this.props.sector.abbr}/trials/${trial.trial_id}/updates`} component='a'>
                              View Updates
                            </Button>
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                :
                  <Flex justifyContent='center' height="50%">
                    <Box alignSelf='center' maxWidth="50%">
                      <Box>
                        <Typography variant="body1">
                          No trials found
                        </Typography>
                      </Box>
                    </Box>
                  </Flex>
                }
              </Box>
            </Paper>
          </Grid>


        </Grid>

        <Grid container spacing={3} justifyContent='center' alignItems='stretch' component={Box} pb={3}>
          <Grid item xs={12} lg={10}>
            <Paper style={{height: '600px'}}>
              <Typography variant="h6">
                Latest News
              </Typography>

              <Box height="100%">
                {this.props.news_items.length > 0 ?
                  <Box mt={2} overflow='auto' height="90%">
                    <ArticlesList
                      dense={true}
                      newsItems={this.props.news_items}
                      sector={this.props.sector}
                    />
                  </Box>
                :
                  <Flex justifyContent='center' height="50%">
                    <Box alignSelf='center' maxWidth="50%">
                      <Box>
                        <Typography variant="body1">
                          No articles found
                        </Typography>
                      </Box>
                    </Box>
                  </Flex>
                }
              </Box>
            </Paper>
          </Grid>
        </Grid>

        {this.props.sector.monitor_product ? ""
        :
          <Grid container spacing={3} alignItems='stretch' component={Box} pb={3}>

            <Grid item xs={12} lg={6}>
              <ProfileFieldsContainer
                profileFields={this.props.profile_fields}
                product={this.props.product}
                sector={this.props.sector}
              />
            </Grid>
          </Grid>
        }

      </Fragment>
    );
  }
}
