import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Chart from "../charts/Chart"
import Timeline from "../charts/Timeline"

export default class TimelineChartContainer extends Component {
  constructor(props) {
    super(props)
  }

  setXAxisMin() {
    let dateArray = []
    let maxDate = ""
    let minDate = ""

    if (this.props.chartData.length > 0) {
      this.props.chartData.map((data) => {
        dateArray.push(data.date)
      })
      maxDate = Math.max.apply(Math, dateArray)
      minDate = Math.min.apply(Math, dateArray)
    }

    let padding = (maxDate - minDate) * 0.05

    return(minDate - padding)
  }

  setXAxisMax() {
    let dateArray = []
    let maxDate = ""
    let minDate = ""

    if (this.props.chartData.length > 0) {
      this.props.chartData.map((data) => {
        dateArray.push(data.date)
      })
      maxDate = Math.max.apply(Math, dateArray)
      minDate = Math.min.apply(Math, dateArray)
    }

    let padding = (maxDate - minDate) * 0.05

    return(maxDate + padding)
  }

  formatData() {
    const chartData = this.props.chartData.map((chartData) => {
      let date = parseFloat(chartData.date)
      return({ x: date, name: chartData.name, label: chartData.label, description: chartData.label })
    })

    return(chartData)
  }

  render() {
    return(
      <Fragment>

        <Timeline
          chartId={this.props.chartId}
          chartOptions={{type: 'timeline', height: 300 }}
          title={this.props.title}
          formattedData={this.formatData()}
          xAxisMax={this.setXAxisMax()}
          xAxisMin={this.setXAxisMin()}
          yAxisVisible={false}
          xAxisVisible={false}
        />

      </Fragment>
    )
  }
}

TimelineChartContainer.defaultProps = {
  title: 'Trial Timeline',
  chartId: 'timeline'
}
