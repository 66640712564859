import React, { Fragment } from "react"
import { TableRow, TableCell } from "../tables"
import Box from "../Box"
import AttributeDisplay from "./AttributeDisplay"
import RangeAttributeDisplay from "./RangeAttributeDisplay"
import List from "../lists/List"

export default function RegionDataAttribute(props) {
  if (props.field == 'Excipient') {
    console.log(props.data)
  }

  let regionalData = props.data.filter(dataObject => props.header.name == 'Investigational' ? dataObject.investigational : dataObject.world_region_id == props.header.id)

  let numberValues = regionalData.filter(dataObject => dataObject.characteristic_value && dataObject.characteristic_value.metric ? dataObject : '').sort((a, b) => parseInt(a.characteristic_value.name) - parseInt(b.characteristic_value.name))

  let rangeData = regionalData.filter(data => data.range ? data : '').sort((a, b) => parseInt(a.characteristic_value.name) - parseInt(b.characteristic_value.name))

  console.log(rangeData)

  return (
    <TableCell borderRight={true} className='w-25'>
      <List dense={true} disablePadding>
        {numberValues.length > 1 ?
          rangeData.length > 0 ?
            <RangeAttributeDisplay
              rangeData={rangeData}
              sector={props.sector}
            />
          :
            numberValues.map((dataObject) =>
              <AttributeDisplay
                key={dataObject.id}
                data={dataObject.characteristic_value ? dataObject.characteristic_value.name : dataObject.name}
                metric={dataObject.characteristic_value && dataObject.characteristic_value.metric ? dataObject.characteristic_value.metric.name : ''}
                sourceId={dataObject.source_id}
                sector={props.sector}
              />
            )
        :
          rangeData.length > 0 ?
            <RangeAttributeDisplay
              rangeData={rangeData}
              sector={props.sector}
            />
          :
            regionalData.map((dataObject) =>
              <AttributeDisplay
                key={dataObject.id}
                data={dataObject.characteristic_value ? dataObject.characteristic_value.name : dataObject.name}
                metric={dataObject.characteristic_value && dataObject.characteristic_value.metric ? dataObject.characteristic_value.metric.name : null}
                sourceId={dataObject.source_id}
                sector={props.sector}
              />
            )
        }
      </List>
    </TableCell>
  )
}
