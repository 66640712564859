import React, { Fragment } from "react"
import Paper from "../../Paper"
import List from "../../lists/List";
import ProductListItem from "./ProductListItem"

export default function ProductsList(props) {
  const trialProductIds = props.trialProducts.map((trialProduct) => trialProduct.product_id)
  const productIdArray = [...trialProductIds, ...props.activeProducts]
  
  return (
    <Fragment>
      
      <List>
        {props.products.map((product) =>
          <ProductListItem 
            key={product.id}
            product={product}
            checked={productIdArray.indexOf(product.id) == -1 ? false : true}
            handleCheckboxSelect={props.handleCheckboxSelect}
          /> 
        )}
      </List>
      
    </Fragment>
  )
}
