import React, { Component } from "react"
import { Table, TableRow, TableCell, TableHead, TableBody } from "../tables"
import GroupedDataTable from "./GroupedDataTable"

export default function ActivityDataTable(props) {
  return (
    <React.Fragment>
      <Table size='small'>
        <TableHead>
          <TableRow hover={false}>
            <TableCell>Meeting</TableCell>
            <TableCell>Presentation</TableCell>
          </TableRow>
        </TableHead>
        
        <TableBody>
          {Object.keys(props.groupedAbstractsData).map((year) =>
            <GroupedDataTable 
              key={year}
              header={year}
              sector={props.sector}
              groupedAbstractsData={props.groupedAbstractsData[year]}
            />
          )}
        </TableBody>
      </Table>
    </React.Fragment>
  );
}
