import React, { Fragment } from "react"
import Typography from "@material-ui/core/Typography"
import { List } from "../../../lists"
import Box from "../../../Box"
import KClearanceListItem from "./ListItem"

export default function KClearanceList(props) {

  return(
    <Fragment>

      <Box width={1/2}>
        <Typography variant="h6">
          510(k) Records
        </Typography>

        <Box overflow="auto" height="80%">
          <List>
            {props.productKClearances.map((productKClearance) =>
              <KClearanceListItem
                key={productKClearance.id}
                productKClearance={productKClearance}
                sectors={props.sectors}
                handleDeleteClick={props.handleDeleteClick}
              />
            )}
          </List>
        </Box>
      </Box>

    </Fragment>
  )
}
