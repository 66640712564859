import React, { Component } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Chart from "../charts/Chart"

export default class EnrollmentChart extends Component {
  constructor(props) {
    super(props)
  }

  setVisible(array) {
    switch (array[0]) {
      case 'Current enrollment':
        return(true)
        break;
      case 'Target enrollment':
        return(true)
        break;
      default:
        return(false)
    }
    // switch
    //   when array[0] == 'Current enrollment' then true
    //   when array[0] in @props.activeSeriesArr then true
    //   else false
  }

  setLineColor(array) {
    switch (array[0]) {
      case 'Target enrollment':
        return('#FF0000')
        break;
      case 'Target enrollment changes':
        return('#FF0000')
        break;
      default:
        return('#357edd')
    }
  }

  determinePlotLine() {
    if (this.props.showTargetEnrollment && this.props.targetEnrollment) {
      return([{ color: '#FF0000', value: this.props.targetEnrollment.enrollment, width: 2, zIndex: 1, id: 'target-enrollment-line', label: { text: `Target Enrollment - ${this.props.targetEnrollment.enrollment}` } }])
    }
  }

  setXAxisPlotLine() {
    if (this.props.showEnrollmentDates) {
      return([{ color: '#B57E7E', value: this.props.enrollmentStartDate, width: 2, zIndex: 1, id: 'enrollment-start-date-line', label: { text: 'Enrollment start date' }}, { color: '#B57E7E', value: this.props.enrollmentEndDate, width: 2, zIndex: 1, id: 'enrollment-end-date-line', label: { text: 'Completion date' }}])
    }
  }

  setMarkerDisplay(array) {
    if (array[0] == 'Target enrollment') {
      return(false)
    } else {
      return(true)
    }
  }

  setDataLabelDisplay(array) {
    if (this.props.hideDataLabels) {
      return(false)
    } else {
      if (array[0] == 'Target enrollment') {
        return(false)
      } else {
        return(true)
      }
    }
  }

  setXaxisMin() {
    if (this.props.chartData.length == 0) {
      null
    } else {
      let enrollmentDateValArray = []
      let [first, ...enrollmentVals] = this.props.chartData[0]

      enrollmentVals.map(enrollmentArray =>
        enrollmentDateValArray.push(enrollmentArray[0])
      )

      if (!this.props.showEnrollmentDates) {

        return(Math.min.apply(Math, enrollmentDateValArray) - ((Math.max.apply(Math, enrollmentDateValArray) - Math.min.apply(Math, enrollmentDateValArray)) * 0.05))

      } else {

        let minEnrollmentDate = Math.min.apply(Math, enrollmentDateValArray)
        let maxEnrollmentDate = Math.max.apply(Math, enrollmentDateValArray)

        if (minEnrollmentDate < this.props.enrollmentStartDate) {

          let padding = (maxEnrollmentDate - minEnrollmentDate) * 0.05

          return(minEnrollmentDate - padding)

        } else {

          let lastWithEndDate = [maxEnrollmentDate, this.props.enrollmentEndDate]

          let padding = (Math.max.apply(Math, lastWithEndDate) - Math.min.apply(Math, lastWithEndDate)) * 0.05

          return(this.props.enrollmentStartDate - padding)
        }
      }
    }
  }

  setXaxisMax() {
    let enrollmentDateValArray = []
    let [first, ...enrollmentVals] = this.props.chartData[0]

    enrollmentVals.map(enrollmentArray =>
      enrollmentDateValArray.push(enrollmentArray[0])
    )

    if (!this.props.showEnrollmentDates) {

      return(((Math.max.apply(Math, enrollmentDateValArray) - Math.max.apply(Math, enrollmentDateValArray)) * 0.05) + Math.max.apply(Math, enrollmentDateValArray))

    } else {

      let maxEnrollmentDate = Math.max.apply(Math, enrollmentDateValArray)
      let lastWithEndDate = [maxEnrollmentDate, this.props.enrollmentEndDate]

      let padding = (Math.max.apply(Math, lastWithEndDate) - Math.min.apply(Math, lastWithEndDate)) * 0.05

      return(padding + Math.max.apply(Math, lastWithEndDate))
    }
  }

  setYaxisMax() {
    if (this.props.chartData.length == 0) {
      null
    } else {
      let enrollmentValArray = []
      let [first, ...enrollmentVals] = this.props.chartData[0]

      enrollmentVals.map(enrollmentArray =>
        enrollmentValArray.push(enrollmentArray[1])
      )

      if (this.props.targetEnrollment) {
        enrollmentValArray.push(this.props.targetEnrollment.enrollment)
      }

      let maxEnrollment = Math.max.apply(Math, enrollmentValArray)

      return(maxEnrollment + 5)
    }
  }

  setYaxisMin() {
    if (this.props.chartData.length == 0) {
      return(null)
    } else {
      let enrollmentValArray = []
      let [first, ...enrollmentVals] = this.props.chartData[0]

      enrollmentVals.map(enrollmentArray =>
        enrollmentValArray.push(enrollmentArray[1])
      )

      if (this.props.targetEnrollment) {
        enrollmentValArray.push(this.props.targetEnrollment.enrollment)
      }

      let minEnrollment = Math.min.apply(Math, enrollmentValArray)

      if (enrollmentValArray.length == 1) {
        return(enrollmentValArray[0] - 0.25 * enrollmentValArray[0])
      } else {
        return(null)
      }
    }

  }

  formatData() {
    let chartData = ""
    const data = this.props.chartData.map((array) => {
      let newArr = new Array(array)
      let flatArr = Array.prototype.concat.apply([], newArr)
      let dataArr = flatArr.splice(1)

      let chartData = dataArr.map((arr) => {
        let date = parseFloat(arr[0])
        return({ x: date, y: arr[1], name: arr[2] })
      })

      return({
        name: array[0],
        color: this.setLineColor(array),
        zIndex: (array[0] == 'Target enrollment' ? 2 : 3),
        visible: this.setVisible(array),
        marker: {
          enabled: this.setMarkerDisplay(array)
        },
        dataLabels: {
          enabled: this.setDataLabelDisplay(array)
        },
        data: chartData
      })
    })

    console.log(data)

    return(data)
  }

  render() {
    return(
      <Chart
        chartId={this.props.chartId}
        chartOptions={{type: 'spline', height: 300}}
        legend={true}
        plotOptions={{spline: { dataLabels: { enabled: true }, marker: { enabled: true }}}}
        // yAxisPlotLine={this.determinePlotLine()}
        xAxisType='datetime'
        xAxisPlotLine={this.setXAxisPlotLine()}
        xAxisMin={this.setXaxisMin()}
        xAxisMax={this.props.chartData.length > 0 ? this.setXaxisMax() : null}
        yAxisMax={this.setYaxisMax()}
        yAxisMin={this.setYaxisMin()}
        formattedData={this.formatData()}
        startOnTick={true}
        endOnTick={true}
        title={this.props.title}
        yAxisTitle={this.props.yAxisTitle}
        minTickInterval={28*24*3600*1000}
      />
    )
  }
}

EnrollmentChart.defaultProps = {
  chartId: 'enrollment-chart'
}
