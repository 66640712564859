import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Paper from "../../Paper"
import { Table, TableRow, TableHead, TableCell, TableBody } from "../../tables"
import IconButton from "../../IconButton"
import Flex from "../../Flex"
import Box from "../../Box"
import { Button } from "../../buttons"
import Typography from "@material-ui/core/Typography"
import update from "immutability-helper"
import StatementRow from "./StatementRow"


export default class CompanyStatements extends Component {
  constructor(props) {
    super(props)
    this.handleDeleteClick = this.handleDeleteClick.bind(this)
    this.state = { companyStatements: this.props.company_statements }
  }

  handleDeleteClick(companyStatement) {
    $.ajax({
      type: 'PUT',
      url: `/admin/companies/${this.props.company.id}/statements/${companyStatement.id}`,
      data: {
        company_statement: {
          deleted: true
        }
      },
      dataType: 'json',
      success: (data) => {
        let index = this.state.companyStatements.indexOf(companyStatement)

        let companyStatements = update(this.state.companyStatements, {
          $splice: [[index, 1]]
        })

        this.setState({
          companyStatements: companyStatements
        })
      },
      error: (data) => {
        this.setState({
          showSnackbar: true,
          snackbarText: 'There was an error creating the statement'
        })
      },
      complete: (data) => {
        setTimeout(() => {
          this.setState({
            showSnackbar: false,
            snackbarText: ''
          })
        }, 4000)
      }
    })
  }

  render() {
    return(
      <Fragment>
        <Paper minFullHeight={true}>

          <Flex align='items-center'>
            <Box>
            </Box>

            <Box className='ml-auto'>
              <Button href={`/admin/companies/${this.props.company.id}/statements/new`}>
                New Statement
              </Button>
            </Box>
          </Flex>

          <Table>

            <TableHead>
              <TableCell>Statement</TableCell>
              <TableCell>Sector</TableCell>
              <TableCell>Source</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableHead>

            <TableBody>
              {this.state.companyStatements.length > 0 ?
                this.state.companyStatements.map((companyStatement) =>
                  <StatementRow
                    company={this.props.company}
                    companyStatement={companyStatement}
                    handleDeleteClick={this.handleDeleteClick}
                  />
                )
              :
                <TableRow>
                  <TableCell colSpan={4}>
                    No company statements found
                  </TableCell>
                </TableRow>
              }
            </TableBody>
          </Table>

        </Paper>
      </Fragment>
    )
  }
}
