import React from "react"
import FdaPmaApprovalItem from "../fda_pma_approvals/SearchItem"

export default function RegulatoryItem(props) {

  return(
    <FdaPmaApprovalItem
      url={`/sectors/${props.sector.abbr}/fda_pma_approvals/${props.result.id}`}
      {...props}
    />
  )
}