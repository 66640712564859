import React from "react"
import PropTypes from "prop-types"
import { Table, TableRow, TableCell, TableHeadSort, TableBody } from "../../tables"
import Link from "../../Link"
import Checkbox from "../../Checkbox"

export default function AssignmentRow(props) {

  function handleCheckboxClick(e) {
    props.handleMarkCompleteClick(props.abstract)
  }

  return (
    <React.Fragment>
      <TableRow key={props.abstract.id}>
        <TableCell>
          <Checkbox
            onChange={handleCheckboxClick}
          />
        </TableCell>
        <TableCell>{props.abstract.number}</TableCell>
        <TableCell>
          <a href={`/admin/abstracts/${props.abstract.id}/uploader`} target="_blank">
            {props.abstract.title}
          </a>
        </TableCell>
        <TableCell>{props.abstract.date}</TableCell>
        <TableCell>{`${props.abstract.short_name} ${props.abstract.year}`}</TableCell>
        <TableCell>{`${props.abstract.first_name} ${props.abstract.last_name}`}</TableCell>
      </TableRow>
    </React.Fragment>
  );
}
