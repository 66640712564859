import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Header from "../../Header"
import Paper from "../../Paper"
import Flex from "../../Flex"
import Box from "../../Box"
import StatSection from "../../dashboard/StatSection"
import Typography from "@material-ui/core/Typography"
import { List, ListItem, ListItemText } from "../../lists"

export default class Dashboard extends Component {
  constructor(props) {
    super(props)
  }
  
  render() {
    return(
      <Fragment>
        
        <Header 
          header='Dashboard'
          background='bg-washed-green' 
        />
        
        <Flex alignItems='center' className='flex-stretch'>
          <Box mr={3} className='w-33'>
            <StatSection 
              statContent='1000'
              statDetail='Patents'
            />
          </Box>
          
          <Box className='w-33 mr3'>
            <StatSection 
              statContent='10'
              statDetail='New Patents'
              secondaryContent='This month'
            />
          </Box>
          
          <Box className='w-33'>
            <StatSection 
              statContent='12'
              statDetail='Products to review'
            />
          </Box>
        </Flex>
        
        <Flex my={4}>
          
          <Box width={1}>
            <Paper>
              <Typography variant="h6" component="h6" className='mv1'>Tasks</Typography>
              
              <div>
                <List>
                  {this.props.user_tasks.length > 0 ? this.props.user_tasks.map((task) => 
                    <ListItem key={task.id}>
                      <ListItemText primary={task.name} />
                      <ListItemText 
                        secondary={humanDateFormat(task.due_on)} 
                      />
                    </ListItem>
                  ) : <ListItem>
                    <ListItemText primary='No tasks found' />
                  </ListItem>}
                </List>
              
              </div>
            </Paper>
          </Box>
        </Flex>
        
        <Flex alignItems='center'>
          
          <Box mr={3} className='w-50-ns w-100 mr3'>
            <Paper>
              <Typography variant="h6" component="h6" className='mv0'>
                New Patents
              </Typography>
              
              <div>
                <List>
                  <ListItem>
                    <ListItemText primary='Patent #1' />
                    <ListItemText secondary='2019-08-27' />
                  </ListItem>
                </List>
              </div>
            </Paper>
          </Box>
          
          <Box className='w-50-ns w-100'>
            <Paper>
              <Typography variant="h6" component="h6" className='mv0'>Patents to Review</Typography>
              <div>
                <List>
                  <ListItem>
                    <ListItemText primaryText='Patent 2' />
                  </ListItem>
                </List>
              </div>
            </Paper>
          </Box>
          
        </Flex>
        
      </Fragment>
    )
  }
}
