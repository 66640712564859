import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Card from "../../cards/Card"
import CardSection from "../../cards/CardSection"
import SidebarSection from "../../SidebarSection"
import Link from "../../Link"
import Box from "../../Box"
import Flex from "../../Flex"
import RegionTabs from "./RegionTabs"

export default class RegionalProductCard extends Component {
  constructor(props) {
    super(props)
  }
  
  render() {
    const technologyGroups = this.props.technologyGroups.map((technologyGroup) =>
      <div key={technologyGroup.id}>
        <Link target="_blank" href={`/products?technology_group_id=${technologyGroup.id}&value=${technologyGroup.name}&value_type='Technology group'&product_id=${this.props.product.id}`}>
          {technologyGroup.name}
        </Link>
      </div>
    )
    const targetMarkets = this.props.targetMarkets.map((targetMarket) =>
      <div key={targetMarket.id}>
        <Link target="_blank" href={`/products?target_market_id=${targetMarket.id}&value=${targetMarket.id}&value_type='Target market'&product_id=${this.props.product.id}`}>{targetMarket.name}</Link>
      </div>
    )
    const therapyTypes = this.props.therapyTypes.map((therapyType) =>
      <div key={therapyType.id}>
        <Link target="_blank" href={`/products?therapy_type_id=${therapyType.id}&value=${therapyType.name}&value_type='Type of therapy'&product_id=${this.props.product.id}`}>
          {therapyType.name}
        </Link>
      </div>
    )
    
    const editUrl = `/admin/products/${this.props.product.id}/edit`
    return (
      <Card bgcolor='lightGreen'>
        
        <CardSection>
          <h4>Details</h4>
          <SidebarSection header='Full Name' content={this.props.product.full_name} />
          <SidebarSection header='Company' content={this.props.company.short_name} />
          
        </CardSection>
        
        
        <RegionTabs 
          activeRegionIndex={this.props.activeRegionIndex}
          handleRegionIndexUpdate={this.props.handleRegionIndexUpdate}
          worldRegions={this.props.worldRegions}
        />
        
        {this.props.regulatory_statuses ? 
          <CardSection>
            <h4>Regulatory Statuses</h4>
            {Object.keys(this.props.regulatory_statuses).map((region) =>
              <div key={region} className='mb2'>
                <div className='b'>
                  {region}
                </div>
                
                {this.props.regulatory_statuses[region].map((regulatoryStatus) =>
                  <div key={regulatoryStatus.id}>
                    {regulatoryStatus.name}
                  </div>
                )}
              </div>
            )}
            
          </CardSection> 
        : ''}
        
        {/* <CardSection>
          <h4>Collections</h4>
          {this.props.collections.map((collection) =>
            <div key={collection.id}>
              <Link href={`/products?collection_id=${collection.id}`}>{collection.name}</Link>
            </div>
          )}
        </CardSection> */}
        
        <CardSection>
          <h4>Attributes</h4>
          <SidebarSection header='Technology Group' content={technologyGroups} />
          <SidebarSection header='Target markets' content={targetMarkets} />
          <SidebarSection header='Type of therapy' content={therapyTypes} />
        </CardSection>
        
        <CardSection noBorder>
          <h4>Characteristics of technology</h4>
          {Object.keys(this.props.productCharacteristics).length === 0 ? 
            <div>None</div>
            : Object.keys(this.props.productCharacteristics).map((groupName) =>
            <div key={groupName} className='mb2'>
              <div className='b'>
                {groupName}
              </div>
              {this.props.productCharacteristics[groupName].map((productCharacteristic) =>
                <div key={productCharacteristic.id} className='mb1'>
                  {productCharacteristic.characteristic_value ? `${productCharacteristic.characteristic_value.name} ${productCharacteristic.characteristic_value.metric ? productCharacteristic.characteristic_value.metric.name : ''}` : 'N/A'}
                </div>
              )}
            </div>
          )}
        </CardSection>
      </Card>
    )
  }
}
