import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Paper from "../../Paper"
import { Table, TableHead, TableRow, TableCell, TableBody, EmptyDataTableRow } from "../../tables"
import InvestorPresentationRow from "./InvestorPresentationRow"
import update from "immutability-helper"
import Snackbar from "../../Snackbar"


export default class InvestorPresentations extends Component {
  constructor(props) {
    super(props)
    this.handleDeleteClick = this.handleDeleteClick.bind(this)
    this.handleUndoClick = this.handleUndoClick.bind(this)
    this.state = { investorPresentations: this.props.investor_presentations, showSnackbar: false, snackbarText: '', lastDeleted: '' }
  }
  
  handleUndoClick(e) {
    if (this.state.lastDeleted) {
      $.ajax({
        type: 'PUT',
        url: `/admin/investor_presentations/${this.state.lastDeleted.id}`,
        data: {
          investor_presentation: {
            deleted: null, deleted_by: null
          }
        },
        dataType: 'json',
        success: (data) => {
          const investorPresentations = update(this.state.investorPresentations, { $push: [this.state.lastDeleted] })
          this.setState({
            investorPresentations: investorPresentations,
            showSnackbar: true,
            snackbarText: 'Investor presentation was undeleted',
            lastDeleted: null
          })
        },
        error: (data) => {
          this.setState({
            showSnackbar: true,
            snackbarText: data.responseText
          })
        },
        completed: (data) => {
          setTimeout(() => {
            this.setState({
              showSnackbar: false,
              snackbarText: ''
            })
          }, 4000)
        }
      })
    }
  }
  
  handleDeleteClick(investorPresentation) {
    $.ajax({
      type: 'PUT',
      url: `/admin/investor_presentations/${investorPresentation.id}`,
      data: {
        investor_presentation: {
          deleted: true, deleted_by: this.props.current_user
        }
      },
      dataType: 'json',
      success: (data) => {
        const index = this.state.investorPresentations.indexOf(investorPresentation)
        let investorPresentations = update(this.state.investorPresentations, {
           $splice: [[index,1]] 
        })
        this.setState({
          investorPresentations: investorPresentations,
          showSnackbar: true,
          snackbarText: 'Investor presentation was deleted',
          lastDeleted: investorPresentation
        })
      },
      error: (data) => {
        this.setState({
          showSnackbar: true,
          snackbarText: `Investor presentation could not be deleted. ${data.responseText}`
        })
      },
      complete: (data) => {
        setTimeout(() => {
          this.setState({
            showSnackbar: false,
            snackbarText: ''
          })
        }, 4000)
      }
    })
  }
  
  render() {
    return(
      <Fragment>
        
        <Paper>
          <Table className='w-100'>
            <TableHead>
              <TableRow>
                <TableCell>Investor Presentation</TableCell>
                <TableCell>URL</TableCell>
                <TableCell>Created By</TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.state.investorPresentations.length > 0 ? this.state.investorPresentations.map((investorPresentation) =>
                <InvestorPresentationRow
                  key={investorPresentation.id} 
                  investorPresentation={investorPresentation}
                  handleDeleteClick={this.handleDeleteClick}
                />
              ) : <EmptyDataTableRow colSpan={5} message='No investor presentations yet. Create an investor presentation and it will display here' />}
            </TableBody>
          </Table>
        </Paper>
        
        <Snackbar 
          open={this.state.showSnackbar}
          handleUndoClick={this.handleUndoClick}
          handleClose={this.handleClose}
          message={this.state.snackbarText} 
        />
      </Fragment>
    )
  }
}
