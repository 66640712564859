import React, { Fragment } from "react"
import List from "../../lists/List";
import NestedListItemLink from "../../lists/NestedListItemLink"
import ListItem from '@material-ui/core/ListItem';
import ListItemText from "../../lists/ListItemText";
import ExpandLess from '../../ExpandLess';
import ExpandMore from '../../ExpandMore';
import Collapse from '@material-ui/core/Collapse';

export default class NavigationList extends React.Component {
  constructor(props) {
    super(props)
    this.state = { open: false }
  }

  componentDidMount() {
    if (this.props.activeGroup == 'Abstracts') {
      this.setState({
        open: true
      })
    }
  }

  render() {
    return(
      <Fragment>

        <ListItem button onClick={() => this.setState({open: !this.state.open})}>
          <ListItemText primary="Abstracts" />
          {this.state.open ? <ExpandLess /> : <ExpandMore />}
        </ListItem>

        <Collapse in={this.state.open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>

            <NestedListItemLink href="/admin/abstracts/dashboard" active={this.props.activeGroup == 'Abstracts' && this.props.activeItem == 'Dashboard'}>
              <ListItemText primary="Dashboard" />
            </NestedListItemLink>

            <NestedListItemLink href="/admin/abstracts/staged_associator" active={this.props.activeGroup == 'Abstracts' && this.props.activeItem == 'Staged Associator'}>
              <ListItemText primary="Staged Associator" />
            </NestedListItemLink>

            <NestedListItemLink href="/admin/abstracts/staged_extractor" active={this.props.activeGroup == 'Abstracts' && this.props.activeItem == 'Staged Extractor'}>
              <ListItemText primary="Staged Extractor" />
            </NestedListItemLink>

            <NestedListItemLink href="/admin/abstracts" active={this.props.activeGroup == 'Abstracts' && this.props.activeItem == 'List'}>
              <ListItemText primary="List" />
            </NestedListItemLink>

            <NestedListItemLink href="/admin/abstracts/new" active={this.props.activeGroup == 'Abstracts' && this.props.activeItem == 'New Abstract'}>
              <ListItemText primary="New Abstract" />
            </NestedListItemLink>

            <NestedListItemLink href="/admin/abstracts/reviewer" active={this.props.activeGroup == 'Abstracts' && this.props.activeItem == 'Reviewer'}>
              <ListItemText primary="Reviewer" />
            </NestedListItemLink>

          </List>
        </Collapse>

      </Fragment>
    )
  }
}
