import React from 'react'
import Paper from "../../Paper"
import Flex from "../../Flex"
import Box from "../../Box"
import TrialsTable from './TrialsTable'
import IndexFilters from './IndexFilters'
import Typography from '@material-ui/core/Typography'
import { withSnackbar } from "../../SnackbarContainer"

class IndexContainer extends React.Component {
  constructor(props) {
    super(props)
    this.handleFilterChange = this.handleFilterChange.bind(this)
    this.handleSearchClick = this.handleSearchClick.bind(this)
    this.handleClearSearchClick = this.handleClearSearchClick.bind(this)
    this.getTrials = this.getTrials.bind(this)
    this.getTrialsBySearch = this.getTrialsBySearch.bind(this)
    this.handleAddTrialClick = this.handleAddTrialClick.bind(this)
    this.state = { trials: this.props.trials, trialsCount: this.props.trials_count, filterValues: { page: this.props.page, sector_id: this.props.sector_id, company_id: this.props.company_id, product_id: this.props.product_id, query: this.props.query }, filterOptions: { sectors: this.props.sectors, companies: this.props.companies, products: this.props.products } }
  }

  handleAddTrialClick(trial) {
    // $.ajax({
    //   type: "POST",
    //   url: `/admin/ictrp_trials/${trial.id}/convert_to_trial`,
      
    // })
  }

  handleFilterChange(name, value) {
    let params = Object.assign({}, this.state.filterValues)

    params[name] = value

    this.getTrials(params)
  }

  handleSearchClick(query) {
    let params = Object.assign({}, this.state.filterValues)

    params['query'] = query

    this.getTrialsBySearch(params)
  }

  handleClearSearchClick(e) {
    let params = Object.assign({}, this.state.filterValues)

    params['query'] = null

    this.getTrials(params)
  }

  getTrials(params) {
    $.ajax({
      type: "GET",
      url: "/admin/ictrp_trials",
      data: params,
      dataType: 'json',
      success: (data) => {
        this.setState({
          trials: data.trials,
          trialsCount: data.trials_count,
          filterValues: params
        })
      }
    })
  }

  getTrialsBySearch(params) {
    $.ajax({
      type: "GET",
      url: "/admin/ictrp_trials/search",
      data: params,
      dataType: 'json',
      success: (data) => {
        this.setState({
          trials: data.trials,
          trialsCount: data.trials_count,
          filterValues: params
        })
      }
    })
  }

  render() {
    return(
      <Paper fullHeight>
        <Box height="100%">

          <Flex mb={2} alignItems='center'>
            <Box>
              <IndexFilters 
                filterOptions={this.state.filterOptions}
                filterValues={this.state.filterValues}
                handleFilterChange={this.handleFilterChange}
                handleSearchClick={this.handleSearchClick}
              />
            </Box>

            <Box ml='auto'>
              <Typography variant="body2">
                {`${this.state.trialsCount} trials`}
              </Typography>
            </Box>
          </Flex>

          <Box overflow="auto" height="80%">
            <TrialsTable 
              trials={this.state.trials}
              trialsCount={this.state.trialsCount}
              page={this.state.filterValues.page}
            />
          </Box>
        </Box>
      </Paper>
    )
  }
}

export default withSnackbar(IndexContainer)