import React from 'react'

function NumberDisplay(props) {
  return(
    <React.Fragment>
      {`${props.patent.country}${props.patent.xml_patent_no}${props.patent.patent_kind}`}
    </React.Fragment>
  )
}

export default NumberDisplay