import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Box from "../../Box"
import { Table, TableBody, TableRow, TableHead, TableCell, TableHeadSort } from "../../tables"
import Link from "../../Link"

export default class StagedExtractor extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    let headers = [{ label: '#', name: 'number' }, { label: 'Title', name: 'title' }, { label: 'Priority', name: 'priority_id' }, { label: 'Date', name: 'date' }, { label: 'PDF', title: 'pdf', align: 'right' } ]
    return(
      <Fragment>

        <Box overflow='auto' maxHeight='80%' mt={3}>
          <Table size='small' aria-label='staged extractor table'>

            <TableHeadSort
              headers={headers}
              order={this.props.order}
              orderBy={this.props.orderBy}
              onRequestSort={this.props.handleRequestSort}
            />

            <TableBody>
              {this.props.stableSort(this.props.abstracts, this.props.getSorting(this.props.order, this.props.orderBy)).map((abstract) =>
                <TableRow key={abstract.id} component={Link} href={this.props.activeIndex == 'products' ? `/admin/products/${abstract.product_id}/edit?source_id=${abstract.abstract_source_id}` : `/admin/abstracts/${abstract.id}/extractor`} target="_blank">

                  <TableCell>
                    {abstract.number}
                  </TableCell>

                  <TableCell>
                    {abstract.title}
                  </TableCell>

                  <TableCell>
                    {abstract.priority_id}
                  </TableCell>

                  <TableCell>
                    {humanDateFormat(abstract.date)}
                  </TableCell>

                  <TableCell align='right'>
                    {abstract.pdf ? <span className='red'>PDF</span> : ''}
                  </TableCell>

                </TableRow>
              )}
            </TableBody>
          </Table>
        </Box>

      </Fragment>
    )
  }
}
