import React, { Fragment } from "react"
import Box from "../../../Box"
import Typography from "@material-ui/core/Typography"
import SearchDropdown from "../../../filters/SearchDropdown"
import Select from "../../../Select"
import Flex from "../../../Flex"
import { RaisedButton } from "../../../buttons"

export default function Form(props) {

  return(
    <Fragment>
      <Typography variant="h6">
        New Acquisition
      </Typography>

      <Box mt={2}>

        <Flex>
          <Box width={1/2}>
            <SearchDropdown
              options={props.companies}
              handleQueryChange={props.handleQueryChange}
              handleEntityChange={props.handleCompanyChange}
            />
          </Box>

          <Box mr={3} width='25%'>
            <Select
              multiple
              entities={props.sectors}
              label='Sector'
              name='sector_ids'
              value={props.sectorIds}
              handleChange={props.handleSectorChange}
              width='100%'
              renderValue={selected => (
                <Flex flexWrap='wrap'>
                  {selected.join(", ")}
                </Flex>
              )}
            />
          </Box>

          <Box>
            <Box>
              <label>Date</label>
            </Box>
            <input
              type="date"
              onChange={props.handleDateChange}
              value={props.date}
            />
          </Box>

          <Box>
            <RaisedButton onClick={props.handleSaveClick}>
              Save
            </RaisedButton>
          </Box>
        </Flex>
      </Box>
    </Fragment>
  )
}
