import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Select from "../../../Select"
import TextInput from "../../../TextInput"
import Typography from '@material-ui/core/Typography';
import Flex from "../../../Flex"
import Box from "../../../Box"
import RaisedButton from "../../../buttons/RaisedButton"
import DatePicker from "../../../DatePicker"
import ButtonLayout from "../../ButtonLayout"

function Form(props) {

  function handleChange(e) {
    props.handleChange(e.target.name, e.target.value)
  }

  function handleDateTextChange(e) {
    let name = props.dateType == "expected" ? "approval_expected_date_txt" : "submitted_date_txt"

    props.handleChange(name, e.target.value)
  }

  return(
    <Fragment>

      <Flex alignItems='start' width={1} justifyContent='center'>

        <Box mr={3}>
          <TextInput
            name='path'
            label='Regulatory Path'
            value={props.productRegulatoryPath.path}
            onChange={handleChange}
            placeholder="PMA"
            fullWidth
          />
        </Box>

        <Box mr={3}>
          <TextInput
            name='indication'
            label='Indication'
            value={props.productRegulatoryPath.indication}
            onChange={handleChange}
            fullWidth
          />
        </Box>

        <Box mr={3} width='25%'>
          <Select
            entities={props.worldRegions}
            label='Region'
            name='world_region_id'
            value={props.productRegulatoryPath.world_region_id}
            handleChange={props.handleChange}
            width='100%'
          />
        </Box>

        <Box mr={3} width='25%'>
          <Select
            multiple
            entities={props.sectors}
            label='Sector'
            name='sector_ids'
            value={props.sectorIds}
            handleChange={props.handleSectorChange}
            width='100%'
            renderValue={selected => (
              <Flex flexWrap='wrap'>
                {selected.join(", ")}
              </Flex>
            )}
          />
        </Box>

      </Flex>

      <Flex alignItems='start' width={1} justifyContent='center' mt={4}>

        <Box>
          <Select
            entities={[{ name: "Expected", value: "expected" }, { name: "Submitted", value: "submitted" }]}
            label='Date Type'
            name='dateType'
            value={props.dateType}
            handleChange={props.handleDateTypeChange}
            width="100%"
          />
        </Box>

        <Box mx={3}>
          <TextInput
            name='date_txt'
            label='Date text'
            value={props.productRegulatoryPath.approval_expected_date_txt || props.productRegulatoryPath.submitted_date_txt}
            onChange={handleDateTextChange}
            fullWidth
          />
        </Box>

        <Box width='25%'>
          <Box>
            <label>Date</label>
          </Box>
          <input
            type="date"
            onChange={props.handleDateChange}
            value={props.productRegulatoryPath.submitted_date || props.productRegulatoryPath.approval_expected_date}
          />
        </Box>
      </Flex>

      <ButtonLayout>
        <RaisedButton handleClick={props.handleSubmitClick}>
          Save Path
        </RaisedButton>
      </ButtonLayout>

    </Fragment>
  )
}

export default Form
