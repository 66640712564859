import React from "react"
import Paper from "../../Paper"
import Flex from "../../Flex"
import Box from "../../Box"
import Link from "../../Link"

export default function ApprovalPanel(props) {
  const [editingDate, setEditingDate] = React.useState(false);
  const [date, setDate] = React.useState(props.deviceApproval.date);

  function handleBlur(e) {
    setEditingDate(false)
    props.setDeviceApproval(date)
  }

  return(
    <React.Fragment>

      <Paper fullHeight={true}>

        <Box height="100%" overflow="auto">

        <Flex justifyContent="right">
            {props.deviceApproval.url ? 
              <Box>
                <Link href={props.deviceApproval.url} target="_blank">
                  View on regulatory site
                </Link>
              </Box>
            : "No URL provided"}
          </Flex>

          <Flex flexDirection='column' mb={2}>
            <Box width={1} mb={1}>
              <label className='b'>Regulatory ID</label>
            </Box>
            <Box width={1}>
              {props.deviceApproval.regulatory_id}
            </Box>
          </Flex>

          <Flex flexDirection='column' mb={2}>
            <Box width={1} mb={1}>
              <label className='b'>Device Name</label>
            </Box>
            <Box width={1}>
              {props.deviceApproval.device_name || "N/A"}
            </Box>
          </Flex>

          <Flex flexDirection='column' mb={2}>
            <Box width={1} mb={1}>
              <label className='b'>Manufacturer</label>
            </Box>
            <Box width={1}>
              {props.deviceApproval.manufacturer || "N/A"}
            </Box>
          </Flex>

          <Flex flexDirection='column' mb={2}>
            <Box width={1} mb={1}>
              <label className='b'>Sponsor</label>
            </Box>
            <Box width={1}>
              {props.deviceApproval.sponsor_name || "N/A"}
            </Box>
          </Flex>

          <Flex flexDirection='column' mb={2}>
            <Box width={1} mb={1}>
              <label className='b'>Date</label>
              {props.deviceApproval.country_id == 110 ? <Link className='pl2' handleClick={() => setEditingDate(true)}>edit</Link> : ""}
            </Box>
            <Box width={1}>
              {editingDate 
              ? 
                <input
                  type="date"
                  value={date}
                  onChange={(e) => setDate(e.target.value)}
                  onBlur={handleBlur}
                  autoFocus={true}
                />
              : 
                props.deviceApproval.date || "N/A"
              }
            </Box>
          </Flex>

          <Flex flexDirection='column' mb={2}>
            <Box width={1} mb={1}>
              <label className='b'>Approval Type</label>
            </Box>
            <Box width={1}>
              {props.deviceApproval.approval_type || "N/A"}
            </Box>
          </Flex>

          <Flex flexDirection='column' mb={2}>
            <Box width={1} mb={1}>
              <label className='b'>Approval Class</label>
            </Box>
            <Box width={1}>
              {props.deviceApproval.approval_class || "N/A"}
            </Box>
          </Flex>

          <Flex flexDirection='column' mb={2}>
            <Box width={1} mb={1}>
              <label className='b'>Device Info</label>
            </Box>
            <Box width={1}>
              {props.deviceApproval.device_info || "N/A"}
            </Box>
          </Flex>

          <Flex flexDirection='column' mb={2}>
            <Box width={1} mb={1}>
              <label className='b'>Description</label>
            </Box>
            <Box width={1}>
              {props.deviceApproval.english_description || props.deviceApproval.description || "N/A"}
            </Box>
          </Flex>

          
        </Box>

      </Paper>

    </React.Fragment>
  )
}
