import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Paper from "../Paper"
import CompetitorsTable from "./CompetitorsTable"
import CompetitorFilters from "./CompetitorFilters"
import Box from "../Box"
import Typography from "@material-ui/core/Typography"

export default class Competitors extends Component {
  constructor(props) {
    super(props)
    this.handleFilterChange = this.handleFilterChange.bind(this)
    this.state = { products: this.props.products, target_market_id: '', technology_group_id: '', regulatory_status_id: '', world_region_id: '' }
  }
  
  handleFilterChange(name, value) {
    this.setState({
      [name]: value
    }, () => {
      const { products, ...rest } = this.state
      $.ajax({
        type: "GET",
        url: `/sectors/${this.props.sector.abbr}/products/${this.props.product.id}/competitors`,
        data: {...rest},
        dataType: 'json',
        success: (data) => {
          this.setState({
            products: data.products
          })
        },
        error: (data) => {
          this.setState({
            showSnackbar: true,
            snackbarText: 'An error has occurred. Please try again.'
          })
        }
      })
    })
  }
  
  render() {
    return(
      <Fragment>
        
        <Paper>
          
          <Typography variant="h6" component="h6">
            Competitive Matrix
          </Typography>
          
          <Box my={3}>
            <CompetitorFilters 
              targetMarkets={this.props.target_markets}
              technologyGroups={this.props.technology_groups}
              regulatoryStatuses={this.props.regulatory_statuses}
              worldRegions={this.props.world_regions}
              worldRegion={this.state.world_region_id}
              targetMarket={this.state.target_market_id}
              regulatoryStatus={this.state.regulatory_status_id}
              handleFilterChange={this.handleFilterChange}
            />
          </Box>
          
          <Typography variant="body2">
            {`${this.props.products.length} products`}
          </Typography>
          
          <Box>
            <CompetitorsTable
              products={this.state.products}
              targetMarket={this.state.target_market_id} 
              regulatoryStatus={this.state.regulatory_status_id}
              {...this.props}
            />
          </Box>
          
        </Paper>
        
      </Fragment>
    )
  }
}
