import React from "react"
import { TableRow, TableCell } from "../tables"
import Link from "../Link"
import Typography from "@material-ui/core/Typography"
import Box from "../Box"

export default function PatentRow(props) {
  return (
    <TableRow>
      <TableCell>{props.patent.html_patent_no}</TableCell>
      <TableCell>
        <Link href={`/sectors/${props.sector.abbr}/patents/${props.patent.id}`}>
          {props.patent.title}
        </Link>
        <Box>
          <Typography variant="caption">
            {props.patent.abstract}
          </Typography>
        </Box>
      </TableCell>
      <TableCell>
        <Link href={`/sectors/${props.sector.abbr}/companies/${props.patent.company_id}`}>
          {props.patent.company_name}
        </Link>
      </TableCell>
      <TableCell>{props.patent.effective_date_str}</TableCell>
    </TableRow>
  )
}
