import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Paper from "../Paper"
import Link from "../Link"
import InvestigatorsDataTable from "./InvestigatorsDataTable"
import InvestigatorsChartContainer from "./InvestigatorsChartContainer"
import Flex from "../Flex"
import Box from "../Box"
import Switch from "../Switch"
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Snackbar from "../Snackbar"
import IconButton from "../IconButton"
import ListIcon from '@material-ui/icons/List';
import BarChartIcon from '@material-ui/icons/BarChart';
import Select from "../Select"
import Typography from "@material-ui/core/Typography"

class Investigators extends Component {
  constructor(props) {
    super(props)
    this.handleStatusChange = this.handleStatusChange.bind(this)
    this.handleFilterChange = this.handleFilterChange.bind(this)
    this.getInvestigators = this.getInvestigators.bind(this)
    this.handleChartViewClick = this.handleChartViewClick.bind(this)
    this.handleListViewClick = this.handleListViewClick.bind(this)
    this.state = { view: 'list', investigators: this.props.investigators, currentInvestigators: this.props.current_investigators, clinical_status_ids: [], show_current: this.props.show_current, showSnackbar: false, snackbarText: '' }
  }

  handleStatusChange(e) {
    // let params = { clinical_status_ids: this.state.clinical_status_ids }
    // params["show_current"] = e.target.checked
    //
    // this.getInvestigators(params)
    this.setState({
      show_current: e.target.checked
    })
  }

  handleFilterChange(name, value) {
    let params = { show_current: this.state.show_current }

    params["clinical_status_ids"] = value

    this.getInvestigators(params)
  }

  getInvestigators(params) {
    $.ajax({
      type: "GET",
      url: `/sectors/${this.props.sector.abbr}/trials/${this.props.trial.id}/investigators`,
      data: params,
      dataType: 'json',
      success: (data) => {
        this.setState({
          investigators: data.investigators,
          currentInvestigators: data.current_investigators,
          show_current: params.show_current,
          clinical_status_ids: params.clinical_status_ids
        })
      },
      error: (data) => {
        this.setState({
          showSnackbar: true,
          snackbarText: 'There was an error. Please try again.'
        })
      },
      complete: (data) => {
        setTimeout(() => {
          this.setState({
            showSnackbar: false,
            snackbarText: ''
          })
        }, 4000)
      }
    })
  }


  handleListViewClick(e) {
    this.setState({
      view: 'list'
    })
  }

  handleChartViewClick(e) {
    this.setState({
      view: 'chart'
    })
  }

  render() {
    const selectedStatuses = this.props.status_options.filter(status => this.state.clinical_status_ids.includes(status.value) ? status : null)

    const chosenStatuses = this.props.status_options.filter(status => this.state.clinical_status_ids.includes(status.value) ? status : null)
    return(
      <Fragment>

        <Paper fullHeight={this.state.view == 'list' ? true : false}>

          <Flex alignItems='center'>
            {/* <Box mr={4}>
              <Select
                multiple
                label='Status'
                name='clinical_status_ids'
                value={this.state.clinical_status_ids}
                entities={this.props.status_options}
                handleChange={this.handleFilterChange}
                renderValue={selected => (
                  <Flex flexWrap='wrap'>
                    {`${selectedStatuses.length} chosen`}
                  </Flex>
                )}
              />
            </Box> */}

            <Box>
              {this.state.view == 'list' ?
                <FormControlLabel
                  control={
                    <Switch
                      checked={this.state.show_current}
                      handleChange={this.handleStatusChange}
                    />
                  }
                  label="Show current investigators only"
                />
              : ''}
            </Box>

            <Flex ml='auto' alignItems='center'>
              <Box>
                <IconButton onClick={this.handleListViewClick} color={this.state.view == 'list' ? 'primary' : 'default'}>
                  <ListIcon />
                </IconButton>
              </Box>

              <Box>
                <IconButton onClick={this.handleChartViewClick} color={this.state.view == 'chart' ? 'primary' : 'default'} tooltip='Chart'>
                  <BarChartIcon />
                </IconButton>
              </Box>
            </Flex>
          </Flex>

          <Box height="100%" mt={2}>

            {this.state.investigators.length > 0 ?

              this.state.view == 'list' ?
                <InvestigatorsDataTable
                  investigators={this.state.investigators}
                  currentInvestigators={this.state.currentInvestigators}
                  showCurrent={this.state.show_current}
                  sector={this.props.sector}
                />
              :
                <InvestigatorsChartContainer
                  investigatorsChartData={this.props.investigators_chart_data}
                  investigators={this.state.investigators}
                  currentInvestigators={this.state.currentInvestigators}
                  enrollmentStartDate={this.props.enrollment_start_date}
                  enrollmentStartDateForChart={this.props.enrollment_start_date_for_chart}
                  enrollmentEndDateForChart={this.props.enrollment_end_date_for_chart}
                  enrollmentEndDate={this.props.enrollment_end_date}
                  statusChartData={this.props.status_chart_data}
                  currentTime={this.props.current_time}
                  showEnrollmentDates={true}
                  sector={this.props.sector}
                />


            :
              <Flex justifyContent='center' height="50%">
                <Box alignSelf='center' maxWidth="50%">
                  <Box>
                    <Typography variant="body1">
                      No investigators found for this trial
                    </Typography>
                  </Box>
                </Box>
              </Flex>
            }
          </Box>
        </Paper>

        <Snackbar
          open={this.state.showSnackbar}
          message={this.state.snackbarText}
        />

      </Fragment>
    )
  }
}

export default Investigators
