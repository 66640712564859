import React, { Component, Fragment } from "react"
import Flex from "../../Flex"
import Box from "../../Box"
import Checkbox from "../../Checkbox"
import { Button } from "../../buttons"
import { List, ListItem, ListItemText } from "../../lists"
import ExpandLess from '../../ExpandLess';
import ExpandMore from '../../ExpandMore';
import Collapse from '@material-ui/core/Collapse';

export default class CharacteristicGroupDisplay extends Component {
  constructor(props) {
    super(props)
    this.handleRangeClick = this.handleRangeClick.bind(this)
    this.handleDiscreteClick = this.handleDiscreteClick.bind(this)
    this.state = { open: false, range: true, discrete: false }
  }

  handleRangeClick(e) {
    this.setState({
      range: e.target.checked
    })
  }

  handleDiscreteClick(e) {
    this.setState({
      discrete: e.target.checked
    })
  }

  render() {
    const groupDisplay = this.props.characteristicGroup.region ? `${this.props.characteristicGroup.region} ${this.props.characteristicGroup.name}` : this.props.characteristicGroup.name
    return(
      <Fragment>
        <List>

          <ListItem button onClick={() => this.setState({open: !this.state.open})}>
            {this.state.open ? <ExpandLess /> : <ExpandMore />}
            <ListItemText primary={groupDisplay} />
          </ListItem>

          <Collapse in={this.state.open} timeout="auto" unmountOnExit>

            {(groupDisplay.toLowerCase().includes('length') || groupDisplay.includes('Diameter')) && this.props.activeRegionIndex == 1000000 ? <Flex align='items-center' justifyContent='center'>
              <Box mr={4}>
                <Checkbox
                  name={`${groupDisplay}_range`}
                  label='Range'
                  checked={this.props[`${groupDisplay}_range`]}
                  onClick={this.props.handleRangeClick}
                />
              </Box>

              <Box>
                <Checkbox
                  name={`${groupDisplay}_discrete`}
                  label='Discrete'
                  checked={this.props[`${groupDisplay}_discrete`]}
                  onClick={this.props.handleDiscreteClick}
                />
              </Box>
            </Flex> : ''}

            <List component="div" disablePadding>

              {this.props.displayCharacteristics}

              <Flex>
                <Box></Box>
                <Box className='ml-auto'>
                  <Button tag='a' href={`/admin/characteristic_values/new?characteristic_group_id=${this.props.characteristicGroup.id}&r=/admin/products/${this.props.product.id}/edit?active_index=2`}>
                    Add Value
                  </Button>
                </Box>
              </Flex>

            </List>
          </Collapse>
        </List>
      </Fragment>
    )
  }
}
