import React, {Component} from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Paper from "../Paper"
import { Table, TableHead, TableRow, TableCell, TableBody } from "../tables"
import EntityHeader from "./EntityHeader"
import Link from "../Link"
import CompareFieldRow from "./CompareFieldRow"
import { Button, RaisedButton, Fab } from "../buttons"
import Flex from "../Flex"
import Box from "../Box"
import DeleteButton from "../admin/DeleteButton"
import Tooltip from '@material-ui/core/Tooltip';

export default class CompareEntities extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    return(
      <Box overflow='auto' height="80%">

        <Table aria-label='compare table' stickyHeader={true}>
          <TableHead>
            <TableRow hover={false}>
              <TableCell borderRight={true} className='bg-white'>
                <Fab onClick={this.props.handleAddEntityClick} />
              </TableCell>

              {this.props.entities.length > 0 ? this.props.entities.map((entity) =>
                <EntityHeader
                  key={entity.id}
                  entity={entity}
                  activeEntity={this.props.activeEntity}
                  sector={this.props.sector}
                  entityType={this.props.entityType}
                  handleEntityRemovalClick={this.props.handleEntityRemovalClick}
                />
              ) : ''}
            </TableRow>
          </TableHead>

          <TableBody>
            {this.props.fields.length > 0 ? this.props.fields.sort().map((field) =>
              <CompareFieldRow
                key={field}
                field={field}
                entities={this.props.entities}
                humanizedFields={this.props.humanizedFields}
                rootUrl={this.props.rootUrl}
                sector={this.props.sector}
              />
            ) : ''}
          </TableBody>

        </Table>

      </Box>
    )
  }
}
