import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import DashboardModule from "../../dashboard/Module"
import Flex from "../../Flex"
import Box from "../../Box"
import { Button, RaisedButton } from "../../buttons"
import Link from "../../Link"
import Paper from "../../Paper"
import { Table, TableRow, TableCell, TableHead, TableBody } from "../../tables"
import Typography from "@material-ui/core/Typography"
import InfoIcon from '@material-ui/icons/Info';
import NewTrialsModule from "../trials/NewTrialsModule"
import UpdatedModule from "../trials/UpdatedModule"
import NewPublicationsModule from "../publications/NewPublicationsModule"
import NewPressReleasesModule from "../press_releases/List"
import NewNewsArticleModule from "../news_articles/List"
import Grid from '@material-ui/core/Grid';
import TasksList from "./TasksList"

export default class Dashboard extends Component {
  constructor(props) {
    super(props)
  }


  render() {
    return (
      <Fragment>

        <Box mb={3}>
          <Typography variant="h6">
            {`Activity for ${humanDateFormat(this.props.date)} to ${humanDateFormat(this.props.current_date)}`}
          </Typography>
        </Box>

        <Grid container alignItems='stretch' spacing={3} component={Box} pb={3}>
          <Grid item xs={10}>
            <DashboardModule className='h-100'>
              <Typography variant="h6">
                Tasks
              </Typography>
              <TasksList 
                manuscripts_to_review={this.props.manuscripts_to_review}
                ct_gov_trials_to_review={this.props.ct_gov_trials_to_review}
                news_items_added={this.props.news_items_added}
                products_with_pma_predictions={this.props.products_with_pma_predictions}
                products_with_510_k_predictions={this.props.products_with_510_k_predictions}
                trials_with_predicted_products={this.props.trials_with_predicted_products}
                trials_with_newly_associated_products={this.props.trials_with_newly_associated_products}
                device_recalls_to_review={this.props.device_recalls_to_review}
                // manuscripts_to_associate={this.props.manuscripts_to_associate}
              />
            </DashboardModule>
          </Grid>
        </Grid>

        <Grid container alignItems='stretch' spacing={3} component={Box} pb={3}>

          <Grid item xs={6}>
            <DashboardModule className='h-100'>
              <Typography variant="h6">
                New Trials
              </Typography>
              <NewTrialsModule
                newTrials={this.props.new_trials}
              />
            </DashboardModule>
          </Grid>

          <Grid item xs={6}>
            <DashboardModule className='h-100'>
              <Typography variant="h6">
                Recently Updated
              </Typography>
              <UpdatedModule
                trials={this.props.newly_updated_trials}
              />
            </DashboardModule>
          </Grid>

        </Grid>

        <Grid container alignItems='stretch' spacing={3} justifyContent='center' component={Box} pb={3}>
          <Grid item xs={10}>
            <DashboardModule className='h-100'>
              <Typography variant="h6">
                Publications
              </Typography>
              {this.props.new_publications.length > 0 ?
                <NewPublicationsModule
                  newPublications={this.props.new_publications}
                />
              : "No new publications"}
            </DashboardModule>
          </Grid>
        </Grid>

      </Fragment>
    )
  }
}
