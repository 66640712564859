import React, { Fragment } from "react"
import PropTypes from "prop-types"
import Typography from "@material-ui/core/Typography"
import Flex from "../Flex"
import Box from "../Box"

export default function AlertDisplay(props) {

  return(
    <Box mb={2} width={1/2}>

      <Box mb={0.5}>
        <Box>
          <div dangerouslySetInnerHTML={{__html: props.alert.body }} />
        </Box>

        {props.alert.body_secondary ?
          <Box mt={0.5}>
            <Typography variant="caption" component="span" color="textSecondary">
              <span dangerouslySetInnerHTML={{__html: props.alert.body_secondary }} />
            </Typography>
          </Box>
        : ""}
      </Box>

      <Flex alignItems='baseline'>
        <Fragment>
          {props.alert.date ?
            <Box mr={2}>
              <Typography variant="caption" component="span" color="textSecondary">
                {humanDateFormat(props.alert.date)}
              </Typography>
            </Box>
          : ''}
          {props.alert.source_text ?
            <Box mr={2}>
              <Typography variant="caption" component="span" color="textSecondary">
                {props.alert.source_text}
              </Typography>
            </Box>
          : ''}
          {props.alert.citation ?
            <Box mr={2}>
              <Typography variant="caption" component="span" color="textSecondary">
                {props.alert.citation}
              </Typography>
            </Box>
          : ""}
        </Fragment>

      </Flex>
    </Box>
  )
}
