import React, { Component } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Paper from "../Paper"
import TextInput from "../TextInput"
import RaisedButton from "../buttons/RaisedButton"
import Link from "../Link"

class ForgotPassword extends Component {
  constructor(props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
    this.handleResetPasswordClick = this.handleResetPasswordClick.bind(this)
    this.state = { email: '' }
  }
  
  handleResetPasswordClick(e) {
    e.preventDefault()
    $.ajax({
      type: 'POST',
      url: "/users/password",
      data: {
        user: {
          email: this.state.email
        }
      }
    })
  }
  
  handleChange(e) {
    let name = e.target.name
    let value = e.target.value
    this.setState({
      [name]: value
    })
  }
  
  render() {
    return(
      <section className="mt5 mw5 mw7-ns center pa3 ph5-ns">
        <Paper className='mt4 w-70 center'>
          <div>
            <h3>Forgot your password?</h3>
            <p>No worries, just enter your email below</p>
          </div>
          <div className='mv4'>
            <TextInput 
              label='Email' 
              name='email' 
              value={this.state.email} 
              onChange={this.handleChange} 
              className='w-100' 
            />
          </div>
          <div className='w-100 mt4'>
            <RaisedButton className='w-100' handleClick={this.handleResetPasswordClick}>
              Reset Password
            </RaisedButton>
          </div>
        </Paper>
      </section>
    )
  }
}

export default ForgotPassword
