import React from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Flex from "../Flex"
import Box from "../Box"
import Paper from "../Paper"
import { Table, TableRow, TableHead, TableCell, TableBody } from "../tables"
import Link from "../Link"
import FdaLink from "./FdaLink"

export default class Profile extends React.Component {
  constructor(props) {
    super(props)
  }

  // componentDidMount() {
  //   let pmaApprovalIds = this.props.pma_approvals.map(pmaApproval => pmaApproval.id)
  //   let index = pmaApprovalIds.indexOf(this.props.pma_approval.id)
  //
  //   this.setState({
  //     activeIndex: index
  //   })
  // }

  desc(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = cmp(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
  }

  getSorting(order, orderBy) {
    return order === 'desc' ? (a, b) => this.desc(a, b, orderBy) : (a, b) => -this.desc(a, b, orderBy);
  }

  handleActiveIndexUpdate(e, newValue) {
    this.setState({
      activeIndex: newValue
    })
  }

  render() {
    return (
      <React.Fragment>

        <Paper>

          {/* <Box mb={2} fontSize={14}>
            <Link href={`/sectors/${this.props.sector.abbr}/fda_pma_approvals`}>
              Back to list
            </Link>
          </Box> */}

          <Box mt={3}>
            <Table>
              <TableHead>
                <TableRow hover={false}>
                  <TableCell borderRight={true}>
                    <FdaLink 
                      k_number={this.props.fda_k_clearance.k_number} 
                    />
                  </TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {this.props.k_clearance_attributes.map((kClearanceAttributeHash) =>
                  <TableRow key={kClearanceAttributeHash.attr_value}>
                    <TableCell borderRight={true} fontWeight="bold" className='w-25'>
                      {kClearanceAttributeHash.header}
                    </TableCell>
                    <TableCell>
                      {kClearanceAttributeHash.date ? humanDateFormat(this.props.fda_k_clearance[kClearanceAttributeHash.attr_value]) : kClearanceAttributeHash.value ? kClearanceAttributeHash.value : this.props.fda_k_clearance[kClearanceAttributeHash.attr_value] }
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </Box>

        </Paper>
      </React.Fragment>
    )
  }
}
