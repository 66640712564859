import React, { Component, Fragment } from "react"
import Paper from "../../Paper"
import Typography from '@material-ui/core/Typography';
import Box from "../../Box"
import update from "immutability-helper"
import ManuscriptSearchResultsList from "../manuscripts/SearchResultsList"
import AbstractSearchResultsList from "../abstracts/SearchResultsList"
import CircularProgress from '@material-ui/core/CircularProgress';
import { Tabs, Tab, TabPanel } from "../../tabs"
import Snackbar from "../../Snackbar"
import SearchTermForm from "./SearchTermForm"
import Link from "../../Link"
import NewsSearchResultsList from "../news/SearchResultsList"

export default class SearchTermContainer extends Component {
  constructor(props) {
    super(props)
    this.handleTermChange = this.handleTermChange.bind(this)
    this.handleSaveClick = this.handleSaveClick.bind(this)
    this.handleTestClick = this.handleTestClick.bind(this)
    this.handleActiveIndexClick = this.handleActiveIndexClick.bind(this)
    this.handleCheckboxClick = this.handleCheckboxClick.bind(this)
    this.getTestResults = this.getTestResults.bind(this)
    this.state = { trialSearchTerm: this.props.trial_search_term, abstracts: this.props.abstracts, manuscripts: this.props.manuscripts, news: this.props.news, loading: false, activeIndex: 0, showSnackbar: false, snackbarText: '' }
  }

  handleActiveIndexClick(e, newValue) {
    this.setState({
      activeIndex: newValue
    })
  }

  handleCheckboxClick(e) {
    let trialSearchTerm = update(this.state.trialSearchTerm, {
      [e.target.name]: { $set: e.target.checked }
    })

    this.setState({
      trialSearchTerm: trialSearchTerm
    })
  }

  handleTestClick(e) {
    let searchTerm = this.getTestResults({ term: this.state.trialSearchTerm.search_term, case_sensitive: this.state.trialSearchTerm.case_sensitive })
  }

  getTestResults(searchParams) {
    if (searchParams.term == "") {
      this.setState({
        abstracts: [],
        manuscripts: []
      })
    } else {
      $.ajax({
        type: 'GET',
        url: `/admin/trials/${this.props.trial.id}/term_search`,
        data: searchParams,
        dataType: 'json',
        success: (data) => {
          this.setState({
            abstracts: data.abstracts,
            manuscripts: data.manuscripts,
            news: data.news,
            loading: false
          })
        },
        beforeSend: (data) => {
          this.setState({
            loading: true
          })
        },
        error: (data) => {
          this.setState({
            loading: false,
            showSnackbar: true,
            snackbarText: 'There was an error fetching results. Please try again.'
          })
        },
        complete: (data) => {
          setTimeout(() => {
            this.setState({
              showSnackbar: false,
              snackbarText: ''
            })
          }, 5000)
        }
      })
    }
  }

  handleSaveClick(e) {
    if (this.state.trialSearchTerm.id) {
      $.ajax({
        type: 'PUT',
        url: `/admin/trials/${this.props.trial.id}/trial_search_terms/${this.state.trialSearchTerm.id}`,
        data: {
          trial_search_term: this.state.trialSearchTerm
        },
        dataType: 'json',
        success: (data) => {
          this.setState({
            trialSearchTerm: data,
            showSnackbar: true,
            snackbarText: 'Search term was saved'
          })
        },
        error: (data) => {
          this.setState({
            showSnackbar: true,
            snackbarText: data.responseText
          })
        },
        complete: (data) => {
          setTimeout(() => {
            this.setState({
              showSnackbar: false,
              snackbarText: ''
            })
          }, 5000)
        }
      })
    } else {
      $.ajax({
        type: 'POST',
        url: `/admin/trials/${this.props.trial.id}/trial_search_terms`,
        data: {
          trial_search_term: this.state.trialSearchTerm
        },
        dataType: 'json',
        success: (data) => {
          window.location = `/admin/trials/${this.props.trial.id}/search_terms`
        },
        error: (data) => {
          this.setState({
            showSnackbar: true,
            snackbarText: data.responseText
          })
        },
        complete: (data) => {
          setTimeout(() => {
            this.setState({
              showSnackbar: false,
              snackbarText: ''
            })
          }, 5000)
        }
      })
    }
  }

  handleTermChange(e) {
    let trialSearchTerm = update(this.state.trialSearchTerm, {
      search_term: { $set: e.target.value }
    })

    this.setState({
      trialSearchTerm: trialSearchTerm
    })
  }

  render() {
    return(
      <Fragment>

        <Paper minFullHeight>
          <Box mb={3}>
            <Link href={`/admin/trials/${this.props.trial.id}/trial_search_terms`}>
              Back to search terms
            </Link>
          </Box>

          <Typography variant="h6" component="h6">
            Search Term
          </Typography>

          <Box mt={3}>

            <SearchTermForm
              trialSearchTerm={this.state.trialSearchTerm}
              trialDataView={this.props.trial_data_view}
              handleTermChange={this.handleTermChange}
              handleTestClick={this.handleTestClick}
              handleSaveClick={this.handleSaveClick}
              handleCheckboxClick={this.handleCheckboxClick}
            />

            {this.state.loading ?
              <Box>
                <CircularProgress />
              </Box>
            : ''}

            <Box mt={3}>
              <Typography variant="h6" component="h6">
                Results
              </Typography>

              <Box mt={4}>
                <Tabs variant="fullWidth" value={this.state.activeIndex} onChange={this.handleActiveIndexClick} className='bb b--black-10'>
                  <Tab label={`Presentations (${this.state.abstracts.length})`} />
                  <Tab label={`Publications (${this.state.manuscripts.length})`} />
                  <Tab label={`News (${this.state.news.length})`} />
                </Tabs>
              </Box>

              <TabPanel value={this.state.activeIndex} index={0}>
                <AbstractSearchResultsList
                  abstracts={this.state.abstracts}
                />
              </TabPanel>

              <TabPanel value={this.state.activeIndex} index={1}>
                <ManuscriptSearchResultsList
                  manuscripts={this.state.manuscripts}
                />
              </TabPanel>

              <TabPanel value={this.state.activeIndex} index={2}>
                <NewsSearchResultsList
                  news={this.state.news}
                />
              </TabPanel>
            </Box>
          </Box>
        </Paper>

        <Snackbar
          open={this.state.showSnackbar}
          message={this.state.snackbarText}
        />

      </Fragment>
    )
  }
}
