import React, { Component, Fragment } from "react"
import Paper from "../../Paper"
import { Table, TableHead, TableRow, TableCell, TableBody } from "../../tables"
import Box from "../../Box"
import Flex from "../../Flex"
import { withSnackbar } from "../../SnackbarContainer"
import Link from "../../Link"
import SectorFilter from "../filters/Sector"
import Typography from "@material-ui/core/Typography"
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TablePaginationActions from "../../tables/TablePaginationActions"

class Patents extends Component {
  constructor(props) {
    super(props)
    this.handleSectorChange = this.handleSectorChange.bind(this)
    this.handleChangePage = this.handleChangePage.bind(this)
    this.state = { patents: this.props.patents, patentsCount: this.props.patents_count, sector_id: this.props.sector_id, page: this.props.page }
  }

  handleChangePage(e, newPage) {
    let params = { sector_id: this.state.sector_id }

    params["page"] = newPage

    this.getPatents(params)
  }

  handleSectorChange(name, value) {
    this.getPatents({ sector_id: value, page: this.state.page })
  }

  getPatents(params) {
    $.ajax({
      type: "GET",
      url: `/admin/companies/${this.props.company.id}/patents`,
      data: params,
      dataType: 'json',
      success: (data) => {
        this.setState({
          patents: data.patents,
          patentsCount: data.patents_count,
          sector_id: params.sector_id,
          page: params.page
        })
      },
      error: (data) => {
        this.props.snackbarShowMessage("Patents could not be fetched. Please try again")
      }
    })
  }

  render() {
    return(
      <Paper fullHeight>

        <Flex>
          <Box mb={2} width={1/4}>
            <SectorFilter
              sector_id={this.state.sector_id}
              sectors={this.props.sectors}
              handleSectorChange={this.handleSectorChange}
            />
          </Box>

          <Box ml='auto'>
            <Typography variant="caption">
              {`${this.state.patentsCount} Patents`}
            </Typography>
          </Box>
        </Flex>

        <Box overflow="auto" height="90%">

          <Table size='small' stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>#</TableCell>
                <TableCell>Title</TableCell>
                <TableCell>Date</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {this.state.patents.map((patent) =>
                <TableRow key={patent.id}>
                  <TableCell>{patent.html_patent_no}</TableCell>
                  <TableCell>
                    <Link href={`/admin/patents/${patent.id}/associator`} target="_blank">
                      {patent.title}
                    </Link>
                  </TableCell>
                  <TableCell>{patent.effective_date_str}</TableCell>
                </TableRow>
              )}
            </TableBody>
            <TableFooter>
              <TablePagination
                colSpan={4}
                count={this.state.patentsCount}
                rowsPerPage={100}
                page={this.state.page}
                SelectProps={{
                  inputProps: { 'aria-label': 'rows per page' },
                  native: true,
                }}
                onChangePage={this.handleChangePage}
                onChangeRowsPerPage={this.handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableFooter>
          </Table>
        </Box>

      </Paper>
    )
  }
}

export default withSnackbar(Patents)
