import React, { Fragment } from "react"
import Paper from "../../Paper"
import List from "../../lists/List";
import AssociatedProductListItem from "./AssociatedProductListItem"

export default function AssociatedProductsList(props) {
  
  return (
    <Fragment>
      
      <List>
        {props.trialProducts.map((trialProduct) =>
          <AssociatedProductListItem
            trialProduct={trialProduct} 
            handleInvestigatedSwitchChange={props.handleInvestigatedSwitchChange}
            handleUnassociateClick={props.handleUnassociateClick}
          />
        )}
      </List>
      
    </Fragment>
  )
}
