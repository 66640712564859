import React, { Fragment, Component } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import CompareContainer from "./CompareContainer"
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

export default class CompareRouterContainer extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    console.log(this.props.product)
    let url = this.props.product ? `/sectors/${this.props.sector.abbr}/products/${this.props.product.id}/compare` : `/sectors/${this.props.sector.abbr}/products`
    return(
      <Fragment>
        <Switch>
          <Route
            path={url}
            children={<CompareContainer {...this.props} />}
          />
        </Switch>
      </Fragment>
    )
  }
}
