import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
// import TabBar from '@material/react-tab-bar';
// import Tab from '@material/react-tab';
import Tabs from '../../tabs/Tabs';
import Tab from '../../tabs/Tab';

class EditContainerTabs extends Component {
  constructor(props) {
    super(props)
  }
  
  render() {
    return(
      <Fragment>
        <Tabs value={this.props.activeIndex} onChange={this.props.handleActiveIndexUpdate} centered={true} className='bb b--black-10'>
          <Tab label='Profile' />
          <Tab label='Sectors' />
          <Tab label='Metrics' />
        </Tabs>
        {/* <TabBar className='bb b--black-10' activeIndex={this.props.activeIndex} handleActiveIndexUpdate={this.props.handleActiveIndexUpdate}>
        <Tab>
          <span className='mdc-tab__text-label'>Profile</span>
        </Tab>
        <Tab>
          <span className='mdc-tab__text-label'>Sectors</span>
        </Tab>
        <Tab>
          <span className='mdc-tab__text-label'>Metrics</span>
        </Tab>
      </TabBar> */}
      </Fragment>
    )
  }
}

export default EditContainerTabs
