import React, { Fragment } from "react"
import Box from "../Box"
import Paper from "../Paper"
import Link from "../Link"
import Typography from "@material-ui/core/Typography"
import Flex from "../Flex"
import SidebarSection from "../SidebarSection"
import { List, ListItemLink, ListItemText } from "../lists"

export default function OverviewModule(props) {

  return(
    <Fragment>
      <Paper className='h-100'>
        <Typography variant="h6">
          Overview
        </Typography>

        <Box mt={3}>
          <SidebarSection header='Title' content={props.nihGrant.project_title} />
          <SidebarSection header='Project Number' content={`${props.nihGrant.award_type}${props.nihGrant.core_project_num}`} />
          <SidebarSection header='Contact PI / Project Leader' content={props.nihGrant.contact_pi_name} />

          {props.nihOrganization ?
            <SidebarSection header='Organization'>
              {props.nihOrganization.org_name}
            </SidebarSection>
          : ""}

          <SidebarSection header='Fiscal Year' content={props.nihGrant.fiscal_year} />
          <SidebarSection header='Project Start Date' content={props.nihGrant.project_start_date} />
          <SidebarSection header='Project End Date' content={props.nihGrant.project_end_date} />

          {props.overview_description ?
            <SidebarSection header='Description'>
              <div dangerouslySetInnerHTML={{__html: props.overview_description.content}} />
            </SidebarSection>
          : ""}
        </Box>

        {props.nihGrant.project_detail_url ?
          <Box mt={3}>
            <Typography variant="Body1" className='b'>
              Related links
            </Typography>

            <Box mt={1}>
              <List dense={true}>
                <ListItemLink divider={true} href={props.nihGrant.project_detail_url} target="_blank">
                  <ListItemText primary="NIH Grant Page" />
                </ListItemLink>
              </List>
            </Box>
          </Box>
        : ''}
      </Paper>

    </Fragment>
  );
}
