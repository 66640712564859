import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Link from "../Link.jsx"
import { TableRow, TableCell } from "../tables"
import TrialTypeListDisplay from "./TrialTypeListDisplay"
// import Checkbox from "../Checkbox"
import MuiCheckbox from '@material-ui/core/Checkbox';
import EditButton from "../admin/EditButton"
import IconButton from "../IconButton"
import FavoriteIcon from "@material-ui/icons/Favorite"
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder"
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import ExpandMore from "@material-ui/icons/ExpandMore"
import ExpandLess from "@material-ui/icons/ExpandLess"
import Flex from "../Flex"
import Box from "../Box"
import MuiIconButton from '@material-ui/core/IconButton';
import Typography from "@material-ui/core/Typography"

export default class TrialRow extends Component {
  constructor(props) {
    super(props)
    this.handleTrialCheckboxClick = this.handleTrialCheckboxClick.bind(this)
    this.handleFavoriteClick = this.handleFavoriteClick.bind(this)
    this.showIcons = this.showIcons.bind(this)
    this.hideIcons = this.hideIcons.bind(this)
    this.handleCompareCheckboxClick = this.handleCompareCheckboxClick.bind(this)
    this.handleExpandClick = this.handleExpandClick.bind(this)
    this.state = { showIcons: false }
  }

  handleTrialCheckboxClick(e) {
    this.props.handleTrialCheckboxClick(this.props.trial.trial_id)
  }

  handleCompareCheckboxClick(e) {
    this.props.handleCompareCheckboxClick(this.props.trial.trial_id)
  }

  handleExpandClick(e) {
    this.props.handleExpandClick(this.props.trial.trial_id)
  }

  showIcons(e) {
    this.setState({
      showIcons: true
    })
  }

  hideIcons(e) {
    this.setState({
      showIcons: false
    })
  }

  handleFavoriteClick(e) {
    this.props.handleFavoriteClick(this.props.trial, this.props.trial.user_trial_id)
  }

  render () {

    // let allCompanies = [...this.props.trial.distinct_companies, ...this.props.trial.distinct_sponsors]

    const trialUrl = `/sectors/${this.props.sector.abbr}/trials/${this.props.trial.trial_id}`

    const editUrl = `/admin/trials/${this.props.trial.trial_id}/edit`

    const selectedTrial = this.props.checkedTrials.includes(this.props.trial.trial_id) ? true : false

    const expandedTrial = this.props.expandedTrial == this.props.trial.trial_id ? true : false

    let groupedTypes = typeof(this.props.trial.grouped_types) == "string" ? this.props.trial.grouped_types.split(";") : this.props.trial.grouped_types

    return(
      <Fragment>
        <TableRow onMouseEnter={this.showIcons} onMouseLeave={this.hideIcons} selected={selectedTrial || expandedTrial}>

          <TableCell padding='checkbox' className={`${selectedTrial ? '' : 'o-50'}`}>
            <MuiIconButton onClick={this.handleTrialCheckboxClick} edge="start" style={{height: '22px', width: '22px'}}>
              {selectedTrial ? <CheckBoxIcon color='primary' style={{height: '22px', width: '22px'}} /> : <CheckBoxOutlineBlankIcon style={{height: '22px', width: '22px'}} />}
            </MuiIconButton>
            {/* <Checkbox
              onChange={this.handleProductCheckboxClick}
              checked={selected}
            /> */}
          </TableCell>

          <TableCell className='w-50'>
            {this.props.choose ?
              <Box className='pointer' onClick={this.handleTrialCheckboxClick}>
                {this.props.trial.acronym || this.props.trial.short_name}
              </Box>
            :
              <Link href={trialUrl}>
                <Box>
                  {this.props.trial.acronym || this.props.trial.short_name}
                  <Box>
                    <Typography variant="caption" color="textSecondary">
                      {this.props.trial.nct_id || this.props.trial.regulatory_id}
                    </Typography>
                  </Box>
                </Box>
              </Link>
            }

          </TableCell>

          {/* <TableCell>
            {this.props.trial.nct_id || this.props.trial.regulatory_id}
          </TableCell> */}

          <TableCell className='w-25'>
            {this.props.trial.company_id ?
              <Link href={`/sectors/${this.props.sector.abbr}/companies/${this.props.trial.company_id}`}>
                <Box>
                  {this.props.trial.sponsor_name}
                </Box>
              </Link>
            :
              <Box>
                {this.props.trial.sponsor_name}
              </Box>
            }
          </TableCell>

          <TableCell className='w-15'>
            {this.props.trial.grouped_types ?
              groupedTypes.map((type) =>
                <div>
                  {type}
                </div>
              )
            : ''}
          </TableCell>

          {/* <TableCell>{`${this.props.trial.last_result_meeting_name} ${getYear(this.props.trial.last_result_created_at)}`}</TableCell> */}

          <TableCell className='w-10' align='right'>
            {this.state.showIcons || selectedTrial ?
              <MuiIconButton onClick={this.handleFavoriteClick} size='small' edge='end' style={{height: '22px', width: '22px'}}>
                {this.props.trial.user_trial_id ? <FavoriteIcon color='primary' style={{height: '22px', width: '22px'}} /> : <FavoriteBorderIcon style={{height: '22px', width: '22px'}} />}
              </MuiIconButton>
            :
              <Box py={0.1}>{dateFormat(this.props.trial.last_updated)}</Box>
            }
          </TableCell>


          {/* <TableCell className='w-10' padding='checkbox' align='right' className={this.state.showIcons || selectedTrial || expandedTrial ? '' : 'dn'}>

            <Box>
              <IconButton onClick={this.handleFavoriteClick} tooltip='Favorite' size='small' edge='end'>
                {this.props.trial.user_trial_id ? <FavoriteIcon fontSize='small' color='primary' /> : <FavoriteBorderIcon fontSize='small' />}
              </IconButton>
            </Box>

          </TableCell> */}

        </TableRow>
      </Fragment>
    )
  }
}

TrialRow.propTypes = {
  trial: PropTypes.object
};
