import React from "react"
import ReactDOM from "react-dom"
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

export default function SearchDropdown(props) {
  return(
    <Autocomplete
      id="search-dropdown"
      onChange={props.handleEntityChange}
      options={props.options}
      getOptionLabel={(option) => option.name}
      renderInput={(params) => (
        <TextField
          {...params}
          label={props.label}
          margin="normal"
          variant="outlined"
          onChange={props.handleChange}
          InputProps={{ ...params.InputProps, type: 'search' }}
        />
      )}
    />
  )
}
