import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Paper from "../../Paper"
import Form from "./Form"
import update from "immutability-helper"
import Snackbar from "../../Snackbar"

export default class EditContainer extends Component {
  constructor(props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
    this.handleSaveClick = this.handleSaveClick.bind(this)
    this.handleCompanyChange = this.handleCompanyChange.bind(this)
    this.state = { investorPresentation: this.props.investor_presentation, showSnackbar: false, snackbarText: '' }
  }

  handleCompanyChange(name, value) {
    let investorPresentation = update(this.state.investorPresentation, {
      company_id: { $set: value }
    })

    this.setState({
      investorPresentation: investorPresentation
    })
  }

  handleChange(e) {
    const name = e.currentTarget.name
    const value = e.currentTarget.value

    const investorPresentation = update(this.state.investorPresentation, { [name]: { $set: value } })

    this.setState({
      investorPresentation: investorPresentation
    })
  }

  handleSaveClick(e) {
    $.ajax({
      type: 'PUT',
      url: `/admin/investor_presentations/${this.state.investorPresentation.id}`,
      data: {
        investor_presentation: this.state.investorPresentation
      },
      error: (data) => {
        this.setState({
          showSnackbar: true,
          snackbarText: `The investor presentation could not be updated. ${data.responseText}`
        })
      },
      complete: (data) => {
        setTimeout(() => {
          this.setState({
            showSnackbar: false,
            snackbarText: ''
          })
        }, 4000)
      }
    })
  }

  render() {
    return(
      <Fragment>

        <Paper fullHeight>

          <Form
            investorPresentation={this.state.investorPresentation}
            companies={this.props.companies}
            handleChange={this.handleChange}
            handleDateChange={this.handleDateChange}
            handleSaveClick={this.handleSaveClick}
            handleCompanyChange={this.handleCompanyChange}
          />


        </Paper>

        <Snackbar
          open={this.state.showSnackbar}
          message={this.state.snackbarText}
        />
      </Fragment>
    )
  }
}
