import React, { Fragment } from "react"
import ResultItemContainer from "../search/ResultItemContainer"
import Flex from "../Flex"
import Box from "../Box"

export default function SearchItem(props) {
  let url = ""

  if (props.url) {
    url = props.url
  } else if (props.admin) {
    url = `/admin/investor_presentations/${props.result.id}`
  } else {
    url = props.result.url
  }

  function renderSearchDetails(result) {
    return (
      <Flex alignItems='baseline'>
        {result.company_name ? <Box mr={2}>{result.company_name}</Box> : ""}
      </Flex>
    );
  }

  return(
    <ResultItemContainer 
      searchItemType={props.result.record_type}
      searchTitle={props.result.title}
      date={props.result.date}
      searchDescription={props.result.text}
      url={url}
      target="_blank"
      searchDetails={renderSearchDetails(props.result)}
    />
  )
}