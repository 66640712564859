import React, { Fragment, Component } from "react"
import Paper from "../Paper"
import Flex from "../Flex"
import Box from "../Box"
import { Table, TableRow, TableHead, TableCell, TableBody } from "../tables"
import Typography from "@material-ui/core/Typography"
import update from "immutability-helper"
import TimelineContainer from "./acquisitions/TimelineContainer"
import { Tabs, Tab } from "../tabs"
import TimelineIcon from '@material-ui/icons/Timeline';
import ListIcon from '@material-ui/icons/List';
import CompareIcon from '@material-ui/icons/Compare';
import IconButton from "../IconButton"
import Link from "../Link"
import ExpandMore from "@material-ui/icons/ExpandMore"
import ExpandLess from "@material-ui/icons/ExpandLess"
import Collapse from '@material-ui/core/Collapse';
import Row from "./acquisitions/Row"

export default class Acquistions extends Component {
  constructor(props) {
    super(props)
    this.handleExpandClick = this.handleExpandClick.bind(this)
    this.state = { chartData: this.props.chart_data, view: 'table', showSubAcquistions: true }
  }

  handleExpandClick(e) {
    this.setState({
      showSubAcquistions: !this.state.showSubAcquistions
    })
  }

  render () {
    return (
      <Fragment>
        <Paper minFullHeight>

          <Flex>
            <Box ml="auto">
              <IconButton color={this.state.view == 'table' ? 'primary' : 'default'} onClick={ () => { this.setState({ view: 'table' })}}>
                <ListIcon />
              </IconButton>

              <IconButton color={this.state.view == 'timeline' ? 'primary' : 'default'} tooltip='View Timeline' onClick={ () => { this.setState({ view: 'timeline' })}}>
                <TimelineIcon />
              </IconButton>
            </Box>
          </Flex>

          {/* <Tabs centered value={0} className='bb b--black-10'>
            <Tab label='Data' />
          </Tabs> */}

          {this.props.acquisitions.length < 1 ?
            <Flex justifyContent='center' height="50%">
              <Box alignSelf='center' maxWidth="50%">
                <Box>
                  <Typography variant="body1">
                    No acquisitions found for this company
                  </Typography>
                </Box>
              </Box>
            </Flex>
          :
            <Fragment>
              {this.state.view == 'timeline' ?
                <Box mt={3}>
                  <TimelineContainer
                    key={this.state.chartData}
                    title='Acquisition Timeline'
                    chartId='acquisition-timeline-chart'
                    chartData={this.state.chartData}
                    currentTime={this.props.current_time}
                  />
                </Box>
              : ""}

              {this.state.view == 'table' ?
                <Box>
                  <Table stickyHeader size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell className="bg-white"></TableCell>
                        <TableCell className="bg-white">Company</TableCell>
                        <TableCell className="bg-white">Date</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {this.props.acquisitions.map((acquisition) =>
                        <Row
                          key={acquisition.id}
                          acquisition={acquisition}
                          sector={this.props.sector}
                        />
                      )}
                    </TableBody>
                  </Table>
                </Box>
              : ""}
            </Fragment>

          }


        </Paper>
      </Fragment>
    )
  }
}
