import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import update from "immutability-helper"
import Snackbar from "../../Snackbar"
import List from "../../lists/List";
import SectorListItem from "../../sectors/SectorListItem";

export default class SectorForm extends Component {
  constructor(props) {
    super(props)
    this.handleCheckboxSelect = this.handleCheckboxSelect.bind(this)
    this.state = { characteristicGroup: this.props.characteristicGroup, characteristicGroupSectors: this.props.characteristicGroupSectors, showSnackbar: false, snackbarText: '' }
  }

  handleCheckboxSelect(sector, checked) {
    if (checked) {
      this.setState({
        showSnackbar: true,
        snackbarText: 'The sector can\'t be removed from the characteristic group'
      })
      setTimeout(() => {
        this.setState({
          showSnackbar: false,
          snackbarText: ''
        })
      }, 4000)
    } else {
      $.post("/admin/characteristic_group_sectors", { characteristic_group_sector: { characteristic_group_id: this.state.characteristicGroup.id, sector_id: sector.id, created_by: this.props.currentUser.id }}, (data) => {
        let characteristicGroupSectors = update(this.state.characteristicGroupSectors, {
          $push: [data.characteristic_group_sector]
        })
        this.setState({
          characteristicGroupSectors: characteristicGroupSectors
        })
      }, 'json').fail((data) => {
        this.setState({
          showSnackbar: true,
          snackbarText: `The sector could not be added. ${data.responseText}`
        })
      }).done((data) => {
        setTimeout(() => {
          this.setState({
            showSnackbar: false,
            snackbarText: ''
          })
        }, 4000)
      })
    }
  }

  render() {
    return(
      <Fragment>
        <List>
          {this.props.sectors.map((sector) =>
            <SectorListItem
              key={sector.id}
              sector={sector}
              checked={this.state.characteristicGroupSectors.filter((characteristicGroupSector) => characteristicGroupSector.sector_id == sector.id)[0] ? true : false}
              handleCheckboxSelect={this.handleCheckboxSelect}
            />
          )}
        </List>

        <Snackbar
          open={this.state.showSnackbar}
          message={this.state.snackbarText}
        />
      </Fragment>
    )
  }
}
