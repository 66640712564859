import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Paper from "../Paper"
import Flex from "../Flex"
import Box from "../Box"
import IconButton from "../IconButton"
import TimelineIcon from '@material-ui/icons/Timeline';
import ListIcon from '@material-ui/icons/List';
import FamilyList from "./FamilyList"
import FamilyTimelineContainer from "./FamilyTimelineContainer"
import Typography from "@material-ui/core/Typography"

export default class FamilyContainer extends Component {
  constructor(props) {
    super(props)
    this.handleListViewClick = this.handleListViewClick.bind(this)
    this.handleTimelineViewClick = this.handleTimelineViewClick.bind(this)
    this.state = { view: 'list' }
  }

  handleListViewClick(e) {
    this.setState({
      view: 'list'
    })
  }

  handleTimelineViewClick(e) {
    this.setState({
      view: 'timeline'
    })
  }

  render() {
    return (
      <Fragment>

        <Paper minFullHeight>
          <Box height="100%">

            <Fragment>
              <Box>
                <FamilyList
                  patents={this.props.patents}
                  patent={this.props.patent}
                  sector={this.props.sector}
                />
              </Box>
            </Fragment>
          </Box>
        </Paper>

      </Fragment>
    )
  }
}
