import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Select from "../../Select"
import Typography from "@material-ui/core/Typography"

class RegulatoryStatusForm extends Component {
  constructor(props) {
    super(props)
    this.handleRegulatoryStatusChange = this.handleRegulatoryStatusChange.bind(this)
  }
  
  handleRegulatoryStatusChange(name, value) {
    const objectHash = { product_regulatory_status: { product_id: this.props.product.id, regulatory_status_id: value, created_by: this.props.currentUser.id, source_id: 1, active: true }}
    const objectUrl = "/admin/product_regulatory_statuses"
    
    this.props.handleStatusChange(objectHash, objectUrl, 'productRegulatoryStatuses')
  }
  
  render() {
    return(
      <Fragment>
        <Typography variant="h6" component="h6" className='mb1'>Regulatory Statuses</Typography>
        
        <Select  
          entities={this.props.regulatoryStatuses}
          label='Regulatory Statuses'
          value={this.props.regulatoryStatusValue}
          displayEmpty={true}
          handleChange={this.handleRegulatoryStatusChange} 
        />
        
      </Fragment>
    )
  }
}

export default RegulatoryStatusForm
