import React, { Fragment } from "react"
import Paper from "../../Paper"
import List from "../../lists/List";
import AssociatedTrialListItem from "./AssociatedTrialListItem"
import AssociatedProductListItem from "./AssociatedProductListItem"
import AssociatedCompanyListItem from "./AssociatedCompanyListItem"
import ListSubheader from '@material-ui/core/ListSubheader';

export default function AssociatedList(props) {
  return (
    <Fragment>

      <List>
        {props.entityTrials && props.entityTrials.length > 0 ?
          <Fragment>
            <ListSubheader>Trials</ListSubheader>

            {props.entityTrials.map((entityTrial) =>
              <AssociatedTrialListItem
                key={entityTrial.id}
                entityTrial={entityTrial}
                handleTrialDeleteClick={props.handleTrialDeleteClick}
              />
            )}
          </Fragment>
        : ''}

        {props.entityCompanies && props.entityCompanies.length > 0 ?
          <Fragment>
            <ListSubheader>Companies</ListSubheader>

            {props.entityCompanies.map((entityCompany) =>
              <AssociatedCompanyListItem
                key={entityCompany.id}
                entityCompany={entityCompany}
                handleCompanyDeleteClick={props.handleCompanyDeleteClick}
              />
            )}
          </Fragment>
        : ''}

        {props.entityProducts && props.entityProducts.length > 0 ?
          <Fragment>
            <ListSubheader>Products</ListSubheader>

            {props.entityProducts.map((entityProduct) =>
              <AssociatedProductListItem
                key={entityProduct.id}
                entityProduct={entityProduct}
                product={entityProduct.product ? entityProduct.product : null}
                handleProductDeleteClick={props.handleProductDeleteClick}
              />
            )}
          </Fragment>
        : ''}
      </List>

    </Fragment>
  )
}
