import React, { Component, Fragment } from "react"
import { Table, TableHead, TableRow, TableCell, TableBody, TableHeadSort } from "../../tables"
import KClearanceRow from "./KClearanceRow"
import Paper from "../../Paper"
import Box from "../../Box"

class IndexContainer extends Component {
  constructor(props) {
    super(props)
    this.handleRequestSort = this.handleRequestSort.bind(this)
    this.desc = this.desc.bind(this)
    this.stableSort = this.stableSort.bind(this)
    this.getSorting = this.getSorting.bind(this)
    this.state = { kClearances: this.props.k_clearances, order: "desc", orderBy: "decision_date" }
  }

  desc(a, b, orderBy) {
    let bOrderKey = b[orderBy]
    let aOrderKey = a[orderBy]
    if (bOrderKey < aOrderKey) {
      return -1;
    }
    if (bOrderKey > aOrderKey) {
      return 1;
    }
    return 0;
  }

  stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = cmp(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
  }

  getSorting(order, orderBy) {
    return order === 'desc' ? (a, b) => this.desc(a, b, orderBy) : (a, b) => -this.desc(a, b, orderBy);
  }

  handleRequestSort(event, property) {
    const isDesc = this.state.orderBy === property && this.state.order === 'desc';

    this.setState({
      order: isDesc ? 'asc' : 'desc',
      orderBy: property
    })
  };

  render() {
    let headers = [{ name: "k_number", label: "510(k) Number" }, { name: 'applicant', label: "Applicant" }, { name: 'decision_date', label: 'Decision Date' }]
    return(
      <Fragment>
        <Paper fullHeight>
          <Box height="100%">
            <Box overflow='auto' height="90%">
              <Table size='small' aria-label='510(k) clearances table' stickyHeader={true}>

                <TableHeadSort
                  headers={headers}
                  order={this.state.order}
                  orderBy={this.state.orderBy}
                  onRequestSort={this.handleRequestSort}
                />

                <TableBody>
                  {this.stableSort(this.state.kClearances, this.getSorting(this.state.order, this.state.orderBy))
                    .map((kClearance) =>
                      <KClearanceRow
                        key={kClearance.id}
                        kClearance={kClearance}
                        sector={this.props.sector}
                        product={this.props.product}
                      />
                  )}
                </TableBody>
              </Table>
            </Box>
          </Box>
        </Paper>
      </Fragment>
    )
  }
}

export default IndexContainer
