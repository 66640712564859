import React from 'react'
import Link from '../Link'

function FdaLink(props) {

  let rootUrl = `https://www.accessdata.fda.gov/scripts/cdrh/cfdocs/cfpma/pma.cfm?id=${props.pmaApproval.pma_number}`

  if (props.pmaApproval.supplement_number) {
    rootUrl += `${props.pmaApproval.supplement_number}`
  }

  let pmaApprovalUrl = rootUrl

  return (
    <Link href={pmaApprovalUrl} target="_blank">
      {props.linkText || "View on FDA site"}
    </Link>
  )
}

export default FdaLink