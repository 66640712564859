import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import TextInput from "../TextInput"
import Box from "../Box"

export default class CollectionForm extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    return(
      <Fragment>

        <TextInput
          name='collectionTitle'
          label='Title'
          placeholder='Name your collection...'
          value={this.props.title}
          onChange={this.props.handleChange}
          className='w-100'
        />

        <Box my={2}>
          <TextInput
            multiline
            rows={3}
            name='collectionDescription'
            label='Description'
            placeholder='Describe your collection...'
            value={this.props.description}
            onChange={this.props.handleChange}
            className='w-100'
          />
        </Box>

      </Fragment>
    )
  }
}
