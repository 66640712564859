import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Form from "./Form"
import PmaList from "./List"
import { withSnackbar } from "../../../SnackbarContainer"
import update from "immutability-helper"

class FormContainer extends Component {
  constructor(props) {
    super(props)
    this.handlePmaSave = this.handlePmaSave.bind(this)
    this.handlePmaChange = this.handlePmaChange.bind(this)
    this.handleDeleteClick = this.handleDeleteClick.bind(this)
    this.handleSectorChange = this.handleSectorChange.bind(this)
    this.state = { pma: null, supplement_number: null, sector_id: null, productPmas: this.props.productPmas }
  }

  handlePmaChange(e) {
    let name = e.target.name
    let value = e.target.value

    this.setState({
      [name]: value
    })
  }

  handleSectorChange(name, value) {
    this.setState({
      [name]: value
    })
  }

  handleDeleteClick(productPma) {
    $.ajax({
      type: 'DELETE',
      url: `/admin/product_pmas/${productPma.id}`,
      dataType: 'json',
      success: (data) => {
        let index = this.state.productPmas.indexOf(productPma)

        let productPmas = update(this.state.productPmas, {
          $splice: [[index, 1]]
        })

        this.setState({
          productPmas: productPmas
        })

        this.props.snackbarShowMessage("PMA removed")
      },
      error: (data) => {
        this.props.snackbarShowMessage("PMA could not be removed. Please try again.", "error")
      }
    })
  }

  handlePmaSave(e) {
    let params = { product_id: this.props.product.id, pma: this.state.pma, sector_id: this.state.sector_id }

    if (this.state.supplement_number && this.state.supplement_number != "") {
      params["supplement_number"] = this.state.supplement_number
    }

    $.ajax({
      type: 'POST',
      url: `/admin/product_pmas`,
      data: {
        product_pma: params
      },
      dataType: 'json',
      success: (data) => {
        let productPmas = update(this.state.productPmas, {
          $push: [data.product_pma]
        })

        this.setState({
          productPmas: productPmas,
          pma: null,
          supplement_number: null,
          sector_id: null
        })

        this.props.snackbarShowMessage("PMA added")
      },
      error: (data) => {
        this.props.snackbarShowMessage("PMA could not be added. Please try again.", "error")
      }
    })
  }

  render() {
    return(
      <Fragment>
        <Form
          pma={this.state.pma}
          supplement_number={this.state.supplement_number}
          sector_id={this.state.sector_id}
          sectors={this.props.sectors}
          handlePmaSave={this.handlePmaSave}
          handlePmaChange={this.handlePmaChange}
          handleSectorChange={this.handleSectorChange}
        />

        <PmaList
          productPmas={this.state.productPmas}
          sectors={this.props.sectors}
          handleDeleteClick={this.handleDeleteClick}
        />

      </Fragment>
    )
  }
}


export default withSnackbar(FormContainer)
