import React, { Fragment } from "react"
import Filter from "../filters/Filter"
import Box from "../Box"
import Grid from '@material-ui/core/Grid';


export default class Filters extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <Grid container spacing={2} alignItems='stretch'>

        <Grid item xs lg={2}>
          <Box>
            <Filter
              label='Meeting'
              name='meeting_ids'
              value={this.props.filterValues.meeting_ids}
              entities={this.props.filterOptions.meetings}
              handleChange={this.props.handleFilterChange}
            />
          </Box>
        </Grid>

        {/* <Grid item xs lg={2}>
          <Box>
            <Filter
              label='Year'
              name='years'
              value={this.props.filterValues.years}
              entities={this.props.filterOptions.meetingYears}
              handleChange={this.props.handleFilterChange}
            />
          </Box>
        </Grid> */}

        <Grid item xs lg={2}>
          <Box>
            <Filter
              label='Product'
              name='product_ids'
              value={this.props.filterValues.product_ids}
              entities={this.props.filterOptions.products}
              handleChange={this.props.handleFilterChange}
            />
          </Box>
        </Grid>

        <Grid item xs lg={2}>
          <Box>
            <Filter
              label='Trial'
              name='trial_ids'
              value={this.props.filterValues.trial_ids}
              entities={this.props.filterOptions.trials}
              handleChange={this.props.handleFilterChange}
            />
          </Box>
        </Grid>

        {this.props.sector.monitor_product ?
          ''
        :
          <Fragment>
            {this.props.sector.market_id != 3 ?
              <Grid item xs lg={2}>
                <Box>
                  <Filter
                    label='Target site'
                    name='target_site_ids'
                    value={this.props.filterValues.target_site_ids}
                    entities={this.props.filterOptions.targetSites}
                    handleChange={this.props.handleFilterChange}
                  />
                </Box>
              </Grid>
            : ""}

            <Grid item xs lg={2}>
              <Box>
                <Filter
                  label='Target market'
                  name='target_market_ids'
                  value={this.props.filterValues.target_market_ids}
                  entities={this.props.filterOptions.targetMarkets}
                  handleChange={this.props.handleFilterChange}
                />
              </Box>
            </Grid>

            <Grid item xs lg={2}>
              <Box>
                <Filter
                  label='Therapy type'
                  name='therapy_type_ids'
                  value={this.props.filterValues.therapy_type_ids}
                  entities={this.props.filterOptions.therapyTypes}
                  handleChange={this.props.handleFilterChange}
                />
              </Box>
            </Grid>
          </Fragment>
        }

        {/* {this.props.forSearch ?
          <Grid item xs={6} lg>
            <Box pt={1}>
              <form onSubmit={this.props.handleSearchClick}>
                <TextInput
                  name='query'
                  placeholder='Search'
                  size='small'
                  value={this.props.filterValues.query ? this.props.filterValues.query : ''}
                  onChange={this.props.handleChange}
                  className='w-100'
                  InputProps={{
                    startAdornment: <InputAdornment position="start"><SearchIcon /></InputAdornment>,
                  }}
                />
              </form>
            </Box>
          </Grid>
        : ''} */}
      </Grid>
    )
  }
}
