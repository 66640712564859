import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Paper from "./Paper"
import Flex from "./Flex"
import Box from "./Box"
import TextInput from "./TextInput"
import RaisedButton from "./buttons/RaisedButton"
import Grid from '@material-ui/core/Grid';
import Typography from "@material-ui/core/Typography"
import ButtonLayout from "./admin/ButtonLayout"
import Snackbar from "./Snackbar"

export default class Contact extends Component {
  constructor(props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
    this.handleSendClick = this.handleSendClick.bind(this)
    this.state = { name: '', title: '', organization: '', email: '', phone: '', showSnackbar: false, snackbarText: '' }
  }

  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  handleSendClick(e) {
    $.ajaxSetup({
      headers: {
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
      }
    })
    $.ajax({
      type: 'POST',
      url: '/send_contact',
      data: {
        name: this.state.name, email: this.state.email, title: this.state.title, organization: this.state.organization, phone: this.state.phone
      },
      dataType: 'json',
      success: (data) => {
        this.setState({
          showSnackbar: true,
          snackbarText: 'Thank you for contacting us. We will be contacting you shortly.'
        })
      },
      complete: (data) => {
        setTimeout(() => {
          window.location.href = '/'
        }, 4000)
      }
    })
  }

  render() {
    return (
      <section className="mt4 mw5 mw7-ns center pa3 ph5-ns">
        <Paper>
          <Typography variant="h6">
            Contact Us
          </Typography>

          <Box mt={5}>

            <Grid container spacing={3} component={Box} pb={2}>
              <Grid item xs={12}>
                <TextInput
                  label='Name'
                  name='name'
                  value={this.state.name}
                  onChange={this.handleChange}
                  className='w-100'
                />
              </Grid>
            </Grid>

            <Grid container spacing={3} component={Box} pb={2}>
              <Grid item xs={6}>
                <TextInput
                  label='Title'
                  name='title'
                  value={this.state.title}
                  onChange={this.handleChange}
                  className='w-100'
                />
              </Grid>

              <Grid item xs={6}>
                <TextInput
                  label='Organization'
                  name='organization'
                  value={this.state.organization}
                  onChange={this.handleChange}
                  className='w-100'
                />
              </Grid>
            </Grid>

            <Grid container spacing={3} component={Box} pb={5}>
              <Grid item xs={6}>
                <TextInput
                  label='Phone'
                  name='phone'
                  value={this.state.phone}
                  onChange={this.handleChange}
                  className='w-100'
                />
              </Grid>

              <Grid item xs={6}>
                <TextInput
                  label='Email'
                  name='email'
                  value={this.state.email}
                  onChange={this.handleChange}
                  className='w-100'
                />
              </Grid>
            </Grid>


            <ButtonLayout>
              <RaisedButton onClick={this.handleSendClick}>
                Send
              </RaisedButton>
            </ButtonLayout>


          </Box>
        </Paper>

        <Snackbar
          open={this.state.showSnackbar}
          message={this.state.snackbarText}
        />
      </section>
    )
  }
}
