import React, { Component, Fragment } from "react"
import PatentsDisplay from "../patents/IndexDisplay"
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

export default class Patents extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    return(
      <Fragment>
        <Switch>
          <Route
            path={`/sectors/${this.props.sector.abbr}/companies/${this.props.company.id}/patents`}
            children={
              <PatentsDisplay
                patents={this.props.patents}
                patents_count={this.props.patents_count}
                sector={this.props.sector}
                query={this.props.query}
                order_by={this.props.order_by}
                year={this.props.year}
                kinds={this.props.kinds}
                years={this.props.years}
                order_options={this.props.order_options}
                url={`/sectors/${this.props.sector.abbr}/companies/${this.props.company.id}/patents`}
              />
            }
          />
        </Switch>
      </Fragment>
    )
  }
}

