import React from "react"
import MuiTableCell from '@material-ui/core/TableCell';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles(theme => ({
  borderRight: {
    borderRightWidth: 1,
    borderColor: theme.palette.grey[300],
    borderRightStyle: 'solid'
  },
  borderTop: {
    borderTopWidth: 1,
    borderColor: theme.palette.grey[300],
    borderTopStyle: 'solid'
  },
  borderLeft: {
    borderLeftWidth: 1,
    borderColor: theme.palette.grey[300],
    borderLeftStyle: 'solid'
  },
  noBorder: {
    borderStyle: 'none'
  },
  root: {
    fontSize: '1rem'
  },
  noPadding: {
    padding: '0px'
  }
}));


export default function TableCell(props) {
  const { children, borderRight, borderLeft, borderTop, primaryData, className, noBorder, ...rest } = props

  const classes = useStyles()

  const classNameStyles = `${borderRight ? classes.borderRight : ''} ${borderTop ? classes.borderTop : ''} ${borderLeft ? classes.borderLeft : ''} ${noBorder ? classes.noBorder : ''} ${className}`
  return (
    <MuiTableCell className={classNameStyles} {...rest}>
      {children}
    </MuiTableCell>
  )
}
