import React, { Component } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Paper from "./Paper"
import IconButton from "./IconButton"
import Flex from "./Flex"
import Box from "./Box"
import FavoriteIcon from '@material-ui/icons/Favorite';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import TabPanel from "./tabs/TabPanel"
import Tabs from './tabs/Tabs';
import Tab from './tabs/Tab';
import Typography from "@material-ui/core/Typography"

export default class Header extends Component {
  constructor(props) {
    super(props)
  }

  renderTabs() {
    const activeTabArray = this.props.tabs.filter((tab) => tab[0] == this.props.activeTab)
    const tabNames = this.props.tabs.map(tab => tab[0])

    const tabIndex = tabNames.indexOf(this.props.activeTab)

    const tabElements = this.props.tabs.map((tab) =>
      <Tab
        small={true}
        key={tab[0]}
        component="a"
        label={tab[0]}
        href={tab[1]}
      />
    )
    return(
      <div className='bg-white w-100'>
        <Tabs value={tabIndex} aria-label="nav tabs" small={true}>
          {tabElements}
        </Tabs>
      </div>
    )
  }

  render () {
    let classStyle = `normal pl2 mb0 mt0 ${this.props.background} white pv2`
     // classes={{root: `pa0 ${this.props.marginBottom}`}}
    return (
      <Box mb={4}>
        <Paper header={true}>
          <h3 className={classStyle}>

            <Flex alignItems='center' justifyContent='center'>

              <Box>
                <Typography variant="h6" className='mv0'>
                  {this.props.header}
                </Typography>
              </Box>

              {this.props.subHeader ? <Box ml={2} mt={1}>
                <Typography variant="button">
                  ({this.props.subHeader})
                </Typography>
              </Box> : ''}

              {this.props.favoritable ?
                <Box ml='auto' pr={2} color='white'>
                  <IconButton onClick={this.props.handleClick} size='small' color='inherit' tooltip={this.props.userEntity ? 'Unfavorite' : 'Favorite'}>
                    {this.props.userEntity ? <FavoriteIcon color='inherit' fontSize='small' /> : <FavoriteBorderIcon color='inherit' fontSize='small' />}
                  </IconButton>
                </Box>
              :
                <Box className='ml-auto'></Box>
              }

            </Flex>

          </h3>

          {this.props.tabs ? this.renderTabs() : ''}
        </Paper>
      </Box>
    )
  }
}

Header.propTypes = {
  header: PropTypes.string,
  activeTab: PropTypes.string,
  tabs: PropTypes.array,
  noPadding: PropTypes.bool
};

Header.defaultProps = {
  marginBottom: 'mb4',
  noPadding: false
};
