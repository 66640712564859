import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import { Table, TableHead, TableRow, TableCell, TableBody, TableHeadSort } from "../tables"
import PipelineProductRow from "./PipelineProductRow"
import Paper from "../Paper"
import Flex from "../Flex"
import Box from "../Box"
import Select from "../Select"
import update from "immutability-helper"

export default class Pipeline extends Component {
  constructor(props) {
    super(props)
    this.handleFilterChange = this.handleFilterChange.bind(this)
    this.handleRequestSort = this.handleRequestSort.bind(this)
    this.desc = this.desc.bind(this)
    this.stableSort = this.stableSort.bind(this)
    this.getSorting = this.getSorting.bind(this)
    this.state = { pipelineProducts: this.props.pipeline_products, region_id: this.props.world_region_id, order: 'asc', orderBy: 'short_name' }
  }

  desc(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = cmp(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
  }

  getSorting(order, orderBy) {
    return order === 'desc' ? (a, b) => this.desc(a, b, orderBy) : (a, b) => -this.desc(a, b, orderBy);
  }

  handleRequestSort(event, property) {
    const isDesc = this.state.orderBy === property && this.state.order === 'desc';

    this.setState({
      order: isDesc ? 'asc' : 'desc',
      orderBy: property
    })
  };

  handleFilterChange(name, value) {
    const region = update(this.state.region_id, {
      $set: value
    })

    $.ajax({
      type: 'GET',
      url: `/sectors/${this.props.sector.abbr}/companies/${this.props.company.id}/pipeline`,
      data: {
        world_region_id: region
      },
      dataType: 'json',
      success: (data) => {
        this.setState({
          region_id: region,
          pipelineProducts: data.pipeline_products
        })
      }
    })
  }

  render() {
    let headers = this.props.sector.market_id == 3 ? [{ name: 'short_name', label: 'Product' }, { name: 'target_markets', label: 'Target Markets' }, { name: 'therapy_types', label: 'Therapy Types' }, { name: 'us_status', label: 'Status' }] : [{ name: 'short_name', label: 'Product' }, { name: 'us_target_sites', label: 'Target Sites' }, { name: 'investigational_target_sites', label: 'Investigational Target Sites' }, { name: 'us_target_markets', label: 'Target Market' }, { name: 'investigational_target_markets', label: 'Investigational Target Market' }, { name: 'us_status', label: 'Status' }]
    return(
      <Fragment>

        <Paper fullHeight>

          <Box height="100%">

            <Flex alignItems='center' mb={3}>
              {this.props.sector.market_id != 3 ?
                <Box width="12%">
                  <Select
                    label='Regions'
                    name='region_id'
                    value={this.state.region_id}
                    entities={this.props.world_regions}
                    handleChange={this.handleFilterChange}
                  />
                </Box>
              : ""}
            </Flex>

            <Box overflow='auto' height={this.props.height || "80%"}>

              <Table size='small' stickyHeader={true}>

                <TableHeadSort
                  headers={headers}
                  order={this.state.order}
                  orderBy={this.state.orderBy}
                  onRequestSort={this.handleRequestSort}
                />

                <TableBody>
                  {this.stableSort(this.state.pipelineProducts, this.getSorting(this.state.order, this.state.orderBy)).map((product) =>
                    <PipelineProductRow
                      key={product.id}
                      product={product}
                      region={this.state.region_id}
                      sector={this.props.sector}
                    />
                  )}
                </TableBody>

              </Table>
            </Box>

          </Box>

        </Paper>

      </Fragment>
    )
  }
}
