import React, { Component } from "react"
import PropTypes from "prop-types"
import ReactDOM from "react-dom"
import { Table, TableHead, TableRow, TableCell, TableBody, EmptyDataTableRow } from "../../tables"
import TableHeadSort from "../../tables/TableHeadSort"
import ListRecord from "./ListRecord"
import Box from "../../Box"

class ProductsTable extends Component {
  constructor(props) {
    super(props)
    this.handleRequestSort = this.handleRequestSort.bind(this)
    this.desc = this.desc.bind(this)
    this.stableSort = this.stableSort.bind(this)
    this.getSorting = this.getSorting.bind(this)
    this.state = { order: 'asc', orderBy: 'short_name' }
  }

  desc(a, b, orderBy) {
    let bOrderKey = orderBy == 'short_name' || orderBy == 'company_name' ? b[orderBy].toLowerCase() : b[orderBy]
    let aOrderKey = orderBy == 'short_name' || orderBy == 'company_name' ? a[orderBy].toLowerCase() : a[orderBy]
    if (bOrderKey < aOrderKey) {
      return -1;
    }
    if (bOrderKey > aOrderKey) {
      return 1;
    }
    return 0;
  }

  stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = cmp(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
  }

  getSorting(order, orderBy) {
    return order === 'desc' ? (a, b) => this.desc(a, b, orderBy) : (a, b) => -this.desc(a, b, orderBy);
  }

  handleRequestSort(event, property) {
    const isDesc = this.state.orderBy === property && this.state.order === 'desc';

    this.setState({
      order: isDesc ? 'asc' : 'desc',
      orderBy: property
    })
  };

  render() {
    let headers = [{ label: 'Product', name: 'short_name' }, { label: 'Company', name: 'company_name' }, { label: 'Updated', name: 'updated_at' }, { label: 'Trials', name: 'n_trials', align: 'right' }, { label: '', value: ''}]
    return(
      <Box overflow='auto' height="80%">
        <Table size='small' aria-label='products table' stickyHeader={true} id='products-table'>
          <TableHeadSort
            headers={headers}
            order={this.state.order}
            orderBy={this.state.orderBy}
            onRequestSort={this.handleRequestSort}
          />

          <TableBody>
            {this.props.products.map((product) =>
              <ReviwerRecord
                key={product.id}
                product={product}
                sector={this.props.sector}
              />
            )}
          </TableBody>
        </Table>
      </Box>
    )
  }
}

export default ProductsTable
