import React, { Component, Fragment } from "react"
import PropTypes from "prop-types"
import { Table, TableRow, TableBody, TableHead, TableCell, TableHeadSort } from "../tables"
import Box from "../Box"
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TablePaginationActions from "../tables/TablePaginationActions"
import LinearProgress from '@material-ui/core/LinearProgress';
import ListRecord from "./ListRecord"

export default class PeopleTable extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    return(
      <Fragment>
        <Box overflow='auto' height="80%">

          <Table size='small' stickyHeader={true} aria-label='people table'>

            <TableHeadSort
              headers={[{ name: 'text', label: 'Name' }, { name: 'abstracts_count', label: 'Presentations', align: 'right' }, { name: 'manuscripts_count', label: 'Publications', align: 'right' }, { name: 'trials_count', label: 'Trials', align: 'right' }]}
              order={this.props.order}
              orderBy={this.props.orderBy}
              onRequestSort={this.props.handleRequestSort}
            />

            <TableBody>
              <React.Fragment>
                {this.props.loading ?
                  <TableRow hover={false}>
                    <TableCell noBorder colSpan={5}>
                      <LinearProgress />
                    </TableCell>
                  </TableRow>
                : ""}
                {this.props.people.map((person) =>
                  <ListRecord
                    key={person.person_id}
                    person={person}
                    sector={this.props.sector}
                    orderBy={this.props.orderBy}
                    personAbstracts={this.props.personAbstracts}
                    personTrials={this.props.personTrials}
                    personManuscripts={this.props.personManuscripts}
                  />
                )}
              </React.Fragment>
            </TableBody>
            <TableFooter>
              <TablePagination
                colSpan={4}
                count={this.props.peopleCount}
                rowsPerPage={100}
                page={this.props.page}
                SelectProps={{
                  inputProps: { 'aria-label': 'rows per page' },
                  native: true,
                }}
                onChangePage={this.props.handleChangePage}
                onChangeRowsPerPage={this.props.handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableFooter>
          </Table>
        </Box>
      </Fragment>
    )
  }
}
