import React, { Component, Fragment } from "react"

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import Typography from "@material-ui/core/Typography"

import Link from "./Link"

export default class ProfileFieldContainer extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    return(
      <Fragment>
        {this.props.profileFields.map((profileField, index) =>
          <ExpansionPanel key={profileField.id} defaultExpanded={index === 0}>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} ariacontrols={`${profileField.name}-content`} id={`${profileField.name}-header`}>
              <Typography className="f4">
                {profileField.name}
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Typography>
                <div dangerouslySetInnerHTML={{__html: profileField.content}} />
                <Caption>
                  (<Link href={profileField.source.source_url} target="_blank">
                    {profileField.source.source_title}
                  </Link>)
                </Caption>
              </Typography>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        )}
      </Fragment>
    )
  }
}
