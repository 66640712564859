import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import SitesChart from "./SitesChart"
import Flex from "../Flex"
import Box from "../Box"
import { Tabs, Tab, TabPanel } from "../tabs"
import SitesDataTable from "./SitesDataTable"
import Switch from "../Switch"
import FormControlLabel from '@material-ui/core/FormControlLabel';

export default class SitesChartContainer extends Component {
  constructor(props) {
    super(props)
    this.handleTabClick = this.handleTabClick.bind(this)
    this.handleEnrollmentDateSwitch = this.handleEnrollmentDateSwitch.bind(this)
    this.state = { activeIndex: 0, showEnrollmentDates: true }
  }

  handleTabClick(e, newValue) {
    this.setState({
      activeIndex: newValue
    })
  }

  handleEnrollmentDateSwitch() {
    const newShowEnrollmentDatesState = !this.state.showEnrollmentDates

    this.setState({
      showEnrollmentDates: newShowEnrollmentDatesState
    })
  }

  render() {
    return(
      <Fragment>

        <Box width={{ xs: 1, lg: 9/10}} mx='auto' mb={3}>
          <Box mb={1}>
            <SitesChart
              sitesChartData={this.props.sitesChartData}
              sites={this.props.sites}
              enrollmentStartDate={this.props.enrollmentStartDate}
              enrollmentStartDateForChart={this.props.enrollmentStartDateForChart}
              enrollmentEndDate={this.props.enrollmentEndDate}
              enrollmentEndDateForChart={this.props.enrollmentEndDateForChart}
              showEnrollmentDates={this.state.showEnrollmentDates}
            />
          </Box>

          {/* <Box mx='auto'>
            <Flex alignItems='center' justifyContent='center'>

              <Box mr={3}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={this.state.showEnrollmentDates}
                      handleChange={this.handleEnrollmentDateSwitch}
                      value={this.state.showEnrollmentDates}
                    />
                  }
                  label='Show dates'
                />
              </Box>

            </Flex>
          </Box> */}
        </Box>

        {this.props.noDataView ?
          ''
        :
          <Box>

            <Box textAlign='center' width={1}>
              <Box my={4}>
                <Tabs centered value={this.state.activeIndex} onChange={this.handleTabClick} aria-label="site tabs" className='bb b--black-10'>
                  <Tab label='Data' />
                </Tabs>
              </Box>

              <TabPanel value={this.state.activeIndex} index={0}>
                <SitesDataTable
                  sites={this.props.sites}
                  currentSites={this.props.currentSites}
                />
              </TabPanel>
            </Box>

          </Box>
        }
      </Fragment>
    )
  }
}
