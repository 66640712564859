import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Filters from "../../patents/Filters"
import Paper from "../../Paper"
import { Table, TableHead, TableRow, TableCell, TableBody } from "../../tables"
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TablePaginationActions from "../../tables/TablePaginationActions"
// import PatentRow from "./index/Row"
import Flex from "../../Flex"
import Box from "../../Box"
import Typography from "@material-ui/core/Typography"
import { withSnackbar } from "../../SnackbarContainer"
import { RaisedButton } from "../../buttons"
import TextInput from "../../TextInput"
import SearchIcon from '@material-ui/icons/Search'
import InputAdornment from '@material-ui/core/InputAdornment';
import DeviceRow from "./DeviceRow"


class Devices extends Component {
  constructor(props) {
    super(props)
    this.handleFilterChange = this.handleFilterChange.bind(this)
    this.handleSearchChange = this.handleSearchChange.bind(this)
    this.handleSearchSubmit = this.handleSearchSubmit.bind(this)
    this.handleCreateProductClick = this.handleCreateProductClick.bind(this)
    this.handleDeviceCheckboxClick = this.handleDeviceCheckboxClick.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.state = { devices: this.props.devices, product_code: this.props.product_code, products: this.props.products, checkedDevice: null, companyQuery: '', company_id: null }
  }

  handleDeviceCheckboxClick(device) {
    this.setState({
      checkedDevice: device.id
    })
  }

  handleChange(entityOptionValue) {
    this.setState({
      company_id: entityOptionValue
    })
  }

  handleSearchChange(e) {
    let name = e.target.name
    let value = e.target.value

    this.setState({
      [name]: value
    })
  }

  handleSearchSubmit(e) {
    this.getDevices({ product_code: this.state.product_code })
  }

  handleCreateProductClick(device) {
    let pmaNumber = device.supplement_number ? `${device.pma_number} ${device.supplement_number}` : device.pma_number

    $.ajax({
      type: "POST",
      url: "/admin/product_codes/create_product",
      data: {
        device_id: device.id, product_name: device.trade_name || device.device_name, company_id: this.state.company_id, applicant: device.applicant, user_id: this.props.current_user.id, k_number: device.k_number, pma_number: pmaNumber, product_code: device.product_code
      },
      dataType: 'JSON',
      success: (data) => {
        this.props.snackbarShowMessage("Device created as product")

        this.setState({
          products: data.products
        })
      }
    })
  }

  handleFilterChange(name, value) {
    let params = Object.assign({}, this.state.filterValues)

    params[name] = value

    this.getPatents(params)
  }

  getDevices(params) {
    $.ajax({
      type: 'GET',
      url: `/admin/product_codes/devices`,
      data: params,
      dataType: 'json',
      success: (data) => {

        this.setState({
          devices: data.devices
        })
      },
      error: (data) => {
        this.props.snackbarShowMessage('There was a problem getting devices. Please try again.', 'error')
      }
    })
  }

  render() {
    return(
      <Fragment>

        <Paper fullHeight>

          <Box height={1}>

            <Flex alignItems='center' mb={3}>

              <Box width={4/5} mr={3}>
                <TextInput
                  name='product_code'
                  placeholder='Enter Product Code'
                  size='small'
                  value={this.state.product_code}
                  onChange={this.handleSearchChange}
                  fullWidth
                  InputProps={{
                    startAdornment: <InputAdornment position="start"><SearchIcon fontSize='small' /></InputAdornment>
                  }}
                />
              </Box>

              <Box>
                <RaisedButton onClick={this.handleSearchSubmit}>
                  Search
                </RaisedButton>
              </Box>
            </Flex>

            <Box my={3}>
              {/* <Filters
                filterOptions={this.state.filterOptions}
                filterValues={this.state.filterValues}
                handleFilterChange={this.handleFilterChange}
              /> */}
            </Box>

            <Flex alignItems='center'>

              <Box>
                <Typography variant="body2">
                  {`${this.state.devices.length} devices`}
                </Typography>
              </Box>
            </Flex>

            <Box overflow='auto' height="90%">
              <Table size="small" stickyHeader={true}>
                <TableHead>
                  <TableRow hover={false}>
                    <TableCell />
                    <TableCell>Device</TableCell>
                    <TableCell>Applicant</TableCell>
                    <TableCell>Product Code</TableCell>
                    <TableCell>Reg #</TableCell>
                    <TableCell>Date</TableCell>
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.devices.map((device) =>
                    <DeviceRow
                      key={device.id}
                      device={device}
                      companyQuery={this.state.companyQuery}
                      checkedDevice={this.state.checkedDevice}
                      products={this.state.products}
                      companies={this.props.companies}
                      handleDeviceCheckboxClick={this.handleDeviceCheckboxClick}
                      handleChange={this.handleChange}
                      handleCreateProductClick={this.handleCreateProductClick}
                    />
                  )}
                </TableBody>
              </Table>
            </Box>
          </Box>
        </Paper>
      </Fragment>
    )
  }
}


export default withSnackbar(Devices)
