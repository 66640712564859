import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Paper from "../../Paper"
import Form from "./Form"
import update from "immutability-helper"
import Snackbar from "../../Snackbar"

export default class NewContainer extends Component {
  constructor(props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
    this.handleSaveClick = this.handleSaveClick.bind(this)
    this.handleDateChange = this.handleDateChange.bind(this)
    this.state = { fdaDocument: this.props.fda_document, showSnackbar: false, snackbarText: '' }
  }

  componentDidMount() {
    const fdaDocument = update(this.state.fdaDocument, {
      created_by: { $set: this.props.current_user.id }
    })

    this.setState({
      fdaDocument: fdaDocument
    })
  }

  handleDateChange(date) {
    const fdaDocument = update(this.state.fdaDocument, {
      date: { $set: date }
    })

    this.setState({
      fdaDocument: fdaDocument
    })
  }

  handleChange(e) {
    const name = e.target.name
    const value = e.target.value

    const fdaDocument = update(this.state.fdaDocument, {
      [name]: { $set: value }
    })

    this.setState({
      fdaDocument: fdaDocument
    })
  }

  handleSaveClick(e) {
    const url = this.props.redirect_url ? `/admin/fda_documents?r=${this.props.redirect_url}` : "/admin/fda_documents"
    $.post(url, { fda_document: this.state.fdaDocument }, () => {
      window.location.href = "/admin/fda_documents"
    }, 'json').fail((data) => {
      this.setState({
        showSnackbar: true,
        snackbarText: `The FDA document could not be created. ${data.responseText}.`
      })
    }).always(() => {
      setTimeout(() => {
        this.setState({
          showSnackbar: false,
          snackbarText: ''
        })
      }, 4000)
    })
  }

  render() {
    return(
      <Fragment>

        <Paper>

          <Form
            fdaDocument={this.state.fdaDocument}
            handleChange={this.handleChange}
            handleDateChange={this.handleDateChange}
            handleSaveClick={this.handleSaveClick}
          />

        </Paper>

        <Snackbar
          open={this.state.showSnackbar}
          message={this.state.snackbarText}
        />

      </Fragment>
    )
  }
}
