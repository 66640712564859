import React, { useState } from "react"
import Box from "../Box"
import { ListItem, ListItemText } from "../lists"
import Typography from "@material-ui/core/Typography"
import Collapse from '@material-ui/core/Collapse';

function StatementItem(props) {

  return(
    <React.Fragment>
      <Collapse in={props.open} timeout="auto" unmountOnExit>
        <div className='mh3 mt1 pa3' style={{backgroundColor: 'rgba(0, 0, 0, 0.04)'}}>
          <div
            dangerouslySetInnerHTML={{__html: props.companyStatement.statement }}
          />
        </div>
      </Collapse>
    </React.Fragment>
  )
}

export default StatementItem
