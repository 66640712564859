import React, { useState } from "react"
import Paper from "../Paper"
import { Table, TableHead, TableCell, TableRow, TableHeadSort, TableBody } from "../tables"
import Flex from "../Flex"
import Box from "../Box"
import Typography from "@material-ui/core/Typography"
import Link from "../Link"

export default function Recalls(props) {
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('recall_date')


  function desc(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = cmp(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
  }

  function getSorting(order, orderBy) {
    return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
  }

  function handleRequestSort(event, property) {
    const isDesc = orderBy === property && order === 'desc';

    setOrder(isDesc ? 'asc' : 'desc')
    setOrderBy(property)
  };

  let headers = [{ label: 'Country', name: 'recall_country' }, { label: 'Title', name: 'recall_title' }, { label: 'Reason', name: 'recall_description' }, { label: 'Status', name: 'recall_status' }, { label: 'Date', name: 'recall_date' }]

  return (
    <Paper fullHeight>
      <Box height="100%">

        <Box>
          <Typography variant="h6" component="h6">
            Recalls & Safety Notices
          </Typography>
        </Box>

        {props.recalls.length > 0 ?
          <Box mb={2} className='tr'>
            {props.recalls.length} {props.recalls.length > 1 ? 'recalls' : 'recall'}
          </Box>
        : ''}

        <Box height="90%" overflow='auto'>

          {props.recalls.length > 0 ?
            <Table size='small' aria-label='product recalls table' stickyHeader={true} id='product-recalls-table'>
              <TableHeadSort
                headers={headers}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
              />

              <TableBody>
                {stableSort(props.recalls, getSorting(order, orderBy)).map((recall) =>
                  <TableRow key={recall.id}>
                    <TableCell>
                      {recall.recall_country}
                    </TableCell>
                    <TableCell className='w-40'>
                      <Link href={recall.recall_url ? recall.recall_url : `https://www.accessdata.fda.gov/scripts/cdrh/cfdocs/cfres/res.cfm?id=${recall.recall_id}`} target="_blank">
                        <Box>
                          {truncateString(recall.recall_title, 100)}
                        </Box>
                      </Link>
                    </TableCell>
                    {/* <TableCell align='center'>
                      {recall.recall_class}
                    </TableCell> */}
                    <TableCell className="w-30">
                      {recall.recall_description}
                    </TableCell>
                    <TableCell className="w-15">
                      {recall.recall_status || "N/A"}
                    </TableCell>
                    <TableCell className="w-15">
                      {recall.recall_date}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          :
            <Flex justifyContent='center' height="50%">
              <Box alignSelf='center' maxWidth="50%">
                <Box>
                  <Typography variant="body1">
                    No recalls found for this product
                  </Typography>
                </Box>
              </Box>
            </Flex>
          }
        </Box>
      </Box>
    </Paper>
  )
}
