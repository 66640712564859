import React from "react"
import Paper from "../../Paper"
import Box from "../../Box"
import Tabs from "../../tabs/Tabs"
import Tab from "../../tabs/Tab"
import TabPanel from "../../tabs/TabPanel"


export default function EntityReviewPanel(props) {
  return (
    <React.Fragment>
      <Paper fullHeight>

        <Box height="100%">

          <Box mt={3} height="90%" className='overflow-auto'>
            {props.associated}
          </Box>
        </Box>

      </Paper>
    </React.Fragment>
  )
}
