import React, { Fragment, Component } from "react"
import PropTypes from "prop-types"
import { Table, TableRow, TableHead, TableBody, TableCell } from "../tables"
import Tabs from "../tabs/Tabs"
import Tab from "../tabs/Tab"
import Box from "../Box"
import RegionDataAttribute from "./RegionDataAttribute"
import DataAttribute from "./DataAttribute"

export default class RegionProfile extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    let regions = this.props.productRegions.map(region => region)

    let headers = [...this.props.productRegions, {id: 10000, name: 'Investigational'}]

    let fields = Object.keys(this.props.fields).sort()

    let nonRegionGroups = Object.keys(this.props.nonRegionalProductCharacteristics)

    return (
      <Fragment>

        <Box overflow='auto' height='95%'>
          <Table size='small' stickyHeader={true}>

            <TableHead>
              <TableRow hover={false}>
                <TableCell className='bg-white'>Attribute</TableCell>
                {headers.map((header) =>
                  <TableCell key={header.id} className='bg-white'>
                    {header.name}
                  </TableCell>
                )}
              </TableRow>
            </TableHead>

            <TableBody>
              {fields.map((field) =>
                <TableRow hover={false} key={field} selected={this.props.attribute == field ? true : false}>
                  <TableCell borderLeft={true} borderRight={true} className='w-10'>
                    {field}
                  </TableCell>
                  {headers.map((header) =>
                    nonRegionGroups.includes(field) ?
                      <DataAttribute
                        key={header.id}
                        header={header}
                        field={field}
                        data={Object.values(this.props.fields[field])}
                        sector={this.props.sector}
                      />
                    :
                      <RegionDataAttribute
                        key={header.id}
                        header={header}
                        field={field}
                        data={Object.values(this.props.fields[field])}
                        sector={this.props.sector}
                      />
                  )}
                </TableRow>
              )}
            </TableBody>
          </Table>
        </Box>

      </Fragment>
    )
  }
}
