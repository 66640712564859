import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import { TableRow, TableCell } from "../../tables"
import Link from "../../Link"

export default class SourceRow extends Component {
  constructor(props) {
    super(props)
  }
  
  render() {
    console.log(this.props.source)
    return(
      <Fragment>
        <TableRow>
          <TableCell>
            <Link href={`/admin/sources/${this.props.source.id}/edit`}>
              {this.props.source.entity_source_display_hash.source_kind}
            </Link>
          </TableCell>
          <TableCell>
            {this.props.source.entity_source_display_hash.source_title}
          </TableCell>
          <TableCell>
            {displayInitials(this.props.source.entity_source_display_hash.created_by.first_name, this.props.source.entity_source_display_hash.created_by.last_name)}
          </TableCell>
        </TableRow>
      </Fragment>
    )
  }
}
