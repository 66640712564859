import React from "react"
import Select from "../Select"
import Flex from "../Flex"

export default function Filter(props) {
  const { label, name, value, entities, ...rest } = props;

  const selectedOptions = entities.filter(entity => value.includes(entity.value) ? entity : null)

  return(
    <Select
      multiple
      label={label}
      name={name}
      value={value}
      entities={entities}
      handleChange={props.handleChange}
      includeBlank={true}
      renderValue={selected => (
        <Flex flexWrap='wrap'>
          {`${selectedOptions.length} chosen`}
        </Flex>
      )}
    />
  )
}
