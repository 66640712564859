import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Paper from "../../Paper"
import Flex from "../../Flex"
import Box from "../../Box"
import { Table, TableBody, TableHead, TableRow, TableCell } from "../../tables"
import StagedAssociatorRow from "./StagedAssociatorRow"
import update from "immutability-helper"
import AssociatorFilterPanel from "./AssociatorFilterPanel"
import Snackbar from "../../Snackbar"

export default class StagedAssociator extends Component {
  constructor(props) {
    super(props)
    this.handleStatusChange = this.handleStatusChange.bind(this)
    this.handleStatusUpdate = this.handleStatusUpdate.bind(this)
    this.handleDropdownChange = this.handleDropdownChange.bind(this)
    this.handleDaysChange = this.handleDaysChange.bind(this)
    this.handleHideClick = this.handleHideClick.bind(this)
    this.handleFindPublicationsClick = this.handleFindPublicationsClick.bind(this)
    this.handleSectorChange = this.handleSectorChange.bind(this)
    this.handleQueryChange = this.handleQueryChange.bind(this)
    this.state = { publications: this.props.publications, loading: false, filterOptions: { relevantOptions: this.props.relevant_options, priorities: this.props.priorities }, filterValues: { relevant: '', priority_id: this.props.priority_id, timeSince: this.props.time_since, number: '', sector_id: this.props.sector_id }, showSnackbar: false, snackbarText: '', query: '' }
  }

  componentDidMount() {
    let filterValues = update(this.state.filterValues, {
      relevant: { $set: this.props.relevant },
      timeSince: { $set: this.props.time_since }
    })

    this.setState({
      filterValues: filterValues
    })
  }

  handleQueryChange(e) {
    this.setState({
      query: e.target.value
    })
  }

  handleFindPublicationsClick(e) {
    if (this.state.query) {
      $.get("/admin/publications/search", { query: this.state.query }, (data) => {
        this.setState({
          publications: data.publications
        })
      }, 'json').fail((data) => {
        this.setState({
          showSnackbar: true,
          snackbarText: 'There was an error. Please try again.',
          loading: false
        })
      }).done((data) => {
        setTimeout(() => {
          this.setState({
            showSnackbar: false,
            snackbarText: '',
            loading: false
          })
        })
      })
    } else {
      console.log(this.state.filterValues.priority)
      $.get("/admin/publications/staged_associator", { relevant: this.state.filterValues.relevant, priority: this.state.filterValues.priority, time_since: this.state.filterValues.timeSince, sector_id: (this.state.filterValues.priority_id == "low" ? null : this.state.filterValues.sector_id), priority_id: this.state.filterValues.priority_id }, (data) => {
        this.setState({
          publications: data.publications
        })
      }, 'json').fail((data) => {
        this.setState({
          showSnackbar: true,
          snackbarText: 'Publications could not be found. Please try again.',
          loading: false
        })
      }).always((data) => {
        this.setState({
          loading: false
        })
      })
    }
  }

  handleHideClick(publication) {
    $.ajax({
      type: 'PUT',
      url: `/admin/publications/${publication.id}`,
      data: {
        manuscript: {
          hide: true
        }
      },
      dataType: 'json',
      success: (data) => {
        let index = this.state.publications.indexOf(publication)

        let publications = update(this.state.publications, {
          $splice: [[index, 1]]
        })

        this.setState({
          publications: publications
        })
      },
      error: (data) => {
        this.setState({
          showSnackbar: true,
          snackbarText: 'The publication could not be hidden. Please try again.'
        })
      },
      complete: (data) => {
        setTimeout(() => {
          this.setState({
            showSnackbar: false,
            snackbarText: ''
          })
        }, 4000)
      }
    })
  }

  handleDaysChange(e) {
    let filterValues = update(this.state.filterValues, {
      timeSince: { $set: e.target.value }
    })
    this.setState({
      filterValues: filterValues
    })
  }

  handleDropdownChange(name, value) {
    let filterValues = update(this.state.filterValues, {
      [name]: { $set: value }
    })

    this.setState({
      filterValues: filterValues
    })
  }

  handleStatusChange(publication) {
    $.ajax({
      type: 'POST',
      url: '/admin/manuscript_associator_statuses',
      data: {
        manuscript_associator_status: {
          trial_id: trial.id, status: true
        }
      },
      dataType: 'json',
      success: (data) => {
        let index = this.state.publications.indexOf(trial)
        let publications = update(this.state.publications, {
          [index]: {
            $merge: {
              manuscript_associator_status: data.manuscript_associator_status
            }
          }
        })

        this.setState({
          publications: publications
        })
      },
      error: (data) => {
        this.setState({
          showSnackbar: true,
          snackbarText: 'There was an issue setting the status. Please try again'
        })
      },
      complete: (data) => {
        setTimeout(() => {
          this.setState({
            showSnackbar: false,
            snackbarText: ''
          })
        }, 4000)
      }
    })
  }

  handleStatusUpdate(manuscriptAssociatorStatus, publication) {
    $.ajax({
      type: 'PUT',
      url: `/admin/manuscript_associator_statuses/${manuscriptAssociatorStatus.id}`,
      data: {
        manuscript_associator_status: {
          status: !manuscriptAssociatorStatus.status
        }
      },
      dataType: 'json',
      success: (data) => {
        let index = this.state.publications.indexOf(publication)
        let publications = update(this.state.publications, {
          [index]: {
            $merge: {
              manuscript_associator_status: data.manuscript_associator_status
            }
          }
        })

        this.setState({
          publications: publications
        })
      },
      error: (data) => {
        this.setState({
          showSnackbar: true,
          snackbarText: 'There was an issue setting the status. Please try again'
        })
      },
      complete: (data) => {
        setTimeout(() => {
          this.setState({
            showSnackbar: false,
            snackbarText: ''
          })
        }, 4000)
      }
    })
  }

  handleSectorChange(name, value) {

    let filterValues = update(this.state.filterValues, {
      sector_id: {
        $set: value
      }
    })
    this.setState({
      filterValues: filterValues
    })
  }

  render() {
    return(
      <Fragment>

        <Paper fullHeight>

          <AssociatorFilterPanel
            filterOptions={this.state.filterOptions}
            filterValues={this.state.filterValues}
            query={this.state.query}
            handleChange={this.handleQueryChange}
            handleDaysChange={this.handleDaysChange}
            handleDropdownChange={this.handleDropdownChange}
            sectors={this.props.sectors}
            priorities={this.props.priorities}
            handleFindPublicationsClick={this.handleFindPublicationsClick}
            handleSectorChange={this.handleSectorChange}
          />

          <Box height="100%">

            <Box overflow='auto' height="80%">

              <Table stickyHeader size='small' aria-label='publication staged associator table'>
                <TableHead>
                  <TableRow hover={false}>
                    <TableCell className='bg-white'>
                      PMID
                    </TableCell>
                    <TableCell className='bg-white'>
                      Publication
                    </TableCell>
                    <TableCell className='bg-white' align='right'>
                      Date
                    </TableCell>
                    <TableCell className='bg-white' align='right'>
                      # of predictions
                    </TableCell>
                    <TableCell className='bg-white' align='right'>
                      Hide
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {this.state.publications.map((publication) =>
                    <StagedAssociatorRow
                      key={publication.id}
                      publication={publication}
                      handleStatusChange={this.handleStatusChange}
                      handleStatusUpdate={this.handleStatusUpdate}
                      handleHideClick={this.handleHideClick}
                    />
                  )}
                </TableBody>
              </Table>
            </Box>
          </Box>

        </Paper>

      </Fragment>
    )
  }
}
