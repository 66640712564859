import React, { Component } from "react"
import PropTypes from "prop-types"
import ReactDOM from "react-dom"
import Select from "../Select"
import Flex from "../Flex"
import Box from "../Box"

export default class ActivityFilters extends Component {
  constructor(props) {
    super(props)
  }

  // renderBySector() {
  //   switch (this.props.sector.id) {
  //     case 1:
  //     case 2:
  //     case 10:
  //       return(
  //         <React.Fragment>
  //           <div className='ph3'>
  //
  //             <ActivityFilter
  //               label='Method'
  //               name='method'
  //               entities={this.props.filterOptions.methods}
  //               value={this.props.filterValues.method}
  //               handleChange={this.props.handleFilterChange}
  //             />
  //
  //           </div>
  //
  //           <div className='ph3'>
  //
  //             <ActivityFilter
  //               label='Target Market'
  //               name='target_market'
  //               entities={this.props.filterOptions.targetMarkets}
  //               value={this.props.filterValues.target_market}
  //               handleChange={this.props.handleFilterChange}
  //             />
  //
  //           </div>
  //
  //           <div className='ph3'>
  //
  //             <ActivityFilter
  //               label='Technology'
  //               name='technology'
  //               entities={this.props.filterOptions.technologies}
  //               value={this.props.filterValues.technology}
  //               handleChange={this.props.handleFilterChange}
  //             />
  //
  //           </div>
  //         </React.Fragment>
  //       )
  //       break;
  //     case 7:
  //     case 9:
  //       return(
  //         <React.Fragment>
  //           <div className='ph3'>
  //             <ActivityFilter
  //               label='Target Market'
  //               name='target_market'
  //               entities={this.props.filterOptions.targetMarkets}
  //               value={this.props.filterValues.target_market}
  //               handleChange={this.props.handleFilterChange}
  //             />
  //           </div>
  //           <div className='ph3'>
  //             <ActivityFilter
  //               label='Technology'
  //               name='technology'
  //               entities={this.props.filterOptions.technologies}
  //               value={this.props.filterValues.technology}
  //               handleChange={this.props.handleFilterChange}
  //             />
  //           </div>
  //         </React.Fragment>
  //       )
  //       break;
  //     case 3:
  //       return(
  //         <React.Fragment>
  //           <div className='ph3'>
  //             <ActivityFilter
  //               label='Heart Valve'
  //               name='valve_position'
  //               entities={this.props.filterOptions.valvePositions}
  //               value={this.props.filterValues.valve_position}
  //               handleChange={this.props.handleFilterChange}
  //             />
  //           </div>
  //           <div className='ph3'>
  //             <ActivityFilter
  //               label='Therapy'
  //               name='therapy'
  //               entities={this.props.filterOptions.therapies}
  //               value={this.props.filterValues.therapy}
  //               handleChange={this.props.handleFilterChange}
  //             />
  //           </div>
  //
  //           <div className='ph3'>
  //
  //             <ActivityFilter
  //               label='Method'
  //               name='method'
  //               entities={this.props.filterOptions.methods}
  //               value={this.props.filterValues.method}
  //               handleChange={this.props.handleFilterChange}
  //             />
  //
  //           </div>
  //         </React.Fragment>
  //       )
  //     default:
  //
  //   }
  // }

  render () {
    return (
      <React.Fragment>
        <Flex alignItems='center' justifyContent='center'>

          <Box width="12%" mr={2}>
            <Select
              label='Entities'
              name='chart_entity_type'
              value={this.props.filterValues.chart_entity_type}
              entities={this.props.filterOptions.chartEntityTypes}
              handleChange={this.props.handleFilterChange}
            />
          </Box>

          <Box>

            {/* <ActivityFilter
              label='Entities'
              name='chart_entity_type'
              entities={this.props.chartEntityTypes}
              value={this.props.filterValues.chart_entity_type}
              handleChange={this.props.handleEntityTypeChange}
              includeBlank={false}
            /> */}
          </Box>

          {/* {this.renderBySector()} */}

        </Flex>
      </React.Fragment>
    );
  }
}
