import React, { Fragment } from "react"
import ReactDOM from "react-dom"
import Flex from "../../Flex"
import Box from "../../Box"
import Select from "../../Select"
import TextInput from "../../TextInput"
import DatePicker from "../../DatePicker"
import ButtonLayout from "../ButtonLayout"
import { RaisedButton } from "../../buttons"


export default function Form(props) {
  return (
    <Fragment>
      <Flex alignItems='start'>

        <Box width={1/2}>
          <Select
            entities={props.companies}
            label='Company'
            name='company_id'
            value={props.pressRelease.company_id}
            displayEmpty={true}
            handleChange={props.handleCompanyChange}
          />
        </Box>

        <Box ml="auto">
          <Box>
            <label>Date</label>
          </Box>
          <input
            type="date"
            onChange={props.handleDateChange}
            value={props.pressRelease.date}
          />
        </Box>

      </Flex>

      <Box my={3} width={1}>
        <TextInput
          name='title'
          label='Title'
          textarea={true}
          value={props.pressRelease.title}
          onChange={props.handleChange}
          fullWidth
        />
      </Box>

      <Box my={3} width={1}>
        <TextInput
          name='url'
          label='URL'
          value={props.pressRelease.url}
          onChange={props.handleChange}
          fullWidth
        />
      </Box>

      <Box my={3} width={1}>
        <TextInput
          name='service'
          label='Publication'
          value={props.pressRelease.service}
          onChange={props.handleChange}
          fullWidth
        />
      </Box>

      <ButtonLayout>
        <RaisedButton handleClick={props.handleSaveClick}>
          Save Press Release
        </RaisedButton>
      </ButtonLayout>
    </Fragment>
  )
}
