import React, { Component } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Select from "./Select"
import Link from "./Link"
import { List, ListItem, ListItemText, ListItemLink } from "./lists"

class AlertGroupFilter extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    return(
      <div>
        <div className='db-ns dn'>
          <List>
            {this.props.alertGroups.map((alertGroup) =>
              <ListItemLink key={alertGroup} href={`/sectors/${this.props.sector.abbr}/${this.props.entityType}/${this.props.entity.id}/alerts?group_id=${alertGroup.alert_group_id}`} tag='a' className='h2'>
                <ListItemText
                  className={alertGroup.alert_group_id == this.props.groupId ? 'orange' : ''}
                  primary={alertGroup.group_name}
                />
              </ListItemLink>
            )}
          </List>
        </div>
        <div className='dn-ns db'>
          <Select label='Alert groups' />
        </div>
      </div>
    )
  }
}

export default AlertGroupFilter
