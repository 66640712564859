import React from "react"
import Link from "../Link"

export default function AccountLink(props) {
  let url = `https://twitter.com/${props.accountUserName}`
  return(
    <Link href={url} target="_blank">
      {props.display}
    </Link>
  )
}
