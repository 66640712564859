import React from "react"
import PropTypes from "prop-types"
import { TableRow, TableCell } from "../../tables"
import DeleteButton from "../DeleteButton"
import EditButton from "../EditButton"
import Link from "../../Link"

export default function TargetSiteRow(props) {
  
  function handleDeleteClick(e) {
    props.handleDeleteClick(props.targetSite)
  }
  
  const url = `/admin/target_sites/${props.targetSite.id}/edit`
  const user = props.targetSite.creator
  return(
    <TableRow>
      <TableCell>
        <Link href={url}>
          {props.targetSite.name}
        </Link>
      </TableCell>
      <TableCell>
        {displayInitials(user.first_name, user.last_name)}
      </TableCell>
      <TableCell>
        <EditButton component="a" href={url} />
      </TableCell>
      <TableCell>
        <DeleteButton onClick={handleDeleteClick} />
      </TableCell>
    </TableRow>
  )
}
