import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import CKEditor from 'ckeditor4-react';
import Typography from "@material-ui/core/Typography"
import Paper from "../../Paper"
import update from "immutability-helper"
import { Button, RaisedButton } from "../../buttons"
import Snackbar from "../../Snackbar"
import Select from "../../Select"
import Dialog from "../../Dialog"
import SourceForm from "../SourceForm"
import Chip from '@material-ui/core/Chip';
import Flex from "../../Flex"
import Box from "../../Box"
import ButtonLayout from "../ButtonLayout"

export default class NewContainer extends Component {
  constructor(props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
    this.handleSaveClick = this.handleSaveClick.bind(this)
    this.handleSourceChange = this.handleSourceChange.bind(this)
    this.handleSectionChange = this.handleSectionChange.bind(this)
    this.handleChooseSourceClick = this.handleChooseSourceClick.bind(this)
    this.handleSourceRemovalClick = this.handleSourceRemovalClick.bind(this)
    this.state = { profileField: this.props.profile_field, source: null, showSnackbar: false, snackbarText: '', openDialog: false }
  }

  UNSAFE_componentWillMount() {
    let profileField = update(this.state.profileField, {
      created_by: { $set: this.props.current_user.id },
      profile_field_name_id: { $set: (this.props.old_profile_field ? this.props.old_profile_field.profile_field_name_id : null) },
      content: { $set: (this.props.old_profile_field ? this.props.old_profile_field.content : null) },
      active: { $set: true }
    })
    this.setState({
      profileField: profileField
    })
  }

  handleSaveClick(e) {
    const params = { company_profile_field: this.state.profileField }
    $.ajax({
      type: 'POST',
      url: `/admin/companies/${this.props.company.id}/profile_fields`,
      data: params,
      error: (data) => {
        this.setState({
          showSnackbar: true,
          snackbarText: `${data.responseText}`
        })
      },
      complete: (data) => {
        setTimeout(() => {
          this.setState({
            showSnackbar: false,
            snackbarText: ''
          })
        }, 4000)
      }
    })
  }

  handleSourceRemovalClick(e) {
    this.setState({
      source: null
    })
  }

  handleChooseSourceClick(e) {
    this.setState({
      openDialog: true
    })
  }

  handleSourceChange(entity, sourceType) {
    if (sourceType == 'recently_used') {
      let profileField = update(this.state.profileField, {
        source_id: { $set: entity.id }
      })
      this.setState({
        source: entity,
        profileField: profileField,
        openDialog: false
      })
    } else {
      $.get("/admin/sources/find_source", { source_type: sourceType, entity_id: entity.id }, (data) => {
        if (data.source) {
          let profileField = update(this.state.profileField, {
            source_id: { $set: data.source.source_id }
          })
          this.setState({
            source: data.source,
            profileField: profileField,
            openDialog: false
          })
        } else {
          $.post("/admin/sources/filter", { source: { kind: sourceType, entity_id: entity.id } }, (data) => {
            let profileField = update(this.state.profileField, {
              source_id: { $set: data.source.source_id }
            })
            this.setState({
              source: data.source,
              profileField: profileField,
              openDialog: false
            })
          })
        }
      }, 'json')
    }
  }

  handleSectionChange(name, value) {
    let profileField = update(this.state.profileField, {
      profile_field_name_id: { $set: value }
    })
    this.setState({
      profileField: profileField
    })
  }

  handleChange(evt) {
    let text = evt.editor.getData()
    let profileField = update(this.state.profileField, { content: { $set: text } })
    this.setState({
      profileField: profileField
    })
  }

  render() {
    return(
      <Fragment>

        <Paper>

          <Flex align='items-end'>
            <Box width={1/4}>
              <Select
                value={this.state.profileField.profile_field_name_id}
                label='Profile Field Name'
                entities={this.props.sections}
                displayEmpty={false}
                handleChange={this.handleSectionChange}
              />
            </Box>

            <Box ml={3}>
              <Button tag='a' href={`/admin/profile_field_names/new?r=/admin/companies/${this.props.company.id}/profile_fields/new`}>
                Add Profile Field Name
              </Button>
            </Box>
          </Flex>

          <div>
            <div className='mv3'>
              {this.state.source ?
                <Chip
                  label={this.state.source.source_title}
                  onDelete={this.handleSourceRemovalClick}
                />
              :
                <Button onClick={this.handleChooseSourceClick}>Choose Source</Button>}
            </div>

            {this.state.openDialog ?
              <Dialog
                openDialog={this.state.openDialog}
                dialogTitle='Choose Source'
                withButtons={false}
                handleClick={this.handleAddClick}
                dialogContent={<SourceForm sources={this.props.sources} sourceTypes={this.props.source_types} handleSourceChange={this.handleSourceChange} />}
              /> : ''}

            <div>
              <CKEditor
                data={this.state.profileField.content}
                onChange={this.handleChange}
              />
            </div>
          </div>

          <ButtonLayout>
            <RaisedButton handleClick={this.handleSaveClick}>
              Save
            </RaisedButton>
          </ButtonLayout>

        </Paper>

        <Snackbar
          open={this.state.showSnackbar}
          message={this.state.snackbarText}
        />

      </Fragment>
    )
  }
}
