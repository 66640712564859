import React, { Component, Fragment } from "react"
import Paper from "../Paper"
import Flex from "../Flex"
import Box from "../Box"
import AdverseEventFilters from "./AdverseEventFilters"
import AdverseEvents from "./AdverseEvents"
import AdverseEventTrends from "./AdverseEventTrends"
import Typography from "@material-ui/core/Typography"
import { withSnackbar } from '../SnackbarContainer'

class AdverseEventsContainer extends Component {
  constructor(props) {
    super(props)
    this.handleFilterChange = this.handleFilterChange.bind(this)
    this.handleViewChange = this.handleViewChange.bind(this)
    this.handleChangePage = this.handleChangePage.bind(this)
    this.handleRequestSort = this.handleRequestSort.bind(this)
    this.state = { adverseEvents: this.props.adverse_events, adverseEventsCount: this.props.adverse_events_count, eventTypes: this.props.event_types, view: 'list', eventType: '', reportType: '', reporterCountryCodes: this.props.reporter_country_codes, reporterCountryCode: '', deviceProblems: this.props.device_problems, deviceProblemCode: this.props.device_problem_code, reportTypeChartData: this.props.report_type_chart_data, countryCodeChartData: this.props.country_code_chart_data, collections: this.props.collections, collectionId: '', therapyTypes: this.props.therapy_types, therapyTypeId: '', targetSites: this.props.target_sites, targetSiteId: '', page: this.props.page, orderBy: 'date_received', orderDirection: 'desc', loading: false }
  }

  handleChangePage(e, newPage) {
    let filters = { event_type: this.state.eventType, report_type: this.state.reportType, reporter_country_code: this.state.reporterCountryCode, device_problem: this.state.deviceProblem, collection_id: this.state.collectionId, therapy_type_id: this.state.therapyTypeId, target_site_id: this.state.targetSiteId, page: this.state.page }

    filters['page'] = newPage

    $.ajax({
      type: 'GET',
      data: filters,
      dataType: 'json',
      beforeSend: (data) => {
        this.setState({
          loading: true
        })
      },
      success: (data) => {
        this.setState({
          adverseEvents: data.adverse_events,
          adverseEventsCount: data.adverse_events_count,
          page: newPage,
          loading: false
        })
      },
      error: (message) => {
        this.props.snackbarShowMessage('Error loading adverse events', 'error')
        this.setState({
          loading: false
        })
      }
    })
  }

  handleViewChange(view) {
    this.setState({ view: view })
  }

  handleFilterChange(name, value) {
    let filters = { event_type: this.state.eventType, report_type: this.state.reportType, reporter_country_code: this.state.reporterCountryCode, device_problem_code: this.state.deviceProblemCode, collection_id: this.state.collectionId, therapy_type_id: this.state.therapyTypeId, target_site_id: this.state.targetSiteId }

    filters[name] = value

    $.ajax({
      type: 'GET',
      data: filters,
      dataType: 'json',
      beforeSend: (data) => {
        this.setState({
          loading: true
        })
      },
      success: (data) => {
        this.setState({
          adverseEvents: data.adverse_events,
          adverseEventsCount: data.adverse_events_count,
          eventTypes: data.event_types,
          reporterCountryCodes: data.reporter_country_codes,
          deviceProblems: data.device_problems,
          therapyTypes: data.therapy_types,
          targetSites: data.target_sites,
          eventType: filters['event_type'],
          reportType: filters['report_type'],
          reporterCountryCode: filters['reporter_country_code'],
          deviceProblemCode: filters['device_problem_code'],
          collectionId: filters['collection_id'],
          therapyTypeId: filters['therapy_type_id'],
          targetSiteId: filters['target_site_id'],
          loading: false
        })
      },
      error: (data) => {
        this.props.snackbarShowMessage('Error loading adverse events', 'error')
        this.setState({
          loading: false
        })
      }
    })
  }

  handleRequestSort(event, property) {
    let params = { event_type: this.state.eventType, report_type: this.state.reportType, reporter_country_code: this.state.reporterCountryCode, device_problem_code: this.state.deviceProblemCode, collection_id: this.state.collectionId, therapy_type_id: this.state.therapyTypeId, target_site_id: this.state.targetSiteId, order_by: this.state.orderBy, order_direction: this.state.orderDirection }

    params["order_by"] = property

    let isDesc = this.state.orderBy === property && this.state.orderDirection === 'desc';

    params["order_direction"] = isDesc ? 'asc' : 'desc'

    $.ajax({
      type: 'GET',
      url: `/sectors/${this.props.sector.abbr}/fda_adverse_events/order`,
      data: params,
      dataType: 'json',
      beforeSend: (data) => {
        this.setState({
          loading: true
        })
      },
      success: (data) => {
        this.setState({
          orderDirection: params["order_direction"],
          orderBy: params["order_by"],
          adverseEvents: data.adverse_events,
          loading: false
        })
      },
      error: (data) => {
        this.props.snackbarShowMessage('Error loading adverse events', 'error')
        this.setState({
          loading: false
        })
      }
    })
  }

  render() {
    return (
      <Paper fullHeight>
        <Box height="100%">
          <Flex alignItems='center'>

            <AdverseEventFilters
              eventTypes={this.state.eventTypes}
              reportTypes={this.props.report_types}
              reporterCountryCodes={this.state.reporterCountryCodes}
              eventType={this.state.eventType}
              reportType={this.state.reportType}
              sector={this.props.sector}
              reporterCountryCode={this.state.reporterCountryCode}
              deviceProblemCode={this.state.deviceProblem}
              deviceProblems={this.state.deviceProblems}
              collections={this.state.collections}
              collectionId={this.state.collectionId}
              therapyTypes={this.state.therapyTypes}
              therapyTypeId={this.state.therapyTypeId}
              targetSites={this.state.targetSites}
              targetSiteId={this.state.targetSiteId}
              handleFilterChange={this.handleFilterChange}
            />
          </Flex>

          <Box mt={1} height="100%">
            <AdverseEvents
              adverseEvents={this.state.adverseEvents}
              adverseEventsCount={this.state.adverseEventsCount}
              sector={this.props.sector}
              page={this.state.page}
              view={this.state.view}
              orderDirection={this.state.orderDirection}
              orderBy={this.state.orderBy}
              loading={this.state.loading}
              handleViewChange={this.handleViewChange}
              handleChangePage={this.handleChangePage}
              handleRequestSort={this.handleRequestSort}
            />
          </Box>
          {/* :
            <Flex justifyContent='center' height="50%">
              <Box alignSelf='center' maxWidth="50%">
                <Box>
                  <Typography variant="body1">
                    No adverse events found
                  </Typography>
                </Box>
              </Box>
            </Flex>
          } */}
        </Box>
      </Paper>
    )
  }
}

export default withSnackbar(AdverseEventsContainer)