import React, { Component, Fragment } from "react"
import PropTypes from "prop-types"
import Paper from "../Paper"
import Filters from "./Filters"
import update from 'immutability-helper'
import ManuscriptsTable from "./ManuscriptsTable"
import Flex from "../Flex"
import Box from "../Box"
import Button from "../buttons/Button"
import Typography from "@material-ui/core/Typography"
import FilterChipsContainer from "./FilterChipsContainer"
import { withRouter } from "react-router-dom";
import Snackbar from "../Snackbar"


class Manuscripts extends Component {
  constructor(props) {
    super(props)
    this.handleFilterChange = this.handleFilterChange.bind(this)
    this.getManuscripts = this.getManuscripts.bind(this)
    this.handleChangePage = this.handleChangePage.bind(this)
    this.handleDelete = this.handleDelete.bind(this)
    this.handleSearchClick = this.handleSearchClick.bind(this)
    this.handleSearchCloseClick = this.handleSearchCloseClick.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleClearFilterClick = this.handleClearFilterClick.bind(this)
    this.handleRequestSort = this.handleRequestSort.bind(this)
    this.state = { manuscripts: this.props.manuscripts, manuscriptsCount: this.props.manuscripts_count, filterOptions: { companies: this.props.companies, products: this.props.products, journals: this.props.journals, years: this.props.years, targetSites: this.props.target_sites, therapyTypes: this.props.therapy_types, targetMarkets: this.props.target_markets, trials: this.props.trials }, filterValues: { company_ids: [], product_ids: [], journal_ids: [], time_period: '', target_site_ids: [], target_market_ids: [], therapy_type_ids: [], trial_ids: [], query: null }, showFilters: false, loading: false, chosen: { products: this.props.chosen_products, companies: this.props.chosen_companies, journals: this.props.chosen_journals, targetSites: this.props.chosen_target_sites, targetMarkets: this.props.chosen_target_markets, therapyTypes: this.props.chosen_therapy_types, trials: this.props.chosen_trials }, page: 0, rowsPerPage: 100, showSnackbar: false, snackbarText: '', search: false, order: 'desc', orderBy: 'published_date' }
  };

  componentDidMount() {
    let filterKeys = Object.keys(this.props.filter_values)
    let filterValueHash = { query: this.props.query }


    let parsedFilterValues = filterKeys.map((filterKey) =>
      filterKey == 'time_period' ? (filterValueHash['time_period'] = this.props.filter_values[filterKey] ? this.props.filter_values[filterKey] : '')  : this.props.filter_values[filterKey] == null ? filterValueHash[filterKey] = [] : filterValueHash[filterKey] = this.props.filter_values[filterKey].map((value) => parseInt(value))
    )

    let filterValues = update(this.state.filterValues, {
      $set: filterValueHash
    })

    this.setState({
      filterValues: filterValues,
      search: this.props.query ? true : false
    })
  }

  handleRequestSort(event, property) {
    let params = Object.assign({}, this.state.filterValues)

    params["order_by"] = property

    let isDesc = this.state.orderBy === property && this.state.order === 'desc';

    params["order"] = isDesc ? 'asc' : 'desc'

    $.ajax({
      type: 'GET',
      url: `/sectors/${this.props.sector.abbr}/publications/order_manuscripts`,
      data: params,
      dataType: 'json',
      beforeSend: (data) => {
        this.setState({
          loading: true
        })
      },
      success: (data) => {
        this.setState({
          order: params["order"],
          orderBy: params["order_by"],
          manuscripts: data.manuscripts,
          loading: false
        })
      },
      error: (data) => {
        console.log(data.responseText)
        this.setState({
          showSnackbar: true,
          snackbarText: 'There was an error. Please try again.',
          loading: false
        })
      },
      complete: (data) => {
        setTimeout(() => {
          this.setState({
            showSnackbar: false,
            snackbarText: '',
            loading: false
          })
        }, 5000)
      }
    })
  }

  handleChangePage(e, newPage) {
    let params = Object.assign({}, this.state.filterValues)

    if (newPage < this.state.page) {
      params['page'] = newPage - 1
    } else {
      params['page'] = newPage + 1
    }

    this.getManuscripts(params)
  }

  handleClearFilterClick(e) {
    let filterValues = update(this.state.filterValues, {
      product_ids: { $set: [] },
      trial_ids: { $set: [] },
      target_market_ids: { $set: [] },
      target_site_ids: { $set: [] },
      therapy_type_ids: { $set: [] },
      journal_ids: { $set: [] },
      time_period: { $set: '' },
      query: { $set: null }
    })

    this.setState({
      filterValues: filterValues
    }, () => {
      this.handleFilterChange('', '')
    })
  }

  handleChange(e) {

    const value = e.target.value

    let filterValues = update(this.state.filterValues, {
      query: { $set: value }
    })

    this.setState({
      filterValues: filterValues,
      search: false
    })
  }

  handleSearchCloseClick(e) {
    this.setState({
      search: false
    })
  }

  handleSearchClick(e) {
    $.ajax({
      type: 'GET',
      url: `/sectors/${this.props.sector.abbr}/publications`,
      data: {
        query: this.state.filterValues.query
      },
      dataType: 'json',
      success: (data) => {
        this.setState({
          manuscripts: data.manuscripts,
          manuscriptsCount: data.manuscripts_count,
          search: true
        })
      }
    })
  }

  handleDelete(value, type) {
    if (type == 'query') {
      let filterValues = update(this.state.filterValues, {
        query: {
          $set: null
        }
      })

      this.getManuscripts(filterValues)
    } else {
      const index = this.state.filterValues[type].indexOf(value)

      const filterValues = update(this.state.filterValues, {
        [type]: { $splice: [[index, 1]] }
      })

      this.getManuscripts(filterValues)
    }
  }

  handleFilterChange(name, value) {
    let params = Object.assign({}, this.state.filterValues)

    if (value == null) {
      params[name] = ''
    } else if (value.includes(null)) {
      params[name] = []
    } else {
      params[name] = value
    }

    this.getManuscripts(params)
  }

  getManuscripts(params) {
    $.ajax({
      type: 'GET',
      url: `/sectors/${this.props.sector.abbr}/publications`,
      data: params,
      dataType: 'json',
      beforeSend: (data) => {
        this.setState({
          loading: true
        })
      },
      success: (data) => {

        let filterOptions = { companies: data.companies, products: data.products, years: data.years, journals: data.journals, targetSites: data.target_sites, targetMarkets: data.target_markets, therapyTypes: data.therapy_types, trials: data.trials }

        let chosen = { companies: data.chosen_companies, products: data.chosen_products, journals: data.chosen_journals, targetSites: data.chosen_target_sites, targetMarkets: data.chosen_target_markets, therapyTypes: data.chosen_therapy_types, trials: data.chosen_trials }

        let filterKeys = Object.keys(params)
        let queryParams = filterKeys.map((filterKey) =>
          filterKey == 'time_period' || filterKey == 'query' ? (params[filterKey] == null ? null : `${filterKey}=${params[filterKey]}`) : (params[filterKey].length > 0 ? params[filterKey].map((filterValue) => `${filterKey}[]=${filterValue}`) : null)
        ).filter(param => param)
        // let queryParams = filterKeys.map((filterKey) => params[filterKey] && params[filterKey].length > 0 ? params[filterKey].map((filterValue) => `${filterKey}[]=${filterValue}`) : null).filter(param => param)

        let url = `/sectors/${this.props.sector.abbr}/publications`

        let flattenedQueryParams = [].concat.apply([], queryParams);
        this.props.history.push(`${url}?${flattenedQueryParams.join("&")}`);

        const page = params['page'] ? params['page'] : this.state.page

        this.setState({
          manuscripts: data.manuscripts,
          manuscriptsCount: data.manuscripts_count,
          filterValues: params,
          page: page,
          loading: false,
          filterOptions,
          chosen
        })
      },
      error: (data) => {
        this.setState({
          showSnackbar: true,
          snackbarText: 'There was an error. Please try again.',
          loading: false
        })
      },
      complete: (data) => {
        setTimeout(() => {
          this.setState({
            showSnackbar: false,
            snackbarText: ''
          })
        }, 4000)
      }
    })
  }

  render () {
    let filterValueKeys = Object.keys(this.state.filterValues).filter(key => key !== 'time_period')
    return (
      <Fragment>

        <Paper fullHeight>
          <Box height="100%" overflow='hidden'>

            <Box mb={2}>
              <Filters
                filterOptions={this.state.filterOptions}
                filterValues={this.state.filterValues}
                search={this.state.search}
                sector={this.props.sector}
                handleChange={this.handleChange}
                handleSearchClick={this.handleSearchClick}
                handleSearchCloseClick={this.handleSearchCloseClick}
                handleFilterChange={this.handleFilterChange}
              />
            </Box>

            <Flex my={2} alignItems='center' minHeight="28px">
              <FilterChipsContainer
                filterOptions={this.state.filterOptions}
                filterValues={this.state.filterValues}
                chosen={this.state.chosen}
                search={this.state.search}
                handleDelete={this.handleDelete}
              />

              <Box ml='auto'>

                <Flex alignItems='center'>

                  {filterValueKeys.filter(filterValueKey => this.state.filterValues[filterValueKey] ? this.state.filterValues[filterValueKey].length > 0 : null).length > 0 ?
                    <Box>
                      <Button onClick={this.handleClearFilterClick}>
                        Clear all
                      </Button>
                    </Box>
                  : ''}

                  <Box>
                    <Typography variant="body2">
                      {`${this.state.manuscriptsCount} publications`}
                    </Typography>
                  </Box>
                </Flex>
              </Box>
            </Flex>

            <ManuscriptsTable
              manuscripts={this.state.manuscripts}
              loading={this.state.loading}
              manuscriptsCount={this.state.manuscriptsCount}
              page={this.state.page}
              order={this.state.order}
              orderBy={this.state.orderBy}
              rowsPerPage={this.state.rowsPerPage}
              handleChangePage={this.handleChangePage}
              sector={this.props.sector}
              handleRequestSort={this.handleRequestSort}
            />

          </Box>
        </Paper>

        <Snackbar
          open={this.state.showSnackbar}
          message={this.state.snackbarText}
        />

      </Fragment>
    );
  }
}

export default withRouter(Manuscripts)
