import React, { useState } from "react"
import { List, ListItem, ListItemText } from "../lists"
import Checkbox from "../Checkbox"
import { Button } from "../buttons"

import { withStyles, createMuiTheme, ThemeProvider, makeStyles } from '@material-ui/core/styles';

const theme = createMuiTheme({
  palette: {
    primary: {
      light: "#e57373",
      main: "#f44336",
      dark: "#d32f2f",
      contrastText: "#fff"
    },
  },
});

const useStyles = makeStyles(theme => ({
  root: {
    color: '#fff !important',
    '&:hover': {
      backgroundColor: "#f44336",
    },
  }
}));

export default function UserListItem(props) {
  const classes = useStyles(props);

  const [hoverButtonText, setHoverButtonText] = useState(null);

  let memberIds = props.userCollectionMembers.map(userCollectionMember => userCollectionMember.member_id)

  let member = memberIds.includes(props.user.id)

  let actualButtonText = member ? (hoverButtonText ? hoverButtonText : "Shared") : "Share"

  return(
    <ListItem divider>

      <ListItemText
        primary={`${props.user.first_name} ${props.user.last_name}`}
      />

      <Button variant={member ? "contained" : "outlined"} color="primary" onClick={() => { props.handleShareCheckboxClick(props.user) }} onMouseEnter={member ? () => { setHoverButtonText("Remove") } : ""} onMouseLeave={() => { setHoverButtonText(null) }} className={hoverButtonText ? classes.root : ""}>
        {actualButtonText}
      </Button>

    </ListItem>
  )
}
