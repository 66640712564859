import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';

const YearRangeSelector = (props) => {
    const { years } = props
    const currentYear = new Date().getFullYear();

    const [startYear, setStartYear] = useState(currentYear - 10); // Default to 10 years ago
    const [endYear, setEndYear] = useState(currentYear); // Default to the current year

    return (
        <div style={{ display: 'flex', justifyContent: 'space-between', width: "100%" }}>
            <TextField
                select
                label="Begin Year"
                value={startYear}
                onChange={(e) => setStartYear(e.target.value)}
                variant="outlined"
                style={{ width: '50%' }}
            >
                {years.map((year) => (
                    <MenuItem key={year.name} value={year.name}>
                        {`${year.name} (${year.count.toLocaleString('en-US', { maximumFractionDigits: 0})})`}
                    </MenuItem>
                ))}
            </TextField>

            -
            
            <TextField
                select
                label="End Year"
                value={endYear}
                onChange={(e) => setEndYear(e.target.value)}
                variant="outlined"
                style={{ width: '50%' }}
            >
                {years.map((year) => (
                    <MenuItem key={year.name} value={year.name}>
                        {`${year.name} (${year.count.toLocaleString('en-US', { maximumFractionDigits: 0})})`}
                    </MenuItem>
                ))}
            </TextField>
        </div>
    );
};

export default YearRangeSelector;
