import React, { Fragment, useState } from "react"
import update from "immutability-helper"
import GroupDisplay from "../characteristics/GroupDisplay"
import SearchInput from "../../search/Input";

function DialogFilters(props) {
  // const [characteristicValueIds, characteristicValueIds] = useState([])
  const [query, setQuery] = useState("")
  const [productCharacteristics, setProductCharacteristics] = useState(props.productCharacteristics)

  function handleQueryChange(e) {
    let filteredProductCharacteristics = {}

    Object.keys(props.productCharacteristics).map((characteristicGroupId) => {
      let filteredValues = props.productCharacteristics[characteristicGroupId].filter(characteristicValue => {
        return characteristicValue.name.toLowerCase().includes(e.target.value.toLowerCase());
      });

      // If there are any filtered values, add them to the filteredCharacteristics object
      if (filteredValues.length > 0) {
        filteredProductCharacteristics[characteristicGroupId] = filteredValues;
      }
    })

    setQuery(e.target.value)
    setProductCharacteristics(filteredProductCharacteristics)
  }

  const handleClearSearchClick = () => {
    setQuery("")
    setProductCharacteristics(props.productCharacteristics)
  }

  return(
    <Fragment>
      <SearchInput 
        searchLabel="Search characteristics"
        query={query}
        handleQueryChange={handleQueryChange}
        handleClearSearchClick={handleClearSearchClick}
      />
      {Object.keys(productCharacteristics).map((characteristicGroupId) =>
        <GroupDisplay
          key={characteristicGroupId}
          groupName={productCharacteristics[characteristicGroupId][0].group_name}
          characteristicValues={productCharacteristics[characteristicGroupId]}
          characteristicValueIds={props.characteristicValueIds}
          handleCharacteristicValueChange={props.handleCharacteristicValueChange}
        />
      )}
    </Fragment>
  )
}

export default DialogFilters
