import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Box from "../../Box"
import update from "immutability-helper"
import Snackbar from "../../Snackbar"
import Paper from "../../Paper"
import ResultsTable from "../trial_results/ResultsTable"
import { Button } from "../../buttons"

export default class ResultsContainer extends Component {
  constructor(props) {
    super(props)
    this.handleDeleteClick = this.handleDeleteClick.bind(this)
    this.state = { trialResults: this.props.trial_results, showSnackbar: false, snackbarText: '' }
  }

  handleDeleteClick(result) {
    $.ajax({
      type: 'PUT',
      url: `/admin/trials/${this.props.trial.id}/results/${result.id}`,
      data: {
        trial_result: {
          deleted: true, deleted_by: this.props.user.id
        }
      },
      dataType: 'JSON',
      success: (data) => {
        let index = this.state.trialResults.indexOf(result)
        let trialResults = update(this.state.trialResults, {
          $splice: [[index, 1]]
        })

        this.setState({
          trialResults: trialResults
        })
      },
      error: (data) => {
        this.setState({
          showSnackbar: true,
          snackbarText: 'There was an error removing the result'
        })
      },
      complete: (data) => {
        setTimeout(() => {
          this.setState({
            showSnackbar: false,
            snackbarText: ''
          })
        }, 5000)
      }
    })
  }

  render() {
    return (
      <Fragment>
        <Paper fullHeight>
          <Box mb={3}>
            <Button component='a' href={`/admin/trials/${this.props.trial.id}/results/new`}>
              New Result
            </Button>
          </Box>
          <ResultsTable
            trialResults={this.state.trialResults}
            handleDeleteClick={this.handleDeleteClick}
          />
        </Paper>

        <Snackbar
          open={this.state.showSnackbar}
          message={this.state.snackbarText}
        />
      </Fragment>
    )
  }
}
