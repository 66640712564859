import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Paper from "../../../Paper"
import Index from "./Index"
import Flex from "../../../Flex"
import Box from "../../../Box"
import Typography from '@material-ui/core/Typography';
import update from "immutability-helper"
import IconButton from "../../../IconButton"
import { withSnackbar } from "../../../SnackbarContainer"
import Form from "./Form"
import LoadingContainer from "../../../LoadingContainer"

class Container extends Component {
  constructor(props) {
    super(props)
    this.handleDeleteClick = this.handleDeleteClick.bind(this)
    this.handleActiveClick = this.handleActiveClick.bind(this)
    this.handleSubmitClick = this.handleSubmitClick.bind(this)
    this.handleDateChange = this.handleDateChange.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleSectorChange = this.handleSectorChange.bind(this)
    this.handleSectorDelete = this.handleSectorDelete.bind(this)
    this.handleDateTypeChange = this.handleDateTypeChange.bind(this)
    this.state = { productRegulatoryPath: null, productRegulatoryPaths: null, date: "", dateText: "", dateType: "expected", loading: true, sectorIds: [] }
  }

  componentDidMount() {
    $.ajax({
      type: "GET",
      url: `/admin/products/${this.props.product.id}/regulatory_path`,
      data: {
        source_id: this.props.source ? this.props.source.source_id : null
      },
      dataType: 'json',
      success: (data) => {
        this.setState({
          productRegulatoryPath: data.product_regulatory_path,
          productRegulatoryPaths: data.product_regulatory_paths,
          loading: false
        })
      },
      error: (data) => {
        this.setState({
          loading: false
        })
        this.props.snackbarShowMessage("There was an error fetching the data. Please refresh the page. If the issue persists, create an asana task.", "error")
      }
    })
  }

  handleDateChange(e) {
    let dateAttr = this.state.dateType == "expected" ? "approval_expected_date" : "submitted_date"

    const productRegulatoryPath = update(this.state.productRegulatoryPath, {
      [dateAttr]: { $set: e.target.value }
    })

    this.setState({
      productRegulatoryPath: productRegulatoryPath
    })
  }

  handleChange(name, value) {
    const productRegulatoryPath = update(this.state.productRegulatoryPath, {
      [name]: { $set: value }
    })

    this.setState({
      productRegulatoryPath: productRegulatoryPath
    })
  }

  handleDateTypeChange(name, value) {
    this.setState({
      [name]: value
    })
  }

  handleSectorChange(name, value) {
    this.setState({
      sectorIds: value
    })
  }

  handleSubmitClick(e) {
    if (this.state.productRegulatoryPath.source_id) {
      $.post("/admin/product_regulatory_paths", { product_regulatory_path: this.state.productRegulatoryPath, sector_ids: this.state.sectorIds }, (data) => {
        this.setState({
          productRegulatoryPaths: data.product_regulatory_paths
        })
        this.props.snackbarShowMessage("Path has been added")
      }, 'json').fail((data) => {
        this.props.snackbarShowMessage("Path could not be added. Please try again. If it doesn't work again, please create asana task.", "error")
      })
    } else {
      this.props.snackbarShowMessage("You must choose a source before adding a status", "error")
    }
  }

  handleSectorDelete(regulatoryPathSectorId) {
    $.ajax({
      type: "PUT",
      url: `/admin/product_regulatory_path_sectors/${regulatoryPathSectorId}`,
      data: {
        product_id: this.props.product.id, product_regulatory_path_sector: { deleted: true, deleted_by: this.props.currentUser.id }
      },
      dataType: "JSON",
      success: (data) => {
        this.setState({
          productRegulatoryPaths: data.product_regulatory_paths
        })
      }
    })
  }

  handleActiveClick(productStatus) {
    const active = productStatus.active ? null : true
    $.ajax({
      type: 'PUT',
      url: `/admin/product_regulatory_statuses/${productStatus.id}`,
      data: {
        product_regulatory_status: {
          active: active, updated_by: this.props.currentUser.id
        }
      },
      dataType: 'json',
      success: (data) => {
        let index = this.state.productRegulatoryStatuses.indexOf(productStatus)

        let productRegulatoryStatuses = update(this.state.productRegulatoryStatuses, {
          [productStatus.id]: { [0]: { active: { $set: active } }}
        })

        this.setState({
          productRegulatoryStatuses: productRegulatoryStatuses
        })
      }
    })
  }

  handleDeleteClick(productRegulatoryPath) {
    $.ajax({
      type: 'PUT',
      url: `/admin/product_regulatory_paths/${productRegulatoryPath.id}`,
      data: {
        product_regulatory_path: {
          deleted: true, deleted_by: this.props.currentUser.id
        }
      },
      dataType: 'json',
      success: (data) => {

        let productRegulatoryPaths = update(this.state.productRegulatoryPaths, {
          $unset: [data.product_regulatory_path.id]
        })

        this.setState({
          productRegulatoryPaths: productRegulatoryPaths
        })
      },
      error: (data) => {
        this.props.snackbarShowMessage("There was an issue removing the path record. Please try again. If the issue persists, write up an asana task.", "error")
      }
    })
  }

  render() {
    return(
      <Fragment>
        <Box height="100%" mt={2}>

          <Box height="90%" overflow='auto'>

            <LoadingContainer isLoading={this.state.loading}>

              <Form
                productRegulatoryPath={this.state.productRegulatoryPath}
                worldRegions={this.props.worldRegions}
                dateType={this.state.dateType}
                sectors={this.props.sectors}
                sectorIds={this.state.sectorIds}
                handleChange={this.handleChange}
                handleDateChange={this.handleDateChange}
                handleSectorChange={this.handleSectorChange}
                handleSubmitClick={this.handleSubmitClick}
                handleDateTypeChange={this.handleDateTypeChange}
              />

              <Box mt={4}>
                <Typography variant="h6" component="h6">
                  Paths
                </Typography>

                <Index
                  productRegulatoryPaths={this.state.productRegulatoryPaths}
                  handleDeleteClick={this.handleDeleteClick}
                  handleActiveClick={this.handleActiveClick}
                  handleSectorDelete={this.handleSectorDelete}
                />

              </Box>
            </LoadingContainer>

          </Box>
        </Box>
      </Fragment>
    )
  }
}

export default withSnackbar(Container)
