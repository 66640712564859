import React, { Fragment } from 'react';
import AppBar from "./AppBar"
import UserSidebar from "./UserSidebar"
import CssBaseline from '@material-ui/core/CssBaseline';
import Hidden from '@material-ui/core/Hidden';
import { makeStyles } from '@material-ui/core/styles';

const drawerWidth = 199;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex'
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    color: 'rgba(0, 0, 0, 0.87)',
    backgroundColor: '#fff'
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  title: {
    flexGrow: 0.5,
    marginLeft: '1rem',
  },
  logo: {
    flexGrow: 0.5,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    justifyContent: 'flex-end',
    minHeight: '51px',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
}));

export default function UserApp(props) {
  const classes = useStyles();

  return (
    <Fragment>
      <div className={classes.root}>
        <CssBaseline />

        <AppBar
          activeGroup={props.activeGroup}
          title={props.title}
          search={props.search}
          tabs={props.tabs}
          subTabs={props.subTabs}
          sectors={props.sectors}
          sector={props.sector}
          market={props.market}
          activeTab={props.activeTab}
          userEntity={props.userEntity}
          entity={props.entity}
          parentProduct={props.parentProduct}
          productFamily={props.productFamily}
          company={props.company}
          currentUser={props.currentUser}
          userSectors={props.userSectors}
          showSidebar={props.showSidebar}
          showSearchInput={props.showSearchInput}
          handleDrawerOpen={props.handleDrawerOpen}
          handleFavoriteClick={props.handleFavoriteClick}
          handleSearchClick={props.handleSearchClick}
          handleSearchChange={props.handleSearchChange}
          handleSearchSubmit={props.handleSearchSubmit}
          handleSectorChange={props.handleSectorChange}
          forUsers={true}
        />

        <Hidden only={['sm', 'xs', 'md']}>
          <UserSidebar
            variant='persistent'
            showSidebar={props.showSidebar}
            handleDrawerClose={props.handleDrawerClose}
            activeItem={props.activeItem}
            activeGroup={props.activeGroup}
            sector={props.sector}
            currentUser={props.currentUser}
          />
        </Hidden>

        <Hidden only={['lg', 'xl']}>
          <UserSidebar
            variant='temporary'
            showSidebar={props.showSidebar}
            handleDrawerClose={props.handleDrawerClose}
            activeItem={props.activeItem}
            activeGroup={props.activeGroup}
            sector={props.sector}
            currentUser={props.currentUser}
          />
        </Hidden>
      </div>
    </Fragment>
  );
}
