import React, { Component } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"

class DownloadIcon extends Component {
  constructor(props) {
    super(props)
  }
  
  render() {
    return(
      <svg style="width:24px;height:24px" viewBox="0 0 24 24">
          <path fill="#000000" d="M5,20H19V18H5M19,9H15V3H9V9H5L12,16L19,9Z" />
      </svg>
    )
  }
}

export default DownloadIcon
