import React from "react"
import PropTypes from "prop-types"
import { TableCell } from "../tables"
import TableSortLabel from '@material-ui/core/TableSortLabel';
import { lighten, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));


export default function TableCellSort(props) {

  const { order, orderBy, onRequestSort, ...rest } = props;

  const classes = useStyles();

  function createSortHandler(e) {
    onRequestSort(event, props.attribute.name);
  };

  const display = props.attribute.hide ? 'dn' : ''
  const className = `bg-white ${display}`
  return(
    <TableCell align={props.attribute.align ? props.attribute.align : 'left'} padding={props.attribute.checkbox ? 'checkbox' : 'default'} sortDirection={orderBy === props.attribute.name ? order : false} id={props.id} className={className} {...rest}>
      {props.attribute.noSort ?
          props.attribute.label
        :
          <TableSortLabel active={orderBy === props.attribute.name} direction={order} onClick={createSortHandler}>
          {props.attribute.label}
          {orderBy === props.attribute.name ? (
            <span className={classes.visuallyHidden}>
              {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
            </span>
          ) : null}
        </TableSortLabel>
      }
      {props.buttonComponent}
    </TableCell>
  )
}
