import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Tabs from '../../tabs/Tabs';
import Tab from '../../tabs/Tab';

class NewContainerTabs extends Component {
  constructor(props) {
    super(props)
  }
  
  render() {
    return(
      <Fragment>
        <Tabs variant="fullWidth" value={this.props.activeIndex} onChange={this.props.handleActiveIndexUpdate} className='bb b--black-10' aria-label="nav tabs">
          <Tab label='Source' />
          <Tab label='Profile' />
        </Tabs>
      </Fragment>
    )
  }
}

export default NewContainerTabs
