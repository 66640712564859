import React, { Fragment, Component } from "react"
import ReactDOM from "react-dom"
import Paper from "../../Paper"
import Box from "../../Box"
import { withSnackbar } from "../../SnackbarContainer"
import GrantsTable from "./GrantsTable"
import Filters from "./Filters"
import Typography from "@material-ui/core/Typography"
import update from "immutability-helper"

class Index extends Component {
  constructor(props) {
    super(props)
    this.handleChangePage = this.handleChangePage.bind(this)
    this.getGrants = this.getGrants.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleSearchClick = this.handleSearchClick.bind(this)
    this.handleClearSearchClick = this.handleClearSearchClick.bind(this)
    this.state = { nihGrants: this.props.nih_grants, nihGrantsCount: this.props.nih_grants_count, filterValues: { query: this.props.query, year: null, page: 0 }}
  }

  handleClearSearchClick(e) {
    let params = Object.assign({}, this.state.filterValues)

    params["query"] = null

    this.getGrants(params)
  }

  handleSearchClick(e) {
    let params = Object.assign({}, this.state.filterValues)

    this.getGrants(params)
  }

  handleChange(e) {
    let filterValues = update(this.state.filterValues, {
      [e.target.name]: {
        $set: e.target.value
      }
    })

    this.setState({
      filterValues: filterValues
    })
  }

  handleChangePage(e, newPage) {
    let params = Object.assign({}, this.state.filterValues)

    params["page"] = newPage

    this.getGrants(params)
  }

  getGrants(params) {
    $.ajax({
      type: "GET",
      url: '/admin/nih_project_grants',
      data: params,
      dataType: 'json',
      success: (data) => {
        this.setState({
          nihGrants: data.nih_grants,
          nihGrantsCount: data.nih_grants_count,
          filterValues: params
        })
      }
    })
  }

  render() {
    return(
      <Fragment>
        <Paper fullHeight>
          <Box height="100%">

            <Box>
              <Filters
                filterValues={this.state.filterValues}
                handleChange={this.handleChange}
                handleSearchClick={this.handleSearchClick}
                handleClearSearchClick={this.handleClearSearchClick}
              />
            </Box>

            <Box my={2}>
              <Typography variant="body2">
                {`${this.state.nihGrantsCount} Grants`}
              </Typography>
            </Box>

            <Box overflow='auto' height="80%">

              <GrantsTable
                nihGrants={this.state.nihGrants}
                nihGrantsCount={this.state.nihGrantsCount}
                page={this.state.filterValues.page}
                handleChangePage={this.handleChangePage}
              />

            </Box>
          </Box>
        </Paper>
      </Fragment>
    )
  }
}

export default withSnackbar(Index)
