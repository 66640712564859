import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import Paper from "../../Paper"
import Box from "../../Box"
import RecallsTable from './RecallsTable'
import { withSnackbar } from '../../SnackbarContainer'
import IndexFilters from "./IndexFilters"
import ListingsCountDisplay from '../../ListingsCountDisplay'
import update from "immutability-helper"

class IndexContainer extends Component {
  constructor(props) {
    super(props)
    this.handleSearchClick= this.handleSearchClick.bind(this)
    this.getFdaRecalls = this.getFdaRecalls.bind(this)
    this.handlePageChange = this.handlePageChange.bind(this)
    this.handleClearSearchClick = this.handleClearSearchClick.bind(this)
    this.handleFilterChange = this.handleFilterChange.bind(this)
    this.handleDeleteClick = this.handleDeleteClick.bind(this)
    this.state = { fdaRecalls: this.props.fda_recalls, fdaRecallsCount: this.props.fda_recalls_count, filterValues: { query: this.props.query, page: this.props.page, product_code: this.props.product_code, association_type: this.props.association_type }, filterOptions: { product_codes: this.props.product_codes, association_types: this.props.association_types } }
  }

  handleDeleteClick(fdaRecall) {
    $.ajax({
      type: "PUT",
      url: `/admin/fda_recalls/${fdaRecall.id}`,
      data: {
        fda_recall: {
          hide: true
        }
      },
      dataType: 'json',
      success: (data) => {
        let index = this.state.fdaRecalls.indexOf(fdaRecall)

        let fdaRecalls = update(this.state.fdaRecalls, {
          $splice: [[index, 1]]
        })

        this.setState({
          fdaRecalls: fdaRecalls
        })

        this.props.snackbarShowMessage("Recall was hidden")
      }
    })
  }

  handleFilterChange(name, value) {
    let params = Object.assign({}, this.state.filterValues)

    // params["order_by"] = this.state.orderBy
    // params["order"] = this.state.order
    params["product_code"] = value

    this.getFdaRecalls(params)
  }

  handlePageChange(e, newPage) {
    let params = Object.assign({}, this.state.filterValues)

    // params["order_by"] = this.state.orderBy
    // params["order"] = this.state.order
    params["page"] = newPage

    this.getFdaRecalls(params)
  }

  handleSearchClick(query) {
    let params = Object.assign({}, this.state.filterValues)

    params["query"] = query

    this.getFdaRecalls(params)
  }

  handleClearSearchClick(e) {
    let params = Object.assign({}, this.state.filterValues)

    params["query"] = null

    this.getFdaRecalls(params)
  }

  getFdaRecalls(params) {
    $.ajax({
      type: "GET",
      url: "/admin/fda_recalls",
      data: params,
      dataType: 'json',
      success: (data) => {
        this.setState({
          fdaRecalls: data.fda_recalls,
          fdaRecallsCount: data.fda_recalls_count,
          filterValues: params
        })
      }
    })
  }


  render() {
    return(
      <Fragment>
        <Paper fullHeight>
          <Box height="100%">
            
            <Box mb={2}>
              <IndexFilters
                filterOptions={this.state.filterOptions}
                filterValues={this.state.filterValues} 
                handleSearchClick={this.handleSearchClick}
                handleClearSearchClick={this.handleClearSearchClick}
                handleFilterChange={this.handleFilterChange}
                searchLabel="Search"
              />
            </Box>

            <ListingsCountDisplay 
              display={`${this.state.fdaRecallsCount} Recalls`}
            />

            <Box overflow="auto" height="80%">

              <RecallsTable 
                fdaRecalls={this.state.fdaRecalls}
                page={this.state.filterValues.page}
                fdaRecallsCount={this.state.fdaRecallsCount}
                handlePageChange={this.handlePageChange}
                handleDeleteClick={this.handleDeleteClick}
              />

            </Box>
          </Box>
        </Paper>
      </Fragment>
    )
  }
}

export default withSnackbar(IndexContainer)