import React, { Fragment } from "react"
import { Table, TableBody, TableHeadSort, TableHead, TableCell, TableRow } from "../../tables"
import Box from "../../Box"
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TablePaginationActions from "../../tables/TablePaginationActions"
import TrialRow from "./TrialRow"
import IconButton from "../../IconButton"
import IndeterminateCheckBoxIcon from '@material-ui/icons/IndeterminateCheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';

export default function TrialsTable(props) {
  
  let dateColumn = props.dateColumn ? props.dateColumn : 'Updated'

  return (
    <Fragment>
      <Box overflow='auto' height="80%">
        <Table size='small' stickyHeader={true} aria-label='product studies table'>

          <TableHead>
            <TableRow>
              <TableCell padding='checkbox' className='bg-white'>
                <IconButton onClick={props.handleBulkTrialCheck} tooltip='Select' edge="start">
                  {props.checkedTrials.length > 0 ? <IndeterminateCheckBoxIcon style={{height: '22px', width: '22px'}} /> : <CheckBoxOutlineBlankIcon style={{height: '22px', width: '22px'}} />}
                </IconButton>
              </TableCell>
              <TableCell className='bg-white'>Trial</TableCell>
              <TableCell className='bg-white'>Sponsor</TableCell>
              <TableCell className='bg-white'>Products</TableCell>
              <TableCell className='bg-white'>Type</TableCell>
              <TableCell className='bg-white'>Status</TableCell>
              <TableCell className='bg-white'>Site</TableCell>
              <TableCell className='bg-white'>Role</TableCell>
            </TableRow>

          </TableHead>

          {/* <TableHeadSort
            headers={[{label: <IconButton onClick={props.handleBulkTrialCheck} tooltip='Select' edge="start">{props.checkedTrials.length > 0 ? <IndeterminateCheckBoxIcon style={{height: '22px', width: '22px'}} /> : <CheckBoxOutlineBlankIcon style={{height: '22px', width: '22px'}} />}</IconButton>, name: '', noSort: true, checkbox: true }, { name: 'short_name', label: 'Trial' }, { name: 'sponsor', label: 'Sponsor' }, { name: 'short_name', label: 'Products' }, { name: 'type', label: 'Type' }, { name: 'status', label: 'Status' }, { name: "facility", label: "Site" }, { name: 'role', label: 'Role' }]}
            order={props.order}
            orderBy={props.orderBy}
            onRequestSort={props.handleRequestSort}
          /> */}

          <TableBody>
            {Object.keys(props.trials).map((trialId) =>
              <TrialRow 
                key={trialId}
                trial={props.trials[trialId]}
                sector={props.sector}
                checkedTrials={props.checkedTrials}
                handleTrialCheckboxClick={props.handleTrialCheckboxClick}
              />
            )}
          </TableBody>
          {/* {props.handleChangePage ?
            <TableFooter>
              <TablePagination
                colSpan={9}
                count={props.trialsCount}
                rowsPerPage={100}
                page={props.page}
                SelectProps={{
                  inputProps: { 'aria-label': 'rows per page' },
                  native: true,
                }}
                onChangePage={props.handleChangePage}
                onChangeRowsPerPage={props.handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableFooter>
          : ""} */}
        </Table>
      </Box>
    </Fragment>
  )
}
