import React, { useState } from "react"
import Box from "../Box"
import { List, ListItem, ListItemText } from "../lists"
import Typography from "@material-ui/core/Typography"
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction"
import IconButton from "../IconButton"
import ExpandMore from "../ExpandMore";
import ExpandLess from '../ExpandLess';
import Link from "../Link"
import StatementItem from "./StatementItem"

function StatementGroup(props) {
  const [openAll, setOpenAll] = useState(props.open)

  function handleClick(e) {
    setOpenAll(!openAll)
  }

  let companyStatement = props.companyStatements[0]
  let sourceDateDisplay = companyStatement.source_display && companyStatement.source_display.source_date ? humanDateFormat(companyStatement.source_display.source_date) : "N/A"
  return(
    <React.Fragment>
      <List>
        <ListItem button divider onClick={handleClick}>
          <ListItemText
            primary={
              <Link href={`/sectors/${props.sector.abbr}/sources/${companyStatement.source_id}`} target="_blank">
                {companyStatement.source_display ? companyStatement.source_display.source_title : "N/A"}
              </Link>}
            secondary={
              <React.Fragment>
                <Typography variant="body2" color="textSecondary">
                {companyStatement.source_display && companyStatement.source_display.source_details ? `${companyStatement.source_details} - ${sourceDateDisplay}` : sourceDateDisplay}
                </Typography>
              </React.Fragment>
            }
            className='f5'
          />
          <ListItemSecondaryAction>
            <IconButton onClick={handleClick} tooltip='expand' edge="end" aria-label="expand">
              {openAll ? <ExpandLess /> : <ExpandMore />}
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
      </List>

      {props.companyStatements.map((companyStatement) =>
        <StatementItem
          companyStatement={companyStatement}
          open={openAll}
        />
      )}
    </React.Fragment>
  )
}

export default StatementGroup
