import React, {Component} from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import TextInput from "./TextInput"
import IconButton from "./IconButton"
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

class PasswordInput extends Component {
  constructor(props) {
    super(props)
    this.handleClick = this.handleClick.bind(this)
    this.state = { type: 'password', icon: 'visibility_off' }
  }

  handleClick(e) {
    e.preventDefault()
    this.setState({
      type: (this.state.type == 'password' ? 'text' : 'password'),
      icon: (this.state.icon == 'visibility_off' ? 'visibility' : 'visibility_off')
    })
  }

  render() {
    return(
      <TextInput
        label={this.props.label}
        type={this.state.type}
        name={this.props.name}
        value={this.props.value}
        inputId='password-input'
        trailingIcon={
          <IconButton onClick={this.handleClick}>
            {this.state.icon == 'visibility_off' ? <VisibilityIcon /> : <VisibilityOffIcon />}
          </IconButton>
        }
        autoComplete={this.state.type == 'password' ? 'current-password' : 'off'}
        onChange={this.props.handleChange}
        className={this.props.className}
      />
    )
  }
}

PasswordInput.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string
}

PasswordInput.defaultProps = {
  name: 'password',
  label: 'Password'
}
// {...this.props}
export default PasswordInput
