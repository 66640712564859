import React, { Component } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Paper from "../Paper"
import { Button, RaisedButton } from "../buttons"
import Link from "../Link"
import Footer from "../Footer"
import ProductStatusMockup from "./ProductStatusMockup"
import TrialCompletionDateMockup from "./TrialCompletionDateMockup"
import Flex from "../Flex"
import Box from "../Box"
import TimelineIcon from '@material-ui/icons/Timeline';
import Typography from "@material-ui/core/Typography"
import ProductAttributeMockup from "./ProductAttributeMockup"
// import DataCollectionMockup from "./DataCollectionMockup"
// import ProductAttributesMockup from "./ProductAttributesMockup"
import continuousUpdatesImage from "../../../assets/images/24_7_updates_images.png"
import sourcesImage from "../../../assets/images/sources_image.png"
import hubAndSpoke from "../../../assets/images/hub_and_spoke.png"
import interconnectedSections from "../../../assets/images/interconnected_sections.png"
import productCompareFeature from "../../../assets/images/product_compare_feature.png"
import productStatusFeature from "../../../assets/images/product_status_feature.png"
import trialCompletionDateChangesFeature from "../../../assets/images/trial_completion_date_changes_feature.png"
import productFeaturesMockup from "../../../assets/images/product_features_mockup.png"

export default class Home extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    let headerSize = "h3"
    return (
      <div className='w-100'>
        <div>
          <div className='w-90-m w-100 center z-5'>
            <div className='dtc-m dtc-l v-mid w-100 w-50-m w-50-l ph4'>
              <Typography variant={headerSize} className='mv3 fw5'>
                Connect the dots of medtech product development
              </Typography>
              <div className='pb3 pb4-m pb4-l'>
                <Typography className='mt0 fw5 lh-copy f4 measure pr5-l mb4'>
                  <p>Our pipeline services organize and track medtech product development from early stages to market. Everything that’s important to a product’s development is connected, organized, and made accessible through our online system.</p>

                  <p>CVPipeline has been the standard in coronary markets for over 15 years,  providing detailed coverage of thousands of product technologies in Afib, CHF, Valves, CAD, CRM, and HTN.</p>

                  <p>See our newest generation service lines in peripheral vascular and aortic therapies, and stay tuned for upcoming releases in new service areas.</p>

                  {/* <p>
                    With product offerings in:
                    <ul>
                      <li>
                        Coronary
                      </li>
                      <li>
                        Peripheral
                      </li>
                      <li>
                        Aortic
                      </li>
                      <li>
                        Ventricular Tacchycardia
                      </li>
                      <li>
                        And many more...
                      </li>
                    </ul>
                  </p> */}
                </Typography>
                <div className='flex-ns items-center'>
                  <div className='w-40 w-100-xs'>
                    <RaisedButton href="/contact_us" className='w-100 white' style={{height: '48px'}}>
                      Request Demo
                    </RaisedButton>
                  </div>

                  {/* <div className='ml4'>
                    <Button href="/contact_us" component='a'>
                      Contact us
                    </Button>
                  </div> */}
                </div>
              </div>
            </div>
            <div className='dn dtc-m dtc-l v-mid pl4 pr3 w-45'>
              <img src={hubAndSpoke} alt="image" style={{width: '500px'}} />
            </div>
          </div>
        </div>
        {/* <div className='bg-lightest-gray dn-xs'>
          <div className='mh4'>
            <div className='tc pt4 nt2'>
              <Headline5 className='fw5 mt0 mb1'>
                From the team at cvPipeline, the leading coronary R&D pipeline data provider to the industry for the last 15 years.
              </Headline5>
            </div>
          </div>
        </div> */}

        <div className='w-100 bg-white'>
          <div className='w-90 center pv4'>

            <div className='dn dtc-m dtc-l v-mid pl4 pr3 w-45'>
              <img src={sourcesImage} alt="image" />
            </div>


            <div className='dtc-m dtc-l v-mid w-100 w-50-m w-50-l ph4'>
              <Typography variant={headerSize} className='mv3 fw5'>
                Everything you need in one place
              </Typography>
              <div className='pb3 pb4-m pb4-l'>
                <Typography className='mt0 fw5 lh-copy f4 measure pr5-l mb4'>
                  {/* Offering products in Coronary, Peripheral and Aortic */}
                  <p>
                    There has never been more information available; the problem is integration, organization, and accessibility.
                  </p>

                  <p>
                    Our system lets you spend more time analyzing and understanding what the data mean, instead of figuring out how to get the data.
                  </p>

                  <p>
                    All of our data sources are “public.” We reference everything, and link you to the original sources.
                  </p>
                </Typography>
              </div>
            </div>

          </div>
        </div>

        <div className='w-100 bg-near-white'>
          <div className='w-90 center pv4'>

            <div className='dtc-m dtc-l v-mid w-100 w-40-m w-40-l ph4'>
              <Typography variant={headerSize} className='mv3 fw5'>
                Comprehensive and detailed, with intuitive navigation
              </Typography>
              <div className='pb3 pb4-m pb4-l'>
                <Typography className='mt0 fw5 lh-copy f4 measure pr5-l mb4'>
                  <p>
                    We do more than just integrate the information: we make the information accessible and useful in your workflow.
                  </p>
                </Typography>
              </div>
            </div>

            <div className='dn dtc-m dtc-l v-mid pl4 pr3 w-60'>
              <img src={interconnectedSections} alt="image" />
            </div>

          </div>
        </div>

        <div className='w-100 bg-white'>
          <div className='w-90-m w-100 center pv4'>
            <div className='dtc-m dtc-l v-mid w-100 w-50-m w-40-l ph4 pv3'>

              <div className='mb5-m mb5-l mb4'>
                {/* f3 f2-m f2-l  */}
                <Typography variant={headerSize} className='fw5 mt0 mb3 tc'>
                  With specialized features that let you...
                </Typography>

              </div>

              <Flex alignItems='center' justifyContent='space-between'>
                <Box width={1/4}>
                  <Box mb={2} textAlign='center'>
                    <Typography variant="body1">
                      See the big picture
                    </Typography>
                    {/* <Typography variant="body1" style={{fontSize: '1.25rem'}}>
                      Understand where products are in the regulatory path
                    </Typography> */}
                  </Box>


                  <Box>
                    {/* <ProductStatusMockup products={this.props.products} /> */}
                    <img src={productStatusFeature} alt="image" />
                  </Box>
                </Box>

                <Box width={1/4}>
                  <Box textAlign='center' mb={2}>
                    <Typography variant="body1">
                      Drill down to important details
                    </Typography>
                    {/* <Typography variant="body1" style={{fontSize: '1.25rem'}}>
                      View the granular product data and compare across products
                    </Typography> */}
                  </Box>


                  <Box>
                    {/* <ProductStatusMockup products={this.props.products} /> */}
                    <img src={productCompareFeature} alt="image" />
                  </Box>
                </Box>

                <Box width={1/4}>
                  <Box mb={2} textAlign='center'>
                    <Typography variant="body1">
                      Find insights and draw conclusions
                    </Typography>
                    {/* <Typography variant="body1" style={{fontSize: '1.25rem'}}>
                      Understand the progress of a trial on key data points
                    </Typography> */}
                  </Box>

                  <Box>
                    <img src={trialCompletionDateChangesFeature} alt="image" />
                  </Box>
                </Box>
              </Flex>

              {/* <Box mt={10} width={3/4} mx='auto'>
                <Box>
                  <Box ml={6} textAlign='center' mb={3}>
                    <Typography variant="body1">
                      Drill down to important details
                    </Typography>
                    <Typography variant="body1" style={{fontSize: '1.25rem'}}>
                      View the granular product data and compare across products
                    </Typography>
                  </Box>

                  <Box>
                    <ProductAttributeMockup />
                  </Box>
                </Box>
              </Box> */}

            </div>
          </div>
        </div>

        <div className='w-100 bg-near-white'>

          <div className='w-80-m w-100 center pv5'>

            <div className='dn dtc-m dtc-l v-mid pl4 pr3 w-45'>
              <img src={productFeaturesMockup} alt="image" />
            </div>

            <div className='dtc-m dtc-l v-mid w-100 w-50-m w-50-l ph4'>
              <Typography variant={headerSize} className='mv3 fw5'>
                Specialized data, customized views
              </Typography>
              <div className='pb3 pb4-m pb4-l'>
                <Typography className='mt0 fw5 lh-copy f4 measure pr5-l mb4'>
                  <p>
                    Whether your perspective is clinical, technological, regulatory, marketing, or business, our databases will help you see all of the relevant parts of the picture.
                  </p>
                </Typography>
              </div>
            </div>
          </div>
        </div>

        <div className='w-100 bg-white'>
          <div className='w-80-m w-100 center pv5'>

            <div className='mb4-m mb4-l mb3'>
              <Typography variant={headerSize} className='fw5 mt0 mb3 tc'>
                24/7 Monitoring
              </Typography>

              <div className='tc'>
                <Typography variant={headerSize} className='lh-copy f4 mt0'>
                  Our system operates continuously
                </Typography>
              </div>
            </div>

            <Flex alignItems='center' justifyContent='center'>

              <Box className='measure' width={1/2}>
                <Typography className='fw4 lh-copy f4'>
                  <li>Databases are updated automatically</li>
                </Typography>
                <Typography className='fw4 lh-copy f4'>
                  <li>Email alerts make sure you know when something important has changed</li>
                </Typography>
              </Box>

              <Box width={1/3}>
                <img src={continuousUpdatesImage} alt='sources' />
              </Box>

            </Flex>
          </div>
        </div>


        <div className='w-100 bg-near-white'>
          <div className='w-90-m w-100 center pv5 ph4'>

            <div className='mb4'>
              {/* f3 f2-m f2-l */}
              <Typography variant={headerSize} className='fw5 mt0 mb3 tc'>
                Contact Us
              </Typography>

              <div className='tc'>
                <div className='measure'>
                  <Typography className='lh-copy f4 mt0'>
                    Don't see what you're looking for? Contact us to discuss alternatives for customized monitoring of smaller or more specialized markets.
                  </Typography>
                </div>
              </div>
            </div>

            <div className='w-100 ph4 pb3 tc'>

              <RaisedButton href="/contact_us" className='w-25 white' style={{height: '48px'}}>
                Contact Us
              </RaisedButton>

            </div>
          </div>
        </div>

        <footer className='bt b--black-40'>
          <Footer year={this.props.year} />
        </footer>
      </div>
    )
  }
}
