import React, { Component, Fragment } from "react"
import PropTypes from "prop-types"
import ReactDOM from "react-dom"
import { ListItem, ListItemText } from '../lists';
import Box from "../Box"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import IconButton from "../IconButton"
import ExpandLess from '../ExpandLess';
import ExpandMore from '../ExpandMore';
import Button from "../buttons/Button"

export default class AlertGroupHeader extends Component {
  constructor(props) {
    super(props)
  }

  render () {
    return (
      <ListItem className='items-baseline mb0'>
        <ListItemIcon>
          <Button onClick={this.props.handleClick} color='default' lowercase={true} className='f5'>
            {this.props.open ? <ExpandLess /> : <ExpandMore />}
            <span className='pl2'>{this.props.alertGroup}</span>
          </Button>
        </ListItemIcon>

        {/* <ListItemText primary={this.props.alertGroup} /> */}

        <ListItemText
          secondary={`(${this.props.alerts.length})`}
          className='ml-auto flex-none'
        />

        {/* <ListItemIcon>
          <IconButton>
            {this.props.open ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
        </ListItemIcon> */}
      </ListItem>
    );
  }
}
