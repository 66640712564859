import React from "react"
import TableCell from "../tables/TableCell"
import Link from "../Link"
import Flex from "../Flex"
import Box from "../Box"
import CloseIcon from "@material-ui/icons/Close"
import IconButton from "../IconButton"

export default function EntityHeader(props) {
  
  function handleEntityRemovalClick(e) {
    props.handleEntityRemovalClick(props.entity)
  }
  
  return (
    <TableCell borderRight={true} className='pr2 bg-white'>
      <Flex alignItems='center'>
        
        <Box width={4/5}>
          <div>
            <Link href={`/sectors/${props.sector.abbr}/${props.entityType}/${props.entity.id}`}>
              {props.entity.name}
            </Link>
          </div>
          {props.entityType == 'products' ? 
            <em className='f6'>{props.entity.company}</em> 
          : ''}
        </Box>
        
        <Box ml="auto">
          {props.activeEntity && props.entity.id == props.activeEntity.id ? 
            '' 
          : 
            <IconButton onClick={handleEntityRemovalClick} size='small'>
              <CloseIcon fontSize='small' />
            </IconButton>
          }
        </Box>
        
      </Flex>
    </TableCell>
  )
}
