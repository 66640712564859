import React, { Component, Fragment } from "react"
import TextInput from "../../TextInput"
import { RaisedButton } from "../../buttons"
import Typography from '@material-ui/core/Typography';
import Flex from "../../Flex"
import Box from "../../Box"
import Checkbox from "../../Checkbox"

export default class SearchTermForm extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    return(
      <Fragment>

        <Flex alignItems='center'>
          <Box width={1/2}>
            <Box>
              <Box width={3/4} pr={2}>
                <TextInput
                  name='search_term'
                  label='Search term'
                  value={this.props.trialSearchTerm.search_term}
                  onChange={this.props.handleTermChange}
                  fullWidth
                />
              </Box>

              <Box>
                <Flex alignItems='center'>
                  <Box>
                    <Checkbox
                      name='case_sensitive'
                      label='Case sensitive'
                      checked={this.props.trialSearchTerm.case_sensitive}
                      onClick={this.props.handleCheckboxClick}
                    />
                  </Box>

                  {/* <Box>
                    <Checkbox
                      name='root_name'
                      label='Root name'
                      checked={this.props.trialSearchTerm.root_name}
                      onClick={this.props.handleCheckboxClick}
                    />
                  </Box> */}
                </Flex>
              </Box>

              <Flex mt={3} width={1/2}>
                <Box mr={3} width={1/2}>
                  <RaisedButton onClick={this.props.handleSaveClick}>
                    Save
                  </RaisedButton>
                </Box>

                <Box ml={3} width={1/2}>
                  <RaisedButton onClick={this.props.handleTestClick} color='default'>
                    Test
                  </RaisedButton>
                </Box>

              </Flex>
            </Box>
          </Box>

          <Box width={1/2}>
            <Box>
              {["acronym", "short_name", "full_name"].map((trialName) =>
                <Box key={trialName} pb={2}>
                  <Typography variant="body2">
                    {trialName.replace("_", " ")}
                  </Typography>
                  <Box>
                    {this.props.trialDataView[trialName] ? this.props.trialDataView[trialName] : 'N/A'}
                  </Box>
                </Box>
              )}
            </Box>
            {/* <Typography variant="h6" component="h6">
              Rules
            </Typography>

            <Box>
              <List>
                <ListItem>
                  <ListItemText primary="If the acronym is a unique enough word, that can be used" />
                </ListItem>
                <ListItem>
                  <ListItemText primary="Use keywords from trial name" secondary='e.g., Excluder AND AAA' />
                </ListItem>
                <ListItem>
                  <ListItemText primary="If the term is multiple words, add quotes around the phrase" secondary='e.g., "Natural Vascular Scaffolding"' />
                </ListItem>
                <ListItem>
                  <ListItemText primary="Some terms take priority over others, you can use parantheses to set priority" secondary='e.g., Excluder AND (TAMBE OR Thoracoabdominal)' />
                </ListItem>
                <ListItem>
                  <ListItemText primary="When using AND/OR/NOT they must be capitalized" secondary='e.g., Lutonix NOT (035 OR 014 OR 018)' />
                </ListItem>
                <ListItem>
                  <ListItemText primary="Before saving, test the search term and view the results below" />
                </ListItem>
                <ListItem>
                  <ListItemText primary="Once the search term is saved, the process will run and the predictions and associations will be made" />
                </ListItem>
              </List>
            </Box> */}
          </Box>
        </Flex>

      </Fragment>
    )
  }
}
