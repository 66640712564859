import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Flex from "../../Flex"
import Box from "../../Box"
import { Tabs, Tab } from "../../tabs"
import { Table, TableCell, TableRow, TableBody, TableHead, EmptyDataTableRow, TableHeadSort } from "../../tables"
import Paper from "../../Paper"
import StagedAssociatorRow from "./StagedAssociatorRow"
import { withSnackbar } from "../../SnackbarContainer"
import update from "immutability-helper"
import Typography from "@material-ui/core/Typography"
import StagedAssociatorFilters from "./StagedAssociatorFilters"

class StagedAssociator extends Component {
  constructor(props) {
    super(props)
    this.handleDeleteClick = this.handleDeleteClick.bind(this)
    this.handleRequestSort = this.handleRequestSort.bind(this)
    this.desc = this.desc.bind(this)
    this.stableSort = this.stableSort.bind(this)
    this.getSorting = this.getSorting.bind(this)
    this.handleFilterChange = this.handleFilterChange.bind(this)
    this.handleDateChange = this.handleDateChange.bind(this)
    this.getPatents = this.getPatents.bind(this)
    this.handleNotRelevantClick = this.handleNotRelevantClick.bind(this)
    this.handleActiveIndexUpdate = this.handleActiveIndexUpdate.bind(this)
    this.state = { patents: this.props.patents, sector_id: this.props.sector_id, date: this.props.date, kind: this.props.patent_kind, priority: this.props.priority, priorities: this.props.priorities, order: 'desc', orderBy: 'date', lastDeleted: null, activeIndex: 'high_priority', work_type: this.props.work_type }
  }

  handleActiveIndexUpdate(e, newValue) {
    $.ajax({
      type: 'GET',
      url: `/admin/patents/staged_associator`,
      data: {
        type: newValue
      },
      dataType: 'json',
      success: (data) => {
        this.setState({
          activeIndex: newValue,
          patents: data.patents
        })
      }
    })
  }

  handleFilterChange(name, value) {
    let filters = { date: this.state.date, kind: this.state.kind, priority: this.state.priority, sector_id: this.state.sector_id, work_type: this.state.work_type, type: this.state.activeIndex }

    filters[name] = value

    this.getPatents(filters)
  }

  handleDateChange(date) {
    let filters = { date: this.state.date, sector_id: this.state.sectorId, priority: this.state.priority, kind: this.state.kind, type: this.state.activeIndex, work_type: this.state.work_type }

    filters['date'] = date

    this.getPatents(filters)
  }

  getPatents(filters) {
    $.ajax({
      type: 'GET',
      url: `/admin/patents/staged_associator`,
      data: filters,
      dataType: 'json',
      success: (data) => {
        this.setState({
          patents: data.patents,
          sector_id: filters.sector_id,
          date: filters.date,
          kind: filters.kind,
          priority: filters.priority,
          work_type: filters.work_type,
          type: filters.type
        })
      },
      error: (data) => {
        this.props.snackbarShowMessage("There was an error fetching the patents. Please try again.", "error")
      }
    })
  }

  handleNotRelevantClick(patent) {
    $.ajax({
      type: 'PUT',
      url: `/admin/patents/${patent.id}`,
      data: {
        patent: {
          relevant: false
        }
      },
      dataType: 'json',
      success: (data) => {
        const index = this.state.patents.indexOf(patent)

        const patents = update(this.state.patents, {
          $splice: [[index,1]]
        })

        this.setState({
          patents: patents,
          lastDeleted: patent
        })
        this.props.snackbarShowMessage("Patent has been marked as not relevant")
      },
      error: (data) => {
        this.props.snackbarShowMessage("Patent could not be marked as not relevant. Please notify development in slack.", "error")
      }
    })
  }

  handleDeleteClick(patent) {
    $.ajax({
      type: 'PUT',
      url: `/admin/patent_companies/${patent.patent_company_id}`,
      data: {
        patent_company: {
          deleted: true, deleted_by: this.props.current_user.id
        }
      },
      dataType: 'json',
      success: (data) => {
        const index = this.state.patents.indexOf(patent)
        const patents = update(this.state.patents, {
          $splice: [[index, 1]]
        })

        this.setState({
          patents: patents,
          lastDeleted: patent
        })
        this.props.snackbarShowMessage("Patent has been unassociated with the company")
      },
      error: (data) => {
        this.props.snackbarShowMessage("Patent could not be unassociated with the company. Notify development in slack.", "error")
      }
    })
  }

  desc(a, b, orderBy) {
    let bOrderKey = b[orderBy]
    let aOrderKey = a[orderBy]
    if (bOrderKey < aOrderKey) {
      return -1;
    }
    if (bOrderKey > aOrderKey) {
      return 1;
    }
    return 0;
  }

  stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = cmp(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
  }

  getSorting(order, orderBy) {
    return order === 'desc' ? (a, b) => this.desc(a, b, orderBy) : (a, b) => -this.desc(a, b, orderBy);
  }

  handleRequestSort(event, property) {
    const isDesc = this.state.orderBy === property && this.state.order === 'desc';

    this.setState({
      order: isDesc ? 'asc' : 'desc',
      orderBy: property
    })
  };

  render() {
    let headers = [{ name: 'html_patent_no', label: '#', borderRight: true }, { name: 'title', label: 'Title', borderRight: true }, { name: 'assignee', label: 'Assignee', borderRight: true }, { name: 'applicant', label: 'Applicant', borderRight: true }, { name: 'created_at', label: 'Date', borderRight: true }, { name: 'rank', label: 'Rank', borderRight: true }, { label: 'Sector', value: 'sector_id' }, { name: '', label: 'Hide', borderRight: true }]
    return(
      <Fragment>

        <Paper fullHeight>

          <Box mb={2}>
            <Tabs variant="fullWidth" value={this.state.activeIndex} onChange={this.handleActiveIndexUpdate} className='bb b--black-10' aria-label="nav tabs">
              <Tab label='Company Associations' value='high_priority' />
              <Tab label='All' value='all' />
              <Tab label='Hidden' value='hidden' />
            </Tabs>
          </Box>

          <Box height="100%">

            <Box mb={2}>
              <StagedAssociatorFilters
                patentKinds={this.props.patent_kinds}
                kind={this.state.kind}
                date={this.state.date}
                sectorId={this.state.sector_id}
                sectors={this.props.sectors}
                priority={this.state.priority}
                work_type={this.state.work_type}
                priorities={this.props.priorities}
                activeIndex={this.state.activeIndex}
                handleFilterChange={this.handleFilterChange}
                handleDateChange={this.handleDateChange}
              />
            </Box>

            <Box textAlign='right'>
              <Typography variant="caption">
                {`${this.state.patents.length} patents`}
              </Typography>
            </Box>

            <Box overflow='auto' height="80%">
              <Table size='small' aria-label='patents table' stickyHeader={true} id='patents-table'>
                <TableHeadSort
                  headers={headers}
                  order={this.state.order}
                  orderBy={this.state.orderBy}
                  onRequestSort={this.handleRequestSort}
                />

                <TableBody>
                  {this.state.patents.length > 0 ?
                    this.stableSort(this.state.patents, this.getSorting(this.state.order, this.state.orderBy)).map((patent) =>
                    <StagedAssociatorRow
                      key={patent.patent_id}
                      patent={patent}
                      handleRelevantClick={this.handleRelevantClick}
                      handleDeleteClick={this.handleDeleteClick}
                      handleNotRelevantClick={this.handleNotRelevantClick}
                    />
                  ) : <EmptyDataTableRow colSpan={9} message='No patents to review' />}
                </TableBody>
              </Table>
            </Box>
          </Box>

        </Paper>
      </Fragment>
    )
  }
}

export default withSnackbar(StagedAssociator)
