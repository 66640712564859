import React, { Component } from "react"
import { Card, CardSection, CardSectionHeader } from "../cards"
import Box from "../Box"
import Flex from "../Flex"
import Link from "../Link"
import { RaisedButton } from "../buttons"
import Typography from "@material-ui/core/Typography"

export default class AbstractCard extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    const companyUrl = `/sectors/${this.props.sector.abbr}/companies/${this.props.company.id}`
    return (
      <Card>
        <CardSection noBorder>
          <Box mb={2}>
            <Typography variant="subtitle1">
              <Link href={`${companyUrl}/investor_presentations`} className="f6">
                Back to Investor Presentations
              </Link>
            </Typography>
          </Box>

          <Box my={2}>
            <div className='b'>Company</div>
            <div> 
              <Link href={companyUrl}>
                {this.props.company.short_name}
              </Link>
            </div>
          </Box> 

          <Box mb={2}>
            <div className='b'>Date</div>
            <Box>
              {humanDateFormat(this.props.investorPresentation.date)}
            </Box>
          </Box>


        </CardSection>
      </Card>
    )
  }
}
