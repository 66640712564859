import React from "react"
import MuiList from '@material-ui/core/List';

export default function List(props) {
  return (
    <MuiList {...props}>
      {props.children}
    </MuiList>
  )
}
