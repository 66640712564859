import React from 'react';
import Typography from "@material-ui/core/Typography"
import Button from "@material-ui/core/Button"

export default function EventTypeFilter(props) {
  const { eventType, handleEventTypeClick } = props;

  function handleClick(e) {
    e.preventDefault();
    handleEventTypeClick(eventType);
  }

  function renderButton(applicant) {
    return (
      <Button onClick={handleClick} size='small'>
        <Typography variant="body2" color="textSecondary">
          {eventType}
        </Typography>
      </Button>
    )
  }

  function renderSpan(eventType) {
    return (
      <Typography variant="body2" color="textSecondary">
        {eventType}
      </Typography>
    )
  }

  return(
    <React.Fragment >
      {handleEventTypeClick ? renderButton(eventType) : renderSpan(eventType)}
    </React.Fragment>
  )
}