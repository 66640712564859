import React from "react"
import PropTypes from "prop-types"
import Paper from "../../Paper"
import Box from "../../Box"
import Select from "../../Select"
import CKEditor from 'ckeditor4-react';
import { Button, RaisedButton } from "../../buttons"
import Grid from '@material-ui/core/Grid';
import update from "immutability-helper"
import Snackbar from "../../Snackbar"
import DatePicker from "../../DatePicker"
import SearchDropdown from "../../SearchDropdown"


export default function Form(props) {
  return (
    <React.Fragment>

      <Grid container direction="row" justify="center" alignItems="center" spacing={6}>

        <Grid item>
          <Paper>

            <Box mb={2}>
              <Select
                value={props.alert.sector_id}
                name='sector_id'
                label='Sector'
                entities={props.sectors}
                handleChange={props.handleSectorChange}
              />
            </Box>

            <Box mb={2}>
              <Select
                value={props.alert.alert_group_id}
                name='alert_group_id'
                label='Alert group'
                entities={props.alert_groups}
                handleChange={props.handleDropdownChange}
              />
            </Box>

            <Box>
              <DatePicker
                name='date'
                label='Date'
                handleChange={(value, date) => { props.handleDateChange(value, date) }}
                value={props.alert.date}
              />
            </Box>

            <Box mt={2}>
              <SearchDropdown
                value={props.company_id}
                inputName='company_id'
                label='Companies'
                options={props.companies}
                handleChange={props.handleCompanyChange}
              />
            </Box>

            <Box mt={2}>
              <SearchDropdown
                value={props.product_id}
                inputName='product_id'
                label='Products'
                options={props.products}
                handleChange={props.handleProductChange}
              />
            </Box>

            <Box mt={2}>
              <SearchDropdown
                value={props.trial_id}
                inputName='trial_id'
                label='Trials'
                options={props.trials}
                handleChange={props.handleTrialChange}
              />
            </Box>
          </Paper>
        </Grid>

        <Grid item>
          <Box>
            <CKEditor
              data={props.alert.body}
              onChange={props.handleChange}
            />
          </Box>
        </Grid>
      </Grid>

      <Box mt={4} width={1/4} mx='auto'>
        <RaisedButton handleClick={props.handleSaveClick}>
          Save
        </RaisedButton>
      </Box>
    </React.Fragment>
  );
}
