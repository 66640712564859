import React, { PureComponent } from 'react'
import ReactDiffViewer from 'react-diff-viewer'

class TextDiff extends PureComponent {
  render = () => {
    return (
      <ReactDiffViewer {...this.props} />
    )
  }
}

export default TextDiff
