import React, { Fragment } from "react"
import Paper from "../Paper"
import FamilyTimeline from "./FamilyTimeline"
import Tabs from "../tabs/Tabs"
import Tab from "../tabs/Tab"
import Box from "../Box"
import FamilyList from "./FamilyList"

export default function FamilyTimelineContainer(props) {
  return(
    <Fragment>

      <FamilyTimeline
        key={`patent-timeline-chart-${props.chartData.length}`}
        title='Timeline'
        exportingTitle={`${props.patent.title}`}
        chartId='patent-timeline-chart'
        chartData={props.chartData}
        currentTime={props.currentTime}
      />

      <Tabs centered value={0} className='bb b--black-10'>
        <Tab label='Data' />
      </Tabs>

      <Box mt={3}>
        <FamilyList
          patents={props.chartData}
          sector={props.sector}
        />
      </Box>

    </Fragment>
  )
}
