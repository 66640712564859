import React, { Component } from "react"
import PropTypes from "prop-types"
import ReactDOM from "react-dom"
import Chart from "../charts/Chart"

export default class ActivityChart extends Component {
  constructor(props) {
    super(props)
  }
  
  setChartTitle() {
    let chartTitle = ""
    switch (this.props.chartEntityType) {
      case 'person_manuscript':
        chartTitle = "Articles by Journal"
        break;
      case 'person_trial': 
        chartTitle = "Trials by Sponsor"
        break;
      case 'person_abstract':
        chartTitle = "Presentations by Conference"
        break;
    }
    return(chartTitle)
  }
  
  setYAxisTitle() {
    let yAxisTitle = ""
    switch (this.props.chartEntityType) {
      case 'person_manuscript':
        yAxisTitle = "# of Articles"
        break;
      case 'person_trial': 
        yAxisTitle = "# of Trials"
        break;
      case 'person_abstract':
        yAxisTitle = "# of presentations"
        break;
    }
    return(yAxisTitle)
  }
  
  render () {
    let chartTitle = this.setChartTitle()
    let yAxisTitle = this.setYAxisTitle()
    return (
      <React.Fragment>
        <Chart 
          key={this.props.chartData} 
          chartId='network-chart' 
          chartOptions={{type: this.props.chartDisplayType.toLowerCase(), height: 300}} 
          categories={this.props.categories} 
          title={chartTitle} 
          data={this.props.chartData} 
          simple={true} 
          yAxisTitle={yAxisTitle} 
          legend={false}
        />
      </React.Fragment>
    );
  }
}
