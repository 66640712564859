import React, { useRef, useState } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

const YearRangeFilter = (props) => {
    const { years } = props;

    // const allYears = years.map(year => year.name);
    const [selectedRange, setSelectedRange] = useState({ start: years[0].name, end: years[years.length - 1].name });

    const chartRef = useRef(null);

    const handleDrag = (e) => {
        const chart = chartRef.current.chart;
        const xAxis = chart.xAxis[0];
        const year = xAxis.toValue(e.xAxis[0].value);

        if (e.target.graphic.symbolName === 'triangle-left') {
            setSelectedRange(prev => ({ start: Math.round(year), end: prev.end }));
        } else if (e.target.graphic.symbolName === 'triangle-right') {
            setSelectedRange(prev => ({ start: prev.start, end: Math.round(year) }));
        }

        chart.series[0].setData(
            years.map(y => ({
                y: y.count,
                color: y.name >= selectedRange.start && y <= selectedRange.end ? '#82ca9d' : '#8884d8',
            }))
        );
    };

    const options = {
        chart: {
            type: 'column',
            animation: false, // Disable animations for smoother dragging
            events: {
                redraw: function() {
                    const chart = this;
                    const xAxis = chart.xAxis[0];
                    
                    const startPixel = xAxis.toPixels(selectedRange.start);
                    const endPixel = xAxis.toPixels(selectedRange.end);
                    
                    const leftHandle = chart.renderer.symbol('triangle-left', startPixel - 10, chart.plotHeight - 10, 20, 20).attr({
                        zIndex: 6,
                        fill: '#f45b5b',
                        cursor: 'ew-resize',
                        'stroke-width': 1,
                        stroke: 'black',
                    }).add();
                    
                    leftHandle.element.addEventListener('mousedown', (e) => handleMouseDown(e, 'left'));
                    
                    const rightHandle = chart.renderer.symbol('triangle-right', endPixel - 10, chart.plotHeight - 10, 20, 20).attr({
                        zIndex: 6,
                        fill: '#f45b5b',
                        cursor: 'ew-resize',
                        'stroke-width': 1,
                        stroke: 'black',
                    }).add();                    
                },
            },
        },
        title: {
            text: `Selected Range: ${selectedRange.start} - ${selectedRange.end}`,
        },
        xAxis: {
            categories: years.map(year => year.name),
            crosshair: true,
        },
        yAxis: {
            min: 0,
            title: {
                text: null,
            },
            labels: {
                enabled: false,
            },
            gridLineWidth: 0,
        },
        tooltip: {
            enabled: false,
        },
        plotOptions: {
            column: {
                pointPadding: 0.2,
                borderWidth: 0,
            },
        },
        series: [
            {
                name: 'Years',
                data: years.map(year => ({
                    y: year.count,
                    color: year.name >= selectedRange.start && year <= selectedRange.end ? '#82ca9d' : '#8884d8',
                })),
            },
        ],
    };

    return <HighchartsReact highcharts={Highcharts} options={options} ref={chartRef} />;
};

export default YearRangeFilter;
