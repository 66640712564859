import React, { Fragment } from "react"
import Tabs from '../../tabs/Tabs';
import Tab from '../../tabs/Tab';

export default function CharacteristicStatusTabs(props) {
  return(
    <Fragment>
      <Tabs variant="fullWidth" value={props.activeIndex} onChange={props.handleIndexUpdate} className='bb b--black-10' aria-label="characteristic status tabs">
        <Tab label='Approved' value={0} />
        <Tab label='Investigational' value={1000000} />
      </Tabs>
    </Fragment>
  )
}
