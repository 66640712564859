import React from 'react'
import { TableCell, TableRow } from '../../../tables'
import FdaLink from '../../510_k_clearances/FdaLink'
import { RaisedButton } from "../../../buttons"
import DeleteButton from "../../DeleteButton"
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import MuiIconButton from '@material-ui/core/IconButton';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import FilterChip from "../../../filters/FilterChip"
import Box from "../../../Box"
import Link from '../../../Link'

export default function AssociatedRow(props) {

  function handleDeleteClick(e) {
    props.handleDeleteClick(props.kClearances[0])
  }

  function handlekClearanceCheckboxClick(e) {
    props.handlekClearanceCheckboxClick(props.kClearances[0])
  }

  const selected = false // props.checkedkClearances.includes(props.kClearance.id)

  let kClearance = props.kClearances[0]

  return (
    <TableRow>
      <TableCell>
        <MuiIconButton onClick={handlekClearanceCheckboxClick} edge="start">
          {selected ? <CheckBoxIcon color='primary' style={{height: '22px', width: '22px'}} /> : <CheckBoxOutlineBlankIcon style={{height: '22px', width: '22px'}} />}
        </MuiIconButton>
      </TableCell>
      <TableCell>
        <FdaLink 
          fdaKClearance={kClearance}
          linkText={kClearance.k_number}
        />
      </TableCell>
      <TableCell className="measure-wide">
        <Link href={`/admin/fda_k_clearances/${kClearance.id}/associator`}>
          {kClearance.device_name}
        </Link>
      </TableCell>
      <TableCell className="measure-wide">{kClearance.applicant}</TableCell>
      <TableCell>{kClearance.product_code}</TableCell>
      <TableCell>{kClearance.date_received}</TableCell>
      <TableCell>
        {props.kClearances.map((kClearance) => {
          return(
            <Box mb={1} key={kClearance.k_clearance_sector_id}>
              <FilterChip
                name={kClearance.sector}
                handleDelete={() => { props.handleSectorDelete(kClearance.k_clearance_sector_id) }}
              />
            </Box>
          )
        })}
      </TableCell>
      <TableCell>
        <DeleteButton onClick={handleDeleteClick} />
      </TableCell>
    </TableRow>
  )
}
