import React, { Component, Fragment } from "react"
import Paper from "../../../Paper"
import Flex from "../../../Flex"
import Box from "../../../Box"
import Typography from "@material-ui/core/Typography"
import FollowsTable from "./FollowsTable"
import update from "immutability-helper"

class Follows extends Component {
  constructor(props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
    this.handleDeleteClick = this.handleDeleteClick.bind(this)
    this.state = { follows: this.props.follows, followsCount: this.props.follows_count }
  }

  handleChange(e) {
    this.setState({
      query: e.target.value
    })
  }

  handleDeleteClick(follow) {
    $.ajax({
      type: "PUT",
      url: `/admin/twitter_follows/${follow.id}`,
      data: {
        twitter_follow: {
          deleted: true, deleted_by: this.props.user.id
        }
      },
      dataType: 'json',
      success: (data) => {
        let index = this.state.follows.indexOf(follow)

        let follows = update(this.state.follows, {
          $splice: [[index, 1]]
        })

        let followsCount = this.state.followsCount - 1

        this.setState({
          follows: follows,
          followsCount: followsCount
        })
      }
    })
  }

  render() {
    return(
      <Fragment>

        <Paper fullHeight>

          <Box ml="auto">
            <Typography variant="body2">
              {`${this.state.followsCount} Follows`}
            </Typography>
          </Box>

          <Box overflow="auto" height="90%">

            <FollowsTable
              follows={this.state.follows}
              handleDeleteClick={this.handleDeleteClick}
            />

          </Box>
        </Paper>

      </Fragment>
    )
  }
}

export default Follows
