import React, { Component } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Link from "../../Link.jsx"
import { TableRow, TableCell } from "../../tables"
import EditButton from "../EditButton"

export default class ReviewerRecord extends Component {
  constructor(props) {
    super(props)
  }

  render () {
    const editUrl = `/admin/products/${this.props.product.id}/edit`
    return(
      <TableRow>
        <TableCell>
          <Link href={`/sectors/Peripheral/products/${this.props.product.id}`}>
            {this.props.product.short_name}
          </Link>
        </TableCell>
        <TableCell>
          <Link href={`/admin/companies/${this.props.product.company_id}/edit`}>
            {this.props.product.company_name}
          </Link>
        </TableCell>
        <TableCell align='right'>
          <EditButton component="a" href={editUrl} />
        </TableCell>
      </TableRow>
    )
  }
}
