import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import TrialSponsorRow from "./TrialSponsorRow"
import Flex from "../../Flex"
import Box from "../../Box"
import Typography from '@material-ui/core/Typography';
import Snackbar from "../../Snackbar"
import { Table, TableHead, TableBody, TableCell, TableRow } from "../../tables"
import update from "immutability-helper"
import Select from "../../Select"
import { withSnackbar } from "../../SnackbarContainer"
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

class SponsorForm extends Component {
  constructor(props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
    this.handleDeleteClick = this.handleDeleteClick.bind(this)
    this.state = { trialSponsors: [], trialCollaborators: this.props.trialCollaborators }
  }

  componentDidMount() {
    $.get(`/admin/trials/${this.props.trial.id}/sponsors`, {}, (data) => {
      this.setState({
        trialSponsors: data.trial_sponsors
      })
    }, 'json')
  }

  handleChange(e, selected) {
    if (this.props.source) {
      $.post("/admin/trial_sponsors", {trial_sponsor: { trial_id: this.props.trial.id, company_id: selected.value, source_id: this.props.source.source_id, created_by: this.props.currentUser.id }}, (data) => {
        let trialSponsors = update(this.state.trialSponsors, { $push: [data.trial_sponsor]})
        this.setState({
          trialSponsors: trialSponsors
        })
      }, 'json').fail(() => {
        this.props.snackbarShowMessage("Sponsor could not be added. Please try again.")
      })
    } else {
      this.props.snackbarShowMessage("You must choose a source before adding a sponsor", "info")
    }
  }

  handleDeleteClick(trialSponsorHash) {
    $.ajax({
      type: 'PUT',
      url: `/admin/trial_sponsors/${trialSponsorHash.trial_sponsor.id}`,
      data: {
        trial_sponsor: {
          deleted: true, deleted_by: this.props.currentUser.id
        }
      },
      dataType: 'json',
      success: (data) => {
        let index = this.state.trialSponsors.indexOf(trialSponsorHash)
        let trialSponsors = update(this.state.trialSponsors, { $splice: [[index, 1]]})
        this.setState({
          trialSponsors: trialSponsors
        })
      },
      error: (data) => {
        this.props.snackbarShowMessage("The sponsor could not be removed. Please try again.")
      }
    })
  }

  render() {
    return(
      <Fragment>
        <Typography variant="h6" component="h6" className='mb1 mt1 mr1'>
          New Sponsor
        </Typography>

        <Flex alignItems='center' mt={1} width={1}>

          <Box width='20%'>
            {/* <Select
              entities={this.props.companies}
              displayEmpty={true}
              label='Company'
              handleChange={this.handleChange}
            /> */}
            <div style={{ width: 300 }}>
              <Autocomplete
                id='company_id'
                options={this.props.companies}
                name='company_id'
                getOptionLabel={(option) => option.name}
                style={{ width: 300 }}
                selectOnFocus
                onChange={this.handleChange}
                renderInput={(params) => <TextField {...params} name='company_id' label='Company' variant="outlined" />}
              />
            </div>
          </Box>
        </Flex>

        <Box mt={4}>
          <Typography variant="h6" component="h6" className='mb1 mt1 mr1'>
            Sponsors
          </Typography>

          <Table>

            <TableHead>
              <TableRow>
                <TableCell>Sponsor</TableCell>
                <TableCell>Source</TableCell>
                <TableCell>User</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {this.state.trialSponsors.length > 0 ? this.state.trialSponsors.map((trialSponsorHash) =>
                <TrialSponsorRow
                  key={trialSponsorHash.trial_sponsor.id}
                  trialSponsorHash={trialSponsorHash}
                  handleDeleteClick={this.handleDeleteClick}
                />
              ) : <TableRow><TableCell colSpan={4}>No sponsors yet. Add one above.</TableCell></TableRow>}
            </TableBody>

          </Table>
        </Box>

        <Snackbar
          open={this.state.showSnackbar}
          message={this.state.snackbarText}
        />

      </Fragment>
    )
  }
}

export default withSnackbar(SponsorForm)
