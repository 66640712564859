import React, { useState } from "react"
import { TableRow, TableCell } from "../../tables"
import IconButton from '@material-ui/core/IconButton';
import EditButton from '../EditButton';
import DeleteButton from '../DeleteButton'
import Link from "../../Link"
import Switch from "../../Switch"
import Box from "../../Box"
import DatePicker from "../../DatePicker"
import AddIcon from '@material-ui/icons/Add';
import FilterChip from "../../filters/FilterChip"
import AlertDisplay from "./Display"

export default function AlertRow(props) {

  return (
    <TableRow>
      <TableCell>
        {props.alert.id}
      </TableCell>
      <TableCell>
        {props.alert.abbr}
      </TableCell>
      <TableCell>
        <Box>
          {humanDateFormat(props.alert.date)}
        </Box>
      </TableCell>
      <TableCell>
        <AlertDisplay
          alert={props.alert}
        />
      </TableCell>
      <TableCell>
        {props.alert.alert_group_name ? props.alert.alert_group_name : 'N/A'}
      </TableCell>
    </TableRow>
  )
}
