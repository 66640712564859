import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Paper from "../../Paper"
import { Table, TableHead, TableRow, TableCell, TableBody, EmptyDataTableRow } from "../../tables"
import NewsArticleRow from "./NewsArticleRow"
import update from "immutability-helper"
import Snackbar from "../../Snackbar"


export default class NewsArticles extends Component {
  constructor(props) {
    super(props)
    this.handleDeleteClick = this.handleDeleteClick.bind(this)
    this.handleUndoClick = this.handleUndoClick.bind(this)
    this.state = { newsArticles: this.props.news_articles, showSnackbar: false, snackbarText: '', lastDeleted: '' }
  }

  handleUndoClick(e) {
    if (this.state.lastDeleted) {
      $.ajax({
        type: 'PUT',
        url: `/admin/news_articles/${this.state.lastDeleted.id}`,
        data: {
          news_article: {
            deleted: null, deleted_by: null
          }
        },
        dataType: 'json',
        success: (data) => {
          const newsArticles = update(this.state.newsArticles, { $push: [this.state.lastDeleted] })
          this.setState({
            newsArticles: newsArticles,
            showSnackbar: true,
            snackbarText: 'News article was undeleted',
            lastDeleted: null
          })
        },
        error: (data) => {
          this.setState({
            showSnackbar: true,
            snackbarText: data.responseText
          })
        },
        completed: (data) => {
          setTimeout(() => {
            this.setState({
              showSnackbar: false,
              snackbarText: ''
            })
          }, 4000)
        }
      })
    }
  }

  handleDeleteClick(newsArticle) {
    $.ajax({
      type: 'PUT',
      url: `/admin/news_articles/${newsArticle.id}`,
      data: {
        news_article: {
          deleted: true, deleted_by: this.props.current_user
        }
      },
      dataType: 'json',
      success: (data) => {
        const index = this.state.newsArticles.indexOf(newsArticle)
        let newsArticles = update(this.state.newsArticles, { $splice: [[index,1]] })
        this.setState({
          newsArticles: newsArticles,
          showSnackbar: true,
          snackbarText: 'News article was deleted',
          lastDeleted: newsArticle
        })
      },
      error: (data) => {
        this.setState({
          showSnackbar: true,
          snackbarText: `News article could not be deleted. ${data.responseText}`
        })
      },
      complete: (data) => {
        setTimeout(() => {
          this.setState({
            showSnackbar: false,
            snackbarText: ''
          })
        }, 4000)
      }
    })
  }

  render() {
    return(
      <Fragment>

        <Paper>
          <Table className='w-100'>
            <TableHead>
              <TableRow>
                <TableCell className='w-75'>News Article</TableCell>
                {/* <TableCell className='w-25'>URL</TableCell> */}
                <TableCell>Created By</TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.state.newsArticles.length > 0 ? this.state.newsArticles.map((newsArticle) =>
                <NewsArticleRow
                  key={newsArticle.id}
                  newsArticle={newsArticle}
                  handleDeleteClick={this.handleDeleteClick}
                />
              ) : <EmptyDataTableRow colSpan={5} message='No news article yet. Create an news article and it will display here' />}
            </TableBody>
          </Table>
        </Paper>

        <Snackbar
          open={this.state.showSnackbar}
          handleUndoClick={this.handleUndoClick}
          handleClose={this.handleClose}
          message={this.state.snackbarText}
        />
      </Fragment>
    )
  }
}
