import React from "react"
import Paper from "../../../Paper"
import Flex from "../../../Flex"
import Box from "../../../Box"
import { Table, TableRow, TableHead, TableBody, TableCell } from "../../../tables"
import NumberDisplay from "./NumberDisplay"


class Predictions extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    return(
      <React.Fragment>
        <Paper fullHeight>

          <Box height="100%">

            <Box overflow="auto" height="90%">
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>PMA</TableCell>
                    <TableCell>Trade Name</TableCell>
                    <TableCell>Search Term</TableCell>
                    <TableCell>Supplement Reason</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {this.props.pma_predictions.map((fdaPmaApproval) =>
                    <TableRow key={fdaPmaApproval.id}>
                      <TableCell>
                        <NumberDisplay fdaPmaApproval={fdaPmaApproval} />
                      </TableCell>
                      <TableCell>{fdaPmaApproval.trade_name}</TableCell>
                      <TableCell>{fdaPmaApproval.search_term}</TableCell>
                      <TableCell>{fdaPmaApproval.supplement_reason}</TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </Box>
          </Box>
        </Paper>
      </React.Fragment>
    )
  }
}


export default Predictions
