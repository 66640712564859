import React, { Component, Fragment } from "react"
import Paper from "../../Paper"
import Flex from "../../Flex"
import Box from "../../Box"
import Typography from "@material-ui/core/Typography"
import TweetsTable from "./TweetsTable"
import Filters from "./Filters"
import SectorCheckboxList from "../sectors/CheckboxList"
import Dialog from "../../Dialog"
import update from "immutability-helper"
import { withSnackbar } from "../../SnackbarContainer"

class Tweets extends Component {
  constructor(props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
    this.handleSearchClick = this.handleSearchClick.bind(this)
    this.handleChangePage = this.handleChangePage.bind(this)
    this.handleClearSearchClick = this.handleClearSearchClick.bind(this)
    this.handleAlertClick = this.handleAlertClick.bind(this)
    this.handleSectorClick = this.handleSectorClick.bind(this)
    this.handleAddClick = this.handleAddClick.bind(this)
    this.handleCloseDialog = this.handleCloseDialog.bind(this)
    this.state = { tweets: this.props.tweets, tweetsCount: this.props.tweets_count, activeTweet: null, filterValues: { query: this.props.query, page: this.props.page }, sectorIds: [], existingAlertTweets: this.props.existing_alert_tweets}
  }

  handleCloseDialog(e) {
    this.setState({
      openAlertDialog: false
    })
  }

  handleChange(e) {
    this.setState({
      query: e.target.value
    })
  }

  handleChangePage(e, newPage) {
    let params = Object.assign({}, this.state.filterValues)

    params["page"] = newPage

    this.getTweets(params)
  }

  handleSearchClick(query) {
    let params = Object.assign({}, this.state.filterValues)

    params["query"] = query

    this.getTweets(params)
  }

  handleClearSearchClick(query) {
    e.preventDefault()

    let params = Object.assign({}, this.state.filterValues)

    params["query"] = null

    this.getTweets(params)
  }

  getTweets(params) {
    $.ajax({
      type: "GET",
      url: `/admin/tweets`,
      data: params,
      dataType: 'json',
      success: (data) => {
        this.setState({
          tweets: data.tweets,
          tweetsCount: data.tweets_count,
          filterValues: params
        })
      }
    })
  }

  handleSectorClick(sector) {
    let index = this.state.sectorIds.indexOf(sector.id)

    let sectorIds = ""

    if (index == -1) {
      sectorIds = update(this.state.sectorIds, {
        $push: [sector.id]
      })
    } else {
      sectorIds = update(this.state.sectorIds, {
        $splice: [[index, 1]]
      })
    }

    this.setState({
      sectorIds: sectorIds
    })
  }

  handleAlertClick(tweet) {
    this.setState({
      openAlertDialog: true,
      activeTweet: tweet
    })

  }

  handleAddClick(e) {
    $.ajax({
      type: "POST",
      url: `/admin/tweets/${this.state.activeTweet.id}/create_alert`,
      data: {
        sector_ids: this.state.sectorIds
      },
      dataType: 'json',
      success: (data) => {
        let alertTweetIds = update(this.state.existingAlertTweets, {
          $push: [this.state.activeTweet.id]
        })

        this.setState({
          existingAlertTweets: alertTweetIds,
          activeTweet: null,
          openAlertDialog: false
        })

        this.props.snackbarShowMessage("Tweet has been created as an alert")
      }
    })
  }

  render() {
    return(
      <Fragment>

        {this.state.openAlertDialog ?
          <Dialog
            openDialog={this.state.openAlertDialog}
            dialogTitle='Add Sectors'
            handleAddClick={this.handleAddClick}
            handleClose={this.handleCloseDialog}
            dialogContent={
              <SectorCheckboxList
                sectors={this.props.sectors}
                sectorIds={this.state.sectorIds}
                handleCheckboxSelect={this.handleSectorClick}
              />
            }
          />
        : ''}

        <Paper fullHeight>

          <Flex justifyContent='center' alignItems='center'>
            <Box width={1/2}>
              <Filters
                query={this.state.filterValues.query}
                handleChange={this.handleChange}
                handleSearchClick={this.handleSearchClick}
                handleClearSearchClick={this.handleClearSearchClick}
              />
            </Box>
          </Flex>

          <Box ml="auto">
            <Typography variant="body2">
              {`${this.state.tweetsCount} tweets`}
            </Typography>
          </Box>

          <Box overflow="auto" height="90%">

            <TweetsTable
              tweets={this.state.tweets}
              count={this.state.tweetsCount}
              page={this.state.filterValues.page}
              existingAlertTweets={this.state.existingAlertTweets}
              handleChangePage={this.handleChangePage}
              handleAlertClick={this.handleAlertClick}
            />

          </Box>
        </Paper>

      </Fragment>
    )
  }
}

export default withSnackbar(Tweets)
