import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Paper from "../../Paper"
import Flex from "../../Flex"
import Box from "../../Box"
import { Table, TableBody, TableRow, TableHead, TableCell, TableHeadSort } from "../../tables"
import Link from "../../Link"
import Typography from "@material-ui/core/Typography"
import Select from "../../Select"
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
// import TablePaginationActions from "../tables/TablePaginationActions"
import LinearProgress from '@material-ui/core/LinearProgress';

export default class StagedAssociator extends Component {
  constructor(props) {
    super(props)
    this.handleSectorChange = this.handleSectorChange.bind(this)
    this.handleRequestSort = this.handleRequestSort.bind(this)
    this.state = { abstracts: this.props.abstracts, sector_id: this.props.sector_id, order: 'desc', orderBy: 'score' }
  }

  handleRequestSort(event, property) {

  }

  handleSectorChange(name, value) {
    $.ajax({
      type: 'GET',
      url: '/admin/abstracts/staged_associator',
      data: {
        sector_id: value
      },
      dataType: 'json',
      success: (data) => {
        this.setState({
          abstracts: data.abstracts,
          sector_id: value
        })
      }
    })
  }

  render() {
    return(
      <Fragment>

        <Paper fullHeight>

          <Flex>
            <Box>
              <Select
                label='Sector'
                name='sector_id'
                includeBlank
                value={this.state.sector_id}
                entities={this.props.sectors}
                handleChange={this.handleSectorChange}
              />
            </Box>

            <Box ml='auto'>
              <Typography variant="body2">
                {`${this.state.abstracts.length} Presentations`}
              </Typography>
            </Box>
          </Flex>

          <Box overflow='auto' maxHeight='90%' mt={3}>
            <Table size='small' aria-label='staged associator table'>

              <TableHeadSort
                headers={[ { name: 'number', label: '#' }, { name: 'title', label: 'Title' }, { name: "score", label: "Score" }, { name: 'predicted_trial_count', label: 'Predicted trials', align: 'tr' } ]}
                order={this.state.order}
                orderBy={this.state.orderBy}
                onRequestSort={this.state.handleRequestSort}
              />

              <TableBody>
                {this.state.abstracts.map((abstract) =>
                  <TableRow key={abstract.id} component={Link} href={`/admin/abstracts/${abstract.id}/associator`} target="_blank">

                    <TableCell>
                      {abstract.number}
                    </TableCell>

                    <TableCell>
                      {abstract.title}
                    </TableCell>

                    <TableCell>
                      {abstract.score}
                    </TableCell>

                    <TableCell align='right'>
                      {abstract.predicted_trial_count}
                    </TableCell>

                  </TableRow>
                )}
              </TableBody>
              {/* <TableFooter>
                <TablePagination
                  colSpan={5}
                  count={this.state.abstracts.length}
                  rowsPerPage={100}
                  page={this.state.page}
                  SelectProps={{
                    inputProps: { 'aria-label': 'rows per page' },
                    native: true,
                  }}
                  onChangePage={this.handleChangePage}
                  onChangeRowsPerPage={this.handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableFooter> */}
            </Table>
          </Box>

        </Paper>

      </Fragment>
    )
  }
}
