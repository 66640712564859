import React from 'react'
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TablePaginationActions from '@material-ui/core/TablePagination/TablePaginationActions'
import { Table, TableBody, TableCell, TableHead, TableRow } from '../../tables'
import ExpandableTrialRow from './ExpandableTrialRow'

export default function TrialsTable(props) {
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell />
          <TableCell>ID</TableCell>
          <TableCell>Trial</TableCell>
          <TableCell>Sponsor</TableCell>
          <TableCell>Date</TableCell>
          <TableCell>Status</TableCell>
          <TableCell>Hide</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {props.trials.map((trial) =>
          <ExpandableTrialRow 
            key={trial._id || trial.id}
            trial={trial._source || trial}
          />
        )}
      </TableBody>
      <TableFooter>
        <TablePagination 
          colSpan={8}
          count={props.trialsCount}
          rowsPerPage={100}
          page={props.page}
          SelectProps={{
            inputProps: { 'aria-label': 'rows per page' },
            native: true,
          }}
          onChangePage={props.handleChangePage}
          onChangeRowsPerPage={props.handleChangeRowsPerPage}
          ActionsComponent={TablePaginationActions}
        />
      </TableFooter>
    </Table>
  )
}
