import React from "react"
import MuiTable from '@material-ui/core/Table';

export default function Table(props) {
  return (
    <MuiTable {...props}>
      {props.children}
    </MuiTable>
  )
}
