import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Paper from "../../Paper"
import Snackbar from "../../Snackbar"
import update from "immutability-helper"
import Form from "./Form"

export default class NewContainer extends Component {
  constructor(props) {
    super(props)
    this.handleSwitchChange = this.handleSwitchChange.bind(this)
    this.handleSaveClick = this.handleSaveClick.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleDateChange = this.handleDateChange.bind(this)
    this.state = { userCompany: this.props.user_company, userCompanySectors: [], showSnackbar: false, snackbarText: '' }
  }

  handleChange(e) {
    let userCompany = update(this.state.userCompany, {
      [e.target.name]: { $set: e.target.value }
    })

    this.setState({
      userCompany: userCompany
    })
  }

  handleDateChange(date) {
    let userCompany = update(this.state.userCompany, {
      expires_on: { $set: date }
    })

    this.setState({
      userCompany: userCompany
    })
  }

  handleSwitchChange(sector_id) {
    let userCompanySectors = update(this.state.userCompanySectors, {
      $push: [{ sector_id: sector_id }]
    })

    this.setState({
      userCompanySectors: userCompanySectors
    })
  }

  handleSaveClick(e) {
    let user_company_sectors_attributes = {}

    this.state.userCompanySectors.map((sectorHash, index) =>
      user_company_sectors_attributes[index] = { sector_id: sectorHash.sector_id, created_by: this.props.current_user.id }
    )

    let userCompany = update(this.state.userCompany, {
      $merge: { user_company_sectors_attributes: user_company_sectors_attributes }
    })

    $.ajax({
      type: 'POST',
      url: `/admin/user_companies`,
      data: {
        user_company: userCompany
      },
      dataType: 'json',
      success: (data) => {
        window.location = `/admin/user_companies/${data.user_company.id}/edit`
      },
      error: (data) => {
        this.setState({
          showSnackbar: true,
          snackbarText: 'The user company could not be saved. Make sure the email has been filled out'
        })
      },
      complete: (data) => {
        setTimeout(() => {
          this.setState({
            showSnackbar: false,
            snackbarText: ''
          })
        }, 5000)
      }
    })
  }

  render() {
    return(
      <Fragment>
        <Paper minFullHeight>
          <Form
            userCompany={this.state.userCompany}
            userCompanySectors={this.state.userCompanySectors}
            sectors={this.props.sectors}
            buttonText='Create User Company'
            handleChange={this.handleChange}
            handleDateChange={this.handleDateChange}
            handleSwitchChange={this.handleSwitchChange}
            handleSaveClick={this.handleSaveClick}
          />
        </Paper>

        <Snackbar
          open={this.state.showSnackbar}
          message={this.state.snackbarText}
        />
      </Fragment>
    )
  }
}
