import React, { Component, Fragment } from "react"
import Paper from "../Paper"
import Flex from "../Flex"
import Box from "../Box"
import Pagination from '@material-ui/lab/Pagination';
import Typography from "@material-ui/core/Typography"
import { Button } from "../buttons"
import { withSnackbar } from "../SnackbarContainer"
import update from "immutability-helper"
import { Table, TableHead, TableRow, TableCell, TableBody } from "../tables"
import SearchTermRow from "./SearchTermRow"


class SearchTerms extends Component {
  constructor(props) {
    super(props)
    this.handleAlertableChange = this.handleAlertableChange.bind(this)
    this.handleDeleteClick = this.handleDeleteClick.bind(this)
    this.state = { userSearchTerms: this.props.user_search_terms }
  }

  handleDeleteClick(userSearchTerm) {
    $.ajax({
      type: "PUT",
      url: `/sectors/${this.props.sector.abbr}/user_twitter_search_terms/${userSearchTerm.id}`,
      data: {
        user_twitter_search_term: {
          deleted: true
        }
      },
      dataType: 'json',
      success: (data) => {
        let index = this.state.userSearchTerms.indexOf(userSearchTerm)

        let userSearchTerms = update(this.state.userSearchTerms, {
          $splice: [[index, 1]]
        })

        this.setState({
          userSearchTerms: userSearchTerms
        })

        this.props.snackbarShowMessage("The search term has been removed.")
      },
      error: (data) => {
        this.props.snackbarShowMessage("There was an issue removing the search term. Please try again.", "error")
      }
    })
  }

  handleAlertableChange(userSearchTerm, alertable) {
    console.log(alertable)
    $.ajax({
      type: "PUT",
      url: `/sectors/${this.props.sector.abbr}/user_twitter_search_terms/${userSearchTerm.id}`,
      data: {
        user_twitter_search_term: {
          alertable: alertable ? null : true
        }
      },
      dataType: 'json',
      success: (data) => {
        let index = this.state.userSearchTerms.indexOf(userSearchTerm)

        let userSearchTerms = update(this.state.userSearchTerms, {
          [index]: {
            alertable: { $set: alertable ? null : true }
          }
        })

        this.setState({
          userSearchTerms: userSearchTerms
        })

        let message = alertable ? "You will no longer receive alerts for this search term" : "You will now receive alerts when tweets are found with this search term"

        this.props.snackbarShowMessage(message)
      },
      error: (data) => {
        this.props.snackbarShowMessage("There was an issue making the search term alertable. Please try again.", "error")
      }
    })
  }

  render() {
    return(
      <Paper fullHeight>
        <Box height="100%">

          <Box overflow="auto" height="90%">
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Search Term</TableCell>
                  <TableCell>Alertable</TableCell>
                  <TableCell>Remove</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.userSearchTerms.map((userSearchTerm) =>
                  <SearchTermRow
                    key={userSearchTerm.id}
                    userSearchTerm={userSearchTerm}
                    sector={this.props.sector}
                    handleAlertableChange={this.handleAlertableChange}
                    handleDeleteClick={this.handleDeleteClick}
                  />
                )}
              </TableBody>
            </Table>
          </Box>
        </Box>
      </Paper>
    )
  }
}

export default withSnackbar(SearchTerms)
