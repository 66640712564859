import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Paper from "../../Paper"
import Flex from "../../Flex"
import Box from "../../Box"
import EntityCard from "../EntityCard"
import Highlighter from "react-highlight-words";
import Link from "../../Link"

export default class TrialPanel extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    console.log(this.props.trial.interventions)
    return(
      <Fragment>

        <Flex flexDirection='column' mb={3}>
          <Box width={1} mb={1}>
            <label className='b'>Trial</label>
          </Box>
          <Box>
            {this.props.trial.acronym || this.props.trial.short_name}
          </Box>
        </Flex>

        <Flex flexDirection='column' mb={3}>
          <Box width={1} mb={1}>
            <label className='b'>NCT ID</label>
          </Box>
          <Box>
            <Link href={this.props.trial.ct_gov_url} target="_blank">
              {this.props.trial.nct_id}
            </Link>
          </Box>
        </Flex>

        <Flex flexDirection='column' mb={3}>
          <Box width={1} mb={1}>
            <label className='b'>Full Name</label>
          </Box>
          <Box>
            <Highlighter
              searchWords={this.props.searchWords}
              autoEscape={true}
              textToHighlight={this.props.trial.full_name}
            />
          </Box>
        </Flex>

        <Flex flexDirection='column' mb={3}>
          <Box width={1} mb={1}>
            <label className='b'>Brief Summary</label>
          </Box>
          <Box>
            <Highlighter
              searchWords={this.props.searchWords}
              autoEscape={true}
              textToHighlight={this.props.trial.brief_summary}
            />
          </Box>
        </Flex>

        <Flex flexDirection='column' mb={3}>
          <Box width={1} mb={1}>
            <label className='b'>Detailed Description</label>
          </Box>
          <Box>
            <Highlighter
              searchWords={this.props.searchWords}
              autoEscape={true}
              textToHighlight={this.props.trial.detailed_description}
            />
          </Box>
        </Flex>

        <Flex flexDirection='column' mb={3}>
          <Box width={1} mb={1}>
            <label className='b'>Arms</label>
          </Box>
          <Box>
            {this.props.trial.trialArmsText ? this.props.trial.trialArmsText.map((trialArmText) =>
              <Box mb={0.5}>
                <Highlighter
                  key={trialArmText}
                  searchWords={this.props.searchWords}
                  autoEscape={true}
                  textToHighlight={trialArmText}
                />
              </Box>
            ) : <Box>None</Box>}
          </Box>
        </Flex>

        <Flex flexDirection='column'>
          <Box width={1} mb={2}>
            <label className='b'>Interventions</label>
          </Box>
          <Box>
            {this.props.trial.interventions ? this.props.trial.interventions.split(";").map((trialInterventionText) =>
              <Box mb={1}>
                <Highlighter
                  key={trialInterventionText}
                  searchWords={this.props.searchWords}
                  autoEscape={true}
                  textToHighlight={trialInterventionText}
                />
              </Box>
            ) : <Box>None</Box>}
          </Box>
        </Flex>

      </Fragment>
    )
  }
}
