import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Paper from "../../Paper"
import Flex from "../../Flex"
import Box from "../../Box"
import TriageTable from "./TriageTable"
import TriageFilters from "./TriageFilters"
import Typography from "@material-ui/core/Typography"
import update from 'immutability-helper'
import Select from "../../Select"
import { RaisedButton } from "../../buttons"
import Snackbar from "../../Snackbar"

export default class Triage extends Component {
  constructor(props) {
    super(props)
    this.handleFilterChange = this.handleFilterChange.bind(this)
    this.handleAbstractCheckboxClick = this.handleAbstractCheckboxClick.bind(this)
    this.handleBulkAbstractCheck = this.handleBulkAbstractCheck.bind(this)
    this.handlePriorityChange = this.handlePriorityChange.bind(this)
    this.handleAddStagedAssociatorClick = this.handleAddStagedAssociatorClick.bind(this)
    this.handleReporterChange = this.handleReporterChange.bind(this)
    this.state = { abstracts: this.props.abstracts, totalAbstracts: this.props.total_abstracts, checkedAbstracts: [], showSnackbar: false, snackbarText: '', filterOptions: { dates: this.props.dates, sessionTitles: this.props.session_titles, abstractTypes: this.props.abstract_types, priorities: this.props.priorities }, filterValues: { date: this.props.date, session: '', type: '', priority_id: '', reporter_user_id: '' } }
  }

  handleAbstractCheckboxClick(abstractId) {
    const index = this.state.checkedAbstracts.indexOf(abstractId)


    let abstracts = index == -1 ?
      update(this.state.checkedAbstracts, {
        $push: [abstractId]
      })
    :
      update(this.state.checkedAbstracts, {
        $splice: [[index, abstractId]]
      })

    this.setState({
      checkedAbstracts: abstracts
    })
  }

  handleBulkAbstractCheck(e) {
    if (this.state.checkedAbstracts.length > 0) {
      this.setState({
        checkedAbstracts: []
      })
    } else {
      let checkedAbstracts = this.state.abstracts.map((abstract) => abstract.id)
      this.setState({
        checkedAbstracts: checkedAbstracts
      })
    }
  }

  handleReporterChange(name, value) {
    if (this.state.checkedAbstracts.length > 1) {
      $.ajax({
        type: 'PUT',
        url: `/admin/abstracts/bulk_reporter_assignment`,
        data: {
          reporter_user_id: value, abstract_ids: this.state.checkedAbstracts
        },
        dataType: 'json',
        success: (data) => {
          this.setState({
            abstracts: data.abstracts
          })
        }
      })
    } else {
      $.ajax({
        type: 'PUT',
        url: `/admin/abstracts/${this.state.checkedAbstracts[0]}`,
        data: {
          abstract: {
            reporter_user_id: value
          }
        },
        dataType: 'json',
        success: (data) => {
          let abstractIds = this.state.abstracts.map(abstract => abstract.id)

          let index = abstractIds.indexOf(this.state.checkedAbstracts[0])

          let user = this.props.reporters.filter(userHash => userHash.value == value)[0]

          let splitName = user.name.split(" ")

          let abstracts = update(this.state.abstracts, {
            [index]: {
              reporter_user_id: { $set: value },
              first_name: { $set: splitName[0] },
              last_name: { $set: splitName[1] }
            }
          })

          this.setState({
            abstracts: abstracts
          })
        }
      })
    }
  }

  handlePriorityChange(name, value) {
    $.ajax({
      type: 'POST',
      url: `/admin/abstracts/new_priorities`,
      data: {
        abstract_ids: this.state.checkedAbstracts, user_id: this.props.user.id, priority_id: value, for: 'triage', meeting_id: this.state.abstracts[0].science_meeting_occurrence_id
      },
      dataType: 'json',
      success: (data) => {
        this.setState({
          abstracts: data.abstracts
        })
      }
    })
  }

  handleFilterChange(name, value) {
    let params = Object.assign({}, this.state.filterValues)

    params[name] = value

    $.ajax({
      type: 'GET',
      url: `/admin/meetings/${this.props.meeting.id}/triage`,
      data: params,
      dataType: 'json',
      success: (data) => {
        let filterOptions = { dates: data.dates, sessionTitles: data.session_titles, abstractTypes: data.abstract_types, priorities: data.priorities }

        this.setState({
          abstracts: data.abstracts,
          totalAbstracts: data.total_abstracts,
          filterValues: params,
          filterOptions
        })
      }
    })
  }

  handleAddStagedAssociatorClick(e) {
    $.ajax({
      type: 'POST',
      url: `/admin/abstracts/add_to_staged_associator`,
      data: {
        abstract_ids: this.state.checkedAbstracts, user_id: this.props.user.id, meeting_id: this.state.abstracts[0].science_meeting_occurrence_id
      },
      dataType: 'json',
      success: (data) => {
        this.setState({
          showSnackbar: true,
          snackbarText: 'Abstracts added to staged associator'
        })
      },
      error: (data) => {
        this.setState({
          showSnackbar: true,
          snackbarText: 'Abstracts could not be added to staged associator'
        })
      },
      complete: (data) => {
        setTimeout(() => {
          this.setState({
            showSnackbar: false,
            snackbarText: ''
          })
        }, 4000)
      }
    })
  }

  render() {
    return(
      <Fragment>

        <Paper fullHeight>

          <Box height="100%">
            <Flex alignItems='center' mb={3}>
              {this.state.checkedAbstracts.length == 0 ?
                <Box width={9/10}>
                  <TriageFilters
                    filterOptions={this.state.filterOptions}
                    filterValues={this.state.filterValues}
                    handleFilterChange={this.handleFilterChange}
                  />
                </Box>

              :
                <Flex alignItems='center'>
                  <Box mr={3}>
                    <RaisedButton onClick={this.handleAddStagedAssociatorClick}>
                      Add to Staged Associator
                    </RaisedButton>
                  </Box>

                  <Box mr={3}>
                    <Box>
                      <Select
                        label='Reporter'
                        name='reporter_user_id'
                        value={this.state.reporter_user_id}
                        entities={this.props.reporters}
                        handleChange={this.handleReporterChange}
                      />
                    </Box>
                  </Box>

                  <Box>
                    <Box width="12%">
                      <Select
                        label='Priority'
                        name='priority_id'
                        value={this.state.priorityId}
                        entities={this.props.priorities}
                        handleChange={this.handlePriorityChange}
                      />
                    </Box>
                  </Box>
                </Flex>
              }

              <Box ml='auto'>
                <Typography variant="body2">
                  {`${this.state.totalAbstracts} abstracts`}
                </Typography>
              </Box>
            </Flex>

            <TriageTable
              abstracts={this.state.abstracts}
              checkedAbstracts={this.state.checkedAbstracts}
              handleAbstractCheckboxClick={this.handleAbstractCheckboxClick}
              handleBulkAbstractCheck={this.handleBulkAbstractCheck}
            />
          </Box>

        </Paper>

        <Snackbar
          open={this.state.showSnackbar}
          message={this.state.snackbarText}
        />

      </Fragment>
    )
  }
}
