import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import { TableRow, TableCell } from "../../tables"
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import FavoriteIcon from '@material-ui/icons/Favorite';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';

class TrialTypeRow extends Component {
  constructor(props) {
    super(props)
    this.handleDeleteClick = this.handleDeleteClick.bind(this)
    this.handleFavoriteClick = this.handleFavoriteClick.bind(this)
  }

  handleDeleteClick(e) {
    this.props.handleDeletion(this.props.trialType.trial_type)
  }

  handleFavoriteClick(e) {
    this.props.handleFavoriteClick(this.props.trialType.trial_type)
  }

  render() {
    const user = this.props.trialType.creator
    return(
      <TableRow>

        <TableCell>
          {this.props.trialType.clinical_type}
        </TableCell>

        <TableCell>
          {this.props.trialType.source_object ? this.props.trialType.source_object.source_title : 'N/A'}
        </TableCell>

        <TableCell>
          {user ? displayInitials(user.first_name, user.last_name) : 'N/A'}
        </TableCell>

        <TableCell>
          <IconButton onClick={this.handleDeleteClick}>
            <DeleteIcon />
          </IconButton>
        </TableCell>

      </TableRow>
    )
  }
}

export default TrialTypeRow
