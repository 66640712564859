import React from "react"
import ResultItemContainer from "../search/ResultItemContainer"
import Flex from "../Flex"
import Box from "../Box"
import Typography from "@material-ui/core/Typography"

export default function SearchItem(props) {

  console.log(props.result)

  function renderSearchDetails(result) {
    return (
      <Flex>
        <Box key={result.applicant_name} mr={2}>
          <Typography variant="body2" color="textSecondary">
            {result.applicant_name}
          </Typography>
        </Box>
      </Flex>
    );
  }

  return(
    <ResultItemContainer 
      searchItemType='FDA Humanitarian Device Exemption'
      searchTitle={`${props.result.formatted_hde_number} - ${props.result.trade_name}`}
      date={props.result.decision_date}
      searchDescription={props.result.ao_statement}
      url={props.result.url}
      target="_blank"
      searchDetails={renderSearchDetails(props.result)}
    />
  )
}