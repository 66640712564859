import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import ProfileFieldsContainer from "./ProfileFieldsContainer"
import ProductCard from "./ProductCard"
import EntityLayout from "../EntityLayout"
import Box from "../Box"
import Paper from "../Paper"
import RegionProfile from "./RegionProfile"

export default class Profile extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    return(
      <Fragment>

        <EntityLayout>

          <Box className='w-100 w-60-ns mb3 mb0-ns'>
            <ProfileFieldsContainer
              profileFields={this.props.profile_fields}
              product={this.props.product}
              sector={this.props.sector}
            />
          </Box>

          <Box className='w-100 w-33-ns ml-auto'>
            <ProductCard
              {...this.props}
            />
          </Box>

        </EntityLayout>

      </Fragment>
    );
  }
}

Profile.propTypes = {
  product: PropTypes.object,
  profile_fields: PropTypes.array
}
