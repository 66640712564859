import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import { TableCell, TableRow } from "../../tables"
import Link from "../../Link"
import Switch from "../../Switch"

export default class TrialRow extends Component {
  constructor(props) {
    super(props)
    this.handleSwitchChange = this.handleSwitchChange.bind(this)
  }

  handleSwitchChange(e) {
    this.props.handleSwitchChange(e.target.checked, this.props.ctGovTrial)
  }

  render() {
    return(
      <TableRow>
        <TableCell>{this.props.ctGovTrial.nct_id}</TableCell>
        <TableCell>
          <Link href={`/admin/ct_gov_trials/${this.props.ctGovTrial.id}`}>
            {this.props.ctGovTrial.brief_title}
          </Link>
        </TableCell>
        <TableCell>{this.props.ctGovTrial.sponsor}</TableCell>
        <TableCell>
          {this.props.ctGovTrial.nct_collaborators ? this.props.ctGovTrial.nct_collaborators.map((collaborator) =>
            <div key={collaborator.id}>
              {collaborator.name}
            </div>
          ) : ''}
        </TableCell>
        <TableCell>
          {this.props.ctGovTrial.nct_conditions ? this.props.ctGovTrial.nct_conditions.map((condition) =>
            <div key={condition.id}>
              {condition.name}
            </div>
          ) : ''}
        </TableCell>
        <TableCell>
          {this.props.ctGovTrial.nct_interventions ? this.props.ctGovTrial.nct_interventions.map((intervention) =>
            <div key={intervention.id}>
              {intervention.name}
            </div>
          ) : ''}
        </TableCell>
        <TableCell>
          <Switch
            checked={this.props.ctGovTrial.hide_trial}
            handleChange={this.handleSwitchChange}
          />
        </TableCell>
      </TableRow>
    )
  }
}
