import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import { Table, TableHead, TableRow, TableCell, TableBody } from "../../tables"
import Paper from "../../Paper"
import ClinicalTypeRow from "./ClinicalTypeRow"
import Snackbar from "../../Snackbar"
import update from "immutability-helper"

export default class ClinicalTypes extends Component {
  constructor(props) {
    super(props)
    this.handleDeleteClick = this.handleDeleteClick.bind(this)
    this.state = { activeTab: 'List', clinicalTypes: this.props.clinical_types, showSnackbar: false, snackbarText: '' }
  }
  
  handleDeleteClick(clinicalType) {
    $.ajax({
      type: 'PUT',
      url: `/admin/clinical_types/${clinicalType.id}`,
      data: {
        clinical_type: {
          deleted: true, deleted_by: this.props.current_user.id
        }
      },
      dataType: 'json',
      success: (data) => {
        let clinicalTypeIds = this.state.clinicalTypes.map((clinicalType) => clinicalType.id )
        let index = clinicalTypeIds.indexOf(clinicalType.id)
        let clinicalTypes = update(this.state.clinicalTypes, { $splice: [[index, 1]]})
        
        this.setState({
          clinicalTypes: clinicalTypes,
          showSnackbar: true,
          snackbarText: 'The clinical type was successfully deleted'
        })
      },
      error: (data) => {
        this.setState({
          showSnackbar: true,
          snackbarText: 'The clinical type could not be deleted. Please try again.'
        })
      },
      complete: (data) => {
        setTimeout(() => {
          this.setState({
            showSnackbar: false,
            snackbarText: ''
          })
        }, 4000)
      }
    })
  }
  
  render() {
    return(
      <Fragment>
        
        <Paper>
          <Table className='w-100'>
            <TableHead>
              <TableCell>Name</TableCell>
              <TableCell>User</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableHead>
            
            <TableBody>
              {this.state.clinicalTypes.length > 0 ? this.state.clinicalTypes.map((clinicalType) =>
                <ClinicalTypeRow
                  key={clinicalType.id}
                  clinicalType={clinicalType}
                  handleDeleteClick={this.handleDeleteClick}
                />
              ) : 
              <TableRow>
                <TableCell colSpan={3}>
                  No Types yet. Create a new type and it will show up here.
                </TableCell>
              </TableRow>
            }
          </TableBody>
            
          </Table>
        </Paper>
        
        <Snackbar 
          open={this.state.showSnackbar}
          message={this.state.snackbarText} 
        />
        
      </Fragment>
    )
  }
}
