import React, { Fragment } from "react"
import { ListItem, ListItemText } from "../../lists"
import DeleteButton from "../DeleteButton"

class SectorListItem extends React.Component {
  constructor(props) {
    super(props)
    this.handleDeleteClick = this.handleDeleteClick.bind(this)
  }
  
  handleDeleteClick(e) {
    e.preventDefault()
    this.props.handleDeleteClick(this.props.entitySector)
  }
  
  render() {
    return(
      <Fragment>
        <ListItem>
          <ListItemText 
            primary={this.props.entitySector.sector.abbr} 
            secondary={this.props.entitySector.created_by} 
          />
          
          <ListItemIcon>
            <DeleteButton handleClick={this.handleDeleteClick} />
          </ListItemIcon> 
          
        </ListItem>
      </Fragment>
    )
  }
}

export default SectorListItem
