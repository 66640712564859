import React from "react"
import PropTypes from 'prop-types';
import Typography from "@material-ui/core/Typography"
import Flex from "./Flex"
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  icon: {
    fontSize: 48,
    marginBottom: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
  message: {
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

const EmptyDataDisplay = ({ message, icon: Icon }) => {
  const classes = useStyles();

  return(
    <Flex justifyContent='center' height="50%" alignItems='center' flexDirection='column'>
      {Icon && <Icon className={classes.icon} />}
      <Typography variant="h6" className={classes.message}>
        {message}
      </Typography>
    </Flex>
  )
}


EmptyDataDisplay.propTypes = {
  message: PropTypes.string.isRequired,
  icon: PropTypes.elementType,
};

export default EmptyDataDisplay;