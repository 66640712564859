import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Paper from "../../Paper"
import Typography from '@material-ui/core/Typography';
import Flex from "../../Flex"
import Box from "../../Box"
import update from "immutability-helper"
import EditButton from "../EditButton"
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction"
import CircularProgress from '@material-ui/core/CircularProgress';
import Snackbar from "../../Snackbar"
import { Table, TableHead, TableRow, TableCell, TableBody, EmptyDataTableRow } from "../../tables"
import TableHeadSort from "../../tables/TableHeadSort"
import Select from "../../Select"

export default class ProductSearchTerms extends Component {
  constructor(props) {
    super(props)
    this.handleRequestSort = this.handleRequestSort.bind(this)
    this.desc = this.desc.bind(this)
    this.stableSort = this.stableSort.bind(this)
    this.getSorting = this.getSorting.bind(this)
    this.state = { trials: this.props.trial_search_terms, order: 'asc', orderBy: 'acronym', with_term: this.props.with_term }
  }

  desc(a, b, orderBy) {
    let bOrderKey = b[orderBy]
    let aOrderKey = a[orderBy]
    if (bOrderKey < aOrderKey) {
      return -1;
    }
    if (bOrderKey > aOrderKey) {
      return 1;
    }
    return 0;
  }

  stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = cmp(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
  }

  getSorting(order, orderBy) {
    return order === 'desc' ? (a, b) => this.desc(a, b, orderBy) : (a, b) => -this.desc(a, b, orderBy);
  }

  handleRequestSort(event, property) {
    const isDesc = this.state.orderBy === property && this.state.order === 'desc';

    this.setState({
      order: isDesc ? 'asc' : 'desc',
      orderBy: property
    })
  };

  handleDeleteClick(trialSearchTerm) {
    $.ajax({
      type: 'PUT',
      url: `/admin/trial_search_terms/${trialSearchTerm.id}`,
      data: {
        trial_search_term: {
          deleted: true
        }
      },
      dataType: 'json',
      success: (data) => {
        let index = this.state.trialSearchTerms.indexOf(trialSearchTerm)
        let trialSearchTerms = update(this.state.trialSearchTerms, {
          $splice: [[index,1]]
        })

        this.setState({
          trialSearchTerms: trialSearchTerms
        })
      }
    })
  }

  handleFilterChange(name, value) {
    $.ajax({
      type: 'GET',
      url: '/admin/trial_search_terms',
      data: {
        with_term: value
      },
      dataType: 'json',
      success: (data) => {
        this.setState({
          trialSearchTerms: data.trial_search_terms,
          withTerm: value
        })
      }
    })
  }

  render() {
    let headers = [{ label: 'Search term', name: 'search_term' }, { label: 'Trial', name: 'acronym' }, { label: '', name: '' }]
    return(
      <Fragment>

        <Paper fullHeight>
          <Box height="100%">
            <Flex alignItems='center'>
              <Box>
                <Select
                  label='With term'
                  name='withTerm'
                  value={this.state.withTerm}
                  entities={[{ name: 'No search term', value: "false" }, { name: 'With search term', value: "true" }]}
                  handleChange={this.handleFilterChange}
                />
              </Box>

              <Box ml='auto'>
                <div>
                  <div>{`${this.state.trials.length} trials`}</div>
                </div>
              </Box>
            </Flex>

            <Box overflow='auto' height="90%">
              <Table size='small' aria-label='trials table' stickyHeader={true} id='trials-table'>
                <TableHeadSort
                  headers={headers}
                  order={this.state.order}
                  orderBy={this.state.orderBy}
                  onRequestSort={this.handleRequestSort}
                />

                <TableBody>
                  {this.state.trials.map((trialSearchTerm) =>
                    <TableRow key={trialSearchTerm.id}>
                      <TableCell>{trialSearchTerm.search_term}</TableCell>
                      <TableCell>
                        {trialSearchTerm.acronym}
                      </TableCell>
                      <TableCell>
                        <EditButton component='a' href={`/admin/trials/${trialSearchTerm.id}/trial_search_terms/new`} target="_blank" />
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </Box>
          </Box>
        </Paper>

      </Fragment>
    )
  }
}
