import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Paper from "../../Paper"
import Flex from "../../Flex"
import Box from "../../Box"
import ProductsTable from "./ProductsTable"


export default class Reviewer extends Component {
  constructor(props) {
    super(props)
    this.state = { products: this.props.products }
  }

  render() {
    return(
      <Fragment>

        <Paper fullHeight>
          <Box height='100%'>

            <Flex>
              <Box ml='auto'>
                <div className='mh2'>
                  <Body2>{`${this.state.products.length} products`}</Body2>
                </div>
              </Box>
            </Flex>

            <ProductsTable
              products={this.state.products}
            />

          </Box>
        </Paper>

      </Fragment>
    )
  }
}
