import React from 'react'
import InlineSearchContainer from "../search/InlineSearchContainer"
import Flex from '../../Flex'
import Box from '../../Box'
import Select from "../../Select"

export default function IndexFilters(props) {

  return (
    <Flex>
      <Box mr={3}>
        <Select
          label='Product'
          name='product_id'
          value={props.filterValues.product_id}
          entities={props.filterOptions.products}
          handleChange={props.handleFilterChange}
          includeBlank={true}
        />
      </Box>

      <Box mr={3}>
        <Select
          label='Sector'
          name='sector_id'
          value={props.filterValues.sector_id}
          entities={props.filterOptions.sectors}
          handleChange={props.handleFilterChange}
          includeBlank={true}
        />
      </Box>

      <Box mr={3}>
        <Select
          label='Company'
          name='company_id'
          value={props.filterValues.company_id}
          entities={props.filterOptions.companies}
          handleChange={props.handleFilterChange}
          includeBlank={true}
        />
      </Box>

      <Box>
        <InlineSearchContainer
          searchLabel="Search ictrp trials"
          query={props.filterValues.query}
          handleSearchClick={props.handleSearchClick}
          handleClearSearchClick={props.handleClearSearchClick}
        />
      </Box>
    </Flex>
  )
}
