import React, { Fragment, Component } from "react"
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import MarketsContainer from "./MarketsContainer";

export default class MarketsRouter extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    return(
      <Fragment>
        <Switch>
          <Route
            path={`/sectors/${this.props.sector.abbr}/products/markets`}
            children={<MarketsContainer {...this.props} />}
          />
        </Switch>
      </Fragment>
    )
  }
}
