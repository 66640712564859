import React from "react"
import PropTypes from "prop-types"
import Paper from "../Paper"
import Box from "../Box"
import Flex from "../Flex"
import EntityLayout from "../EntityLayout"
import { Table, TableHead, TableRow, TableCell } from "../tables"
import Link from "../Link"
import Typography from "@material-ui/core/Typography"
import SidebarSection from "../SidebarSection"
import { RaisedButton } from "../buttons"
import { List, ListItemLink, ListItemText } from "../lists"

function ApprovalProfile(props) {

  return(
    <EntityLayout>

      <Box className='w-100 w-60-ns mb3 mb0-ns'>
        <Paper>
          <Box>
            {props.device_approval.url ? 
              <Link href={`/sectors/${props.sector.abbr}/device_approvals/${props.device_approval.id}/url_redirect`} target="_blank">
                View on regulatory site
              </Link>
            : ""}
          </Box>
          <Box p={3}>
            <Table>
              <TableRow>
                <TableCell borderRight>Device</TableCell>
                <TableCell>{props.device_approval.device_name}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell borderRight>Device Info</TableCell>
                <TableCell>{props.device_approval.device_info}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell borderRight>Country</TableCell>
                <TableCell>{props.country.name}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell borderRight>Agency</TableCell>
                <TableCell>{props.regulatory_agency_name}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell borderRight>Regulatory ID</TableCell>
                <TableCell>{props.device_approval.regulatory_id}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell borderRight>Date</TableCell>
                <TableCell>{props.device_approval.date}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell borderRight>Manufacturer</TableCell>
                <TableCell>{props.device_approval.manufacturer}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell borderRight>Description</TableCell>
                <TableCell>{props.device_approval.english_description || props.device_approval.description || "N/A"}</TableCell>
              </TableRow>
            </Table>
            
            {props.device_approval.media_url ? 
              <Box>
                <Flex justifyContent='center' textAlign='center' mt={5}>
                  <Box mb={2} width={1/4}>
                    <RaisedButton href={props.device_approval.media_url} target="_blank">
                      View PDF
                    </RaisedButton>
                  </Box>
                </Flex>
              </Box>
            : ""}

          </Box>
        </Paper>
      </Box>

      
      <Box className='w-100 w-33-ns ml-auto'>
        <Paper> 
          <Box mb={2}>
            <Typography variant='h6' component="h6" fontSize="19px">
              Similar Device Approvals
            </Typography>
          </Box>
          {/* <SidebarSection header='Products'>
            {props.products.map((product) =>
              <Box key={product.id} mb={1}>
                <Link href={`/sectors/${props.sector.abbr}/products/${product.id}`}>
                  {product.short_name}
                </Link>
              </Box>
            )}
          </SidebarSection> */}
          <SidebarSection>
            <List dense>
              {props.other_device_approvals.map((deviceApproval) =>
                <ListItemLink key={deviceApproval.id} href={`/sectors/${props.sector.abbr}/device_approvals/${deviceApproval.id}`}>
                  <ListItemText primary={deviceApproval.device_name} secondary={deviceApproval.name} />
                </ListItemLink>
              )}
            </List>
          </SidebarSection>
        </Paper>
      </Box>
    </EntityLayout>
  )
}


ApprovalProfile.propTypes = {
  deviceApproval: PropTypes.object
};


export default ApprovalProfile
