import React, { Component } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Header from "../../Header.jsx"
import Paper from "../../Paper"

class HeaderContainer extends Component {
  constructor(props) {
    super(props)
  }
  
  render() {
    let tabs = [['List', '/admin/product_profile_field_names'], ['New Profile Field Name', '/admin/product_profile_field_names/new']]
    return (
      <Header 
        header={this.props.header} 
        tabs={tabs} 
        activeTab={this.props.activeTab} 
        background='bg-washed-green' 
      />
    )
  }
}

HeaderContainer.propTypes = {
  activeTab: PropTypes.string
}

export default HeaderContainer
