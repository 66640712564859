import React, { Component } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Highcharts from "highcharts"
// import HighchartsReact from "highcharts-react-official";
import highchartsXRange from "highcharts/modules/xrange";
import HC_more from "highcharts/highcharts-more";

// HC_more(Highcharts);
highchartsXRange(Highcharts);

export default class XRangeChart extends Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    let customTooltip = this.props.tooltipEnabled ? { enabled: true } : { enabled: false }
    Highcharts.setOptions({
      lang: {
        noData: "No data to display",
        thousandsSep: ','
      }
    })
    Highcharts.chart(`${this.props.chartId}`, {
      chart: {
        type: 'xrange'
      },
      credits: {
        enabled: false
      },
      noData:{
        style:{
          fontWeight: 'bold'
        }
      },
      xAxis: {
        type: 'datetime',
      },
      yAxis: {
        title: {
          text: ''
        },
        categories: this.props.categories,
        reversed: true
      },
      legend: {
          enabled: false
      },
      title: {
          text: this.props.title
      },
      subtitle: {
          text: this.props.subtitle
      },
      tooltip: customTooltip,
      series: [{
        name: 'Trial duration',
        data: this.props.data
      }]
    });
  }

  render() {
    return(
      <div id={this.props.chartId}></div>
    )
  }
}

XRangeChart.defaultProps = {
  tooltipEnabled: true
}
