import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Paper from "../../Paper"
import Collapse from '@material-ui/core/Collapse';
import { ListItemText, ListItem } from "../../lists"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ExpandLess from '../../ExpandLess';
import ExpandMore from '../../ExpandMore';
import Typography from "@material-ui/core/Typography"
import Flex from "../../Flex"
import Box from "../../Box"
import { Button } from "../../buttons"

export default class ContentDisplay extends Component {
  constructor(props) {
    super(props)
    this.handleDeleteClick = this.handleDeleteClick.bind(this)
    this.state = { open: false }
  }

  handleDeleteClick(e) {
    this.props.handleDeleteClick(this.props.profileFieldHash.profile_field)
  }

  renderMarkup(content) {
    return {__html: content}
  }

  render() {
    const editUrl = `/admin/companies/${this.props.company.id}/profile_fields/new?old_profile_field_id=${this.props.profileFieldHash.profile_field.id}`
    const user = this.props.profileFieldHash.user
    return(
      <Fragment>

        <ListItem button onClick={() => this.setState({open: !this.state.open})}>
          <ListItemIcon>
            {this.state.open ? <ExpandLess /> : <ExpandMore />}
          </ListItemIcon>

          <ListItemText primary={this.props.profileFieldName} />
        </ListItem>

        <Collapse in={this.state.open} timeout="auto" unmountOnExit>
          <Box mx={3} mt={1} p={3}>
            <div
              dangerouslySetInnerHTML={this.renderMarkup(this.props.profileFieldHash.profile_field.content)}
            />

            <Flex alignItems='center'>
              <Box>
                <Typography variant="caption">
                  <span className='mr3'>
                    ({this.props.profileFieldHash.source_object ? this.props.profileFieldHash.source_object.source_title : "N/A"})
                  </span>
                  <span className='mr3'>
                    ({user ? displayInitials(user.first_name, user.last_name) : 'N/A'})
                  </span>
                </Typography>
              </Box>

              <Box ml='auto'>
                <Button component="a" href={editUrl}>Edit</Button>
              </Box>
            </Flex>

          </Box>
        </Collapse>

      </Fragment>
    )
  }
}
