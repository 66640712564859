import React, { useState } from "react"
import { TableRow, TableCell } from "../../tables"
import IconButton from '@material-ui/core/IconButton';
import EditButton from '../EditButton';
import DeleteButton from '../DeleteButton'
import Link from "../../Link"
import Switch from "../../Switch"
import Box from "../../Box"
import DatePicker from "../../DatePicker"
import AddIcon from '@material-ui/icons/Add';
import FilterChip from "../../filters/FilterChip"
import AlertDisplay from "./Display"

export default function AlertGroupRow(props) {

  function displayEntity() {
    if (props.alert.manuscript_id) {
      return(
        <Link href={`/admin/publications/${props.alert.manuscript_id}`} target="_blank">
          {props.alert.manuscript_title}
        </Link>
      )
    } else if (props.alert.abstract_id) {
      return(
        <Link href={`/admin/abstracts/${props.alert.abstract_id}`} target="_blank">
          {props.alert.abstract_title}
        </Link>
      )
    } else if (props.alert.trial_id) {
      return(
        <Link href={`/admin/trials/${props.alert.trial_id}/edit`} target="_blank">
          {props.alert.acronym || props.alert.short_name}
        </Link>
      )
    } else if (props.alert.product_id) {
      return(
        <Link href={`/admin/products/${props.alert.product_id}/edit`} target="_blank">
          {props.alert.product_name}
        </Link>
      )
    } else if (props.alert.patent_id) {
      return(
        <Link href={`/admin/patents/${props.alert.patent_id}/reviewer`} target="_blank">
          {props.alert.html_patent_no}
        </Link>
      )
    } else if (props.alert.company_id) {
      return(
        <Link href={`/admin/companies/${props.alert.company_id}/edit`} target="_blank">
          {props.alert.company_name}
        </Link>
      )
    }
  }

  function handleEditClick(e) {
    props.handleEditClick(props.alert)
  }

  function handleDeleteClick(e) {
    props.handleDeleteClick(props.alert)
  }

  function handleSwitchChange(e) {
    props.handleSwitchChange(props.alert)
  }

  return (
    <TableRow>
      <TableCell>
        {props.alert.id}
      </TableCell>
      <TableCell>
        {props.alert.sector_abbr}
      </TableCell>
      <TableCell>
        <Box>
          {humanDateFormat(props.alert.date)}
        </Box>
      </TableCell>
      <TableCell>
        <AlertDisplay
          alert={props.alert}
        />
      </TableCell>
      <TableCell>
        {displayEntity()}
      </TableCell>
      <TableCell>
        {props.alert.group_name ? props.alert.group_name : 'N/A'}
      </TableCell>
      <TableCell>
        {props.alert.source_kind ? humanizeString(props.alert.source_kind) : "N/A"}
      </TableCell>
      <TableCell>
        {humanDateFormat(props.alert.source_date)}
      </TableCell>
      {/* <TableCell>
        {props.alert.live ? 'Live' : 'Staged'}
      </TableCell> */}
      <TableCell>
        <Switch
          checked={props.alert.live}
          handleChange={handleSwitchChange}
          value={props.alert.live}
        />
      </TableCell>
      <TableCell>
        {props.status == 'deleted' ? <IconButton onClick={handleDeleteClick}><AddIcon /></IconButton> : <DeleteButton onClick={handleDeleteClick} />}
      </TableCell>
      <TableCell>
        <EditButton onClick={handleEditClick} />
      </TableCell>
    </TableRow>
  )
}
