import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Flex from "../../Flex"
import Box from "../../Box"
import TextInput from "../../TextInput"
import Link from "../../Link"
import Switch from "../../Switch";
import ButtonLayout from "../ButtonLayout"
import RaisedButton from "../../buttons/RaisedButton"
import FormControlLabel from '@material-ui/core/FormControlLabel';
import DatePicker from "../../DatePicker"

class ProfileForm extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <Fragment>

        <Flex mb={3} alignItems="center">
          <Box>
            <FormControlLabel
              control={
                <Switch
                  name='deleted'
                  checked={this.props.trial.deleted}
                  onChange={this.props.handleSwitchChange}
                  value={this.props.trial.deleted}
                />
              }
              label='Deleted'
            />
          </Box>

          <Box ml="auto">
            <FormControlLabel
              control={
                <Switch
                  name='archived'
                  checked={this.props.trial.archived}
                  handleChange={this.props.handleSwitchChange}
                  value={this.props.trial.archived}
                />
              }
              label='Archived'
            />
          </Box>
        </Flex>

        <Box mb={3}>
          <TextInput
            name='full_name'
            label='Full Name'
            value={this.props.full_name}
            onChange={this.props.handleChange}
            fullWidth
          />
        </Box>

        <Box mb={3}>
          <TextInput
            name='short_name'
            label='Short Name'
            value={this.props.short_name}
            onChange={this.props.handleChange}
            fullWidth
          />
        </Box>

        <Box mb={3}>
          <TextInput
            name='acronym'
            label='Acronym'
            value={this.props.acronym}
            onChange={this.props.handleChange}
            fullWidth
          />
        </Box>

        <Box mb={3}>
          <TextInput
            name='nct_id'
            label='NCT ID'
            value={this.props.trial.nct_id}
            onChange={this.props.handleChange}
            dense={true}
            fullWidth
          />
        </Box>

        <Box mb={3}>
          <TextInput
            name='ctri_id'
            label='CTRI ID'
            value={this.props.trial.ctri_id}
            onChange={this.props.handleChange}
            dense={true}
            fullWidth
          />
        </Box>

        <Box mb={3}>
          <TextInput
            name='number_of_arms'
            label='Number Of Arms'
            value={this.props.trial.number_of_arms}
            onChange={this.props.handleChange}
            dense={true}
            fullWidth
          />
        </Box>

        {/* <Box mb={3}>
          <TextInput
            name='phase'
            label='Phase'
            value={this.props.trial.phase}
            onChange={this.props.handleChange}
            dense={true}
            fullWidth
          />
        </Box> */}

        <ButtonLayout>
          <RaisedButton handleClick={this.props.handleSaveClick}>
            Save Trial
          </RaisedButton>
        </ButtonLayout>

      </Fragment>
    )
  }
}

export default ProfileForm
