import React, { Fragment } from "react"
import { List, ListItem, ListItemLink, ListItemText } from "../../lists"
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction"
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import IconButton from "../../IconButton"
import DeleteButton from "../DeleteButton"


export default function EntityListItem(props) {

  function handleEntityClick(e) {
    props.handleEntityClick(props.entity)
  }

  return(
    <Fragment>
      <ListItem selected={props.entity.id == props.activeEntity.id} button onClick={handleEntityClick} divider key={props.entity.id}>

        <ListItemIcon>
          <DeleteButton onClick={props.handleEntityRemoval(props.entity)} />
        </ListItemIcon>

        <ListItemText
          primary={props.displayText}
        />
        <ListItemSecondaryAction>
          {props.activeEntity.id == props.entity.id ? <NavigateBeforeIcon /> : <NavigateNextIcon />}
        </ListItemSecondaryAction>
      </ListItem>
    </Fragment>
  )
}
