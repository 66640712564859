import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import { List, ListItem, ListItemText } from '../lists';
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction"
import IconButton from "../IconButton"
import ExpandMore from "../ExpandMore";
import ExpandLess from '../ExpandLess';
import Collapse from '@material-ui/core/Collapse';


export default class UpdateContentDisplay extends Component {
  constructor(props) {
    super(props)
    this.handleClick = this.handleClick.bind(this)
    this.state = { open: this.props.openAll }
  }

  handleClick(e) {
    e.preventDefault()
    this.setState({
      open: !this.state.open
    })
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.openAll != nextProps.openAll) {
      this.setState({
        open: nextProps.openAll
      })
    }
  }

  render() {
    let attrName = ""
    if (this.props.update.changed_attribute) {
      switch (this.props.update.changed_attribute) {
        case 'clinical_status_id':
          attrName = 'Status'
          break;
        case 'enrollment':
          console.log(this.props.update.model)
          attrName = this.props.update.model == 'trial_target_enrollment' ? 'Target enrollment' : 'Current enrollment'
          break;
        case 'clinical_type_id':
          attrName = 'Type'
          break;
        default:
          attrName = humanizeString(this.props.update.changed_attribute)
          break;
      }
    } else {
      attrName = this.props.update.update_section_name
    }

    return(
      <div className='bb b--black-10 pb2'>
        <ListItem button onClick={this.handleClick}>
          <ListItemText
            primary={attrName}
          />
          <ListItemSecondaryAction>
            <IconButton onClick={this.handleClick} tooltip='expand' edge="end" aria-label="expand">
              {this.state.open ? <ExpandLess /> : <ExpandMore />}
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>

        <Collapse in={this.state.open} timeout="auto" unmountOnExit>
          <div className='mh3 mt1 pa3' style={{backgroundColor: '#F8EDED'}}>
            <div
              dangerouslySetInnerHTML={{__html: this.props.update.content}}
            />
          </div>
        </Collapse>
      </div>
    )
  }
}
