import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Paper from "../../Paper"
import Form from "./Form"
import update from "immutability-helper"
import Snackbar from "../../Snackbar"

export default class NewContainer extends Component {
  constructor(props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
    this.handleSaveClick = this.handleSaveClick.bind(this)
    this.handleDateChange = this.handleDateChange.bind(this)
    this.handleCompanyChange = this.handleCompanyChange.bind(this)
    this.state = { investorPresentation: this.props.investor_presentation, showSnackbar: false, snackbarText: '' }
  }

  componentDidMount() {
    const investorPresentation = update(this.state.investorPresentation, { created_by: { $set: this.props.current_user.id }})

    this.setState({
      investorPresentation: investorPresentation
    })
  }

  handleCompanyChange(name, value) {
    let investorPresentation = update(this.state.investorPresentation, {
      company_id: { $set: value }
    })

    this.setState({
      investorPresentation: investorPresentation
    })
  }

  handleDateChange(date) {
    const investorPresentation = update(this.state.investorPresentation, {
      date: { $set: date }
    })

    this.setState({
      investorPresentation: investorPresentation
    })
  }

  handleChange(e) {
    const name = e.target.name
    const value = e.target.value

    const investorPresentation = update(this.state.investorPresentation, { [name]: { $set: value }})

    this.setState({
      investorPresentation: investorPresentation
    })
  }

  handleSaveClick(e) {
    const url = this.props.redirect_url ? `/admin/investor_presentations?r=${this.props.redirect_url}` : "/admin/investor_presentations"
    $.post(url, { investor_presentation: this.state.investorPresentation }, () => {
      window.location.href = url
    }, 'json').fail((data) => {
      this.setState({
        showSnackbar: true,
        snackbarText: `The investor presentation could not be created. ${data.responseText}.`
      })
    }).always(() => {
      setTimeout(() => {
        this.setState({
          showSnackbar: false,
          snackbarText: ''
        })
      }, 4000)
    })
  }

  render() {
    return(
      <Fragment>

        <Paper fullHeight>

          <Form
            investorPresentation={this.state.investorPresentation}
            companies={this.props.companies}
            handleChange={this.handleChange}
            handleDateChange={this.handleDateChange}
            handleSaveClick={this.handleSaveClick}
            handleCompanyChange={this.handleCompanyChange}
          />


        </Paper>

        <Snackbar
          open={this.state.showSnackbar}
          message={this.state.snackbarText}
        />
      </Fragment>
    )
  }
}
