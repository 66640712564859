import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import CompanyCard from "./CompanyCard.jsx"
import EntityLayout from "../EntityLayout"
import Box from "../Box"
import Paper from "../Paper"
import ProfileFieldsContainer from "./ProfileFieldsContainer"


class Profile extends Component {
  constructor(props) {
    super(props)
    this.handleFavoriteClick = this.handleFavoriteClick.bind(this)
    this.state = { userCompany: this.props.user_company }
  }
  
  handleFavoriteClick(e) {
    if (this.state.userCompany) {
      $.ajax({
        url: `/admin/user_company_favorites/${this.state.userCompany.id}`,
        type: 'DELETE',
        dataType: 'json',
        success: (data) => {
          this.setState({
            userCompany: data
          })
        },
        error: (data) => {
          window.flash_messages.addMessage({ id: 'id', text: 'The company could not be removed.', type: 'error' })
        }
      })
    } else {
      $.ajax({
        url: `/admin/user_company_favorites`,
        type: 'POST',
        data: {
          user_company_favorite: {
            company_id: this.props.company.id, user_id: 1
          }
        },
        dataType: 'json',
        success: (data) => {
          this.setState({
            userCompany: data
          })
        }
      })
    }
  }
  
  
  render() {
    return(
      <Fragment>
        
        <EntityLayout>
          
          <Box className='w-100 w-60-ns mb3 mb0-ns'>
            <ProfileFieldsContainer 
              profileFields={this.props.profile_fields} 
              company={this.props.company}
              sector={this.props.sector}
            />
          </Box>
          
          <Box className='w-100 w-33-ns ml-auto'>
            <CompanyCard {...this.props} />
          </Box>
          
        </EntityLayout>

      </Fragment>
    );
  }
}

Profile.propTypes = {
  company: PropTypes.object
}

export default Profile
