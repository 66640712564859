import React, { Component, Fragment } from "react"
import PropTypes from "prop-types"
import ReactDOM from "react-dom"
import Paper from "../Paper"
import Box from "../Box"
import NetworkFilters from "./NetworkFilters"
import NetworkChart from "./NetworkChart"
import NetworkTabs from "./NetworkTabs"
import NetworkDataTable from "./NetworkDataTable"
import Snackbar from "../Snackbar"


export default class NetworkContainer extends Component {
  constructor(props) {
    super(props)
    this.handleFilterChange = this.handleFilterChange.bind(this)
    this.handleActiveIndexUpdate = this.handleActiveIndexUpdate.bind(this)
    this.getNetworkData = this.getNetworkData.bind(this)
    this.state = { networkData: this.props.network_data, chartData: this.props.chart_data, categories: this.props.categories, chartDisplayType: this.props.chart_display_options[1], filterOptions: { chartEntityTypes: this.props.chart_entity_types, dates: this.props.dates }, filterValues: { chart_entity_type: this.props.chart_entity_type, time_period: this.props.time_period }, activeIndex: 0, showSnackbar: false, snackbarText: '' }
  }

  handleFilterChange(name, value) {
    let params = Object.assign({}, this.state.filterValues)

    params[name] = value

    this.getNetworkData(params)
  }

  getNetworkData(params) {
    $.ajax({
      type: 'GET',
      url: `/sectors/${this.props.sector.abbr}/people/${this.props.person.id}/network`,
      data: params,
      dataType: 'json',
      success: (data) => {
        this.setState({
          networkData: data.network_data,
          chartData: data.chart_data,
          categories: data.categories,
          filterValues: params
        })
      },
      error: (data) => {
        this.setState({
          showSnackbar: true,
          snackbarText: 'There was an error. Please try again'
        })
      },
      complete: (data) => {
        setTimeout(() => {
          this.setState({
            showSnackbar: false,
            snackbarText: ''
          })
        })
      }
    })
  }

  handleActiveIndexUpdate(e, newValue) {
    this.setState({
      activeIndex: newValue
    })
  }

  render() {
    return(
      <Fragment>

        <Paper>

          <Box mb={3}>
            <NetworkFilters
              filterOptions={this.state.filterOptions}
              filterValues={this.state.filterValues}
              handleFilterChange={this.handleFilterChange}
            />
          </Box>

          <Box mb={2}>
            <NetworkChart
              chartData={this.state.chartData}
              categories={this.state.categories}
              chartDisplayType={this.state.chartDisplayType}
              chartEntityType={this.state.filterValues.chart_entity_type}
            />
          </Box>

          <Box textAlign='center'>
            <NetworkTabs
              activeIndex={this.state.activeIndex}
              handleActiveIndexUpdate={this.handleActiveIndexUpdate}
            />
          </Box>

          <Box>
            <NetworkDataTable
              networkData={this.state.networkData}
              chartEntityType={this.state.filterValues.chart_entity_type}
              sector={this.props.sector}
            />
          </Box>

        </Paper>

        <Snackbar
          open={this.state.showSnackbar}
          message={this.state.snackbarText}
        />
      </Fragment>
    )
  }
}
