import React, { Component } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Highcharts from "highcharts"
// import HighchartsReact from "highcharts-react-official";
import highchartsDumbbell from "highcharts/modules/dumbbell";
// import HC_more from "highcharts/highcharts-more";

// HC_more(Highcharts);
highchartsDumbbell(Highcharts);

export default class DumbbellChart extends Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    let customTooltip = this.props.tooltipEnabled ? { enabled: true } : { enabled: false }
    // let customTooltip = this.props.tooltipEnabled ? { headerFormat: this.props.tooltip.headerFormat, pointFormat: this.props.tooltip.pointFormat, footerFormat: this.props.tooltip.footerFormat } : { enabled: true }
    Highcharts.setOptions({
      lang: {
        noData: "No data to display",
        thousandsSep: ','
      }
    })
    Highcharts.chart(`${this.props.chartId}`, {
    chart: {
        zoomType: 'x',
        type: 'dumbbell',
        inverted: true
    },
    credits: {
      enabled: false
    },
    noData:{
      style:{
        fontWeight: 'bold'
      }
    },
    xAxis: {
      type: 'category',
    },
    yAxis: {
      title: {
        text: "Trial Duration"
      }
    },
    legend: {
        enabled: false
    },
    title: {
        text: this.props.title
    },
    subtitle: {
        text: this.props.subtitle
    },
    tooltip: customTooltip,
    series: [{
        name: 'Trial duration',
        data: this.props.formattedData
      }]
    });
  }

  render () {
    return(
      <div id={this.props.chartId}></div>
    )
  }
}

DumbbellChart.defaultProps = {
  tooltipEnabled: true
}
