import React from "react"
import PropTypes from "prop-types"
import Paper from "../Paper"
import Box from "../Box"
import Flex from "../Flex"
import { withSnackbar } from '../SnackbarContainer'
import ListingsCountDisplay from '../ListingsCountDisplay'
import update from "immutability-helper"
import ApprovalsTable from "../devices/ApprovalsTable"
import InlineSearchContainer from '../admin/search/InlineSearchContainer'
import CountryAutoComplete from "../filters/CountryAutocomplete"


class ApprovalsContainer extends React.Component {
  constructor(props) {
    super(props)
    this.handleSearchClick= this.handleSearchClick.bind(this)
    this.getDeviceApprovals = this.getDeviceApprovals.bind(this)
    this.handleClearSearchClick = this.handleClearSearchClick.bind(this)
    this.handleFilterChange = this.handleFilterChange.bind(this)
    this.handleSearchSubmit = this.handleSearchSubmit.bind(this)
    this.handleClearClick = this.handleClearClick.bind(this)
    this.handleCountrySearchChange = this.handleCountrySearchChange.bind(this)
    this.state = { deviceApprovals: this.props.device_approvals, deviceApprovalsCount: this.props.device_approvals_count, filterValues: { query: this.props.query, country_id: this.props.country_id } }
  }

  handleSearchSubmit(query) {
    let params = Object.assign({}, this.state.filterValues)

    params["query"] = query

    this.getDeviceApprovals(params)
  }

  getDeviceApprovals(params) {
    $.ajax({
      type: "GET",
      url: `/sectors/${this.props.sector.abbr}/products/${this.props.product.id}/device_approvals`,
      data: params,
      dataType: 'json',
      success: (data) => {
        this.setState({
          deviceApprovals: data.device_approvals,
          deviceApprovalsCount: data.device_approvals_count,
          filterValues: params
        })
      },
      error: (data) => {
        console.log(data)
        this.props.snackbarShowMessage("Devices could not be loaded. Please try again.", "error")
      }
    })
  }

  handleClearClick(e) {
    console.log("hello")
  }

  handleFilterChange(name, value) {
    let params = Object.assign({}, this.state.filterValues)

    // params["order_by"] = this.state.orderBy
    // params["order"] = this.state.order
    params[name] = value

    this.getDeviceApprovals(params)
  }

  handleSearchClick(query) {
    let params = Object.assign({}, this.state.filterValues)

    params["query"] = query

    this.getDeviceApprovals(params)
  }

  handleClearSearchClick(e) {
    let params = Object.assign({}, this.state.filterValues)

    params["query"] = null

    this.getDeviceApprovals(params)
  }

  handleCountrySearchChange(e, selected) {
    let params = Object.assign({}, this.state.filterValues)

    params['country_id'] = selected.value

    this.getDeviceApprovals(params)
  }


  render() {

    let countryValue = this.state.filterValues.country_id ? { value: this.state.filterValues.country_id, name: this.props.countries.find((country) => { return country.value == this.state.filterValues.country_id }).name } : ''

    console.log(countryValue)
    return (
      <React.Fragment>
        <Paper fullHeight>
          <Box height="100%">
            
            <Flex alignItems='center'>
              <Box width={1/5} mr={2}>
                <CountryAutoComplete 
                  countries={this.props.countries}
                  countryValue={countryValue}
                  handleCountrySearchChange={this.handleCountrySearchChange}
                />
              </Box>
            </Flex>

            <ListingsCountDisplay 
              display={`${this.state.deviceApprovalsCount} Device Approvals`}
            />

            <Box overflow="auto" height="80%">

              <ApprovalsTable 
                deviceApprovals={this.state.deviceApprovals}
                page={this.state.filterValues.page}
                sector={this.props.sector}
                deviceApprovalsCount={this.state.deviceApprovalsCount}
                handleDeleteClick={this.handleDeleteClick}
              />

            </Box>
          </Box>
        </Paper>
      </React.Fragment>
    );
  }
}

ApprovalsContainer.propTypes = {
  deviceApprovals: PropTypes.array
};

export default withSnackbar(ApprovalsContainer)
