import React, { Component, Fragment } from "react"
import TrialsTable from "./TrialsTable"
import Filters from "./Filters"
import Typography from "@material-ui/core/Typography"
import Flex from "../Flex"
import Box from "../Box"
import update from 'immutability-helper'
import FilterChipsContainer from "./FilterChipsContainer"
import { withRouter } from "react-router-dom";
import CircularProgress from '@material-ui/core/CircularProgress';


class TableContainer extends Component {
  constructor(props) {
    super(props)
    this.handleFilterChange = this.handleFilterChange.bind(this)
    // this.onScroll = this.onScroll.bind(this)
    this.loadTrials = this.loadTrials.bind(this)
    this.handleBookmarkClick = this.handleBookmarkClick.bind(this)
    this.handleDelete = this.handleDelete.bind(this)
    this.getTrials = this.getTrials.bind(this)
    this.handleChangePage = this.handleChangePage.bind(this)
    this.handleFilterExpandClick = this.handleFilterExpandClick.bind(this)
    this.handleBulkTrialCheck = this.handleBulkTrialCheck.bind(this)
    this.handleExpandClick = this.handleExpandClick.bind(this)
    this.handleDownloadClick = this.handleDownloadClick.bind(this)
    this.state = { isLoading: false, error: false, hasMore: true, trials: this.props.trials, trialsCount: this.props.trials_count, trialsToCompare: [], filterOptions: { companies: this.props.companies, products: this.props.products, targetMarkets: this.props.target_markets, therapyTypes: this.props.therapy_types, clinicalTypes: this.props.clinical_types, statuses: this.props.statuses, enrollmentOptions: this.props.enrollment_options, targetSites: this.props.target_sites }, filterValues: { company_ids: [], sponsor_ids: [], product_ids: [], target_market_ids: [], therapy_type_ids: [], clinical_type_ids: [], status_ids: [], enrollment_numbers: [], target_site_ids: [] }, chosen: { products: this.props.chosen_products, statuses: this.props.chosen_statuses, therapyTypes: this.props.chosen_therapy_types, targetMarkets: this.props.chosen_target_markets, clinicalTypes: this.props.chosen_clinical_types, companies: this.props.chosen_companies, targetSites: this.props.chosen_target_sites }, page: 0, rowsPerPage: 100, showFilters: false, trialsToCompare: [], expandedTrial: null, checkedTrials: [], loaded: false }
  };

  componentDidMount() {
    $.ajax({
      type: 'GET',
      url: `/sectors/${this.props.sector.abbr}/trials`,
      dataType: 'json',
      success: (data) => {

        let filterOptions = { companies: data.companies, products: data.products, targetMarkets: data.target_markets, therapyTypes: data.therapy_types, clinicalTypes: data.clinical_types, statuses: data.statuses, enrollmentOptions: data.enrollment_options, targetSites: data.target_sites }

        let chosen = { companies: data.chosen_companies, products: data.chosen_products, targetMarkets: data.chosen_target_markets, therapyTypes: data.chosen_therapy_types, clinicalTypes: data.chosen_clinical_types, statuses: data.chosen_statuses, targetSites: data.chosen_target_sites }

        this.setState({
          openDialog: true,
          trials: data.trials,
          trialsCount: data.trials_count,
          loaded: true,
          filterOptions,
          chosen
        })
      }
    })
  }

  handleExpandClick(trialId) {
    this.setState({
      expandedTrial: trialId == this.state.expandedTrial ? null : trialId
    })
  }

  handleFilterExpandClick(e) {
    const newShowFilterState = !this.state.showFilters

    this.setState({
      showFilters: newShowFilterState
    })
  }

  handleDownloadClick(e) {
    console.log("helo")
  }

  handleBulkTrialCheck(e) {
    if (this.state.checkedTrials.length > 0) {
      this.setState({
        checkedTrials: []
      })
    } else {
      const trialIds = this.state.trials.map((trial) => trial.id)

      this.props.handleBulkTrialCheckboxClick(trialIds)
      // this.setState({
      //   checkedTrials: trialIds
      // })
    }
  }

  handleChangeRowsPerPage(e) {
    console.log(e)
  }

  handleChangePage(e, newPage) {
    this.loadTrials(newPage)
  }

  loadTrials(newPage) {
    let pageParam = { page: newPage + 1 }
    let params = {...this.state.filterValues, ...pageParam}
    $.ajax({
      type: 'GET',
      url: `/sectors/${this.props.sector.abbr}/trials`,
      data: params,
      dataType: 'json',
      beforeSend: (smthing) => {
        this.setState({
          isLoading: true
        })
      },
      success: (data) => {
        // let trials = [...this.state.trials, ...data.trials]

        let filterOptions = { companies: data.companies, products: data.products, targetMarkets: data.target_markets, therapyTypes: data.therapy_types, clinicalTypes: data.clinical_types, statuses: data.statuses, enrollmentOptions: data.enrollment_options, targetSites: data.target_sites }

        this.setState({
          trials: data.trials,
          page: newPage,
          filterOptions
        })
      },
      error: (xhjr) => {
        this.setState({
          error: true
        })
      },
      complete: (smthing) => {
        this.setState({
          isLoading: false
        })
      }
    })
  }

  handleDelete(value, type) {
    const index = this.state.filterValues[type].indexOf(value)

    const filterValues = update(this.state.filterValues, {
      [type]: { $splice: [[index, 1]] }
    })

    this.getTrials(filterValues)
  }

  handleBookmarkClick(e) {

  }

  handleFilterChange(name, value) {
    let params = Object.assign({}, this.state.filterValues)

    if (value.includes(null)) {
      params[name] = []
    } else {
      params[name] = value
    }

    this.getTrials(params)
  }

  getTrials(params) {
    let url = `/sectors/${this.props.sector.abbr}/trials`

    $.ajax({
      type: 'GET',
      url: url,
      data: params,
      dataType: 'json',
      success: (data) => {
        let filterOptions = { companies: data.companies, products: data.products, targetMarkets: data.target_markets, therapyTypes: data.therapy_types, clinicalTypes: data.clinical_types, statuses: data.statuses, enrollmentOptions: data.enrollment_options, targetSites: data.target_sites }

        let chosen = { companies: data.chosen_companies, products: data.chosen_products, targetMarkets: data.chosen_target_markets, therapyTypes: data.chosen_therapy_types, clinicalTypes: data.chosen_clinical_types, statuses: data.chosen_statuses, targetSites: data.chosen_target_sites }

        this.setState({
          trials: data.trials,
          trialsCount: data.trials_count,
          filterValues: params,
          filterOptions,
          chosen
        })
      },
      error: (data) => {
        console.log(data.responseText)
        this.setState({
          showSnackbar: true,
          snackbarText: "The trials could not be found. We've been notified and are looking into it"
        })
      }
    })
  }

  render () {
    if (this.state.loaded) {
      return (

        <Fragment>

          <Box height="100%">

            <Box mb={3}>
              <Filters
                filterOptions={this.state.filterOptions}
                filterValues={this.state.filterValues}
                forSearch={false}
                sector={this.props.sector}
                currentUser={this.props.currentUser}
                handleFilterChange={this.handleFilterChange}
              />
            </Box>

            <Flex flexWrap='wrap' my={2} minHeight='28px' alignItems='center'>

              <FilterChipsContainer
                filterOptions={this.state.filterOptions}
                filterValues={this.state.filterValues}
                chosen={this.state.chosen}
                handleDelete={this.handleDelete}
              />

              <Box ml='auto'>

                <Flex alignItems='center'>

                  <Box ml='auto'>
                    <Typography variant="body2">
                      {`${this.state.trialsCount} trials`}
                    </Typography>
                  </Box>

                </Flex>
              </Box>

            </Flex>

            <TrialsTable
              trials={this.state.trials}
              trialsCount={this.state.trialsCount}
              page={this.state.page}
              sector={this.props.sector}
              choose={this.props.choose}
              forCompare={this.props.for_compare}
              checkedTrials={this.props.checkedTrials}
              expandedTrial={this.state.expandedTrial}
              handleTrialCheckboxClick={this.props.handleTrialCheckboxClick}
              handleBulkTrialCheck={this.handleBulkTrialCheck}
              handleExpandClick={this.handleExpandClick}
              handleChangePage={this.handleChangePage}
              handleChangeRowsPerPage={this.handleChangeRowsPerPage}
            />

          </Box>
        </Fragment>
      );
    } else {
      return(
        <Flex justifyContent='center'>
          <Box>
            <CircularProgress />
          </Box>
        </Flex>
      )
    }
  }
}


export default withRouter(TableContainer)
