import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Paper from "../../Paper"
import { Table, TableHead, TableRow, TableCell, TableBody, EmptyDataTableRow } from "../../tables"
import InternalDocumentRow from "./InternalDocumentRow"
import update from "immutability-helper"
import Snackbar from "../../Snackbar"

export default class InternalDocuments extends Component {
  constructor(props) {
    super(props)
    this.handleDeleteClick = this.handleDeleteClick.bind(this)
    this.handleUndoClick = this.handleUndoClick.bind(this)
    this.state = { internalDocuments: this.props.internal_documents, showSnackbar: false, snackbarText: '', lastDeleted: '' }
  }
  
  handleUndoClick(e) {
    if (this.state.lastDeleted) {
      $.ajax({
        type: 'PUT',
        url: `/admin/internal_documents/${this.state.lastDeleted.id}`,
        data: {
          internal_document: {
            deleted: null, deleted_by: null
          }
        },
        dataType: 'json',
        success: (data) => {
          const internalDocuments = update(this.state.internalDocuments, { $push: [this.state.lastDeleted] })
          this.setState({
            internalDocuments: internalDocuments,
            showSnackbar: true,
            snackbarText: 'Internal document was undeleted',
            lastDeleted: null
          })
        },
        error: (data) => {
          this.setState({
            showSnackbar: true,
            snackbarText: data.responseText
          })
        },
        completed: (data) => {
          setTimeout(() => {
            this.setState({
              showSnackbar: false,
              snackbarText: ''
            })
          }, 4000)
        }
      })
    }
  }
  
  handleDeleteClick(internalDocument) {
    $.ajax({
      type: 'PUT',
      url: `/admin/internal_documents/${internalDocument.id}`,
      data: {
        internal_document: {
          deleted: true, deleted_by: this.props.current_user
        }
      },
      dataType: 'json',
      success: (data) => {
        const index = this.state.internalDocuments.indexOf(internalDocument)
        let internalDocuments = update(this.state.internalDocuments, { $splice: [[index,1]] })
        this.setState({
          internalDocuments: internalDocuments,
          showSnackbar: true,
          snackbarText: 'Internal document was deleted',
          lastDeleted: internalDocument
        })
      },
      error: (data) => {
        this.setState({
          showSnackbar: true,
          snackbarText: `Internal document could not be deleted. ${data.responseText}`
        })
      },
      complete: (data) => {
        setTimeout(() => {
          this.setState({
            showSnackbar: false,
            snackbarText: ''
          })
        }, 4000)
      }
    })
  }
  
  render() {
    return(
      <Fragment>
        <Fragment>
          
          <Paper>
            <Table className='w-100'>
              <TableHead>
                <TableRow>
                  <TableCell>Document</TableCell>
                  <TableCell>Created By</TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              
              <TableBody>
                {this.state.internalDocuments.length > 0 ? this.state.internalDocuments.map((internalDocument) =>
                  <InternalDocumentRow
                    key={internalDocument.id} 
                    internalDocument={internalDocument}
                    handleDeleteClick={this.handleDeleteClick}
                  />
                ) : <EmptyDataTableRow colSpan={4} message='No internal documents yet. Create a internal document and it will display here' />}
              </TableBody>
              
            </Table>
          </Paper>
          
          <Snackbar 
            open={this.state.showSnackbar}
            handleUndoClick={this.handleUndoClick}
            handleClose={this.handleClose}
            message={this.state.snackbarText} 
          />
        </Fragment>
      </Fragment>
    )
  }
}
