import React, { Fragment } from "react"
import RegionValueListItem from "../characteristic_values/RegionValueListItem"


export default function RegionDisplayContainer(props) {
  return (
    <Fragment>
      {props.characteristicGroup.characteristic_values.map((characteristicValue) =>
        <RegionValueListItem 
          key={characteristicValue.id} 
          characteristicValue={characteristicValue}
          characteristicGroup={props.characteristicGroup} 
          productCharacteristic={props.productCharacteristics.filter((productCharacteristic) => productCharacteristic.characteristic_value_id == characteristicValue.id)[0]}
          checked={props.productCharacteristics.filter((productCharacteristic) => productCharacteristic.characteristic_value_id == characteristicValue.id && productCharacteristic.world_region_id == props.characteristicGroup.world_region_id)[0] ? true : false}
          handleCheckboxSelect={props.handleCheckboxSelect}
          handleCheckboxRegionSelect={props.handleCheckboxRegionSelect}
        />)}
    </Fragment>
  )
}
