import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import CompaniesTable from "./CompaniesTable"
import Paper from "../../Paper"
import Flex from "../../Flex"
import Box from "../../Box"
import Typography from "@material-ui/core/Typography"


export default class Index extends Component {
  constructor(props) {
    super(props)
    this.state = { companies: this.props.companies }
  }

  render() {
    return(
      <Fragment>

        <Paper>
          <div>
            <div className='mb4'>

              {/* <Filters
                filterOptions={this.state.filterOptions}
                filterValues={this.state.filterValues}
                sector={this.props.sector}
                handleFilterChange={this.handleFilterChange}
              /> */}

            </div>

            <Flex>
              <Box>
                <div className='mh2'>
                  <Typography variant="caption">{`${this.state.companies.length} companies`}</Typography>
                </div>
              </Box>
            </Flex>

            <CompaniesTable
              companies={this.state.companies}
              sector={this.props.sector}
            />

          </div>
        </Paper>

      </Fragment>
    )
  }
}
