import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import TrialCollaboratorRow from "./TrialCollaboratorRow"
import Flex from "../../Flex"
import Box from "../../Box"
import Typography from '@material-ui/core/Typography';
import Snackbar from "../../Snackbar"
import { Table, TableHead, TableBody, TableCell, TableRow } from "../../tables"
import update from "immutability-helper"
import Select from "../../Select"

class CollaboratorForm extends Component {
  constructor(props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
    this.handleDeleteClick = this.handleDeleteClick.bind(this)
    this.state = { trialCollaborators: [], showSnackbar: false, snackbarText: '' }
  }
  
  componentDidMount() {
    $.get(`/admin/trials/${this.props.trial.id}/collaborators`, {}, (data) => {
      this.setState({
        trialCollaborators: data.trial_collaborators
      })
    }, 'json')
  }
  
  handleChange(name, value) {
    if (this.props.source) {
      $.post("/admin/trial_collaborators", {trial_collaborator: { trial_id: this.props.trial.id, company_id: value, source_id: this.props.source.source_id, created_by: this.props.currentUser.id }}, (data) => {
        let trialCollaborators = update(this.state.trialCollaborators, { $push: [data.trial_collaborator]})
        this.setState({
          trialCollaborators: trialCollaborators,
          showSnackbar: true,
          snackbarText: 'Collaborator has been added'
        })
      }, 'json').fail(() => {
        this.setState({
          showSnackbar: true,
          snackbarText: `Collaborator could not be added. Please try again.`
        })
      }).always(() => {
        setTimeout(() => {
          this.setState({
            showSnackbar: false,
            snackbarText: ''
          })
        }, 4000)
      })
    } else {
      this.setState({
        showSnackbar: true,
        snackbarText: 'You must choose a source before adding a collaborator'
      })
      setTimeout(() => {
        this.setState({
          showSnackbar: false,
          snackbarText: ''
        })
      }, 4000)
    }
  }
  
  handleDeleteClick(trialCollaboratorHash) {
    $.ajax({
      type: 'PUT',
      url: `/admin/trial_collaborators/${trialCollaboratorHash.trial_collaborator.id}`,
      data: {
        trial_collaborator: {
          deleted: true, updated_by: this.props.currentUser.id
        }
      },
      dataType: 'json',
      success: (data) => {
        let index = this.state.trialCollaborators.indexOf(trialCollaboratorHash)
        let trialCollaborators = update(this.state.trialCollaborators, { $splice: [[index, 1]]})
        this.setState({
          trialCollaborators: trialCollaborators,
          showSnackbar: true,
          snackbarText: 'The collaborator record was removed'
        })
      },
      error: (data) => {
        this.setState({
          showSnackbar: true,
          snackbarText: `The collaborator could not be removed. Please try again.`
        })
      },
      complete: () => {
        this.setState({
          showSnackbar: false,
          snackbarText: ''
        })
      }
    })
  }
  
  render() {
    return(
      <Fragment>
        <Typography variant="h6" component="h6">
          New Collaborator
        </Typography>
        
        <Flex alignItems='center' mt={1} width={1}>
          
          <Box className='w-20'>
            <Select  
              entities={this.props.companies}
              label='Company'
              displayEmpty={true}
              handleChange={this.handleChange} 
            />
          </Box>
        </Flex>
        
        <Box mt={4}>
          <Typography variant="h6" component="h6">
            Collaborators
          </Typography>
          
          <Table>
            
            <TableHead>
              <TableRow>
                <TableCell>Collaborator</TableCell>
                <TableCell>Source</TableCell>
                <TableCell>User</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            
            <TableBody>
              {this.state.trialCollaborators.length > 0 ? this.state.trialCollaborators.map((trialCollaboratorHash) =>
                <TrialCollaboratorRow 
                  key={trialCollaboratorHash.trial_collaborator.id}
                  trialCollaboratorHash={trialCollaboratorHash} 
                  handleDeleteClick={this.handleDeleteClick}
                />
              ) : <TableRow><TableCell colSpan={4}>No collaborators found. Add one above.</TableCell></TableRow>}
            </TableBody>
            
          </Table>
        
        </Box>
        
        <Snackbar 
          open={this.state.showSnackbar}
          message={this.state.snackbarText} 
        />
        
      </Fragment>
    )
  }
}

export default CollaboratorForm
