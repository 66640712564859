import React from "react"
import { Table, TableHead, TableCell, TableBody, TableRow } from "../../tables"
import EditButton from "../EditButton"
import DeleteButton from "../DeleteButton"
import Link from "../../Link"
import Box from "../../Box"

export default function TrialRow(props) {

  function handleDeleteClick(e) {
    props.handleDeleteClick(props.trial, props.trial.trial_sector_id)
  }

  // const trialUrl = `/sectors/${props.sector.abbr}/trials/${props.trial.id}`

  const editUrl = `/admin/trials/${props.trial.trial_id}/edit`
  return(
    <TableRow>
      <TableCell>
        {/* <Link href={trialUrl}> */}
        {props.trial.short_name}
        {/* </Link> */}
      </TableCell>
      <TableCell>
        <Box>
          {props.trial.sponsor_name}
        </Box>
      </TableCell>
      <TableCell>{props.trial.normalized_score}</TableCell>
      <TableCell>{dateFormat(props.trial.updated_at)}</TableCell>
      <TableCell>
        <EditButton component="a" href={editUrl} />
      </TableCell>
      <TableCell>
        <DeleteButton onClick={handleDeleteClick} />
      </TableCell>
    </TableRow>
  )
}
