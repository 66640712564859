import React from 'react'
import { Table, TableBody, TableCell, TableRow, TableHead } from '../../../tables'
import AssociatedRow from './AssociatedRow'

export default function AssociatedTable(props) {
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell />
          <TableCell className='bg-white'>510(k) #</TableCell>
          <TableCell className='bg-white'>Device</TableCell>
          <TableCell className='bg-white'>Applicant</TableCell>
          <TableCell className='bg-white'>Product Code</TableCell>
          <TableCell className='bg-white'>Date Received</TableCell>
          <TableCell className='bg-white'>Sectors</TableCell>
          <TableCell className='bg-white'></TableCell>
        </TableRow>
      </TableHead>

      <TableBody>
        {Object.keys(props.kClearances).map((kClearanceId) =>
          <AssociatedRow 
            key={kClearanceId}
            kClearances={props.kClearances[kClearanceId]}
            checkedKClearances={props.checkedKClearances}
            handleDeleteClick={props.handleDeleteClick}
            handleSectorDelete={props.handleSectorDelete}
            handleKClearanceCheckboxClick={props.handleKClearanceCheckboxClick}
          />
        )}
      </TableBody>
    </Table>
  )
}