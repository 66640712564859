import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import { Table, TableHead, TableRow, TableCell, TableBody } from "../../tables"
import ExpandLess from '../../ExpandLess';
import ExpandMore from '../../ExpandMore';
import Button from "../../buttons/Button"
import Link from "../../Link"
import IndeterminateCheckBoxIcon from '@material-ui/icons/IndeterminateCheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import IconButton from "../../IconButton"
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Checkbox from '@material-ui/core/Checkbox';
import MuiIconButton from '@material-ui/core/IconButton';


export default function TriageRow(props) {

  let selected = props.checkedAbstracts.includes(props.abstract.id)

  let startTime = props.abstract.start_time ? new Date(props.abstract.start_time) : null

  return(
    <TableRow>
      <TableCell borderLeft borderRight padding='checkbox' className={selected ? '' : 'o-50'}>
        <MuiIconButton onClick={() => props.handleAbstractCheckboxClick(props.abstract.id)} edge="start">
          {selected ? <CheckBoxIcon color='primary' style={{height: '22px', width: '22px'}} /> : <CheckBoxOutlineBlankIcon style={{height: '22px', width: '22px'}} />}
        </MuiIconButton>
      </TableCell>
      <TableCell borderRight>
        {props.abstract.number ? props.abstract.number : ''}
      </TableCell>
      <TableCell borderRight>
        {props.abstract.date ? humanDateFormat(props.abstract.date) : ''}
      </TableCell>
      <TableCell borderRight>
        {startTime ? `${startTime.getHours()}:${(startTime.getMinutes()<10?'0':'') + startTime.getMinutes()}` : ''}
      </TableCell>
      <TableCell borderRight>
        <Link href={`/admin/abstracts/${props.abstract.id}`} target="_blank">
          {props.abstract.title ? props.abstract.title : 'N/A'}
        </Link>
      </TableCell>
      <TableCell borderRight>
        {props.abstract.presenter ? props.abstract.presenter : 'N/A'}
      </TableCell>
      <TableCell borderRight className='red'>
        {props.abstract.pdf ? 'YES' : ''}
      </TableCell>
      <TableCell borderRight>
        {props.abstract.priority_id ? props.abstract.priority_id : ''}
      </TableCell>
      <TableCell borderRight>
        {props.abstract.reporter_user_id ? `${props.abstract.first_name[0]}${props.abstract.last_name[0]}` : ''}
      </TableCell>
    </TableRow>
  )
}
