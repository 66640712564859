import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import { Card, CardSection, CardSectionHeader } from "../../cards"
import SidebarSection from "../../SidebarSection"
import Link from "../../Link"

export default class ProfileCard extends Component {
  constructor(props) {
    super(props)
  }
  
  render() {
    return (
      <Card background='bg-light-gray-red'>
        <div>{this.props.ctGovTrial.official_title}</div>
        <CardSection>
          <h4>Details</h4>
          <SidebarSection header='Design'>
            <div>
              {this.props.ctGovTrial.study_design}
            </div>
          </SidebarSection>
          <SidebarSection header='Status'>
            <div>
              {this.props.ctGovTrial.overall_status}
            </div>
          </SidebarSection>
          
          <SidebarSection header='NCT ID'>
            <div>
              {this.props.ctGovTrial.nct_id}
              {this.props.ctGovTrial.first_received_date ? <span className='pl2 f6 i'>
                ({humanDateFormat(this.props.ctGovTrial.first_received_date)})
              </span> : ''}
            </div>
          </SidebarSection>
          
          <SidebarSection 
            header='Number of Arms' 
            content={this.props.ctGovTrial.number_of_arms} 
          />
          
          <SidebarSection 
            header='Enrollment' 
            content={this.props.ctGovTrial.enrollment} 
          />
          
          <SidebarSection 
            header='Type' 
            content={this.props.ctGovTrial.study_type}
          />
          
          <SidebarSection 
            header='Start date' 
            content={this.props.ctGovTrial.start_date ? this.props.ctGovTrial.start_date : 'N/A'} 
          />
          
          <SidebarSection 
            header='Completion date' 
            content={this.props.ctGovTrial.completion_date ? this.props.ctGovTrial.completion_date : 'N/A'} 
          />
          
          <SidebarSection 
            header='Primary completion date' 
            content={this.props.ctGovTrial.primary_completion_date ? this.props.ctGovTrial.primary_completion_date : 'N/A'} 
          />

        </CardSection>
        
        <CardSection>
          <CardSectionHeader>Sponsors</CardSectionHeader>
          <div>
            {this.props.ctGovTrial.sponsor}
          </div>
        </CardSection>
        
        <CardSection>
          <CardSectionHeader>Collaborators</CardSectionHeader>
          {this.props.collaborators.length > 1 ? this.props.collaborators.map((collaborator) =>
            <div key={collaborator.id}>{collaborator.name}</div>
          ) : 'None'}
        </CardSection>
      </Card>
    )
  }
}
