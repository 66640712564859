import React, { Component } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Link from "./Link"
// import MaterialTab from '@material/react-tab';

class Tab extends Component {
  constructor(props) {
    super(props)
  }
  
  render() {
    const activeStyle = this.props.activeTab == this.props.text ? "dib ph2 pb2 bb bw1 b--orange" : "dib ph2"
    const activeLink = (this.props.activeTab == this.props.text)
    return (
      <li className={activeStyle}>
        <Link href={this.props.href} active={activeLink} handleClick={this.props.handleClick} classStyle={this.props.notification ? 'pos-rel' : ''}>
          {this.props.text}
        </Link>
        {this.props.notification ? <span className="num">{this.props.notification}</span> : ''}
      </li>
    )
  }
}

export default Tab

// return (
//   <MaterialTab active={this.props.active} stacked={this.props.stacked} href={this.props.href}>
//     <span className='mdc-tab__text-label' onClick={this.props.handleClick}>{this.props.children}</span>
//   </MaterialTab>
// )
