import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import ListItem from "../../lists/ListItem";
import ListItemText from "../../lists/ListItemText";
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Checkbox from "../../Checkbox"

export default function ValueListItem(props) {
  
  function handleCheckboxSelect(e) {
    props.handleCheckboxSelect(props.characteristicValue, props.characteristicGroup, props.checked, props.productCharacteristic)
  }
  
  return (
    <Fragment>

      <ListItem key={props.characteristicValue.id} role={undefined} button onClick={handleCheckboxSelect}>

        <ListItemIcon>
          <Checkbox 
            checked={props.checked} 
          />
        </ListItemIcon>

        <ListItemText 
          primary={`${props.characteristicValue.name} ${props.characteristicValue.metric ? props.characteristicValue.metric.name : ''}`} 
        />

      </ListItem>
    </Fragment>
  )
}


ValueListItem.propTypes = {
  characteristicValue: PropTypes.object,
  withCheckbox: PropTypes.bool
};

ValueListItem.defaultProps = {
  withCheckbox: true
};
