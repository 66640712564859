import React, { Component } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import EntityAlerts from "../EntityAlerts"
import Typography from "@material-ui/core/Typography"

export default class Alerts extends React.PureComponent {
  constructor(props) {
    super(props)
  }
  
  render() {
    return(
      <div>
        
        <EntityAlerts 
          entity={this.props.product} 
          entityType='products' 
          cardBackground='bg-light-green' 
          alerts={this.props.alerts} 
          years={this.props.years}
          year={this.props.year}
          alertGroups={this.props.alert_groups}
          groupId={this.props.group_id}
          sector={this.props.sector}
        /> 
      </div>
    );
  }
}

Alerts.propTypes = {
  product: PropTypes.object
}
