import React, { Fragment } from "react"
import Paper from "../../Paper"
import List from "../../lists/List";
import DateListItem from "./DateListItem"

export default function DateList(props) {
  return (
    <Fragment>
      
      <List>
        {props.dates.map((date) =>
          <DateListItem 
            key={date.id}
            date={date}
            checked={false}
            forSelection={true}
            handleCheckboxSelect={props.handleCheckboxSelect}
          /> 
        )}
      </List>
      
    </Fragment>
  )
}
