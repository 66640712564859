import React, { Component } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"

class ExpansionPanel extends Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.state = { show: false }
  }
  
  handleClick() {
    this.setState({
      show: !this.state.show
    });
  }
  
  renderContent() {
    if (this.props.html) {
      return(<div className='mt3' dangerouslySetInnerHTML={{__html: this.props.children}} />)
    } else {
      return(<div className='mt3'>{this.props.children}</div>)
    }
  }
  
  render () {
    let defaultStyle = 'bg-white bb b--light-gray pv3 ph2 shadow-expansion pointer'
    let style = (this.state.show == true ? `${defaultStyle} mv3` : defaultStyle)
    return(
      this.props.children ? <div onClick={this.handleClick} className={style}>
        <h4 className='mv0 normal'>
          {this.props.header}
        </h4>
        {this.state.show == true ? this.renderContent() : <div></div>}
      </div> : ''
    );
  }
}

ExpansionPanel.propTypes = {
  header: PropTypes.string
}

export default ExpansionPanel
