// https://material-ui.com/api/text-field/
import React from "react"
import TextField from '@material-ui/core/TextField';


export default function TextInput(props) {
  const { variant = 'outlined', value = '', ...rest} = props
  
  return (
    <TextField
      variant={variant}
      value={value}
      {...rest}
    />
  )
}
