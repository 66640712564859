// ExportDialog.jsx
import React, { useState } from 'react';
import { 
  Dialog, DialogTitle, DialogContent, DialogActions, 
  Button, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio,
  Checkbox, FormGroup
} from '@material-ui/core';

const EXPORTABLE_FIELDS = [
  { name: 'date_of_event', label: 'Event Date' },
  { name: 'date_received', label: 'Date Received' },
  { name: 'device_name', label: 'Device Name' },
  { name: 'device_problems', label: 'Device Problem' },
  { name: 'event_type', label: 'Event Type' },
  { name: 'manufacturer_g1_name', label: 'Manufacturer' },
  { name: 'product_code', label: 'Product Code' },
  { name: 'Additional Manufacturer Narrative', label: 'Additional Manufacturer Narrative' },
  { name: 'Description of Event or Problem', label: 'Description of Event or Problem' },
  { name: 'URL', label: 'URL' }
];

const ExportDialog = ({ open, onClose, onExport, hasSelectedItems }) => {
  const [format, setFormat] = useState('csv');
  const [exportOption, setExportOption] = useState('all');
  const [selectedColumns, setSelectedColumns] = useState(EXPORTABLE_FIELDS.map(field => field.value));

  const handleFormatChange = (event) => setFormat(event.target.value);
  const handleExportOptionChange = (event) => setExportOption(event.target.value);
  
  const handleColumnToggle = (column) => {
    setSelectedColumns(prev => 
      prev.includes(column) 
        ? prev.filter(c => c !== column)
        : [...prev, column]
    );
  };

  const handleExport = () => {
    onExport(format, exportOption, selectedColumns);
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Export Results</DialogTitle>
      <DialogContent>
        <FormControl component="fieldset">
          <FormLabel component="legend">Format</FormLabel>
          <RadioGroup value={format} onChange={handleFormatChange}>
            <FormControlLabel value="csv" control={<Radio />} label="CSV" />
          </RadioGroup>
        </FormControl>

        <FormControl component="fieldset">
          <FormLabel component="legend">Option</FormLabel>
          <RadioGroup value={exportOption} onChange={handleExportOptionChange}>
            <FormControlLabel value="all" control={<Radio />} label="All Results" />
            <FormControlLabel value="current_page" control={<Radio />} label="Current Page" />
            {hasSelectedItems && (
              <FormControlLabel value="selected" control={<Radio />} label="Selected Items" />
            )}
          </RadioGroup>
        </FormControl>

        <FormControl component="fieldset">
          <FormLabel component="legend">Columns to Export</FormLabel>
          <FormGroup>
            {EXPORTABLE_FIELDS.map(field => (
              <FormControlLabel
                key={field.value}
                control={
                  <Checkbox 
                    disabled={true}
                    checked={selectedColumns.includes(field.value)}
                    onChange={() => handleColumnToggle(field.value)}
                  />
                }
                label={field.label}
              />
            ))}
          </FormGroup>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">Cancel</Button>
        <Button onClick={handleExport} color="primary" variant="contained">
          Export
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ExportDialog;