import React, { Fragment, Component } from "react"
import Paper from "../Paper"
import Flex from "../Flex"
import Box from "../Box"
import TimelineChartContainer from "./timeline/TimelineChartContainer"
import { Table, TableRow, TableHead, TableCell, TableBody } from "../tables"
import Typography from "@material-ui/core/Typography"
import Tabs from "../tabs/Tabs"
import Tab from "../tabs/Tab"
import ComponentFilter from "./ComponentFilter"
import Checkbox from "../Checkbox"
import DataTableRow from "./timeline/DataTableRow"

export default class Timeline extends Component {
  constructor(props) {
    super(props)
    this.handleChildProductClick = this.handleChildProductClick.bind(this)
    this.handleChildProductChange = this.handleChildProductChange.bind(this)
    this.handleRegulatoryTrialChange = this.handleRegulatoryTrialChange.bind(this)
    this.handlePostApprovalTrialChange = this.handlePostApprovalTrialChange.bind(this)
    this.filterChartData = this.filterChartData.bind(this)
    this.handleRecallChange = this.handleRecallChange.bind(this)
    this.state = { activeIndex: this.props.product.id, regulatoryStatuses: this.props.regulatory_statuses, chartData: this.props.chart_data, originalChartData: this.props.chart_data, componentId: (this.props.child_products ? this.props.child_products[0].id : null), subChartData: this.props.sub_chart_data, withRegulatoryTrials: true, withPostApprovalTrials: true, withRecalls: true, activeDataSources: ['regulatory_trials', 'post_approval_trials'] }
  }

  handleRecallChange(e, newValue) {
    this.filterChartData('recalls', newValue, 'withRecalls')
  }

  handleChildProductChange(name, value) {
    $.ajax({
      type: 'GET',
      url: `/sectors/${this.props.sector.abbr}/products/${this.props.product.id}/timeline`,
      data: {
        child_product_id: value
      },
      dataType: 'json',
      success: (data) => {
        this.setState({
          componentId: value,
          regulatoryStatuses: data.regulatory_statuses,
          chartData: data.chart_data
        })
      }
    })
  }

  handleChildProductClick(e, newValue) {
    $.ajax({
      type: 'GET',
      url: `/sectors/${this.props.sector.abbr}/products/${this.props.product.id}/timeline`,
      data: {
        child_product_id: newValue
      },
      dataType: 'json',
      success: (data) => {
        this.setState({
          activeIndex: newValue,
          regulatoryStatuses: data.regulatory_statuses,
          chartData: data.chart_data
        })
      }
    })
  }

  handleRegulatoryTrialChange(e, newValue) {

    this.filterChartData('regulatory_trials', newValue, 'withRegulatoryTrials')
  }

  handlePostApprovalTrialChange(e, newValue) {

    this.filterChartData('post_approval_trials', newValue, 'withPostApprovalTrials')
  }


  filterChartData(typeName, checkboxValue, stateName) {

    let chartDataArray = ""

    let params = { regulatory_trials: this.state.withRegulatoryTrials, post_approval_trials: this.state.withPostApprovalTrials, recalls: this.state.withRecalls, regulatory_statuses: true, off_market: true }

    params[typeName] = checkboxValue

    let dataToFilter = checkboxValue == false ? this.state.chartData : this.state.originalChartData

    chartDataArray = dataToFilter.filter((chartDataHash) => {
      return params[chartDataHash.type] == true;
    })

    this.setState({
      chartData: chartDataArray,
      [stateName]: checkboxValue
    })

  }

  render () {
    let chosenFieldsLabel = this.state.activeDataSources.length == this.props.data_sources.length ? 'All' : `${this.state.activeDataSources.length} chosen`
    return (
      <Fragment>
        <Paper minFullHeight>

          <Box mb={3}>
            <Flex>
              {this.props.child_products ?
                <ComponentFilter
                  componentId={this.state.componentId}
                  childProductOptions={this.props.child_product_options}
                  handleChildProductChange={this.handleChildProductChange}
                />
              : ''}
            </Flex>
          </Box>

          {this.state.chartData ?
            <Fragment>
              <TimelineChartContainer
                key={`${this.state.componentId}-${this.state.chartData}`}
                title={`${this.props.product.short_name} Timeline`}
                chartId='product-status-timeline-chart'
                chartData={this.state.chartData}
                currentTime={this.props.current_time}
              />

              <Box mt={3}>
                <Flex justifyContent='center' alignItems='center'>
                  <Box mr={3}>
                    Filter timeline by:
                  </Box>
                  <Box>
                    <Checkbox
                      label='Regulatory Trials'
                      name='regulatory_trials'
                      checked={this.state.withRegulatoryTrials}
                      onChange={this.handleRegulatoryTrialChange}
                    />
                  </Box>

                  <Box>
                    <Checkbox
                      label='Post Approval Trials'
                      name='post_approval_trials'
                      checked={this.state.withPostApprovalTrials}
                      onChange={this.handlePostApprovalTrialChange}
                    />
                  </Box>

                  <Box>
                    <Checkbox
                      label='Recalls'
                      name='recalls'
                      checked={this.state.withRecalls}
                      onChange={this.handleRecallChange}
                    />
                  </Box>
                </Flex>
              </Box>

              {/* <Box mt={4}>
                <XRangeChartContainer
                  key={this.state.componentId}
                  title={this.state.subChartData.trial_name}
                  chartId='trial-dumbbell-chart'
                  chartData={this.state.subChartData}
                  currentTime={this.props.current_time}
                />
              </Box> */}
            </Fragment>
          :
            <Flex justifyContent='center' height="50%">
              <Box alignSelf='center' maxWidth="50%">
                <Box>
                  <Typography variant="body1">
                    No status data found for this product
                  </Typography>
                </Box>
              </Box>
            </Flex>
          }

          <Tabs centered value={0} className='bb b--black-10'>
            <Tab label='Data' />
          </Tabs>

          <Box className='mt3'>
            <Table>
              <TableHead>
                <TableRow hover={false}>
                  <TableCell>Status</TableCell>
                  <TableCell>Source</TableCell>
                  <TableCell>Date</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {this.state.chartData.map((chartData) =>
                  <DataTableRow 
                    key={chartData.id}
                    chartData={chartData}
                    sector={this.props.sector}
                  />
                )}
              </TableBody>
            </Table>
          </Box>
        </Paper>
      </Fragment>
    )
  }
}
