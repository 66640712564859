import React from "react"
import { ListItem, ListItemText } from "../../lists"
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemIcon from "@material-ui/core/ListItemIcon"
import Switch from "../../Switch"
import CloseIcon from '@material-ui/icons/Close';
import IconButton from "../../IconButton"

export default function ScrapedNameListItem(props) {

  function handleNameAdd(e) {
    props.handleNameAdd(props.scrapedName)
  }

  let trialInvestigator = props.trialInvestigators.filter(trialInvestigator => trialInvestigator.id == props.scrapedName.trial_investigator_id)[0]
  let checked = trialInvestigator ? true : false
  return(
    <ListItem key={props.scrapedName.id} divider button>
      {/* <ListItemIcon>
        <IconButton size='small'>
          <CloseIcon fontSize='small' />
        </IconButton>
      </ListItemIcon> */}

      <ListItemText
        primary={props.scrapedName.text}
        secondary={props.scrapedName.score}
      />

      <ListItemSecondaryAction>
        <Switch
          checked={checked}
          handleChange={handleNameAdd}
        />
      </ListItemSecondaryAction>
    </ListItem>
  )
}
