import React, { Fragment } from "react"
import PieChart from "../charts/PieChart"


export default function ReportTypeChart(props) {

  function formatData() {
    let seriesData = props.reportTypeChartData.map((array) => {
      return({ y: array[1], name: array[0] })
    })

    const data = [{
      name: 'Report Types',
      colorByPoint: true,
      data: seriesData
    }]

    return(data)
  }

  let chartReloadKey = Math.random()

  return(
    <Fragment>
      <PieChart
        key={`product-report-type-chart-${chartReloadKey}`}
        chartId='product-report-type-chart'
        exportingChartOptions={{ credits: { enabled: true, text: '\u00A9 pvpipeline' } }}
        formattedData={formatData()}
        title='Report Types'
      />
    </Fragment>
  )
}
