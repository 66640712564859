import React, { Fragment } from "react"
import Box from "../../Box"
import Paper from "../../Paper"
import Link from "../../Link"
import Typography from "@material-ui/core/Typography"
import Flex from "../../Flex"
import SidebarSection from "../../SidebarSection"
import { List, ListItemLink, ListItemText } from "../../lists"

export default function OverviewModule(props) {

  return(
    <Fragment>
      <Paper className='h-100'>
        <Typography variant="h6">
          Overview
        </Typography>

        <Box mt={3}>

          <SidebarSection header='Private' content={props.company.public ? 'No' : 'Yes'} />
          <SidebarSection header='Headquarters' content={props.company.hq_loc} />
          <SidebarSection header='Sector & Subsector' content={props.companySectors} />

          {props.overview_description ?
            <SidebarSection header='Description'>
              <div dangerouslySetInnerHTML={{__html: props.overview_description.content}} />
            </SidebarSection>
          : ""}

          {props.company.url ?
            <Box mt={3}>
              <Typography variant="Body1" className='b'>
                Related links
              </Typography>

              <Box mt={1}>
                <List dense={true}>
                  <ListItemLink divider={true} href={props.company.url} target="_blank">
                    <ListItemText primary="Company Web Site" />
                  </ListItemLink>
                </List>
              </Box>
            </Box>
          : ''}
        </Box>
      </Paper>

    </Fragment>
  );
}
