import React from "react"
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import InputAdornment from '@material-ui/core/InputAdornment';
import ClearIcon from '@material-ui/icons/Clear';
import IconButton from '../IconButton'

export default function CompanyAutocomplete(props) {

  function handleAutocompleteChange(e, selected) {
    props.handleAutocompleteChange(e, selected, 'company_id')
  }

  return (
    <Autocomplete
      id='company_id'
      options={props.companies}
      name='company_id'
      defaultValue={props.companyValue}
      value={props.companyValue}
      getOptionLabel={(option) => option.name}
      selectOnFocus
      onChange={handleAutocompleteChange}
      renderInput={(params) => <TextField {...params} name='company_name' label='Company' variant="outlined"  />}
    />
  )
}