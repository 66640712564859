import React from "react"
import { TableRow, TableCell } from "../../tables"
import DeleteButton from "../DeleteButton"
import Select from "../../Select"

export default function InvestigatorRow(props) {

  function handleDeleteClick(e) {
    props.handleDeleteClick(props.investigator)
  }

  function handleChange(name, value) {
    props.handleChange(name, value, props.investigator)
  }


  const person = props.investigator.person
  const name = person.name
  const user = props.investigator.creator
  console.log(props.investigator.role)
  return (
    <TableRow>
      <TableCell>{`${name.first_name} ${name.last_name}`}</TableCell>
      <TableCell>
        <Select
          name='role'
          entities={[ { name: 'Principal investigator', value: 'Principal Investigator' }, { name: 'Study Chair', value: 'Study Chair' }, { name: 'Study Director', value: 'Study Director' }, { name: 'Sub-Investigator', value: 'Sub-Investigator' }]}
          handleChange={handleChange}
          value={props.investigator.role}
        />
      </TableCell>
      <TableCell>NA</TableCell>
      <TableCell>{humanDateFormat(props.investigator.created_at)}</TableCell>
      <TableCell>{displayInitials(user.first_name, user.last_name)}</TableCell>
      <TableCell>
        <DeleteButton onClick={handleDeleteClick} />
      </TableCell>
    </TableRow>
  )

}
