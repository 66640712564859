import React, { Component, Fragment } from "react"
import Paper from "../../Paper"
import Flex from "../../Flex"
import Box from "../../Box"
import TableDisplay from "../../trials/TableDisplay"
import { withSnackbar } from "../../SnackbarContainer"
import Filters from "./Filters"
import TrialCountDisplay from "../../trials/CountDisplay"

class Container extends Component {
  constructor(props) {
    super(props)
    this.handleChangePage = this.handleChangePage.bind(this)
    this.handleRequestSort = this.handleRequestSort.bind(this)
    this.getTrials = this.getTrials.bind(this)
    this.desc = this.desc.bind(this)
    this.stableSort = this.stableSort.bind(this)
    this.getSorting = this.getSorting.bind(this)
    this.handleFilterChange = this.handleFilterChange.bind(this)
    this.state = { trials: this.props.trials, trialsCount: this.props.trials_count, filterValues: { product_id: '', clinical_type_id: '', sponsored_only: false, page: 0, rowsPerPage: 100, order: 'desc', order_by: 'updated_at' }, products: this.props.products, clinicalTypes: this.props.clinical_types, loading: false }
  }

  handleFilterChange(name, value) {
    let params = Object.assign({}, this.state.filterValues)

    params[name] = value

    this.getTrials(params)
  }

  desc(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = cmp(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
  }

  getSorting(order, orderBy) {
    return order === 'desc' ? (a, b) => this.desc(a, b, orderBy) : (a, b) => -this.desc(a, b, orderBy);
  }

  handleRequestSort(event, property) {
    let params = Object.assign({}, this.state.filterValues)

    params["order_by"] = property

    let isDesc = this.state.filterValues.order_by === property && this.state.filterValues.order === 'desc';

    console.log(isDesc)

    params["order"] = isDesc ? 'asc' : 'desc'

    this.getTrials(params)
  };

  handleChangePage(e, newPage) {
    let params = Object.assign({}, this.state.filterValues)

    params["order_by"] = this.state.filterValues.orderBy
    params["order"] = this.state.filterValues.order
    params["page"] = newPage

    this.getTrials(params)
  }

  getTrials(params) {
    $.ajax({
      type: 'GET',
      url: `/sectors/${this.props.sector.abbr}/companies/${this.props.company.id}/trials`,
      data: params,
      dataType: 'json',
      beforeSend: (data) => {
        this.setState({
          loading: true
        })
      },
      success: (data) => {
        this.setState({
          trials: data.trials,
          trialsCount: data.trials_count,
          loading: false,
          filterValues: params,
          products: data.products,
          clinicalTypes: data.clinical_types
        })
      },
      error: (data) => {
        this.props.snackbarShowMessage("An error has occurred while trying to load the data. Please try again.", "error")

        this.setState({
          loading: false
        })
      }
    })
  }

  render() {
    return(
      <Paper fullHeight>
        <Box mb={3}>
          <Filters
            clinicalTypes={this.state.clinicalTypes}
            products={this.state.products}
            company={this.props.company}
            filterValues={this.state.filterValues}
            handleFilterChange={this.handleFilterChange}
          />
        </Box>

        <Flex justifyContent='right'>
          <TrialCountDisplay
            count={this.state.trialsCount}
          />
        </Flex>

        <Box height="100%">
          <TableDisplay
            trials={this.state.trials}
            trialsCount={this.state.trialsCount}
            page={this.state.filterValues.page}
            order={this.state.filterValues.order}
            orderBy={this.state.filterValues.order_by}
            sector={this.props.sector}
            loading={this.state.loading}
            handleRequestSort={this.handleRequestSort}
            handleChangePage={this.handleChangePage}
            height="88%"
          />
        </Box>
      </Paper>
    )
  }
}


export default withSnackbar(Container)
