import React from "react"
import Flex from "../Flex"
import Box from "../Box"
import Typography from "@material-ui/core/Typography"
import RaisedButton from "../buttons/RaisedButton"

export default function EmptyDataDisplay(props) {
  return(
    <Flex justifyContent='center' height="50%">
      <Box alignSelf='center' maxWidth="50%" textAlign='center'>
        <Box>
          <Typography variant="body1">
            {props.query ? 'No results found for this query' : 'Start searching by using the search input above'}
          </Typography>
        </Box>

        {props.activeIndex == 'Product' && props.query ?
          <Box mt={3}>
            <Typography variant="body2">
              Interested in a product we don't currently cover? Let us know by clicking the button below. We'll review whether it meets our criteria for being included in the sector.
            </Typography>

            <Box mt={4} mx="auto" width={[3/4, 1/2, 1/2]}>
              <RaisedButton onClick={props.handleProductRequestClick}>
                Request Product
              </RaisedButton>
            </Box>
          </Box>
        : ""}
      </Box>
    </Flex>
  )
}