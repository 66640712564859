import React, { useState } from "react"
import { Table, TableHead, TableCell, TableBody, TableRow } from "../../tables"
import AlertRow from "./AlertRow"

export default function DisplayTable(props) {

  return (
    <Table>
      <TableHead>
        <TableCell>ID</TableCell>
        <TableCell>Sector</TableCell>
        <TableCell>Date</TableCell>
        <TableCell>Body</TableCell>
        <TableCell>Group</TableCell>
      </TableHead>

      <TableBody>
        {props.alerts.map((alert) =>
          <AlertRow
            key={alert.id}
            alert={alert}
          />
        )}
      </TableBody>
    </Table>
  )
}
