import React, { Fragment } from 'react';
import Link from "./Link"
import IconButton from './IconButton';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import FavoriteIcon from '@material-ui/icons/Favorite';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import TabElements from "./TabElements"
import Flex from "./Flex"
import Box from "./Box"
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import useAppBarHeight from './useAppBarHeight';
import SearchIcon from '@material-ui/icons/Search'


export default function SectionHeader(props) {

  const appBarHeight = useAppBarHeight()

  console.log(appBarHeight)

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const colors = { 'Trials': 'bg-washed-red', 'Products': 'bg-washed-green', 'Companies': 'bg-gray-blue', 'People': 'bg-yellow', 'Presentations': 'bg-moderate-blue', 'Meetings': 'bg-moderate-blue', 'Abstracts': 'bg-moderate-blue', 'Publications': 'bg-cyan', Patents: 'bg-moderate-blue', 'Alerts': 'bg-gray-cyan', 'Sources': 'bg-washed-red', 'Home': 'bg-orange', 'Search': 'bg-orange', 'Settings': 'bg-gray', 'Users': 'bg-black', 'User Companies': 'bg-black', 'Sectors': 'bg-black', 'News': 'bg-mmi-blue' }

  return (
    <Fragment>

      <Flex alignItems='center' pl={0.5} className={colors[props.activeGroup] ? colors[props.activeGroup] : 'bg-black'} color='white' width={1}>

        <Box width="80%" ml={1}>
          <Flex alignItems='baseline'>

            <Box mr={2}>
              <Flex alignItems='baseline' onMouseEnter={handleOpen} aria-owns={open ? 'mouse-over-popover' : undefined}>
                {props.productFamily ?
                  <Box pr={1} alignSelf='end' style={{height: "32px"}}>
                    {open ? <ArrowDropDownIcon size='small' /> : <ArrowRightIcon size='small' /> }
                  </Box>
                : ''}

                <Box aria-haspopup="true">
                  <Typography variant="h6">
                    {props.title}
                  </Typography>
                </Box>
              </Flex>

              {props.productFamily ?
                <Menu
                  id="product-family-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{ onMouseLeave: handleClose }}
                >
                  {props.productFamily.map((productFamily) =>
                    <MenuItem key={productFamily.id} component='a' href={`/sectors/${props.sector.abbr}/products/${productFamily.id}`}>
                      {productFamily.component_short_name ? productFamily.component_short_name : productFamily.short_name}
                    </MenuItem>
                  )}
                </Menu>
              : ''}
            </Box>

            <Box>
              {props.activeGroup == 'Products' && props.company ?
                <Typography variant='overline'>
                  <Link href={`/sectors/${props.sector.abbr}/companies/${props.company.id}`} textColor='white'>
                    {props.company.short_name}
                  </Link>
                </Typography>
              : ''}
            </Box>
          </Flex>
        </Box>

        {props.forUsers && props.entity ?
          <Box ml='auto' pr={3}>
            <Flex alignItems='center'>
              {props.activeGroup == 'Products' ? 
                <Box>
                  <IconButton edge="end" href={`/sectors/${props.sector.abbr}/products/${props.entity.id}/search`} size='small' color='inherit' tooltip={`Search ${props.entity.short_name}`}>
                    <SearchIcon />
                  </IconButton>
                </Box>
              : ""}
              <Box>
                <IconButton edge="end" onClick={props.handleFavoriteClick} size='small' color='inherit' tooltip={props.userEntity ? 'Unfavorite' : 'Favorite'}>
                  {props.userEntity ? <FavoriteIcon color='inherit' fontSize='small' /> : <FavoriteBorderIcon color='inherit' fontSize='small' />}
                </IconButton>
              </Box>
            </Flex>
          </Box>
        :
          <Box className='ml-auto'></Box>
        }
      </Flex>

      {props.tabs ?
        <TabElements
          tabs={props.tabs}
          subTabs={props.subTabs}
          activeTab={props.activeTab}
          activeGroup={props.activeGroup}
        />
      : ''}
    </Fragment>
  );
}
