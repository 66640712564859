import React from "react"
import ResultItemContainer from "../search/ResultItemContainer"
import Box from "../Box"
import Typography from "@material-ui/core/Typography"

export default function SearchItem(props) {

  function renderSearchDetails(result) {
    return (
      <Box>
        <Typography variant="body2" color="textSecondary">
          {result.manufacturer}
        </Typography>
      </Box>
    );
  }

  return(
    <ResultItemContainer 
      searchItemType={props.result.record_type}
      searchTitle={props.result.english_title || props.result.title}
      date={props.result.date}
      searchDescription={props.result.description || props.result.text}
      url={props.result.url}
      target="_blank"
      searchDetails={renderSearchDetails(props.result)}
    />
  )
}