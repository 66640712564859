import React, { Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Paper from "../../../Paper"
import Flex from "../../../Flex"
import Box from "../../../Box"
import EntityCard from "../../EntityCard"
import Link from "../../../Link"

export default function TrialCard(props) {
  return(
    <Fragment>

      <Paper fullHeight>
        <Box height="100%">

          <Box mt={2} height="90%" overflow='auto'>
            <Flex flexDirection='column' mb={3}>
              <Box width={1} mb={1}>
                <label className='b'>Trial</label>
              </Box>
              <Box>
                {props.trialDataView.acronym || props.trialDataView.short_name}
              </Box>
            </Flex>

            <Flex flexDirection='column' mb={3}>
              <Box width={1} mb={1}>
                <label className='b'>NCT ID</label>
              </Box>
              <Box>
                <Link href={`https://clinicaltrials.gov/ct2/show/${props.trialDataView.nct_id}`} target="_blank">
                  {props.trialDataView.nct_id}
                </Link>
              </Box>
            </Flex>

            <Flex flexDirection='column' mb={3}>
              <Box width={1} mb={1}>
                <label className='b'>Full Name</label>
              </Box>
              <Box>
                {props.trialDataView.full_name}
              </Box>
            </Flex>

            <Flex flexDirection='column' mb={3}>
              <Box width={1} mb={1}>
                <label className='b'>Brief Summary</label>
              </Box>
              <Box>{props.briefSummary ? props.briefSummary.text_field_value : "N/A"}</Box>
            </Flex>

            <Flex flexDirection='column' mb={3}>
              <Box width={1} mb={1}>
                <label className='b'>Detailed Description</label>
              </Box>
              <Box>{props.detailedDescription ? props.detailedDescription.text_field_value : "N/A"}</Box>
            </Flex>

            <Flex flexDirection='column' mb={3}>
              <Box width={1} mb={1}>
                <label className='b'>Arms</label>
              </Box>
              <Box>
                {props.trialArms.map((trialArm) =>
                  <Box key={trialArm.id}>
                    {trialArm.name}
                  </Box>
                )}
              </Box>
            </Flex>

            <Flex flexDirection='column'>
              <Box width={1} mb={2}>
                <label className='b'>Interventions</label>
              </Box>
              <Box>
                {props.trialInterventions.map((trialIntervention) =>
                  <Box key={trialIntervention.id}>
                    {trialIntervention.name}
                  </Box>
                )}
              </Box>
            </Flex>
          </Box>
        </Box>
      </Paper>

    </Fragment>
  )
}
