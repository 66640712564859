import React, { Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import PmaApprovalsList from "./PmaApprovalsList"
import PmaApprovalsTimelineContainer from "./PmaApprovalsTimelineContainer"
import Paper from "../Paper"
import Flex from "../Flex"
import Box from "../Box"
import Typography from "@material-ui/core/Typography"
import Link from "../Link"
import PmaApprovalsFilters from "./PmaApprovalsFilters"
import IconButton from "../IconButton"
import TimelineIcon from '@material-ui/icons/Timeline';
import ListIcon from '@material-ui/icons/List';

export default class PmaApprovals extends React.Component {
  constructor(props) {
    super(props)
    this.handleFilterChange = this.handleFilterChange.bind(this)
    this.handleListViewClick = this.handleListViewClick.bind(this)
    this.handleTimelineViewClick = this.handleTimelineViewClick.bind(this)
    this.state = { pmaApprovals: this.props.pma_approvals, chartData: this.props.chart_data, supplementReason: this.props.supplement_reason, pmaNumber: this.props.pma_number, view: 'list' }
  }

  handleFilterChange(name, value) {
    let params = { supplement_reason: this.state.supplementReason, pma_number: this.state.pmaNumber }

    params[name] = value

    $.get(`/sectors/${this.props.sector.abbr}/products/${this.props.product.id}/pma_approvals`, params, (data) => {
      this.setState({
        pmaApprovals: data.pma_approvals,
        chartData: data.chart_data,
        supplementReasons: data.supplement_reasons,
        supplementReason: params.supplement_reason,
        pmaNumber: params.pma_number
      })
    }, 'json')
  }

  handleListViewClick(e) {
    this.setState({
      view: 'list'
    })
  }

  handleTimelineViewClick(e) {
    this.setState({
      view: 'timeline'
    })
  }

  render() {
    return(
      <React.Fragment>

        <Paper fullHeight={this.state.view == 'list' ? true : false}>
          <Box height="100%">
            <Flex alignItems='center'>
              <Box>
                <Typography variant="h6" component="h6">
                  PMA Approvals
                </Typography>
              </Box>

              <Box ml="auto">
                <IconButton onClick={this.handleListViewClick} color={this.state.view == 'list' ? 'primary' : 'default'} aria-label="list" tooltip='List'>
                  <ListIcon />
                </IconButton>

                <IconButton onClick={this.handleTimelineViewClick} color={this.state.view == 'timeline' ? 'primary' : 'default'} aria-label="timeline" tooltip="Timeline">
                  <TimelineIcon />
                </IconButton>
              </Box>
            </Flex>

            {this.state.pmaApprovals.length > 0 ?
              <Fragment>
                <Box mt={1} mb={2}>
                  <PmaApprovalsFilters
                    supplement_reasons={this.props.supplement_reasons}
                    pmaNumberOptions={this.props.pma_number_options}
                    pmaNumber={this.state.pmaNumber}
                    supplementReason={this.state.supplementReason}
                    handleFilterChange={this.handleFilterChange}
                  />
                </Box>

                <Box height="100%">
                  { this.state.view == 'list' ?
                    <PmaApprovalsList
                      pmaApprovals={this.state.pmaApprovals}
                      product={this.props.product}
                      sector={this.props.sector}
                    />
                  :
                    <PmaApprovalsTimelineContainer
                      pmaApprovals={this.state.pmaApprovals}
                      chartData={this.state.chartData}
                      supplementReason={this.state.supplementReason}
                      product={this.props.product}
                      currentTime={this.props.current_time}
                      sector={this.props.sector}
                    />
                  }
                </Box>
              </Fragment>
            :
              <Flex justifyContent='center' height="50%">
                <Box alignSelf='center' maxWidth="50%">
                  <Box>
                    <Typography variant="body1">
                      This product has not been PMA approved
                    </Typography>
                  </Box>
                </Box>
              </Flex>
            }
          </Box>
        </Paper>

      </React.Fragment>
    )
  }
}
