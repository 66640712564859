import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import List from "../../lists/List";
import NestedListItemLink from "../../lists/NestedListItemLink"
import ListItemLink from "../../lists/ListItemLink"
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ExpandLess from '../../ExpandLess';
import ExpandMore from '../../ExpandMore';
import Collapse from '@material-ui/core/Collapse';


export default class NavigationList extends Component {
  constructor(props) {
    super(props)
    this.state = { open: false }
  }

  componentDidMount() {
    if (this.props.activeGroup == 'Users') {
      this.setState({
        open: true
      })
    }
  }

  render() {
    return(
      <Fragment>

        <ListItem button onClick={() => this.setState({open: !this.state.open})}>
          <ListItemText primary="Users" />
          {this.state.open ? <ExpandLess /> : <ExpandMore />}
        </ListItem>

        <Collapse in={this.state.open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>

            <NestedListItemLink href={`/admin/users`}>
              <ListItemText primary='List' className={this.props.activeItem == 'List' && this.props.activeGroup == 'Users' ? 'orange' : ''} />
            </NestedListItemLink>

            <NestedListItemLink href={`/admin/user_companies`}>
              <ListItemText primary='User Companies' className={this.props.activeItem == 'User Companies' && this.props.activeGroup == 'Users' ? 'orange' : ''} />
            </NestedListItemLink>


          </List>
        </Collapse>

      </Fragment>
    )
  }
}
