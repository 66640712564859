import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Paper from "../../Paper"
import { Table, TableRow, TableCell, TableHead, TableBody } from "../../tables"
import ReviewerRow from "./ReviewerRow"
import Flex from "../../Flex"
import Box from "../../Box"
import Link from "../../Link"
import update from "immutability-helper"
import Select from "../../Select"
import Snackbar from "../../Snackbar"

export default class Reviewer extends Component {
  constructor(props) {
    super(props)
    this.handleStatusUpdate = this.handleStatusUpdate.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.state = { abstracts: this.props.abstracts, sector_id: this.props.sector_id, user_id: this.props.user_id, priority_id: this.props.priority_id, meeting_id: this.props.meeting_id, review_type: this.props.review_type }
  }

  handleStatusUpdate(abstractReview, abstract) {
    if (abstractReview) {
      $.ajax({
        type: 'PUT',
        url: `/admin/abstract_reviews/${abstractReview.id}`,
        data: {
          abstract_review: {
            reviewed: !abstractReview.reviewed, user_id: this.props.current_user.id
          }
        },
        dataType: 'json',
        success: (data) => {
          let index = this.state.abstracts.indexOf(abstract)
          let abstracts = update(this.state.abstracts, {
            [index]: {
              $merge: {
                abstract_review: data.abstract_review
              }
            }
          })

          this.setState({
            abstracts: abstracts
          })
        },
        error: (data) => {
          this.setState({
            showSnackbar: true,
            snackbarText: 'There was an issue setting the status. Please try again'
          })
        },
        complete: (data) => {
          setTimeout(() => {
            this.setState({
              showSnackbar: false,
              snackbarText: ''
            })
          }, 4000)
        }
      })
    } else {
      $.ajax({
        type: 'POST',
        url: `/admin/abstract_reviews`,
        data: {
          abstract_review: {
            abstract_id: abstract.id, reviewed: true, user_id: this.props.current_user.id, reviewed_for: 'extractor_reviewer'
          }
        },
        dataType: 'json',
        success: (data) => {
          let index = this.state.abstracts.indexOf(abstract)

          let abstracts = update(this.state.abstracts, {
            $splice: [[index,1]]
          })

          this.setState({
            abstracts: abstracts,
            showSnackbar: true,
            snackbarText: 'Abstract has been marked as reviewed'
          })
        },
        error: (data) => {
          this.setState({
            showSnackbar: true,
            snackbarText: 'There was an issue setting the status. Please try again'
          })
        },
        complete: (data) => {
          setTimeout(() => {
            this.setState({
              showSnackbar: false,
              snackbarText: ''
            })
          }, 4000)
        }
      })
    }
  }

  handleChange(name, value) {
    let params = { sector_id: this.state.sector_id, priority_id: this.state.priority_id, user_id: this.state.user_id, meeting_id: this.state.meeting_id, review_type: this.state.review_type }

    params[name] = value

    $.ajax({
      type: 'GET',
      url: this.props.url,
      data: params,
      dataType: 'json',
      success: (data) => {
        this.setState({
          abstracts: data.abstracts,
          [name]: value
        })
      }
    })
  }

  render() {
    console.log(this.state.abstracts)
    return(
      <Fragment>
        <Paper fullHeight>

          <Flex mb={4}>
            <Box>
              <Select
                label='Sector'
                name='sector_id'
                includeBlank
                value={this.state.sector_id}
                entities={this.props.sectors}
                handleChange={this.handleChange}
              />
            </Box>

            <Box mx={3}>
              <Select
                label='User'
                name='user_id'
                includeBlank
                value={this.state.user_id}
                entities={this.props.users}
                handleChange={this.handleChange}
              />
            </Box>

            <Box mr={3}>
              <Select
                label='Priority'
                name='priority_id'
                includeBlank
                value={this.state.priority_id}
                entities={this.props.priorities}
                handleChange={this.handleChange}
              />
            </Box>

            <Box>
              <Select
                label='Review Type'
                name='review_type'
                includeBlank
                value={this.state.review_type}
                entities={this.props.review_types}
                handleChange={this.handleChange}
              />
            </Box>

            <Box ml='auto'>
              {`${this.state.abstracts.length} Abstracts`}
            </Box>
          </Flex>


          <Box height="100%">
            <Box overflow='auto' height="90%">

              <Table size='small' stickyHeader={true}>
                <TableHead>
                  <TableRow>
                    <TableCell className='bg-white'>#</TableCell>
                    <TableCell className='bg-white'>Abstract</TableCell>
                    <TableCell className='bg-white' align='right'>
                      Completed
                    </TableCell>
                    <TableCell className='bg-white' align='right'>
                      User
                    </TableCell>
                    <TableCell className='bg-white'></TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {this.state.abstracts.map((abstract) =>
                    <ReviewerRow
                      key={abstract.id}
                      abstract={abstract}
                      handleStatusUpdate={this.handleStatusUpdate}
                    />
                  )}
                </TableBody>
              </Table>

            </Box>
          </Box>
        </Paper>

        <Snackbar
          open={this.state.showSnackbar}
          message={this.state.snackbarText}
        />
      </Fragment>
    )
  }
}
