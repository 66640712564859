import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import TrialStatusRow from "./TrialStatusRow"
import { Table, TableRow, TableCell, TableBody, TableHead } from "../../tables"
import Typography from '@material-ui/core/Typography';
import Select from "../../Select"
import Box from "../../Box"
import Snackbar from "../../Snackbar"

class StatusForm extends Component {
  constructor(props) {
    super(props)
    this.handleStatusChange = this.handleStatusChange.bind(this)
    this.handleDeletion = this.handleDeletion.bind(this)
    this.state = { trialStatuses: [], showSnackbar: false, snackbarText: '' }
  }

  componentDidMount() {
    $.get(`/admin/trials/${this.props.trial.id}/statuses`, {}, (data) => {
      this.setState({
        trialStatuses: data.trial_statuses
      })
    }, 'json')
  }

  handleStatusChange(name, value) {
    if (this.props.source) {
      $.post("/admin/trial_statuses", { trial_status: { trial_id: this.props.trial.id, clinical_status_id: value, active: true, created_by: this.props.currentUser.id, source_id: this.props.source.source_id }}, (data) => {
        this.setState({
          trialStatuses: data.trial_statuses,
          showSnackbar: true,
          snackbarText: 'The status has been added'
        })
      }, 'json').fail(() => {
        this.setState({
          showSnackbar: true,
          snackbarText: `The status could not be added. Please try again.`
        })
      }).always(() => {
        setTimeout(() => {
          this.setState({
            showSnackbar: false,
            snackbarText: ''
          })
        }, 4000)
      })
    } else {
      this.setState({
        showSnackbar: true,
        snackbarText: 'You must choose a source before adding a status'
      })
      setTimeout(() => {
        this.setState({
          showSnackbar: false,
          snackbarText: ''
        })
      }, 4000)
    }
  }

  handleDeletion(trialStatus) {
    $.ajax({
      type: 'PUT',
      url: `/admin/trial_statuses/${trialStatus.id}`,
      data: {
        trial_status: {
          deleted: true, updated_by: this.props.currentUser.id
        }
      },
      dataType: 'json',
      success: (data) => {
        this.setState({
          trialStatuses: data.trial_statuses
        })
      },
      error: (data) => {
        this.setState({
          showSnackbar: true,
          snackbarText: `The status could not be removed. Please try again.`
        })
      },
      complete: () => {
        this.setState({
          showSnackbar: false,
          snackbarText: ''
        })
      }
    })
  }

  render() {
    return(
      <Fragment>
        <div>
          <Typography variant="h6" component="h6" className='mb1'>
            New Trial Status
          </Typography>

          <Select
            entities={this.props.statuses}
            label='Statuses'
            displayEmpty={true}
            handleChange={this.handleStatusChange}
          />
        </div>

        <Box mt={3}>

          <Typography variant="h6" component="h6" className='mb2'>
            Statuses
          </Typography>

          <Table>

            <TableHead>
              <TableRow>
                <TableCell>Active</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Source</TableCell>
                <TableCell>User</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {this.state.trialStatuses.length > 0 ? this.state.trialStatuses.map((trialStatus) =>
                <TrialStatusRow
                  key={trialStatus.trial_status.id}
                  trialStatus={trialStatus}
                  handleDeletion={this.handleDeletion}
                />
              ) : <TableRow><TableCell colSpan={5}>No statuses. Add one above.</TableCell></TableRow>}
            </TableBody>

          </Table>
        </Box>

        <Snackbar
          open={this.state.showSnackbar}
          message={this.state.snackbarText}
        />
      </Fragment>
    )
  }
}

export default StatusForm
