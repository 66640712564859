import React, { Component } from "react"
import EntityLayout from "../EntityLayout"
import Box from "../Box"
import AbstractCard from "./AbstractCard"
import { List, ListItemText, ListItemLink } from "../lists"
import Typography from "@material-ui/core/Typography"
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

export default class Profile extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    return(
      <div>

        <EntityLayout>
          <Box className='w-100 w-60-ns mb3 mb0-ns'>

            {this.props.abstract_conclusion ? <ExpansionPanel defaultExpanded={true}>
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} ariacontrols={`conclusion-panel`} id={`conclusion-header`}>
                <Typography className="f4">
                  Conclusion
                </Typography>
              </ExpansionPanelSummary>

              <ExpansionPanelDetails>
                <Typography className='w-100'>
                  <div dangerouslySetInnerHTML={{__html: this.props.abstract_conclusion.conclusion }} />
                </Typography>
              </ExpansionPanelDetails>

            </ExpansionPanel> : ''}

            {this.props.abstract_products.length > 0 ?
              <ExpansionPanel defaultExpanded={true}>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} ariacontrols={`products-panel`} id={`products-header`}>
                  <Typography className="f4">
                    Products
                  </Typography>
                </ExpansionPanelSummary>

                <ExpansionPanelDetails>
                  <List className='w-100'>
                    {this.props.abstract_products.map((product) =>
                      <ListItemLink divider key={product.id} href={`/sectors/${this.props.sector.abbr}/products/${product.id}`}>
                        <ListItemText primary={product.short_name} className='f6' />
                      </ListItemLink>
                    )}
                  </List>
                </ExpansionPanelDetails>

              </ExpansionPanel> : ''}

            {this.props.abstract_trials.length > 0 ?
              <ExpansionPanel defaultExpanded={true}>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} ariacontrols={`trials-panel`} id={`trials-header`}>
                  <Typography className="f4">
                    Trials
                  </Typography>
                </ExpansionPanelSummary>

                <ExpansionPanelDetails>
                  <List className='w-100'>
                    {this.props.abstract_trials.map((trial) =>
                      <ListItemLink divider key={trial.id} href={`/sectors/${this.props.sector.abbr}/trials/${trial.id}`}>
                        <ListItemText
                          primary={trial.acronym || trial.short_name}
                        />
                      </ListItemLink>
                    )}
                  </List>
                </ExpansionPanelDetails>

              </ExpansionPanel>
            : ''}

            {this.props.abstract_texts.length > 0 && (this.props.current_user.id == 1 || this.props.current_user.id == 2) ?
              <ExpansionPanel defaultExpanded={true}>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} ariacontrols={`text-panel`} id={`text-header`}>
                  <Typography className="f4">
                    Text
                  </Typography>
                </ExpansionPanelSummary>

                <ExpansionPanelDetails>
                  <Box>
                    {this.props.abstract_texts.map((abstractText) =>
                      <p>{abstractText.text}</p>
                    )}
                  </Box>
                </ExpansionPanelDetails>

              </ExpansionPanel>
            : ''}
          </Box>

          <Box className='w-100 w-33-ns ml-auto'>
            <AbstractCard
              abstract={this.props.abstract}
              meetingName={this.props.meeting_name}
              abstractProducts={this.props.abstract_products}
              abstractTrials={this.props.abstract_trials}
              sector={this.props.sector}
              person={this.props.person}
            />
          </Box>

        </EntityLayout>
      </div>
    )
  }
}
