import React, { Fragment } from "react"
import Box from "../../Box"
import InlineSearchContainer from "../search/InlineSearchContainer"

export default function Filters(props) {

  return(
    <Fragment>
      <Box width={1}>
        <InlineSearchContainer
          searchLabel="Search Tweets"
          query={props.query}
          handleSearchClick={props.handleSearchClick}
          handleClearSearchClick={props.handleClearSearchClick}
        />
      </Box>
    </Fragment>
  )
}
