import React, { Fragment } from "react"
import Paper from "../../Paper"
import Box from "../../Box"
import Tabs from "../../tabs/Tabs"
import Tab from "../../tabs/Tab"
// import SuggestedAssociationsList from "./SuggestedAssociationsList"
import CompaniesList from "./CompaniesList"
import AssociatedCompanyList from "./AssociatedCompanyList"


export default function EntitiesPanel(props) {
  return (
    <React.Fragment>
      <Paper fullHeight>

        <Box height="100%">

          <Tabs variant='fullWidth' value={props.activeIndex} onChange={props.handleActiveIndexUpdate} className='bb b--black-10' aria-label="entities tabs">
            {/* <Tab label='Predicted' value='predicted' /> */}
            <Tab label='Companies' value='companies' />
            <Tab label='Associated' value='associated' />
          </Tabs>

          <Box mt={2} height="90%" overflow="auto">

            {props.activeIndex == 'predicted' ?
              <SuggestedAssociationsList
                predictedProducts={props.predictedProducts}
                predictedTrials={props.predictedTrials}
                activeProducts={props.activeProducts}
                abstractProducts={props.abstractProducts}
                activeTrials={props.activeTrials}
                abstractTrials={props.abstractTrials}
                handleCheckboxSelect={props.handleCheckboxSelect}
                predictedIndex={props.predictedIndex}
                handlePredictedIndexUpdate={props.handlePredictedIndexUpdate}
              />
            : ''}

            {props.activeIndex == 'companies' ?
              <CompaniesList
                companies={props.companies}
                companyId={props.companyId}
                companyQuery={props.companyQuery}
                searchedCompanies={props.searchedCompanies}
                handleCheckboxSelect={props.handleCheckboxSelect}
                handleCompanySearchClick={props.handleCompanySearchClick}
                handleCompanySearchChange={props.handleCompanySearchChange}
              />
            : ''}

            {props.activeIndex == 'associated' ?
              <Box>
                {props.patentCompany ?
                  <AssociatedCompanyList
                    patentCompany={props.patentCompany}
                    handleUnassociateClick={props.handleUnassociateClick}
                  />
                : "No associations"}
              </Box>
            : ''}

          </Box>
        </Box>

      </Paper>
    </React.Fragment>
  )
}
