import React, { Fragment, useState } from "react"
import CKEditor from 'ckeditor4-react';
import TextInput from "../../TextInput"
import ButtonLayout from "../ButtonLayout"
import { Button, RaisedButton } from "../../buttons"
import Chip from '@material-ui/core/Chip';
import Flex from "../../Flex"
import Box from "../../Box"
import Select from "../../Select"
import Dialog from "../../Dialog"
import SourceForm from "../SourceForm"

export default function ResultForm(props) {

  return (
    <Fragment>


      <Flex alignItems='end'>

        <Box width="25%">
          <Select
            value={props.result.trial_result_name_id}
            label='Name'
            entities={props.resultNames}
            displayEmpty={false}
            handleChange={props.handleSectionChange}
          />
        </Box>

        <Box ml={3} width={3/4}>
          {props.addName ?
            <Flex alignItems='center'>
              <Box>
                <TextInput
                  name='name'
                  label='Name'
                  value={props.newResultName}
                  onChange={props.handleNewNameChange}
                  className='w-100'
                />
              </Box>

              <Box ml={3}>
                <RaisedButton onClick={props.handleNewNameSave}>
                  Add Name
                </RaisedButton>
              </Box>
            </Flex>
          :
            <Button onClick={props.handleAddNameClick}>
              Add Name
            </Button>
          }
        </Box>

      </Flex>

      <Box my={2}>
        <Box>
          {props.source ?
            <Chip
              label={props.source.source_title}
              onDelete={props.handleSourceRemovalClick}
            />
          :
            <Button onClick={props.handleChooseSourceClick}>
              Choose Source
            </Button>
          }
        </Box>
      </Box>

      {props.openDialog ?
        <Dialog
          openDialog={props.openDialog}
          dialogTitle='Choose Source'
          withButtons={false}
          handleClick={props.handleAddClick}
          dialogContent={<SourceForm sources={props.sources} sourceTypes={props.sourceTypes} handleSourceChange={props.handleSourceChange} />}
        /> : ''}

      <Box>
        {/* <CKEditor
          data={props.update.content}
          onChange={props.handleChange}
        /> */}
        <TextInput
          name='result_value'
          label='Value'
          value={props.result.result_value}
          onChange={props.handleChange}
          className='w-100'
        />
      </Box>

      <ButtonLayout>
        <RaisedButton handleClick={props.handleSaveClick}>
          Save Result
        </RaisedButton>
      </ButtonLayout>
    </Fragment>
  )
}
