import React, { Fragment } from "react"
import Paper from "../../Paper"
import List from "../../lists/List";
import AssociatedProductListItem from "./AssociatedProductListItem"
import ListSubheader from '@material-ui/core/ListSubheader';

export default function AssociatedProductsList(props) {

  return (
    <Fragment>

      {props.abstractProducts.length > 0 ? <List>
        <ListSubheader>Products</ListSubheader>
        {props.abstractProducts.map((abstractProduct) =>
          <AssociatedProductListItem
            key={abstractProduct.id}
            abstractProduct={abstractProduct}
            handleUnassociateClick={props.handleUnassociateClick}
          />
        )}
      </List>  : ''}

    </Fragment>
  )
}
