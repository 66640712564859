import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import TextInput from "../../TextInput"
import RaisedButton from "../../buttons/RaisedButton"
import ButtonLayout from "../ButtonLayout"
import SectorListItem from "../../sectors/SectorListItem"
import Box from "../../Box"
import Typography from "@material-ui/core/Typography"
import { List } from "../../lists"

export default class ProfileForm extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    return(
      <Fragment>

        <div className='mv4'>
          <TextInput
            name='name'
            label='Name'
            value={this.props.targetSite.name}
            onChange={this.props.handleChange}
            className='w-100'
          />
        </div>

        <Box mt={3}>
          <Typography variant="h6" component="h6">
            Sectors
          </Typography>

          <List>
            {this.props.sectors.map((sector) =>
              <SectorListItem
                key={sector.id}
                sector={sector}
                checked={this.props.entitySectorIds.filter((entitySectorId) => entitySectorId == sector.id)[0] ? true : false}
                handleCheckboxSelect={this.props.handleCheckboxSelect}
              />
            )}
          </List>
        </Box>

        <ButtonLayout>
          <RaisedButton handleClick={this.props.handleSaveClick}>
            Save Target Site
          </RaisedButton>
        </ButtonLayout>

      </Fragment>
    )
  }
}
