import React from "react"
import MuiExpandLess from '@material-ui/icons/ExpandLess';

export default function ExpandMore(props) {
  return (
    <MuiExpandLess 
      color={props.color || 'action'} 
      {...props} 
    />
  )
}
