import React from "react"
import ResultItemContainer from "../search/ResultItemContainer"
import Box from "../Box"
import Typography from "@material-ui/core/Typography"

export default function SearchItem(props) {

  function renderSearchDetails(result) {
    return (
      <Box>
        <Typography variant="body2" color="textSecondary">
          {result.device_manufacturer_name}
        </Typography>
      </Box>
    );
  }

  return(
    <ResultItemContainer 
      searchItemType={props.result.record_type}
      searchTitle={props.result.device_name}
      date={props.result.date}
      searchDescription={props.result.description}
      url={`/sectors/${props.sector.abbr}/device_approvals/${props.result.id}`}
      target="_blank"
      searchDetails={renderSearchDetails(props.result)}
    />
  )
}