import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import { Table, TableHead, TableRow, TableCell, TableBody } from "../../tables"
import Paper from "../../Paper"
import StatusRow from "./StatusRow"
import Snackbar from "../../Snackbar"
import update from "immutability-helper"


export default class ProductStatuses extends Component {
  constructor(props) {
    super(props)
    this.handleDeleteClick = this.handleDeleteClick.bind(this)
    this.state = { statuses: this.props.statuses }
  }
  
  handleDeleteClick(status) {
    let paramName = this.props.type == 'development_statuses' ? 'development_status' : 'regulatory_status'
    $.ajax({
      type: "PUT",
      url: `/admin/${this.props.type}/${status.id}`,
      data: {
        [paramName]: {
          deleted: true, deleted_by: this.props.current_user.id
        }
      },
      dataType: 'json',
      success: (data) => {
        let index = this.state.statuses.indexOf(status)
        let statuses = update(this.state.statuses, { 
          $splice: [[index, 1]]
        })
        this.setState({
          statuses: statuses
        })
      },
      error: (data) => {
        this.setState({
          showSnackbar: true,
          snackbarText: `The status could not be deleted. Please try again.`
        })
      },
      complete: (data) => {
        setTimeout(() => {
          this.setState({
            showSnackbar: false,
            snackbarText: ''
          })
        }, 4000)
      }
    })
  }
  
  render() {
    const header = this.props.type == 'development_statuses' ? 'Development statuses' : 'Regulatory Statuses'
    let tabs = [['List', `/admin/${this.props.type}`], [`New ${this.props.type == 'development_statuses' ? 'Development' : 'Regulatory'} Status`, `/admin/${this.props.type}/new`]]
    return(
      <Fragment>
        
        <Paper>
          <Table className='w-100'>
            <TableHead>
              <TableRow>
                <TableCell>Status</TableCell>
                <TableCell>Region</TableCell>
                <TableCell>Approval Status</TableCell>
                <TableCell>Created by</TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            
            <TableBody>
              {this.state.statuses.map((status) =>
                <StatusRow 
                  key={status.id}
                  status={status}
                  handleDeleteClick={this.handleDeleteClick}
                />
              )}
            </TableBody>
            
          </Table>
        </Paper>
      </Fragment>
    )
  }
}
