import React from "react"
import IconButton from "../IconButton"
import DeleteIcon from "@material-ui/icons/Delete"

export default function DeleteButton(props) {
  return (
    <IconButton {...props}>
      <DeleteIcon fontSize='small' />
    </IconButton>
  )
}
