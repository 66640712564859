import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Paper from "../Paper"
import { withRouter } from "react-router-dom";
import { withSnackbar } from "../SnackbarContainer"
import SearchFilters from "./SearchFilters"
import Box from "../Box";
import update from 'immutability-helper';
import InlineSearchContainer from '../search/InlineSearchContainer'
import { Grid } from "@material-ui/core";
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import { IconButton, Tooltip } from '@material-ui/core';
import ExportDialog from "./ExportDialog";
import EmptyDataDisplay from "../EmptyDataDisplay";
import SearchIcon from '@material-ui/icons/Search'
import { Pagination } from "@material-ui/lab";
import IndexResults from "./IndexResults"
import { LinearProgress } from "@material-ui/core";

class Index extends Component {
  constructor(props) {
    super(props)
    this.getPatents = this.getPatents.bind(this)
    this.handleSearchChange = this.handleSearchChange.bind(this)
    this.handleSearchClick = this.handleSearchClick.bind(this)
    this.handlePageChange = this.handlePageChange.bind(this)
    this.handleOpen = this.handleOpen.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.handleFilterChange = this.handleFilterChange.bind(this)
    this.handleClearSearchClick = this.handleClearSearchClick.bind(this)
    this.handleCheckAllClick = this.handleCheckAllClick.bind(this)
    this.handleProductCodeClick = this.handleProductCodeClick.bind(this)
    this.handleApplicantClick = this.handleApplicantClick.bind(this)
    this.handleExportClick = this.handleExportClick.bind(this)
    this.handleExportClose = this.handleExportClose.bind(this);
    this.handleItemSelect = this.handleItemSelect.bind(this);
    this.handleExport = this.handleExport.bind(this);
    this.state = { patents: this.props.patents, patentsCount: this.props.patents_count, companyOptions: this.props.company_options, patentDataKindOptions: this.props.patent_data_kind_options, classificationOptions: this.props.classification_options, years: this.props.years, originalYears: this.props.years, activeIndex: 'All', date: this.props.date, anchorEl: null, loading: false, filterValues: { query: this.props.query, companies: this.props.companies, patent_data_kinds: this.props.patent_data_kinds, begin_year: this.props.begin_year, end_year: this.props.end_year, order_by: this.props.order_by, classifications: this.props.classifications, page: this.props.page }, exportDialogOpen: false, selectedItems: [], company: this.props.company || null }
  }

  componentDidMount() {
    if (this.props.filter) {

      let index = this.props.filter

      this.setState({
        activeIndex: index,
        filter: this.props.filter
      })
    }
  }

  handleProductCodeClick(productCode, deviceName) {
    let params = Object.assign({}, this.state.filterValues)
    
    params["product_codes"] = [`${productCode}: ${deviceName}`]

    this.getPatents(params)
  }

  handleApplicantClick(applicant) {
    let params = Object.assign({}, this.state.filterValues)

    params["manufacturers"] = [applicant]

    this.getPatents(params)
  }

  handleClearSearchClick(e) {
    let params = Object.assign({}, this.state.filterValues)
    
    params["query"] = null

    this.getPatents(params)
  }

  handleCheckAllClick(filterOptions, filterName) {
    let params = Object.assign({}, this.state.filterValues)

    if (filterOptions.length == 0) {
      params[filterName] = []
    } else {
      params[filterName] = filterOptions.map((option) => (option.value || option.name))
    }

    this.getPatents(params)
  }

  handleFilterChange(value, filter) {
    let params = Object.assign({}, this.state.filterValues)

    if (filter == 'years') {
      params["begin_year"] = value[0]
      params["end_year"] = value[1]
    } else if (filter == 'order_by') {
      params["order_by"] = value
    } else if (params[filter].includes(value)) {
      params[filter] = params[filter].filter((item) => item != value)
    } else {
      params[filter].push(value)
    }

    this.getPatents(params)
  }

  handleOpen(event) {
    this.setState({
      anchorEl: event.currentTarget
    })
  };

  handleClose() {
    this.setState({
      anchorEl: null
    })
  };

  handlePageChange(e, page) {
    let params = Object.assign({}, this.state.filterValues)

    params["page"] = page

    this.getPatents(params)
  }

  handleSearchChange(e) {
    let filterValues = update(this.state.filterValues, {
      query: { $set: e.target.value }
    })

    this.setState({
      filterValues: filterValues
    })
  }

  handleSearchClick(query) {
    let params = Object.assign({}, this.state.filterValues)

    params["query"] = query

    this.getPatents(params)
  }

  getPatents(params) {
    let url = this.props.url || `/sectors/${this.props.sector.abbr}/patents`

    $.ajax({
      type: 'GET',
      url: url,
      data: params,
      dataType: 'json',
      beforeSend: (xhr) => {
        this.setState({ loading: true });
      },
      success: (data) => {

        // let urlParams = {}
        // urlParams["query"] = params["query"]

        // let filterKeys = Object.keys(urlParams)
        // let queryParams = filterKeys.map((filterKey) => `${filterKey}=${urlParams[filterKey]}`)

        // this.props.history.push(`${url}?${queryParams.join("&")}`);

        // Initialize the state object with common properties
        const newState = {
          patents: data.patents,
          patentsCount: data.patents_count,
          companyOptions: data.company_options,
          patentDataKindOptions: data.patent_data_kind_options,
          years: data.years,
          filterValues: params,
          page: params.page,
          loading: false
        };

        this.setState(newState);
      },
      error: (jqXHR, textStatus, errorThrown) => {
        console.error('Error fetching patents:', textStatus, errorThrown);
        this.setState({ loading: false });  // Set loading to false on error
        this.props.snackbarShowMessage('Failed to fetch patents', 'error');
      }
    })
  }

  handleExportClick = () => {
    this.setState({ exportDialogOpen: true });
  };

  handleExportClose = () => {
    this.setState({ exportDialogOpen: false });
  };

  handleItemSelect = (itemId) => {
    this.setState(prevState => ({
      selectedItems: prevState.selectedItems.includes(itemId)
        ? prevState.selectedItems.filter(id => id !== itemId)
        : [...prevState.selectedItems, itemId]
    }));
  };

  handleExport = (format, exportOption, selectedColumns) => {
    const { filterValues, selectedItems } = this.state;
    
    let params = Object.assign({}, this.state.filterValues)

    params["file_format"] = format
    params["data_source"] = 'patents'
    params["columns"] = selectedColumns
  
    if (exportOption === 'selected' && selectedItems.length > 0) {
      params["selected"] = selectedItems
    } else if (exportOption === 'current_page') {
      params["page"] = filterValues.page
    } else {
      params["export_all"] = true
    }
  
    $.ajax({
      url: `/sectors/${this.props.sector.abbr}/patents/export`,
      method: 'POST',
      data: params,
      xhrFields: {
        responseType: 'blob'  // Set the response type to blob
      },
      beforeSend: (xhr) => {
        this.setState({ loading: true });
        xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
      },
      success: (blob, status, xhr) => {
        const filename = xhr.getResponseHeader('X-Filename') || 'export.csv';
        
        // Create a link element and trigger download
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = filename;
        link.click();

        // Clean up
        window.URL.revokeObjectURL(link.href);
        // this.props.snackbarShowMessage('Export completed. Your file is downloading.', 'success', 5000);
      },
      error: (jqXHR, textStatus, errorThrown) => {
        console.error('Export error:', textStatus, errorThrown);
        this.props.snackbarShowMessage('Failed to start export job', 'error');
      },
      complete: () => {
        this.setState({ loading: false });
        this.handleExportClose();
      }
    });
  };

  render() {
    
    const { patents, patentsCount, loading } = this.state;

    return(
      <Fragment>
        <Paper minHeight="500px" minFullHeight={patentsCount != 0} fullHeight={patentsCount === 0}>
          <Box height="100%" style={{overflowY: "auto", overflowX: "hidden" }}>
            <Box width={1/2} mx='auto' pt={1}>
              <InlineSearchContainer
                searchLabel="Search patents" 
                query={this.state.filterValues.query}
                suggestionsUrl={`/sectors/${this.props.sector.abbr}/patents/suggestions`}
                handleSearchClick={this.handleSearchClick}
                handleClearSearchClick={this.handleClearSearchClick}
              />
            </Box>

            <Box display="flex" justifyContent='right'>
              <Tooltip title="Export">
                <IconButton onClick={this.handleExportClick} disabled={this.state.loading} size="small">
                  <CloudDownloadIcon />
                </IconButton>
              </Tooltip>
            </Box>

            <ExportDialog
              open={this.state.exportDialogOpen}
              onClose={this.handleExportClose}
              onExport={this.handleExport}
              hasSelectedItems={this.state.selectedItems.length > 0}
            />

            <Grid container spacing={3}>
              <Grid item xs={4} sm={4} md={3} xl={2}>
                <SearchFilters
                  years={this.state.years}
                  companies={this.state.companyOptions}
                  company={this.state.company}
                  patentDataKinds={this.state.patentDataKindOptions}
                  classifications={this.state.classificationOptions}
                  recordCount={patentsCount}
                  filterValues={this.state.filterValues}
                  handleFilterChange={this.handleFilterChange}
                  handleCheckAllClick={this.handleCheckAllClick}
                />
              </Grid>

              <Grid item xs={12} sm={8} md={9} xl={10}>
                {patentsCount === 0 ?
                  <EmptyDataDisplay 
                    message="No results found. Please try adjusting your search or filters."
                    icon={SearchIcon}
                  />
                : 
                  <Fragment>
                    {loading && <LinearProgress />}
                    <IndexResults
                      patents={patents}
                      resultsCount={patentsCount}
                      activeIndex={this.state.activeIndex}
                      sector={this.props.sector}
                      query={this.state.query}
                      onItemSelect={this.handleItemSelect}
                      selectedItems={this.state.selectedItems}
                    />
                  </Fragment>
                }
              </Grid>
            </Grid>

            <Box display="flex" justifyContent='center' width={9/10}>
              <Pagination
                page={this.state.filterValues.page}
                count={patentsCount > 20 ? Math.round(patentsCount/20) : 1}
                onChange={this.handlePageChange}
                showFirstButton
                showLastButton
              />
            </Box>

          </Box>

        </Paper>
      </Fragment>
    )
  }
}

export default withRouter(withSnackbar(Index))
