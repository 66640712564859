import React, { Fragment } from "react"
import Paper from "../../Paper"
import List from "../../lists/List";
import ProductListItem from "./ProductListItem"
import TextInput from "../../TextInput"
import Flex from "../../Flex"
import Box from "../../Box"
import SearchIcon from '@material-ui/icons/Search'
import InputAdornment from '@material-ui/core/InputAdornment';
import { RaisedButton } from "../../buttons"

export default function ProductsList(props) {
  return (
    <Fragment>

      <Flex alignItems='center'>
        <Box width={1}>
          <TextInput
            name='query'
            label='Search Products'
            value={props.productQuery}
            onChange={props.handleProductSearchChange}
            fullWidth
            InputProps={{
              startAdornment: <InputAdornment position="start"><SearchIcon /></InputAdornment>,
            }}
            helperText='Search by name'
          />
        </Box>

        <Box ml='auto'>
          <RaisedButton onClick={props.handleProductSearchClick}>
            Search
          </RaisedButton>
        </Box>
      </Flex>

      <List>
        {(props.searchedProducts.length > 0 ? props.searchedProducts : props.products).map((product) =>
          <ProductListItem
            key={product._source ? product._source.id : product.id}
            product={product._source ? product._source : product}
            checked={props.activeProducts.indexOf(product._source ? product._source.id : product.id) == -1 ? false : true}
            handleCheckboxSelect={props.handleCheckboxSelect}
          />
        )}
      </List>

    </Fragment>
  )
}
