import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Flex from "../../../Flex"
import Box from "../../../Box"
import TextInput from "../../../TextInput"
import RaisedButton from "../../../buttons/RaisedButton"
import Typography from "@material-ui/core/Typography"
import Select from "../../../Select"

export default function Form(props) {

  return(
    <Fragment>
      <Typography variant="h6">
        New PMA Number
      </Typography>

      <Box mt={2}>
        <Flex mb={4} alignItems='center'>
          <Box mr={3}>
            <TextInput
              name='pma'
              label='PMA number'
              value={props.pma}
              onChange={props.handlePmaChange}
              className='w-100'
            />
          </Box>

          <Box mr={3}>
            <TextInput
              name='supplement_number'
              label='Supplement number'
              value={props.supplement_number}
              onChange={props.handlePmaChange}
              className='w-100'
            />
          </Box>

          <Box mr={3}>
            <Select
              label='Sector'
              name='sector_id'
              value={props.sector_id}
              entities={props.sectors}
              handleChange={props.handleSectorChange}
            />
          </Box>

          <Box>
            <RaisedButton onClick={props.handlePmaSave}>
              Add PMA
            </RaisedButton>
          </Box>
        </Flex>
      </Box>

    </Fragment>
  )
}
