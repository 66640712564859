import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Paper from "../../Paper"
import Flex from "../../Flex"
import Box from "../../Box"
import Highlighter from "react-highlight-words";
import Link from "../../Link"

export default class PublicationPanel extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    return(
      <Fragment>

        <Paper fullHeight={true}>

          <Box height="100%" overflow="auto">
            <Flex flexDirection='column' mb={2}>
              <Box width={1} mb={1}>
                <label className='b'>Title</label>
              </Box>
              <Box width={1}>
                {this.props.searchWords ?
                  <Highlighter
                    searchWords={this.props.searchWords}
                    autoEscape={true}
                    textToHighlight={this.props.publication.title}
                  />
                : this.props.publication.title}
              </Box>
            </Flex>

            <Flex flexDirection='column' mb={2}>
              <Box width={1} mb={1}>
                <label className='b'>PMID</label>
              </Box>
              <Box width={1}>
                <Link href={`https://pubmed.ncbi.nlm.nih.gov/${this.props.publication.pubmed_id}`} target="_blank">
                  {`${this.props.publication.pubmed_id} (view on pubmed site)`}
                </Link>
              </Box>
            </Flex>

            {/* <Flex flexDirection='column' mb={2}>
              <Box width={1} mb={1}>
                <label className='b'>Rank</label>
              </Box>
              <Box width={1}>
                {this.props.publication.rank ? this.props.publication.rank : 'N/A'}
              </Box>
            </Flex> */}

            <Flex flexDirection='column' mb={2}>
              <Box width={1} mb={1}>
                <label className='b'>Journal</label>
              </Box>

              <Box width={1}>
                {this.props.journal ? this.props.journal.short_name : "N/A"}
              </Box>
            </Flex>

            <Flex flexDirection='column' mb={2}>
              <Box width={1} mb={1}>
                <label className='b'>Citation</label>
              </Box>
              <Box>
                {this.props.publication.citation}
              </Box>
            </Flex>

            <Flex flexDirection='column'>
              <Box width={1} mb={1}>
                <label className='b'>Abstract</label>
              </Box>
              <Box>
                {this.props.searchWords ?
                  <Highlighter
                    searchWords={this.props.searchWords}
                    autoEscape={true}
                    textToHighlight={this.props.publicationText}
                  />
                : this.props.publicationText}
              </Box>
            </Flex>

            <Flex flexDirection='column' mb={2}>
              <Box width={1} mb={1}>
                <label className='b'>Authors</label>
              </Box>
              <Box>
                {this.props.authors.length > 0 ? this.props.authors.map((pubmedAuthor) =>
                  <Box key={pubmedAuthor.id}>
                    {pubmedAuthor.first_name} {pubmedAuthor.last_name}
                  </Box>
                ) : <Box fontStyle='italic'>None</Box>}
              </Box>
            </Flex>


            <Flex flexDirection='column' mb={2}>
              <Box width={1} mb={1}>
                <label className='b'>Keywords</label>
              </Box>
              <Box>
                {this.props.keywords.map((keyword) =>
                  <Box key={keyword.id}>
                    {keyword.name}
                  </Box>
                )}
              </Box>
            </Flex>

            <Flex flexDirection='column' mb={2}>
              <Box width={1} mb={1}>
                <label className='b'>Mesh terms</label>
              </Box>
              <Box>
                {this.props.meshTerms.map((meshTerm) =>
                  <Box key={meshTerm.id}>
                    {meshTerm.name}
                  </Box>
                )}
              </Box>
            </Flex>
          </Box>

        </Paper>

      </Fragment>
    )
  }
}
