import React, { Component, Fragment } from "react"
import Paper from "../../Paper"
import Select from "../../Select"
import TextInput from "../../TextInput"
import ButtonLayout from "../ButtonLayout"
import { RaisedButton } from "../../buttons"
import Box from '@material-ui/core/Box';
import Radio from '@material-ui/core/Radio';
import RadioGroup from "../../RadioGroup"
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

export default class AssociatorFilterPanel extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    return(
      <Fragment>

        <Paper className='w-100'>

          <Box display="flex" alignItems='start'>

            <Box mr={3}>
              <Select
                value={this.props.kind}
                name='kind'
                label='Kind'
                entities={this.props.patentKinds}
                includeBlank={false}
                handleChange={this.props.handleDropdownChange}
              />
            </Box>

            <Box mr={3}>
              <Select
                value={this.props.sectorId}
                name='sector_id'
                label='Sector'
                entities={this.props.sectors}
                includeBlank={false}
                handleChange={this.props.handleDropdownChange}
              />
            </Box>

            <Box>
              <Select
                value={this.props.priority}
                name='priority'
                label='Priority'
                entities={this.props.priorities}
                displayEmpty={false}
                handleChange={this.props.handleDropdownChange}
              />
            </Box>

          </Box>

          <Box mt={3}>
            <FormControl component="fieldset">
              <FormLabel component="legend">From</FormLabel>
              <RadioGroup aria-label="days-since" name="days-since" value={parseInt(this.props.timeSince)} onChange={this.props.handleDaysChange}>
                <FormControlLabel value={7} control={<Radio />} label="1 Week" />
                <FormControlLabel value={14} control={<Radio />} label="2 Weeks" />
                <FormControlLabel value={21} control={<Radio />} label="3 Weeks" />
                <FormControlLabel value={200} control={<Radio />} label="6 Months" />
              </RadioGroup>
            </FormControl>
          </Box>



          <Box mt={3}>
            <TextInput
              name='number'
              label='Patent Search'
              value={this.props.number}
              onChange={this.props.handleChange}
              className='w-100'
            />
          </Box>

          <ButtonLayout>
            <RaisedButton handleClick={this.props.handleFindPatentsClick}>
              Find Patents
            </RaisedButton>
          </ButtonLayout>

        </Paper>
      </Fragment>
    )
  }
}
