import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import DashboardModule from "./dashboard/Module"
import Flex from "./Flex"
import Box from "./Box"
import EditButton from "./admin/EditButton"
import { Button, RaisedButton } from "./buttons"
import Link from "./Link"
import Paper from "./Paper"
import { List, ListItem, ListItemText, ListItemLink } from "./lists"
import { Table, TableRow, TableCell, TableHead, TableBody } from "./tables"
import Typography from "@material-ui/core/Typography"
import InfoIcon from '@material-ui/icons/Info';
import IconButton from "./IconButton"
import Tabs from './tabs/Tabs';
import Tab from './tabs/Tab';
import Grid from '@material-ui/core/Grid';
import Chart from "./charts/Chart"
import CompareIcon from '@material-ui/icons/Compare';
import ArticlesList from "./articles/ArticlesList"
import TopUpdates from "./trials/TopUpdates"
import NearCompletionModule from "./trials/NearCompletionModule"
import { ListItemIcon } from "@material-ui/core"

export default class UserDashboard extends Component {
  constructor(props) {
    super(props)
    this.handleActiveIndexUpdate = this.handleActiveIndexUpdate.bind(this)
    this.handleUpdateIndexChange = this.handleUpdateIndexChange.bind(this)
    this.state = { activeIndex: 0, updateIndex: this.props.updates_date_index, trialsChartData: this.props.trials_chart_data, trialCategories: this.props.trial_categories, productsChartData: this.props.products_chart_data, productCategories: this.props.product_categories, publicationsChartData: this.props.publications_chart_data, publicationCategories: this.props.publication_categories, presentationChartData: this.props.presentation_chart_data, presentationCategories: this.props.presentation_categories, trialChanges: this.props.trial_changes, trendingAbstractProducts: this.props.trending_abstract_products, trendingPublicationProducts: this.props.trending_publication_products, trendingTrialProducts: this.props.trending_trial_products }
  }

  handleActiveIndexUpdate(e, newValue) {
    this.setState({
      activeIndex: newValue
    })
  }

  handleUpdateIndexChange(e, newValue) {
    e.preventDefault();

    let dateParams = { 0: "1_week", 1: "1_month", 2: "3_months" }

    $.ajax({
      type: 'GET',
      url: `/sectors/${this.props.sector.abbr}/top_updates`,
      data: {
        date_value: dateParams[newValue]
      },
      dataType: 'json',
      success: (data) => {
        console.log(data.trial_changes)
        this.setState({
          trialChanges: data.trial_changes,
          updateIndex: newValue
        })
      }
    })
  }

  render() {
    const chartReloadKey = Math.random()
    return (
      <Fragment>

        {/* <Paper className='mb3'>

          <Flex alignItems='center'>
            <Box pl={3}>
              <div className='mv3'>
                <h2 className='f4 fw5 ma0' style={{letterSpacing: '.0125em', lineHeight: '2rem'}}>
                  {`${this.props.user.first_name} ${this.props.user.last_name}`}
                </h2>

                <h3 className='f5 fw5 ma0' style={{letterSpacing: '.0125em', lineHeight: '2rem'}}>
                  {this.props.user.role ? this.props.user.role : 'Add a role'}
                </h3>

                <h3 className='f6 fw5 mt0' style={{letterSpacing: '.00714em', color: 'rgba(0,0,0,.54)'}}>
                  {this.props.user_company ? this.props.user_company.name : 'Company Name'}
                </h3>
              </div>
            </Box>

            <Box className='ml-auto'>
              <EditButton />
            </Box>

          </Flex>
        </Paper> */}



        <Grid container spacing={3} component={Box} pb={3}>

          <Grid item xs={4} lg>
            <DashboardModule>
              {this.props.total_companies} Companies
            </DashboardModule>
          </Grid>

          <Grid item xs={4} lg>
            <DashboardModule>
              {this.props.total_products} Products
            </DashboardModule>
          </Grid>

          <Grid item xs={4} lg>
            <DashboardModule>
              {this.props.total_trials} Trials
            </DashboardModule>
          </Grid>

          <Grid item xs={6} lg>
            <DashboardModule>
              {this.props.total_abstracts} Presentations
            </DashboardModule>
          </Grid>

          <Grid item xs={6} lg>
            <DashboardModule>
              {this.props.total_manuscripts} Publications
            </DashboardModule>
          </Grid>

        </Grid>

        <Grid container alignItems='stretch' spacing={3} component={Box} pb={3}>

          <Grid item xs={12} md={6} lg={8}>
            <TopUpdates
              updates={this.state.trialChanges}
              tabIndex={this.state.updateIndex}
              sector={this.props.sector}
              handleTabIndexChange={this.handleUpdateIndexChange}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <DashboardModule className='h-100'>
              <Box mb={1}>
                <Typography variant="h6">
                  New entities
                </Typography>
              </Box>

              <Flex>

                <Box width={1}>
                  <Tabs value={this.state.activeIndex} onChange={this.handleActiveIndexUpdate} className='bb b--black-10' aria-label="nav tabs" variant="fullWidth">
                    <Tab label='Products' />
                    <Tab label='Trials' />
                    <Tab label='Companies' />
                  </Tabs>
                </Box>

              </Flex>

              {this.state.activeIndex == 2 ? <List>
                {this.props.new_companies.map((company) =>
                  <ListItemLink key={company.id} href={`/sectors/${this.props.sector.abbr}/companies/${company.id}`}>
                    <ListItemText primary={company.short_name} secondary={humanDateFormat(company.created_at)} />
                  </ListItemLink>
                )}
              </List> : ''}
              {this.state.activeIndex == 0 ? <List>
                {this.props.new_products.map((product) =>
                  <ListItemLink key={product.id} href={`/sectors/${this.props.sector.abbr}/products/${product.id}`}>
                    <ListItemText primary={product.short_name} secondary={humanDateFormat(product.created_at)} />
                  </ListItemLink>
                )}
              </List> : ''}
              {this.state.activeIndex == 1 ? <List>
                {this.props.new_trials.map((trial) =>
                  <ListItemLink key={trial.id} href={`/sectors/${this.props.sector.abbr}/trials/${trial.id}`}>
                    <ListItemText primary={trial.data_view_acronym || trial.data_view_short_name} secondary={humanDateFormat(trial.created_at)} />
                  </ListItemLink>
                )}
              </List> : ''}
            </DashboardModule>

          </Grid>

        </Grid>

        {this.props.user.user_company_id == 1 ?
          <Grid container justifyContent='center' alignItems='flex-stretch' spacing={3} component={Box} pb={3}>
            <Grid item xs={6}>
              <Paper style={{height: '600px'}}>
                <Box height="100%">
                  <Box p={2}>
                    <Typography variant="h6">
                      Latest safety alerts
                    </Typography>
                  </Box>

                  <Box height="90%" overflow='auto'>
                    <List>
                      {this.props.latest_recalls.map((recall) =>
                        <ListItemLink key={recall.id} href={recall.url} divider target="_blank">
                          <ListItemText>
                            <Typography variant="caption" color="textSecondary">
                              {recall.country_display_name}
                            </Typography>

                            <Box pb={1}>
                              <Typography variant="body1">
                                {recall.title}
                              </Typography>
                            </Box>

                            <Box maxWidth="40em">
                              <Typography variant="body2" color="textSecondary">
                                <div
                                  dangerouslySetInnerHTML={{__html: recall.recall_type ? `${humanDateFormat(recall.date)} - ${recall.recall_type}` : humanDateFormat(recall.date) }}
                                />
                              </Typography>
                            </Box>
                          </ListItemText>
                        </ListItemLink>
                      )}
                    </List>
                  </Box>
                </Box>
              </Paper>
            </Grid>

            <Grid item xs={6}>
              <Paper style={{height: '600px'}}>
                <Box height="100%">
                  <Box p={2}>
                    <Typography variant="h6">
                      Latest registry records
                    </Typography>
                  </Box>

                  <Box height="90%" overflow='auto'>
                    <List>
                      {this.props.latest_approvals.map((approval) =>
                        <ListItemLink key={approval.id} href={approval.url} divider target="_blank">
                          <ListItemText>
                            <Typography variant="caption" color="textSecondary">
                              {approval.country_display_name}
                            </Typography>

                            <Box pb={1}>
                              <Typography variant="body1">
                                {approval.device_name}
                              </Typography>
                            </Box>

                            <Box maxWidth="40em">
                              <Typography variant="body2" color="textSecondary">
                                <div
                                  dangerouslySetInnerHTML={{__html: approval.description ? `${humanDateFormat(approval.date)}` : humanDateFormat(approval.date) }}
                                />
                              </Typography>
                            </Box>
                          </ListItemText>
                        </ListItemLink>
                      )}
                    </List>
                  </Box>
                </Box>
              </Paper>
            </Grid>
          </Grid>
        : ""}

        <Grid container justifyContent='center' alignItems='flex-stretch' spacing={3} component={Box} pb={3}>
          <Grid item xs={10}>
            <NearCompletionModule
              trials={this.props.trials_with_upcoming_milestones}
              sector={this.props.sector}
            />
          </Grid>
        </Grid>

        <Grid container justifyContent='center' alignItems='flex-stretch' spacing={3} component={Box} pb={3}>
          <Grid item xs={4}>
            <Paper>
              <Box>
                <Flex alignItems='center'>
                  <Box>
                    <Typography variant='h6'>
                      Trial Comparisons
                    </Typography>
                  </Box>

                  <Box>
                    <IconButton tooltip='Compare trials' component='a' href={`/sectors/${this.props.sector.abbr}/trials/compare_trials`}>
                      <CompareIcon />
                    </IconButton>
                  </Box>
                </Flex>

                <Box textAlign='center' fontSize="18px" py={2}>
                  Compare <span className='b'>trials</span> on key data points including,
                </Box>

                <Box>
                  <Flex alignItems='center' justifyContent='space-evenly'>
                    <Box>
                      <li>Start Date</li>
                    </Box>
                    <Box>
                      <li>Completion Date</li>
                    </Box>
                    <Box>
                      <li>Status</li>
                    </Box>
                  </Flex>

                  <Box mt={2} textAlign='center'>and more...</Box>
                </Box>

                <Box mt={4} mb={2} mx="auto" width={1/2}>
                  <RaisedButton className='white-important' href={`/sectors/${this.props.sector.abbr}/trials/compare_trials`}>
                    Start comparing now
                  </RaisedButton>
                </Box>
              </Box>
            </Paper>
          </Grid>

          {this.props.sector.monitor_product ?
            ''
          :
            <Grid item xs={4}>
              <Paper>
                <Flex alignItems='center'>
                  <Box>
                    <Typography variant='h6'>
                      Product Comparisons
                    </Typography>
                  </Box>

                  <Box>
                    <IconButton tooltip='Compare products' component='a' href={`/sectors/${this.props.sector.abbr}/products/compare_products`}>
                      <CompareIcon />
                    </IconButton>
                  </Box>
                </Flex>

                <Box textAlign='center' fontSize="18px" py={2}>
                  Compare <span className='b'>products</span> on key data points including,
                </Box>

                <Box>
                  <Flex alignItems='center' justifyContent='space-evenly'>
                    <Box>
                      <li>Target markets</li>
                    </Box>
                    <Box>
                      <li>Target sites</li>
                    </Box>
                    <Box>
                      <li>Length</li>
                    </Box>
                  </Flex>

                  <Box mt={2} textAlign='center'>and more...</Box>
                </Box>

                <Box mt={4} mb={2} mx="auto" width={1/2}>
                  <RaisedButton className='white-important' href={`/sectors/${this.props.sector.abbr}/products/compare_products`}>
                    Start comparing now
                  </RaisedButton>
                </Box>
              </Paper>
            </Grid>
          }
        </Grid>

        <Grid container spacing={3} component={Box} justifyContent='center' pb={3}>
          <Grid item xs={12} md={10}>
            <Paper style={{height: '600px'}}>
              <Typography variant="h6">
                Latest News
              </Typography>

              <Box height="100%">
                <Box mt={2} overflow='auto' height="90%">
                  <ArticlesList
                    dense={true}
                    newsItems={this.props.all_news}
                    sector={this.props.sector}
                  />
                </Box>
              </Box>
            </Paper>
          </Grid>
        </Grid>

        <Box mt={4} mb={2}>
          <Flex alignItems='baseline'>
            <Box>
              <Typography variant="h6" component="h6">
                Trending Products
              </Typography>
            </Box>

            <Box ml={2}>
              <Typography variant="caption">
                {this.props.sector.market_id == 3 ? '' : "During the last year"}
              </Typography>
            </Box>
          </Flex>
        </Box>

        <Grid container spacing={3} alignItems='stretch' component={Box} pb={3}>
          <Grid item xs={12} lg={4}>
            <Paper className='h-100'>
              <Flex alignItems='center'>
                <Box>
                  <Typography variant="h6">
                    By trials
                  </Typography>
                </Box>

                <Box ml='auto'>
                  <Typography variant="caption">
                    {this.props.sector.market_id == 3 ? 'All time' : ""}
                  </Typography>
                </Box>
              </Flex>

              <Box mt={2}>
                <Table>
                  <TableHead>
                    <TableRow hover={false}>
                      <TableCell>Product</TableCell>
                      <TableCell align='right'># of trials</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.trendingTrialProducts.map((product) =>
                      <TableRow key={product.id}>
                        <TableCell>
                          <Link href={`/sectors/${this.props.sector.abbr}/products/${product.id}`}>
                            <Box>
                              {product.short_name}
                            </Box>
                          </Link>
                        </TableCell>
                        <TableCell align='right'>
                          {product.trial_count}
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </Box>
            </Paper>
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <Paper className='h-100'>
              <Flex>
                <Box>
                  <Typography variant="h6">
                    By publication
                  </Typography>
                </Box>

                <Box ml='auto'>
                  <Typography variant="caption">
                    {this.props.sector.market_id == 3 ? 'Past year' : ""}
                  </Typography>
                </Box>
              </Flex>

              <Box mt={2}>
                <Table>
                  <TableHead>
                    <TableRow hover={false}>
                      <TableCell>Product</TableCell>
                      <TableCell align='right'># of publications</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.trendingPublicationProducts.map((product) =>
                      <TableRow key={product.id}>
                        <TableCell>
                          <Link href={`/sectors/${this.props.sector.abbr}/products/${product.id}`}>
                            <Box>
                              {product.short_name}
                            </Box>
                          </Link>
                        </TableCell>
                        <TableCell align='right'>
                          {product.manuscript_count}
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </Box>
            </Paper>
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <Paper className='h-100'>
              <Flex>
                <Box>
                  <Typography variant="h6">
                    By presentations
                  </Typography>
                </Box>

                <Box ml='auto'>
                  <Typography variant="caption">
                    {this.props.sector.market_id == 3 ? 'Past year' : ""}
                  </Typography>
                </Box>
              </Flex>

              <Box mt={2}>
                <Table>
                  <TableHead>
                    <TableRow hover={false}>
                      <TableCell>Product</TableCell>
                      <TableCell align='right'># of presentations</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.trendingAbstractProducts.map((product) =>
                      <TableRow key={product}>
                        <TableCell>
                          <Link href={`/sectors/${this.props.sector.abbr}/products/${product.id}`}>
                            <Box>
                              {product.short_name}
                            </Box>
                          </Link>
                        </TableCell>
                        <TableCell align='right'>
                          {product.abstract_count}
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </Box>
            </Paper>
          </Grid>
        </Grid>

        <Box mb={2}>
          <Typography variant="h6" component="h6">
            My Entities
          </Typography>
        </Box>

        <Grid container component={Box} alignItems='stretch' spacing={3} pb={3}>

          <Grid item xs={4}>
            <DashboardModule className='h-100'>
              <Box pb={2} fontSize={18} borderBottom={1} borderColor="#e5e5e5">
                My Companies
              </Box>

              {this.props.my_companies.length > 0 ?
                <List>
                  {this.props.my_companies.map((myCompany) =>
                    <ListItemLink key={myCompany.company.id} button href={`/sectors/${this.props.sector.abbr}/companies/${myCompany.company.id}`}>
                      <ListItemText primary={myCompany.company.short_name} />
                    </ListItemLink>
                  )}
                </List>
              :
                <Flex justifyContent='center' mt={2}>
                  <Box alignSelf='center' maxWidth="50%">
                    <Box>
                      <Typography variant="body1">
                        You haven't added any companies to your favorites
                      </Typography>
                      <Box pt={2}>
                        <Typography variant="body2">
                          When you do, they'll show up here
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Flex>
              }
            </DashboardModule>
          </Grid>

          <Grid item xs={4}>
            <DashboardModule className='h-100'>
              <Box pb={2} fontSize={18} borderBottom={1} borderColor="#e5e5e5">
                My Products
              </Box>

              { this.props.my_products.length > 0 ?
                <List>
                  {this.props.my_products.map((myProduct) =>
                    <ListItemLink key={myProduct.product.id} button href={`/sectors/${this.props.sector.abbr}/products/${myProduct.product.id}`}>
                      <ListItemText primary={myProduct.product.short_name} />
                    </ListItemLink>
                  )}
                </List>
              :
                <Flex justifyContent='center' mt={2}>
                  <Box alignSelf='center' maxWidth="50%">
                    <Box>
                      <Typography variant="body1">
                        You haven't added any products to your favorites
                      </Typography>
                      <Box pt={2}>
                        <Typography variant="body2">
                          When you do, they'll show up here
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Flex>
              }
            </DashboardModule>
          </Grid>

          <Grid item xs={4}>
            <DashboardModule className='h-100'>
              <Box pb={2} fontSize={18} borderBottom={1} borderColor="#e5e5e5">
                My Trials
              </Box>

              {this.props.my_trials.length > 0 ?
                <List>
                  {this.props.my_trials.map((myTrial) =>
                    <ListItemLink key={myTrial.id} button href={`/sectors/${this.props.sector.abbr}/trials/${myTrial.id}`}>
                      <ListItemText primary={myTrial.acronym || myTrial.short_name} />
                    </ListItemLink>
                  )}
                </List>
              :
                <Flex justifyContent='center' mt={2}>
                  <Box alignSelf='center' maxWidth="50%">
                    <Box>
                      <Typography variant="body1">
                        You haven't added any trials to your favorites
                      </Typography>
                      <Box pt={2}>
                        <Typography variant="body2">
                          When you do, they'll show up here
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Flex>
              }
            </DashboardModule>

          </Grid>

        </Grid>

        <Grid container spacing={3} component={Box} pb={3} justifyContent='center'>
          <Grid item xs={12} md={10}>
            <Paper>
              <Typography variant="h6">
                Trials by year
              </Typography>

              <Box mt={2}>
                <Chart
                  key={`trials-by-year-${chartReloadKey}`}
                  chartId='trial-by-year-count-chart'
                  chartOptions={{ type: 'column', height: '35%' }}
                  exportingChartOptions={{ credits: { enabled: true, text: '\u00A9 pvpipeline' } }}
                  legend={true}
                  data={this.state.trialsChartData}
                  categories={this.state.trialCategories}
                  title=''
                  yAxisTitle='# of trials'
                  xAxisTitle='Year'
                  simple={true}
                />
              </Box>
            </Paper>
          </Grid>

          {/* <Grid item xs={12} md={6}>
            <Paper>
              <Typography variant="h6">
                Products by year
              </Typography>

              <Box mt={2}>
                <Chart
                  key={`products-by-year-${chartReloadKey}`}
                  chartId='product-by-year-count-chart'
                  chartOptions={{ type: 'column', height: '35%' }}
                  exportingChartOptions={{ credits: { enabled: true, text: '\u00A9 pvpipeline' } }}
                  legend={true}
                  data={this.state.productsChartData}
                  categories={this.state.productCategories}
                  title=''
                  yAxisTitle='# of products'
                  xAxisTitle='Year'
                  simple={true}
                />
              </Box>
            </Paper>
          </Grid> */}
        </Grid>

        <Grid container spacing={3} component={Box} pb={3}>
          <Grid item xs={12} md={6}>
            <Paper>
              <Typography variant="h6">
                Publications by year
              </Typography>

              <Box mt={2}>
                <Chart
                  key={`publications-by-year-${chartReloadKey}`}
                  chartId='publications-by-year-count-chart'
                  chartOptions={{ type: 'column', height: '35%' }}
                  exportingChartOptions={{ credits: { enabled: true, text: '\u00A9 pvpipeline' } }}
                  legend={true}
                  data={this.state.publicationsChartData}
                  categories={this.state.publicationCategories}
                  title=''
                  yAxisTitle='# of publications'
                  xAxisTitle='Year'
                  simple={true}
                />
              </Box>
            </Paper>
          </Grid>

          <Grid item xs={12} md={6}>
            <Paper>
              <Typography variant="h6">
                Presentations by year
              </Typography>

              <Box mt={2}>
                <Chart
                  key={`presentations-by-year-${chartReloadKey}`}
                  chartId='presentations-by-year-count-chart'
                  chartOptions={{ type: 'column', height: '35%' }}
                  exportingChartOptions={{ credits: { enabled: true, text: '\u00A9 pvpipeline' } }}
                  legend={true}
                  data={this.state.presentationChartData}
                  categories={this.state.presentationCategories}
                  title=''
                  yAxisTitle='# of presentations'
                  xAxisTitle='Year'
                  simple={true}
                />
              </Box>
            </Paper>
          </Grid>
        </Grid>

      </Fragment>
    )
  }
}
