import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Flex from "../Flex"
import Box from "../Box"
import { Table, TableHead, TableRow, TableBody, TableCell, TableHeadSort } from "../tables"
import Link from "../Link"
import Typography from "@material-ui/core/Typography"

export default class ManuscriptsTable extends Component {
  constructor(props) {
    super(props)
    this.handleRequestSort = this.handleRequestSort.bind(this)
    this.desc = this.desc.bind(this)
    this.stableSort = this.stableSort.bind(this)
    this.getSorting = this.getSorting.bind(this)
    this.state = { order: 'desc', orderBy: 'published_date' }
  }

  desc(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = cmp(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
  }

  getSorting(order, orderBy) {
    return order === 'desc' ? (a, b) => this.desc(a, b, orderBy) : (a, b) => -this.desc(a, b, orderBy);
  }

  handleRequestSort(event, property) {
    const isDesc = this.state.orderBy === property && this.state.order === 'desc';

    this.setState({
      order: isDesc ? 'asc' : 'desc',
      orderBy: property
    })
  };

  render() {
    return(
      <Fragment>
        <Flex justifyContent='right' mb={2}>
          <Typography variant="body2">
            {`${this.props.manuscripts.length} publications`}
          </Typography>
        </Flex>
        <Box overflow='auto' height="80%">
          <Table size='small' stickyHeader={true} aria-label='product manuscripts table'>

            <TableHeadSort
              headers={[{ name: 'title', label: 'Publication' }, { name: 'author', label: 'Authors' }, { name: 'journal_name', label: 'Journal' }, { name: 'published_date', label: 'Published' }]}
              order={this.state.order}
              orderBy={this.state.orderBy}
              onRequestSort={this.handleRequestSort}
            />

            <TableBody>
              {this.stableSort(this.props.manuscripts, this.getSorting(this.state.order, this.state.orderBy)).map((manuscript) =>
                <TableRow key={manuscript.id}>
                  <TableCell className='w-60'>
                    <Link href={`/sectors/${this.props.sector.abbr}/publications/${manuscript.id}`}>
                      {manuscript.title}
                    </Link>
                  </TableCell>
                  <TableCell className='w-15'>
                    {manuscript.author}
                    {/* {manuscript.people.length > 0 ? manuscript.people.map((person) =>
                      <Link key={person.id} href={`/sectors/${this.props.sector.abbr}/people/${person.id}`}>
                        <Box>
                          {person.name.text}
                        </Box>
                      </Link>
                    ) : ''} */}
                  </TableCell>
                  <TableCell className='w-15'>
                    {manuscript.journal_name}
                  </TableCell>
                  <TableCell className='w-25'>
                    {humanDateFormat(manuscript.published_date)}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </Box>

      </Fragment>
    )
  }
}
