import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Chart from "../../charts/Chart"
// import Timeline from "../charts/Timeline"
import DumbbellChart from "../../charts/DumbbellChart"

export default function DumbbellChartContainer(props) {

  function formatData() {
    const chartData = props.chartData.map((chartData) => {
      let date = parseFloat(chartData.x)
      let endDate = parseFloat(chartData.x2)
      return({ low: date, high: endDate, name: chartData.description })
    })

    return(chartData)
  }

  return (
    <DumbbellChart
      chartId='product-trials-timeline'
      chartOptions={{type: 'dumbell', inverted: true, height: 300}}
      legend={true}
      title={props.title}
      xAxisType='category'
      xAxisTitle='Source Date'
      yAxisType='datetime'
      yAxisTitle='Trial duration'
      formattedData={formatData()}
    />

  )
}
