import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Paper from "../Paper"
import ManuscriptsTable from "./ManuscriptsTable"
import ManuscriptsChartContainer from "./ManuscriptsChartContainer"
import ManuscriptsChartDataTable from "./ManuscriptsChartDataTable"
import Link from "../Link"
import Select from "../Select"
import Flex from "../Flex"
import Box from "../Box"
import Typography from "@material-ui/core/Typography"
import BarChartIcon from '@material-ui/icons/BarChart';
import ListIcon from '@material-ui/icons/List';
import IconButton from "../IconButton"
import Tabs from "../tabs/Tabs"
import Tab from "../tabs/Tab"
import FilterChip from '../filters/FilterChip';


export default class Manuscripts extends Component {
  constructor(props) {
    super(props)
    this.handleListViewClick = this.handleListViewClick.bind(this)
    this.handleChartViewClick = this.handleChartViewClick.bind(this)
    this.handleActiveIndexUpdate = this.handleActiveIndexUpdate.bind(this)
    this.handleFilterChange = this.handleFilterChange.bind(this)
    this.getManuscripts = this.getManuscripts.bind(this)
    this.state = { manuscripts: this.props.manuscripts, view: 'list', activeIndex: 0, filterValues: { journal_ids: [] } }
  }

  handleListViewClick(e) {
    this.setState({
      view: 'list'
    })
  }

  handleChartViewClick(e) {
    this.setState({
      view: 'chart'
    })
  }

  handleActiveIndexUpdate(e, newValue) {
    this.setState({
      activeIndex: newValue
    })
  }

  handleFilterChange(name, value) {
    let params = Object.assign({}, this.state.filterValues)

    params[name] = value

    this.getManuscripts(params)
  }

  getManuscripts(params) {
    $.ajax({
      type: 'GET',
      url: `/sectors/${this.props.sector.abbr}/trials/${this.props.product.id}/manuscripts`,
      data: params,
      dataType: 'json',
      success: (data) => {
        this.setState({
          manuscripts: data.manuscripts,
          filterValues: params
        })
      }
    })
  }

  render() {
    const selectedJournals = this.props.journals.filter(journal => this.state.filterValues.journal_ids.includes(journal.value) ? journal : null)
    return(
      <Fragment>

        <Paper fullHeight={this.state.view == 'list' ? true : false}>

          <Box height="100%">

            <Flex mb={2}>
              {this.state.view == 'list' ? <Box width={1/5}>
                <Select
                  multiple
                  label='Journal'
                  name='journal_id'
                  value={this.state.filterValues.journal_ids}
                  entities={this.props.journals}
                />
              </Box> : ''}

              {this.state.manuscripts.length > 0 ? <Box ml='auto'>
                <IconButton onClick={this.handleListViewClick} color={this.state.view == 'list' ? 'primary' : 'default'}>
                  <ListIcon />
                </IconButton>

                <IconButton onClick={this.handleChartViewClick} color={this.state.view == 'chart' ? 'primary' : 'default'} tooltip='Chart'>
                  <BarChartIcon />
                </IconButton>
              </Box> : ''}
            </Flex>

            {this.state.view == 'list' ? <Flex minHeight='28px' alignItems='center'>
              {selectedJournals.map((activeFilter) =>
                <FilterChip
                  key={activeFilter.value}
                  activeFilter={activeFilter}
                  type='journal_ids'
                  handleDelete={this.handleDelete}
                />
              )}
            </Flex> : ''}

            {this.state.manuscripts.length > 0 ? ''
            :
              <Flex justifyContent='center' height="50%">
                <Box alignSelf='center' maxWidth="50%">
                  <Box>
                    <Typography variant="body1">
                      No publications found for this trial
                    </Typography>
                  </Box>
                </Box>
              </Flex>
            }

            {this.state.view == 'list' && this.state.manuscripts.length > 0 ?
              <ManuscriptsTable
                manuscripts={this.state.manuscripts}
                sector={this.props.sector}
              />
            : ''}

            {this.state.view == 'chart' ?
              <Box>
                <Box mb={2}>
                  <ManuscriptsChartContainer
                    trial={this.props.trial}
                    chartData={this.props.chart_data}
                    categories={this.props.categories}
                    groupedManuscriptsData={this.props.grouped_manuscripts_data}
                  />
                </Box>

                <Box textAlign='center'>
                  <Tabs centered value={this.state.activeIndex} onChange={this.handleActiveIndexUpdate} className='bb b--black-10' aria-label="product manuscripts chart tabs">
                    <Tab label='Data' />
                  </Tabs>
                </Box>

                <Box>
                  <ManuscriptsChartDataTable
                    groupedManuscriptsData={this.props.grouped_manuscripts_data}
                  />
                </Box>

              </Box>
            : ''}

          </Box>

        </Paper>

      </Fragment>
    )
  }
}
