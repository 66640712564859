import React, { Fragment, useState } from "react"
import { List, ListItemLink, ListItemText } from "../../lists"
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ExpandLess from '../../ExpandLess';
import ExpandMore from '../../ExpandMore';
import Collapse from '@material-ui/core/Collapse';
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction"

export default function SearchResultsList(props) {
  const [open, setOpen] = useState(false)

  return(
    <List>
      {Object.keys(props.ctGovTrials).length > 0 ?
        Object.keys(props.ctGovTrials).map((nctId) =>
          <ListItemLink key={props.ctGovTrials[nctId][0].id} divider button href={`/admin/ct_gov_trials/${props.ctGovTrials[nctId][0].id}`}>
            <ListItemText primary={props.ctGovTrials[nctId][0].acronym || props.ctGovTrials[nctId][0].brief_title} />
          </ListItemLink>
      ) : 'No ct gov trials found'}
    </List>
  )
}
