import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import { ListItem, ListItemText } from "../../lists"
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Checkbox from "../../Checkbox"

export default class MetricListItem extends Component {
  constructor(props) {
    super(props)
    this.handleCheckboxSelect = this.handleCheckboxSelect.bind(this)
  }
  
  handleCheckboxSelect(e) {
    this.props.handleCheckboxSelect(this.props.metric, this.props.checked, this.props.characteristicGroupMetric)
  }
  
  render() {
    return(
      <ListItem button key={this.props.metric.id} onClick={this.handleCheckboxSelect} role={undefined}>
        <ListItemIcon>
          <Checkbox
            edge="start"
            checked={this.props.checked} 
            tabIndex={-1}
            disableRipple
          />
        </ListItemIcon>
        
        <ListItemText primary={this.props.metric.name} />
        
      </ListItem>
    )
  }
}
