import React, { Component, Fragment } from "react"
import Paper from "../../Paper"
import Flex from "../../Flex"
import Box from "../../Box"
import Select from "../../Select"
import TextInput from "../../TextInput"
import { RaisedButton } from "../../buttons"
import { Table, TableRow, TableCell, TableHead, TableBody } from "../../tables"
import LinearProgress from '@material-ui/core/LinearProgress';
import Link from "../../Link"

class DbSearch extends Component {
  constructor(props) {
    super(props)
    this.handleQueryChange = this.handleQueryChange.bind(this)
    this.handleSearchClick = this.handleSearchClick.bind(this)
    this.handleSectorChange = this.handleSectorChange.bind(this)
    this.state = { ctGovTrials: null, query: '', sector_search_term_id: '', loading: false }
  }

  handleQueryChange(e) {
    this.setState({
      query: e.target.value
    })
  }

  handleSectorChange(name, value) {

    let sectorSearchTerm = this.props.sector_search_terms.filter(sectorSearchTerm => sectorSearchTerm.value == value)[0]

    $.ajax({
      type: "GET",
      url: "/admin/ct_gov_trials/search",
      data: {
        query: sectorSearchTerm.name
      },
      dataType: 'json',
      beforeSend: (data) => {
        this.setState({
          loading: true
        })
      },
      success: (data) => {
        this.setState({
          ctGovTrials: data.trials,
          sector_search_term_id: value,
          loading: false,
          query: ''
        })
      }
    })
  }

  handleSearchClick(e) {
    $.ajax({
      type: "GET",
      url: "/admin/ct_gov_trials/search",
      data: {
        query: this.state.query
      },
      dataType: 'json',
      beforeSend: (data) => {
        this.setState({
          loading: true
        })
      },
      success: (data) => {
        this.setState({
          ctGovTrials: data.trials,
          loading: false,
          sector_search_term_id: ''
        })
      }
    })
  }

  render() {
    return(
      <Fragment>
        <Paper fullHeight>
          <Box height="100%">
            <Box mb={3}>
              <Flex alignItems='center'>
                <Box width={1/4} mr={3}>
                  <Select
                    label='Sector Search Terms'
                    name='sector_search_term_id'
                    value={this.state.sector_search_term_id}
                    entities={this.props.sector_search_terms}
                    handleChange={this.handleSectorChange}
                  />
                </Box>

                <Box width={1/2}>
                  <Flex alignItems='center'>
                    <Box mr={2} width={3/4}>
                      <TextInput
                        name='search_query'
                        label='Search'
                        placeholder='Search'
                        value={this.state.query}
                        onChange={this.handleQueryChange}
                        className='w-100'
                        margin='dense'
                      />
                    </Box>

                    <Box>
                      <RaisedButton handleClick={this.handleSearchClick}>
                        Search
                      </RaisedButton>
                    </Box>
                  </Flex>
                </Box>
              </Flex>
            </Box>

            <Box overflow="auto" height="80%">
              <Table size='small' stickyHeader={true}>
                <TableHead>
                  <TableRow>
                    <TableCell>NCT ID</TableCell>
                    <TableCell>Trial</TableCell>
                    <TableCell>Sponsor</TableCell>
                    <TableCell>Interventions</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <Fragment>
                    {this.state.loading ?
                      <TableRow hover={false}>
                        <TableCell noBorder colSpan={5}>
                          <LinearProgress />
                        </TableCell>
                      </TableRow>
                    : ""}
                    {this.state.ctGovTrials ?
                      this.state.ctGovTrials.map((ctGovTrial) =>
                        <TableRow>
                          <TableCell>
                            <Link href={`/admin/ct_gov_trials/${ctGovTrial.id}/new_trial`} target="_blank">
                              {ctGovTrial.nct_id}
                            </Link>
                          </TableCell>
                          <TableCell>
                            <Link href={`/admin/ct_gov_trials/${ctGovTrial.id}/new_trial`} target="_blank">
                              {ctGovTrial.acronym || ctGovTrial.brief_title}
                            </Link>
                          </TableCell>
                          <TableCell>
                            {ctGovTrial.sponsor}
                          </TableCell>
                          <TableCell>
                            {ctGovTrial.nct_interventions ? ctGovTrial.nct_interventions.map((intervention) =>
                              <div key={intervention.id}>
                                {intervention.name}
                              </div>
                            ) : ''}
                          </TableCell>
                          <TableCell></TableCell>
                        </TableRow>
                      )
                    : ""}
                  </Fragment>
                </TableBody>
              </Table>
            </Box>
          </Box>
        </Paper>
      </Fragment>
    )
  }
}


export default DbSearch
