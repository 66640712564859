import React from "react"
import { TableRow, TableCell } from "../tables"
import Link from "../Link"

export default function StudyRow(props) {
  return (
    <TableRow>
      <TableCell>
        <Link href={`/sectors/${props.sector.abbr}/trials/${props.trialData.id}`}>
          {props.trialData.short_name}
        </Link>
      </TableCell>
      <TableCell>
        {props.trialData.clinical_type}
      </TableCell>
      <TableCell>
        {props.trialData.company_name}
      </TableCell>
      <TableCell>
        {humanDateFormat(props.trialData.updated_at)}
      </TableCell>
    </TableRow>
  )
}
