import React, { Component, Fragment } from "react"
import PropTypes from "prop-types"
import Select from "../Select"
import Flex from "../Flex"
import Box from "../Box"
import Chip from '@material-ui/core/Chip';
import IconButton from '../IconButton';
import SearchIcon from '@material-ui/icons/Search'
import TextInput from "../TextInput"
import InputAdornment from '@material-ui/core/InputAdornment';
import { RaisedButton } from "../buttons"
import CloseIcon from '@material-ui/icons/Close';
import Grid from '@material-ui/core/Grid';
import Filter from "./Filter"

export default function Filters(props) {

  let xsSize = props.forSearch ? 3 : 4

  const selectedOptions = props.filterOptions.products.filter(entity => props.filterValues.product_ids.includes(entity.value) ? entity : null)

  return(

    <Grid container spacing={2} alignItems='center'>
      <Grid item xs={xsSize} lg>
        <Box>
          <Filter
            // key={`sponsors-${props.filterValues.sponsor_ids}`}
            label='Sponsor'
            name='sponsor_ids'
            value={props.filterValues.sponsor_ids}
            entities={props.filterOptions.companies}
            handleChange={props.handleFilterChange}
          />
        </Box>
      </Grid>

      <Grid item xs={xsSize} lg>
        <Box>
          <Filter
            label='Products'
            name='product_ids'
            value={props.filterValues.product_ids}
            entities={props.filterOptions.products}
            handleChange={props.handleFilterChange}
          />
        </Box>
      </Grid>

      {props.sector.monitor_product ?
        ''
      :
        <Fragment>
          <Grid item xs={xsSize} lg>
            <Box>
              <Filter
                // key={props.filterValues.target_market_ids}
                label='Target Market'
                name='target_market_ids'
                value={props.filterValues.target_market_ids}
                entities={props.filterOptions.targetMarkets}
                handleChange={props.handleFilterChange}
              />
            </Box>
          </Grid>

          {props.sector.abbr == "Aortic" ? '' : <Grid item xs={xsSize} lg>
            <Box>
              <Filter
                // key={props.filterValues.therapy_type_ids}
                label='Therapy'
                name='therapy_type_ids'
                value={props.filterValues.therapy_type_ids}
                entities={props.filterOptions.therapyTypes}
                handleChange={props.handleFilterChange}
              />
            </Box>
          </Grid>}

          {props.sector.id == 1 || props.sector.id == 2 ?
            <Grid item xs={xsSize} lg>
              <Box>
                <Filter
                  // key={props.filterValues.target_site_ids}
                  label='Target Site'
                  name='target_site_ids'
                  value={props.filterValues.target_site_ids}
                  entities={props.filterOptions.targetSites}
                  handleChange={props.handleFilterChange}
                />
              </Box>
            </Grid>
          : ""}
        </Fragment>
      }

      <Grid item xs={xsSize} lg>
        <Box>
          <Filter
            // key={props.filterValues.clinical_type_ids}
            label='Type'
            name='clinical_type_ids'
            value={props.filterValues.clinical_type_ids}
            entities={props.filterOptions.clinicalTypes}
            handleChange={props.handleFilterChange}
          />
        </Box>
      </Grid>

      {/* <Box width="12%">
        <Select
          multiple
          label='Enrollment'
          name='enrollment_numbers'
          value={props.filterValues.enrollment_numbers}
          entities={props.filterOptions.enrollmentOptions}
          handleChange={props.handleFilterChange}
          includeBlank={true}
          renderValue={selected => (
            <Flex flexWrap='wrap'>
              {`${selectedEnrollmentNumbers.length} chosen`}
            </Flex>
          )}
        />
      </Box> */}

      <Grid item xs={xsSize} lg>
        <Box>
          <Filter
            label='Status'
            name='status_ids'
            value={props.filterValues.status_ids}
            entities={props.filterOptions.statuses}
            handleChange={props.handleFilterChange}
          />
        </Box>
      </Grid>

      {props.forSearch ?
        <Grid item xs={6} lg>
          <Box>
            <form onSubmit={props.handleSearchClick}>
              <TextInput
                name='query'
                placeholder='Search'
                size='small'
                value={props.filterValues.query ? props.filterValues.query : ''}
                onChange={props.handleChange}
                className='w-100'
                InputProps={{
                  startAdornment: <InputAdornment position="start"><SearchIcon /></InputAdornment>,
                }}
              />
            </form>
          </Box>
        </Grid>
      : ''}
    </Grid>
  )
}

Filters.defaultProps = {
  forSearch: true
}
