import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import MuiAppBar from '@material-ui/core/AppBar';
import Link from "./Link"
import CssBaseline from '@material-ui/core/CssBaseline';
import IconButton from './IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import FavoriteIcon from '@material-ui/icons/Favorite';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import clsx from 'clsx';
import ListIcon from '@material-ui/icons/List';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import SearchIcon from '@material-ui/icons/Search'
import TabElements from "./TabElements"
import Flex from "./Flex"
import Box from "./Box"
import InputAdornment from '@material-ui/core/InputAdornment';
import ListSubheader from '@material-ui/core/ListSubheader';
import TextInput from "./TextInput"
import CloseIcon from "@material-ui/icons/Close"
import Hidden from '@material-ui/core/Hidden';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
import Select from "./Select"
import ListItemText from "./lists/ListItemText"
import { Button } from "./buttons"
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Popover from '@material-ui/core/Popover';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import SectionHeader from './SectionHeader';
import useAppBarHeight from "./useAppBarHeight"


const drawerWidth = 180;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    minHeight: '128px',
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    color: 'rgba(0, 0, 0, 0.87)',
    backgroundColor: '#fff'
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    [theme.breakpoints.down('md')]: {
      width: `calc(100% - ${0}px)`,
      marginLeft: 0
    },
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  title: {
    flexGrow: 0.5,
  },
  logo: {
    flexGrow: 0.4,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    justifyContent: 'flex-end',
    minHeight: '51px',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  offset: theme.mixins.toolbar,
}));

export default function AppBar(props) {

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const classes = useStyles();

  const colors = { 'Trials': 'bg-washed-red', 'Products': 'bg-washed-green', 'Companies': 'bg-gray-blue', 'People': 'bg-yellow', 'Presentations': 'bg-moderate-blue', 'Meetings': 'bg-moderate-blue', 'Abstracts': 'bg-moderate-blue', 'Publications': 'bg-cyan', Patents: 'bg-moderate-blue', 'Alerts': 'bg-gray-cyan', 'Sources': 'bg-washed-red', 'Home': 'bg-orange', 'Search': 'bg-orange', 'Settings': 'bg-gray', 'Users': 'bg-black', 'User Companies': 'bg-black', 'Sectors': 'bg-black', 'News': 'bg-mmi-blue' }

  const productName = props.sector && props.sector.monitor_product ? 'MONITOR' : 'PIPELINE'

  return (
    <Fragment>

      <MuiAppBar
        position="fixed"
        id="app-bar"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: props.showSidebar,
        })}
      >
        <Toolbar variant='dense' component={Box} px={2} disableGutters minHeight='51.43px'>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={props.handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, props.showSidebar && classes.hide)}
          >
            <MenuIcon />
          </IconButton>

          <Box className={props.showSearchInput ? '' : classes.logo}>
            <Link href={`/sectors/${props.sector.abbr}/dashboard`} className='f4'>
              <span className='orange'>{props.market.abbr}</span>
              <span className='black'>{productName}</span>
            </Link>
          </Box>

          <Box flexGrow={0.6}>
            <Box>
              <form onSubmit={props.handleSearchSubmit} acceptCharset="UTF-8">
                <TextInput
                  name='query'
                  placeholder={`Search ${props.market.abbr}${productName}`}
                  size='small'
                  value={props.search}
                  onChange={props.handleSearchChange}
                  className='w-50'
                  InputProps={{
                    startAdornment: <InputAdornment position="start"><SearchIcon fontSize='small' /></InputAdornment>
                  }}
                />
              </form>
            </Box>
          </Box>

          {props.forUsers && props.sectors ? <Box ml={3}>
            <Select
              name='sector_id'
              value={props.sector.id}
            >
                {props.sectors.map((sector) =>
                  <MenuItem key={sector.value} disabled={props.userSectors.map(userSector => userSector.id).includes(sector.value) ? false : true} value={sector.value} component="a" href={`/sectors/${sector.name}/dashboard`} className='f5'>
                    <ListItemText primary={sector.name} />
                  </MenuItem>
                )}
              </Select>
          </Box> : ''}
        </Toolbar>

        <SectionHeader 
          {...props}
        />
      </MuiAppBar>

      <Toolbar />

    </Fragment>
  );
}
