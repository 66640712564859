import React from "react"
import { makeStyles } from '@material-ui/core/styles';
import ListItemLink from './ListItemLink';

const useStyles = makeStyles(theme => ({
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

export default function NestedListItemLink(props) {
  const classes = useStyles();
  return (
    <ListItemLink className={classes.nested} {...props} />
  )
}
