import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Paper from "../../Paper"
import { List, ListItem, ListItemText } from "../../lists"
import IconButton from "../../IconButton"
import ContentDisplay from "./ContentDisplay"
import Snackbar from "../../Snackbar"
import Dialog from "../../Dialog"
import Typography from "@material-ui/core/Typography"
import Flex from "../../Flex"
import Box from "../../Box"
import { Fab, Button } from "../../buttons"

export default class Index extends Component {
  constructor(props) {
    super(props)
    this.handleDeleteClick = this.handleDeleteClick.bind(this)
    this.handleUndoClick = this.handleUndoClick.bind(this)
    this.state = { profileFields: this.props.profile_fields, showSnackbar: false, snackbarText: '', justDeletedId: null }
  }

  handleUndoClick(e) {
    if (this.state.justDeletedId) {
      $.ajax({
        type: 'PUT',
        url: `/admin/companies/${this.props.company.id}/profile_fields/${this.state.justDeletedId}`,
        data: {
          company_profile_field: {
            deleted: null, active: true, deleted_by: null
          }
        },
        dataType: 'json',
        success: (data) => {
          this.setState({
            showDialog: false,
            profileFields: data.profile_fields,
            showSnackbar: true,
            snackbarText: 'Profile field was deleted',
            justDeletedId: null
          })
        },
        error: (data) => {
          this.setState({
            showSnackbar: true,
            snackbarText: data.responseText
          })
        },
        completed: (data) => {
          setTimeout(() => {
            this.setState({
              showSnackbar: false,
              snackbarText: ''
            })
          }, 4000)
        }
      })
    }
  }

  handleDeleteClick(profileField) {
    $.ajax({
      type: 'PUT',
      url: `/admin/companies/${this.props.company.id}/profile_fields/${profileField.id}`,
      data: {
        company_profile_field: {
          deleted: true, active: false, deleted_by: this.props.current_user.id
        }
      },
      dataType: 'json',
      success: (data) => {
        this.setState({
          showDialog: false,
          profileFields: data.profile_fields,
          showSnackbar: true,
          snackbarText: 'Profile field was deleted',
          justDeletedId: profileField.id
        })
      },
      error: (data) => {
        this.setState({
          showSnackbar: true,
          snackbarText: data.responseText
        })
      },
      completed: (data) => {
        setTimeout(() => {
          this.setState({
            showSnackbar: false,
            snackbarText: ''
          })
        }, 5000)
      }
    })
  }

  render() {
    const newProfileFieldUrl = `/admin/companies/${this.props.company.id}/profile_fields/new`
    const profileFieldKeys = Object.keys(this.state.profileFields)
    return(
      <Fragment>

        <Paper>

          <Flex align='items-center'>
            <Box>
              <Typography variant="h6" className='mt0 mb2'>
                Profile fields
              </Typography>
            </Box>

            <Box className='ml-auto'>
              {/* <Fab icon='add' mini={true} href={newProfileFieldUrl} /> */}
              <Button href={newProfileFieldUrl}>New Profile Field</Button>
            </Box>
          </Flex>


          <List>
            {profileFieldKeys.length > 0 ?
              Object.keys(this.state.profileFields).map((profileFieldName) =>
                <ContentDisplay
                  key={profileFieldName}
                  company={this.props.company}
                  profileFieldName={profileFieldName}
                  handleDeleteClick={this.handleDeleteClick}
                  profileFieldHash={this.state.profileFields[profileFieldName][0]}
                />
              )
            :
              <ListItem>
                <ListItemText primary='No profile fields found' />
              </ListItem>
            }
          </List>

        </Paper>

        <Snackbar
          open={this.state.showSnackbar}
          handleUndoClick={this.handleUndoClick}
          handleClose={this.handleClose}
          message={this.state.snackbarText}
        />

      </Fragment>
    )
  }
}
