import React from "react"
import ResultItemContainer from "../search/ResultItemContainer"
import Typography from "@material-ui/core/Typography"

export default function SearchItem(props) {

  let url = props.result.url

  function renderSearchDetails(result) {
    return (
      <Typography variant="body2" color="textSecondary">
        {result.company_name}
      </Typography>
    );
  }

  return(
    <ResultItemContainer 
      searchItemType={props.result.record_type}
      searchTitle={props.result.title}
      date={props.result.date}
      searchDescription={props.result.filing_type}
      url={url}
      searchDetails={renderSearchDetails(props.result)}
    />
  )
}