import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Paper from "../../Paper"
import Box from "../../Box"
import { RaisedButton, Button } from "../../buttons"
import DoneIcon from '@material-ui/icons/Done';
import CheckIcon from '@material-ui/icons/Check';

export default class ControlPanel extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    return(
      <Fragment>

        <Paper>

          <Box width={1} mb={3}>
            <RaisedButton handleClick={this.props.handleAssociateClick}>
              {this.props.buttonText}
            </RaisedButton>
          </Box>

          {this.props.handleMentionedClick ? <Box width={1}>
            <RaisedButton color='default' handleClick={this.props.handleMentionedClick}>
              Mentioned
            </RaisedButton>
          </Box> : ''}

          {this.props.handleCompleteClick ?
            <Box width={1} mx='auto'>

              <Button variant={this.props.completed ? 'contained' : 'outlined'} color={this.props.completed ? 'primary' : 'default'} onClick={this.props.handleCompleteClick}>
                {this.props.associatorStatus && this.props.associatorStatus.status ? 'Completed' : 'Mark Complete'}
              </Button>

            </Box>
          : ''}

        </Paper>

      </Fragment>
    )
  }
}

ControlPanel.defaultProps = {
  buttonText: 'Associate'
}
