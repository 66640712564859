import React, { Component } from "react"
import PropTypes from "prop-types"
import ReactDOM from "react-dom"
import Select from "../Select"
import Flex from "../Flex"
import Box from "../Box"

export default class NetworkFilters extends Component {
  constructor(props) {
    super(props)
  }

  render () {
    return (
      <React.Fragment>
        <Flex alignItems='center' justifyContent='center'>

          <Box width="12%" mr={3}>
            <Select
              label='Entities'
              name='chart_entity_type'
              value={this.props.filterValues.chart_entity_type}
              entities={this.props.filterOptions.chartEntityTypes}
              handleChange={this.props.handleFilterChange}
            />
          </Box>

          <Box width="12%">
            <Select
              label='Time Period'
              name='time_period'
              value={this.props.filterValues.time_period}
              entities={this.props.filterOptions.dates}
              handleChange={this.props.handleFilterChange}
            />
          </Box>

        </Flex>
      </React.Fragment>
    );
  }
}
