import React, { Fragment } from "react"
import List from "../../lists/List";
import ProductListItem from "./ProductListItem"

export default function CheckboxList(props) {
  const entityProductIds = props.entityProducts.map((entityProduct) => entityProduct.id)
  const predictedProductIds = props.predictedProducts ? props.predictedProducts.map((predictedProduct) => predictedProduct.id) : []

  return (
    <Fragment>
      
      <List>
        {props.products.map((product) =>
          <ProductListItem 
            key={product.id}
            product={product}
            associated={entityProductIds.indexOf(product.id) == -1 ? false : true}
            checked={props.activeProducts.indexOf(product.id) == -1 ? false : true}
            predicted={props.predictedProducts ? predictedProductIds.indexOf(product.id) == -1 ? false : true : false}
            handleCheckboxSelect={props.handleCheckboxSelect}
            handleProductDeleteClick={props.handleProductDeleteClick}
          /> 
        )}
      </List>
      
    </Fragment>
  )
}
