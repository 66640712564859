import React, { Fragment, useState } from "react"
import Paper from "../Paper"
import Flex from "../Flex"
import Box from "../Box"
import Link from "../Link"
import IconButton from "../IconButton"
import BarChartIcon from '@material-ui/icons/BarChart';
import ShowChartIcon from '@material-ui/icons/ShowChart';
import ListIcon from '@material-ui/icons/List';
import AdverseEventFilters from "./AdverseEventFilters"
import AdverseEvents from "./AdverseEvents"
import AdverseEventTrends from "./AdverseEventTrends"
import Typography from "@material-ui/core/Typography"

export default function AdverseEventsContainer(props) {
  const [adverseEvents, setAdverseEvents] = useState(props.adverse_events)
  const [chartData, setChartData] = useState(props.chart_data)
  const [categories, setCategories] = useState(props.categories)
  const [eventTypeChartData, setEventTypeChartData] = useState(props.event_type_chart_data)
  const [chartReloadKey, setChartReloadKey] = useState(Math.random())
  const [eventTypes, setEventTypes] = useState(props.event_types)
  const [view, setView] = useState('chart')
  const [eventType, setEventType] = useState('')
  const [reportType, setReportType] = useState('')
  const [reporterCountryCodes, setReporterCountryCodes] = useState(props.reporter_country_codes)
  const [reporterCountryCode, setReporterCountryCode] = useState('')
  const [deviceProblems, setDeviceProblems] = useState(props.device_problems)
  const [deviceProblem, setDeviceProblem] = useState('')
  const [reportTypeChartData, setReportTypeChartData] = useState(props.report_type_chart_data)
  const [countryCodeChartData, setCountryCodeChartData] = useState(props.country_code_chart_data)

  function handleFilterChange(name, value) {
    let filters = { event_type: eventType, report_type: reportType, reporter_country_code: reporterCountryCode, device_problem: deviceProblem }

    filters[name] = value

    $.ajax({
      type: 'GET',
      data: filters,
      dataType: 'json',
      success: (data) => {
        setAdverseEvents(data.adverse_events)
        setChartData(data.chart_data)
        setCategories(data.categories)
        setEventTypeChartData(data.event_type_chart_data)
        setReportTypeChartData(data.report_type_chart_data)
        setChartReloadKey(Math.random())
        setEventTypes(data.event_types)
        setReporterCountryCodes(data.reporter_country_codes)
        setDeviceProblems(data.device_problems)
        setCountryCodeChartData(data.country_code_chart_data)
        setEventType(filters['event_type'])
        setReportType(filters['report_type'])
        setReporterCountryCode(filters['reporter_country_code'])
        setDeviceProblem(filters['device_problem'])
      }
    })
  }

  return (
    <Paper fullHeight={view == 'list' ? true : false} minFullHeight={view == 'chart' ? true : false}>
      <Box height="100%">

        <Fragment>
          <Flex alignItems='center' mb={3}>

            <AdverseEventFilters
              eventTypes={eventTypes}
              reportTypes={props.report_types}
              reporterCountryCodes={reporterCountryCodes}
              eventType={eventType}
              reportType={reportType}
              reporterCountryCode={reporterCountryCode}
              deviceProblem={deviceProblem}
              deviceProblems={deviceProblems}
              handleFilterChange={handleFilterChange}
              sector={props.sector}
            />

            <Box ml='auto'>
              <IconButton onClick={() => { setView('list') }} color={view == 'list' ? 'primary' : 'default'}>
                <ListIcon />
              </IconButton>

              <IconButton onClick={() => { setView('chart') }} color={view == 'chart' ? 'primary' : 'default'} tooltip='Chart'>
                <ShowChartIcon />
              </IconButton>
            </Box>
          </Flex>

          { adverseEvents.length > 0 ?
            view == 'list' ?
              <AdverseEvents
                adverseEvents={adverseEvents}
                eventTypes={eventTypes}
                reportTypes={props.report_types}
                reportType={reportType}
                eventType={eventType}
                handleFilterChange={handleFilterChange}
                comparison={props.comparison}
                sector={props.sector}
              />
            :
              <Box mt={3}>
                <AdverseEventTrends
                  adverseEvents={adverseEvents}
                  chartData={chartData}
                  eventTypeChartData={eventTypeChartData}
                  reportTypeChartData={reportTypeChartData}
                  countryCodeChartData={countryCodeChartData}
                  categories={categories}
                  chartReloadKey={chartReloadKey}
                  comparison={props.comparison}
                  dateApproved={props.date_approved}
                  chartDisplay={props.chart_display}
                  recallDates={props.date_of_recalls}
                />
              </Box>
            :
              <Flex justifyContent='center' height="50%">
                <Box alignSelf='center' maxWidth="50%">
                  <Box>
                    <Typography variant="body1">
                      No adverse events found for this product
                    </Typography>
                  </Box>
                </Box>
              </Flex>
            }
        </Fragment>
      </Box>
    </Paper>
  )
}
