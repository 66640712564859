import React, { Component } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Link from "../Link.jsx"
import { TableRow, TableCell } from "../tables"

class ListRecord extends Component {
  render () {
    return(
      <TableRow>

        <TableCell>
          <Link href={`/sectors/${this.props.sector.abbr}/meetings/${this.props.meeting.id}`}>
            {`${this.props.meeting.short_name} ${this.props.meeting.year}`}
          </Link>
        </TableCell>

        <TableCell>
          {`${humanDateRange(this.props.meeting.start_date, this.props.meeting.end_date)}`}
        </TableCell>

        <TableCell align='right'>{this.props.meeting.year}</TableCell>

        <TableCell align='right'>{this.props.meeting.n_pubs}</TableCell>
      </TableRow>
    )
  }
}

ListRecord.propTypes = {
  meeting: PropTypes.object
};

export default ListRecord
