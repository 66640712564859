import React, { Fragment } from "react"
import Paper from "../../Paper"
import List from "../../lists/List";
import CriteriaListItem from "./CriteriaListItem"

export default function CriteriaList(props) {
  return (
    <Fragment>
      
      <List>
        {props.criterias.map((criteria) =>
          <CriteriaListItem 
            key={criteria.id}
            criteria={criteria}
            checked={false}
            forSelection={true}
            handleCheckboxSelect={props.handleCheckboxSelect}
          /> 
        )}
      </List>
      
    </Fragment>
  )
}
