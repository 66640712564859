import React from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Paper from "../../Paper"
import Flex from "../../Flex"
import { Button } from "../../buttons"
import Box from "../../Box"
import Link from "../../Link"
import { Table, TableHead, TableRow, TableCell, TableBody, TableHeadSort } from "../../tables"

export default class UserCompanies extends React.Component {
  constructor(props) {
    super(props)
    this.handleRequestSort = this.handleRequestSort.bind(this)
    this.desc = this.desc.bind(this)
    this.stableSort = this.stableSort.bind(this)
    this.getSorting = this.getSorting.bind(this)
    this.state = { userCompanies: this.props.user_companies, order: 'asc', orderBy: 'expires_on' }
  }

  desc(a, b, orderBy) {
    let bOrderKey = b[orderBy]
    let aOrderKey = a[orderBy]
    if (bOrderKey < aOrderKey) {
      return -1;
    }
    if (bOrderKey > aOrderKey) {
      return 1;
    }
    return 0;
  }

  stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = cmp(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
  }

  getSorting(order, orderBy) {
    return order === 'desc' ? (a, b) => this.desc(a, b, orderBy) : (a, b) => -this.desc(a, b, orderBy);
  }

  handleRequestSort(event, property) {
    const isDesc = this.state.orderBy === property && this.state.order === 'desc';

    this.setState({
      order: isDesc ? 'asc' : 'desc',
      orderBy: property
    })
  };

  render() {
    let headers = [{ label: 'Name', name: 'name' }, { label: 'Expiration Date', name: 'expires_on' }, { label: '# of Users', name: 'user_count' }, { label: 'Billing Contact', name: 'billing_contact_email'} ]
    return(
      <React.Fragment>

        <Paper fullHeight>
          <Box height="100%">
            <Flex>
              <Box mb={3}>
                Filters HERE
              </Box>

              <Box ml='auto'>
                <Button href={`/admin/user_companies/new`}>
                  Add User Company
                </Button>
              </Box>
            </Flex>

            <Box overflow='auto' height="90%">
              <Table size='small' aria-label='user companies table' stickyHeader={true} id='user-companies-table'>

                <TableHeadSort
                  headers={headers}
                  order={this.state.order}
                  orderBy={this.state.orderBy}
                  onRequestSort={this.handleRequestSort}
                />

                <TableBody>
                  {this.stableSort(this.state.userCompanies, this.getSorting(this.state.order, this.state.orderBy)).map((userCompany) =>
                    <TableRow key={userCompany.id} hover={false}>
                      <TableCell>
                        <Link href={`/admin/user_companies/${userCompany.id}/edit`}>
                          <Box>
                            {userCompany.name}
                          </Box>
                        </Link>
                      </TableCell>
                      <TableCell>
                        {humanDateFormat(userCompany.expires_on)}
                      </TableCell>
                      <TableCell>
                        {userCompany.user_count}
                      </TableCell>
                      <TableCell>
                        {userCompany.billing_contact_email}
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </Box>
          </Box>
        </Paper>
      </React.Fragment>
    )
  }
}
