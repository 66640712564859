import React, { Component, Fragment } from "react"
import List from "../lists/List";
import NestedListItemLink from "../lists/NestedListItemLink"
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ExpandLess from '../ExpandLess';
import ExpandMore from '../ExpandMore';
import Collapse from '@material-ui/core/Collapse';

class NestedProductList extends Component {
  constructor(props) {
    super(props)
    this.state = { open: false }
  }

  UNSAFE_componentWillMount() {
    if (this.props.activeGroup == 'Products') {
      this.setState({
        open: true
      })
    }
  }

  render() {
    return(
      <Fragment>

        <ListItem button onClick={() => this.setState({open: !this.state.open})}>
          <ListItemText primary="Products" />
          {this.state.open ? <ExpandLess /> : <ExpandMore />}
        </ListItem>

        <Collapse in={this.state.open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>

            <NestedListItemLink href={`/admin/products/dashboard`}>
              <ListItemText primary='Dashboard' className={this.props.activeItem == 'Dashboard' && this.props.activeGroup == 'Products' ? 'orange' : ''} />
            </NestedListItemLink>

            <NestedListItemLink href={`/admin/products`}>
              <ListItemText primary='List' className={this.props.activeItem == 'List' && this.props.activeGroup == 'Products' ? 'orange' : ''} />
            </NestedListItemLink>

            <NestedListItemLink href={`/admin/products/new`}>
              <ListItemText primary='New Product' className={this.props.activeItem == 'New Product' && this.props.activeGroup == 'Products' ? 'orange' : ''} />
            </NestedListItemLink>

            <NestedListItemLink href={`/admin/device_approvals`}>
              <ListItemText primary='Device Approvals' className={this.props.activeItem == 'Device Approvals' && this.props.activeGroup == 'Products' ? 'orange' : ''} />
            </NestedListItemLink>

            <NestedListItemLink href={`/admin/device_recalls`}>
              <ListItemText primary='Device Recalls' className={this.props.activeItem == 'Device Recalls' && this.props.activeGroup == 'Products' ? 'orange' : ''} />
            </NestedListItemLink>

            <NestedListItemLink href={`/admin/scraped_products`}>
              <ListItemText primary='Scraped Products' className={this.props.activeItem == 'Scraped Products' && this.props.activeGroup == 'Products' ? 'orange' : ''} />
            </NestedListItemLink>

            <NestedListItemLink href={`/admin/regulatory_statuses`}>
              <ListItemText primary='Regulatory statuses' className={this.props.activeItem == 'Regulatory statuses' && this.props.activeGroup == 'Products' ? 'orange' : ''} />
            </NestedListItemLink>

            <NestedListItemLink href={`/admin/development_statuses`}>
              <ListItemText
                primary='Development statuses'
                className={this.props.activeItem == 'Development statuses' && this.props.activeGroup == 'Products' ? 'orange' : ''}
              />
            </NestedListItemLink>

            <NestedListItemLink href={`/admin/characteristic_groups`}>
              <ListItemText primary='Characteristic Groups' className={this.props.activeItem == 'Characteristic Groups' && this.props.activeGroup == 'Products' ? 'orange' : ''} />
            </NestedListItemLink>

            <NestedListItemLink href={`/admin/characteristic_values`}>
              <ListItemText primary='Characteristic Values' className={this.props.activeItem == 'Characteristic Values' && this.props.activeGroup == 'Products' ? 'orange' : ''} />
            </NestedListItemLink>

            <NestedListItemLink href={`/admin/target_sites`}>
              <ListItemText primary='Target Sites' className={this.props.activeItem == 'Target Sites' && this.props.activeGroup == 'Products' ? 'orange' : ''} />
            </NestedListItemLink>

            <NestedListItemLink href={`/admin/technology_groups`}>
              <ListItemText primary='Technology Groups' className={this.props.activeItem == 'Technology Groups' && this.props.activeGroup == 'Products' ? 'orange' : ''} />
            </NestedListItemLink>

            <NestedListItemLink href={`/admin/target_markets`}>
              <ListItemText primary='Target Markets' className={this.props.activeItem == 'Target Markets' && this.props.activeGroup == 'Products' ? 'orange' : ''} />
            </NestedListItemLink>

            <NestedListItemLink href={`/admin/therapy_types`}>
              <ListItemText primary='Therapy Types' className={this.props.activeItem == 'Therapy Types' && this.props.activeGroup == 'Products' ? 'orange' : ''} />
            </NestedListItemLink>

            <NestedListItemLink href={`/admin/product_profile_field_names`}>
              <ListItemText primary='Profile Field Names' className={this.props.activeItem == 'Profile Field Names' && this.props.activeGroup == 'Products' ? 'orange' : ''} />
            </NestedListItemLink>

            <NestedListItemLink href={`/admin/product_search_terms`}>
              <ListItemText primary='Product Search Terms' className={this.props.activeItem == 'Product Search Terms' && this.props.activeGroup == 'Products' ? 'orange' : ''} />
            </NestedListItemLink>

          </List>
        </Collapse>

      </Fragment>
    )
  }
}

export default NestedProductList
