import React, { Component } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Link from "../../Link.jsx"
import { TableRow, TableCell } from "../../tables"
import EditButton from "../EditButton"

class ListRecord extends Component {
  constructor(props) {
    super(props)
  }

  render () {
    const companyUrl = `/admin/companies/${this.props.company.id}/edit`
    return(
      <TableRow>
        <TableCell>
          <Link href={companyUrl}>
          {this.props.company.full_name}
        </Link>
        </TableCell>
        <TableCell>{this.props.company.n_products}</TableCell>
        <TableCell>{dateFormat(this.props.company.created_at)}</TableCell>
        <TableCell>
          {this.props.company.user ? displayInitials(this.props.company.user.first_name, this.props.company.user.last_name) : ""}
        </TableCell>
        <TableCell>
          <EditButton component="a" href={companyUrl} />
        </TableCell>
      </TableRow>
    )
  }
}

ListRecord.propTypes = {
  company: PropTypes.object
};

export default ListRecord
