import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import { Table, TableHead, TableRow, TableCell, TableBody, TableHeadSort } from "../../tables"
import DeleteButton from "../DeleteButton"
import Switch from "../../Switch"

export default class KClearanceRow extends Component {
  constructor(props) {
    super(props)
    this.handleProductSwitch = this.handleProductSwitch.bind(this)
  }

  handleProductSwitch(e) {
    this.handleProductSwitch(this.props.kClearance)
  }

  render() {

    let product = this.props.products.filter(product => product.k_number == this.props.kClearance.k_number)

    console.log(product)

    return(
      <TableRow>
        <TableCell className='w-15'>{this.props.kClearance.k_number}</TableCell>
        <TableCell>{this.props.kClearance.applicant}</TableCell>
        <TableCell>{this.props.kClearance.device_name}</TableCell>
        <TableCell>
          <Switch
            edge="end"
            checked={false}
            onChange={this.handleProductSwitch}
          />
        </TableCell>
        {/* <TableCell>
          <DeleteButton />
        </TableCell> */}
      </TableRow>
    )
  }
}
