import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import { Table, TableBody, TableHeadSort } from "../tables"
import DataCompareRow from "../trials/DataCompareRow"
import { RaisedButton } from "../buttons"
import Box from "../Box"
import { CSVLink, CSVDownload } from "react-csv";
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import IconButton from "../IconButton"

export default class DataView extends Component {
  constructor(props) {
    super(props)
    this.handleRequestSort = this.handleRequestSort.bind(this)
    this.desc = this.desc.bind(this)
    this.stableSort = this.stableSort.bind(this)
    this.getSorting = this.getSorting.bind(this)
    this.handleCsvClick = this.handleCsvClick.bind(this)
    this.state = { order: 'desc', orderBy: 'completion_date' }
  }

  handleCsvClick(event, done) {
    $.get(`/sectors/${this.props.sector.abbr}/log_csv_download?for=trials_compare`).then(() => {
      done(); // REQUIRED to invoke the logic of component
    });
  }

  desc(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = cmp(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
  }

  getSorting(order, orderBy) {
    return order === 'desc' ? (a, b) => this.desc(a, b, orderBy) : (a, b) => -this.desc(a, b, orderBy);
  }

  handleRequestSort(event, property) {
    const isDesc = this.state.orderBy === property && this.state.order === 'desc';

    this.setState({
      order: isDesc ? 'asc' : 'desc',
      orderBy: property
    })
  };

  render() {
    let headers = [{label: <RaisedButton onClick={this.props.handleAddEntityClick}>Add Trial</RaisedButton>, name: '', noSort: true }, { name: 'type', label: 'Type' }, { name: 'status', label: 'Status' }, { name: 'first_received_date', label: 'First received date' }, , { name: 'start_date', label: 'Start date' }, { name: 'primary_completion_date', label: 'Primary completion date' }, { name: 'completion_date', label: 'Completion date' }, { name: 'duration', label: 'Duration' }]

    let csvHeaders = [{ key: 'trial', label: 'Trial' }, { key: 'type', label: "Type" }, { key: 'status', label: 'Status' }, { key: 'first_received_date', label: 'First received date' }, { key: 'start_date', label: 'Start date' }, { key: 'primary_completion_date', label: 'Primary completion date' }, { key: 'completion_date', label: 'Completion date' }, { key: 'duration', label: 'Duration' }]

    let csvData = []
    this.stableSort(this.props.entities, this.getSorting(this.state.order, this.state.orderBy)).map((entity) => {
      csvData.push({ trial: entity.acronym || entity.short_name, type: entity.grouped_types, status: entity.status, first_received_date: dateFormat(entity.first_received_date), start_date: entity.start_date ? dateFormat(entity.start_date) : "N/A", primary_completion_date: entity.primary_completion_date ? dateFormat(entity.primary_completion_date) : "N/A", completion_date: entity.completion_date ? `${dateFormat(entity.completion_date)} ${entity.completion_date_type ? entity.completion_date_type : ''}` : "N/A", duration: `${diffDays(entity.start_date, entity.completion_date)} days${entity.completion_date ? "" : " to date"}` })
    })

    return(
      <Box overflow='auto' height="80%">

        <Box textAlign='right'>
          <CSVLink
            data={csvData}
            headers={csvHeaders}
            filename={`market-monitors-trial-comparison.csv`}
            asyncOnClick={true}
            onClick={this.handleCsvClick}
          >
            <IconButton tooltip='Download CSV file' size='small'>
              <CloudDownloadIcon style={{height: '22px', width: '22px'}} />
            </IconButton>
          </CSVLink>
        </Box>

        <Table aria-label='compare table' stickyHeader={true}>

          <TableHeadSort
            headers={headers}
            borderRight={true}
            order={this.state.order}
            orderBy={this.state.orderBy}
            onRequestSort={this.handleRequestSort}
          />

          <TableBody>
            {this.props.entities.length > 0 ?
              this.stableSort(this.props.entities, this.getSorting(this.state.order, this.state.orderBy)).map((entity) =>
                <DataCompareRow
                  key={entity.id}
                  entity={entity}
                  sector={this.props.sector}
                  activeEntity={this.props.activeEntity}
                  handleEntityRemovalClick={this.props.handleEntityRemovalClick}
                />
              )
            : ''}
          </TableBody>

        </Table>

      </Box>
    )
  }
}
