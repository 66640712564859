import React from 'react'
import { Table, TableBody, TableCell, TableRow, TableHead } from '../../../tables'
import Row from './Row'

export default function PmaTable(props) {
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell />
          <TableCell>PMA</TableCell>
          <TableCell>Trade Name</TableCell>
          <TableCell className="measure-wide">AO Statement</TableCell>
          <TableCell>Sectors</TableCell>
          <TableCell>Supplement Reason</TableCell>
          <TableCell />
        </TableRow>
      </TableHead>

      <TableBody>
        {Object.keys(props.pmaApprovals).map((pmaApprovalId) =>
          <Row 
            key={pmaApprovalId}
            pmaApprovals={props.pmaApprovals[pmaApprovalId]}
            checkedFdaPmaApprovals={props.checkedFdaPmaApprovals}
            handleDeleteClick={props.handleDeleteClick}
            handleSectorDelete={props.handleSectorDelete}
            handleFdaPmaApprovalCheckboxClick={props.handleFdaPmaApprovalCheckboxClick}
          />
        )}
      </TableBody>
    </Table>
  )
}