import React, { Fragment, Component } from "react"
import Paper from "../../Paper"
import { List, ListItem } from "../../lists";
import ProductListItem from "./ProductListItem"
import TrialListItem from "./TrialListItem"
import ListSubheader from '@material-ui/core/ListSubheader';
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction"
import IconButton from "../../IconButton"
import InfoIcon from '@material-ui/icons/Info';
import Tabs from "../../tabs/Tabs"
import Tab from "../../tabs/Tab"
import TabPanel from "../../tabs/TabPanel"
import Flex from "../../Flex"
import Box from "../../Box"

export default class SuggestedAssociationsList extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    const abstractProductIds = this.props.abstractProducts.map((abstractProduct) => abstractProduct.product_id)
    const abstractTrialIds = this.props.abstractTrials.map((abstractTrial) => abstractTrial.trial_id)

    const productIdArray = [...abstractProductIds, ...this.props.activeProducts]
    const trialIdArray = [...abstractTrialIds, ...this.props.activeTrials]

    // const suggestedTrials = props.suggestedAssociations.filter((suggestedAssociation) => suggestedAssociation.trial ? suggestedAssociation : null)
    // const suggestedProducts = props.suggestedAssociations.filter((suggestedAssociation) => suggestedAssociation.product ? suggestedAssociation : null)
    return (
      <Fragment>
        <Box>
          <Tabs centered value={this.props.predictedIndex} onChange={this.props.handlePredictedIndexUpdate} className='bb b--black-10' aria-label="entities tabs">
            <Tab label='Products' />
            <Tab label='Trials' />
          </Tabs>
        </Box>

          <TabPanel value={this.props.predictedIndex} index={0}>
            <List>
              {this.props.predictedProducts.length > 0 ?
                <Fragment>
                  {/* <ListSubheader disableSticky>Products</ListSubheader> */}
                  {this.props.predictedProducts.map((predictedProduct) =>
                    <ProductListItem
                      key={predictedProduct.id}
                      product={predictedProduct.product}
                      associated={abstractProductIds.indexOf(predictedProduct.product.id) == -1 ? false : true}
                      checked={productIdArray.indexOf(predictedProduct.product.id) == -1 ? false : true}
                      hits={predictedProduct.hits}
                      handleCheckboxSelect={this.props.handleCheckboxSelect}
                    />
                  )}
                </Fragment>
              : ''}
            </List>
          </TabPanel>


          <TabPanel value={this.props.predictedIndex} index={1}>
            <List>
              {this.props.predictedIndex == 1 && this.props.predictedTrials.length > 0 ?
                <Fragment>
                  <ListItem disableGutters={true}>
                    {/* <ListSubheader disableSticky>Trials</ListSubheader> */}
                    {/* <ListItemSecondaryAction>
                      <IconButton onClick={handleInfoClick} tooltip='info' edge="end" aria-label="info" size='small' target="_blank">
                        <InfoIcon fontSize='small' />
                      </IconButton>
                    </ListItemSecondaryAction> */}
                  </ListItem>
                  {this.props.predictedTrials.map((trial) =>
                    <TrialListItem
                      key={trial.trial_id}
                      trial={trial}
                      associated={abstractTrialIds.indexOf(trial.trial_id) == -1 ? false : true}
                      checked={trialIdArray.indexOf(trial.trial_id) == -1 ? false : true}
                      hits={trial.abstract_predicted_trial ? trial.abstract_predicted_trial.hits : ''}
                      handleCheckboxSelect={this.props.handleCheckboxSelect}
                    />
                  )}
                </Fragment>
              : ''}


            </List>
          </TabPanel>

      </Fragment>
    )
  }
}
