import React from 'react'
import { Table, TableBody, TableCell, TableRow, TableHead } from '../../../tables'
import ApprovalRow from './ApprovalRow'

export default function ApprovalsTable(props) {
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>ID</TableCell>
          <TableCell>Country</TableCell>
          <TableCell>Product</TableCell>
          <TableCell className="measure-wide">Description</TableCell>
          <TableCell>Sectors</TableCell>
          <TableCell>Manufacturer</TableCell>
          <TableCell>Date</TableCell>
          {/* <TableCell>Reviewed</TableCell> */}
          <TableCell />
        </TableRow>
      </TableHead>

      <TableBody>
        {props.approvals.map((approval) =>
          <ApprovalRow 
            key={approval.id}
            approval={approval}
            checkedApprovals={props.checkedApprovals}
            handleDeleteClick={props.handleDeleteClick}
            handleSectorDelete={props.handleSectorDelete}
            handleApprovalCheckboxClick={props.handleApprovalCheckboxClick}
            handleReviewedClick={props.handleReviewedClick}
          />
        )}
      </TableBody>
    </Table>
  )
}