import React, { Fragment } from "react"
import Typography from "@material-ui/core/Typography"

export default function CountDisplay(props) {
  return(
    <Typography variant="body2">
      {`${props.count} trials`}
    </Typography>
  )
}
