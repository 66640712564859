import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Paper from "../../Paper"
import StatusForm from "./StatusForm"
import Snackbar from "../../Snackbar"
import update from "immutability-helper"


export default class NewContainer extends Component {
  constructor(props) {
    super(props)
    this.handleSaveClick = this.handleSaveClick.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.state = { status: this.props.status, showSnackbar: false, snackbarText: '' }
  }
  
  componentDidMount() {
    let status = update(this.state.status, { 
      created_by: { $set: this.props.current_user.id }
    })
    this.setState({
      status: status
    })
  }
  
  handleChange(e) {
    const name = e.currentTarget.name
    const value = e.currentTarget.value
    let status = update(this.state.status, { 
      [name]: { $set: value } 
    })
    
    this.setState({
      status: status
    })
    
  }
  
  handleSaveClick(e) {

    let paramName = this.props.type == 'development_statuses' ? 'development_status' : 'regulatory_status'
    
    $.ajax({
      type: "POST",
      url: `/admin/${this.props.type}`,
      data: {
        [paramName]: this.state.status
      },
      dataType: 'json',
      success: (data) => {
        window.location.href = `/admin/${this.props.type}`
      },
      error: (data) => {
        this.setState({
          showSnackbar: true,
          snackbarText: `The status could not be created. Please try again.`
        })
      },
      complete: (data) => {
        setTimeout(() => {
          this.setState({
            showSnackbar: false,
            snackbarText: ''
          })
        }, 4000)
      }
    })
  }
  
  render() {
    const header = this.props.type == 'development_statuses' ? 'Development statuses' : 'Regulatory Statuses'
    const statusType = this.props.type == 'development_statuses' ? 'Development' : 'Regulatory'
    let tabs = [['List', `/admin/${this.props.type}`], [`New ${statusType} Status`, `/admin/${this.props.type}/new`]]
    return(
      <Fragment>
        
        <Paper>
          
          <StatusForm 
            status={this.state.status}
            handleChange={this.handleChange}
            handleSaveClick={this.handleSaveClick}
          />
          
          <Snackbar 
            open={this.state.showSnackbar}
            message={this.state.snackbarText} 
          />
          
        </Paper>
      </Fragment>
    )
  }
}
