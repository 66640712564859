import React from "react"
import PropTypes from "prop-types"
import { ListItem, ListItemText } from "../../lists"
import Checkbox from "../../Checkbox"
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction"


export default function EnpointListItem(props)  {

  function handleCheckboxSelect(e) {
    props.handleCheckboxSelect(props.endpoint, 'activeEndpoints')
  }

  return(
    <React.Fragment>
      <ListItem button key={props.endpoint.id} onClick={handleCheckboxSelect} role={undefined}>

        {props.forSelection ? <Checkbox
          checked={props.checked}
        /> : ''}

        <ListItemText primary={props.endpoint.hit} />

      </ListItem>
    </React.Fragment>
  )
}
