import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import { Table, TableRow, TableHead, TableCell, TableBody } from "../tables"
import StudyRow from "./StudyRow"
import Button from "../buttons/Button"
import ExpandLess from '../ExpandLess';
import ExpandMore from '../ExpandMore';


export default class GroupedTrials extends Component {
  constructor(props) {
    super(props)
    this.state = { open: true }
  }

  render() {
    return(
      <Fragment>

        <TableRow hover={false}>
          <TableCell colSpan={4} className='pv2'>
            <Button onClick={() => this.setState({ open: !this.state.open })} color='default' lowercase={true} className='f5'>
              {this.state.open ? <ExpandLess /> : <ExpandMore />}
              {this.props.header}
            </Button>
          </TableCell>
        </TableRow>

        {this.state.open ? this.props.trials.map((trialData) =>
          <StudyRow
            key={trialData.id}
            trialData={trialData}
            sector={this.props.sector}
          />
        ) : ''}
      </Fragment>
    )
  }
}
