import React from "react"
import { TableRow, TableCell } from "../../tables"
import Link from "../../Link"
import DeleteButton from "../DeleteButton"
import Box from "../../Box"
import { Button } from "../../buttons"

export default function RecallRow(props) {

  function handleDeleteClick(e) {
    props.handleDeleteClick(props.recall)
  }

  function handleAssociateClick(e) {
    props.handleAssociateClick(props.recall)
  }

  function handlePredictionDeleteClick(e) {
    props.handlePredictionDeleteClick(props.recall)
  }

  return(
    <TableRow>
      <TableCell>
        {props.recall.recall_id}
      </TableCell>
      <TableCell className='w-30'>
        <Link href={`https://www.accessdata.fda.gov/scripts/cdrh/cfdocs/cfres/res.cfm?id=${props.recall.recall_id}`} target="_blank">
          <Box>
            {props.recall.recall_product}
          </Box>
        </Link>
      </TableCell>
      <TableCell>
        {props.recall.manufacturer_reason_for_recall}
      </TableCell>
      <TableCell>
        {props.recall.pma_number}
      </TableCell>
      <TableCell>
        {props.recall.k_number}
      </TableCell>
      <TableCell>
        {props.recall.date_posted || props.recall.date_initiated_by_firm}
      </TableCell>
      <TableCell>
        {props.view == "associations" ?
          <DeleteButton onClick={handleDeleteClick} />
        :
          <Button variant="outlined" onClick={handleAssociateClick}>
            Associate
          </Button>
        }
      </TableCell>
      {props.view == "predictions" ?
        <TableCell>
          <DeleteButton onClick={handlePredictionDeleteClick} />
        </TableCell>
      : ""}
    </TableRow>
  )
}
