import React, { Fragment } from "react"
import { Table, TableRow, TableCell, TableHead, TableBody } from "../../../tables"
import LinearProgress from '@material-ui/core/LinearProgress';
import SearchResultRow from "./SearchResultRow"
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TablePaginationActions from "@material-ui/core/TablePagination"


export default function PatentResultsTable(props) {
  return(
    <Table size='small' stickyHeader={true}>
      <TableHead>
        <TableRow>
          <TableCell className="w-10">#</TableCell>
          <TableCell className="w-25">Patent</TableCell>
          <TableCell>Applicant</TableCell>
          <TableCell>Assignee</TableCell>
          <TableCell>Company</TableCell>
          <TableCell>Score</TableCell>
          {/* <TableCell></TableCell> */}
        </TableRow>
      </TableHead>
      <TableBody>
        <Fragment>
          {props.loading ?
            <TableRow hover={false}>
              <TableCell noBorder colSpan={5}>
                <LinearProgress />
              </TableCell>
            </TableRow>
          : ""}
          {props.patents ?
            props.patents.map((patent) =>
              <SearchResultRow
                patent={patent._source}
              />
            )
          : ""}
        </Fragment>
        <TableFooter>
          <TablePagination
            colSpan={4}
            count={props.patentsCount}
            rowsPerPage={100}
            page={props.page}
            SelectProps={{
              inputProps: { 'aria-label': 'rows per page' },
              native: true,
            }}
            onChangePage={props.handleChangePage}
            onChangeRowsPerPage={props.handleChangeRowsPerPage}
            ActionsComponent={TablePaginationActions}
          />
        </TableFooter>
      </TableBody>
    </Table>
  )
}
