import React, { Fragment } from "react"
import { Table, TableHead, TableRow, TableCell, TableBody } from "../../tables"
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TablePaginationActions from "../../tables/TablePaginationActions"
import GrantRow from "./GrantRow"

function GrantsTable(props) {

  return(
    <Fragment>
      <Table size="small" stickyHeader={true}>
        <TableHead>
          <TableRow>
            <TableCell>Firm</TableCell>
            <TableCell>Title</TableCell>
            <TableCell>Fiscal Year</TableCell>
            {/* <TableCell /> */}
          </TableRow>
        </TableHead>

        <TableBody>
          {props.nihGrants.map((nihGrant) =>
            <GrantRow 
              nihGrant={nihGrant._source ? nihGrant._source : nihGrant}
            />
          )}
        </TableBody>
        <TableFooter>
          <TablePagination
            colSpan={5}
            count={props.nihGrantsCount}
            rowsPerPage={100}
            page={props.page}
            SelectProps={{
              inputProps: { 'aria-label': 'rows per page' },
              native: true,
            }}
            onChangePage={props.handleChangePage}
            onChangeRowsPerPage={props.handleChangeRowsPerPage}
            ActionsComponent={TablePaginationActions}
          />
        </TableFooter>
      </Table>
    </Fragment>
  )
}

export default GrantsTable
