import React, { Component, Fragment } from "react"
import Paper from "../../Paper"
import update from "immutability-helper"
import Snackbar from "../../Snackbar"
import Form from "./Form"

export default class EditContainer extends Component {
  constructor(props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
    this.handleSaveClick = this.handleSaveClick.bind(this)
    this.handleDateChange = this.handleDateChange.bind(this)
    this.state = { pressRelease: this.props.press_release, showSnackbar: false, snackbarText: '' }
  }
  
  handleDateChange(date) {
    const pressRelease = update(this.state.pressRelease, {
      date: { $set: date }
    })
    
    this.setState({
      pressRelease: pressRelease
    })
  }
  
  handleChange(e) {
    const name = e.target.name
    const value = e.target.value
    const pressRelease = update(this.state.pressRelease, { [name]: { $set: value } })
    this.setState({
      pressRelease: pressRelease
    })
  }
  
  handleSaveClick(e) {
    $.ajax({
      type: 'PUT',
      url: `/admin/press_releases/${this.state.pressRelease.id}`,
      data: {
        press_release: this.state.pressRelease
      },
      error: (data) => {
        this.setState({
          showSnackbar: true,
          snackbarText: `The press release could not be updated. ${data.responseText}`
        })
      },
      complete: (data) => {
        setTimeout(() => {
          this.setState({
            showSnackbar: false,
            snackbarText: ''
          })
        }, 4000)
      }
    })
  }
  
  render() {
    return(
      <Fragment>
        
        <Paper>
          
          <Form 
            pressRelease={this.state.pressRelease}
            companies={this.props.companies}
            handleChange={this.handleChange}
            handleSaveClick={this.handleSaveClick}
            handleDateChange={this.handleDateChange}
            handleCompanyChange={this.handleCompanyChange}
          />
          
        </Paper>
        
        <Snackbar 
          open={this.state.showSnackbar}
          message={this.state.snackbarText} 
        />
      </Fragment>
    )
  }
}
