import React from 'react'
import Flex from './Flex'
import Typography from '@material-ui/core/Typography'

function ListingsCountDisplay(props) {
  return (
    <Flex justifyContent='right'>
      <Typography variant="body2">
        {props.display}
      </Typography>
    </Flex>
  )
}

export default ListingsCountDisplay