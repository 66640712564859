import React, { Component } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Link from "../../Link.jsx"
import { TableRow, TableCell } from "../../tables"
import EditButton from "../EditButton"

class ListRecord extends Component {
  constructor(props) {
    super(props)
  }

  render () {
    const editUrl = this.props.review ? (this.props.review == 'no_sector' ? `/admin/products/${this.props.product.id}/edit?active_index=5` : `/admin/products/${this.props.product.id}/search_terms`) : `/admin/products/${this.props.product.id}/edit`
    return(
      <TableRow>
        <TableCell>
          <Link href={`/sectors/${this.props.sectorObject.name}/products/${this.props.product.id}`}>
            {this.props.product.short_name}
          </Link>
        </TableCell>
        <TableCell>
          <Link href={`/admin/companies/${this.props.product.company_id}/edit`}>
            {this.props.product.company_name}
          </Link>
        </TableCell>
        <TableCell>{dateFormat(this.props.product.updated_at)}</TableCell>
        <TableCell align='right'>{this.props.product.n_trials}</TableCell>
        <TableCell align='right'>
          <EditButton component="a" href={editUrl} />
        </TableCell>
      </TableRow>
    )
  }
}

ListRecord.propTypes = {
  product: PropTypes.object
};

export default ListRecord
