import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Paper from "../../Paper"
import Typography from "@material-ui/core/Typography"
import { Table, TableHead, TableRow, TableCell, TableBody } from "../../tables"
import TrialRow from "./TrialRow"
import Snackbar from "../../Snackbar"
import update from "immutability-helper"

export default class Reviewer extends Component {
  constructor(props) {
    super(props)
    this.handleSwitchChange = this.handleSwitchChange.bind(this)
    this.state = { showSnackbar: false, snackbarText: '' }
  }

  handleSwitchChange(checked, ctGovTrial) {
    $.ajax({
      type: 'PUT',
      url: `/admin/ct_gov_trials/${ctGovTrial.id}`,
      data: {
        ct_gov_trial: {
          hide_trial: checked
        }
      },
      dataType: 'json',
      success: (data) => {
        this.setState({
          showSnackbar: true,
          snackbarText: `Trial has been marked as ${checked ? 'hidden' : 'unhidden'}`
        })
      },
      error: (data) => {
        this.setState({
          showSnackbar: true,
          snackbarText: 'The trial could not be hidden. Please try again.'
        })
      },
      complete: (data) => {
        setTimeout(() => {
          this.setState({
            showSnackbar: false,
            snackbarText: ''
          })
        }, 4000)
      }
    })
  }

  render() {
    return(
      <Fragment>

        <Paper>
          <Typography variant="h6">Trials to Review</Typography>

          <Table>
            <TableHead>
              <TableRow>
                <TableCell>NCT ID</TableCell>
                <TableCell>Trial</TableCell>
                <TableCell>Sponsor</TableCell>
                <TableCell>Collaborators</TableCell>
                <TableCell>Conditions</TableCell>
                <TableCell>Interventions</TableCell>
                <TableCell>Hide</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {this.props.ct_gov_trials.map((ctGovTrial) =>
                <TrialRow
                  key={ctGovTrial.id}
                  ctGovTrial={ctGovTrial}
                  handleSwitchChange={this.handleSwitchChange}
                />
              )}
            </TableBody>
          </Table>
        </Paper>

        <Snackbar
          open={this.state.showSnackbar}
          message={this.state.snackbarText}
        />

      </Fragment>
    )
  }
}
