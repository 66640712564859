import React, { Component } from "react"
import PropTypes from "prop-types"
import ReactDOM from "react-dom"
import Link from "../Link"
import { Table, TableRow, TableCell, TableHead, TableBody } from "../tables"
import GroupedManuscripts from "./GroupedManuscripts"

export default class ManuscriptsChartDataTable extends Component {
  constructor(props) {
    super(props)
  }
  
  render() {
    return (
      <React.Fragment>
        <Table size='small'>
          <TableHead>
            <TableRow hover={false}>
              <TableCell>Journal</TableCell>
              <TableCell>Publication</TableCell>
            </TableRow>
          </TableHead>
          
          <TableBody>
            {Object.keys(this.props.groupedManuscriptsData).map((year) =>
              <GroupedManuscripts 
                key={year}
                header={year}
                sector={this.props.sector}
                groupedManuscriptsData={this.props.groupedManuscriptsData[year]}
              />
            )}
          </TableBody>
        </Table>
      </React.Fragment>
    );
  }
}
