import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import { TableRow, TableCell } from "../../tables"
import Link from "../../Link"
import EditButton from "../EditButton"
import DeleteButton from "../DeleteButton"

export default class FdaDocumentRow extends Component {
  constructor(props) {
    super(props)
    this.handleDeleteClick = this.handleDeleteClick.bind(this)
  }
  
  handleDeleteClick(e) {
    this.props.handleDeleteClick(this.props.regulatoryAgencyDocument)
  }
  
  render() {
    const editUrl = `/admin/regulatory_agency_documents/${this.props.regulatoryAgencyDocument.id}/edit`
    return(
      <Fragment>
        <TableRow>
          <TableCell>
            <Link href={editUrl}>
              {this.props.regulatoryAgencyDocument.title}
            </Link>
          </TableCell>
          <TableCell>
            {humanDateFormat(this.props.regulatoryAgencyDocument.date)}
          </TableCell>
          <TableCell>
            {this.props.regulatoryAgencyDocument.creator ? displayInitials(this.props.regulatoryAgencyDocument.creator.first_name, this.props.regulatoryAgencyDocument.creator.last_name) : 'N/A'}
          </TableCell>
          <TableCell>
            <EditButton component="a" href={editUrl} />
          </TableCell>
          <TableCell>
            <DeleteButton onClick={this.handleDeleteClick} />
          </TableCell>
        </TableRow>
      </Fragment>
    )
  }
}
