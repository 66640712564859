import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Paper from "../../Paper"
import Flex from "../../Flex"
import Box from "../../Box"
import Select from "../../Select"
import { Button, RaisedButton, WarningButton } from "../../buttons"
import update from "immutability-helper"
import Snackbar from "../../Snackbar"
import CKEditor from 'ckeditor4-react';
import Typography from "@material-ui/core/Typography"
import Dialog from "../../Dialog"
import { Tabs, Tab } from "../../tabs"

export default class Emailer extends Component {
  constructor(props) {
    super(props)
    this.handleTextChange = this.handleTextChange.bind(this)
    this.handleEmailSendClick = this.handleEmailSendClick.bind(this)
    this.handleEmailPauseClick = this.handleEmailPauseClick.bind(this)
    this.handleTodayTestEmailClick = this.handleTodayTestEmailClick.bind(this)
    this.handleTomorrowTestEmailClick = this.handleTomorrowTestEmailClick.bind(this)
    this.handleDeveloperTestEmailClick = this.handleDeveloperTestEmailClick.bind(this)
    this.handleActiveIndexUpdate = this.handleActiveIndexUpdate.bind(this)
    this.handleAlertEmailQueueClick = this.handleAlertEmailQueueClick.bind(this)
    this.handleWeeklyAlertEmailQueueClick = this.handleWeeklyAlertEmailQueueClick.bind(this)
    this.handleWeeklyTestEmailClick = this.handleWeeklyTestEmailClick.bind(this)
    this.state = { alertMessage: '', showSnackbar: false, snackbarText: '', showConfirmDialog: false, activeIndex: 'test_email', alertEmailStatus: this.props.alert_email_status }
  }

  handleActiveIndexUpdate(e, newValue) {
    this.setState({
      activeIndex: newValue
    })
  }

  handleTextChange(evt) {
    let text = evt.editor.getData()

    this.setState({
      alertMessage: text
    })
  }

  handleWeeklyEmailPauseClick(e) {
    $.ajax({
      type: 'PUT',
      url: `/admin/alerts/pause_weekly_email`,
      data: {
        pause: true
      },
      dataType: 'json',
      success: (data) => {
        this.setState({
          showSnackbar: true,
          snackbarText: 'Weekly alert email has been paused',
          alertEmailStatus: data.alert_email_status
        })
      },
      error: (data) => {
        this.setState({
          showSnackbar: false,
          snackbarText: 'There was a problem pausing the alert. Please notify Stephen in slack'
        })
      },
      complete: (data) => {
        setTimeout(() => {
          this.setState({
            showSnackbar: false,
            snackbarText: ''
          })
        }, 4000)
      }
    })
  }

  handleEmailPauseClick(e) {
    $.ajax({
      type: 'PUT',
      url: `/admin/alerts/pause_email`,
      data: {
        pause: true
      },
      dataType: 'json',
      success: (data) => {
        this.setState({
          showSnackbar: true,
          snackbarText: 'Alert email has been paused',
          alertEmailStatus: data.alert_email_status
        })
      },
      error: (data) => {
        this.setState({
          showSnackbar: false,
          snackbarText: 'There was a problem pausing the alert. Please notify Stephen in slack'
        })
      },
      complete: (data) => {
        setTimeout(() => {
          this.setState({
            showSnackbar: false,
            snackbarText: ''
          })
        }, 4000)
      }
    })
  }

  handleDeveloperTestEmailClick(e) {
    $.ajax({
      type: 'GET',
      url: `/admin/alerts/developer_test_email_send`,
      data: {
        message: this.state.alertMessage
      },
      dataType: 'json',
      success: (data) => {
        this.setState({
          showSnackbar: true,
          snackbarText: 'Test Alert has been sent'
        })
      },
      error: (data) => {
        this.setState({
          showSnackbar: false,
          snackbarText: 'There was a problem sending the alert. Please notify Stephen in slack'
        })
      },
      complete: (data) => {
        setTimeout(() => {
          this.setState({
            showSnackbar: false,
            snackbarText: ''
          })
        }, 4000)
      }
    })
  }

  handleWeeklyAlertEmailQueueClick(e) {
    $.ajax({
      type: "PUT",
      url: `/admin/alerts/queue_weekly_email`,
      data: {
        message: this.state.alertMessage
      },
      dataType: 'json',
      success: (data) => {
        this.setState({
          showSnackbar: true,
          snackbarText: 'Weekly alerts have been queued. Expect delivery within 15 minutes.',
          alertEmailStatus: data.alert_email_status
        })
      },
      error: (data) => {
        this.setState({
          showSnackbar: false,
          snackbarText: 'There was an error. Please notify Stephen in #bug-reports'
        })
      },
      complete: (data) => {
        setTimeout(() => {
          this.setState({
            showSnackbar: false,
            snackbarText: ''
          })
        }, 4000)
      }
    })
  }

  handleAlertEmailQueueClick(e) {
    $.ajax({
      type: 'PUT',
      url: `/admin/alerts/queue_email`,
      data: {
        message: this.state.alertMessage
      },
      dataType: 'json',
      success: (data) => {
        this.setState({
          showSnackbar: true,
          snackbarText: 'Alerts has been queued. Expect delivery within 5 minutes.',
          alertEmailStatus: data.alert_email_status
        })
      },
      error: (data) => {
        this.setState({
          showSnackbar: false,
          snackbarText: 'There was an error. Please notify Stephen in #bug-reports'
        })
      },
      complete: (data) => {
        setTimeout(() => {
          this.setState({
            showSnackbar: false,
            snackbarText: ''
          })
        }, 4000)
      }
    })
  }

  handleEmailSendClick(e) {
    $.ajax({
      type: 'GET',
      url: `/admin/alerts/live_email`,
      data: {
        message: this.state.alertMessage
      },
      dataType: 'json',
      success: (data) => {
        this.setState({
          showSnackbar: true,
          snackbarText: 'Alert has been sent',
          showConfirmDialog: false
        })
      },
      error: (data) => {
        this.setState({
          showSnackbar: false,
          snackbarText: 'There was a problem sending the alert. Please notify Stephen in slack'
        })
      },
      complete: (data) => {
        setTimeout(() => {
          this.setState({
            showSnackbar: false,
            snackbarText: ''
          })
        }, 4000)
      }
    })
  }

  handleWeeklyTestEmailClick(e) {
    $.ajax({
      type: 'GET',
      url: `/admin/alerts/weekly_test_email_send`,
      data: {
        message: this.state.alertMessage
      },
      dataType: 'json',
      success: (data) => {
        this.setState({
          showSnackbar: true,
          snackbarText: 'Weekly test alert has been sent'
        })
      },
      error: (data) => {
        this.setState({
          showSnackbar: false,
          snackbarText: 'There was a problem sending the alert. Please notify Stephen in slack'
        })
      },
      complete: (data) => {
        setTimeout(() => {
          this.setState({
            showSnackbar: false,
            snackbarText: ''
          })
        }, 4000)
      }
    })
  }

  handleTodayTestEmailClick(e) {
    $.ajax({
      type: 'GET',
      url: `/admin/alerts/today_test_email_send`,
      data: {
        message: this.state.alertMessage
      },
      dataType: 'json',
      success: (data) => {
        this.setState({
          showSnackbar: true,
          snackbarText: 'Test alert has been sent'
        })
      },
      error: (data) => {
        this.setState({
          showSnackbar: false,
          snackbarText: 'There was a problem sending the alert. Please notify Stephen in slack'
        })
      },
      complete: (data) => {
        setTimeout(() => {
          this.setState({
            showSnackbar: false,
            snackbarText: ''
          })
        }, 4000)
      }
    })
  }

  handleTomorrowTestEmailClick(e) {
    $.ajax({
      type: 'GET',
      url: `/admin/alerts/tomorrow_test_email_send`,
      data: {
        message: this.state.alertMessage
      },
      dataType: 'json',
      success: (data) => {
        this.setState({
          showSnackbar: true,
          snackbarText: 'Test alert has been sent'
        })
      },
      error: (data) => {
        this.setState({
          showSnackbar: false,
          snackbarText: 'There was a problem sending the alert. Please notify Stephen in slack'
        })
      },
      complete: (data) => {
        setTimeout(() => {
          this.setState({
            showSnackbar: false,
            snackbarText: ''
          })
        }, 4000)
      }
    })
  }

  render() {
    return (
      <Fragment>

        {this.state.showConfirmDialog ?
          <Dialog
            openDialog={this.state.showConfirmDialog}
            dialogTitle='Send Alerts to Users?'
            withButtons
            handleAddClick={this.handleEmailSendClick}
            handleClose={() => { this.setState({ showConfirmDialog: false })}}
            confirmText='Confirm'
            dialogContent='By clicking confirm, you will be sending alerts to users.'
          />
        : ''}

        <Paper minFullHeight>
          <Box>
            <Typography variant="h6">
              Send alerts to users
            </Typography>
          </Box>


          {/* <Box my={4}>

            <Box width={1/2} mx='auto'>
              <Box mb={2}>
                Add message to alert email
              </Box>
              <CKEditor
                data={this.state.alertMessage}
                onChange={this.handleTextChange}
              />
            </Box>
          </Box> */}

          <Box mt={7}>

            <Tabs variant="fullWidth" value={this.state.activeIndex} onChange={this.handleActiveIndexUpdate} className='bb b--black-10' aria-label="nav tabs">
              <Tab label='Test' value='test_email' />
              <Tab label='Live' value='live_email' />
            </Tabs>

            { this.state.activeIndex == 'test_email' ?
              <Box mt={10}>
                <Flex alignItems='center' justifyContent='space-evenly'>
                  <Box>
                    <RaisedButton color='default' onClick={this.handleTodayTestEmailClick}>
                      Test Todays Alerts
                    </RaisedButton>
                  </Box>

                  {this.props.current_user.id == 1 ?
                    <Box>
                      <RaisedButton color='default' onClick={this.handleDeveloperTestEmailClick}>
                        Send Developer Test Alerts
                      </RaisedButton>
                    </Box>
                  : ''}

                  <Box>
                    <RaisedButton color='default' onClick={this.handleTomorrowTestEmailClick}>
                      Test Tomorrows Alerts
                    </RaisedButton>
                  </Box>

                  <Box>
                    <RaisedButton color='default' onClick={this.handleWeeklyTestEmailClick}>
                      Test Weekly Alerts
                    </RaisedButton>
                  </Box>
                </Flex>
              </Box>
            : ''}

            {this.state.activeIndex == 'live_email' ?
              <Box mt={5}>
                <Box textAlign='center' mb={9}>
                  <Typography variant="h6">
                    LIVE EMAIL
                  </Typography>
                </Box>

                <Flex alignItems='center' justifyContent='space-evenly'>
                  {this.state.alertEmailStatus.send_email ?
                    <Box width={1/4}>
                      <RaisedButton onClick={this.handleEmailPauseClick}>
                        Pause Alert Email
                      </RaisedButton>
                    </Box>
                  :
                    <Box width={1/4}>
                      <RaisedButton onClick={this.handleAlertEmailQueueClick}>
                        Queue Alert Email
                      </RaisedButton>
                    </Box>
                  }

                  {this.state.alertEmailStatus.send_weekly_email ?
                    <Box width={1/4}>
                      <RaisedButton onClick={this.handleWeeklyEmailPauseClick}>
                        Pause Weekly Alert Email
                      </RaisedButton>
                    </Box>
                  :
                    <Box width={1/4}>
                      <RaisedButton onClick={this.handleWeeklyAlertEmailQueueClick}>
                        Queue Weekly Alert Email
                      </RaisedButton>
                    </Box>
                  }
                </Flex>
              </Box>
            : ""}
          </Box>
        </Paper>

        <Snackbar
          open={this.state.showSnackbar}
          message={this.state.snackbarText}
        />
      </Fragment>
    )
  }
}
