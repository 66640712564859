import { Collapse, Typography } from '@material-ui/core';
import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons';
import React from 'react'
import Box from '../../Box';
import { Button } from '../../buttons';
import Flex from '../../Flex';
import IconButton from '../../IconButton'
import Link from '../../Link';
import { TableCell, TableRow } from '../../tables'

function ExpandableTrialRow(props) {
  const [open, setOpen] = React.useState(false);

  function handleAddTrialClick(e) {
    // props.handleAddTrialClick(props.trial)
  }

  return (
    <React.Fragment>
      <TableRow>
        <TableCell>
          <IconButton aria-label='expand row' size='small' onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown /> }
          </IconButton>
        </TableCell>
        <TableCell>
          <Link href={`/admin/ictrp_trials/${props.trial.id}`} target="_blank">
            {props.trial.regulatory_id}
          </Link>
        </TableCell>
        <TableCell>{props.trial.public_title}</TableCell>
        <TableCell>{props.trial.primary_sponsor}</TableCell>
        <TableCell>{props.trial.last_changed_date}</TableCell>
        <TableCell>{props.trial.recruitment_status}</TableCell>
        {/* <TableCell>Conditions</TableCell> */}
        <TableCell>Hide</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Flex>
              <Box>
                <Typography variant="h6">
                  Conditions
                </Typography>
              </Box>

              <Box>
                <Typography variant="h6">
                  Interventions
                </Typography>
              </Box>

              <Box>
                <Typography variant="h6">
                  Source Support
                </Typography>
              </Box>
            </Flex>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  )
}

export default ExpandableTrialRow