import React from 'react';
import Box from "./Box"

export default function DatePicker(props) {

  function handleChange(e) {
    props.handleChange(e.target.value)
  }

  return (
    <React.Fragment>
      <Box>
        <label>{props.label}</label>
      </Box>
      <input
        type="date"
        name={props.name}
        onChange={handleChange}
        value={props.value}
      />
    </React.Fragment>
  )
}
