import React, { Component } from "react"

export default class Hoverable extends Component {
  state = { hovered: false }

  render() {
    return (
      <div onMouseEnter={() => this.setState({ hovered: true })} onMouseLeave={() => this.setState({ hovered: false })}>
        {this.props.children(this.state.hovered)}
      </div>
    )
  }
}
