import React, { Fragment } from "react"
import { TableRow, TableCell } from "../../../tables"
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import MuiIconButton from '@material-ui/core/IconButton';
import Link from "../../../Link"
import Box from "../../../Box"
import Select from "../../../Select"


export default function ClassificationRow(props) {

  function handleSectorAssociation(name, value) {
    let classification = props.classificationArray.length ? props.classificationArray[0] : props.classificationArray

    props.handleSectorAssociation(classification.id, props.classificationArray, classification.patent_classification_sectors, value)
  }

  let selected = false

  let classification = props.classificationArray.length ? props.classificationArray[0] : props.classificationArray
  return(
    <TableRow>
      <TableCell>
        <Link href={`/admin/patents/db_search?patent_classification_definition_id=${classification.id}`} target="_blank">
          {classification.classification}
        </Link>
      </TableCell>
      <TableCell>
        {classification.definition}
      </TableCell>
      <TableCell>
        {classification.definition_text}
      </TableCell>
      <TableCell>
        {props.classificationArray.length ? props.classificationArray[1]._score : "N/A"}
      </TableCell>
      <TableCell>
        <Select
          label='Sector'
          name='sector_id'
          value={classification.patent_classification_sectors.length > 0 ? classification.patent_classification_sectors[0].sector_id : null}
          entities={props.sectors}
          handleChange={handleSectorAssociation}
        />
      </TableCell>
    </TableRow>
  )
}
