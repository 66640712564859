import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Paper from "../Paper"
import { Table, TableHead, TableRow, TableCell, TableBody } from "../tables"
import Map from "../charts/Map.jsx"
import Tabs from "../tabs/Tabs"
import Tab from "../tabs/Tab"
import Box from "../Box"
import SitesDataTable from "./SitesDataTable"

export default class Sites extends Component {
  constructor(props) {
    super(props)
    this.handleActiveIndexClick = this.handleActiveIndexClick.bind(this)
    this.state = { activeIndex: 0 }
  }

  handleActiveIndexClick(e, newValue) {
    this.setState({
      activeIndex: newValue
    })
  }

  render () {
    return(
      <Fragment>

        <Map data={this.props.data} />

        <Box width={1} textAlign='center'>
          <Box my={4}>
            <Tabs centered value={this.state.activeIndex} onChange={this.handleActiveIndexClick} aria-label="status tabs" className='bb b--black-10'>
              <Tab label='Data' />
            </Tabs>
          </Box>

          <SitesDataTable
            sites={this.props.sites}
          />

        </Box>

      </Fragment>
    );
  }
}

Sites.propTypes = {
  trial: PropTypes.object,
  sites: PropTypes.object
};
