import React, { Fragment } from "react"
import Flex from "../Flex"
import Box from "../Box"
import Paper from "../Paper"
import { List, ListItem, ListItemText } from "../lists"
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Switch from "../Switch"

export default function UserSectors(props) {

  return(
    <Fragment>
      <Box width={1/4} mt={3}>
        <List>
          {props.sectors.map((sector) =>
            <ListItem key={sector.id} button>
              <ListItemText
                primary={sector.abbr}
              />

              <ListItemSecondaryAction>
                <Switch
                  edge="end"
                  checked={props.userSectors.filter(userSector => userSector.sector_id == sector.id)[0] ? true : false}
                  handleChange={props.handleSwitchChange}
                />
              </ListItemSecondaryAction>

            </ListItem>
          )}
        </List>
      </Box>
    </Fragment>
  )
}
