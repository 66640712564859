import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Paper from "../../Paper"
import update from "immutability-helper"
import Select from "../../Select"
import TextInput from "../../TextInput"
import DatePicker from "../../DatePicker"
import SourceForm from "../SourceForm"
import Flex from "../../Flex"
import Box from "../../Box"
import InputAdornment from '@material-ui/core/InputAdornment';
import ButtonLayout from "../ButtonLayout"
import { RaisedButton, Button } from "../../buttons"
import { Tabs, Tab } from '../../tabs';


export default function NewContainer(props) {

  console.log(props.fundingEvent)

  return(
    <Fragment>

      <Flex width={1} mb={3}>
        <Box width={1/2} mr={4}>
          <Select
            entities={props.companies}
            label='Company'
            name='company_id'
            value={props.fundingEvent.company_id}
            handleChange={props.handleCompanyChange}
            disabled={true}
          />
        </Box>

        <Box width={1/2}>
          <Select
            entities={props.fundingEventKinds}
            label='Kind'
            name='funding_event_kind_id'
            value={props.fundingEvent.funding_event_kind_id}
            handleChange={props.handleKindChange}
          />
        </Box>

      </Flex>

      <Flex width={1} mb={5} alignItems='center' justifyContent='space-evenly'>
        <Box width={1/3}>
          <TextInput
            name='amount_raised'
            label='Amount Raised'
            value={props.fundingEvent.amount_raised}
            onChange={props.handleChange}
            InputProps={{
              startAdornment: <InputAdornment position="start">$</InputAdornment>,
            }}
            fullWidth
          />
        </Box>

        <Box width={1/3}>
          <TextInput
            name='amount_offered'
            label='Amount Offered'
            value={props.fundingEvent.amount_offered}
            onChange={props.handleChange}
            InputProps={{
              startAdornment: <InputAdornment position="start">$</InputAdornment>,
            }}
            fullWidth
          />
        </Box>
      </Flex>

      <Flex mb={4}>

        <Box width={1/2} mx='auto'>
          <DatePicker
            name='offering_date'
            label='Offering Date'
            handleChange={props.handleDateChange}
            value={props.fundingEvent.offering_date}
          />
        </Box>

        {/* <Box width={1/3}>
          <DatePicker
            name='closed_date'
            label='Closed Date'
            handleChange={props.handleDateChange}
            value={props.fundingEvent.closed_date}
          />
        </Box>

        <Box width={1/3}>
          <DatePicker
            name='date_first_sale'
            label='Date first sold'
            handleChange={props.handleDateChange}
            value={props.fundingEvent.date_first_sale}
          />
        </Box> */}
      </Flex>


      <ButtonLayout>
        <RaisedButton handleClick={props.handleSaveClick}>
          Save Funding Event
        </RaisedButton>
      </ButtonLayout>

    </Fragment>
  )
}
