import React from "react"
import Paper from "../Paper"
import Typography from "@material-ui/core/Typography"
import CardSection from "../cards/CardSection"
import SidebarSection from "../SidebarSection"
import Box from "../Box"
import Link from "../Link"

export default function AttributesModule(props) {

  const technologyGroups = props.technologyGroups ? props.technologyGroups.map((technologyGroup) =>
    <div key={technologyGroup.id}>
      <Box>
        {technologyGroup.name}
      </Box>
    </div>
  ): null

  const therapyTypes = props.therapyTypes ? props.therapyTypes.map((therapyType) =>
    <div key={therapyType.id}>
      {therapyType.name}
    </div>
  ) : ""

  return(
    <Paper>
      <Typography variant="h6">
        Attributes At a Glance
      </Typography>

      <Box mt={2}>
        {props.collections.length > 0 ? <CardSection>
          <h4>Collections</h4>
          {props.collections.map((collection) =>
            <div key={collection.id}>
              <Link href={`/sectors/${props.sector.abbr}/products?collection_ids[]=${collection.id}`}>
                {collection.title}
              </Link>
            </div>
          )}
        </CardSection> : ''}

        {Object.keys(props.targetSites).length > 0 ? <CardSection>
          <h4>Target Sites</h4>

          <Box>
            {Object.keys(props.targetSites).map((worldRegion) =>
              <Box key={worldRegion} mb={2}>
                <div className='b'>
                  {worldRegion}
                </div>

                <Box>
                  {props.targetSites[worldRegion].map((targetSite) =>
                    <Box>
                      {targetSite.name}
                    </Box>
                  )}
                </Box>
              </Box>
            )}
          </Box>
        </CardSection> : ''}

        {Object.keys(props.targetMarkets).length > 0 ? <CardSection>
          <h4>Target Markets</h4>

          <Box>
            {props.sector.market_id == 3
            ?
              <Box>
                {props.targetMarkets.map((targetMarket) =>
                  <Box>
                    {targetMarket.name}
                  </Box>
                )}
              </Box>
            :
              Object.keys(props.targetMarkets).map((worldRegion) =>
                <Box key={worldRegion} mb={2}>
                  <div className='b'>
                    {worldRegion}
                  </div>

                  <Box>
                    {props.targetMarkets[worldRegion].map((targetMarket) =>
                      <Box>
                        {targetMarket.name}
                      </Box>
                    )}
                  </Box>
                </Box>
              )}
          </Box>
        </CardSection> : ''}

        {technologyGroups.length > 0 ? <CardSection>
          <h4>Technology Group</h4>

          <Box>{technologyGroups}</Box>
        </CardSection> : ''}

        {therapyTypes.length > 0 ? <CardSection noBorder>
          <h4>Therapy Types</h4>

          <Box>{therapyTypes}</Box>
        </CardSection> : ''}
      </Box>
    </Paper>
  )
}
