import React from "react"
import Paper from "../../Paper"
import Box from "../../Box"
import AssociatorCard from "./AssociatorCard"


export default function TrialPanel(props) {
  const productNames = props.predictedProducts.map((predictedProduct) => predictedProduct.short_name)

  const trialArmsAll = props.trialArms.map((trialArm) => trialArm.group_label)
  const trialArms = new Set([...trialArmsAll])

  const trialArmsArray = Array.from(trialArms)

  const trialInterventionsAll = props.trialInterventions.map((trialIntervention) => trialIntervention.name)
  const trialInterventions = new Set([...trialInterventionsAll])
  const trialInterventionsArray = Array.from(trialInterventions)
  return (
    <React.Fragment>
      <Paper fullHeight>

        <Box height="100%">

          <Box mt={2} height="90%" overflow='auto'>
            <AssociatorCard
              trial={props.trial}
              trialDataView={props.trialDataView}
              productNames={productNames}
              trialInterventions={props.trialInterventions}
              trialArms={props.trialArms}
            />
          </Box>
        </Box>

      </Paper>
    </React.Fragment>
  )
}
