import React, { Fragment } from "react"
import Paper from "../../Paper"
import List from "../../lists/List"
import TextInput from "../../TextInput"
import Flex from "../../Flex"
import Box from "../../Box"
import SearchIcon from '@material-ui/icons/Search'
import InputAdornment from '@material-ui/core/InputAdornment';
import { RaisedButton } from "../../buttons"
import CompanyListItem from "./CompanyListItem"

export default function CompaniesList(props) {
  return (
    <Fragment>

      <Flex alignItems='center'>
        <Box width={1}>
          <TextInput
            name='query'
            label='Search Companies'
            value={props.companyQuery}
            onChange={props.handleCompanySearchChange}
            fullWidth
            InputProps={{
              startAdornment: <InputAdornment position="start"><SearchIcon /></InputAdornment>,
            }}
            helperText='Search by name'
          />
        </Box>

        <Box ml='auto'>
          <RaisedButton onClick={props.handleCompanySearchClick}>
            Search
          </RaisedButton>
        </Box>
      </Flex>

      <List>
        {(props.searchedCompanies.length > 0 ? props.searchedCompanies : props.companies).map((company) => {
          const companyData = company?._source || company;
          return (
            <CompanyListItem
              key={companyData.id}
              company={companyData}
              checked={props.companyId == companyData.id}
              handleCheckboxSelect={props.handleCheckboxSelect}
            />
          );
        })}
      </List>

    </Fragment>
  )
}
