import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import { Table, TableHead, TableRow, TableCell, TableBody, TableHeadSort } from "../tables"
import TrialTypeListDisplay from "../trials/TrialTypeListDisplay"
import Link from "../Link"
import Box from "../Box"
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TablePaginationActions from "../tables/TablePaginationActions"


export default function StudiesTable(props) {
  let dateColumn = props.dateColumn ? props.dateColumn : 'Updated'
  return (
    <Box overflow='auto' height={props.height}>
      <Table size='small' stickyHeader={true} aria-label='product studies table'>

        <TableHeadSort
          headers={[{ name: 'short_name', label: 'Trial' }, { name: 'sponsor_name', label: 'Sponsor' }, { name: 'type', label: 'Type' }, { name: 'status', label: 'Status' }, { name: 'updated_at', label: dateColumn }]}
          order={props.order}
          orderBy={props.orderBy}
          onRequestSort={props.handleRequestSort}
        />

        <TableBody>
          {props.trials.map((trial) =>
            <TableRow key={trial.trial_id}>
              <TableCell>
                <Link href={`/sectors/${props.sector.abbr}/trials/${trial.trial_id}`}>
                  <Box>
                    {trial.acronym || trial.short_name}
                  </Box>
                </Link>
              </TableCell>
              <TableCell>
                {trial.company_id ?
                  <Link href={`/sectors/${props.sector.abbr}/companies/${trial.company_id}`}>
                    <Box>
                      {trial.sponsor_name}
                    </Box>
                  </Link>
                :
                  <Box>
                    {trial.sponsor_name}
                  </Box>
                }
              </TableCell>
              <TableCell>
                {/* <TrialTypeListDisplay clinicalTypes={trial.clinical_types} /> */}
                {trial.grouped_types ?
                  trial.grouped_types.split(";").map((type) =>
                    <div>
                      {type}
                    </div>
                  )
                : ''}
              </TableCell>
              <TableCell>
                <Box>{trial.status}</Box>
              </TableCell>
              <TableCell>
                {props.dateColumn ? trial.date : humanDateFormat(trial.last_updated)}
              </TableCell>
            </TableRow>
          )}
        </TableBody>
        {props.handleChangePage ?
          <TableFooter>
            <TablePagination
              colSpan={6}
              count={props.trialsCount}
              rowsPerPage={100}
              page={props.page}
              SelectProps={{
                inputProps: { 'aria-label': 'rows per page' },
                native: true,
              }}
              onChangePage={props.handleChangePage}
              onChangeRowsPerPage={props.handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </TableFooter>
        : ""}
      </Table>
    </Box>
  )
}
