import React, { Component } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Link from "../Link"
import Typography from '@material-ui/core/Typography';


class AlertHeader extends Component {
  constructor(props) {
    super(props)
  }

  renderHeader() {
    if (this.props.alert.trial_id) {
      return(<Typography className='mv0'><Link className='blue' href={`/trials/${this.props.alert.trial_id}`}>{this.props.alert.trial}</Link></Typography>)
    } else if (this.props.alert.product_id) {
      return(<Typography className='mv0'><Link className='blue' href={`/products/${this.props.alert.product_id}`}>{this.props.alert.product}</Link></Typography>)
    } else if (this.props.alert.company_id) {
      return(<Typography className='mv0'><Link className='blue' href={`/companies/${this.props.alert.company_id}`}>{this.props.alert.company}</Link></Typography>)
    }
  }

  render() {
    return(
      <div>
        {this.renderHeader()}
      </div>
    )
  }

}


export default AlertHeader
