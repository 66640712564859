import React, { Fragment } from "react"
import { List } from "../lists"
import ItemDisplay from "./ListItemDisplay"
import Typography from "@material-ui/core/Typography"
import Box from "../Box"


export default function ListDisplay(props) {

  return(
    <Fragment>
      <Box>
        <Box justifyContent="right" display="flex">
          <Typography variant="body2">
            {props.manuscriptsCount} Publications
          </Typography>
        </Box>
        <List>
          {props.manuscripts.map((publication) =>
            <ItemDisplay
              key={publication._id || publication.id}
              publicationParent={publication}
              publication={publication._source ? publication._source : publication}
              sector={props.sector}
            />
          )}
        </List>
      </Box>
    </Fragment>
  )
}
