import React, { Fragment, useState } from "react"
import ExpandLess from '../ExpandLess';
import ExpandMore from '../ExpandMore';
import Collapse from '@material-ui/core/Collapse';
import Flex from "../Flex"
import Box from "../Box"
import Typography from "@material-ui/core/Typography"
import Button from "../buttons/Button"
import { Table, TableHead, TableRow, TableCell, TableBody } from "../tables"


export default function CountrySites(props) {
  const [open, setOpen] = useState(true)

  function handleGroupClick(e) {
    const openState = !open

    setOpen(openState)
  }

  return(
    <Fragment>
      <TableRow key={props.site.id}>
        <TableCell>
          {props.site.facility}
        </TableCell>
        <TableCell>
          {props.site.country}
        </TableCell>
        <TableCell>
          {props.site.state ? props.site.state : ''}
        </TableCell>
        <TableCell>
          {props.site.city}
        </TableCell>
        <TableCell align='right'>
          {props.site.investigator_count}
        </TableCell>
      </TableRow>
    </Fragment>
  )
}
