import React from "react"
import { Table, TableHead, TableRow, TableCell, TableBody } from "../tables"
import Link from "../Link"
import Box from "../Box"

export default function InvestigatorRow(props) {
  let activeInvestigator = props.showCurrent ? props.currentInvestigators.includes(props.investigator.person_id) : true
  return (
    <TableRow>
      <TableCell>
        <Link href={`/sectors/${props.sector.abbr}/people/${props.investigator.person_id}`} className={activeInvestigator ? '' : '0-50'}>
          <Box>
            {props.investigator.text}
          </Box>
        </Link>
      </TableCell>
      <TableCell className={activeInvestigator ? '' : '0-50'}>
        {props.investigator.affiliation || props.investigator.facility_name}
      </TableCell>
      <TableCell className={activeInvestigator ? '' : '0-50'}>
        {props.investigator.role}
      </TableCell>
    </TableRow>
  )
}
