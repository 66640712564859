import React, { Component, Fragment } from "react"
import Flex from "../../Flex"
import Box from "../../Box"
import TrialProducts from "./TrialProducts"
import Typography from '@material-ui/core/Typography';
import Select from "../../Select"
import Checkbox from "../../Checkbox"
import update from "immutability-helper"
import { withSnackbar } from "../../SnackbarContainer"
import { RaisedButton } from "../../buttons"
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

class ProductForm extends Component {
  constructor(props) {
    super(props)
    this.handleProductChange = this.handleProductChange.bind(this)
    this.handleDeleteClick = this.handleDeleteClick.bind(this)
    this.handleFormSave = this.handleFormSave.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.state = { trialProducts: [], investigated_device: null, product: null }
  }

  componentDidMount() {
    $.get(`/admin/trials/${this.props.trial.id}/products`, {}, (data) => {
      this.setState({
        trialProducts: data.trial_products
      })
    }, 'json')
  }

  handleFormSave(e) {
    if (this.props.source) {
      $.post("/admin/trial_products", { trial_product: { trial_id: this.props.trial.id, product_id: this.state.product_id, source_id: this.props.source.source_id, created_by: this.props.currentUser.id, investigated_device: this.state.investigated_device }}, (data) => {
        let trialProducts = update(this.state.trialProducts, { $push: [data.trial_product] })
        this.setState({
          trialProducts: trialProducts
        })
        this.props.snackbarShowMessage('The product has been added')
      }, 'json').fail((data) => {
        this.props.snackbarShowMessage(`The product could not be added. ${data.responseText}`)
      })
    } else {
      this.props.snackbarShowMessage("You must choose a source before adding a product")
    }
  }

  handleProductChange(e, selected) {
    this.setState({
      product_id: selected.value
    })
  }

  handleDeleteClick(trialProductHash) {
    $.ajax({
      type: "PUT",
      url: `/admin/trial_products/${trialProductHash.trial_product.id}`,
      data: {
        trial_product: {
          deleted: true, deleted_by: this.props.currentUser.id
        }
      },
      dataType: 'json',
      success: (data) => {
        let index = this.state.trialProducts.indexOf(trialProductHash)
        let trialProducts = update(this.state.trialProducts, { $splice: [[index, 1]]})
        this.setState({
          trialProducts: trialProducts,
        })
        this.props.snackbarShowMessage('The product has been removed from this trial')
      },
      error: (data) => {
        this.props.snackbarShowMessage('The product could not be removed from the trial. Please try again.', 'error')
      }
    })
  }

  handleChange(e, newValue) {
    this.setState({
      investigated_device: newValue
    })
  }

  render() {
    let productValue = this.props.products.filter(product => product.value == this.state.product_id)[0]
    return(
      <Fragment>
        <div>
          <Typography variant="h6" component="h6" className='mb1'>
            New Product
          </Typography>

          <Flex alignItems='center'>
            <Box width={1/2}>
              <div style={{ width: 300 }}>
                <Autocomplete
                  id='product_id'
                  options={this.props.products}
                  name='product_id'
                  defaultValue={productValue}
                  value={productValue}
                  getOptionLabel={(option) => option.name}
                  style={{ width: 300 }}
                  selectOnFocus
                  onChange={this.handleProductChange}
                  renderInput={(params) => <TextField {...params} name='product_id' label='Product' variant="outlined" />}
                />
              </div>
            </Box>

            <Box ml='auto'>
              <Checkbox
                name='investigated_device'
                label='Investigated'
                checked={this.state.investigated_device}
                onChange={this.handleChange}
              />
            </Box>

            <Box ml='auto'>
              <RaisedButton onClick={this.handleFormSave}>
                Save
              </RaisedButton>
            </Box>
          </Flex>
        </div>

        <TrialProducts
          trialProducts={this.state.trialProducts}
          handleDeleteClick={this.handleDeleteClick}
        />
      </Fragment>
    )
  }
}

export default withSnackbar(ProductForm)
