import React from "react"
import PropTypes from "prop-types"
import Paper from "../../Paper"
import Flex from "../../Flex"
import Box from "../../Box"
import { Table, TableRow, TableCell, TableHeadSort, TableBody } from "../../tables"
import Link from "../../Link"
import Select from "../../Select"
import AssignmentRow from "./AssignmentRow"
import update from "immutability-helper"

class Assignments extends React.Component {
  constructor(props) {
    super(props)
    this.handleFilterChange = this.handleFilterChange.bind(this)
    this.handleMarkCompleteClick = this.handleMarkCompleteClick.bind(this)
    this.state = { abstracts: this.props.abstracts, user_id: this.props.user_id, meeting_id: this.props.meeting_id, order: 'desc', orderBy: 'date' }
  }

  handleMarkCompleteClick(abstract) {
    $.ajax({
      type: 'POST',
      url: "/admin/abstract_reporter_assignment_statuses",
      data: {
        abstract_reporter_assignment_status: {
          abstract_id: abstract.id, completed: true, completed_by: this.props.current_user.id
        }
      },
      dataType: 'json',
      success: (data) => {
        let index = this.state.abstracts.indexOf(abstract)

        let abstracts = update(this.state.abstracts, {
          $splice: [[index, 1]]
        })

        this.setState({
          abstracts: abstracts
        })
      }
    })
  }

  handleFilterChange(name, value) {
    let params = { meeting_id: this.state.meeting_id, user_id: this.state.user_id }

    params[name] = value

    $.ajax({
      type: 'GET',
      url: "/admin/assignments",
      data: params,
      dataType: 'JSON',
      success: (data) => {
        this.setState({
          abstracts: data.abstracts,
          meeting_id: params.meeting_id,
          user_id: params.user_id
        })
      }
    })
  }

  render () {
    return (
      <React.Fragment>
        <Paper fullHeight>
          <Flex mb={3}>
            <Box mr={3} width="15%">
              <Select
                label='Meeting'
                name='meeting_id'
                includeBlank={true}
                value={this.state.meeting_id}
                entities={this.props.meetings}
                handleChange={this.handleFilterChange}
              />
            </Box>

            {this.props.current_user.user_company_id == 1 ?
              <Box width="15%">
                <Select
                  label='User'
                  name='user_id'
                  includeBlank={true}
                  value={this.state.user_id}
                  entities={this.props.users}
                  handleChange={this.handleFilterChange}
                />
              </Box>
            : ""}
          </Flex>


          <Box overflow='auto' height="90%">
            <Table size='small' aria-label='Assignments table' stickyHeader={true}>

              <TableHeadSort
                headers={[{ name: '', label: 'Mark as Completed' }, { name: 'number', label: '#' }, { name: 'title', label: 'Abstract' }, { name: 'date', label: 'Date' }, { name: 'science_meeting_occurrence_id', label: 'Meeting' }, { name: 'reporter_user_id', label: 'Assignee' }]}
                order={this.state.order}
                orderBy={this.state.orderBy}
                onRequestSort={this.handleRequestSort}
              />

              <TableBody>
                <React.Fragment>
                  {this.props.loading ?
                    <TableRow hover={false}>
                      <TableCell noBorder colSpan={5}>
                        <LinearProgress />
                      </TableCell>
                    </TableRow>
                  : ""}
                  {this.state.abstracts.map((abstract) =>
                    <AssignmentRow
                      abstract={abstract}
                      handleMarkCompleteClick={this.handleMarkCompleteClick}
                    />
                  )}
                </React.Fragment>
              </TableBody>
            </Table>
          </Box>
        </Paper>
      </React.Fragment>
    );
  }
}

export default Assignments
