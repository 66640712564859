import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Paper from "../../Paper"
import { Table, TableHead, TableRow, TableCell, TableBody, EmptyDataTableRow } from "../../tables"
import FdaDocumentRow from "./FdaDocumentRow"
import update from "immutability-helper"
import Snackbar from "../../Snackbar"


export default class InvestorPresentations extends Component {
  constructor(props) {
    super(props)
    this.handleDeleteClick = this.handleDeleteClick.bind(this)
    this.handleUndoClick = this.handleUndoClick.bind(this)
    this.state = { fdaDocuments: this.props.fda_documents, showSnackbar: false, snackbarText: '', lastDeleted: '' }
  }

  handleUndoClick(e) {
    if (this.state.lastDeleted) {
      $.ajax({
        type: 'PUT',
        url: `/admin/fda_documents/${this.state.lastDeleted.id}`,
        data: {
          fda_document: {
            deleted: null, deleted_by: null
          }
        },
        dataType: 'json',
        success: (data) => {
          const fdaDocuments = update(this.state.fdaDocuments, { $push: [this.state.lastDeleted] })
          this.setState({
            fdaDocuments: fdaDocuments,
            showSnackbar: true,
            snackbarText: 'FDA document was undeleted',
            lastDeleted: null
          })
        },
        error: (data) => {
          this.setState({
            showSnackbar: true,
            snackbarText: data.responseText
          })
        },
        completed: (data) => {
          setTimeout(() => {
            this.setState({
              showSnackbar: false,
              snackbarText: ''
            })
          }, 4000)
        }
      })
    }
  }

  handleDeleteClick(fdaDocument) {
    $.ajax({
      type: 'PUT',
      url: `/admin/fda_documents/${fdaDocument.id}`,
      data: {
        fda_document: {
          deleted: true, deleted_by: this.props.current_user
        }
      },
      dataType: 'json',
      success: (data) => {
        const index = this.state.fdaDocuments.indexOf(fdaDocument)
        let fdaDocuments = update(this.state.fdaDocuments, { $splice: [[index,1]] })
        this.setState({
          fdaDocuments: fdaDocuments,
          showSnackbar: true,
          snackbarText: 'FDA Document was deleted',
          lastDeleted: fdaDocument
        })
      },
      error: (data) => {
        this.setState({
          showSnackbar: true,
          snackbarText: `Fda Document could not be deleted. ${data.responseText}`
        })
      },
      complete: (data) => {
        setTimeout(() => {
          this.setState({
            showSnackbar: false,
            snackbarText: ''
          })
        }, 4000)
      }
    })
  }

  render() {
    return(
      <Fragment>

        <Paper>
          <Table className='w-100'>
            <TableHead>
              <tr>
                <TableCell>FDA Document</TableCell>
                <TableCell>URL</TableCell>
                <TableCell>Date</TableCell>
                <TableCell>Created By</TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
              </tr>
            </TableHead>
            <TableBody>
              {this.state.fdaDocuments.length > 0 ? this.state.fdaDocuments.map((fdaDocument) =>
                <FdaDocumentRow
                  key={fdaDocument.id}
                  fdaDocument={fdaDocument}
                  handleDeleteClick={this.handleDeleteClick}
                />
              ) : <EmptyDataTableRow colSpan={5} message='No FDA document yet. Create an FDA document and it will display here' />}
            </TableBody>
          </Table>
        </Paper>


        <Snackbar
          open={this.state.showSnackbar}
          onClose={this.handleUndoClick}
          message={this.state.snackbarText}
        />
      </Fragment>
    )
  }
}
