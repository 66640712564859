import React, { Component } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"

class CardSectionHeader extends Component {
  constructor(props) {
    super(props)
  }
  
  render() {
    return(
      <h4 className='mv2'>{this.props.children}</h4>
    )
  }
}

export default CardSectionHeader
