import React, {Component} from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Flex from "./Flex"
import Box from "./Box"
import IconButton from "./IconButton"
import Paper from "./Paper"
import DeviseAlert from "./DeviseAlert"

class Alert extends Component {

  componentDidMount() {
    this.timer = setTimeout(
      this.props.onClose,
      this.props.timeout
    );
  }

  componentWillUnmount() {
    clearTimeout(this.timer);
  }

  alertClass (type) {
    let classes = {
      error: 'white bg-red',
      alert: 'white bg-red',
      notice: 'bg-black-50 white',
      success: 'dark-green bg-light-green ba b--lightest-green'
    };
    return classes[type] || classes.success;
  }

  render() {
    const message = this.props.message;
    const alertClassName = `alert tc fade in pt3 fixed left-50 right-auto z-9999 flex align-center justify-center translate-x-50`

    return(
      <div className={ alertClassName }>
        <Paper className={`${this.alertClass(message.type)} pv2 ph4 br2`} withPadding={false}>
          <Flex wrap align='items-center'>
            <Box className='pv2'>
              {message.devise ? <DeviseAlert header={message.header} items={message.items} /> : message.text }
            </Box>
            {/* <Box className='ml-auto'>
              <IconButton icon='close' handleClick={this.props.onClose} iconColor='white' className='bg-transparent white bn pointer' />
            </Box> */}
          </Flex>
        </Paper>
      </div>
    );
  }
}

Alert.propTypes = {
  onClose: PropTypes.func,
  timeout: PropTypes.number,
  message: PropTypes.object.isRequired
};

Alert.defaultProps = {
  timeout: 3000
};

export default Alert
