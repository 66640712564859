import React from "react"
import { TableRow, TableCell } from "../../tables"
import Flex from "../../Flex"
import Box from "../../Box"
import { RaisedButton } from "../../buttons"
import MuiIconButton from '@material-ui/core/IconButton';
import IndeterminateCheckBoxIcon from '@material-ui/icons/IndeterminateCheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Checkbox from '@material-ui/core/Checkbox';
import SearchDropdown from "../../SearchDropdown"


export default function DeviceRow(props) {

  function handleDeviceCheckboxClick(e) {
    props.handleDeviceCheckboxClick(props.device)
  }

  function handleChange(e, entityOption) {
    props.handleChange(entityOption.value)
  }

  function handleCreateProductClick(e) {
    props.handleCreateProductClick(props.device)
  }

  let regulatoryNumber = props.device.k_number ? props.device.k_number : (props.device.supplement_number ? `${props.device.pma_number} ${props.device.supplement_number}` : props.device.pma_number)

  return(
    <TableRow>
      <TableCell padding='checkbox'>
        <MuiIconButton onClick={handleDeviceCheckboxClick} edge="start">
          {props.checkedDevice == props.device.id ? <CheckBoxIcon color='primary' style={{height: '22px', width: '22px'}} /> : <CheckBoxOutlineBlankIcon style={{height: '22px', width: '22px'}} />}
        </MuiIconButton>
      </TableCell>
      <TableCell>
        {props.device.device_name || props.device.trade_name}
      </TableCell>
      <TableCell>
        <Flex alignItems='center'>
          <Box>
            {props.device.applicant}
          </Box>
          {props.checkedDevice == props.device.id ?
            <Box>
              <SearchDropdown
                value={props.companyQuery}
                name='company_id'
                options={props.companies}
                handleChange={handleChange}
              />
            </Box>
          : ""}
        </Flex>

      </TableCell>
      <TableCell>
        {props.device.product_code}
      </TableCell>
      <TableCell>
        {regulatoryNumber}
      </TableCell>
      <TableCell>
        {props.device.date_received}
      </TableCell>
      <TableCell>
        {props.products.filter(product => (product.k_number || product.pma_number) == regulatoryNumber)[0]
        ?
          "Already Created"
        :
          <RaisedButton size="small" onClick={handleCreateProductClick}>
          Create Product
        </RaisedButton>}
      </TableCell>
    </TableRow>
  )
}
