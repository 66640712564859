import React, { Component } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Chart from "../charts/Chart"

export default class SitesChart extends Component {
  constructor(props) {
    super(props)
  }

  determinePlotLine() {
    if (this.props.showTargetEnrollment && this.props.targetEnrollment) {
      return([{ color: '#FF0000', value: this.props.targetEnrollment.enrollment, width: 2, zIndex: 1, id: 'target-enrollment-line', label: { text: `Target Enrollment - ${this.props.targetEnrollment.enrollment}` } }])
    }
  }

  setXAxisPlotLine() {
    if (this.props.showEnrollmentDates) {
      return([{ color: '#B57E7E', value: this.props.enrollmentStartDateForChart, width: 2, zIndex: 1, id: 'enrollment-start-date-line', label: { text: 'Enrollment start date' }}, { color: '#B57E7E', value: this.props.enrollmentEndDateForChart, width: 2, zIndex: 1, id: 'enrollment-end-date-line', label: { text: 'Completion date' }}])
    }
  }

  setMarkerDisplay(array) {
    if (array[0] == 'Target enrollment') {
      return(false)
    } else {
      return(true)
    }
  }

  setDataLabelDisplay(array) {
    if (this.props.hideDataLabels) {
      return(false)
    } else {
      if (array[0] == 'Target enrollment') {
        return(false)
      } else {
        return(true)
      }
    }
  }

  setXaxisMin() {
    if (this.props.sitesChartData.length == 0) {
      null
    } else {
      let dateValArray = []

      this.props.sitesChartData.map(array =>
        dateValArray.push(array.x)
      )

      if (!this.props.showEnrollmentDates) {
        return(Math.min.apply(Math, dateValArray) - ((Math.max.apply(Math, dateValArray) - Math.min.apply(Math, dateValArray)) * 0.05))
      } else {
        let minEnrollmentDate = Math.min.apply(Math, dateValArray)
        let maxEnrollmentDate = Math.max.apply(Math, dateValArray)


        if (minEnrollmentDate < this.props.enrollmentStartDateForChart) {

          let padding = (maxEnrollmentDate - minEnrollmentDate) * 0.05

          return(minEnrollmentDate - padding)

        } else {

          let lastWithEndDate = [maxEnrollmentDate, this.props.enrollmentEndDateForChart]

          let padding = (Math.max.apply(Math, lastWithEndDate) - Math.min.apply(Math, lastWithEndDate)) * 0.05

          return(this.props.enrollmentStartDateForChart - padding)
        }

      }
    }
  }

  setXaxisMax() {
    let enrollmentDateValArray = []

    this.props.sitesChartData.map(enrollmentArray =>
      enrollmentDateValArray.push(enrollmentArray.x)
    )

    if (!this.props.showEnrollmentDates) {

      return(((Math.max.apply(Math, enrollmentDateValArray) - Math.max.apply(Math, enrollmentDateValArray)) * 0.05) + Math.max.apply(Math, enrollmentDateValArray))

    } else {

      let maxEnrollmentDate = Math.max.apply(Math, enrollmentDateValArray)
      let lastWithEndDate = [maxEnrollmentDate, this.props.enrollmentEndDateForChart]

      let padding = (Math.max.apply(Math, lastWithEndDate) - Math.min.apply(Math, lastWithEndDate)) * 0.05

      return(padding + Math.max.apply(Math, lastWithEndDate))
    }
  }

  setYaxisMax() {
    if (this.props.chartData.length == 0) {
      null
    } else {
      let enrollmentValArray = []
      let [first, ...enrollmentVals] = this.props.chartData[0]

      enrollmentVals.map(enrollmentArray =>
        enrollmentValArray.push(enrollmentArray[1])
      )

      if (this.props.targetEnrollment) {
        enrollmentValArray.push(this.props.targetEnrollment.enrollment)
      }

      let maxEnrollment = Math.max.apply(Math, enrollmentValArray)

      return(maxEnrollment + 5)
    }
  }

  setYaxisMin() {
    if (this.props.chartData.length == 0) {
      return(null)
    } else {
      let enrollmentValArray = []
      let [first, ...enrollmentVals] = this.props.chartData[0]

      enrollmentVals.map(enrollmentArray =>
        enrollmentValArray.push(enrollmentArray[1])
      )

      if (this.props.targetEnrollment) {
        enrollmentValArray.push(this.props.targetEnrollment.enrollment)
      }

      let minEnrollment = Math.min.apply(Math, enrollmentValArray)

      if (enrollmentValArray.length == 1) {
        return(enrollmentValArray[0] - 0.25 * enrollmentValArray[0])
      } else {
        return(null)
      }
    }

  }

  formatData() {
    let chartData = ""
    const data = this.props.sitesChartData.map((array) => {
      let newArr = new Array(array)
      let flatArr = Array.prototype.concat.apply([], newArr)
      let dataArr = flatArr.splice(1)

      return({
        name: array[0],
        marker: {
          enabled: this.props.dataLabels
        },
        dataLabels: {
          enabled: this.props.dataLabels
        },
        data: dataArr
      })
    })

    return(data)
  }

  render() {
    console.log(this.props.categories)
    return(
      <Chart
        key='sites-chart'
        chartId='sites-chart'
        chartOptions={{type: 'spline', height: 300}}
        legend={true}
        plotOptions={{spline: { dataLabels: { enabled: this.props.dataLabels }, marker: { enabled: this.props.dataLabels }}}}
        xAxisType={this.props.xAxisType}
        categories={this.props.categories}
        xAxisTitle={this.props.xAxisTitle}
        // xAxisPlotLine={this.setXAxisPlotLine()}
        // xAxisMin={this.setXaxisMin()}
        // xAxisMax={this.setXaxisMax()}
        // yAxisMax={this.setYaxisMax()}
        // yAxisMin={this.setYaxisMin()}
        formattedData={this.formatData()}
        simple={false}
        startOnTick={true}
        endOnTick={true}
        title={this.props.title}
        yAxisTitle='# of sites'
      />
    )
  }
}

SitesChart.defaultProps = {
  title: 'Sites',
  xAxisType: 'datetime',
  xAxisTitle: '',
  dataLabels: true
}
