import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Form from "./Form"
import Paper from "../../Paper"
import update from "immutability-helper"
import { withSnackbar } from "../../SnackbarContainer"


class EditContainer extends Component {
  constructor(props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
    this.handleSaveClick = this.handleSaveClick.bind(this)
    this.state = { searchKeyword: this.props.search_keyword }
  }

  handleTabClick(e) {
    e.preventDefault()
    this.setState({
      activeTab: e.target.text
    })
  }

  handleChange(e) {
    const name = e.currentTarget.name
    const value = e.currentTarget.value

    let searchKeyword = update(this.state.searchKeyword, { [name]: { $set: value } })

    this.setState({
      searchKeyword: searchKeyword
    })

  }

  handleSaveClick(e) {
    $.ajax({
      type: 'PUT',
      url: `/admin/search_keywords/${this.state.searchKeyword.id}`,
      data: {
        search_keyword: this.state.searchKeyword
      },
      dataType: 'json',
      success: (data) => {
        window.location.href = "/admin/search_keywords"
      },
      error: (data) => {
        this.props.snackbarShowMessage('Search keyword could not be added. Please try again.', "error")
      }
    })
  }

  render() {
    return(
      <Fragment>

        <Paper fullHeight>

          <Form
            searchKeyword={this.state.searchKeyword}
            handleChange={this.handleChange}
            handleSaveClick={this.handleSaveClick}
          />

        </Paper>
      </Fragment>
    )
  }
}


export default withSnackbar(EditContainer)
