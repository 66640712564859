import React, { Fragment } from "react"
import { TableRow, TableCell } from "../../tables"
import Link from "../../Link"

export default function KClearanceRow(props) {

  return(
    <TableRow component={Link} href={`/sectors/${props.sector.abbr}/products/${props.product.id}/k_clearances/${props.kClearance.id}`}>
      <TableCell>{props.kClearance.k_number}</TableCell>
      <TableCell>{props.kClearance.applicant}</TableCell>
      <TableCell>{props.kClearance.decision_date}</TableCell>
    </TableRow>
  )
}
