import React, { Component, Fragment } from "react"
import Paper from "../Paper"
import { diffLines } from 'diff'
import Flex from "../Flex"
import Box from "../Box"
import HistoryDisplay from "./HistoryDisplay"
import Typography from "@material-ui/core/Typography"
import Select from "../Select"
import { Tabs, Tab } from "../tabs"

export default class HistoryContainer extends Component {
  constructor(props) {
    super(props)
    this.handleActiveIndexUpdate = this.handleActiveIndexUpdate.bind(this)
    this.handleOpenDialogClick = this.handleOpenDialogClick.bind(this)
    this.handleCloseDialog = this.handleCloseDialog.bind(this)
    this.handleOldSourceChange = this.handleOldSourceChange.bind(this)
    this.handleNewSourceChange = this.handleNewSourceChange.bind(this)
    // this.handleCompareClick = this.handleCompareClick.bind(this)
    this.handleFilterChange = this.handleFilterChange.bind(this)
    this.state = { attribute: this.props.attribute, record1Text: this.props.record_1_text, record2Text: this.props.record_2_text, source_id_1: this.props.source_id_1, source_id_2: this.props.source_id_2, new_source_id: this.props.new_source_id, dateOptions: this.props.date_options, changedAttributeSources: this.props.changed_attribute_sources, latestSource: this.props.latest_source, oldSource: this.props.old_source, openDialog: false }
  }

  componentDidMount() {
    if (this.props.attribute) {
      this.setState({
        attribute: this.props.attribute
      })
    }
  }

  handleOpenDialogClick(e) {
    this.setState({
      openDialog: true
    })
  }

  handleCloseDialog(e) {
    this.setState({
      openDialog: false
    })
  }

  handleOldSourceChange(e) {
    this.setState({
      old_source_id: e.target.value
    })
  }

  handleNewSourceChange(e) {
    this.setState({
      new_source_id: e.target.value
    })
  }

  handleActiveIndexUpdate(e, newValue) {
    let params = { attribute: newValue }

    $.getJSON(`/sectors/${this.props.sector.abbr}/trials/${this.props.trial.id}/history`, params, (data) => {
      this.setState({
        attribute: data.attribute,
        record1Text: data.record_1_text,
        record2Text: data.record_2_text,
        source_id_1: data.source_id_1,
        source_id_2: data.source_id_2,
        dateOptions: data.date_options,
        changedAttributeSources: data.changed_attribute_sources
      })
    })
  }

  handleFilterChange(name, value) {
    let params = { attribute: this.state.attribute, source_id_1: this.state.source_id_1, source_id_2: this.state.source_id_2 }

    params[name] = value

    this.getHistoryData(params)
  }

  getHistoryData(params) {
    $.getJSON(`/sectors/${this.props.sector.abbr}/trials/${this.props.trial.id}/history_data`, params, (data) => {
      this.setState({
        record1Text: data.record_1_text,
        record2Text: data.record_2_text,
        source_id_1: params.source_id_1,
        source_id_2: params.source_id_2
      })
    })
  }

  render() {
    let wordDiffs = ""
    let changedAttributeSourcesLength = this.state.changedAttributeSources.length
    let dateOptionsLength = this.state.dateOptions ? this.state.dateOptions.length : 0
    if (this.state.changedAttributeSources.length > 0) {
      wordDiffs = diffLines(this.state.record1Text, this.state.record2Text)
    }
    return (
      <Fragment>

        <Paper minFullHeight={changedAttributeSourcesLength >= 1 ? true : false} fullHeight={changedAttributeSourcesLength == 0 ? true : false}>

          <Box height="100%">

            {changedAttributeSourcesLength > 0 ?

              <Fragment>
                <Box className='bb b--black-30'>

                  <Box mb={3}>
                    <Tabs variant="fullWidth" value={this.state.attribute} onChange={this.handleActiveIndexUpdate} className='bb b--black-10' aria-label="nav tabs">
                      {this.props.attributes.map((attribute) =>
                        <Tab key={attribute.value} label={attribute.name} value={attribute.value} />
                      )}
                    </Tabs>
                  </Box>

                  <Box textAlign='center' mb={2} fontSize={16} fontWeight={500}>
                    <Typography>
                      Showing changes between
                    </Typography>
                  </Box>

                  <Flex alignItems='center' justifyContent='center'>

                    <Box fontSize={16}>
                      <Select
                        label='Sources'
                        name='source_id_1'
                        value={this.state.source_id_1}
                        entities={this.state.dateOptions}
                        handleChange={this.handleFilterChange}
                        includeBlank={false}
                      />
                    </Box>

                    <Box px={2}>vs</Box>

                    <Box fontSize={16}>
                      <Select
                        label='Sources'
                        name='source_id_2'
                        value={this.state.source_id_2}
                        entities={this.state.dateOptions}
                        handleChange={this.handleFilterChange}
                        includeBlank={false}
                      />
                    </Box>

                  </Flex>

                  {/* <Box display="flex" justifyContent='center'>
                    <Button onClick={this.handleOpenDialogClick}>Change Dates</Button>
                  </Box> */}

                  <Flex flexDirection='column'>
                    <Box ml='auto'>
                      <span className='red-highlight'>red</span> = Deletions
                    </Box>
                    <Box ml='auto'>
                      <span className='green-highlight'>green</span> = Additions
                    </Box>
                  </Flex>

                </Box>

                <Box mt={3}>
                  <HistoryDisplay
                    attribute={this.state.attribute}
                    wordDiffs={wordDiffs}
                  />
                </Box>
              </Fragment>
            :
              <Flex justifyContent='center' height="50%">
                <Box alignSelf='center' maxWidth="50%">
                  <Box>
                    <Typography variant="body1">
                      No changes were found for this trial.
                    </Typography>
                  </Box>
                </Box>
              </Flex>
            }

          </Box>

        </Paper>
      </Fragment>
    );
  }
}
