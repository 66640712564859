import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Paper from "../../Paper"
import Flex from "../../Flex"
import Box from "../../Box"
import EntityCard from "../EntityCard"
import Highlighter from "react-highlight-words";
import Link from "../../Link"
import Typography from "@material-ui/core/Typography"

export default class PatentPanel extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    return(
      <Fragment>

        <Paper fullHeight={true}>

          <Box mb={3}>
            <Link href={this.props.sbirGrant.award_link} target="_blank">
              View on SBIR site
            </Link>
          </Box>

          <Box height="100%" overflow="auto">
            <Flex flexDirection='column' mb={2}>
              <Box width={1} mb={1}>
                <label className='b'>Award Title</label>
              </Box>
              <Box width={1}>
                {this.props.sbirGrant.award_title}
              </Box>
            </Flex>

            <Flex flexDirection='column' mb={2}>
              <Box width={1} mb={1}>
                <label className='b'>Firm</label>
              </Box>
              <Box width={1}>
                {this.props.sbirGrant.firm}
              </Box>
            </Flex>

            <Flex flexDirection='column' mb={2}>
              <Box width={1} mb={1}>
                <label className='b'>Award Year</label>
              </Box>
              <Box width={1}>
                {this.props.sbirGrant.award_year}
              </Box>
            </Flex>

            <Flex flexDirection='column' mb={2}>
              <Box width={1} mb={1}>
                <label className='b'>Abstract</label>
              </Box>
              <Box width={1}>
                {this.props.sbirGrant.abstract}
              </Box>
            </Flex>

          </Box>

        </Paper>

      </Fragment>
    )
  }
}
