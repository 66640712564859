import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Paper from "../../Paper"
import Flex from "../../Flex"
import Box from "../../Box"
import PdfStatsModule from "./PdfStatsModule"
import DashboardModule from "../../dashboard/Module"

export default class Dashboard extends Component {
  constructor(props) {
    super(props)
  }
  
  render() {
    return(
      <Fragment>
          
        <Flex mb={4}>
          <Box width={[1/4, 1]} pr={3}>
            <DashboardModule>
              <h3 className='normal'>Abstracts</h3>
            </DashboardModule>
          </Box>
          
          <Box width={[1/4, 1]} pr={3}>
            <DashboardModule>
              <h3 className='normal'>Presentations</h3>
            </DashboardModule>
          </Box>
          
          <Box width={[1/4, 1]} pr={3}>
            <DashboardModule>
              <h3 className='normal'>Posters</h3>
            </DashboardModule>
          </Box>
          
          <Box width={[1/4, 1]}>
            <DashboardModule>
              <h3 className='normal'>Associations</h3>
            </DashboardModule>
          </Box>
        </Flex>
        
        <Flex align='items-stretch'>
          
          <Box width={[1/2, 3/4]} mr={3}>
            <Paper>
              <h3 className='normal fsl'>Tasks</h3>
            </Paper>
          </Box>
          
          <Box width={[1/2, 1/4]}>
            <Paper>
              <PdfStatsModule 
                totalPdfs={this.props.total_pdfs} 
                // relevant_pdfs={this.state.relevant_pdfs} 
                // relevant_abstracts={this.state.relevant_abstracts} 
                totalAbstracts={this.props.total_abstracts} 
                // activeTab={this.state.activeTab} 
                // meetingYears={this.props.meeting_years} 
                // handlePdfTabClick={this.handlePdfTabClick} 
              />
            </Paper>
          </Box>
          
        </Flex>
        
      </Fragment>
    )
  }
}
