import React from "react"
import PropTypes from "prop-types"
import Paper from "../../Paper"
import Box from "../../Box"
import { withSnackbar } from '../../SnackbarContainer'
// import IndexFilters from "./IndexFilters"
import ListingsCountDisplay from '../../ListingsCountDisplay'
import update from "immutability-helper"
import ApprovalsTable from "./ApprovalsTable"
import InlineSearchContainer from '../search/InlineSearchContainer'
import { Table, TableBody, TableCell, TableHead, TableRow } from "../../tables"
import Link from "../../Link"
import SectorFilter from "../../filters/Sector"


class ProductList extends React.Component {
  constructor(props) {
    super(props)
    this.handleSectorChange = this.handleSectorChange.bind(this)
    this.state = { products: this.props.products, productsCount: this.props.products_count, sector_id: this.props.sector_id }
  }

  handleSectorChange(name, value) {
    const sectorId = value
    const url = `/admin/device_approvals/by_product`
    
    $.ajax({
      url: url,
      data: {
        sector_id: sectorId
      },
      dataType: 'json',
      success: (data) => {
        this.setState({
          products: data.products,
          productsCount: data.products_count,
          sector_id: sectorId
        })
      },
      error: (xhr, status, err) => {
        this.props.snackbarShowMessage(`Error: ${err.toString()}`, 'error')
      }
    })
  }
  
  render() {
    return (
      <React.Fragment>
        <Paper fullHeight>
          <Box height="100%">

            <Box width={1/4}>
              <SectorFilter 
                sectors={this.props.sectors}
                sector_id={this.state.sector_id}
                handleSectorChange={this.handleSectorChange}
              />
            </Box>

            <ListingsCountDisplay 
              display={`${this.state.productsCount} products`}
            />

            <Box overflow="auto" height="80%">

              <Table stickyHeader size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Product</TableCell>
                    <TableCell># of Approvals</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {this.state.products.map((product) =>
                    <TableRow key={product.id}>
                      <TableCell>
                        <Link href={`/admin/products/${product.id}/approvals`} target="_blank">
                          {product.short_name}
                        </Link>
                      </TableCell>
                      <TableCell>{product.device_approvals_count}</TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>

            </Box>
          </Box>
        </Paper>
      </React.Fragment>
    );
  }
}

ProductList.propTypes = {
  products: PropTypes.array
};

export default withSnackbar(ProductList)
