import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Paper from "../../Paper"
import Form from "./Form"
import update from "immutability-helper"
import Snackbar from "../../Snackbar"
import Box from "../../Box"

export default class NewContainer extends Component {
  constructor(props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
    this.handleMeetingChange = this.handleMeetingChange.bind(this)
    this.handleSaveClick = this.handleSaveClick.bind(this)
    this.handleDateChange = this.handleDateChange.bind(this)
    this.handleNewMeetingChange = this.handleNewMeetingChange.bind(this)
    this.state = { meeting: this.props.science_meeting_occurrence, showSnackbar: false, snackbarText: '', meetingName: null }
  }

  componentDidMount() {
    const meeting = update(this.state.meeting, {
      created_by: { $set: this.props.current_user.id }
    })

    this.setState({
      meeting: meeting
    })
  }

  handleDateChange(name, date) {
    const meeting = update(this.state.meeting, {
      [name]: { $set: date }
    })

    this.setState({
      meeting: meeting
    })
  }

  handleNewMeetingChange(name, value) {
    this.setState({
      meetingName: value
    })
  }

  handleChange(name, value) {
    const meeting = update(this.state.meeting, {
      [name]: { $set: value }
    })

    this.setState({
      meeting: meeting
    })
  }

  handleMeetingChange(e, selected) {
    let meeting = update(this.state.meeting, {
      science_meeting_id: { $set: selected.value }
    })

    this.setState({
      meeting: meeting
    })
  }

  handleSaveClick(e) {
    const url = this.props.redirect_url ? `/admin/meetings?r=${this.props.redirect_url}` : "/admin/meetings"
    $.post(url, { science_meeting_occurrence: this.state.meeting, meeting_name: this.state.meetingName ? this.state.meetingName : null }, () => {
      window.location.href = url
    }, 'html').fail((data) => {
      this.setState({
        showSnackbar: true,
        snackbarText: `The meeting could not be created. ${data.responseText}.`
      })
    }).always(() => {
      setTimeout(() => {
        this.setState({
          showSnackbar: false,
          snackbarText: ''
        })
      }, 4000)
    })
  }

  render() {
    return(
      <Fragment>

        <Paper>

          <Box width={1/2}>
            <Form
              meeting={this.state.meeting}
              scienceMeetings={this.props.science_meetings}
              years={this.props.years}
              meetingName={this.state.meetingName}
              handleChange={this.handleChange}
              handleDateChange={this.handleDateChange}
              handleSaveClick={this.handleSaveClick}
              handleMeetingChange={this.handleMeetingChange}
              handleNewMeetingChange={this.handleNewMeetingChange}
            />
          </Box>

        </Paper>

        <Snackbar
          open={this.state.showSnackbar}
          message={this.state.snackbarText}
        />
      </Fragment>
    )
  }
}
