import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import NestedListItemLink from "../../lists/NestedListItemLink"
import ListItemLink from "../../lists/ListItemLink"
import { List, ListItem, ListItemText } from "../../lists";
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ExpandLess from '../../ExpandLess';
import ExpandMore from '../../ExpandMore';
import Collapse from '@material-ui/core/Collapse';

export default class NavigationList extends Component {
  constructor(props) {
    super(props)
    this.state = { open: false }
  }

  componentDidMount() {
    if (this.props.activeGroup == 'Publications') {
      this.setState({
        open: true
      })
    }
  }

  render() {
    return(
      <Fragment>

        <ListItem button onClick={() => this.setState({open: !this.state.open})}>
          <ListItemText primary="Publications" />
          {this.state.open ? <ExpandLess /> : <ExpandMore />}
        </ListItem>

        <Collapse in={this.state.open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>

            <NestedListItemLink href={`/admin/publications/dashboard`}>
              <ListItemText primary='Dashboard' className={this.props.activeItem == 'Dashboard' && this.props.activeGroup == 'Publications' ? 'orange' : ''} />
            </NestedListItemLink>

            <NestedListItemLink href={`/admin/publications`}>
              <ListItemText primary='List' className={this.props.activeItem == 'List' && this.props.activeGroup == 'Publications' ? 'orange' : ''} />
            </NestedListItemLink>

            <NestedListItemLink href={`/admin/publications/staged_associator`}>
              <ListItemText primary='Staged Associator' className={this.props.activeItem == 'Staged Associator' && this.props.activeGroup == 'Publications' ? 'orange' : ''} />
            </NestedListItemLink>

            <NestedListItemLink href={`/admin/publications/staged_reviewer`}>
              <ListItemText primary='Staged Reviewer' className={this.props.activeItem == 'Staged Reviewer' && this.props.activeGroup == 'Publications' ? 'orange' : ''} />
            </NestedListItemLink>

            <NestedListItemLink href={`/admin/publications/downloader`}>
              <ListItemText primary='Downloader' className={this.props.activeItem == 'Downloader' && this.props.activeGroup == 'Publications' ? 'orange' : ''} />
            </NestedListItemLink>

          </List>
        </Collapse>

      </Fragment>
    )
  }
}
