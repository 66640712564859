import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Paper from "../../Paper"
import Flex from "../../Flex"
import Box from "../../Box"
import TextInput from "../../TextInput"
import Select from "../../Select"
import ButtonLayout from "../ButtonLayout"
import { RaisedButton } from "../../buttons"
import update from "immutability-helper"
import Snackbar from "../../Snackbar"

export default class EditContainer extends Component {
  constructor(props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
    this.handleKindChange = this.handleKindChange.bind(this)
    this.handleSaveClick = this.handleSaveClick.bind(this)
    this.state = { profileFieldName: this.props.profile_field_name, snackbarText: '', showSnackbar: false }
  }
  
  UNSAFE_componentWillMount() {
    const profileFieldName = update(this.state.profileFieldName, { 
      updated_by: { $set: this.props.current_user.id }
    })
    
    this.setState({
      profileFieldName: profileFieldName
    })
  }
  
  handleKindChange(name, value) {
    const profileFieldName = update(this.state.profileFieldName, { [name]: { $set: value } })
    
    this.setState({
      profileFieldName: profileFieldName
    })
  }
  
  handleChange(e) {
    const name = e.currentTarget.name
    const value = e.currentTarget.value
    
    const profileFieldName = update(this.state.profileFieldName, { [name]: { $set: value }})
    this.setState({
      profileFieldName: profileFieldName
    })
  }
  
  handleSaveClick(e) {
    const url = `/admin/profile_field_names/${this.state.profileFieldName.id}`
    $.ajax({
      type: 'PUT',
      url: url,
      data: {
        profile_field_name: this.state.profileFieldName
      },
      dataType: "json",
      success: (data) => {
        this.setState({
          profileFieldName: data.profile_field_name,
          showSnackbar: true,
          snackbarText: 'Profile field name was updated'
        })
      },
      error: (data) => {
        this.setState({
          showSnackbar: true,
          snackbarText: `Profile field name could not be saved. ${data.responseText}`
        })
      },
      complete: (data) => {
        setTimeout(() => {
          this.setState({
            showSnackbar: false,
            snackbarText: ''
          })
        }, 4000)
      }
    })
  }
  
  render() {
    return(
      <Fragment>
        
        <Paper>
          
          <div className='mb3'>
            <Select 
              value={this.state.profileFieldName.kind} 
              name='kind'
              label='Kind'
              entities={this.props.kinds}
              displayEmpty={true}
              handleChange={this.handleKindChange} 
            />
          </div>
          
          <TextInput 
            name='name' 
            label='Name' 
            value={this.state.profileFieldName.name} 
            onChange={this.handleChange} 
            className='w-100' 
          />
          
          <ButtonLayout>
            <RaisedButton handleClick={this.handleSaveClick}>
              Save Profile Field Name
            </RaisedButton>
          </ButtonLayout>
          
        </Paper>
        
        <Snackbar 
          open={this.state.showSnackbar}
          message={this.state.snackbarText} 
        />
        
      </Fragment>
    )
  }
}
