import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Paper from "../../Paper"
import Flex from "../../Flex"
import Box from "../../Box"
import EntityCard from "../EntityCard"
import Highlighter from "react-highlight-words";
import Link from "../../Link"
import Typography from "@material-ui/core/Typography"
import FdaLink from "./FdaLink"

export default function PmaApprovalCard(props) {

  return(
    <Fragment>

      <Paper fullHeight={true}>

        <Box height="100%" overflow="auto">

          <Flex justifyContent="right">
            <Box>
              <FdaLink 
                pmaApproval={props.pmaApproval}
              />
            </Box>
          </Flex>

          <Flex flexDirection='column' mb={2}>
            <Box width={1} mb={1}>
              <label className='b'>PMA</label>
            </Box>
            <Box width={1}>
              {props.pmaApproval.formatted_pma_number || "N/A"}
            </Box>
          </Flex>

          <Flex flexDirection='column' mb={2}>
            <Box width={1} mb={1}>
              <label className='b'>Applicant</label>
            </Box>
            <Box width={1}>
              ${props.pmaApproval.applicant || "N/A"}
            </Box>
          </Flex>

          <Flex flexDirection='column' mb={2}>
            <Box width={1} mb={1}>
              <label className='b'>Product</label>
            </Box>
            <Box width={1}>
              {props.pmaApproval.trade_name || "N/A"}
            </Box>
          </Flex>

          <Flex flexDirection='column' mb={2}>
            <Box width={1} mb={1}>
              <label className='b'>Generic Name</label>
            </Box>
            <Box width={1}>
              {props.pmaApproval.generic_name || "N/A"}
            </Box>
          </Flex>

          <Flex flexDirection='column' mb={2}>
            <Box width={1} mb={1}>
              <label className='b'>Product Code</label>
            </Box>
            <Box width={1}>
              ${props.pmaApproval.product_code || "N/A"}
            </Box>
          </Flex>

          <Flex flexDirection='column' mb={2}>
            <Box width={1} mb={1}>
              <label className='b'>Reason</label>
            </Box>
            <Box width={1}>
              ${props.pmaApproval.supplement_reason || "N/A"}
            </Box>
          </Flex>

          <Flex flexDirection='column' mb={2}>
            <Box width={1} mb={1}>
              <label className='b'>Date Received</label>
            </Box>
            <Box width={1}>
              {props.pmaApproval.date_received || "N/A"}
            </Box>
          </Flex>

          <Flex flexDirection='column' mb={2}>
            <Box width={1} mb={1}>
              <label className='b'>Decision Date</label>
            </Box>
            <Box width={1}>
              {props.pmaApproval.decision_date || "N/A"}
            </Box>
          </Flex>

          <Flex flexDirection='column' mb={2}>
            <Box width={1} mb={1}>
              <label className='b'>AO Statement</label>
            </Box>
            <Box width={1}>
              {props.pmaApproval.ao_statement || "N/A"}
            </Box>
          </Flex>

          
        </Box>

      </Paper>

    </Fragment>
  )
}
