import React from "react"
import Flex from "../../Flex"
import Box from "../../Box"
import Select from "../../Select"
import Grid from '@material-ui/core/Grid';
import TextInput from "../../TextInput"
import InputAdornment from "@material-ui/core/InputAdornment"
import SearchIcon from '@material-ui/icons/Search'
import ClearIcon from '@material-ui/icons/Clear';
import IconButton from '@material-ui/core/IconButton';
import { RaisedButton } from "../../buttons"

export default function Filters(props) {
  return (
    <Flex justifyContent='center' alignItems='center'>
      <Box width={1/2}>
        <form onSubmit={props.handleSearchClick}>
          <TextInput
            name='query'
            placeholder='Search'
            size='small'
            value={props.filterValues.query ? props.filterValues.query : ''}
            onChange={props.handleChange}
            className='w-100'
            InputProps={{
              startAdornment: <InputAdornment position="start"><SearchIcon /></InputAdornment>,
              endAdornment: <InputAdornment position="end"><IconButton onClick={props.handleClearSearchClick} size='small'><ClearIcon fontSize='small' /></IconButton></InputAdornment>
            }}
          />
        </form>
      </Box>

      <Box ml={3}>
        <RaisedButton onClick={props.handleSearchClick}>
          Search
        </RaisedButton>
      </Box>
    </Flex>
  )
}
