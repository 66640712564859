import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import { TableRow, TableCell } from "../../tables"
import Link from "../../Link"
import IconButton from "../../IconButton"

export default class CharacteristicValueRow extends Component {
  constructor(props) {
    super(props)
    this.handleDeleteClick = this.handleDeleteClick.bind(this)
  }
  
  handleDeleteClick(e) {
    this.props.handleDeleteClick(this.props.profileFieldName)
  }
  
  render() {
    const editUrl = `/admin/profile_field_names/${this.props.profileFieldName.id}/edit`
    return(
      <Fragment>
        <TableRow>
          <TableCell>
            <Link href={editUrl}>
              {this.props.profileFieldName.name}
            </Link>
          </TableCell>
          <TableCell>
            {this.props.profileFieldName.kind}
          </TableCell>
          <TableCell>
            {this.props.profileFieldName.creator ? displayInitials(this.props.profileFieldName.creator.first_name, this.props.profileFieldName.creator.last_name) : 'N/A'}
          </TableCell>
          <TableCell>
            <IconButton icon='edit' isLink={true} href={editUrl} />
          </TableCell>
          <TableCell>
            <IconButton icon='delete' handleClick={this.handleDeleteClick} />
          </TableCell>
        </TableRow>
      </Fragment>
    )
  }
}
