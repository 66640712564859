import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Paper from "../../Paper"
import { List, ListItem, ListItemText } from "../../lists"
import Flex from "../../Flex"
import Box from "../../Box"
import Link from "../../Link"
import TextInput from "../../TextInput"
import ButtonLayout from "../ButtonLayout"
import RaisedButton from "../../buttons/RaisedButton"
import Typography from "@material-ui/core/Typography"

export default class Downloader extends Component {
  constructor(props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
    this.handleSearchClick = this.handleSearchClick.bind(this)
    this.handleDownloadClick = this.handleDownloadClick.bind(this)
    this.state = { query: '', trials: [], searched: null }
  }

  handleChange(e) {
    const name = e.target.name
    const value = e.target.value

    this.setState({
      query: value
    })
  }

  handleSearchClick(e) {
    $.ajax({
      type: 'GET',
      url: `/admin/ct_gov_trials/search`,
      data: {
        nct_id: this.state.query
      },
      dataType: 'json',
      success: (data) => {
        this.setState({
          trials: data.trials,
          searched: true
        })
      }
    })
  }

  handleDownloadClick(e) {
    $.ajax({
      type: 'get',
      url: '/admin/ct_gov_trials/download',
      data: {
        nct_id: this.state.query
      },
      dataType: 'json',
      success: (data) => {
        alert("Download is in process")
      }
    })
  }

  render() {
    return(
      <Fragment>

        <Paper fullHeight={true}>

          <Flex alignItems='center' justifyContent='center' mb={3}>
            <Box mr={2}>
              <TextInput
                name='search_query'
                label='Search'
                placeholder='Search by NCT ID'
                value={this.state.query}
                onChange={this.handleChange}
                className='w-100'
                margin='dense'
              />
            </Box>

            <Box>
              <RaisedButton handleClick={this.handleSearchClick}>
                Search
              </RaisedButton>
            </Box>
          </Flex>

          <Box>
            {this.state.query && this.state.searched && this.state.trials.length == 0
            ?
              <Flex justifyContent='center' height="50%">
                <Box alignSelf='center' maxWidth="50%">
                  <Box>
                    <Typography variant="body1">
                      No trial was found, if you want to download this trial, click the button below
                    </Typography>

                    <ButtonLayout>
                      <RaisedButton handleClick={this.handleDownloadClick}>
                        Download
                      </RaisedButton>
                    </ButtonLayout>
                  </Box>
                </Box>
              </Flex>
            :
              <Box>
                <Box>{`${this.state.trials.length} found`}</Box>

                <List className='w-100'>
                  {this.state.trials.map((trial) =>
                    <ListItem key={trial.id} button>
                      <ListItemText primary={trial.brief_title} />
                    </ListItem>
                  )}
                </List>
              </Box>
            }
          </Box>

        </Paper>

      </Fragment>
    )
  }
}
