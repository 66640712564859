import React from "react"
import Paper from "../../Paper"
import Box from "../../Box"
import Tabs from "../../tabs/Tabs"
import Tab from "../../tabs/Tab"
import TabPanel from "../../tabs/TabPanel"


export default function EntitiesPanel(props) {
  return (
    <React.Fragment>
      <Paper fullHeight>

        <Box height="100%">
          <Tabs variant="fullWidth" value={props.activeIndex} onChange={props.handleActiveIndexUpdate} className='bb b--black-10' aria-label="entities tabs">
            {props.predicted ? <Tab label='Predicted' value='predicted' /> : ""}
            {props.companies ? <Tab label='Companies' value='companies' /> : ""}
            {props.products ? <Tab label='Products' value='products' /> : ""}
            {props.trials ? <Tab label='Trials' value='trials' /> : ""}
            <Tab label='Associated' value='associated' />
          </Tabs>

          <Box mt={3} height="90%" overflow='auto'>
            <TabPanel value={props.activeIndex} index='predicted'>
              {props.predicted}
            </TabPanel>
            <TabPanel value={props.activeIndex} index='companies'>
              {props.companies}
            </TabPanel>
            <TabPanel value={props.activeIndex} index='products'>
              {props.products}
            </TabPanel>
            <TabPanel value={props.activeIndex} index='trials'>
              {props.trials}
            </TabPanel>
            <TabPanel value={props.activeIndex} index='associated'>
              {props.associated}
            </TabPanel>
          </Box>
        </Box>

      </Paper>
    </React.Fragment>
  )
}
