import React, { Fragment } from "react"
import PropTypes from "prop-types"
import AdminApp from "./admin/AdminApp"
import Box from "./Box"
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';

class MtPipelineAdmin extends React.Component {
  constructor(props) {
    super(props)
    this.handleDrawerClose = this.handleDrawerClose.bind(this)
    this.handleDrawerOpen = this.handleDrawerOpen.bind(this)
    this.handleSearchChange = this.handleSearchChange.bind(this)
    this.handleSearchSubmit = this.handleSearchSubmit.bind(this)
    this.state = { showSidebar: null, search: '' }
  }

  componentDidMount() {
    if (this.props.sidebarOpen == false) {
      this.setState({
        showSidebar: false
      })

      $('#content').addClass('ph3-important')
    } else {
      this.setState({
        showSidebar: isWidthUp('md', this.props.width) ? true : false
      })
    }
  }

  handleDrawerClose(e) {
    this.setState({
      showSidebar: false
    })
    $('#content').addClass('ph3-important')
  }

  handleDrawerOpen(e) {
    this.setState({
      showSidebar: true
    })
    $('#content').removeClass('ph3-important')
  }

  handleSearchChange(e) {
    console.log("hello")
    this.setState({
      search: e.target.value
    })
  }

  handleSearchSubmit(e) {
    e.preventDefault()
    window.location.href = `/admin/search?query=${this.state.search}`
  }

  render () {
    let height = this.props.tabs ? '134px' : '95px'
    return (
      <Fragment>

        <Box id='header-container' style={{minHeight: height}}>
          <AdminApp
            handleDrawerClose={this.handleDrawerClose}
            handleDrawerOpen={this.handleDrawerOpen}
            title={this.props.title}
            showSidebar={this.state.showSidebar}
            market={this.props.market}
            activeItem={this.props.active_item}
            activeGroup={this.props.active_group}
            tabs={this.props.tabs}
            search={this.state.search}
            sector={this.props.sector}
            activeTab={this.props.active_tab}
            sidebarOpen={this.props.sidebarOpen}
            handleSearchChange={this.handleSearchChange}
            handleSearchSubmit={this.handleSearchSubmit}
          />
        </Box>

      </Fragment>
    )
  }
}

MtPipelineAdmin.propTypes = {
  title: PropTypes.string
}

export default withWidth()(MtPipelineAdmin)
