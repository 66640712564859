import React, { Component, Fragment } from "react"
import PropTypes from "prop-types"
import ReactDOM from "react-dom"
import AlertGroupHeader from "./AlertGroupHeader"
import AlertDisplayContainer from "./AlertDisplayContainer"
import Typography from "@material-ui/core/Typography"
import Collapse from "@material-ui/core/Collapse"
import Box from "../Box"
import AlertDisplay from "./AlertDisplay"

export default class AlertGroupContainer extends Component {
  constructor(props) {
    super(props)
    this.handleClick = this.handleClick.bind(this)
    this.state = { open: false }
  }

  groupBy(list, props) {
    return list.reduce((a, b) => {
       (a[b[props]] = a[b[props]] || []).push(b);
       return a;
    }, {});
  }

  handleClick(e) {
    e.preventDefault()
    this.setState({
      open: !this.state.open
    })
  }

  renderAlerts() {
    if (this.props.alerts[0].trial_id) {
      return (<AlertDisplayContainer alerts={this.props.alerts} sector={this.props.sector} groupKey='trial' entityRoute='trials' entityId='trial_id' />)
    } else if (this.props.alerts[0].product_id) {
      return (<AlertDisplayContainer alerts={this.props.alerts} sector={this.props.sector} groupKey='product_name' entityRoute='products' entityId='product_id' />)
    } else if (this.props.alerts[0].company_id) {
      return (<AlertDisplayContainer alerts={this.props.alerts} sector={this.props.sector} groupKey='company_name' entityRoute='companies' entityId='company_id' />)
    } else if (this.props.alerts[0].abstract_id) {
      return (<AlertDisplayContainer alerts={this.props.alerts} sector={this.props.sector} groupKey='abstract_title' entityRoute='abstracts' entityId='abstract_id' />)
    } else {
      return (<AlertDisplayContainer alerts={this.props.alerts} sector={this.props.sector} />)
    }
  }

  render () {
    let groupedAlerts = this.groupBy(this.props.alerts, "header_id")
    return (
      <Fragment>

        <div className='bb b--black-10'>

          <AlertGroupHeader
            alerts={this.props.alerts}
            alertGroup={this.props.alertGroup}
            open={this.state.open}
            handleClick={this.handleClick}
          />

          <Collapse in={this.state.open} timeout="auto" unmountOnExit>
            <Box ml={3}>
              {Object.keys(groupedAlerts).map((headerId) =>
                <Box key={headerId} className='mb4'>
                  <Box mb={1}>
                    <Box>
                      <span
                        dangerouslySetInnerHTML={{__html: groupedAlerts[headerId][0].header_text }}
                      />
                    </Box>
                    {groupedAlerts[headerId][0].header_secondary ?
                      <Box>
                        <Box mt={1}>
                          <Typography variant="caption" component="span" color="textSecondary">
                            <span dangerouslySetInnerHTML={{__html: groupedAlerts[headerId][0].header_secondary }} />
                          </Typography>
                        </Box>
                      </Box>
                    : ""}
                  </Box>
                  {groupedAlerts[headerId].map((alert) =>
                    <AlertDisplay
                      key={alert.id}
                      alert={alert}
                    />
                  )}
                </Box>
              )}
            </Box>
          </Collapse>

        </div>

      </Fragment>
    );
  }
}
