import React, { Component } from "react"
import PropTypes from "prop-types"
import ReactDOM from "react-dom"
import Chart from "../charts/Chart"

class ActivityChart extends Component {
  constructor(props) {
    super(props)
  }

  setChartTitle() {
    let chartTitle = ""
    switch (this.props.chartEntityType) {
      case 'manuscript_person':
        chartTitle = "Journal Articles by Year"
        break;
      case 'trial_investigator':
        chartTitle = "Trials by Year"
        break;
      case 'abstract_person':
        chartTitle = "Meeting Presentations by Year"
        break;
    }
    return(chartTitle)
  }

  setYAxisTitle() {
    let yAxisTitle = ""
    switch (this.props.chartEntityType) {
      case 'manuscript_person':
        yAxisTitle = "# of Articles"
        break;
      case 'trial_investigator':
        yAxisTitle = "# of Trials"
        break;
      case 'abstract_person':
        yAxisTitle = "# of Abstracts"
        break;
    }
    return(yAxisTitle)
  }

  render () {
    let chartTitle = this.setChartTitle()
    let yAxisTitle = this.setYAxisTitle()
    console.log(this.props.chartData)
    return (
      <React.Fragment>
        <Chart
          key={this.props.chartData}
          chartId='activity-chart'
          chartOptions={{type: this.props.chartDisplayType.toLowerCase(), height: 300}}
          categories={this.props.dates}
          title={chartTitle}
          data={this.props.chartData}
          simple={true}
          yAxisTitle={yAxisTitle}
          legend={false}
        />
      </React.Fragment>
    );
  }
}

export default ActivityChart
