import React, { Component } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Paper from "../Paper"

export default class Module extends Component {
  render () {
    return (
      <Paper padding='pa3' className={this.props.className} elevation={1}>
        {this.props.children}
      </Paper>
    )
  }
}
