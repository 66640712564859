import React, { Component, Fragment } from "react"
import PropTypes from "prop-types"
import ReactDOM from "react-dom"
import { Table, TableHead, TableRow, TableCell, TableBody } from "../tables"
import TableHeadSort from "../tables/TableHeadSort"
import ProductRow from "./ProductRow"
import Checkbox from "../Checkbox"
import Box from "../Box"
import IndeterminateCheckBoxIcon from '@material-ui/icons/IndeterminateCheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import IconButton from "../IconButton"
import Typography from "@material-ui/core/Typography"
import Popover from '@material-ui/core/Popover';
import LinearProgress from '@material-ui/core/LinearProgress';

export default class ProductsTable extends Component {
  constructor(props) {
    super(props)
    this.handleRequestSort = this.handleRequestSort.bind(this)
    this.desc = this.desc.bind(this)
    this.stableSort = this.stableSort.bind(this)
    this.getSorting = this.getSorting.bind(this)
    this.state = { order: 'asc', orderBy: 'short_name' }
  }

  componentDidMount() {
    if (this.props.products) {
      this.setState({
        products: this.props.products
      })
    } else {
      $.ajax({
        type: 'GET',
        url: `/sectors/${this.props.sector.abbr}/products`,
        dataType: 'json',
        success: (data) => {
          this.setState({
            products: data
          })
        }
      })
    }
  }

  desc(a, b, orderBy) {
    console.log(orderBy)
    let bOrderKey = orderBy == 'short_name' || orderBy == 'company_name' ? b[orderBy].toLowerCase() : (orderBy == "last_updated_at" || orderBy == "updated_at" ? new Date(b[orderBy]) : b[orderBy])
    let aOrderKey = orderBy == 'short_name' || orderBy == 'company_name' ? a[orderBy].toLowerCase() : (orderBy == "last_updated_at" || orderBy == "updated_at" ? new Date(a[orderBy]) : a[orderBy])

    if (bOrderKey < aOrderKey) {
      return -1;
    }
    if (bOrderKey > aOrderKey) {
      return 1;
    }
    return 0;
  }

  stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = cmp(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
  }

  getSorting(order, orderBy) {
    return order === 'desc' ? (a, b) => this.desc(a, b, orderBy) : (a, b) => -this.desc(a, b, orderBy);
  }

  handleRequestSort(event, property) {
    const isDesc = this.state.orderBy === property && this.state.order === 'desc';

    this.setState({
      order: isDesc ? 'asc' : 'desc',
      orderBy: property
    })
  };

  render() {

    let headers = [{ label: <IconButton onClick={this.props.handleBulkProductCheck} tooltip='Select' edge="start">{this.props.checkedProducts.length > 0 ? <IndeterminateCheckBoxIcon style={{height: '22px', width: '22px'}} /> : <CheckBoxOutlineBlankIcon style={{height: '22px', width: '22px'}} />}</IconButton>, name: '', noSort: true, checkbox: true, id: 'product-checkbox' }, { label: 'Product', name: 'short_name' }, { label: 'Company', name: 'company_name' }, { label: 'Stage of Development', name: 'status_view_name', hide: (this.props.user.user_company_id == 1 ? false : true) }, { label: 'Last Activity', name: 'last_updated_at' }, { label: 'Trials', name: 'n_trials', align: 'right' }]

    return(
      <Box overflow='auto' height="80%">

        <Table size='small' aria-label='products table' stickyHeader={true} id='products-table'>

          <TableHeadSort
            headers={headers}
            order={this.state.order}
            orderBy={this.state.orderBy}
            onRequestSort={this.handleRequestSort}
          />

          <TableBody>
            <Fragment>
              {this.props.loading ?
                <TableRow hover={false}>
                  <TableCell noBorder colSpan={5}>
                    <LinearProgress />
                  </TableCell>
                </TableRow>
              : ""}
              {this.props.products.length > 0 ?
                this.stableSort(this.props.products, this.getSorting(this.state.order, this.state.orderBy))
                  .map((product) =>
                    <ProductRow
                      key={product.id}
                      product={product}
                      childProducts={this.props.groupedComponents ? this.props.groupedComponents[product.id] : null}
                      userProducts={this.props.userProducts}
                      sector={this.props.sector}
                      choose={this.props.choose}
                      checkedProducts={this.props.checkedProducts}
                      currentUser={this.props.currentUser}
                      chosenAttributes={this.props.chosenAttributes.map(chosenAttribute => chosenAttribute.label)}
                      handleProductCheckboxClick={this.props.handleProductCheckboxClick}
                      handleFavoriteClick={this.props.handleFavoriteClick}
                      user={this.props.user}
                    />
                )
              :
                <TableRow>
                  <TableCell colSpan={5}>
                    <Typography>
                      No products found. Try changing the filters.
                    </Typography>
                  </TableCell>
                </TableRow>
              }
            </Fragment>
          </TableBody>
        </Table>
      </Box>
    )
  }
}
