import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import ExpandLess from '../../ExpandLess';
import ExpandMore from '../../ExpandMore';
import Collapse from '@material-ui/core/Collapse';
import { List, ListItem, ListItemText } from "../../lists"
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';


export default class NestedDataForm extends Component {
  constructor(props) {
    super(props)
    this.handleActiveIndexUpdate = this.handleActiveIndexUpdate.bind(this)
  }

  handleActiveIndexUpdate(e) {
    this.props.handleActiveIndexUpdate(this.props.open ? null : this.props.index)
  }

  render() {
    return(
      <Fragment>

        <ListItem button onClick={this.handleActiveIndexUpdate} divider={true}>
          <ListItemIcon>
            <CheckCircleOutlineIcon className={this.props.completed ? 'green' : 'grey'} />
          </ListItemIcon>

          <ListItemText
            primary={this.props.attribute}
            className={this.props.open ? 'orange' : ''}
          />

          <ListItemSecondaryAction>
            {this.props.open ? <ExpandLess /> : <ExpandMore />}
          </ListItemSecondaryAction>
        </ListItem>

        <Collapse in={this.props.open} timeout="auto" unmountOnExit>
          {this.props.children}
        </Collapse>

      </Fragment>
    )
  }
}
