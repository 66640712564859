import React, { Fragment } from "react"
import Paper from "../../Paper"
import Box from "../../Box"
import { List, ListItem, ListItemText } from "../../lists"
import SectorCheckbox from "../sectors/SectorCheckbox"

export default function SectorCheckboxList(props) {

  return(
    <Fragment>

      <Paper mt={2}>

        <Box>
          <List>
            {props.sectors.map((sector) =>
              <SectorCheckbox
                key={sector.id}
                sector={sector}
                checked={props.entitySectors.length > 0 ? props.entitySectors.filter((entitySector) => entitySector.sector_id == sector.id)[0] ? true : false : false}
                predicted={props.predictedSectors ? props.predictedSectors.filter((predictedSector) => predictedSector.sector_id == sector.id)[0] : null}
                handleCheckboxSelect={props.handleSectorCheckboxSelect}
              />
            )}
          </List>
        </Box>

      </Paper>

    </Fragment>
  )
}
