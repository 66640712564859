import React from "react"
import MuiIconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

export default function IconButton(props) {
  const tooltipTitle = props.children ? (props.children.type.displayName ? props.children.type.displayName.replace('Icon', '') : '') : ''
  
  const { tooltip=tooltipTitle, ...rest } = props
  
  return (
    <Tooltip title={tooltip}>
      <MuiIconButton {...rest} />
    </Tooltip>
  )
}
