import React, {Component} from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Link from "../Link"
import {Table, TableHead, TableRow, TableCell} from "../tables"
import CompareFieldCell from "./CompareFieldCell"

class CompareFieldRow extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    return(
      <TableRow key={this.props.field}>
        <TableCell borderRight={true} paddingBottom='pb0'>
          {this.props.field}
        </TableCell>
        {this.props.entities.map((entity) =>
          <CompareFieldCell
            key={`${entity.id}-${this.props.field}`}
            entity={entity}
            fields={entity.fields}
            field={this.props.field}
            rootUrl={this.props.rootUrl}
            entitiesCount={this.props.entities.length}
            sector={this.props.sector}
          />
        )}
      </TableRow>
    )
  }
}

export default CompareFieldRow
