import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Expandable from "../Expandable"
import { List, ListItem, ListItemText } from "../lists";
import Paper from "../Paper"
import { ListItemLink } from "../lists"
import FundingEvent from "../funding/FundingEvent"
import Box from "../Box"

class Funding extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    return(
      <Fragment>
        <Paper minFullHeight>
          <div>
            {this.props.company.public ?
              <strong>This company is publicly held, and company data is available through its public disclosures.</strong>
              :
                this.props.funding_events.length > 0 ?
                  <div>
                    {/* <div>
                      <Expandable header='Investors Include'>
                        <List>
                          {this.props.investors.map((investor) =>
                            <ListItemLink tag='a' href={`/sectors/${this.props.sector.abbr}/investors/${investor.id}`}>
                              <ListItemText primaryText={investor.investor_name} />
                            </ListItemLink>
                          )}
                        </List>
                      </Expandable>
                    </div> */}
                    <Box>
                      {this.props.funding_events.map((fundingEvent) =>
                        <Box key={fundingEvent.id} mb={3}>
                          <FundingEvent
                            key={fundingEvent.id}
                            fundingEvent={fundingEvent}
                            sector={this.props.sector}
                          />
                        </Box>
                      )}
                    </Box>
                  </div>
                : <div>No funding events available</div>
              }
          </div>
        </Paper>
      </Fragment>
    )
  }
}

export default Funding
