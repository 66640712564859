import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Paper from "../../Paper"
import update from "immutability-helper"
import Snackbar from "../../Snackbar"
import TextInput from "../../TextInput"
import Button from "../../buttons/Button"
import Box from '@material-ui/core/Box';

export default class EditContainer extends Component {
  constructor(props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
    this.handleSaveClick = this.handleSaveClick.bind(this)
    this.state = { alertGroup: this.props.alert_group, showSnackbar: false, snackbarText: '' }
  }
  
  handleChange(e) {
    const alertGroup = update(this.state.alertGroup, {
      [e.target.name]: { $set: e.target.value },
      created_by: { $set: this.props.current_user.id }
    })
    
    this.setState({
      alertGroup: alertGroup
    })
  }
  
  handleSaveClick(e) {
    $.ajax({
      type: 'PUT',
      url: `/admin/alert_groups/${this.state.alertGroup.id}`,
      data: {
        alert_group: this.state.alertGroup
      },
      dataType: 'html',
      error: (data) => {
        this.setState({
          showSnackbar: true,
          snackbarText: 'Alert group could not be updated'
        })
      },
      complete: (data) => {
        setTimeout(() => {
          this.setState({
            showSnackbar: false,
            snackbarText: ''
          })
        }, 5000)
      }
    })
  }
  
  render() {
    return (
      <Fragment>
        
        <Paper>
          
          <TextInput 
            id='outlined-name'
            name='name' 
            label='Name' 
            value={this.state.alertGroup.name} 
            onChange={this.handleChange} 
            variant='outlined'
            margin='normal'
            fullWidth
          />
          
          <Box mt={4} mx="auto" width={[3/4, 1/2, 1/4]}>
            <Button variant='contained' fullWidth={true} onClick={this.handleSaveClick}>
              Save Alert Group
            </Button>
          </Box>
          
        </Paper>
        
        <Snackbar 
          open={this.state.showSnackbar}
          message={this.state.snackbarText} 
        /> 
      </Fragment>
    )
  }
}
