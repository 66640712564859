import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import { TableRow, TableCell } from "../../tables"
import Link from "../../Link"
import EditButton from "../EditButton"
import DeleteButton from "../DeleteButton"
import IconButton from "../../IconButton"
import TextInput from "../../TextInput"
import { Button, RaisedButton } from "../../buttons"
import ReorderIcon from "@material-ui/icons/Reorder"

class ProfileFieldNameRow extends Component {
  constructor(props) {
    super(props)
    this.handleNameChange = this.handleNameChange.bind(this)
    this.handleSaveClick = this.handleSaveClick.bind(this)
    this.handleEditClick = this.handleEditClick.bind(this)
    this.handleDeleteClick = this.handleDeleteClick.bind(this)
  }
  
  handleEditClick(e) {
    this.props.handleEditClick(this.props.profileFieldName)
  }
  
  handleSaveClick(e) {
    this.props.handleSaveClick(this.props.profileFieldName)
  }
  
  handleNameChange(e) {
    this.props.handleNameChange(this.props.profileFieldName, e.target.value)
  }
  
  handleDeleteClick(e) {
    this.props.handleDeleteClick(this.props.profileFieldName)
  }
  
  render() {
    const url = `/admin/product_profile_field_names/${this.props.profileFieldName.id}`
    return(
      <Fragment>
        <TableRow>
          <TableCell>
            <IconButton onClick={this.handleOrderClick}>
              <ReorderIcon />
            </IconButton>
          </TableCell>
          <TableCell>
            {this.props.editing ? 
              <TextInput dense={true} name='name' value={this.props.profileFieldName.name} onChange={this.handleNameChange} /> 
            : 
              <Link href={url}>{this.props.profileFieldName.name}</Link>
            }
          </TableCell>
          <TableCell className='tr'>
            {this.props.profileFieldName.field_order}
          </TableCell>
          <TableCell className='tr'>
            {this.props.editing ? 
              <RaisedButton dense={true} handleClick={this.handleSaveClick}>Save</RaisedButton>
            : 
              <EditButton onClick={this.handleEditClick} />
            }
          </TableCell>
          <TableCell>
            {this.props.editing ?
              <Button dense={true} handleClick={this.props.handleCancelClick}>Cancel</Button>
            :
              <DeleteButton onClick={this.handleDeleteClick} />
            }
          </TableCell>
        </TableRow>
      </Fragment>
    )
  }
}

export default ProfileFieldNameRow
