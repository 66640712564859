import React from "react"
import { TableRow, TableCell } from "../../tables"
import Link from "../../Link"

export default function ReviewerRow(props) {

  function handleDeleteClick(e) {
    props.handleDeleteClick(props.publicationAssociation)
  }

  const product = props.publication.product || null
  const trial = props.publication.trial || null

  return (
    <TableRow>
      <TableCell borderRight>
        <Link href={`/admin/publications/${props.publication.id}/reviewer`} target="_blank">
          {props.publication.pubmed_id}
        </Link>
      </TableCell>
      <TableCell borderRight>
        <Link href={`/admin/publications/${props.publication.id}/reviewer`} target="_blank">
          {props.publication.title}
        </Link>
      </TableCell>
      {/* <TableCell borderRight>{product ? product.short_name : ''}</TableCell> */}
      {/* <TableCell borderRight>{trial ? trial.short_name : ''}</TableCell> */}
      {/* <TableCell borderRight>
        {displayInitials(props.publication.first_name, props.publication.last_name)}
      </TableCell> */}
      <TableCell borderRight>
        {humanDateFormat(props.publication.published_date)}
      </TableCell>
      {/* <TableCell>
        <DeleteButton onClick={handleDeleteClick} />
      </TableCell> */}
    </TableRow>
  )
}
