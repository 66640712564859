import React from "react"
import Paper from "../../../Paper"
import Flex from "../../../Flex"
import Box from "../../../Box"
import update from "immutability-helper"
import { withSnackbar } from "../../../SnackbarContainer"
import { Tabs, Tab } from "../../../tabs"
import { RaisedButton } from "../../../buttons"
import Dialog from "../../../Dialog"
import SectorCheckboxList from "../../sectors/CheckboxList"
import ApprovalsTable from "./ApprovalsTable"


class Container extends React.Component {
  constructor(props) {
    super(props)
    this.handleDeleteClick = this.handleDeleteClick.bind(this)
    this.handleActiveIndexUpdate = this.handleActiveIndexUpdate.bind(this)
    this.handleAddPmaClick = this.handleAddPmaClick.bind(this)
    this.handleApprovalCheckboxClick = this.handleApprovalCheckboxClick.bind(this)
    this.handleAddAllClick = this.handleAddAllClick.bind(this)
    this.handleSectorClick = this.handleSectorClick.bind(this)
    this.handleAddClick = this.handleAddClick.bind(this)
    this.handleSectorDelete = this.handleSectorDelete.bind(this)
    this.handleAddAllPmaClick = this.handleAddAllPmaClick.bind(this)
    this.handleReviewedClick = this.handleReviewedClick.bind(this)
    this.state = { approvals: this.props.approvals, view: "associations", activeApproval: null, checkedApprovals: [], openSectorDialog: false, sectorIds: [] }
  }

  handleReviewedClick(e) {
    let ids = this.state.checkedApprovals.length > 0 ? this.state.checkedApprovals : this.state.approvals.map((pmaApproval) => pmaApproval.id)

    $.ajax({
      type: 'PUT',
      url: `/admin/product_fda_pma_approval_predictions/mark_as_reviewed`,
      data: {
        ids: ids, user_id: this.props.user.id, product_id: this.props.product.id
      },
      dataType: 'json',
      success: (data) => {
        this.setState({
          pmaPredictions: data.pma_predictions
        })
        this.props.snackbarShowMessage("Predictions have been marked as reviewed")
      },
      error: (data) => {
        this.props.snackbarShowMessage("Something went wrong marking predictions as reviewed. Please try again.", "error")
      }
    })
  }

  handleDeletePredictionClick(pmaApproval) {
    $.ajax({
      type: "PUT",
      url: `/admin/product_fda_pma_approval_predictions/${pmaApproval.product_fda_pma_approval_prediction_id}`,
      data: {
        product_fda_pma_approval_prediction: {
          deleted: true, deleted_by: this.props.user.id
        }
      },
      dataType: 'json',
      success: (data) => {
        let index = this.state.pmaPredictions.indexOf(pmaApproval)

        let pmaPredictions = update(this.state.pmaPredictions, {
          $splice: [[index, 1]]
        })

        this.setState({
          pmaPredictions: pmaPredictions
        })

        this.props.snackbarShowMessage("PMA prediction has been removed")
      },
      error: (data) => {
        this.props.snackbarShowMessage("The PMA prediction could not be removed. Refresh the page and try again.", "error")
      }
    })
  }

  handleSectorDelete(productFdaPmaApprovalSectorId) {
    $.ajax({
      type: "DELETE",
      url: `/admin/product_fda_pma_approval_sectors/${productFdaPmaApprovalSectorId}`,
      data: {
        with_approvals: true
      },
      dataType: 'json',
      success: (data) => {
        this.setState({
          pmaApproval: data.pma_approvals
        })

        this.props.snackbarShowMessage("Sector has been removed")
      },
      error: (data) => {
        this.props.snackbarShowMessage("Sector could not be removed", "error")
      }
    })
  }

  handleSectorClick(sector) {
    let index = this.state.sectorIds.indexOf(sector.id)

    let sectorIds = ""

    if (index == -1) {
      sectorIds = update(this.state.sectorIds, {
        $push: [sector.id]
      })
    } else {
      sectorIds = update(this.state.sectorIds, {
        $splice: [[index, 1]]
      })
    }

    this.setState({
      sectorIds: sectorIds
    })
  }

  handleAddAllPmaClick(e) {
    this.setState({
      openSectorDialog: true,
      checkedPmaApprovals: this.state.checkedPmaApprovals.length > 0 ? this.state.checkedPmaApprovals : this.state.pmaPredictions.map((pmaApproval) => pmaApproval.id)
    })
  }

  handleAddPmaClick(pmaApproval) {
    this.setState({
      openSectorDialog: true,
      activePma: pmaApproval
    })
  }

  handleActiveIndexUpdate(e, newValue) {
    this.setState({
      view: newValue
    })
  }

  handleApprovalCheckboxClick(approval) {

    let index = this.state.checkedApprovals.indexOf(approval.id)

    let approvalIds = ""
    if (index == -1) {
      approvalIds = update(this.state.checkedApprovals, {
        $push: [approval.id]
      })
    } else {
      approvalIds = update(this.state.checkedApprovals, {
        $splice: [[index, 1]]
      })
    }

    this.setState({
      checkedApprovals: approvalIds
    })
  }


  handleAddAllClick(e) {

    $.ajax({
      type: "POST",
      url: `/admin/product_fda_pma_approvals/bulk_add`,
      data: {
        product_id: this.props.product.id, fda_pma_approval_ids: this.state.checkedPmaApprovals, created_by: this.props.user.id, sector_ids: this.state.sectorIds, with_approvals: true, with_predictions: true
      },
      dataType: 'json',
      success: (data) => {
        this.setState({
          pmaApprovals: data.pma_approvals,
          pmaPredictions: data.pma_predictions,
          openSectorDialog: false,
          checkedPmaApprovals: []
        })

        this.props.snackbarShowMessage("PMA approval has been added")
      },
      error: (data) => {
        this.props.snackbarShowMessage("The PMA approval could not be added. Refresh the page and try again.", "error")
      }
    })
  }

  handleAddClick(e) {
    $.ajax({
      type: "POST",
      url: `/admin/product_fda_pma_approvals`,
      data: {
        product_fda_pma_approval: {
          product_id: this.props.product.id, fda_pma_approval_id: this.state.activePma.id, created_by: this.props.user.id, sector_ids: this.state.sectorIds
        }, with_approvals: true
      },
      dataType: 'json',
      success: (data) => {
        let index = this.state.pmaPredictions.indexOf(this.state.activePma)

        let pmaPredictions = update(this.state.pmaPredictions, {
          $splice: [[index, 1]]
        })

        this.setState({
          pmaApprovals: data.pma_approvals,
          pmaPredictions: pmaPredictions,
          openSectorDialog: false,
          activePma: null
        })

        this.props.snackbarShowMessage("PMA approval has been added")
      },
      error: (data) => {
        this.props.snackbarShowMessage("The PMA approval could not be added. Refresh the page and try again.", "error")
      }
    })
  }

  handleDeleteClick(artgApproval) {
    $.ajax({
      type: "PUT",
      url: `/admin/artg_approval_products/${artgApproval.artg_approval_product_id}`,
      data: {
        artg_approval_product: {
          deleted: true, deleted_by: this.props.user.id
        }, with_approvals: true
      },
      dataType: 'json',
      success: (data) => {

        this.setState({
          approvals: data.artg_approvals
        })

        this.props.snackbarShowMessage("ARTG approval has been removed from this product")
      },
      error: (data) => {
        this.props.snackbarShowMessage("The ARTG approval product association could not be removed. Refresh the page and try again.", "error")
      }
    })
  }

  handleReviewedClick(artgApproval) {
    $.ajax({
      type: "PUT",
      url: `/admin/artg_approvals/${artgApproval.id}`,
      data: {
        artg_approval: {
          reviewed: true, reviewed_by: this.props.user.id
        }
      },
      dataType: 'json',
      success: (data) => {
        let artgApprovals = update(this.state.artgApprovals, {
          [artgApproval.id]: { [0]: { $merge: { reviewed: true }} }
        })

        this.setState({
          artgApprovals: artgApprovals
        })

        this.props.snackbarShowMessage("Approval has been marked as reviewed")
      },
      error: (data) => {
        this.props.snackbarShowMessage("The approval could not be marked as reviewed. Refresh the page and try again.", "error")
      }
    })
  }

  render() {
    return(
      <React.Fragment>

        {this.state.openSectorDialog ?
          <Dialog
            openDialog={this.state.openSectorDialog}
            dialogTitle={`Add Sectors for ${this.state.checkedApprovals.length > 0 ? `${this.state.checkedApprovals.length} Approvals` : 'N/A'}`}
            handleAddClick={this.state.checkedApprovals.length > 0 ? this.handleAddAllClick : this.handleAddClick}
            handleClose={this.handleCloseDialog}
            dialogContent={
              <SectorCheckboxList
                sectors={this.props.sectors}
                sectorIds={this.state.sectorIds}
                suggestedSectors={this.props.product_sectors}
                handleCheckboxSelect={this.handleSectorClick}
              />
            }
          />
        : ''}

        <Paper fullHeight>

          <Box height="100%">

            {/* <Box mb={2}>
              <Tabs variant="fullWidth" value={this.state.view} onChange={this.handleActiveIndexUpdate} className='bb b--black-10' aria-label="pma tabs">
                <Tab label='Associations' value='associations' />
                <Tab label='Predictions' value='predictions' />
              </Tabs>
            </Box> */}

            {this.state.view == "predictions" ? 
              <Flex mb={2}>
                <Box width={1/4}>
                  <RaisedButton onClick={this.handleAddAllPmaClick}>
                    Add all
                  </RaisedButton>
                </Box>

                <Box width={1/4} ml={3}>
                  <RaisedButton color="default" onClick={this.handleReviewedClick}>
                    Mark as reviewed
                  </RaisedButton>
                </Box>
              </Flex>
            : ""}

            <Box overflow="auto" height="90%">
              <ApprovalsTable 
                approvals={this.state.approvals}
                checkedApprovals={this.state.checkedApprovals}
                handleDeleteClick={this.handleDeleteClick}
                handleSectorDelete={this.handleSectorDelete}
                handleApprovalCheckboxClick={this.handleApprovalCheckboxClick}
                handleReviewedClick={this.handleReviewedClick}
              />
            </Box>
          </Box>
        </Paper>
      </React.Fragment>
    )
  }
}


export default withSnackbar(Container)
