import React, { Component, Fragment } from "react"
import PropTypes from "prop-types"
import ReactDOM from "react-dom"
import Paper from "../Paper"
import Chart from "../charts/Chart"
import Timeline from "../charts/Timeline"
import TimelineChartContainer from "./TimelineChartContainer"
import { Tabs, Tab, TabPanel } from "../tabs"
import Box from "../Box"
import TimelineDataTable from "./TimelineDataTable"
import Select from "../Select"
import EnrollmentProfile from "./EnrollmentProfile"

export default class TimelineContainer extends Component {
  constructor(props) {
    super(props)
    this.handleTabClick = this.handleTabClick.bind(this)
    this.handleFilterChange = this.handleFilterChange.bind(this)
    this.state = { activeIndex: 0, chartData: this.props.chart_data, attribute: 'all' }
  }

  componentDidMount() {
    if (this.props.attribute) {
      this.setState({
        attribute: this.props.attribute
      })
    }
  }

  handleTabClick(e, newValue) {
    this.setState({
      activeIndex: newValue
    })
  }

  handleFilterChange(name, value) {
    $.ajax({
      type: 'GET',
      url: `/sectors/${this.props.sector.abbr}/trials/${this.props.trial.id}/timeline`,
      data: {
        attribute: value
      },
      dataType: 'json',
      success: (data) => {
        this.setState({
          chartData: data.chart_data,
          attribute: value
        })
      }
    })
  }

  render() {
    const chartReloadKey = Math.random()
    return (
      <Fragment>

        <Paper>

          <Box width="25%" mr={2}>
            <Select
              label='Attributes'
              name='attribute'
              value={this.state.attribute}
              entities={this.props.attributes}
              handleChange={this.handleFilterChange}
            />
          </Box>


          <TimelineChartContainer
            key={`timeline-attribute-chart-${chartReloadKey}`}
            title={this.state.attribute == 'all' ? 'Trial timeline' : `${humanizeString(this.state.attribute)} timeline`}
            chartData={this.state.chartData}
            currentTime={this.props.current_time}
          />

          <Box width={1} textAlign='center' mt={2}>
            <Box mb={4}>
              <Tabs centered value={this.state.activeIndex} onChange={this.handleTabClick} aria-label="timeline tabs" className='bb b--black-10'>
                <Tab label='Data' />
              </Tabs>
            </Box>


            <TabPanel value={this.state.activeIndex} index={0}>
              <TimelineDataTable
                chartData={this.state.chartData}
              />
            </TabPanel>


          </Box>

        </Paper>
      </Fragment>
    );
  }
}
