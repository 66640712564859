import React, { Fragment, Component } from "react"
import Paper from "../../Paper"
import Flex from "../../Flex"
import Box from "../../Box"
import { Table, TableRow, TableHead, TableCell, TableBody } from "../../tables"
import Typography from "@material-ui/core/Typography"
import update from "immutability-helper"
import TrialProgressRow from "./TrialProgressRow"

export default function TrialProgressTable(props) {
  return (
    <Fragment>
      <Typography variant="h6">
        Trial Progress
      </Typography>

      <Table>
        <TableHead className='bg-white'>
          <TableRow>
            <TableCell>Trial</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>First Received</TableCell>
            <TableCell>Completion Date</TableCell>
            <TableCell>Duration</TableCell>
            <TableCell>
              Average
            </TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {props.regulatoryTrials.map((regulatoryTrial) =>
            <TrialProgressRow
              key={regulatoryTrial.id}
              regulatoryTrial={regulatoryTrial}
              averageIdeDuration={props.averageIdeDuration}
              trialsForAverage={props.trialsForAverage}
              sector={props.sector}
            />
          )}
        </TableBody>
      </Table>
    </Fragment>
  )
}
