import React from "react"
import Flex from "../Flex"
import Box from "../Box"
import { ListItem, ListItemText } from "../lists"
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction"
import TweetLink from "./TweetLink"
import AccountLink from "./AccountLink"
import Typography from "@material-ui/core/Typography"
import DeleteButton from "../admin/DeleteButton"


export default function Tweet(props) {

  function handleDeleteClick(e) {
    props.handleDeleteClick(props.tweet)
  }

  return(
    <ListItem divider>
      <ListItemText>
        <Flex pb={0.5} alignItems='end'>
          <Box mr={2}>
            <Typography variant="body2">
              <AccountLink
                display={props.tweet.account_name}
                accountUserName={props.tweet.account_user_name}
              />
            </Typography>
          </Box>

          <Box mr={2}>
            <Typography variant="caption" color="textSecondary">
              {`@${props.tweet.account_user_name}`}
            </Typography>
          </Box>

        </Flex>

        <Box pb={0.5} width={1/2}>
          <Typography>
            <TweetLink
              tweet={props.tweet}
            />
          </Typography>
        </Box>

        <Box>
          <Typography variant="caption">
            {humanDateFormat(props.tweet.date)}
          </Typography>
        </Box>
      </ListItemText>

      {props.handleDeleteClick ?
        <ListItemSecondaryAction>
          <DeleteButton
            onClick={handleDeleteClick}
          />
        </ListItemSecondaryAction>
      : ""}
    </ListItem>
  )
}
