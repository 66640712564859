import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Flex from "../../Flex"
import Box from "../../Box"
import TextInput from "../../TextInput"
import Select from "../../Select"
import Switch from "../../Switch";
import ButtonLayout from "../ButtonLayout"
import RaisedButton from "../../buttons/RaisedButton"
import FormControlLabel from '@material-ui/core/FormControlLabel';

export default class ProfileForm extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    return(
      <Fragment>
        <Box mb={4}>

          <TextInput
            name='full_name'
            label='Full Name'
            value={this.props.product.full_name}
            onChange={this.props.handleChange}
            className='w-100'
          />
        </Box>

        <Box mb={4}>
          <TextInput
            name='short_name'
            label='Short Name'
            value={this.props.product.short_name}
            onChange={this.props.handleChange}
            className='w-100'
          />
        </Box>

        <Box mb={4}>
          <TextInput
            name='root_name'
            label='Root Name'
            value={this.props.product.root_name}
            onChange={this.props.handleChange}
            className='w-100'
          />
        </Box>

        <Box mb={4}>
          <TextInput
            name='den_number'
            label='DEN number'
            value={this.props.product.den_number}
            onChange={this.props.handleChange}
            className='w-100'
          />
        </Box>

        <Box mb={4}>
          <Select
            name='company_id'
            value={this.props.product.company_id || ''}
            label='Company'
            entities={this.props.companies}
            handleChange={this.props.handleCompanyChange}
          />
        </Box>

        <Box mb={4}>
          <TextInput
            name='url'
            label='Company Product Page URL'
            value={this.props.product.url}
            onChange={this.props.handleChange}
            className='w-100'
          />
        </Box>

        <Box mb={4}>
          <TextInput
            name='device_image_url'
            label='Device Image URL'
            value={this.props.product.device_image_url}
            onChange={this.props.handleChange}
            className='w-100'
          />
        </Box>

        <Flex justifyContent='space-between'>
          <Box mb={2}>
            <Flex>
              <Box>
                <FormControlLabel
                  control={
                    <Switch
                      checked={this.props.product.off_market}
                      handleChange={this.props.handleOffMarketChange}
                      value={this.props.product.off_market}
                    />
                  }
                  label='Off Market'
                />
              </Box>

              {this.props.product.off_market ? 
                <Box>
                  <input
                    type="date"
                    onChange={this.props.handleOffMarketDateChange}
                    value={this.props.product.off_market_date}
                  />
                </Box>
              : ""}
            </Flex>
          </Box>

          <Box>
            <FormControlLabel
              control={
                <Switch
                  checked={this.props.product.archived}
                  handleChange={this.props.handleArchivedChange}
                  value={this.props.product.archived}
                />
              }
              label='Archived'
            />
          </Box>

          <Box>
            <FormControlLabel
              control={
                <Switch
                  checked={this.props.product.deleted}
                  handleChange={this.props.handleDeletedChange}
                  value={this.props.product.deleted}
                />
              }
              label='Deleted'
            />
          </Box>
        </Flex>

        <ButtonLayout>
          <RaisedButton handleClick={this.props.handleSaveClick}>
            Save Product
          </RaisedButton>
        </ButtonLayout>

      </Fragment>
    )
  }
}
