import React, { Component } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Button from "./Button"

class ButtonContainer extends Component {
  constructor(props) {
    super(props)
  }
  
  render() {
    return(
      <Button raised className={this.props.className}>{this.props.text}</Button>
    )
  }
}

export default ButtonContainer
