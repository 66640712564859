import React, { Fragment, useState } from "react"
import Paper from "../Paper"
import { Table, TableHead, TableCell, TableRow, TableHeadSort, TableBody } from "../tables"
import Flex from "../Flex"
import Box from "../Box"
import Link from "../Link"

export default function AdverseEvents(props) {
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('date_received')


  function desc(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = cmp(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
  }

  function getSorting(order, orderBy) {
    return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
  }

  function handleRequestSort(event, property) {
    const isDesc = orderBy === property && order === 'desc';

    setOrder(isDesc ? 'asc' : 'desc')
    setOrderBy(property)
  };

  // let headers = [{ label: 'Product', name: 'short_name' }]

  let headers = [{ label: 'Report number', name: 'report_number' }, { label: 'Type', name: '' }, { label: 'Event Type', name: 'event_type' }, { label: 'Device problem', name: 'device_problem'}, { label: 'Date Received', name: 'date_received' }]

  return (
    <Fragment>
      {props.adverseEvents.length > 0 ?
        <Box textAlign='right'>
          {`${props.adverseEvents.length} ${props.adverseEvents.length > 1 ? 'adverse events' : 'adverse event'}`}
        </Box>
      : ''}

      <Box height="85%" overflow='auto'>
        <Table size='small' aria-label='product adverse events table' stickyHeader={true} id='product-adverse-events-table'>
          <TableHeadSort
            headers={headers}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
          />

          <TableBody>
            {stableSort(props.adverseEvents, getSorting(order, orderBy)).map((adverseEvent) =>
              <TableRow key={adverseEvent.id}>
                <TableCell>
                  <Link href={`https://www.accessdata.fda.gov/scripts/cdrh/cfdocs/cfMAUDE/detail.cfm?mdrfoi__id=${adverseEvent.mdr_report_key}`} target="_blank">
                    <Box>
                      {adverseEvent.report_number}
                    </Box>
                  </Link>
                </TableCell>
                <TableCell>
                  {adverseEvent.adverse_event_flag == 'Y' ? 'Adverse event' : 'Product problem'}
                </TableCell>
                <TableCell>
                  {adverseEvent.event_type}
                </TableCell>
                <TableCell>
                  {adverseEvent.device_problem}
                </TableCell>
                <TableCell>
                  {adverseEvent.date_received}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </Box>
    </Fragment>
  )
}
