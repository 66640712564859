import React, { Component, Fragment } from "react"
import CKEditor from 'ckeditor4-react';
import Box from "../../Box"
import Radio from '@material-ui/core/Radio';
import RadioGroup from "../../RadioGroup"
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Flex from "../../Flex"
import { RaisedButton } from "../../buttons"


export default class EndpointForm extends Component {
  constructor(props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
    this.handleEndpointSaveClick = this.handleEndpointSaveClick.bind(this)
    this.handleEndpointChange = this.handleEndpointChange.bind(this)
    this.state = { endpoint_name: 'primary_outcome', endpoint: '' }
  }

  componentDidMount() {
    if (this.props.trialPrimaryEndpoint) {

      let trialEndpoint = this.props.trialPrimaryEndpoint ? this.props.trialPrimaryEndpoint.text : null

      this.setState({
        endpoint: trialEndpoint
      })
    }
  }

  handleEndpointChange(e) {
    const text = e.editor.getData()

    this.setState({
      endpoint: text
    })
  }

  handleEndpointSaveClick(e) {
    this.props.handleEndpointSaveClick(this.state.endpoint_name, this.state.endpoint)
  }

  handleChange(e) {
    let value = e.target.value

    let endpoint = ""
    if (value == 'primary_outcome') {
      endpoint = this.props.trialPrimaryEndpoint ? this.props.trialPrimaryEndpoint.text : null
    } else {
      endpoint = this.props.trialSecondaryEndpoint ? this.props.trialSecondaryEndpoint.text : null
    }

    this.setState({
      [e.target.name]: e.target.value,
      endpoint: endpoint
    })
  }

  render() {
    return(
      <Fragment>
        <Box mt={2} mb={6}>

          <Flex justifyContent='center' mb={2}>
            <RadioGroup aria-label="endpoint-type" name="endpoint_name" value={this.state.endpoint_name} onChange={this.handleChange}>
              <FormControlLabel value='primary_outcome' control={<Radio />} label="Primary Endpoint" />
              <FormControlLabel value='secondary_outcome' control={<Radio />} label="Secondary Endpoint" />
            </RadioGroup>
          </Flex>

          <Box mb={2}>
            <CKEditor
              data={this.state.endpoint}
              onChange={this.handleEndpointChange}
            />
          </Box>

          <Box mt={5}>
            <RaisedButton handleClick={this.handleEndpointSaveClick}>
              {`Save ${this.state.endpoint_name.replace("_", " ")}`}
            </RaisedButton>
          </Box>
        </Box>
      </Fragment>
    )
  }
}
