import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import Flex from "../../Flex"
import Box from "../../Box"
import Paper from "../../Paper"
import Select from "../../Select"
import { RaisedButton } from "../../buttons"
import { withSnackbar } from "../../SnackbarContainer"
import { Table, TableHead, TableRow, TableCell, TableBody } from "../../tables"
import update from "immutability-helper"
import ButtonLayout from "../ButtonLayout"
import InlineProductForm from "../products/InlineAddForm"
import Link from "../../Link"

class NewContainer extends Component {
  constructor(props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
    this.handleMultipleChange = this.handleMultipleChange.bind(this)
    this.handleCreateClick = this.handleCreateClick.bind(this)
    this.handleHideClick = this.handleHideClick.bind(this)
    this.handleProductAddition = this.handleProductAddition.bind(this)
    this.state = { types: [], sectors: [], status_id: this.props.status ? this.props.status.id : '', trialProductsAttributes: [], showSnackbar: false, snackbarText: '' }
  }

  handleChange(name, value) {
    this.setState({
      [name]: value
    })
  }

  handleMultipleChange(name, value) {
    const index = this.state[name].indexOf(value)
    const activeEntities = index == -1 ? update(this.state[name], { $set: value }) : update(this.state[name], { $splice: [[index, 1]] })

    this.setState({
      [name]: activeEntities
    })
  }

  handleProductAddition(productId, investigatedDevice) {

    let trialProductsAttributes = ""
    let index = this.state.trialProductsAttributes.indexOf({ product_id: productId, investigated_device: investigatedDevice, created_by: this.props.user.id })

    if (index == -1) {
      trialProductsAttributes = update(this.state.trialProductsAttributes, {
        $push: [{ product_id: productId, investigated_device: investigatedDevice, created_by: this.props.user.id }]
      })
    } else {
      trialProductsAttributes = update(this.state.trialProductsAttributes, {
        $splice: [[index, 1]]
      })
    }


    this.setState({
      trialProductsAttributes: trialProductsAttributes
    })
  }

  handleHideClick(e) {
    $.ajax({
      type: 'PUT',
      url: `/admin/ictrp_trials/${this.props.trial.id}`,
      data: {
        ictrp_trial: {
          relevant: false
        }
      },
      dataType: 'json',
      success: (data) => {
        this.props.snackbarShowMessage("The trial has been hidden")
      }
    })
  }

  handleCreateClick(e) {
    if (this.state.type_id == '') {
      this.setState({
        showSnackbar: true,
        snackbarText: 'You have to add a type to create the trial'
      })

      setTimeout(() => {
        this.setState({
          showSnackbar: false
        })
      }, 5000)
    } else if (this.state.sector_id == '') {
      this.setState({
        showSnackbar: true,
        snackbarText: 'You have to add a sector to create the trial'
      })

      setTimeout(() => {
        this.setState({
          showSnackbar: false
        })
      }, 5000)
    } else {
      $.post("/admin/trials/from_ictrp", { trial: { url: this.props.trial.url, registration_date: this.props.trial.date_registration, source_id: this.props.source.id, user_id: this.props.user.id, regulatory_id: this.props.trial.regulatory_id }, ictrp_trial_id: this.props.trial.id, types: this.state.types, sectors: this.state.sectors, trial_products_attributes: this.state.trialProductsAttributes }, (data) => {
        let url = (this.props.redirect_url ? this.props.redirect_url : `/admin/trials/${data.trial.id}/edit`)
        window.location.href = url
      }, 'json')
    }
  }

  render() {
    return(
      <Fragment>

        <Paper>

          <Table>
            <TableHead>
              <TableRow hover={false}>
                <TableCell borderRight={true}>
                  <Link href={this.props.trial.url} target="_blank">
                    View trial
                  </Link>
                </TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              <TableRow>
                <TableCell borderRight={true}>
                  Regulatory ID
                </TableCell>
                <TableCell>
                  {this.props.trial.regulatory_id}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell borderRight={true}>
                  Full Name
                </TableCell>
                <TableCell>
                  {this.props.trial.scientific_title}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell borderRight={true}>
                  Name
                </TableCell>
                <TableCell>
                  {this.props.trial.public_title}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell borderRight={true}>
                  Predicted Products
                </TableCell>
                <TableCell>
                  {this.props.products ?
                    this.props.products.map((product) =>
                      <InlineProductForm
                        key={product.id}
                        product={product}
                        trialProductsAttributes={this.state.trialProductsAttributes}
                        handleProductAddition={this.handleProductAddition}
                      />
                    ) : "N/A"}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell borderRight={true}>
                  Type
                </TableCell>
                <TableCell>
                  <Box width={1/4}>
                    <Select
                      multiple
                      name='types'
                      entities={this.props.clinical_types}
                      label='Type'
                      value={this.state.types}
                      handleChange={this.handleMultipleChange}
                      className='w-50'
                      renderValue={selected => (
                        <Flex flexWrap='wrap'>
                          {this.props.clinical_types.filter(clinicalTypeHash => selected.includes(clinicalTypeHash.value)).map(clinicalTypeHash => clinicalTypeHash.name).join("; ")}
                        </Flex>
                      )}
                    />
                  </Box>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell borderRight={true}>
                  Sector
                </TableCell>
                <TableCell>
                  <Flex alignItems='center'>
                    <Box mr={3}>
                      {this.props.predicted_sectors.map((sector) =>
                        <div key={sector.id}>
                          {sector.abbr}
                        </div>
                      )}
                    </Box>

                    <Box width={1/4}>
                      <Select
                        name='sectors'
                        multiple
                        entities={this.props.sectors}
                        label='Sector'
                        value={this.state.sectors}
                        handleChange={this.handleMultipleChange}
                        className='w-50'
                        renderValue={selected => (
                          <Flex flexWrap='wrap'>
                            {this.props.sectors.filter(sectorHash => selected.includes(sectorHash.value)).map(sectorHash => sectorHash.name).join("; ")}
                          </Flex>
                        )}
                      />
                    </Box>
                  </Flex>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell borderRight={true}>
                  Status
                </TableCell>
                <TableCell>
                  <Flex alignItems='center'>
                    <Box mr={3}>
                      {this.props.trial.recruitment_status}
                    </Box>

                    <Box width={1/4}>
                      <Select
                        name='status_id'
                        entities={this.props.clinical_statuses}
                        label='Status'
                        value={this.state.status_id}
                        handleChange={this.handleChange}
                        className='w-50'
                      />
                    </Box>
                  </Flex>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell borderRight={true}>
                  Sponsor
                </TableCell>
                <TableCell>
                  {this.props.trial.primary_sponsor}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell borderRight={true}>
                  Secondary Sponsors
                </TableCell>
                <TableCell>
                  {this.props.trial.secondary_sponsors}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell borderRight={true}>
                  Source Support
                </TableCell>
                <TableCell>
                  {this.props.trial.source_support}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell borderRight={true}>
                  Conditions
                </TableCell>
                <TableCell>
                  {this.props.trial.conditions}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell borderRight={true}>
                  Interventions
                </TableCell>
                <TableCell>
                  {this.props.trial.interventions}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell borderRight={true}>
                  Inclusion Criteria
                </TableCell>
                <TableCell className='pre-line'>
                  {this.props.trial.inclusion_criteria}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell borderRight={true}>
                  Exclusion Criteria
                </TableCell>
                <TableCell className='pre-line'>
                  {this.props.trial.exclusion_criteria}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell borderRight={true}>
                  Primary Endpoints
                </TableCell>
                <TableCell className='pre-line'>
                  {this.props.trial.primary_outcome}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell borderRight={true}>
                  Secondary Endpoints
                </TableCell>
                <TableCell className='pre-line'>
                  {this.props.trial.secondary_outcomes}
                </TableCell>
              </TableRow>


              <TableRow>
                <TableCell borderRight={true}>
                  Countries
                </TableCell>
                <TableCell className='pre-line'>
                  {this.props.trial.countries}
                </TableCell>
              </TableRow>

            </TableBody>
          </Table>

          <ButtonLayout>
            <Flex justifyContent="space-between">
              <Box width={1/2}>
                <RaisedButton handleClick={this.handleCreateClick}>
                  Create Trial
                </RaisedButton>
              </Box>
              <Box width={1/3}>
                <RaisedButton color="default" handleClick={this.handleHideClick}>
                  Hide Trial
                </RaisedButton>
              </Box>
            </Flex>
          </ButtonLayout>

        </Paper>

      </Fragment>
    )
  }
}

export default withSnackbar(NewContainer)