import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Flex from "../../Flex"
import Box from "../../Box"
import { Tabs, Tab } from "../../tabs"
import { Table, TableCell, TableRow, TableBody, TableHead, EmptyDataTableRow, TableHeadSort } from "../../tables"
import Paper from "../../Paper"
import ReviewerRow from "./ReviewerRow"
import Snackbar from "../../Snackbar"
import update from "immutability-helper"
import Typography from "@material-ui/core/Typography"
import ReviewerFilters from "./ReviewerFilters"

export default class StagedReviewer extends Component {
  constructor(props) {
    super(props)
    this.handleDeleteClick = this.handleDeleteClick.bind(this)
    this.handleUndoClick = this.handleUndoClick.bind(this)
    this.handleRequestSort = this.handleRequestSort.bind(this)
    this.desc = this.desc.bind(this)
    this.stableSort = this.stableSort.bind(this)
    this.getSorting = this.getSorting.bind(this)
    this.handleFilterChange = this.handleFilterChange.bind(this)
    this.handleDateChange = this.handleDateChange.bind(this)
    this.getPatents = this.getPatents.bind(this)
    this.handleNotRelevantClick = this.handleNotRelevantClick.bind(this)
    this.handleSectorDeleteClick = this.handleSectorDeleteClick.bind(this)
    this.state = { patents: this.props.patents, sector_id: this.props.sector_id, date: this.props.date, kind: this.props.patent_kind, review_type: this.props.review_type, order: 'desc', orderBy: 'date', lastDeleted: null, showSnackbar: false, snackbarText: '' }
  }

  handleSectorDeleteClick(patent) {
    $.ajax({
      type: 'PUT',
      url: `/admin/patent_sector_predictions/${patent.patent_sector_prediction_id}`,
      data: {
        patent_sector_prediction: {
          deleted: true, deleted_by: this.props.current_user.id
        }
      },
      dataType: 'json',
      success: (data) => {
        let index = this.state.patents.indexOf(patent)

        let patents = update(this.state.patents, {
          $splice: [[index, 1]]
        })

        this.setState({
          patents: patents
        })
      }
    })
  }

  handleFilterChange(name, value) {
    let filters = { date: this.state.date, sector_id: this.state.sector_id, kind: this.state.kind, review_type: this.props.review_type }

    filters[name] = value

    this.getPatents(filters)
  }

  handleDateChange(date) {
    let filters = { date: this.state.date, sector_id: this.state.sectorId, kind: this.state.kind, review_type: this.props.review_type }

    filters['date'] = date

    this.getPatents(filters)
  }

  getPatents(filters) {
    $.ajax({
      type: 'GET',
      url: `/admin/patents/staged_reviewer`,
      data: filters,
      dataType: 'json',
      success: (data) => {
        console.log(data)
        this.setState({
          patents: data.patents,
          sector_id: filters.sector_id,
          date: filters.date,
          kind: filters.kind,
          review_type: filters.review_type
        })
      },
      error: (data) => {
        console.log(data)
        this.setState({
          showSnackbar: true,
          snackbarText: 'There was an error fetching the patents'
        })
      },
      complete: (data) => {
        console.log(data)
        setTimeout(() => {
          this.setState({
            showSnackbar: false,
            snackbarText: ''
          })
        }, 4000)
      }
    })
  }

  handleUndoClick(e) {
    if (this.state.lastDeleted) {
      $.ajax({
        type: 'PUT',
        url: `/admin/patent_companies/${this.state.lastDeleted.patent_company_id}`,
        data: {
          patent_company: {
            deleted: null, deleted_by: null
          }
        },
        dataType: 'json',
        success: (data) => {
          const patents = update(this.state.patents, {
            $push: [this.state.lastDeleted]
          })
          this.setState({
            patents: patents,
            showSnackbar: true,
            snackbarText: 'Patent association was undeleted',
            lastDeleted: null
          })
        },
        error: (data) => {
          this.setState({
            showSnackbar: true,
            snackbarText: data.responseText
          })
        },
        completed: (data) => {
          setTimeout(() => {
            this.setState({
              showSnackbar: false,
              snackbarText: ''
            })
          }, 4000)
        }
      })
    }
  }

  handleNotRelevantClick(patent) {
    $.ajax({
      type: 'PUT',
      url: `/admin/patents/${patent.patent_id}`,
      data: {
        patent: {
          relevant: false
        }
      },
      dataType: 'json',
      success: (data) => {
        const index = this.state.patents.indexOf(patent)

        const patents = update(this.state.patents, {
          $splice: [[index,1]]
        })

        this.setState({
          patents: patents,
          showSnackbar: true,
          snackbarText: `Patent has been marked as not relevant`,
          lastDeleted: patent
        })
      },
      complete: (data) => {
        setTimeout(() => {
          this.setState({
            showSnackbar: false,
            snackbarText: ''
          })
        }, 4000)
      }
    })
  }

  handleDeleteClick(patent) {
    $.ajax({
      type: 'PUT',
      url: `/admin/patent_companies/${patent.patent_company_id}`,
      data: {
        patent_company: {
          deleted: true, deleted_by: this.props.current_user.id
        }
      },
      dataType: 'json',
      success: (data) => {
        const index = this.state.patents.indexOf(patent)
        const patents = update(this.state.patents, {
          $splice: [[index, 1]]
        })

        this.setState({
          patents: patents,
          showSnackbar: true,
          snackbarText: 'Patent has been unassociated',
          lastDeleted: patent
        })
      },
      error: (data) => {
        this.setState({
          showSnackbar: true,
          snackbarText: `Something went wrong. ${data.responseText}`
        })
      },
      complete: (data) => {
        setTimeout(() => {
          this.setState({
            showSnackbar: false,
            snackbarText: ''
          })
        }, 4000)
      }
    })
  }

  desc(a, b, orderBy) {
    let bOrderKey = orderBy == 'short_name' || orderBy == 'company_name' ? b[orderBy].toLowerCase() : b[orderBy]
    let aOrderKey = orderBy == 'short_name' || orderBy == 'company_name' ? a[orderBy].toLowerCase() : a[orderBy]
    if (bOrderKey < aOrderKey) {
      return -1;
    }
    if (bOrderKey > aOrderKey) {
      return 1;
    }
    return 0;
  }

  stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = cmp(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
  }

  getSorting(order, orderBy) {
    return order === 'desc' ? (a, b) => this.desc(a, b, orderBy) : (a, b) => -this.desc(a, b, orderBy);
  }

  handleRequestSort(event, property) {
    const isDesc = this.state.orderBy === property && this.state.order === 'desc';

    this.setState({
      order: isDesc ? 'asc' : 'desc',
      orderBy: property
    })
  };

  render() {
    // { name: '', label: 'Delete Association', borderRight: true }
    let headers = [{ name: 'title', label: 'Title', borderRight: true }, { name: 'company_name', label: 'Company', borderRight: true }, { name: 'assignee', label: 'Assignee', borderRight: true }, { name: 'applicant', label: 'Applicant', borderRight: true }, { name: 'created_at', label: 'Date', borderRight: true }, { label: 'Sector', value: 'sector_name' }, { name: '', label: 'Hide', borderRight: true }]
    return(
      <Fragment>

        <Paper fullHeight>

          <Box height="100%">

            <Box mb={3}>
              <ReviewerFilters
                patentKinds={this.props.patent_kinds}
                kind={this.state.kind}
                date={this.state.date}
                sector_id={this.state.sector_id}
                sectors={this.props.sectors}
                review_type={this.state.review_type}
                handleFilterChange={this.handleFilterChange}
                handleDateChange={this.handleDateChange}
              />
            </Box>

            <Box textAlign='right'>
              <Typography variant="caption">
                {`${this.state.patents.length} patents`}
              </Typography>
            </Box>

            <Box overflow='auto' height="80%">
              <Table size='small' aria-label='patents table' stickyHeader={true} id='patents-table'>
                <TableHeadSort
                  headers={headers}
                  order={this.state.order}
                  orderBy={this.state.orderBy}
                  onRequestSort={this.handleRequestSort}
                />

                <TableBody>
                  {this.state.patents.length > 0 ?
                    this.stableSort(this.state.patents, this.getSorting(this.state.order, this.state.orderBy)).map((patent) =>
                    <ReviewerRow
                      key={patent.patent_id}
                      patent={patent}
                      handleRelevantClick={this.handleRelevantClick}
                      handleDeleteClick={this.handleDeleteClick}
                      handleSectorDeleteClick={this.handleSectorDeleteClick}
                      handleNotRelevantClick={this.handleNotRelevantClick}
                    />
                  ) : <EmptyDataTableRow colSpan={9} message='No patents to review' />}
                </TableBody>
              </Table>
            </Box>
          </Box>

        </Paper>

        <Snackbar
          open={this.state.showSnackbar}
          handleUndoClick={this.handleUndoClick}
          handleClose={this.handleClose}
          message={this.state.snackbarText}
        />
      </Fragment>
    )
  }
}
