import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import TextInput from "../../TextInput"
import { RaisedButton } from "../../buttons"
import Box from "../../Box"
import ButtonLayout from "../ButtonLayout"


export default class StatusForm extends Component {
  constructor(props) {
    super(props)
  }
  
  render() {
    return(
      <Fragment>
        
        <Box mb={4}>
          <TextInput 
            label='Status'
            name='name'
            value={this.props.status.name}
            onChange={this.props.handleChange}
            fullWidth
          />
        </Box>
        
        <ButtonLayout>
          <RaisedButton handleClick={this.props.handleSaveClick}>
            Save Status
          </RaisedButton>
        </ButtonLayout>
        
      </Fragment>
    )
  }
}
