import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Paper from "../Paper"
import Flex from "../Flex"
import Box from "../Box"
import IconButton from "../IconButton"
import { Button, RaisedButton } from "../buttons"
import Dialog from "../Dialog"
import ProductsTableContainer from "./ProductsTableContainer"
import update from 'immutability-helper'
import CompareAttributeChip from "../CompareAttributeChip"
import CompareEntitiesContainer from "../compare/CompareEntitiesContainer"
import EmptyMessage from "../compare/EmptyMessage"
import Typography from "@material-ui/core/Typography"
import Snackbar from "../Snackbar"
import { withRouter } from "react-router-dom";
import Tabs from "../tabs/Tabs"
import Tab from "../tabs/Tab"
import ComponentFilter from "./ComponentFilter"

class CompareContainer extends Component {
  constructor(props) {
    super(props)
    this.handleAddProductClick = this.handleAddProductClick.bind(this)
    this.handleProductCheckboxClick = this.handleProductCheckboxClick.bind(this)
    this.handleBulkProductCheck = this.handleBulkProductCheck.bind(this)
    this.handleChipSelection = this.handleChipSelection.bind(this)
    this.handleTuneClick = this.handleTuneClick.bind(this)
    this.handleAddClick = this.handleAddClick.bind(this)
    this.handleCloseDialog = this.handleCloseDialog.bind(this)
    this.handleProductRemovalClick = this.handleProductRemovalClick.bind(this)
    this.handleFilterChange = this.handleFilterChange.bind(this)
    this.getCompareData = this.getCompareData.bind(this)
    this.state = { openDialog: false, products: this.props.products, fields: this.props.all_fields, showChips: false, newProducts: [], attributeOptions: this.props.attribute_options, worldRegions: this.props.world_regions, filterValues: { world_region_id: this.props.world_region_id, attribute_status_type: this.props.attribute_status_type }, showSnackbar: false, snackbarText: '' }
  }

  handleProductRemovalClick(product) {
    let index = this.state.products.indexOf(product)

    let products = update(this.state.products, {
      $splice: [[index, 1]]
    })

    let newProductIndex = this.state.newProducts.indexOf(product.id)

    let newProducts = update(this.state.newProducts, {
      $splice: [[newProductIndex, 1]]
    })

    let url = this.props.product ? `/sectors/${this.props.sector.abbr}/products/${this.props.product.id}/compare` : `/sectors/${this.props.sector.abbr}/products/compare_products`

    let params = this.state.filterValues

    params['product_ids'] = products.map((product) => product.id)

    let filterKeys = Object.keys(params)

    let queryParams = filterKeys.map((filterKey) =>
      filterKey == 'product_ids' ? params[filterKey].map((filterValue) => `${filterKey}[]=${filterValue}`) : `${filterKey}=${params[filterKey]}`
    )

    let flattenedQueryParams = [].concat.apply([], queryParams);

    this.props.history.push(`${url}?${flattenedQueryParams.join("&")}`);


    this.setState({
      products: products,
      newProducts: newProducts
    })
  }

  handleProductCheckboxClick(productId) {
    let products = update(this.state.newProducts, {
      $push: [productId]
    })

    this.setState({
      newProducts: products
    })
  }

  handleBulkProductCheck(productIds) {
    this.setState({
      newProducts: productIds
    })
  }

  handleCloseDialog(e) {
    this.setState({
      openDialog: false
    })
  }

  handleAddClick(e) {
    let productIds = this.state.products.map((product) => product.id)
    let allProductIds = [...productIds, ...this.state.newProducts]
    let productId = this.props.product ? this.props.product.id : null
    $.ajax({
      type: 'GET',
      url: `/sectors/${this.props.sector.abbr}/products/products_for_compare`,
      data: {
        product_ids: allProductIds, product_id: productId, world_region_id: this.state.filterValues.world_region_id, attribute_status_type: this.state.filterValues.attribute_status_type
      },
      dataType: 'json',
      success: (data) => {
        let filterValues = this.state.filterValues
        if (this.state.filterValues.world_region_id == null) {
          filterValues = update(this.state.filterValues, {
            world_region_id: { $set: data.world_region_id }
          })
        }

        let url = this.props.product ? `/sectors/${this.props.sector.abbr}/products/${this.props.product.id}/compare` : `/sectors/${this.props.sector.abbr}/products/compare_products`

        let params = this.state.filterValues

        params['product_ids'] = allProductIds

        let filterKeys = Object.keys(params)

        let queryParams = filterKeys.map((filterKey) =>
          filterKey == 'product_ids' ? params[filterKey].map((filterValue) => `${filterKey}[]=${filterValue}`) : `${filterKey}=${params[filterKey]}`
        )

        let flattenedQueryParams = [].concat.apply([], queryParams);

        this.props.history.push(`${url}?${flattenedQueryParams.join("&")}`);

        this.setState({
          products: data.products,
          allFields: data.all_fields,
          fields: data.all_fields,
          worldRegions: data.world_regions,
          filterValues: filterValues,
          attributeOptions: data.attribute_options,
          openDialog: false
        })
      },
      error: (data) => {
        this.setState({
          showSnackbar: true,
          snackbarText: "An error has occurred. Please try again."
        })
      },
      complete: (data) => {
        setTimeout(() => {
          this.setState({
            showSnackbar: false,
            snackbarText: ''
          })
        }, 4000)
      }
    })
  }

  handleAddProductClick(e) {
    e.preventDefault()
    this.setState({
      openDialog: true
    })
  }

  handleTuneClick(e) {
    e.preventDefault()
    this.setState({
      showChips: !this.state.showChips
    })
  }

  handleChipSelection(name, value) {
    this.setState({
      fields: value
    })
  }

  handleFilterChange(name, value) {
    let params = Object.assign({}, this.state.filterValues)

    params[name] = value

    let productIds = this.state.products.map((product) => product.id)
    params['product_ids'] = productIds
    // this.state.componentId ? params['child_product_id'] = this.state.componentId : ''

    this.getCompareData(params)
  }

  getCompareData(params) {
    let url = this.props.product ? `/sectors/${this.props.sector.abbr}/products/${this.props.product.id}/compare` : `/sectors/${this.props.sector.abbr}/products/compare_products`

    $.ajax({
      type: 'GET',
      url: url,
      data: params,
      dataType: 'json',
      success: (data) => {

        let filterKeys = Object.keys(params)

        let queryParams = filterKeys.map((filterKey) =>
          filterKey == 'product_ids' ? params[filterKey].map((filterValue) => `${filterKey}[]=${filterValue}`) : `${filterKey}=${params[filterKey]}`
        )

        let flattenedQueryParams = [].concat.apply([], queryParams);

        this.props.history.push(`${url}?${flattenedQueryParams.join("&")}`);

        this.setState({
          filterValues: params,
          fields: data.fields,
          products: data.products,
          attributeOptions: data.attribute_options
        })
      },
      error: (data) => {
        this.setState({
          showSnackbar: true,
          snackbarText: "An error has occurred. Please try again."
        })
      },
      complete: (data) => {
        setTimeout(() => {
          this.setState({
            showSnackbar: false,
            snackbarText: ''
          })
        }, 4000)
      }
    })
  }


  render() {
    return(
      <React.Fragment>

        {this.state.openDialog ?
          <Dialog
            openDialog={this.state.openDialog}
            dialogTitle='Choose Products'
            handleAddClick={this.handleAddClick}
            handleClose={this.handleCloseDialog}
            dialogContent={
              <ProductsTableContainer
                sector={this.props.sector}
                choose={true}
                products={this.props.all_products}
                onlySystems={this.props.only_systems}
                onlyComponents={this.props.only_components}
                groupedComponents={this.props.grouped_components}
                chosenAttributes={this.props.attributes}
                checkedProducts={this.state.newProducts}
                userProducts={[]}
                user={this.props.user}
                handleProductCheckboxClick={this.handleProductCheckboxClick}
                handleBulkProductCheck={this.handleBulkProductCheck}
              />
            }
          />
        : ''}

        <Paper fullHeight={true}>
          <Box height="100%">

            <Box height="100%">
              {this.state.products.length > 0 || this.props.product ?
                <CompareEntitiesContainer
                  entities={this.state.products}
                  activeEntity={this.props.product ? this.props.product : null}
                  allFields={this.props.all_fields}
                  attributeOptions={this.state.attributeOptions}
                  fields={this.state.fields}
                  sector={this.props.sector}
                  handleAddEntityClick={this.handleAddProductClick}
                  handleEntityRemovalClick={this.handleProductRemovalClick}
                  entityType='products'
                  rootUrl={this.props.product ? `/sectors/${this.props.sector.abbr}/products/${this.props.product.id}/compare` : `/sectors/${this.props.sector.abbr}/products/compare_products`}
                  showChips={this.state.showChips}
                  worldRegions={this.state.worldRegions}
                  attributeStatusTypes={this.props.attribute_status_types}
                  filterValues={this.state.filterValues}
                  handleFilterChange={this.handleFilterChange}
                  handleChipSelection={this.handleChipSelection}
                />
              :
                <Flex justifyContent='center' height="50%">
                  <Box alignSelf='center' maxWidth="50%">
                    <Box textAlign='center'>
                      <Typography variant="h6" component="h6">
                        Start comparing by clicking the button below and adding products
                      </Typography>

                      <Typography variant="body1">
                        <Box>
                          You can also compare by going through the product list tab,
                        </Box>
                        <Box>
                          choosing the products you wish to compare and clicking the compare icon
                        </Box>
                      </Typography>

                      <Box mt={4} mx="auto" width={1/3}>
                        <RaisedButton onClick={this.handleAddProductClick}>
                          Add Products
                        </RaisedButton>
                      </Box>
                    </Box>
                  </Box>
                </Flex>
              }
            </Box>
          </Box>
        </Paper>

        <Snackbar
          open={this.state.showSnackbar}
          message={this.state.snackbarText}
        />
      </React.Fragment>
    )
  }
}


export default withRouter(CompareContainer)
