import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import List from "../../lists/List";
import NestedListItemLink from "../../lists/NestedListItemLink"
import ListItemLink from "../../lists/ListItemLink"
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ExpandLess from '../../ExpandLess';
import ExpandMore from '../../ExpandMore';
import Collapse from '@material-ui/core/Collapse';


export default class NavigationList extends Component {
  constructor(props) {
    super(props)
    this.state = { open: false }
  }

  componentDidMount() {
    if (this.props.activeGroup == 'FDA') {
      this.setState({
        open: true
      })
    }
  }

  render() {
    console.log(this.props.activeItem)
    return(
      <Fragment>

        <ListItem button onClick={() => this.setState({open: !this.state.open})}>
          <ListItemText primary="FDA" />
          {this.state.open ? <ExpandLess /> : <ExpandMore />}
        </ListItem>

        <Collapse in={this.state.open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>

            <NestedListItemLink href={`/admin/fda_k_clearances`}>
              <ListItemText primary='510(k)' className={this.props.activeItem == '510(k)' && this.props.activeGroup == 'FDA' ? 'orange' : ''} />
            </NestedListItemLink>

            <NestedListItemLink href={`/admin/adverse_events`}>
              <ListItemText primary='Adverse Events' className={this.props.activeItem == 'Adverse Events' && this.props.activeGroup == 'FDA' ? 'orange' : ''} />
            </NestedListItemLink>

            <NestedListItemLink href={`/admin/pma_approvals`}>
              <ListItemText primary='PMA Approvals' className={this.props.activeItem == 'PMA Approvals' && this.props.activeGroup == 'FDA' ? 'orange' : ''} />
            </NestedListItemLink>

            <NestedListItemLink href={`/admin/fda_recalls`}>
              <ListItemText primary='Recalls' className={this.props.activeItem == 'FDA Recalls' && this.props.activeGroup == 'FDA' ? 'orange' : ''} />
            </NestedListItemLink>


          </List>
        </Collapse>

      </Fragment>
    )
  }
}
