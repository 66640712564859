import React from "react"
import Chip from '@material-ui/core/Chip';
import DoneIcon from "@material-ui/icons/Done"

export default function ProductAttributeChip(props) {
  let selected = props.chosenAttributes.includes(props.attribute)

  return (
    <Chip 
      label={props.attribute} 
      deleteIcon={selected ? <DoneIcon /> : ''}
      onDelete={selected ? props.handleChipSelection : ''}
      onClick={props.handleChipSelection} 
    />
  )
}
