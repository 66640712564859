import React, { Component, Fragment } from "react"
import ProductsList from "../ProductsList"
import ControlPanel from "../../associator/ControlPanel"
import update from "immutability-helper"
import Associator from "../../Associator"
import EntitiesPanel from "../../associator/EntitiesPanel"
import AssociatedList from "../../associator/AssociatedList"
import KClearancePanel from "./Panel"
import { withSnackbar } from "../../../SnackbarContainer"


class AssociatorContainer extends Component {
  constructor(props) {
    super(props)
    this.handleActiveIndexUpdate = this.handleActiveIndexUpdate.bind(this)
    this.handleCheckboxSelect = this.handleCheckboxSelect.bind(this)
    this.handleAssociateClick = this.handleAssociateClick.bind(this)
    this.handleProductDeleteClick = this.handleProductDeleteClick.bind(this)
    this.handleSectorCheckboxSelect = this.handleSectorCheckboxSelect.bind(this)
    this.handleProductSearchChange = this.handleProductSearchChange.bind(this)
    this.handleProductSearchClick = this.handleProductSearchClick.bind(this)
    this.state = { fdaKClearance: this.props.fda_k_clearance, productFdaKClearances: this.props.product_fda_k_clearance, activeIndex: 'associated', activeProducts: [], associationLoading: false, searchedProducts: '', productQuery: '' }
  }

  handleActiveIndexUpdate(e, newValue) {
    this.setState({
      activeIndex: newValue
    })
  }

  handleProductDeleteClick(productFdaKClearance) {
    $.ajax({
      type: "PUT",
      url: `/admin/product_fda_k_clearances/${productFdaKClearance.id}`,
      data: {
        product_fda_k_clearance: {
          deleted: true, deleted_by: this.props.current_user.id
        }
      },
      dataType: 'json',
      success: (data) => {
        let index = this.state.productFdaKClearances.indexOf(productFdaKClearance)

        let productFdaKClearances = update(this.state.productFdaKClearances, {
          $splice: [[index, 1]]
        })
        this.setState({
          productFdaKClearances: productFdaKClearances
        })

        this.props.snackbarShowMessage("Product has been removed")
      },
      error: (data) => {
        this.props.snackbarShowMessage('There was an error removing the product', "error")
      }
    })
  }


  handleCheckboxSelect(entity, entityType) {
    const index = this.state[entityType].indexOf(entity.id)

    const activeEntities = index == -1 ? update(this.state[entityType], { $set: [entity.id] }) : update(this.state[entityType], { $splice: [[index, 1]] })

    this.setState({
      [entityType]: activeEntities
    })
  }

  handleAssociateClick(e) {
    $.post(`/admin/product_fda_k_clearances`, { k_number: this.props.fda_k_clearance.k_number, product_id: this.state.activeProducts[0] }, (data) => {

      let productFdaKClearances = update(this.state.productFdaKClearances, {
        $push: [{id: data.product_fda_k_clearance.id, short_name: data.product.short_name }]
      })

      console.log(productFdaKClearances)

      this.setState({
        productFdaKClearances: productFdaKClearances
      })
      this.props.snackbarShowMessage("Product was associated with 510(k) record")
    }, 'json').fail((data) => {
      this.props.snackbarShowMessage("Product could not be associated. Please try again.", "error")
    })
  }

  handleSectorCheckboxSelect(sector, checked) {
    let newsItemUrl = this.props.news_type == 'news_article' ? 'news_article_sectors' : 'press_release_sectors'

    let modelNameId = this.props.news_type == 'news_article' ? 'news_article_id' : 'press_release_id'

    if (checked) {
      let newsItemSector = this.state.newsItemSectors.filter(newsItemSector => newsItemSector.sector_id == sector.id)[0]
      $.ajax({
        type: 'PUT',
        url: `/admin/${newsItemUrl}/${newsItemSector.id}`,
        data: {
          [newsItemUrl.replace(/s$/, "")]: {
            deleted: true, deleted_by: 1
          }
        },
        dataType: 'JSON',
        success: (data) => {
          let index = this.state.newsItemSectors.indexOf(sector)

          let newsItemSectors = update(this.state.newsItemSectors, {
            $splice: [[index, 1]]
          })

          this.setState({
            newsItemSectors: newsItemSectors,
            showSnackbar: true,
            snackbarText: 'The sector was removed'
          })
        },
        error: (data) => {
          this.setState({
            showSnackbar: true,
            snackbarText: 'The sector could not be removed'
          })
        },
        complete: (data) => {
          setTimeout(() => {
            this.setState({
              showSnackbar: false,
              snackbarText: ''
            })
          }, 4000)
        }
      })
    } else {
      $.ajax({
        type: 'POST',
        url: `/admin/${newsItemUrl}`,
        data: {
          [newsItemUrl.replace(/s$/, "")]: {
            [modelNameId]: this.state.newsItem.id, sector_id: sector.id, created_by: this.props.current_user.id
          }
        },
        dataType: 'json',
        success: (data) => {
          let newsItemSectors = update(this.state.newsItemSectors, {
            $push: [data.news_item_sector]
          })

          this.setState({
            newsItemSectors: newsItemSectors,
            showSnackbar: true,
            snackbarText: 'The sector was added'
          })
        },
        error: (data) => {
          this.setState({
            showSnackbar: true,
            snackbarText: 'The sector could not be added'
          })
        },
        complete: (data) => {
          setTimeout(() => {
            this.setState({
              showSnackbar: false,
              snackbarText: ''
            })
          }, 4000)
        }
      })
    }
  }

  handleProductSearchChange(e) {
    let query = e.target.value

    this.setState({
      productQuery: query
    })
  }

  handleProductSearchClick(e) {
    $.ajax({
      type: 'GET',
      url: `/admin/products/search`,
      data: {
        query: this.state.productQuery
      },
      dataType: 'json',
      success: (data) => {
        this.setState({
          searchedProducts: data.products
        })
      }
    })
  }

  render() {

    return(
      <Fragment>

        <Associator
          loading={this.state.loading}
          firstPanel={
            <KClearancePanel
              fdaKClearance={this.props.fda_k_clearance}
            />
          }
          secondPanel={
            <ControlPanel
              handleAssociateClick={this.handleAssociateClick}
            />
          }
          thirdPanel={
            <EntitiesPanel
              activeIndex={this.state.activeIndex}
              handleActiveIndexUpdate={this.handleActiveIndexUpdate}
              products={
                <ProductsList
                  activeProducts={this.state.activeProducts}
                  products={this.props.products}
                  searchedProducts={this.state.searchedProducts}
                  productQuery={this.state.productQuery}
                  handleProductSearchChange={this.handleProductSearchChange}
                  handleProductSearchClick={this.handleProductSearchClick}
                  handleCheckboxSelect={this.handleCheckboxSelect}
                />
              }
              associated={
                <AssociatedList
                  entityProducts={this.state.productFdaKClearances}
                  handleProductDeleteClick={this.handleProductDeleteClick}
                />
              }
            />
          }
          associationLoading={this.state.associationLoading}
        />
      </Fragment>
    )
  }
}


export default withSnackbar(AssociatorContainer)
