import React from "react"
import { TableRow, TableCell } from "../../tables"
import Link from "../../Link"
import DeleteButton from "../DeleteButton"
import Box from "../../Box"
import { Button } from "../../buttons"

export default function RecallRow(props) {

  function handleDeleteClick(e) {
    props.handleDeleteClick(props.recall)
  }

  function handleAssociateClick(e) {
    props.handleAssociateClick(props.recall)
  }

  function handlePredictionDeleteClick(e) {
    props.handlePredictionDeleteClick(props.recall)
  }

  return(
    <TableRow>
      <TableCell>
        
      </TableCell>
      <TableCell className='w-30'>
        <Link href={`/admin/bfarm_recalls/${props.recall.id}/associator`} target="_blank">
          <Box>
            {props.recall.title}
          </Box>
        </Link>
      </TableCell>
      <TableCell>
        {props.recall.description}
      </TableCell>
      <TableCell>
        {props.recall.date}
      </TableCell>
      <TableCell>
        <Link href={props.recall.url}>
          View on site
        </Link>
      </TableCell>
      <TableCell>
        <DeleteButton onClick={handlePredictionDeleteClick} />
      </TableCell>
    </TableRow>
  )
}
