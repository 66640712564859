import React, { Fragment } from "react"
import Select from "../Select"
import Flex from "../Flex"
import Box from "../Box"


export default function AdverseEventFilters(props) {
  return(
    <Fragment>
      <Box width={1/5} mr={1}>
        <Select
          name='device_problem'
          label='Device Problem'
          includeBlank={true}
          entities={props.deviceProblems}
          value={props.deviceProblem}
          handleChange={props.handleFilterChange}
        />
      </Box>

      <Box width={1/5} mr={1}>
        <Select
          name='report_type'
          label='Report Type'
          includeBlank={true}
          entities={props.reportTypes}
          value={props.reportType}
          handleChange={props.handleFilterChange}
        />
      </Box>

      <Box width={1/5} mr={1}>
        <Select
          name='event_type'
          label='Event Type'
          includeBlank={true}
          entities={props.eventTypes}
          value={props.eventType}
          handleChange={props.handleFilterChange}
        />
      </Box>

      <Box width={1/5}>
        <Select
          name='reporter_country_code'
          label='Reporter Country Code'
          includeBlank={true}
          entities={props.reporterCountryCodes}
          value={props.reporterCountryCode}
          handleChange={props.handleFilterChange}
        />
      </Box>
    </Fragment>
  )
}
