import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Paper from "../../Paper"
import Flex from "../../Flex"
import Box from "../../Box"
import Select from "../../Select"
import TextInput from "../../TextInput"
import ButtonLayout from "../ButtonLayout"
import { RaisedButton } from "../../buttons"
import update from "immutability-helper"
import Snackbar from "../../Snackbar"

export default class EditContainer extends Component {
  constructor(props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
    this.handleSaveClick = this.handleSaveClick.bind(this)
    this.handleMetricChange = this.handleMetricChange.bind(this)
    this.state = { characteristicValue: this.props.characteristic_value }
  }
  
  UNSAFE_componentWillMount() {
    const characteristicValue = update(this.state.characteristicValue, { updated_by: { $set: this.props.current_user.id } })
    
    this.setState({
      characteristicValue: characteristicValue
    })
  }
  
  handleChange(e) {
    const name = e.target.name
    const value = e.target.value
    
    const characteristicValue = update(this.state.characteristicValue, { [name]: { $set: value } })
    
    this.setState({
      characteristicValue: characteristicValue
    })
  }
  
  handleMetricChange(name, value) {
    const characteristicValue = update(this.state.characteristicValue, { 
      metric_id: { $set: value } 
    })
    
    this.setState({
      characteristicValue: characteristicValue
    })
  }
  
  handleSaveClick(e) {
    $.ajax({
      type: 'PUT',
      url: `/admin/characteristic_values/${this.state.characteristicValue.id}`,
      data: {
        characteristic_value: this.state.characteristicValue
      },
      error: (data) => {
        this.setState({
          showSnackbar: true,
          snackbarText: `There was a problem updating the characteristic value. ${data.responseText}`
        })
      },
      complete: (data) => {
        setTimeout(() => {
          this.setState({
            showSnackbar: false,
            snackbarText: ''
          })
        }, 4000)
      }
    })
  }
  
  render() {
    return(
      <Fragment>
        
        <Paper>
          
          <Flex alignItems='baseline' width={1}>
            
            <Box width={3/4}>
              <TextInput 
                name='name' 
                label='Name' 
                value={this.state.characteristicValue.name} 
                onChange={this.handleChange} 
                fullWidth
              />
            </Box>
            
            <Box ml={3} width={1/4}>
              <Select 
                value={this.state.characteristicValue.metric_id} 
                name='metric'
                label='Metric'
                entities={this.props.group_metrics}
                displayEmpty={true}
                handleChange={this.handleMetricChange} 
              />
            </Box>
          </Flex>
          
          <ButtonLayout>
            <RaisedButton handleClick={this.handleSaveClick}>
              Save Characteristic Value
            </RaisedButton>
          </ButtonLayout>
          
        </Paper>
         
        <Snackbar 
          open={this.state.showSnackbar}
          message={this.state.snackbarText} 
        /> 
        
      </Fragment>
    )
  }
}
