import React from "react"
import PropTypes from "prop-types"
import Paper from "../Paper"
import Box from "../Box"
import Flex from "../Flex"
import { withSnackbar } from '../SnackbarContainer'
import ListingsCountDisplay from '../ListingsCountDisplay'
import update from "immutability-helper"
import ApprovalsTable from "./ApprovalsTable"
import InlineSearchContainer from '../admin/search/InlineSearchContainer'
import CountryAutoComplete from "../filters/CountryAutocomplete"
import CompanyAutocomplete from "../filters/CompanyAutocomplete"


class ApprovalsContainer extends React.Component {
  constructor(props) {
    super(props)
    this.handleSearchClick= this.handleSearchClick.bind(this)
    this.getDeviceApprovals = this.getDeviceApprovals.bind(this)
    this.handlePageChange = this.handlePageChange.bind(this)
    this.handleClearSearchClick = this.handleClearSearchClick.bind(this)
    this.handleFilterChange = this.handleFilterChange.bind(this)
    this.handleSearchSubmit = this.handleSearchSubmit.bind(this)
    this.handleClearClick = this.handleClearClick.bind(this)
    this.handleAutocompleteChange = this.handleAutocompleteChange.bind(this)
    this.handleRequestSort = this.handleRequestSort.bind(this)
    this.state = { deviceApprovals: this.props.device_approvals, deviceApprovalsCount: this.props.device_approvals_count, loading: false, manufacturers: this.props.manufacturers, countries: this.props.countries, filterValues: { query: this.props.query, country_id: this.props.country_id, order_direction: this.props.order_direction, order_by: this.props.order_by, page: this.props.page, company_id: this.props.company_id } }
  }

  handleRequestSort(event, property) {
    const isDesc = this.state.filterValues.order_by === property && this.state.filterValues.order_direction === 'desc';
    let orderDirection = isDesc ? 'asc' : 'desc'

    let params = Object.assign({}, this.state.filterValues)

    params["order_by"] = property
    params["order_direction"] = orderDirection

    this.getDeviceApprovals(params)
  }

  handleSearchSubmit(query) {
    let params = Object.assign({}, this.state.filterValues)

    params["query"] = query

    this.getDeviceApprovals(params)
  }

  getDeviceApprovals(params) {
    $.ajax({
      type: "GET",
      url: `/sectors/${this.props.sector.abbr}/device_approvals`,
      data: params,
      dataType: 'json',
      beforeSend: () => {
        this.setState({ loading: true })
      },
      success: (data) => {
        this.setState({
          deviceApprovals: data.device_approvals,
          deviceApprovalsCount: data.device_approvals_count,
          manufacturers: data.manufacturers,
          countries: data.countries,
          filterValues: params,
          loading: false
        })
      },
      error: (data) => {
        this.setState({ loading: false })
        this.props.snackbarShowMessage("Devices could not be loaded. Please try again.", "error")
      }
    })
  }

  handleClearClick(e) {
    console.log("hello")
  }

  handleFilterChange(name, value) {
    let params = Object.assign({}, this.state.filterValues)

    params[name] = value

    this.getDeviceApprovals(params)
  }

  handlePageChange(e, newPage) {
    let params = Object.assign({}, this.state.filterValues)

    params["page"] = newPage

    this.getDeviceApprovals(params)
  }

  handleSearchClick(query) {
    let params = Object.assign({}, this.state.filterValues)

    params["query"] = query

    this.getDeviceApprovals(params)
  }

  handleClearSearchClick(e) {
    let params = Object.assign({}, this.state.filterValues)

    params["query"] = null

    this.getDeviceApprovals(params)
  }

  handleAutocompleteChange(e, selected, name) {
    let params = Object.assign({}, this.state.filterValues)

    if (selected == null) {
      params[name] = null
    } else {
      params[name] = selected.value
    }

    this.getDeviceApprovals(params)
  }


  render() {

    let countryValue = this.state.filterValues.country_id ? { value: this.state.filterValues.country_id, name: this.props.countries.find((country) => { return country.value == this.state.filterValues.country_id }).name } : ''

    let companyValue = this.state.filterValues.company_id ? { value: this.state.filterValues.company_id, name: this.props.manufacturers.find((company) => { return company.value == this.state.filterValues.company_id}).name } : ''

    return (
      <React.Fragment>
        <Paper fullHeight>
          <Box height="100%">
            
            <Flex alignItems='center'>
              <Box width={1/5} mr={2}>
                <CountryAutoComplete 
                  countries={this.state.countries}
                  countryValue={countryValue}
                  handleAutocompleteChange={this.handleAutocompleteChange}
                />
              </Box>

              <Box width={1/5} mr={2}>
                <CompanyAutocomplete 
                  companies={this.state.manufacturers}
                  countryValue={companyValue}
                  handleAutocompleteChange={this.handleAutocompleteChange}
                />
              </Box>

              <Box width={1/3}>
                <InlineSearchContainer
                  searchLabel="Search Devices" 
                  query={this.state.filterValues.query}
                  handleSearchClick={this.handleSearchSubmit}
                  handleClearSearchClick={this.handleClearSearchClick}
                />
              </Box>
            </Flex>

            <ListingsCountDisplay 
              display={`${this.state.deviceApprovalsCount} records`}
            />

            <Box overflow="auto" height="80%">

              <ApprovalsTable
                key={this.state.deviceApprovals}
                deviceApprovals={this.state.deviceApprovals}
                page={this.state.filterValues.page}
                sector={this.props.sector}
                loading={this.state.loading}
                orderDirection={this.state.filterValues.order_direction}
                orderBy={this.state.filterValues.order_by}
                deviceApprovalsCount={this.state.deviceApprovalsCount}
                handlePageChange={this.handlePageChange}
                handleDeleteClick={this.handleDeleteClick}
                handleRequestSort={this.handleRequestSort}
              />

            </Box>
          </Box>
        </Paper>
      </React.Fragment>
    );
  }
}

ApprovalsContainer.propTypes = {
  deviceApprovals: PropTypes.array
};

export default withSnackbar(ApprovalsContainer)
