import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Tabs from '../../tabs/Tabs';
import Tab from '../../tabs/Tab';
import { withRouter } from "react-router";
import { Link } from "react-router-dom";

class EditContainerTabs extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    return(
      <Fragment>
        <Tabs variant="fullWidth" value={this.props.activeIndex} onChange={this.props.handleActiveIndexUpdate} className='bb b--black-10' aria-label="nav tabs">
          <Tab label='Source' value='source' />
          <Tab label='Profile' value='profile' />
          {/* <Tab label='PMA' value='pma' /> */}
          {/* <Tab label='510(k)' value='k_clearance' /> */}
          <Tab label='Characteristics' value='characteristics' />
          <Tab label='Region Characteristics' value='region_characteristics' />
          <Tab label='Status' value='status' />
          <Tab label='Path' value='path' />
          <Tab label='Sectors' value='sectors' />
          <Tab label='Components' value='components' />
        </Tabs>
      </Fragment>
    )
  }
}

export default withRouter(EditContainerTabs)
