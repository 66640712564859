import React, { Component } from "react"
import EntityLayout from "../EntityLayout"
import Box from "../Box"
import Typography from "@material-ui/core/Typography"
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ProfileDetails from "./ProfileDetails"
import Flex from "../Flex";
import { RaisedButton } from "../buttons";

export default class Profile extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    const rootUrl = `/sectors/${this.props.sector.abbr}/investor_presentations/${this.props.investor_presentation.id}`
    return(
      <div>

        <EntityLayout>
          <Box className='w-100 w-60-ns mb3 mb0-ns'>

            <ExpansionPanel defaultExpanded={true}>
              <ExpansionPanelSummary>
                <Typography className="f4">
                  Resources
                </Typography>
              </ExpansionPanelSummary>

              <ExpansionPanelDetails>
                <Flex justifyContent="center" className="w-100">
                  {this.props.investor_presentation.url && 
                    <Box mr={1}>
                      <RaisedButton variant="outlined" color="default" href={`${rootUrl}/view_webcast`} target="_blank">
                        Access Webcast
                      </RaisedButton>
                    </Box>
                  }
                  {this.props.investor_presentation.pdf && 
                    <Box mr={1}>
                      <RaisedButton variant="outlined" color="default" href={`${rootUrl}/view_pdf`} target="_blank">
                        View PDF
                      </RaisedButton>
                    </Box>
                  }
                  {this.props.investor_presentation.press_release_url && 
                    <Box mr={1}>
                      <RaisedButton variant="outlined" color="default" href={`${rootUrl}/view_press_release`} target="_blank">
                      View Press Release
                      </RaisedButton>
                    </Box>
                  }
                  {this.props.investor_presentation.seeking_alpha_url && 
                    <Box mr={1}>
                      <RaisedButton variant="outlined" color="default" href={`${rootUrl}/view_transcript`} target="_blank">
                        View Transcript
                      </RaisedButton>
                    </Box>
                  }
                </Flex>
              </ExpansionPanelDetails>
            </ExpansionPanel>

            {this.props.investor_presentation.ai_summary ? 
              <ExpansionPanel defaultExpanded={true}>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} ariacontrols={`summary-panel`} id={`summary-header`}>
                  <Typography className="f4">
                    Summary
                  </Typography>
                </ExpansionPanelSummary>

              <ExpansionPanelDetails>
                <Typography className='w-100'>
                  <Typography variant="caption" color="textSecondary">
                    This summary was automatically generated using artificial intelligence and may contain errors.
                  </Typography>
                  <div dangerouslySetInnerHTML={{__html: this.props.investor_presentation.ai_summary }} />
                </Typography>
              </ExpansionPanelDetails>

            </ExpansionPanel> : ''}

            {/* {this.props.investor_presentation.pdf && this.props.files &&
              <ExpansionPanel defaultExpanded={false} className="mt3">
                <ExpansionPanelSummary 
                  expandIcon={<ExpandMoreIcon />} 
                  aria-controls="pdf-panel" 
                  id="pdf-header"
                >
                  <Typography className="f4">
                    View PDF
                  </Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <iframe
                    src={this.props.files[0].url}
                    style={{ width: '100%', height: '600px' }}
                    frameBorder="0"
                  />
                </ExpansionPanelDetails>
              </ExpansionPanel>
            } */}
          </Box>

          <Box className='w-100 w-33-ns ml-auto'>
            <ProfileDetails
              investorPresentation={this.props.investor_presentation}
              company={this.props.company}
              sector={this.props.sector}
            />
          </Box>

        </EntityLayout>
      </div>
    )
  }
}
