import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
// import TrialDateRow from "./TrialDateRow"
import Flex from "../../Flex"
import Box from "../../Box"
import TextInput from "../../TextInput"
import RaisedButton from "../../buttons/RaisedButton"
import Typography from '@material-ui/core/Typography';
import Snackbar from "../../Snackbar"
import { Table, TableRow, TableHead, TableCell, TableBody } from "../../tables"
import update from "immutability-helper"
import Select from "../../Select"
import DatePicker from "../../DatePicker"
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';

export default class DateForm extends Component {
  constructor(props) {
    super(props)
    this.handleDateChange = this.handleDateChange.bind(this)
    this.handleSelectChange = this.handleSelectChange.bind(this)
    this.handleSaveClick = this.handleSaveClick.bind(this)
    this.handleDeleteClick = this.handleDeleteClick.bind(this)
    this.handleNameChange = this.handleNameChange.bind(this)
    this.state = { trialDates: [], newDate: '', dateName: 'start_date', dateType: '', showSnackbar: false, snackbarText: '' }
  }

  componentDidMount() {
    $.get(`/admin/trials/${this.props.trial.id}/dates`, {}, (data) => {
      this.setState({
        trialDates: data.trial_dates
      })
    }, 'json')
  }

  handleNameChange(name, value) {
    this.setState({
      dateName: value
    })
  }

  handleSelectChange(name, value) {
    this.setState({
      [name]: value
    })
  }

  handleDateChange(e) {
    this.setState({
      newDate: e.target.value
    })
  }

  handleSaveClick(e) {
    if (this.props.source) {
      let url = `/admin/trial_dates`
      let params = { trial_date: { trial_id: this.props.trial.id, date_name: this.state.dateName, date_value: this.state.newDate, date_type: this.state.dateType, source_id: this.props.source.source_id, created_by: this.props.currentUser.id }}

      $.post(url, params, (data) => {
        this.setState({
          trialDates: data.trial_dates,
          newEnrollment: '',
          showSnackbar: true,
          snackbarText: 'Date has been added'
        })
      }, 'json').fail(() => {
        this.setState({
          showSnackbar: true,
          snackbarText: `Date could not be added. Please try again.`
        })
      }).always(() => {
        setTimeout(() => {
          this.setState({
            showSnackbar: false,
            snackbarText: ''
          })
        }, 4000)
      })
    } else {
      this.setState({
        showSnackbar: true,
        snackbarText: 'You must choose a source before adding enrollment'
      })
      setTimeout(() => {
        this.setState({
          showSnackbar: false,
          snackbarText: ''
        })
      }, 4000)
    }
  }

  handleDeleteClick(trialDateId) {
    $.ajax({
      type: 'PUT',
      url: `/admin/trial_dates/${trialDateId}`,
      data: {
        trial_date: {
          deleted: true
        }
      },
      dataType: 'json',
      success: (data) => {
        this.setState({
          trialDates: data.trial_dates,
          showSnackbar: true,
          snackbarText: 'The date record was removed'
        })
      },
      error: (data) => {
        this.setState({
          showSnackbar: true,
          snackbarText: `The date record could not be removed. Please try again.`
        })
      },
      complete: () => {
        this.setState({
          showSnackbar: false,
          snackbarText: ''
        })
      }
    })
  }

  render() {
    return(
      <Fragment>
        <Typography variant="h6" component="h6" className='mb1 mt1 mr1'>
          New Date
        </Typography>

        <Flex alignItems='center' justifyContent="center" className='w-100'>

          <Box width="20" mr={3}>
            <Select
              name='dateName'
              entities={[{ name: 'Start date', value: 'start_date' }, { name: 'Completion date', value: 'completion_date' }, { name: 'Primary completion date', value: 'primary_completion_date' }, { name: 'Enrollment completion date', value: 'enrollment_completion_date'} ]}
              value={this.state.dateName}
              handleChange={this.handleNameChange}
            />
          </Box>

          <Box width={1/3}>
            {/* <DatePicker
              name='dateValue'
              label='Date'
              handleChange={this.handleDateChange}
              value={this.state.newDate}
            /> */}
            <Box>
              <label>Date</label>
            </Box>
            <input
              type="date"
              onChange={this.handleDateChange}
              value={this.state.newDate}
            />
          </Box>

          <Box width={1/3}>
            <Select
              name='dateType'
              entities={[{ name: 'Actual', value: 'Actual' }, { name: 'Anticipated', value: 'Anticipated' } ]}
              value={this.state.dateType}
              handleChange={this.handleSelectChange}
            />
          </Box>

          <Box mh={5} width={1/4}>
            <RaisedButton handleClick={this.handleSaveClick}>
              Save Date
            </RaisedButton>
          </Box>

        </Flex>

        <Box mt={4}>
          <Typography variant="h6" component="h6" className='mb1 mt1 mr1'>
            Trial Dates
          </Typography>

          <Table>

            <TableHead>
              <TableRow>
                {/* <TableCell>Active</TableCell> */}
                <TableCell>Kind</TableCell>
                <TableCell>Date</TableCell>
                <TableCell>Type</TableCell>
                <TableCell>Source</TableCell>
                {/* <TableCell>User</TableCell> */}
                <TableCell></TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {this.state.trialDates.length > 0 ? this.state.trialDates.map((trialDate) =>
                <TableRow key={trialDate.trial_date.id}>
                  <TableCell>{trialDate.name}</TableCell>
                  <TableCell>{trialDate.value}</TableCell>
                  <TableCell>{trialDate.trial_date.date_type}</TableCell>
                  <TableCell>{trialDate.source_object.source_title}</TableCell>
                  <TableCell>
                    <IconButton onClick={() => this.handleDeleteClick(trialDate.trial_date.id)}>
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ) : <TableRow><TableCell colSpan={6}>No enrollments yet. Add an enrollment above.</TableCell></TableRow>}
            </TableBody>

          </Table>

        </Box>

        <Snackbar
          open={this.state.showSnackbar}
          message={this.state.snackbarText}
        />

      </Fragment>
    )
  }
}
