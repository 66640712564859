import React, { Fragment, Component } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Select from "../Select"
import Flex from "../Flex"
import Box from "../Box"

export default class Filters extends Component {
  constructor(props) {
    super(props)
  }
  
  render() {
    const selectedCompanies = this.props.companies.filter(company => this.props.companyIds.includes(company.value) ? company : null)
    const selectedProducts = this.props.products.filter(product => this.props.productIds.includes(product.value) ? product : null)
    return(
      <Fragment>
        
        <Flex alignItems='center'>
          
          <Box width="12%" mr={2}>
            <Select
              multiple
              label='Company' 
              name='company_ids'
              value={this.props.companyIds} 
              entities={this.props.companies}
              handleChange={this.props.handleFilterChange}
              includeBlank={true}
              renderValue={selected => (
                <Flex flexWrap='wrap'>
                  {selectedCompanies.length}
                </Flex>
              )}
            />
          </Box>
          
          <Box width="12%">
            <Select
              multiple
              label='Product' 
              name='product_ids'
              value={this.props.productIds} 
              entities={this.props.products}
              handleChange={this.props.handleFilterChange}
              includeBlank={true}
              renderValue={selected => (
                <Flex flexWrap='wrap'>
                  {selectedProducts.length}
                </Flex>
              )}
            />
          </Box>
          
        </Flex>
        
      </Fragment>
    )
  }
}
