import React from "react"
import Flex from "../Flex"
import Box from "../Box"
import Typography from "@material-ui/core/Typography"
import { List, ListItemLink, ListItemText } from "../lists"
import Button from "../buttons/Button"
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction"
import Link from "../Link"

export default function ResultsDisplay(props) {

  function setUrl(result, sector) {
    if (result.search_url) {
      return(result.search_url)
    } else if (result.device_approval) {
      return(`/sectors/${sector.abbr}/device_approvals/${result.record_id}`)
    } else {
      return(`/sectors/${sector.abbr}/${result.record_type.toLowerCase()}s/${result.id}`)
    }
  }

  return(
    <React.Fragment>
      <Box width="80%" mx='auto' height="100%">
        <List>
          {props.searchResults.map((result) =>
            <ListItemLink key={result.record_id} href={setUrl(result, props.sector)} alignItems='flex-start' divider={true} target={result.search_url ? "_blank" : ""}>
              <ListItemText>
                <Typography variant="caption" color="textSecondary">
                  {result.record_type}
                </Typography>

                <Box pb={1}>
                  <Typography variant="body1">
                    {result.search_title}
                  </Typography>
                </Box>


                <Box maxWidth="40em">
                  <Typography variant="body2" color="textSecondary">
                    <div
                      dangerouslySetInnerHTML={{__html: result.date ? `${humanDateFormat(result.date)} - ${result.search_description}` : result.search_description }}
                    />
                  </Typography>
                </Box>

                {result.search_details ? 
                  <Box mt={1}>
                    <Typography variant="body2" color="textPrimary">
                      <div
                        dangerouslySetInnerHTML={{__html: result.search_details }}
                      />
                    </Typography>
                  </Box> 
                : ""}
              </ListItemText>

              <ListItemSecondaryAction>
                {result.record_type == "Product" ? 
                  <Button color='default' className='ttn' href={`/sectors/${props.sector.abbr}/products/${result.record_id}/search?query=${props.query}`}>
                    Search in product
                  </Button>
                : ""}
              </ListItemSecondaryAction>
            </ListItemLink>
          )}
        </List>
      </Box>
    </React.Fragment>
  )
}