import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import ProfileForm from "./ProfileForm"
import Paper from "../../Paper"
import Snackbar from "../../Snackbar"
import TabSection from "../../TabSection"
import update from "immutability-helper"


class EditContainer extends Component {
  constructor(props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
    this.handleSaveClick = this.handleSaveClick.bind(this)
    this.state = { clinicalType: this.props.clinical_type, showSnackbar: false, snackbarText: '' }
  }
  
  UNSAFE_componentWillMount() {
    let clinicalType = update(this.state.clinicalType, { updated_by: { $set: this.props.current_user.id }})
    this.setState({
      clinicalType: clinicalType
    })
  }
  
  handleChange(e) {
    const name = e.currentTarget.name
    const value = e.currentTarget.value
    let clinicalType = update(this.state.clinicalType, { [name]: { $set: value } })
    this.setState({
      clinicalType: clinicalType
    })
    
  }
  
  handleSaveClick(e) {
    $.ajax({
      type: 'PUT',
      url: `/admin/clinical_types/${this.state.clinicalType.id}`,
      data: {
        clinical_type: this.state.clinicalType
      },
      dataType: 'json',
      success: (data) => {
        this.setState({
          clinicalType: data.clinical_type,
          showSnackbar: true,
          snackbarText: 'The clinical type has been updated'
        })
      },
      error: (data) => {
        this.setState({
          showSnackbar: true,
          snackbarText: 'The clinical type could not be updated. Please try again.'
        })
      },
      complete: (data) => {
        setTimeout(() => {
          this.setState({
            showSnackbar: false,
            snackbarText: ''
          })
        }, 4000)
      }
    })
  }
  
  render() {
    return(
      <Fragment>
        
        <Paper>
          
          <ProfileForm 
            clinicalType={this.state.clinicalType}
            handleChange={this.handleChange}
            handleSaveClick={this.handleSaveClick}
          />
          
        </Paper>
        
        <Snackbar 
          open={this.state.showSnackbar}
          message={this.state.snackbarText} 
        />
      </Fragment>
    )
  }
}


export default EditContainer
