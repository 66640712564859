import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Paper from "../Paper"
import { withRouter } from "react-router-dom";
import { withSnackbar } from "../SnackbarContainer"
import SearchTabs from "./SearchTabs"
import Filters from "./Filters"
import SearchDisplayContainer from "./DisplayContainer"
import ResultsDisplay from "./ResultsDisplay"
import Pagination from "./Pagination"

class SearchResults extends Component {
  constructor(props) {
    super(props)
    this.handleActiveIndexClick = this.handleActiveIndexClick.bind(this)
    this.handleSearchChange = this.handleSearchChange.bind(this)
    this.handleSearchClick = this.handleSearchClick.bind(this)
    this.handlePageChange = this.handlePageChange.bind(this)
    this.handleOpen = this.handleOpen.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.handleProductRequestClick = this.handleProductRequestClick.bind(this)
    this.state = { query: this.props.query, searchResults: this.props.search_results, resultsCount: this.props.results_count, activeIndex: 'All', page: 1, date: this.props.date, anchorEl: null, loading: false }
  }

  componentDidMount() {
    if (this.props.filter) {

      let index = this.props.filter

      this.setState({
        activeIndex: index,
        filter: this.props.filter
      })
    }
  }

  handleOpen(event) {
    this.setState({
      anchorEl: event.currentTarget
    })
  };

  handleClose() {
    this.setState({
      anchorEl: null
    })
  };

  handleProductRequestClick(e) {
    $.ajax({
      type: "POST",
      url: "/user_product_requests",
      data: {
        user_product_request: {
          user_id: this.props.user.id, product_string: this.state.query
        }
      },
      dataType: 'json',
      success: (data) => {
        this.props.snackbarShowMessage("Request has been submitted. We will get back to you shortly.")
      }
    })
  }

  handlePageChange(e, page) {
    let url = `/sectors/${this.props.sector.abbr}/search`

    let filter = this.state.activeIndex

    $.ajax({
      type: 'GET',
      url: url,
      data: {
        query: this.state.query, page: page, filter: filter
      },
      dataType: 'json',
      success: (data) => {

        let params = {}

        params["query"] = this.state.query
        params["page"] = page
        filter ? params["filter"] = filter : ''

        let filterKeys = Object.keys(params)
        let queryParams = filterKeys.map((filterKey) => `${filterKey}=${params[filterKey]}`)

        this.props.history.push(`${url}?${queryParams.join("&")}`);

        this.setState({
          searchResults: data.search_results,
          resultsCount: data.results_count,
          page: page,
          filter: filter
        })
      }
    })
  }

  handleActiveIndexClick(e, newValue) {
    let url = `/sectors/${this.props.sector.abbr}/search`

    let filter = newValue

    $.ajax({
      type: 'GET',
      url: url,
      data: {
        query: this.state.query, filter: filter
      },
      dataType: 'json',
      success: (data) => {

        let params = {}
        params["query"] = this.state.query
        filter ? params["filter"] = filter : ''

        let filterKeys = Object.keys(params)
        let queryParams = filterKeys.map((filterKey) => `${filterKey}=${params[filterKey]}`)

        this.props.history.push(`${url}?${queryParams.join("&")}`);

        this.setState({
          searchResults: data.search_results,
          resultsCount: data.results_count,
          activeIndex: newValue,
          filter: filter
        })
      }
    })
  }

  handleSearchChange(e) {
    this.setState({
      query: e.target.value
    })
  }

  handleSearchClick(e) {
    let url = `/sectors/${this.props.sector.abbr}/search`

    $.ajax({
      type: 'GET',
      url: url,
      data: {
        query: this.state.query, filter: this.state.activeIndex
      },
      dataType: 'json',
      success: (data) => {

        let filter = this.state.activeIndex

        // this.props.history.push(`${url}?query=${this.state.query}`);
        let params = {}
        params["query"] = this.state.query
        filter ? params["filter"] = filter : ''

        let filterKeys = Object.keys(params)
        let queryParams = filterKeys.map((filterKey) => `${filterKey}=${params[filterKey]}`)

        this.props.history.push(`${url}?${queryParams.join("&")}`);

        this.setState({
          searchResults: data.search_results,
          resultsCount: data.results_count,
          filter: filter
        })
      }
    })
  }

  render() {
    return(
      <Fragment>
        <Paper minFullHeight>

          <SearchDisplayContainer 
            resultsCount={this.state.resultsCount}
            loading={this.state.loading}
            form={
              <Form 
                query={this.state.query}
                handleSearchChange={this.handleSearchChange}
                handleSearchClick={this.handleSearchClick}
              />
            }
            tabs={
              <SearchTabs 
                activeIndex={this.state.activeIndex}
                handleActiveIndexClick={this.handleActiveIndexClick}
              />
            }
            filters={
              <Filters 
                dateOptions={this.props.date_options}
                anchorEl={this.state.anchorEl}
                handleOpen={this.handleOpen}
                handleClose={this.handleClose}
                handleFilterChange={this.handleFilterChange}
              />
            }
            results={
              <ResultsDisplay 
                searchResults={this.state.searchResults}
                resultsCount={this.state.resultsCount}
                activeIndex={this.state.activeIndex}
                query={this.state.query}
              />
            }
            pagination={
              <Pagination 
                page={this.state.page}
                resultsCount={this.state.resultsCount}
                handlePageChange={this.handlePageChange}
              />
            }
          />

        </Paper>
      </Fragment>
    )
  }
}

export default withRouter(withSnackbar(SearchResults))
