import React, { Fragment } from "react"
import PieChart from "../charts/PieChart"


export default function CountryCodeChart(props) {

  let chartReloadKey = Math.random()

  return(
    <Fragment>
      <PieChart
        key={`product-country-code-chart-${chartReloadKey}`}
        chartId='product-country-code-chart'
        exportingChartOptions={{ credits: { enabled: true, text: '\u00A9 pvpipeline' } }}
        formattedData={[{ name: 'Reporter Country', colorByPoint: true, data: props.countryCodeChartData }]}
        title='Reporter Country'
      />
    </Fragment>
  )
}
