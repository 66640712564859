import React from "react"
import DeleteButton from "../DeleteButton"
import { ListItem, ListItemText } from "../../lists"
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction"

export default function AssociatedCompanyListItem(props) {

  function handleUnassociateClick(e) {
    props.handleCompanyDeleteClick(props.entityCompany)
  }

  return (
    <ListItem button key={props.entityCompany.id} role={undefined}>

      <ListItemText
        primary={props.company ? props.company.short_name : props.entityCompany.short_name}
        secondary={props.entityCompany.score}
      />

      <ListItemSecondaryAction>
        <DeleteButton onClick={handleUnassociateClick} />
      </ListItemSecondaryAction>

    </ListItem>
  )
}
