import React from 'react'
import { TableCell, TableRow } from '../../../tables'
import FdaLink from '../../510_k_clearances/FdaLink'
import { RaisedButton } from "../../../buttons"
import DeleteButton from "../../DeleteButton"
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import MuiIconButton from '@material-ui/core/IconButton';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import FilterChip from "../../../filters/FilterChip"
import Box from "../../../Box"
import Link from '../../../Link'

export default function PredictedRow(props) {

  function handleDeleteClick(e) {
    props.handleDeleteClick(props.kClearance)
  }

  function handleAddKClearanceClick(e) {
    props.handleAddKClearanceClick(props.kClearance)
  }

  function handlekClearanceCheckboxClick(e) {
    props.handleKClearanceCheckboxClick(props.kClearance)
  }

  const selected = props.checkedKClearances.includes(props.kClearance.id)

  return (
    <TableRow>
      <TableCell>
        <MuiIconButton onClick={handlekClearanceCheckboxClick} edge="start">
          {selected ? <CheckBoxIcon color='primary' style={{height: '22px', width: '22px'}} /> : <CheckBoxOutlineBlankIcon style={{height: '22px', width: '22px'}} />}
        </MuiIconButton>
      </TableCell>
      <TableCell>
        <FdaLink 
          fdaKClearance={props.kClearance}
          linkText={props.kClearance.k_number}
        />
      </TableCell>
      <TableCell className="measure-wide">
        <Link href={`/admin/fda_k_clearances/${props.kClearance.id}/associator`}>
          {props.kClearance.device_name}
        </Link>
      </TableCell>
      <TableCell className="measure-wide">{props.kClearance.applicant}</TableCell>
      <TableCell>{props.kClearance.product_code}</TableCell>
      <TableCell>{props.kClearance.date_received}</TableCell>
      <TableCell>
        <DeleteButton onClick={handleDeleteClick} />
      </TableCell>
    </TableRow>
  )
}
