import React, { Fragment } from "react"
import Paper from "../../Paper"
import List from "../../lists/List";
import CompanyListItem from "./CompanyListItem"

export default function CompaniesPanel(props) {
  return (
    <Fragment>
      
      <Paper className='vh-100 overflow-scroll'>
        <List>
          {props.companies.map((company) =>
            <CompanyListItem 
              key={company.id}
              company={company}
              checked={props.activeCompanies.indexOf(company.id) == -1 ? false : true}
              handleCheckboxSelect={props.handleCheckboxSelect}
            /> 
          )}
        </List>
      </Paper>
      
    </Fragment>
  )
}
