import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import { Table, TableHead, TableBody, TableRow, TableCell } from "../../tables"
import ReviewerRow from "./ReviewerRow"
import Paper from "../../Paper"
import Snackbar from "../../Snackbar"
import update from "immutability-helper"
import Select from "../../Select"
import Flex from "../../Flex"
import Box from "../../Box"
import DatePicker from "../../DatePicker"

export default class StagedReviewer extends Component {
  constructor(props) {
    super(props)
    this.handleDeleteClick = this.handleDeleteClick.bind(this)
    this.handleUndoClick = this.handleUndoClick.bind(this)
    this.handleSectorChange = this.handleSectorChange.bind(this)
    this.handleDateChange = this.handleDateChange.bind(this)
    this.getPublications = this.getPublications.bind(this)
    this.state = { publications: this.props.publications, filterValues: { sector_id: this.props.sector_id, begin_date: this.props.begin_date, end_date: this.props.end_date }, lastDeleted: null, showSnackbar: false, snackbarText: '' }
  }

  handleUndoClick(event, reason) {
    if (this.state.lastDeleted) {
      $.ajax({
        type: 'PUT',
        url: `/admin/publication_associations/${this.state.lastDeleted.id}`,
        data: {
          publication_association: {
            deleted: null, deleted_by: null
          }
        },
        dataType: 'json',
        success: (data) => {
          const publicationAssociations = update(this.state.publicationAssociations, {
            $push: [this.state.lastDeleted]
          })

          this.setState({
            publicationAssociations: publicationAssociations,
            showSnackbar: true,
            snackbarText: 'Association was undeleted',
            lastDeleted: null
          })
        },
        error: (data) => {
          this.setState({
            showSnackbar: true,
            snackbarText: data.responseText
          })
        },
        completed: (data) => {
          setTimeout(() => {
            this.setState({
              showSnackbar: false,
              snackbarText: ''
            })
          }, 4000)
        }
      })
    }
  }

  handleDeleteClick(publicationAssociation) {
    $.ajax({
      type: "PUT",
      url: `/admin/publication_associations/${publicationAssociation.id}`,
      data: {
        publication_association: {
          deleted: true, deleted_by: this.props.current_user.id
        }
      },
      dataType: 'json',
      success: (data) => {
        const index = this.state.publications.indexOf(publication)

        const publications = update(this.state.publications, {
          $splice: [[index, 1]]
        })

        this.setState({
          publications: publications,
          lastDeleted: publication,
          showSnackbar: true,
          snackbarText: 'The association was removed'
        })
      },
      error: (data) => {
        this.setState({
          showSnackbar: true,
          snackbarText: 'The association could not be deleted'
        })
      },
      complete: (data) => {
        setTimeout(() => {
          this.setState({
            showSnackbar: false,
            snackbarText: ''
          })
        }, 5000)
      }
    })
  }

  handleDateChange(name, value, date) {
    let params = Object.assign({}, this.state.filterValues)

    params[name] = date

    this.getPublications(params)
  }

  handleSectorChange(name, value) {
    let params = Object.assign({}, this.state.filterValues)

    params[name] = value

    this.getPublications(params)
  }

  getPublications(params) {
    console.log(params)
    $.ajax({
      type: "GET",
      url: `/admin/publications/staged_reviewer`,
      data: params,
      dataType: 'json',
      success: (data) => {
        this.setState({
          publications: data.publications,
          filterValues: params
        })
      }
    })
  }

  render() {
    console.log(this.state.filterValues)
    return(
      <Fragment>

        <Paper fullHeight>

          <Box height="100%">
            <Flex mb={4}>
              <Box width={1/4} mr={5}>
                <Select
                  label='Sector'
                  name='sector_id'
                  includeBlank
                  value={this.state.filterValues.sector_id}
                  entities={this.props.sectors}
                  handleChange={this.handleSectorChange}
                />
              </Box>

              {/* <Box mr={3}>
                <DatePicker
                  name='begin_date'
                  label='Begin Date'
                  handleChange={(value, date) => { this.handleDateChange('begin_date', value, date) }}
                  value={this.state.filterValues.begin_date}
                />
              </Box>

              <Box>
                <DatePicker
                  name='end_date'
                  label='End Date'
                  handleChange={(value, date) => { this.handleDateChange('end_date', value, date) }}
                  value={this.state.filterValues.end_date}
                />
              </Box> */}

              <Box ml='auto'>
                {`${this.state.publications.length} publications`}
              </Box>
            </Flex>

            <Box overflow="auto" height="80%">
              <Table>
                <TableHead>
                  <TableRow hover={false}>
                    <TableCell borderRight>PMID</TableCell>
                    <TableCell borderRight>Title</TableCell>
                    {/* <TableCell borderRight>User</TableCell> */}
                    <TableCell borderRight>Date</TableCell>
                    {/* <TableCell></TableCell> */}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {this.state.publications.map((publication) =>
                    <ReviewerRow
                      key={publication.id}
                      publication={publication}
                      handleDeleteClick={this.handleDeleteClick}
                    />
                  )}
                </TableBody>
              </Table>
            </Box>
          </Box>
        </Paper>

        <Snackbar
          open={this.state.showSnackbar}
          handleUndoClick={this.handleUndoClick}
          message={this.state.snackbarText}
        />

      </Fragment>
    )
  }
}
