import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Slider from '@material-ui/core/Slider';

const YearRangeSlider = (props) => {
  const { years, originalYears, beginYear, endYear, handleFilterChange } = props
  
  const minYear = Number(originalYears[0].name);
  const maxYear = Number(originalYears[originalYears.length - 1].name);

  // find the index of the year closest to the middle of the range
  // let midYear = originalYears.length > 0 ? Math.round(originalYears.length / 2) : 0

  const [value, setValue] = useState([beginYear, endYear]);
  const [marks, setMarks] = useState([
    { value: minYear, label: minYear.toString() },
    { value: maxYear, label: maxYear.toString() }
  ]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleMouseUp = (event, newValue) => {
    setValue(newValue);

    let chosenMarks = newValue.filter(value => value !== minYear && value !== maxYear)
                              .map(value => ({ value: value, label: value.toString() }));

    // Prepare the newMarks array by combining minYear, maxYear, and the filtered chosenMarks
    let newMarks = [
      { value: minYear, label: minYear.toString() },
      { value: maxYear, label: maxYear.toString() },
      ...chosenMarks
    ];
    
    setMarks(newMarks);
    
    console.log(newMarks);
    handleFilterChange(newValue, 'years')
  };

  return (
      <div style={{ width: '80%', margin: '0 auto' }}>
        <Slider
          color='secondary'
          value={value}
          onChange={handleChange}
          onChangeCommitted={handleMouseUp}
          valueLabelDisplay="auto"
          aria-labelledby="range-slider"
          min={minYear}
          max={maxYear}
          marks={marks}
        />
      </div>
  );
};

YearRangeSlider.propTypes = {
  handleFilterChange: PropTypes.func.isRequired,
  dateValueArray: PropTypes.arrayOf(PropTypes.string).isRequired,
  years: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.integer
  })).isRequired
};

export default YearRangeSlider;
