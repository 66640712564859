import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Paper from "../../Paper"
import Flex from "../../Flex"
import Box from "../../Box"
import Select from "../../Select"
import TextInput from "../../TextInput"
import ButtonLayout from "../ButtonLayout"
import { RaisedButton } from "../../buttons"
import update from "immutability-helper"
import Snackbar from "../../Snackbar"
import DatePicker from "../../DatePicker"

export default class EditContainer extends Component {
  constructor(props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
    this.handleSaveClick = this.handleSaveClick.bind(this)
    this.handleWebsiteKindChange = this.handleWebsiteKindChange.bind(this)
    this.handleDateChange = this.handleDateChange.bind(this)
    this.state = { website: this.props.website, showSnackbar: false, snackbarText: '' }
  }

  handleDateChange(date) {
    const website = update(this.state.website, {
      date: { $set: date }
    })

    this.setState({
      website: website
    })
  }

  handleWebsiteKindChange(name, value) {
    const website = update(this.state.website, {
      [name]: { $set: value }
    })

    this.setState({
      website: website
    })
  }

  handleChange(e) {
    const name = e.target.name
    const value = e.target.value
    const website = update(this.state.website, { [name]: { $set: value } })
    this.setState({
      website: website
    })
  }

  handleSaveClick(e) {
    $.ajax({
      type: 'PUT',
      url: `/admin/websites/${this.state.website.id}`,
      data: {
        website: this.state.website
      },
      dataType: 'json',
      success: (data) => {
        window.location.href = "/admin/websites"
      },
      error: (data) => {
        this.setState({
          showSnackbar: true,
          snackbarText: `The website could not be updated. ${data.responseText}`
        })
      },
      complete: (data) => {
        setTimeout(() => {
          this.setState({
            showSnackbar: false,
            snackbarText: ''
          })
        }, 4000)
      }
    })
  }

  render() {
    console.log(this.state.website.website_kind_id)
    return(
      <Fragment>

        <Paper>

          <Flex alignItems='start'>

            <Box mr={3} width={1/2}>
              <Select
                value={this.state.website.website_kind_id ? this.state.website.website_kind_id.toString() : this.state.website.website_kind_id}
                name='website_kind_id'
                label='Kind'
                entities={this.props.website_kinds}
                displayEmpty={true}
                handleChange={this.handleWebsiteKindChange}
              />
            </Box>

            <Box ml="auto">
              <DatePicker
                name='date'
                label='Date'
                handleChange={this.handleDateChange}
                value={this.state.website.date}
              />
            </Box>

          </Flex>

          <Box mt={3}>
            <TextInput
              name='title'
              label='Title'
              value={this.state.website.title}
              onChange={this.handleChange}
              className='w-100'
            />
          </Box>

          <Box my={3} width={1}>
            <TextInput
              name='name'
              label='Website'
              multiline={true}
              rows={4}
              value={this.state.website.name}
              onChange={this.handleChange}
              className='w-100'
            />
          </Box>

          <ButtonLayout>
            <RaisedButton handleClick={this.handleSaveClick}>
              Save Website
            </RaisedButton>
          </ButtonLayout>


        </Paper>

        <Snackbar
          open={this.state.showSnackbar}
          message={this.state.snackbarText}
        />
      </Fragment>
    )
  }
}
