import React, { Component } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Chart from "./charts/Chart"

export default class DateChart extends Component {
  constructor(props) {
    super(props)
  }

  setXaxisMin() {
    if (this.props.sitesChartData.length == 0) {
      null
    } else {
      let dateValArray = []

      this.props.sitesChartData.map(array =>
        dateValArray.push(array.x)
      )

      if (!this.props.showEnrollmentDates) {
        return(Math.min.apply(Math, dateValArray) - ((Math.max.apply(Math, dateValArray) - Math.min.apply(Math, dateValArray)) * 0.05))
      } else {
        let minEnrollmentDate = Math.min.apply(Math, dateValArray)
        let maxEnrollmentDate = Math.max.apply(Math, dateValArray)


        if (minEnrollmentDate < this.props.enrollmentStartDateForChart) {

          let padding = (maxEnrollmentDate - minEnrollmentDate) * 0.05

          return(minEnrollmentDate - padding)

        } else {

          let lastWithEndDate = [maxEnrollmentDate, this.props.enrollmentEndDateForChart]

          let padding = (Math.max.apply(Math, lastWithEndDate) - Math.min.apply(Math, lastWithEndDate)) * 0.05

          return(this.props.enrollmentStartDateForChart - padding)
        }

      }
    }
  }

  setXaxisMax() {
    let enrollmentDateValArray = []

    this.props.sitesChartData.map(enrollmentArray =>
      enrollmentDateValArray.push(enrollmentArray.x)
    )

    if (!this.props.showEnrollmentDates) {

      return(((Math.max.apply(Math, enrollmentDateValArray) - Math.max.apply(Math, enrollmentDateValArray)) * 0.05) + Math.max.apply(Math, enrollmentDateValArray))

    } else {

      let maxEnrollmentDate = Math.max.apply(Math, enrollmentDateValArray)
      let lastWithEndDate = [maxEnrollmentDate, this.props.enrollmentEndDateForChart]

      let padding = (Math.max.apply(Math, lastWithEndDate) - Math.min.apply(Math, lastWithEndDate)) * 0.05

      return(padding + Math.max.apply(Math, lastWithEndDate))
    }
  }

  setYaxisMax() {
    if (this.props.chartData.length == 0) {
      null
    } else {
      let enrollmentValArray = []
      let [first, ...enrollmentVals] = this.props.chartData[0]

      enrollmentVals.map(enrollmentArray =>
        enrollmentValArray.push(enrollmentArray[1])
      )

      if (this.props.targetEnrollment) {
        enrollmentValArray.push(this.props.targetEnrollment.enrollment)
      }

      let maxEnrollment = Math.max.apply(Math, enrollmentValArray)

      return(maxEnrollment + 5)
    }
  }

  setYaxisMin() {
    if (this.props.chartData.length == 0) {
      return(null)
    } else {
      let enrollmentValArray = []
      let [first, ...enrollmentVals] = this.props.chartData[0]

      enrollmentVals.map(enrollmentArray =>
        enrollmentValArray.push(enrollmentArray[1])
      )

      if (this.props.targetEnrollment) {
        enrollmentValArray.push(this.props.targetEnrollment.enrollment)
      }

      let minEnrollment = Math.min.apply(Math, enrollmentValArray)

      if (enrollmentValArray.length == 1) {
        return(enrollmentValArray[0] - 0.25 * enrollmentValArray[0])
      } else {
        return(null)
      }
    }

  }

  formatData() {
    let chartData = ""
    const data = this.props.dateChartData.map((array) => {
      let newArr = new Array(array)
      let flatArr = Array.prototype.concat.apply([], newArr)
      let dataArr = flatArr.splice(1)

      return({
        name: array[0],
        marker: {
          enabled: true
        },
        dataLabels: {
          enabled: true
        },
        data: dataArr
      })
    })

    return(data)
  }

  render() {
    return(
      <Chart
        key={this.props.key}
        chartId={this.props.chartId}
        chartOptions={{type: 'spline', height: 300}}
        legend={true}
        plotOptions={{spline: { dataLabels: { enabled: true, format: '{y:%B %d, %Y}' }, marker: { enabled: true }}}}
        xAxisType='datetime'
        yAxisType='datetime'
        xAxisTitle='Source Date'
        formattedData={this.formatData()}
        simple={false}
        startOnTick={true}
        endOnTick={true}
        yAxisTitle={this.props.attribute}
        tooltip={{ headerFormat: '<span style="font-size: 10px">{series.name}</span><br/>', pointFormat: '<span style="color:{point.color}">\u25CF</span> {point.name}: <b>{point.y:%B %d, %Y}</b><br/>' }}
      />
    )
  }
}

DateChart.defaultProps = {
  chartId: 'date-chart'
}
