import React from "react";
import Flex from "../../Flex"
import Box from "../../Box"
import TextInput from "../../TextInput"
import ButtonLayout from "../ButtonLayout"
import { RaisedButton, Button } from "../../buttons"
import DatePicker from "../../DatePicker"
import Select from "../../Select"
import SearchDropdown from "../../SearchDropdown";
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Tooltip from '@material-ui/core/Tooltip';

export default function Form(props) {
  let publicationValue = props.newsPublications.filter(newsPublication => newsPublication.value == props.newsItem.news_publication_id)[0]
  return(
    <React.Fragment>
      <Flex alignItems='start'>

        <Box width={1/2}>
          <TextInput
            name='url'
            label='URL'
            value={props.newsItem.url}
            onChange={props.handleChange}
            fullWidth
          />
        </Box>

        <Box ml={4} width={1/4}>
          <div style={{ width: 300 }}>
            <Autocomplete
              id='news_publication_id'
              options={props.newsPublications}
              name='news_publication_id'
              defaultValue={publicationValue}
              value={publicationValue}
              getOptionLabel={(option) => option.name}
              style={{ width: 300 }}
              selectOnFocus
              freeSolo={true}
              inputValue={publicationValue ? publicationValue.name : (props.newsItem.news_publication_name || '')}
              onChange={props.handleSearchChange}
              onInputChange={props.handleChange}
              renderInput={(params) => <TextField {...params} name='news_publication_name' label='Publication' variant="outlined" />}
            />
          </div>
        </Box>

      </Flex>

      <Flex my={3}>
        <Box>
          <DatePicker
            name='date'
            label='Date'
            handleChange={props.handleDateChange}
            value={props.newsItem.date}
          />
        </Box>

        <Box ml={4} width={1/4}>
          <Select
            name='news_type_id'
            label='Type'
            entities={props.newsTypes}
            value={props.newsItem.news_type_id}
            handleChange={props.handleSelectChange}
            fullWidth
          />
        </Box>
      </Flex>

      <Box width={1} my={3}>
        <TextInput
          name='title'
          label='Title'
          multiline={true}
          rows={4}
          value={props.newsItem.title}
          onChange={props.handleChange}
          fullWidth
        />
      </Box>

      <Flex justifyContent='space-evenly'>
        <Box>
          <Tooltip title="Once a url is added, click this button to populate data">
            <Button onClick={props.handleFetchDataClick}>
              Fetch data
            </Button>
          </Tooltip>
        </Box>

        <Box>
          <RaisedButton handleClick={props.handleSaveClick}>
            Save News Item
          </RaisedButton>
        </Box>
      </Flex>

      {/* <ButtonLayout>
        <Button onClick={props.handleFetchDataClick}>
          Fetch data
        </Button>
        <RaisedButton handleClick={props.handleSaveClick}>
          Save News Item
        </RaisedButton>
      </ButtonLayout> */}

    </React.Fragment>
  )
}