import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  breakpoints: {
    keys: ["xs", "sm", "md", "lg", "xl"],
    values: {
      xl: 1920,
      lg: 1280,
      md: 960,
      sm: 600,
      xs: 0
    },
  },
  // mixins: {
  //   toolbar: {
  //     minHeight: 128
  //   }
  // },
  palette: {
    primary: {
      contrastText: "#fff",
      dark: "rgb(161, 82, 36)",
      light: "rgb(235, 145, 92)",
      main: "#e67634"
    },
    secondary: {
      contrastText: "#fff",
      dark: "rgb(161, 82, 36)",
      light: "rgb(235, 145, 92)",
      main: "#e67634"
    },
    success: {
      light: "#81c784",
      main: "#4caf50",
      dark: "#388e3c",
      contrastText: "rgba(0, 0, 0, 0.87)"
    },
    trials: {
      main: "#b57e7e"
    },
    abstracts: {
      main: "#5a65cd",
      light: "#dbe2fb"
    },
    error: {
      light: "#e57373",
      main: "#f44336",
      dark: "#d32f2f",
      contrastText: "#fff"
    },
    lightGreen: "#ebeddf",
    white: "#fff",
    caption: "rgb(95, 99, 104)",
    grey: {
      500: "#9e9e9e"
    },
    text: {
      primary: "rgba(0, 0, 0, 0.87)",
      secondary: "rgba(0, 0, 0, 0.54)",
      disabled: "rgba(0, 0, 0, 0.38)",
      hint: "rgba(0, 0, 0, 0.38)"
    }
  },
  action: {
    active: "rgba(0, 0, 0, 0.54)",
    disabled: "rgba(0, 0, 0, 0.26)",
    disabledBackground: "rgba(0, 0, 0, 0.12)",
    hover: "rgba(0, 0, 0, 0.08)",
    hoverOpacity: 0.08,
    selected: "rgba(0, 0, 0, 0.14)"
  },
  typography: {
    fontSize: 16,
    htmlFontSize: 16,
    fontFamily: "\"Roboto\", \"Helvetica\", \"Arial\", sans-serif",
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
    body1: {
      fontFamily: "\"Roboto\", \"Helvetica\", \"Arial\", sans-serif",
      fontSize: "1rem",
      fontWeight: 400,
      letterSpacing: "0.00938em",
      lineHeight: 1.5
    },
    body2: {
      fontFamily: "\"Roboto\", \"Helvetica\", \"Arial\", sans-serif",
      fontSize: "0.875rem",
      fontWeight: 400,
      letterSpacing: "0.01071em",
      lineHeight: 1.43,
    },
    button: {
      fontSize: "0.875rem",
      fontWeight: 500,
      letterSpacing: "0.02857em",
      lineHeight: 1.75,
      textTransform: "uppercase",
      fontFamily: "\"Roboto\", \"Helvetica\", \"Arial\", sans-serif"
    },
    caption: {
      fontFamily: "\"Roboto\", \"Helvetica\", \"Arial\", sans-serif",
      fontWeight: 400,
      fontSize: "0.75rem",
      lineHeight: 1.66,
      letterSpacing: "0.03333em"
    }
  }
});

export default theme
