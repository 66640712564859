import React, { Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Flex from "../Flex"
import Box from "../Box"
import FilterChip from '../filters/FilterChip';

export default function FilterChipsContainer(props) {
  // const selectedCollections = props.filterOptions.collections.filter(collection => props.filterValues.collection_ids.includes(collection.value) ? collection : null)
  
  // const selectedTargetSites = props.filterOptions.targetSites.filter(targetSite => props.filterValues.target_site_ids.includes(targetSite.value) ? targetSite : null)
  
  // const selectedTargetMarkets = props.filterOptions.targetMarkets.filter(targetMarket => props.filterValues.target_market_ids.includes(targetMarket.value) ? targetMarket : null)
  
  // const selectedTherapyTypes = props.filterOptions.therapyTypes.filter(therapyType => props.filterValues.therapy_type_ids.includes(therapyType.value) ? therapyType : null)
  
  // const selectedTechnologyGroups = props.filterOptions.technologyGroups.filter(technologyGroup => props.filterValues.technology_group_ids.includes(technologyGroup.value) ? technologyGroup : null)
  
  return(
    <Fragment>
      
      {/* {selectedTargetSites.map((activeFilter) =>
        <FilterChip 
          key={activeFilter.value}
          activeFilter={activeFilter}
          type='target_site_ids'
          handleDelete={props.handleDelete}
        />
      )}
      {selectedTargetMarkets.map((activeFilter) =>
        <FilterChip 
          key={`${activeFilter.value} - target_market_ids`}
          activeFilter={activeFilter}
          type='target_market_ids'
          handleDelete={props.handleDelete}
        />
      )}
      {selectedTechnologyGroups.map((activeFilter) =>
        <FilterChip 
          key={activeFilter.value}
          activeFilter={activeFilter}
          type='technology_group_ids'
          handleDelete={props.handleDelete}
        />
      )} */}

    </Fragment>
  )
}
