import React from "react"
import Chip from '@material-ui/core/Chip';
import Box from "../Box"

export default function FilterChip(props) {

  function handleChipDelete(e) {
    props.handleDelete(props.value, props.type)
  }

  const { activeFilter, disabled, handleDelete, ...rest } = props;

  return (
    <Box mr={2}>
      <Chip
        label={props.name}
        onDelete={handleDelete ? handleChipDelete : null}
        disabled={props.disabled}
        {...rest}
      />
    </Box>
  )
}
