import React, { Fragment } from "react"
import Paper from "../../Paper"
import { List, ListItem, ListItemText } from "../../lists/"
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction"
import DeleteButton from "../DeleteButton"

export default function AssociatedCompanyList(props) {

  return (
    <Fragment>

      <List>
        <ListItem button key={props.patentCompany.id} role={undefined}>

          <ListItemText primary={props.patentCompany.company.short_name} />

          <ListItemSecondaryAction>
            <DeleteButton onClick={props.handleUnassociateClick} />
          </ListItemSecondaryAction>

        </ListItem>
      </List>

    </Fragment>
  )
}
