import React, {Component} from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Select from "./Select"
import Link from "./Link"

class YearFilter extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    return(
      <div className='mb4'>
        <span className='mr2'>View by year:</span>
        <div className='dib'>
          {this.props.years.map((year) =>
            <span key={year} className='mr2'>
              <Link
                href={`/sectors/${this.props.sector.abbr}/${this.props.entityType}/${this.props.entity.id}/alerts?group_id=${this.props.groupId}&year=${year}`}
                active={this.props.year == year}>
                {year}
              </Link>
            </span>
          )}
        </div>
        {/* <div className='dn-m dib'>
          <Select label='Year' />
        </div> */}
      </div>
    )
  }
}

export default YearFilter
