import React, { Fragment, Component } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import { Table, TableHead, TableRow, TableCell, TableBody, TableHeadSort } from "../tables"
import InvestigatorRow from "./InvestigatorRow"
import Box from "../Box"
import Typography from "@material-ui/core/Typography"

export default class SitesDataTable extends Component {
  constructor(props) {
    super(props)
    this.desc = this.desc.bind(this)
    this.stableSort = this.stableSort.bind(this)
    this.getSorting = this.getSorting.bind(this)
    this.handleRequestSort = this.handleRequestSort.bind(this)
    this.state = { order: 'asc', orderBy: 'text' }
  }

  desc(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = cmp(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
  }

  getSorting(order, orderBy) {
    return order === 'desc' ? (a, b) => this.desc(a, b, orderBy) : (a, b) => -this.desc(a, b, orderBy);
  }

  handleRequestSort(event, property) {
    const isDesc = this.state.orderBy === property && this.state.order === 'desc';

    this.setState({
      order: isDesc ? 'asc' : 'desc',
      orderBy: property
    })
  };

  render() {
    let headers = [{ name: 'text', label: 'Investigator'}, {name: 'facility_name', label: 'Location'}, { name: 'role', label: 'Role' }]
    return(
      <Fragment>

        <Box textAlign='right' mb={2}>
          <Typography variant="body2">
            {`${this.props.showCurrent ? this.props.currentInvestigators.length : this.props.investigators.length} investigators`}
          </Typography>
        </Box>

        <Box overflow='auto' height="80%">
          <Table size='small' stickyHeader={true} aria-label='Investigators data table'>

            <TableHeadSort
              headers={headers}
              order={this.state.order}
              orderBy={this.state.orderBy}
              onRequestSort={this.handleRequestSort}
            />

            <TableBody>
              {this.stableSort((this.props.showCurrent ? this.props.currentInvestigators : this.props.investigators), this.getSorting(this.state.order, this.state.orderBy)).map((investigator) =>
                <InvestigatorRow
                  key={investigator.id}
                  investigator={investigator}
                  currentInvestigators={this.props.currentInvestigators}
                  showCurrent={this.props.showCurrent}
                  sector={this.props.sector}
                />
              )}
            </TableBody>

          </Table>
        </Box>

      </Fragment>
    )
  }
}
