import React from "react"
import PropTypes from "prop-types"
import Paper from "../Paper"
import Flex from "../Flex"
import Box from "../Box"
import Grid from '@material-ui/core/Grid';
import OverviewModule from "./OverviewModule"
import Typography from "@material-ui/core/Typography"
import InvestigatorsList from "./InvestigatorsList"
import PublicationsList from "../publications/DashboardModuleList"

function Profile(props) {
  return (
    <React.Fragment>
      <Grid container component={Box} pb={3} spacing={3}>
        <Grid item xs={12} lg={6}>
          <OverviewModule 
            nihGrant={props.nih_grant}
            nihOrganization={props.nih_organization}
          />
        </Grid>

        <Grid item xs={12} lg={6} container>
          <Grid item container direction="column" spacing={2}>
            <Grid item className='h-40'>
              <Paper>
                <Flex alignItems='center'>
                  <Box>
                    <Typography variant="h6">
                      {`Project Funding Information for ${props.nih_grant.fiscal_year}`}
                    </Typography>
                  </Box>
                </Flex>

                <Box mt={3}>
                  <Flex justifyContent='space-between'>
                    <Box>
                      <label>Total Funding</label>
                      <Box>
                        <b>{`$${props.nih_grant.award_amount.toLocaleString()}`}</b>
                      </Box>
                    </Box>
                    <Box>
                      <label>Direct Costs</label>
                      <Box>
                        <b>{`$${props.nih_grant.direct_cost_amt.toLocaleString()}`}</b>
                      </Box>
                    </Box>
                    <Box>
                      <label>Indirect Costs</label>
                      <Box>
                        <b>{`$${props.nih_grant.indirect_cost_amt.toLocaleString()}`}</b>
                      </Box>
                    </Box>
                  </Flex>
                </Box>
              </Paper>
            </Grid>
            
            <Grid item className='h-30'>
              <Paper>
                <Box>
                  <Typography variant="h6">
                    Investigators
                  </Typography>
                </Box>
                <Box>
                  {props.nih_investigators.length > 0 ? <InvestigatorsList nihInvestigators={props.nih_investigators} sector={props.sector} /> : "No investigators"}
                </Box>
              </Paper>
            </Grid>

            <Grid item>
              <Paper>
                <Box>
                  <Typography variant="h6">
                    Public Health Relevance Statement
                  </Typography>
                </Box>

                <Box>
                  {props.nih_grant.phr_text}
                </Box>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid container justifyContent="center" component={Box} pb={3} spacing={3}>
        <Grid item xs={10} lg={10}>
          <Paper className='h-100'>
            <Flex alignItems='center'>
              <Box mx='auto' className="w-75">
                <Typography variant="h6">
                  Description
                </Typography>
              </Box>
            </Flex>

            <div>
              <Box mx='auto' className="lh-copy w-75">
                {props.nih_grant.abstract_text}
              </Box>
            </div>
          </Paper>
        </Grid>

        {/* <Grid item xs={10} lg={6}>
          <Paper className='h-100'>
            <div>
              <Typography variant="h6">
                Public Health Relevance Statement
              </Typography>
            </div>

            <div className="lh-copy">
              {props.nih_grant.phr_text}
            </div>
          </Paper>
        </Grid> */}
      </Grid>

      <Grid container component={Box} pb={3} spacing={3}>
        <Grid item xs={6} lg={6}>
          <Paper className='h-100'>
            <Box mb={2}>
              <Typography variant="h6">
                Publications
              </Typography>
            </Box>

            <Box>
              <PublicationsList 
                publications={props.manuscripts}
                sector={props.sector}
                emptyMessage='No publications associated with this grant'
              />
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={6} lg={6}>
          <Paper className='h-100'>
            <Box>
              <Typography variant="h6">
                Patents
              </Typography>
            </Box>

            <Box>
              Patents here
            </Box>
          </Paper>
        </Grid>
      </Grid>


    </React.Fragment>
  );
}

Profile.propTypes = {
  nihGrant: PropTypes.object
};

export default Profile
