import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Select from "../../Select"
import TextInput from "../../TextInput"
import Typography from '@material-ui/core/Typography';
import Flex from "../../Flex"
import Box from "../../Box"
import RaisedButton from "../../buttons/RaisedButton"
import DatePicker from "../../DatePicker"

export default class NewProductStatusForm extends Component {
  constructor(props) {
    super(props)
  }

  render() {

    return(
      <Fragment>

        <Flex alignItems='start' width={1}>

          <Box mr={3} width='50%'>
            <Select
              entities={this.props.regulatoryStatuses}
              label='Regulatory Status'
              name='regulatory_status_id'
              value={this.props.productRegulatoryStatus.regulatory_status_id}
              handleChange={this.props.handleChange}
              width='100%'
            />
          </Box>

          <Box mr={3} width='25%'>
            <Select
              entities={this.props.worldRegions}
              label='Region'
              name='world_region_id'
              value={this.props.productRegulatoryStatus.world_region_id}
              handleChange={this.props.handleChange}
              width='100%'
            />
          </Box>

          <Box mr={3} width='25%'>
            <Select
              multiple
              entities={this.props.sectors}
              label='Sector'
              name='sector_ids'
              value={this.props.sector_ids}
              handleChange={this.props.handleSectorChange}
              width='100%'
              renderValue={selected => (
                <Flex flexWrap='wrap'>
                  {selected.join(", ")}
                </Flex>
              )}
            />
          </Box>

          <Box width='25%'>
            <Box>
              <label>Date</label>
            </Box>
            <input
              type="date"
              onChange={this.props.handleDateChange}
              value={this.props.productRegulatoryStatus.date}
            />
            {/* <DatePicker
              name='date'
              label='Date'
              handleChange={this.props.handleDateChange}
              value={this.props.productRegulatoryStatus.date}
            /> */}
          </Box>

          <Box className='ml-auto'>
            <RaisedButton handleClick={this.props.handleSubmitClick}>
              Save Status
            </RaisedButton>
          </Box>

        </Flex>

      </Fragment>
    )
  }
}
