import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Paper from "../../Paper"
import ManuscriptsTable from "./ManuscriptsTable"
import Link from "../../Link"
import Flex from "../../Flex"
import Box from "../../Box"
import update from "immutability-helper"
import Snackbar from "../../Snackbar"
import Typography from "@material-ui/core/Typography"


export default class Manuscripts extends Component {
  constructor(props) {
    super(props)
    this.handleDeleteClick = this.handleDeleteClick.bind(this)
    this.state = { manuscripts: this.props.publications }
  }

  handleDeleteClick(manuscript) {
    $.ajax({
      type: "PUT",
      url: `/admin/publication_trials/${manuscript.manuscript_trial_id}`,
      data: {
        publication_trial: {
          deleted: true, deleted_by: this.props.current_user.id
        }
      },
      dataType: 'json',
      success: (data) => {
        let index = this.state.manuscripts.indexOf(manuscript)

        let manuscripts = update(this.state.manuscripts, {
          $splice: [[index, 1]]
        })

        this.setState({
          manuscripts: manuscripts,
          showSnackbar: true,
          snackbarText: 'Manuscript has been removed'
        })
      },
      error: (data) => {
        this.setState({
          showSnackbar: true,
          snackbarText: 'There was an error removing the trial'
        })
      },
      complete: (data) => {
        setTimeout(() => {
          this.setState({
            showSnackbar: false,
            snackbarText: ''
          })
        }, 4000)
      }
    })
  }

  render() {
    return(
      <Fragment>

        <Paper fullHeight>

          <Box height="100%">

            {this.state.manuscripts.length > 0 ? ''
            :
              <Flex justifyContent='center' height="50%">
                <Box alignSelf='center' maxWidth="50%">
                  <Box>
                    <Typography variant="body1">
                      No publications found for this trial
                    </Typography>
                  </Box>
                </Box>
              </Flex>
            }

            {this.state.manuscripts.length > 0 ?
              <ManuscriptsTable
                manuscripts={this.state.manuscripts}
                handleDeleteClick={this.handleDeleteClick}
              />
            : ''}

          </Box>

        </Paper>

        <Snackbar
          open={this.state.showSnackbar}
          message={this.state.snackbarText}
        />

      </Fragment>
    )
  }
}
