import React from "react"
import Paper from "../../Paper"
import Flex from "../../Flex"
import Box from "../../Box"
import Link from "../../Link"

export default function SmdrPanel(props) {

  return(
    <React.Fragment>

      <Paper fullHeight={true}>

        <Box height="100%" overflow="auto">

          <Flex justifyContent="right">
            <Box>
              <Link href={props.smdrApproval.url} target="_blank">
                View on SMDR site
              </Link>
            </Box>
          </Flex>

          <Flex flexDirection='column' mb={2}>
            <Box width={1} mb={1}>
              <label className='b'>Device Registration No</label>
            </Box>
            <Box width={1}>
              {props.smdrApproval.device_registration_no}
            </Box>
          </Flex>

          <Flex flexDirection='column' mb={2}>
            <Box width={1} mb={1}>
              <label className='b'>Device Name</label>
            </Box>
            <Box width={1}>
              {props.smdrApproval.device_name || "N/A"}
            </Box>
          </Flex>

          <Flex flexDirection='column' mb={2}>
            <Box width={1} mb={1}>
              <label className='b'>Product Owner</label>
            </Box>
            <Box width={1}>
              {props.smdrApproval.product_owner || "N/A"}
            </Box>
          </Flex>

          <Flex flexDirection='column' mb={2}>
            <Box width={1} mb={1}>
              <label className='b'>Registrant</label>
            </Box>
            <Box width={1}>
              {props.smdrApproval.registrant || "N/A"}
            </Box>
          </Flex>

          <Flex flexDirection='column' mb={2}>
            <Box width={1} mb={1}>
              <label className='b'>Registration Date</label>
            </Box>
            <Box width={1}>
              {props.smdrApproval.registration_date || "N/A"}
            </Box>
          </Flex>

          <Flex flexDirection='column' mb={2}>
            <Box width={1} mb={1}>
              <label className='b'>Medical Device Class</label>
            </Box>
            <Box width={1}>
              {props.smdrApproval.medical_device_class || "N/A"}
            </Box>
          </Flex>

          <Flex flexDirection='column' mb={2}>
            <Box width={1} mb={1}>
              <label className='b'>Device System Info</label>
            </Box>
            <Box width={1}>
              {props.smdrApproval.device_system_info || "N/A"}
            </Box>
          </Flex>

          <Flex flexDirection='column' mb={2}>
            <Box width={1} mb={1}>
              <label className='b'>Description</label>
            </Box>
            <Box width={1}>
              {props.smdrApproval.description || "N/A"}
            </Box>
          </Flex>

          
        </Box>

      </Paper>

    </React.Fragment>
  )
}
