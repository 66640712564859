import React from "react"
import ResultItemContainer from "../search/ResultItemContainer"
import Typography from "@material-ui/core/Typography"
import ApplicantDisplay from "../ApplicantDisplay";
import ProductCodeContainer from "../fda_device_classifications/ProductCodeContainer";
import Box from "../Box";
import Flex from "../Flex";
import EventTypeFilter from "../regulatory/EventTypeFilter";

export default function FdaAdverseEventItem(props) {

  function renderSearchDetails(result) {
    const applicant = result.applicant || result.manufacturer_g1_name;
    return (
      <Flex alignItems='baseline'>
        <Box mr={2}>
          {result.event_type ? 
            <EventTypeFilter 
              eventType={result.event_type} 
              handleEventTypeClick={props.handleEventTypeClick}
            />
          : ""}
        </Box>
        <Box mr={2}>
          {applicant ?
            <ApplicantDisplay 
              applicant={applicant} 
              handleApplicantClick={props.handleApplicantClick} 
            />
          : ""}
        </Box>
        <Box>
          {result.fda_device_classifications ? 
            <ProductCodeContainer 
              productCodes={result.fda_device_classifications} 
              handleProductCodeClick={props.handleProductCodeClick} 
            /> 
          : ""}
        </Box>
      </Flex>
    );
  }

  return(
    <ResultItemContainer 
      searchItemType={props.result.record_type}
      searchTitle={props.result.search_title}
      date={props.result.date_of_event}
      searchDescription={props.result.text}
      url={props.result.url || props.result.search_url}
      target="_blank"
      searchDetails={renderSearchDetails(props.result)}
    />
  )
}