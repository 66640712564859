import React, { Fragment } from "react"
import { List, ListItem, ListItemText } from "../../lists"
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction"
import IconButton from "../../IconButton"
import CloseIcon from '@material-ui/icons/Close';

export default function NewInvestigatorsList(props) {
  console.log(props.chosenPeople)
  return (
    <Fragment>
      <List>
        {props.chosenPeople.map((person) =>
          <ListItem key={person.id}>
            <ListItemText 
              primary={`${person.first_name} ${person.last_name}`} 
            />
            
            <ListItemSecondaryAction>
              <IconButton>
                <CloseIcon />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        )}
      </List>
    </Fragment>
  )
}
