import React, { Fragment } from "react"
import { Tabs, Tab } from "../tabs"

export default function ComponentTabs(props) {
  return(
    <Fragment>
      <Tabs variant="fullWidth" value={props.activeIndex} onChange={props.handleChildProductClick} className='bb b--black-10' aria-label="nav tabs">
        {props.childProducts.map((childProduct) =>
          <Tab
            key={childProduct.id}
            label={childProduct.component_short_name || childProduct.short_name}
            value={childProduct.id}
          />
        )}
      </Tabs>
    </Fragment>
  )
}
