import React, { Component, Fragment } from "react"
import Paper from "../Paper"
import Flex from "../Flex"
import Box from "../Box"
import Pagination from '@material-ui/lab/Pagination';
import Typography from "@material-ui/core/Typography"
import { Button } from "../buttons"
import { withSnackbar } from "../SnackbarContainer"
import update from "immutability-helper"
import { Table, TableRow, TableCell, TableBody } from "../tables"
import { List, ListItem, ListItemText } from "../lists"
import AccountLink from "./AccountLink"
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Switch from "../Switch"


export default function AccountListItem(props) {

  function handleGroupSwitchChange(userTwitterAccount) {
    props.handleGroupSwitchChange(userTwitterAccount, props.account)
  }

  let userTwitterAccount = props.userTwitterAccounts.filter(userTwitterAccount => userTwitterAccount.twitter_account_id == props.account.id)[0]

  return(
    <ListItem divider>
      <ListItemText
        primary={
          <AccountLink
            display={props.account.account_name}
            accountUserName={props.account.username}
          />
        }
        secondary={props.account.username}
      />

      <ListItemSecondaryAction>
        <Switch
          edge="end"
          checked={userTwitterAccount ? true : false}
          handleChange={() => { handleGroupSwitchChange(userTwitterAccount) }}
        />
      </ListItemSecondaryAction>
    </ListItem>
  )
}
