import React, { Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Link from "../Link"
import Expandable from "../Expandable"
import Box from "../Box"

export default function FundingEvent(props) {

  console.log(props.fundingEvent)

  return(
    <Expandable header={props.fundingEvent.series_name} style={{backgroundColor: 'rgba(0, 0, 0, 0.04)'}}>
      <Box mt={3}>
        {props.fundingEvent.amount ?
          <div className='mb2 pl3 mt3'>
            <span className='pb1'>Amount: </span>
            <span>{props.fundingEvent.amount}</span>
          </div>
        : ''}
        {props.fundingEvent.amount_offered ?
          <div className='mb2 pl3 mt3'>
            <span className='pb1'>Amount offered: </span>
            <span>{`$${numberWithCommas(props.fundingEvent.amount_offered)}`}</span>
          </div>
        : ''}
        {props.fundingEvent.amount_raised ?
          <div className='mb2 pl3'>
            <span className='pb1'>Amount raised: </span>
            <span>{`$${numberWithCommas(props.fundingEvent.amount_raised)}`}</span>
          </div>
        : ''}
        {props.fundingEvent.date ?
          <div className='mb2 pl3'>
            <span className='pb1'>Date of Announcement: </span>
            <span>{humanDateFormat(props.fundingEvent.date)}</span>
          </div>
        : ''}
        {props.fundingEvent.offering_date ?
          <div className='mb2 pl3'>
            <span className='pb1'>Offering Date: </span>
            <span>{humanDateFormat(props.fundingEvent.offering_date)}</span>
          </div>
        : ''}
        {props.fundingEvent.date_first_sale ?
          <div className='mb2 pl3'>
            <span className='pb1'>Date first sold: </span>
            <span>{humanDateFormat(props.fundingEvent.date_first_sale)}</span>
          </div>
        : ''}
        {props.fundingEvent.duration ?
          <div className='mb2 pl3'>
            <span className='pb1'>Closing date: </span>
            <span>{props.fundingEvent.duration}</span>
          </div>
        : ''}
        {props.fundingEvent.closed_date ?
          <div className='mb2 pl3'>
            <span className='pb1'>Closing date: </span>
            <span>{props.fundingEvent.closed_date}</span>
          </div>
        : ''}
        {props.fundingEvent.securities_type ?
          <div className='mb2 pl3'>
            <span className='pb1'>Type of securities: </span>
            <span>{props.fundingEvent.securities_type}</span>
          </div>
        : ''}
        {props.fundingEvent.filing_type ?
          <div className='mb2 pl3'>
            <span className='pb1'>Filing type: </span>
            <span>{props.fundingEvent.filing_type}</span>
          </div>
        : ''}
        {props.fundingEvent.number_investors ?
          <div className='mb2 pl3'>
            <span className='pb1'>Number of investors: </span>
            <span>{props.fundingEvent.number_investors}</span>
          </div>
        : ''}
        {props.fundingEvent.investors.length > 0 ?
          <div className='mb2 pl3'>
            <span className='pb1'>Investors: </span>
            <span>
              {props.fundingEvent.investors.map((investor) =>
                <Box key={investor.id} ml={2} pt={1}>
                  {investor.name}
                </Box>
              )}
            </span>
          </div>
        : ''}
      </Box>
    </Expandable>
  )
}
