import React, { Fragment, Component } from "react"
import Flex from "../Flex"
import Box from "../Box"
import Paper from "../Paper"
import Grid from '@material-ui/core/Grid';
import Typography from "@material-ui/core/Typography"
import EditIcon from '@material-ui/icons/Edit';
import IconButton from "../IconButton"
import EditPassword from "../signup/EditPassword"
import { Tabs, Tab, TabPanel } from "../tabs"
import Collapse from '@material-ui/core/Collapse';
import Snackbar from "../Snackbar"
import ProfileForm from "./ProfileForm"
import UserSectors from "./UserSectors"
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import AlertsCustomizationForm from "./AlertsCustomizationForm"
import { withSnackbar } from "../SnackbarContainer"

class Settings extends Component {
  constructor(props) {
    super(props)
    this.handleTabClick = this.handleTabClick.bind(this)
    this.handleSwitchChange = this.handleSwitchChange.bind(this)
    this.handleGroupSwitchChange = this.handleGroupSwitchChange.bind(this)
    this.handlePreferenceSwitchChange = this.handlePreferenceSwitchChange.bind(this)
    this.state = { userAlertPreference: this.props.alert_preference, userAlertGroups: this.props.user_alert_groups, activeIndex: 0 }
  }

  handleTabClick(e, newValue) {
    this.setState({
      activeIndex: newValue
    })
  }

  handleSwitchChange(e) {
    this.props.snackbarShowMessage("Sectors are added by your company")
  }

  handleGroupSwitchChange(alertGroup, userAlertGroup) {
    if (userAlertGroup) {
      $.ajax({
        type: "PUT",
        url: `/user_alert_groups/${userAlertGroup.id}`,
        data: {
          user_alert_group: {
            deleted: userAlertGroup.deleted ? null : true
          }
        },
        dataType: 'json',
        success: (data) => {
          this.setState({
            userAlertGroups: data.user_alert_groups
          })

          let msg = userAlertGroup.deleted ? "You will now receive alerts from the selected group" : "You will no longer receive alerts from the selected group"

          this.props.snackbarShowMessage(msg)
        },
        error: (data) => {
          this.props.snackbarShowMessage("There was an error updating your alert preferences. Please try again.", "error")
        }
      })
    } else {
      $.ajax({
        type: "POST",
        url: `/user_alert_groups`,
        data: {
          user_alert_group: {
            user_id: this.props.user.id, alert_group_id: alertGroup.id
          }
        },
        dataType: 'json',
        success: (data) => {
          this.setState({
            userAlertGroups: data.user_alert_groups
          })

          let msg = userAlertGroup.deleted ? "You will now receive alerts from the selected group" : "You will no longer receive alerts from the selected group"

          this.props.snackbarShowMessage(msg)
        },
        error: (data) => {
          this.props.snackbarShowMessage("There was an error updating your alert preferences. Please try again.", "error")
        }
      })
    }
  }

  handlePreferenceSwitchChange(value, alertPreference) {
    $.ajax({
      type: "PUT",
      url: `/user_alert_preferences/${alertPreference.id}`,
      data: {
        user_alert_preference: {
          daily: alertPreference.daily ? null : true, weekly: alertPreference.weekly ? null : true
        }
      },
      dataType: 'json',
      success: (data) => {
        // setUserAlertPreference(data.user_alert_preference)
        this.setState({
          userAlertPreference: data.user_alert_preference
        })
        this.props.snackbarShowMessage("Your alert preference has been updated")
      },
      error: (data) => {
        this.props.snackbarShowMessage("There was an error updating your alert preference. Please try again.", "error")
      }
    })
  }

  render() {
    return(
      <Fragment>

        <Grid container spacing={3} component={Box} justifyContent="center" pb={3}>
          <Grid item xs={12}>
            <Paper fullHeight>

              <Box height="100%">
                <Tabs centered value={this.state.activeIndex} onChange={this.handleTabClick} className='bb b--black-10'>
                  <Tab label="Profile" />
                  <Tab label="Sectors" />
                  <Tab label="Alerts" />
                </Tabs>

                <Box height="90%" overflow="auto">
                  <Collapse in={this.state.activeIndex == 0} timeout="auto" unmountOnExit>
                    <ProfileForm
                      user={this.props.user}
                    />
                  </Collapse>

                  <Collapse in={this.state.activeIndex == 1} timeout="auto" unmountOnExit>
                    <UserSectors
                      user={this.props.user}
                      userSectors={this.props.user_sectors}
                      sectors={this.props.sectors}
                      handleSwitchChange={this.handleSwitchChange}
                    />
                  </Collapse>

                  <Collapse in={this.state.activeIndex == 2} timeout="auto" unmountOnExit>
                    <AlertsCustomizationForm
                      user={this.props.user}
                      alertGroups={this.props.alert_groups}
                      userAlertGroups={this.state.userAlertGroups}
                      alertPreference={this.state.userAlertPreference}
                      handlePreferenceSwitchChange={this.handlePreferenceSwitchChange}
                      handleGroupSwitchChange={this.handleGroupSwitchChange}
                    />
                  </Collapse>
                </Box>
              </Box>

            </Paper>
          </Grid>
        </Grid>

      </Fragment>
    )
  }
}

export default withSnackbar(Settings)
