import React, { Component } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Card from "../cards/Card.jsx"
import CardSection from "../cards/CardSection.jsx"
import Link from "../Link"
import Box from "../Box"

export default class PersonCard extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <Card background='bg-light-yellow'>
        <CardSection noBorder={true}>
          <h4>Details</h4>

          {this.props.topSite ?
            <Box mb={2}>
              <div className='b'>Top Site</div>
              <div>
                {this.props.topSite}
              </div>
            </Box>
          : ''}

          {this.props.topConference ?
            <Box mb={2}>
              <Box className='b'>
                Top Conference
              </Box>
              <Box>
                {this.props.topConference}
              </Box>
            </Box>
          : ''}

          {this.props.topJournal ?
            <Box mb={2}>
              <div className='b'>Top Journal</div>
              <div>
                {this.props.topJournal}
              </div>
            </Box>
          : ''}

        </CardSection>
      </Card>
    )
  }
}
