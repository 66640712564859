import React from "react"
import ListItem from '@material-ui/core/ListItem';
import theme from "../theme"
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    color: "#e67634"
  },
});

export default function ListItemLink(props) {
  const { active = false, className, ...rest } = props
  const classes = useStyles()
  return (
    <ListItem 
      button 
      component="a" 
      className={`${className} ${active ? classes.root : ''}`}
      {...rest} 
    />
    
  )
}
