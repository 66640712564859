import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import Paper from "../Paper"
import Flex from "../Flex"
import Box from "../Box"
import Link from "../Link"


export default class Profile extends Component {
  constructor(props) {
    super(props)
  }
  
  render() {
    const sourceEntityHash = this.props.source.entity_source_display_hash
    return(
      <Fragment>
        
        <Flex>
          
          <Box width={[1, 3/5]}>
            <Paper>
              <Box mb={2}>
                <label className='b'>Title</label>
                <Box>{sourceEntityHash.source_title}</Box>
              </Box>
              
              <Box mb={2}>
                <label className='b'>Kind</label>
                <Box>{sourceEntityHash.source_kind}</Box>
              </Box>
              
              <Box mb={2}>
                <label className='b'>URL</label>
                <Box>
                  {sourceEntityHash.source_url ? 
                    <Link href={sourceEntityHash.source_url} target="_blank" className='underline'>
                      {sourceEntityHash.source_url}
                    </Link>
                  : "N/A"}
                </Box>
              </Box>
            </Paper>
          </Box>
          
          {/* <Box width={[1, 1/3]} ml='auto'>
            <Paper>
              More source info
            </Paper>
          </Box> */}
          
        </Flex>
        
        
      </Fragment>
    )
  }
}
