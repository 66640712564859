import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import update from "immutability-helper"
import PersonForm from "../people/Form"
import Paper from "../../Paper"
import Flex from "../../Flex"
import Box from "../../Box"
import Select from "../../Select"
import TextInput from "../../TextInput"
import Typography from "@material-ui/core/Typography"
import ButtonLayout from "../ButtonLayout"
import { RaisedButton, Button } from "../../buttons"


export default function Form(props) {
  return(
    <Fragment>
      <Box mb={3}>
        <Typography variant="h6">
          {props.personName.text}
        </Typography>
      </Box>
      <Flex width={1} mb={5} alignItems='center' justifyContent='space-between'>
        <Box width={1/3}>
          <TextInput
            name='title'
            label='Title'
            value={props.companyPerson.title}
            onChange={props.handleChange}
            fullWidth
          />
        </Box>

        <Box width={1/3} mx={3}>
          <TextInput
            name='previous_affiliation'
            label='Previous Affiliation'
            value={props.companyPerson.previous_affiliation}
            onChange={props.handleChange}
            fullWidth
          />
        </Box>

        <Box width={1/3}>
          <Select
            entities={props.roles}
            label='Role'
            name='role'
            value={props.companyPerson.role}
            handleChange={props.handleRoleChange}
          />
        </Box>
      </Flex>

      <ButtonLayout>
        <RaisedButton handleClick={props.handleSaveClick}>
          Save Company Person
        </RaisedButton>
      </ButtonLayout>
    </Fragment>
  )
}
