import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import NewProductStatusForm from "./NewProductStatusForm"
import RegulatoryStatusForm from "./RegulatoryStatusForm"
import DevelopmentStatusForm from "./DevelopmentStatusForm"
import ProductStatusRow from "./ProductStatusRow"
import Flex from "../../Flex"
import Box from "../../Box"
import Typography from '@material-ui/core/Typography';
import update from "immutability-helper"
import IconButton from "../../IconButton"
import StatusDisplayContainer from "./StatusDisplayContainer"
import { withSnackbar } from "../../SnackbarContainer"
import LoadingContainer from "../../LoadingContainer"

class StatusForm extends Component {
  constructor(props) {
    super(props)
    this.handleDeleteClick = this.handleDeleteClick.bind(this)
    this.handleActiveClick = this.handleActiveClick.bind(this)
    this.handleSubmitClick = this.handleSubmitClick.bind(this)
    this.handleDateChange = this.handleDateChange.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleSectorChange = this.handleSectorChange.bind(this)
    this.handleSectorDelete = this.handleSectorDelete.bind(this)
    this.state = { productRegulatoryStatuses: '', regulatoryStatuses: '', productRegulatoryStatus: '', sector_ids: [], loading: false, showSnackbar: false, snackbarText: '' }
  }

  componentDidMount() {
    $.ajax({
      type: "GET",
      url: `/admin/products/${this.props.product.id}/regulatory_status`,
      dataType: 'json',
      success: (data) => {
        let productRegulatoryStatus = update(this.state.productRegulatoryStatus, {
          $set: { source_id: this.props.source ? this.props.source.source_id : null, product_id: this.props.product.id, created_by: this.props.currentUser.id, date: new Date() }
        })

        this.setState({
          productRegulatoryStatuses: data.product_regulatory_statuses,
          regulatoryStatuses: data.regulatory_statuses,
          productRegulatoryStatus: productRegulatoryStatus
        })
      },
      error: (data) => {
        this.setState({
          loading: false
        })
        this.props.snackbarShowMessage("There was an error fetching the data. Please refresh the page. If the issue persists, create an asana task.", "error")
      }
    })
  }

  handleDateChange(e) {
    console.log(e.target.value)

    const productRegulatoryStatus = update(this.state.productRegulatoryStatus, {
      date: { $set: e.target.value }
    })

    this.setState({
      productRegulatoryStatus: productRegulatoryStatus
    })
  }

  handleChange(name, value) {
    const productRegulatoryStatus = update(this.state.productRegulatoryStatus, {
      [name]: { $set: value }
    })

    this.setState({
      productRegulatoryStatus: productRegulatoryStatus
    })
  }

  handleSectorChange(name, value) {
    this.setState({
      sector_ids: value
    })
  }

  handleSubmitClick(e) {
    if (this.state.productRegulatoryStatus.source_id) {
      $.post("/admin/product_regulatory_statuses", { product_regulatory_status: this.state.productRegulatoryStatus, sector_ids: this.state.sector_ids }, (data) => {
        this.setState({
          productRegulatoryStatuses: data.statuses
        })
        this.props.snackbarShowMessage("Status has been added")
      }, 'json').fail((data) => {
        this.props.snackbarShowMessage(`Status could not be added. Please try again. If this persists, please write up an Asana task`)
      })
    } else {
      this.props.snackbarShowMessage("You must choose a source before adding a status")
    }
  }

  handleSectorDelete(regulatoryStatusSectorId) {
    $.ajax({
      type: "PUT",
      url: `/admin/product_regulatory_status_sectors/${regulatoryStatusSectorId}`,
      data: {
        product_id: this.props.product.id, product_regulatory_status_sector: { deleted: true, deleted_by: this.props.currentUser.id }
      },
      dataType: "JSON",
      success: (data) => {
        this.setState({
          productRegulatoryStatuses: data.product_regulatory_statuses
        })
      }
    })
  }

  handleActiveClick(productStatus) {
    const active = productStatus.active ? null : true
    $.ajax({
      type: 'PUT',
      url: `/admin/product_regulatory_statuses/${productStatus.id}`,
      data: {
        product_regulatory_status: {
          active: active, updated_by: this.props.currentUser.id
        }
      },
      dataType: 'json',
      success: (data) => {
        let index = this.state.productRegulatoryStatuses.indexOf(productStatus)

        let productRegulatoryStatuses = update(this.state.productRegulatoryStatuses, {
          [productStatus.id]: { [0]: { active: { $set: active } }}
        })

        this.setState({
          productRegulatoryStatuses: productRegulatoryStatuses
        })
      }
    })
  }

  handleDeleteClick(productStatus) {
    const deleted = productStatus.deleted ? false : true
    $.ajax({
      type: 'PUT',
      url: `/admin/product_regulatory_statuses/${productStatus.id}`,
      data: {
        product_regulatory_status: {
          deleted: deleted, deleted_by: this.props.currentUser.id
        }
      },
      dataType: 'json',
      success: (data) => {

        let productRegulatoryStatuses = update(this.state.productRegulatoryStatuses, {
          $unset: [data.product_regulatory_status.id]
        })

        this.setState({
          productRegulatoryStatuses: productRegulatoryStatuses
        })
      }
    })
  }

  render() {
    return(
      <Fragment>

        <LoadingContainer loading={this.state.loading}>
          <Box height="100%">
            <Box height="90%" overflow='auto'>
              <NewProductStatusForm
                regulatoryStatuses={this.state.regulatoryStatuses}
                productRegulatoryStatus={this.state.productRegulatoryStatus}
                worldRegions={this.props.worldRegions}
                sectors={this.props.sectors}
                sector_ids={this.state.sector_ids}
                handleChange={this.handleChange}
                handleDateChange={this.handleDateChange}
                handleSectorChange={this.handleSectorChange}
                handleSubmitClick={this.handleSubmitClick}
              />

              <Box mt={4}>
                <Typography variant="h6" component="h6">
                  Statuses
                </Typography>

                <StatusDisplayContainer
                  productRegulatoryStatuses={this.state.productRegulatoryStatuses}
                  handleDeleteClick={this.handleDeleteClick}
                  handleActiveClick={this.handleActiveClick}
                  handleSectorDelete={this.handleSectorDelete}
                />

              </Box>
            </Box>
          </Box>
        </LoadingContainer>
      </Fragment>
    )
  }
}

export default withSnackbar(StatusForm)
