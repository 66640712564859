import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import SourceForm from "../SourceForm"
import ProfileForm from "./ProfileForm"
import Paper from "../../Paper"
import TabSection from "../../TabSection"
import Snackbar from "../../Snackbar"
import Chip from '@material-ui/core/Chip';
import update from "immutability-helper"
import Typography from "@material-ui/core/Typography"
import { Tabs, Tab } from '../../tabs';

class EditContainer extends Component {
  constructor(props) {
    super(props)
    this.handleActiveIndexUpdate = this.handleActiveIndexUpdate.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handlePublicChange = this.handlePublicChange.bind(this)
    this.handleArchivedChange = this.handleArchivedChange.bind(this)
    this.handleSaveClick = this.handleSaveClick.bind(this)
    this.handleSourceChange = this.handleSourceChange.bind(this)
    this.handleDeletedChange = this.handleDeletedChange.bind(this)
    this.state = { company: this.props.company, source: null, activeIndex: 0 }
  }

  componentDidMount() {
    let company = update(this.state.company, {
      user_id: { $set: this.props.current_user.id }
    })
    this.setState({
      company: company
    })
  }

  handleActiveIndexUpdate(e, newValue) {
    this.setState({
      activeIndex: newValue
    })
  }

  handleArchivedChange(e) {
    const value = e.target.checked
    let company = update(this.state.company, { archived: { $set: value }})

    this.setState({
      company: company
    })
  }

  handleDeletedChange(e) {
    const value = e.target.checked

    let company = update(this.state.company, { 
      deleted: { $set: value },
      deleted_by: { $set: { deleted_by: this.props.current_user.id }}
    })

    this.setState({
      company: company
    })
  }

  handlePublicChange(e) {
    const value = e.target.checked
    let company = update(this.state.company, { public: { $set: value }})

    this.setState({
      company: company
    })
  }

  handleChange(e) {
    const name = e.currentTarget.name
    const value = e.currentTarget.value
    let company = update(this.state.company, { [name]: { $set: value } })

    this.setState({
      company: company
    })

  }

  handleSaveClick(e) {
    $.ajax({
      type: 'PUT',
      url: `/admin/companies/${this.state.company.id}`,
      data: {
        company: this.state.company
      },
      dataType: 'json',
      success: (data) => {
        this.setState({
          company: data.company,
          showSnackbar: true,
          snackbarText: 'The company was successfully saved'
        })
      },
      error: (data) => {
        this.setState({
          showSnackbar: true,
          snackbarText: 'The company could not be saved. Please try again.'
        })
      },
      complete: (data) => {
        setTimeout(() => {
          this.setState({
            showSnackbar: false,
            snackbarText: ''
          })
        }, 4000)
      }
    })
  }

  handleSourceChange(entity, sourceType) {
    if (sourceType == 'recently_used') {
      this.setState({
        source: entity
      })
    } else {
      $.get("/admin/sources/find_source", { source_type: sourceType, entity_id: entity.id }, (data) => {
        if (data.source) {
          let company = update(this.state.company, {
            source_id: { $set: data.source.source_id }
          })
          this.setState({
            source: data.source,
            company: company
          })
        } else {
          $.post("/admin/sources/filter", { source: { kind: sourceType, entity_id: entity.id } }, (data) => {
            let company = update(this.state.company, {
              source_id: { $set: data.source.source_id }
            })
            this.setState({
              source: data.source,
              company: company
            })
          })
        }
      }, 'json')
    }
  }

  render() {
    return(
      <Fragment>

        <Paper>

          <div className='mb4'>
            <Tabs variant="fullWidth" value={this.state.activeIndex} onChange={this.handleActiveIndexUpdate} className='bb b--black-10' aria-label="nav tabs">
              <Tab label='Source' value='source' />
              <Tab label='Profile' value='profile' />
              <Tab label='Acquisitions' value='acquisitions' />
            </Tabs>
          </div>

          <div className='mb4 tc'>
            {this.state.source ?
              <Chip
                label={this.state.source.source_title}
              />
            : <div>Please choose a source</div>}
          </div>

          {this.state.activeIndex == 'source' ?
            <SourceForm
              company={this.state.company}
              source={this.state.source}
              sources={this.props.sources}
              sourceTypes={this.props.source_types}
              sourceType={this.props.source_type}
              handleSourceChange={this.handleSourceChange}
            />
          : ''}

          {this.state.activeIndex == 'profile' ?
            <ProfileForm
              company={this.state.company}
              companies={this.props.companies}
              source={this.state.source}
              handleChange={this.handleChange}
              handlePublicChange={this.handlePublicChange}
              handleArchivedChange={this.handleArchivedChange}
              handleDeletedChange={this.handleDeletedChange}
              handleSaveClick={this.handleSaveClick}
            />
          : ''}

          {this.state.activeIndex == 'acquisitions' ?
            <Typography variant="h6" component="h6">COMING SOON</Typography>
          : ''}

        </Paper>

        <Snackbar
          open={this.state.showSnackbar}
          message={this.state.snackbarText}
        />

      </Fragment>
    )
  }
}

export default EditContainer
