import React, { Component } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Link from "../Link"
import AlertHeader from "./AlertHeader"
import Typography from '@material-ui/core/Typography';

// - update_count = trial.find_update_count(trial_update.trial_source_id)
// - pluralized_header = pluralize(update_count, 'new result', 'new results')
// .alert-header= "#{pluralized_header} including #{link_to trial_update.section, results_sector_trial_path(@sector.abbr, trial_update.trial_id, section_id: trial_update.id)}".html_safe


class TrialUpdateAlertHeader extends Component {
  constructor(props) {
    super(props)
    this.state = { trialUpdate: '', updateCount: '' }
  }

  UNSAFE_componentWillMount() {
    this.findUpdateCount()
  }

  findUpdateCount() {
    $.ajax({
      type: 'GET',
      url: `/admin/trial_updates/${this.props.alert.trial_update_id}/update_count`,
      dataType: 'json',
      success: (data) => {
        this.setState({
          trialUpdate: data.trial_update,
          updateCount: data.update_count
        })
      }
    })
  }

  render() {
    return(
      <div>
        <AlertHeader alert={this.props.alert} />
        <div className='mt1'>
          <Typography className='mv0'>
            <Link href='#'>
              {`${this.state.updateCount} new results including ${this.state.trialUpdate.section_name}`}
            </Link>
          </Typography>
          {/* <Body1><Link href='#'>{this.state.trialUpdate.section_name}</Link></Body1> */}
        </div>
      </div>
    )
  }

}


export default TrialUpdateAlertHeader
