import React, { Component, Fragment } from "react"
import PropTypes from "prop-types"
import ListRecord from "./ListRecord"
import { Table, TableHead, TableRow, TableCell, TableBody } from "../tables"
import Box from "../Box"
import TableHeadSort from "../tables/TableHeadSort"

export default class CompaniesTable extends Component {
  constructor(props) {
    super(props)
    this.handleRequestSort = this.handleRequestSort.bind(this)
    this.desc = this.desc.bind(this)
    this.stableSort = this.stableSort.bind(this)
    this.getSorting = this.getSorting.bind(this)
    this.state = { order: 'asc', orderBy: 'full_name' }
  }

  desc(a, b, orderBy) {
    let bOrderKey = orderBy == 'full_name' ? b[orderBy].toLowerCase() : b[orderBy]
    let aOrderKey = orderBy == 'full_name' ? a[orderBy].toLowerCase() : a[orderBy]
    if (bOrderKey < aOrderKey) {
      return -1;
    }
    if (bOrderKey > aOrderKey) {
      return 1;
    }
    return 0;
  }

  stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = cmp(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
  }

  getSorting(order, orderBy) {
    return order === 'desc' ? (a, b) => this.desc(a, b, orderBy) : (a, b) => -this.desc(a, b, orderBy);
  }

  handleRequestSort(event, property) {
    const isDesc = this.state.orderBy === property && this.state.order === 'desc';

    this.setState({
      order: isDesc ? 'asc' : 'desc',
      orderBy: property
    })
  };

  render() {
    return(
      <Box overflow='auto' height="80%">

        <Table size='small' aria-label='companies table' stickyHeader={true}>

          <TableHeadSort
            headers={[{ name: 'full_name', label: 'Name' }, { name: 'created_at', label: 'Date Added' }, { name: 'n_products', label: '# of Products', align: 'right' }]}
            order={this.state.order}
            orderBy={this.state.orderBy}
            onRequestSort={this.handleRequestSort}
          />

          <TableBody>
            {this.stableSort(this.props.companies, this.getSorting(this.state.order, this.state.orderBy))
              .map((company) =>
                <ListRecord
                  key={company.id}
                  company={company}
                  sector={this.props.sector}
                  handleFavoriteClick={this.props.handleFavoriteClick}
                />
            )}
          </TableBody>
        </Table>

      </Box>
    )
  }
}
