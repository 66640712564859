import React, { Fragment, Component } from "react"
import Paper from "../../Paper"
import Flex from "../../Flex"
import Box from "../../Box"
import { Table, TableRow, TableHead, TableCell, TableBody } from "../../tables"
import Typography from "@material-ui/core/Typography"
import update from "immutability-helper"
import TimelineChartContainer from "./ChartContainer"
import { Tabs, Tab } from "../../tabs"
import TrialProgressTable from "./TrialProgressTable"
import TimelineIcon from '@material-ui/icons/Timeline';
import ListIcon from '@material-ui/icons/List';
import CompareIcon from '@material-ui/icons/Compare';
import IconButton from "../../IconButton"

export default class Container extends Component {
  constructor(props) {
    super(props)
    this.state = { chartData: this.props.chart_data, view: 'table' }
  }

  render () {
    return (
      <Fragment>
        <Paper minFullHeight>

          <Flex>
            <Box ml="auto">
              <IconButton color={this.state.view == 'table' ? 'primary' : 'default'} onClick={ () => { this.setState({ view: 'table' })}}>
                <ListIcon />
              </IconButton>

              <IconButton color={this.state.view == 'timeline' ? 'primary' : 'default'} tooltip='View Timeline' onClick={ () => { this.setState({ view: 'timeline' })}}>
                <TimelineIcon />
              </IconButton>

              <IconButton component='a' target="_blank" tooltip='Compare trials'>
                <CompareIcon />
              </IconButton>
            </Box>
          </Flex>


          <Box mt={3}>
            <TimelineChartContainer
              key={this.state.chartData}
              title='Product Timeline'
              chartId='product-timeline-chart'
              chartData={this.state.chartData}
              currentTime={this.props.current_time}
            />
          </Box>

          <Tabs centered value={0} className='bb b--black-10'>
            <Tab label='Data' />
          </Tabs>

          <Box mt={2}>
            <TrialProgressTable
              regulatoryTrials={this.props.regulatory_trials}
              averageIdeDuration={this.props.average_ide_duration}
              trialsForAverage={this.props.trials_for_average}
              sector={this.props.sector}
            />
          </Box>


        </Paper>
      </Fragment>
    )
  }
}
