import React from "react"
import { TableRow, TableCell } from "../../tables"
import Box from "../../Box"
import Switch from "../../Switch"
import Link from "../../Link"

export default function StagedAssociatorRow(props) {

  function handleStatusChange(e) {
    e.preventDefault()
    props.trial.trial_associator_status ? props.handleStatusUpdate(props.trial.trial_associator_status, props.trial) : props.handleStatusChange(props.trial)
  }
  return (
    <TableRow>
      <TableCell>
        <Link href={`/admin/trials/${props.trial.id}/associator?sector_id=${props.sectorId}`} target="_blank">
          <Box>
            {props.trial.acronym || props.trial.short_name}
          </Box>
        </Link>
      </TableCell>

      <TableCell>
        {props.trial.predicted_count}
      </TableCell>

      <TableCell>
        <Switch
          checked={props.trial.trial_associator_status && props.trial.trial_associator_status.status ? true : false}
          handleChange={handleStatusChange}
        />
      </TableCell>
    </TableRow>
  )

}
