import React from "react"
import Paper from "../../Paper"
import Box from "../../Box"
import { withSnackbar } from "../../SnackbarContainer"
import { Table, TableRow, TableHead, TableBody, TableCell } from "../../tables"
import Typography from "@material-ui/core/Typography"
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Link from "../../Link"
import DeleteButton from "../DeleteButton"
import update from "immutability-helper"
import Button from "../../buttons/Button"

class Profile extends React.Component {
  constructor(props) {
    super(props)
    this.handleDeleteProduct = this.handleDeleteProduct.bind(this)
    this.state = { newsArticle: this.props.news_article, alerts: this.props.alerts, products: this.props.products }
  }

  handleDeleteProduct(productNewsArticle) {
    $.ajax({
      type: 'PUT',
      url: `/admin/product_news_articles/${productNewsArticle.product_news_article_id}`,
      data: {
        product_news_article: {
          deleted: true, deleted_by: this.props.current_user.id
        }
      },
      dataType: 'json',
      success: (data) => {
        let index = this.state.products.indexOf(productNewsArticle)

        let products = update(this.state.products, {
          $splice: [[index,1]]
        })

        this.setState({
          products: products
        })
      },
      error: (data) => {
        this.props.snackbarShowMessage("The product could not be removed. Please try again.", "error")
      }
    })
  }

  render() {
    return(
      <React.Fragment>
        <Box width={3/4}>

          <ExpansionPanel defaultExpanded={true}>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} ariacontrols={`alerts-panel`} id={`Alerts-header`}>
              <Box>
                <Typography className="f4">
                  Profile
                </Typography>
              </Box>
            </ExpansionPanelSummary>

            <ExpansionPanelDetails>
              <Box>
                <Box mb={2}>
                  <div className='b'>Title</div>
                  <div>{this.props.news_article.title}</div>
                </Box>
                <Box mb={2}>
                  <div className='b'>URL</div>
                  <div>
                    <Link href={this.props.news_article.url} target="_blank">
                      {this.props.news_article.url}
                    </Link>
                  </div>
                </Box>
                <Box mb={2}>
                  <div className='b'>Date</div>
                  <div>{humanDateFormat(this.props.news_article.date)}</div>
                </Box>

                <Box ml='auto'>
                  <Button component="a" href={`/admin/news_articles/${this.props.news_article.id}/edit`}>
                    Edit
                  </Button>
                </Box>
              </Box>
            </ExpansionPanelDetails>

          </ExpansionPanel>


          <ExpansionPanel defaultExpanded={true}>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} ariacontrols={`alerts-panel`} id={`Alerts-header`}>
              <Box>
                <Typography className="f4">
                  Alerts
                </Typography>
              </Box>
            </ExpansionPanelSummary>

            <ExpansionPanelDetails>
              {this.props.alerts.length > 0 ?
                <Table size='small'>
                  <TableHead>
                    <TableRow>
                      <TableCell>Date</TableCell>
                      <TableCell>Body</TableCell>
                      <TableCell>Live</TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {this.props.alerts.map((alert) =>
                      <TableRow key={alert.id}>
                        <TableCell>{humanDateFormat(alert.date)}</TableCell>
                        <TableCell>
                          <div dangerouslySetInnerHTML={{ __html: alert.body }} />
                        </TableCell>
                        <TableCell>{alert.live ? "YES" : "NO"}</TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              :
                <Box>No Alerts</Box>
              }
            </ExpansionPanelDetails>

          </ExpansionPanel>


          <ExpansionPanel defaultExpanded={true}>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} ariacontrols={`products-panel`} id={`products-header`}>
              <Typography className="f4">
                Products
              </Typography>
            </ExpansionPanelSummary>

            <ExpansionPanelDetails>
              {this.state.products.length > 0 ?
                <Table size='small'>
                  <TableHead>
                    <TableRow>
                      <TableCell>Product</TableCell>
                      <TableCell>Search Term</TableCell>
                      <TableCell>Score</TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {this.state.products.map((product) =>
                      <TableRow key={product.id}>
                        <TableCell>{product.short_name}</TableCell>
                        <TableCell>{product.search_term}</TableCell>
                        <TableCell>{product.score}</TableCell>
                        <TableCell>
                          <DeleteButton onClick={() => { this.handleDeleteProduct(product) }} />
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              : <Box>No Products</Box>}
            </ExpansionPanelDetails>

          </ExpansionPanel>

          {this.props.news_article_text ?
            <ExpansionPanel defaultExpanded={true}>
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} ariacontrols={`text-panel`} id={`text-header`}>
                <Typography className="f4">
                  Text
                </Typography>
              </ExpansionPanelSummary>

              <ExpansionPanelDetails>
                <Box>
                  <p>{this.props.news_article_text.text}</p>
                </Box>
              </ExpansionPanelDetails>

            </ExpansionPanel>
          : ''}
        </Box>
      </React.Fragment>
    )
  }
}

export default withSnackbar(Profile)
