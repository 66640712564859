import React, { useState, useRef } from "react"
import Box from "../Box"
import ListItem from "../lists/ListItem"
import ListItemText from "../lists/ListItemText"
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction"
import IconButton from "../IconButton"
import InfoIcon from '@material-ui/icons/Info';

export default function AttributeDisplay(props) {

  const [showIcon, setShowIcon] = useState(false);

  let display = ""
  display += props.data
  props.metric ? display += ` ${props.metric}` : ''

  return (
    <ListItem dense button ContainerProps={{onMouseLeave: () => setShowIcon(false)}} onMouseEnter={() => setShowIcon(true)}>
      <ListItemText
        primary={props.data ? display : ''}
      />

      {showIcon && props.sourceId ? <ListItemSecondaryAction>
        <IconButton tooltip='View Source' edge="end" aria-label="info" size='small' component="a" href={`/sectors/${props.sector.abbr}/sources/${props.sourceId}`} target="_blank">
          <InfoIcon fontSize='small' />
        </IconButton>
      </ListItemSecondaryAction> : ''}
    </ListItem>
  )

}
