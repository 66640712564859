import React, { Fragment } from "react"
import Flex from "../Flex"
import Box from "../Box"
import Select from "../Select"

export default function StudiesFilters(props) {
  return (
    <Fragment>
      <Flex alignItems='center'>
        
        <Box width={1/5} ml={2} mr={1}>
          <Select
            label='Competing products' 
            name='product_id'
            value={props.chosenProduct ? props.chosenProduct.id : ''} 
            entities={props.products}
            handleChange={props.handleFilterChange}
            includeBlank={true}
          />
        </Box>
        
      </Flex>
    </Fragment>
  )
}
