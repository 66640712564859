import React from "react"
import Box from "../Box"
import { List, ListItem, ListItemLink, ListItemText } from "../lists"

export default function InvestigatorsList(props) {
  return(
    <List>
      {props.nihInvestigators.map((nihInvestigator) =>
        <ListItemLink divider href={`/sectors/${props.sector.abbr}/people/${nihInvestigator.person_id}`}>
          <ListItemText primary={nihInvestigator.full_name} secondary={nihInvestigator.is_contact_pi ? "PI" : ""} />
        </ListItemLink>
      )}
    </List>
  )
}