import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Paper from "../../Paper"
import { Table, TableHead, TableRow, TableCell, TableBody, EmptyDataTableRow } from "../../tables"
import RegulatoryAgencyDocumentRow from "./RegulatoryAgencyDocumentRow"
import update from "immutability-helper"
import Snackbar from "../../Snackbar"


export default class InvestorPresentations extends Component {
  constructor(props) {
    super(props)
    this.handleDeleteClick = this.handleDeleteClick.bind(this)
    this.handleUndoClick = this.handleUndoClick.bind(this)
    this.state = { regulatoryAgencyDocuments: this.props.regulatory_agency_documents, showSnackbar: false, snackbarText: '', lastDeleted: '' }
  }
  
  handleUndoClick(e) {
    if (this.state.lastDeleted) {
      $.ajax({
        type: 'PUT',
        url: `/admin/regulatory_agency_documents/${this.state.lastDeleted.id}`,
        data: {
          regulatory_agency_document: {
            deleted: null, deleted_by: null
          }
        },
        dataType: 'json',
        success: (data) => {
          const regulatoryAgencyDocuments = update(this.state.regulatoryAgencyDocuments, { $push: [this.state.lastDeleted] })
          this.setState({
            regulatoryAgencyDocuments: regulatoryAgencyDocuments,
            showSnackbar: true,
            snackbarText: 'Regulatory agency document was undeleted',
            lastDeleted: null
          })
        },
        error: (data) => {
          this.setState({
            showSnackbar: true,
            snackbarText: data.responseText
          })
        },
        completed: (data) => {
          setTimeout(() => {
            this.setState({
              showSnackbar: false,
              snackbarText: ''
            })
          }, 4000)
        }
      })
    }
  }
  
  handleDeleteClick(regulatoryAgencyDocument) {
    $.ajax({
      type: 'PUT',
      url: `/admin/regulatory_agency_documents/${regulatoryAgencyDocument.id}`,
      data: {
        regulatory_agency_document: {
          deleted: true, deleted_by: this.props.current_user
        }
      },
      dataType: 'json',
      success: (data) => {
        const index = this.state.regulatoryAgencyDocuments.indexOf(regulatoryAgencyDocument)
        let regulatoryAgencyDocuments = update(this.state.regulatoryAgencyDocuments, { $splice: [[index,1]] })
        this.setState({
          regulatoryAgencyDocuments: regulatoryAgencyDocuments,
          showSnackbar: true,
          snackbarText: 'Regulatory agency document was deleted',
          lastDeleted: regulatoryAgencyDocument
        })
      },
      error: (data) => {
        this.setState({
          showSnackbar: true,
          snackbarText: `Regulatory agency document could not be deleted. ${data.responseText}`
        })
      },
      complete: (data) => {
        setTimeout(() => {
          this.setState({
            showSnackbar: false,
            snackbarText: ''
          })
        }, 4000)
      }
    })
  }
  
  render() {
    return(
      <Fragment>
        
        <Paper>
          <Table className='w-100'>
            <TableHead>
              <TableRow>
                <TableCell>Document</TableCell>
                <TableCell>Date</TableCell>
                <TableCell>Created By</TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.state.regulatoryAgencyDocuments.length > 0 ? this.state.regulatoryAgencyDocuments.map((regulatoryAgencyDocument) =>
                <RegulatoryAgencyDocumentRow
                  key={regulatoryAgencyDocument.id} 
                  regulatoryAgencyDocument={regulatoryAgencyDocument}
                  handleDeleteClick={this.handleDeleteClick}
                />
              ) : <EmptyDataTableRow colSpan={5} message='No Regulatory agency document yet. Create an Regulatory agency document and it will display here' />}
            </TableBody>
          </Table>
        </Paper>
        
        <Snackbar 
          open={this.state.showSnackbar}
          handleUndoClick={this.handleUndoClick}
          handleClose={this.handleClose}
          message={this.state.snackbarText} 
        />
      </Fragment>
    )
  }
}
