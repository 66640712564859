import React from "react"
import Box from "../../Box"
import Typography from "@material-ui/core/Typography"

function Display(props) {
  return(
    <Box>
      {props.alert.header_text ?
        <div>
          <span
            dangerouslySetInnerHTML={{__html: props.alert.header_text}}
          />
        </div>
      : ""}
      {props.alert.header_secondary ?
        <div>
          <span
            dangerouslySetInnerHTML={{__html: props.alert.header_secondary}}
          />
        </div>
      : ""}
      <span
        dangerouslySetInnerHTML={{__html: props.alert.body }}
      />
      {props.alert.body_secondary ?
        <div>
          <Typography variant="caption" component="span" color="textSecondary">
            <span dangerouslySetInnerHTML={{__html: props.alert.body_secondary }} />
          </Typography>
        </div>
      : ""}
    </Box>
  )
}

export default Display
