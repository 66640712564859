import React from "react"
import Paper from "../../Paper"
import Box from "../../Box"
import Tabs from "../../tabs/Tabs"
import Tab from "../../tabs/Tab"
import TabPanel from "../../tabs/TabPanel"


export default function EntityPanelContainer(props) {
  return (
    <React.Fragment>

      <Box height="100%">

        {props.search}

        <Box height="90%" overflow="auto">
          {props.entitiesList}
        </Box>  
      </Box>

    </React.Fragment>
  )
}
