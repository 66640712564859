import React from "react"
import { List } from "../lists"
import Tweet from "./Tweet"

export default function Tweets(props) {
  return(
    <List>
      {props.tweets.map((tweet) =>
        <Tweet
          key={tweet._id || tweet.id}
          tweet={tweet._source ? tweet._source : tweet}
          handleDeleteClick={props.handleDeleteClick}
        />
      )}
    </List>
  )
}
