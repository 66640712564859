import React from 'react'
import { TableCell, TableRow } from '../../../tables'
import NumberDisplay from './NumberDisplay'
import { RaisedButton } from "../../../buttons"
import DeleteButton from "../../DeleteButton"
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import MuiIconButton from '@material-ui/core/IconButton';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';

export default function PredictionRow(props) {

  function handleDeleteClick(e) {
    props.handleDeletePredictionClick(props.pmaApproval)
  }

  function handleAddPmaClick(e) {
    props.handleAddPmaClick(props.pmaApproval)
  }

  function handlePmaApprovalCheckboxClick(e) {
    props.handlePmaApprovalCheckboxClick(props.pmaApproval)
  }

  const selected = props.checkedPmaApprovals.includes(props.pmaApproval.id)

  return (
    <TableRow>
      <TableCell>
      <MuiIconButton onClick={handlePmaApprovalCheckboxClick} edge="start">
        {selected ? <CheckBoxIcon color='primary' style={{height: '22px', width: '22px'}} /> : <CheckBoxOutlineBlankIcon style={{height: '22px', width: '22px'}} />}
      </MuiIconButton>
      </TableCell>
      <TableCell>
        <NumberDisplay fdaPmaApproval={props.pmaApproval} />
      </TableCell>
      <TableCell className="measure-wide">{props.pmaApproval.trade_name}</TableCell>
      <TableCell className="measure-wide">{props.pmaApproval.ao_statement}</TableCell>
      <TableCell>{props.pmaApproval.search_term}</TableCell>
      <TableCell>{props.pmaApproval.supplement_reason}</TableCell>
      <TableCell>
        <RaisedButton onClick={handleAddPmaClick}>Add</RaisedButton>
      </TableCell>
      <TableCell>
        <DeleteButton onClick={handleDeleteClick} />
      </TableCell>
    </TableRow>
  )
}
