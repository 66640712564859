import React, { Component } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Link from "./Link"

class Footer extends Component {
  constructor(props) {
    super(props)
  }
  
  render() {
    return (
      <div className='pa2 ph3-m ph4-l pv3-m pv4-l cf w-100 center bg-mmi-blue white'>
        <ul className='flex wrap tc'>
          <li className='pr4 dib'><Link href="/" className='white'>Home</Link></li>
          {/* <li className='pr4 dib'><Link href="/" className='white'>About</Link></li> */}
          <li className='pr4 dib'><Link href="/" className='white'>Contact</Link></li>
          <li className='dib'>Copyright {this.props.year} Market Monitors Inc.</li>
        </ul>
      </div>
    )
  }
}

// %li
//   %a{:href => "/"} Home
// %li
//   %a{:href => "/public/about"} About
// %li
//   %a{:href => "/public/newsroom"} News
// %li
//   %a{:href => "/public/contact_us"} Contact
// %li
//   %a{:href => "/public/tour"} Tour
// %li= "Copyright #{Date.today.year} Market Monitors Inc."

export default Footer
