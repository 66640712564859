import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Button from "../../buttons/Button"
import { TableRow, TableCell } from "../../tables"
import ExpandLess from '../../ExpandLess';
import ExpandMore from '../../ExpandMore';
import Link from "../../Link"
import Flex from "../../Flex"
import Box from "../../Box"
import DeleteButton from "../DeleteButton"

export default class AbstractTypePresentations extends Component {
  constructor(props) {
    super(props)
    this.state = { open: true }
  }

  render() {
    let tool = this.props.forExtractor ? 'extractor' : `associator`
    return(
      <Fragment>

        {this.state.open ? this.props.abstracts.map((abstract) =>
          <TableRow key={abstract.id}>

            <TableCell component={Link} href={`/admin/abstracts/${abstract.id}/${tool}`} target="_blank">
              {abstract.number}
            </TableCell>

            <TableCell component={Link} href={`/admin/abstracts/${abstract.id}/${tool}`} target="_blank">
              {abstract.title}
            </TableCell>

            <TableCell align='right'>
              {abstract.score}
            </TableCell>

            <TableCell>
              {abstract.media_url || abstract.pdf ? "YES" : ""}
            </TableCell>

            <TableCell>
              <DeleteButton onClick={() => { this.props.handleDeleteClick(abstract) }} />
            </TableCell>

          </TableRow>
        ) : ''}

      </Fragment>
    )
  }
}
