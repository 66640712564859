import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Paper from "../../Paper"
import update from "immutability-helper"
import SourceForm from "../SourceForm"
import Form from "./Form"
import { Tabs, Tab } from '../../tabs';
import Box from "../../Box"
import Chip from '@material-ui/core/Chip';


export default class NewContainer extends Component {
  constructor(props) {
    super(props)
    this.handleKindChange = this.handleKindChange.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleDateChange = this.handleDateChange.bind(this)
    this.handleSourceChange = this.handleSourceChange.bind(this)
    this.handleActiveIndexUpdate = this.handleActiveIndexUpdate.bind(this)
    this.handleSaveClick = this.handleSaveClick.bind(this)
    this.state = { fundingEvent: this.props.funding_event, source: null, activeIndex: 0, showSnackbar: false, snackbarText: '' }
  }

  componentDidMount() {
    let fundingEvent = update(this.state.fundingEvent, {
      company_id: { $set: this.props.company.id },
      user_id: { $set: this.props.current_user.id }
    })

    this.setState({
      fundingEvent: fundingEvent
    })
  }

  handleKindChange(name, value) {
    let fundingEvent = update(this.state.fundingEvent, {
      funding_event_kind_id: { $set: value }
    })

    this.setState({
      fundingEvent: fundingEvent
    })
  }

  handleChange(e) {
    let fundingEvent = update(this.state.fundingEvent, {
      [e.target.name]: { $set: e.target.value }
    })

    this.setState({
      fundingEvent: fundingEvent
    })
  }

  handleActiveIndexUpdate(e, newValue) {
    this.setState({
      activeIndex: newValue
    })
  }

  handleDateChange(date) {
    const fundingEvent = update(this.state.fundingEvent, { offering_date: { $set: date } })

    this.setState({
      fundingEvent: fundingEvent
    })
  };

  handleSourceChange(entity, sourceType) {
    if (sourceType == 'recently_used') {
      this.setState({
        source: entity
      })
    } else {
      $.get("/admin/sources/find_source", { source_type: sourceType, entity_id: entity.id }, (data) => {
        if (data.source) {
          let fundingEvent = update(this.state.fundingEvent, {
            source_id: { $set: data.source.source_id }
          })
          this.setState({
            source: data.source,
            fundingEvent: fundingEvent
          })
        } else {
          $.post("/admin/sources/filter", { source: { kind: sourceType, entity_id: entity.id } }, (data) => {
            let fundingEvent = update(this.state.fundingEvent, {
              source_id: { $set: data.source.source_id }
            })
            this.setState({
              source: data.source,
              fundingEvent: fundingEvent
            })
          })
        }
      }, 'json')
    }
  }

  handleSaveClick() {
    $.ajax({
      type: 'POST',
      url: `/admin/companies/${this.props.company.id}/funding_events`,
      data: {
        company_funding_event: this.state.fundingEvent
      },
      dataType: 'json',
      success: (data) => {
        window.location.href = `/admin/companies/${this.props.company.id}/funding_events`
      },
      error: (data) => {
        this.setState({
          showSnackbar: true,
          snackbarText: 'There was an error creating the funding event. Make sure a source has been selected'
        })
      },
      complete: (data) => {
        setTimeout(() => {
          this.setState({
            showSnackbar: false,
            snackbarText: ''
          })
        }, 4000)
      }
    })
  }

  render() {
    return(
      <Paper minFullHeight>

        <Box mb={4} width={1}>
          <Tabs variant="fullWidth" value={this.state.activeIndex} onChange={this.handleActiveIndexUpdate} className='bb b--black-10' aria-label="nav tabs">
            <Tab label='Source' />
            <Tab label='Funding event' />
          </Tabs>
        </Box>

        <Box display="flex" justifyContent="center" mb={3}>
          <Box>
            {this.state.source ?
              <Chip
                label={this.state.source.source_title}
              />
            : <Box>Please choose a source</Box>}
          </Box>
        </Box>

        {this.state.activeIndex == 0 ?
          <SourceForm
            source={this.state.source}
            sources={null}
            sourceTypes={this.props.source_types}
            sourceType={this.props.source_type}
            redirectUrl={`/admin/companies/${this.props.company.id}/funding_events/new`}
            handleSourceChange={this.handleSourceChange}
          />
        : ''}

        {this.state.activeIndex == 1 ?
          <Form
            fundingEvent={this.state.fundingEvent}
            companies={this.props.companies}
            fundingEventKinds={this.props.funding_event_kinds}
            handleKindChange={this.handleKindChange}
            handleChange={this.handleChange}
            handleDateChange={this.handleDateChange}
            handleSaveClick={this.handleSaveClick}
          />
        : ''}


      </Paper>
    )
  }
}
