import React, { Component, Fragment } from "react"
import ControlPanel from "../associator/ControlPanel"
import PublicationPanel from "./PublicationPanel"
import Snackbar from "../../Snackbar"
import update from "immutability-helper"
import Associator from "../Associator"
import EntityReviewPanel from "./EntityReviewPanel"
import AssociatedList from "./AssociatedList"


export default class Reviewer extends Component {
  constructor(props) {
    super(props)
    this.handleProductDeleteClick = this.handleProductDeleteClick.bind(this)
    this.handleTrialDeleteClick = this.handleTrialDeleteClick.bind(this)
    this.handleSectorCheckboxSelect = this.handleSectorCheckboxSelect.bind(this)
    this.handleReviewedClick = this.handleReviewedClick.bind(this)
    this.state = { publication: this.props.publication, publicationProducts: this.props.publication_products, publicationTrials: this.props.publication_trials, showSnackbar: false, snackbarText: '', publicationSectors: this.props.publication_sectors, reviewed: this.props.publication_review_status ? this.props.publication_review_status.status : false }
  }

  handleProductDeleteClick(publicationProduct) {
    $.ajax({
      type: "PUT",
      url: `/admin/publication_products/${publicationProduct.id}`,
      data: {
        publication_product: {
          deleted: true, deleted_by: this.props.current_user.id
        }
      },
      dataType: 'json',
      success: (data) => {
        let publicationProductIds = this.state.publicationProducts.map(publicationProduct => publicationProduct.id)
        let index = publicationProductIds.indexOf(data.publication_product.id)

        let publicationProducts = update(this.state.publicationProducts, {
          $splice: [[index, 1]]
        })
        this.setState({
          publicationProducts: publicationProducts,
          showSnackbar: true,
          snackbarText: 'Product has been removed'
        })
      },
      error: (data) => {
        this.setState({
          showSnackbar: true,
          snackbarText: 'There was an error removing the product'
        })
      },
      complete: (data) => {
        setTimeout(() => {
          this.setState({
            showSnackbar: false,
            snackbarText: ''
          })
        }, 4000)
      }
    })
  }

  handleTrialDeleteClick(publicationTrial) {
    $.ajax({
      type: "PUT",
      url: `/admin/publication_trials/${publicationTrial.manuscript_trials_id}`,
      data: {
        publication_trial: {
          deleted: true, deleted_by: this.props.current_user.id
        }
      },
      dataType: 'json',
      success: (data) => {
        let index = this.state.publicationTrials.indexOf(publicationTrial)

        let publicationTrials = update(this.state.publicationTrials, {
          $splice: [[index, 1]]
        })
        this.setState({
          publicationTrials: publicationTrials,
          showSnackbar: true,
          snackbarText: 'Trial has been removed'
        })
      },
      error: (data) => {
        this.setState({
          showSnackbar: true,
          snackbarText: 'There was an error removing the trial'
        })
      },
      complete: (data) => {
        setTimeout(() => {
          this.setState({
            showSnackbar: false,
            snackbarText: ''
          })
        }, 4000)
      }
    })
  }

  handleReviewedClick(e) {
    let type = 'PUT'
    let url = `/admin/manuscript_review_statuses/${this.props.publication_review_status.id}`
    $.ajax({
      type: type,
      url: url,
      data: {
        manuscript_review_status: {
          manuscript_id: this.props.publication.id, status: true, user_id: this.props.current_user.id
        }
      },
      dataType: 'json',
      success: (data) => {
        this.setState({
          showSnackbar: true,
          snackbarText: 'Publication has been marked as reviewed',
          reviewed: true
        })
      },
      error: (data) => {
        this.setState({
          showSnackbar: true,
          snackbarText: 'There was an error. Please try again.'
        })
      },
      complete: (data) => {
        setTimeout(() => {
          this.setState({
            showSnackbar: false,
            snackbarText: ''
          })
        }, 4000)
      }
    })
  }



  handleSectorCheckboxSelect(sector, checked) {
    let publicationSector = this.state.publicationSectors.filter((publicationSector) => publicationSector.sector_id == sector.id)[0]

    if (checked) {
      $.ajax({
        type: 'PUT',
        url: `/admin/publication_sectors/${publicationSector.id}`,
        data: {
          publication_sector: {
            deleted: true, deleted_by: 1
          }
        },
        dataType: 'JSON',
        success: (data) => {
          let index = this.state.publicationSectors.indexOf(sector)

          let publicationSectors = update(this.state.publicationSectors, {
            $splice: [[index, 1]]
          })

          this.setState({
            publicationSectors: publicationSectors,
            showSnackbar: true,
            snackbarText: 'The sector was removed'
          })
        },
        error: (data) => {
          this.setState({
            showSnackbar: true,
            snackbarText: 'The sector could not be removed'
          })
        },
        complete: (data) => {
          setTimeout(() => {
            this.setState({
              showSnackbar: false,
              snackbarText: ''
            })
          }, 4000)
        }
      })
    } else {
      $.ajax({
        type: 'POST',
        url: `/admin/publication_sectors`,
        data: {
          publication_sector: {
            manuscript_id: this.props.publication.id, sector_id: sector.id, created_by: this.props.current_user.id
          }
        },
        dataType: 'json',
        success: (data) => {
          let publicationSectors = update(this.state.publicationSectors, {
            $push: [data.publication_sector]
          })

          this.setState({
            publicationSectors: publicationSectors,
            showSnackbar: true,
            snackbarText: 'The sector was added'
          })
        },
        error: (data) => {
          this.setState({
            showSnackbar: true,
            snackbarText: 'The sector could not be added'
          })
        },
        complete: (data) => {
          setTimeout(() => {
            this.setState({
              showSnackbar: false,
              snackbarText: ''
            })
          }, 4000)
        }
      })
    }
  }

  render() {

    return(
      <Fragment>

        <Associator
          loading={this.state.loading}
          firstPanel={
            <PublicationPanel
              publication={this.props.publication}
              journal={this.props.journal}
              authors={this.props.authors}
              publicationText={this.props.publication_text}
              meshTerms={this.props.mesh_terms}
              keywords={this.props.keywords}
              searchWords={this.props.search_words}
            />
          }
          secondPanel={
            <ControlPanel
              reviewed={this.state.reviewed}
              sectors={this.props.sectors}
              entitySectors={this.state.publicationSectors}
              handleSectorCheckboxSelect={this.handleSectorCheckboxSelect}
              handleReviewedClick={this.handleReviewedClick}
            />
          }
          thirdPanel={
            <EntityReviewPanel
              associated={
                <AssociatedList
                  publicationProducts={this.state.publicationProducts}
                  publicationTrials={this.state.publicationTrials}
                  handleProductDeleteClick={this.handleProductDeleteClick}
                  handleTrialDeleteClick={this.handleTrialDeleteClick}
                />
              }
            />
          }
          associationLoading={this.state.associationLoading}
        />

        <Snackbar
          open={this.state.showSnackbar}
          handleUndoClick={this.handleUndoClick}
          message={this.state.snackbarText}
        />
      </Fragment>
    )
  }
}
