import React, { Component, Fragment } from "react"
import Paper from "../../Paper"
import Box from "../../Box"
import Snackbar from "../../Snackbar"
import update from "immutability-helper"
import { List, ListItemLink, ListItemText } from "../../lists"
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction"
import { Tabs, Tab, TabPanel } from "../../tabs"
import Grid from '@material-ui/core/Grid';
import PublicationTextPanel from "../publications/TextPanel"
import TrialPanel from "./TrialPanel"
import { RaisedButton } from "../../buttons"
import DeleteButton from "../DeleteButton"


export default class AssociatorContainer extends Component {
  constructor(props) {
    super(props)
    this.handleActiveIndexUpdate = this.handleActiveIndexUpdate.bind(this)
    this.handleCheckboxSelect = this.handleCheckboxSelect.bind(this)
    this.handleAssociateClick = this.handleAssociateClick.bind(this)
    this.handleUnassociateClick = this.handleUnassociateClick.bind(this)
    this.handleEntityClick = this.handleEntityClick.bind(this)
    this.associateManuscript = this.associateManuscript.bind(this)
    this.associateTrial = this.associateTrial.bind(this)
    this.state = { predictedManuscripts: this.props.predicted_manuscripts, trials: this.props.trials, abstracts: this.props.abstracts, ctGovTrials: this.props.ct_gov_trials, activeEntity: '', activeIndex: 0, loading: false, showSnackbar: false, snackbarText: '' }
  }

  handleActiveIndexUpdate(e, newValue) {
    this.setState({
      activeIndex: newValue,
      activeEntity: ''
    })
  }

  handleEntityClick(entity) {
    this.setState({
      activeEntity: this.state.activeEntity != '' && this.state.activeEntity.id == entity.id ? '' : entity
    })
  }

  handleCheckboxSelect(entity, type) {
    const index = this.state[type].indexOf(entity.id)
    const activeEntities = index == -1 ? update(this.state[type], { $push: [entity.id] }) : update(this.state[type], { $splice: [[index, 1]] })

    this.setState({
      [type]: activeEntities
    })
  }

  handleAssociateClick(e) {
    console.log(this.state.activeIndex)
    switch (this.state.activeIndex) {
      case 1:
        this.associateManuscript()
        break;
      case 0:
        this.associateTrial()
        break;
      case 2:
        this.associateAbstract()
        break
      default:
    }
  }


  associateManuscript() {
    $.ajax({
      type: 'POST',
      url: `/admin/publication_products`,
      data: {
        publication_product: {
          product_id: this.props.product.id, manuscript_id: this.state.activeEntity.id, created_by: this.props.current_user.id
        }
      },
      dataType: 'json',
      success: (data) => {
        let index = this.state.predictedManuscripts.indexOf(this.state.activeEntity)
        let predictedManuscripts = update(this.state.predictedManuscripts, {
          $splice: [[index,1]]
        })
        this.setState({
          predictedManuscripts: predictedManuscripts,
          activeEntity: '',
          showSnackbar: true,
          snackbarText: 'Manuscript has been associated'
        })
      },
      error: (data) => {
        this.setState({
          showSnackbar: true,
          snackbarText: 'Something went wrong. Please try again.'
        })
      },
      complete: (data) => {
        setTimeout(() => {
          this.setState({
            showSnackbar: false,
            snackbarText: ''
          })
        }, 4000)
      }
    })
  }

  associateTrial() {
    $.ajax({
      type: 'POST',
      url: `/admin/trial_products`,
      data: {
        trial_product: {
          product_id: this.props.product.id, trial_id: this.state.activeEntity.id, created_by: this.props.current_user.id, source_id: this.state.activeEntity.source_id
        }
      },
      dataType: 'json',
      success: (data) => {
        let index = this.state.trials.indexOf(this.state.activeEntity)
        let trials = update(this.state.trials, {
          $splice: [[index,1]]
        })
        this.setState({
          trials: trials,
          activeEntity: '',
          showSnackbar: true,
          snackbarText: 'Trial has been associated'
        })
      },
      error: (data) => {
        this.setState({
          showSnackbar: true,
          snackbarText: 'Something went wrong. Please try again.'
        })
      },
      complete: (data) => {
        setTimeout(() => {
          this.setState({
            showSnackbar: false,
            snackbarText: ''
          })
        }, 4000)
      }
    })
  }

  handleUnassociateClick(trialProduct) {
    $.ajax({
      type: 'PUT',
      url: `/admin/trial_products/${trialProduct.id}`,
      data: {
        trial_product: {
          deleted: true, deleted_by: this.props.current_user.id
        }
      },
      dataType: 'json',
      success: (data) => {

        let index = this.state.trialProducts.indexOf(trialProduct.id)
        let trialProducts = update(this.state.trialProducts, {
          $splice: [[index, 1]]
        })

        this.setState({
          trialProducts: trialProducts,
          showSnackbar: true,
          snackbarText: 'The product has been unassociated'
        })
      },
      error: (data) => {
        this.setState({
          showSnackbar: true,
          snackbarText: 'Something went wrong. Please try again.'
        })
      },
      complete: (data) => {
        setTimeout(() => {
          this.setState({
            showSnackbar: false,
            snackbarText: ''
          })
        }, 4000)
      }
    })
  }


  handleAbstractRemoval = abstract => e => {
    // param is the argument you passed to the function
    // e is the event object that returned
    $.ajax({
      type: 'PUT',
      url: `/admin/abstract_predicted_products/${abstract.abstract_predicted_product_id}`,
      data: {
        abstract_predicted_product: {
          deleted: true
        }
      },
      dataType: 'json',
      success: (data) => {
        let predictedAbstractIds = this.state.abstracts.map(predictedAbstract => predictedAbstract.abstract_predicted_product_id)
        let index = predictedAbstractIds.indexOf(data.id)

        let abstracts = update(this.state.abstracts, {
          $splice: [[index,1]]
        })

        this.setState({
          abstracts: abstracts
        })
      },
      error: (data) => {
        alert("There was an issue removing the entity")
      }
    })
  };

  handleCtGovTrialRemoval = ctGovTrial => e => {
    // param is the argument you passed to the function
    // e is the event object that returned
    $.ajax({
      type: 'PUT',
      url: `/admin/ct_gov_trial_predicted_products/${ctGovTrial.ct_gov_trial_predicted_product_id}`,
      data: {
        ct_gov_trial_predicted_product: {
          deleted: true
        }
      },
      dataType: 'json',
      success: (data) => {
        let predictedCtGovTrialIds = this.state.ctGovTrials.map(predictedTrial => predictedTrial.ct_gov_trial_predicted_product_id)
        let index = predictedCtGovTrialIds.indexOf(data.id)

        let ctGovTrials = update(this.state.ctGovTrials, {
          $splice: [[index,1]]
        })

        this.setState({
          ctGovTrials: ctGovTrials
        })
      },
      error: (data) => {
        alert("There was an issue removing the entity")
      }
    })
  }


  handleTrialRemoval = trial => e => {
    // param is the argument you passed to the function
    // e is the event object that returned
    $.ajax({
      type: 'PUT',
      url: `/admin/trial_predicted_products/${trial.trial_predicted_product_id}`,
      data: {
        trial_predicted_product: {
          deleted: true
        }
      },
      dataType: 'json',
      success: (data) => {
        let predictedTrialIds = this.state.trials.map(predictedTrial => predictedTrial.trial_predicted_product_id)
        let index = predictedTrialIds.indexOf(data.id)

        let trials = update(this.state.trials, {
          $splice: [[index,1]]
        })

        this.setState({
          trials: trials
        })
      },
      error: (data) => {
        alert("There was an issue removing the entity")
      }
    })
  };

  handleEntityRemoval = entity => e => {
    // param is the argument you passed to the function
    // e is the event object that returned
    $.ajax({
      type: 'PUT',
      url: `/admin/manuscript_predicted_products/${entity.manuscript_predicted_product_id}`,
      data: {
        manuscript_predicted_product: {
          deleted: true
        }
      },
      dataType: 'json',
      success: (data) => {
        let predictedManuscriptIds = this.state.predictedManuscripts.map(predictedManuscript => predictedManuscript.manuscript_predicted_product_id)
        let index = predictedManuscriptIds.indexOf(data.id)

        let predictedManuscripts = update(this.state.predictedManuscripts, {
          $splice: [[index,1]]
        })

        this.setState({
          predictedManuscripts: predictedManuscripts
        })
      },
      error: (data) => {
        alert("There was an issue removing the entity")
      }
    })
  };

  render() {
    let gridSize = this.state.activeEntity ? 6 : 12
    return(
      <Fragment>

        <Grid container spacing={3}>
          <Grid item xs={gridSize}>

            <Paper minFullHeight>

              <Box mb={3}>
                <Tabs variant='fullWidth' value={this.state.activeIndex} onChange={this.handleActiveIndexUpdate} className='bb b--black-10' aria-label="entities tabs">
                  <Tab label="Trials" />
                  <Tab label="Ct Gov Trials" />
                  <Tab label="Publications" />
                  <Tab label="Abstracts" />
                </Tabs>
              </Box>

              <TabPanel value={this.state.activeIndex} index={2}>
                <List>
                  {this.state.predictedManuscripts.length > 0 ? this.state.predictedManuscripts.map((manuscript) =>
                    <ListItemLink divider href={`/admin/publications/${manuscript.id}/associator`} target="_blank">
                      <ListItemText
                        primary={manuscript.title}
                      />
                    </ListItemLink>)
                  : 'No manuscripts found for this product'}
                </List>
              </TabPanel>

              <TabPanel value={this.state.activeIndex} index={0}>
                <List>
                  {this.state.trials.map((trial) =>
                    <ListItemLink divider href={`/admin/trials/${trial.id}/associator`} target="_blank">
                    
                      <ListItemText
                        primary={trial.acronym || trial.short_name}
                      />

                      <ListItemSecondaryAction>
                        <DeleteButton onClick={this.handleTrialRemoval(trial)} />
                      </ListItemSecondaryAction>

                    </ListItemLink>
                    // <EntityListItem
                    //   key={trial.id}
                    //   entity={trial}
                    //   activeEntity={this.state.activeEntity}
                    //   displayText={trial.acronym || trial.short_name}
                    //   handleEntityClick={this.handleEntityClick}
                    // />
                  )}
                </List>
              </TabPanel>

              <TabPanel value={this.state.activeIndex} index={1}>
                <List>
                  {this.state.ctGovTrials ? this.state.ctGovTrials.map((ctGovTrial) =>
                    <ListItemLink divider href={`/admin/ct_gov_trials/${ctGovTrial.id}/new_trial`} target="_blank">
                      <ListItemText
                        primary={ctGovTrial.acronym || ctGovTrial.brief_title}
                      />

                      <ListItemSecondaryAction>
                        <DeleteButton onClick={this.handleCtGovTrialRemoval(ctGovTrial)} />
                      </ListItemSecondaryAction>

                    </ListItemLink>
                  ) : 'No ct gov trials found'}
                </List>
              </TabPanel>

              <TabPanel value={this.state.activeIndex} index={3}>
                <List>
                  {this.state.abstracts.map((abstract) =>
                    <ListItemLink key={abstract.id} button href={`/admin/abstracts/${abstract.id}/associator`} divider target="_blank">

                      <ListItemText
                        primary={abstract.title}
                      />
                    </ListItemLink>
                  )}
                </List>
              </TabPanel>
            </Paper>

          </Grid>

          <Grid item xs={this.state.activeEntity ? 6 : 0}>
            {this.state.activeEntity ?
              <Paper>

                <Box width={1/4} mx='auto' mb={3}>
                  <RaisedButton handleClick={this.handleAssociateClick}>
                    Associate
                  </RaisedButton>
                </Box>

                <Box height="100%">

                  <Box mt={2} height="90%" overflow='auto'>
                    <TabPanel value={this.state.activeIndex} index={1}>
                      <PublicationTextPanel
                        publication={this.state.activeEntity}
                        searchWords={[this.props.product.root_name]}
                        handleAssociateClick={this.handleAssociateClick}
                      />
                    </TabPanel>

                    <TabPanel value={this.state.activeIndex} index={0}>
                      <TrialPanel
                        trial={this.state.activeEntity}
                        searchWords={[this.props.product.root_name]}
                        handleAssociateClick={this.handleAssociateClick}
                      />
                    </TabPanel>

                    {/* <TabPanel value={this.state.activeIndex} index={2}>
                      <AbstractPanel
                        trial={this.state.activeEntity}
                        searchWords={[this.props.product.root_name]}
                        handleAssociateClick={this.handleAssociateClick}
                      />
                    </TabPanel> */}
                  </Box>

                </Box>

                <Box width={1/4} mx='auto' mb={3}>
                  <RaisedButton handleClick={this.handleAssociateClick}>
                    Associate
                  </RaisedButton>
                </Box>

              </Paper>
            : ''}
          </Grid>
        </Grid>

        <Snackbar
          open={this.state.showSnackbar}
          handleUndoClick={this.handleUndoClick}
          message={this.state.snackbarText}
        />
      </Fragment>
    )
  }
}
