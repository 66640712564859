import React from "react"
import TextInput from "../../TextInput"
import InputAdornment from "@material-ui/core/InputAdornment"
import SearchIcon from '@material-ui/icons/Search'
import ClearIcon from '@material-ui/icons/Clear';
import IconButton from '@material-ui/core/IconButton';

export default function SearchFilter(props) {
  return(
    <form onSubmit={props.handleSearchClick}>
      <TextInput
        name='query'
        placeholder='Search'
        size='small'
        value={props.query ? props.query : ''}
        onChange={props.handleChange}
        className='w-100'
        InputProps={{
          startAdornment: <InputAdornment position="start"><SearchIcon /></InputAdornment>,
          endAdornment: <InputAdornment position="end"><IconButton onClick={props.handleClearSearchClick} size='small'><ClearIcon fontSize='small' /></IconButton></InputAdornment>
        }}
      />
    </form>
  )
}
