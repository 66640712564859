import React, { Component, Fragment, useState } from "react"
import { makeStyles } from '@material-ui/core/styles';
import { List, ListItem, ListItemText } from "../lists"
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import DeleteButton from "../admin/DeleteButton"
import ExpandLess from '../ExpandLess';
import ExpandMore from '../ExpandMore';
import Collapse from "@material-ui/core/Collapse"
import Flex from "../Flex"
import Box from "../Box"
import { OutlinedButton } from "../buttons"

const useStyles = makeStyles((theme) => ({
  nested: {
    paddingLeft: theme.spacing(6),
  },
}));

export default function UserCollectionListItem(props) {
  const [open, setOpen] = useState(false);

  const classes = useStyles();

  return(
    <Fragment>
      <ListItem button component="a" divider href={`/sectors/${props.sector.abbr}/user_collections/${props.userCollection.id}`} key={props.userCollection.id}>
        {/* <ListItemIcon>
          <ExpandMore />
        </ListItemIcon> */}

        <ListItemText
          primary={props.userCollection.title}
          secondary={`${props.userCollection.first_name} ${props.userCollection.last_name}`}
        />

        <ListItemSecondaryAction>
          <Flex alignItems='baseline'>
            {/* <Box>
              <IconButton tooltip='Share'>
                <PersonAddIcon size='small' />
              </IconButton>
            </Box> */}

            <Box>
              {props.user.id == props.userCollection.user_id ?
                <DeleteButton
                  onClick={() => props.handleCollectionDeleteClick(props.userCollection)}
                />
              :
                <OutlinedButton onClick={() => props.handleUnfollowClick(props.userCollection)}>
                  Unfollow
                </OutlinedButton>
              }
            </Box>
          </Flex>
        </ListItemSecondaryAction>
      </ListItem>

      {/* <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding divider>
          {props.userCollection.user_collection_products.map((userCollectionProduct) =>
            <ListItem key={userCollectionProduct.product.id} button className={classes.nested}>

              <ListItemText
                primary={userCollectionProduct.product.short_name}
              />

              <ListItemSecondaryAction>
                <DeleteButton />
              </ListItemSecondaryAction>
            </ListItem>
          )}
        </List>
      </Collapse> */}
    </Fragment>
  )
}
