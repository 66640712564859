import React, { Component, Fragment } from "react"
import Paper from "../../Paper"
import Box from "../../Box"
import Select from "../../Select"
import { Table, TableHead, TableRow, TableCell, TableBody, TableHeadSort } from "../../tables"
import GreenroomRow from "./GreenroomRow"
import update from "immutability-helper"
import AlertEditor from "./AlertEditor"
import { withSnackbar } from "../../SnackbarContainer"
import { withRouter } from "react-router-dom";
import InlineSearchContainer from "../search/InlineSearchContainer"

class Greenroom extends Component {
  constructor(props) {
    super(props)
    this.handleFilterChange = this.handleFilterChange.bind(this)
    this.handleDeleteClick = this.handleDeleteClick.bind(this)
    this.handleEditClick = this.handleEditClick.bind(this)
    this.handleRequestSort = this.handleRequestSort.bind(this)
    this.desc = this.desc.bind(this)
    this.stableSort = this.stableSort.bind(this)
    this.getSorting = this.getSorting.bind(this)
    this.handleSwitchChange = this.handleSwitchChange.bind(this)
    this.handleDateChange = this.handleDateChange.bind(this)
    this.handleSelectChange = this.handleSelectChange.bind(this)
    this.handleFinishedEditingClick = this.handleFinishedEditingClick.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleSearchClick = this.handleSearchClick.bind(this)
    this.handleClearSearchClick = this.handleClearSearchClick.bind(this)
    this.handleSectorDelete = this.handleSectorDelete.bind(this)
    this.handleSectorChange = this.handleSectorChange.bind(this)
    this.getAlerts = this.getAlerts.bind(this)
    this.state = { alerts: this.props.alerts, activeAlert: null, date: this.props.date, status: this.props.status, sector_id: this.props.sector_id, alert_group_id: this.props.alert_group_id, showSnackbar: false, snackbarText: '', order: 'asc', orderBy: 'group_name', query: "" }
  }

  handleSearchClick(query) {
    $.ajax({
      type: "GET",
      url: `/admin/alerts/search`,
      data: {
        query: query
      },
      dataType: "json",
      success: (data) => {
        this.setState({
          alerts: data.alerts,
          query: query
        })
      },
      error: (data) => {
        this.props.snackbarShowMessage("There was an error when searching alerts. Please try again.")
      }
    })
  }

  handleClearSearchClick(e) {
    let params = { date: this.state.date, sector_id: this.state.sector_id, status: this.state.status, alert_group_id: this.state.alert_group_id }

    this.getAlerts(params)
  }

  handleChange(evt) {
    let text = evt.editor.getData()

    let activeAlert = update(this.state.activeAlert, { body: { $set: text } })

    this.setState({
      activeAlert: activeAlert
    })
  }

  handleSectorDelete(alert, sectorAbbr) {
    $.ajax({
      type: "get",
      url: `/admin/alert_sectors/delete_alert_sector`,
      data: {
        alert_id: alert.id, abbr: sectorAbbr
      },
      dataType: 'json',
      success: (data) => {

        let sectors = alert.grouped_sectors.replace(sectorAbbr, "")

        let index = this.state.alerts.indexOf(alert)

        let alerts = update(this.state.alerts, {
          [index]: {
            grouped_sectors: {
              $set: sectors
            }
          }
        })

        this.setState({
          alerts: alerts
        })
      },
      error: (data) => {
        console.log(data)
        this.props.snackbarShowMessage("Sector could not be removed. Please try again", "error")
      }
    })
  }

  handleFinishedEditingClick(e) {
    $.ajax({
      type: 'PUT',
      url: `/admin/alerts/${this.state.activeAlert.id}`,
      data: {
        alert: this.state.activeAlert
      },
      dataType: 'json',
      success: (data) => {
        let alertIds = this.state.alerts.map(alert => alert.id)

        let index = alertIds.indexOf(this.state.activeAlert.id)

        let sector = this.props.sectors.filter((sector) => sector.value == this.state.activeAlert.sector_id)[0]

        let alerts = update(this.state.alerts, {
          [index]: {
            alert_group_id: { $set: this.state.activeAlert.alert_group_id },
            sector_id: { $set: this.state.activeAlert.sector_id },
            sector_abbr: { $set: sector.name },
            body: { $set: this.state.activeAlert.body }
          }
        })

        this.setState({
          activeAlert: null,
          alerts: alerts
        })
      }
    })
  }

  handleFilterChange(name, value) {
    let params = { date: this.state.date, sector_id: this.state.sector_id, status: this.state.status, alert_group_id: this.state.alert_group_id }

    params[name] = value

    this.getAlerts(params)
  }

  getAlerts(params) {
    $.ajax({
      type: "GET",
      url: `/admin/alerts/greenroom`,
      data: params,
      dataType: 'json',
      success: (data) => {

        let filterKeys = Object.keys(params)

        let queryParams = filterKeys.map((filterKey) =>
          params[filterKey] == null ? '' : `${filterKey}=${params[filterKey]}`
        ).filter(param => param)

        let flattenedQueryParams = [].concat.apply([], queryParams);
        this.props.history.push(`/admin/alerts/greenroom?${flattenedQueryParams.join("&")}`);

        this.setState({
          date: params.date,
          sector_id: params.sector_id, 
          status: params.status,
          alert_group_id: params.alert_group_id,
          alerts: data.alerts
        })
      }
    })
  }

  handleEditClick(alert) {
    this.setState({
      activeAlert: alert
    })
  }

  handleDeleteClick(alert) {
    let alertParams = alert.deleted ? { deleted: null, deleted_by: null } : { deleted: true, deleted_by: this.props.current_user.id }

    $.ajax({
      type: 'PUT',
      url: `/admin/alerts/${alert.id}`,
      data: {
        alert: alertParams
      },
      dataType: 'json',
      success: (data) => {
        const index = this.state.alerts.indexOf(alert)
        const alerts = update(this.state.alerts, {
          $splice: [[index, 1]]
        })

        let snackbarMessage = this.state.status == 'deleted' ? "Alert has been undeleted" : 'Alert has been deleted'

        this.setState({
          alerts: alerts
        })

        this.props.snackbarShowMessage(snackbarMessage)
      },
      error: (data) => {
        this.props.snackbarShowMessage('Alert could not be deleted', 'error')
      }
    })
  }

  desc(a, b, orderBy) {
    let bOrderKey = orderBy == 'entity' ? b[orderBy].toLowerCase() : b[orderBy]
    let aOrderKey = orderBy == 'entity' ? a[orderBy].toLowerCase() : a[orderBy]
    if (bOrderKey < aOrderKey) {
      return -1;
    }
    if (bOrderKey > aOrderKey) {
      return 1;
    }
    return 0;
  }

  stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = cmp(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
  }

  getSorting(order, orderBy) {
    return order === 'desc' ? (a, b) => this.desc(a, b, orderBy) : (a, b) => -this.desc(a, b, orderBy);
  }

  handleRequestSort(event, property) {
    const isDesc = this.state.orderBy === property && this.state.order === 'desc';

    this.setState({
      order: isDesc ? 'asc' : 'desc',
      orderBy: property
    })
  };

  handleDateChange(value, date) {
    $.ajax({
      type: 'PUT',
      url: `/admin/alerts/${this.state.activeAlert.id}`,
      data: {
        alert: {
          date: value
        }
      },
      dataType: 'json',
      success: (data) => {
        const index = this.state.alerts.indexOf(this.state.activeAlert)

        const alerts = update(this.state.alerts, {
          [index]: {
            date: { $set: data.alert.date }
          }
        })

        const activeAlert = update(this.state.activeAlert, {
          date: { $set: data.alert.date }
        })

        this.setState({
          alerts: alerts,
          activeAlert: activeAlert
        })
      },
      error: (data) => {
        this.props.snackbarShowMessage("Alert date could not be changed", "error")
      }
    })
  }

  handleSectorChange(name, value) {
    $.ajax({
      type: 'PUT',
      url: `/admin/alerts/${this.state.activeAlert.id}`,
      data: {
        alert: {
          sector_id: value
        }
      },
      dataType: 'json',
      success: (data) => {
        const index = this.state.alerts.indexOf(this.state.activeAlert)

        const alerts = update(this.state.alerts, {
          [index]: {
            sector_id: { $set: data.alert.sector_id }
          }
        })

        const activeAlert = update(this.state.activeAlert, {
          sector_id: { $set: data.alert.sector_id }
        })

        this.setState({
          alerts: alerts,
          activeAlert: activeAlert
        })
      },
      error: (data) => {
        this.props.snackbarShowMessage("Alert date could not be changed", "error")
      }
    })
  }

  handleSelectChange(name, value) {
    let alert = update(this.state.activeAlert, {
      [name]: { $set: value }
    })

    this.setState({
      activeAlert: alert
    })
  }

  handleSwitchChange(alert) {
    let live = alert.live ? null : true
    $.ajax({
      type: 'PUT',
      url: `/admin/alerts/${alert.id}`,
      data: {
        alert: {
          live: live, updated_by: this.props.current_user.id
        }
      },
      dataType: 'json',
      success: (data) => {
        const index = this.state.alerts.indexOf(alert)
        const alerts = update(this.state.alerts, {
          [index]: {
            live: { $set: live }
          }
        })

        this.setState({
          alerts: alerts
        })
      },
      error: (data) => {
        this.props.snackbarShowMessage("Alert could not be set to live", "error")
      }
    })
  }

  render() {
    let headers = [ { name: 'id', label: 'ID' }, { name: 'sector_id', label: 'Sector' }, { name: 'date', label: 'Date' }, { name: 'body', label: 'Body' }, { name: 'entity', label: 'Entity' }, { name: 'group_name', label: 'Group' }, { name: 'kind', label: 'Source' }, { name: 'source_date', label: 'Source date' }, { name: 'status', label: 'Live' }, { name: '', label: this.state.status == 'deleted' ? 'Undelete' : 'Delete' }, { name: '', label: 'Edit' } ]
    return(
      <Fragment>

        <Paper fullHeight>

          <Box height="100%">
            <Box display="flex" alignItems='start' mb={3}>
              <Box>
                <Select
                  value={this.state.date || ''}
                  name='date'
                  label='Date'
                  entities={this.props.dates}
                  handleChange={this.handleFilterChange}
                />
              </Box>

              <Box mx={3}>
                <Select
                  value={this.state.status || ''}
                  name='status'
                  label='Status'
                  entities={this.props.statuses}
                  handleChange={this.handleFilterChange}
                />
              </Box>

              <Box>
                <Select
                  value={this.state.sector_id || ''}
                  name='sector_id'
                  label='Sector'
                  includeBlank
                  entities={this.props.sectors}
                  handleChange={this.handleFilterChange}
                />
              </Box>

              <Box mx={3}>
                <Select
                  value={this.state.alert_group_id || ''}
                  name='alert_group_id'
                  label='Group'
                  includeBlank
                  entities={this.props.alert_groups}
                  handleChange={this.handleFilterChange}
                />
              </Box>

              <Box>
                <InlineSearchContainer
                  searchLabel="Search alerts" 
                  helperText=""
                  query={this.state.query}
                  handleSearchClick={this.handleSearchClick}
                  handleClearSearchClick={this.handleClearSearchClick}
                />
              </Box>

              <Box ml="auto">
                {`${this.state.alerts.length} alerts`}
              </Box>
            </Box>

            {this.state.activeAlert ? <Box my={4}>
              <AlertEditor
                alert={this.state.activeAlert}
                alertGroups={this.props.alert_groups}
                sectors={this.props.sectors}
                products={this.props.products}
                companies={this.props.companies}
                sourceTypes={this.props.source_types}
                handleDateChange={this.handleDateChange}
                handleSelectChange={this.handleSelectChange}
                handleChange={this.handleChange}
                handleSectorChange={this.handleSectorChange}
                handleFinishedEditingClick={this.handleFinishedEditingClick}
              />
            </Box> : ''}

            <Box height="80%" overflow="auto">
              <Table size='small' aria-label='products table' stickyHeader={true}>

                <TableHeadSort
                  headers={headers}
                  order={this.state.order}
                  orderBy={this.state.orderBy}
                  onRequestSort={this.handleRequestSort}
                />

                <TableBody>
                  {this.state.alerts.length > 0 ?
                    this.stableSort(this.state.alerts, this.getSorting(this.state.order, this.state.orderBy)).map((alert) =>
                      <GreenroomRow
                        key={alert.id}
                        alert={alert}
                        status={this.state.status}
                        handleDeleteClick={this.handleDeleteClick}
                        handleEditClick={this.handleEditClick}
                        handleSwitchChange={this.handleSwitchChange}
                        handleDateChange={this.handleDateChange}
                        handleSectorDelete={this.handleSectorDelete}
                      />
                    )
                  :
                    <TableRow colSpan={8}>
                      <TableCell>No alerts found. Try changing the date.</TableCell>
                    </TableRow>
                  }
                </TableBody>
              </Table>
            </Box>
          </Box>
        </Paper>
      </Fragment>
    )
  }
}


export default withSnackbar(withRouter(Greenroom))
