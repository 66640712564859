import React, { Fragment } from "react"
import { makeStyles } from '@material-ui/core/styles';
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Paper from "../Paper"
import { Table, TableHead, TableRow, TableCell, TableBody } from "../tables"
import { List, ListItem, ListItemText, FlexAvailableListItemText } from "../lists"
import ListItemIcon from '@material-ui/core/ListItemIcon';
import PersonIcon from '@material-ui/icons/Person'
import Expandable from "../Expandable"
import Flex from "../Flex"
import Box from "../Box"
import Typography from "@material-ui/core/Typography"
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction"

const useStyles = makeStyles((theme) => ({
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

export default function People(props) {
  const classes = useStyles();

  return(
    <Fragment>

      <Paper minFullHeight>
        {props.managers.length > 0 || props.board_members.length > 0 || props.sab_members.length > 0 ?
          <div>
            <List className='mb3'>
              <Expandable header='Senior Managers and Founders' secondary={props.managers.length} style={{backgroundColor: 'rgba(0, 0, 0, 0.04)'}}>
                {props.managers.map((manager) =>
                  <ListItem divider={true} key={manager.id} className={classes.nested}>
                    <ListItemIcon>
                      <PersonIcon />
                    </ListItemIcon>

                    <ListItemText
                      style={{flex: '2 0px'}}
                      primary={manager.text}
                      secondary={manager.title}
                    />

                    {manager.previous_affiliation ?
                      <ListItemText
                        style={{flex: '2 0px'}}
                        primary='Previous affiliation'
                        secondary={manager.previous_affiliation}
                      />
                    : ''}
                  </ListItem>
                )}
              </Expandable>
            </List>

            {props.board_members.length > 0 ?
              <List className='mb3'>
                <Expandable header='Board of Directors' secondary={props.board_members.length} style={{backgroundColor: 'rgba(0, 0, 0, 0.04)'}}>
                  {props.board_members.map((boardMember) =>
                    <ListItem divider={true} key={boardMember.id} className={classes.nested}>
                      <ListItemIcon>
                        <PersonIcon />
                      </ListItemIcon>

                      <FlexAvailableListItemText
                        primary={`${boardMember.first_name} ${boardMember.last_name}`}
                        secondary={boardMember.title}
                      />

                      {boardMember.previous_affiliation ?
                        <FlexAvailableListItemText
                          secondary={boardMember.previous_affiliation}
                        />
                      : ''}
                    </ListItem>
                  )}
                </Expandable>
              </List>
            : ''}

            {props.sab_members.length > 0 ?
              <List>
                <Expandable header='Scientific Advisory Board' secondary={props.sab_members.length} style={{backgroundColor: 'rgba(0, 0, 0, 0.04)'}}>
                  {props.sab_members.map((sabMember) =>
                    <ListItem divider={true} key={sabMember.id} className={classes.nested}>
                      <Flex>
                        <Box>
                          <ListItemIcon>
                            <PersonIcon />
                          </ListItemIcon>
                        </Box>

                        <Box>
                          <ListItemText
                            primary={`${sabMember.first_name} ${sabMember.last_name}`}
                            secondary={sabMember.title}
                          />
                        </Box>
                      </Flex>

                      {sabMember.previous_affiliation ?
                        <FlexAvailableListItemText
                          secondary={sabMember.previous_affiliation}
                        />
                      : ''}
                    </ListItem>
                  )}
                </Expandable>
              </List>
            : ''}

          </div>
        :
          <Flex justifyContent='center' height="50%">
            <Box alignSelf='center' maxWidth="50%">
              <Box>
                <Typography variant="body1">
                  No people found for this company
                </Typography>
              </Box>
            </Box>
          </Flex>
        }
      </Paper>
    </Fragment>
  )
}
