import React, { Component } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import EntityAlerts from "../EntityAlerts"

export default class Alerts extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    return(
      <div>

        <EntityAlerts
          entity={this.props.company}
          entityType='companies'
          cardBackground='bg-light-blue'
          alerts={this.props.alerts}
          years={this.props.years}
          year={this.props.year}
          alertGroups={this.props.alert_groups}
          groupId={this.props.groupId}
          sector={this.props.sector}
        />

      </div>
    );
  }
}

Alerts.propTypes = {
  company: PropTypes.object
}
