import React, { Fragment } from 'react'
import { Table, TableBody, TableCell, TableHead, TableRow } from '../../tables'
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TablePaginationActions from "../../tables/TablePaginationActions"
import Row from './Row'

export default function RecallsTable(props) {
  return (
    <Fragment>
      <Table stickyHeader size="small">
      <TableHead>
          <TableRow>
            <TableCell className='bg-white'>ID</TableCell>
            <TableCell className='bg-white'>Product</TableCell>
            <TableCell className='bg-white'>Reason for Recall</TableCell>
            <TableCell className='bg-white'>PMA #</TableCell>
            <TableCell className='bg-white'>510(k) #</TableCell>
            <TableCell className='bg-white'>Date Posted</TableCell>
            <TableCell className='bg-white'>Hide</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {props.fdaRecalls.map((fdaRecall) =>
            <Row 
              key={fdaRecall.id}
              fdaRecall={fdaRecall}
              handleDeleteClick={props.handleDeleteClick}
            />
          )}
        </TableBody>
        <TableFooter>
          <TablePagination
            colSpan={7}
            count={props.fdaRecallsCount}
            rowsPerPage={100}
            page={props.page}
            SelectProps={{
              inputProps: { 'aria-label': 'rows per page' },
              native: true,
            }}
            onChangePage={props.handlePageChange}
            onChangeRowsPerPage={props.handleChangeRowsPerPage}
            ActionsComponent={TablePaginationActions}
          />
        </TableFooter>
      </Table>
    </Fragment>
  )
}
