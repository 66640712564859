import React from "react"
import { List, ListItem, ListItemText } from "../../lists"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import Checkbox from "../../Checkbox"

export default function TherapyTypeListItem(props) {
  
  function handleCheckboxSelect(e) {
    if (props.checked) {
      props.handleCheckboxDelete(props.productTherapyType)
    } else {
      props.handleCheckboxSelect(props.therapyType, props.productTherapyType)
    }
    
  }
  
  return (
    <React.Fragment>
      
      <ListItem button key={props.therapyType.id} role={undefined} onClick={handleCheckboxSelect}>
        <ListItemIcon>
          <Checkbox 
            checked={props.checked} 
          />
        </ListItemIcon>
        
        <ListItemText primary={props.therapyType.name} />
      </ListItem>
      
    </React.Fragment>
  )
}
