import React, { Component } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import TableHead from "./TableHead.jsx"

class TableHeader extends Component {
  constructor(props) {
    super(props)
  }
  
  render() {
    const headers = this.props.headers.map((header) =>
      <TableCell key={header.text} align={header.align}>{header.text}</TableCell>
    )
    return (
      <TableHead>
        <TableRow>
          {headers}
        </TableRow>
      </TableHead>
    )
  }
}

export default TableHeader
