import React, { Fragment } from "react"
import Paper from "../../Paper"
import List from "../../lists/List";
import ProductListItem from "./ProductListItem"

export default function PredictedProductsList(props) {
  const trialProductIds = props.trialProducts.map((trialProduct) => trialProduct.product_id)
  const productIdArray = [...trialProductIds, ...props.activeProducts]
  return (
    <Fragment>
      
      <List>
        {props.predictedProducts.map((predictedProduct) =>
          <ProductListItem 
            key={predictedProduct.trial_predicted_product_id}
            product={predictedProduct}
            checked={productIdArray.indexOf(predictedProduct.id) == -1 ? false : true}
            forSelection={trialProductIds.includes(predictedProduct.id) ? false : true}
            hits={predictedProduct.hits}
            handleCheckboxSelect={props.handleCheckboxSelect}
          /> 
        )}
      </List>
      
    </Fragment>
  )
}
