import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Filters from "./Filters"
import Paper from "../Paper"
import List from "./List"
import Flex from "../Flex"
import Box from "../Box"
import IconButton from "../IconButton"
import Typography from "@material-ui/core/Typography"
import { withSnackbar } from "../SnackbarContainer"
import update from "immutability-helper"
import { withRouter } from "react-router-dom";
import FiltersContainer from "./FiltersContainer"
import Pagination from '@material-ui/lab/Pagination';
import Select from "../Select"
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';


class Index extends Component {
  constructor(props) {
    super(props)
    this.handleFilterChange = this.handleFilterChange.bind(this)
    this.getNews = this.getNews.bind(this)
    this.handleDelete = this.handleDelete.bind(this)
    this.handleRequestSort = this.handleRequestSort.bind(this)
    this.orderNews = this.orderNews.bind(this)
    this.handleChangePage = this.handleChangePage.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleSearchClick = this.handleSearchClick.bind(this)
    this.handleClearSearchClick = this.handleClearSearchClick.bind(this)
    this.handlePublicationChange = this.handlePublicationChange.bind(this)
    this.state = { news: this.props.news, newsCount: this.props.news_count, loading: false, filterOptions: { publications: this.props.publications, years: this.props.years, orderOptions: this.props.order_options }, filterValues: { news_publication_id: this.props.news_publication_id, year: this.props.year, order: 'desc', order_by: 'date', page: this.props.page, query: this.props.query } }
  }

  handleChange(e) {
    let filterValues = update(this.state.filterValues, {
      query: { $set: e.target.value }
    })

    this.setState({
      filterValues: filterValues
    })
  }

  handleClearSearchClick(e) {
    let params = Object.assign({}, this.state.filterValues)

    params["query"] = null
    params["order_by"] = "date"

    this.getNews(params)
  }

  handleSearchClick(query) {
    let params = Object.assign({}, this.state.filterValues)

    params["query"] = query
    params["order_by"] = "_score"

    this.getNews(params)
  }

  handleRequestSort(event, property) {
    let params = Object.assign({}, this.state.filterValues)

    params["order_by"] = property

    let isDesc = this.state.filterValues.order_by === property && this.state.filterValues.order === 'desc';

    params["order"] = isDesc ? 'asc' : 'desc'

    this.orderNews(params)
  };

  orderNews(params) {
    $.ajax({
      type: "GET",
      url: `/sectors/${this.props.sector.abbr}/news`,
      data: params,
      dataType: 'json',
      success: (data) => {
        this.setState({
          news: data.news,
          newsCount: data.news_count,
          filterValues: params
        })
      },
      error: (data) => {
        this.props.snackbarShowMessage("There was an issue ordering the news. Please try again")
      }
    })
  }

  handleFilterChange(name, value) {
    let params = Object.assign({}, this.state.filterValues)

    params[name] = value

    this.getNews(params)
  }

  handlePublicationChange(e, selected) {
    let params = Object.assign({}, this.state.filterValues)

    if (selected) {
      params['news_publication_id'] = selected.value 
    } else {
      params['news_publication_id'] = null
    }

    this.getNews(params)
  }

  handleDelete(value, type) {
    const index = this.state.filterValues[type].indexOf(value)

    const filterValues = update(this.state.filterValues, {
      [type]: { $splice: [[index, 1]] }
    })

    this.getNews(filterValues)
  }

  handleChangePage(e, newPage) {
    let params = Object.assign({}, this.state.filterValues)

    params["order_by"] = this.state.order_by
    params["order"] = this.state.order
    params["page"] = newPage

    this.getNews(params)
  }

  getNews(params) {
    $.ajax({
      type: 'GET',
      url: `/sectors/${this.props.sector.abbr}/news`,
      data: params,
      dataType: 'json',
      beforeSend: (smthing) => {
        this.setState({
          loading: true
        })
      },
      success: (data) => {
        let filterOptions = { publications: data.publications, years: data.years, orderOptions: data.order_options }

        let url = `/sectors/${this.props.sector.abbr}/news`

        let filterKeys = Object.keys(params)

        let queryParams = filterKeys.map((filterKey) =>
          (typeof(params[filterKey]) == "string" || params[filterKey] == null) ? (params[filterKey] ? `${filterKey}=${params[filterKey]}` : null) : (params[filterKey].length > 0 ? params[filterKey].map((filterValue) => `${filterKey}[]=${filterValue}`) : null)
        ).filter(param => param)

        let flattenedQueryParams = [].concat.apply([], queryParams);
        // this.props.match.path
        this.props.history.push(`${url}?${flattenedQueryParams.join("&")}`);

        this.setState({
          news: data.news,
          newsCount: data.news_count,
          filterValues: params,
          loading: false,
          filterOptions
        })
      },
      error: (data) => {
        this.props.snackbarShowMessage("There was a problem fetching the articles. Please try again.", "error")
      }
    })
  }

  render() {
    let pages = Math.round(this.state.newsCount / 100)

    let publicationValue = this.state.filterOptions.publications.filter(newsPublication => newsPublication.value == this.state.filterValues.news_publication_id)[0]
    return(
      <Fragment>

        <Paper maxFullHeight>

          <Box height="100%">
            <Box>
              <FiltersContainer
                query={this.state.filterValues.query}
                handleSearchClick={this.handleSearchClick}
                handleClearSearchClick={this.handleClearSearchClick}
              />
            </Box>

            <Flex>
              <Box ml='auto'>
                <Typography variant="body2">
                  {`${this.state.newsCount} articles`}
                </Typography>
              </Box>
            </Flex>

            <Flex>
              <Box width={1/5} mr={2}>
                <div>
                  <Autocomplete
                    className="MuiFormControl-marginDense"
                    id='news_publication_id'
                    options={this.state.filterOptions.publications}
                    name='news_publication_id'
                    defaultValue={publicationValue}
                    value={publicationValue}
                    getOptionLabel={(option) => option.name}
                    size='small'
                    margin='dense'
                    onChange={this.handlePublicationChange}
                    selectOnFocus
                    renderInput={(params) => <TextField {...params} name='news_publication_id' label='Publication' variant="outlined" />}
                  />
                </div>
              </Box>
              <Box width={1/5} mr={2}>
                <Select
                  label='Year'
                  name='year'
                  includeBlank
                  value={this.state.filterValues.year}
                  entities={this.state.filterOptions.years}
                  handleChange={this.handleFilterChange}
                />
              </Box>
              <Box width={1/5}>
                <Select
                  label='Order'
                  name='order_by'
                  value={this.state.filterValues.order_by}
                  entities={this.state.filterOptions.orderOptions}
                  handleChange={this.handleRequestSort}
                />
              </Box>
            </Flex>

            <Box height="75%" overflow="auto" mt={2}>
              <List
                key={this.state.loading}
                news={this.state.news}
                newsCount={this.state.newsCount}
                order={this.state.filterValues.order}
                orderBy={this.state.filterValues.order_by}
                page={this.state.filterValues.page}
                handleChangePage={this.handleChangePage}
                handleRequestSort={this.handleRequestSort}
                loading={this.state.loading}
                query={this.state.query}
                sector={this.props.sector}
              />
            </Box>

            <Box mx='auto' width="30%" mt={2}>
              <Pagination
                count={pages}
                page={this.state.filterValues.page}
                onChange={this.handleChangePage}
              />
            </Box>
          </Box>
        </Paper>
      </Fragment>
    )
  }
}

export default withRouter(withSnackbar(Index))
