import React, { Fragment, useState } from "react"
import { Table, TableCell, TableRow, TableHeadSort, TableBody } from "../tables"
import Flex from "../Flex"
import Box from "../Box"
import Link from "../Link"
import IndeterminateCheckBoxIcon from '@material-ui/icons/IndeterminateCheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import LinearProgress from '@material-ui/core/LinearProgress';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TablePaginationActions from "../tables/TablePaginationActions"
import CompareIcon from '@material-ui/icons/Compare';
import IconButton from "../IconButton"
import ListIcon from '@material-ui/icons/List';

export default function AdverseEvents(props) {

  let headers = [{ label: 'Product', name: 'short_name' }, { label: 'Manufacturer', name: 'manufacturer_g1_name' }, { label: 'Event Type', name: 'event_type' }, { label: 'Date Received', name: 'date_received' }, { label: 'Report Key', name: 'mdr_report_key' }]

  return (
    <Fragment>
      <Flex alignItems='center' justifyContent='right'>
        <Box textAlign='right'>
          {`${props.adverseEventsCount} adverse events`}
        </Box>

        {/* <Box>
          <IconButton onClick={() => { props.handleViewChange('list') }} color={props.view == 'list' ? 'primary' : 'default'}>
            <ListIcon />
          </IconButton>

          <IconButton onClick={() => { props.handleViewChange('compare') }} color={props.view == 'compare' ? 'primary' : 'default'} tooltip='Compare'>
            <CompareIcon />
          </IconButton>
        </Box> */}
      </Flex>

      <Box height="85%" overflow='auto'>
        <Table size='small' aria-label='adverse events table' stickyHeader={true} id='adverse-events-table'>
          <TableHeadSort
            headers={headers}
            order={props.orderDirection}
            orderBy={props.orderBy}
            onRequestSort={props.handleRequestSort}
          />

          <TableBody>
            <React.Fragment>
              {props.loading ?
                <TableRow hover={false}>
                  <TableCell noBorder colSpan={5}>
                    <LinearProgress />
                  </TableCell>
                </TableRow>
              : ""}
              {props.adverseEvents.map((adverseEvent) =>
                <TableRow key={adverseEvent.id}>
                  <TableCell>
                    <Link href={`/sectors/${props.sector.abbr}/products/${adverseEvent.product_id}`}>
                      <Flex>
                        <Box>
                          {adverseEvent.short_name}
                        </Box>
                      </Flex>
                    </Link>
                  </TableCell>
                  <TableCell>
                    <Link href={`/sectors/${props.sector.abbr}/companies/${adverseEvent.company_id}`}>
                      <Box>
                        {adverseEvent.manufacturer_g1_name}
                      </Box>
                    </Link>
                  </TableCell>
                  <TableCell>
                    {adverseEvent.event_type}
                  </TableCell>
                  <TableCell>
                    {adverseEvent.date_received}
                  </TableCell>
                  <TableCell>
                    <Link href={`https://www.accessdata.fda.gov/scripts/cdrh/cfdocs/cfMAUDE/detail.cfm?mdrfoi__id=${adverseEvent.mdr_report_key}`} target="_blank">
                      <Box>
                        {adverseEvent.mdr_report_key}
                      </Box>
                    </Link>
                  </TableCell>
                </TableRow>
              )}
            </React.Fragment>
          </TableBody>
          <TableFooter>
            <TablePagination
              colSpan={5}
              count={props.adverseEventsCount}
              rowsPerPage={100}
              page={props.page}
              SelectProps={{
                inputProps: { 'aria-label': 'rows per page' },
                native: true,
              }}
              onChangePage={props.handleChangePage}
              onChangeRowsPerPage={props.handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </TableFooter>
        </Table>
      </Box>
    </Fragment>
  )
}
