import React from "react"
import { TableRow, TableCell } from "../../../tables"
import AccountLink from "./AccountLink"
import DeleteButton from "../../DeleteButton"

export default function FollowRow(props) {

  function handleDeleteClick(e) {
    props.handleDeleteClick(props.follow)
  }

  return(
    <TableRow>
      <TableCell>
        <AccountLink
          display={props.follow.account_name}
          accountUserName={props.follow.username}
        />
      </TableCell>
      <TableCell>
        {props.follow.account_name}
      </TableCell>
      <TableCell>
        <DeleteButton onClick={handleDeleteClick} />
      </TableCell>
    </TableRow>
  )
}
