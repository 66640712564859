import React, { Component } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Abstracts from "./Abstracts"
import update from 'immutability-helper'

class MeetingContainer extends Component {
  constructor(props) {
    super(props)
    this.handleFilterChange = this.handleFilterChange.bind(this)
    this.state = { activeTab: 'Abstracts', abstracts: this.props.abstracts, filterOptions: { companies: this.props.companies, products: this.props.products, trials: this.props.trials, years: this.props.years }, filterValues: { company: this.props.company, product: this.props.product, trial: this.props.trial, year: this.props.year } }
  }

  handleFilterChange(name, value) {
    let params = Object.assign({}, this.state.filterValues)
    params[name] = value
    $.ajax({
      type: 'GET',
      url: `/sectors/${this.props.sector.abbr}/meetings/${this.props.meeting.id}`,
      data: params,
      dataType: 'json',
      success: (data) => {
        let filterValues = update(this.state.filterValues, {[name]: {$set: value}})
        let filterOptions = { companies: data.companies, products: data.products, trials: data.trials }
        this.setState({
          abstracts: data.abstracts,
          filterValues,
          filterOptions
        })
      }
    })
  }

  render() {
    return(
      <div>

        <Abstracts
          header={this.props.meeting.long_name}
          abstracts={this.state.abstracts}
          sector={this.props.sector}
        />

      </div>
    )
  }
}

export default MeetingContainer
