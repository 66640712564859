import React from "react"
import { TableRow, TableCell } from "../../../tables"
import Link from "../../../Link"

export default function ClassificationTableRow(props) {
  return (
    <TableRow>
      <TableCell>
        <Link target="_blank" href={`/admin/patents/db_search?patent_classification_definition_id=${props.classification.patent_classification_definition_id}`}>
          {props.classification.classification}
        </Link>
      </TableCell>
      <TableCell>
        {props.classification.definition}
      </TableCell>
      <TableCell>
        {props.classification.patent_count}
      </TableCell>
      <TableCell>
        {`${Math.round((props.classification.patent_count * 100.0) / props.classificationsCount)} %`}
      </TableCell>
    </TableRow>
  )
}
