import React, { Fragment } from "react"
import Chart from "../charts/Chart"


export default function AdverseEventsChart(props) {

  function setXAxisPlotLine() {
    return([{ color: '#B57E7E', value: props.dateApproved, width: 2, zIndex: 1, id: 'product-approval', label: { text: 'Product Approved' }}, { color: '#B57E7E', value: props.dateRecalled, width: 2, zIndex: 1, id: 'product-approval', label: { text: 'Product Recalled' }}])
  }


  return(
    <Fragment>

      <Chart
        key={`product-adverse-events-chart-${props.chartReloadKey}`}
        chartId={props.chartId || 'product-adverse-events-count-chart'}
        chartOptions={{ type: 'column', height: '35%' }}
        plotOptions={{ column: { dataLabels: { enabled: true } } }}
        exportingChartOptions={{ credits: { enabled: true, text: '\u00A9 MarketMonitors' } }}
        legend={true}
        data={props.chartData}
        categories={props.categories}
        title={props.comparison ? 'Top 10 Adverse Events by Product' : 'Top 10 Adverse Events by Device Problem'}
        subtitle="2015 - 2022 YTD     |     Source: FDA MAUDE"
        yAxisTitle='# of adverse events'
        xAxisTitle={props.comparison ? 'Product' : 'Device Problem'}
        simple={true}
        xAxisPlotLine={setXAxisPlotLine()}
      />


    </Fragment>
  )
}
