import React, { Component, Fragment } from 'react';
import MuiDialog from '@material-ui/core/Dialog';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogContentText from '@material-ui/core/DialogContentText';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import Button from "./buttons/Button"
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Flex from "./Flex"
import Box from "./Box"

export default class Dialog extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <MuiDialog open={this.props.openDialog} onClose={this.props.handleClose} aria-labelledby="source-dialog" fullWidth={this.props.fullWidth} maxWidth='md'>
        <MuiDialogTitle>
          <Flex alignItems='center'>
            <Box>
              {this.props.dialogTitle}
            </Box>

            {this.props.withClose ?
              <Box ml="auto">
                <IconButton aria-label="close" onClick={this.props.handleClose} size='small'>
                  <CloseIcon />
                </IconButton>
              </Box>
            : ""}
          </Flex>
        </MuiDialogTitle>
        <MuiDialogContent>
          {this.props.dialogContent}
        </MuiDialogContent>
        {this.props.withButtons ?
          <MuiDialogActions>
            <Button onClick={this.props.handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={this.props.handleAddClick} color="primary">
              {this.props.confirmText ? this.props.confirmText : 'Add'}
            </Button>
          </MuiDialogActions>
        : ''}
        {this.props.actionContent ?
          <MuiDialogActions>
            {this.props.actionContent}
          </MuiDialogActions>
        : ""}
      </MuiDialog>
    );
  }
}

Dialog.defaultProps = {
  withButtons: true,
  fullWidth: true
}
