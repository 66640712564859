import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import { ListItem, ListItemText } from "../../lists"

export default class TaskListItem extends Component {
  constructor(props) {
    super(props)
  }
  
  render() {
    const projectId = this.props.task.projects[0].id
    const taskUrl = this.props.task.tags.length > 0 ? this.props.task.tags[0].name : `https://app.asana.com/0/${projectId}/${this.props.task.id}`
    return(
      <Fragment>
        <ListItem key={this.props.task.id} component='a' href={taskUrl} target="_blank">
          <ListItemText primary={this.props.task.name} />
          <ListItemText 
            secondary={humanDateFormat(this.props.task.due_on)} 
          />
        </ListItem>
      </Fragment>
    )
  }
}
