import React from "react"
import { List, ListItemLink, ListItemText } from "../lists"


export default function NewModule(props) {
  return(
    <List>
      {props.newTrials.map((trial) =>
        <ListItemLink key={trial.id} href={`/sectors/${props.sector.abbr}/trials/${trial.id}`}>
          <ListItemText primary={trial.data_view_acronym || trial.data_view_short_name} secondary={humanDateFormat(trial.created_at)} />
        </ListItemLink>
      )}
    </List>
  )
}
