import React from 'react';
import MuiCheckbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';


export default function Checkbox(props) {
  const { label, ...rest } = props
  return(
    <FormControlLabel
      control={
        <MuiCheckbox {...rest} />
      }
      label={label}
    />
  )
}
