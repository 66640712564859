import React from "react"
import Paper from "../Paper"
import Flex from "../Flex"
import Box from "../Box"
import Typography from "@material-ui/core/Typography"
import { Tabs, Tab } from '../tabs';
import { List, ListItem, ListItemText, ListItemLink } from "../lists"

export default function NearCompletionModule(props) {

  return(
    <Paper className='h-100'>

      <Flex alignItems='center' borderBottom={1} borderColor="#e5e5e5">
        <Box>
          <Flex alignItems='baseline'>
            <Box>
              <Typography variant="h6">
                Upcoming Trial Completion
              </Typography>
            </Box>

            <Box ml={4}>
              <Typography variant="caption">
                within the next 3 months
              </Typography>
            </Box>
          </Flex>
        </Box>

        {/* <Box ml='auto'>
          <Tabs value={props.tabIndex} onChange={props.handleTabIndexChange} aria-label="trials date tabs">
            <Tab label="3 Months" />
            <Tab label="6 Months" />
            <Tab label="1 Year" />
          </Tabs>
        </Box> */}
      </Flex>

      <Box mt={2} height="90%">
        {props.trials.length > 0 ?
          <List>
            {props.trials.map((trial) =>
              <ListItemLink key={trial.id} my={2} href={`/sectors/${props.sector.abbr}/trials/${trial.trial_id}`} divider>
                <ListItemText
                  primary={trial.acronym || trial.short_name}
                />

                <Typography variant="body1" color='black'>
                  {humanDateFormat(trial.completion_date)}
                </Typography>
              </ListItemLink>
            )}
          </List>
        :
          <Flex alignItems='center' justifyContent='center' height="90%">
            <Box textAlign='center' maxWidth="50%">
              No trials are set to complete within the next 3 months.
            </Box>
          </Flex>
        }
      </Box>

    </Paper>
  )
}
