import React, { Component } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"

class CardSection extends Component {

  render() {
    let className = this.props.noBorder ? '' : 'bb b--black-10 pb3'
    let classes = `${className} ${this.props.className}`
    return (
      <div className={classes}>
        {this.props.children}
      </div>
    );
  }
}

export default CardSection
