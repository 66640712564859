import React, { Fragment } from "react"
import Paper from "../../Paper"
import Box from "../../Box"
import Tabs from "../../tabs/Tabs"
import Tab from "../../tabs/Tab"
import SuggestedAssociationsList from "./SuggestedAssociationsList"
import ProductsList from "../products/ProductsList"
import TrialsList from "../trials/associator/EntitiesList"
import AssociatedProductsList from "./AssociatedProductsList"
import AssociatedTrialsList from "./AssociatedTrialsList"


export default function EntitiesPanel(props) {
  return (
    <React.Fragment>
      <Paper fullHeight>

        <Box height="100%">

          <Tabs variant='fullWidth' value={props.activeIndex} onChange={props.handleActiveIndexUpdate} className='bb b--black-10' aria-label="entities tabs">
            <Tab label='Predicted' />
            <Tab label='Products' />
            <Tab label='Trials' />
            <Tab label='Associated' />
          </Tabs>

          <Box mt={2} height="90%" overflow="auto">

            {props.activeIndex == 0 ?
              <SuggestedAssociationsList
                predictedProducts={props.predictedProducts}
                predictedTrials={props.predictedTrials}
                activeProducts={props.activeProducts}
                abstractProducts={props.abstractProducts}
                activeTrials={props.activeTrials}
                abstractTrials={props.abstractTrials}
                handleCheckboxSelect={props.handleCheckboxSelect}
                predictedIndex={props.predictedIndex}
                handlePredictedIndexUpdate={props.handlePredictedIndexUpdate}
              />
            : ''}

            {props.activeIndex == 1 ?
              <ProductsList
                products={props.products}
                activeProducts={props.activeProducts}
                entityProducts={props.abstractProducts}
                searchedProducts={props.searchedProducts}
                productQuery={props.productQuery}
                handleProductSearchChange={props.handleProductSearchChange}
                handleProductSearchClick={props.handleProductSearchClick}
                handleCheckboxSelect={props.handleCheckboxSelect}
              />
            : ''}

            {props.activeIndex == 2 ?
              <TrialsList
                trials={props.trials}
                searchedTrials={props.searchedTrials}
                activeTrials={props.activeTrials}
                entityTrials={props.abstractTrials}
                query={props.trialsQuery}
                handleCheckboxSelect={props.handleCheckboxSelect}
                handleSearchChange={props.handleTrialSearchChange}
                handleSearchClick={props.handleTrialSearchClick}
              />
            : ''}

            {props.activeIndex == 3 ?
              <Box>
                <AssociatedProductsList
                  abstractProducts={props.abstractProducts}
                  handleUnassociateClick={props.handleUnassociateClick}
                />
                <AssociatedTrialsList
                  abstractTrials={props.abstractTrials}
                  handleTrialUnassociateClick={props.handleTrialUnassociateClick}
                />
              </Box>
            : ''}

          </Box>
        </Box>

      </Paper>
    </React.Fragment>
  )
}
