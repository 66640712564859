import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Flex from "../Flex"
import Box from "../Box"
import Paper from "../Paper"
import Grid from '@material-ui/core/Grid';
import StatSection from "../dashboard/StatSection"
import Typography from "@material-ui/core/Typography"
import Chart from "../charts/Chart"
import ArticlesList from "../articles/ArticlesList"
import { List, ListItem, ListItemLink, ListItemText } from "../lists"
import { Table, TableRow, TableCell, TableBody, TableHead } from "../tables"
import Tabs from '../tabs/Tabs';
import Tab from '../tabs/Tab';
import { Button } from "../buttons"
import Link from "../Link"
import Tooltip from "@material-ui/core/Tooltip"
import OverviewModule from "./profile/OverviewModule"

export default function ProfileDashboard(props) {

  return(
    <Fragment>

      {/* <Box flexGrow={1} pb={3}> */}
      <Grid container component={Box} pb={3} spacing={3}>
        <Grid item xs={12} lg={6}>
          <OverviewModule
            product={props.product}
            company={props.company}
            overview_description={props.overview_description}
            product_pmas={props.product_pmas}
            sector={props.sector}
          />
        </Grid>

        <Grid item xs={12} lg={6} container>
          <Grid item container direction="column" spacing={2}>
            <Grid item className='h-40'>
              <Paper className='h-100'>
                <Typography variant="h6">
                  Regulatory Status
                </Typography>

                <Box mt={2} height={1}>
                  {Object.keys(props.regulatory_statuses).length > 0 ?
                    <Box>
                      {Object.keys(props.regulatory_statuses).map((region) =>
                        <div key={region} className='mb2'>
                          <div className='b'>
                            {region}
                          </div>

                          {props.regulatory_statuses[region].map((regulatoryStatus) =>
                            <div key={regulatoryStatus.id}>
                              {regulatoryStatus.name}
                              {regulatoryStatus.status_display_date ?
                                <Tooltip title='Date of status'>
                                  <span className='pl2 f6 i'>
                                    {`(${humanDateFormat(regulatoryStatus.status_display_date)})`}
                                  </span>
                                </Tooltip>
                              : ""}
                            </div>
                          )}
                        </div>
                      )}
                    </Box>

                  :
                    <Flex justifyContent='center' height="50%">
                      <Box alignSelf='center' maxWidth="50%">
                        <Box>
                          <Typography variant="body1">
                            No status found
                          </Typography>
                        </Box>
                      </Box>
                    </Flex>
                  }
                </Box>
              </Paper>
            </Grid>

            <Grid item className='h-30'>
              <Box height={1}>
                <Paper height="100%">
                  <Typography variant="h6">
                    Our Data
                  </Typography>

                  <Box mt={2}>
                    <List dense>
                      <ListItemLink href={`/sectors/${props.sector.abbr}/products/${props.product.id}/studies`} divider={true}>
                        <ListItemText primary={`${props.trial_count} Trials`} />
                      </ListItemLink>

                      <ListItemLink href={`/sectors/${props.sector.abbr}/products/${props.product.id}/presentations`} divider={true}>
                        <ListItemText
                          primary={`${props.presentations_count} Presentations`}
                        />
                      </ListItemLink>

                      <ListItemLink href={`/sectors/${props.sector.abbr}/products/${props.product.id}/manuscripts`} divider={true}>
                        <ListItemText
                          primary={`${props.publications_count} Publications`}
                        />
                      </ListItemLink>

                      <ListItemLink href={`/sectors/${props.sector.abbr}/products/${props.product.id}/adverse_events`} divider={true}>
                        <ListItemText
                          primary={`${props.adverse_events_count} Adverse Events`}
                        />
                      </ListItemLink>

                    </List>
                  </Box>
                </Paper>
              </Box>


            </Grid>

          </Grid>
        </Grid>
      </Grid>

      {/* <Grid container spacing={3} alignItems='stretch' justifyContent='center' component={Box} pb={3}>
        <Grid item xs={10}>
          <Paper className='h-100'>
            <Typography variant="h6">
              Yearly Activity
            </Typography>

            <Box mt={2}>
              <Chart
                key='activity-by-year'
                chartId='activity-by-year-count-chart'
                chartOptions={{ type: 'column', height: '35%' }}
                exportingChartOptions={{ credits: { enabled: true, text: '\u00A9 pvpipeline' } }}
                legend={true}
                data={props.activity_data}
                categories={props.activity_categories}
                title=''
                yAxisTitle='Updates'
                xAxisTitle='Year'
                simple={true}
              />
            </Box>
          </Paper>
        </Grid>
      </Grid> */}


      <Grid container spacing={3} alignItems='stretch' component={Box} pb={3}>
        <Grid item xs={12} lg={12}>
          <Paper className='h-100'>
            <Flex alignItems='center'>
              <Box>
                <Typography variant="h6">
                  Latest Trial Updates
                </Typography>
              </Box>

              <Box ml='auto'>
                <Button href={`/sectors/${props.sector.abbr}/products/${props.product.id}/studies`} component='a'>
                  View All Trials
                </Button>
              </Box>
            </Flex>

            <Box mt={2} height={1}>
              {props.trial_changes.length > 0 ?
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Trial</TableCell>
                      <TableCell>Sponsor</TableCell>
                      <TableCell>Type</TableCell>
                      <TableCell>Last Update</TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {props.trial_changes.map((trialChange) =>
                      <TableRow key={trialChange.trial_id}>
                        <TableCell>
                          <Link href={`/sectors/${props.sector.abbr}/trials/${trialChange.trial_id}`}>
                            {trialChange.acronym || trialChange.short_name}
                          </Link>
                        </TableCell>
                        <TableCell>
                          {trialChange.company_id ?
                            <Link href={`/sectors/${props.sector.abbr}/companies/${trialChange.company_id}`}>
                              {trialChange.sponsor_name}
                            </Link>
                          :
                            trialChange.sponsor_name
                          }
                        </TableCell>
                        <TableCell>
                          {trialChange.grouped_types ?
                            trialChange.grouped_types.split(";").map((type) =>
                              <div>
                                {type}
                              </div>
                            )
                          : ''}
                        </TableCell>
                        <TableCell>
                          {humanDateFormat(trialChange.last_updated)}
                        </TableCell>

                        <TableCell>
                          <Button href={`/sectors/${props.sector.abbr}/trials/${trialChange.trial_id}/updates`} component='a'>
                            View Updates
                          </Button>
                        </TableCell>
                      </TableRow>
                      // <ListItemLink key={trialChange.trial_id} divider my={2} href={`/sectors/${props.sector.abbr}/trials/${trialChange.trial_id}/updates`}>
                      //   <ListItemText
                      //     style={{flex: "0.5"}}
                      //     primary={trialChange.acronym || trialChange.short_name}
                      //     secondary={trialChange.grouped_types}
                      //   />
                      //
                      //   <Typography variant="body1" style={{flex: "0.5"}} color='black'>
                      //     {trialChange.sponsor_name}
                      //   </Typography>
                      //
                      //   <Typography variant="body1" color='black'>
                      //     {`${trialChange.change_count} updates`}
                      //   </Typography>
                      // </ListItemLink>
                    )}
                  </TableBody>
                </Table>
              :
                <Flex justifyContent='center' height="50%">
                  <Box alignSelf='center' maxWidth="50%">
                    <Box>
                      <Typography variant="body1">
                        No updates found
                      </Typography>
                    </Box>
                  </Box>
                </Flex>
              }
            </Box>
          </Paper>
        </Grid>

        {/* <Grid item xs={12} lg={5}>
          <Paper className='h-100'>
            <Typography variant="h6">
              Trials by year
            </Typography>

            <Box mt={2}>
              <Chart
                key={`trials-by-year`}
                chartId='trials-by-year-count-chart'
                chartOptions={{ type: 'column', height: '35%' }}
                exportingChartOptions={{ credits: { enabled: true, text: '\u00A9 pvpipeline' } }}
                legend={true}
                data={props.trial_chart_data}
                categories={props.trial_categories}
                title=''
                yAxisTitle='# of trials'
                xAxisTitle='Year'
                simple={true}
              />
            </Box>
          </Paper>
        </Grid> */}


      </Grid>

      <Grid container spacing={3} justifyContent='center' alignItems='stretch' component={Box} pb={3}>
        <Grid item xs={12} lg={10}>
          <Paper style={{height: '600px'}}>
            <Typography variant="h6">
              Latest News
            </Typography>

            <Box height="100%">
              {props.all_news.length > 0 ?
                <Box mt={2} overflow='auto' height="90%">
                  <ArticlesList
                    dense={true}
                    newsArticles={props.all_news}
                    sector={props.sector}
                  />
                </Box>
              :
                <Flex justifyContent='center' height="50%">
                  <Box alignSelf='center' maxWidth="50%">
                    <Box>
                      <Typography variant="body1">
                        No articles found
                      </Typography>
                    </Box>
                  </Box>
                </Flex>
              }
            </Box>
          </Paper>
        </Grid>
      </Grid>


      <Grid container spacing={3} alignItems='stretch' component={Box} pb={3}>
        <Grid item xs={12} lg={6}>
          <Paper className='h-100'>
            <Flex alignItems='center'>
              <Box>
                <Typography variant="h6">
                  Latest Publications
                </Typography>
              </Box>

              <Box ml='auto'>
                <Button href={`/sectors/${props.sector.abbr}/products/${props.product.id}/manuscripts`} component='a'>
                  View All
                </Button>
              </Box>
            </Flex>

            <Box mt={2} height={1}>
              {props.latest_manuscripts.length > 0 ?
                <List dense={props.dense ? props.dense : false}>
                  {props.latest_manuscripts.map((manuscript) =>
                    <ListItemLink divider key={manuscript.id} href={`/sectors/${props.sector.abbr}/publications/${manuscript.id}`} target="_blank">
                      <ListItemText
                        primary={manuscript.title}
                        secondary={manuscript.journal_id}
                      />
                      <ListItemText secondary={manuscript.date ? manuscript.date.split("T")[0] : 'N/A'} className='tr' />
                    </ListItemLink>
                  )}
                </List>
              :
                <Flex justifyContent='center' height="50%">
                  <Box alignSelf='center' maxWidth="50%">
                    <Box>
                      <Typography variant="body1">
                        No publications found
                      </Typography>
                    </Box>
                  </Box>
                </Flex>
              }
            </Box>
          </Paper>

        </Grid>

        <Grid item xs={12} lg={6}>
          <Paper className='h-100'>
            <Flex alignItems='center'>
              <Box>
                <Typography variant="h6">
                  Latest Presentations
                </Typography>
              </Box>

              <Box ml='auto'>
                <Button href={`/sectors/${props.sector.abbr}/products/${props.product.id}/presentations`} component='a'>
                  View All
                </Button>
              </Box>
            </Flex>

            <Box mt={2} height={1}>
              {props.latest_presentations.length > 0 ?
                <List dense={props.dense ? props.dense : false}>
                  {props.latest_presentations.map((abstract) =>
                    <ListItemLink divider key={abstract.id} href={`/sectors/${props.sector.abbr}/abstracts/${abstract.id}`} target="_blank">
                      <ListItemText
                        primary={abstract.title}
                        secondary={`${abstract.short_name} ${abstract.year}`}
                      />
                      <ListItemText secondary={abstract.date ? abstract.date.split("T")[0] : "N/A"} className='tr' />
                    </ListItemLink>
                  )}
                </List>
              :
                <Flex justifyContent='center' height="50%">
                  <Box alignSelf='center' maxWidth="50%">
                    <Box>
                      <Typography variant="body1">
                        No presentations found
                      </Typography>
                    </Box>
                  </Box>
                </Flex>
              }
            </Box>
          </Paper>

        </Grid>
      </Grid>

      {props.fda_adverse_event_chart_data ?
        <Grid container spacing={3} justifyContent='center' component={Box} pb={3}>
          <Grid item xs={12} lg={10}>
            <Paper>
              <Typography variant="h6">
                FDA adverse events by year
              </Typography>

              <Box mt={2}>
                <Chart
                  key={`adverse-events-by-year`}
                  chartId='adverse-events-by-year-count-chart'
                  chartOptions={{ type: 'column', height: '35%' }}
                  exportingChartOptions={{ credits: { enabled: true, text: '\u00A9 pvpipeline' } }}
                  legend={true}
                  data={props.fda_adverse_event_chart_data}
                  categories={props.adverse_event_categories}
                  title=''
                  yAxisTitle='# of adverse events'
                  xAxisTitle='Year'
                  simple={true}
                />
              </Box>
            </Paper>
          </Grid>
        </Grid>
      : ''}

      <Grid container spacing={3} component={Box} pb={3}>
        <Grid item xs={12} lg={6}>
          <Paper className='h-100'>
            <Typography variant="h6">
              Publications by year
            </Typography>

            <Box mt={2}>
              <Chart
                key={`publications-by-year`}
                chartId='publications-by-year-count-chart'
                chartOptions={{ type: 'column', height: '80%' }}
                exportingChartOptions={{ credits: { enabled: true, text: '\u00A9 pvpipeline' } }}
                legend={true}
                data={props.publication_chart_data}
                categories={props.publication_categories}
                title=''
                yAxisTitle='# of publications'
                xAxisTitle='Year'
                simple={true}
              />
            </Box>
          </Paper>
        </Grid>

        <Grid item xs={12} lg={6}>

          <Paper className='h-100'>
            <Typography variant="h6">
              Presentations by year
            </Typography>

            <Box mt={2}>
              <Chart
                key={`presentations-by-year`}
                chartId='presentations-by-year-count-chart'
                chartOptions={{ type: 'column', height: '80%' }}
                exportingChartOptions={{ credits: { enabled: true, text: '\u00A9 pvpipeline' } }}
                legend={true}
                data={props.presentation_chart_data}
                categories={props.presentation_categories}
                title=''
                yAxisTitle='# of presentations'
                xAxisTitle='Year'
                simple={true}
              />
            </Box>
          </Paper>

        </Grid>
      </Grid>

    </Fragment>
  );
}
