import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Flex from "../../Flex"
import Box from "../../Box"
import { Button } from "../../buttons"
import Typography from "@material-ui/core/Typography"
import Paper from "../../Paper"
import { List, ListItem, ListItemText } from "../../lists"
import IconButton from "../../IconButton"
import ContentDisplay from "./ContentDisplay"
import Snackbar from "../../Snackbar"
import Dialog from "../../Dialog"

class Index extends Component {
  constructor(props) {
    super(props)
    this.handleDeleteClick = this.handleDeleteClick.bind(this)
    this.handleUndoClick = this.handleUndoClick.bind(this)
    this.state = { profileFields: this.props.profile_fields, showSnackbar: false, snackbarText: '', justDeletedId: null }
  }

  handleUndoClick(e) {
    if (this.state.justDeletedId) {
      $.ajax({
        type: 'PUT',
        url: `/admin/products/${this.props.product.id}/profile_fields/${this.state.justDeletedId}`,
        data: {
          product_profile_field: {
            deleted: null, active: true, deleted_by: null
          }
        },
        dataType: 'json',
        success: (data) => {
          this.setState({
            showDialog: false,
            profileFields: data.profile_fields,
            showSnackbar: true,
            snackbarText: 'Profile field was deleted',
            justDeletedId: null
          })
        },
        error: (data) => {
          this.setState({
            showSnackbar: true,
            snackbarText: data.responseText
          })
        },
        completed: (data) => {
          setTimeout(() => {
            this.setState({
              showSnackbar: false,
              snackbarText: ''
            })
          }, 4000)
        }
      })
    }
  }

  handleDeleteClick(profileField) {
    $.ajax({
      type: 'PUT',
      url: `/admin/products/${this.props.product.id}/profile_fields/${profileField.id}`,
      data: {
        product_profile_field: {
          deleted: true, active: false, deleted_by: this.props.current_user.id
        }
      },
      dataType: 'json',
      success: (data) => {
        this.setState({
          showDialog: false,
          profileFields: data.profile_fields,
          showSnackbar: true,
          snackbarText: 'Profile field was deleted',
          justDeletedId: profileField.id
        })
      },
      error: (data) => {
        this.setState({
          showSnackbar: true,
          snackbarText: data.responseText
        })
      },
      completed: (data) => {
        setTimeout(() => {
          this.setState({
            showSnackbar: false,
            snackbarText: ''
          })
        }, 5000)
      }
    })
  }

  render() {
    const profileFieldNames = Object.keys(this.state.profileFields)
    return(
      <Fragment>

        <Paper minFullHeight>

          <Flex mb={3}>
            <Box>
              <Typography variant="h5" component="h5" className='mt0'>
                Profile Fields
              </Typography>
            </Box>

            <Box ml="auto">
              <Button tag='a' href={`/admin/products/${this.props.product.id}/profile_fields/new`}>
                New Profile Field
              </Button>
            </Box>
          </Flex>


          <List>
            {profileFieldNames.length > 0 ? profileFieldNames.map((profileFieldName) =>
              <ContentDisplay
                key={profileFieldName}
                product={this.props.product}
                profileFieldName={profileFieldName}
                handleDeleteClick={this.handleDeleteClick}
                profileFieldHash={this.state.profileFields[profileFieldName][0]}
              />
            ) : <div>No profile fields found. Create a profile field and it will display here.</div>}
          </List>

        </Paper>

        <Snackbar
          open={this.state.showSnackbar}
          handleUndoClick={this.handleUndoClick}
          handleClose={this.handleClose}
          message={this.state.snackbarText}
        />

      </Fragment>
    )
  }
}


export default Index
