import React, {Component} from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Paper from "../Paper"
import { Table, TableHead, TableRow, TableCell, TableBody } from "../tables"
import Link from "../Link"
import { Button, RaisedButton, Fab } from "../buttons"
import Flex from "../Flex"
import Box from "../Box"
import DeleteButton from "../admin/DeleteButton"
import Tooltip from '@material-ui/core/Tooltip';

export default class CompareEntities extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    return(
      <Paper elevation={6}>
        <Table aria-label='compare table'>
          <TableHead>
            <TableRow hover={false}>
              <TableCell borderRight={true} className='bg-white'>
              </TableCell>

              <TableCell borderRight={true} className='bg-white'>
                <div>
                  Product A
                </div>
                <em className='f6'>Company A</em>
              </TableCell>

              <TableCell borderRight={true} className='bg-white'>
                <div>
                  Product B
                </div>
                <em className='f6'>Company B</em>
              </TableCell>

              <TableCell borderRight={true} className='bg-white'>
                <div>
                  Product C
                </div>
                <em className='f6'>Company C</em>
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            <TableRow hover={false}>
              <TableCell borderRight={true}>
                Drug Method of Action
              </TableCell>

              <TableCell borderRight={true}>
                Antiproliferative
              </TableCell>

              <TableCell borderRight={true}>
                mTOR inhibitor
              </TableCell>

              <TableCell borderRight={true}>
                Antiproliferative
              </TableCell>
            </TableRow>

            <TableRow hover={false}>
              <TableCell borderRight={true}>
                Drug(s)
              </TableCell>

              <TableCell borderRight={true}>
                Paclitaxel
              </TableCell>

              <TableCell borderRight={true}>
                Sirolimus
              </TableCell>

              <TableCell borderRight={true}>
                Paclitaxel
              </TableCell>
            </TableRow>

            <TableRow hover={false}>
              <TableCell borderRight={true}>
                Excipient
              </TableCell>

              <TableCell borderRight={true}>
                Polysorbate + sorbitol
              </TableCell>

              <TableCell borderRight={true}>
                Phospholipid
              </TableCell>

              <TableCell borderRight={true}>
                Organic ester
              </TableCell>
            </TableRow>

            <TableRow hover={false}>
              <TableCell borderRight={true}>
                Guidewire sizes
              </TableCell>

              <TableCell borderRight={true}>
                <div>
                  0.014 in
                </div>
              </TableCell>

              <TableCell borderRight={true}>
                <div>
                  0.014 in
                </div>
                <div>
                  0.018 in
                </div>
              </TableCell>

              <TableCell borderRight={true}>
                <div>
                  0.014 in
                </div>
              </TableCell>
            </TableRow>

            <TableRow hover={false}>
              <TableCell borderRight={true}>
                Lengths
              </TableCell>

              <TableCell borderRight={true}>
                <div>
                  40 mm
                </div>
                <div>
                  80 mm
                </div>
                <div>
                  100 mm
                </div>
                <div>
                  120 mm
                </div>
                <div>
                  150 mm
                </div>
              </TableCell>

              <TableCell borderRight={true}>
                <div>
                  40 mm
                </div>
                <div>
                  60 mm
                </div>
                <div>
                  80 mm
                </div>
                <div>
                  100 mm
                </div>
                <div>
                  120 mm
                </div>
                <div>
                  150 mm
                </div>
                <div>
                  200 mm
                </div>
              </TableCell>

              <TableCell borderRight={true}>
                <div>
                  40 mm
                </div>
                <div>
                  60 mm
                </div>
                <div>
                  80 mm
                </div>
                <div>
                  120 mm
                </div>
                <div>
                  150 mm
                </div>
                <div>
                  200 mm
                </div>
              </TableCell>
            </TableRow>
          </TableBody>

        </Table>
      </Paper>
    )
  }
}
