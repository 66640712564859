import React from "react";
import Flex from "../Flex";
import Box from "../Box";
import FilterOption from "./FilterOption";
import TextInput from "../TextInput";
import ClearIcon from '@material-ui/icons/Clear';
import IconButton from "../IconButton";
import InputAdornment from "@material-ui/core/InputAdornment"
import SecondaryText from "../SecondaryText";
import Collapse from "@material-ui/core/Collapse";
import ExpandMore from "../ExpandMore";
import ExpandLess from '../ExpandLess';
import SortIcon from '@material-ui/icons/Sort';
import SortByAlphaIcon from '@material-ui/icons/SortByAlpha';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

export default function FilteredList(props) {
  const { items, filterName, label, filterValues, handleFilterChange, children, defaultOpen=false, filterValue: initialFilterValue } = props;
  
  const [filteredItems, setFilteredItems] = React.useState(items);
  const [filterValue, setFilterValue] = React.useState(initialFilterValue || '');
  const [open, setOpen] = React.useState(defaultOpen);
  const [alphSortOrder, setAlphSortOrder] = React.useState('asc');
  const [countSortOrder, setCountSortOrder] = React.useState('asc');
  const [allChecked, setAllChecked] = React.useState(false);

  function handleCheckAllClick(e) {
    setAllChecked(!allChecked);
    props.handleFilterChange(filterValue, filterName)
  }

  function handleFilterSearchChange(e) {
    setFilterValue(e.target.value);
    const filtered = items.filter(item => item.name.toLowerCase().includes(e.target.value.toLowerCase()));
    setFilteredItems(filtered);
  }

  function handleClearSearchClick(e) {
    setFilterValue('');
    setFilteredItems(items);
  }

  function handleSortCountClick(e) {
    if (countSortOrder === 'asc') {
      const sorted = filteredItems.sort((a, b) => a.count - b.count);
      setFilteredItems([...sorted]);
      setCountSortOrder('desc');
    } else {
      const sorted = filteredItems.sort((a, b) => b.count - a.count);
      setFilteredItems([...sorted]);
      setCountSortOrder('asc');
    }
  }

  function handleAlphabeticalSortClick(e) {
    if (alphSortOrder === 'asc') {
      const sorted = filteredItems.sort((a, b) => a.name.localeCompare(b.name));
      setFilteredItems([...sorted]);
      setAlphSortOrder('desc');
    } else {
      const sorted = filteredItems.sort((a, b) => b.name.localeCompare(a.name));
      setFilteredItems([...sorted]);
      setAlphSortOrder('asc');
    }
  }

  return (
    <Box border={1} borderColor="rgba(0, 0, 0, 0.12)">
      <Flex alignItems='center' width={1} px={2}>
        <Box className='MuiTypography-colorTextSecondary' width="90%">
          {label}
        </Box>
        <Box>
          <IconButton onClick={() => setOpen(!open)} tooltip='expand' edge="end" aria-label="expand">
            {open ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
        </Box>
      </Flex>

      <Collapse in={open} timeout="auto" unmountOnExit>
        <Flex alignItems='center' pr={1} mb={2}>
          <Box>
            <IconButton onClick={handleCheckAllClick} size='small'>
              <CheckBoxIcon color='primary' fontSize='small' />
            </IconButton>
          </Box>
          <Box>
            <IconButton onClick={handleSortCountClick} size='small'>
              <SortIcon fontSize='small' />
            </IconButton>
          </Box>
          <Box>
            <IconButton onClick={handleAlphabeticalSortClick} size='small'>
              <SortByAlphaIcon fontSize='small' />
            </IconButton>
          </Box>
          <Box ml='auto' width="60%">
            <TextInput
              name={`${filterName}_search`}
              placeholder="Filter"
              size='small'
              value={filterValue}
              onChange={handleFilterSearchChange}
              InputProps={{
                endAdornment: <InputAdornment position="end"><IconButton onClick={handleClearSearchClick} size='small'><ClearIcon fontSize='small' /></IconButton></InputAdornment>
              }}
            />
          </Box>
        </Flex>
        <Box height="300px" overflow="auto">
          {filteredItems.length === 0 ? (
            <Box mt={2} textAlign="center">
              <SecondaryText>
                <div>{`No ${label} found.`}</div>
                <div>We couldn't find any matching your filter criteria. Please adjust your filters or try a different search term</div>
              </SecondaryText>
            </Box>
          ) : filteredItems.map((item) => {
            // const filterValueForItem = filterName == 'product_codes' ? item.name.split(" -")[0] : item.name;
            const filterValueForItem = item.name;
            const isChecked = allChecked ? true : filterValues[filterName].includes(filterValueForItem);
            
            return(
              <FilterOption
                key={item.name}
                filterObject={item}
                filterName={filterName}
                filterValue={filterValueForItem}
                checked={isChecked}
                handleFilterChange={handleFilterChange}
              />
            )
          }
          )}
        </Box>
      </Collapse>
    </Box>
  );
}
