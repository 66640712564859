import React, { Component } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Link from "../Link"
import { Table, TableRow, TableCell, TableHeader } from "./"

class GroupedTable extends Component {
  constructor(props) {
    super(props);
  }
  
  setEntity() {
    switch (this.props.chartEntityType) {
      case 'person_manuscripts':
      case 'person_abstract':
        "publications"
        break;
      case 'person_trial':
        "trials"
        break;
    }
  }
  
  render() {
    const tbody = Object.keys(this.props.object).map((key) =>
      <TableBody key={key}>
        <TableRow>
          <td className='bg-moon-gray pv1 tc' colSpan={this.props.headers.length}>{key}</td>
        </TableRow>
        {this.props.object[key].map((value) =>
          <TableRow key={value.id}>
            <TableCell>
              {value.entity_info}
            </TableCell>
            
            <TableCell>
              <Link href={`/sectors/${this.props.sector.abbr}/${this.setEntity()}/${value.entity_id}`} target="_blank">
                {value.name}
              </Link>
            </TableCell>
          </TableRow> 
        )}
      </TableBody>
    )
    return (
      <Table className={this.props.className}>
        <TableHeader headers={this.props.headers} />
        {tbody}
      </Table>
    )
  }
}

export default GroupedTable
