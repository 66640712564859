import React from "react"
import FdaPmaApprovalItem from "../fda_pma_approvals/RegulatoryItem"
import FdaKClearanceItem from "../fda_k_clearances/RegulatoryItem"
import FdaHdeApprovalItem from "../fda_hdes/SearchItem"
import DeviceApprovalRow from "../device_approvals/SearchItem"

export default function ApprovalRow(props) {

  const componentMapping = {
    '510k Clearance': FdaKClearanceItem,
    'FDA PMA Approval': FdaPmaApprovalItem,
    'FDA Human Device Exemption': FdaHdeApprovalItem
  };

  const item = props.result

  const modelType = item.record_type; // Assuming modelType is a property to identify the model

  // Get the right component based on the model type
  const ComponentToRender = componentMapping[modelType];

  // Render the component, or a default if the model type is not mapped
  return ComponentToRender ? <ComponentToRender result={item} sector={props.sector} handleProductCodeClick={props.handleProductCodeClick} handleApplicantClick={props.handleApplicantClick} /> : <DeviceApprovalRow result={item} sector={props.sector} url={`/sectors/${props.sector.abbr}/device_approvals/${item.id}`} />;
}