import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import { TableRow, TableCell } from "../../tables"
import IconButton from '@material-ui/core/IconButton';
import FavoriteIcon from '@material-ui/icons/Favorite';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import DeleteIcon from '@material-ui/icons/Delete';

class TrialEnrollmentRow extends Component {
  constructor(props) {
    super(props)
    this.handleDeleteClick = this.handleDeleteClick.bind(this)
    this.handleFavoriteClick = this.handleFavoriteClick.bind(this)
  }

  handleDeleteClick(e) {
    this.props.handleDeleteClick(this.props.trialEnrollmentHash.trial_enrollment, this.props.trialEnrollmentHash.kind)
  }

  handleFavoriteClick(e) {
    this.props.handleFavoriteClick(this.props.trialEnrollmentHash.trial_enrollment, this.props.trialEnrollmentHash.kind)
  }

  render() {
    // const user = this.props.trialEnrollmentHash.creator
    return(
      <TableRow>

        {/* <TableCell>
          <IconButton onClick={this.handleFavoriteClick}>
            {this.props.trialEnrollmentHash.trial_enrollment.active ? <FavoriteIcon /> : <FavoriteBorderIcon />}
          </IconButton>
        </TableCell> */}

        <TableCell>
          {this.props.trialEnrollmentHash.kind}
        </TableCell>

        <TableCell>
          {this.props.trialEnrollmentHash.trial_enrollment.enrollment}
        </TableCell>

        <TableCell>
          {this.props.trialEnrollmentHash.trial_enrollment.date ? humanDateFormat(this.props.trialEnrollmentHash.trial_enrollment.date) : "N/A"}
        </TableCell>

        <TableCell>
          {this.props.trialEnrollmentHash.source_object ? this.props.trialEnrollmentHash.source_object.source_title : 'N/A'}
        </TableCell>

        {/* <TableCell>
          {displayInitials(user.first_name, user.last_name)}
        </TableCell> */}

        <TableCell>
          <IconButton onClick={this.handleDeleteClick}>
            <DeleteIcon />
          </IconButton>
        </TableCell>

      </TableRow>
    )
  }
}

export default TrialEnrollmentRow
