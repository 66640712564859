import React, { Fragment } from "react"
import PropTypes from "prop-types"
import { Table, TableHead, TableBody, TableRow, TableCell } from "../tables"

export default function TimelineDataTable(props) {
  return(
    <Fragment>
      <Table>
        <TableHead>
          <TableRow hover={false}>
            <TableCell>Value</TableCell>
            <TableCell>Kind</TableCell>
            <TableCell>Source</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {props.chartData.map((dataHash) =>
            <TableRow>
              <TableCell>
                <Fragment>
                  <span>
                    {dataHash.kind == 'Status' ? dataHash.label : dataHash.name}
                  </span>
                  {dataHash.kind != 'Status' && dataHash.date_type ?
                    <span className='pl2 i'>
                      {`(${dataHash.date_type})`}
                    </span>
                  : ''}
                </Fragment>
              </TableCell>
              <TableCell>
                {dataHash.kind}
              </TableCell>
              <TableCell>
                {`${dataHash.source_object.source_title} - ${humanDateFormat(dataHash.source_object.source_date)}`}
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </Fragment>
  )
}
