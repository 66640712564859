import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import AppBar from "../AppBar"
// import Link from '@material-ui/core/Link';
import Link from "../Link"
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '../IconButton';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import List from '../lists/List'
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from "../lists/ListItemText";
import ListItemLink from "../lists/ListItemLink";
import NestedProductList from "./NestedProductList"
import NestedCompanyList from "./NestedCompanyList"
import NestedTrialList from "./NestedTrialList"
import NavigationList from "./sources/NavigationList"
import PatentNavigationList from "./patents/PatentNavigationList"
import AlertNavigationList from "./alerts/NavigationList"
import PublicationsNavigationList from "./publications/NavigationList"
import UsersNavigationList from "./users/NavigationList"
import AbstractNavigationList from "./abstracts/NavigationList"
import MeetingsNavigationList from "./meetings/NavigationList"
import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import SearchIcon from '@material-ui/icons/Search'
import HelpIcon from '@material-ui/icons/Help';
import LogoutIcon from "../LogoutIcon"
import TabElements from "../TabElements"
import Box from "../Box"
import FdaNavigationList from "./fda/NavigationList"

const drawerWidth = 180;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    color: 'rgba(0, 0, 0, 0.87)',
    backgroundColor: '#fff'
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  title: {
    flexGrow: 0.5,
    marginLeft: '1rem',
  },
  logo: {
    flexGrow: 0.5,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    minHeight: '51px',
    justifyContent: 'flex-end',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
}));

export default function AdminApp(props) {
  const classes = useStyles();

  return (
    <Fragment>
      <div className={classes.root}>
        <CssBaseline />

        <AppBar
          search={props.search}
          activeGroup={props.activeGroup}
          title={props.title}
          sector={props.sector}
          tabs={props.tabs}
          market={props.market}
          activeTab={props.activeTab}
          showSidebar={props.showSidebar}
          handleDrawerOpen={props.handleDrawerOpen}
          handleSearchChange={props.handleSearchChange}
          handleSearchSubmit={props.handleSearchSubmit}
        />

        <Drawer
          className={classes.drawer}
          variant="persistent"
          anchor="left"
          open={props.showSidebar}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <div className={classes.drawerHeader}>
            <IconButton onClick={props.handleDrawerClose}>
              <ChevronLeftIcon />
            </IconButton>
          </div>
          <Divider />

          <List>

            <ListItemLink href="/sectors/Peripheral/companies">
              <ListItemText primary='User site' />
            </ListItemLink>

            <ListItemLink href="/admin" active={props.activeItem == 'Dashboard' && props.activeGroup == 'Admin'}>
              <ListItemText primary='Dashboard' />
            </ListItemLink>

            <ListItemLink href="/admin/assignments" active={props.activeItem == 'Assignments' && props.activeGroup == 'Admin'}>
              <ListItemText primary='Assignments' />
            </ListItemLink>

            <NestedCompanyList
              activeGroup={props.activeGroup}
              activeItem={props.activeItem}
            />

            <NestedProductList
              activeGroup={props.activeGroup}
              activeItem={props.activeItem}
            />

            <NestedTrialList
              activeGroup={props.activeGroup}
              activeItem={props.activeItem}
            />

            <AbstractNavigationList
              activeGroup={props.activeGroup}
              activeItem={props.activeItem}
            />

            <MeetingsNavigationList
              activeGroup={props.activeGroup}
              activeItem={props.activeItem}
            />

            <PatentNavigationList
              activeGroup={props.activeGroup}
              activeItem={props.activeItem}
            />

            <PublicationsNavigationList
              activeGroup={props.activeGroup}
              activeItem={props.activeItem}
            />

            <AlertNavigationList
              activeGroup={props.activeGroup}
              activeItem={props.activeItem}
            />

            <NavigationList
              activeGroup={props.activeGroup}
              activeItem={props.activeItem}
            />

            <UsersNavigationList
              activeGroup={props.activeGroup}
              activeItem={props.activeItem}
            />

            <FdaNavigationList 
              activeGroup={props.activeGroup}
              activeItem={props.activeItem}
            />

            <ListItemLink href="/admin/sectors" active={props.activeItem == 'Sectors' && props.activeGroup == 'Admin'}>
              <ListItemText primary='Sectors' />
            </ListItemLink>

            <ListItemLink href="/admin/scraped_contents" active={props.activeItem == 'Scraped Contents' && props.activeGroup == 'Admin'}>
              <ListItemText primary='Scraped Contents' />
            </ListItemLink>

            <ListItemLink href="/admin/news" active={props.activeItem == 'News' && props.activeGroup == 'Admin'}>
              <ListItemText primary='News' />
            </ListItemLink>

            <ListItemLink href="/admin/tweets" active={props.activeItem == 'Tweets' && props.activeGroup == 'Tweets'}>
              <ListItemText primary='Tweets' />
            </ListItemLink>

            <ListItemLink href="/admin/search_keywords" active={props.activeItem == 'Search Keywords' && props.activeGroup == 'Admin'}>
              <ListItemText primary='Search Keywords' />
            </ListItemLink>

            <ListItemLink href="/admin/sbir_grants" active={props.activeItem == 'SBIR Grants' && props.activeGroup == 'Admin'}>
              <ListItemText primary='SBIR Grants' />
            </ListItemLink>

            <ListItemLink href="/admin/nih_project_grants" active={props.activeItem == 'NIH grants' && props.activeGroup == 'Admin'}>
              <ListItemText primary='NIH Grants' />
            </ListItemLink>

          </List>

          <Divider />

          <List>
            <ListItemLink href='/support' tag='a'>
              <IconButton>
                <HelpIcon />
              </IconButton>
              <ListItemText primary='Support' />
            </ListItemLink>
            <ListItemLink href='/logout' tag='a'>
              <IconButton>
                <LogoutIcon />
              </IconButton>
              <ListItemText primary='Logout' />
            </ListItemLink>
          </List>
        </Drawer>
      </div>
    </Fragment>
  );
}
