import React from "react"
import Greenroom from "./Greenroom"
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

export default function GreenroomContainer(props) {
  return(
    <React.Fragment>
      <Switch>
        <Route
          path={`/admin/alerts/greenroom`}
          children={<Greenroom {...props} />}
        />
      </Switch>
    </React.Fragment>
  )
}
