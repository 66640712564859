import React from "react"
import styled from "styled-components"
import { palette, display, flexbox, spacing, sizing, typography, borders, positions, shadows } from "@material-ui/system"

const StyledBox = styled.div`
  ${palette}${display}${flexbox}${spacing}${sizing}${typography}${borders}${positions}${shadows}
`

export default function Box(props) {
  return (
    <StyledBox {...props} />
  )
}
