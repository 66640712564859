import React, { Component } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Highcharts from "highcharts"
import Exporting from 'highcharts/modules/exporting';
import SeriesLabel from "highcharts/modules/series-label"
import NoDataToDisplay from 'highcharts/modules/no-data-to-display';
// Initialize exporting module.
Exporting(Highcharts);
// SeriesLabel(Highcharts)
NoDataToDisplay(Highcharts);

class Chart extends Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    let customTooltip = this.props.tooltip ? { headerFormat: this.props.tooltip.headerFormat, pointFormat: this.props.tooltip.pointFormat, footerFormat: this.props.tooltip.footerFormat } : { enabled: true }
    console.log(this.props.formattedData)
    Highcharts.setOptions({
      lang: {
        noData: "No data to display",
        thousandsSep: ','
      }
    })
    Highcharts.chart(`${this.props.chartId}`, {
      chart: this.props.chartOptions,
      credits:{
        enabled: false
      },
      noData:{
        style:{
          fontWeight: 'bold'
        }
      },
      title: {
        text: this.props.title
      },
      subtitle: {
        text: this.props.subtitle
      },
      tooltip: customTooltip,
      xAxis: {
        title: {
          text: this.props.xAxisTitle
        },
        categories: this.props.categories,
        type: this.props.xAxisType,
        minPadding: 0,
        startOnTick: (this.props.startOnTick ? this.props.startOnTick : false),
        endOnTick: (this.props.endOnTick ? this.props.endOnTick : false),
        minTickInterval: this.props.minTickInterval,
        tickInterval: this.props.tickInterval,
        gridLineDashStyle: this.props.gridLineDashStyle,
        gridLineWidth: this.props.gridLineWidth,
        gridLineColor: this.props.gridLineColor,
        lineColor: this.props.xAxisLineColor,
        labels: { enabled: this.props.xAxisLabels },
        plotBands: this.props.xAxisPlotBand,
        plotLines: (this.props.xAxisPlotLine ? this.props.xAxisPlotLine : null),
        maxRange: (this.props.xAxisMaxRange ? props.xAxisMaxRange : null),
        minRange: (this.props.xAxisMinRange ? this.props.xAxisMinRange : null),
        min: this.props.xAxisMin,
        max: this.props.xAxisMax,
        visible: this.props.xAxisVisible
      },
      yAxis: {
        title: {
          text: this.props.yAxisTitle
        },
        type: this.props.yAxisType,
        allowDecimals: false,
        minRange: (this.props.plotLines ? this.props.plotLines['value'] : null),
        min: this.props.yAxisMin,
        max: this.props.yAxisMax,
        plotLines: (this.props.yAxisPlotLine ? this.props.yAxisPlotLine : null),
        tickInterval: this.props.yAxisTickInterval,
        allowDecimals: false,
        minRange: (this.props.plotLines ? (this.props.plotLines['value'] + (this.props.plotLines['value']/2)) : null),
        visible: this.props.yAxisVisible
      },
      plotOptions: this.props.plotOptions,
      legend: {
        enabled: this.props.legend
      },
      exporting: {
        filename: `${this.props.exportingTitle || this.props.title}_chart`,
        credits: { enabled: true, text: '\u00A9 MarketMonitors' }
      },
      series: this.props.formattedData ? this.props.formattedData : this.buildData(this.props.data, this.props.yAxisTitle)
    })
  }

  buildData(data, seriesName) {
    if (this.props.simple) {
      return [{ data: data, name: seriesName }]
    } else {
      return data
    }
  }

  render () {
    return(
      <div id={this.props.chartId}></div>
    )
  }
}

Chart.defaultProps = {
  legend: true,
  simple: false,
  tooltipEnabled: true,
  plotOptions: { series: { connectNulls: true }},
  xAxisLabels: true
}

export default Chart
