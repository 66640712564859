import React, { Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Paper from "../Paper"
import Box from "../Box"

function Container(props) {
  let paperClassName = `br1 ${props.background}`

  return (
    <Paper sidebar elevation={props.elevation} className={paperClassName}>
      {props.children}
    </Paper>
  )
}

Container.propTypes = {
  background: PropTypes.string
}

Container.defaultProps = {
  elevation: 2,
  background: 'bg-white'
}

export default Container
