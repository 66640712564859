import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import List from "../../lists/List";
import NestedListItemLink from "../../lists/NestedListItemLink"
import ListItemLink from "../../lists/ListItemLink"
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ExpandLess from '../../ExpandLess';
import ExpandMore from '../../ExpandMore';
import Collapse from '@material-ui/core/Collapse';

export default class NestedCompanyList extends Component {
  constructor(props) {
    super(props)
    this.state = { open: false }
  }

  UNSAFE_componentWillMount() {
    if (this.props.activeGroup == 'Patents') {
      this.setState({
        open: true
      })
    }
  }

  render() {
    return(
      <Fragment>

        <ListItem button onClick={() => this.setState({open: !this.state.open})}>
          <ListItemText primary="Patents" />
          {this.state.open ? <ExpandLess /> : <ExpandMore />}
        </ListItem>

        <Collapse in={this.state.open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>

            <NestedListItemLink href={`/admin/patents/dashboard`}>
              <ListItemText primary='Dashboard' className={this.props.activeItem == 'Dashboard' && this.props.activeGroup == 'Patents' ? 'orange' : ''} />
            </NestedListItemLink>

            <NestedListItemLink href={`/admin/patents`}>
              <ListItemText primary='List' className={this.props.activeItem == 'List' && this.props.activeGroup == 'Patents' ? 'orange' : ''} />
            </NestedListItemLink>

            <NestedListItemLink href={`/admin/patents/staged_associator`}>
              <ListItemText primary='Staged Associator' className={this.props.activeItem == 'Staged Associator' && this.props.activeGroup == 'Patents' ? 'orange' : ''} />
            </NestedListItemLink>

            <NestedListItemLink href={`/admin/patents/staged_reviewer`}>
              <ListItemText primary='Staged Reviewer' className={this.props.activeItem == 'Staged Reviewer' && this.props.activeGroup == 'Patents' ? 'orange' : ''} />
            </NestedListItemLink>

            <NestedListItemLink href={`/admin/patents/db_search`}>
              <ListItemText primary='Search' className={this.props.activeItem == 'Search' && this.props.activeGroup == 'Patents' ? 'orange' : ''} />
            </NestedListItemLink>

          </List>
        </Collapse>

      </Fragment>
    )
  }
}
