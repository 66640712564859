import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Paper from "../../Paper"
import PublicationCard from "./PublicationCard"
import Box from "../../Box"

export default class PublicationPanel extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    return(
      <Fragment>

        <Paper fullHeight>
          <Box height="100%">
            <Box height="90%" className='overflow-auto'>
              {this.props.publications.length > 0 ? this.props.publications.map((publication) =>
                <PublicationCard
                  key={publication.id}
                  publication={publication}
                  active={this.props.activePublication && this.props.activePublication.id == publication.id ? true : false}
                  handlePublicationCardClick={this.props.handlePublicationCardClick}
                />
              ) : 'No publications found. Try changing the date'}
            </Box>
          </Box>
        </Paper>

      </Fragment>
    )
  }
}
