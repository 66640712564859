import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Paper from "../../Paper"
import { Table, TableRow, TableBody, TableCell, TableHead, EmptyDataTableRow } from "../../tables"
import WebsiteRow from "./WebsiteRow"
import update from "immutability-helper"
import Snackbar from "../../Snackbar"
import Box from "../../Box"


export default class Websites extends Component {
  constructor(props) {
    super(props)
    this.handleDeleteClick = this.handleDeleteClick.bind(this)
    this.handleUndoClick = this.handleUndoClick.bind(this)
    this.state = { websites: this.props.websites, showSnackbar: false, snackbarText: '', lastDeleted: '' }
  }
  
  handleUndoClick(e) {
    if (this.state.lastDeleted) {
      $.ajax({
        type: 'PUT',
        url: `/admin/websites/${this.state.lastDeleted.id}`,
        data: {
          website: {
            deleted: null, deleted_by: null
          }
        },
        dataType: 'json',
        success: (data) => {
          const websites = update(this.state.websites, { $push: [this.state.lastDeleted] })
          this.setState({
            websites: websites,
            showSnackbar: true,
            snackbarText: 'Website was undeleted',
            lastDeleted: null
          })
        },
        error: (data) => {
          this.setState({
            showSnackbar: true,
            snackbarText: data.responseText
          })
        },
        completed: (data) => {
          setTimeout(() => {
            this.setState({
              showSnackbar: false,
              snackbarText: ''
            })
          }, 4000)
        }
      })
    }
  }
  
  handleDeleteClick(website) {
    $.ajax({
      type: 'PUT',
      url: `/admin/websites/${website.id}`,
      data: {
        website: {
          deleted: true, deleted_by: this.props.current_user
        }
      },
      dataType: 'json',
      success: (data) => {
        const index = this.state.websites.indexOf(website)
        let websites = update(this.state.websites, { $splice: [[index,1]] })
        this.setState({
          websites: websites,
          showSnackbar: true,
          snackbarText: 'Website was deleted',
          lastDeleted: website
        })
      },
      error: (data) => {
        this.setState({
          showSnackbar: true,
          snackbarText: `Website could not be deleted. ${data.responseText}`
        })
      },
      complete: (data) => {
        setTimeout(() => {
          this.setState({
            showSnackbar: false,
            snackbarText: ''
          })
        }, 4000)
      }
    })
  }
  
  render() {
    return(
      <Fragment>
        
        <Paper fullHeight>
          <Box height="100%">
            
            <Box overflow='auto' height="90%">
              <Table size='small' aria-label='websites table' stickyHeader={true}>
                <TableHead>
                  <TableRow hover={false}>
                    <TableCell className='bg-white'>Title</TableCell>
                    <TableCell className='bg-white'>Website</TableCell>
                    <TableCell className='bg-white'>Kind</TableCell>
                    <TableCell className='bg-white'>Created By</TableCell>
                    <TableCell className='bg-white'></TableCell>
                    <TableCell className='bg-white'></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.websites.length > 0 ? this.state.websites.map((website) =>
                    <WebsiteRow
                      key={website.id} 
                      website={website}
                      handleDeleteClick={this.handleDeleteClick}
                    />
                  ) : <EmptyDataTableRow colSpan={4} message='No websites yet. Create a website and it will display here' />}
                </TableBody>
              </Table>
            </Box>
            
          </Box>
        </Paper>
        
        <Snackbar 
          open={this.state.showSnackbar}
          handleUndoClick={this.handleUndoClick}
          handleClose={this.handleClose}
          message={this.state.snackbarText} 
        />
      </Fragment>
    )
  }
}
