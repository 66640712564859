import React, { Component, Fragment } from "react"
import PropTypes from "prop-types"
import Select from "../../Select"
import Flex from "../../Flex"
import Box from "../../Box"


export default class TriageFilters extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    return(
      <Fragment>

        <Flex alignItems='center'>
          <Box width="12%" mr={2}>
            <Select
              label='Dates'
              name='date'
              value={this.props.filterValues.date}
              entities={this.props.filterOptions.dates}
              handleChange={this.props.handleFilterChange}
            />
          </Box>

          <Box width="12%" mr={2}>
            <Select
              label='Type'
              name='type'
              value={this.props.filterValues.abstractType}
              entities={this.props.filterOptions.abstractTypes}
              handleChange={this.props.handleFilterChange}
            />
          </Box>

          <Box width="12%" mr={2}>
            <Select
              label='Session'
              name='session'
              value={this.props.filterValues.sessionTitle}
              entities={this.props.filterOptions.sessionTitles}
              handleChange={this.props.handleFilterChange}
            />
          </Box>

          <Box width="12%" mr={2}>
            <Select
              label='Priority'
              name='priority_id'
              value={this.props.filterValues.priorityId}
              entities={this.props.filterOptions.priorities}
              handleChange={this.props.handleFilterChange}
            />
          </Box>
        </Flex>

      </Fragment>
    )
  }
}
