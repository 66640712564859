import React, {Component} from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Paper from "../Paper"
import {Table, TableHead, TableRow, TableCell} from "../tables"
import Link from "../Link"

class Competitors extends Component {
  constructor(props) {
    super(props)
  }
  
  render() {
    return(
      <section>
        
        <Paper>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Company</TableCell>
                <TableCell>Product</TableCell>
                {this.props.sector.id != 7 || this.props.sector.id != 9 ? <TableCell>Method</TableCell> : ''}
                {this.props.sector.id == 7 ? <TableCell>Polymer Type</TableCell> : ''}
                <TableCell>Target Market</TableCell>
                <TableCell>Technology</TableCell>
                {this.props.sector.id != 9 ? <TableCell>Regulatory Path</TableCell> : ''}
              </TableRow>
            </TableHead>
            <TableBody>
              {this.props.products.map((product) =>
                <TableRow key={product.id}>
                  <TableCell>
                    <Link href={`/sectors/${this.props.sector.abbr}/companies/${product.company_id}`}>{product.company_name}</Link>
                  </TableCell>
                  <TableCell>
                    <Link href={`/sectors/${this.props.sector.abbr}/products/${product.product_id}`}>{product.product_name}</Link>
                  </TableCell>
                  <TableCell>{product.method_type_name}</TableCell>
                  {this.props.sector.id == 7 ? <TableCell>{product.sectspec1}</TableCell> : ''}
                  <TableCell>{product.target_market_group_name}</TableCell>
                  <TableCell>{product.technology_group_name}</TableCell>
                  {this.props.sector.id != 9 ? <TableCell>{product.regulatory_approach}</TableCell> : ''}
                </TableRow>
              )}
            </TableBody>
          </Table>
        </Paper>
      </section>
    )
  }
}

export default Competitors
