import React from "react"
import MuiFab from '@material-ui/core/Fab';
import AddIcon from "@material-ui/icons/Add"

export default function Fab(props) {
  const { children, ...rest } = props
  return (
    <MuiFab color="primary" {...rest}>
      {children ? children : <AddIcon />}
    </MuiFab>
  )
}
