import React, { Fragment } from "react"
import { Table, TableRow, TableCell, TableHead, TableBody } from "../../../tables"
import Row from "./Row"


function Index(props) {
  return(
    <Fragment>

      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Path</TableCell>
            <TableCell>Indication</TableCell>
            <TableCell>Region</TableCell>
            <TableCell>Sectors</TableCell>
            <TableCell>Approval Expected Date</TableCell>
            <TableCell>Approval Submitted Date</TableCell>
            <TableCell>Source</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.keys(props.productRegulatoryPaths).map((productRegulatoryPathId) =>
            <Row
              key={productRegulatoryPathId}
              productRegulatoryPaths={props.productRegulatoryPaths[productRegulatoryPathId]}
              handleDeleteClick={props.handleDeleteClick}
              handleSectorDelete={props.handleSectorDelete}
            />
          )}
        </TableBody>
      </Table>

    </Fragment>
  )
}

export default Index
