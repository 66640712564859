import React from 'react';
import Box from "../Box"
import ProductCode from "./ProductCode"

export default function ProductCodeContainer(props) {
  // Convert productCodes to array if it's a hash/object
  const normalizedProductCodes = Array.isArray(props.productCodes) 
    ? props.productCodes 
    : [props.productCodes]

  console.log(normalizedProductCodes)
  return(
    <React.Fragment>
      <Box height="100%">
        {normalizedProductCodes.map((productCode) =>
          <ProductCode 
            key={productCode.code}
            code={productCode.code}
            deviceName={productCode.device_name}
            handleProductCodeClick={props.handleProductCodeClick}
          />
        )}
      </Box>
    </React.Fragment>
  )
}