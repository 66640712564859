import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Paper from "../../Paper"
import Flex from "../../Flex"
import Box from "../../Box"
import Link from "../../Link"
import Typography from "@material-ui/core/Typography"
import { Tabs, Tab } from "../../tabs"
import StagedExtractorTable from "./StagedExtractorTable"
import Filters from "./staged_extractor/Filters"

export default class StagedExtractor extends Component {
  constructor(props) {
    super(props)
    this.handleActiveIndexUpdate = this.handleActiveIndexUpdate.bind(this)
    this.handleFilterChange = this.handleFilterChange.bind(this)
    this.handleRequestSort = this.handleRequestSort.bind(this)
    this.desc = this.desc.bind(this)
    this.stableSort = this.stableSort.bind(this)
    this.getSorting = this.getSorting.bind(this)
    this.state = { abstracts: this.props.abstracts, sector_id: this.props.sector_id, meeting_id: this.props.meeting_id, workType: this.props.work_type, activeIndex: 'trials', order: 'desc', orderBy: 'date', loading: false }
  }

  handleFilterChange(name, value) {
    let params = { sector_id: this.state.sector_id, meeting_id: this.state.meeting_id, work_type: this.state.workType }

    params[name] = value

    $.ajax({
      type: 'GET',
      url: '/admin/abstracts/staged_extractor',
      data: params,
      dataType: 'json',
      beforeSend: (data) => {
        this.setState({
          loading: true
        })
      },
      success: (data) => {
        this.setState({
          abstracts: data.abstracts,
          sector_id: params.sector_id,
          meeting_id: params.meeting_id
        })
      },
      complete: (data) => {
        this.setState({
          loading: false
        })
      },
      error: (data) => {
        alert("There has been an error. Please try again.")
      }
    })
  }

  handleActiveIndexUpdate(e, newValue) {
    $.ajax({
      type: 'GET',
      url: `/admin/meetings/${this.props.meeting.id}/staged_extractor`,
      data: {
        entity_type: newValue
      },
      dataType: 'json',
      success: (data) => {
        this.setState({
          abstracts: data.abstracts,
          activeIndex: newValue
        })
      }
    })
    // this.setState({
    //   activeIndex: newValue
    // })
  }

  desc(a, b, orderBy) {
    let bOrderKey = orderBy == 'title' ? b[orderBy].toLowerCase() : b[orderBy]
    let aOrderKey = orderBy == 'title' ? a[orderBy].toLowerCase() : a[orderBy]
    if (bOrderKey < aOrderKey) {
      return -1;
    }
    if (bOrderKey > aOrderKey) {
      return 1;
    }
    return 0;
  }

  stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = cmp(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
  }

  getSorting(order, orderBy) {
    return order === 'desc' ? (a, b) => this.desc(a, b, orderBy) : (a, b) => -this.desc(a, b, orderBy);
  }

  handleRequestSort(event, property) {
    const isDesc = this.state.orderBy === property && this.state.order === 'desc';

    this.setState({
      order: isDesc ? 'asc' : 'desc',
      orderBy: property
    })
  };

  render() {
    return(
      <Fragment>

        <Paper fullHeight>

          {/* <Box mb={3}>
            <Tabs variant="fullWidth" value={this.state.activeIndex} onChange={this.handleActiveIndexUpdate} className='bb b--black-10' aria-label="nav tabs">
              <Tab label='Trials' value='trials' />
              <Tab label='Products' value='products' />
            </Tabs>
          </Box> */}

          <Filters
            sector_id={this.state.sector_id}
            meeting_id={this.state.meeting_id}
            workType={this.state.workType}
            sectors={this.props.sectors}
            meetings={this.props.meetings}
            workTypes={this.props.work_types}
            handleFilterChange={this.handleFilterChange}
          />

          <Flex justifyContent='right'>
            <Typography variant="body2">
              {`${Object.values(this.state.abstracts).length} Presentations`}
            </Typography>
          </Flex>

          <StagedExtractorTable
            abstracts={this.state.abstracts}
            order={this.state.order}
            orderBy={this.state.orderBy}
            activeIndex={this.state.activeIndex}
            handleRequestSort={this.handleRequestSort}
            stableSort={this.stableSort}
            getSorting={this.getSorting}
          />

        </Paper>

      </Fragment>
    )
  }
}
