import React, { Component, Fragment }  from "react"
import Paper from "../Paper"
import Flex from "../Flex"
import Box from "../Box"
import { Table, TableRow, TableHead, TableCell, TableBody, TableHeadSort } from "../tables"
import Link from "../Link"
import Typography from "@material-ui/core/Typography"

class Substudies extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    return(
      <Fragment>
        <Paper fullHeight>
          {this.props.substudies.length > 0 ?
            <Box height="100%">

              <Box height="90%" overflow="auto">

                <Table size='small' aria-label='substudies table' stickyHeader={true} id='substudies-table'>
                  <TableHeadSort
                    headers={[{ name: 'short_name', label: 'Trial' }, { name: 'type', label: 'Type' }, { name: 'updated_at', label: 'Updated', align: 'right' }]}
                    order={this.props.order}
                    orderBy={this.props.orderBy}
                    onRequestSort={this.props.handleRequestSort}
                  />

                  <TableBody>
                    {this.props.substudies.map((trial) =>
                      <TableRow key={trial.id}>
                        <TableCell>
                          <Link href={`/sectors/${this.props.sector.abbr}/trials/${trial.trial_id}`}>
                            <Box>
                              {trial.acronym || trial.short_name}
                            </Box>
                          </Link>
                        </TableCell>

                        <TableCell className='w-15'>
                          {trial.grouped_types ?
                            trial.grouped_types.split(";").map((type) =>
                              <div>
                                {type}
                              </div>
                            )
                          : ''}
                        </TableCell>

                        <TableCell align="right">
                          <Box py={0.1}>{dateFormat(trial.last_updated)}</Box>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>

              </Box>

            </Box>
          :
            <Flex justifyContent='center' height="50%">
              <Box alignSelf='center' maxWidth="50%">
                <Box>
                  <Typography variant="body1">
                    No substudies found for this trial
                  </Typography>
                </Box>
              </Box>
            </Flex>
          }
        </Paper>
      </Fragment>
    )
  }
}

export default Substudies
