import React, { Component } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"

class Link extends Component {
  render() {
    let defaultStyle = this.props.disabled ? 'cur-na o-30 link' : `link ${this.props.textColor} pointer hover-${this.props.color} ${this.props.className}`
    let style = this.props.active ? `${defaultStyle} ${this.props.color}` : defaultStyle
    return(
      <a href={this.props.href} onClick={this.props.handleClick} className={style} target={this.props.target} style={{outline: 'none'}} onMouseEnter={this.props.onMouseEnter} onMouseLeave={this.props.onMouseLeave}>
        {this.props.children}
      </a>
    )
  }
}

Link.propTypes = {
  href: PropTypes.string,
  handleClick: PropTypes.func,
  color: PropTypes.string
};

Link.defaultProps = {
  color: 'orange',
  textColor: 'black'
}

export default Link
