import React, { Component } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Link from "../Link"
import { Table, TableHead, TableBody, TableRow, TableCell } from "../tables"
import EnrollmentDataTable from "./EnrollmentDataTable"

export default class EnrollmentChartData extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    return(
      <Table>
        <TableHead>
          <TableRow hover={false}>
            <TableCell>Enrollment</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Source</TableCell>
            <TableCell>Date</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>

          {this.props.currentEnrollmentHash.length > 0 ?
            <EnrollmentDataTable
              header='Current enrollment'
              enrollmentKindHash={this.props.currentEnrollmentHash}
            />
          : ''}

          {this.props.targetEnrollmentHash.length > 0 ?
            <EnrollmentDataTable
              header='Target enrollment'
              enrollmentKindHash={this.props.targetEnrollmentHash}
            />
          : ''}

        </TableBody>
      </Table>
    )
  }
}
