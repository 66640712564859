import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Paper from "../../Paper"
import Form from "./Form"
import update from "immutability-helper"
import Snackbar from "../../Snackbar"

export default class EditContainer extends Component {
  constructor(props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
    this.handleSaveClick = this.handleSaveClick.bind(this)
    this.handleDateChange = this.handleDateChange.bind(this)
    this.state = { regulatoryAgencyDocument: this.props.regulatory_agency_document, showSnackbar: false, snackbarText: '' }
  }
  
  handleChange(e) {
    const name = e.target.name
    const value = e.target.value
    
    const regulatoryAgencyDocument = update(this.state.regulatoryAgencyDocument, { 
      [name]: { $set: value } 
    })
    
    this.setState({
      regulatoryAgencyDocument: regulatoryAgencyDocument
    })
  }
  
  handleDateChange(date) {
    const regulatoryAgencyDocument = update(this.state.regulatoryAgencyDocument, { 
      date: { $set: date }
    })
    
    this.setState({
      regulatoryAgencyDocument: regulatoryAgencyDocument
    })
  }
  
  handleSaveClick(e) {
    $.ajax({
      type: 'PUT',
      url: `/admin/regulatory_agency_documents/${this.state.regulatoryAgencyDocument.id}`,
      data: {
        regulatory_agency_document: this.state.regulatoryAgencyDocument
      },
      error: (data) => {
        this.setState({
          showSnackbar: true,
          snackbarText: `The Regulatory agency document could not be updated. ${data.responseText}`
        })
      },
      complete: (data) => {
        setTimeout(() => {
          this.setState({
            showSnackbar: false,
            snackbarText: ''
          })
        }, 4000)
      }
    })
  }
  
  render() {
    return(
      <Fragment>
        
        <Paper>
          
          <Form 
            regulatoryAgencyDocument={this.state.regulatoryAgencyDocument}
            handleChange={this.handleChange}
            handleDateChange={this.handleDateChange}
            handleSaveClick={this.handleSaveClick}
          />
          
          
        </Paper>
        
        <Snackbar 
          open={this.state.showSnackbar}
          message={this.state.snackbarText} 
        />
      </Fragment>
    )
  }
}
