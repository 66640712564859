import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

export default function SearchDropdown(props) {

  return (
    <div style={{ width: 300 }}>
      <Autocomplete
        id={props.id}
        options={props.options}
        name={props.name}
        getOptionLabel={(option) => option.name}
        style={{ width: 300 }}
        onChange={props.handleChange}
        renderInput={(params) => <TextField {...params} name={props.inputName} label={props.label} variant="outlined" />}
      />
    </div>
  );
}
