import React, { Fragment } from "react"
import CKEditor from 'ckeditor4-react';
import ButtonLayout from "../ButtonLayout"
import { Button, RaisedButton } from "../../buttons"
import Chip from '@material-ui/core/Chip';
import Flex from "../../Flex"
import Box from "../../Box"
import Select from "../../Select"
import Dialog from "../../Dialog"
import SourceForm from "../SourceForm"

export default function UpdateForm(props) {
  return (
    <Fragment>
      
      
      <Flex alignItems='end'>
        
        <Box width="10%">
          <Select 
            value={props.update.trial_update_section_id} 
            label='Section'
            entities={props.sections}
            displayEmpty={false}
            handleChange={props.handleSectionChange} 
          />
        </Box>
        
        <Box ml={3}>
          <Button tag='a' href={`/admin/trial_update_sections/new?r=/admin/trials/${props.trial.id}/updates/new`}>
            Add Section
          </Button>
        </Box>
      
      </Flex>
      
      <Box my={2}>
        <Box>
          {props.source ? 
            <Chip
              label={props.source.source_title}
              onDelete={props.handleSourceRemovalClick}
            />
          : 
            <Button onClick={props.handleChooseSourceClick}>
              Choose Source
            </Button>
          }
        </Box>
      </Box>
      
      {props.openDialog ? 
        <Dialog 
          openDialog={props.openDialog} 
          dialogTitle='Choose Source' 
          withButtons={false}
          handleClick={props.handleAddClick} 
          dialogContent={<SourceForm sources={props.sources} sourceTypes={props.sourceTypes} handleSourceChange={props.handleSourceChange} />} 
        /> : ''}
      
      <Box>
        <CKEditor
          data={props.update.content}
          onChange={props.handleChange}
        />
      </Box>
      
      <ButtonLayout>
        <RaisedButton handleClick={props.handleSaveClick}>
          Save Update
        </RaisedButton>
      </ButtonLayout>
    </Fragment>
  )
}
