import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Button from "./buttons/Button";
import MuiSnackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import theme from "./theme"

const useStyles = makeStyles(theme => ({
  close: {
    padding: theme.spacing(0.5),
  },
}));

export default function Snackbar(props) {
  const classes = useStyles();
  
  function setActionItems() {
    let actionItems = []
    if (props.handleUndoClick) {
      actionItems.push(<Button key="undo" size="small" onClick={props.handleUndoClick}>UNDO</Button>)
    }
    return(actionItems)
  }
  
  return (
    <MuiSnackbar
      anchorOrigin={{
        vertical: props.vertical || 'bottom',
        horizontal: props.horizontal || 'center',
      }}
      open={props.open}
      autoHideDuration={6000}
      onClose={props.handleClose}
      ContentProps={{
        'aria-describedby': 'message-id',
      }}
      message={<span id="message-id">{props.message}</span>}
      action={setActionItems()}
    />
  );
}


{/* <IconButton
  key="close"
  aria-label="close"
  color="inherit"
  className={classes.close}
  onClick={props.handleClose}
>
  <CloseIcon />
</IconButton>, */}
