import React, { useState, useEffect } from "react"
import Form from "./Form"
import Tips from "./Tips"

export default function FormContainer(props) {
  const [open, setOpen] = useState(false);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [localQuery, setLocalQuery] = useState(props.query);
  const suggestionTimer = React.useRef(null);

  const fetchSuggestions = (query) => {
    if (props.user.user_company_id != 1) {
      return;
    }

    if (query.length < 2) {
      setSuggestions([]);
      setShowSuggestions(false);
      return;
    }

    $.ajax({
      url: props.suggestionsUrl,
      method: 'GET',
      data: { query },
      dataType: 'json',
      success: (data) => {
        setSuggestions(data.suggestions);
        setShowSuggestions(true);
      }
    });
  };

  const handleSearchChange = (e) => {
    const query = e.target.value;
    setLocalQuery(query);
    
    clearTimeout(suggestionTimer.current);
    
    suggestionTimer.current = setTimeout(() => {
      fetchSuggestions(query);
    }, 150);
  };

  const handleSearchClick = () => {
    setShowSuggestions(false);
    props.onSearch(localQuery);
  };

  const handleSuggestionSelect = (option) => {
    setLocalQuery(option.query);
    setShowSuggestions(false);
    props.onSearch(option.query);
  };

  const handleClickOpen = () => {
    setOpen(true);
    $.ajax({
      url: `/sectors/${props.sector.abbr}/search/tips_viewed`,
      type: "GET",
      success: function(data) {},
      error: function() {
        console.log("error");
      }
    });
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    return () => {
      clearTimeout(suggestionTimer.current);
    };
  }, []);

  console.log(suggestions);

  return(
    <React.Fragment>
      <Tips open={open} handleClose={handleClose} />

      <Form 
        query={localQuery}
        placeholderText={props.search_text}
        suggestions={suggestions}
        showSuggestions={showSuggestions}
        setShowSuggestions={setShowSuggestions}
        handleSearchChange={handleSearchChange}
        handleSearchClick={handleSearchClick}
        handleTipsClick={handleClickOpen}
        handleSuggestionSelect={handleSuggestionSelect}
      />
    </React.Fragment>
  )
}