import React, { Fragment, Component } from "react"
import Paper from "../Paper"
import { Table, TableHead, TableCell, TableRow, TableBody, TableHeadSort } from "../tables"
import Select from "../Select"
import Grid from '@material-ui/core/Grid';
import Box from "../Box"
import Flex from "../Flex"
import update from "immutability-helper"
import Link from "../Link"
import Typography from "@material-ui/core/Typography"
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Snackbar from "../Snackbar"
import ComponentStentConfigurationRow from "./ComponentStentConfigurationRow"


export default class SystemConfiguration extends Component {
  constructor(props) {
    super(props)
    this.handleFilterChange = this.handleFilterChange.bind(this)
    this.handleRequestSort = this.handleRequestSort.bind(this)
    this.desc = this.desc.bind(this)
    this.stableSort = this.stableSort.bind(this)
    this.getSorting = this.getSorting.bind(this)
    this.state = { products: this.props.products, targetMarkets: this.props.target_markets, stentConfigurations: this.props.stent_configurations, target_market_id: this.props.target_market_id, order: 'asc', orderBy: 'short_name', productLength: this.props.product_length, showSnackbar: false, snackbarText: '' }
  }


  handleFilterChange(name, value) {
    $.ajax({
      type: 'GET',
      url: `/sectors/${this.props.sector.abbr}/products/system_configuration`,
      data: {
        target_market_id: value
      },
      dataType: 'json',
      success: (data) => {

        this.setState({
          products: data.products,
          target_market_id: value,
          productLength: data.product_length,
          stentConfigurations: data.stent_configurations
        })
      },
      error: (data) => {
        this.setState({
          showSnackbar: true,
          snackbarText: 'There was an issue. Please try again.'
        })
      },
      complete: (data) => {
        setTimeout(() => {
          this.setState({
            showSnackbar: false,
            snackbarText: ''
          })
        }, 4000)
      }
    })
  }


  desc(a, b, orderBy) {
    console.log(b)
    let bOrderKey = orderBy == 'short_name' ? b[orderBy].toLowerCase() : b['stent_configuration'] == orderBy ? true : false
    let aOrderKey = orderBy == 'short_name' ? a[orderBy].toLowerCase() : a['stent_configuration'] == orderBy ? true : false
    if (bOrderKey < aOrderKey) {
      return -1;
    }
    if (bOrderKey > aOrderKey) {
      return 1;
    }
    return 0;
  }

  stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = cmp(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
  }

  getSorting(order, orderBy) {
    return order === 'desc' ? (a, b) => this.desc(a, b, this.state.orderBy) : (a, b) => -this.desc(a, b, this.state.orderBy);
  }

  handleRequestSort(event, property) {
    console.log(property)

    // homes.sort((a, b) => parseFloat(a.price) - parseFloat(b.price));

    const isDesc = this.state.orderBy === property && this.state.order === 'desc';

    this.setState({
      order: isDesc ? 'asc' : 'desc',
      orderBy: property
    })
  };

  groupBy(list, props) {
    return list.reduce((a, b) => {
       (a[b[props]] = a[b[props]] || []).push(b);
       return a;
    }, {});
  }


  render() {
    let headers = [{ label: 'Component', name: 'short_name', borderRight: true, align: 'center' }]
    this.state.stentConfigurations.map((stentConfiguration) =>
      headers.push({ label: stentConfiguration.name, name: stentConfiguration.id, borderRight: true, align: 'center' })
    )

    console.log(this.state.products)

    return(
      <Fragment>
        <Paper fullHeight>
          <Box mb={2}>

            <Grid container spacing={2} alignItems='stretch'>

              <Grid item xs={3}>
                <Box>
                  <Select
                    label='Target Market'
                    name='target_market_id'
                    value={this.state.target_market_id}
                    entities={this.state.targetMarkets}
                    handleChange={this.handleFilterChange}
                  />
                </Box>
              </Grid>

            </Grid>
          </Box>

          <Flex>

            <Box mb={1} ml='auto'>
              {`${this.state.productLength} products`}
            </Box>
          </Flex>


          <Box overflow='auto' height="85%">
            <Table size='small' aria-label='product systems configuration table' stickyHeader={true} id='product-systems-configuration-table'>

              {/* <TableHeadSort
                headers={headers}
                order={this.state.order}
                orderBy={this.state.orderBy}
                onRequestSort={this.handleRequestSort}
                borderRight={true}
              /> */}

              <TableHead>
                <TableRow hover={false}>
                  {headers.map((header) =>
                    <TableCell key={header.name} attribute={header} borderRight={true} className='bg-white'>
                      {header.label}
                    </TableCell>
                  )}
                </TableRow>
              </TableHead>

              <TableBody>
                {Object.keys(this.state.products).map((parentProductId) =>
                  <Fragment>
                    <TableRow key={parentProductId} selected={true}>
                      <TableCell borderRight colSpan={headers.length} style={{padding: "4px 16px"}}>
                        <Fragment>
                          <Link href={`/sectors/${this.props.sector.abbr}/products/${this.state.products[parentProductId][0].parent_product_id}`}>
                            {this.state.products[parentProductId][0].parent_product_name}
                          </Link>
                          <span className='ml2'>
                            <Link href={`/sectors/${this.props.sector.abbr}/companies/${this.state.products[parentProductId][0].company_id}`}>
                              <Typography variant="caption">
                                {`(${this.state.products[parentProductId][0].company_name})`}
                              </Typography>
                            </Link>
                          </span>
                        </Fragment>
                      </TableCell>
                    </TableRow>

                    <ComponentStentConfigurationRow
                      products={this.state.products[parentProductId]}
                      stentConfigurations={this.state.stentConfigurations}
                      sector={this.props.sector}
                    />
                  </Fragment>
                )}
              </TableBody>
            </Table>
          </Box>
        </Paper>

        <Snackbar
          open={this.state.showSnackbar}
          message={this.state.snackbarText}
        />
      </Fragment>
    )
  }
}
