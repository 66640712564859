import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import { Table, TableHead, TableRow, TableCell, TableBody } from "../tables"
import Link from "../Link"
import CompetitorRow from "./CompetitorRow"


export default class CompetitorsTable extends Component {
  constructor(props) {
    super(props)
  }
  
  render() {
    return(
      <Table>
        <TableHead>
          <TableRow hover={false}>
            <TableCell>Company</TableCell>
            <TableCell>Product</TableCell>
            {/* {this.props.sector.id != 7 || this.props.sector.id != 9 ? <TableCell>Method</TableCell> : ''} */}
            {/* {this.props.sector.id == 7 ? <TableCell>Polymer Type</TableCell> : ''} */}
            <TableCell>Target Market</TableCell>
            <TableCell>Technology</TableCell>
            {/* {this.props.sector.id != 9 ? <TableCell>Regulatory Path</TableCell> : ''} */}
            <TableCell>US Status</TableCell>
            <TableCell>EU Status</TableCell>
          </TableRow>
        </TableHead>
        
        <TableBody>
          <TableRow hover={false} className='bg-light-yellow'>
            <TableCell borderRight={true}>
              <Link href={`/sectors/${this.props.sector.abbr}/companies/${this.props.company.id}`}>
                {this.props.company.short_name}
              </Link>
            </TableCell>
            <TableCell borderRight={true}>
              {this.props.product.short_name}
            </TableCell>
            <TableCell borderRight={true}>
              {this.props.target_markets.map((targetMarket) =>
                <div key={targetMarket.value}>
                  {targetMarket.name}
                </div>
              )}
            </TableCell>
            <TableCell borderRight={true}>
              {this.props.technology_groups.map((technologyGroup) =>
                <div key={technologyGroup.value}>
                  {technologyGroup.name}
                </div>
              )}
            </TableCell>
            <TableCell borderRight={true}>
              {this.props.us_status ? this.props.us_status.name : ''}
            </TableCell>
            <TableCell borderRight={true}>
              {this.props.eu_status ? this.props.eu_status.name : ''}
            </TableCell>
          </TableRow>
          
          {this.props.products.map((product) =>
            <CompetitorRow 
              product={product}
              targetMarket={this.props.targetMarket}
              regulatoryStatus={this.props.regulatoryStatus}
              sector={this.props.sector}
            />
          )}
          
        </TableBody>
        
      </Table>
    )
  }
}
