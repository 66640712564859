import React from 'react';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

export default function SnackbarAlerts(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  let severityHash = { "notice": "success", "alert": "error", "info": "notice" }

  return (
    <div className={classes.root}>
      {props.flash.map((flashArray) =>
        flashArray[0] == 'snackbar' ?
          <Snackbar
            key={`${flashArray[0]}-${flashArray[1]}`}
            open={open}
            autoHideDuration={5000}
            onClose={handleClose}
            message={flashArray[1]}
          />
        :
          <Snackbar key={`${flashArray[0]}-${flashArray[1]}`} open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity={severityHash[flashArray[0]]}>
            {flashArray[1]}
          </Alert>
        </Snackbar>
      )}
    </div>
  );
}
