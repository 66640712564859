import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Paper from "../../Paper"
import Flex from "../../Flex"
import Box from "../../Box"
import TextInput from "../../TextInput"
import ButtonLayout from "../ButtonLayout"
import { RaisedButton } from "../../buttons"
import update from "immutability-helper"
import Snackbar from "../../Snackbar"

export default class NewContainer extends Component {
  constructor(props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
    this.handleSaveClick = this.handleSaveClick.bind(this)
    this.state = { name: '', showSnackbar: false, snackbarText: '' }
  }

  handleChange(e) {
    const name = e.currentTarget.name
    const value = e.currentTarget.value
    this.setState({
      [name]: value
    })
  }

  handleSaveClick(e) {
    const url = this.props.redirect_url ? `/admin/internal_documents?r=${this.props.redirect_url}` : `/admin/internal_documents`
    $.post(url, { internal_document: { name: this.state.name, created_by: this.props.current_user.id } }, () => {
      window.location.href = url
    }, 'json').fail((data) => {
      this.setState({
        showSnackbar: true,
        snackbarText: `The internal document could not be created. ${data.responseText}.`
      })
    }).always(() => {
      setTimeout(() => {
        this.setState({
          showSnackbar: false,
          snackbarText: ''
        })
      }, 4000)
    })
  }

  render() {
    return(
      <Fragment>

        <Paper>

          <Box width={1} my={3}>
            <TextInput
              name='name'
              label='Document'
              value={this.state.name}
              onChange={this.handleChange}
              fullWidth
            />
          </Box>

          <ButtonLayout>
            <RaisedButton handleClick={this.handleSaveClick}>
              Save Internal Document
            </RaisedButton>
          </ButtonLayout>


        </Paper>

        <Snackbar
          open={this.state.showSnackbar}
          message={this.state.snackbarText}
        />
      </Fragment>
    )
  }
}
