import React, { Fragment } from "react"
import Paper from "../../../Paper"
import Box from "../../../Box"
import { List, ListItem, ListItemText } from "../../../lists"
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction"
import Switch from "../../../Switch"
import Typography from "@material-ui/core/Typography"


export default function ComparatorPanel(props) {
  return (
    <React.Fragment>
      <Paper fullHeight>

        <Typography variant="caption">
          Mark the investigated devices
        </Typography>

        <List>
          {props.trialProducts.map((trialProduct) =>
            <ListItem key={trialProduct.id} divider>
              <ListItemText primary={trialProduct.short_name} />

              <ListItemSecondaryAction>
                <Switch
                  edge="end"
                  checked={trialProduct.investigated_device}
                  handleChange={() => { props.handleSwitchChange(trialProduct) }}
                />
              </ListItemSecondaryAction>
            </ListItem>
          )}
        </List>

      </Paper>
    </React.Fragment>
  )
}
