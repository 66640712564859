import React, { Fragment } from "react"
import { Table, TableRow, TableCell, TableHead, TableBody } from "../../../tables"
import LinearProgress from '@material-ui/core/LinearProgress';
import Link from "../../../Link"
import Box from "../../../Box"
import ClassificationRow from "./ClassificationRow"

export default function ClassificationResultsTable(props) {
  return(
    <Table size='small' stickyHeader={true}>
      <TableHead>
        <TableRow>
          <TableCell>Classification</TableCell>
          <TableCell>Definition</TableCell>
          <TableCell>Text</TableCell>
          <TableCell>Score</TableCell>
          <TableCell>Sector</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <Fragment>
          {props.loading ?
            <TableRow hover={false}>
              <TableCell noBorder colSpan={5}>
                <LinearProgress />
              </TableCell>
            </TableRow>
          : ""}
          {props.classifications ?
            props.classifications.map((classificationArray) =>
              <ClassificationRow
                key={classificationArray.length ? classificationArray[0].id : classificationArray}
                classificationArray={classificationArray}
                sectors={props.sectors}
                handleClassificationSearchClick={props.handleClassificationSearchClick}
                handleSectorAssociation={props.handleSectorAssociation}
              />
            )
          : ""}
        </Fragment>
      </TableBody>
    </Table>
  )
}
