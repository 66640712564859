import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Paper from "../../Paper"
import Flex from "../../Flex"
import Box from "../../Box"
import Select from "../../Select"
import TextInput from "../../TextInput"
import { RaisedButton } from "../../buttons"

export default class NewContainer extends Component {
  constructor(props) {
    super(props)
    this.handleSourceTypeChange = this.handleSourceTypeChange.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleSaveClick = this.handleSaveClick.bind(this)
    this.state = { sourceType: '', sourceValue: '', sourceDate: '' }
  }
  
  handleSourceTypeChange(name, value) {
    this.setState({
      sourceType: value
    })
  }
  
  handleChange(e) {
    const value = e.currentTarget.value
    this.setState({
      sourceValue: value
    })
  }
  
  handleSaveClick(e) {
    $.post(`/admin/${this.state.sourceType}s`, { [this.state.sourceType]: {  } })
  }
  
  render() {
    return(
      <Fragment>
        
        <Paper>
          
          <Flex align='items-start'>
            
            <Box className='mr3'>
              <Select 
                value={this.state.sourceType} 
                label='Source Type'
                entities={this.props.source_types}
                displayEmpty={true}
                handleChange={this.handleSourceTypeChange} 
              />
            </Box>
            
            <Box className='w-100'>
              <TextInput 
                name='source_date' 
                label='Date' 
                value={this.state.sourceDate} 
                onChange={this.handleChange} 
                className='w-100' 
              />
            </Box>
            
          </Flex>
          
          <Box className='w-100 mv3 h4'>
            <TextInput 
              name='source_value' 
              label='Value' 
              textarea={true}
              value={this.state.sourceValue} 
              onChange={this.handleChange} 
              className='w-100' 
            />
          </Box>
          
          <ButtonLayout>
            <RaisedButton handleClick={this.handleSaveClick}>
              Save Source
            </RaisedButton>
          </ButtonLayout>
          
          
        </Paper>
        
        <Snackbar 
          open={this.state.showSnackbar}
          message={this.state.snackbarText} 
        />
      </Fragment>
    )
  }
}
