import React from "react"
import { makeStyles } from '@material-ui/core/styles';
import ListItem from './ListItem';

const useStyles = makeStyles(theme => ({
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

export default function NestedListItemLink(props) {
  const classes = useStyles();
  return (
    <ListItem className={classes.nested} {...props} />
  )
}
