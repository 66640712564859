import React, { Component } from "react"
import PropTypes from "prop-types"
import Select from "../../../Select"
import Box from "../../../Box"
import Grid from '@material-ui/core/Grid';

export default function Filters(props) {
  return (
    <Grid container spacing={3} alignItems='stretch'>

      <Grid item xs={4} lg={2}>
        <Box>
          <Select
            label='Work Type'
            name='work_type'
            includeBlank={true}
            value={props.workType || ''}
            entities={props.workTypes}
            handleChange={props.handleFilterChange}
          />
        </Box>
      </Grid>

      <Grid item xs={4} lg={2}>
        <Box>
          <Select
            label='Meeting'
            name='meeting_id'
            includeBlank={true}
            value={props.meeting_id || ''}
            entities={props.meetings}
            handleChange={props.handleFilterChange}
          />
        </Box>
      </Grid>

      <Grid item xs={4} lg={2}>
        <Box>
          <Select
            label='Sectors'
            name='sector_id'
            includeBlank={true}
            value={props.sector_id || ''}
            entities={props.sectors}
            handleChange={props.handleFilterChange}
          />
        </Box>
      </Grid>

    </Grid>
  )
}
