import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Paper from "../../Paper"
import Box from "../../Box"
import { RaisedButton } from "../../buttons"

export default class ControlPanel extends Component {
  constructor(props) {
    super(props)
  }
  
  render() {
    return(
      <Fragment>
        
        <Paper>
          
          <Box width={1}>
            <RaisedButton handleClick={this.props.handleAssociateClick}>
              Associate
            </RaisedButton>
          </Box>
          
        </Paper>
        
      </Fragment>
    )
  }
}
