import React from "react"
import { TableRow, TableCell } from "../../../tables"
import Link from "../../../Link"

export default function Row(props) {
  return (
    <TableRow>
      <TableCell>{props.patent.html_patent_no}</TableCell>
      <TableCell>
        <Link target="_blank" href={`/admin/patents/${props.patent.id}/reviewer`}>
          {props.patent.entity_title}
        </Link>
      </TableCell>
      <TableCell>{props.patent.company_name}</TableCell>
      <TableCell>{props.patent.effective_date_str}</TableCell>
    </TableRow>
  )
}
