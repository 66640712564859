import React, { useState } from "react"
import Link from "../Link"
import Flex from "../Flex"
import Box from "../Box"
import Typography from "@material-ui/core/Typography"
import Button from "../buttons/Button"

export default function DateDisplay(props) {

  const { dateElement } = props;
  const [hoverHistory, setHoverHistory] = useState(false);

  return(
    <Box mb={2} onMouseEnter={() => setHoverHistory(true)} onMouseLeave={() => setHoverHistory(false)}>

      <Flex alignItems='center'>
        <Box>
          {dateElement}
        </Box>

        {hoverHistory ?
          <Box ml='auto'>
            <Button size='small' color="default" component="a" href={props.url}>
              {props.buttonText}
            </Button>
          </Box>
        : ''}

      </Flex>
    </Box>
  )
}
