import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Header from "../../Header"

export default class HeaderContainer extends Component {
  constructor(props) {
    super(props)
  }
  
  render() {
    const tabs = [['List', '/admin/press_releases'], ['New Press Release', '/admin/press_releases/new']]
    return(
      <Fragment>
        <Header 
          header={this.props.header}
          background='bg-washed-red'
          activeTab={this.props.activeTab}
          tabs={tabs}
        />
      </Fragment>
    )
  }
}

HeaderContainer.propTypes = {
  header: PropTypes.string,
  activeTab: PropTypes.string
};

HeaderContainer.defaultProps = {
  header: 'Press Releases'
}
