import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Filters from "./Filters"
import Paper from "../Paper"
import PatentsTable from "./PatentsTable"
import Flex from "../Flex"
import Box from "../Box"
import IconButton from "../IconButton"
import BookmarkIcon from "@material-ui/icons/Bookmark"
import BookmarkBorderIcon from '@material-ui/icons/BookmarkBorder';
import Typography from "@material-ui/core/Typography"
import FilterChip from '../filters/FilterChip';
import { withSnackbar } from "../SnackbarContainer"
import update from "immutability-helper"
import { withRouter } from "react-router-dom";

export default class FilterContainer extends Component {
  constructor(props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
    this.handleSearchClick = this.handleSearchClick.bind(this)
    this.handleClearSearchClick = this.handleClearSearchClick.bind(this)
    this.state = { query: this.props.query }
  }

  handleChange(e) {
    this.setState({
      query: e.target.value
    })
  }

  handleSearchClick(e) {
    e.preventDefault()
    this.props.handleSearchClick(this.state.query)
  }

  handleClearSearchClick(e) {
    e.preventDefault()

    this.setState({
      query: null
    }, () => {
      this.props.handleClearSearchClick()
    })
  }

  render() {
    return(
      <Fragment>
        <Filters
          query={this.state.query}
          classification={this.props.classification}
          handleChange={this.handleChange}
          handleSearchClick={this.handleSearchClick}
          handleClearSearchClick={this.handleClearSearchClick}
        />
      </Fragment>
    )
  }
}
