import React from 'react'
import Link from '../../Link'

function FdaLink(props) {
  let recallUrl = `https://www.accessdata.fda.gov/scripts/cdrh/cfdocs/cfres/res.cfm?id=${props.fdaRecall.recall_id}`

  return (
    <Link href={recallUrl} target="_blank">
      {props.linkText || "View on FDA site"}
    </Link>
  )
}

export default FdaLink