import React from "react"
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from "../../lists/ListItemText"
import Radio from '@material-ui/core/Radio';

export default function SourceItemDisplay(props) {

  function handleClick(e) {
    const sourceEntity = props.data.sources[props.index]
    props.data.handleClick(sourceEntity)
  }

  const sourceEntity = props.data.sources[props.index]

  const source = sourceEntity.source_hash || sourceEntity

  const checked = source.id === (props.activeSource ? props.activeSource.id : null)

  return(
    <ListItem button style={props.style} onClick={handleClick}>

      <ListItemIcon>
        <Radio
          edge="start"
          checked={checked}
          value={source.id}
          name='source-id'
          inputProps={{ 'aria-label': source.source_title }}
          disableRipple
        />
      </ListItemIcon>

      <ListItemText 
        primary={source.source_title || source.title} 
        secondary={source.source_details || source.meeting_name}
      />

    </ListItem>
  )


}
