import React, { Component, Fragment } from "react"
import ProductStatusRow from "./ProductStatusRow"
import { Table, TableBody, TableHead, TableCell, TableRow } from "../../tables"
import { List, ListItem, ListItemText } from "../../lists"
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ExpandLess from '../../ExpandLess';
import ExpandMore from '../../ExpandMore';
import Collapse from '@material-ui/core/Collapse';

class StatusGroupDisplay extends Component {
  constructor(props) {
    super(props)
    this.state = { open: false }
  }

  componentDidMount() {
    if (this.props.header == 'All') {
      this.setState({
        open: true
      })
    }
  }

  render() {
    return(
      <Fragment>

        <List>

          <ListItem button onClick={() => this.setState({open: !this.state.open})}>
            <ListItemIcon>
              {this.state.open ? <ExpandLess /> : <ExpandMore />}
            </ListItemIcon>

            <ListItemText className='grow-1' primary={this.props.header} />

            <ListItemText secondary={this.props.statuses.length} />
          </ListItem>

          <Collapse in={this.state.open} timeout="auto" unmountOnExit>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Active</TableCell>
                  <TableCell>Region</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Sectors</TableCell>
                  <TableCell>Source</TableCell>
                  <TableCell>Date</TableCell>
                  <TableCell>Date Created</TableCell>
                  <TableCell>User</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.keys(this.props.statuses).map((productStatusId) =>
                  <ProductStatusRow
                    key={productStatusId}
                    productStatusId={productStatusId}
                    productStatuses={this.props.statuses[productStatusId]}
                    handleDeleteClick={this.props.handleDeleteClick}
                    handleActiveClick={this.props.handleActiveClick}
                    handleSectorDelete={this.props.handleSectorDelete}
                  />
                )}
              </TableBody>
            </Table>
          </Collapse>

        </List>
      </Fragment>
    )
  }
}

export default StatusGroupDisplay
