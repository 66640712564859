import React, { Component } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import TableHead from "./TableHead"

class SimpleTable extends Component {
  constructor(props) {
    super(props)
  }
  
  render() {
    return (
      <table className='collapse'>
        <TableHead>
          <TableRow>
            {this.props.headerTemplate}
          </TableRow>
        </TableHead>
        <TableBody>
          {this.props.bodyTemplate}
        </TableBody>
      </table>
    )
  }
}

export default SimpleTable
