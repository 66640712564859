import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import { Table, TableHead, TableRow, TableCell, TableBody, EmptyDataTableRow } from "../../tables"
import Paper from "../../Paper"
import TherapyTypeRow from "./TherapyTypeRow"
import Snackbar from "../../Snackbar"
import update from "immutability-helper"

export default class TherapyTypes extends Component {
  constructor(props) {
    super(props)
    this.handleDeleteClick = this.handleDeleteClick.bind(this)
    this.state = { activeTab: 'List', therapyTypes: this.props.therapy_types, showSnackbar: false, snackbarText: '' }
  }
  
  handleDeleteClick(therapyType) {
    $.ajax({
      type: 'PUT',
      url: `/admin/therapy_types/${therapyType.id}`,
      data: {
        therapy_type: {
          deleted: true, deleted_by: this.props.current_user.id
        }
      },
      dataType: 'json',
      success: (data) => {
        let therapyTypeIds = this.state.therapyTypes.map((therapyType) => therapyType.id )
        let index = therapyTypeIds.indexOf(therapyType.id)
        let therapyTypes = update(this.state.therapyTypes, { $splice: [[index, 1]]})
        
        this.setState({
          therapyTypes: therapyTypes,
          showSnackbar: true,
          snackbarText: 'The therapy type was successfully deleted'
        })
      },
      error: (data) => {
        this.setState({
          showSnackbar: true,
          snackbarText: 'The therapy type could not be deleted. Please try again.'
        })
      },
      complete: (data) => {
        setTimeout(() => {
          this.setState({
            showSnackbar: false,
            snackbarText: ''
          })
        }, 4000)
      }
    })
  }
  
  render() {
    return(
      <Fragment>
        
        <Paper>
          <Table className='w-100'>
            <TableHead>
              <TableCell>Name</TableCell>
              <TableCell>Sector</TableCell>
              <TableCell>Created by</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableHead>
            
            <TableBody>
              {this.state.therapyTypes.map((therapyType) =>
                <TherapyTypeRow
                  key={therapyType.id}
                  therapyType={therapyType}
                  handleDeleteClick={this.handleDeleteClick}
                />
              )}
            </TableBody>
            
          </Table>
        </Paper>
        
        <Snackbar 
          open={this.state.showSnackbar}
          message={this.state.snackbarText} 
        />
        
      </Fragment>
    )
  }
}
