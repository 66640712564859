import React, { Fragment } from "react"
import ResultItemContainer from "../search/ResultItemContainer"
import Flex from "../Flex"
import Box from "../Box"
import Typography from "@material-ui/core/Typography"
import ProductCodeContainer from "../fda_device_classifications/ProductCodeContainer"
import ApplicantDisplay from "../ApplicantDisplay"

export default function SearchItem(props) {

  // let url = props.admin ? `/admin/pma_approvals/${props.result.id}/associator` : props.result.url
  let url = ""

  if (props.url) {
    url = props.url
  } else if (props.admin) {
    url = `/admin/pma_approvals/${props.result.id}/associator`
  } else {
    url = props.result.url
  }

  function renderSearchDetails(result) {
    return (
      <Flex alignItems='baseline'>
        <Box mr={2}>
          <ApplicantDisplay 
            applicant={result.applicant} 
            handleApplicantClick={props.handleApplicantClick} 
          />
        </Box>
        <Box>
          {result.fda_device_classifications ? <ProductCodeContainer productCodes={result.fda_device_classifications} handleProductCodeClick={props.handleProductCodeClick} /> : ""}
        </Box>
      </Flex>
    );
  }

  return(
    <ResultItemContainer 
      searchItemType={props.result.record_type}
      searchTitle={`${props.result.formatted_pma_number} - ${props.result.trade_name}`}
      date={props.result.decision_date}
      searchDescription={props.result.ao_statement}
      url={url}
      target="_blank"
      searchDetails={renderSearchDetails(props.result)}
    />
  )
}