import React, { Fragment } from "react"
import { List, ListItem, ListItemLink, ListItemText } from "../lists"
import Box from "../Box"
import Flex from "../Flex"
import Typography from "@material-ui/core/Typography"

export default function ArticlesList(props) {
  return(
    <List dense={props.dense ? props.dense : false}>
      {props.newsItems.map((newsItem) =>
        <ListItemLink divider key={newsItem.id} href={`/sectors/${props.sector.abbr}/news/${newsItem.id}/url_redirect`} target="_blank">
          <ListItemText>

            <Flex>
              <Box mr={1}>
                <img width="16px" height="16px" src={`https://www.google.com/s2/favicons?domain=${newsItem.url}`} />
              </Box>

              <Box>
                <Typography variant="body2" color="textSecondary">
                  {newsItem.news_publication_name || newsItem.publication || newsItem.service}
                </Typography>
              </Box>
            </Flex>

            <Box pb={0.5} width="60em">
              <Typography variant="body1">
                {newsItem.title}
              </Typography>
            </Box>

            <Box width="40em">
              <Typography variant="body2" color="textSecondary">
                {newsItem.description}
              </Typography>
            </Box>


          </ListItemText>

          <ListItemText secondary={newsItem.date ? newsItem.date.split("T")[0] : 'N/A'} className='tr' />
        </ListItemLink>
      )}
    </List>
  )
}
