import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import ProfileForm from "./ProfileForm"
import Paper from "../../Paper"
import Snackbar from "../../Snackbar"
import update from "immutability-helper"


export default class NewContainer extends Component {
  constructor(props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
    this.handleSaveClick = this.handleSaveClick.bind(this)
    this.handleCheckboxSelect = this.handleCheckboxSelect.bind(this)
    this.state = { targetSite: this.props.target_site, sectorIds: [], showSnackbar: false, snackbarText: '', activeTab: 'New Target Site' }
  }

  componentDidMount() {
    let targetSite = update(this.state.targetSite, {
      created_by: { $set: this.props.current_user.id }
    })

    this.setState({
      targetSite: targetSite
    })
  }

  handleTabClick(e) {
    e.preventDefault()
    this.setState({
      activeTab: e.target.text
    })
  }

  handleChange(e) {
    const name = e.currentTarget.name
    const value = e.currentTarget.value

    let targetSite = update(this.state.targetSite, {
      [name]: { $set: value }
    })

    this.setState({
      targetSite: targetSite
    })

  }

  handleSaveClick(e) {
    const url = this.props.redirect_url ? `/admin/target_sites?r=${this.props.redirect_url}` : "/admin/target_sites"
    $.ajax({
      type: 'POST',
      url: url,
      data: {
        target_site: this.state.targetSite, sector_ids: this.state.sectorIds
      },
      dataType: 'json',
      success: (data) => {
        window.location.href = url
      },
      error: (data) => {
        this.setState({
          showSnackbar: true,
          snackbarText: 'Target site could not be updated. Please try again.'
        })
      },
      complete: (data) => {
        setTimeout(() => {
          this.setState({
            showSnackbar: false,
            snackbarText: ''
          })
        }, 4000)
      }
    })
  }

  handleCheckboxSelect(sector, checked) {
    if (checked) {
      let index = this.state.sectorIds.indexOf(sector.id)
      let sectorIds = update(this.state.sectorIds, {
        $splice: [[index,1]]
      })

      this.setState({
        sectorIds: sectorIds
      })
    } else {
      let sectorIds = update(this.state.sectorIds, {
        $push: [sector.id]
      })

      this.setState({
        sectorIds: sectorIds
      })
    }
  }

  render() {
    console.log(this.state.sectorIds)
    return(
      <Fragment>

        <Paper>

          <ProfileForm
            targetSite={this.state.targetSite}
            sectors={this.props.sectors}
            entitySectorIds={this.state.sectorIds}
            handleChange={this.handleChange}
            handleSaveClick={this.handleSaveClick}
            handleCheckboxSelect={this.handleCheckboxSelect}
          />

        </Paper>

        <Snackbar
          open={this.state.showSnackbar}
          message={this.state.snackbarText}
        />

      </Fragment>
    )
  }
}
