import React from "react"
import PropTypes from "prop-types"
import { ListItem, ListItemText } from "../../lists"
import Checkbox from "../../Checkbox"
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction"
import Link from "../../Link"


export default function CheckboxListItem(props)  {

  function handleCheckboxSelect(e) {
    props.handleCheckboxSelect(props.product, 'activeProducts')
  }

  return(
    <React.Fragment>
      <ListItem>

        {props.forSelection ?
          <Checkbox
            onClick={handleCheckboxSelect}
            checked={props.checked}
          />
        : ''}

        <ListItemText>
          <Link href={`/admin/products/${props.product.id}`} target="_blank">
            {props.product.short_name}
          </Link>
        </ListItemText>

        <ListItemSecondaryAction>
          {props.associated ? 'Associated' : (props.hits ? props.hits : '')}
        </ListItemSecondaryAction>

      </ListItem>
    </React.Fragment>
  )
}

ProductListItem.defaultProps = {
  forSelection: true
}
