import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Header from "../Header"
import Filters from "./Filters"
import Paper from "../Paper"
import Box from "../Box"
import { Table, TableRow, TableHead, TableCell, TableBody } from "../tables"
import Flex from "../Flex"
import FilterValueDisplay from "./FilterValueDisplay"
import update from "immutability-helper"
import NavigatorRow from "./NavigatorRow"

export default class NavigatorContainer extends Component {
  constructor(props) {
    super(props)
    this.handleFilterChange = this.handleFilterChange.bind(this)
    this.handleDelete = this.handleDelete.bind(this)
    this.getProducts = this.getProducts.bind(this)
    this.state = { navigatorProducts: this.props.navigator_products, company_ids: [], product_ids: [] }
  }
  
  handleFilterChange(name, value) {
    this.getProducts(value)
  }
  
  handleDelete(value, type) {
    const index = this.state[type].indexOf(value)
    
    const productIds = update(this.state[type], {
      $splice: [[index, 1]]
    })
    
    this.getProducts(productIds)
  }
  
  getProducts(product_ids) {
    $.ajax({
      type: 'GET',
      url: `/sectors/${this.props.sector.abbr}/navigator`,
      data: {
        product_ids: product_ids
      },
      dataType: 'json',
      success: (data) => {
        this.setState({
          product_ids: product_ids,
          navigatorProducts: data.navigator_products
        })
      }
    })
  }
  
  render() {
    return (
      <Fragment>
        <Header
          header='Navigator'
          background='bg-washed-dark-blue'
        />
        
        <Paper>
          
          <Box mb={2}>
            <Filters 
              companies={this.props.companies}
              products={this.props.products}
              companyIds={this.state.company_ids}
              productIds={this.state.product_ids}
              handleFilterChange={this.handleFilterChange}
            />
          </Box>
          
          <FilterValueDisplay 
            products={this.props.products}
            productIds={this.state.product_ids}
            handleDelete={this.handleDelete}
          />
          
          
          <Table>
            
            <TableHead>
              <TableRow hover={false}>
                <TableCell>Product</TableCell>
                <TableCell>Company</TableCell>
                {/* <TableCell>Target Market</TableCell> */}
                <TableCell>Market Status</TableCell>
                <TableCell>Trial</TableCell>
                <TableCell>Trial Status</TableCell>
              </TableRow>
            </TableHead>
            
            {/* <TableBody> */}
            {this.state.navigatorProducts.map((product) =>
              <TableBody key={product.id}>
                <NavigatorRow
                  product={product}
                />
              </TableBody>
            )}
            {/* </TableBody> */}
            
          </Table>
        </Paper>
      </Fragment>
    )
  }
}
