import React from "react"
import { ListItem, ListItemText, ListItemLink } from "../../lists"
import Checkbox from "../../Checkbox"
import DeleteButton from "../DeleteButton"
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction"


export default function AssociatedTrialListItem(props) {

  function handleUnassociateClick(e) {
    props.handleTrialUnassociateClick(props.abstractTrial)
  }

  return(
    <React.Fragment>
      <ListItemLink href={`/admin/trials/${props.abstractTrial.trial_id}/edit`} target="_blank">

        <ListItemText
          primary={props.abstractTrial.acronym || props.abstractTrial.short_name}
          secondary={props.abstractTrial.nct_id}
        />

        <ListItemSecondaryAction>
          {props.handleTrialUnassociateClick ? <DeleteButton onClick={handleUnassociateClick} /> : (props.associated ? 'Associated' : '')}
        </ListItemSecondaryAction>

      </ListItemLink>
    </React.Fragment>
  )
}
