import React from "react"
import { Table, TableRow, TableCell, TableHead, TableBody } from "../../tables"
import Link from "../../Link"


export default function List(props) {
  return(
    <Table>
      <TableBody>
        {props.pressReleases.map((pressRelease) =>
          <TableRow key={pressRelease.id}>
            <TableCell>
              <Link href={`/admin/press_releases/${pressRelease.id}`}>
                {pressRelease.title}
              </Link>
            </TableCell>
            <TableCell>
              {humanDateFormat(pressRelease.date)}
            </TableCell>
            <TableCell>{pressRelease.first_name ? displayInitials(pressRelease.first_name, pressRelease.last_name) : "SYS"}</TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  )
}
