import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Paper from "../../Paper"
import TextInput from "../../TextInput"
import Flex from "../../Flex"
import Box from "../../Box"
import update from "immutability-helper"
import Typography from "@material-ui/core/Typography"
import { Table, TableRow, TableHead, TableBody, TableCell } from "../../tables"
import RaisedButton from "../../buttons/RaisedButton"
import ButtonLayout from "../ButtonLayout"
import Snackbar from "../../Snackbar"


export default class ProfileReview extends Component {
  constructor(props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
    this.handleTrialOutcomeChange = this.handleTrialOutcomeChange.bind(this)
    this.handleSaveClick = this.handleSaveClick.bind(this)
    this.state = { trial: this.props.trial, showSnackbar: false, snackbarText: '' }
  }
  
  componentDidMount() {
    let primaryOutcome = this.props.trial_outcome ? this.props.trial_outcome.primary_outcome : ''
    let secondaryOutcome = this.props.trial_outcome ? this.props.trial_outcome.secondary_outcome : ''
    const trial_outcomes_attributes = { 0: { trial_id: this.props.trial.id, user_id: this.props.current_user.id, source_id: '', primary_outcome: primaryOutcome, secondary_outcome: secondaryOutcome } }
    
    let trial = update(this.state.trial, {
      user_id: { $set: this.props.current_user.id },
      from_review: { $set: true },
      $merge: { trial_outcomes_attributes: trial_outcomes_attributes }
    })
    
    this.setState({
      trial: trial
    })
  }
  
  handleChange(e) {
    const trial = update(this.state.trial, {
      [name]: { $set: e.target.value }
    })
    
    this.setState({
      trial: trial
    })
  }
  
  handleTrialOutcomeChange(e) {
    const name = e.target.name
    const value = e.target.value
    
    let trial = update(this.state.trial, { 
      trial_outcomes_attributes: { 0: { 
        [name]: { $set: value },
        source_id: { $set: this.state.trial.source_id}
      }}
    })
    
    this.setState({
      trial: trial
    })
  }
  
  handleSaveClick(e) {
    $.ajax({
      type: 'PUT',
      url: `/admin/trials/${this.state.trial.id}`,
      data: {
        trial: this.state.trial, from_review: true
      },
      dataType: 'json',
      success: (data) => {
        let trial = ""
        if (data.trial_outcome) {
          const trial_outcomes_attributes = { 0: { trial_id: data.trial.id, user_id: data.trial.user_id, source_id: '', primary_outcome: data.trial_outcome.primary_outcome, secondary_outcome: data.trial_outcome.secondary_outcome } }
          trial = update(data.trial, {$merge: { trial_outcomes_attributes: trial_outcomes_attributes }})  
        } else {
          trial = data.trial
        }
        
        this.setState({
          trial: trial,
          showSnackbar: true,
          snackbarText: 'The trial was successfully saved'
        })
      },
      error: (data) => {
        this.setState({
          showSnackbar: false,
          snackbarText: 'There was an error saving the trial. Please try again.'
        })
      },
      complete: (data) => {
        setTimeout(() => {
          this.setState({
            showSnackbar: false,
            snackbarText: ''
          })
        }, 4000)
      }
    })
  }
  
  render() {
    return(
      <Fragment>
        
        <Paper>
          
          <Typography variant="h6" component="h6">
            Profile Review
          </Typography>
          
          <Flex mt={5}>
            
            <Box width={1/2} borderRight={1}>
            
              <Box mb={3} borderBottom={1}>
                <Typography variant="h6" component="h6">
                  {this.state.trial.acronym || this.state.trial.short_name}
                </Typography>
              </Box>
              
              <Box mb={4} width={3/4}>
                <TextInput 
                  multiline={true} 
                  rowsMax={20}
                  fullWidth={true}
                  name='brief_summary' 
                  label='Brief Summary' 
                  value={this.state.trial.brief_summary} 
                  onChange={this.handleChange} 
                />
              </Box>
              
              <Box mb={4} width={3/4}>
                <TextInput 
                  multiline={true} 
                  rowsMax={30}
                  fullWidth={true}
                  name='detailed_description' 
                  label='Detailed Description' 
                  value={this.state.trial.detailed_description} 
                  onChange={this.handleChange} 
                />
              </Box>
            
              <Box mb={4} width={3/4}>
                <TextInput 
                  multiline={true} 
                  rows={15}
                  name='inclusion_criteria' 
                  label='Inclusion Criteria' 
                  value={this.state.trial.inclusion_criteria} 
                  onChange={this.handleChange} 
                  fullWidth
                />
              </Box>
              
              <Box mb={4} width={3/4}>
                <TextInput 
                  multiline={true} 
                  rows={15}
                  name='exclusion_criteria' 
                  label='Exclusion Criteria' 
                  value={this.state.trial.exclusion_criteria} 
                  onChange={this.handleChange} 
                  fullWidth
                />
              </Box>
              
              <Box mb={4} width={3/4}>
                <TextInput
                  multiline={true}
                  rows={10}
                  name='primary_outcome'
                  label='Primary Outcome'
                  value={this.state.trial.trial_outcomes_attributes ? this.state.trial.trial_outcomes_attributes[0].primary_outcome : ''}
                  onChange={this.handleTrialOutcomeChange} 
                  fullWidth
                />
              </Box>
              
              <Box mb={4} width={3/4}>
                <TextInput 
                  multiline={true} 
                  rows={10}
                  name='secondary_outcome' 
                  label='Secondary Outcome' 
                  value={this.state.trial.trial_outcomes_attributes ? this.state.trial.trial_outcomes_attributes[0].secondary_outcome : ''} 
                  onChange={this.handleTrialOutcomeChange} 
                  fullWidth
                />
              </Box>
              
            </Box>
            
            <Box width={1/2} justifySelf='right'>
            
              <Box ml='auto' mb={3} textAlign='right' borderBottom={1}>
                <Typography variant="h6" component="h6">
                  {`${this.props.ct_gov_trial.nct_id} - ${this.props.ct_gov_trial.last_changed_date}`}
                </Typography>
              </Box>
              
              <Box mb={4} width={3/4} ml='auto'>
                <TextInput 
                  multiline={true} 
                  rowsMax={20}
                  fullWidth={true}
                  name='brief_summary' 
                  label='Brief Summary' 
                  value={this.props.ct_gov_trial.brief_summary} 
                  onChange={this.handleChange} 
                />
              </Box>
              
              <Box mb={4} width={3/4} ml='auto'>
                <TextInput 
                  multiline={true} 
                  rowsMax={30}
                  fullWidth={true}
                  name='detailed_description' 
                  label='Detailed Description' 
                  value={this.props.ct_gov_trial.detailed_description} 
                  onChange={this.handleChange} 
                />
              </Box>
            
              <Box mb={4} width={3/4} ml='auto'>
                <TextInput 
                  multiline={true} 
                  rowsMax={33}
                  name='trial_criteria' 
                  label='Trial Criteria' 
                  value={this.props.ct_gov_trial.trial_criteria} 
                  onChange={this.handleChange} 
                  fullWidth
                />
              </Box>
              
              <Box mb={4} width={3/4} ml='auto'>
                <TextInput
                  multiline={true}
                  rows={10}
                  name='primary_outcome'
                  label='Primary Outcome'
                  value={this.props.ct_gov_trial_outcomes ? this.ct_gov_trial_outcomes.primary_outcome : ''}
                  fullWidth
                />
              </Box>
              
              <Box mb={4} width={3/4} ml='auto'>
                <TextInput 
                  multiline={true} 
                  rows={10}
                  name='secondary_outcome' 
                  label='Secondary Outcome' 
                  value={this.props.ct_gov_trial_outcomes ? this.props.ct_gov_trial_outcomes.secondary_outcome : ''} 
                  fullWidth
                />
              </Box>
              
            </Box>
            
          </Flex>
          
          <ButtonLayout>
            <RaisedButton handleClick={this.handleSaveClick}>
              Save Trial
            </RaisedButton>
          </ButtonLayout>
        
        </Paper>
        
        <Snackbar 
          open={this.state.showSnackbar}
          message={this.state.snackbarText} 
        />
        
      </Fragment>
    )
  }
}
