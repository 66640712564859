import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Paper from "../../Paper"
import Box from "../../Box"
import { Table, TableHead, TableRow, TableCell, TableBody } from "../../tables"
import Link from "../../Link"
import Filters from "./Filters"
import update from "immutability-helper"

export default class Meetings extends Component {
  constructor(props) {
    super(props)
    this.getMeetings = this.getMeetings.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleSearchClick = this.handleSearchClick.bind(this)
    this.handleClearSearchClick = this.handleClearSearchClick.bind(this)
    this.handleFilterChange = this.handleFilterChange.bind(this)
    this.state = { meetings: this.props.meetings, filterValues: { query: '', year: this.props.year, science_meeting_id: this.props.science_meeting_id }}
  }

  handleClearSearchClick(e) {
    let params = Object.assign({}, this.state.filterValues)

    params["query"] = null

    this.getMeetings(params)
  }

  handleSearchClick(e) {
    let params = Object.assign({}, this.state.filterValues)

    this.getMeetings(params)
  }

  handleChange(e) {
    let filterValues = update(this.state.filterValues, {
      [e.target.name]: {
        $set: e.target.value
      }
    })

    this.setState({
      filterValues: filterValues
    })
  }

  handleFilterChange(name, value) {
    let params = Object.assign({}, this.state.filterValues)

    params[name] = value

    this.getMeetings(params)
  }

  getMeetings(params) {
    $.ajax({
      type: "GET",
      url: '/admin/meetings',
      data: params,
      dataType: 'json',
      success: (data) => {
        this.setState({
          meetings: data.meetings,
          filterValues: params
        })
      }
    })
  }
  
  render() {
    return(
      <Fragment>
        
        <Paper>

          <Box mb={2}>
            <Filters 
              filterValues={this.state.filterValues}
              scienceMeetings={this.props.science_meetings}
              years={this.props.years}
              handleFilterChange={this.handleFilterChange}
              handleChange={this.handleChange}
              handleSearchClick={this.handleSearchClick}
              handleClearSearchClick={this.handleClearSearchClick}
            />
          </Box>
          
          <Box>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Meeting</TableCell>
                  <TableCell>Date</TableCell>
                </TableRow>
              </TableHead>
              
              <TableBody>
                {this.state.meetings.map((meeting) =>
                  <TableRow key={meeting.smo_id} component={Link} href={`/admin/meetings/${meeting.smo_id}`}>
                    <TableCell>{`${meeting.short_name} ${meeting.year}`}</TableCell>
                    <TableCell>{humanDateFormat(meeting.start_date)}</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </Box>
          
        </Paper>
        
      </Fragment>
    )
  }
}
