import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Paper from "../Paper"
import SearchIcon from '@material-ui/icons/Search'
import TextInput from "../TextInput"
import InputAdornment from '@material-ui/core/InputAdornment';
import { RaisedButton } from "../buttons"
import Flex from "../Flex"
import Box from "../Box"
import IconButton from "../IconButton"
import CloseIcon from '@material-ui/icons/Close';
import { List, ListItem, ListItemLink, ListItemText } from "../lists"
import { Tabs, Tab, TabPanel } from "../tabs"
import Typography from "@material-ui/core/Typography"
import { withRouter } from "react-router-dom";
import Pagination from '@material-ui/lab/Pagination';
import ResultsDisplay from "../search/ResultsDisplay"

class SearchResults extends Component {
  constructor(props) {
    super(props)
    this.handleActiveIndexClick = this.handleActiveIndexClick.bind(this)
    this.handleSearchChange = this.handleSearchChange.bind(this)
    this.handleSearchClick = this.handleSearchClick.bind(this)
    this.handlePageChange = this.handlePageChange.bind(this)
    this.state = { query: this.props.query, searchResults: this.props.search_results, resultsCount: this.props.results_count, activeIndex: 'All', page: 1 }
  }

  componentDidMount() {
    if (this.props.filter) {

      let index = this.props.filter

      this.setState({
        activeIndex: index,
        filter: this.props.filter
      })
    }
  }

  handlePageChange(e, page) {
    let url = `/admin/search`

    let filter = this.state.activeIndex

    $.ajax({
      type: 'GET',
      url: url,
      data: {
        query: this.state.query, page: page, filter: filter
      },
      dataType: 'json',
      success: (data) => {

        let params = {}

        params["query"] = this.state.query
        params["page"] = page
        filter ? params["filter"] = filter : ''

        let filterKeys = Object.keys(params)
        let queryParams = filterKeys.map((filterKey) => `${filterKey}=${params[filterKey]}`)

        this.props.history.push(`${url}?${queryParams.join("&")}`);

        this.setState({
          searchResults: data.search_results,
          resultsCount: data.results_count,
          page: page,
          filter: filter
        })
      }
    })
  }

  handleActiveIndexClick(e, newValue) {
    let url = `/admin/search`

    let filter = newValue

    $.ajax({
      type: 'GET',
      url: url,
      data: {
        query: this.state.query, filter: filter
      },
      dataType: 'json',
      success: (data) => {

        let params = {}
        params["query"] = this.state.query
        filter ? params["filter"] = filter : ''

        let filterKeys = Object.keys(params)
        let queryParams = filterKeys.map((filterKey) => `${filterKey}=${params[filterKey]}`)

        this.props.history.push(`${url}?${queryParams.join("&")}`);

        this.setState({
          searchResults: data.search_results,
          resultsCount: data.results_count,
          activeIndex: newValue,
          filter: filter
        })
      }
    })
  }

  handleSearchChange(e) {
    this.setState({
      query: e.target.value
    })
  }

  handleSearchClick(e) {
    let url = `/admin/search`

    $.ajax({
      type: 'GET',
      url: url,
      data: {
        query: this.state.query, filter: this.state.activeIndex
      },
      dataType: 'json',
      success: (data) => {

        // this.props.history.push(`${url}?query=${this.state.query}`);
        let params = {}

        params["query"] = this.state.query

        this.state.activeIndex ? params["filter"] = this.state.activeIndex : ''

        let filterKeys = Object.keys(params)
        let queryParams = filterKeys.map((filterKey) => `${filterKey}=${params[filterKey]}`)

        this.props.history.push(`${url}?${queryParams.join("&")}`);

        this.setState({
          searchResults: data.search_results,
          resultsCount: data.results_count,
          filter: this.state.activeIndex
        })
      }
    })
  }

  setUrl(result) {
    if (result.admin_search_url) {
      return(result.admin_search_url)
    } else if (result.search_url) {
      return(result.search_url)
    } else if (result.record_type == 'Presentation') {
      return(`/admin/abstracts/${result.id}`)
    } else if (result.record_type == 'Company') {
      return(`/admin/companies/${result.id}/edit`)
    } else if (this.state.activeIndex == "news") {
      return(`/admin/news_items/${result.id}/associator`)
    } else if (this.state.activeIndex == 'tweet') {
      return(`/admin/tweets`)
    } else if (this.state.activeIndex == 'SbirGrant') {
      return('/admin/sbir_grants')
    } else if (this.state.record_type == 'Person') {
      return(`/sectors/Peripheral/people/${result.id}`)
    } else if (result.record_type == "Patent") {
      return(`/admin/patents/${result.record_id}`)
    } else {
      return(`/admin/${result.record_type.toLowerCase()}s/${result.id}`)
    }
  }

  render() {
    return(
      <Fragment>
        <Paper minFullHeight>

          <Box height="100%">

            <form onSubmit={this.handleSearchClick} acceptCharset="UTF-8">
              <Flex alignItems='center' width={1} justifyContent='center'>
                <Box width={1/2}>
                  <TextInput
                    name='query'
                    label='Search'
                    value={this.state.query}
                    onChange={this.handleSearchChange}
                    className='w-100'
                    InputProps={{
                      startAdornment: <InputAdornment position="start"><SearchIcon /></InputAdornment>,
                    }}
                  />
                </Box>

                <Box ml={3}>
                  <RaisedButton onClick={this.handleSearchClick}>
                    Search
                  </RaisedButton>
                </Box>

              </Flex>
            </form>

            <Box mt={4}>
              <Tabs centered variant='scrollable' scrollButtons='auto' value={this.state.activeIndex} onChange={this.handleActiveIndexClick} className='bb b--black-10'>
                <Tab label='All' value="All" />
                <Tab label='Companies' value="Company" />
                <Tab label='Products' value="Product" />
                <Tab label='Trials' value="TrialDataView" />
                <Tab label='Ct Gov Trials' value="CtGovTrial" />
                <Tab label='ICTRP Trials' value="IctrpTrial" />
                <Tab label='Patents' value="PatentDataView" />
                <Tab label='Presentations' value="Abstract" />
                <Tab label='Publications' value="Manuscript" />
                <Tab label='Person' value="Person" />
                <Tab label='Approvals' value="FdaPmaApproval" />
                <Tab label='Recalls' value='recalls' />
                <Tab label='Adverse Events' value="FdaAdverseEvent" />
                <Tab label='News' value='news' />
                <Tab label='Twitter' value='Tweet' />
                <Tab label='Sbir Grants' value='SbirGrant' />
                <Tab label='NIH Grants' value='NihProjectGrant' />
              </Tabs>
            </Box>

            <Box my={2}>
              {`${this.state.resultsCount} ${this.state.resultsCount > 1 ? 'results' : 'result'}`}
            </Box>

            <Box my={2} width="80%" mx='auto' height="100%">
              {this.state.resultsCount == 0 ?
                <Flex justifyContent='center' height="50%">
                  <Box alignSelf='center' maxWidth="50%" textAlign='center'>
                    <Box>
                      <Typography variant="body1">
                        No results found for this query
                      </Typography>
                    </Box>

                    {this.state.activeIndex == 'Product' ?
                      <Box mt={3}>
                        <Typography variant="body2">
                          Interested in a product we don't currently cover? Let us know by clicking the button below. We'll review whether it meets our criteria for being included in the sector.
                        </Typography>

                        <Box mt={4} mx="auto" width={[3/4, 1/2, 1/2]}>
                          <RaisedButton onClick={this.handleProductRequestClick}>
                            Request Product
                          </RaisedButton>
                        </Box>
                      </Box>
                    : ""}
                  </Box>
                </Flex>
              :
                <Fragment>
                  <ResultsDisplay
                    searchResults={this.state.searchResults}
                    query={this.state.query}
                    sector={this.props.sector}
                    admin={true}
                  />

                  <Flex mt={2} justifyContent='center'>
                    <Pagination
                      page={this.state.page}
                      count={this.state.resultsCount > 20 ? Math.round(this.state.resultsCount/20) : 1}
                      onChange={this.handlePageChange}
                      showFirstButton
                      showLastButton
                    />
                  </Flex>
                </Fragment>
              }
            </Box>

          </Box>

        </Paper>
      </Fragment>
    )
  }
}

export default withRouter(SearchResults)
