import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Card from "../cards/Card"
import CardSection from "../cards/CardSection"
import SidebarSection from "../SidebarSection"
import Link from "../Link"
import EditButton from "../admin/EditButton"
import Box from "../Box"
import Flex from "../Flex"
import { Button } from "../buttons"
import Typography from "@material-ui/core/Typography"
import { List, ListItem, ListItemText, ListItemLink } from "../lists"
import Tooltip from '@material-ui/core/Tooltip';

export default class ProductCard extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    // const targetSites = this.props.target_sites.map((targetSite) =>
    //   <Box key={targetSite.id}>
    //     <Link target="_blank" href={`/products?target_site_id=${targetSite.id}`}>
    //       {targetSite.name}
    //     </Link>
    //   </Box>
    // )
    const technologyGroups = this.props.technology_groups.map((technologyGroup) =>
      <div key={technologyGroup.id}>
        <Box>
          {technologyGroup.name}
        </Box>
      </div>
    )
    // const targetMarkets = this.props.target_markets.map((targetMarket) =>
    //   <div key={targetMarket.id}>
    //     <Link target="_blank" href={`/products?target_market_id=${targetMarket.id}`}>{targetMarket.name}</Link>
    //   </div>
    // )
    const therapyTypes = this.props.therapy_types.map((therapyType) =>
      <div key={therapyType.id}>
        {therapyType.name}
      </div>
    )

    const editUrl = `/admin/products/${this.props.product.id}/edit`
    return (
      <Card background='bg-light-green'>
        {/* <Flex>
          <Box ml='auto'>
            <EditButton component="a" href={editUrl} />
          </Box>
        </Flex> */}

        <CardSection>
          <Box mb={2}>
            <Typography variant='h6' component="h6" fontSize="19px">
              At a glance
            </Typography>
          </Box>
          <SidebarSection header='Full Name' content={this.props.product.full_name} />
          <SidebarSection header='Company'>
            <Link href={`/sectors/${this.props.sector.abbr}/companies/${this.props.company.id}`}>
               {this.props.company.short_name}
            </Link>
          </SidebarSection>
          {this.props.product_pmas.length > 0 ?
            <SidebarSection header='PMA Number'>
              {this.props.product_pmas.map((productPma) =>
                <Box key={productPma.id}>
                  {productPma.fda_pma_approval_id ?
                    <Link href={`/sectors/${this.props.sector.abbr}/products/${this.props.product.id}/pma_approvals/${productPma.fda_pma_approval_id}`}>
                      {productPma.pma_number}
                    </Link>
                  :
                    <Box>{productPma.pma_number}</Box>
                  }
                </Box>
              )}
            </SidebarSection>
          : ''}
        </CardSection>

        {this.props.parent_product ?
          <CardSection>
            <h4 className='mb2'>Product System</h4>

            <Box>
              <Link href={`/sectors/${this.props.sector.abbr}/products/${this.props.parent_product.id}`}>
                {this.props.parent_product.short_name}
              </Link>
            </Box>
          </CardSection>
        : ''}

        {this.props.child_products ?
          <CardSection>
            <h4 className='mb2'>Product Components</h4>

            <List>
              {this.props.child_products.map((product) =>
                <ListItemLink href={`/sectors/${this.props.sector.abbr}/products/${product.id}`} key={product.id}>
                  <ListItemText
                    primary={product.short_name}
                  />
                </ListItemLink>
              )}
            </List>
          </CardSection>
        : ''}

        {this.props.collections && this.props.collections.length > 0 ? <CardSection>
          <h4>Collections</h4>
          {this.props.collections.map((collection) =>
            <div key={collection.id}>
              <Link href={`/sectors/${this.props.sector.abbr}/products?collection_ids[]=${collection.id}`}>
                {collection.title}
              </Link>
            </div>
          )}
        </CardSection> : ''}

        {Object.keys(this.props.regulatory_statuses).length > 0 ?
          <CardSection>
            <h4>Regulatory Statuses</h4>
            {Object.keys(this.props.regulatory_statuses).map((region) =>
              <div key={region} className='mb2'>
                <div className='b'>
                  {region}
                </div>

                {this.props.regulatory_statuses[region].map((regulatoryStatus) =>
                  <div key={regulatoryStatus.id}>
                    {regulatoryStatus.name}
                    {regulatoryStatus.status_display_date ?
                      <Tooltip title='Date of status'>
                        <span className='pl2 f6 i'>
                          {`(${humanDateFormat(regulatoryStatus.status_display_date)})`}
                        </span>
                      </Tooltip>
                    : ""}
                  </div>
                )}
              </div>
            )}

          </CardSection>
        : ''}

        {Object.keys(this.props.target_sites).length > 0 ? <CardSection>
          <h4>Target Sites</h4>

          <Box>
            {Object.keys(this.props.target_sites).map((worldRegion) =>
              <Box key={worldRegion} mb={2}>
                <div className='b'>
                  {worldRegion}
                </div>

                <Box>
                  {this.props.target_sites[worldRegion].map((targetSite) =>
                    <Box>
                      {targetSite.name}
                    </Box>
                  )}
                </Box>
              </Box>
            )}
          </Box>
        </CardSection> : ''}

        {Object.keys(this.props.target_markets).length > 0 ? <CardSection>
          <h4>Target Markets</h4>

          <Box>
            {this.props.sector.market_id == 3
            ?
              <Box>
                {this.props.target_markets.map((targetMarket) =>
                  <Box>
                    {targetMarket.name}
                  </Box>
                )}
              </Box>
            :
              Object.keys(this.props.target_markets).map((worldRegion) =>
                <Box key={worldRegion} mb={2}>
                  <div className='b'>
                    {worldRegion}
                  </div>

                  <Box>
                    {this.props.target_markets[worldRegion].map((targetMarket) =>
                      <Box>
                        {targetMarket.name}
                      </Box>
                    )}
                  </Box>
                </Box>
              )}
          </Box>
        </CardSection> : ''}

        {technologyGroups.length > 0 ? <CardSection>
          <h4>Technology Group</h4>

          <Box>{technologyGroups}</Box>
        </CardSection> : ''}

        {therapyTypes.length > 0 ? <CardSection noBorder>
          <h4>Therapy Types</h4>

          <Box>{therapyTypes}</Box>
        </CardSection> : ''}

        {/* <CardSection noBorder>
          <h4>Characteristics of technology</h4>
          {Object.keys(this.props.product_characteristics).map((groupName) =>
            <div key={groupName} className='mb2'>
              <div className='b'>
                {groupName}
              </div>
              {this.props.product_characteristics[groupName].map((productCharacteristic) =>
                <div key={productCharacteristic.id} className='mb1'>
                  {productCharacteristic.characteristic_value ? `${productCharacteristic.characteristic_value.name} ${productCharacteristic.characteristic_value.metric ? productCharacteristic.characteristic_value.metric.name : ''}` : 'N/A'}
                </div>
              )}
            </div>
          )}
        </CardSection> */}
      </Card>
    )
  }
}
