import React from 'react'
import { Table, TableBody, TableCell, TableRow, TableHead } from '../../../tables'
import PredictionRow from './PredictionRow'

export default function PmaPredictionsTable(props) {
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell />
          <TableCell>PMA</TableCell>
          <TableCell>Trade Name</TableCell>
          <TableCell className="measure-wide">AO Statement</TableCell>
          <TableCell>Search Term</TableCell>
          <TableCell>Supplement Reason</TableCell>
          <TableCell />
          <TableCell>Hide</TableCell>
        </TableRow>
      </TableHead>

      <TableBody>
        {props.pmaApprovals.map((pmaApproval) =>
          <PredictionRow 
            key={pmaApproval.id}
            pmaApproval={pmaApproval}
            checkedPmaApprovals={props.checkedPmaApprovals}
            handleAddPmaClick={props.handleAddPmaClick}
            handleDeletePredictionClick={props.handleDeletePredictionClick}
            handlePmaApprovalCheckboxClick={props.handlePmaApprovalCheckboxClick}
          />
        )}
      </TableBody>
    </Table>
  )
}