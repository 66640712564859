import React, { useEffect, useState } from "react";
import Flex from "../Flex";
import Box from "../Box";
import FilterOption from "./FilterOption";
import TextInput from "../TextInput";
import ClearIcon from '@material-ui/icons/Clear';
import IconButton from "../IconButton";
import InputAdornment from "@material-ui/core/InputAdornment"
import SecondaryText from "../SecondaryText";
import Collapse from "@material-ui/core/Collapse";
import ExpandMore from "../ExpandMore";
import ExpandLess from '../ExpandLess';
import SortIcon from '@material-ui/icons/Sort';
import SortByAlphaIcon from '@material-ui/icons/SortByAlpha';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Clear from "@material-ui/icons/Clear";

export default function FilteredList(props) {
  const { items, filterName, label, filterValues, handleFilterChange, children, defaultOpen=false, filterValue: initialFilterValue, dateSorting=false, defaultChecked } = props;
  
  const [filteredItems, setFilteredItems] = React.useState([]);
  const [filterValue, setFilterValue] = React.useState(initialFilterValue || '');
  const [open, setOpen] = React.useState(defaultOpen);
  const [alphSortOrder, setAlphSortOrder] = React.useState('asc');
  const [countSortOrder, setCountSortOrder] = React.useState('asc');
  const [allChecked, setAllChecked] = React.useState(false);
  const [activeFilterCount, setActiveFilterCount] = useState('');

  useEffect(() => {
    setActiveFilterCount(filterValues[filterName]?.length || 0);

    let itemsToSet = [...items];
    
    // Move defaultChecked item to top if it exists
    if (defaultChecked) {
      const defaultItem = itemsToSet.find(item => item.value === defaultChecked);
      if (defaultItem) {
        itemsToSet = [
          defaultItem,
          ...itemsToSet.filter(item => item.value !== defaultChecked)
        ];
      }
    }

    if (dateSorting) {
      const sorted = itemsToSet.sort((a, b) => a.name.localeCompare(b.name));
      setFilteredItems([...sorted]);
      setAlphSortOrder('desc');
    } else {
      filterValue ? filteredItems : setFilteredItems(itemsToSet);
    }
  }, [items, dateSorting]);

  const toggleFilter = (item, isChecked) => {
    handleFilterChange((item.value || item.name), filterName);
    setActiveFilterCount(prevCount => isChecked ? prevCount - 1 : prevCount + 1);
  };

  function handleCheckAllClick(e) {
    if (allChecked) {
      setAllChecked(!allChecked);
      props.handleCheckAllClick([], filterName);
      setActiveFilterCount(0);
    } else {
      setAllChecked(!allChecked);
      props.handleCheckAllClick(filteredItems, filterName)
      setActiveFilterCount(filteredItems.length);
    }
  }

  function handleRemoveAllClick(e) {
    setAllChecked(false);
    props.handleCheckAllClick([], filterName);
    setActiveFilterCount(0);
  }

  function handleFilterSearchChange(e) {
    setFilterValue(e.target.value);
    let filtered = items.filter(item => 
      item.name.toLowerCase().includes(e.target.value.toLowerCase())
    );

    // Keep defaultChecked item at top of filtered results if it matches search
    if (defaultChecked) {
      const defaultItem = filtered.find(item => item.value === defaultChecked);
      if (defaultItem) {
        filtered = [
          defaultItem,
          ...filtered.filter(item => item.value !== defaultChecked)
        ];
      }
    }

    setFilteredItems(filtered);
  }

  function handleClearSearchClick(e) {
    setFilterValue('');
    setFilteredItems(items);
  }

  function handleSortCountClick(e) {
    if (countSortOrder === 'asc') {
      const sorted = [...filteredItems].sort((a, b) => {
        if (a.id === defaultChecked) return -1;
        if (b.id === defaultChecked) return 1;
        return a.count - b.count;
      });
      setFilteredItems(sorted);
      setCountSortOrder('desc');
    } else {
      const sorted = [...filteredItems].sort((a, b) => {
        if (a.id === defaultChecked) return -1;
        if (b.id === defaultChecked) return 1;
        return b.count - a.count;
      });
      setFilteredItems(sorted);
      setCountSortOrder('asc');
    }
  }

  function handleAlphabeticalSortClick(e) {
    if (alphSortOrder === 'asc') {
      const sorted = [...filteredItems].sort((a, b) => {
        if (a.id === defaultChecked) return -1;
        if (b.id === defaultChecked) return 1;
        return a.name.localeCompare(b.name);
      });
      setFilteredItems(sorted);
      setAlphSortOrder('desc');
    } else {
      const sorted = [...filteredItems].sort((a, b) => {
        if (a.id === defaultChecked) return -1;
        if (b.id === defaultChecked) return 1;
        return b.name.localeCompare(a.name);
      });
      setFilteredItems(sorted);
      setAlphSortOrder('asc');
    }
  }

  const renderFilterOptions = () => {
    return filteredItems.map(item => {
      const filterValue = item.value ? item.value : item.name;
      const isChecked = filterValues[filterName].includes(filterValue);
      const isDefaultChecked = defaultChecked && item.value === defaultChecked;

      return (
        <FilterOption
          key={filterValue}
          filterObject={item}
          filterName={filterName}
          filterValue={filterValue}
          checked={isChecked || isDefaultChecked}
          handleFilterChange={() => !isDefaultChecked && toggleFilter(item, isChecked)}
          disabled={isDefaultChecked}
        />
      );
    });
  };

  return (
    <Box border={1} borderColor="rgba(0, 0, 0, 0.12)">
      <Flex alignItems='center' width={1} px={2}>
        <Box className='MuiTypography-colorTextSecondary' width="90%">
          {label} ({activeFilterCount})
        </Box>
        <Box>
          <IconButton onClick={handleRemoveAllClick} tooltip='remove all filters' size='small'>
            <Clear color='action' fontSize='small' />
          </IconButton>
        </Box>
        <Box>
          <IconButton onClick={() => setOpen(!open)} tooltip='expand' edge="end" aria-label="expand">
            {open ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
        </Box>
      </Flex>

      <Collapse in={open} timeout="auto" unmountOnExit>
        <Flex alignItems='center' pr={1} mb={2}>
          <Box>
            <IconButton onClick={handleCheckAllClick} size='small'>
              <CheckBoxIcon color='primary' fontSize='small' />
            </IconButton>
          </Box>
          <Box>
            <IconButton onClick={handleSortCountClick} size='small'>
              <SortIcon fontSize='small' />
            </IconButton>
          </Box>
          <Box>
            <IconButton onClick={handleAlphabeticalSortClick} size='small'>
              <SortByAlphaIcon fontSize='small' />
            </IconButton>
          </Box>
          <Box ml='auto' width="60%">
            <TextInput
              name={`${filterName}_search`}
              placeholder="Filter"
              size='small'
              value={filterValue}
              onChange={handleFilterSearchChange}
              InputProps={{
                endAdornment: <InputAdornment position="end"><IconButton onClick={handleClearSearchClick} size='small'><ClearIcon fontSize='small' /></IconButton></InputAdornment>
              }}
            />
          </Box>
        </Flex>
        <Box height="300px" overflow="auto">
          {filteredItems.length === 0 ? (
            <Box mt={2} textAlign="center">
              <SecondaryText>
                <div>{`No ${label} found.`}</div>
                <div>We couldn't find any matching your filter criteria. Please adjust your filters or try a different search term</div>
              </SecondaryText>
            </Box>
          ) : renderFilterOptions()}
        </Box>
      </Collapse>
    </Box>
  );
}
