import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import List from "../../lists/List";
import NestedListItemLink from "../../lists/NestedListItemLink"
import ListItemLink from "../../lists/ListItemLink"
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from "../../lists/ListItemText";
import ExpandLess from '../../ExpandLess';
import ExpandMore from '../../ExpandMore';
import Collapse from '@material-ui/core/Collapse';

export default class NavigationList extends Component {
  constructor(props) {
    super(props)
    this.state = { open: false }
  }

  UNSAFE_componentWillMount() {
    if (this.props.activeGroup == 'Alerts') {
      this.setState({
        open: true
      })
    }
  }

  render() {
    return(
      <Fragment>

        <ListItem button onClick={() => this.setState({open: !this.state.open})}>
          <ListItemText primary="Alerts" />
          {this.state.open ? <ExpandLess /> : <ExpandMore />}
        </ListItem>

        <Collapse in={this.state.open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>

            <NestedListItemLink href="/admin/alerts/dashboard" active={this.props.activeGroup == 'Alerts' && this.props.activeItem == 'Dashboard'}>
              <ListItemText primary="Dashboard" />
            </NestedListItemLink>

            <NestedListItemLink href="/admin/alerts/emailer" active={this.props.activeGroup == 'Alerts' && this.props.activeItem == 'Emailer'}>
              <ListItemText primary="Emailer" />
            </NestedListItemLink>

            <NestedListItemLink href="/admin/alerts/greenroom" active={this.props.activeGroup == 'Alerts' && this.props.activeItem == 'Greenroom'}>
              <ListItemText primary="Greenroom" />
            </NestedListItemLink>

            <NestedListItemLink href="/admin/alerts/new" active={this.props.activeGroup == 'Alerts' && this.props.activeItem == 'New Alert'}>
              <ListItemText primary="New Alert" />
            </NestedListItemLink>

            <NestedListItemLink href="/admin/alert_groups" active={this.props.activeGroup == 'Alerts' && this.props.activeItem == 'Alert groups'}>
              <ListItemText primary="Alert groups" />
            </NestedListItemLink>

          </List>
        </Collapse>

      </Fragment>
    )
  }
}
