import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import ExpandLess from '../../ExpandLess';
import ExpandMore from '../../ExpandMore';
import Collapse from '@material-ui/core/Collapse';
import { List, ListItem, ListItemText } from "../../lists"
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';


export default class NestedEnrollmentForm extends Component {
  constructor(props) {
    super(props)
    this.state = { open: false }
  }

  componentDidMount() {
    if (this.props.activeIndex == 0) {
      this.setState({
        open: true
      })
    }
  }

  render() {
    return(
      <Fragment>

        <ListItem button onClick={() => this.setState({open: !this.state.open})} clasName='bb b--black-10'>
          <ListItemIcon>
            <CheckCircleOutlineIcon />
          </ListItemIcon>

          <ListItemText primary='Enrollment' />

          <ListItemSecondaryAction>
            {this.state.open ? <ExpandLess /> : <ExpandMore />}
          </ListItemSecondaryAction>
        </ListItem>

        <Collapse in={this.state.open} timeout="auto" unmountOnExit>
          {this.props.children}
        </Collapse>

      </Fragment>
    )
  }
}
