import React, { Component, Fragment } from "react"
import PropTypes from "prop-types"
import ReactDOM from "react-dom"
import Paper from "../Paper"
import Select from "../Select"
import Chart from "../charts/Chart"
import ActivityFilters from "./ActivityFilters"
import ActivityChart from "./ActivityChart"
import ActivityChartBreakdown from "./ActivityChartBreakdown"
import ActivityDataTable from "./ActivityDataTable"
import Link from "../Link"
import Flex from "../Flex"
import Box from "../Box"
import { Tabs, Tab } from "../tabs"
import ExpandLess from '../ExpandLess';
import ExpandMore from '../ExpandMore';
import Collapse from '@material-ui/core/Collapse';

export default class Activity extends Component {
  constructor(props) {
    super(props)
    this.handleTabClick = this.handleTabClick.bind(this)
    this.handleFilterChange = this.handleFilterChange.bind(this)
    this.handleActiveIndexUpdate = this.handleActiveIndexUpdate.bind(this)
    this.state = { activeTab: 'Charts', chartDisplayType: this.props.chart_display_options[1], chartData: this.props.chart_data, dates: this.props.dates, tableData: this.props.activity_data, methodsData: this.props.methods_data, targetMarketChartData: this.props.target_market_chart_data, therapyTypeChartData: this.props.therapy_type_chart_data, targetSiteChartData: this.props.target_site_chart_data, technologyGroupChartData: this.props.technology_group_chart_data, technologyData: this.props.technology_data, therapiesData: this.props.therapies_data, methodChartData: this.props.method_chart_data, valvesChartData: this.props.valves_chart_data, filterOptions: { chartEntityTypes: this.props.chart_entity_types, technologies: this.props.technologies, valvePositions: this.props.valve_positions, therapies: this.props.therapies, targetMarkets: this.props.target_markets, methods: this.props.methods }, filterValues: { chart_entity_type: this.props.chart_entity_type, target_market: '', technology: '', therapy: '', valve_position: ''}, activeIndex: 0 }
  }

  handleActiveIndexUpdate(e, newValue) {
    this.setState({
      activeIndex: newValue
    })
  }

  handleFilterChange(name, value) {
    let params = Object.assign({}, this.state.filterValues)

    params[name] = value

    $.ajax({
      type: 'GET',
      url: `/sectors/${this.props.sector.abbr}/people/${this.props.person.id}/activity`,
      data: params,
      dataType: 'json',
      success: (data) => {

        // let filterOptions = { technologies: data.technologies, valvePositions: data.valve_positions, therapies: data.therapies, targetMarkets: data.target_markets, methods: data.methods }

        this.setState({
          chartData: data.chart_data,
          tableData: data.activity_data,
          dates: data.dates,
          filterValues: params,
          targetSiteChartData: data.target_site_chart_data,
          targetMarketChartData: data.target_market_chart_data,
          therapyTypeChartData: data.therapy_type_chart_data,
          technologyGroupChartData: data.technology_group_chart_data,
          methodChartData: data.method_chart_data,
          valvesChartData: data.valves_chart_data
        })
      }
    })
  }

  // handleFilterChange(selectName, selectValue) {
  //   let value = selectValue
  //   let name = selectName
  //   let params = Object.assign({}, this.state.filters)
  //   params[name] = value
  //   params['chart_entity_type'] = this.state.filters.chart_entity_type
  //   $.ajax({
  //     type: 'GET',
  //     url: `/sectors/${this.props.sector.abbr}/people/${this.props.person.id}/activity`,
  //     data: params,
  //     dataType: 'json',
  //     success: (data) => {
  //       let filterState = this.state.filters[name] = value
  //       let filterOptionsState = this.state.filterOptions = { technologies: data.technologies, valvePositions: data.valve_positions, therapies: data.therapies, targetMarkets: data.target_markets, methods: data.methods }
  //       this.setState({
  //         chartData: data.activity_data,
  //         tableData: data.person_activity_data,
  //         methodsData: data.methods_data,
  //         targetMarketsData: data.target_markets_data,
  //         therapiesData: data.therapies_data,
  //         valvesData: data.valves_data,
  //         filterState,
  //         filterOptionsState
  //       })
  //     }
  //   })
  // }

  handleTabClick(e) {
    e.preventDefault()
    this.setState({
      activeTab: e.target.text
    })
  }

  handleChartDisplayOptionClick(e) {
    e.preventDefault()
    this.setState({
      chartDisplayType: e.target.text
    })
  }

  render () {
    return (
      <React.Fragment>

        <Paper>

          <Box mb={2}>

              <ActivityFilters
                filterValues={this.state.filterValues}
                filterOptions={this.state.filterOptions}
                sector={this.props.sector}
                handleFilterChange={this.handleFilterChange}
              />

          </Box>

          <Box mb={3}>

            <ActivityChart
              chartData={this.state.chartData}
              dates={this.state.dates}
              chartDisplayType={this.state.chartDisplayType}
              chartEntityType={this.state.filterValues.chart_entity_type}
            />

            {/* <div className='tc'>
              {this.props.chart_display_options.map((chartDisplayOption) =>
                <Link key={chartDisplayOption} href='#' active={chartDisplayOption == capitalizeFirstLetter(this.state.chartDisplayType)} handleClick={this.handleChartDisplayOptionClick} className='dib ph2'>
                  {chartDisplayOption}
                </Link>
              )}
            </div> */}

          </Box>

          <Box textAlign='center'>
            <Tabs centered value={this.state.activeIndex} onChange={this.handleActiveIndexUpdate} className='bb b--black-10' aria-label="chart tabs">
              <Tab label='Charts' />
              <Tab label='Data' />
            </Tabs>
          </Box>


          <Collapse in={this.state.activeIndex == 0} timeout="auto" unmountOnExit>
            <ActivityChartBreakdown
              chartEntityType={this.state.chartEntityType}
              methodChartData={this.state.methodChartData}
              targetMarketChartData={this.state.targetMarketChartData}
              targetSiteChartData={this.state.targetSiteChartData}
              technologyGroupChartData={this.state.technologyGroupChartData}
              valvesChartData={this.state.valvesChartData}
              therapyTypeChartData={this.state.therapyTypeChartData}
              sector={this.props.sector}
            />
          </Collapse>


          <Collapse in={this.state.activeIndex == 1} timeout="auto" unmountOnExit>
            <ActivityDataTable
              data={this.state.tableData}
              chartEntityType={this.state.filterValues.chart_entity_type}
            />
          </Collapse>

        </Paper>
      </React.Fragment>
    );
  }
}
