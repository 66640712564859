import React from "react"
import Flex from "../Flex"
import Box from "../Box"
import Typography from "@material-ui/core/Typography"
import { List, ListItemLink, ListItemText } from "../lists"
import ApprovalRow from "./ApprovalRow"

export default function ResultsDisplay(props) {

  return(
    <React.Fragment>
      <Box height="100%">
        <List>
          {props.searchResults.map((result) =>
            <ApprovalRow 
              key={result._id} 
              result={result._source ? result._source : result} 
              sector={props.sector} 
              handleProductCodeClick={props.handleProductCodeClick}
              handleApplicantClick={props.handleApplicantClick}
            />
          )}
        </List>
      </Box>
    </React.Fragment>
  )
}