import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Paper from "../../Paper"
import Flex from "../../Flex"
import Box from "../../Box"
import TextInput from "../../TextInput"
import SearchIcon from '@material-ui/icons/Search'
import InputAdornment from '@material-ui/core/InputAdornment';
import { Table, TableHead, TableRow, TableCell, TableBody } from "../../tables"
import Select from "../../Select"
import Link from "../../Link"
import { RaisedButton } from "../../buttons"
import IndeterminateCheckBoxIcon from '@material-ui/icons/IndeterminateCheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import IconButton from "../../IconButton"
import DeleteButton from "../DeleteButton"
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Checkbox from '@material-ui/core/Checkbox';
import MuiIconButton from '@material-ui/core/IconButton';
import update from "immutability-helper"
import Snackbar from "../../Snackbar"


export default class ScrapedProducts extends Component {
  constructor(props) {
    super(props)
    this.handleSearchChange = this.handleSearchChange.bind(this)
    this.handleSearchSubmit = this.handleSearchSubmit.bind(this)
    this.handleDeleteClick = this.handleDeleteClick.bind(this)
    this.handleProductCheckboxClick = this.handleProductCheckboxClick.bind(this)
    this.handleFilterChange = this.handleFilterChange.bind(this)
    this.handleCreateProductClick = this.handleCreateProductClick.bind(this)
    this.state = { scrapedProducts: this.props.scraped_products, checkedProducts: [], query: '', company_id: '' }
  }

  handleProductCheckboxClick(scrapedProduct) {
    const index = this.state.checkedProducts.indexOf(scrapedProduct.id)

    console.log(this.state.checkedProducts)

    let scrapedProducts = index == -1 ?
      update(this.state.checkedProducts, {
        $push: [scrapedProduct.id]
      })
    :
      update(this.state.checkedProducts, {
        $splice: [[index, scrapedProduct.id]]
      })

    console.log(scrapedProducts)

    this.setState({
      checkedProducts: scrapedProducts
    })
  }

  handleSearchChange(e) {
    this.setState({
      query: e.target.value
    })
  }

  handleSearchSubmit(e) {
    $.ajax({
      type: 'GET',
      url: '/admin/scraped_products/search',
      data: {
        query: this.state.query
      },
      dataType: 'json',
      success: (data) => {
        this.setState({
          scrapedProducts: data.scraped_products
        })
      }
    })
  }

  handleDeleteClick(scrapedProduct) {
    $.ajax({
      type: 'PUT',
      url: `/admin/scraped_products/${scrapedProduct.id}`,
      data: {
        scraped_product: {
          deleted: true, deleted_by: this.props.current_user.id
        }
      },
      dataType: 'json',
      success: (data) => {
        let index = this.state.scrapedProducts.indexOf(scrapedProduct)

        let scrapedProducts = update(this.state.scrapedProducts, {
          $splice: [[index,1]]
        })

        this.setState({
          scrapedProducts: scrapedProducts,
          showSnackbar: true,
          snackbarText: "Scraped product was removed"
        })
      },
      complete: (data) => {
        setTimeout(() => {
          this.setState({
            showSnackbar: false,
            snackbarText: ""
          })
        }, 4000)
      }
    })
  }

  handleFilterChange(name, value) {
    let companyId = value
    $.ajax({
      type: 'GET',
      url: '/admin/scraped_products',
      data: {
        company_id: companyId
      },
      dataType: 'json',
      success: (data) => {
        this.setState({
          scrapedProducts: data.scraped_products,
          company_id: companyId
        })
      }
    })
  }

  handleCreateProductClick(e) {
    if (this.state.checkedProducts.length > 0) {
      $.ajax({
        type: 'GET',
        url: '/admin/scraped_products/create_products',
        data: {
          scraped_product_ids: this.state.checkedProducts, user_id: this.props.current_user.id, company_id: this.state.company_id
        },
        dataType: 'json',
        success: (data) => {
          this.setState({
            scrapedProducts: data.scraped_products,
            showSnackbar: true,
            snackbarText: "Product was created"
          })
        },
        complete: (data) => {
          setTimeout(() => {
            this.setState({
              showSnackbar: false,
              snackbarText: ""
            })
          }, 4000)
        }
      })
    } else {
      alert("Choose a product(s) first")
      // this.setState({
      //   showSnackbar: true,
      //   snackbarText: "Choose a product(s) first"
      // })
      //
      // setTimeout(() => {
      //   this.setState({
      //     showSnackbar: false,
      //     snackbarText: ""
      //   })
      // }, 4000)
    }
  }

  render() {
    return(
      <Fragment>

        <Paper fullHeight>
          <Box height='100%'>

            <Flex alignItems='center' mb={3}>

              <Box width={4/5} mr={3}>
                <TextInput
                  name='query'
                  placeholder='Search Scraped Products'
                  size='small'
                  value={this.state.query}
                  onChange={this.handleSearchChange}
                  fullWidth
                  InputProps={{
                    startAdornment: <InputAdornment position="start"><SearchIcon fontSize='small' /></InputAdornment>
                  }}
                />
              </Box>

              <Box>
                <RaisedButton onClick={this.handleSearchSubmit}>
                  Search
                </RaisedButton>
              </Box>
            </Flex>

            <Flex alignItems='center' mb={3}>
              <Box>
                <Select
                  label='Company'
                  name='company_id'
                  value={this.state.company_id}
                  entities={this.props.companies}
                  handleChange={this.handleFilterChange}
                  includeBlank={true}
                />
              </Box>

              <Box ml='auto'>
                <RaisedButton onClick={this.handleCreateProductClick}>
                  Create As Product
                </RaisedButton>
              </Box>


              <Box ml='auto'>
                <div className='mh2'>
                  <div>{`${this.state.scrapedProducts.length} products`}</div>
                </div>
              </Box>
            </Flex>

            <Box overflow='auto' height="80%">
              <Table size='small'>
                <TableHead>
                  <TableRow>
                    <TableCell padding='checkbox'>
                      <IconButton onClick={this.handleBulkProductCheck} tooltip='Select' edge="start">
                        {this.state.checkedProducts.length > 0 ? <IndeterminateCheckBoxIcon style={{height: '22px', width: '22px'}} /> : <CheckBoxOutlineBlankIcon style={{height: '22px', width: '22px'}} />}
                      </IconButton>
                    </TableCell>
                    <TableCell>Product</TableCell>
                    <TableCell>Company</TableCell>
                    <TableCell>Description</TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {this.state.scrapedProducts.map((scrapedProduct) =>
                    <TableRow key={scrapedProduct.id}>
                      <TableCell padding='checkbox'>
                        <MuiIconButton onClick={() => { this.handleProductCheckboxClick(scrapedProduct) }} edge="start">
                          {this.state.checkedProducts.includes(scrapedProduct.id) ? <CheckBoxIcon color='primary' style={{height: '22px', width: '22px'}} /> : <CheckBoxOutlineBlankIcon style={{height: '22px', width: '22px'}} />}
                        </MuiIconButton>
                        {/* <Checkbox
                          onChange={this.handleProductCheckboxClick}
                          checked={selected}
                        /> */}
                      </TableCell>
                      <TableCell>
                        <Link href={scrapedProduct.url} target="_blank">
                          {scrapedProduct.name}
                        </Link>
                      </TableCell>
                      <TableCell>{scrapedProduct.company_name}</TableCell>
                      <TableCell>{scrapedProduct.description}</TableCell>
                      <TableCell></TableCell>
                      <TableCell>
                        <DeleteButton onClick={() => { this.handleDeleteClick(scrapedProduct) }} />
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </Box>

          </Box>
        </Paper>

        <Snackbar
          open={this.state.showSnackbar}
          message={this.state.snackbarText}
        />

      </Fragment>
    )
  }
}
