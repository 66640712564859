import React from 'react'
import Flex from '../../Flex'
import Box from '../../Box'
import TextInput from "../../TextInput"
import { RaisedButton } from "../../buttons"
import SearchIcon from '@material-ui/icons/Search'
import InputAdornment from '@material-ui/core/InputAdornment';
import ClearIcon from '@material-ui/icons/Clear';
import IconButton from '../../IconButton'

function InlineSearch(props) {
  return (
    <Flex alignItems='center'>
      <Box width={1} mr={1}>
        <TextInput
          name='query'
          label={props.searchLabel || 'Search'}
          value={props.query}
          onChange={props.handleQueryChange}
          fullWidth
          InputProps={{
            startAdornment: <InputAdornment position="start"><SearchIcon /></InputAdornment>, endAdornment: <InputAdornment position="end"><IconButton onClick={props.handleClearSearchClick} size='small'><ClearIcon fontSize='small' /></IconButton></InputAdornment>
          }}
          helperText={props.helperText}
        />
      </Box>

      <Box ml='auto'>
        <RaisedButton onClick={props.handleSearchClick}>
          Search
        </RaisedButton>
      </Box>
    </Flex>
  )
}

export default InlineSearch