import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import EnrollmentChart from "./EnrollmentChart"
import Paper from "../Paper"
import Tabs from "../tabs/Tabs"
import Tab from "../tabs/Tab"
import EnrollmentChartData from "./EnrollmentChartData"
import EnrollmentProfile from "./EnrollmentProfile"
import Flex from "../Flex"
import Box from "../Box"
import Switch from "../Switch"
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Snackbar from "../Snackbar"
import EmptyDataMessageContainer from "../EmptyDataMessageContainer"
import Typography from "@material-ui/core/Typography"

export default class Enrollment extends Component {
  constructor(props) {
    super(props)
    this.handleTabClick = this.handleTabClick.bind(this)
    this.handleEnrollmentDateSwitch = this.handleEnrollmentDateSwitch.bind(this)
    this.handleTargetEnrollmentSwitch = this.handleTargetEnrollmentSwitch.bind(this)
    this.state = { activeIndex: 0, showEnrollmentDates: true, showTargetEnrollment: true, showSnackbar: false, snackbarText: '' }
  }

  componentDidMount() {
    const showTargetEnrollmentState = this.props.target_enrollments ? true : false

    this.setState({
      showTargetEnrollment: showTargetEnrollmentState
    })
  }

  handleTabClick(e, newValue) {
    this.setState({
      activeIndex: newValue
    })
  }

  handleEnrollmentDateSwitch(e) {
    const newShowEnrollmentDatesState = !this.state.showEnrollmentDates

    this.setState({
      showEnrollmentDates: newShowEnrollmentDatesState
    })
  }

  handleTargetEnrollmentSwitch(e) {
    const newShowTargetEnrollmentState = this.props.target_enrollments ? !this.state.showTargetEnrollment : false
    const newShowTargetEnrollmentSnackbarState = this.props.target_enrollments ? false : true

    this.setState({
      showTargetEnrollment: newShowTargetEnrollmentState,
      showSnackbar: newShowTargetEnrollmentSnackbarState,
      snackbarText: 'There is no target enrollment for this trial'
    })

    if (newShowTargetEnrollmentSnackbarState) {
      setTimeout(() => {
        this.setState({
          showSnackbar: false
        })
      }, 4000)
    }
  }

  render() {
    return(
      <Fragment>

        <Paper minFullHeight={true}>

          {this.props.chart_data.length > 0
          ?
            <Box width={1}>

              <Box width={{ xs: "100%", lg: "80%"}} mx='auto'>
                <Box mb={3}>
                  <EnrollmentChart
                    key={`enrollment-chart-${this.state.showEnrollmentDates}-${this.state.showTargetEnrollment}`}
                    chartData={this.props.chart_data}
                    categories={this.props.categories}
                    targetEnrollment={this.props.target_enrollments}
                    enrollmentStartDate={this.props.enrollment_start_date_for_chart}
                    enrollmentEndDate={this.props.enrollment_end_date_for_chart}
                    title={`${this.props.trial_data_view.acronym ? this.props.trial_data_view.acronym : this.props.trial_data_view.short_name} Enrollment`}
                    showEnrollmentDates={this.state.showEnrollmentDates}
                    showTargetEnrollment={this.state.showTargetEnrollment}
                  />
                </Box>

                <Box mx='auto'>
                  <Flex alignItems='center' justifyContent='center'>
                    <Box mr={3}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={this.state.showEnrollmentDates}
                            handleChange={this.handleEnrollmentDateSwitch}
                            value={this.state.showEnrollmentDates}
                          />
                        }
                        label='Show dates'
                      />
                    </Box>

                    {/* <Box>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={this.state.showTargetEnrollment}
                            handleChange={this.handleTargetEnrollmentSwitch}
                            value={this.state.showTargetEnrollment}
                          />
                        }
                        label='Show target enrollment'
                      />
                    </Box> */}
                  </Flex>
                </Box>
              </Box>

              <Box textAlign='center' width={1}>
                <Box my={4}>
                  <Tabs centered value={this.state.activeIndex} onChange={this.handleTabClick} aria-label="enrollment tabs" className='bb b--black-10'>
                    <Tab label='Profile' />
                    <Tab label='Data' />
                  </Tabs>
                </Box>

                {this.state.activeIndex == 0 ?
                  <EnrollmentProfile
                    trial={this.props.trial}
                    enrollmentStartDate={this.props.enrollment_start_date}
                    enrollmentEndDate={this.props.enrollment_end_date}
                    enrollmentNumbers={this.props.enrollment_numbers}
                    status={this.props.trial_status}
                  />
                : ''}

                {this.state.activeIndex == 1 ?
                  <Box>
                    <EnrollmentChartData
                      currentEnrollmentHash={this.props.current_enrollment_hash}
                      targetEnrollmentHash={this.props.target_enrollment_hash}
                    />
                  </Box>
                : ''}

              </Box>
            </Box>
          :
            <EmptyDataMessageContainer
              headline='No enrollment data for this trial'
              body='If you would like to be updated when we have received enrollment data, click the heart icon in the header. You will receive an alert when enrollment has been updated.'
            />
          }

        </Paper>

        <Snackbar
          open={this.state.showSnackbar}
          message={this.state.snackbarText}
        />

      </Fragment>
    )
  }
}
