import React, { Component, Fragment } from "react"
import { List, ListItem, ListItemText } from "../../lists"

export default class TasksList extends Component {
  constructor(props) {
    super(props)
  }
  
  render() {
    // let taskUrl = `/admin/ct_gov_trials/${this.props.trialTask.id}/new_trial`
    return(
      <List>
        <ListItem divider component="a" href="/admin/trials/product_reviewer" target="_blank">
          <ListItemText 
            primary="Trials with newly associated products"
            secondary={`${this.props.trials_with_newly_associated_products} to review`}
          />
        </ListItem>
        <ListItem divider component="a" href="/admin/trials/staged_associator" target="_blank">
          <ListItemText 
            primary="Trials with predicted products"
            secondary={`${this.props.trials_with_predicted_products} to add`}
          />
        </ListItem>
        <ListItem divider component="a" href="/admin/ct_gov_trials/new_trials" target="_blank">
          <ListItemText 
            primary="New CtGov Trials"
            secondary={`${this.props.ct_gov_trials_to_review} to add`}
          />
        </ListItem>

        <ListItem divider component="a" href="/admin/device_recalls" target="_blank">
          <ListItemText 
            primary="Device Recalls"
            secondary={`${this.props.device_recalls_to_review} to review`}
          />
        </ListItem>

        <ListItem divider component="a" href="/admin/publications/staged_reviewer" target="_blank">
          <ListItemText 
            primary="Pubmed Reviewer"
            secondary={`${this.props.manuscripts_to_review} to review`}
          />
        </ListItem>

        <ListItem divider component="a" href="/admin/news" target="_blank">
          <ListItemText 
            primary="News"
            secondary={`${this.props.news_items_added} added`}
          />
        </ListItem>

        <ListItem divider component="a" href="/admin/pma_approvals/predictions" target="_blank">
          <ListItemText 
            primary="Products with PMA predictions"
            secondary={`${this.props.products_with_pma_predictions} products`}
          />
        </ListItem>

        <ListItem divider component="a" href="/admin/fda_k_clearances/predictions" target="_blank">
          <ListItemText 
            primary="Products with 510(k) predictions"
            secondary={`${this.props.products_with_510_k_predictions} products`}
          />
        </ListItem>
      </List>
    )
  }
}
