import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Box from "../../Box"
import Checkbox from "../../Checkbox"
import TextInput from "../../TextInput"
import Button from "../../buttons/Button"


export default class EditContainer extends Component {
  constructor(props) {
    super(props)
  }
  
  render() {
    return(
      <Fragment>
        
        {/* <Box>
          <Checkbox 
            name='regional'
            label='Regional'
            value={this.props.characteristicGroupRegion ? this.props.characteristicGroupRegion.regional : false}
            checked={this.props.characteristicGroupRegion ? this.props.characteristicGroupRegion.regional : false}
            onChange={this.props.handleRegionChange}
          />
        </Box> */}
        
        <TextInput 
          id='outlined-name'
          name='name' 
          label='Name' 
          value={this.props.characteristicGroup.name} 
          onChange={this.props.handleChange} 
          variant='outlined'
          margin='normal'
          fullWidth
        />
        
        <Box mt={4} mx="auto" width={[3/4, 1/2, 1/4]}>
          <Button variant='contained' fullWidth={true} onClick={this.props.handleSaveClick}>
            Save Characteristic Group
          </Button>
        </Box>
        
      </Fragment>
    )
  }
}
