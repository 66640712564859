import React, { Fragment } from "react"
import PropTypes from "prop-types"
import Flex from "../../Flex"
import Box from "../../Box"
import TextInput from "../../TextInput"
import DatePicker from "../../DatePicker"
import { Button, RaisedButton } from "../../buttons"
import Select from "../../Select"
import { List, ListItem, ListItemText } from "../../lists"
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Switch from "../../Switch"
import Typography from '@material-ui/core/Typography';
import ButtonLayout from "../ButtonLayout"


export default function Form(props) {
  return(
    <Fragment>

      <Box mb={3} width={1/2}>
        <TextInput
          name='name'
          label='Name'
          value={props.userCompany.name}
          onChange={props.handleChange}
          fullWidth
        />
      </Box>

      <Box mb={3} width={1/4}>
        <DatePicker
          name='expires_on'
          label='Expiration Date'
          handleChange={props.handleDateChange}
          value={props.userCompany.expires_on}
          className='w-100'
        />
      </Box>

      <Box mb={3} width={1/2}>
        <TextInput
          name='address'
          label='Address'
          value={props.userCompany.address}
          onChange={props.handleChange}
          fullWidth
        />
      </Box>

      <Box mb={3} width={1/2}>
        <TextInput
          name='city'
          label='City'
          value={props.userCompany.city}
          onChange={props.handleChange}
          fullWidth
        />
      </Box>

      <Box mb={3} width={1/2}>
        <TextInput
          name='state'
          label='State'
          value={props.userCompany.state}
          onChange={props.handleChange}
          fullWidth
        />
      </Box>

      <Box mb={3} width={1/2}>
        <TextInput
          name='zip'
          label='Zip Code'
          value={props.userCompany.zip}
          onChange={props.handleChange}
          fullWidth
        />
      </Box>

      <Box mb={3} width={1/2}>
        <TextInput
          name='billing_contact_name'
          label='Billing Contact Name'
          value={props.userCompany.billing_contact_name}
          onChange={props.handleChange}
          fullWidth
        />
      </Box>

      <Box mb={3} width={1/2}>
        <TextInput
          name='billing_contact_email'
          label='Billing Contact Email'
          value={props.userCompany.billing_contact_email}
          onChange={props.handleChange}
          fullWidth
        />
      </Box>

      <Box mb={3} width={1/2}>
        <TextInput
          name='subscription_fees'
          label='Fees'
          value={props.userCompany.subscription_fees}
          onChange={props.handleChange}
          fullWidth
        />
      </Box>

      <Box mt={3}>
        <Typography variant="h6" component="h6">
          Sectors
        </Typography>

        <Box mt={2} width={1/4}>
          <List className='w-100'>
            {props.sectors.map((sector) =>
              <ListItem key={sector.id} button divider>
                <ListItemText primary={sector.abbr} />

                <ListItemSecondaryAction>
                  <Switch
                    edge="end"
                    checked={props.userCompanySectors.filter(userCompanySector => userCompanySector.sector_id == sector.id)[0] ? true : false}
                    handleChange={() => props.handleSwitchChange(sector.id)}
                  />
                </ListItemSecondaryAction>
              </ListItem>
            )}
          </List>
        </Box>
      </Box>

      <ButtonLayout>
        <RaisedButton handleClick={props.handleSaveClick}>
          {props.buttonText}
        </RaisedButton>
      </ButtonLayout>
    </Fragment>
  )
}
