import React from "react"
import { TableRow, TableCell } from "../../tables"
import AccountLink from "./twitter/AccountLink"
import TweetLink from "./twitter/TweetLink"
import { Button } from "../../buttons"

export default function TweetRow(props) {

  function handleAlertClick(e) {
    props.handleAlertClick(props.tweet)
  }

  return(
    <TableRow>
      <TableCell>
        <AccountLink
          display={props.tweet.account_name}
          accountUserName={props.tweet.account_user_name}
        />
      </TableCell>
      <TableCell>
        <TweetLink
          tweet={props.tweet}
        />
      </TableCell>
      <TableCell>{humanDateFormat(props.tweet.date)}</TableCell>
      <TableCell>
        {props.existingAlertTweets.includes(props.tweet.id) ?
          <div></div>
        :
          <Button onClick={handleAlertClick}>
            Create Alert
          </Button>
        }
      </TableCell>
    </TableRow>
  )
}
