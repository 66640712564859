import React from "react"
import { makeStyles } from '@material-ui/core/styles';
import MuiSelect from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import update from "immutability-helper"
import Checkbox from "./Checkbox"
import ListItemText from "./lists/ListItemText"

export default function Select(props) {
  const useStyles = makeStyles(theme => ({
    formControl: {
      minWidth: 120,
      width: '100%'
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    root: {
      maxHeight: '40px'
    }
  }));


  function handleChange(event) {
    if (props.handleChange) {
      props.handleChange(event.target.name, event.target.value)
    }
  };

  function renderOption(entity) {
    if (entity.value) {
      return(<MenuItem key={entity.value} value={entity.value} className='f5'>
        {props.multiple && entity.value != '' ? <Checkbox checked={props.value.indexOf(entity.value) > -1} /> : ''}
        <ListItemText primary={entity.name} />
      </MenuItem>)
    } else {
      return(<MenuItem key={entity} value={entity} className='f5'>
        {props.multiple && entity != '' ? <Checkbox checked={props.value.indexOf(entity) > -1} /> : ''}
        <ListItemText primary={entity} />
      </MenuItem>)
    }
  }

  const inputLabel = React.useRef(null);
  const [labelWidth, setLabelWidth] = React.useState(0);
  React.useEffect(() => {
    setLabelWidth(inputLabel.current.offsetWidth);
  }, []);

  const classes = useStyles()

  let entities = props.entities && props.includeBlank ? update(props.entities, {$unshift: [{ name: <em>{props.blankOption ? props.blankOption : 'None'}</em>, value: null }] }) : props.entities

  return (
    <FormControl margin='dense' size='small' variant='outlined' className={classes.formControl}>
      <InputLabel ref={inputLabel} htmlFor={`select-${props.name}`}>
        {props.label}
      </InputLabel>
      <MuiSelect onChange={handleChange} variant={props.variant || 'outlined'} inputProps={{name: props.name, id: `select-${props.name}`}} autoWidth={true} value={props.value} labelWidth={labelWidth} renderValue={props.renderValue} multiple={props.multiple} displayEmpty={props.displayEmpty} disabled={props.disabled} className={classes.root}>
        {entities && entities.length > 0 ? entities.map((entity) =>
          <MenuItem key={entity.value} value={entity.value} className='f5'>
            {props.multiple && entity.value != '' ? <Checkbox checked={props.value.indexOf(entity.value) > -1} /> : ''}
            <ListItemText primary={entity.name} />
          </MenuItem>
        ) : props.children ? props.children : <MenuItem>No options found</MenuItem>}
      </MuiSelect>
      {props.helperText ? <FormHelperText>{props.helperText}</FormHelperText> : ''}
    </FormControl>
  )
}
