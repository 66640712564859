import React, { Fragment } from "react"
import { List, ListItem, ListItemText } from "../lists"
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Switch from "../Switch"

export default function AlertGroupListItem(props) {

  function handleGroupSwitchChange(e) {
    props.handleGroupSwitchChange(props.alertGroup, props.userAlertGroup)
  }

  return(
    <Fragment>
      <ListItem button>
        <ListItemText
          className='measure-l measure-narrow'
          primary={props.alertGroup.name}
          secondary={props.alertGroup.description}
        />

        <ListItemSecondaryAction>
          <Switch
            edge="end"
            checked={props.checked}
            handleChange={handleGroupSwitchChange}
          />
        </ListItemSecondaryAction>

      </ListItem>
    </Fragment>
  )
}
