import React, { Fragment } from "react"
import Box from "../Box"
import GroupedTrials from "./GroupedTrials"
import { Table, TableRow, TableHead, TableCell, TableBody } from "../tables"
import StudyRow from "./StudyRow"
import Tabs from "../tabs/Tabs"
import Tab from "../tabs/Tab"
import StudiesTable from "./StudiesTable"

export default function StudiesDataContainer(props) {
  let trialsData = props.activeIndex == 0 ? props.trialsData : props.chosenTrialsData
  return (
    <Fragment>
      <Tabs centered value={props.activeIndex} onChange={props.handleTabClick} className='bb b--black-10'>
        <Tab label={`Trials`} />
        {/* {props.chosenProduct ? <Tab label={`${props.chosenProduct.short_name} Trials`} /> : ''} */}
      </Tabs>

      <Box className='mt3'>
        <StudiesTable
          trials={props.trialsData}
          dateColumn='Year'
          sector={props.sector}
        />
      </Box>
    </Fragment>
  )
}
