import React from "react"
import Box from "../../../Box"
import { TableRow, TableCell } from "../../../tables"
import SearchDropdown from "../../../SearchDropdown"
import { RaisedButton } from "../../../buttons"
import MuiIconButton from '@material-ui/core/IconButton';
import IndeterminateCheckBoxIcon from '@material-ui/icons/IndeterminateCheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Checkbox from '@material-ui/core/Checkbox';
import CircularProgress from '@material-ui/core/CircularProgress';

export default function Reviewer(props) {

  function handleSponsorCheckboxClick(e) {
    props.handleSponsorCheckboxClick(props.sponsor)
  }

  function handleCompanySearchChange(e, entityOption) {
    props.handleCompanySearchChange(entityOption.value, props.sponsor.id)
  }

  return(
    <TableRow>

      <TableCell padding='checkbox'>
        <MuiIconButton onClick={handleSponsorCheckboxClick} edge="start">
          {props.checkedSponsor == props.sponsor.id ? <CheckBoxIcon color='primary' style={{height: '22px', width: '22px'}} /> : <CheckBoxOutlineBlankIcon style={{height: '22px', width: '22px'}} />}
        </MuiIconButton>
      </TableCell>

      <TableCell>
        {props.sponsor.name}
      </TableCell>

      <TableCell>
        {props.checkedSponsor == props.sponsor.id ?
          <Box>
            <SearchDropdown
              value={props.companyQuery}
              name='company_id'
              options={props.companies}
              handleChange={handleCompanySearchChange}
            />

            {props.loading ?
              <CircularProgress />
            : ""}

          </Box>
        : ""}
      </TableCell>
    </TableRow>
  )
}
