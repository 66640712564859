import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import { TableRow, TableCell } from "../../tables"
import Link from "../../Link"
import Box from "../../Box"
import Switch from "../../Switch"

export default class ReviewerRow extends Component {
  constructor(props) {
    super(props)
    this.handleStatusChange = this.handleStatusChange.bind(this)
  }

  handleStatusChange(e) {
    this.props.handleStatusUpdate(this.props.abstract.abstract_review, this.props.abstract)
  }

  render() {
    return(
      <TableRow>
        <TableCell>{this.props.abstract.number}</TableCell>
        <TableCell>
          <Link href={`/admin/abstracts/${this.props.abstract.id}/extractor`} target="_blank">
            <Box>
              {this.props.abstract.title}
            </Box>
          </Link>
        </TableCell>
        <TableCell align='right'>{dateFormat(this.props.abstract.completed_on)}</TableCell>
        <TableCell align='right'>{displayInitials(this.props.abstract.first_name, this.props.abstract.last_name)}</TableCell>
        <TableCell align='right'>
          <Switch
            checked={this.props.abstract.abstract_review_id ? true : false}
            handleChange={this.handleStatusChange}
          />
        </TableCell>
      </TableRow>
    )
  }
}
