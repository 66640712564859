import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import update from "immutability-helper"
import Form from "./Form"


export default class NewContainer extends Component {
  constructor(props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
    this.handleSourceChange = this.handleSourceChange.bind(this)
    this.handleSaveClick = this.handleSaveClick.bind(this)
    this.handleChooseSourceClick = this.handleChooseSourceClick.bind(this)
    this.handleSourceRemovalClick = this.handleSourceRemovalClick.bind(this)
    this.handleSectorChange = this.handleSectorChange.bind(this)
    this.state = { companyStatement: this.props.company_statement, source: null, sector_id: this.props.sector_id, showSnackbar: false, snackbarText: '', openDialog: false }
  }

  componentDidMount() {
    let companyStatement = update(this.state.companyStatement, {
      user_id: { $set: this.props.current_user.id }
    })

    this.setState({
      companyStatement: companyStatement
    })
  }

  handleSectorChange(name, value) {
    let companyStatement = update(this.state.companyStatement, {
      sector_id: { $set: value }
    })

    this.setState({
      companyStatement: companyStatement
    })
  }

  handleChooseSourceClick() {
    this.setState({
      openDialog: !this.state.openDialog
    })
  }

  handleChange(evt) {
    let text = evt.editor.getData()
    let companyStatement = update(this.state.companyStatement, { statement: { $set: text } })

    this.setState({
      companyStatement: companyStatement
    })
  }

  handleSourceRemovalClick(e) {
    this.setState({
      source: null
    })
  }

  handleSourceChange(entity, sourceType) {
    if (sourceType == 'recently_used') {
      this.setState({
        source: entity
      })
    } else {
      $.get("/admin/sources/find_source", { source_type: sourceType, entity_id: entity.id }, (data) => {
        if (data.source) {
          let companyStatement = update(this.state.companyStatement, {
            source_id: { $set: data.source.source_id }
          })
          this.setState({
            source: data.source,
            companyStatement: companyStatement,
            openDialog: false
          })
        } else {
          $.post("/admin/sources/filter", { source: { kind: sourceType, entity_id: entity.id } }, (data) => {
            let companyStatement = update(this.state.companyStatement, {
              source_id: { $set: data.source.source_id }
            })
            this.setState({
              source: data.source,
              companyStatement: companyStatement,
              openDialog: false
            })
          })
        }
      }, 'json')
    }
  }

  handleSaveClick(e) {
    $.ajax({
      type: 'POST',
      url: `/admin/companies/${this.props.company.id}/statements`,
      data: {
        company_statement: this.state.companyStatement
      },
      dataType: 'json',
      success: (data) => {
        window.location.href = `/admin/companies/${this.props.company.id}/statements`
      },
      error: (data) => {
        this.setState({
          showSnackbar: true,
          snackbarText: 'There was an error creating the statement. Make sure a source has been selected'
        })
      },
      complete: (data) => {
        setTimeout(() => {
          this.setState({
            showSnackbar: false,
            snackbarText: ''
          })
        }, 4000)
      }
    })
  }

  render() {
    return(
      <Form
        companyStatement={this.state.companyStatement}
        source={this.state.source}
        openDialog={this.state.openDialog}
        sectorId={this.state.sector_id}
        sources={this.props.sources}
        sourceTypes={this.props.source_types}
        sectors={this.props.sectors}
        handleChooseSourceClick={this.handleChooseSourceClick}
        handleChange={this.handleChange}
        handleSourceRemovalClick={this.handleSourceRemovalClick}
        handleSourceChange={this.handleSourceChange}
        handleSaveClick={this.handleSaveClick}
        handleSectorChange={this.handleSectorChange}
      />
    )
  }
}
