import React, { Fragment } from "react"
import Paper from "../../Paper"
import { Table, TableRow, TableCell, TableBody, TableHead } from "../../tables"
import Box from "../../Box"

export default function Profile(props) {

  return(
    <Paper fullHeight>

      <Table>
        <TableBody>

          <TableRow>
            <TableCell borderRight fontWeight="bold" className='w-20'>510(k) Number</TableCell>
            <TableCell>{props.k_clearance.k_number}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell borderRight fontWeight="bold" className='w-20'>Device Name</TableCell>
            <TableCell>{props.k_clearance.device_name}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell borderRight fontWeight="bold" className='w-20'>Applicant</TableCell>
            <TableCell>{props.k_clearance.applicant}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell borderRight fontWeight="bold" className='w-20'>Applicant Contact</TableCell>
            <TableCell>{props.k_clearance.contact}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell borderRight fontWeight="bold" className='w-20'>Correspondent</TableCell>
            <TableCell>
              <Box>
                <Box>
                  {props.k_clearance.applicant}
                </Box>
                <Box>
                  {props.k_clearance.street1}
                </Box>
                <Box>
                  {`${props.k_clearance.city}, ${props.k_clearance.state} ${props.k_clearance.zip}`}
                </Box>

              </Box>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell borderRight fontWeight="bold" className='w-20'>Classification Product Code</TableCell>
            <TableCell>{props.k_clearance.product_code}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell borderRight fontWeight="bold" className='w-20'>Date Received</TableCell>
            <TableCell>{props.k_clearance.date_received}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell borderRight fontWeight="bold" className='w-20'>Decision Date</TableCell>
            <TableCell>{props.k_clearance.decision_date}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell borderRight fontWeight="bold" className='w-20'>Decision</TableCell>
            <TableCell>{props.k_clearance.decision}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell borderRight fontWeight="bold" className='w-20'>Regulation Medicality Specialty</TableCell>
            <TableCell>{props.k_clearance.class_advise_comm}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell borderRight fontWeight="bold" className='w-20'>510k Review Panel</TableCell>
            <TableCell>{props.k_clearance.review_advise_comm}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell borderRight fontWeight="bold" className='w-20'>Type</TableCell>
            <TableCell>{props.k_clearance.clearance_type}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell borderRight fontWeight="bold" className='w-20'>Reviewed by Third Party</TableCell>
            <TableCell>{props.k_clearance.third_party == 'N' ? 'No' : 'Yes'}</TableCell>
          </TableRow>
        </TableBody>
      </Table>

    </Paper>
  )
}
