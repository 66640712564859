import React from "react"
import DeleteButton from "../DeleteButton"
import { ListItem, ListItemText } from "../../lists"
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction"
import Switch from "../../Switch"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import Tooltip from "@material-ui/core/Tooltip"

export default function AssociatedProductListItem(props) {

  function handleUnassociateClick(e) {
    props.handleUnassociateClick(props.trialProduct)
  }

  function handleInvestigatedSwitchChange(e) {
    props.handleInvestigatedSwitchChange(props.trialProduct)
  }

  return (
    <ListItem button key={props.trialProduct.id} role={undefined}>
      
      <Tooltip title='Investigated device'>
        <ListItemIcon>
          <Switch
            edge="end"
            checked={props.trialProduct.investigated_device}
            handleChange={handleInvestigatedSwitchChange}
          />
        </ListItemIcon>
      </Tooltip>

      <ListItemText primary={props.trialProduct.product ? props.trialProduct.product.short_name : "N/A"} />

      <ListItemSecondaryAction>
        <DeleteButton onClick={handleUnassociateClick} />
      </ListItemSecondaryAction>

    </ListItem>
  )
}
