import React, { Component, Fragment } from "react"
import ProfileForm from "./ProfileForm"
import Paper from "../../Paper"
import Snackbar from "../../Snackbar"
import update from "immutability-helper"


class NewContainer extends Component {
  constructor(props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
    this.handleSaveClick = this.handleSaveClick.bind(this)
    this.state = { clinicalStatus: this.props.clinical_status, showSnackbar: false, snackbarText: '', activeTab: 'New Clinical Status' }
  }
  
  componentDidMount() {
    let clinicalStatus = update(this.state.clinicalStatus, { created_by: { $set: this.props.current_user.id }})
    this.setState({
      clinicalStatus: clinicalStatus
    })
  }
  
  handleTabClick(e) {
    e.preventDefault()
    this.setState({
      activeTab: e.target.text
    })
  }
  
  handleChange(e) {
    const name = e.currentTarget.name
    const value = e.currentTarget.value
    let clinicalStatus = update(this.state.clinicalStatus, { [name]: { $set: value } })
    this.setState({
      clinicalStatus: clinicalStatus
    })
    
  }
  
  handleSaveClick(e) {
    $.ajax({
      type: 'POST',
      url: `/admin/clinical_statuses`,
      data: {
        clinical_status: this.state.clinicalStatus
      },
      error: (data) => {
        this.setState({
          showSnackbar: true,
          snackbarText: 'Clinical status could not be updated. Please try again.'
        })
      },
      complete: (data) => {
        setTimeout(() => {
          this.setState({
            showSnackbar: false,
            snackbarText: ''
          })
        }, 4000)
      }
    })
  }
  
  render() {
    return(
      <Fragment>
        
        <Paper>
          
          
          <ProfileForm 
            clinicalStatus={this.state.clinicalStatus}
            handleChange={this.handleChange}
            handleSaveClick={this.handleSaveClick}
          />

        </Paper>
        
        <Snackbar 
          open={this.state.showSnackbar}
          message={this.state.snackbarText} 
        />
      </Fragment>
    )
  }
}


export default NewContainer
