import React from "react"
import { TableRow, TableCell } from "../../tables"
import Link from "../../Link"

function GrantRow(props) {

  return(
    <TableRow key={props.nihGrant.id}>
      <TableCell>{props.nihGrant.organization_name}</TableCell>
      <TableCell>
        <Link href={props.nihGrant.project_detail_url}>
          {props.nihGrant.project_title}
        </Link>
      </TableCell>
      {/* <TableCell>{nihGrant.abstract_text.substr(0, 140)}</TableCell> */}
      <TableCell>{props.nihGrant.fiscal_year}</TableCell>
      {/* <TableCell></TableCell> */}
    </TableRow>
  )
}

export default GrantRow