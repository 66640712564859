import React from "react"
import Header from "./Header"
import AlertDisplay from "./AlertDisplay"
import Box from "../Box"

export default function AlertDisplayContainer(props) {

  function groupBy(list, props) {
    return list.reduce((a, b) => {
       (a[b[props]] = a[b[props]] || []).push(b);
       return a;
    }, {});
  }

  return(
    <React.Fragment>
      {Object.keys(props.groupedAlerts).map((key) =>
        <Box key={key} mb={1}>
          <Header
            alert={props.groupedAlerts[key][0]}
          />
          {props.groupedAlerts[key].map((alert) =>
            <AlertDisplay
              key={alert.id}
              alert={alert}
            />
          )}
        </Box>
      )}
    </React.Fragment>
  )
}
