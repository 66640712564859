import React, { Component, Fragment } from "react"
import SourceMaterialsPanel from "./SourceMaterialsPanel"
import Paper from "../../Paper"
import Box from "../../Box"
import Flex from "../../Flex"
import Snackbar from "../../Snackbar"
import update from "immutability-helper"
import DataPanel from "./DataPanel"
import Grid from "@material-ui/core/Grid"
import { Tabs, Tab } from "../../tabs"
import { Button } from "../../buttons"
import Typography from "@material-ui/core/Typography"


export default class ExtractorContainer extends Component {
  constructor(props) {
    super(props)
    this.handleActiveIndexUpdate = this.handleActiveIndexUpdate.bind(this)
    this.handleSourceMaterialIndexUpdate = this.handleSourceMaterialIndexUpdate.bind(this)
    this.handleCheckboxSelect = this.handleCheckboxSelect.bind(this)
    this.handleTrialIndexUpdate = this.handleTrialIndexUpdate.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleEnrollmentSaveClick = this.handleEnrollmentSaveClick.bind(this)
    this.handleDateSaveClick = this.handleDateSaveClick.bind(this)
    this.handleCriteriaSaveClick = this.handleCriteriaSaveClick.bind(this)
    this.handleCriteriaDeleteClick = this.handleCriteriaDeleteClick.bind(this)
    this.handleEndpointSaveClick = this.handleEndpointSaveClick.bind(this)
    this.handleStatusSaveClick = this.handleStatusSaveClick.bind(this)
    this.handleTrialTextFieldSaveClick = this.handleTrialTextFieldSaveClick.bind(this)
    this.handleConclusionSaveClick = this.handleConclusionSaveClick.bind(this)
    this.handleTypeSaveClick = this.handleTypeSaveClick.bind(this)
    this.handleCompleteClick = this.handleCompleteClick.bind(this)
    this.handleStartClick = this.handleStartClick.bind(this)
    this.handleAddNoteClick = this.handleAddNoteClick.bind(this)
    this.handleNoteChange = this.handleNoteChange.bind(this)
    this.handleSaveNoteClick = this.handleSaveNoteClick.bind(this)
    this.handleDateDeleteClick = this.handleDateDeleteClick.bind(this)
    this.handleStatusDeleteClick = this.handleStatusDeleteClick.bind(this)
    this.state = { abstract: this.props.abstract, activeEnrollments: [], activeDates: [], activeCriterias: [], loading: false, activeIndex: null, sourceMaterialIndex: 0, trialIndex: (this.props.abstract_trials[0] ? this.props.abstract_trials[0].trial_id : null), showSnackbar: false, snackbarText: '', trial: this.props.abstract_trials[0], trialDates: this.props.trial_dates, trialCriterias: this.props.trial_criterias, trialPrimaryEndpoint: this.props.trial_primary_endpoint, trialSecondaryEndpoint: this.props.trial_secondary_endpoint, trialStatus: this.props.trial_status, trialCurrentEnrollment: this.props.trial_current_enrollment, trialTargetEnrollment: this.props.trial_target_enrollment, trialType: this.props.trial_type, trialTextFields: this.props.trial_text_fields, trialInvestigators: this.props.trial_investigators ? this.props.trial_investigators : [], abstractConclusion: this.props.abstract_conclusion, completed: false, started: false, abstractExtractorStatus: this.props.abstract_extractor_status, showNoteToEditor: true, writer_notes: '', scrapedNames: this.props.scraped_names }
  }

  handleNoteChange(e) {
    let value = e.target.value

    let abstract = update(this.state.abstract, {
      writer_notes: { $set: value }
    })

    this.setState({
      abstract: abstract
    })
  }

  handleSaveNoteClick(e) {
    $.ajax({
      type: 'PUT',
      url: `/admin/abstracts/${this.props.abstract.id}`,
      data: {
        abstract: {
          writer_notes: this.state.abstract.writer_notes
        }
      },
      dataType: 'json',
      success: (data) => {
        this.setState({
          abstract: data.abstract
        })
      }
    })
  }

  handleAddNoteClick(e) {
    this.setState({
      showNoteToEditor: !this.state.showNoteToEditor
    })
  }

  handleStartClick(e) {
    let date = new Date()
    $.ajax({
      type: 'POST',
      url: '/admin/abstract_extractor_statuses',
      data: {
        abstract_extractor_status: {
          abstract_id: this.props.abstract.id, started_on: date, user_id: this.props.current_user.id
        }
      },
      dataType: 'json',
      success: (data) => {
        this.setState({
          started: true,
          abstractExtractorStatus: data.abstract_extractor_status
        })
      }
    })
  }

  handleCompleteClick(e) {
    let date = new Date()
    $.ajax({
      type: 'PUT',
      url: `/admin/abstract_extractor_statuses/${this.state.abstractExtractorStatus.id}`,
      data: {
        abstract_extractor_status: {
          abstract_id: this.props.abstract.id, status: true, completed_on: date
        }
      },
      dataType: 'json',
      success: (data) => {
        this.setState({
          completed: true,
          abstractExtractorStatus: data.abstract_extractor_status
        })
      }
    })
  }

  handleTypeSaveClick(clinicalTypeId) {
    $.ajax({
      type: 'POST',
      url: `/admin/trial_types`,
      data: {
        trial_type: {
          trial_id: this.state.trial.trial_id, clinical_type_id: clinicalTypeId, created_by: this.props.current_user.id, source_id: this.props.source.id
        }
      },
      dataType: 'json',
      success: (data) => {
        this.setState({
          trialType: data.trial_type,
          showSnackbar: true,
          snackbarText: 'Type was added'
        })
      },
      error: (data) => {
        this.setState({
          showSnackbar: true,
          snackbarText: 'There was an error saving the type'
        })
      },
      complete: (data) => {
        setTimeout(() => {
          this.setState({
            showSnackbar: false,
            snackbarText: ''
          })
        }, 4000)
      }
    })
  }

  handleEnrollmentSaveClick(enrollmentType, date, enrollment) {
    if (enrollment) {
      $.ajax({
        type: 'POST',
        url: `/admin/trial_${enrollmentType}s`,
        data: {
          [`trial_${enrollmentType}`]: {
            trial_id: this.state.trial.trial_id, enrollment: enrollment, date: date, source_id: this.props.source.id
          }
        },
        dataType: 'json',
        success: (data) => {
          this.setState({
            showSnackbar: true,
            snackbarText: 'Enrollment was added'
          })
        },
        error: (data) => {
          this.setState({
            showSnackbar: true,
            snackbarText: 'There was an error saving the enrollment'
          })
        },
        complete: (data) => {
          setTimeout(() => {
            this.setState({
              showSnackbar: false,
              snackbarText: ''
            })
          }, 4000)
        }
      })
    }
  }

  handleDateDeleteClick(dateName) {
    let trialDate = this.state.trialDates.filter(trialDate => trialDate.date_name == dateName)[0]

    if (trialDate) {
      $.ajax({
        type: 'PUT',
        url: `/admin/trial_dates/${trialDate.id}`,
        data: {
          trial_date: {
            deleted: true, deleted_by: this.props.current_user.id
          }
        },
        dataType: 'json',
        success: (data) => {
          let index = this.state.trialDates.indexOf(trialDate)
          let trialDates = update(this.state.trialDates, {
            $splice: [[index, 1]]
          })
          this.setState({
            trialDates: trialDates,
            showSnackbar: true,
            snackbarText: 'Date was removed'
          })
        },
        error: (data) => {
          this.setState({
            showSnackbar: true,
            snackbarText: 'There was an error removing the date'
          })
        },
        complete: (data) => {
          setTimeout(() => {
            this.setState({
              showSnackbar: false,
              snackbarText: ''
            })
          }, 4000)
        }
      })
    }
  }

  handleDateSaveClick(dateName, date, dateType) {
    let trialDate = this.state.trialDates.filter(trialDate => trialDate.date_name == dateName)[0]
    if (date) {
      if (trialDate) {
        $.ajax({
          type: 'PUT',
          url: `/admin/trial_dates/${trialDate.id}`,
          data: {
            trial_date: {
              date_value: date, date_name: dateName, date_type: dateType
            }
          },
          dataType: 'json',
          success: (data) => {
            let index = this.state.trialDates.indexOf(trialDate)
            let trialDates = update(this.state.trialDates, {
              [index]: {
                $set: data.trial_date
              }
            })
            this.setState({
              trialDates: trialDates,
              showSnackbar: true,
              snackbarText: 'Date was added'
            })
          },
          error: (data) => {
            this.setState({
              showSnackbar: true,
              snackbarText: 'There was an error saving the date'
            })
          },
          complete: (data) => {
            setTimeout(() => {
              this.setState({
                showSnackbar: false,
                snackbarText: ''
              })
            }, 4000)
          }
        })
      } else {
        $.ajax({
          type: 'POST',
          url: `/admin/trial_dates`,
          data: {
            trial_date: {
              trial_id: this.state.trial.trial_id, date_name: dateName, date_value: date, date_type: dateType, source_id: this.props.source.id, created_by: this.props.current_user.id
            }
          },
          dataType: 'json',
          success: (data) => {
            let trialDates = update(this.state.trialDates, {
              $push: [data.trial_date]
            })
            this.setState({
              trialDates: trialDates,
              showSnackbar: true,
              snackbarText: 'Date was added'
            })
          },
          error: (data) => {
            console.log(data)
            this.setState({
              showSnackbar: true,
              snackbarText: 'There was an error saving the date'
            })
          },
          complete: (data) => {
            setTimeout(() => {
              this.setState({
                showSnackbar: false,
                snackbarText: ''
              })
            }, 4000)
          }
        })
      }
    }
  }

  handleCriteriaDeleteClick(criteriaName) {
    let trialCriteria = this.state.trialCriterias.filter(trialCriteria => trialCriteria.criteria_name == criteriaName)[0]

    if (trialCriteria) {
      $.ajax({
        type: 'PUT',
        url: `/admin/trial_criterias/${trialCriteria.id}`,
        data: {
          trial_criteria: {
            deleted: true, deleted_by: this.props.current_user.id
          }
        },
        dataType: 'json',
        success: (data) => {
          let index = this.state.trialCriterias.indexOf(trialCriteria)
          console.log(index)
          let trialCriterias = update(this.state.trialCriterias, {
            $splice: [[index,1]]
          })

          console.log(trialCriterias)

          this.setState({
            trialCriterias: trialCriterias,
            showSnackbar: true,
            snackbarText: 'Criteria was removed'
          })
        },
        error: (data) => {
          this.setState({
            showSnackbar: true,
            snackbarText: 'There was an error removing the criteria'
          })
        },
        complete: (data) => {
          setTimeout(() => {
            this.setState({
              showSnackbar: false,
              snackbarText: ''
            })
          }, 4000)
        }
      })
    }
  }

  handleCriteriaSaveClick(criteriaName, criteria) {
    let trialCriteria = this.state.trialCriterias.filter(trialCriteria => trialCriteria.criteria_name == criteriaName)[0]

    if (criteria) {
      if (trialCriteria) {
        $.ajax({
          type: 'PUT',
          url: `/admin/trial_criterias/${trialCriteria.id}`,
          data: {
            trial_criteria: {
              criteria_name: criteriaName, criteria_value: criteria, created_by: this.props.current_user.id, source_id: this.props.source.id
            }
          },
          dataType: 'json',
          success: (data) => {
            let index = this.state.trialCriterias.indexOf(trialCriteria)
            let trialCriterias = update(this.state.trialCriterias, {
              [index]: {
                $set: data.trial_criteria
              }
            })
            this.setState({
              trialCriterias: trialCriterias,
              showSnackbar: true,
              snackbarText: 'Criteria was updated'
            })
          },
          error: (data) => {
            this.setState({
              showSnackbar: true,
              snackbarText: 'There was an error saving the criteria'
            })
          },
          complete: (data) => {
            setTimeout(() => {
              this.setState({
                showSnackbar: false,
                snackbarText: ''
              })
            }, 4000)
          }
        })
      } else {
        $.ajax({
          type: 'POST',
          url: "/admin/trial_criterias",
          data: {
            trial_criteria: {
              trial_id: this.state.trial.trial_id, criteria_name: criteriaName, criteria_value: criteria, created_by: this.props.current_user.id, source_id: this.props.source.id
            }
          },
          dataType: 'json',
          success: (data) => {
            let trialCriterias = update(this.state.trialCriterias, {
              $push: [data.trial_criteria]
            })
            this.setState({
              trialCriterias: trialCriterias,
              showSnackbar: true,
              snackbarText: 'Criteria was added'
            })
          },
          error: (data) => {
            this.setState({
              showSnackbar: true,
              snackbarText: 'There was an error saving the criteria'
            })
          },
          complete: (data) => {
            setTimeout(() => {
              this.setState({
                showSnackbar: false,
                snackbarText: ''
              })
            }, 4000)
          }
        })
      }
    }
  }

  handleEndpointSaveClick(endpointName, endpoint) {
    // let trialEndpoint = this.state.trialEndpoints.filter(trialEndpoint => trialEndpoint.outcome_name == endpointName)[0]

    let modelName = `trial_${endpointName}`
    let stateName = camelize(modelName.replace("outcome", "endpoint"))

    if (endpoint) {
      if (this.state[stateName]) {
        $.ajax({
          type: 'PUT',
          url: `/admin/trial_${endpointName}s/${this.state[stateName].id}`,
          data: {
            [modelName]: {
              text: endpoint, user_id: this.props.current_user.id, source_id: this.props.source.id
            }
          },
          dataType: 'json',
          success: (data) => {
            let trialEndpoint = update(this.state[stateName], {
              text: {
                $set: data.trial_endpoint.text
              }
            })
            this.setState({
              [stateName]: trialEndpoint,
              showSnackbar: true,
              snackbarText: 'Endpoint was updated'
            })
          },
          error: (data) => {
            this.setState({
              showSnackbar: true,
              snackbarText: 'There was an error saving the endpoint'
            })
          },
          complete: (data) => {
            setTimeout(() => {
              this.setState({
                showSnackbar: false,
                snackbarText: ''
              })
            }, 4000)
          }
        })
      } else {
        $.ajax({
          type: 'POST',
          url: `/admin/trial_${endpointName}s`,
          data: {
            [modelName]: {
              trial_id: this.state.trial.trial_id, text: endpoint, user_id: this.props.current_user.id, source_id: this.props.source.id
            }
          },
          dataType: 'json',
          success: (data) => {
            this.setState({
              [stateName]: data.trial_endpoint,
              showSnackbar: true,
              snackbarText: 'Endpoint was added'
            })
          },
          error: (data) => {
            this.setState({
              showSnackbar: true,
              snackbarText: 'There was an error saving the endpoint'
            })
          },
          complete: (data) => {
            setTimeout(() => {
              this.setState({
                showSnackbar: false,
                snackbarText: ''
              })
            }, 4000)
          }
        })
      }
    }
  }

  handleTrialTextFieldSaveClick(field, text) {
    let trialTextField = this.state.trialTextFields.filter(trialTextField => trialTextField.text_field_name == field)[0]

    if (text) {
      if (trialTextField) {
        $.ajax({
          type: 'PUT',
          url: `/admin/trial_text_fields/${trialTextField.id}`,
          data: {
            trial_text_field: {
              text_field_name: field, text_field_value: text
            }
          },
          dataType: 'json',
          success: (data) => {
            let index = this.state.trialTextFields.indexOf(trialTextField)
            let trialTextFields = update(this.state.trialTextFields, {
              [index]: {
                $set: data.trial_text_field
              }
            })
            this.setState({
              trialTextFields: trialTextFields,
              showSnackbar: true,
              snackbarText: `${field} was updated`
            })
          },
          error: (data) => {
            this.setState({
              showSnackbar: true,
              snackbarText: 'There was an error saving the text field'
            })
          },
          complete: (data) => {
            setTimeout(() => {
              this.setState({
                showSnackbar: false,
                snackbarText: ''
              })
            }, 4000)
          }
        })
      } else {
        $.ajax({
          type: 'POST',
          url: "/admin/trial_text_fields",
          data: {
            trial_text_field: {
              trial_id: this.state.trial.trial_id, text_field_name: field, text_field_value: text, source_id: this.props.source.id, created_by: this.props.current_user.id
            }
          },
          dataType: 'json',
          success: (data) => {
            let trialTextFields = update(this.state.trialTextFields, {
              $push: [data.trial_text_field]
            })
            this.setState({
              trialTextFields: trialTextFields,
              showSnackbar: true,
              snackbarText: `${field} was added`
            })
          },
          error: (data) => {
            this.setState({
              showSnackbar: true,
              snackbarText: 'There was an error saving the text field'
            })
          },
          complete: (data) => {
            setTimeout(() => {
              this.setState({
                showSnackbar: false,
                snackbarText: ''
              })
            }, 4000)
          }
        })
      }
    }
  }

  handleStatusDeleteClick(clinical_status_id) {
    if (this.state.trialStatus) {
      $.ajax({
        type: 'PUT',
        url: `/admin/trial_statuses/${this.state.trialStatus.id}`,
        data: {
          trial_status: {
            deleted: true, deleted_by: this.props.current_user.id
          }
        },
        dataType: 'json',
        success: (data) => {
          this.setState({
            trialStatus: null,
            showSnackbar: true,
            snackbarText: 'Status was removed'
          })
        },
        error: (data) => {
          this.setState({
            showSnackbar: true,
            snackbarText: 'There was an error saving the status'
          })
        },
        complete: (data) => {
          setTimeout(() => {
            this.setState({
              showSnackbar: false,
              snackbarText: ''
            })
          }, 4000)
        }
      })
    }
  }

  handleStatusSaveClick(clinical_status_id) {
    if (clinical_status_id) {
      if (this.state.trialStatus) {
        $.ajax({
          type: 'PUT',
          url: `/admin/trial_statuses/${this.state.trialStatus.id}`,
          data: {
            trial_status: {
              clinical_status_id: clinical_status_id, source_id: this.props.source.id, updated_by: this.props.current_user.id
            }
          },
          dataType: 'json',
          success: (data) => {
            this.setState({
              trialStatus: data.trial_status,
              showSnackbar: true,
              snackbarText: 'Status was added'
            })
          },
          error: (data) => {
            this.setState({
              showSnackbar: true,
              snackbarText: 'There was an error saving the status'
            })
          },
          complete: (data) => {
            setTimeout(() => {
              this.setState({
                showSnackbar: false,
                snackbarText: ''
              })
            }, 4000)
          }
        })
      } else {
        $.ajax({
          type: 'POST',
          url: "/admin/trial_statuses",
          data: {
            trial_status: {
              trial_id: this.state.trial.trial_id, clinical_status_id: clinical_status_id, source_id: this.props.source.id, created_by: this.props.current_user.id
            }
          },
          dataType: 'json',
          success: (data) => {
            this.setState({
              trialStatus: data.trial_status,
              showSnackbar: true,
              snackbarText: 'Status was added'
            })
          },
          error: (data) => {
            this.setState({
              showSnackbar: true,
              snackbarText: 'There was an error saving the status'
            })
          },
          complete: (data) => {
            setTimeout(() => {
              this.setState({
                showSnackbar: false,
                snackbarText: ''
              })
            }, 4000)
          }
        })
      }
    }
  }

  handleConclusionSaveClick(conclusion) {
    if (this.state.abstractConclusion) {
      $.ajax({
        type: 'PUT',
        url: `/admin/abstract_conclusions/${this.state.abstractConclusion.id}`,
        data: {
          abstract_conclusion:{
            conclusion: conclusion
          }
        },
        dataType: 'json',
        success: (data) => {
          this.setState({
            trialStatus: data.trial_status,
            showSnackbar: true,
            snackbarText: 'Conclusion was updated'
          })
        },
        error: (data) => {
          this.setState({
            showSnackbar: true,
            snackbarText: 'There was an error saving the conclusion'
          })
        },
        complete: (data) => {
          setTimeout(() => {
            this.setState({
              showSnackbar: false,
              snackbarText: ''
            })
          }, 4000)
        }
      })
    } else {
      $.ajax({
        type: 'POST',
        url: `/admin/abstract_conclusions`,
        data: {
          abstract_conclusion:{
            abstract_id: this.props.abstract.id, conclusion: conclusion, user_id: this.props.current_user.id
          }
        },
        dataType: 'json',
        success: (data) => {
          this.setState({
            abstractConclusion: data.abstract_conclusion,
            showSnackbar: true,
            snackbarText: 'Conclusion was added'
          })
        },
        error: (data) => {
          this.setState({
            showSnackbar: true,
            snackbarText: 'There was an error saving the conclusion'
          })
        },
        complete: (data) => {
          setTimeout(() => {
            this.setState({
              showSnackbar: false,
              snackbarText: ''
            })
          }, 4000)
        }
      })
    }
  }

  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  handleActiveIndexUpdate(newValue) {
    this.setState({
      activeIndex: newValue
    })
  }

  handleSourceMaterialIndexUpdate(e, newValue) {
    this.setState({
      sourceMaterialIndex: newValue
    })
  }

  handleCheckboxSelect(entity, type) {
    const index = this.state[type].indexOf(entity.id)
    const activeEntities = index == -1 ? update(this.state[type], { $push: [entity.id] }) : update(this.state[type], { $splice: [[index, 1]] })

    this.setState({
      [type]: activeEntities
    })
  }

  handleTrialIndexUpdate(e, newValue) {
    let trial = this.props.abstract_trials.filter(trial => trial.trial_id == newValue)[0]

    $.ajax({
      type: 'GET',
      url: `/admin/trials/${newValue}/data_for_abstract_extractor`,
      data: {
        source_id: this.props.source.id
      },
      dataType: 'json',
      success: (data) => {
        this.setState({
          trialDates: data.trial_dates,
          trialTextFields: data.trial_text_fields,
          trialCriterias: data.trial_criterias,
          trialEndpoints: data.trial_endpoints,
          trialStatus: data.trial_status,
          trialType: data.trial_type,
          trialInvestigators: data.trial_investigators,
          trialCurrentEnrollment: data.trial_current_enrollment,
          trialTargetEnrollment: data.trial_target_enrollment,
          trial: trial,
          trialIndex: newValue
        })
      }
    })
  }

  render() {
    let searchWords = ""

    switch (this.state.activeIndex) {
      case 1:
        searchWords = this.props.suggested_enrollments.map((suggestedEnrollment) => suggestedEnrollment.text || suggestedEnrollment.hit)
        break;
      case 2:
        searchWords = this.props.dates.map((date) => date.text)
        break;
      case 3:
        searchWords = this.props.criterias.map((criteria) => criteria.hit)
        break;
      case 4:
        searchWords = this.props.endpoints.map((endpoint) => endpoint.hit)
        break;
      case 6:
        searchWords = this.props.scraped_names.map((name) => name.text.length > 1 ? name.text : '')
        break
    }

    return(
      <Fragment>

        <Grid container component={Box} mb={3}>
          <Grid item xs={12}>
            <Paper>
              <Box>
                {`Presentation Date: ${this.props.abstract.date}`}
              </Box>
              <Flex justifyContent='center' mb={3}>
                <Box>
                  {this.state.abstractExtractorStatus && this.state.abstractExtractorStatus.status ?
                    <Button variant='contained' onClick={this.handleCompleteClick}>
                      Completed
                    </Button>
                  :
                    this.state.abstractExtractorStatus && this.state.abstractExtractorStatus.started_on ?
                      <Button variant='contained' onClick={this.handleCompleteClick}>
                        Mark Complete
                      </Button>
                    :
                      <Button variant='contained' color='default' onClick={this.handleStartClick}>
                        Start
                      </Button>
                  }
                </Box>

              </Flex>

              <Box className='bb b--black-10' mb={1}>
                <Flex alignItems='center'>
                  <Box>
                    <Typography variant="h6">
                      Trials
                    </Typography>
                  </Box>

                  {this.props.current_user.id == 3 || this.props.current_user.id == 6 || this.props.current_user.id == 1 ?
                    <Box ml={3}>
                      <Button href={`/admin/trials/new?source_type=abstract&source_id=${this.props.source.source_id}`} component='a' target="_blank">
                        Add a trial
                      </Button>
                    </Box>
                  : ''}
                </Flex>
              </Box>

              <Box>
                <Tabs variant="fullWidth" value={this.state.trialIndex} onChange={this.handleTrialIndexUpdate} className='bb b--black-10' aria-label="trial tabs">
                  {this.props.abstract_trials.map((trial) =>
                    <Tab
                      key={trial.trial_id}
                      label={`${trial.acronym || trial.short_name}${trial.nct_id ? ' - ' + trial.nct_id : ''}`}
                      value={trial.trial_id}
                    />
                  )}
                </Tabs>
              </Box>
            </Paper>
          </Grid>
        </Grid>

        <Grid container spacing={2}>

          <Grid item xs={7}>

            <Box>
              <SourceMaterialsPanel
                abstract={this.props.abstract}
                files={this.props.files}
                abstractTexts={this.props.abstract_texts}
                activeIndex={this.state.sourceMaterialIndex}
                handleSourceMaterialIndexUpdate={this.handleSourceMaterialIndexUpdate}
                searchWords={searchWords}
              />
            </Box>
          </Grid>

          <Grid item xs={5}>
            <Box>
              <DataPanel
                key={this.state.trial ? this.state.trial.id : ''}
                abstract={this.state.abstract}
                trial={this.state.trial}
                trialDates={this.state.trialDates}
                trialCriterias={this.state.trialCriterias}
                trialPrimaryEndpoint={this.state.trialPrimaryEndpoint}
                trialSecondaryEndpoint={this.state.trialSecondaryEndpoint}
                trialCurrentEnrollment={this.state.trialCurrentEnrollment}
                trialTargetEnrollment={this.state.trialTargetEnrollment}
                trialStatus={this.state.trialStatus}
                trialType={this.state.trialType}
                writerNotes={this.state.writer_notes}
                trialTextFields={this.state.trialTextFields}
                trialInvestigators={this.state.trialInvestigators}
                showNoteToEditor={this.state.showNoteToEditor}
                abstractConclusion={this.state.abstractConclusion}
                currentUser={this.props.current_user}
                statuses={this.props.statuses}
                types={this.props.types}
                scrapedNames={this.state.scrapedNames}
                analysis={this.props.analysis}
                handleChange={this.handleChange}
                activeIndex={this.state.activeIndex}
                handleActiveIndexUpdate={this.handleActiveIndexUpdate}
                handleEnrollmentSaveClick={this.handleEnrollmentSaveClick}
                handleDateSaveClick={this.handleDateSaveClick}
                handleCriteriaSaveClick={this.handleCriteriaSaveClick}
                handleCriteriaDeleteClick={this.handleCriteriaDeleteClick}
                handleEndpointSaveClick={this.handleEndpointSaveClick}
                handleStatusSaveClick={this.handleStatusSaveClick}
                handleTrialTextFieldSaveClick={this.handleTrialTextFieldSaveClick}
                handleConclusionSaveClick={this.handleConclusionSaveClick}
                handleTypeSaveClick={this.handleTypeSaveClick}
                handleAddNoteClick={this.handleAddNoteClick}
                handleNoteChange={this.handleNoteChange}
                handleSaveNoteClick={this.handleSaveNoteClick}
                handleDateDeleteClick={this.handleDateDeleteClick}
                handleStatusDeleteClick={this.handleStatusDeleteClick}
              />
            </Box>
          </Grid>

        </Grid>

        <Snackbar
          open={this.state.showSnackbar}
          handleUndoClick={this.handleUndoClick}
          message={this.state.snackbarText}
        />
      </Fragment>
    )
  }
}
