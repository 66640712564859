import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Paper from "../../Paper"
import Box from "../../Box"
import { Table, TableRow, TableBody, TableCell, TableHead } from "../../tables"
import Link from "../../Link"

export default class Sectors extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    return(
      <Fragment>
        <Paper fullHeight>
          <Box height="100%">

            <Box overflow="auto" height="90%">
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Sector</TableCell>
                    <TableCell>Kind</TableCell>
                    <TableCell>Status</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {this.props.sectors.map((sector) =>
                    <TableRow key={sector.id}>
                      <TableCell>
                        <Link href={`/admin/sectors/${sector.abbr}/edit`}>
                          {sector.abbr}
                        </Link>
                      </TableCell>

                      <TableCell>
                        {sector.monitor_product ? "Monitor" : "Pipeline"}
                      </TableCell>

                      <TableCell>
                        {sector.live ? "Live" : "Staged"}
                      </TableCell>

                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </Box>
          </Box>
        </Paper>
      </Fragment>
    )
  }
}
