import React from 'react'
import { TableCell, TableRow } from '../../../tables'
import DeleteButton from "../../DeleteButton"
import CheckCircleOutline from '@material-ui/icons/CheckCircleOutline';
import Link from "../../../Link"
import IconButton from '../../../IconButton';

export default function ApprovalRow(props) {

  function handleDeleteClick(e) {
    props.handleDeleteClick(props.approvals[0])
  }

  function handleApprovalCheckboxClick(e) {
    props.handleApprovalCheckboxClick(props.approvals[0])
  }

  function handleReviewedClick(e) {
    props.handleReviewedClick(props.approvals[0])
  }

  return (
    <TableRow selected={props.approval.reviewed}>
      <TableCell>
        <Link href={`/admin/device_approvals/${props.approval.id}/associator`} target="_blank">
          {props.approval.regulatory_id}
        </Link>
      </TableCell>
      <TableCell>
        {props.approval.country_abbr}
      </TableCell>
      <TableCell>
        <Link href={`/admin/device_approvals/${props.approval.id}/associator`} target="_blank">
          {props.approval.device_name}
        </Link>
      </TableCell>
      <TableCell className="measure-wide">{props.approval.description}</TableCell>
      <TableCell>
        {/* {props.pmaApprovals.map((pmaApproval) => {
          return(
            <Box mb={1} key={pmaApproval.product_fda_pma_approval_sector_id}>
              <FilterChip
                name={pmaApproval.abbr}
                handleDelete={() => { props.handleSectorDelete(pmaApproval.product_fda_pma_approval_sector_id) }}
              />
            </Box>
          )
        })} */}
      </TableCell>
      <TableCell>{props.approval.manufacturer}</TableCell>
      <TableCell>{props.approval.date}</TableCell>
      {/* <TableCell>
        <IconButton tooltip="Mark as reviewed">
          <CheckCircleOutline color={approval.reviewed ? 'primary' : 'default'} onClick={handleReviewedClick} />
        </IconButton>
      </TableCell> */}
      <TableCell>
        <DeleteButton tooltip="Delete the product association" onClick={handleDeleteClick} />
      </TableCell>
    </TableRow>
  )
}
