import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Paper from "../../Paper"
import Box from "../../Box"
import { RaisedButton, Button } from "../../buttons"
import { List } from "../../lists"
import SectorListItem from "../../sectors/SectorListItem"

export default class ControlPanel extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    return(
      <Fragment>

        <Paper>

          {this.props.handleAssociateClick ?
            <Box width={1} mb={3}>
              <RaisedButton handleClick={this.props.handleAssociateClick}>
                Associate
              </RaisedButton>
            </Box>
          : ""}

          {this.props.handleUnassociateClick ?
            <Box width={1} mb={3}>
              <RaisedButton handleClick={this.props.handleUnassociateClick}>
                Unassociate
              </RaisedButton>
            </Box>
          : ""}

          {this.props.handleCompleteClick ?
            <Box width={1}>

              <Button variant={this.props.completed ? 'contained' : 'outlined'} color={this.props.completed ? 'primary' : 'default'} onClick={this.props.handleCompleteClick}>
                {this.props.completed ? 'Completed' : 'Mark Complete'}
              </Button>

            </Box>
          : ''}

          <Box mt={4}>
            <List>
              {this.props.sectors.map((sector) =>
                <SectorListItem
                  key={sector.id}
                  sector={sector}
                  checked={this.props.publicationSectors.filter((publicationSector) => publicationSector.sector_id == sector.id)[0] ? true : false}
                  handleCheckboxSelect={this.props.handleSectorCheckboxSelect}
                />
              )}
            </List>
          </Box>

        </Paper>

      </Fragment>
    )
  }
}
