import React from 'react';
import ItemDisplay from './ItemDisplay';
import { List } from "../lists"
import Box from "../Box"

const IndexResults = ({ patents, resultsCount, sector }) => {
  return (
    <React.Fragment>
      <Box height="100%">
        <List>
          {patents.map((patent) => (
            <ItemDisplay 
              key={patent.id} 
              patent={patent._source} 
              patentParent={patent}
              sector={sector}
            />
          ))}
        </List>
      </Box>
    </React.Fragment>
  );
};

export default IndexResults;
