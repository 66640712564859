import React, {Component} from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"

class DeviseAlert extends Component {
  constructor(props) {
    super(props)
  }
  
  render() {
    return(
      <div>
        <h4 className='mv1'>{this.props.header}</h4>
        <ul className='mv1'>
          {this.props.items.map((item) =>
            <li key={item}>{item}</li>
          )}
        </ul>
      </div>
    );
  }
}

export default DeviseAlert
