import React, { Fragment } from "react"
import PropTypes from "prop-types"
import Flex from "../../Flex"
import Box from "../../Box"
import TextInput from "../../TextInput"
import DatePicker from "../../DatePicker"
import { Button, RaisedButton } from "../../buttons"
import Select from "../../Select"
import { List, ListItem, ListItemText } from "../../lists"
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Switch from "../../Switch"
import Typography from '@material-ui/core/Typography';
import ButtonLayout from "../ButtonLayout"
// import Grid from '@material-ui/core/Grid';


export default function Form(props) {
  return(
    <Fragment>

      <Box mb={3} width={1/2}>
        <TextInput
          name='first_name'
          label='First name'
          value={props.user.first_name}
          onChange={props.handleChange}
          fullWidth
        />
      </Box>

      <Box mb={3} width={1/2}>
        <TextInput
          name='last_name'
          label='Last name'
          value={props.user.last_name}
          onChange={props.handleChange}
          fullWidth
        />
      </Box>

      <Box mb={3} width={1/2}>
        <TextInput
          name='email'
          label='Email'
          value={props.user.email}
          onChange={props.handleChange}
          fullWidth
        />
      </Box>

      <Box mb={3} width={1/2}>
        <Select
          label='Company'
          entities={props.userCompanies}
          value={props.user.user_company_id}
          handleChange={props.handleUserCompanyChange}
        />
      </Box>


      <Box width={1/4}>
        <DatePicker
          name='expiration_date'
          label='Expiration Date'
          handleChange={props.handleDateChange}
          value={props.user.expiration_date ? props.user.expiration_date.split('T')[0] : ''}
          className='w-100'
        />
      </Box>

      <Box mt={3}>
        <Typography variant="h6" component="h6">
          Sectors
        </Typography>

        <Box mt={2} width={1/4}>
          <List className='w-100'>
            {props.sectors.map((sector) =>
              <ListItem key={sector.id} button divider>
                <ListItemText primary={sector.abbr} />

                <ListItemSecondaryAction>
                  <Switch
                    edge="end"
                    checked={props.userSectors.filter(userSector => userSector.sector_id == sector.id)[0] ? true : false}
                    handleChange={() => props.handleSwitchChange(sector.id)}
                  />
                </ListItemSecondaryAction>
              </ListItem>
            )}
          </List>
        </Box>
      </Box>

      <ButtonLayout>
        <RaisedButton handleClick={props.handleSaveClick}>
          {props.buttonText}
        </RaisedButton>
      </ButtonLayout>
    </Fragment>
  )
}
