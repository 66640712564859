import React, { Component, Fragment } from "react"
import StudiesChartContainer from "./StudiesChartContainer"
import StudiesDataContainer from "./StudiesDataContainer"
import Box from "../Box"
import CircularProgress from '@material-ui/core/CircularProgress';

export default class StudiesAnalytics extends Component {
  constructor(props) {
    super(props)
    this.handleFilterChange = this.handleFilterChange.bind(this)
    this.handleTabClick = this.handleTabClick.bind(this)
    this.state = { chartData: '', products: '', chosenProduct: '', categories: '', trialsData: '', chosenProduct: '', chosenTrialsData: [], chartReloadKey: '', activeIndex: 0, loading: true }
  }

  componentDidMount() {
    $.get(`/sectors/${this.props.sector.abbr}/products/${this.props.product.id}/studies_analytics`, {}, (data) => {
      this.setState({
        chartData: data.chart_data,
        categories: data.categories,
        trialsData: data.trials_data,
        products: data.products,
        loading: false
      })
    }, 'json')
  }

  handleTabClick(e, newValue) {
    this.setState({
      activeIndex: newValue
    })
  }

  handleFilterChange(name, value) {
    $.get(`/sectors/${this.props.sector.abbr}/products/${this.props.product.id}/studies_analytics`, { chosen_product: value }, (data) => {
      this.setState({
        chartData: data.chart_data,
        chosenTrialsData: data.chosen_trial_data,
        categories: data.categories,
        trialsData: data.trials_data,
        chosenTrialData: data.chosen_trial_data,
        chosenProduct: data.chosen_product,
        chartReloadKey: Math.random()
      })
    }, 'json')
  }

  render() {
    if (this.state.loading) {
      return (
        <Box>
          <Box width="10%" mx="auto">
            <CircularProgress />
          </Box>
        </Box>
      )
    } else {
      return (
        <Fragment>

          {/* <Box mb={3}>
            <StudiesFilters
              products={this.state.products}
              chosenProduct={this.state.chosenProduct}
              handleFilterChange={this.handleFilterChange}
            />
          </Box> */}

          <Box>
            <StudiesChartContainer
              product={this.props.product}
              chosenProduct={this.state.chosenProduct}
              chartData={this.state.chartData}
              categories={this.state.categories}
              groupOption={this.state.groupOption}
              chartReloadKey={this.state.chartReloadKey}
            />
          </Box>

          <Box my={3}>
            <StudiesDataContainer
              product={this.props.product}
              chosenProduct={this.state.chosenProduct}
              trialsData={this.state.trialsData}
              chosenTrialsData={this.state.chosenTrialsData}
              activeIndex={this.state.activeIndex}
              handleTabClick={this.handleTabClick}
              sector={this.props.sector}
            />
          </Box>
        </Fragment>
      )
    }
  }
}
