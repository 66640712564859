import React, { Fragment, useState } from "react"
import { List, ListItem, ListItemText, ListItemLink } from "../../lists"
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ExpandLess from '../../ExpandLess';
import ExpandMore from '../../ExpandMore';
import Collapse from '@material-ui/core/Collapse';
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction"

export default function SearchResultsList(props) {
  const [open, setOpen] = useState(false)

  return(
    <List>
      {props.news.length > 0 ?
        props.news.map((newsItem) =>
          <ListItemLink key={newsItem.id} divider button href={newsItem.url}>
            <ListItemText
              primary={newsItem.title}
              secondary={newsItem.publication}
            />
          </ListItemLink>
      ) : 'No news items found'}
    </List>
  )
}
