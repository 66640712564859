import React, { Fragment, Component } from "react"
import RaisedButton from "../buttons/RaisedButton"
import Box from "../Box"
import TextInput from "../TextInput"
import Snackbar from "../Snackbar"

export default class ProfileForm extends Component {
  constructor(props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
    this.handleProfileUpdateClick = this.handleProfileUpdateClick.bind(this)
    this.state = { email: this.props.user.email, role: this.props.user.role, name: this.props.user.name, department: this.props.user.department, showSnackbar: false, snackbarText: '' }
  }

  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  handleProfileUpdateClick(e) {
    $.ajax({
      type: 'PUT',
      url: `/users/${this.props.user.id}`,
      data: {
        user: {
          role: this.state.role, name: this.state.name, department: this.state.department
        }
      },
      dataType: 'json',
      success: (data) => {
        this.setState({
          showSnackbar: true,
          snackbarText: 'Your profile has been updated'
        })
      },
      error: (data) => {
        console.log(data.responseText)
        this.setState({
          showSnackbar: true,
          snackbarText: 'Your profile could not be updated'
        })
      },
      complete: (data) => {
        setTimeout(() => {
          this.setState({
            showSnackbar: false,
            snackbarText: ''
          })
        }, 4000)
      }
    })
  }

  render() {
    return (
      <Fragment>
        <Box mt={4}>
          <Box mb={3}>
            <TextInput
              label='Email'
              name='email'
              disabled={true}
              value={this.state.email}
              onChange={this.handleChange}
              className='w-100'
            />
          </Box>

          <Box my={3}>
            <TextInput
              label='Name'
              name='name'
              value={this.state.name}
              onChange={this.handleChange}
              className='w-100'
            />
          </Box>

          <Box my={3}>
            <TextInput
              label='Department'
              name='department'
              value={this.state.department}
              onChange={this.handleChange}
              className='w-100'
            />
          </Box>

          <Box my={3}>
            <TextInput
              label='Role'
              name='role'
              value={this.state.role}
              onChange={this.handleChange}
              className='w-100'
            />
          </Box>

          <Box mt={4} width={1/2} mx='auto'>
            <RaisedButton handleClick={this.handleProfileUpdateClick}>
              Update Profile
            </RaisedButton>
          </Box>

        </Box>

        <Snackbar
          open={this.state.showSnackbar}
          message={this.state.snackbarText}
        />
      </Fragment>
    )
  }
}
