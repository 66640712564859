import React, {Component} from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"


export default class ImageDisplay extends Component {
  constructor(props) {
    super(props)
  }
  
  render() {
    return(
      <img 
        src={this.props.src} 
        onClick={this.props.handleClick} 
        className={this.props.className} 
        alt={this.props.alt} 
      />
    )
  }
}
