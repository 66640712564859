import React from "react"
import MuiListItemText from '@material-ui/core/ListItemText';
import theme from "../theme"
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    flex: '-moz-available'
  }
}));

export default function FlexAvailableListItemText(props) {
  const classes = useStyles();

  return (
    <MuiListItemText className={classes.root} {...props} />
  )
}
