import React, { Fragment } from "react"
import Flex from "../Flex"
import { List } from "../lists"
import ItemDisplay from "./ItemDisplay"
import Pagination from '@material-ui/lab/Pagination';


export default function IndexList(props) {
  let numPages = Math.round(props.patentsCount / 100)

  let pages = numPages == 0 ? 1 : numPages

  return(
    <Fragment>
      <List>
        {props.patents.map((patent) =>
          <ItemDisplay
            key={patent._id || patent.id}
            patentParent={patent}
            patent={patent._source ? patent._source : patent}
            sector={props.sector}
          />
        )}
      </List>

      <Flex mt={2} justifyContent='center'>
        <Pagination
          page={props.page}
          count={pages}
          onChange={props.handleChangePage}
          showFirstButton
          showLastButton
        />
      </Flex>
    </Fragment>
  )
}
