import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Chart from "../../charts/Chart"
import Timeline from "../../charts/Timeline"

export default function TimelineChartContainer(props) {

  function formatData() {
    const chartData = props.chartData.map((chartData) => {
      let date = parseFloat(chartData.date)
      return({ x: date, name: chartData.value, label: chartData.value, description: chartData.description ? chartData.description : "N/A" })
    })

    return(chartData)
  }

  return (
    <Timeline
      chartId={props.chartId}
      chartOptions={{type: 'timeline', height: 300 }}
      title={props.title}
      formattedData={formatData()}
      yAxisVisible={false}
      xAxisVisible={false}
      // xAxisMax={props.currentTime}
      tooltip={{headerFormat: '<span>{point.label}</span><br/>', pointFormat: '<span style="color:{point.color}">\u25CF</span><span style="font-size:14px;margin-left:4px"><b>{point.label}</b></span><br/>', footerFormat: '<span style="font-size:11px">Source: {point.description}</span><br/>'}}
    />
  )
}
