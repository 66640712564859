import React, { Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import ListItem from "../../lists/ListItem";
import ListItemText from "../../lists/ListItemText";
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Checkbox from "../../Checkbox"

export default function RegionValueListItem(props) {
  
  function handleCheckboxSelect(e) {
    if (props.productCharacteristic) {
      props.handleCheckboxRegionSelect(props.characteristicGroup.world_region_id, props.productCharacteristic.product_characteristic_region_id, props.productCharacteristic, props.checked)
    } else {
      props.handleCheckboxSelect(props.characteristicValue, props.characteristicGroup, props.checked, props.productCharacteristic)
    }
  }
  
  return (
    <Fragment>

      <ListItem key={props.characteristicValue.id} role={undefined} button onClick={handleCheckboxSelect}>

        <ListItemIcon>
          <Checkbox 
            checked={props.checked} 
          />
        </ListItemIcon>

        <ListItemText 
          primary={`${props.characteristicValue.name} ${props.characteristicValue.metric ? props.characteristicValue.metric.name : ''}`} 
        />

      </ListItem>
    </Fragment>
  )
}


RegionValueListItem.propTypes = {
  characteristicValue: PropTypes.object,
  withCheckbox: PropTypes.bool
};

RegionValueListItem.defaultProps = {
  withCheckbox: true
};
