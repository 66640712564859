import React, { Component, Fragment } from "react"
import Paper from "../../Paper"
import Typography from '@material-ui/core/Typography';
import Box from "../../Box"
import update from "immutability-helper"
import TrialSearchResultsList from "../trials/SearchResultsList"
import CtGovTrialSearchResultsList from "../ct_gov_trials/SearchResultsList"
import ManuscriptSearchResultsList from "../manuscripts/SearchResultsList"
import AbstractSearchResultsList from "../abstracts/SearchResultsList"
import CircularProgress from '@material-ui/core/CircularProgress';
import { Tabs, Tab, TabPanel } from "../../tabs"
import Snackbar from "../../Snackbar"
import SearchTermForm from "./SearchTermForm"
import Link from "../../Link"
import ResultsDisplay from "../../search/ResultsDisplay"
import SearchTabs from "../../search/SearchTabs"

export default class SearchTermContainer extends Component {
  constructor(props) {
    super(props)
    this.handleTermChange = this.handleTermChange.bind(this)
    this.handleSaveClick = this.handleSaveClick.bind(this)
    this.handleTestClick = this.handleTestClick.bind(this)
    this.handleActiveIndexClick = this.handleActiveIndexClick.bind(this)
    this.handleCheckboxClick = this.handleCheckboxClick.bind(this)
    this.getTestResults = this.getTestResults.bind(this)
    this.state = { productSearchTerm: this.props.product_search_term, searchResults: this.props.search_results, resultsCount: this.props.search_results_count, loading: false, activeIndex: 'All', showSnackbar: false, snackbarText: '' }
  }

  handleActiveIndexClick(e, newValue) {
    let filter = newValue

    $.ajax({
      type: 'GET',
      url: `/admin/products/${this.props.product.id}/product_search_terms/test_search`,
      data: {
        term: this.state.productSearchTerm.search_term, filter: filter
      },
      dataType: 'json',
      success: (data) => {

        this.setState({
          searchResults: data.search_results,
          resultsCount: data.results_count,
          activeIndex: newValue,
          filter: filter
        })
      }
    })
  }

  handleCheckboxClick(e) {
    let productSearchTerm = update(this.state.productSearchTerm, {
      [e.target.name]: { $set: e.target.checked }
    })

    this.setState({
      productSearchTerm: productSearchTerm
    })
  }

  handleTestClick(e) {
    let searchTerm = this.getTestResults({ term: this.state.productSearchTerm.search_term, case_sensitive: this.state.productSearchTerm.case_sensitive })
  }

  getTestResults(searchParams) {
    $.ajax({
      type: 'GET',
      url: `/admin/products/${this.props.product.id}/product_search_terms/test_search`,
      data: searchParams,
      dataType: 'json',
      success: (data) => {
        this.setState({
          searchResults: data.search_results,
          resultsCount: data.results_count,
          loading: false
        })
      },
      beforeSend: (data) => {
        this.setState({
          loading: true
        })
      },
      error: (data) => {
        this.setState({
          loading: false,
          showSnackbar: true,
          snackbarText: 'There was an error fetching results. Please try again.'
        })
      },
      complete: (data) => {
        setTimeout(() => {
          this.setState({
            showSnackbar: false,
            snackbarText: ''
          })
        }, 5000)
      }
    })
  }

  handleSaveClick(e) {
    if (this.state.productSearchTerm.id) {
      $.ajax({
        type: 'PUT',
        url: `/admin/products/${this.props.product.id}/product_search_terms/${this.state.productSearchTerm.id}`,
        data: {
          product_search_term: this.state.productSearchTerm
        },
        dataType: 'json',
        success: (data) => {
          this.setState({
            productSearchTerm: data,
            showSnackbar: true,
            snackbarText: 'Search term was saved'
          })
        },
        error: (data) => {
          this.setState({
            showSnackbar: true,
            snackbarText: data.responseText
          })
        },
        complete: (data) => {
          setTimeout(() => {
            this.setState({
              showSnackbar: false,
              snackbarText: ''
            })
          }, 5000)
        }
      })
    } else {
      $.ajax({
        type: 'POST',
        url: `/admin/products/${this.props.product.id}/product_search_terms`,
        data: {
          product_search_term: this.state.productSearchTerm
        },
        dataType: 'json',
        success: (data) => {
          window.location = `/admin/products/${this.props.product.id}/search_terms`
        },
        error: (data) => {
          this.setState({
            showSnackbar: true,
            snackbarText: data.responseText
          })
        },
        complete: (data) => {
          setTimeout(() => {
            this.setState({
              showSnackbar: false,
              snackbarText: ''
            })
          }, 5000)
        }
      })
    }
  }

  handleTermChange(e) {
    let productSearchTerm = update(this.state.productSearchTerm, {
      search_term: { $set: e.target.value }
    })

    this.setState({
      productSearchTerm: productSearchTerm
    })
  }

  groupBy(list, props) {
    return list.reduce((a, b) => {
       (a[b[props]] = a[b[props]] || []).push(b);
       return a;
    }, {});
  }

  render() {

    return(
      <Fragment>

        <Paper minFullHeight>
          <Box mb={3}>
            <Link href={`/admin/products/${this.props.product.id}/product_search_terms`}>
              Back to search terms
            </Link>
          </Box>

          <Typography variant="h6" component="h6">
            Search Term
          </Typography>

          <Box mt={3}>

            <SearchTermForm
              productSearchTerm={this.state.productSearchTerm}
              handleTermChange={this.handleTermChange}
              handleTestClick={this.handleTestClick}
              handleSaveClick={this.handleSaveClick}
              handleCheckboxClick={this.handleCheckboxClick}
            />

            {this.state.loading ?
              <Box>
                <CircularProgress />
              </Box>
            : ''}

            <Box mt={3}>

              <SearchTabs 
                activeIndex={this.state.activeIndex}
                tabs={this.props.search_tabs}
                handleActiveIndexClick={this.handleActiveIndexClick}
              />

              <ResultsDisplay 
                searchResults={this.state.searchResults}
                resultsCount={this.state.resultsCount}
                activeIndex={this.state.activeIndex}
                query={this.state.query}
                admin={true}
                sector={this.props.sector}
              />
            </Box>
          </Box>
        </Paper>

        <Snackbar
          open={this.state.showSnackbar}
          message={this.state.snackbarText}
        />

      </Fragment>
    )
  }
}
