import React from "react"
import Link from "../../../Link"

export default function TweetLink(props) {
  let url = `https://twitter.com/${props.tweet.account_user_name}/status/${props.tweet.string_tweet_id}`
  return(
    <Link href={url} target="_blank">
      {props.tweet.text}
    </Link>
  )
}
