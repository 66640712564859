import React from "react"
import Box from "../Box"

export default function ButtonLayout(props) {
  return (
    <Box mt={4} mx="auto" width={[3/4, 1/2, 1/4]}>
      {props.children}
    </Box>
  )
}
