import React from "react"
import { Table, TableHead, TableCell, TableBody, TableRow } from "../../tables"
import Paper from "../../Paper"
import Flex from "../../Flex"
import Box from "../../Box"
import Typography from "@material-ui/core/Typography"
import TrialRow from "./TrialRow"
import update from "immutability-helper"
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TablePaginationActions from "../../tables/TablePaginationActions"
import TableHeadSort from "../../tables/TableHeadSort"
import Select from "../../Select"

export default class Trials extends React.Component {
  constructor(props) {
    super(props)
    this.handleRequestSort = this.handleRequestSort.bind(this)
    this.desc = this.desc.bind(this)
    this.stableSort = this.stableSort.bind(this)
    this.getSorting = this.getSorting.bind(this)
    this.handleChangePage = this.handleChangePage.bind(this)
    this.loadTrials = this.loadTrials.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleDeleteClick = this.handleDeleteClick.bind(this)
    this.state = { trials: this.props.trials, trialsCount: this.props.trials_count, sector_ids: this.props.sector_ids, page: this.props.page, order: 'desc', orderBy: 'updated_at', review: this.props.review }
  }

  handleChange(name, value) {
    this.loadTrials({ page: this.state.page, sector_ids: value, review: this.state.review })
  }

  handleDeleteClick(trial, trialSectorId) {
    $.ajax({
      type: 'PUT',
      url: `/admin/trial_sectors/${trialSectorId}`,
      data: {
        trial_sector: {
          deleted: true, deleted_by: this.props.current_user.id
        }
      },
      dataType: 'json',
      success: (data) => {
        let index = this.state.trials.indexOf(trial)

        let trials = update(trials, {
          $splice: [index, 1]
        })

        this.setState({
          trials: trials
        })
      }
    })
  }

  handleChangePage(e, newPage) {
    this.loadTrials({ page: newPage + 1, sector_ids: this.state.sector_ids })
  }

  loadTrials(params) {
    $.ajax({
      type: 'GET',
      url: `/admin/trials`,
      data: params,
      dataType: 'json',
      beforeSend: (smthing) => {
        this.setState({
          isLoading: true
        })
      },
      success: (data) => {
        this.setState({
          trials: data.trials,
          trialsCount: data.trials_count,
          page: params.page,
          sector_ids: params.sector_ids
        })
      },
      error: (xhjr) => {
        this.setState({
          error: true
        })
      },
      complete: (smthing) => {
        this.setState({
          isLoading: false
        })
      }
    })
  }

  desc(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = cmp(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
  }

  getSorting(order, orderBy) {
    return order === 'desc' ? (a, b) => this.desc(a, b, orderBy) : (a, b) => -this.desc(a, b, orderBy);
  }

  handleRequestSort(event, property) {
    const isDesc = this.state.orderBy === property && this.state.order === 'desc';

    this.setState({
      order: isDesc ? 'asc' : 'desc',
      orderBy: property
    })
  };

  render() {
    return (
      <React.Fragment>

        <Paper fullHeight>
          <Box height="100%">
            <Flex>
              <Box>
                <Select
                  label='Sector'
                  name='sector_ids'
                  value={this.state.sector_ids}
                  entities={this.props.sectors}
                  handleChange={this.handleChange}
                  includeBlank={true}
                />
              </Box>

              {/* <Box>
                <Select
                  label='Review'
                  name='review'
                  value={this.state.review}
                  entities={[{ name: 'No search terms', value: 'no_search_term' }, { name: 'No sector', value: 'no_sector' }]}
                  handleChange={this.handleFilterChange}
                />
              </Box> */}

              <Box ml='auto'>
                <div className='mh2'>
                  <Typography variant="body2">
                    {`${this.state.trialsCount} trials`}
                  </Typography>
                </div>
              </Box>
            </Flex>

            <Box>

            </Box>

            <Box overflow='auto' height={this.props.height || "90%"}>
              <Table size='small' aria-label='trials table' stickyHeader={true} id='trials-table'>
                <TableHeadSort
                  headers={[{ name: 'short_name', label: 'Trial' }, { name: 'sponsor', label: 'Sponsor' }, { name: 'normalized_score', label: 'Score' }, { name: 'updated_at', label: 'Updated' }, { name: '', label: 'Edit' }, { name: '', label: 'Remove from sector'}]}
                  order={this.state.order}
                  orderBy={this.state.orderBy}
                  onRequestSort={this.handleRequestSort}
                />

                <TableBody>
                  {this.stableSort(this.state.trials, this.getSorting(this.state.order, this.state.orderBy))
                    .map((trial) =>
                      <TrialRow
                        key={trial.id}
                        trial={trial}
                        sector={this.props.sector}
                        handleDeleteClick={this.handleDeleteClick}
                      />
                  )}
                </TableBody>

                <TableFooter>
                  <TablePagination
                    colSpan={5}
                    count={this.state.trialsCount}
                    rowsPerPage={100}
                    page={this.state.page}
                    SelectProps={{
                      inputProps: { 'aria-label': 'rows per page' },
                      native: true,
                    }}
                    onChangePage={this.handleChangePage}
                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableFooter>
              </Table>
            </Box>
          </Box>

        </Paper>
      </React.Fragment>
    )
  }
}
