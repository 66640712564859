import React, { Fragment, Component } from "react"
import PropTypes from "prop-types"
import Paper from "../Paper"
import { Table, TableRow, TableHead, TableBody, TableCell } from "../tables"
import Tabs from "../tabs/Tabs"
import Tab from "../tabs/Tab"
import Box from "../Box"
import AttributeDisplay from "./AttributeDisplay"
import List from "../lists/List"

export default class CvpAttributes extends Component {
  constructor(props) {
    super(props)
  }

  render() {

    let fields = Object.keys(this.props.fields).sort()

    return (
      <Fragment>

        <Paper fullHeight>
          <Box overflow='auto' height='95%'>
            <Table size='small' stickyHeader={true}>

              <TableHead>
                <TableRow hover={false}>
                  <TableCell className='bg-white'>Attribute</TableCell>
                  <TableCell className='bg-white'>Value</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {fields.map((field) =>
                  <TableRow hover={false} key={field} selected={this.props.attribute == field ? true : false}>
                    <TableCell borderLeft={true} borderRight={true} className='w-10'>
                      {field}
                    </TableCell>
                    <TableCell borderRight>
                      <List dense={true} disablePadding>
                        {Object.values(this.props.fields[field]).map((dataObject) =>
                          <AttributeDisplay
                            key={dataObject.id}
                            data={dataObject.characteristic_value ? dataObject.characteristic_value.name : (dataObject.name || dataObject.other_value)}
                            metric={dataObject.characteristic_value ? (dataObject.characteristic_value.metric ? dataObject.characteristic_value.metric.name : null) : null}
                            sourceId={null}
                          />
                        )}
                      </List>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </Box>
        </Paper>

      </Fragment>
    )
  }
}
