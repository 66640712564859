import React, { Fragment, useState } from "react"
import Paper from "../Paper"
import { Table, TableHead, TableCell, TableRow, TableBody, TableHeadSort } from "../tables"
import Link from "../Link"
import Box from "../Box"
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

export default function ComponentStentConfigurationRow(props) {

  function groupBy(list, props) {
    return list.reduce((a, b) => {
       (a[b[props]] = a[b[props]] || []).push(b);
       return a;
    }, {});
  }

  function productDisplay(product, sector) {
    return(
      <TableCell size='small' borderRight style={{padding: "4px 16px"}}>
        <Fragment>
          <Link href={`/sectors/${sector.abbr}/products/${product.id}`}>
            {product.component_short_name ? product.component_short_name : product.short_name}
          </Link>
        </Fragment>
      </TableCell>
    )
  }

  let groupedComponents = groupBy(props.products, 'id')

  return(
    <Fragment>
      {Object.keys(groupedComponents).map((productId) =>
        <TableRow key={productId}>
          {productDisplay(groupedComponents[productId][0], props.sector)}
          {props.stentConfigurations.map((stentConfiguration) =>
            <TableCell size='small' key={stentConfiguration.id} borderRight style={{padding: "4px 16px"}}>
              {groupedComponents[productId].map((product) =>
                stentConfiguration.id == product.stent_configuration_id ?
                  <Box textAlign='center'>
                    <CheckCircleIcon
                      color='primary'
                    />
                  </Box>
                : ''
              )}
            </TableCell>
          )}

        </TableRow>
      )}
    </Fragment>
  )
}
