import React from "react"
import MuiTableHead from '@material-ui/core/TableHead';
import theme from "../theme"

export default function TableHead(props) {
  return (
    <MuiTableHead {...props}>
      {props.children}
    </MuiTableHead>
  )
}
