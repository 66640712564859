import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Paper from "../Paper"

export default class StatSection extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <Paper padding='pa3' classStyle={this.props.classStyle} elevation={1}>
        <div>
          <h4 className='fw3 f3 ma0 pb1'>
            {this.props.statContent}
            <span className='ml2 f6'>{this.props.statDetail}</span>
          </h4>
          <div className='o-60 f7'>{this.props.secondaryContent}</div>
        </div>
      </Paper>
    )

  }
}
