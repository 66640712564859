import React from "react"
import ResultItemContainer from "./ResultItemContainer"
import Typography from "@material-ui/core/Typography"
import LinkToAbstract from "../abstracts/LinkToAbstract"

export default function AbstractItem(props) {

  let url = props.admin ? props.result.admin_search_url : `/sectors/${props.sector.abbr}/abstracts/${props.result.id}`

  function renderSearchDetails(result) {
    return(renderMeetingName(result.meeting_name))
  }

  function renderMeetingName(meetingName) {
    return (
      <Typography variant="body2" color="textSecondary">
        {meetingName}
      </Typography>
    );
  }

  function renderSecondaryAction(abstractId, sectorAbbr) {
    return(
      <LinkToAbstract 
        abstractId={abstractId}
        sectorAbbr={sectorAbbr}
      />
    )
  }

  return(
    <ResultItemContainer 
      searchItemType={props.result.record_type}
      searchTitle={props.result.title}
      date={props.result.date}
      searchDescription={props.result.text}
      url={url}
      searchDetails={renderSearchDetails(props.result)}
      secondaryAction={props.result.url ? renderSecondaryAction(props.result.id, props.sector.abbr) : null}
    />
  )
}