import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import ValueListItem from "./ValueListItem"

export default function CharacteristicValueItemContainer(props) {
  let productCharacteristic = ""
  
  if (props.activeRegion == 1000000) {
    productCharacteristic = props.productCharacteristics.filter((productCharacteristic) => productCharacteristic.characteristic_value_id == props.characteristicValue.id && productCharacteristic.investigational && !productCharacteristic.deleted)[0]
  } else if (props.activeRegion == 0) {
    productCharacteristic = props.productCharacteristics.filter((productCharacteristic) => productCharacteristic.characteristic_value_id == props.characteristicValue.id && !productCharacteristic.investigational && !productCharacteristic.deleted)[0]
  } else {
    productCharacteristic = props.productCharacteristics.filter((productCharacteristic) => productCharacteristic.characteristic_value_id == props.characteristicValue.id && productCharacteristic.world_region_id == props.activeRegion && !productCharacteristic.investigational && !productCharacteristic.deleted)[0]
  }
  
  let checked = false
  if (productCharacteristic) {
    if (props.activeRegion == 1000000) {
      checked = productCharacteristic.investigational ? true : false
    } else if (props.activeRegion == 0) {
      checked = productCharacteristic && !productCharacteristic.investigational ? true : false
    } else {
      checked = productCharacteristic.world_region_id == props.activeRegion ? true : false
    }
  }
  
  return (
    <Fragment>
      
      <ValueListItem
        characteristicValue={props.characteristicValue}
        characteristicGroup={props.characteristicGroup} 
        productCharacteristic={productCharacteristic}
        checked={checked}
        handleCheckboxSelect={props.handleCheckboxSelect}
      />
      
    </Fragment>
  )
}
