import React, { Fragment } from "react"
import Tabs from '../../tabs/Tabs';
import Tab from '../../tabs/Tab';

export default function EditContainerTabs(props) {
  return(
    <Fragment>
      <Tabs centered value={props.activeIndex} onChange={props.handleActiveIndexUpdate} className='bb b--black-10' aria-label="nav tabs">
        <Tab label='Source' />
        <Tab label='Person' />
      </Tabs>
    </Fragment>
  )
}
