import React from 'react'
import Link from '../../Link'

function ArtgLink(props) {

  let url = `https://www.tga.gov.au/resources/artg/${props.artgApproval.artg_id}`

  return (
    <Link href={url} target="_blank">
      {props.linkText || "View on ARTG site"}
    </Link>
  )
}

export default ArtgLink