import React from "react"
import Card from "@material-ui/core/Card"
import CardContent from '@material-ui/core/CardContent';
// import Card from "../cards/Card"
// import CardSection from "../cards/CardSection"
import SidebarSection from "../SidebarSection"
import SidebarContainer from "../sidebar/Container"
import Link from "../Link"
import EditButton from "../admin/EditButton"
import Box from "../Box"
import Flex from "../Flex"
import Typography from "@material-ui/core/Typography"
import { RaisedButton, Button } from "../buttons"

export default function PatentSidebar(props) {
  return(
    <SidebarContainer>
      <Box className='white bg-moderate-blue'>
        <Box p={2}>
          <Typography variant="h6">
          {`${props.patent.country}${props.patent.xml_patent_no}${props.patent.patent_kind}`}
          </Typography>
          <Typography variant="caption">
            {props.patent.kind ? capitalizeFirstLetter(props.patent.kind) : ""}
          </Typography>
        </Box>
      </Box>

      <Box py={2}>
        <Flex justifyContent="space-evenly">
          {/* <Box>
            <Button color="default" href="" target="_blank">
              View Similar
            </Button>
          </Box> */}

          {props.patent.pdf ?
            <Box>
              <Button color="default" href={`/sectors/${props.sector.abbr}/patents/${props.patent.id}/pdf`} target="_blank">
                View PDF
              </Button>
            </Box>
          : ""}

          {props.patent.uspto_url ? 
            <Box>
              <Button color="default" href={`/sectors/${props.sector.abbr}/patents/${props.patent.id}/uspto_redirect`} target="_blank">
                View on USPTO
              </Button>
            </Box>
          : ""}

          {props.patent.epo_url ? 
            <Box>
              <Button color="default" href={`/sectors/${props.sector.abbr}/patents/${props.patent.id}/epo_redirect`} target="_blank">
                View on Espacenet
              </Button>
            </Box>
          : ""}
        </Flex>
      </Box>

      <Box p={2}>
        {props.patentAttributes.map((patentAttributeHash) =>
          patentAttributeHash.value ?
            <Box mb={2}>
              <div className='f6 b'>{patentAttributeHash.header}</div>
              <div>
                {patentAttributeHash.link ?
                    <Link target="_blank" href={patentAttributeHash.href}>
                      {patentAttributeHash.value}
                    </Link>
                  : patentAttributeHash.value}
              </div>
            </Box>
          : ""
        )}

        {props.company ?
          <Box mt={2}>
            <div className='f6 b'>Company</div>
            <Box>
              <Link target="_blank" href={`/sectors/${props.sector.abbr}/companies/${props.company.id}`}>
                {props.company.short_name}
              </Link>
            </Box>
          </Box>
        : ""}

        <Box mt={2}>
          <div className='f6 b'>Inventors</div>
          <Box>
            {props.inventors.map((inventor) =>
              <Box mb={0.5} key={inventor.id}>
                <Link key={inventor.id} href={`/sectors/${props.sector.abbr}/people/${inventor.id}`}>
                  {inventor.name.text}
                </Link>
              </Box>
            )}
          </Box>
        </Box>

        <Box mt={2}>
          <div className='f6 b'>Published as</div>
          <Box>
            {props.equivalentPatents.length > 0 ? props.equivalentPatents.map((patent) =>
              <Box mb={0.5} key={patent.id}>
                <Link href={`/sectors/${props.sector.abbr}/patents/${patent.id}`}>
                  {`${patent.country}${patent.xml_patent_no}${patent.patent_kind}`}
                </Link>
              </Box>
            ) : "N/A"}
          </Box>
        </Box>
      </Box>
    </SidebarContainer>
  )
}
