import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Paper from "../../Paper"
import PatentCard from "./PatentCard"
import Box from '@material-ui/core/Box';

export default class PatentsPanel extends Component {
  constructor(props) {
    super(props)
  }
  
  render() {
    return(
      <Fragment>
        
        <Paper className='vh-100'>
          <div className='overflow-auto h-100'>
            <div className='mb1'>
              {this.props.patents.length > 0 ? this.props.patents.map((patent) =>
                <PatentCard
                  key={patent.id}
                  patent={patent}
                  active={this.props.activePatents.filter(activePatent => activePatent.id == patent.id)[0] ? true : false}
                  handlePatentCardClick={this.props.handlePatentCardClick}
                />
              ) : 'No patents found. Try changing the date'}
            </div>
          </div>
        </Paper>
        
      </Fragment>
    )
  }
}
