import React, { Component, Fragment} from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Header from "../../Header"
import Paper from "../../Paper"
import Flex from "../../Flex"
import Box from "../../Box"
import { Table, TableRow, TableCell, TableBody, TableHead } from "../../tables"
import Typography from "@material-ui/core/Typography"
import Link from "../../Link"
import ReviewerRow from "./ReviewerRow"
import update from "immutability-helper"
import { withSnackbar } from "../../SnackbarContainer"
import SectorFilter from "../filters/Sector"
import Select from "../../Select"

class Reviewer extends Component {
  constructor(props) {
    super(props)
    this.handleCheckboxSelect = this.handleCheckboxSelect.bind(this)
    this.handleFilterChange = this.handleFilterChange.bind(this)
    this.getTrials = this.getTrials.bind(this)
    this.state = { trials: this.props.trials, sector_id: this.props.sector_id, sponsor_type: this.props.sponsor_type, showSnackbar: false, snackbarText: '' }
  }

  handleFilterChange(name, value) {
    let params = { sector_id: this.state.sector_id, sponsor_type: this.state.sponsor_type }

    params[name] = value

    this.getTrials(params)
  }

  getTrials(params) {
    $.ajax({
      type: "GET",
      url: '/admin/trials/reviewer',
      data: params,
      dataType: 'json',
      success: (data) => {
        this.setState({
          trials: data.trials,
          sector_id: params.sector_id,
          sponsor_type: params.sponsor_type
        })
      },
      error: (data) => {
        this.props.snackbarShowMessage("An error has occurred", "error")
      }
    })
  }

  handleCheckboxSelect(trial) {
    $.ajax({
      type: 'put',
      url: `/admin/trial_reviews/${trial.trial_review_id}`,
      data: {
        trial_review: {
          reviewed: true, reviewed_by: this.props.current_user.id
        }
      },
      dataType: 'json',
      success: (data) => {
        const index = this.state.trials.indexOf(trial)

        const trials = update(this.state.trials, {
          [index]: { reviewed: { $set: true }}
        })

        this.setState({
          trials: trials,
          showSnackbar: true,
          snackbarText: 'Trial was marked as reviewed'
        })
      },
      error: (data) => {
        this.setState({
          showSnackbar: true,
          snackbarText: 'The trial could not be marked as reviewed. Please try again.'
        })
      },
      complete: (data) => {
        setTimeout(() => {
          this.setState({
            showSnackbar: false,
            snackbarText: ''
          })
        }, 4000)
      }
    })
  }

  render() {
    return(
      <Fragment>

        <Paper fullHeight>

          <Box height={"100%"}>

            <Flex mb={1}>
              <Box width={1/4}>
                <SectorFilter
                  sector_id={this.state.sector_id}
                  sectors={this.props.sectors}
                  handleSectorChange={this.handleFilterChange}
                />
              </Box>

              <Box width={1/4}>
                <Select
                  label="Sponsor Type"
                  name='sponsor_type'
                  value={this.state.sponsor_type}
                  entities={[{ name: 'All', value: 'all' }, { name: 'Industry', value: 'industry' }]}
                  includeBlank
                  handleChange={this.handleFilterChange}
                />
              </Box>

              <Box ml="auto">
                {`${this.state.trials.length} trials`}
              </Box>
            </Flex>

            <Box height="90%" overflow="auto">
              <Table stickyHeader={true} size="small">
                <TableHead>
                  <TableRow hover={false}>
                    <TableCell>Trial</TableCell>
                    <TableCell>Sponsor</TableCell>
                    <TableCell>Type</TableCell>
                    <TableCell>Date</TableCell>
                    {/* <TableCell>Status</TableCell> */}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {this.state.trials.length > 0 ? this.state.trials.map((trial) =>
                    <ReviewerRow
                      key={trial.id}
                      trial={trial}
                      handleCheckboxSelect={this.handleCheckboxSelect}
                    />
                  ) :
                    <TableRow>
                      <TableCell colSpan={5}>No trials to review. Check back later.</TableCell>
                    </TableRow>
                  }
                </TableBody>
              </Table>
            </Box>
          </Box>
        </Paper>

      </Fragment>
    )
  }
}


export default withSnackbar(Reviewer)
