import React, { Fragment } from "react"
import Flex from "../Flex"
import Box from "../Box"
import Typography from "@material-ui/core/Typography"
import { TableRow, TableCell } from "../tables"
import Link from "../Link"
import Switch from "../Switch"
import Tooltip from '@material-ui/core/Tooltip';
import DeleteButton from "../admin/DeleteButton"


export default function SearchTermRow(props) {

  function handleAlertableChange(e) {
    props.handleAlertableChange(props.userSearchTerm, props.userSearchTerm.alertable)
  }

  function handleDeleteClick(e) {
    props.handleDeleteClick(props.userSearchTerm)
  }

  return(
    <TableRow>
      <TableCell className='w-75'>
        <Link href={`/sectors/${props.sector.abbr}/tweets?search_term_id=${props.userSearchTerm.id}`}>
          {props.userSearchTerm.search_term}
        </Link>
      </TableCell>

      <TableCell>
        <Tooltip title="Receive alerts when new tweets contain your search term">
          <Switch
            edge="end"
            checked={props.userSearchTerm.alertable}
            handleChange={handleAlertableChange}
          />
        </Tooltip>
      </TableCell>

      <TableCell>
        <DeleteButton
          onClick={handleDeleteClick}
        />
      </TableCell>
    </TableRow>
  )
}
