import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Paper from "../../Paper"
import { Table, TableRow, TableCell, TableBody, TableHead, EmptyDataTableRow } from "../../tables"
import CharacteristicValueRow from "./CharacteristicValueRow"
import Select from "../../Select"
import Flex from "../../Flex"
import Box from "../../Box"
import update from "immutability-helper"
import Snackbar from "../../Snackbar"

export default class CharacteristicValues extends Component {
  constructor(props) {
    super(props)
    this.handleDeleteClick = this.handleDeleteClick.bind(this)
    this.handleUndoClick = this.handleUndoClick.bind(this)
    this.handleCharacteristicGroupChange = this.handleCharacteristicGroupChange.bind(this)
    this.state = { characteristicValues: this.props.characteristic_values, showSnackbar: false, snackbarText: '', lastDeleted: '', characteristicGroupId: '' }
  }

  handleCharacteristicGroupChange(name, value) {
    $.getJSON("/admin/characteristic_values", { characteristic_group_id: value }, (data) => {
      this.setState({
        characteristicValues: data.characteristic_values,
        characteristicGroupId: value
      })
    }).fail((data) => {
      this.setState({
        showSnackbar: true,
        snackbarText: 'There was a problem. Please try again.'
      })
    }).always((data) => {
      setTimeout(() => {
        this.setState({
          showSnackbar: false,
          snackbarText: ''
        })
      }, 4000)
    })
  }

  handleUndoClick(e) {
    if (this.state.lastDeleted) {
      $.ajax({
        type: 'PUT',
        url: `/admin/characteristic_values/${this.state.lastDeleted.id}`,
        data: {
          characteristic_value: {
            deleted: null, deleted_by: null
          }
        },
        dataType: 'json',
        success: (data) => {
          const characteristicValues = update(this.state.characteristicValues, { $push: [this.state.lastDeleted] })
          this.setState({
            characteristicValues: characteristicValues,
            showSnackbar: true,
            snackbarText: 'Characteristic value was undeleted',
            lastDeleted: null
          })
        },
        error: (data) => {
          this.setState({
            showSnackbar: true,
            snackbarText: data.responseText
          })
        },
        completed: (data) => {
          setTimeout(() => {
            this.setState({
              showSnackbar: false,
              snackbarText: ''
            })
          }, 4000)
        }
      })
    }
  }

  handleDeleteClick(characteristicValue) {
    $.ajax({
      type: 'PUT',
      url: `/admin/characteristic_values/${characteristicValue.id}`,
      data: {
        characteristic_value: {
          deleted: true, deleted_by: this.props.current_user.id
        }
      },
      dataType: 'json',
      success: (data) => {
        let characteristicValueIds = this.state.characteristicValues.map((characteristicValue) => characteristicValue.id )
        let index = characteristicValueIds.indexOf(characteristicValue.id)
        let characteristicValues = update(this.state.characteristicValues, { $splice: [[index, 1]]})

        this.setState({
          characteristicValues: characteristicValues,
          lastDeleted: characteristicValue,
          showSnackbar: true,
          snackbarText: 'The characteristic value was successfully deleted'
        })
      },
      error: (data) => {
        this.setState({
          showSnackbar: true,
          snackbarText: `The characteristic value could not be deleted. ${data.responseText}`
        })
      },
      complete: (data) => {
        setTimeout(() => {
          this.setState({
            showSnackbar: false,
            snackbarText: ''
          })
        }, 4000)
      }
    })
  }

  render() {
    return(
      <Fragment>

        <Paper fullHeight>

          <Flex mb={3} width={1}>

            <Box width={1/4}>
              <Select
                value={this.state.characteristicGroupId}
                name='characteristic_group_id'
                label='Characteristic Group'
                entities={this.props.characteristic_groups}
                displayEmpty={true}
                handleChange={this.handleCharacteristicGroupChange}
              />
            </Box>

          </Flex>

          <Box height="90%" overflow='auto'>

            <Table size='small' aria-label='characteristic values table' stickyHeader={true}>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Group</TableCell>
                  <TableCell>Created By</TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {this.state.characteristicValues.length > 0 ? this.state.characteristicValues.map((characteristicValue) =>
                  <CharacteristicValueRow
                    key={characteristicValue.id}
                    characteristicValue={characteristicValue}
                    handleDeleteClick={this.handleDeleteClick}
                  />
                ) : <EmptyDataTableRow colSpan={5} message='No characteristic groups found. Create one and it will display here.' />}
              </TableBody>
            </Table>
          </Box>
        </Paper>

        <Snackbar
          open={this.state.showSnackbar}
          handleUndoClick={this.handleUndoClick}
          message={this.state.snackbarText}
        />

      </Fragment>
    )
  }
}
