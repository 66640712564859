import React, { Component } from "react"
import PropTypes from "prop-types"
import ReactDOM from "react-dom"
import Chart from "../charts/Chart"
import Flex from "../Flex"
import Box from "../Box"

class ActivityChartBreakdown extends Component {
  constructor(props) {
    super(props)
  }

  targetSitesChart() {
    return(<Chart key={this.props.targetSiteChartData} chartId='target-sites-chart' data={this.props.targetSiteChartData} chartOptions={{type: 'pie', height: 300}} title='Target Sites' legend={true} simple={true} />)
  }

  methodChart() {
    return(<Chart key={this.props.methodChartData} chartId='method-chart' data={this.props.methodChartData} chartOptions={{type: 'pie', height: 300}} title='Methods' legend={true} simple={true} />)
  }

  targetMarketsChart() {
    return(<Chart key={this.props.targetMarketChartData} chartId='target-markets-chart' data={this.props.targetMarketChartData} chartOptions={{type: 'pie', height: 300}} title='Target Markets' legend={true} simple={true} />)
  }

  technologyChart() {
    return(<Chart key={this.props.technologyGroupChartData} chartId='technology-chart' data={this.props.technologyGroupChartData} chartOptions={{type: 'pie', height: 300}} title='Technology' legend={true} simple={true} />)
  }

  therapiesChart() {
    return(<Chart key={this.props.therapyTypeChartData} chartId='therapies-chart' data={this.props.therapyTypeChartData} chartOptions={{type: 'pie', height: 300}} title='Therapy' legend={true} simple={true} />)
  }

  renderBySector() {
    console.log(this.props.sector.id)
    switch (this.props.sector.id) {
      case 1:
        return(
          <React.Fragment>
            <Flex alignItems='center' justifyContent='center' my={4}>
              <Box>
                {this.targetMarketsChart()}
              </Box>

              <Box>
                {this.targetSitesChart()}
              </Box>
            </Flex>

            <Flex justifyContent='center'>
              {this.therapiesChart()}
            </Flex>
          </React.Fragment>
        )
      case 2:
        return(
          <React.Fragment>
            <Flex alignItems='center' justifyContent='center' my={4}>
              <Box>
                {this.targetMarketsChart()}
              </Box>

              <Box>
                {this.targetSitesChart()}
              </Box>
            </Flex>

            <Flex justifyContent='center'>
              {this.therapiesChart()}
            </Flex>
          </React.Fragment>
        )
      case 8:
      case 4:
        return(
          <React.Fragment>
            <Flex alignItems='center' justifyContent='center' my={4}>
              <Box>
                {this.methodChart()}
              </Box>

              <Box>
                {this.targetMarketsChart()}
              </Box>
            </Flex>

            <Flex justifyContent='center'>
              {this.technologyChart()}
            </Flex>
          </React.Fragment>
        )
      case 5:
        return(<React.Fragment>
          <div className='mt4 mb3 w-90 center'>
            <div className='dib w-50'>
              {this.methodChart()}
            </div>

            <div className='dib w-50'>
              {this.targetMarketsChart()}
            </div>
          </div>

          <div>
            {this.technologyChart()}
          </div>
        </React.Fragment>)
        break;
      case 6:
      case 7:
        return(<div className='mt4 mb3 w-90 center'>
          <div className='dib w-50'>
            {this.targetMarketsChart()}
          </div>

          <div className='dib w-50'>
            {this.technologyChart()}
          </div>
        </div>)
        break;
      case 10:
        return(
          <React.Fragment>
            <Flex alignItems='center' justifyContent='center' mt={4} mb={6}>
              <Box>
                {this.methodChart()}
              </Box>

              <Box>
                {this.therapiesChart()}
              </Box>
            </Flex>

            <Flex justifyContent='center'>
              <Chart key={this.props.valvesChartData} chartId='valves-chart' data={this.props.valvesChartData} chartOptions={{type: 'pie', height: 300}} title='Valve' legend={true} simple={true} />
            </Flex>
          </React.Fragment>)
        break;
    }
  }


  render () {
    return (
      <React.Fragment>
        {this.renderBySector()}
      </React.Fragment>
    );
  }
}

export default ActivityChartBreakdown
