import React, { Fragment, useState } from "react"
import ScrapedNameListItem from "./ScrapedNameListItem"
import { List } from "../../lists"
import InvestigatorRow from "./InvestigatorRow"
import PersonForm from "../trial_investigators/PersonForm"
import Flex from "../../Flex"
import Box from "../../Box"
import TextInput from "../../TextInput"
import { Button, RaisedButton } from "../../buttons"

export default function InvestigatorForm(props) {
  const [suggestions, setSuggestions] = useState([]);
  const [query, setQuery] = useState("")
  const [addingPerson, setAddingPerson] = useState(false)
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")

  function handleNameChange(e) {
    if (e.target.name == 'firstName') {
      setFirstName(e.target.value)
    } else {
      setLastName(e.target.value)
    }
  }

  function handleNewPerson(e) {
    setAddingPerson(false)
    props.handleNewPerson(firstName, lastName)
  }

  function handleChange(e) {
    const value = e.target.value

    if (value == '' || value == null) {
      setSuggestions([])
    } else {
      $.ajax({
        type: "GET",
        url: "/admin/people/search",
        data: {
          query: value
        },
        dataType: 'json',
        success: (data) => {
          setSuggestions(data.people)
          setQuery(value)
        }
      })
    }
  }

  return(
    <Fragment>
      <Box mt={3}>
        <PersonForm
          query={query}
          suggestions={suggestions}
          firstName={firstName}
          lastName={lastName}
          addingPerson={addingPerson}
          handleNameChange={handleNameChange}
          handleNewPersonClick={handleNewPerson}
          handlePersonClick={props.handlePersonClick}
          handleChange={handleChange}
        />
      </Box>

      {props.trialInvestigators && props.trialInvestigators.length > 0 ?
        <List>
          {props.trialInvestigators.map((trialInvestigator) =>
            <InvestigatorRow
              key={trialInvestigator}
              trialInvestigator={trialInvestigator}
              handleInvestigatorRemoval={props.handleInvestigatorRemoval}
            />
          )}
        </List>
      : ''}

      {/* {addingPerson ?
        <Box>
          <Flex mb={3} alignItems='center'>
            <Box pr={3} width={1/3}>
              <TextInput
                name='first_name'
                label='First Name'
                value={firstName}
                onChange={(e) => { setFirstName(e.target.value) }}
                fullWidth
              />
            </Box>

            <Box pr={3} width={1/3}>
              <TextInput
                name='last_name'
                label='Last Name'
                value={lastName}
                onChange={(e) => { setLastName(e.target.value) }}
                fullWidth
              />
            </Box>

            <Box pr={2}>
              <RaisedButton onClick={handleNewPerson}>
                Save
              </RaisedButton>
            </Box>

            <Box>
              <RaisedButton onClick={() => {
                setAddingPerson(false)
                setFirstName("")
                setLastName("")
              }}>
                Cancel
              </RaisedButton>
            </Box>
          </Flex>
        </Box>
      :
        <Box>
          <Button onClick={() => { setAddingPerson(true) }}>
            Create new person
          </Button>
        </Box>
      } */}

      {/* {props.scrapedNames.length > 0 ?
        <List>
          {props.scrapedNames.map((scrapedName) =>
            scrapedName.text.length > 3 ?
              <ScrapedNameListItem
                key={scrapedName.id}
                scrapedName={scrapedName}
                trialInvestigators={props.trialInvestigators}
                handleNameAdd={props.handleNameAdd}
              />
            : ''
          )}
        </List>
      : ''} */}
    </Fragment>
  )
}
