import React, { Component, Fragment } from "react"
import PropTypes from "prop-types"
import Paper from "../Paper"
import CompaniesTable from "./CompaniesTable"
import Filters from "./Filters"
import update from 'immutability-helper'
import Flex from "../Flex"
import Box from "../Box"
import Button from "../buttons/Button"
import Typography from "@material-ui/core/Typography"
import Collapse from "@material-ui/core/Collapse"
import ExpandLess from '../ExpandLess';
import ExpandMore from '../ExpandMore';
import FilterChipsContainer from "./FilterChipsContainer"
import { withRouter } from "react-router-dom";

class Listing extends Component {
  constructor(props) {
    super(props)
    this.getCompanies = this.getCompanies.bind(this)
    this.handleFilterChange = this.handleFilterChange.bind(this)
    this.handleFilterExpandClick = this.handleFilterExpandClick.bind(this)
    this.handleDelete = this.handleDelete.bind(this)
    this.handleFavoriteClick = this.handleFavoriteClick.bind(this)
    this.state = { activeTab: this.props.active_tab, companies: this.props.companies, showFilters: false, userFavoriteCompanies: this.props.user_favorite_companies, filterOptions: { typeOptions: this.props.type_options }, filterValues: { type: '' } }
  };

  componentDidMount() {
    let type = this.props.type ? this.props.type : ''
    let filterValues = update(this.state.filterValues, {
      type: { $set: type }
    })

    this.setState({
      filterValues: filterValues
    })
  }

  handleFilterExpandClick(e) {
    let newFilterState = !this.state.showFilters

    this.setState({
      showFilters: newFilterState
    })
  }

  handleFilterChange(name, value) {
    let params = Object.assign({}, this.state.filterValues)
    params[name] = value

    this.getCompanies(params)
  }

  handleDelete(value, type) {
    const index = this.state.filterValues[type].indexOf(value)

    const filterValues = update(this.state.filterValues, {
      [type]: { $splice: [[index, 1]] }
    })

    this.getCompanies(filterValues)
  }

  getCompanies(params) {
    let url = this.props.my_companies ? `/sectors/${this.props.sector.abbr}/companies/my_companies` : `/sectors/${this.props.sector.abbr}/companies`
    $.ajax({
      type: 'GET',
      url: url,
      data: params,
      dataType: 'json',
      success: (data) => {

        // let filterOptions = { technologyGroups: data.technology_groups, targetMarkets: data.target_markets, targetSites: data.target_sites }

        if (params['type']) {
          this.props.history.push(`${url}?type=${params['type']}`);
        } else {
          this.props.history.push(url)
        }

        this.setState({
          companies: data.companies,
          filterValues: params
        })
      }
    })
  }

  handleFavoriteClick(company) {
    if (company.user_company_id && !company.user_company_deleted) {
      $.ajax({
        type: 'PUT',
        url: `/sectors/${this.props.sector.abbr}/user_company_favorites/${company.user_company_id}`,
        data: {
          user_company_favorite: {
            deleted: true
          }
        },
        dataType: 'json',
        success: (data) => {
          let index = this.state.companies.indexOf(company)
          let companies = update(this.state.companies, {
            [index]: {
              user_company_id: { $set: null }
            }
          })

          this.setState({
            companies: companies
          })
        },
        error: (data) => {
          this.setState({
            showSnackbar: true,
            snackbarText: 'There was an error removing the item from your favorites. Please try again.'
          })
        },
        complete: (data) => {
          setTimeout(() => {
            this.setState({
              showSnackbar: false,
              snackbarText: ''
            })
          }, 4000)
        }
      })
    } else {
      $.ajax({
        type: 'POST',
        url: `/sectors/${this.props.sector.abbr}/user_company_favorites`,
        data: {
          user_company_favorite: {
            user_id: this.props.current_user.id, company_id: company.id
          }
        },
        dataType: 'json',
        success: (data) => {
          let index = this.state.companies.indexOf(company)
          let companies = update(this.state.companies, {
            [index]: {
              user_company_id: { $set: data.user_company_favorite.id }
            }
          })

          this.setState({
            companies: companies
          })
        },
        error: (data) => {
          this.setState({
            showSnackbar: true,
            snackbarText: 'There was an error adding the company to your favorites. Please try again.'
          })
        },
        complete: (data) => {
          setTimeout(() => {
            this.setState({
              showSnackbar: false,
              snackbarText: ''
            })
          }, 4000)
        }
      })
    }
  }

  render() {
    return (
      <Fragment>

        <Paper fullHeight>
          <Box height="100%">

            <Box mb={3}>
              <Filters
                filterOptions={this.state.filterOptions}
                filterValues={this.state.filterValues}
                sector={this.props.sector}
                handleFilterChange={this.handleFilterChange}
              />
            </Box>

            <Flex my={2} minHeight='28px' alignItems='center'>

              <FilterChipsContainer
                filterOptions={this.state.filterOptions}
                filterValues={this.state.filterValues}
                handleDelete={this.handleDelete}
              />

              <Box ml='auto'>
                <Typography variant="body2">
                  {`${this.state.companies.length} companies`}
                </Typography>
              </Box>
            </Flex>

            {/* <Flex alignItems='center'>
              <Box>
                <Button onClick={this.handleFilterExpandClick} color='default' lowercase={true} className='f5'>
                  {this.state.showFilters ? <ExpandLess /> : <ExpandMore />}
                  Filters
                </Button>
              </Box>

              <Box ml='auto'>
                <Typography variant="body2">
                  {`${this.state.companies.length} companies`}
                </Typography>
              </Box>
            </Flex> */}

            {/* <Collapse in={this.state.showFilters} timeout="auto" unmountOnExit>
              <Box mb={3} mt={2}>
                <Filters
                  filterOptions={this.state.filterOptions}
                  filterValues={this.state.filterValues}
                  sector={this.props.sector}
                  handleFilterChange={this.handleFilterChange}
                />
              </Box>
            </Collapse> */}


            <CompaniesTable
              companies={this.state.companies}
              handleFavoriteClick={this.handleFavoriteClick}
              sector={this.props.sector}
            />

          </Box>
        </Paper>

      </Fragment>
    );
  }
}

Listing.propTypes = {
  companies: PropTypes.array
};

export default withRouter(Listing)
