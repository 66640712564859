import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Paper from "../../Paper"
import update from "immutability-helper"
import Snackbar from "../../Snackbar"
import EditContainerTabs from "./EditContainerTabs"
import ProfileForm from "./ProfileForm"
import SectorForm from "./SectorForm"
import MetricsForm from "./MetricsForm"


export default class EditContainer extends Component {
  constructor(props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
    this.handleSaveClick = this.handleSaveClick.bind(this)
    this.handleActiveIndexUpdate = this.handleActiveIndexUpdate.bind(this)
    this.state = { characteristicGroup: this.props.characteristic_group, activeIndex: 0 }
  }

  componentDidMount() {
    const characteristicGroup = update(this.state.characteristicGroup, {
      updated_by: { $set: this.props.current_user.id }
    })

    this.setState({
      characteristicGroup: characteristicGroup
    })
  }

  handleChange(e) {
    const name = e.currentTarget.name
    const value = e.currentTarget.value

    const characteristicGroup = update(this.state.characteristicGroup, { [name]: { $set: value } })

    this.setState({
      characteristicGroup: characteristicGroup
    })
  }

  handleSaveClick(e) {
    $.ajax({
      type: 'PUT',
      url: `/admin/characteristic_groups/${this.state.characteristicGroup.id}`,
      data: {
        characteristic_group: this.state.characteristicGroup
      },
      dataType: 'json',
      success: (data) => {
        this.setState({
          showSnackbar: true,
          snackbarText: "Characteristic group has been saved"
        })
      },
      error: (data) => {
        this.setState({
          showSnackbar: true,
          snackbarText: `There was a problem updating the characteristic group. ${data.responseText}`
        })
      },
      complete: (data) => {
        setTimeout(() => {
          this.setState({
            showSnackbar: false,
            snackbarText: ''
          })
        }, 4000)
      }
    })
  }

  handleActiveIndexUpdate(e, newValue) {
    this.setState({
      activeIndex: newValue
    })
  }

  render() {
    return(
      <Fragment>

        <Paper>

          <div className='mb4'>
            <EditContainerTabs
              activeIndex={this.state.activeIndex}
              handleActiveIndexUpdate={this.handleActiveIndexUpdate}
            />
          </div>

          {this.state.activeIndex == 0 ?
            <ProfileForm
              characteristicGroup={this.state.characteristicGroup}
              characteristicGroupRegion={this.state.characteristicGroupRegion}
              handleChange={this.handleChange}
              handleRegionChange={this.handleRegionChange}
              handleSaveClick={this.handleSaveClick}
            />
           : ''}

           {this.state.activeIndex == 1 ?
             <SectorForm
               characteristicGroup={this.state.characteristicGroup}
               characteristicGroupSectors={this.props.characteristic_group_sectors}
               sectors={this.props.sectors}
               currentUser={this.props.current_user}
             />
           : ''}

           {this.state.activeIndex == 2 ?
             <MetricsForm
               characteristicGroup={this.state.characteristicGroup}
               characteristicGroupMetrics={this.props.characteristic_group_metrics}
               metrics={this.props.metrics}
               currentUser={this.props.current_user}
             />
           : ''}

        </Paper>

        <Snackbar
          open={this.state.showSnackbar}
          message={this.state.snackbarText}
        />

      </Fragment>
    )
  }
}
