import React, { Fragment } from "react"
import Typography from "@material-ui/core/Typography"
import { List, ListItem, ListItemText } from "../../../lists"
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction"
import DeleteButton from "../../DeleteButton"
import Box from "../../../Box"

export default function KClearanceListItem(props) {

  function handleDeleteClick() {
    props.handleDeleteClick(props.productKClearance)
  }

  let sector = props.productKClearance.sector_id ? props.sectors.filter(sector => sector.value == props.productKClearance.sector_id)[0] : null

  return(
    <Fragment>
      <ListItem divider>
        <ListItemText
          primary={props.productKClearance.k_number}
          secondary={sector ? sector.name : ""}
        />

        <ListItemSecondaryAction>
          <DeleteButton onClick={handleDeleteClick} />
        </ListItemSecondaryAction>

      </ListItem>

    </Fragment>
  )
}
