import React, { Component, Fragment } from "react"
import Paper from "../../../Paper"
import Box from "../../../Box"
import Typography from "@material-ui/core/Typography"
import { withSnackbar } from "../../../SnackbarContainer"
import Form from "./Form"
import AliasesTable from "./Table"
import update from "immutability-helper"


class Container extends Component {
  constructor(props) {
    super(props)
    this.handleAddAliasClick = this.handleAddAliasClick.bind(this)
    this.handleDeleteClick = this.handleDeleteClick.bind(this)
    this.handleNameChange = this.handleNameChange.bind(this)
    this.state = { companyAliases: this.props.company_aliases, name: '' }
  }

  handleNameChange(e) {
    this.setState({
      name: e.target.value
    })
  }

  handleAddAliasClick(e) {
    $.ajax({
      type: 'POST',
      url: `/admin/company_aliases`,
      data: {
        company_alias: {
          name: this.state.name, company_id: this.props.company.id, created_by: this.props.current_user.id
        }
      },
      dataType: 'json',
      success: (data) => {
        let index = this.state.companyAliases.length
        let companyAliases = update(this.state.companyAliases, {
          $splice: [[0, 0, data.company_alias]]
        })

        this.setState({
          companyAliases: companyAliases
        })

        this.props.snackbarShowMessage("Alias has been added")
      },
      error: (data) => {
        this.props.snackbarShowMessage("There was an issue adding the alias. Please try again", "error")
      }
    })
  }

  handleDeleteClick(companyAlias) {
    $.ajax({
      type: 'PUT',
      url: `/admin/company_aliases/${companyAlias.id}`,
      data: {
        company_alias: {
          deleted: true, deleted_by: this.props.current_user.id
        }
      },
      dataType: 'json',
      success: (data) => {
        let index = this.state.companyAliases.indexOf(companyAlias)
        let companyAliases = update(this.state.companyAliases, {
          $splice: [[index, 1]]
        })

        this.setState({
          companyAliases: companyAliases
        })

        this.props.snackbarShowMessage("Alias has been removed")
      },
      error: (data) => {
        this.props.snackbarShowMessage("There was an issue removing the alias. Please try again", "error")
      }
    })
  }

  render() {
    return(
      <Paper fullHeight>
        <Box height={1}>

          <Form
            name={this.state.name}
            handleNameChange={this.handleNameChange}
            handleAddAliasClick={this.handleAddAliasClick}
          />

          <Box mt={3} height="90%" overflow="auto">
            <AliasesTable
              companyAliases={this.state.companyAliases}
              handleDeleteClick={this.handleDeleteClick}
            />
          </Box>
        </Box>
      </Paper>
    )
  }
}


export default withSnackbar(Container)
