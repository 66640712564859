import React, { Component } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Highcharts from "highcharts"

export default class PieChart extends Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    Highcharts.chart(`${this.props.chartId}`, {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'pie'
      },
      credits:{
        enabled: false
      },
      title: {
        text: this.props.title
      },
      tooltip: {
        pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
      },
      accessibility: {
        point: {
          valueSuffix: '%'
        }
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: true,
            format: '<b>{point.name}</b>: {point.percentage:.1f} %'
          }
        }
      },
      exporting: {
        filename: `${this.props.title}_chart`,
        credits: { enabled: true, text: '\u00A9 pvpipeline' }
      },
      series: this.props.formattedData ? this.props.formattedData : this.buildData(this.props.data, this.props.yAxisTitle)
    })
  }

  buildData(data, seriesName) {
    if (this.props.simple) {
      return [{ data: data, name: seriesName }]
    } else {
      return data
    }
  }

  render () {
    return(
      <div id={this.props.chartId}></div>
    )
  }
}
