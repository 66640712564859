import React, { Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Box from "./Box"
import Typography from "@material-ui/core/Typography"
import Flex from "./Flex"

export default function EmptyDataMessageContainer(props) {
  
  return(
    <Flex alignItems='center' justifyContent='center' minHeight="60vh">
      <Box textAlign='center' maxWidth="50%">
        
        <Box mb={1}>
          <Typography variant="h6">
            {props.headline}
          </Typography>
        </Box>
        
        {props.body ? <Box className='measure'>
          <Typography variant="body1">
            {props.body}
          </Typography>
        </Box> : ''}
        
      </Box>
    </Flex>
  )
}
