import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import ProfileForm from "./ProfileForm"
import Box from "../../Box"
import update from "immutability-helper"
import { withSnackbar } from "../../SnackbarContainer"

class ProfileFormContainer extends Component {
  constructor(props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
    this.handleSaveClick = this.handleSaveClick.bind(this)
    this.handleDateChange = this.handleDateChange.bind(this)
    this.state = { trial_name: null, trial_date: null, full_name: null, short_name: null, acronym: null, start_date: null, completion_date: null, primary_completion_date: null, nct_id: this.props.trial.nct_id, ctri_id: this.props.trial.ctri_id, number_of_arms: this.props.trial.number_of_arms, loading: true, trial: {}, showSnackbar: false, snackbarText: '' }
  }

  handleDateChange(dateName, dateValue, parsedDateValue) {
    this.setState({
      [dateName]: dateValue
    })
  }

  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  componentDidMount() {
    $.ajax({
      type: 'GET',
      url: `/admin/trials/${this.props.trial.id}/edit_profile`,
      dataType: 'json',
      success: (data) => {
        let trial_names_attributes = {}
        data.trial_names.map((trialName, index) =>
          trial_names_attributes[index] = trialName
        )

        let trial_dates_attributes = {}
        data.trial_dates.map((trialDate, index) =>
          trial_dates_attributes[index] = trialDate
        )

        this.setState({
          trial_names: data.trial_names,
          full_name: data.full_name,
          short_name: data.short_name,
          acronym: data.acronym,
          trial_dates: data.trial_dates,
          start_date: data.start_date,
          completion_date: data.completion_date,
          primary_completion_date: data.primary_completion_date,
          loading: false
        })
      }
    })
  }

  handleSaveClick(e) {
    $.ajax({
      type: 'POST',
      url: `/admin/trials/${this.props.trial.id}/new_from_profile`,
      data: {
        source_id: this.props.source.source_id, trial_names: { short_name: this.state.short_name, full_name: this.state.full_name, acronym: this.state.acronym }, trial: { nct_id: this.state.nct_id, number_of_arms: this.state.number_of_arms, ctri_id: this.state.ctri_id }
      },
      dataType: 'json',
      success: (data) => {
        this.props.snackbarShowMessage(`Trial was updated`)
      },
      error: (data) => {
        this.props.snackbarShowMessage(`The trial could not be updated. Make sure you have chosen a source`)
      }
    })
  }

  render() {
    return (
      <Fragment>
        {this.state.loading ?
          <Box>Loading...</Box>
        :
          <ProfileForm
            trial={this.props.trial}
            full_name={this.state.full_name}
            short_name={this.state.short_name}
            acronym={this.state.acronym}
            start_date={this.state.start_date}
            completion_date={this.state.completion_date}
            primary_completion_date={this.state.primary_completion_date}
            handleChange={this.handleChange}
            handleSaveClick={this.handleSaveClick}
            handleDateChange={this.handleDateChange}
            handleSwitchChange={this.props.handleSwitchChange}
          />
        }
      </Fragment>
    )
  }
}

export default withSnackbar(ProfileFormContainer)
