import React, { Component, Fragment } from "react"
import InlineSearch from "./InlineSearch"

export default class InlineSearchContainer extends Component {
  constructor(props) {
    super(props)
    this.handleQueryChange = this.handleQueryChange.bind(this)
    this.handleSearchClick = this.handleSearchClick.bind(this)
    this.handleClearSearchClick = this.handleClearSearchClick.bind(this)
    this.state = { query: this.props.query }
  }

  componentDidMount() {
    this.setState({
      query: this.props.query
    })
  }

  componentDidUpdate(prevProps) {
    if (prevProps.query != this.props.query) {
      this.setState({
        query: this.props.query || ""
      })
    }
  }

  handleQueryChange(e) {
    this.setState({
      query: e.target.value
    })
  }

  handleSearchClick(e) {
    e.preventDefault()
    this.props.handleSearchClick(this.state.query)
  }

  handleClearSearchClick(e) {
    e.preventDefault()
  
    this.setState({
      query: ""
    }, () => {
      this.props.handleClearSearchClick()
    })
  }

  render() {
    return(
      <Fragment>
        <InlineSearch
          searchLabel={this.props.searchLabel} 
          helperText={this.props.helperText}
          query={this.state.query}
          handleQueryChange={this.handleQueryChange}
          handleSearchClick={this.handleSearchClick}
          handleClearSearchClick={this.handleClearSearchClick}
        />
      </Fragment>
    )
  }
}
