import React from "react"
import { TableRow, TableCell } from "../../tables"
import Link from "../../Link"
import DeleteButton from "../DeleteButton"
import Box from "../../Box"
import FdaLink from "./FdaLink"

export default function Row(props) {

  function handleDeleteClick(e) {
    props.handleDeleteClick(props.fdaRecall)
  }

  return(
    <TableRow>
      <TableCell>
        <FdaLink 
          fdaRecall={props.fdaRecall}
          linkText={props.fdaRecall.recall_id}
        />
      </TableCell>
      <TableCell>
        <Link href={`/admin/fda_recalls/${props.fdaRecall.id}/associator`}>
          <Box width={1/4}>
            {props.fdaRecall.product ? truncateString(props.fdaRecall.product, 300) : ""}
          </Box>
        </Link>
      </TableCell>
      <TableCell>
        {props.fdaRecall.manufacturer_reason_for_recall ? truncateString(props.fdaRecall.manufacturer_reason_for_recall, 100) : ""}
      </TableCell>
      <TableCell>
        {props.fdaRecall.pma_number}
      </TableCell>
      <TableCell>
        {props.fdaRecall.k_number}
      </TableCell>
      <TableCell>
        {props.fdaRecall.date_posted || props.fdaRecall.date_initiated_by_firm}
      </TableCell>
      <TableCell>
        <DeleteButton 
          onClick={handleDeleteClick}
        />
      </TableCell>
    </TableRow>
  )
}
