import React, { Component } from "react"
import PropTypes from "prop-types"
import ReactDOM from "react-dom"
import Tabs from "../tabs/Tabs"
import Tab from "../tabs/Tab"

export default function NetworkTabs(props) {
  return(
    <React.Fragment>
      <Tabs centered value={props.activeIndex} onChange={props.handleActiveIndexUpdate} className='bb b--black-10' aria-label="network chart tabs">
        <Tab label='Data' />
      </Tabs>
    </React.Fragment>
  )
}
