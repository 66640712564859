import React, { Component } from "react"
import Paper from "../Paper"

class EyContainer extends Component {
  
  render() {
    return (
      <Paper>
        This should be displaying
      </Paper>
    )
  }
}


export default EyContainer