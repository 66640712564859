import React, { Fragment } from "react"
import Box from "../Box"
import Select from "../Select"

export default function ComponentFilter(props) {
  return(
    <Fragment>
      <Box width="40%">
        <Select
          label='Components'
          name='component'
          value={props.componentId}
          entities={props.childProductOptions}
          handleChange={props.handleChildProductChange}
        />
      </Box>
    </Fragment>
  )
}
