import React, { Fragment, useState } from "react"
import Paper from "../Paper"
import Flex from "../Flex"
import Box from "../Box"
import { List, ListItem, ListItemLink, ListItemText } from "../lists"
import Typography from "@material-ui/core/Typography"
import Select from "../Select"
import ArticlesList from "../articles/ArticlesList"

export default function NewsArticles(props) {
  const [newsTypeId, setNewsTypeId] = useState(props.news_type_id);
  const [newsType, setNewsType] = useState(props.news_type)
  const [newsItems, setNewsItems] = useState(props.all_news)

  function handleNewsTypeChange(name, value) {
    $.ajax({
      type: 'GET',
      url: props.url,
      data: {
        news_type_id: value
      },
      dataType: 'json',
      success: (data) => {
        setNewsItems(data.news)
        setNewsTypeId(value)
        setNewsType(data.news_type)
      }
    })
  }

  let typeLabel = newsTypeId == null ? 'Articles And Press Releases' : newsType.name

  return(
    <Paper fullHeight>
      <Box height="100%">
        <Flex alignItems='center' mb={3}>
          <Box>
            <Select
              includeBlank
              label='News Type'
              name='news_type'
              value={newsTypeId}
              entities={props.news_types}
              handleChange={handleNewsTypeChange}
            />
          </Box>
          <Box ml='auto'>
            {`${newsItems.length} ${typeLabel}`}
          </Box>
        </Flex>

        <Box overflow='auto' height="90%">
          {newsItems.length > 0 ?
            <ArticlesList
              newsItems={newsItems}
              sector={props.sector}
            />
          :
            <Flex justifyContent='center' height="50%">
              <Box alignSelf='center' maxWidth="50%">
                <Box>
                  <Typography variant="body1">
                    {`No ${typeLabel} found`}
                  </Typography>
                </Box>
              </Box>
            </Flex>
          }
        </Box>
      </Box>
    </Paper>
  )
}
