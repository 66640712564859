import React, { Fragment } from "react"
import Typography from "@material-ui/core/Typography"
import { TableRow, TableCell } from "../../../tables"
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction"
import DeleteButton from "../../DeleteButton"
import Box from "../../../Box"
import FilterChip from "../../../filters/FilterChip"
import Link from "../../../Link"

export default function KClearanceTableRow(props) {

  function handleDeleteClick() {
    props.handleDeleteClick(props.productKClearances[0])
  }

  let productKClearance = props.productKClearances[0]

  return(
    <TableRow>
      <TableCell>
        <Link href={`https://www.accessdata.fda.gov/scripts/cdrh/cfdocs/cfpmn/pmn.cfm?ID=${productKClearance.k_number}`} target="_blank">
          {productKClearance.k_number}
        </Link>
      </TableCell>
      <TableCell>
        {props.productKClearances.map((productKClearance) =>
          <Box mb={1}>
            {productKClearance.sector_deleted ?
              ""
            :
              <FilterChip
                name={productKClearance.sector}
                handleDelete={() => { props.handleSectorDelete(productKClearance.k_clearance_sector_id) }}
              />
            }
          </Box>
        )}
      </TableCell>
      <TableCell>
        {productKClearance.device_name}
      </TableCell>
      <TableCell>
        {productKClearance.applicant}
      </TableCell>
      <TableCell>
        <DeleteButton onClick={handleDeleteClick} />
      </TableCell>

    </TableRow>
  )
}
