import React from "react"
import PropTypes from "prop-types"
import Form from "./Form"
import Paper from "../../Paper"
import update from "immutability-helper"
import { withSnackbar } from "../../SnackbarContainer"

class EditContainer extends React.Component {
  constructor(props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
    this.handleSaveClick = this.handleSaveClick.bind(this)
    this.handleDateChange = this.handleDateChange.bind(this)
    this.handleSelectChange = this.handleSelectChange.bind(this)
    this.handleSearchChange = this.handleSearchChange.bind(this)
    this.handleFetchDataClick = this.handleFetchDataClick.bind(this)
    this.state = { newsItem: this.props.news_item, newsPublications: this.props.news_publications }
  }

  handleFetchDataClick(e) {
    if (this.state.newsItem.url) {
      $.ajax({
        type: "GET",
        url: `/admin/news/fetch_data`,
        data: {
          news_item: this.state.newsItem
        },
        dataType: 'json',
        success: (data) => {
          this.setState({
            newsItem: data.news_item,
            newsPublications: data.news_publications
          })

          this.props.snackbarShowMessage("Data fields have been populated")
        },
        error: (data) => {
          this.props.snackbarShowMessage("There was an issue scraping the article. You might have to fill in the data manually.", "error") 
        }
      })
    } else {
      this.props.snackbarShowMessage("You must add a url to populate the data fields")
    }
  }

  handleSelectChange(name, value) {
    const newsItem = update(this.state.newsItem, {
      [name]: { $set: value }
    })

    this.setState({
      newsItem: newsItem
    }) 
  }

  handleSearchChange(e, selected) {
    const newsItem = update(this.state.newsItem, {
      news_publication_id: { $set: selected.value },
      news_publication_name: { $set: null }
    })

    this.setState({
      newsItem: newsItem
    })
  }

  handleDateChange(date) {
    const newsItem = update(this.state.newsItem, {
      date: { $set: date }
    })

    this.setState({
      newsItem: newsItem
    })
  }

  handleChange(e) {
    console.log(e)
    if (e) {
      const name = e.target.name
      const value = e.target.value
  
      const newsItem = update(this.state.newsItem, {
        [name]: { $set: value }
      })
  
      this.setState({
        newsItem: newsItem
      })
    }
  }

  handleSaveClick(e) {
    $.ajax({
      type: 'PUT',
      url: `/admin/news/${this.state.newsItem.id}`,
      data: {
        news_item: this.state.newsItem
      },
      dataType: 'json',
      success: (data) => {
        this.setState({
          newsItem: data.news_item
        })
        this.props.snackbarShowMessage("The news item was successfully updated")
      },
      error: (data) => {
        this.props.snackbarShowMessage(`The news item could not be updated. ${data.responseText}`, "error")
      }
    })
  }

  render () {
    return (
      <React.Fragment>
        <Paper fullHeight>
          <Form 
            newsItem={this.state.newsItem}
            newsPublications={this.state.newsPublications}
            newsTypes={this.props.news_types}
            handleSelectChange={this.handleSelectChange}
            handleDateChange={this.handleDateChange}
            handleChange={this.handleChange}
            handleSaveClick={this.handleSaveClick}
            handleSearchChange={this.handleSearchChange}
            handleFetchDataClick={this.handleFetchDataClick}
          />
        </Paper>
      </React.Fragment>
    );
  }
}

EditContainer.propTypes = {
  newsItem: PropTypes.object
};

export default withSnackbar(EditContainer)
