import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from "../lists/ListItemText"

export default function TrialAutocomplete(props) {
  return (
    <div style={{ width: 500 }}>
      <Autocomplete
        freeSolo
        disableClearable
        options={props.trials}
        getOptionSelected={(option, value) => option.name === value.name}
        getOptionLabel={option => option.name}
        onChange={props.handleSearchChange}
        renderOption={(option, { selected }) => (
          <div key={option.value} value={option.value} className='f5'>
            {option.name}
          </div>
        )}
        renderInput={params => (
          <TextField
            {...params}
            label="Search trials"
            margin="normal"
            variant="outlined"
            InputProps={{ ...params.InputProps, type: 'search' }}
          />
        )}
      />
    </div>
  );
}
