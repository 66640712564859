import React, { Fragment } from "react"
import { Table, TableHead, TableBody, TableRow, TableCell } from "../../tables"
import Box from "../../Box"
import Typography from "@material-ui/core/Typography"
import DeleteButton from "../DeleteButton"
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction"
import Link from "../../Link"

export default function NewsList(props) {
  return(

    <Table size='small'>
      <TableHead>
        <TableRow>
          <TableCell>Type</TableCell>
          <TableCell>Title</TableCell>
          <TableCell>Date</TableCell>
          <TableCell>Publication</TableCell>
          <TableCell>Term</TableCell>
          <TableCell>Score</TableCell>
          <TableCell></TableCell>
        </TableRow>
      </TableHead>

      <TableBody>
        {props.newsArticles.map((newsArticle) =>
          <TableRow key={newsArticle.id}>
            <TableCell>{newsArticle.record_type}</TableCell>
            <TableCell>
              <Link href={newsArticle.url} target="_blank">
                {newsArticle.title}
              </Link>
            </TableCell>
            <TableCell>
              {newsArticle.date ? newsArticle.date.split("T")[0] : 'N/A'}
            </TableCell>
            <TableCell>
              {newsArticle.publication || newsArticle.service}
            </TableCell>
            <TableCell>
              {newsArticle.search_term}
            </TableCell>
            <TableCell>
              {newsArticle.score}
            </TableCell>
            <TableCell>
              <DeleteButton onClick={() => { props.handleDeleteClick(newsArticle) }} />
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  )
}
