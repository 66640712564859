import React, { Fragment } from "react"
import Tabs from '../../tabs/Tabs';
import Tab from '../../tabs/Tab';

export default function RegionTabs(props) {
  return(
    <Fragment>
      <Tabs variant="fullWidth" value={props.activeRegionIndex} onChange={props.handleRegionIndexUpdate} className='bb b--black-10' aria-label="region tabs">
        {props.worldRegions.map((worldRegion) =>
          <Tab
            key={worldRegion.value} 
            label={worldRegion.name} 
            value={worldRegion.value} 
          />
        )}
        <Tab label='Investigational' value={1000000} />
      </Tabs>
    </Fragment>
  )
}
