import React, { Fragment } from "react"
import Chart from "../charts/Chart"

export default function ManuscriptsChartContainer(props) {

  function setTooltip() {
    let tooltip = ""
    if (props.groupOption == 'Year') {
      tooltip = { headerFormat: '<b>{point.x}</b><br/>', pointFormat: 'Manuscripts: {point.y}' }
    } else {
      // <br/>Total: {point.stackTotal}
      tooltip = { headerFormat: '<b>{point.x}</b><br/>', pointFormat: '{series.name}: {point.y}' }
    }

    return(tooltip)
  }


  let chartTitle = `${props.trial.acronym || props.trial.short_name} Manuscripts`
  let chartKey = `trial-manuscripts-chart`
  console.log(props.chartData)
  return (
    <Fragment>
      <Chart
        key={chartKey}
        chartId='trial-manuscript-count-chart'
        chartOptions={{ type: 'column', height: '35%' }}
        plotOptions={{ column: { dataLabels: { enabled: true } } }}
        exportingChartOptions={{ credits: { enabled: true, text: '\u00A9 pvpipeline' } }}
        legend={true}
        data={props.chartData}
        categories={props.categories}
        title={chartTitle}
        yAxisTitle='# of manuscripts'
        xAxisTitle='Year'
        tooltip={setTooltip()}
        simple={true}
        legend={false}
      />
    </Fragment>
  )
}
