import React, { Fragment, Component } from "react"
import ReactDOM from "react-dom"
import Paper from "../../Paper"
import Box from "../../Box"
import { withSnackbar } from "../../SnackbarContainer"
import GrantsTable from "./GrantsTable"
import Filters from "./Filters"
import Typography from "@material-ui/core/Typography"
import update from "immutability-helper"

class Index extends Component {
  constructor(props) {
    super(props)
    this.handleChangePage = this.handleChangePage.bind(this)
    this.getGrants = this.getGrants.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleSearchClick = this.handleSearchClick.bind(this)
    this.handleClearSearchClick = this.handleClearSearchClick.bind(this)
    this.handleFilterChange = this.handleFilterChange.bind(this)
    this.state = { sbirGrants: this.props.sbir_grants, sbirGrantsCount: this.props.sbir_grants_count, filterValues: { query: '', year: null, page: 0, association_type: this.props.association_type }}
  }

  handleClearSearchClick(e) {
    let params = Object.assign({}, this.state.filterValues)

    params["query"] = null

    this.getGrants(params)
  }

  handleSearchClick(e) {
    let params = Object.assign({}, this.state.filterValues)

    this.getGrants(params)
  }

  handleChange(e) {
    let filterValues = update(this.state.filterValues, {
      [e.target.name]: {
        $set: e.target.value
      }
    })

    this.setState({
      filterValues: filterValues
    })
  }

  handleFilterChange(name, value) {
    let params = Object.assign({}, this.state.filterValues)

    params[name] = value

    this.getGrants(params)
  }

  handleChangePage(e, newPage) {
    let params = Object.assign({}, this.state.filterValues)

    params["page"] = newPage

    this.getGrants(params)
  }

  getGrants(params) {
    $.ajax({
      type: "GET",
      url: '/admin/sbir_grants',
      data: params,
      dataType: 'json',
      success: (data) => {
        this.setState({
          sbirGrants: data.sbir_grants,
          sbirGrantsCount: data.sbir_grants_count,
          filterValues: params
        })
      }
    })
  }

  render() {
    return(
      <Fragment>
        <Paper fullHeight>
          <Box height="100%">

            <Box>
              <Filters
                filterValues={this.state.filterValues}
                associationTypes={this.props.association_types}
                handleChange={this.handleChange}
                handleFilterChange={this.handleFilterChange}
                handleSearchClick={this.handleSearchClick}
                handleClearSearchClick={this.handleClearSearchClick}
              />
            </Box>

            <Box my={2}>
              <Typography variant="body2">
                {`${this.state.sbirGrantsCount} SBIR Grants`}
              </Typography>
            </Box>

            <Box overflow='auto' height="80%">

              <GrantsTable
                sbirGrants={this.state.sbirGrants}
                sbirGrantsCount={this.state.sbirGrantsCount}
                page={this.state.filterValues.page}
                handleChangePage={this.handleChangePage}
              />

            </Box>
          </Box>
        </Paper>
      </Fragment>
    )
  }
}

export default withSnackbar(Index)
