import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Box from "../Box"
import Paper from "../Paper"
import RegionProfile from "./RegionProfile"
import Typography from "@material-ui/core/Typography"
import { Tabs, Tab } from "../tabs"
import ComponentFilter from "./ComponentFilter"
import ComponentTabs from "./ComponentTabs"

export default class Attributes extends Component {
  constructor(props) {
    super(props)
    this.handleChildProductClick = this.handleChildProductClick.bind(this)
    this.handleChildProductChange = this.handleChildProductChange.bind(this)
    this.state = { activeIndex: this.props.product.id, productRegions: this.props.product_regions, regionProductCharacteristics: this.props.region_product_characteristics, nonRegionCharacteristics: this.props.non_region_characteristics, allTargetSites: this.props.all_target_sites, allTargetMarkets: this.props.all_target_markets, productRegions: this.props.product_regions, fields: this.props.fields, componentId: (this.props.child_products ? this.props.child_products[0].id : null) }
  }

  handleChildProductChange(name, value) {
    $.ajax({
      type: 'GET',
      url: `/sectors/${this.props.sector.abbr}/products/${this.props.product.id}/attributes`,
      data: {
        child_product_id: value
      },
      dataType: 'json',
      success: (data) => {
        this.setState({
          componentId: value,
          productRegions: data.product_regions,
          regionProductCharacteristics: data.region_product_characteristics,
          nonRegionCharacteristics: data.non_region_characteristics,
          allTargetSites: data.all_target_sites,
          allTargetMarkets: data.all_target_markets,
          productRegions: data.product_regions,
          fields: data.fields
        })
      }
    })
  }

  handleChildProductClick(e, newValue) {
    $.ajax({
      type: 'GET',
      url: `/sectors/${this.props.sector.abbr}/products/${this.props.product.id}/attributes`,
      data: {
        child_product_id: newValue
      },
      dataType: 'json',
      success: (data) => {
        this.setState({
          activeIndex: newValue,
          productRegions: data.product_regions,
          regionProductCharacteristics: data.region_product_characteristics,
          nonRegionCharacteristics: data.non_region_characteristics,
          allTargetSites: data.all_target_sites,
          allTargetMarkets: data.all_target_markets,
          productRegions: data.product_regions,
          fields: data.fields
        })
      }
    })
  }

  render() {
    console.log(this.props.sector)
    return(
      <Fragment>


        <Paper fullHeight>

          <Box mb={3}>
            {this.props.child_products ?
              <ComponentFilter
                componentId={this.state.componentId}
                childProductOptions={this.props.child_product_options}
                handleChildProductChange={this.handleChildProductChange}
              />
            : ''}
          </Box>

          <Box height='90%'>

            <RegionProfile
              productRegions={this.state.productRegions}
              targetSites={this.state.allTargetSites}
              targetMarkets={this.state.allTargetMarkets}
              productCharacteristics={this.state.allProductCharacteristics}
              nonRegionalProductCharacteristics={this.state.nonRegionCharacteristics}
              fields={this.state.fields}
              attribute={this.props.attribute}
              sector={this.props.sector}
            />

          </Box>


        </Paper>
      </Fragment>
    )
  }
}
