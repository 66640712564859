import React, { Fragment } from "react"
import Paper from "../../Paper"
import List from "../../lists/List";
import ProductListItem from "./ProductListItem"
import ListSubheader from '@material-ui/core/ListSubheader';

export default function SimpleProductsList(props) {
  return (
    <Fragment>
      
      <List>
        <ListSubheader>Products</ListSubheader>
        {props.products.map((product) =>
          <ProductListItem 
            key={product.id}
            product={product}
            forSelection={false}
          /> 
        )}
      </List>
      
    </Fragment>
  )
}
