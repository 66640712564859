import React from "react"
import { Table, TableCell, TableBody } from "../tables"
import clsx from "clsx"

export default function HistoryDisplay(props) {
  return(
    <Table>
      <TableBody>
        <TableCell borderRight borderLeft borderTop className='w-50 v-base pre-wrap'>
          <div>
            {props.wordDiffs.map((wordDiff, index) =>
              <span key={`${props.attribute}-${index}`} className={clsx('f5', { ['deletion-highlight']: wordDiff.removed }, { ['dn']: wordDiff.added})}>
                <span dangerouslySetInnerHTML={{__html: wordDiff.value}} />
              </span>
            )}
          </div>
        </TableCell>
        <TableCell borderRight borderLeft borderTop className='w-50 v-base pre-wrap'>
          {props.wordDiffs.map((wordDiff, index) =>
            <span key={`${props.attribute}-${index}`} className={clsx('f5', { ['dn']: wordDiff.removed }, { ['green-highlight']: wordDiff.added})}>
              <span dangerouslySetInnerHTML={{__html: wordDiff.value}} />
            </span>
          )}
        </TableCell>
      </TableBody>
    </Table>
  )
}
