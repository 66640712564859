import React, { Fragment } from "react"
import Flex from "../Flex"
import Box from "../Box"
import Typography from "@material-ui/core/Typography"
import SitesDataTable from "./SitesDataTable"


export default function SitesListContainer(props) {
  console.log(props.currentSites)
  return(
    <Fragment>

      <Box height="100%">
        { props.sites.length > 0 ?
          <Fragment>
            <SitesDataTable
              sites={props.showCurrent ? props.currentSites : props.sites}
            />
          </Fragment>
        :
          <Flex justifyContent='center' height="50%">
            <Box alignSelf='center' maxWidth="50%">
              <Box>
                <Typography variant="body1">
                  No sites found for this trial
                </Typography>
              </Box>
            </Box>
          </Flex>
        }
      </Box>

    </Fragment>
  )
}
