import React, { Component, Fragment } from "react"
import { TableRow, TableCell } from "../../tables"
import EditButton from "../EditButton"
import DeleteButton from "../DeleteButton"
import Link from "../../Link"


class ClinicalTypeRow extends Component {
  constructor(props) {
    super(props)
    this.handleDeleteClick = this.handleDeleteClick.bind(this)
  }
  
  handleDeleteClick(e) {
    this.props.handleDeleteClick(this.props.clinicalStatus)
  }
  
  render() {
    const url = `/admin/clinical_statuses/${this.props.clinicalStatus.id}/edit`
    const user = this.props.clinicalStatus.creator
    return(
      <Fragment>
        <TableRow>
          <TableCell>
            <Link href={url}>{this.props.clinicalStatus.name}</Link>
          </TableCell>
          
          <TableCell>
            {user ? displayInitials(user.first_name, user.last_name) : ''}
          </TableCell>
          
          <TableCell>
            <EditButton component="a" href={url} />
          </TableCell>
          
          <TableCell>
            <DeleteButton handleClick={this.handleDeleteClick} />
          </TableCell>
          
        </TableRow>
      </Fragment>
    )
  }
}


export default ClinicalTypeRow
