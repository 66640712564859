import React, { Fragment } from "react"
import Container from "./Container"
import {
  BrowserRouter,
  Switch,
  Route,
  Link
} from "react-router-dom";

export default function Router(props) {

  return(
    <Fragment>
      <Switch>
        <Route
          path={`/sectors/${props.sector.abbr}/search`}
          children={<Container {...props} />}
        />
      </Switch>
      <Switch>
        {props.product ? <Route
          path={`/sectors/${props.sector.abbr}/products/${props.product.id}/search`}
          children={<Container {...props} />}
        /> : ""}
      </Switch>
    </Fragment>
  )
}
