import React from "react"
import Link from "../Link.jsx"
import { TableRow, TableCell } from "../tables"
import Box from "../Box"

export default function ListRecord(props) {

  const abstractsCount = props.personAbstracts ? props.personAbstracts.filter(personAbstract => personAbstract.person_id == props.person.person_id)[0] : props.person

  const manuscriptsCount = props.personManuscripts ? props.personManuscripts.filter(personManuscript => personManuscript.person_id == props.person.person_id)[0] : props.person

  const trialsCount = props.personTrials ? props.personTrials.filter(personTrial => personTrial.person_id == props.person.person_id)[0] : props.person

  return(
    <TableRow>
      <TableCell>
        <Link href={`/sectors/${props.sector.abbr}/people/${props.person.person_id}`}>
          <Box>
            {props.person.text}
          </Box>
        </Link>
      </TableCell>
      <TableCell align='right'>
        {abstractsCount ? abstractsCount.abstracts_count : 0}
      </TableCell>
      <TableCell align='right'>
        {manuscriptsCount ? manuscriptsCount.manuscripts_count : 0}
      </TableCell>
      <TableCell align='right'>
        {trialsCount ? trialsCount.trials_count : 0}
      </TableCell>
    </TableRow>
  )
}
