import React, { Fragment } from "react"
import Typography from "@material-ui/core/Typography"
import { Table, TableRow, TableCell, TableBody, TableHead } from "../../../tables"
import Box from "../../../Box"
import KClearanceTableRow from "./Row"

export default function KClearanceTable(props) {

  return(
    <Fragment>

      <Box width={3/4} overflow="auto" height="80%">
        <Typography variant="h6">
          510(k) Records
        </Typography>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>510(k) Number</TableCell>
              <TableCell>Sector</TableCell>
              <TableCell>Device Name</TableCell>
              <TableCell>Applicant</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {Object.keys(props.productKClearances).map((productKClearanceId) =>
              <KClearanceTableRow
                key={productKClearanceId}
                productKClearances={props.productKClearances[productKClearanceId]}
                sectors={props.sectors}
                handleDeleteClick={props.handleDeleteClick}
                handleSectorDelete={props.handleSectorDelete}
              />
            )}
          </TableBody>

        </Table>
      </Box>

    </Fragment>
  )
}
