import React, { Fragment } from "react"
import EntityLayout from "../../EntityLayout"
import Flex from "../../Flex"
import Box from "../../Box"
import Select from "../../Select"
import { Button, RaisedButton } from "../../buttons"
import { Table, TableRow, TableHead, TableBody, TableCell } from "../../tables"
import Typography from "@material-ui/core/Typography"
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DeleteButton from "../DeleteButton"
import update from "immutability-helper"
import TextInput from "../../TextInput"
import AlertsTable from "../alerts/DisplayTable"
import { withSnackbar } from "../../SnackbarContainer"

class Profile extends React.Component {
  constructor(props) {
    super(props)
    this.handleDeleteAbstractSector = this.handleDeleteAbstractSector.bind(this)
    this.handleDeleteAbstractProduct = this.handleDeleteAbstractProduct.bind(this)
    this.handleDeleteAbstractTrial = this.handleDeleteAbstractTrial.bind(this)
    this.handleSectorChange = this.handleSectorChange.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleSaveClick = this.handleSaveClick.bind(this)
    this.handleEditClick = this.handleEditClick.bind(this)
    this.handleRebuildAlertClick = this.handleRebuildAlertClick.bind(this)
    this.fetchAlerts = this.fetchAlerts.bind(this);
    this.pollForAlerts = this.pollForAlerts.bind(this);
    this.state = { abstract: this.props.abstract, abstractSectors: this.props.abstract_sectors, abstractProducts: this.props.abstract_products, abstractTrials: this.props.abstract_trials, sector_id: null, presenter: this.props.abstract.presenter, editing: false, alerts: this.props.alerts }
  }

  handleRebuildAlertClick(e) {
    if (!this.state.abstract.url) {
      this.props.snackbarShowMessage("This presentation doesn't have a url. Once a url is added an alert can be created.", "error")
      return
    }
    if (this.state.abstractSectors.length == 0) {
      this.props.snackbarShowMessage("This presentation doesn't have any sectors. You have to add a sector to create an alert.", "error")
      return
    }
    $.ajax({
      type: "POST",
      url: `/admin/abstracts/${this.state.abstract.id}/build_alerts`,
      dataType: 'json',
      success: (data) => {
        this.props.snackbarShowMessage("Alerts are being created now", "success")
        this.pollForAlerts();
      }
    })
  }

  pollForAlerts() {
    this.alertPollingInterval = setInterval(() => {
      this.fetchAlerts();
    }, 2000); // Poll every 2 seconds
  }

  fetchAlerts() {
    $.ajax({
      type: "GET",
      url: `/admin/abstracts/${this.state.abstract.id}/alerts`,
      dataType: 'json',
      success: (data) => {
        if (data.alerts && data.alerts.length > this.state.alerts.length) {
          clearInterval(this.alertPollingInterval);
          this.setState({ alerts: data.alerts });
          this.props.snackbarShowMessage("Alerts have been created.", "success");
        }
      },
      error: () => {
        clearInterval(this.alertPollingInterval);
        this.props.snackbarShowMessage("Failed to fetch alerts. Please try again.", "error");
      }
    });
  }

  handleEditClick(e) {
    this.setState({
      editing: true
    })
  }

  handleChange(e) {
    console.log(e.target.name)
    let abstract = update(this.state.abstract, {
      [e.target.name]: {
        $set: e.target.value
      }
    })

    this.setState({
      abstract: abstract
    })
  }

  handleSaveClick(e) {
    $.ajax({
      type: "PUT",
      url: `/admin/abstracts/${this.state.abstract.id}`,
      data: {
        abstract: this.state.abstract
      },
      dataType: 'json',
      success: (data) => {
        this.setState({
          abstract: data.abstract,
          editing: false
        })
      }
    })
  }


  handleSectorChange(name, value) {
    $.ajax({
      type: 'POST',
      url: `/admin/abstract_sectors`,
      data: {
        abstract_sector: {
          abstract_id: this.props.abstract.id, sector_id: value
        }
      },
      dataType: 'json',
      success: (data) => {

        this.setState({
          abstractSectors: data.abstract_sectors,
          sectorAdding: false
        })
      }
    })
  }

  handleDeleteAbstractTrial(abstractTrial) {
    $.ajax({
      type: 'PUT',
      url: `/admin/abstract_trials/${abstractTrial.id}`,
      data: {
        abstract_trial: {
          deleted: true, deleted_by: this.props.current_user.id
        }
      },
      dataType: 'json',
      success: (data) => {
        let index = this.state.abstractTrials.indexOf(abstractTrial)

        let abstractTrials = update(this.state.abstractTrials, {
          $splice: [[index,1]]
        })

        this.setState({
          abstractTrials: abstractTrials
        })
      },
      error: (data) => {
        this.setState({
          showSnackbar: true,
          snackbarText: "The sector could not be removed. Please try again."
        })
      },
      complete: (data) => {
        setTimeout(() => {
          this.setState({
            showSnackbar: false,
            snackbarText: ''
          })
        }, 5000)
      }
    })
  }

  handleDeleteAbstractProduct(abstractProduct) {
    $.ajax({
      type: 'PUT',
      url: `/admin/abstract_products/${abstractProduct.id}`,
      data: {
        abstract_product: {
          deleted: true, deleted_by: this.props.current_user.id
        }
      },
      dataType: 'json',
      success: (data) => {
        let index = this.state.abstractProducts.indexOf(abstractProduct)

        let abstractProducts = update(this.state.abstractProducts, {
          $splice: [[index,1]]
        })

        this.setState({
          abstractProducts: abstractProducts
        })
      },
      error: (data) => {
        this.setState({
          showSnackbar: true,
          snackbarText: "The sector could not be removed. Please try again."
        })
      },
      complete: (data) => {
        setTimeout(() => {
          this.setState({
            showSnackbar: false,
            snackbarText: ''
          })
        }, 5000)
      }
    })
  }

  handleDeleteAbstractSector(abstractSector) {
    $.ajax({
      type: 'PUT',
      url: `/admin/abstract_sectors/${abstractSector.id}`,
      data: {
        abstract_sector: {
          deleted: true, deleted_by: this.props.current_user.id
        }
      },
      dataType: 'json',
      success: (data) => {
        let index = this.state.abstractSectors.indexOf(abstractSector)

        let abstractSectors = update(this.state.abstractSectors, {
          $splice: [[index,1]]
        })

        this.setState({
          abstractSectors: abstractSectors
        })
      },
      error: (data) => {
        this.setState({
          showSnackbar: true,
          snackbarText: "The sector could not be removed. Please try again."
        })
      },
      complete: (data) => {
        setTimeout(() => {
          this.setState({
            showSnackbar: false,
            snackbarText: ''
          })
        }, 5000)
      }
    })
  }

  render() {
    console.log(this.state.abstract.date)
    return(
      <div>

        <EntityLayout>
          <Box className='w-75'>

            <ExpansionPanel defaultExpanded={true}>
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} ariacontrols={`products-panel`} id={`products-header`}>
                <Flex>
                  <Box>
                    <Typography className="f4">
                      Details
                    </Typography>
                  </Box>
                </Flex>
              </ExpansionPanelSummary>

              <ExpansionPanelDetails>

                <Box width={1}>

                  <Box mb={2} width={1}>
                    <Box width={1}>
                      {this.state.editing ?
                        <TextInput
                          name='title'
                          label='Title'
                          value={this.state.abstract.title}
                          onChange={this.handleChange}
                          className='w-100'
                        />
                      :
                        <Box>
                          Title
                          <Box>{this.state.abstract.title}</Box>
                        </Box>
                      }
                    </Box>
                  </Box>

                  <Box mb={2}>
                    {this.state.editing ?
                      <Fragment>
                        <label>Date</label>
                        <input
                          type="date"
                          name="date"
                          onChange={this.handleChange}
                          value={this.state.abstract.date}
                        />
                      </Fragment>
                    :
                      <Fragment>
                        <Box>Date</Box>
                        <Box>
                          {humanDateFormat(this.state.abstract.date)}
                        </Box>
                      </Fragment>
                    }
                  </Box>


                  <Box mb={2}>
                    Meeting
                    <Box>
                      {this.props.meeting_name}
                    </Box>
                  </Box>

                  <Box mb={2} width={1}>
                    <Box width={1}>
                      {this.state.editing ?
                        <TextInput
                          name='presenter'
                          label='Presenter'
                          value={this.state.abstract.presenter}
                          onChange={this.handleChange}
                          className='w-100'
                        />
                      :
                        <Box>
                          Presenter
                          <Box>{this.state.abstract.presenter}</Box>
                        </Box>
                      }
                    </Box>
                  </Box>

                  <Box mb={2} width={1}>
                    <Box width={1}>
                      {this.state.editing ?
                        <TextInput
                          name='session_title'
                          label='Session title'
                          value={this.state.abstract.session_title}
                          onChange={this.handleChange}
                          className='w-100'
                        />
                      :
                        <Box>
                          Session title
                          <Box>{this.state.abstract.session_title}</Box>
                        </Box>
                      }
                    </Box>
                  </Box>

                  {this.state.editing ?
                    <Box width={1/4}>
                      <RaisedButton onClick={this.handleSaveClick}>
                        Save
                      </RaisedButton>
                    </Box>
                  : ""}
                </Box>

                <Box ml='auto' display="flex" alignItems="end">
                  {this.state.editing ?
                    <Button onClick={() => { this.setState({ editing: false })}}>
                      Done
                    </Button>
                  :
                    <Button onClick={() => { this.setState({ editing: true })}}>
                      Edit
                    </Button>
                  }
                </Box>
              </ExpansionPanelDetails>

            </ExpansionPanel>

            <ExpansionPanel defaultExpanded={true}>
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} ariacontrols={`products-panel`} id={`products-header`}>
                <Flex>
                  <Box>
                    <Typography className="f4">
                      Sectors
                    </Typography>
                  </Box>

                  <Box ml={4}>
                    <Button onClick={() => { this.setState({ addingSector: true })}}>
                      Add Sector
                    </Button>

                    {this.state.addingSector ?
                      <Select
                        label='Sector'
                        name='sector_id'
                        includeBlank={true}
                        value={this.state.sector_id}
                        entities={this.props.sectors}
                        handleChange={this.handleSectorChange}
                      />
                    : ""}
                  </Box>
                </Flex>
              </ExpansionPanelSummary>

              <ExpansionPanelDetails>
                {this.state.abstractSectors.length > 0 ?
                  <Table size='small'>
                    <TableHead>
                      <TableRow>
                        <TableCell>Sector</TableCell>
                        <TableCell>Search Term</TableCell>
                        <TableCell>Score</TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {this.state.abstractSectors.map((abstractSector) =>
                        <TableRow key={abstractSector.id}>
                          <TableCell>{abstractSector.abbr}</TableCell>
                          <TableCell>{abstractSector.search_term}</TableCell>
                          <TableCell>{abstractSector.normalized_score}</TableCell>
                          <TableCell>
                            <DeleteButton onClick={() => { this.handleDeleteAbstractSector(abstractSector) }} />
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                :
                  <Box>No Sectors</Box>
                }
              </ExpansionPanelDetails>

            </ExpansionPanel>

            <ExpansionPanel defaultExpanded={true}>
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} ariacontrols={`products-panel`} id={`products-header`}>
                <Typography className="f4">
                  Products
                </Typography>
              </ExpansionPanelSummary>

              <ExpansionPanelDetails>
                {this.state.abstractProducts.length > 0 ?
                  <Table size='small'>
                    <TableHead>
                      <TableRow>
                        <TableCell>Product</TableCell>
                        <TableCell>Search Term</TableCell>
                        <TableCell>Score</TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {this.state.abstractProducts.map((abstractProduct) =>
                        <TableRow key={abstractProduct.id}>
                          <TableCell>{abstractProduct.short_name}</TableCell>
                          <TableCell>{abstractProduct.search_term}</TableCell>
                          <TableCell>{abstractProduct.normalized_score}</TableCell>
                          <TableCell>
                            <DeleteButton onClick={() => { this.handleDeleteAbstractProduct(abstractProduct) }} />
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                : <Box>No Products</Box>}
              </ExpansionPanelDetails>

            </ExpansionPanel>

            <ExpansionPanel defaultExpanded={true}>
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} ariacontrols={`trials-panel`} id={`trials-header`}>
                <Typography className="f4">
                  Trials
                </Typography>
              </ExpansionPanelSummary>

              <ExpansionPanelDetails>
                {this.state.abstractTrials.length > 0 ?
                  <Table size='small'>
                    <TableHead>
                      <TableRow>
                        <TableCell>Product</TableCell>
                        <TableCell>Search Term</TableCell>
                        <TableCell>Score</TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {this.state.abstractTrials.map((abstractTrial) =>
                        <TableRow key={abstractTrial.id}>
                          <TableCell>{abstractTrial.short_name}</TableCell>
                          <TableCell>{abstractTrial.search_term}</TableCell>
                          <TableCell>{abstractTrial.normalized_score}</TableCell>
                          <TableCell>
                            <DeleteButton onClick={() => { this.handleDeleteAbstractTrial(abstractTrial) }} />
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                :
                  <Box>No trials</Box>
                }
              </ExpansionPanelDetails>

            </ExpansionPanel>

            <ExpansionPanel defaultExpanded={true}>
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} ariacontrols={`text-panel`} id={`text-header`}>
                <Typography className="f4">
                  Alerts
                </Typography>
              </ExpansionPanelSummary>

              <ExpansionPanelDetails className='db'>
                <div className="mb3">
                  <Button onClick={this.handleRebuildAlertClick}>
                    Build Alerts
                  </Button>
                </div>

                <Box>
                  {this.state.alerts ?
                    <AlertsTable
                      alerts={this.state.alerts}
                    />
                  :
                    <Box>No alerts</Box>
                  }
                </Box>
              </ExpansionPanelDetails>

            </ExpansionPanel>

            <ExpansionPanel defaultExpanded={true}>
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} ariacontrols={`conclusion-panel`} id={`conclusion-header`}>
                <Typography className="f4">
                  Conclusion
                </Typography>
              </ExpansionPanelSummary>

              <ExpansionPanelDetails>
                <Typography className='w-100'>
                  {this.props.abstract_conclusion && this.props.abstract_conclusion.conclusion ? 
                    <div dangerouslySetInnerHTML={{__html: this.props.abstract_conclusion.conclusion }} />
                  : <div>No conclusion has been added. You'll need to add a conclusion to create an alert.</div>}
                </Typography>
              </ExpansionPanelDetails>

            </ExpansionPanel>

            {this.props.abstract_texts.length > 0 ?
              <ExpansionPanel defaultExpanded={true}>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} ariacontrols={`text-panel`} id={`text-header`}>
                  <Typography className="f4">
                    Text
                  </Typography>
                </ExpansionPanelSummary>

                <ExpansionPanelDetails>
                  <Box>
                    {this.props.abstract_texts.map((abstractText) =>
                      <p>{abstractText.text}</p>
                    )}
                  </Box>
                </ExpansionPanelDetails>

              </ExpansionPanel>
            : ''}
          </Box>

        </EntityLayout>
      </div>
    )
  }
}

export default withSnackbar(Profile)