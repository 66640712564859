import React, { Component } from "react"
import PropTypes from "prop-types"
import ReactDOM from "react-dom"
import Link from "../Link"
import { Table, TableRow, TableCell, TableHead, TableBody } from "../tables"
import YearsEntities from "./YearsEntities"

export default class ActivityDataTable extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    const firstHeaderHash = { 'abstract_person': 'Meeting', 'trial_investigator': 'Site', 'manuscript_person': 'Journal' }
    const secondHeaderHash = { 'abstract_person': 'Abstract', 'trial_investigator': 'Trial', 'manuscript_person': 'Manuscript' }
    return (
      <React.Fragment>
        <Table size='small'>
          <TableHead>
            <TableRow hover={false}>
              <TableCell>{firstHeaderHash[this.props.chartEntityType]}</TableCell>
              <TableCell>{secondHeaderHash[this.props.chartEntityType]}</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {Object.keys(this.props.data).map((year) =>
              <YearsEntities
                key={year}
                year={year}
                data={this.props.data[year]}
              />
            )}
          </TableBody>
        </Table>
      </React.Fragment>
    );
  }
}
