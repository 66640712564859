import React, { Fragment } from "react"
import PropTypes from "prop-types"
import Card from "./cards/Card.jsx"
import Link from "./Link"
import Box from "./Box"
import Paper from "./Paper"
// import AlertDisplay from "./alerts/AlertDisplay"
import AlertDisplayContainer from "./alerts/AlertDisplayContainer"
import TrialDevelopmentAlert from "./alerts/TrialDevelopmentAlert"
import ProductDevelopmentAlert from "./alerts/ProductDevelopmentAlert"
import FinancingAlerts from "./alerts/FinancingAlerts"
import YearFilter from "./YearFilter"
import AlertGroupFilter from "./AlertGroupFilter"
import EntityLayout from "./EntityLayout"
import Typography from '@material-ui/core/Typography';
import AlertDisplay from "./alerts/AlertDisplay"

export default function EntityAlerts(props) {

  function groupBy(list, props) {
    return list.reduce((a, b) => {
       (a[b[props]] = a[b[props]] || []).push(b);
       return a;
    }, {});
  }

  let groupedAlerts = groupBy(props.alerts, "header_id")

  return(
    <div>

      <EntityLayout reverseColumn breakpoint='m'>

        <Box width={[1, 3/5, 3/5]} mb={[3, 0, 0]}>
          <Paper minFullHeight>
            <Typography variant="h6" component="h6" className='mt0 mb3'>
              Alerts
            </Typography>
            {props.years ? <YearFilter {...props} /> : ''}
            <div>
              {props.alerts.length > 0 ?
                <AlertDisplayContainer
                  groupedAlerts={groupedAlerts}
                />
              : 'No alerts found. Please try another group.'}
            </div>
          </Paper>
        </Box>

        <Box width={[1, 3/10, 3/10]} ml='auto'>
          <Card background={props.cardBackground}>
            <Typography variant="h6" component="h6" className='mt0 mb2'>
              Alert groups
            </Typography>
            <div>
              {props.alertGroups.length > 0 ?
                <AlertGroupFilter
                  alertGroups={props.alertGroups}
                  groupId={props.groupId}
                  sector={props.sector}
                  entity={props.entity}
                  entityType={props.entityType}
                />
              : 'No alert groups found'}
            </div>
          </Card>
        </Box>
      </EntityLayout>

    </div>
  );
}

EntityAlerts.propTypes = {
  entity: PropTypes.object
}
