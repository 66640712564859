import React, { Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Flex from "../Flex"
import Box from "../Box"
import FilterChip from '../filters/FilterChip';

export default function FilterChipsContainer(props) {

  let companyOptionValues = props.filterOptions.companies.map(company => company.value)
  const selectedCompanies = props.chosen.companies.filter(company => props.filterValues.company_ids.includes(company.id) ? company : null)

  let productOptionValues = props.filterOptions.products.map(product => product.value)
  const selectedProducts = props.chosen.products.filter(product => props.filterValues.product_ids.includes(product.id) ? product : null)

  let journalOptionValues = props.filterOptions.journals.map(journal => journal.value)
  const selectedJournals = props.chosen.journals.filter(journal => props.filterValues.journal_ids.includes(journal.id) ? journal : null)

  let targetSiteOptionValues = props.filterOptions.targetSites.map(targetSite => targetSite.value)
  const selectedTargetSites = props.chosen.targetSites.filter(targetSite => props.filterValues.target_site_ids.includes(targetSite.id) ? targetSite : null)

  let therapyTypeOptionValues = props.filterOptions.therapyTypes.map(therapyType => therapyType.value)
  const selectedTherapyTypes = props.chosen.therapyTypes.filter(therapyType => props.filterValues.therapy_type_ids.includes(therapyType.id) ? therapyType : null)

  let targetMarketOptionValues = props.filterOptions.targetMarkets.map(targetMarket => targetMarket.value)
  const selectedTargetMarkets = props.chosen.targetMarkets.filter(targetMarket => props.filterValues.target_market_ids.includes(targetMarket.id) ? targetMarket : null)

  let trialOptionValues = props.filterOptions.trials.map(trial => trial.value)
  const selectedTrials = props.chosen.trials.filter(trial => props.filterValues.trial_ids.includes(trial.id) ? trial : null)

  return(
    <Fragment>

      {selectedTrials.map((activeFilter) =>
        <FilterChip
          key={activeFilter.value}
          name={activeFilter.acronym || activeFilter.short_name}
          value={activeFilter.id}
          className={trialOptionValues.includes(activeFilter.id) ? '' : 'o-60'}
          type='trial_ids'
          handleDelete={props.handleDelete}
        />
      )}

      {selectedProducts.map((activeFilter) =>
        <FilterChip
          key={activeFilter.value}
          name={activeFilter.short_name}
          value={activeFilter.id}
          className={productOptionValues.includes(activeFilter.id) ? '' : 'o-60'}
          type='product_ids'
          handleDelete={props.handleDelete}
        />
      )}

      {selectedTargetMarkets.map((activeFilter) =>
        <FilterChip
          key={activeFilter.value}
          name={activeFilter.name}
          value={activeFilter.id}
          className={targetMarketOptionValues.includes(activeFilter.id) ? '' : 'o-60'}
          type='target_market_ids'
          handleDelete={props.handleDelete}
        />
      )}

      {selectedTargetSites.map((activeFilter) =>
        <FilterChip
          key={activeFilter.value}
          name={activeFilter.name}
          value={activeFilter.id}
          className={targetSiteOptionValues.includes(activeFilter.id) ? '' : 'o-60'}
          type='target_site_ids'
          handleDelete={props.handleDelete}
        />
      )}

      {selectedTherapyTypes.map((activeFilter) =>
        <FilterChip
          key={activeFilter.value}
          name={activeFilter.name}
          value={activeFilter.id}
          className={therapyTypeOptionValues.includes(activeFilter.id) ? '' : 'o-60'}
          type='therapy_type_ids'
          handleDelete={props.handleDelete}
        />
      )}

      {selectedJournals.map((activeFilter) =>
        <FilterChip
          key={activeFilter.value}
          name={activeFilter.journal_name}
          value={activeFilter.id}
          className={journalOptionValues.includes(activeFilter.id) ? '' : 'o-60'}
          type='journal_ids'
          handleDelete={props.handleDelete}
        />
      )}

      {props.filterValues.query && props.search ?
        <FilterChip
          name={props.filterValues.query}
          value={props.filterValues.query}
          type='query'
          handleDelete={props.handleDelete}
        />
      : ''}

    </Fragment>
  )
}
