import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import TextInput from "../../TextInput"
import Link from "../../Link"
import Select from "../../Select"
import ButtonLayout from "../ButtonLayout"
import RaisedButton from "../../buttons/RaisedButton"
import Box from "../../Box"
import List from "../../lists/List"
import SectorListItem from "../../sectors/SectorListItem";
import Typography from '@material-ui/core/Typography';



class NewProductForm extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    return(
      <Fragment>
        <Box mb={4}>
          <TextInput
            name='full_name'
            label='Full Name'
            value={this.props.product.full_name}
            onChange={this.props.handleChange}
            fullWidth
          />
        </Box>

        <Box mb={4}>
          <TextInput
            name='short_name'
            label='Short Name'
            value={this.props.product.short_name}
            onChange={this.props.handleChange}
            fullWidth
          />
        </Box>

        <Box mb={4}>
          <TextInput
            name='url'
            label='URL'
            value={this.props.product.url}
            onChange={this.props.handleChange}
            fullWidth
          />
        </Box>

        <Box mb={4}>
          <Select
            entities={this.props.companies}
            label='Company'
            name='company_id'
            value={this.props.product.company_id}
            displayEmpty={true}
            handleChange={this.props.handleCompanyChange}
          />
        </Box>

        <Box mb={4}>
          <Typography variant="h6" component="h6">
            Sectors
          </Typography>

          <List>
            {this.props.sectors.map((sector) =>
              <SectorListItem
                key={sector.id}
                sector={sector}
                checked={this.props.productSectors ? this.props.productSectors.includes(sector.id) : false}
                handleCheckboxSelect={this.props.handleCheckboxSelect}
              />
            )}
          </List>
        </Box>

        <ButtonLayout>
          <RaisedButton handleClick={this.props.handleSaveClick}>
            Save Product
          </RaisedButton>
        </ButtonLayout>

      </Fragment>
    )
  }
}


export default NewProductForm
