import React from "react"
import { TableRow, TableCell } from "../tables"

export default function NavigatorRow(props) {
  const trialLength = props.product.trials.length
  const targetMarketLength = props.product.target_markets.length
  
  const rowSpan = targetMarketLength > trialLength ? targetMarketLength : trialLength
  
  return(
    <TableRow>
      <TableCell borderRight={1} rowSpan={rowSpan}>
        {props.product.short_name}
      </TableCell>
      
      <TableCell borderRight={1} rowSpan={rowSpan}>
        {props.product.company.short_name}
      </TableCell>
      
      {/* <TableCell borderRight={1} className='ph0'>
        {props.product.target_markets.map((targetMarket) =>
          <TableRow key={targetMarket.id}>
            <TableCell>
              {targetMarket.name}
            </TableCell>
          </TableRow>
        )}
      </TableCell> */}
      
      <TableCell borderRight={1} rowSpan={rowSpan}>
        {props.product.market_status.name}
      </TableCell>
      
      <TableCell borderRight={1} className='ph0 pr0'>
        {props.product.trials.map((trial) => 
          <TableRow key={trial.id}>
            <TableCell className={trialLength > 1 ? 'bb' : 'bn'}>
              {trial.acronym || trial.short_name}
            </TableCell>
            
            {/* <TableCell>
              {trial.status.name}
            </TableCell> */}
          </TableRow>
        )}
      </TableCell>
      
      <TableCell borderRight={1} className='ph0 pr0'>
        {props.product.trials.map((trial, index) =>
          <TableRow key={trial.status.id}>
            <TableCell className={(trialLength > 1 || (index + 1) != trialLength) ? 'bb' : 'bn'}>
              {trial.status.name}
            </TableCell>
          </TableRow>
        )}
      </TableCell>
      
    </TableRow>
  )
}
