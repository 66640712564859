import React, { Fragment } from "react"
import Flex from "../../Flex"
import Box from "../../Box"
import TextInput from "../../TextInput"
import ButtonLayout from "../ButtonLayout"
import { RaisedButton } from "../../buttons"
import DatePicker from "../../DatePicker"
import Select from "../../Select"

export default function Form(props) {
  return (
    <Fragment>
      <Flex align='items-start' justifyContent='space-between'>

        <Box>
          <Select
            name='company_id'
            value={props.investorPresentation.company_id}
            label='Company'
            entities={props.companies}
            handleChange={props.handleCompanyChange}
          />
        </Box>

        <Box>
          <DatePicker
            name='date'
            label='Date'
            handleChange={props.handleDateChange}
            value={props.investorPresentation.date}
          />
        </Box>

        <Box width={1/2}>
          <TextInput
            name='url'
            label='URL'
            value={props.investorPresentation.url}
            onChange={props.handleChange}
            fullWidth
          />
        </Box>

      </Flex>

      <Box width={1} my={3}>
        <TextInput
          name='title'
          label='Title'
          multiline={true}
          value={props.investorPresentation.title}
          onChange={props.handleChange}
          fullWidth
        />
      </Box>

      <ButtonLayout>
        <RaisedButton handleClick={props.handleSaveClick}>
          Save Investor Presentation
        </RaisedButton>
      </ButtonLayout>
    </Fragment>
  )
}
