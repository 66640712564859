import React, { Component } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Button from "./Button"

class OutlinedButton extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    return(
      <Button variant='outlined' {...this.props}>
        {this.props.children}
      </Button>
    )
  }
}

export default OutlinedButton
