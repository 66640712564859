import React from "react"
import { Table, TableRow, TableCell, TableHead, TableBody } from "../../tables"
import Link from "../../Link"


export default function List(props) {
  return(
    <Table>
      <TableBody>
        {props.newsArticles.map((newsArticle) =>
          <TableRow key={newsArticle.id}>
            <TableCell>
              <Link href={`/admin/news_articles/${newsArticle.id}`}>
                {newsArticle.title}
              </Link>
            </TableCell>
            <TableCell>
              {humanDateFormat(newsArticle.date)}
            </TableCell>
            <TableCell>{newsArticle.first_name ? displayInitials(newsArticle.first_name, newsArticle.last_name) : "SYS"}</TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  )
}
