import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Paper from "../../Paper"
import TextInput from "../../TextInput"
import { Button, RaisedButton } from "../../buttons"
import Typography from '@material-ui/core/Typography';
import Flex from "../../Flex"
import Box from "../../Box"
import { List, ListItem, ListItemText } from "../../lists"
import update from "immutability-helper"
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ExpandLess from '../../ExpandLess';
import ExpandMore from '../../ExpandMore';
import Collapse from '@material-ui/core/Collapse';
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction"
import TrialSearchResultsList from "../trials/SearchResultsList"
import CtGovTrialSearchResultsList from "../ct_gov_trials/SearchResultsList"
import ManuscriptSearchResultsList from "../manuscripts/SearchResultsList"
import AbstractSearchResultsList from "../abstracts/SearchResultsList"
import NewsSearchResultsList from "../news/SearchResultsList"
import CircularProgress from '@material-ui/core/CircularProgress';
import { Tabs, Tab, TabPanel } from "../../tabs"
import Snackbar from "../../Snackbar"
import Checkbox from "../../Checkbox"
import SearchTermForm from "../search_terms/SearchTermForm"
import Link from "../../Link"

export default class SearchTermContainer extends Component {
  constructor(props) {
    super(props)
    this.handleTermChange = this.handleTermChange.bind(this)
    this.handleSaveClick = this.handleSaveClick.bind(this)
    this.handleTestClick = this.handleTestClick.bind(this)
    this.handleActiveIndexClick = this.handleActiveIndexClick.bind(this)
    this.handleCheckboxClick = this.handleCheckboxClick.bind(this)
    this.getTestResults = this.getTestResults.bind(this)
    this.handleSectorTabClick = this.handleSectorTabClick.bind(this)
    this.getSectorTestResults = this.getSectorTestResults.bind(this)
    this.state = { sector: this.props.sector, sectorSearchTerm: this.props.sector_search_term, abstracts: this.props.abstracts, manuscripts: this.props.manuscripts, abstractsCount: this.props.abstracts_count, manuscriptsCount: this.props.manuscripts_count, trials: this.props.trials, trialsCount: this.props.trials_count, ctGovTrials: this.props.ct_gov_trials, ctGovTrialsCount: this.props.ct_gov_trials_count, news: this.props.news, newsCount: this.props.news_count, loading: false, activeIndex: 0, showSnackbar: false, snackbarText: '' }
  }

  handleActiveIndexClick(e, newValue) {
    this.setState({
      activeIndex: newValue
    })
  }

  handleSectorTabClick(e, newValue) {
    this.getSectorTestResults({ sector_id: newValue })
  }

  handleCheckboxClick(e) {
    let sectorSearchTerm = update(this.state.sectorSearchTerm, {
      [e.target.name]: { $set: e.target.checked }
    })

    this.setState({
      sectorSearchTerm: sectorSearchTerm
    })
  }

  handleTestClick(e) {
    this.getTestResults({ term: this.state.sectorSearchTerm.search_term, sector_id: this.state.sector.id })
  }

  getSectorTestResults(searchParams) {
    $.ajax({
      type: 'GET',
      url: `/admin/sectors/search_terms`,
      data: searchParams,
      dataType: 'json',
      success: (data) => {
        this.setState({
          abstracts: data.abstracts,
          sector: data.sector,
          manuscripts: data.manuscripts,
          trials: data.trials,
          ctGovTrials: data.ct_gov_trials,
          abstractsCount: data.abstracts_count,
          manuscriptsCount: data.manuscripts_count,
          trialsCount: data.trials_count,
          ctGovTrialsCount: data.ct_gov_trials_count,
          sectorSearchTerm: data.sector_search_term,
          loading: false
        })
      },
      beforeSend: (data) => {
        this.setState({
          loading: true
        })
      },
      error: (data) => {
        this.setState({
          loading: false,
          showSnackbar: true,
          snackbarText: 'There was an error fetching results. Please try again.'
        })
      },
      complete: (data) => {
        setTimeout(() => {
          this.setState({
            showSnackbar: false,
            snackbarText: ''
          })
        }, 5000)
      }
    })
  }

  getTestResults(searchParams) {
    if (searchParams.term == "") {
      this.setState({
        abstracts: [],
        manuscripts: [],
        sectorSearchTerm: ""
      })
    } else {
      $.ajax({
        type: 'GET',
        url: `/admin/sectors/term_search`,
        data: searchParams,
        dataType: 'json',
        success: (data) => {
          console.log(data.abstracts_count)
          this.setState({
            abstracts: data.abstracts,
            manuscripts: data.manuscripts,
            trials: data.trials,
            ctGovTrials: data.ct_gov_trials,
            ctGovTrialsCount: data.ct_gov_trials_count,
            trialsCount: data.trials_count,
            abstractsCount: data.abstracts_count,
            manuscriptsCount: data.manuscripts_count,
            loading: false
          })
        },
        beforeSend: (data) => {
          this.setState({
            loading: true
          })
        },
        error: (data) => {
          this.setState({
            loading: false,
            showSnackbar: true,
            snackbarText: 'There was an error fetching results. Please try again.'
          })
        },
        complete: (data) => {
          setTimeout(() => {
            this.setState({
              showSnackbar: false,
              snackbarText: ''
            })
          }, 5000)
        }
      })
    }
  }

  handleSaveClick(e) {
    if (this.state.sectorSearchTerm.id) {
      $.ajax({
        type: 'PUT',
        url: `/admin/sectors/${this.state.sector.id}/sector_search_terms/${this.state.sectorSearchTerm.id}`,
        data: {
          sector_search_term: this.state.sectorSearchTerm
        },
        dataType: 'json',
        success: (data) => {
          this.setState({
            sectorSearchTerm: data.sector_search_term,
            showSnackbar: true,
            snackbarText: 'Search term was saved'
          })
        },
        error: (data) => {
          this.setState({
            showSnackbar: true,
            snackbarText: data.responseText
          })
        },
        complete: (data) => {
          setTimeout(() => {
            this.setState({
              showSnackbar: false,
              snackbarText: ''
            })
          }, 5000)
        }
      })
    } else {
      $.ajax({
        type: 'POST',
        url: `/admin/sectors/${this.props.sector.id}/sector_search_terms`,
        data: {
          sector_search_term: this.state.sectorSearchTerm
        },
        dataType: 'json',
        success: (data) => {
          window.location.href = `/admin/sectors/${this.props.sector.abbr}/edit`
          // this.setState({
          //   sectorSearchTerm: data.sector_search_term,
          //   showSnackbar: true,
          //   snackbarText: 'Search term was created'
          // })
        },
        error: (data) => {
          this.setState({
            showSnackbar: true,
            snackbarText: data.responseText
          })
        },
        complete: (data) => {
          setTimeout(() => {
            this.setState({
              showSnackbar: false,
              snackbarText: ''
            })
          }, 5000)
        }
      })
    }
  }

  handleTermChange(e) {
    let sectorSearchTerm = update(this.state.sectorSearchTerm, {
      search_term: { $set: e.target.value }
    })

    this.setState({
      sectorSearchTerm: sectorSearchTerm
    })
  }

  groupBy(list, props) {
    return list.reduce((a, b) => {
       (a[b[props]] = a[b[props]] || []).push(b);
       return a;
    }, {});
  }

  render() {

    const groupedCtGovTrials = this.state.ctGovTrials.length > 0 ? groupBy(this.state.ctGovTrials, 'nct_id') : []

    return(
      <Fragment>

        {/* <Paper>
          <Tabs className='bb b--black-10' variant="fullWidth" value={this.state.sector.id} onChange={this.handleSectorTabClick}>
            {this.props.sectors.map((sector) =>
              <Tab key={sector.id} value={sector.id} label={sector.abbr} />
            )}
          </Tabs>
        </Paper> */}

        <Paper minFullHeight>

          <Typography variant="h6" component="h6">
            {`${this.props.sector.abbr} Search Term`}
          </Typography>

          <Box mt={3}>

            <SearchTermForm
              searchTerm={this.state.sectorSearchTerm}
              multiline={true}
              handleTermChange={this.handleTermChange}
              handleTestClick={this.handleTestClick}
              handleSaveClick={this.handleSaveClick}
              handleCheckboxClick={this.handleCheckboxClick}
            />

            {this.state.loading ?
              <Box>
                <CircularProgress />
              </Box>
            : ''}

            <Box mt={4}>
              <Typography variant="h6" component="h6">
                Results
              </Typography>

              <Box mt={4}>
                <Tabs variant="fullWidth" value={this.state.activeIndex} onChange={this.handleActiveIndexClick} className='bb b--black-10'>
                  <Tab label={`Trials (${this.state.trialsCount})`} />
                  <Tab label={`Ct Gov Trials (${Object.keys(groupedCtGovTrials).length})`} />
                  <Tab label={`Presentations (${this.state.abstractsCount})`} />
                  <Tab label={`Publications (${this.state.manuscriptsCount})`} />
                  <Tab label={`News (${this.state.newsCount})`} />
                </Tabs>
              </Box>

              {/* <TabPanel value={this.state.activeIndex} index={0}> */}
              {this.state.activeIndex == 0 ?
                <TrialSearchResultsList
                  trials={this.state.trials}
                />
              : ''}
              {/* </TabPanel> */}

              <TabPanel value={this.state.activeIndex} index={1}>
                <CtGovTrialSearchResultsList
                  ctGovTrials={groupedCtGovTrials}
                />
              </TabPanel>

              <Box overflow='auto'>
                {/* <TabPanel value={this.state.activeIndex} index={2}> */}
                { this.state.activeIndex == 2 ?
                  <AbstractSearchResultsList
                    abstracts={this.state.abstracts}
                  />
                : ''}
                {/* </TabPanel> */}


                {this.state.activeIndex == 3 ?
                  <ManuscriptSearchResultsList
                    manuscripts={this.state.manuscripts}
                  />
                : ""}

                {this.state.activeIndex == 4 ?
                  <NewsSearchResultsList
                    news={this.state.news}
                  />
                : ""}
              </Box>
            </Box>
          </Box>
        </Paper>

        <Snackbar
          open={this.state.showSnackbar}
          message={this.state.snackbarText}
        />

      </Fragment>
    )
  }
}
