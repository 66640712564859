import React, { Component, Fragment } from "react"
import TrialsTable from "./trials/TrialsTable"
import Paper from "../Paper"
import Flex from "../Flex"
import Box from "../Box"
import Typography from "@material-ui/core/Typography"
import update from "immutability-helper"
import Filters from "./trials/Filters"
import { withSnackbar } from "../SnackbarContainer"
import CompareIconDisplay from "../trials/CompareIconDisplay"

class Trials extends Component {
  constructor(props) {
    super(props)
    this.handleChangePage = this.handleChangePage.bind(this)
    this.handleRequestSort = this.handleRequestSort.bind(this)
    this.getTrials = this.getTrials.bind(this)
    this.handleFilterChange = this.handleFilterChange.bind(this)
    this.handleBulkTrialCheck = this.handleBulkTrialCheck.bind(this)
    this.handleTrialCheckboxClick = this.handleTrialCheckboxClick.bind(this)
    this.state = { trials: this.props.trials, trialsCount: this.props.trials_count, checkedTrials: [], studyType: 'Clinical Trials', view: 'list', filterValues: { product_ids: [], clinical_type_ids: [], clinical_status_ids: [], roles: [], sponsor_ids: [], order: 'desc', order_by: 'updated_at', page: 0 }, filterOptions: { products: this.props.products, clinicalTypes: this.props.clinical_types, roles: this.props.roles, clinicalStatuses: this.props.clinical_statuses, sponsors: this.props.sponsors } }
  }

  handleBulkTrialCheck(e) {
    if (this.state.checkedTrials.length > 0) {
      this.setState({
        checkedTrials: []
      })
    } else {
      const trialIds = this.state.trials.map((trial) => trial.trial_id)

      this.setState({
        checkedTrials: trialIds
      })
    }
  }

  handleTrialCheckboxClick(trialId) {
    const index = this.state.checkedTrials.indexOf(trialId)


    const trials = index == -1 ?
      update(this.state.checkedTrials, {
        $push: [trialId]
      })
    :
      update(this.state.checkedTrials, {
        $splice: [[index,1]]
      })

    this.setState({
      checkedTrials: trials
    })
  }

  handleChangePage(e, newPage) {
    let params = Object.assign({}, this.state.filterValues)

    params["order_by"] = this.state.orderBy
    params["order"] = this.state.order
    params["page"] = newPage

    this.getTrials(params)
  }

  handleFilterChange(name, value) {
    let params = Object.assign({}, this.state.filterValues)
    
    params[name] = value

    this.getTrials(params)
  }

  getTrials(params) {
    $.ajax({
      type: 'GET',
      url: `/sectors/${this.props.sector.abbr}/people/${this.props.person.id}/trials`,
      data: params,
      dataType: 'json',
      beforeSend: (data) => {
        this.setState({
          loading: true
        })
      },
      success: (data) => {
        let filterOptions = { products: data.products, clinicalTypes: data.clinical_types, roles: data.roles, clinicalStatuses: data.clinical_statuses, sponsors: data.sponsors }
        
        this.setState({
          trials: data.trials,
          trialsCount: data.trials_count,
          loading: false,
          filterValues: params,
          filterOptions: filterOptions
        })
      },
      error: (data) => {
        this.setState({
          showSnackbar: true,
          snackbarText: 'An error has occurred while trying to load the data. Please try again.'
        })
      },
      complete: (data) => {
        setTimeout(() => {
          this.setState({
            showSnackbar: false,
            snackbarText: ''
          })
        })
      }
    })
  }

  handleRequestSort(event, property) {
    let params = Object.assign({}, this.state.filterValues)

    params["order_by"] = property

    let isDesc = this.state.orderBy === property && this.state.order === 'desc';

    params["order"] = isDesc ? 'asc' : 'desc'

    this.getTrials(params)
  };

  render() {
    return(
      <Fragment>

        <Paper fullHeight>

          <Box height="100%">

            <Box width={1}>
              <Filters 
                filterOptions={this.state.filterOptions}
                filterValues={this.state.filterValues}
                handleFilterChange={this.handleFilterChange}
              />
            </Box>

            {Object.keys(this.props.trials).length > 0 ?
              <Fragment>

                  <Box mb={2}>
                    <Flex justifyContent='right' alignItems='center'>

                      <Box>
                        <Typography variant="body2">
                          {`${this.state.trialsCount} trials`}
                        </Typography>
                      </Box>

                      <Box>
                        <CompareIconDisplay
                          checkedTrials={this.state.checkedTrials}
                          sector={this.props.sector}
                          snackbarShowMessage={this.props.snackbarShowMessage}
                        />
                      </Box>


                    </Flex>
                  </Box>

                  <TrialsTable
                    trials={this.state.trials}
                    trialsCount={this.state.trialsCount}
                    page={this.state.filterValues.page}
                    order={this.state.filterValues.order}
                    orderBy={this.state.filterValues.order_by}
                    sector={this.props.sector}
                    checkedTrials={this.state.checkedTrials}
                    handleRequestSort={this.handleRequestSort}
                    handleChangePage={this.handleChangePage}
                    handleTrialCheckboxClick={this.handleTrialCheckboxClick}
                    handleBulkTrialCheck={this.handleBulkTrialCheck}
                  />

              </Fragment>
            :
                <Flex justifyContent='center' height="50%">
                    <Box alignSelf='center' maxWidth="50%">
                    <Box>
                        <Typography variant="body1">
                            No trials found for this product
                        </Typography>
                    </Box>
                    </Box>
                </Flex>
            }
          </Box>

        </Paper>
      </Fragment>
    )
  }
}

export default withSnackbar(Trials)