// https://material-ui.com/api/button/
import React from "react"
import Button from "./Button"

export default function RaisedButton(props) {
  const { handleClick, ...rest } = props
  return (
    <Button variant='contained' fullWidth={true} onClick={handleClick} {...rest}>
      {props.children}
    </Button>
  )
}
