import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import ProfileForm from "./ProfileForm"
import Paper from "../../Paper"
import Snackbar from "../../Snackbar"
import update from "immutability-helper"


class NewContainer extends Component {
  constructor(props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
    this.handleSaveClick = this.handleSaveClick.bind(this)
    this.state = { technologyGroup: this.props.technology_group, showSnackbar: false, snackbarText: '', activeTab: 'New Technology Group' }
  }
  
  UNSAFE_componentWillMount() {
    let technologyGroup = update(this.state.technologyGroup, { created_by: { $set: this.props.current_user.id }})
    this.setState({
      technologyGroup: technologyGroup
    })
  }
  
  handleTabClick(e) {
    e.preventDefault()
    this.setState({
      activeTab: e.target.text
    })
  }
  
  handleChange(e) {
    const name = e.currentTarget.name
    const value = e.currentTarget.value
    let technologyGroup = update(this.state.technologyGroup, { [name]: { $set: value } })
    this.setState({
      technologyGroup: technologyGroup
    })
    
  }
  
  handleSaveClick(e) {
    const url = this.props.redirect_url ? `/admin/technology_groups?r=${this.props.redirect_url}` : "/admin/technology_groups"
    $.ajax({
      type: 'POST',
      url: url,
      data: {
        technology_group: this.state.technologyGroup
      },
      error: (data) => {
        this.setState({
          showSnackbar: true,
          snackbarText: 'Technology group could not be saved. Please try again.'
        })
      },
      complete: (data) => {
        setTimeout(() => {
          this.setState({
            showSnackbar: false,
            snackbarText: ''
          })
        }, 4000)
      }
    })
  }
  
  render() {
    return(
      <Fragment>
        
        <Paper>
          
          
          <ProfileForm 
            technologyGroup={this.state.technologyGroup}
            handleChange={this.handleChange}
            handleSaveClick={this.handleSaveClick}
          />

        </Paper>
        
        <Snackbar 
          open={this.state.showSnackbar}
          message={this.state.snackbarText} 
        />
      </Fragment>
    )
  }
}


export default NewContainer
