import React, { Fragment } from 'react'
import { Table, TableBody, TableHeadSort } from '../../tables'
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TablePaginationActions from "../../tables/TablePaginationActions"
import DeviceRow from './DeviceRow'

export default function DevicesTable(props) {
  let headers = [{ label: 'ID', name: 'device_id' }, { label: 'Device', name: 'brand_name' }, { label: 'Model #', name: 'version_model_number' }, { label: 'Company', name: 'company_name' }, { label: 'Date', name: 'device_publish_date' }, { label: "Hide", name: "" }]

  return (
    <Fragment>
      <Table stickyHeader size="small">
        <TableHeadSort 
          headers={headers}
          // order={this.state.order}
          // orderBy={this.state.orderBy}
          // onRequestSort={this.handleRequestSort}
        />
              
        <TableBody>
          {props.nihDevices.map((nihDevice) =>
            <DeviceRow
              key={nihDevice.id}
              nihDevice={nihDevice}
            />
          )}
        </TableBody>
      
        <TableFooter>
          <TablePagination
            colSpan={7}
            count={props.nihDevicesCount}
            rowsPerPage={100}
            page={props.page}
            SelectProps={{
              inputProps: { 'aria-label': 'rows per page' },
              native: true,
            }}
            onChangePage={props.handlePageChange}
            onChangeRowsPerPage={props.handleChangeRowsPerPage}
            ActionsComponent={TablePaginationActions}
          />
        </TableFooter>
      </Table>
    </Fragment>
  )
}
