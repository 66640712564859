import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import Box from "../../Box"
import { withSnackbar } from '../../SnackbarContainer'
import Associator from '../Associator'
import ControlPanel from '../associator/ControlPanel'
import Paper from '../../Paper'
import ProductsCheckboxList from '../products/CheckboxList'
import PmaApprovalCard from './PmaApprovalCard'
import EntitiesPanel from '../associator/EntitiesPanel'
import InlineSearchContainer from '../search/InlineSearchContainer'
import update from "immutability-helper"
import EntityPanelContainer from "../associator/EntityPanelContainer"
import AssociatedList from '../associator/AssociatedList'

class AssociatorContainer extends Component {
  constructor(props) {
    super(props)
    this.handleAssociateClick = this.handleAssociateClick.bind(this)
    this.handleCheckboxSelect = this.handleCheckboxSelect.bind(this)
    this.handleClearSearchClick = this.handleClearSearchClick.bind(this)
    this.handleSearchClick = this.handleSearchClick.bind(this)
    this.handleProductDeleteClick = this.handleProductDeleteClick.bind(this)
    this.handleActiveIndexUpdate = this.handleActiveIndexUpdate.bind(this)
    this.handleSectorCheckboxSelect = this.handleSectorCheckboxSelect.bind(this)
    this.addSectorsToExistingProductRecord = this.addSectorsToExistingProductRecord.bind(this)
    this.state = { products: this.props.products, associatedProducts: this.props.associated_products, pmaApprovalSectors: this.props.pma_approval_sectors, query: "", activeProducts: [], activeIndex: 'products', sectorIds: [] }
  }

  handleSectorCheckboxSelect(sector, checked) {
    if (this.state.associatedProducts.length > 0) {
      this.addSectorsToExistingProductRecord(sector, checked)
    } else {

      let index = this.state.sectorIds.indexOf(sector.id)
      
      let sectorIds = ""
      if (index == -1) {
        sectorIds = update(this.state.sectorIds, {
          $push: [sector.id]
        })
      } else {
        sectorIds = update(this.state.sectorIds, {
          $splice: [[index, 1]]
        })
      }

      this.setState({
        sectorIds: sectorIds
      })
    }
  }

  addSectorsToExistingProductRecord(sector, checked) {
    if (checked) {
      let productFdaPmaApprovalSector = this.state.pmaApprovalSectors.filter((pmaApprovalSector) => pmaApprovalSector.sector_id == sector.id)[0]
      
      $.ajax({
        type: "DELETE",
        url: `/admin/product_fda_pma_approval_sectors/${productFdaPmaApprovalSector.id}`,
        dataType: 'json',
        success: (data) => {
          let index = this.state.pmaApprovalSectors.indexOf(productFdaPmaApprovalSector)

          let pmaApprovalSectors = update(this.state.pmaApprovalSectors, {
            $splice: [[index, 1]]
          })

          this.setState({
            pmaApprovalSectors: pmaApprovalSectors
          })
        }
      })
    } else {
      $.ajax({
        type: "POST",
        url: `/admin/product_fda_pma_approval_sectors`,
        data: {
          product_fda_pma_approval_sector: {
            product_fda_pma_approval_id: this.state.associatedProducts[0].entity_product_id, sector_id: sector.id
          }
        },
        dataType: 'json',
        success: (data) => {
          let pmaApprovalSectors = update(this.state.pmaApprovalSectors, {
            $push: [data.product_fda_pma_approval_sector]
          })

          this.setState({
            pmaApprovalSectors: pmaApprovalSectors
          })
        }
      })
    }
  }

  handleClearSearchClick(e) {
    this.setState({
      products: this.props.products
    })
  }

  handleActiveIndexUpdate(e, newValue) {
    this.setState({
      activeIndex: newValue
    })
  }

  handleAssociateClick(e) {
    $.ajax({
      type: "POST",
      url: "/admin/product_fda_pma_approvals",
      data: {
        product_fda_pma_approval: { product_id: this.state.activeProducts[0], fda_pma_approval_id: this.props.pma_approval.id }, sector_ids: this.state.sectorIds
      },
      dataType: 'json',
      success: (data) => {
        this.getAssociatedProducts()
      }
    })
  }

  getAssociatedProducts(e) {
    $.ajax({
      type: "GET",
      url: `/admin/pma_approvals/${this.props.pma_approval.id}/associator`,
      dataType: 'json',
      success: (data) => {
        this.setState({
          associatedProducts: data.associated_products,
          activeProducts: [],
          pmaApprovalSectors: data.pma_approval_sectors
        })

        this.props.snackbarShowMessage("Product has been associated")
      }
    })
  }

  handleProductDeleteClick(entityProduct) {
    $.ajax({
      type: "PUT",
      url: `/admin/product_fda_pma_approvals/${entityProduct.entity_product_id}`,
      data: {
        product_fda_pma_approval: {
          deleted: true, deleted_by: this.props.user.id
        }
      },
      dataType: 'json',
      success: (data) => {
        let index = this.state.associatedProducts.indexOf(entityProduct)

        let associatedProducts = update(this.state.associatedProducts, {
          $splice: [[index ,1]]
        })

        this.props.snackbarShowMessage("Product association has been removed")

        this.setState({
          associatedProducts: associatedProducts
        })

      }
    })
  }

  handleCheckboxSelect(productId, type) {
    let index = this.state.activeProducts.indexOf(productId)
    // let productIds = ""
    // if (index == -1) {
    //   productIds = update(this.state[type], {
    //     $push: [product.id]
    //   })
    // } else {
    //   productIds = update(this.state[type], {
    //     $splice: [[index, 1]]
    //   })
    // }

    this.setState({
      activeProducts: [productId]
    })
  }

  handleSearchClick(query) {
    $.ajax({
      type: "GET",
      url: "/admin/products/search",
      data: {
        query: query
      },
      dataType: 'json',
      success: (data) => {
        this.setState({
          products: data.products,
          query: query
        })
      },
      error: (data) => {
        this.props.snackbarShowMessage("There was an error searching for the product. Please refresh the page and try again.")
      }
    })
  }


  render() {
    return (
      <Fragment>
        <Associator 
          firstPanel={
            <PmaApprovalCard 
              pmaApproval={this.props.pma_approval}
            />
          }

          secondPanel={
            <ControlPanel
              sectors={this.props.sectors}
              entitySectors={this.state.pmaApprovalSectors}
              checkedSectors={this.state.sectorIds}
              handleAssociateClick={this.handleAssociateClick}
              handleSectorCheckboxSelect={this.handleSectorCheckboxSelect}
            />
          }
          thirdPanel={
            <EntitiesPanel 
              activeIndex={this.state.activeIndex}
              handleActiveIndexUpdate={this.handleActiveIndexUpdate}
              associated={
                <AssociatedList 
                  entityProducts={this.state.associatedProducts}
                  handleProductDeleteClick={this.handleProductDeleteClick}
                />
              }
              products={
                <EntityPanelContainer 
                  search={
                    <InlineSearchContainer
                      searchLabel="Search products" 
                      helperText="Search by name"
                      query={this.state.query}
                      handleSearchClick={this.handleSearchClick}
                      handleClearSearchClick={this.handleClearSearchClick}
                    />
                  }
                  entitiesList={
                    <ProductsCheckboxList 
                      products={this.state.products}
                      activeProducts={this.state.activeProducts}
                      entityProducts={this.state.associatedProducts}
                      handleCheckboxSelect={this.handleCheckboxSelect}
                    />
                  }
                />
              }
            />
          }
        />
      </Fragment>
    )
  }
}

export default withSnackbar(AssociatorContainer)