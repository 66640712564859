import React, { useState } from "react"
import Box from "../../Box"
import Flex from "../../Flex"
import Link from "../../Link"
import { Button } from "../../buttons"
import Checkbox from "../../Checkbox"


export default function InlineAddForm(props) {
  const [investigatedDevice, setInvestigatedDevice] = React.useState(false);

  function handleCheckboxChange(e) {
    setInvestigatedDevice(!investigatedDevice)
  }

  function handleProductButtonClick(e) {
    props.handleProductAddition(props.product.id, investigatedDevice)
  }
  return(
    <Box mb={1}>
      <Flex alignItems='center'>
        <Box>
          <Link href={`/admin/products/${props.product.id}`} target="_blank">
            {props.product.short_name}
          </Link>
        </Box>

        <Box ml={3}>
          <Checkbox
            name='investigated_device'
            label='Investigated Device'
            value={investigatedDevice}
            onChange={handleCheckboxChange}
          />
        </Box>

        <Box ml={3}>
          <Button onClick={handleProductButtonClick}>
            {props.trialProductsAttributes.map(trialProductsAttribute => trialProductsAttribute.product_id).includes(props.product.id) ? "Remove Product" : "Add Product"}
          </Button>
        </Box>
      </Flex>
    </Box>
  )
}
