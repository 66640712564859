import React from "react"
import { ListItem, ListItemText, ListItemLink } from "../../lists"
import Checkbox from "../../Checkbox"
import DeleteButton from "../DeleteButton"
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction"


export default function AssociatedTrialListItem(props) {

  function handleUnassociateClick(e) {
    props.handleTrialDeleteClick(props.publicationTrial)
  }

  return(
    <React.Fragment>
      <ListItemLink href={`/admin/trials/${props.publicationTrial.trial_id}/edit`} target="_blank">

        <ListItemText
          primary={props.publicationTrial.acronym || props.publicationTrial.short_name}
          secondary={props.publicationTrial.nct_id}
        />

        <ListItemSecondaryAction>
          <DeleteButton onClick={handleUnassociateClick} />
        </ListItemSecondaryAction>

      </ListItemLink>
    </React.Fragment>
  )
}
