import React, { Fragment } from "react"
import Chart from "../charts/Chart"


export default function AdverseEventsChart(props) {

  function formatData() {
    let chartData = ""
    const data = props.chartData.map((array) => {
      let newArr = new Array(array)
      let flatArr = Array.prototype.concat.apply([], newArr)
      let dataArr = flatArr.splice(1)

      let chartData = dataArr.map((arr) => {
        let date = parseFloat(arr[0])
        return({ y: arr[0] })
      })

      return({
        name: array[0],
        marker: {
          enabled: true
        },
        dataLabels: {
          enabled: true
        },
        data: chartData
      })
    })

    console.log(data)

    return(data)
  }


  return(
    <Fragment>

      <Chart
        key={`product-adverse-events-chart-${props.chartReloadKey}`}
        chartId={props.chartId || 'product-adverse-events-count-chart'}
        chartOptions={{ type: 'spline', height: '35%' }}
        plotOptions={{ spline: { dataLabels: { enabled: true } } }}
        exportingChartOptions={{ credits: { enabled: true, text: '\u00A9 pvpipeline' } }}
        xAxisType='datetime'
        legend={true}
        formattedData={formatData()}
        categories={props.categories}
        title={'Top 10 Products by Adverse Events'}
        subtitle="2015 - 2021 YTD     |     Source: FDA MAUDE"
        yAxisTitle='cumulative number of adverse events'
        xAxisTitle={'Year'}
        simple={true}
      />


    </Fragment>
  )
}
