import React from "react"
import Box from "../Box";
import FilteredList from "../search/FilteredList";
// import YearRangeFilter from "./YearRangeFilter";
// import YearRangeSelector from "./YearRangeSelector";
import Chip from "@material-ui/core/Chip";
// import YearRangeSlider from "./YearRangeSlider";



export default function SearchFilters(props) {
  return(
    <React.Fragment>
      <Box width={1} height={3/4} overflow='auto'>
        {/* 
        <Box mt={2}>
          <YearRangeSlider 
            key={props.years.map(year => year.name).join('')} 
            years={props.years}
            originalYears={props.originalYears}
            beginYear={props.filterValues.begin_year}
            endYear={props.filterValues.end_year}
            handleFilterChange={props.handleFilterChange}
          />
        </Box> */}

        <Box mt={3} width={1}>
          <FilteredList
            key={props.publications.map(publication => publication.name).join('')} 
            label='Publication'
            items={props.publications}
            filterValues={props.filterValues}
            filterName='publications'
            defaultOpen={true}
            handleFilterChange={props.handleFilterChange}
          />
        </Box>

        
      </Box>
    </React.Fragment>
  )
}