import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Filters from "../../../patents/Filters"
import Paper from "../../../Paper"
import { Table, TableHead, TableRow, TableCell, TableBody } from "../../../tables"
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TablePaginationActions from "../../../tables/TablePaginationActions"
import ClassificationRow from "./TableRow"
import Flex from "../../../Flex"
import Box from "../../../Box"
import Typography from "@material-ui/core/Typography"
import { withSnackbar } from "../../../SnackbarContainer"

class Classifications extends Component {
  constructor(props) {
    super(props)
    this.handleFilterChange = this.handleFilterChange.bind(this)
    this.getPatents = this.getPatents.bind(this)
    this.handleDelete = this.handleDelete.bind(this)
    this.handleChangePage = this.handleChangePage.bind(this)
    this.state = { classifications: this.props.classifications, classificationsCount: this.props.classifications_count, page: this.props.page, filterOptions: { companies: this.props.companies, kinds: this.props.kinds, sectors: this.props.sectors }, filterValues: { company_ids: [], kind: 'application', sector_id: this.props.sector_id } }
  }

  handleChangePage(e, newPage) {
    let params = Object.assign({}, this.state.filterValues)

    // params["order_by"] = this.state.orderBy
    // params["order"] = this.state.order
    params["page"] = newPage

    this.getPatents(params)
  }

  handleFilterChange(name, value) {
    let params = Object.assign({}, this.state.filterValues)

    params[name] = value

    this.getPatents(params)
  }

  handleDelete(value, type) {
    const index = this.state.filterValues[type].indexOf(value)

    const filterValues = update(this.state.filterValues, {
      [type]: { $splice: [[index, 1]] }
    })

    this.getPatents(filterValues)
  }

  getPatents(params) {
    $.ajax({
      type: 'GET',
      url: `/admin/patents/classifications`,
      data: params,
      dataType: 'json',
      success: (data) => {
        let filterOptions = { companies: data.companies, sectors: this.props.sectors, kinds: this.props.kinds }

        this.setState({
          classifications: data.classifications,
          classificationsCount: data.classifications_count,
          filterValues: params,
          page: params.page ? params.page : this.state.page,
          filterOptions
        })
      },
      error: (data) => {
        this.props.snackbarShowMessage('There was a problem getting patents. Please try again.', 'error')
      }
    })
  }

  render() {
    const selectedCompanies = this.state.filterOptions.companies.filter(company => this.state.filterValues.company_ids.includes(company.value) ? company : null)
    return(
      <Fragment>

        <Paper fullHeight>

          <Box height={1}>
            <Box my={3}>
              <Filters
                filterOptions={this.state.filterOptions}
                filterValues={this.state.filterValues}
                handleFilterChange={this.handleFilterChange}
              />
            </Box>

            <Flex alignItems='center'>

              <Box>
                <Typography variant="body2">
                  {`${this.state.classificationsCount} classifications`}
                </Typography>
              </Box>
            </Flex>

            <Box overflow='auto' height="80%">
              <Table size="small" stickyHeader={true}>
                <TableHead>
                  <TableRow hover={false}>
                    <TableCell>Classification</TableCell>
                    <TableCell>Definition</TableCell>
                    <TableCell>Count</TableCell>
                    <TableCell>Percentage</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.classifications.map((classification) =>
                    <ClassificationRow
                      key={classification.classification}
                      classification={classification}
                      classificationsCount={this.state.classificationsCount}
                    />
                  )}
                </TableBody>
                <TableFooter>
                  <TablePagination
                    colSpan={4}
                    count={this.state.patentsCount}
                    rowsPerPage={100}
                    page={this.state.page}
                    SelectProps={{
                      inputProps: { 'aria-label': 'rows per page' },
                      native: true,
                    }}
                    onChangePage={this.handleChangePage}
                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableFooter>
              </Table>
            </Box>
          </Box>
        </Paper>
      </Fragment>
    )
  }
}


export default withSnackbar(Classifications)
