import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import update from "immutability-helper"
import Paper from "../../Paper"
import Form from "./Form"
import { withSnackbar } from "../../SnackbarContainer"


class EditContainer extends Component {
  constructor(props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
    this.handleNameSearchChange = this.handleNameSearchChange.bind(this)
    this.handleRoleChange = this.handleRoleChange.bind(this)
    this.handleSaveClick = this.handleSaveClick.bind(this)
    this.handlePersonClick = this.handlePersonClick.bind(this)
    this.handleRemovePersonClick = this.handleRemovePersonClick.bind(this)
    this.state = { companyPerson: this.props.company_person, showSnackbar: false, snackbarText: '', openDialog: false, suggestions: [], query: '', chosenPerson: null, firstName: '', lastName: '' }
  }

  handleChange(e) {
    let companyPerson = update(this.state.companyPerson, {
      [e.target.name]: { $set: e.target.value }
    })

    this.setState({
      companyPerson: companyPerson
    })
  }

  handleRemovePersonClick(e) {
    this.setState({
      chosenPerson: null
    })
  }

  handlePersonClick(person) {
    console.log(person)

    let companyPerson = update(this.state.companyPerson, {
      person_id: { $set: person.person_id }
    })

    this.setState({
      companyPerson: companyPerson,
      query: '',
      chosenPerson: person
    })

  }

  handleNameSearchChange(e) {
    const value = e.target.value

    console.log(value)

    if (value == '' || value == null) {
      this.setState({
        suggestions: []
      })
    } else {
      $.ajax({
        type: "GET",
        url: "/admin/people/search",
        data: {
          query: value
        },
        dataType: 'json',
        success: (data) => {
          this.setState({
            suggestions: data.people,
            query: value
          })
        }
      })
    }
  }

  handleRoleChange(name, value) {
    let companyPerson = update(this.state.companyPerson, {
      role: { $set: value }
    })

    this.setState({
      companyPerson: companyPerson
    })
  }

  handleSaveClick() {
    $.ajax({
      type: 'PUT',
      url: `/admin/companies/${this.props.company.id}/people/${this.state.companyPerson.id}`,
      data: {
        company_person: this.state.companyPerson
      },
      dataType: 'json',
      success: (data) => {
        this.props.snackbarShowMessage("Company person record was saved")
      },
      error: (data) => {
        this.props.snackbarShowMessage("There was an error creating the statement. Make sure a source has been selected")
      }
    })
  }

  render() {
    return(
      <Paper fullHeight>
        <Form
          companyPerson={this.state.companyPerson}
          roles={this.props.roles}
          personName={this.props.person_name}
          handleRoleChange={this.handleRoleChange}
          handleSaveClick={this.handleSaveClick}
          handleChange={this.handleChange}
        />
      </Paper>
    )
  }
}

export default withSnackbar(EditContainer)
