import React, { useState, useRef } from "react"
import Box from "../Box"
import ListItem from "../lists/ListItem"
import ListItemText from "../lists/ListItemText"
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction"
import IconButton from "../IconButton"
import InfoIcon from '@material-ui/icons/Info';

export default function RangeAttributeDisplay(props) {

  const [showIcon, setShowIcon] = useState(false);

  let name = props.rangeData.length == 1 ? `${props.rangeData[0].characteristic_value.name}` : `${props.rangeData[0].characteristic_value.name} - ${props.rangeData[1].characteristic_value.name}`

  let metric = props.rangeData[0].characteristic_value.metric ? props.rangeData[0].characteristic_value.metric.name : null

  let display = ""
  display += name
  metric ? display += ` ${metric}` : ''

  return (
    <ListItem dense button ContainerProps={{onMouseLeave: () => setShowIcon(false)}} onMouseEnter={() => setShowIcon(true)}>
      <ListItemText
        primary={display}
      />

      {showIcon ? <ListItemSecondaryAction>
        <IconButton tooltip='View Source' edge="end" aria-label="info" size='small' component="a" href={`/sectors/${props.sector.abbr}/sources/${props.sourceId}`} target="_blank">
          <InfoIcon fontSize='small' />
        </IconButton>
      </ListItemSecondaryAction> : ''}
    </ListItem>
  )

}
