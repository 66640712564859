import React from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Typography from "@material-ui/core/Typography"
import Flex from "../Flex"
import Box from "../Box"
import Paper from "../Paper"
import { Table, TableRow, TableHead, TableCell, TableBody } from "../tables"
import Link from "../Link"
import Tabs from "../tabs/Tabs"
import Tab from "../tabs/Tab"

export default class KClearance extends React.Component {
  constructor(props) {
    super(props)
  }

  handleActiveIndexUpdate(e, newValue) {
    this.setState({
      activeIndex: newValue
    })
  }

  render() {
    let attributes = [ { header: '510(k) Number', attr_value: 'k_number' }, { header: 'Applicant', attr_value: 'applicant' }, { header: 'Classification Product Code', attr_value: 'product_code' }, { header: 'Date Received', attr_value: 'date_received' }, { header: 'Decision date', attr_value: 'decision_date' }, { header: 'Decision', attr_value: 'decision' }, { header: 'Regulation Medical Specialty', attr_value: 'class_advise_comm' }, { header: 'Summary', attr_value: 'state_or_summ' }, { header: "Type", attr_value: 'clearance_type' }, { header: 'Reviewed By Third Party', attr_value: 'third_party' } ]
    return (
      <React.Fragment>

        <Paper minFullHeight>

          <Box mt={3}>
            <Table>
              <TableHead>
                <TableRow hover={false}>
                  <TableCell borderRight={true}></TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {attributes.map((kClearanceAttributeHash) =>
                  <TableRow key={kClearanceAttributeHash.attr_value}>
                    <TableCell borderRight={true} fontWeight="bold" className='w-25'>
                      {kClearanceAttributeHash.header}
                    </TableCell>
                    <TableCell>
                      {kClearanceAttributeHash.attr_value.includes('date') ? humanDateFormat(this.props.k_clearance[kClearanceAttributeHash.attr_value]) : this.props.k_clearance[kClearanceAttributeHash.attr_value] }
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </Box>

        </Paper>
      </React.Fragment>
    )
  }
}
