import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Paper from "../../Paper"
import Flex from "../../Flex"
import Box from "../../Box"
import TextInput from "../../TextInput"
import ButtonLayout from "../ButtonLayout"
import { RaisedButton } from "../../buttons"
import update from "immutability-helper"
import Snackbar from "../../Snackbar"

export default class NewContainer extends Component {
  constructor(props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
    this.handleSaveClick = this.handleSaveClick.bind(this)
    this.state = { websiteKind: this.props.website_kind, showSnackbar: false, snackbarText: '' }
  }
  
  handleChange(e) {
    const name = e.currentTarget.name
    const value = e.currentTarget.value
    
    const websiteKind = update(this.state.websiteKind, {
      [name]: { $set: value }
    })
    
    this.setState({
      websiteKind: websiteKind
    })
  }
  
  handleSaveClick(e) {
    $.post("/admin/websites/create_kind", { website_kind: { kind: this.state.websiteKind.kind, created_by: this.props.current_user.id } }, 'html').fail((data) => {
      this.setState({
        showSnackbar: true,
        snackbarText: `The website kind could not be created. ${data.responseText}.`
      })
    }).always(() => {
      setTimeout(() => {
        this.setState({
          showSnackbar: false,
          snackbarText: ''
        })
      }, 4000)
    })
  }
  
  render() {
    return(
      <Fragment>
        
        <Paper>
          
          <Box className='w-100 mv3 h4'>
            <TextInput 
              name='kind' 
              label='Kind' 
              value={this.state.websiteKind.kind} 
              onChange={this.handleChange} 
              className='w-100' 
            />
          </Box>
          
          <ButtonLayout>
            <RaisedButton handleClick={this.handleSaveClick}>
              Save Website Kind
            </RaisedButton>
          </ButtonLayout>
          
          
        </Paper>
        
        <Snackbar 
          open={this.state.showSnackbar}
          message={this.state.snackbarText} 
        />
      </Fragment>
    )
  }
}
