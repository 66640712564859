import React from 'react'
import Link from '../../Link'

function FdaLink(props) {
  let kClearanceUrl = `https://www.accessdata.fda.gov/scripts/cdrh/cfdocs/cfpmn/pmn.cfm?ID=${props.fdaKClearance.k_number}`

  return (
    <Link href={kClearanceUrl} target="_blank">
      {props.linkText || "View on FDA site"}
    </Link>
  )
}

export default FdaLink