import React, { Component, Fragment } from "react"
import PropTypes from "prop-types"
import ListRecord from "./ListRecord"
import Paper from "../Paper"
import Box from "../Box"
import { Table, TableBody, TableHead, TableRow, TableCell } from "../tables"
import update from 'immutability-helper'

class Listing extends Component {
  constructor(props) {
    super(props)
    this.handleFilterChange = this.handleFilterChange.bind(this)
    this.state = { activeTab: 'List', meetings: this.props.meetings, filterOptions: { years: this.props.years }, filterValues: { year: this.props.year } }
  };

  handleFilterChange(name, value) {
    let params = Object.assign({}, this.state.filterValues)
    params[name] = value
    $.ajax({
      type: 'GET',
      url: `/sectors/${this.props.sector.abbr}/meetings`,
      data: params,
      dataType: 'json',
      success: (data) => {
        let filterValues = update(this.state.filterValues, {[name]: {$set: value}})
        let filterOptions = { years: this.props.years }
        this.setState({
          meetings: data.meetings,
          filterValues,
          filterOptions
        })
      }
    })
  }

  render () {
    return (
      <Fragment>

        <Paper fullHeight>
          <Box height="100%">

            <Box overflow='auto' height="90%">
              <Table size='small' aria-label='meetings table' stickyHeader={true}>
                <TableHead>
                  <TableRow>
                    <TableCell>Meeting</TableCell>
                    <TableCell>Dates</TableCell>
                    <TableCell>Year</TableCell>
                    <TableCell align='right'># of Abstracts</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.meetings.map((meeting) =>
                    <ListRecord
                      key={meeting.id}
                      meeting={meeting}
                      sector={this.props.sector}
                    />
                  )}
                </TableBody>
              </Table>
            </Box>
          </Box>
        </Paper>

      </Fragment>
    );
  }
}

Listing.propTypes = {
  meetings: PropTypes.array
};

export default Listing
