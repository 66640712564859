import React, { Component, Fragment } from "react"
import UpdateContentDisplay from "../../trials/UpdateContentDisplay"
import { List, ListItem, ListItemText } from '../../lists';
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction"
import IconButton from "../../IconButton"
import ExpandMore from "../../ExpandMore";
import ExpandLess from '../../ExpandLess';
import Typography from "@material-ui/core/Typography"
import Link from "../../Link"

export default class UpdateContainer extends Component {
  constructor(props) {
    super(props)
    this.handleClick = this.handleClick.bind(this)
    this.state = { openAll: false }
  }

  handleClick(e) {
    this.setState({
      openAll: !this.state.openAll
    })
  }

  render() {
    let sourceDateDisplay = this.props.updates[0].source_date ? humanDateFormat(this.props.updates[0].source_date) : "N/A"
    return(
      <Fragment>

        <List>
          <ListItem style={{backgroundColor: '#E3B5B7'}}>
            <ListItemText
              primary={
                <Link href={`/admin/sources/${this.props.updates[0].source_id}`} target="_blank">
                  {this.props.updates[0].source_display}
                </Link>}
              secondary={
                <Fragment>
                  <Typography variant="body2" color="textSecondary">
                    {this.props.updates[0].source_details ? `${this.props.updates[0].source_details} - ${sourceDateDisplay}` : sourceDateDisplay}
                  </Typography>
                </Fragment>
              }
              className='f5'
            />
            <ListItemSecondaryAction>
              <IconButton onClick={this.handleClick} tooltip='expand' edge="end" aria-label="expand">
                {this.state.openAll ? <ExpandLess /> : <ExpandMore />}
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        </List>

        {this.props.updates.map((update) =>
          <List key={update.id}>
            <UpdateContentDisplay
              update={update}
              trial={this.props.trial}
              openAll={this.state.openAll}
            />
          </List>
        )}

      </Fragment>
    )
  }
}
