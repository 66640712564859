import React from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import { Card, CardSection, CardSectionHeader } from "../cards"
import SidebarSection from "../SidebarSection"
import Link from "../Link"
import Flex from "../Flex"
import Box from "../Box"
import Typography from "@material-ui/core/Typography"
import Button from "../buttons/Button"
import DateDisplay from "./DateDisplay"
import Tooltip from '@material-ui/core/Tooltip';

export default function TrialCard(props) {

  const [isHovering, setIsHovering] = React.useState(false);

  const handleMouseOver = () => {
    setIsHovering(true);
  };

  const handleMouseOut = () => {
    setIsHovering(false);
  };

  const clinicalTypes = props.clinical_types.map((clinicalType) =>
    <div key={clinicalType.id}>{clinicalType.name}</div>
  )
  return (
    <Card background='bg-light-gray-red'>

      <div>{props.trial_data.full_name}</div>
      <CardSection>
        <Flex alignItems='center'>
          <Box>
            <h4>Details</h4>
          </Box>

          <Box ml='auto'>
            <Typography variant="caption">
              {`Last updated: ${humanDateFormat(props.trial.updated_at)}`}
            </Typography>
          </Box>
        </Flex>

        <SidebarSection
          header='Short name'
          content={props.trial_data.short_name}
        />

        <SidebarSection
          header='Acronym'
          content={props.trial_data.acronym}
        />

        <SidebarSection
          header='Trial type'
          content={clinicalTypes}
        />

        <SidebarSection
          header='Study design'
          content={props.trial_design}
        />

        {props.trial_data.trial_status_id ?
          <Flex flexDirection='column' onMouseOver={handleMouseOver}
          onMouseOut={handleMouseOut} mb={2}>
            <Box>
              <Flex alignItems='center'>
                <Box>
                  <div className='f6 b'>Status</div>
                </Box>
                <Box ml='auto'>
                  {isHovering ?
                    <Link href={`/sectors/${props.sector.abbr}/trials/${props.trial.id}/timeline?attribute=status`} className='f6'>
                      View History
                    </Link>
                  : ''}
                </Box>
              </Flex>
            </Box>

            <Box>
              <Flex alignItems='center'>
                <Box>
                  {props.trial_status.status_name}
                  {props.trial_status.source_date ?
                    <Tooltip title='Date first reported'>
                      <span className='pl2 f6 i'>
                        {`(${humanDateFormat(props.trial_status.source_date)})`}
                      </span>
                    </Tooltip>
                  : ""}
                </Box>
              </Flex>
              {props.trial_status.message ? 
                <Typography variant="body2" color='textSecondary'>
                  {props.trial_status.message}
                </Typography>
              : ""}
            </Box>
          </Flex>
        : ''}

        {props.trial.nct_id ?
          <SidebarSection header='NCT ID'>
            <Box>
              <Link href={`https://clinicaltrials.gov/ct2/show/${props.trial.nct_id}`} target="_blank">
                {props.trial.nct_id}
              </Link>
            </Box>
          </SidebarSection>
        : ''}

        {props.trial.regulatory_id ?
          <SidebarSection header='Regulatory ID'>
            <Box>
              <Link href={props.trial.url} target="_blank">
                {props.trial.regulatory_id}
              </Link>
            </Box>
          </SidebarSection>
        : ''}

        {props.trial_data.first_received_date && props.trial.nct_id ?
          <Box mb={3}>
            <div className='f6 b'>First received date</div>
            <Box>
              {humanDateFormat(props.trial_data.first_received_date)}
            </Box>
          </Box>
        : ''}

        {props.trial.registration_date && props.trial.regulatory_id ?
          <Box mb={3}>
            <div className='f6 b'>Registration Date</div>
            <Box>
              {humanDateFormat(props.trial.registration_date)}
            </Box>
          </Box>
        : ''}

        {props.trial.results_posted ?
          <Box mb={3}>
            <div className='f6 b'>ClinicalTrials.gov Results</div>
            <Box>
              <Link href={`https://clinicaltrials.gov/ct2/show/results/${props.trial.nct_id}`} target="_blank">View Results</Link>
            </Box>
          </Box>
        : ""}

        {props.trial_data.number_of_sites ?
          <DateDisplay
            url={`/sectors/${props.sector.abbr}/trials/${props.trial.id}/sites`}
            buttonText="View All"
            dateElement={
              <Flex flexDirection='column'>
                <Box>
                  <div className='f6 b'>Number of sites</div>
                </Box>

                <Box>
                  <Flex alignItems='center'>
                    <Box>
                      {props.trial_data.number_of_sites}
                    </Box>
                  </Flex>
                </Box>
              </Flex>
            }
          />
        : ''}

        <SidebarSection
          header='Number of Arms'
          content={props.trial.number_of_arms}
        />

        <SidebarSection
          header='Current enrollment'
          content={props.trial_data.display_current_enrollment}
        />

        <SidebarSection
          header='Target enrollment'
          content={props.trial_data.display_target_enrollment}
        />

        {props.trial_data.start_date || props.trial_data.raw_start_date ?
          <DateDisplay
            url={`/sectors/${props.sector.abbr}/trials/${props.trial.id}/timeline?attribute=start_date`}
            buttonText="View Timeline"
            dateElement={
              <Flex flexDirection='column'>
                <Box>
                  <div className='f6 b'>Enrollment start date</div>
                </Box>

                <Box>
                  <Flex alignItems='center'>
                    <Box>
                      {props.trial_data.raw_start_date ? props.trial_data.raw_start_date : humanDateFormat(props.trial_data.start_date)}
                    </Box>
                    {props.trial_data.start_date_type ?
                      <Box pl={1} fontStyle='italic'>
                        <Typography variant="body2">
                          {`(${props.trial_data.start_date_type})`}
                        </Typography>
                      </Box>
                    : ''}
                  </Flex>
                </Box>
              </Flex>
            }
          />
        : ''}

        {props.trial_data.completion_date || props.trial_data.raw_completion_date ?
          <DateDisplay
            attribute="completion_date"
            url={`/sectors/${props.sector.abbr}/trials/${props.trial.id}/timeline?attribute=completion_date`}
            buttonText="View Timeline"
            dateElement={
              <Flex flexDirection='column'>
                <Box>
                  <div className='f6 b'>Completion date</div>
                </Box>

                <Flex alignItems='center'>
                  <Box>
                    {props.trial_data.raw_completion_date ? props.trial_data.raw_completion_date : humanDateFormat(props.trial_data.completion_date)}
                  </Box>
                  {props.trial_data.completion_date_type ?
                    <Box pl={1} fontStyle='italic'>
                      <Typography variant="body2">
                        {`(${props.trial_data.completion_date_type})`}
                      </Typography>
                    </Box>
                  : ''}
                </Flex>
              </Flex>
            }
          />
        : ''}

        {props.trial_data.primary_completion_date || props.trial_data.raw_primary_completion_date ?
          <DateDisplay
            url={`/sectors/${props.sector.abbr}/trials/${props.trial.id}/timeline?attribute=primary_completion_date`}
            buttonText="View Timeline"
            dateElement={
              <Flex flexDirection='column'>
                <Box>
                  <div className='f6 b'>Primary completion date</div>
                </Box>

                <Box>
                  <Flex alignItems='center'>
                    <Box>
                      {props.trial_data.raw_primary_completion_date ? props.trial_data.raw_primary_completion_date : humanDateFormat(props.trial_data.primary_completion_date)}
                    </Box>
                    {props.trial_data.primary_completion_date_type ?
                      <Box pl={1} fontStyle='italic'>
                        <Typography variant="body2">
                          {`(${props.trial_data.primary_completion_date_type})`}
                        </Typography>
                      </Box>
                    : ''}

                  </Flex>
                </Box>
              </Flex>
            }
          />
        : ''}

        {props.main_trials.length > 0 && props.current_user.user_company_id == 1 ?
          <Box mb={3}>
            <div className='f6 b'>Parent Trial</div>
            {props.main_trials.map((trial) =>
              <Box key={trial.trial_id}>
                <Link href={`/sectors/${props.sector.abbr}/trials/${trial.trial_id}`} target="_blank">
                  {trial.acronym || trial.short_name}
                </Link>
              </Box>
            )}
          </Box>
        : ""}

        {props.current_user.user_company_id == 1 && props.source_entity_hash && props.source_entity_hash.source_title ?
          <Box mb={3}>
            <div className='f6 b'>Source</div>
            <Box>
              <Link href={`/sectors/${props.sector.abbr}/sources/${props.trial.source_id}`}>
                {props.source_entity_hash.source_title}
              </Link>
            </Box>
          </Box>
        : ''}

      </CardSection>

      {props.trial_sponsor ? <CardSection>
        <CardSectionHeader>Sponsor</CardSectionHeader>
        <Box>
          {props.trial_sponsor.company ?
            <Link href={`/sectors/${props.sector.abbr}/companies/${props.trial_sponsor.company.id}`}>
              {props.trial_sponsor.company.short_name}
            </Link>
          :
            <div>{props.trial_sponsor.sponsor.name}</div>}
        </Box>
      </CardSection> : ''}

      {props.collaborators.length > 0 ? <CardSection>
        <CardSectionHeader>Collaborators</CardSectionHeader>
        {props.collaborators.map((trialCollaborator) =>
          <Box mb={1} key={trialCollaborator.id}>
            {trialCollaborator.company ?
              <Link href={`/sectors/${props.sector.abbr}/companies/${trialCollaborator.company.id}`}>{trialCollaborator.company.short_name}</Link>
            :
              <div>{trialCollaborator.collaborator.name}</div>
            }
          </Box>
        )}
      </CardSection> : ''}

      {props.products.length > 0 ? <CardSection noBorder={true}>
        <CardSectionHeader>Products</CardSectionHeader>
        {props.products.map((product) =>
          <div key={product.id}>
            <Link href={`/sectors/${props.sector.abbr}/products/${product.id}`} target="_blank">{product.short_name}</Link>
          </div>
        )}
      </CardSection> : ''}
    </Card>
  )
}
