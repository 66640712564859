import React, { Fragment, Component } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Paper from "../../../Paper"
import Box from "../../../Box"
import { Table, TableHead, TableRow, TableCell, TableBody } from "../../../tables"
import { withSnackbar } from "../../../SnackbarContainer"
import Link from "../../../Link"

class Reviewer extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    return(
      <Fragment>
        <Paper fullHeight>

          <Box height="100%">

            <Box overflow='auto' height="90%">
              <Table size='small' stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell>510(k) Number</TableCell>
                    <TableCell>Product</TableCell>
                    <TableCell>Device</TableCell>
                    <TableCell>Score</TableCell>
                    <TableCell>Search Term</TableCell>
                    <TableCell>Date</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {this.props.fda_k_clearances.map((fdaKClearance) =>
                    <TableRow key={fdaKClearance.id}>
                      <TableCell>
                        <Link href={`/admin/product_fda_k_clearances/${fdaKClearance.id}`} target="_blank">
                          {fdaKClearance.k_number}
                        </Link>
                      </TableCell>
                      <TableCell>
                        <Link href={`/admin/products/${fdaKClearance.product_id}/edit?index=k_clearances`} target="_blank">
                          {fdaKClearance.short_name}
                        </Link>
                      </TableCell>
                      <TableCell>{fdaKClearance.device_name}</TableCell>
                      <TableCell>{fdaKClearance.score}</TableCell>
                      <TableCell>{fdaKClearance.search_term}</TableCell>
                      <TableCell>{humanDateFormat(fdaKClearance.created_at)}</TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </Box>
          </Box>
        </Paper>
      </Fragment>
    )
  }
}

export default withSnackbar(Reviewer)
