import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import { TableRow, TableCell } from "../../tables"
import DeleteButton from "../DeleteButton"
import EditButton from "../EditButton"
import Link from "../../Link"


class TargetMarketRow extends Component {
  constructor(props) {
    super(props)
    this.handleDeleteClick = this.handleDeleteClick.bind(this)
  }
  
  handleDeleteClick(e) {
    this.props.handleDeleteClick(this.props.targetMarket)
  }
  
  render() {
    const url = `/admin/target_markets/${this.props.targetMarket.id}/edit`
    const user = this.props.targetMarket.creator
    return(
      <Fragment>
        <TableRow>
          <TableCell>
            <Link href={url}>{this.props.targetMarket.name}</Link>
          </TableCell>
          <TableCell>
            {this.props.targetMarket.sectors.length > 0 ? 
              this.props.targetMarket.sectors.map((sector) =>
                <div>{sector.abbr}</div>
              )
            : "N/A"}
          </TableCell>
          <TableCell>
            {user ? displayInitials(user.first_name, user.last_name) : 'N/A'}
          </TableCell>
          <TableCell>
            <EditButton component="a" href={url} />
          </TableCell>
          <TableCell>
            <DeleteButton onClick={this.handleDeleteClick} />
          </TableCell>
        </TableRow>
      </Fragment>
    )
  }
}


export default TargetMarketRow
