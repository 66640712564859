import React, { Fragment } from "react"
import Paper from "../../Paper"
import { Button, RaisedButton } from "../../buttons"
import Typography from '@material-ui/core/Typography';
import Switch from "../../Switch"
import Flex from "../../Flex"
import Box from "../../Box"
import { Table, TableHead, TableRow, TableCell, TableBody } from "../../tables"
import update from "immutability-helper"
import Snackbar from "../../Snackbar"
import ButtonLayout from "../ButtonLayout"
import Link from "../../Link"


export default class EditContainer extends React.Component {
  constructor(props) {
    super(props)
    this.handleSaveClick = this.handleSaveClick.bind(this)
    this.handleCheckboxClick = this.handleCheckboxClick.bind(this)
    this.handleLiveSwitch = this.handleLiveSwitch.bind(this)
    this.handleSearchProcessClick = this.handleSearchProcessClick.bind(this)
    this.state = { sector: this.props.sector, sectorSearchTerms: this.props.sector_search_terms, showSnackbar: false, snackbarText: '' }
  }

  handleSearchProcessClick(e) {
    $.ajax({
      type: 'GET',
      url: `/admin/sectors/${this.props.sector.abbr}/run_search_process`,
      dataType: 'json',
      success: (data) => {
        this.setState({
          showSnackbar: true,
          snackbarText: 'The search process has started'
        })
      },
      error: (data) => {
        this.setState({
          showSnackbar: true,
          snackbarText: 'The search process could not be started. Make a note in #bug-reports'
        })
      },
      complete: (data) => {
        setTimeout(() => {
          this.setState({
            showSnackbar: false,
            snackbarText: ''
          })
        }, 5000)
      }
    })
  }

  handleCheckboxClick(e) {
    let sectorSearchTerm = update(this.state.sectorSearchTerm, {
      [e.target.name]: { $set: e.target.checked }
    })

    this.setState({
      sectorSearchTerm: sectorSearchTerm
    })
  }

  handleLiveSwitch(sectorSearchTerm) {
    let checked = !sectorSearchTerm.live
    $.ajax({
      type: 'PUT',
      url: `/admin/sectors/${this.props.sector.abbr}/sector_search_terms/${sectorSearchTerm.id}`,
      data: {
        sector_search_term: {
          live: checked
        }
      },
      dataType: 'json',
      success: (data) => {
        let sectorSearchTermIds = this.state.sectorSearchTerms.map(sectorSearchTerm => sectorSearchTerm.id)
        const index = sectorSearchTermIds.indexOf(data.sector_search_term.id)

        let sectorSearchTerms = update(this.state.sectorSearchTerms, {
          [index]: {
            live: { $set: checked }
          }
        })

        this.setState({
          sectorSearchTerms: sectorSearchTerms
        })
      }
    })
  }



  handleSaveClick(e) {
    if (this.state.sectorSearchTerm.id) {
      $.ajax({
        type: 'PUT',
        url: `/admin/sectors/${this.state.sector.id}/sector_search_terms/${this.state.sectorSearchTerm.id}`,
        data: {
          sector_search_term: this.state.sectorSearchTerm
        },
        dataType: 'json',
        success: (data) => {
          this.setState({
            sectorSearchTerm: data.sector_search_term,
            showSnackbar: true,
            snackbarText: 'Search term was saved'
          })
        },
        error: (data) => {
          this.setState({
            showSnackbar: true,
            snackbarText: data.responseText
          })
        },
        complete: (data) => {
          setTimeout(() => {
            this.setState({
              showSnackbar: false,
              snackbarText: ''
            })
          }, 5000)
        }
      })
    } else {
      $.ajax({
        type: 'POST',
        url: `/admin/sectors/${this.props.sector.id}/sector_search_terms`,
        data: {
          sector_search_term: this.state.sectorSearchTerm
        },
        dataType: 'json',
        success: (data) => {
          window.location.href = `/admin/sectors/${this.props.sector.abbr}/sector_search_terms/${data.sector_search_term.id}/edit`
          // this.setState({
          //   sectorSearchTerm: data.sector_search_term,
          //   showSnackbar: true,
          //   snackbarText: 'Search term was created'
          // })
        },
        error: (data) => {
          this.setState({
            showSnackbar: true,
            snackbarText: data.responseText
          })
        },
        complete: (data) => {
          setTimeout(() => {
            this.setState({
              showSnackbar: false,
              snackbarText: ''
            })
          }, 5000)
        }
      })
    }
  }

  render() {

    return(
      <Fragment>

        <Paper minFullHeight>

          <Box overflow="auto" height="90%">
            <Flex>
              <Box>
                <Typography variant="h6" component="h6">
                  Search Phrases
                </Typography>
              </Box>

              <Box ml='auto'>
                <Button component="a" href={`/admin/sectors/${this.props.sector.abbr}/sector_search_terms/new`}>
                  Add Search Phrase
                </Button>
              </Box>
            </Flex>

            <Box my={3} height={3/4}>

              <Table size='small' aria-label='products table' stickyHeader={true} id='products-table'>
                <TableHead>
                  <TableRow hover={false}>
                    <TableCell className='bg-white'>Search Phrase</TableCell>
                    <TableCell className='bg-white'>Live</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {this.state.sectorSearchTerms.map((sectorSearchTerm) =>
                    <TableRow key={sectorSearchTerm.id}>
                      <TableCell>
                        <Link href={`/admin/sectors/${this.state.sector.abbr}/sector_search_terms/${sectorSearchTerm.id}/edit`}>
                          {sectorSearchTerm.search_term}
                        </Link>
                      </TableCell>

                      <TableCell>
                        <Switch
                          edge="end"
                          checked={sectorSearchTerm.live ? true : false}
                          handleChange={() => this.handleLiveSwitch(sectorSearchTerm)}
                        />
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>

            </Box>

            <Box mt={10}>
              <ButtonLayout>
                <RaisedButton onClick={this.handleSearchProcessClick}>
                  Run Search Process
                </RaisedButton>
              </ButtonLayout>
            </Box>
          </Box>

        </Paper>

        <Snackbar
          open={this.state.showSnackbar}
          message={this.state.snackbarText}
        />

      </Fragment>
    )
  }
}
