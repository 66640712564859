import React from "react"
import Button from "../buttons/Button"
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { List, ListItem, ListItemLink, ListItemText } from "../lists"
import ListItemIcon from '@material-ui/core/ListItemIcon';
import CheckIcon from '@material-ui/icons/Check';


export default function Filters(props) {
  return(
    <React.Fragment>
      <Button aria-controls="date-menu" aria-haspopup="true" onClick={props.handleOpen} endIcon={<ArrowDropDownIcon />} color='default' className='ttn'>
        {props.dateOptions.filter(dateOption => dateOption.value == props.date)[0].name}
      </Button>
      <Menu
        id="search-date"
        anchorEl={props.anchorEl}
        open={Boolean(props.anchorEl)}
        onClose={props.handleClose}
      >
        {props.dateOptions.map((dateOption) =>
          <MenuItem key={dateOption.value} onClick={() => { props.handleFilterChange(dateOption.value) }} dense>
            <ListItemIcon size='small' styles={{minWidth: '30px'}}>
              {props.date == dateOption.value ? <CheckIcon fontSize="small" /> : ""}
            </ListItemIcon>
            <ListItemText primary={dateOption.name} />
          </MenuItem>
        )}
      </Menu>
    </React.Fragment>
  )
}