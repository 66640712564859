import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Paper from "../../Paper"
import Select from "../../Select"
import TextInput from "../../TextInput"
import ButtonLayout from "../ButtonLayout"
import { RaisedButton } from "../../buttons"
import Box from '@material-ui/core/Box';
import DatePicker from "../../DatePicker"

export default class AssociatorFilterPanel extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    return(
      <Fragment>

        <Box display="flex" alignItems='start'>

          <Box mr={3}>
            <Select
              value={this.props.kind}
              name='kind'
              label='Kind'
              entities={this.props.patentKinds}
              includeBlank={false}
              handleChange={this.props.handleFilterChange}
            />
          </Box>

          {/* <Box mr={3}>
            <Select
              value={this.props.sectorId}
              name='sector_id'
              label='Sector'
              entities={this.props.sectors}
              includeBlank={true}
              blankOption="All"
              handleChange={this.props.handleFilterChange}
            />
          </Box> */}

          {this.props.activeIndex == 'all' ?
            <Box mr={3}>
              <Select
                value={this.props.priority}
                name='priority'
                label='Priority'
                entities={this.props.priorities}
                includeBlank={true}
                handleChange={this.props.handleFilterChange}
              />
            </Box>
          : ""}

          {this.props.activeIndex == 'all' ?
            <Fragment>

              <Box>
                <DatePicker
                  name='date'
                  label='Date'
                  value={this.props.date}
                  handleChange={this.props.handleDateChange}
                />
              </Box>
            </Fragment>
          :
            <Box>
              <Select
                value={this.props.work_type}
                name='work_type'
                label='Work Type'
                entities={[ { name: 'Current', value: 'current' }, { name: 'Backlog', value: 'backlog' } ]}
                includeBlank={true}
                handleChange={this.props.handleFilterChange}
              />
            </Box>
          }

        </Box>
      </Fragment>
    )
  }
}
