import React from "react"
import Paper from "../../Paper"
import Flex from "../../Flex"
import Box from "../../Box"
import ArtgLink from "./ArtgLink"

export default function ArtgPanel(props) {

  return(
    <React.Fragment>

      <Paper fullHeight={true}>

        <Box height="100%" overflow="auto">

          <Flex justifyContent="right">
            <Box>
              <ArtgLink 
                artgApproval={props.artgApproval}
              />
            </Box>
          </Flex>

          <Flex flexDirection='column' mb={2}>
            <Box width={1} mb={1}>
              <label className='b'>ARTG ID</label>
            </Box>
            <Box width={1}>
              {props.artgApproval.artg_id}
            </Box>
          </Flex>

          <Flex flexDirection='column' mb={2}>
            <Box width={1} mb={1}>
              <label className='b'>ARTG Name</label>
            </Box>
            <Box width={1}>
              {props.artgApproval.good_name || "N/A"}
            </Box>
          </Flex>

          <Flex flexDirection='column' mb={2}>
            <Box width={1} mb={1}>
              <label className='b'>Product</label>
            </Box>
            <Box width={1}>
              {props.artgApproval.product_name || "N/A"}
            </Box>
          </Flex>

          <Flex flexDirection='column' mb={2}>
            <Box width={1} mb={1}>
              <label className='b'>Manufacturer</label>
            </Box>
            <Box width={1}>
              {props.artgApproval.manufacturer_name || "N/A"}
            </Box>
          </Flex>

          <Flex flexDirection='column' mb={2}>
            <Box width={1} mb={1}>
              <label className='b'>Sponsor</label>
            </Box>
            <Box width={1}>
              {props.artgApproval.sponsor_name || "N/A"}
            </Box>
          </Flex>

          <Flex flexDirection='column' mb={2}>
            <Box width={1} mb={1}>
              <label className='b'>Approval Date</label>
            </Box>
            <Box width={1}>
              {props.artgApproval.date || "N/A"}
            </Box>
          </Flex>

          <Flex flexDirection='column' mb={2}>
            <Box width={1} mb={1}>
              <label className='b'>Updated Date</label>
            </Box>
            <Box width={1}>
              {props.artgApproval.effective_date || "N/A"}
            </Box>
          </Flex>

          <Flex flexDirection='column' mb={2}>
            <Box width={1} mb={1}>
              <label className='b'>Approval Class</label>
            </Box>
            <Box width={1}>
              {props.artgApproval.approval_class || "N/A"}
            </Box>
          </Flex>

          <Flex flexDirection='column' mb={2}>
            <Box width={1} mb={1}>
              <label className='b'>GMDN</label>
            </Box>
            <Box width={1}>
              {props.artgApproval.gmdn || "N/A"}
            </Box>
          </Flex>

          <Flex flexDirection='column' mb={2}>
            <Box width={1} mb={1}>
              <label className='b'>Intended Purpose</label>
            </Box>
            <Box width={1}>
              {props.artgApproval.intended_purpose || "N/A"}
            </Box>
          </Flex>

          
        </Box>

      </Paper>

    </React.Fragment>
  )
}
