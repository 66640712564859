import React from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Select from "../../Select"
import SourceItemDisplay from "../sources/SourceItemDisplay"
import List from '../../lists/List';

class SourceForm extends React.Component {
  constructor(props) {
    super(props)
    this.handleSourceTypeChange = this.handleSourceTypeChange.bind(this)
    this.handleSourceChange = this.handleSourceChange.bind(this)
    this.state = { sourceType: this.props.sourceType, sources: this.props.sources }
  }
  
  handleSourceTypeChange(name, value) {
    const params = this.props.company ? { source_type: value, entity_id: this.props.company.id } : { source_type: value }
    
    $.get('/admin/sources/filter', params, (data) => {
      this.setState({
        sourceType: data.source_type,
        sources: data.sources
      })
    }, 'json')
  }
  
  handleSourceChange(entity) {
    this.props.handleSourceChange(entity, this.state.sourceType)
  }
  
  render () {
    return (
      <React.Fragment>
          
        <Select 
          value={this.state.sourceType} 
          label='Source Type'
          entities={this.props.sourceTypes}
          displayEmpty={false}
          handleChange={this.handleSourceTypeChange} 
        />
        
        <div>
          
          <List>
            
            {this.state.sources ? this.state.sources.map((source, index) =>
              <SourceItemDisplay 
                key={source.id} 
                source={source.source_object || source}
                sourceId={this.props.source ? this.props.source.source_id : null}
                sourceType={this.state.sourceType}
                handleSourceChange={this.handleSourceChange}
              />
            ) : 'No Sources Found. Please choose another source type.'}
            
          </List>
          
        </div>
        
      </React.Fragment>
    );
  }
}

export default SourceForm
