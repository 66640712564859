import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import { TableRow, TableCell } from "../../tables"

export default class SourceRow extends Component {
  constructor(props) {
    super(props)
  }
  
  render() {
    return(
      <Fragment>
        <TableRow>
          <TableCell>
            {this.props.source.entity_id}
          </TableCell>
          <TableCell>
            {this.props.source.kind}
          </TableCell>
          <TableCell>
            user
          </TableCell>
        </TableRow>
      </Fragment>
    )
  }
}
