import React, { Fragment } from "react"
import Box from "../../Box"
import Radio from '@material-ui/core/Radio';
import RadioGroup from "../../RadioGroup"
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Flex from "../../Flex"
import { RaisedButton } from "../../buttons"
import DatePicker from "../../DatePicker"
import Select from "../../Select"


export default class DateForm extends React.Component {
  constructor(props) {
    super(props)
    this.handleDateSaveClick = this.handleDateSaveClick.bind(this)
    this.handleDateDeleteClick = this.handleDateDeleteClick.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleFilterChange = this.handleFilterChange.bind(this)
    this.state = { date_name: 'start_date', date: null, date_type: '' }
  }

  componentDidMount() {
    if (this.props.trialDates) {

      let trialDate = this.props.trialDates.filter(trialDate => trialDate.date_name == 'start_date')[0]

      if (trialDate) {
        this.setState({
          date: trialDate.date_value,
          date_type: trialDate.date_type
        })
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.trialDates != nextProps.trialDates) {

      let trialDate = nextProps.trialDates.filter(trialDate => trialDate.date_name == this.state.date_name)[0]

      if (trialDate) {
        this.setState({
          date: trialDate.date_value,
          date_type: trialDate.date_type
        })
      } else {
        this.setState({
          date: null,
          date_type: ''
        })
      }
    }
  }

  handleFilterChange(name, value) {
    this.setState({
      [name]: value
    })
  }

  handleChange(e) {
    let value = e.target.value

    let existingDate = this.props.trialDates.filter(trialDate => trialDate.date_name == value)[0]

    this.setState({
      [e.target.name]: e.target.value,
      date: existingDate ? existingDate.date_value : null,
      date_type: existingDate ? existingDate.date_type : null
    })
  }

  handleDateSaveClick(e) {
    this.props.handleDateSaveClick(this.state.date_name, this.state.date, this.state.date_type)
  }

  handleDateDeleteClick(e) {
    this.props.handleDateDeleteClick(this.state.date_name)
  }

  render() {
    return(
      <Fragment>
        <Box mt={2} mb={6}>

          <Flex justifyContent='center' mb={4}>
            <RadioGroup aria-label="date-type" name="date_name" value={this.state.date_name} onChange={this.handleChange}>
              <FormControlLabel value='start_date' control={<Radio />} label="Start date" />
              <FormControlLabel value='primary_completion_date' control={<Radio />} label="Primary completion date" />
              <FormControlLabel value='completion_date' control={<Radio />} label="Completion date" />
              <FormControlLabel value='enrollment_completion_date' control={<Radio />} label="Enrollment Completion date" />
            </RadioGroup>
          </Flex>

          <Flex pb={4} alignItems='center'>
            <Box>
              <DatePicker
                name='date'
                label={this.state.date_name.replace(/_/g, " ")}
                value={this.state.date}
                handleChange={(date) => { this.setState({ date: date }) }}
              />
            </Box>

            <Box ml='auto'>
              <Select
                label='Type'
                name='date_type'
                value={this.state.date_type}
                entities={[{ name: 'Actual', value: 'actual' }, { name: 'Predicted', value: 'predicted' }]}
                handleChange={this.handleFilterChange}
                includeBlank={true}
              />
            </Box>
          </Flex>

          <Box mt={4}>
            <RaisedButton handleClick={this.handleDateSaveClick}>
              {`Save ${this.state.date_name.replace(/_/g, " ")}`}
            </RaisedButton>
          </Box>

          {this.props.currentUser.id == 3 ?
            <Box mt={3} color='error.main'>
              <RaisedButton color='inherit' handleClick={this.handleDateDeleteClick}>
                {`Delete ${this.state.date_name.replace("_", " ")}`}
              </RaisedButton>
            </Box>
          : ''}
        </Box>
      </Fragment>
    )
  }
}
