import React, { Component, Fragment } from "react"
import Paper from "../../Paper"
import { Button, RaisedButton } from "../../buttons"
import Typography from '@material-ui/core/Typography';
import Flex from "../../Flex"
import Box from "../../Box"
import { List, ListItem, ListItemText } from "../../lists"
import update from "immutability-helper"
import EditButton from "../EditButton"
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction"

export default class SearchTermContainer extends Component {
  constructor(props) {
    super(props)
    this.state = { trialSearchTerms: this.props.trial_search_terms }
  }

  handleDeleteClick(trialSearchTerm) {
    $.ajax({
      type: 'PUT',
      url: `/admin/trial_search_terms/${trialSearchTerm.id}`,
      data: {
        trial_search_term: {
          deleted: true
        }
      },
      dataType: 'json',
      success: (data) => {
        let index = this.state.trialSearchTerms.indexOf(trialSearchTerm)
        let trialSearchTerms = update(this.state.trialSearchTerms, {
          $splice: [[index,1]]
        })

        this.setState({
          trialSearchTerms: trialSearchTerms
        })
      }
    })
  }

  render() {
    return(
      <Fragment>

        <Paper minFullHeight>
          <Flex alignItems='center'>
            <Box>
              <Typography variant="h6" component="h6">
                Search Terms
              </Typography>
            </Box>

            <Box ml='auto'>
              <Button href={`/admin/trials/${this.props.trial.id}/trial_search_terms/new`}>
                New Search Term
              </Button>
            </Box>
          </Flex>

          <Box mt={3}>

            <Box width={1/4}>
            <List>
              {this.state.trialSearchTerms.map((trialSearchTerm) =>
                <ListItem key={trialSearchTerm.id} component='a' divider button href={`/admin/trials/${this.props.trial.id}/trial_search_terms/${trialSearchTerm.id}/edit`}>
                  <ListItemText
                    primary={trialSearchTerm.search_term}
                  />

                  <ListItemSecondaryAction>
                    <EditButton />
                  </ListItemSecondaryAction>
                </ListItem>
              )}
            </List>
            </Box>
          </Box>
        </Paper>

      </Fragment>
    )
  }
}
