import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Paper from "../../Paper"
import Flex from "../../Flex"
import Box from "../../Box"
import StatSection from "../../dashboard/StatSection"
import Typography from "@material-ui/core/Typography"
import { List, ListItem, ListItemText } from "../../lists"

export default class Dashboard extends Component {
  constructor(props) {
    super(props)
  }
  
  render() {
    return(
      <Fragment>
        
        <Flex alignItems='center' className='flex-stretch'>
          <Box mr={3} className='w-33'>
            <StatSection 
              statContent={this.props.trials_count}
              statDetail='Trials'
            />
          </Box>
          
          <Box width='33%' mr={3}>
            <StatSection 
              statContent={this.props.new_trials.length}
              statDetail='New Trials'
              secondaryContent='This month'
            />
          </Box>
          
          <Box width='33%'>
            <StatSection 
              statContent='12'
              statDetail='Trials to review'
            />
          </Box>
        </Flex>
        
        <Flex my={4}>
          
          <Box width={1}>
            <Paper>
              <Typography variant="h6" component="h6" className='mv1'>
                Tasks
              </Typography>
              
              <Box>
                <List>
                  {this.props.user_tasks.length > 0 ? this.props.user_tasks.map((task) => 
                    <ListItem key={task.id} button href='/admin/trials/reviewer'>
                      <ListItemText primary={task.name} />
                      <ListItemText 
                        secondary={humanDateFormat(task.due_on)} 
                      />
                    </ListItem>
                  ) : <ListItem>
                    <ListItemText primary='No tasks found' />
                  </ListItem>}
                </List>
              
              </Box>
            </Paper>
          </Box>
        </Flex>
        
        <Flex className='flex-stretch'>
          
          <Box width={[1, 1/2]} mr={3}>
            <Paper>
              <Typography variant="h6" component="h6" className='mv0'>
                New Trials
              </Typography>
              
              <Box>
                <List>
                  {this.props.new_trials.map((trial) =>
                    <ListItem button href={`/admin/trials/${trial.id}`}>
                      <ListItemText 
                        primary={trial.acronym || trial.short_name} 
                      />
                    </ListItem>
                  )}
                </List>
              </Box>
              
            </Paper>
          </Box>
          
          <Box width={[1, 1/2]}>
            <Paper>
              
              <Typography variant="h6" component="h6" className='mv0'>
                Trials to Review
              </Typography>
              
              <Box>
                <List>
                  {this.props.trials_to_review.map((trial) =>
                    <ListItem button href={`/admin/trials/${trial.id}/review`}>
                      <ListItemText 
                        primary={trial.acronym || trial.short_name} 
                      />
                    </ListItem>
                  )}
                </List>
              </Box>
              
            </Paper>
          </Box>
          
        </Flex>
      </Fragment>
    )
  }
}
