import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Paper from "../../Paper"
import { Table, TableHead, TableRow, TableCell, TableBody, TableHeadSort } from "../../tables"
import RecallRow from "./RecallRow"
import update from "immutability-helper"
import Flex from "../../Flex"
import Box from "../../Box"
import { withSnackbar } from "../../SnackbarContainer"
import { Tabs, Tab } from "../../tabs"

class Recalls extends Component {
  constructor(props) {
    super(props)
    this.handleDeleteClick = this.handleDeleteClick.bind(this)
    this.handleActiveIndexUpdate = this.handleActiveIndexUpdate.bind(this)
    this.handleAssociateClick = this.handleAssociateClick.bind(this)
    this.handlePredictionDeleteClick = this.handlePredictionDeleteClick.bind(this)
    this.state = { recalls: this.props.recalls, recallPredictions: this.props.recall_predictions, view: "associations" }
  }

  handleActiveIndexUpdate(e, newValue) {
    this.setState({
      view: newValue
    })
  }

  handleAssociateClick(productFdaRecallPrediction) {
    $.ajax({
      type: 'POST',
      url: `/admin/product_fda_recalls`,
      data: {
        product_fda_recall: {
          product_id: this.props.product.id, fda_recall_id: productFdaRecallPrediction.fda_recall_id
        }
      },
      dataType: 'json',
      success: (data) => {
        let index = this.state.recallPredictions.indexOf(productFdaRecallPrediction)

        let recallPredictions = update(this.state.recallPredictions, {
          $splice: [[index, 1]]
        })

        this.setState({
          recallPredictions: recallPredictions
        })

        this.props.snackbarShowMessage("Recall has been added")
      },
      error: (data) => {
        this.props.snackbarShowMessage("There was an error associating the recall. Please try again.", "error")
      }
    })
  }

  handleDeleteClick(productFdaRecall) {
    $.ajax({
      type: 'PUT',
      url: `/admin/product_fda_recalls/${productFdaRecall.id}`,
      data: {
        product_fda_recall: {
          deleted: 1, deleted_by: this.props.user.id
        }
      },
      dataType: 'json',
      success: (data) => {
        let index = this.state.recalls.indexOf(productFdaRecall)

        let recalls = update(this.state.recalls, {
          $splice: [[index, 1]]
        })

        this.setState({
          recalls: recalls
        })
      },
      error: (data) => {
        this.props.snackbarShowMessage("There was an error removing the recall. Please try again.", "error")
      }
    })
  }

  handlePredictionDeleteClick(productFdaRecallPrediction) {
    $.ajax({
      type: 'PUT',
      url: `/admin/product_fda_recall_predictions/${productFdaRecallPrediction.id}`,
      data: {
        product_fda_recall_prediction: {
          deleted: 1, deleted_by: this.props.user.id
        }
      },
      dataType: 'json',
      success: (data) => {
        let index = this.state.recallPredictions.indexOf(productFdaRecallPrediction)

        let recallPredictions = update(this.state.recallPredictions, {
          $splice: [[index, 1]]
        })

        this.setState({
          recallPredictions: recallPredictions
        })

        this.props.snackbarShowMessage("Recall prediction has been removed")
      },
      error: (data) => {
        this.props.snackbarShowMessage("There was an error removing the recall. Please try again.", "error")
      }
    })
  }


  render() {
    let recalls = this.state.view == "associations" ? this.state.recalls : this.state.recallPredictions
    return(
      <Fragment>

        <Paper fullHeight={true}>

          <Box mb={2}>
            <Tabs variant="fullWidth" value={this.state.view} onChange={this.handleActiveIndexUpdate} className='bb b--black-10' aria-label="recall tabs">
              <Tab label='Associations' value='associations' />
              <Tab label='Predictions' value='predictions' />
            </Tabs>
          </Box>

          <Box overflow='auto' height="90%">
            <Table size='small' aria-label='adverse events table' stickyHeader={true}>

              <TableHead>
                <TableRow>
                  <TableCell className='bg-white'>ID</TableCell>
                  <TableCell className='bg-white'>Product</TableCell>
                  <TableCell className='bg-white'>Reason for Recall</TableCell>
                  <TableCell className='bg-white'>PMA #</TableCell>
                  <TableCell className='bg-white'>510(k) #</TableCell>
                  <TableCell className='bg-white'>Date Posted</TableCell>
                  <TableCell className='bg-white'></TableCell>
                  <TableCell className='bg-white'></TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {recalls.map((recall) =>
                  <RecallRow
                    key={recall.id}
                    recall={recall}
                    view={this.state.view}
                    handleAssociateClick={this.handleAssociateClick}
                    handleDeleteClick={this.handleDeleteClick}
                    handlePredictionDeleteClick={this.handlePredictionDeleteClick}
                  />
                )}
              </TableBody>

            </Table>
          </Box>
        </Paper>

      </Fragment>
    )
  }
}

export default withSnackbar(Recalls)
