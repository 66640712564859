import React, { Fragment } from 'react'

function NumberDisplay(props) {

  let number = props.pmaApproval.supplement_number ? `${props.pmaApproval.pma_number} ${props.pmaApproval.supplement_number}` : props.pmaApproval.pma_number

  return (
    <Fragment>
      {number}
    </Fragment>
  )
}

export default NumberDisplay