import React, { Fragment } from "react"
import Flex from "../../Flex"
import Box from "../../Box"
import Highlighter from "react-highlight-words";
import Link from "../../Link"

export default class AssociatorCard extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const trialArmsAll = this.props.trialArms.map((trialArm) => trialArm.group_label)
    const trialArms = new Set([...trialArmsAll])

    const trialArmsArray = Array.from(trialArms)

    const trialInterventionsAll = this.props.trialInterventions.map((trialIntervention) => trialIntervention.name)
    const trialInterventions = new Set([...trialInterventionsAll])
    const trialInterventionsArray = Array.from(trialInterventions)

    console.log(trialInterventionsArray)
    return(
      <Fragment>

        <Flex flexDirection='column' mb={3}>
          <Box width={1} mb={1}>
            <label className='b'>Trial</label>
          </Box>
          <Box>
            {this.props.trialDataView.acronym || this.props.trialDataView.short_name}
          </Box>
        </Flex>

        <Flex flexDirection='column' mb={3}>
          <Box width={1} mb={1}>
            <label className='b'>NCT ID</label>
          </Box>
          <Box>
            <Link href={this.props.trial.ct_gov_url} target="_blank">
              {this.props.trial.nct_id}
            </Link>
          </Box>
        </Flex>

        <Flex flexDirection='column' mb={3}>
          <Box width={1} mb={1}>
            <label className='b'>Full Name</label>
          </Box>
          <Box>
            <Highlighter
              searchWords={this.props.productNames}
              autoEscape={true}
              textToHighlight={this.props.trialDataView.full_name}
            />
          </Box>
        </Flex>

        <Flex flexDirection='column' mb={3}>
          <Box width={1} mb={1}>
            <label className='b'>Brief Summary</label>
          </Box>
          <Box>
            <Highlighter
              searchWords={this.props.productNames}
              autoEscape={true}
              textToHighlight={this.props.trial.brief_summary}
            />
          </Box>
        </Flex>

        <Flex flexDirection='column' mb={3}>
          <Box width={1} mb={1}>
            <label className='b'>Detailed Description</label>
          </Box>
          <Box>
            <Highlighter
              searchWords={this.props.productNames}
              autoEscape={true}
              textToHighlight={this.props.trial.detailed_description}
            />
          </Box>
        </Flex>

        <Flex flexDirection='column' mb={3}>
          <Box width={1} mb={1}>
            <label className='b'>Arms</label>
          </Box>
          <Box>
            {trialArmsArray.map((trialArmText) =>
              <Box mb={0.5}>
                <Highlighter
                  key={trialArmText}
                  searchWords={this.props.productNames}
                  autoEscape={true}
                  textToHighlight={trialArmText}
                />
              </Box>
            )}
          </Box>
        </Flex>

        <Flex flexDirection='column'>
          <Box width={1} mb={2}>
            <label className='b'>Interventions</label>
          </Box>
          <Box>
            {trialInterventionsArray.map((trialInterventionText) =>
              <Box mb={1}>
                <Highlighter
                  key={trialInterventionText}
                  searchWords={this.props.productNames}
                  autoEscape={true}
                  textToHighlight={trialInterventionText}
                />
              </Box>
            )}
          </Box>
        </Flex>

      </Fragment>
    )
  }
}
