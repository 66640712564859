import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Flex from "../../Flex"
import Box from "../../Box"
import { TableRow, TableCell } from "../../tables"
import Link from "../../Link"
import DeleteButton from "../DeleteButton"
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from "../../IconButton"
import Switch from "../../Switch"

export default class ReviewerRow extends Component {
  constructor(props) {
    super(props)
    this.handleDeleteClick = this.handleDeleteClick.bind(this)
    this.handleSectorDeleteClick = this.handleSectorDeleteClick.bind(this)
    this.handleSwitchChange = this.handleSwitchChange.bind(this)
  }

  handleSwitchChange(e) {
    this.props.handleRelevantClick(this.props.patent, e.target.checked)
  }

  handleDeleteClick(e) {
    this.props.handleDeleteClick(this.props.patent)
  }

  handleSectorDeleteClick(e) {
    this.props.handleSectorDeleteClick(this.props.patent)
  }

  render() {
    return(
      <Fragment>
        <TableRow>
          <TableCell borderRight={true}>
            <Link href={`/admin/patents/${this.props.patent.patent_id}/reviewer`} target="_blank">
              {this.props.patent.patent_title}
            </Link>
          </TableCell>
          <TableCell borderRight={true}>
            <Flex justifyContent='space-between' alignItems='center'>
              <Box>
                {this.props.patent.company_name}
              </Box>

              <Box>
                <DeleteButton onClick={this.handleDeleteClick} tooltip='Delete Association' />
              </Box>
            </Flex>
          </TableCell>
          <TableCell borderRight={true}>
            {this.props.patent.assignee}
          </TableCell>
          <TableCell borderRight={true}>
            {this.props.patent.orgname}
          </TableCell>
          {/* <TableCell>
            {displayInitials(this.props.patent.first_name, this.props.patent.last_name)}
          </TableCell> */}
          <TableCell borderRight>
            {humanDateFormat(this.props.patent.effective_date_str)}
          </TableCell>
          <TableCell borderRight>
            <Flex justifyContent='space-between' alignItems='center'>
              <Box>
                {this.props.patent.sector_name}
              </Box>

              <Box>
                <DeleteButton onClick={this.handleSectorDeleteClick} tooltip='Delete Association' />
              </Box>
            </Flex>
          </TableCell>
          {/* <TableCell borderRight={true}>
            <DeleteButton onClick={this.handleDeleteClick} tooltip='Delete Association' />
          </TableCell> */}
          <TableCell borderRight={true}>
            <IconButton onClick={() => { this.props.handleNotRelevantClick(this.props.patent) }} tooltip='Mark as not relevant'>
              <CloseIcon />
            </IconButton>
          </TableCell>
        </TableRow>
      </Fragment>
    )
  }
}
