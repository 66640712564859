import React, { Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Flex from "../Flex"
import Box from "../Box"
import FilterChip from '../filters/FilterChip';

export default function StatusFilterChipsContainer(props) {

  const selectedCollections = props.filterValues.collection_ids.length > 0 ? props.filterOptions.collections.filter(collection => props.filterValues.collection_ids.includes(collection.value) ? collection : null) : null

  const selectedCompanies = props.filterValues.company_ids.length > 0 ? props.filterOptions.companies.filter(company => props.filterValues.company_ids.includes(company.value) ? company : null) : null

  const selectedTargetSites = props.filterValues.target_site_ids.length > 0 ? props.filterOptions.targetSites.filter(targetSite => props.filterValues.target_site_ids.includes(targetSite.value) ? targetSite : null) : null

  const selectedTargetMarkets = props.filterValues.target_market_ids.length > 0 ? props.filterOptions.targetMarkets.filter(targetMarket => props.filterValues.target_market_ids.includes(targetMarket.value) ? targetMarket : null) : null

  const selectedTherapyTypes = props.filterValues.therapy_type_ids.length > 0 ? props.filterOptions.therapyTypes.filter(therapyType => props.filterValues.therapy_type_ids.includes(therapyType.value) ? therapyType : null) : null

  // let methodOptionValues = props.filterOptions.methods ? props.filterOptions.methods.map(methodOption => methodOption.value) : null

  const selectedMethodTypes = props.filterOptions.methods ? props.filterOptions.methods.filter(method => props.filterValues.method_type_ids.includes(method.value) ? method : null) : []

  return(
    <Fragment>

      {selectedCollections ? selectedCollections.map((activeFilter) =>
        <FilterChip
          key={activeFilter.value}
          name={activeFilter.name}
          value={activeFilter.value}
          className={props.filterOptions.collections.map(collection => collection.value).includes(activeFilter.value) ? '' : 'o-60'}
          type='collection_ids'
          handleDelete={props.handleDelete}
        />
      ) : ''}

      {selectedCompanies ? selectedCompanies.map((activeFilter) =>
        <FilterChip
          key={activeFilter.value}
          name={activeFilter.name}
          value={activeFilter.value}
          className={props.filterOptions.companies.map(company => company.value).includes(activeFilter.value) ? '' : 'o-60'}
          type='company_ids'
          handleDelete={props.handleDelete}
        />
      ) : ''}
      {selectedTargetSites ? selectedTargetSites.map((activeFilter) =>
        <FilterChip
          key={activeFilter.value}
          name={activeFilter.name}
          value={activeFilter.value}
          className={props.filterOptions.targetSites.map(targetSite => targetSite.value).includes(activeFilter.value) ? '' : 'o-60'}
          type='target_site_ids'
          handleDelete={props.handleDelete}
        />
      ) : ''}
      {selectedTargetMarkets ? selectedTargetMarkets.map((activeFilter) =>
        <FilterChip
          key={`${activeFilter.value} - target_market_ids`}
          className={props.filterOptions.targetMarkets.map(targetMarket => targetMarket.value).includes(activeFilter.value) ? '' : 'o-60'}
          name={activeFilter.name}
          value={activeFilter.value}
          type='target_market_ids'
          handleDelete={props.handleDelete}
        />
      ) : ''}
      {selectedTherapyTypes ? selectedTherapyTypes.map((activeFilter) =>
        <FilterChip
          key={activeFilter.value}
          className={props.filterOptions.therapyTypes.map(therapyType => therapyType.value).includes(activeFilter.value) ? '' : 'o-60'}
          name={activeFilter.name}
          value={activeFilter.value}
          type='therapy_type_ids'
          handleDelete={props.handleDelete}
        />
      ) : ''}

      {selectedMethodTypes ? selectedMethodTypes.map((activeFilter) =>
        <FilterChip
          key={activeFilter.value}
          className={props.filterOptions.methods.map(method => method.value).includes(activeFilter.value) ? '' : 'o-60'}
          name={activeFilter.name}
          value={activeFilter.value}
          type='method_type_ids'
          handleDelete={props.handleDelete}
        />
      ) : ''}


    </Fragment>
  )
}
