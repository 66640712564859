import React, { Fragment, useState } from "react"
import Paper from "../../Paper"
import Flex from "../../Flex"
import Box from "../../Box"
import Typography from "@material-ui/core/Typography"
import Select from "../../Select"
import NewsList from "./NewsList"
import update from "immutability-helper"

export default function News(props) {
  const [newsType, setNewsType] = useState('all');
  const [newsItems, setNewsItems] = useState(props.all_news)

  function handleNewsTypeChange(name, value) {
    setNewsType(value)
    let newsTypeHash = { all: newsItems, press_releases: props.press_releases, news_articles: props.news_articles }
    setNewsItems(newsTypeHash[value])
    let newsTypeName = { all: 'News Articles and Press Releases'}
  }

  function handleDeleteClick(newsItem) {
    let model = newsItem.controller.replace(/s$/, "")
    $.ajax({
      type: 'PUT',
      url: `/admin/${newsItem.controller}/${newsItem.product_join_id}`,
      data: {
        [model]: {
          deleted: true, deleted_by: props.current_user.id
        }
      },
      dataType: 'json',
      success: (data) => {
        let index = newsItems.indexOf(newsItem)

        let items = update(newsItems, {
          $splice: [[index,1]]
        })

        setNewsItems(items)
      }
    })
  }

  let typeLabel = newsType == 'all' ? 'Articles And Press Releases' : humanizeString(newsType)

  return(
    <Paper fullHeight>
      <Box height="100%">
        <Flex alignItems='center' mb={3}>
          <Box>
            <Select
              label='News Type'
              name='news_type'
              value={newsType}
              entities={props.news_types}
              handleChange={handleNewsTypeChange}
            />
          </Box>
          <Box ml='auto'>
            {`${newsItems.length} ${typeLabel}`}
          </Box>
        </Flex>

        <Box overflow='auto' height="90%">
          {newsItems.length > 0 ?
            <NewsList
              newsArticles={newsItems}
              handleDeleteClick={handleDeleteClick}
            />
          :
            <Flex justifyContent='center' height="50%">
              <Box alignSelf='center' maxWidth="50%">
                <Box>
                  <Typography variant="body1">
                    {`No ${typeLabel} found for this product`}
                  </Typography>
                </Box>
              </Box>
            </Flex>
          }
        </Box>
      </Box>
    </Paper>
  )
}
