import React from "react"
import ResultItemContainer from "./ResultItemContainer"
import Typography from "@material-ui/core/Typography"

export default function FdaAdverseEventItem(props) {

  function renderSearchDetails(result) {
    return (
      <Typography variant="body2" color="textSecondary">
        {result.event_type}
      </Typography>
    );
  }

  return(
    <ResultItemContainer 
      searchItemType={props.result.record_type}
      searchTitle={props.result.search_title}
      date={props.result.date_of_event}
      searchDescription={props.result.text}
      url={props.result.url}
      target="_blank"
      searchDetails={renderSearchDetails(props.result)}
    />
  )
}