import React, { Fragment } from "react"
import Paper from "../../../Paper"
import Flex from "../../../Flex"
import Box from "../../../Box"
import Link from "../../../Link"

export default function Panel(props) {
  return(
    <Fragment>

      <Paper fullHeight={true}>

        <Box height="100%" overflow="auto">
          <Flex flexDirection='column' mb={2}>
            <Box width={1} mb={1}>
              <label className='b'>510(k) Number</label>
            </Box>
            <Box width={1}>
              <Link href={`https://www.accessdata.fda.gov/scripts/cdrh/cfdocs/cfpmn/pmn.cfm?ID=${props.fdaKClearance.k_number}`} target="_blank">
                {props.fdaKClearance.k_number}
              </Link>
            </Box>
          </Flex>

          <Flex flexDirection='column' mb={2}>
            <Box width={1} mb={1}>
              <label className='b'>Applicant</label>
            </Box>

            <Box width={1}>
              {props.fdaKClearance.applicant}
            </Box>
          </Flex>

          <Flex flexDirection='column' mb={2}>
            <Box width={1} mb={1}>
              <label className='b'>Product Code</label>
            </Box>

            <Box width={1}>
              {props.fdaKClearance.product_code}
            </Box>
          </Flex>

          <Flex flexDirection='column' mb={2}>
            <Box width={1} mb={1}>
              <label className='b'>Device Name</label>
            </Box>
            <Box>
              {props.fdaKClearance.device_name}
            </Box>
          </Flex>

          <Flex flexDirection='column' mb={2}>
            <Box width={1} mb={1}>
              <label className='b'>510k Review Panel</label>
            </Box>

            <Box width={1}>
              {props.fdaKClearance.review_advise_comm}
            </Box>
          </Flex>



        </Box>

      </Paper>

    </Fragment>
  )
}
