import React, { Fragment } from "react"
import Paper from "../../Paper"
import List from "../../lists/List";
import AssociatedTrialListItem from "./AssociatedTrialListItem"
import ListSubheader from '@material-ui/core/ListSubheader';

export default function AssociatedTrialsList(props) {
  return (
    <Fragment>

      {props.abstractTrials.length > 0 ? <List>
        <ListSubheader>Trials</ListSubheader>
        {props.abstractTrials.map((abstractTrial) =>
          <AssociatedTrialListItem
            key={abstractTrial.trial_id}
            abstractTrial={abstractTrial}
            // trial={abstractTrial.trial}
            handleTrialUnassociateClick={props.handleTrialUnassociateClick}
          />
        )}
      </List> : ''}

    </Fragment>
  )
}
