import React from "react"
import Paper from "../../../Paper"
import Flex from "../../../Flex"
import Box from "../../../Box"
import AssociatedTable from "./AssociatedTable"
import PredictionsTable from "./PredictionsTable"
import update from "immutability-helper"
import { withSnackbar } from "../../../SnackbarContainer"
import { Tabs, Tab } from "../../../tabs"
import { RaisedButton } from "../../../buttons"
import Dialog from "../../../Dialog"
import SectorCheckboxList from "../../sectors/CheckboxList"


class Container extends React.Component {
  constructor(props) {
    super(props)
    this.handleDeleteClick = this.handleDeleteClick.bind(this)
    this.handleActiveIndexUpdate = this.handleActiveIndexUpdate.bind(this)
    this.handleAddKClearanceClick = this.handleAddKClearanceClick.bind(this)
    this.handleKClearanceCheckboxClick = this.handleKClearanceCheckboxClick.bind(this)
    this.handleAddAllClick = this.handleAddAllClick.bind(this)
    this.handleSectorClick = this.handleSectorClick.bind(this)
    this.handleAddClick = this.handleAddClick.bind(this)
    this.handleSectorDelete = this.handleSectorDelete.bind(this)
    this.handleAddAllKClearanceClick = this.handleAddAllKClearanceClick.bind(this)
    this.handleDeletePredictionClick = this.handleDeletePredictionClick.bind(this)
    this.handleReviewedClick = this.handleReviewedClick.bind(this)
    this.state = { kClearances: this.props.k_clearances, kClearancePredictions: this.props.k_clearance_predictions, view: "associations", activeKClearance: null, checkedKClearances: [], openSectorDialog: false, sectorIds: [] }
  }

  handleReviewedClick(e) {
    let ids = this.state.checkedKClearances.length > 0 ? this.state.checkedKClearances : this.state.kClearancePredictions.map((kClearance) => kClearance.id)

    $.ajax({
      type: 'PUT',
      url: `/admin/product_fda_k_clearance_predictions/mark_as_reviewed`,
      data: {
        ids: ids, user_id: this.props.user.id, product_id: this.props.product.id
      },
      dataType: 'json',
      success: (data) => {
        this.setState({
          kClearancePredictions: data.k_clearance_predictions
        })
        this.props.snackbarShowMessage("Predictions have been marked as reviewed")
      },
      error: (data) => {
        this.props.snackbarShowMessage("Something went wrong marking predictions as reviewed. Please try again.", "error")
      }
    })
  }

  handleDeletePredictionClick(kClearance) {
    $.ajax({
      type: "PUT",
      url: `/admin/product_fda_pma_approval_predictions/${kClearance.product_fda_pma_approval_prediction_id}`,
      data: {
        product_fda_pma_approval_prediction: {
          deleted: true, deleted_by: this.props.user.id
        }
      },
      dataType: 'json',
      success: (data) => {
        let index = this.state.kClearancePredictions.indexOf(kClearance)

        let kClearancePredictions = update(this.state.kClearancePredictions, {
          $splice: [[index, 1]]
        })

        this.setState({
          kClearancePredictions: kClearancePredictions
        })

        this.props.snackbarShowMessage("510(k) prediction has been removed")
      },
      error: (data) => {
        this.props.snackbarShowMessage("The PMA prediction could not be removed. Refresh the page and try again.", "error")
      }
    })
  }

  handleSectorDelete(productFdaKClearanceSectorId) {
    $.ajax({
      type: "DELETE",
      url: `/admin/product_fda_k_clearance_sectors/${productFdaKClearanceSectorId}`,
      data: {
        with_clearances: true
      },
      dataType: 'json',
      success: (data) => {
        this.setState({
          kClearances: data.product_k_clearances
        })

        this.props.snackbarShowMessage("Sector has been removed")
      },
      error: (data) => {
        this.props.snackbarShowMessage("Sector could not be removed", "error")
      }
    })
  }

  handleSectorClick(sector) {
    let index = this.state.sectorIds.indexOf(sector.id)

    let sectorIds = ""

    if (index == -1) {
      sectorIds = update(this.state.sectorIds, {
        $push: [sector.id]
      })
    } else {
      sectorIds = update(this.state.sectorIds, {
        $splice: [[index, 1]]
      })
    }

    this.setState({
      sectorIds: sectorIds
    })
  }

  handleAddAllKClearanceClick(e) {
    this.setState({
      openSectorDialog: true,
      checkedKClearances: this.state.checkedKClearances.length > 0 ? this.state.checkedKClearances : this.state.kClearancePredictions.map((kClearance) => kClearance.id)
    })
  }

  handleAddKClearanceClick(kClearance) {
    this.setState({
      openSectorDialog: true,
      activeKClearance: kClearance
    })
  }

  handleActiveIndexUpdate(e, newValue) {
    this.setState({
      view: newValue
    })
  }

  handleKClearanceCheckboxClick(kClearance) {

    let index = this.state.checkedKClearances.indexOf(kClearance.id)

    let kClearanceIds = ""
    if (index == -1) {
      kClearanceIds = update(this.state.checkedKClearances, {
        $push: [kClearance.id]
      })
    } else {
      kClearanceIds = update(this.state.checkedKClearances, {
        $splice: [[index, 1]]
      })
    }

    this.setState({
      checkedKClearances: kClearanceIds
    })
  }


  handleAddAllClick(e) {

    $.ajax({
      type: "POST",
      url: `/admin/product_fda_k_clearances/bulk_add`,
      data: {
        product_id: this.props.product.id, fda_k_clearance_ids: this.state.checkedKClearances, created_by: this.props.user.id, sector_ids: this.state.sectorIds, with_clearances: true
      },
      dataType: 'json',
      success: (data) => {
        this.setState({
          kClearances: data.k_clearances,
          openSectorDialog: false,
          checkedKClearances: []
        })

        this.props.snackbarShowMessage("510(k) clearances have been added")
      },
      error: (data) => {
        this.props.snackbarShowMessage("The 510(k) clearances could not be added. Refresh the page and try again.", "error")
      }
    })
  }

  handleAddClick(e) {
    $.ajax({
      type: "POST",
      url: `/admin/product_fda_k_clearances`,
      data: {
        product_fda_k_clearance: {
          product_id: this.props.product.id, fda_510k_clearance_id: this.state.activeKClearance.id, created_by: this.props.user.id, sector_ids: this.state.sectorIds
        }, with_clearances: true
      },
      dataType: 'json',
      success: (data) => {
        // let index = this.state.pmaPredictions.indexOf(this.state.activePma)

        // let pmaPredictions = update(this.state.pmaPredictions, {
        //   $splice: [[index, 1]]
        // })

        this.setState({
          kClearances: data.product_k_clearances,
          openSectorDialog: false,
          activeKClearance: null
        })

        this.props.snackbarShowMessage("510(k) clearance has been added")
      },
      error: (data) => {
        this.props.snackbarShowMessage("The 510(k) clearance could not be added. Refresh the page and try again.", "error")
      }
    })
  }

  handleDeleteClick(kClearance) {
    $.ajax({
      type: "PUT",
      url: `/admin/product_fda_k_clearances/${kClearance.product_k_clearance_id}`,
      data: {
        product_fda_k_clearance: {
          deleted: true
        }, with_clearances: true
      },
      dataType: 'json',
      success: (data) => {

        this.setState({
          kClearances: data.product_k_clearances
        })

        this.props.snackbarShowMessage("510(k) clearance has been removed from this product")
      },
      error: (data) => {
        this.props.snackbarShowMessage("The 510(k) clearance could not be removed. Refresh the page and try again.", "error")
      }
    })
  }

  render() {
    return(
      <React.Fragment>

        {this.state.openSectorDialog ?
          <Dialog
            openDialog={this.state.openSectorDialog}
            dialogTitle={`Add Sectors for ${this.state.checkedKClearances.length > 0 ? `${this.state.checkedKClearances.length} 510(k)'s` : this.state.activeKClearance.k_number}`}
            handleAddClick={this.state.checkedKClearances.length > 0 ? this.handleAddAllClick : this.handleAddClick}
            handleClose={this.handleCloseDialog}
            dialogContent={
              <SectorCheckboxList
                sectors={this.props.sectors}
                sectorIds={this.state.sectorIds}
                suggestedSectors={this.props.product_sectors}
                handleCheckboxSelect={this.handleSectorClick}
              />
            }
          />
        : ''}

        <Paper fullHeight>

          <Box height="100%">

            <Box mb={2}>
              <Tabs variant="fullWidth" value={this.state.view} onChange={this.handleActiveIndexUpdate} className='bb b--black-10' aria-label="510(k) tabs">
                <Tab label='Associations' value='associations' />
                <Tab label='Predictions' value='predictions' />
              </Tabs>
            </Box>

            {this.state.view == "predictions" ? 
              <Flex mb={2}>
                <Box width={1/4}>
                  <RaisedButton onClick={this.handleAddAllKClearanceClick}>
                    Add checked 510(k)'s
                  </RaisedButton>
                </Box>

                <Box width={1/4} ml={3}>
                  <RaisedButton color="default" onClick={this.handleReviewedClick}>
                    Mark as reviewed
                  </RaisedButton>
                </Box>
              </Flex>
            : ""}

            <Box overflow="auto" height="90%">
              {this.state.view == "associations" ? 
                <AssociatedTable 
                  kClearances={this.state.kClearances}
                  checkedKClearances={this.state.checkedKClearances}
                  handleDeleteClick={this.handleDeleteClick}
                  handleSectorDelete={this.handleSectorDelete}
                  handleKClearanceCheckboxClick={this.handleKClearanceCheckboxClick}
                />
              : 
                <PredictionsTable 
                  kClearancePredictions={this.state.kClearancePredictions}
                  checkedKClearances={this.state.checkedKClearances}
                  handleDeletePredictionClick={this.handleDeletePredictionClick}
                  handleAddKClearanceClick={this.handleAddKClearanceClick}
                  handleAddAllKClearanceClick={this.handleAddAllKClearanceClick}
                  handleKClearanceCheckboxClick={this.handleKClearanceCheckboxClick}
                />
              }
            </Box>
          </Box>
        </Paper>
      </React.Fragment>
    )
  }
}


export default withSnackbar(Container)
