import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Typography from "@material-ui/core/Typography"
import Flex from "../../Flex"
import Box from "../../Box"
import StatSection from "../../dashboard/StatSection"
import { List, ListItem, ListItemText } from "../../lists"
import Paper from "../../Paper"

export default class Dashboard extends Component {
  constructor(props) {
    super(props)
  }
  
  render() {
    return(
      <Fragment>
        
        <Flex align='items-center' className='flex-stretch'>
          <Box className='w-25-ns w-50 mr3'>
            <StatSection 
              statContent={this.props.new_ct_gov_trials}
              statDetail='New Clinical Trials'
              secondaryContent='This Week'
            />
          </Box>
          
          <Box className='w-25-ns w-50 mr3'>
            <StatSection 
              statContent={this.props.updated_ct_gov_trials}
              statDetail='Clinical Trial Updates'
              secondaryContent='This Week'
            />
          </Box>
          
          <Box className='w-25-ns w-50 mr3'>
            <StatSection 
              statContent={this.props.redacted_trial_updates}
              statDetail='Redacted trial updates'
              secondaryContent='This Week'
            />
          </Box>
          
          <Box className='w-25-ns w-50'>
            <StatSection 
              statContent={this.props.freelancer_trial_work_count}
              statDetail='Trials to review'
              secondaryContent='This Week'
            />
          </Box>
        </Flex>
        
        
        <Flex className='mt4 w-100'>
          <Box className='w-100'>
            <Paper>
              <Typography variant="h6" component="h6" className='mt0 mb2'>
                Latest High Priority Trials
              </Typography>
              <div>
                <List>
                  {this.props.high_priority_trials.length > 0 ? this.props.high_priority_trials.map((ctGovTrial) =>
                    <ListItem divider button component="a" href={`/admin/ct_gov_trials/${ctGovTrial.id}/new_trial`} target="_blank">
                      <ListItemText 
                        primary={ctGovTrial.acronym || ctGovTrial.brief_title}
                        secondary={ctGovTrial.nct_id}
                      />
                    </ListItem>
                  ) : <ListItem>
                    <ListItemText primary="No high priority trials to add" />
                  </ListItem>}
                </List>
              </div>
            </Paper>
          </Box>
        </Flex>
      </Fragment>
    )
  }
}
