import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import ProfileFieldContainer from "../../ProfileFieldContainer"
import ProductCard from "../../products/ProductCard"
import EntityLayout from "../../EntityLayout"
import Box from "../../Box"
import RegionalProductCard from "./RegionalProductCard"
import Paper from "../../Paper"

export default class Profile extends Component {
  constructor(props) {
    super(props)
    this.handleRegionIndexUpdate = this.handleRegionIndexUpdate.bind(this)
    this.state = { activeRegionIndex: this.props.world_region, targetMarkets: this.props.target_markets, productCharacteristics: this.props.product_characteristics }
  }
  
  handleRegionIndexUpdate(e, newValue) {
    console.log(newValue)
    let params = newValue == 1000000 ? { investigational: true } : { world_region_id: newValue }
    $.ajax({
      type: 'GET',
      url: `/admin/products/${this.props.product.id}`,
      data: params,
      dataType: 'json',
      success: (data) => {
        this.setState({
          targetMarkets: data.target_markets,
          productCharacteristics: data.product_characteristics,
          activeRegionIndex: newValue
        })
      }
    })
    this.setState({
      activeRegionIndex: newValue
    })
  }
  
  render() {
    return(
      <div>
          
        <RegionalProductCard 
          therapyTypes={this.props.therapy_types} 
          targetMarkets={this.state.targetMarkets}
          productCharacteristics={this.state.productCharacteristics}
          technologyGroups={this.props.technology_groups}
          product={this.props.product}
          company={this.props.company}
          worldRegions={this.props.world_regions}
          activeRegionIndex={this.state.activeRegionIndex}
          handleRegionIndexUpdate={this.handleRegionIndexUpdate}
        />
        
      </div>
    );
  }
}

Profile.propTypes = {
  product: PropTypes.object,
  profile_fields: PropTypes.array
}
