import React from "react"
import PropTypes from "prop-types"
import { ListItem, ListItemText } from "../../lists"
import Checkbox from "../../Checkbox"
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction"


export default function CriteriaListItem(props)  {
  
  function handleCheckboxSelect(e) {
    props.handleCheckboxSelect(props.criteria, 'activeCriterias')
  }
  
  return(
    <React.Fragment>
      <ListItem button key={props.criteria.id} onClick={handleCheckboxSelect} role={undefined}>
        
        {props.forSelection ? <Checkbox 
          checked={props.checked} 
        /> : ''}
        
        <ListItemText primary={props.criteria.hit} />
        
      </ListItem>
    </React.Fragment>
  )
}
