import React, { Fragment } from "react"
import { TableRow, TableCell } from "../../tables"
import Link from "../../Link"
import DeleteButton from "../DeleteButton"
import Box from "../../Box"

export default function NewTrialRow(props) {

  function handleHideClick(e) {
    props.handleHideClick(props.trial)
  }

  return(
    <TableRow key={props.trial.id}>
      <TableCell>
        {props.trial.nct_id}
      </TableCell>
      <TableCell>
        <Link href={`/admin/ct_gov_trials/${props.trial.id}/new_trial?sector_ids=${props.sector_ids}`} target="_blank">
          <Box>
            {props.trial.brief_title}
          </Box>
        </Link>
      </TableCell>
      <TableCell>
        {props.trial.sponsor}
      </TableCell>
      <TableCell>
        {props.trial.nct_collaborators ? props.trial.nct_collaborators.map((collaborator) =>
          <div key={collaborator.id}>
            {collaborator.name}
          </div>
        ) : ''}
      </TableCell>
      <TableCell>
        {props.trial.nct_interventions ? props.trial.nct_interventions.map((intervention) =>
          <div key={intervention.id}>
            {intervention.name}
          </div>
        ) : ''}
      </TableCell>
      {props.activeIndex == 0 ?
        <TableCell>
          {props.trial.product_name}
        </TableCell>
      : ''}
      <TableCell>
        {props.trial.last_changed_date}
      </TableCell>
      <TableCell align='right'>
        {props.trial.normalized_score || props.trial.rank}
      </TableCell>
      <TableCell align='right'>
        <DeleteButton tooltip='Hide' onClick={handleHideClick} />
      </TableCell>
    </TableRow>
  )
}
