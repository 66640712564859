import React from "react"
import { List, ListItem, ListItemText } from "../../lists"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import Checkbox from "../../Checkbox"

export default function TargetMarketListItem(props) {
  
  function handleCheckboxSelect(e) {
    if (props.checked) {  
      props.handleCheckboxDelete(props.productTargetMarket)
    } else {
      props.handleCheckboxSelect(props.targetMarket, props.productTargetMarket)
    }
    
  }
  
  return (
    <React.Fragment>
      
      <ListItem button key={props.targetMarket.id} role={undefined} onClick={handleCheckboxSelect}>
        <ListItemIcon>
          <Checkbox 
            checked={props.checked} 
          />
        </ListItemIcon>
        
        <ListItemText primary={props.targetMarket.name} />
      </ListItem>
      
    </React.Fragment>
  )
}
