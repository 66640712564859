import React from 'react'
import { TableCell, TableRow } from '../../../tables'
import NumberDisplay from './NumberDisplay'
import { RaisedButton } from "../../../buttons"
import DeleteButton from "../../DeleteButton"
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import MuiIconButton from '@material-ui/core/IconButton';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import FilterChip from "../../../filters/FilterChip"
import Box from "../../../Box"

export default function Row(props) {

  function handleDeleteClick(e) {
    props.handleDeleteClick(props.pmaApprovals[0])
  }

  function handlepmaApprovalCheckboxClick(e) {
    props.handlepmaApprovalCheckboxClick(props.pmaApprovals[0])
  }

  const selected = false // props.checkedpmaApprovals.includes(props.pmaApproval.id)

  let pmaApproval = props.pmaApprovals[0]

  return (
    <TableRow>
      <TableCell>
        <MuiIconButton onClick={handlepmaApprovalCheckboxClick} edge="start">
          {selected ? <CheckBoxIcon color='primary' style={{height: '22px', width: '22px'}} /> : <CheckBoxOutlineBlankIcon style={{height: '22px', width: '22px'}} />}
        </MuiIconButton>
      </TableCell>
      <TableCell>
        <NumberDisplay fdaPmaApproval={pmaApproval} />
      </TableCell>
      <TableCell className="measure-wide">{pmaApproval.trade_name}</TableCell>
      <TableCell className="measure-wide">{pmaApproval.ao_statement}</TableCell>
      <TableCell>
        {props.pmaApprovals.map((pmaApproval) => {
          return(
            <Box mb={1} key={pmaApproval.product_fda_pma_approval_sector_id}>
              <FilterChip
                name={pmaApproval.abbr}
                handleDelete={() => { props.handleSectorDelete(pmaApproval.product_fda_pma_approval_sector_id) }}
              />
            </Box>
          )
        })}
      </TableCell>
      <TableCell>{pmaApproval.supplement_reason}</TableCell>
      <TableCell>
        <DeleteButton onClick={handleDeleteClick} />
      </TableCell>
    </TableRow>
  )
}
