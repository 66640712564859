import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Select from "../../Select"
import Typography from "@material-ui/core/Typography"
import update from "immutability-helper"

class DevelopmentStatusForm extends Component {
  constructor(props) {
    super(props)
    this.handleDevelopmentStatusChange = this.handleDevelopmentStatusChange.bind(this)
  }
  
  handleDevelopmentStatusChange(name, value) {
    const objectHash = { product_development_status: { product_id: this.props.product.id, development_status_id: value, created_by: this.props.currentUser.id, source_id: 1, active: true } }
    const objectUrl = "/admin/product_development_statuses"
    
    this.props.handleStatusChange(objectHash, objectUrl, 'productDevelopmentStatuses')
  }
  
  render() {
    return(
      <Fragment>
        <Typography variant="h6" component="h6" className='mb1'>
          New Development Status
        </Typography>
        
        <Select  
          entities={this.props.developmentStatuses}
          label='Development Statuses'
          value={this.props.developmentStatusValue}
          displayEmpty={true}
          handleChange={this.handleDevelopmentStatusChange} 
        />
      </Fragment>
    )
  }
}

export default DevelopmentStatusForm
