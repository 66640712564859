import React from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import { ListItem, ListItemText } from "../../lists"
import Checkbox from "../../Checkbox"


export default function ProductListItem(props)  {
  
  function handleCheckboxSelect(e) {
    props.handleCheckboxSelect(props.product, 'activeProducts')
  }
  
  return(
    <React.Fragment>
      <ListItem button key={props.product.id} onClick={handleCheckboxSelect} role={undefined}>
        
        <Checkbox 
          checked={props.checked} 
        />
        
        <ListItemText primary={props.product.short_name} />
        
      </ListItem>
    </React.Fragment>
  )
}
