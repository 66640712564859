import React from "react"
import Box from "../Box";
import FilteredList from "../search/FilteredList";
import Select from "../Select";

export default function SearchFilters(props) {
  
  function handleOrderSort(name, value) {
    props.handleFilterChange(value, 'order_by')
  }

  return(
    <React.Fragment>
      <Box width={1} height={3/4} overflow='auto'>

        <Box mb={2} width={1}>
          <Select 
            name='order_by'
            label='Sort By'
            value={props.filterValues.order_by}
            handleChange={handleOrderSort}
            entities={[
              {name: 'Relevance', value: '_score'},
              {name: 'Date', value: 'published_date'},
              {name: 'Title', value: 'title'}
            ]}
          />
        </Box>

        {/* <Box my={2}>
          <YearRangeSlider 
            key={props.years.map(year => year.name).join('')} 
            years={props.years}
            minYear={props.filterValues.begin_year}
            maxYear={props.filterValues.end_year}
            handleFilterChange={props.handleFilterChange}
          />
        </Box> */}


        <Box width={1}>
          <FilteredList
            key={props.journals.map(journal => journal.name).join('')} 
            label='Journals'
            items={props.journals}
            filterValues={props.filterValues}
            filterName='journal_ids'
            handleFilterChange={props.handleFilterChange}
            handleCheckAllClick={props.handleCheckAllClick}
          />
        </Box>

        <Box mt={3} width={1}>
          <FilteredList
            key={props.products.map(product => product.name).join('')} 
            label='Products'
            items={props.products}
            filterValues={props.filterValues}
            filterName='product_ids'
            handleFilterChange={props.handleFilterChange}
            handleCheckAllClick={props.handleCheckAllClick}
          />
        </Box>

        {props.companies.length > 0 && <Box mt={3} width={1}>
          <FilteredList
            key={props.companies.map(company => company.name).join('')} 
            label='Companies'
            items={props.companies}
            filterValues={props.filterValues}
            filterName='company_ids'
            handleFilterChange={props.handleFilterChange}
            handleCheckAllClick={props.handleCheckAllClick}
          />
        </Box>}

        {/* <Box mt={3} width={1}>
          <FilteredList
            key={props.trials.map(trial => trial.name).join('')}
            label='Trials'
            items={props.trials}
            filterValues={props.filterValues}
            filterName='trial_ids'
            handleFilterChange={props.handleFilterChange}
          />
        </Box> */}

        {/* <Box mt={3} width={1}>
          <FilteredList
            key={props.productCodes.map(productCode => productCode.name).join('')}
            label='Product Codes'
            items={props.productCodes}
            filterValues={props.filterValues}
            filterName='product_codes'
            handleFilterChange={props.handleFilterChange}
          />
        </Box> */}
      </Box>
    </React.Fragment>
  )
}