import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Paper from "../../Paper"
import Form from "./Form"
import update from "immutability-helper"
import Snackbar from "../../Snackbar"

export default class EditContainer extends Component {
  constructor(props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
    this.handleSaveClick = this.handleSaveClick.bind(this)
    this.handleDateChange = this.handleDateChange.bind(this)
    this.state = { newsArticle: this.props.news_article, showSnackbar: false, snackbarText: '' }
  }

  handleDateChange(date) {
    const newsArticle = update(this.state.newsArticle, {
      date: { $set: date }
    })

    this.setState({
      newsArticle: newsArticle
    })
  }

  handleChange(e) {
    const name = e.target.name
    const value = e.target.value

    const newsArticle = update(this.state.newsArticle, {
      [name]: { $set: value }
    })

    this.setState({
      newsArticle: newsArticle
    })
  }

  handleSaveClick(e) {
    $.ajax({
      type: 'PUT',
      url: `/admin/news_articles/${this.state.newsArticle.id}`,
      data: {
        news_article: this.state.newsArticle
      },
      dataType: 'json',
      success: (data) => {
        this.setState({
          showSnackbar: true,
          snackbarText: 'News article was updated',
          newsArticle: data.news_article
        })
      },
      error: (data) => {
        this.setState({
          showSnackbar: true,
          snackbarText: `The news article could not be updated. ${data.responseText}`
        })
      },
      complete: (data) => {
        setTimeout(() => {
          this.setState({
            showSnackbar: false,
            snackbarText: ''
          })
        }, 4000)
      }
    })
  }

  render() {
    return(
      <Fragment>

        <Paper>

          <Form
            newsArticle={this.state.newsArticle}
            handleChange={this.handleChange}
            handleDateChange={this.handleDateChange}
            handleSaveClick={this.handleSaveClick}
          />

        </Paper>

        <Snackbar
          open={this.state.showSnackbar}
          message={this.state.snackbarText}
        />
      </Fragment>
    )
  }
}
