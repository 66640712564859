import React from "react"
import { ListItem, ListItemText } from "../../lists"
import Checkbox from "../../Checkbox"
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction"
import Link from "../../Link"
import { Button, Tooltip } from "@material-ui/core"
import IconButton from "../../IconButton"
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';


export default function TrialListItem(props) {

  const handlePredictionRemoval = (e) => {
    props.handlePredictionRemoval(props.trial.manuscript_predicted_trial_id)
  }

  const handlePredictionAssociation = (e) => {
    props.handlePredictionAssociation(props.trial.trial_id)
  }

  return(
    <React.Fragment>
      <ListItem role={undefined}>

        <ListItemText
          primary={props.trial.acronym || props.trial.short_name}
          secondary={props.trial.nct_id}
        />

        <ListItemSecondaryAction>
          <IconButton onClick={handlePredictionAssociation} aria-label="associate" tooltip="Associate">
            <AddIcon fontSize='small' />
          </IconButton>
          <IconButton onClick={handlePredictionRemoval} aria-label="delete" tooltip="Delete">
            <DeleteIcon fontSize='small' />
          </IconButton>
        </ListItemSecondaryAction>

      </ListItem>
    </React.Fragment>
  )
}
