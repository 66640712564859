import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Paper from "../../Paper"
import Flex from "../../Flex"
import Box from "../../Box"
import Typography from "@material-ui/core/Typography"
import Button from "../../buttons/Button"
import { Table, TableRow, TableHead, TableCell, TableBody } from "../../tables"
import InvestigatorRow from "./InvestigatorRow"
import Snackbar from "../../Snackbar"
import update from "immutability-helper"
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from "../../Switch"
import CircularProgress from '@material-ui/core/CircularProgress';

export default class TrialInvestigators extends Component {
  constructor(props) {
    super(props)
    this.handleDeleteClick = this.handleDeleteClick.bind(this)
    this.handleUndoClick = this.handleUndoClick.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleShowCurrentClick = this.handleShowCurrentClick.bind(this)
    this.state = { investigators: this.props.investigators, showCurrent: false, lastDeleted: '', showSnackbar: false, snackbarText: '', loading: false }
  }

  handleShowCurrentClick(e) {
    let showCurrentValue = e.target.checked

    $.ajax({
      type: "GET",
      url: `/admin/trials/${this.props.trial.id}/investigators`,
      data: {
        only_current: showCurrentValue
      },
      dataType: 'json',
      beforeSend: (data) => {
        this.setState({
          loading: true
        })
      },
      success: (data) => {

        this.setState({
          investigators: data.investigators,
          showCurrent: showCurrentValue
        })
      },
      complete: (data) => {
        this.setState({
          loading: false
        })
      }
    })
  }

  handleChange(name, value, investigator) {
    $.ajax({
      type: 'PUT',
      url: `/admin/trials/${this.props.trial.id}/investigators/${investigator.id}`,
      data: {
        trial_investigator: {
          role: value
        }
      },
      dataType: 'json',
      success: (data) => {
        const index = this.state.investigators.indexOf(investigator)

        const investigators = update(this.state.investigators, {
          [index]: {
            role: { $set: value}
          }
        })

        this.setState({
          investigators: investigators,
          showSnackbar: true,
          snackbarText: 'Investigator was updated'
        })
      },
      error: (data) => {
        console.log(data.responseText)
        this.setState({
          showSnackbar: true,
          snackbarText: 'The investigator could not be updated. Please try again.'
        })
      },
      complete: (data) => {
        setTimeout(() => {
          this.setState({
            showSnackbar: false,
            snackbarText: ''
          })
        }, 5000)
      }
    })
  }

  handleDeleteClick(investigator) {
    $.ajax({
      type: 'PUT',
      url: `/admin/trials/${this.props.trial.id}/investigators/${investigator.id}`,
      data: {
        trial_investigator: {
          deleted: true, deleted_by: this.props.current_user.id
        }
      },
      dataType: 'json',
      success: (data) => {
        const index = this.state.investigators.indexOf(investigator)

        const investigators = update(this.state.investigators, {
          $splice: [[index, 1]]
        })

        this.setState({
          investigators: investigators,
          showSnackbar: true,
          snackbarText: 'Investigator was removed',
          lastDeleted: investigator
        })
      },
      error: (data) => {
        console.log(data.responseText)
        this.setState({
          showSnackbar: true,
          snackbarText: 'The investigator could not be removed. Please try again.'
        })
      },
      complete: (data) => {
        setTimeout(() => {
          this.setState({
            showSnackbar: false,
            snackbarText: ''
          })
        }, 5000)
      }
    })
  }

  handleUndoClick(event, reason) {
    if (this.state.lastDeleted) {
      $.ajax({
        type: 'PUT',
        url: `/admin/trials/${this.props.trial.id}/investigators/${this.state.lastDeleted.id}`,
        data: {
          trial_investigator: {
            deleted: null, deleted_by: null
          }
        },
        dataType: 'json',
        success: (data) => {
          const investigators = update(this.state.investigators, {
            $push: [this.state.lastDeleted]
          })

          this.setState({
            investigators: investigators,
            lastDeleted: null
          })
        },
        error: (data) => {
          this.setState({
            showSnackbar: true,
            snackbarText: 'The action could not be undone. Please try again.'
          })
        },
        complete: (data) => {
          setTimeout(() => {
            this.setState({
              showSnackbar: false,
              snackbarText: ''
            })
          }, 5000)
        }
      })
    }
  }

  render() {
    return (
      <Fragment>

        <Paper fullHeight>
          <Box height="100%">
            <Flex mb={3} alignItems='center'>
              <Box>
                <Typography variant="h6" component="h6">
                  Investigators
                </Typography>
              </Box>

              <Box ml='auto'>
                <FormControlLabel
                  control={
                    <Switch
                      checked={this.state.showCurrent}
                      handleChange={this.handleShowCurrentClick}
                    />
                  }
                  label="Show current investigators only"
                />
              </Box>

              <Box ml='auto'>
                <Button tag="a" href={`/admin/trials/${this.props.trial.id}/investigators/new`}>
                  Add Investigator
                </Button>
              </Box>
            </Flex>

            {this.state.loading ?
              <Box>
                <CircularProgress />
              </Box>
            : ''}

            <Box overflow="auto" height="90%">
              <Table>
                <TableHead>
                  <TableRow hover={false}>
                    <TableCell>Person</TableCell>
                    <TableCell>Role</TableCell>
                    <TableCell>Location</TableCell>
                    <TableCell>Date</TableCell>
                    <TableCell>Added by</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {this.state.investigators.map((investigator) =>
                    <InvestigatorRow
                      key={investigator.id}
                      investigator={investigator}
                      handleDeleteClick={this.handleDeleteClick}
                      handleChange={this.handleChange}
                    />
                  )}
                </TableBody>
              </Table>
            </Box>
          </Box>
        </Paper>

        <Snackbar
          open={this.state.showSnackbar}
          message={this.state.snackbarText}
          handleUndoClick={this.handleUndoClick}
        />
      </Fragment>
    )
  }
}
