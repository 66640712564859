import React, { Fragment } from "react"
import FollowRow from "./FollowRow"
import { Table, TableHead, TableBody, TableRow, TableCell } from "../../../tables"

export default function FollowsTable(props) {
  return(
    <Fragment>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>User Name</TableCell>
            <TableCell>Account Name</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {props.follows.map((follow) =>
            <FollowRow
              key={follow.id}
              follow={follow}
              handleDeleteClick={props.handleDeleteClick}
            />
          )}
        </TableBody>
      </Table>

    </Fragment>
  )
}
