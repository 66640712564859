import React, { Fragment } from "react"
import Box from "../Box"
import CheckIcon from '@material-ui/icons/Check';
import Typography from "@material-ui/core/Typography"
// import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  grey: {
    color: '#9e9e9e'
  }
}));

export default function StatusMarker(props) {
  let classes = useStyles();

  return(
    <Box textAlign='center'>
      {props.statusRecord.stage_number == props.stageNumber ?
        <Fragment>
          <Box>
            <CheckCircleIcon
              color={props.statusRecord.world_region_id == 0 ? '' : 'primary'}
            />
          </Box>

          {/* <Box>
            <Typography variant='caption'>
              {humanDateFormat(props.statusRecord.min_date)}
            </Typography>
          </Box> */}
        </Fragment>
      : ''}
    </Box>
  )
}
