import React from "react"
import MuiListItemText from '@material-ui/core/ListItemText';
import theme from "../theme"


export default function ListItemText(props) {

  return (
    <MuiListItemText {...props} />
  )
}
