import React, { Fragment } from "react"
import { TableRow, TableCell } from "../../tables"
import Box from "../../Box"
import Typography from "@material-ui/core/Typography"
import DeleteButton from "../DeleteButton"
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction"
import Link from "../../Link"
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Checkbox from '@material-ui/core/Checkbox';
import MuiIconButton from '@material-ui/core/IconButton';

export default function Row(props) {

  function handleDeleteClick(e) {
    props.handleDeleteClick(props.newsItem)
  }

  function handleNewsCheckboxClick(e) {
    props.handleNewsCheckboxClick(props.newsItem)
  }

  let selected = props.checkedItems.includes(props.newsItem)
  
  return(
    <TableRow>
      <TableCell padding='checkbox' className={selected ? '' : 'o-50'}>
        <MuiIconButton onClick={handleNewsCheckboxClick} edge="start">
          {selected ? <CheckBoxIcon color='primary' style={{height: '22px', width: '22px'}} /> : <CheckBoxOutlineBlankIcon style={{height: '22px', width: '22px'}} />}
        </MuiIconButton>
      </TableCell>
      <TableCell>{props.newsItem.news_type_name}</TableCell>
      <TableCell>
        <Link href={`/admin/news/${props.newsItem.id}/associator`} target="_blank">
          {props.newsItem.title || props.newsItem.search_title}
        </Link>
      </TableCell>
      <TableCell>
        {props.newsItem.date ? props.newsItem.date.split("T")[0] : 'N/A'}
      </TableCell>
      <TableCell>
        {props.newsItem.news_publication_name}
      </TableCell>
      <TableCell>
        {props.newsItem.created_by ? displayInitials(props.newsItem.first_name, props.newsItem.last_name) : "SYS"}
      </TableCell>
      <TableCell>
        <DeleteButton onClick={handleDeleteClick} />
      </TableCell>
    </TableRow>
  )
}
