import React, { Fragment } from "react"
import { List } from "../lists"
import NewsListItem from "./ListItem"
import Box from "../Box"

export default function NewsArticleList(props) {

  return(
    <Fragment>
      <List dense={props.dense ? props.dense : false}>
        {props.news.map((newsItem) =>
          <NewsListItem
            key={newsItem._id || newsItem.id}
            newsItem={newsItem._source ? newsItem._source : newsItem}
            sector={props.sector}
          />
        )}
      </List>
    </Fragment>
  )
}
