import React from "react"
import { TableRow, TableCell } from "../../tables"
import DeleteButton from "../DeleteButton"
import Link from "../../Link"
import FdaLink from "./FdaLink"

export default function PmaApprovalRow(props) {
  let pmaNumber = props.pmaApproval.supplement_number ? `${props.pmaApproval.pma_number} ${props.pmaApproval.supplement_number}` : props.pmaApproval.pma_number
  return(
    <TableRow>
      <TableCell className='w-15'>
        <FdaLink 
          pmaApproval={props.pmaApproval}
          linkText={pmaNumber}
        />
      </TableCell>
      <TableCell>
        <Link href={`/admin/pma_approvals/${props.pmaApproval.id}/associator`}>
          {props.pmaApproval.trade_name}
        </Link>
      </TableCell>
      <TableCell>{props.pmaApproval.applicant}</TableCell>
      <TableCell>{props.pmaApproval.created_at.split("T")[0]}</TableCell>
      <TableCell>
        <DeleteButton />
      </TableCell>
    </TableRow>
  )
}
