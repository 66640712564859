import React from "react"
import PropTypes from "prop-types"
import { Table, TableRow, TableCell, TableBody, TableHead } from "../../tables"
import Link from "../../Link"
import Checkbox from "../../Checkbox"
import Switch from "../../Switch"
import Box from "../../Box"

export default function ReviewerRow(props) {

  function handleCheckboxSelect(e) {
    props.handleCheckboxSelect(props.trial)
  }

  return(
    <TableRow>
      <TableCell>
        <Link href={`/admin/trials/${props.trial.id}/type_review`}>
          <Box>
            {props.trial.acronym || props.trial.short_name}
          </Box>
        </Link>
      </TableCell>
      <TableCell>
        {props.trial.sponsor_name}
      </TableCell>
      <TableCell>
        {props.trial.grouped_types ?
          props.trial.grouped_types.split(";").map((type) =>
            <div>
              {type}
            </div>
          )
        : ''}
      </TableCell>
      <TableCell>{humanDateFormat(props.trial.created_at)}</TableCell>
    </TableRow>
  )
}
