import React from 'react'
import TextInput from "../TextInput"
import SearchIcon from '@material-ui/icons/Search'
import InputAdornment from '@material-ui/core/InputAdornment';
import ClearIcon from '@material-ui/icons/Clear';
import IconButton from '../IconButton'

function Input(props) {
  return (
    <TextInput
      name='query'
      label={props.searchLabel || 'Search'}
      value={props.query}
      onChange={props.handleQueryChange}
      fullWidth
      InputProps={{
        startAdornment: <InputAdornment position="start"><SearchIcon /></InputAdornment>, endAdornment: <InputAdornment position="end"><IconButton onClick={props.handleClearSearchClick} size='small'><ClearIcon fontSize='small' /></IconButton></InputAdornment>
      }}
      helperText={props.helperText}
    />
  )
}

export default Input