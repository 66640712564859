import React, { Fragment } from "react"
import { TableRow, TableCell } from "../tables"
import Box from "../Box"
import AttributeDisplay from "./AttributeDisplay"
import List from "../lists/List"

export default function DataAttribute(props) {

  let nonRegionalData = props.data.filter(dataObject => props.header.name == 'Investigational' ? dataObject.investigational : dataObject)

  let numberValues = nonRegionalData.filter(dataObject => dataObject.characteristic_value && dataObject.characteristic_value.metric ? dataObject : '').sort((a, b) => parseInt(a.characteristic_value.name) - parseInt(b.characteristic_value.name))

  return (
    <TableCell borderRight={true} className='w-25'>
      <List dense={true} disablePadding>
        {numberValues.length > 1 ?
          numberValues.map((dataObject) =>
            <AttributeDisplay
              key={dataObject.id}
              data={dataObject.characteristic_value.name} metric={dataObject.characteristic_value.metric ? dataObject.characteristic_value.metric.name : null}
              sourceId={dataObject.source_id}
              sector={props.sector}
            />
          )
        :
          nonRegionalData.map((dataObject) =>
            <AttributeDisplay
              key={dataObject.id}
              data={dataObject.characteristic_value.name} metric={dataObject.characteristic_value.metric ? dataObject.characteristic_value.metric.name : null}
              sourceId={dataObject.source_id}
              sector={props.sector}
            />
          )
        }
      </List>
    </TableCell>
  )
}
