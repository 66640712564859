import React from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import { Table, TableHead, TableBody, TableCell, TableRow } from "../tables"
import Link from "../Link"
import TableHeadSort from "../tables/TableHeadSort"
import Box from "../Box"

export default class PmaApprovalsList extends React.Component {
  constructor(props) {
    super(props)
    this.handleRequestSort = this.handleRequestSort.bind(this)
    this.desc = this.desc.bind(this)
    this.stableSort = this.stableSort.bind(this)
    this.getSorting = this.getSorting.bind(this)
    this.state = { order: 'asc', orderBy: 'supplement_number' }
  }

  desc(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = cmp(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
  }

  getSorting(order, orderBy) {
    return order === 'desc' ? (a, b) => this.desc(a, b, orderBy) : (a, b) => -this.desc(a, b, orderBy);
  }

  handleRequestSort(event, property) {
    const isDesc = this.state.orderBy === property && this.state.order === 'desc';

    this.setState({
      order: isDesc ? 'asc' : 'desc',
      orderBy: property
    })
  };

  render() {
    return(
      <React.Fragment>

        <Box overflow='auto' height='80%'>
          <Table size='small' aria-label='pma approvals table' stickyHeader={true}>

            <TableHeadSort
              headers={[{ name: 'supplement_number', label: 'PMA Number' }, { name: 'supplement_reason', label: 'Supplement Reason' }, { name: 'decision_date', label: 'Decision Date' }]}
              order={this.state.order}
              orderBy={this.state.orderBy}
              onRequestSort={this.handleRequestSort}
            />

            <TableBody>
              {this.stableSort(this.props.pmaApprovals, this.getSorting(this.state.order, this.state.orderBy))
                .map((pmaApproval) =>
                  <TableRow key={pmaApproval.id}>
                    <TableCell className='primary-data w-50'>
                      <Link href={`/sectors/${this.props.sector.abbr}/products/${this.props.product.id}/pma_approvals/${pmaApproval.id}`}>
                        <Box>
                          {`${pmaApproval.pma_number} ${pmaApproval.supplement_number ? pmaApproval.supplement_number : ''}`}
                        </Box>
                      </Link>
                    </TableCell>
                    <TableCell>
                      <Box>
                        {pmaApproval.supplement_reason}
                      </Box>
                    </TableCell>
                    <TableCell>
                      {humanDateFormat(pmaApproval.decision_date)}
                    </TableCell>
                  </TableRow>
              )}
            </TableBody>
          </Table>
        </Box>

      </React.Fragment>
    )
  }
}
