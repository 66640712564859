import React, { Component, Fragment } from "react"
import Paper from "../../../Paper"
import Flex from "../../../Flex"
import Box from "../../../Box"
import { List, ListItem, ListItemText } from "../../../lists"
import Associator from "../../Associator"
import TrialCard from "./TrialCard"
import { withSnackbar } from "../../../SnackbarContainer"
import ComparatorPanel from "./ComparatorPanel"
import update from "immutability-helper"
import ControlPanel from "../../associator/ControlPanel"

class Reviewer extends Component {
  constructor(props) {
    super(props)
    this.handleSwitchChange = this.handleSwitchChange.bind(this)
    this.handleReviewedClick = this.handleReviewedClick.bind(this)
    this.state = { trialProducts: this.props.trial_products, trialReview: this.props.trial_review }
  }

  handleSwitchChange(trialProduct) {
    let investigatedDevice = trialProduct.investigated_device ? null : true

    $.ajax({
      type: 'PUT',
      url: `/admin/trial_products/${trialProduct.id}`,
      data: {
        trial_product: {
          investigated_device: investigatedDevice
        }
      },
      dataType: 'json',
      success: (data) => {
        let index = this.state.trialProducts.indexOf(trialProduct)

        let trialProducts = update(this.state.trialProducts, {
          [index]: {
            investigated_device: { $set: investigatedDevice }
          }
        })

        this.setState({
          trialProducts: trialProducts
        })

        this.props.snackbarShowMessage("Product has been marked as the investigated device")
      },
      error: (data) => {
        this.props.snackbarShowMessage("Product could not be marked as the investigated device. Please try again.", "error")
      }
    })
  }

  handleReviewedClick(e) {
    if (this.state.trialReview && this.state.trialReview.reviewed) {
      $.ajax({
        type: 'PUT',
        url: `/admin/trial_reviews/${this.state.trialReview.id}`,
        data: {
          trial_review: {
            reviewed: null
          }
        },
        dataType: 'json',
        success: (data) => {
          this.setState({
            trialReview: data.trial_review
          })

          this.props.snackbarShowMessage("Trial has been marked as not reviewed")
        },
        error: (data) => {
          this.props.snackbarShowMessage("Trial could not be marked as not reviewed", "error")
        }
      })
    } else {
      $.ajax({
        type: 'POST',
        url: `/admin/trial_reviews`,
        data: {
          trial_review: {
            trial_id: this.props.trial.id, reviewed: true, review_type: 'product_review'
          }
        },
        dataType: 'json',
        success: (data) => {
          this.setState({
            trialReview: data.trial_review
          })

          this.props.snackbarShowMessage("Trial has been marked as reviewed")
        },
        error: (data) => {
          this.props.snackbarShowMessage("Trial could not be marked as reviewed", "error")
        }
      })
    }
  }

  render() {
    return(
      <Fragment>
        <Associator
          firstPanel={
            <TrialCard
              trial={this.props.trial}
              trialDataView={this.props.trial_data_view}
              trialArms={this.props.trial_arms}
              trialInterventions={this.props.trial_interventions}
              briefSummary={this.props.brief_summary}
              detailedDescription={this.props.detailed_description}
            />
          }
          secondPanel={
            <ControlPanel
              reviewed={this.state.trialReview ? this.state.trialReview.reviewed : false}
              handleReviewedClick={this.handleReviewedClick}
            />
          }
          thirdPanel={
            <ComparatorPanel
              trialProducts={this.state.trialProducts}
              handleSwitchChange={this.handleSwitchChange}
            />
          }
          associationLoading={this.state.associationLoading}
        />
      </Fragment>
    )
  }
}


export default withSnackbar(Reviewer)
