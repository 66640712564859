import React, { Fragment } from "react"
import Chart from "../../charts/Chart"


export default function TimelineChart(props) {

  function setXaxisMin() {
    // return(props.dateApproved ? props.dateApproved : null)
  }

  function setXAxisPlotLine() {
    let xAxisLines = []
    xAxisLines.push({ color: '#B57E7E', value: props.dateApproved, width: 2, zIndex: 1, id: 'product-approval', label: { text: 'Product Approved' }})

    if (props.recallDates) {
      props.recallDates.map((recallDate) => 
        xAxisLines.push({ color: '#B57E7E', value: recallDate, width: 2, zIndex: 1, id: 'product-approval', label: { text: 'Product Recalled' }})
      ) 
    }
    // return([{ color: '#B57E7E', value: props.dateApproved, width: 2, zIndex: 1, id: 'product-approval', label: { text: 'Product Approved' }}, { color: '#B57E7E', value: props.dateRecalled, width: 2, zIndex: 1, id: 'product-approval', label: { text: 'Product Recalled' }}])

    return(xAxisLines)
  }

  function setLineColor(eventTypeName) {
    switch (eventTypeName) {
      case 'Death':
        return('#ff4136')
        break;
      case 'Malfunction':
        return('#777')
        break;
      case 'Injury':
        return("#f8cf05")
        break;
      default:
        return('#357edd')
    }
  }

  function formatData() {
    let chartData = ""
    const data = props.chartData.map((array) => {

      let newArr = new Array(array)
      let flatArr = Array.prototype.concat.apply([], newArr)
      let dataArr = flatArr.splice(1)

      let chartData = dataArr.map((arr) => {
        let date = parseFloat(arr[0])
        return({ x: date, y: arr[1] })
      })

      return({
        name: array[0],
        color: setLineColor(array[0]),
        data: chartData
      })
    })

    return(data)
  }


  return(
    <Fragment>

      <Chart
        key={`product-adverse-events-chart-${props.chartReloadKey}`}
        chartId={props.chartId || 'product-adverse-events-count-chart'}
        chartOptions={{ type: 'column', height: '35%' }}
        xAxisType='datetime'
        plotOptions={{
          column: {
            stacking: 'normal',
            dataLabels: {
              enabled: true
            }
          }
        }}
        exportingChartOptions={{ credits: { enabled: true, text: '\u00A9 MarketMonitors' } }}
        legend={true}
        data={formatData()}
        title='Monthly Adverse Events'
        subtitle="2015 - 2023 YTD     |     Source: FDA MAUDE"
        yAxisTitle='# of adverse events'
        xAxisPlotLine={setXAxisPlotLine()}
        xAxisMin={setXaxisMin()}
        startOnTick={true}
        endOnTick={true}
        minTickInterval={30*24*3600*1000}
        reverseStacks={true}
      />


    </Fragment>
  )
}
