import React from "react"
import PropTypes from "prop-types"
import Paper from "../../Paper"
import { List, ListItem, ListItemText } from "../../lists"
import Box from "../../Box";

class PredictionsContainer extends React.Component {
  constructor(props) {
    super(props)
    this.onFocus = this.onFocus.bind(this)
    this.state = { products: this.props.products }
  }

  componentDidMount() {
    window.addEventListener("focus", this.onFocus)
  }

  componentWillUnmount() {
    window.removeEventListener("focus", this.onFocus)
  }

  onFocus() {
    $.ajax({
      type: "GET",
      url: `/admin/fda_k_clearances/predictions`,
      dataType: 'json',
      success: (data) => {
        this.setState({
          products: data.products
        })
      }
    })
  }

  render () {
    return (
      <Paper fullHeight>
        <Box height="100%">
          <Box overflow="auto" height="90%">
            <List>
              {this.state.products.length > 0 ? this.state.products.map((product) =>
                <ListItem divider key={product.id} button component="a" href={`/admin/products/${product.id}/fda_k_clearances`} target="_blank">
                  <ListItemText 
                    primary={product.short_name}
                  />
                </ListItem>
              ) : <ListItem><ListItemText primary="No predictions found" /></ListItem>}
            </List>
          </Box>
        </Box>
      </Paper>
    );
  }
}

PredictionsContainer.propTypes = {
  products: PropTypes.array
};

export default PredictionsContainer
