import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Paper from "../Paper"
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';


export default class EntityCard extends Component {
  constructor(props) {
    super(props)
    this.handleMouseEnter = this.handleMouseEnter.bind(this)
    this.handleMouseLeave = this.handleMouseLeave.bind(this)
    this.handleClick = this.handleClick.bind(this)
    this.state = { hover: false }
  }

  handleMouseEnter(e) {
    this.setState({
      hover: true
    })
  }

  handleMouseLeave(e) {
    this.setState({
      hover: false
    })
  }

  handleClick(e) {
    this.props.handleClick(this.props.entity)
  }
  
  render() {
    let elevation = this.state.hover || this.props.active ? 8 : 1
    return(
      <Fragment>
        <div className={this.props.className} onMouseEnter={this.handleMouseEnter} onMouseLeave={this.handleMouseLeave} onClick={this.handleClick}>
          
          <Paper elevation={elevation} active={this.props.active} square={true}>
            {this.props.children}
          </Paper>
        
        </div>
      </Fragment>
    )
  }
}
