import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import { TableRow, TableCell } from "../../tables"
import Link from "../../Link"
import DeleteButton from "../DeleteButton"
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from "../../IconButton"
import Switch from "../../Switch"

export default class StagedAssociatorRow extends Component {
  constructor(props) {
    super(props)
    this.handleDeleteClick = this.handleDeleteClick.bind(this)
    this.handleSwitchChange = this.handleSwitchChange.bind(this)
  }

  handleSwitchChange(e) {
    this.props.handleRelevantClick(this.props.patent, e.target.checked)
  }

  handleDeleteClick(e) {
    this.props.handleDeleteClick(this.props.patent)
  }

  render() {
    return(
      <Fragment>
        <TableRow>
          <TableCell borderRight={true}>
            {this.props.patent.html_patent_no}
          </TableCell>
          <TableCell borderRight={true}>
            <Link href={`/admin/patents/${this.props.patent.id}/associator`} target="_blank">
              {this.props.patent.title}
            </Link>
          </TableCell>
          <TableCell borderRight={true}>
            {this.props.patent.react_assignee_name}
          </TableCell>
          <TableCell borderRight={true}>
            {this.props.patent.orgname}
          </TableCell>
          <TableCell borderRight>
            {humanDateFormat(this.props.patent.date)}
          </TableCell>
          <TableCell borderRight>
            {this.props.patent.rank}
          </TableCell>
          <TableCell borderRight>
            {this.props.patent.sector_name}
          </TableCell>
          <TableCell borderRight={true}>
            <IconButton onClick={() => { this.props.handleNotRelevantClick(this.props.patent) }} tooltip='Mark as not relevant'>
              <CloseIcon />
            </IconButton>
          </TableCell>
        </TableRow>
      </Fragment>
    )
  }
}
