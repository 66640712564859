import React, {Fragment} from "react"
import Flex from "../Flex"
import Box from "../Box"
import Typography from "@material-ui/core/Typography"
import Divider from "@material-ui/core/Divider"
import Pagination from '@material-ui/lab/Pagination';
import { List, ListItem, ListItemText } from "../lists"
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction"
import IconButton from "../IconButton"
import InfoIcon from '@material-ui/icons/Info';
import Tooltip from '@material-ui/core/Tooltip';


export default function EntityAlertsDisplay(props) {


  return(
    <Fragment>

      <Box overflow='auto' height={"80%"}>

        <List mt={3}>
          {props.alerts.map((alert) =>
            <ListItem key={alert.id} divider>
              <ListItemText>
                <Typography variant="caption" color="textSecondary">
                  {alert.group_name}
                </Typography>

                <Box>
                  {alert.header_id != props.entity.id ?
                    <Box mt={1}>
                      <span
                        dangerouslySetInnerHTML={{__html: alert.header_text }}
                      />
                    </Box>
                  : ""}
                  {alert.header_secondary ?
                    <Box>
                      <Box mt={1}>
                        <span
                          dangerouslySetInnerHTML={{__html: alert.header_secondary }}
                        />
                      </Box>
                    </Box>
                  : ""}
                </Box>

                <Box>
                  <Typography variant="body1" className='measure'>
                    <div dangerouslySetInnerHTML={{__html: alert.body }} />
                  </Typography>
                </Box>

                <Flex>
                  <Box mr={2}>
                    <Typography variant="body2" color="textSecondary">
                      {humanDateFormat(alert.date)}
                    </Typography>
                  </Box>

                  <Box mr={2}>
                    <Typography variant="body2" color="textSecondary">
                      {alert.source_text}
                    </Typography>
                  </Box>
                </Flex>
              </ListItemText>

              {alert.source_id ?
                <ListItemSecondaryAction>
                  <IconButton tooltip='View Source' edge="end" aria-label="info" size='small' component="a" href={`/sectors/${props.sector.abbr}/sources/${alert.source_id}`} target="_blank">
                    <InfoIcon fontSize='small' />
                  </IconButton>
                </ListItemSecondaryAction>
              : ''}
            </ListItem>
          )}
        </List>
      </Box>

      <Box mx='auto' width="30%" mt={2}>
        <Pagination
          count={props.pages}
          page={props.page}
          onChange={props.handleChange}
        />
      </Box>

    </Fragment>
  )
}
