import React, { Component } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Header from "../../Header.jsx"

class EntityHeader extends Component {
  constructor(props) {
    super(props)
  }
  
  render() {
    return (
      <Header
        header={this.props.header} 
        background='bg-washed-red' 
      />
    );
  }
}

export default EntityHeader
