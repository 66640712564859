import React from "react"
import ReactDOM from "react-dom"
import { ListItem, ListItemText } from "../../../lists"
import DeleteButton from "../../DeleteButton"
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction"

export default function SubstudyRow(props) {

  function handleDelete(e) {
    props.handleDelete(props.substudy)
  }

  return(
    <ListItem divider>
      <ListItemText primary={props.substudy.acronym || props.substudy.short_name} />

      <ListItemSecondaryAction>
        <DeleteButton onClick={handleDelete} />
      </ListItemSecondaryAction>
    </ListItem>
  )
}
