import React from "react"
import { TableRow, TableCell } from "../../tables"
import DeleteButton from "../DeleteButton"
import Link from "../../Link"

export default function DeviceRow(props) {
  return(
    <TableRow>
      <TableCell className='w-15'>
        <Link href={`https://accessgudid.nlm.nih.gov/devices/${props.nihDevice.device_id}`} target="_blank">
          {props.nihDevice.device_id}
        </Link>
      </TableCell>
      <TableCell>
        <Link href={`/admin/nih_devices/${props.nihDevice.id}/associator`}>
          {props.nihDevice.brand_name}
        </Link>
      </TableCell>
      <TableCell>{props.nihDevice.version_model_number}</TableCell>
      <TableCell>{props.nihDevice.company_name}</TableCell>
      <TableCell>{props.nihDevice.device_publish_date}</TableCell>
      <TableCell>
        <DeleteButton />
      </TableCell>
    </TableRow>
  )
}
