import React from 'react'
import Link from '../../Link'

function CtGovLink(props) {

  let ctGovUrl = `https://clinicaltrials.gov/ct2/show/${props.nctId}`

  return (
    <Link href={ctGovUrl} target="_blank">
      {props.linkText || "View on clinicaltrials.gov"}
    </Link>
  )
}

export default CtGovLink