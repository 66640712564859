import React, { Component, Fragment } from "react"
import PropTypes from "prop-types"
import Paper from "../Paper"
import Flex from "../Flex"
import Box from "../Box"
import { List } from "../lists"
import CollectionProductListItem from "./CollectionProductListItem"
import Typography from "@material-ui/core/Typography"
import Snackbar from "../Snackbar"
import update from "immutability-helper"
import CollectionShareForm from "./CollectionShareForm"
import Dialog from "../Dialog"
import { Button, OutlinedButton } from "../buttons"

export default class UserCollectionContainer extends Component {
  constructor(props) {
    super(props)
    this.handleCollectionProductDeleteClick = this.handleCollectionProductDeleteClick.bind(this)
    this.handleShareClick = this.handleShareClick.bind(this)
    this.handleAddShareClick = this.handleAddShareClick.bind(this)
    this.handleShareCheckboxClick = this.handleShareCheckboxClick.bind(this)
    this.handleUnfollowClick = this.handleUnfollowClick.bind(this)
    this.handleFollowClick = this.handleFollowClick.bind(this)
    this.state = { userCollectionProducts: this.props.user_collection_products, userCollectionMembers: this.props.user_collection_members, member: this.props.member, userIds: [], unshareUserIds: [], sharedIds: [], showSnackbar: false, snackbarText: '', showShareDialog: false }
  }

  componentWillMount() {
    $.ajax({
      type: 'GET',
      url: `/users`,
      data: {
        user_company_id: this.props.user.user_company_id, user_id: this.props.user.id, sector_id: this.props.user_collection.sector_id
      },
      dataType: 'json',
      success: (data) => {
        let existingUserIds = this.state.userCollectionMembers.map((userCollectionMember) => userCollectionMember.member_id)
        
        this.setState({
          users: data.users,
          sharedIds: existingUserIds
        })
      }
    })
  }

  handleUnfollowClick(e) {
    $.ajax({
      type: 'PUT',
      url: `/sectors/${this.props.sector.abbr}/user_collection_members/${this.props.member.id}`,
      data: {
        user_collection_member: {
          deleted: true, deleted_by: this.props.user.id
        }
      },
      dataType: 'json',
      success: (data) => {
        this.setState({
          member: null,
          showSnackbar: true,
          snackbarText: 'Collection has been unfollowed'
        })
      },
      error: (data) => {
        this.setState({
          showSnackbar: true,
          snackbarText: 'There was an error unfollowing this collection. Please try again.'
        })
      },
      complete: (data) => {
        setTimeout(() => {
          this.setState({
            showSnackbar: false,
            snackbarText: ''
          })
        }, 5000)
      }
    })
  }

  handleFollowClick(e) {
    $.ajax({
      type: 'POST',
      url: `/sectors/${this.props.sector.abbr}/user_collection_members`,
      data: {
        user_collection_member: {
          member_id: this.props.user.id, user_collection_id: this.props.user_collection.id
        }
      },
      dataType: 'json',
      success: (data) => {
        this.setState({
          member: data.user_collection_member,
          showSnackbar: true,
          snackbarText: 'Collection has been followed'
        })
      },
      error: (data) => {
        this.setState({
          showSnackbar: false,
          snackbarText: 'There was an error following this collection. Please try again.'
        })
      },
      complete: (data) => {
        setTimeout(() => {
          this.setState({
            showSnackbar: false,
            snackbarText: ''
          })
        }, 5000)
      }
    })
  }

  handleShareCheckboxClick(user) {
    let userCollectionMember = this.state.userCollectionMembers.filter(userCollectionMember => userCollectionMember.member_id == user.id)[0]

    if (userCollectionMember) {
      $.ajax({
        type: 'PUT',
        url: `/sectors/${this.props.sector.abbr}/user_collection_members/${userCollectionMember.id}`,
        data: {
          user_collection_member: {
            deleted: true, deleted_by: this.props.user.id
          }
        },
        dataType: 'json',
        success: (data) => {
          let existingUserIds = this.state.userCollectionMembers.map(userCollectionMember => userCollectionMember.id)

          let index = existingUserIds.indexOf(userCollectionMember.id)

          let userCollectionMembers = update(this.state.userCollectionMembers, {
            $splice: [[index,1]]
          })

          this.setState({
            userCollectionMembers: userCollectionMembers
          })
        },
        error: (data) => {
          this.setState({
            showSnackbar: true,
            snackbarText: 'There was an error unsharing this collection. Please try again.'
          })
        },
        complete: (data) => {
          setTimeout(() => {
            this.setState({
              showSnackbar: false,
              snackbarText: ''
            })
          }, 4000)
        }
      })
    } else {
      $.ajax({
        type: 'POST',
        url: `/sectors/${this.props.sector.abbr}/user_collection_members`,
        data: {
          user_collection_member: {
            member_id: user.id, user_collection_id: this.props.user_collection.id
          }
        },
        dataType: 'json',
        success: (data) => {
          this.setState({
            userCollectionMembers: data.user_collection_members
          })
        },
        error: (data) => {
          this.setState({
            showSnackbar: false,
            snackbarText: 'There was an error sharing this collection. Please try again.'
          })
        },
        complete: (data) => {
          setTimeout(() => {
            this.setState({
              showSnackbar: false,
              snackbarText: ''
            })
          }, 5000)
        }
      })
    }
  }

  handleCollectionProductDeleteClick(userCollectionProduct) {
    $.ajax({
      type: 'PUT',
      url: `/sectors/${this.props.sector.abbr}/user_collection_products/${userCollectionProduct.id}`,
      data: {
        user_collection_product: {
          deleted: true
        }
      },
      dataType: 'json',
      success: (data) => {
        let index = this.state.userCollectionProducts.indexOf(userCollectionProduct)
        let userCollectionProducts = update(this.state.userCollectionProducts, {
          $splice: [[index,1]]
        })

        this.setState({
          userCollectionProducts: userCollectionProducts,
          showSnackbar: true,
          snackbarText: 'The product has been removed'
        })
      },
      error: (data) => {
        this.setState({
          showSnackbar: true,
          snackbarText: 'The product could not be removed'
        })
      },
      complete: (data) => {
        setTimeout(() => {
          this.setState({
            showSnackbar: false,
            snackbarText: ''
          })
        }, 4000)
      }
    })
  }

  handleShareClick(e) {
    this.setState({
      showShareDialog: true
    })
  }

  handleAddShareClick(e) {
    $.ajax({
      type: 'POST',
      url: `/sectors/${this.props.sector.abbr}/user_collection_members`,
      data: {
        user_ids: this.state.userIds, user_collection_id: this.props.user_collection.id
      },
      dataType: 'json',
      success: (data) => {
        this.setState({
          userCollectionMembers: data.user_collection_members,
          showShareDialog: false,
          userIds: [],
          showSnackbar: true,
          snackbarText: 'Collection has been shared'
        })
      },
      error: (data) => {
        this.setState({
          showSnackbar: false,
          snackbarText: 'There was an error sharing this collection. Please try again.'
        })
      },
      complete: (data) => {
        setTimeout(() => {
          this.setState({
            showSnackbar: false,
            snackbarText: ''
          })
        }, 5000)
      }
    })
  }

  render() {
    return(
      <Fragment>
        <Paper minFullHeight>
          <Flex height="100%" flexDirection='column' textAlign='center'>

            {this.state.showShareDialog ?
              <Dialog
                openDialog={this.state.showShareDialog}
                dialogTitle='Manage Members'
                fullWidth={true}
                withButtons={false}
                withClose={true}
                handleClose={() => { this.setState({ showShareDialog: false })}}
                dialogContent={
                  <CollectionShareForm
                    users={this.state.users}
                    userCollectionMembers={this.state.userCollectionMembers}
                    handleShareCheckboxClick={this.handleShareCheckboxClick}
                  />
                }
              />
            : ''}

            <Flex mb={3} justifyContent='center'>
              <Box>
                <Typography variant="h5">
                  {this.props.user_collection.title}
                </Typography>

                {this.props.user_collection.description ?
                  <Box mt="12px">
                    {this.props.user_collection.description}
                  </Box>
                : ""}

                <Box mt="12px">
                  {`${this.props.user_collection_owner.first_name} ${this.props.user_collection_owner.last_name}`}
                </Box>

                <Flex mt="12px" justifyContent='center'>
                  <Box mr={3}>
                    {`${this.state.userCollectionProducts.length} Products`}
                  </Box>

                  <Box>
                    {`${this.state.userCollectionMembers.length} Members`}
                  </Box>
                </Flex>

                <Box mt="12px">
                  {this.props.user_collection.user_id == this.props.user.id ?
                    <OutlinedButton onClick={this.handleShareClick}>
                      Manage Members
                    </OutlinedButton>
                  :
                    this.state.member ?
                      <OutlinedButton outlined={true} onClick={this.handleUnfollowClick}>
                        Unfollow
                      </OutlinedButton>
                    :
                      <OutlinedButton outlined={true} onClick={this.handleFollowClick}>
                        Follow
                      </OutlinedButton>
                  }
                </Box>
              </Box>
            </Flex>

            <Box height="90%" overflow='auto' width={1} display="flex" justifyContent='center'>
              <Box width={1/2}>
                <List>
                  {this.state.userCollectionProducts.map((userCollectionProduct) =>
                    <CollectionProductListItem
                      userCollectionProduct={userCollectionProduct}
                      userCollection={this.props.user_collection}
                      user={this.props.user}
                      handleCollectionDeleteClick={this.handleCollectionProductDeleteClick}
                    />
                  )}
                </List>
              </Box>
            </Box>

          </Flex>
        </Paper>

        <Snackbar
          open={this.state.showSnackbar}
          message={this.state.snackbarText}
        />
      </Fragment>
    )
  }
}
