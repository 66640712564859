import React, { Fragment, useState } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Flex from "../../../Flex"
import Box from "../../../Box"
import TextInput from "../../../TextInput"
import RaisedButton from "../../../buttons/RaisedButton"
import Typography from "@material-ui/core/Typography"
import Select from "../../../Select"
import SearchDropdown from "../../../SearchDropdown"
import { List, ListItem, ListItemText } from "../../../lists"

export default function Form(props) {

  return(
    <Fragment>
      <Typography variant="h6">
        New 510(k) Number
      </Typography>

      <Box mt={2}>
        <Flex mb={4} alignItems='center'>
          <Box mr={3}>
            <TextInput
              name='k_number'
              label='510(k) Number'
              value={props.kNumber}
              onChange={props.handleKNumberChange}
              className='w-100'
            />
          </Box>

          <Box mr={3}>
            <Select
              label='Sector'
              name='sector_id'
              value={props.sector_id}
              entities={props.sectors}
              handleChange={props.handleSectorChange}
            />
          </Box>

          <Box>
            <RaisedButton onClick={props.handleKClearanceSave}>
              Add 510(k)
            </RaisedButton>
          </Box>
        </Flex>
      </Box>

    </Fragment>
  )
}
