import React, { Component, Fragment } from "react"
import ProductsList from "../products/ProductsList"
import TrialsList from "../trials/associator/EntitiesList"
import CompaniesList from "../companies/associator/EntitiesList"
import ControlPanel from "../associator/ControlPanel"
import NewsPanel from "./NewsPanel"
import update from "immutability-helper"
import Associator from "../Associator"
import EntitiesPanel from "../associator/EntitiesPanel"
import AssociatedList from "../associator/AssociatedList"
import { withSnackbar } from "../../SnackbarContainer"
import SectorCheckboxList from "../associator/SectorCheckboxList"
import Box from "../../Box"
import RaisedButton from "../../buttons/RaisedButton"
import Paper from "../../Paper"
import { Typography } from "@material-ui/core"


class AssociatorContainer extends Component {
  constructor(props) {
    super(props)
    this.handleActiveIndexUpdate = this.handleActiveIndexUpdate.bind(this)
    this.handleCheckboxSelect = this.handleCheckboxSelect.bind(this)
    this.handleAssociateClick = this.handleAssociateClick.bind(this)
    this.handleProductDeleteClick = this.handleProductDeleteClick.bind(this)
    this.handleTrialDeleteClick = this.handleTrialDeleteClick.bind(this)
    this.handleTrialSearchChange = this.handleTrialSearchChange.bind(this)
    this.handleTrialSearchClick = this.handleTrialSearchClick.bind(this)
    this.handlePredictedIndexUpdate = this.handlePredictedIndexUpdate.bind(this)
    this.handleSectorCheckboxSelect = this.handleSectorCheckboxSelect.bind(this)
    this.handleProductSearchChange = this.handleProductSearchChange.bind(this)
    this.handleProductSearchClick = this.handleProductSearchClick.bind(this)
    this.handleCompanySearchClick = this.handleCompanySearchClick.bind(this)
    this.handleCompanySearchChange = this.handleCompanySearchChange.bind(this)
    this.handleTrialDeleteClick = this.handleTrialDeleteClick.bind(this)
    this.handleCompanyDeleteClick = this.handleCompanyDeleteClick.bind(this)
    this.handleCreateAlertClick = this.handleCreateAlertClick.bind(this)
    this.handleAddCeMarkClick = this.handleAddCeMarkClick.bind(this)
    this.handleReviewedClick = this.handleReviewedClick.bind(this)
    this.state = { newsItem: this.props.news_item, newsItemProducts: this.props.news_item_products, newsItemTrials: this.props.news_item_trials, newsItemCompanies: this.props.news_item_companies, activeIndex: 'companies', activeProducts: [], activeTrials: [], activeCompanies: [], associationLoading: false, showSnackbar: false, snackbarText: '', searchedTrials: '', searchedProducts: '', searchedCompanies: [], predictedIndex: 0, newsItemSectors: this.props.news_item_sectors, productQuery: '', trialsQuery: '', companyQuery: '', alerts: this.props.alerts }
  }

  componentDidMount() {
    if (this.state.newsItemProducts && this.state.newsItemProducts.length > 0) {
      this.setState({
        activeIndex: 'associated'
      })
    }
  }

  handlePredictedIndexUpdate(e, newValue) {
    this.setState({
      predictedIndex: newValue
    })
  }

  handleCompanySearchChange(e) {
    if (e.target.value == '') {
      this.setState({
        companyQuery: '',
        searchedCompanies: []
      })
    } else {
      this.setState({
        companyQuery: e.target.value
      })
    }
  }

  handleReviewedClick(e) {
    $.ajax({
      type: "PUT",
      url: `/admin/news/${this.state.newsItem.id}`,
      data: {
        news_item: {
          reviewed: true
        }
      },
      dataType: 'json',
      success: (data) => {
        this.setState({
          newsItem: data.news_item
        })
        this.props.snackbarShowMessage("News item has been reviewed")
      },
      error: (data) => {
        this.props.snackbarShowMessage("There was an error reviewing the news item", "error")
      }
    })
  }

  handleCompanySearchClick() {
    $.ajax({
      type: 'GET',
      url: `/admin/companies/search`,
      data: {
        query: this.state.companyQuery
      },
      dataType: 'json',
      success: (data) => {
        this.setState({
          searchedCompanies: data.companies
        })
      }
    })
  }

  handleTrialSearchChange(e) {
    if (e.target.value == '') {
      this.setState({
        trialsQuery: '',
        searchedTrials: []
      })
    } else {
      this.setState({
        [e.target.name]: e.target.value
      })
    }
  }

  handleCreateAlertClick(e) {
    let sectorIds = this.state.newsItemSectors.map((newsItemSector) => newsItemSector.sector_id)

    if (sectorIds) {
      $.ajax({
        type: "POST",
        url: `/admin/news/${this.state.newsItem.id}/create_as_alert`,
        dataType: "json",
        success: (data) => {
          this.props.snackbarShowMessage("Alert has been created")
        }
      }) 
    } else {
      this.props.snackbarShowMessage("Please associate the news item with a sector before creating as an alert", "error")
    }
  }

  handleTrialSearchClick(e) {
    $.ajax({
      type: 'GET',
      url: `/admin/trials/search`,
      data: {
        q: this.state.trialsQuery
      },
      dataType: 'json',
      success: (data) => {
        this.setState({
          searchedTrials: data.trials
        })
      }
    })
  }

  handleActiveIndexUpdate(e, newValue) {
    this.setState({
      activeIndex: newValue
    })
  }

  handleProductDeleteClick(newsItemProduct) {

    $.ajax({
      type: "PUT",
      url: `/admin/product_news_items/${newsItemProduct.product_news_item_id}`,
      data: {
        product_news_item: {
          deleted: true, deleted_by: this.props.current_user.id
        }
      },
      dataType: 'json',
      success: (data) => {
        let index = this.state.newsItemProducts.indexOf(newsItemProduct)

        let newsItemProducts = update(this.state.newsItemProducts, {
          $splice: [[index, 1]]
        })
        this.setState({
          newsItemProducts: newsItemProducts,
          showSnackbar: true,
          snackbarText: 'Product has been removed'
        })
      },
      error: (data) => {
        this.setState({
          showSnackbar: true,
          snackbarText: 'There was an error removing the product'
        })
      },
      complete: (data) => {
        setTimeout(() => {
          this.setState({
            showSnackbar: false,
            snackbarText: ''
          })
        }, 4000)
      }
    })
  }

  handleTrialDeleteClick(newsItemTrial) {

    $.ajax({
      type: "PUT",
      url: `/admin/trial_news_items/${newsItemTrial.entity_trial_id}`,
      data: {
        trial_news_item: {
          deleted: true, deleted_by: this.props.current_user.id
        }
      },
      dataType: 'json',
      success: (data) => {
        let index = this.state.newsItemTrials.indexOf(newsItemTrial)

        let newsItemTrials = update(this.state.newsItemTrials, {
          $splice: [[index, 1]]
        })
        this.setState({
          newsItemTrials: newsItemTrials
        })

        this.props.snackbarShowMessage("The trial has been removed.")
      },
      error: (data) => {
        this.props.snackbarShowMessage("There was an error removing the trial. Please try again.", "error")
      }
    })
  }

  handleCompanyDeleteClick(newsItemCompany) {

    $.ajax({
      type: "PUT",
      url: `/admin/company_news_items/${newsItemCompany.entity_company_id}`,
      data: {
        company_news_item: {
          deleted: true, deleted_by: this.props.current_user.id
        }
      },
      dataType: 'json',
      success: (data) => {
        let index = this.state.newsItemCompanies.indexOf(newsItemCompany)

        let newsItemCompanies = update(this.state.newsItemCompanies, {
          $splice: [[index, 1]]
        })
        this.setState({
          newsItemCompanies: newsItemCompanies,
        })

        this.props.snackbarShowMessage("Company has been removed")
      },
      error: (data) => {
        this.props.snackbarShowMessage("Company could not be removed. Please try again.", 'error')
      }
    })
  }

  handleCheckboxSelect(entityId, entityType) {
    const index = this.state[entityType].indexOf(entityId)

    const activeEntities = index == -1 ? update(this.state[entityType], { $push: [entityId] }) : update(this.state[entityType], { $splice: [[index, 1]] })

    this.setState({
      [entityType]: activeEntities
    })
  }

  handleAssociateClick(e) {

    $.post(`/admin/news/${this.state.newsItem.id}/associate`, { product_ids: this.state.activeProducts, trial_ids: this.state.activeTrials, company_ids: this.state.activeCompanies, created_by: this.props.current_user.id }, (data) => {

      this.setState({
        activeProducts: [],
        newsItemProducts: data.news_item_products,
        newsItemCompanies: data.news_item_companies,
        newsItemTrials: data.news_item_trials
      })

      this.props.snackbarShowMessage("News item has been associated")
    }, 'json').fail((data) => {
      this.props.snackbarShowMessage("News item could not be associated. Please try again.", "error")
    })
  }

  handleSectorCheckboxSelect(sector) {
    let checked = this.state.newsItemSectors.filter(newsItemSector => newsItemSector.sector_id == sector.id)[0]

    if (checked) {
      let newsItemSector = this.state.newsItemSectors.filter(newsItemSector => newsItemSector.sector_id == sector.id)[0]
      $.ajax({
        type: 'DELETE',
        url: `/admin/news_item_sectors/${newsItemSector.id}`,
        dataType: 'JSON',
        success: (data) => {
          let index = this.state.newsItemSectors.indexOf(sector)

          let newsItemSectors = update(this.state.newsItemSectors, {
            $splice: [[index, 1]]
          })

          this.setState({
            newsItemSectors: newsItemSectors
          })

          this.props.snackbarShowMessage('The sector was removed')
        },
        error: (data) => {
          this.props.snackbarShowMessage('The sector was removed', 'error')
        }
      })
    } else {
      $.ajax({
        type: 'POST',
        url: `/admin/news_item_sectors`,
        data: {
          news_item_sector: {
            news_item_id: this.state.newsItem.id, sector_id: sector.id, created_by: this.props.current_user.id
          }
        },
        dataType: 'json',
        success: (data) => {
          let newsItemSectors = update(this.state.newsItemSectors, {
            $push: [data.news_item_sector]
          })

          this.setState({
            newsItemSectors: newsItemSectors
          })
          this.props.snackbarShowMessage('The sector was added')
        },
        error: (data) => {
          this.props.snackbarShowMessage('The sector could not be added', 'error')
        }
      })
    }
  }

  handleProductSearchChange(e) {
    let query = e.target.value

    this.setState({
      productQuery: query
    })
  }

  handleProductSearchClick(e) {
    $.ajax({
      type: 'GET',
      url: `/admin/products/search`,
      data: {
        query: this.state.productQuery
      },
      dataType: 'json',
      success: (data) => {
        this.setState({
          searchedProducts: data.products
        })
      }
    })
  }

  handleAddCeMarkClick(e) {
    if (this.state.newsItemProducts.length > 0) {
      $.ajax({
        type: 'POST',
        url: `/admin/news/${this.state.newsItem.id}/add_ce_mark`,
        data: {
          created_by: this.props.current_user.id, sector_ids: this.state.newsItemSectors.map(newsItemSector => newsItemSector.sector_id), product_ids: this.state.newsItemProducts.map(newsItemProduct => newsItemProduct.product_id)
        },
        dataType: 'json',
        success: (data) => {
          this.props.snackbarShowMessage('CE Mark added')
        },
        error: (data) => {
          this.props.snackbarShowMessage('CE Mark could not be added', 'error')
        }
      })
    } else {
      this.props.snackbarShowMessage('Please associate a product first', 'error')
    }
  }

  render() {
    return(
      <Fragment>

        <Associator
          loading={this.state.loading}
          firstPanel={
            <NewsPanel
              newsItem={this.state.newsItem}
              newsItemText={this.props.news_item_text}
              publication={this.props.publication}
              newsType={this.props.news_type}
              searchWords={this.props.search_words}
            />
          }
          secondPanel={
            <Fragment>
              <ControlPanel
                reviewed={this.state.newsItem.reviewed}
                handleAssociateClick={this.handleAssociateClick}
                handleCreateAsAlertClick={this.handleCreateAlertClick}
                handleReviewedClick={this.handleReviewedClick}
              />
              
              <Paper>

                <Box width={1}>
                  <RaisedButton color='default' handleClick={this.handleCreateAlertClick}>
                    Create as alert
                  </RaisedButton>
                </Box>
              </Paper>

              <Paper>
                <Box width={1}>
                  <RaisedButton color='default' handleClick={this.handleAddCeMarkClick}>
                    Add CE Mark to associated product
                  </RaisedButton>
                  <Typography variant="caption">
                    <div>*Associate a product first</div>
                    <div>*Set the sector(s)</div>
                    <div>*Click this button to add CE Mark granted status to the product in the chosen sectors</div>
                  </Typography>
                </Box>
              </Paper>

              <SectorCheckboxList
                sectors={this.props.sectors}
                entitySectors={this.state.newsItemSectors}
                handleSectorCheckboxSelect={this.handleSectorCheckboxSelect}
              />
            </Fragment>
          }
          thirdPanel={
            <EntitiesPanel
              activeIndex={this.state.activeIndex}
              handleActiveIndexUpdate={this.handleActiveIndexUpdate}
              products={
                <ProductsList
                  activeProducts={this.state.activeProducts}
                  products={this.props.products}
                  searchedProducts={this.state.searchedProducts}
                  productQuery={this.state.productQuery}
                  handleProductSearchChange={this.handleProductSearchChange}
                  handleProductSearchClick={this.handleProductSearchClick}
                  handleCheckboxSelect={this.handleCheckboxSelect}
                />
              }
              trials={
                <TrialsList 
                  entityTrials={this.state.newsItemTrials}
                  activeTrials={this.state.activeTrials}
                  trials={this.props.trials}
                  searchedTrials={this.state.searchedTrials}
                  query={this.state.trialsQuery}
                  handleSearchChange={this.handleTrialSearchChange}
                  handleSearchClick={this.handleTrialSearchClick}
                  handleCheckboxSelect={this.handleCheckboxSelect}
                />
              }
              companies={
                <CompaniesList 
                  entityCompanies={this.state.newsItemCompanies}
                  activeCompanies={this.state.activeCompanies}
                  companies={this.props.companies}
                  searchedCompanies={this.state.searchedCompanies}
                  query={this.state.companyQuery}
                  handleSearchChange={this.handleCompanySearchChange}
                  handleSearchClick={this.handleCompanySearchClick}
                  handleCheckboxSelect={this.handleCheckboxSelect}
                />
              }
              associated={
                <AssociatedList
                  entityProducts={this.state.newsItemProducts}
                  entityTrials={this.state.newsItemTrials}
                  entityCompanies={this.state.newsItemCompanies}
                  handleProductDeleteClick={this.handleProductDeleteClick}
                  handleTrialDeleteClick={this.handleTrialDeleteClick}
                  handleCompanyDeleteClick={this.handleCompanyDeleteClick}
                />
              }
            />
          }
          associationLoading={this.state.associationLoading}
        />
      </Fragment>
    )
  }
}

export default withSnackbar(AssociatorContainer)