import React, { Component, Fragment, useState } from "react"
import Paper from "../../Paper"
import Flex from "../../Flex"
import Box from "../../Box"
import Typography from "@material-ui/core/Typography"
import Select from "../../Select"
import NewsList from "./NewsList"
import update from "immutability-helper"
import TextInput from "../../TextInput"
import { RaisedButton } from "../../buttons"
import SearchIcon from '@material-ui/icons/Search'
import InputAdornment from '@material-ui/core/InputAdornment';
import Dialog from "../../Dialog"
import AlertCreationForm from "./AlertCreationForm"
import { withSnackbar } from "../../SnackbarContainer"
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import InlineSearchContainer from '../search/InlineSearchContainer'

class News extends Component {
  constructor(props) {
    super(props)
    this.handleNewsTypeChange = this.handleNewsTypeChange.bind(this)
    this.handleDeleteClick = this.handleDeleteClick.bind(this)
    this.handleSearchChange = this.handleSearchChange.bind(this)
    this.handleSearchSubmit = this.handleSearchSubmit.bind(this)
    this.handleFilterChange = this.handleFilterChange.bind(this)
    this.handleNewsCheckboxClick = this.handleNewsCheckboxClick.bind(this)
    this.handleAlertDialogClick = this.handleAlertDialogClick.bind(this)
    this.handleCreateAlertClick = this.handleCreateAlertClick.bind(this)
    this.handleSectorCheckboxSelect = this.handleSectorCheckboxSelect.bind(this)
    this.handlePageChange = this.handlePageChange.bind(this)
    this.getNewsItems = this.getNewsItems.bind(this)
    this.state = { newsType: 'all', newsItems: this.props.news, newsItemCount: this.props.news_count, filterValues: { page: this.props.page, search_keyword_id: this.props.search_keyword_id, sector_id: this.props.sector_id, query: this.props.query, date: this.props.date, news_publication_id: this.props.news_publication_id, association_type: this.props.association_type, user_id: this.props.user_id }, association_types: this.props.association_types, checkedItems: [], alertSectorIds: [], openDialog: false }
  }

  handleAlertDialogClick(e) {
    this.setState({
      openDialog: true
    })
  }

  handleSectorCheckboxSelect(sector) {
    let index = this.state.alertSectorIds.indexOf(sector.id)

    let sectorIds = ""

    if (index == -1) {
      sectorIds = update(this.state.alertSectorIds, {
        $push: [sector.id]
      })
    } else {
      sectorIds = update(this.state.alertSectorIds, {
        $splice: [[index, 1]]
      })
    }

    this.setState({
      alertSectorIds: sectorIds
    })
  }

  handlePageChange(e, newPage) {
    let params = Object.assign({}, this.state.filterValues)

    params["page"] = newPage

    this.getNewsItems(params)
  }

  handleCreateAlertClick(e) {
    $.ajax({
      type: "POST",
      url: "/admin/news/create_alerts",
      data: {
        items: this.state.checkedItems, sector_ids: this.state.alertSectorIds
      },
      dataType: "json",
      success: (data) => {

        this.setState({
          openDialog: false,
          checkedItems: [],
          alertSectorIds: []
        })

        this.props.snackbarShowMessage("Alerts have been created")
      }
    })
  }

  handleNewsCheckboxClick(newsArticle) {
    let index = this.state.checkedItems.indexOf(newsArticle)

    let checkedItems = ""

    if (index == -1) {
      checkedItems = update(this.state.checkedItems, {
        $push: [newsArticle]
      })
    } else {
      checkedItems = update(this.state.checkedItems, {
        $splice: [[index,1]]
      })
    }

    this.setState({
      checkedItems: checkedItems
    })
  }

  handleNewsTypeChange(name, value) {
    // setNewsType(value)
    // let newsTypeHash = { all: newsItems, press_releases: props.press_releases, news_articles: props.news_articles }
    // setNewsItems(newsTypeHash[value])
    // let newsTypeName = { all: 'News Articles and Press Releases'}
  }

  handleSearchChange(e) {
    let filterValues = update(this.state.filterValues, {
      query: { $set: e.target.value}
    })

    this.setState({
      filterValues: filterValues
    })
  }

  handlePublicationSearchChange(e, selected) {
    let params = Object.assign({}, this.state.filterValues)

    params['news_publication_id'] = selected.value

    this.getNewsItems(params)
  }

  handleSearchSubmit(query) {
    $.ajax({
      type: 'GET',
      url: `/admin/news/search`,
      data: {
        query: query
      },
      dataType: 'json',
      success: (data) => {
        let filterValues = update(this.state.filterValues, {
          query: { $set: query }
        })
        
        this.setState({
          newsItems: data.results,
          filterValues: filterValues
        })
      }
    })
  }

  handleDeleteClick(newsItem) {
    $.ajax({
      type: 'PUT',
      url: `/admin/news/${newsItem.id}`,
      data: {
        news_item: {
          relevant: false
        }
      },
      dataType: 'json',
      success: (data) => {
        let index = this.state.newsItems.indexOf(newsItem)

        let items = update(this.state.newsItems, {
          $splice: [[index,1]]
        })

        this.setState({
          newsItems: items
        })
      }
    })
  }

  handleFilterChange(name, value) {
    let params = Object.assign({}, this.state.filterValues)

    params[name] = value

    this.getNewsItems(params)
  }

  getNewsItems(params) {
    $.ajax({
      type: 'GET',
      url: `/admin/news`,
      data: params,
      dataType: 'json',
      success: (data) => {
        this.setState({
          newsItems: data.news,
          newsItemCount: data.news_count,
          filterValues: params
        })
      }
    })
  }

  render() {
    let typeLabel = this.state.newsType == 'all' ? 'Articles And Press Releases' : humanizeString(this.state.newsType)

    return(
      <Paper fullHeight>

        {this.state.openDialog ?
          <Dialog
            openDialog={this.state.openDialog}
            dialogTitle='Create Alerts'
            handleAddClick={this.handleCreateAlertClick}
            handleClose={this.handleCloseDialog}
            withButtons
            confirmText="Add"
            dialogContent={
              <AlertCreationForm
                sectors={this.props.sector_list}
                sectorIds={this.state.alertSectorIds}
                handleCheckboxSelect={this.handleSectorCheckboxSelect}
              />
            }
          />
        : ''}

        <Box height="100%">

          <Flex alignItems='center' mb={3}>
            <Box width={1/5} mr={4}>
              <Select
                value={this.state.filterValues.association_type}
                name='association_type'
                label='Association Type'
                entities={this.props.association_types}
                handleChange={this.handleFilterChange}
              />
            </Box>

            <Box width={1/5} mr={4}>
              <Select
                value={this.state.filterValues.date || ''}
                name='date'
                label='Date'
                entities={this.props.dates}
                handleChange={this.handleFilterChange}
                includeBlank={true}
              />
            </Box>

            <Box width={1/5} mr={4}>
              <Select
                value={this.state.filterValues.user_id || ''}
                name='user_id'
                label='User'
                entities={this.props.users}
                handleChange={this.handleFilterChange}
                includeBlank={true}
              />
            </Box>

            <Box width={1/5} mr={4}>
              <Select
                value={this.state.filterValues.news_publication_id || ''}
                name='news_publication_id'
                label='Publication'
                entities={this.props.publications}
                handleChange={this.handleFilterChange}
                includeBlank={true}
              />
            </Box>

            <Box width={1/2}>
              <InlineSearchContainer
                searchLabel="Search News" 
                query={this.state.filterValues.query}
                handleSearchClick={this.handleSearchSubmit}
                // handleClearSearchClick={this.handleClearSearchClick}
              />
            </Box>

          </Flex>

          <Box mx="auto" width={[3/4, 1/2, 1/4]}>
            {this.state.checkedItems.length > 0 ?
              <RaisedButton onClick={this.handleAlertDialogClick}>
                Create as Alerts
              </RaisedButton>
            : ""}
          </Box>

          <Box textAlign='right'>
            {`${this.state.newsItemCount} ${typeLabel}`}
          </Box>

          <Box overflow='auto' height="80%" mt={2}>
            <NewsList
              newsItems={this.state.newsItems}
              newsCount={this.state.newsItemCount}
              checkedItems={this.state.checkedItems}
              handleNewsCheckboxClick={this.handleNewsCheckboxClick}
              handleDeleteClick={this.handleDeleteClick}
              page={this.state.filterValues.page}
              handlePageChange={this.handlePageChange}
            />
          </Box>
        </Box>
      </Paper>
    )
  }
}

export default withSnackbar(News)
