import React, { Fragment, Component } from "react"
import ReactDOM from "react-dom"
import Paper from "../../Paper"
import { Table, TableHead, TableRow, TableCell, TableBody } from "../../tables"
import Box from "../../Box"
import { withSnackbar } from "../../SnackbarContainer"
import DeleteButton from "../DeleteButton"
import Link from "../../Link"
import update from "immutability-helper"

class Grants extends Component {
  constructor(props) {
    super(props)
    this.handleDeleteClick = this.handleDeleteClick.bind(this)
    this.state = { grants: this.props.grants }
  }

  handleDeleteClick(grant) {
    $.ajax({
      type: "PUT",
      url: `/admin/sbir_grant_companies/${grant.sbir_grant_company_id}`,
      data: {
        sbir_grant_company: {
          deleted: true, deleted_by: this.props.user.id
        }
      },
      dataType: 'json',
      success: (data) => {
        let index = this.state.grants.indexOf(grant)

        console.log(this.state.grants)

        let grants = update(this.state.grants, {
          $splice: [[index, 1]]
        })

        this.setState({
          grants: grants
        })

        this.props.snackbarShowMessage("The grant was removed")
      },
      error: (data) => {
        this.props.snackbarShowMessage("The grant could not be removed. Refresh the page and try again.", "error")
      }
    })
  }

  render() {
    return(
      <Fragment>
        <Paper fullHeight>
          <Box height="100%">

            <Box overflow='auto' height="90%">

              <Table size="small" stickyHeader={true}>
                <TableHead>
                  <TableRow>
                    <TableCell>Firm</TableCell>
                    <TableCell>Award Title</TableCell>
                    <TableCell>Abstract</TableCell>
                    <TableCell>Year</TableCell>
                    <TableCell />
                  </TableRow>
                </TableHead>

                <TableBody>
                  {this.state.grants.map((grant) =>
                    <TableRow key={grant.id}>
                      <TableCell>
                        {grant.firm}
                      </TableCell>
                      <TableCell>
                        <Link href={grant.award_link}>
                          {grant.award_title}
                        </Link>
                      </TableCell>
                      <TableCell>{grant.abstract}</TableCell>
                      <TableCell>{grant.award_year}</TableCell>
                      <TableCell>
                        <DeleteButton
                          onClick={() => { this.handleDeleteClick(grant) }}
                        />
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>

            </Box>
          </Box>
        </Paper>
      </Fragment>
    )
  }
}

export default withSnackbar(Grants)
