import React, { Component } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Card from "../cards/Card.jsx"
import CardSection from "../cards/CardSection.jsx"
import Link from "../Link"
import Box from "../Box"
import { List, ListItemLink, ListItem, ListItemText } from "../lists"
import DoiLink from "./DoiLink.jsx"

export default class PublicationCard extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <Card background='bg-light-cyan'>
        <CardSection>

          {this.props.manuscript.pubmed_id ? 
            <Box mb={2}>
              <div className='b'>PMID</div>
              <div>
                <Link href={this.props.manuscript.url} target="_blank">
                  {this.props.manuscript.pubmed_id}
                </Link>
              </div>
            </Box>
          : ''}

          {this.props.manuscript.doi ? 
            <Box mb={2}>
              <div className='b'>DOI</div>
              <div>
                <DoiLink doi={this.props.manuscript.doi} />
              </div>
            </Box>
          : ''}

          {this.props.manuscript.citation ?
            <Box mb={1}>
              <div className='b'>Citation</div>
              <div>{this.props.manuscript.citation}</div>
            </Box>
          : '' }

        </CardSection>

        {this.props.companies.length > 0 || this.props.products.length > 0 || this.props.trials.length > 0 ? <CardSection>
          <Box mt={2}>
            {this.props.companies.length > 0 ?
              <Box mb={2}>
                <div className='b'>Companies</div>
                <List>
                  {this.props.companies.map((company) =>
                    <ListItemLink key={company.id} href={`/sectors/${this.props.sector.abbr}/companies/${company.id}`}>
                      <ListItemText primary={company.short_name} />
                    </ListItemLink>
                  )}
                </List>
              </Box>
            : ''}

            {this.props.products.length > 0 ?
              <Box mb={2}>
                <div className='b'>Products</div>
                <List>
                  {this.props.products.map((product) =>
                    <ListItemLink key={product.id} href={`/sectors/${this.props.sector.abbr}/products/${product.id}`}>
                      <ListItemText primary={product.short_name} />
                    </ListItemLink>
                  )}
                </List>
              </Box>
            : ''}

            {this.props.trials.length > 0 ?
              <Box>
                <div className='b'>Trials</div>
                <List>
                  {this.props.trials.map((trial) =>
                    <ListItemLink key={trial.trial_id} href={`/sectors/${this.props.sector.abbr}/trials/${trial.trial_id}`}>
                      <ListItemText primary={trial.acronym || trial.short_name} />
                    </ListItemLink>
                  )}
                </List>
              </Box>
            : ''}
          </Box>
        </CardSection> : ''}

        {this.props.targetSites.length > 0 ?
          <CardSection>
            <Box mt={2}>
              <Box mb={2} className='b'>Target Sites</Box>

              <Box ml={2}>
                {this.props.targetSites.map((targetSite) =>
                  <Box key={targetSite.id} pb={2}>
                    {targetSite.name}
                  </Box>
                )}
              </Box>
            </Box>
          </CardSection>
        : ''}

        {this.props.therapyTypes.length > 0 ?
          <CardSection>
            <Box mt={2}>
              <Box mb={2} className='b'>Therapy</Box>

              <Box ml={2}>
                {this.props.therapyTypes.map((therapyType) =>
                  <Box key={therapyType.id} pb={2}>
                    {therapyType.name}
                  </Box>
                )}
              </Box>
            </Box>
          </CardSection>
        : ''}

        {/* <CardSection>
          <Box mt={2}>
            <Box fontWeight={700}>
              Related articles
            </Box>

            <Box>

            </Box>
          </Box>
        </CardSection> */}
      </Card>
    )
  }
}
