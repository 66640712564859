import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import { Table, TableHead, TableRow, TableCell, TableBody } from "../../tables"
import ExpandLess from '../../ExpandLess';
import ExpandMore from '../../ExpandMore';
import Button from "../../buttons/Button"
import Link from "../../Link"
import IndeterminateCheckBoxIcon from '@material-ui/icons/IndeterminateCheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import IconButton from "../../IconButton"
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Checkbox from '@material-ui/core/Checkbox';
import MuiIconButton from '@material-ui/core/IconButton';


export default class SessionPresentations extends Component {
  constructor(props) {
    super(props)
    this.handleSessionClick = this.handleSessionClick.bind(this)
    this.state = { open: true }
  }

  handleSessionClick(e) {
    const openState = !this.state.open

    this.setState({
      open: openState
    })
  }

  render() {
    return(
      <Fragment>

        <TableRow>
          <TableCell colSpan={7} className='pv2'>
            <Button onClick={this.handleSessionClick} color='default' lowercase={true} className='f6'>
              {this.state.open ? <ExpandLess /> : <ExpandMore />}
              {this.props.sessionTitle}
            </Button>
          </TableCell>
        </TableRow>

        {this.state.open ?
          this.props.abstracts.map((abstract) =>
            <TableRow key={abstract.id} component={Link} href={`/admin/abstracts/${abstract.id}`} target="_blank">
              <TableCell borderRight padding='checkbox' className={false ? '' : 'o-50'}>
                <MuiIconButton onClick={this.handleProductCheckboxClick} edge="start">
                  {false ? <CheckBoxIcon color='primary' style={{height: '22px', width: '22px'}} /> : <CheckBoxOutlineBlankIcon style={{height: '22px', width: '22px'}} />}
                </MuiIconButton>
              </TableCell>
              <TableCell borderRight>
                {abstract.number ? abstract.number : ''}
              </TableCell>
              <TableCell borderRight>
                {abstract.start_time ? abstract.start_time : ''}
              </TableCell>
              <TableCell>
                {abstract.title ? abstract.title : 'N/A'}
              </TableCell>
              <TableCell>
                {abstract.presenter ? abstract.presenter : 'N/A'}
              </TableCell>
              <TableCell className='red'>
                {abstract.pdf ? 'YES' : ''}
              </TableCell>
              <TableCell>
                {/* N/A */}
              </TableCell>
            </TableRow>
          ) : ''}

      </Fragment>
    )
  }
}
