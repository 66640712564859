import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import { List, ListItem, ListItemText } from "../../lists"
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction"
import Typography from '@material-ui/core/Typography';
import update from "immutability-helper"
import Select from "../../Select"
import Box from "../../Box"
import DeleteButton from "../DeleteButton"
import { withSnackbar } from "../../SnackbarContainer"
import LoadingContainer from "../../LoadingContainer"

class ComponentForm extends Component {
  constructor(props) {
    super(props)
    this.handleComponentChange = this.handleComponentChange.bind(this)
    this.handleDeleteClick = this.handleDeleteClick.bind(this)
    this.state = { product_id: '', productComponents: this.props.productComponents, loading: true }
  }

  componentDidMount() {
    $.ajax({
      type: "GET",
      url: `/admin/products/${this.props.product.id}/components`,
      dataType: 'json',
      success: (data) => {
        this.setState({
          productComponents: data.product_components,
          componentProducts: data.component_products,
          loading: false
        })
      },
      error: (data) => {
        this.setState({
          loading: false
        })
        this.props.snackbarShowMessage("There was an error fetching the data. Please refresh the page. If the issue persists, create an asana task.", "error")
      }
    })
  }

  handleComponentChange(name, value) {
    $.ajax({
      type: 'POST',
      url: `/admin/product_families`,
      data: {
        product_family: {
          product_id: this.props.product.id, child_product_id: value
        }
      },
      dataType: 'json',
      success: (data) => {
        this.setState({
          productComponents: data.product_family
        })
      }
    })
  }

  handleDeleteClick(productFamily) {
    $.ajax({
      type: 'PUT',
      url: `/admin/product_families/${productFamily.family_id}`,
      data: {
        product_family: {
          deleted: 1
        }
      },
      dataType: 'json',
      success: (data) => {
        let productComponentIds = this.state.productComponents.map((productComponent) => productComponent.family_id)
        let index = productComponentIds.indexOf(data.product_family.id)
        let productComponents = update(this.state.productComponents, { $splice: [[index, 1]] } )

        this.setState({
          productComponents: productComponents
        })
      }
    })
  }

  render() {
    return(
      <Fragment>

        <LoadingContainer isLoading={this.state.loading}>
          <Box mb={4}>
            <Typography variant="h6" component="h6">
              Add Component
            </Typography>

            <Box my={3}>
              <Select
                name='product_id'
                value={this.state.product_id || ''}
                label='Components'
                entities={this.state.componentProducts}
                handleChange={this.handleComponentChange}
              />
            </Box>
          </Box>

          <Typography variant="h6" component="h6">
            Components
          </Typography>

          <List>
            {this.state.productComponents ?
              this.state.productComponents.map((productComponent) =>
                <ListItem key={productComponent.id} divider button>

                  <ListItemText primary={productComponent.short_name} />

                  <ListItemSecondaryAction>
                    <DeleteButton onClick={() => { this.handleDeleteClick(productComponent)}} />
                  </ListItemSecondaryAction>

                </ListItem>
              )
            : ''}
          </List>
        </LoadingContainer>

      </Fragment>
    )
  }
}

export default withSnackbar(ComponentForm)
