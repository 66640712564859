import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Paper from "../../Paper"
import Form from "./Form"
import update from "immutability-helper"
import Snackbar from "../../Snackbar"

export default class EditContainer extends Component {
  constructor(props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
    this.handleSaveClick = this.handleSaveClick.bind(this)
    this.handleDateChange = this.handleDateChange.bind(this)
    this.state = { fdaDocument: this.props.fda_document, showSnackbar: false, snackbarText: '' }
  }

  handleChange(e) {
    const name = e.currentTarget.name
    const value = e.currentTarget.value

    const fdaDocument = update(this.state.fdaDocument, {
      [name]: { $set: value }
    })

    this.setState({
      fdaDocument: fdaDocument
    })
  }

  handleDateChange(date) {
    const fdaDocument = update(this.state.fdaDocument, {
      date: { $set: date }
    })

    this.setState({
      fdaDocument: fdaDocument
    })
  }

  handleSaveClick(e) {
    $.ajax({
      type: 'PUT',
      url: `/admin/fda_documents/${this.state.fdaDocument.id}`,
      data: {
        fda_document: this.state.fdaDocument
      },
      error: (data) => {
        this.setState({
          showSnackbar: true,
          snackbarText: `The FDA Document could not be updated. ${data.responseText}`
        })
      },
      complete: (data) => {
        setTimeout(() => {
          this.setState({
            showSnackbar: false,
            snackbarText: ''
          })
        }, 4000)
      }
    })
  }

  render() {
    return(
      <Fragment>

        <Paper>

          <Form
            fdaDocument={this.state.fdaDocument}
            handleChange={this.handleChange}
            handleDateChange={this.handleDateChange}
            handleSaveClick={this.handleSaveClick}
          />

        </Paper>

        <Snackbar
          open={this.state.showSnackbar}
          message={this.state.snackbarText}
        />

      </Fragment>
    )
  }
}
