import React, { Component, Fragment } from "react"
import Paper from "../../Paper"
import { Button } from "../../buttons"
import Typography from '@material-ui/core/Typography';
import Flex from "../../Flex"
import Box from "../../Box"
import { List, ListItem, ListItemText } from "../../lists"
import update from "immutability-helper"
import EditButton from "../EditButton"
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction"

export default class SearchTermContainer extends Component {
  constructor(props) {
    super(props)
    this.state = { productSearchTerms: this.props.product_search_terms }
  }

  handleDeleteClick(productSearchTerm) {
    $.ajax({
      type: 'PUT',
      url: `/admin/product_search_terms/${productSearchTerm.id}`,
      data: {
        product_search_term: {
          deleted: true
        }
      },
      dataType: 'json',
      success: (data) => {
        let index = this.state.productSearchTerms.indexOf(productSearchTerm)
        let productSearchTerms = update(this.state.productSearchTerms, {
          $splice: [[index,1]]
        })

        this.setState({
          productSearchTerms: productSearchTerms
        })
      }
    })
  }

  render() {
    return(
      <Fragment>

        <Paper minFullHeight>
          <Flex alignItems='center'>
            <Box>
              <Typography variant="h6" component="h6">
                Search Terms
              </Typography>
            </Box>

            <Box ml='auto'>
              <Button href={`/admin/products/${this.props.product.id}/product_search_terms/new`}>
                New Search Term
              </Button>
            </Box>
          </Flex>

          <Box mt={3}>

            <Box width={1/4}>
            <List>
              {this.state.productSearchTerms.map((productSearchTerm) =>
                <ListItem key={productSearchTerm.id} component='a' divider button href={`/admin/products/${this.props.product.id}/product_search_terms/${productSearchTerm.id}/edit`}>
                  <ListItemText
                    primary={productSearchTerm.search_term}
                  />

                  <ListItemSecondaryAction>
                    <EditButton />
                  </ListItemSecondaryAction>
                </ListItem>
              )}
            </List>
            </Box>
          </Box>
        </Paper>

      </Fragment>
    )
  }
}
