import React, { Fragment } from "react"
import Select from "../Select"
import Flex from "../Flex"
import Box from "../Box"

export default function PmaApprovalsFilters(props) {
  return (
    <Fragment>

      <Flex alignItems='center'>
        {props.pmaNumberOptions ?
          <Box width={1/3} mr={2}>
            <Select
              includeBlank
              name='pma_number'
              label='PMA'
              entities={props.pmaNumberOptions}
              value={props.pmaNumber}
              handleChange={props.handleFilterChange}
            />
          </Box>
        : ""}

        <Box width={1/3}>
          <Select
            name='supplement_reason'
            label='Supplement Reason'
            includeBlank={true}
            entities={props.supplement_reasons}
            value={props.supplementReason}
            handleChange={props.handleFilterChange}
          />
        </Box>
      </Flex>

    </Fragment>
  )
}
