import React, { Fragment } from "react"
import PropTypes from "prop-types"
import Select from "../Select"
import Filter from "../filters/Filter"
import Flex from "../Flex"
import Box from "../Box"
import Grid from '@material-ui/core/Grid';
import SearchIcon from '@material-ui/icons/Search'
import TextInput from "../TextInput"
import InputAdornment from '@material-ui/core/InputAdornment';


export default function Filters(props) {

  const selectedTimePeriod = props.filterOptions.years.filter(date => props.filterValues.time_period == date.value ? date : null)

  return (
    <Grid container spacing={2} alignItems='stretch'>

      <Grid item xs={4} lg>
        <Box>
          <Filter
            label='Trial'
            name='trial_ids'
            value={props.filterValues.trial_ids}
            entities={props.filterOptions.trials}
            handleChange={props.handleFilterChange}
          />
        </Box>
      </Grid>

      <Grid item xs={4} lg>
        <Box>
          <Filter
            label='Product'
            name='product_ids'
            value={props.filterValues.product_ids}
            entities={props.filterOptions.products}
            handleChange={props.handleFilterChange}
          />
        </Box>
      </Grid>

      {props.sector.monitor_product ?
        ''
      :
        <Fragment>
          {props.sector.market_id == 3 ?
            <Grid item xs={4} lg>
              <Box>
                <Filter
                  label='Target Market'
                  name='target_market_ids'
                  value={props.filterValues.target_market_ids}
                  entities={props.filterOptions.targetMarkets}
                  handleChange={props.handleFilterChange}
                />
              </Box>
            </Grid>
          :
            <Grid item xs={4} lg>
              <Box>
                <Filter
                  label='Target Site'
                  name='target_site_ids'
                  value={props.filterValues.target_site_ids}
                  entities={props.filterOptions.targetSites}
                  handleChange={props.handleFilterChange}
                />
              </Box>
            </Grid>
          }

          <Grid item xs={4} lg>
            <Box>
              <Filter
                label='Therapy'
                name='therapy_type_ids'
                value={props.filterValues.therapy_type_ids}
                entities={props.filterOptions.therapyTypes}
                handleChange={props.handleFilterChange}
              />
            </Box>
          </Grid>
        </Fragment>
      }

      <Grid item xs={4} lg>
        <Box>
          <Filter
            label='Journal'
            name='journal_ids'
            value={props.filterValues.journal_ids}
            entities={props.filterOptions.journals}
            handleChange={props.handleFilterChange}
          />
        </Box>
      </Grid>

      <Grid item xs={4} lg>
        <Box>
          <Filter
            multiple={false}
            label='Time Period'
            name='time_period'
            value={props.filterValues.time_period}
            entities={props.filterOptions.years}
            handleChange={props.handleFilterChange}
            renderValue={false}
          />
        </Box>
      </Grid>

      {props.forSearch ?
        <Grid item xs={6} lg>
          <Box pt={1}>
            <form onSubmit={props.handleSearchClick}>
              <TextInput
                name='query'
                placeholder='Search'
                size='small'
                value={props.filterValues.query ? props.filterValues.query : ''}
                onChange={props.handleChange}
                className='w-100'
                InputProps={{
                  startAdornment: <InputAdornment position="start"><SearchIcon /></InputAdornment>,
                }}
              />
            </form>
          </Box>
        </Grid>
      : ''}

    </Grid>
  )
}

Filters.defaultProps = {
  forSearch: true
}
