import React from "react"
import { TableRow, TableCell } from "../../tables"
import Box from "../../Box"
import Switch from "../../Switch"
import Link from "../../Link"
import DeleteButton from "../DeleteButton"

export default function StagedAssociatorRow(props) {

  function handleStatusChange(e) {
    e.preventDefault()
    props.publication.manuscript_associator_status ? props.handleStatusUpdate(props.publication.manuscript_associator_status, props.publication) : props.handleStatusChange(props.publication)
  }

  function handleHideClick(e) {
    props.handleHideClick(props.publication)
  }

  return (
    <TableRow>

      <TableCell>
        <Link href={`https://pubmed.ncbi.nlm.nih.gov/${props.publication.pubmed_id}`} target="_blank">
          <Box>
            {props.publication.pubmed_id}
          </Box>
        </Link>
      </TableCell>

      <TableCell>
        <Link href={`/admin/publications/${props.publication.id}/associator`} target="_blank">
          <Box>
            {props.publication.title}
          </Box>
        </Link>
      </TableCell>

      <TableCell align='right'>
        {props.publication.published_date}
      </TableCell>

      <TableCell align='right'>
        {props.publication.trial_count}
      </TableCell>

      <TableCell align='right'>
        <DeleteButton tooltip='Hide' edge="end" size="small" onClick={handleHideClick} />
      </TableCell>
    </TableRow>
  )

}
