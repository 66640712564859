import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import { TableRow, TableCell } from "../../tables"
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';

class TrialProductRow extends Component {
  constructor(props) {
    super(props)
    this.handleDeleteClick = this.handleDeleteClick.bind(this)
  }

  handleDeleteClick(e) {
    this.props.handleDeleteClick(this.props.trialProductHash)
  }

  render() {
    return(
      <TableRow>
        <TableCell>
          {this.props.trialProductHash.product.short_name}
        </TableCell>

        <TableCell>
          {this.props.trialProductHash.source_object ? this.props.trialProductHash.source_object.source_title : 'N/A'}
        </TableCell>

        <TableCell>
          {this.props.trialProductHash.trial_product.investigated_device ? "Investigated" : (this.props.trialProductHash.trial_product.comparator ? "Comparator" : "N/A")}
        </TableCell>

        <TableCell>
          {this.props.trialProductHash.creator ? displayInitials(this.props.trialProductHash.creator.first_name, this.props.trialProductHash.creator.last_name) : "N/A"}
        </TableCell>

        <TableCell>
          <IconButton onClick={this.handleDeleteClick}>
            <DeleteIcon />
          </IconButton>
        </TableCell>
      </TableRow>
    )
  }
}

export default TrialProductRow
