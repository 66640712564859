import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Paper from "../Paper"
import SearchIcon from '@material-ui/icons/Search'
import TextInput from "../TextInput"
import InputAdornment from '@material-ui/core/InputAdornment';
import { RaisedButton, Button } from "../buttons"
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Flex from "../Flex"
import Box from "../Box"
import IconButton from "../IconButton"
import CloseIcon from '@material-ui/icons/Close';
import { List, ListItem, ListItemLink, ListItemText } from "../lists"
import ListItemIcon from '@material-ui/core/ListItemIcon';
import { Tabs, Tab, TabPanel } from "../tabs"
import Typography from "@material-ui/core/Typography"
import { withRouter } from "react-router-dom";
import Pagination from '@material-ui/lab/Pagination';
import { withSnackbar } from "../SnackbarContainer"
import Select from "../Select"
import CheckIcon from '@material-ui/icons/Check';
import CircularProgress from '@material-ui/core/CircularProgress';

class Search extends Component {
  constructor(props) {
    super(props)
    this.handleActiveIndexClick = this.handleActiveIndexClick.bind(this)
    this.handleSearchChange = this.handleSearchChange.bind(this)
    this.handleSearchClick = this.handleSearchClick.bind(this)
    this.handlePageChange = this.handlePageChange.bind(this)
    this.handleFilterChange = this.handleFilterChange.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.handleOpen = this.handleOpen.bind(this)
    this.state = { query: this.props.query, searchResults: this.props.search_results, resultsCount: this.props.results_count, activeIndex: 'All', page: 1, date: this.props.date, anchorEl: null, loading: false }
  }

  componentDidMount() {
    if (this.props.filter) {

      let index = this.props.filter

      this.setState({
        activeIndex: index,
        filter: this.props.filter
      })
    }
  }

  handleOpen(event) {
    this.setState({
      anchorEl: event.currentTarget
    })
  };

  handleClose() {
    this.setState({
      anchorEl: null
    })
  };

  handleFilterChange(value) {
    $.ajax({
      type: "GET",
      url: `/sectors/${this.props.sector.abbr}/products/${this.props.product.id}/search`,
      data: {
        query: this.state.query, date: value, filter: this.state.activeIndex
      },
      dataType: 'json',
      success: (data) => {
        this.setState({
          date: value,
          searchResults: data.search_results,
          resultsCount: data.results_count,
          anchorEl: null
        })
      }
    })
  }

  handlePageChange(e, page) {
    let url = `/sectors/${this.props.sector.abbr}/products/${this.props.product.id}/search`

    let filter = this.state.activeIndex

    $.ajax({
      type: 'GET',
      url: url,
      data: {
        query: this.state.query, page: page, filter: filter
      },
      dataType: 'json',
      success: (data) => {

        let params = {}

        params["query"] = this.state.query
        params["page"] = page
        filter ? params["filter"] = filter : ''

        let filterKeys = Object.keys(params)
        let queryParams = filterKeys.map((filterKey) => `${filterKey}=${params[filterKey]}`)

        this.props.history.push(`${url}?${queryParams.join("&")}`);

        this.setState({
          searchResults: data.search_results,
          resultsCount: data.results_count,
          page: page,
          filter: filter
        })
      }
    })
  }

  handleActiveIndexClick(e, newValue) {
    let url = `/sectors/${this.props.sector.abbr}/products/${this.props.product.id}/search`

    let filter = newValue

    $.ajax({
      type: 'GET',
      url: url,
      data: {
        query: this.state.query, filter: filter, date: this.state.date
      },
      dataType: 'json',
      success: (data) => {

        let params = {}
        params["query"] = this.state.query
        filter ? params["filter"] = filter : ''

        let filterKeys = Object.keys(params)
        let queryParams = filterKeys.map((filterKey) => `${filterKey}=${params[filterKey]}`)

        this.props.history.push(`${url}?${queryParams.join("&")}`);

        this.setState({
          searchResults: data.search_results,
          resultsCount: data.results_count,
          activeIndex: newValue,
          filter: filter
        })
      }
    })
  }

  handleSearchChange(e) {
    this.setState({
      query: e.target.value
    })
  }

  handleSearchClick(e) {
    let url = `/sectors/${this.props.sector.abbr}/products/${this.props.product.id}/search`

    $.ajax({
      type: 'GET',
      url: url,
      data: {
        query: this.state.query, filter: this.state.activeIndex
      },
      dataType: 'json',
      success: (data) => {

        let filter = this.state.activeIndex

        // this.props.history.push(`${url}?query=${this.state.query}`);
        let params = {}
        params["query"] = this.state.query
        filter ? params["filter"] = filter : ''

        let filterKeys = Object.keys(params)
        let queryParams = filterKeys.map((filterKey) => `${filterKey}=${params[filterKey]}`)

        this.props.history.push(`${url}?${queryParams.join("&")}`);

        this.setState({
          loading: false,
          searchResults: data.search_results,
          resultsCount: data.results_count,
          filter: filter
        })
      },
      error: (data) => {
        this.setState({
          loading: false
        })
        this.props.snackbarShowMessage("There was an error while trying to return results. Please try again.")
      },
      beforeSend: (data) => {
        this.setState({
          loading: true
        })
      }
    })
  }

  setUrl(result) {
    if (result.search_url) {
      return(result.search_url)
    } else if (result.record_type == 'Presentation' || result.record_type == 'Poster') {
      return(`/sectors/${this.props.sector.abbr}/abstracts/${result.record_id}`)
    } else if (result.record_type == 'Company') {
      return(`/sectors/${this.props.sector.abbr}/companies/${result.record_id}`)
    } else if (result.record_type == "NIH Grant") {
      return(`/sectors/${this.props.sector.abbr}/nih_project_grants/${result.record_id}`)
    } else if (result.record_type == "Trial") {
      return(`/sectors/${this.props.sector.abbr}/trials/${result.trial_id}`)
    } else if (result.record_type == "Patent") {
      return(`/sectors/${this.props.sector.abbr}/patents/${result.record_id}`)
    } else {
      return(`/sectors/${this.props.sector.abbr}/${result.record_type.toLowerCase()}s/${result.id}`)
    }
  }

  render() {
    return(
      <Fragment>
        <Paper minFullHeight>

          <Box height="100%">

            <form onSubmit={this.handleSearchClick} acceptCharset="UTF-8">
              <Flex alignItems='center' width={1} justifyContent='center'>
                <Box width={1/2}>
                  <TextInput
                    name='query'
                    label='Search'
                    placeholder={`Search ${this.props.product.short_name}`}
                    value={this.state.query}
                    onChange={this.handleSearchChange}
                    className='w-100'
                    InputProps={{
                      startAdornment: <InputAdornment position="start"><SearchIcon /></InputAdornment>,
                    }}
                  />
                </Box>

                <Box ml={3}>
                  <RaisedButton onClick={this.handleSearchClick}>
                    Search
                  </RaisedButton>
                </Box>

              </Flex>
            </form>

            <Box mt={4}>
              <Tabs variant='scrollable' scrollButtons='auto' value={this.state.activeIndex} onChange={this.handleActiveIndexClick} className='bb b--black-10'>
                <Tab label='All' value="All" />
                <Tab label='Trials' value="TrialDataView" />
                <Tab label='Presentations' value="Abstract" />
                <Tab label='Publications' value="Manuscript" />
                <Tab label='Approvals' value="FdaPmaApproval" />
                <Tab label='Recalls' value='recalls' />
                <Tab label='Adverse Events' value="FdaAdverseEvent" />
                <Tab label='News' value='NewsItem' />
                <Tab label='Twitter' value='Tweet' />
              </Tabs>
            </Box>

            <Flex my={2} alignItems='center' width="40%">
              <Box>
                {`${this.state.resultsCount} ${this.state.resultsCount > 1 || this.state.resultsCount == 0 ? 'results' : 'result'}`}
              </Box>

              <Box ml={3}>
                <Button aria-controls="date-menu" aria-haspopup="true" onClick={this.handleOpen} endIcon={<ArrowDropDownIcon />} color='default' className='ttn'>
                  {this.props.date_options.filter(dateOption => dateOption.value == this.state.date)[0].name}
                </Button>
                <Menu
                  id="search-date"
                  anchorEl={this.state.anchorEl}
                  open={Boolean(this.state.anchorEl)}
                  onClose={this.handleClose}
                >
                  {this.props.date_options.map((dateOption) =>
                    <MenuItem key={dateOption.value} onClick={() => { this.handleFilterChange(dateOption.value) }} dense>
                      <ListItemIcon size='small' styles={{minWidth: '30px'}}>
                        {this.state.date == dateOption.value ? <CheckIcon fontSize="small" /> : ""}
                      </ListItemIcon>
                      <ListItemText primary={dateOption.name} />
                    </MenuItem>
                  )}
                </Menu>
              </Box>

              <Box ml='auto'>
                {this.state.loading ? 
                  <CircularProgress />
                : ""}
              </Box>
            </Flex>

            <Box my={2} width="80%" mx='auto' height="100%">
              {this.state.resultsCount == 0 ?
                <Flex justifyContent='center' height="50%">
                  <Box alignSelf='center' maxWidth="50%" textAlign='center'>
                    <Box>
                      <Typography variant="body1">
                        {this.state.query ? 'No results found for this query' : 'Start searching by using the search input above'}
                      </Typography>
                    </Box>
                  </Box>
                </Flex>
              :
                <Fragment>
                  <List>
                    {this.state.searchResults.map((result) =>
                      <ListItemLink key={result.record_id} href={this.setUrl(result)} my={1} alignItems='flex-start' divider={true} target={result.search_url ? "_blank" : ""}>
                        <ListItemText>
                          {this.state.activeIndex == "NewsItem" ?
                            <Typography variant="caption" color="textSecondary">
                              {result.news_publication_name}
                            </Typography>
                          :
                            <Typography variant="caption" color="textSecondary">
                              {result.record_type}
                            </Typography>
                          }

                          <Box pb={1}>
                            <Typography variant="body1">
                              {result.search_title}
                            </Typography>
                          </Box>


                          <Box maxWidth="40em">
                            <Typography variant="body2" color="textSecondary">
                              <div
                                dangerouslySetInnerHTML={{__html: result.date ? `${humanDateFormat(result.date)} - ${result.search_description}` : result.search_description }}
                              />
                            </Typography>
                          </Box>

                          {result.search_details ? 
                            <Box mt={1}>
                              <Typography variant="body2" color="textPrimary">
                                <div
                                  dangerouslySetInnerHTML={{__html: result.search_details }}
                                />
                              </Typography>
                            </Box> 
                          : ""}
                        </ListItemText>
                      </ListItemLink>
                    )}
                  </List>

                  <Flex mt={2} justifyContent='center'>
                    <Pagination
                      page={this.state.page}
                      count={this.state.resultsCount > 20 ? Math.round(this.state.resultsCount/20) : 1}
                      onChange={this.handlePageChange}
                      showFirstButton
                      showLastButton
                    />
                  </Flex>
                </Fragment>
              }
            </Box>

          </Box>

        </Paper>
      </Fragment>
    )
  }
}

export default withRouter(withSnackbar(Search))
