import React, { Fragment } from "react"
import Paper from "../../Paper"
import List from "../../lists/List";
import AssociatedTrialListItem from "./AssociatedTrialListItem"
import AssociatedProductListItem from "./AssociatedProductListItem"
import ListSubheader from '@material-ui/core/ListSubheader';
import TrialListItem from "./TrialListItem"

export default function AssociatedList(props) {
  const publicationTrialIds = props.publicationTrials.map((publicationTrial) => publicationTrial.trial_id)
  const trialIdArray = [...publicationTrialIds, ...props.activeTrials]

  return (
    <Fragment>

      <List>
        {props.predictedTrials.length > 0 ?
          <Fragment>
            <ListSubheader>Predicted Trials</ListSubheader>

            {props.predictedTrials.map((predictedTrial) =>
              <TrialListItem
                key={predictedTrial.trial_id}
                trial={predictedTrial}
                hits={predictedTrial.publication_predicted_trial ? predictedTrial.publication_predicted_trial.hits : ''}
                handlePredictionAssociation={props.handlePredictionAssociation}
                handlePredictionRemoval={props.handlePredictionRemoval}
                handleUndoAssociation={props.handleUndoAssociation}
                handleUndoRemoval={props.handleUndoRemoval}
              />
            )}
          </Fragment>
        : ''}
        {props.publicationTrials.length > 0 ?
          <Fragment>
            <ListSubheader>Trials</ListSubheader>

            {props.publicationTrials.map((publicationTrial) =>
              <AssociatedTrialListItem
                key={publicationTrial.id}
                publicationTrial={publicationTrial}
                handleTrialDeleteClick={props.handleTrialDeleteClick}
              />
            )}
          </Fragment>
        : ''}

        {props.publicationProducts.length > 0 ?
          <Fragment>
            <ListSubheader>Products</ListSubheader>

            {props.publicationProducts.map((publicationProduct) =>
              <AssociatedProductListItem
                key={publicationProduct.id}
                publicationProduct={publicationProduct}
                handleProductDeleteClick={props.handleProductDeleteClick}
              />
            )}
          </Fragment>
        : ''}
      </List>

    </Fragment>
  )
}
