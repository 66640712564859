import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import ProfileFieldsContainer from "./ProfileFieldsContainer"
import ProductCard from "./ProductCard"
import EntityLayout from "../EntityLayout"
import Box from "../Box"
import Paper from "../Paper"
import RegionProfile from "./RegionProfile"
import Grid from '@material-ui/core/Grid';
import Tooltip from "@material-ui/core/Tooltip"
import Link from "../Link"
import Typography from "@material-ui/core/Typography"
import { List, ListItem, ListItemText, ListItemLink } from "../lists"
import Flex from "../Flex"
import { Button } from "../buttons"
import { Table, TableHead, TableBody, TableRow, TableCell } from "../tables"
import ArticlesList from "../articles/ArticlesList"
import Chart from "../charts/Chart"
import CardSection from "../cards/CardSection"
import SidebarSection from "../SidebarSection"
import OverviewModule from "./profile/OverviewModule"
import AttributesModule from "./AttributesModule"

export default class CvpProfileDashboard extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    return(
      <Fragment>

        <Grid container component={Box} pb={3} spacing={3}>
          <Grid item xs={12} lg={6}>
            <OverviewModule
              product={this.props.product}
              company={this.props.company}
              overview_description={this.props.overview_description}
              pmaApprovals={this.props.pma_approvals}
              productKClearances={this.props.product_k_clearances}
              sector={this.props.sector}
            />
          </Grid>

          <Grid item xs={12} lg={6} container>
            <Grid item container direction="column" spacing={2}>
              <Grid item className='h-40'>
                <Paper className='h-100'>
                  <Typography variant="h6">
                    Regulatory Status
                  </Typography>

                  <Box mt={2} height={1}>
                    {Object.keys(this.props.regulatory_statuses).length > 0 ?
                      <Box>
                        {Object.keys(this.props.regulatory_statuses).map((region) =>
                          <div key={region} className='mb2'>
                            <div className='b'>
                              {region}
                            </div>

                            {this.props.regulatory_statuses[region].map((regulatoryStatus) =>
                              <div key={regulatoryStatus.id}>
                                {regulatoryStatus.name}
                                {regulatoryStatus.status_display_date ?
                                  <Tooltip title='Date of status'>
                                    <span className='pl2 f6 i'>
                                      {`(${humanDateFormat(regulatoryStatus.status_display_date)})`}
                                    </span>
                                  </Tooltip>
                                : ""}
                              </div>
                            )}
                          </div>
                        )}
                      </Box>

                    :
                      <Flex justifyContent='center' height="50%">
                        <Box alignSelf='center' maxWidth="50%">
                          <Box>
                            <Typography variant="body1">
                              No status found
                            </Typography>
                          </Box>
                        </Box>
                      </Flex>
                    }
                  </Box>
                </Paper>
              </Grid>

              <Grid item className='h-30'>
                <Box height={1}>
                  <Paper height="100%">
                    <Typography variant="h6">
                      Our Data
                    </Typography>

                    <Box mt={2}>
                      <List dense>
                        <ListItemLink href={`/sectors/${this.props.sector.abbr}/products/${this.props.product.id}/studies`} divider={true}>
                          <ListItemText primary={`${this.props.trial_count} Trials`} />
                        </ListItemLink>

                        <ListItemLink href={`/sectors/${this.props.sector.abbr}/products/${this.props.product.id}/presentations`} divider={true}>
                          <ListItemText
                            primary={`${this.props.presentations_count} Presentations`}
                          />
                        </ListItemLink>

                        <ListItemLink href={`/sectors/${this.props.sector.abbr}/products/${this.props.product.id}/manuscripts`} divider={true}>
                          <ListItemText
                            primary={`${this.props.publications_count} Publications`}
                          />
                        </ListItemLink>

                        <ListItemLink href={`/sectors/${this.props.sector.abbr}/products/${this.props.product.id}/adverse_events`} divider={true}>
                          <ListItemText
                            primary={`${this.props.adverse_events_count} Adverse Events`}
                          />
                        </ListItemLink>

                      </List>
                    </Box>
                  </Paper>
                </Box>


              </Grid>

            </Grid>
          </Grid>
        </Grid>

        <Grid container spacing={3} alignItems='stretch' component={Box} pb={3}>
          <Grid item xs={12} lg={12}>
            <Paper className='h-100'>
              <Flex alignItems='center'>
                <Box>
                  <Typography variant="h6">
                    Latest Trial Updates
                  </Typography>
                </Box>

                <Box ml='auto'>
                  <Button href={`/sectors/${this.props.sector.abbr}/products/${this.props.product.id}/studies`} component='a'>
                    View All Trials
                  </Button>
                </Box>
              </Flex>

              <Box mt={2} height={1}>
                {this.props.trial_changes.length > 0 ?
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Trial</TableCell>
                        <TableCell>Sponsor</TableCell>
                        <TableCell>Type</TableCell>
                        <TableCell>Last Updated</TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {this.props.trial_changes.map((trialChange) =>
                        <TableRow key={trialChange.trial_id}>
                          <TableCell>
                            <Link href={`/sectors/${this.props.sector.abbr}/trials/${trialChange.trial_id}`}>
                              {trialChange.acronym || trialChange.short_name}
                            </Link>
                          </TableCell>
                          <TableCell>
                            {trialChange.company_id ?
                              <Link href={`/sectors/${this.props.sector.abbr}/companies/${trialChange.company_id}`}>
                                {trialChange.sponsor_name}
                              </Link>
                            :
                              trialChange.sponsor_name
                            }
                          </TableCell>
                          <TableCell>
                            {trialChange.grouped_types ?
                              trialChange.grouped_types.split(";").map((type) =>
                                <div>
                                  {type}
                                </div>
                              )
                            : ''}
                          </TableCell>
                          <TableCell>
                            {humanDateFormat(trialChange.last_updated)}
                          </TableCell>

                          <TableCell>
                            <Button href={`/sectors/${this.props.sector.abbr}/trials/${trialChange.trial_id}/updates`} component='a'>
                              View Updates
                            </Button>
                          </TableCell>
                        </TableRow>
                        // <ListItemLink key={trialChange.trial_id} divider my={2} href={`/sectors/${props.sector.abbr}/trials/${trialChange.trial_id}/updates`}>
                        //   <ListItemText
                        //     style={{flex: "0.5"}}
                        //     primary={trialChange.acronym || trialChange.short_name}
                        //     secondary={trialChange.grouped_types}
                        //   />
                        //
                        //   <Typography variant="body1" style={{flex: "0.5"}} color='black'>
                        //     {trialChange.sponsor_name}
                        //   </Typography>
                        //
                        //   <Typography variant="body1" color='black'>
                        //     {`${trialChange.change_count} updates`}
                        //   </Typography>
                        // </ListItemLink>
                      )}
                    </TableBody>
                  </Table>
                :
                  <Flex justifyContent='center' height="50%">
                    <Box alignSelf='center' maxWidth="50%">
                      <Box>
                        <Typography variant="body1">
                          No updates found
                        </Typography>
                      </Box>
                    </Box>
                  </Flex>
                }
              </Box>
            </Paper>
          </Grid>


        </Grid>

        <Grid container spacing={3} justifyContent='center' alignItems='stretch' component={Box} pb={3}>
          <Grid item xs={12} lg={10}>
            <Paper style={{height: '600px'}}>
              <Typography variant="h6">
                Latest News
              </Typography>

              <Box height="100%">
                {this.props.all_news.length > 0 ?
                  <Box mt={2} overflow='auto' height="90%">
                    <ArticlesList
                      dense={true}
                      newsItems={this.props.all_news}
                      sector={this.props.sector}
                    />
                  </Box>
                :
                  <Flex justifyContent='center' height="50%">
                    <Box alignSelf='center' maxWidth="50%">
                      <Box>
                        <Typography variant="body1">
                          No articles found
                        </Typography>
                      </Box>
                    </Box>
                  </Flex>
                }
              </Box>
            </Paper>
          </Grid>
        </Grid>

        <Grid container spacing={3} alignItems='stretch' component={Box} pb={3}>
          <Grid item xs={12} lg={6}>
            <Paper className='h-100'>
              <Flex alignItems='center'>
                <Box>
                  <Typography variant="h6">
                    Latest Publications
                  </Typography>
                </Box>

                <Box ml='auto'>
                  <Button href={`/sectors/${this.props.sector.abbr}/products/${this.props.product.id}/manuscripts`} component='a'>
                    View All
                  </Button>
                </Box>
              </Flex>

              <Box mt={2} height={1}>
                {this.props.latest_manuscripts.length > 0 ?
                  <List dense={this.props.dense ? this.props.dense : false}>
                    {this.props.latest_manuscripts.map((manuscript) =>
                      <ListItemLink divider key={manuscript.id} href={`/sectors/${this.props.sector.abbr}/publications/${manuscript.id}`} target="_blank">
                        <ListItemText
                          primary={manuscript.title}
                          secondary={manuscript.journal_id}
                        />
                        <ListItemText secondary={manuscript.date ? manuscript.date.split("T")[0] : 'N/A'} className='tr' />
                      </ListItemLink>
                    )}
                  </List>
                :
                  <Flex justifyContent='center' height="50%">
                    <Box alignSelf='center' maxWidth="50%">
                      <Box>
                        <Typography variant="body1">
                          No publications found
                        </Typography>
                      </Box>
                    </Box>
                  </Flex>
                }
              </Box>
            </Paper>

          </Grid>

          <Grid item xs={12} lg={6}>
            <Paper className='h-100'>
              <Flex alignItems='center'>
                <Box>
                  <Typography variant="h6">
                    Latest Presentations
                  </Typography>
                </Box>

                <Box ml='auto'>
                  <Button href={`/sectors/${this.props.sector.abbr}/products/${this.props.product.id}/presentations`} component='a'>
                    View All
                  </Button>
                </Box>
              </Flex>

              <Box mt={2} height={1}>
                {this.props.latest_presentations.length > 0 ?
                  <List dense={this.props.dense ? this.props.dense : false}>
                    {this.props.latest_presentations.map((abstract) =>
                      <ListItemLink divider key={abstract.id} href={`/sectors/${this.props.sector.abbr}/abstracts/${abstract.id}`} target="_blank">
                        <ListItemText
                          primary={abstract.title}
                          secondary={`${abstract.short_name} ${abstract.year}`}
                        />
                        <ListItemText secondary={abstract.date ? abstract.date.split("T")[0] : "N/A"} className='tr' />
                      </ListItemLink>
                    )}
                  </List>
                :
                  <Flex justifyContent='center' height="50%">
                    <Box alignSelf='center' maxWidth="50%">
                      <Box>
                        <Typography variant="body1">
                          No presentations found
                        </Typography>
                      </Box>
                    </Box>
                  </Flex>
                }
              </Box>
            </Paper>

          </Grid>
        </Grid>

        {this.props.fda_adverse_event_chart_data ?
          <Grid container spacing={3} justifyContent='center' component={Box} pb={3}>
            <Grid item xs={12} lg={10}>
              <Paper>
                <Typography variant="h6">
                  FDA adverse events by year
                </Typography>

                <Box mt={2}>
                  <Chart
                    key={`adverse-events-by-year`}
                    chartId='adverse-events-by-year-count-chart'
                    chartOptions={{ type: 'column', height: '35%' }}
                    exportingChartOptions={{ credits: { enabled: true, text: '\u00A9 pvpipeline' } }}
                    legend={true}
                    data={this.props.fda_adverse_event_chart_data}
                    categories={this.props.adverse_event_categories}
                    title=''
                    yAxisTitle='# of adverse events'
                    xAxisTitle='Year'
                    simple={true}
                  />
                </Box>
              </Paper>
            </Grid>
          </Grid>
        : ''}

        {this.props.sector.monitor_product ? ""
        :
          <Grid container spacing={3} alignItems='stretch' component={Box} pb={3}>

            <Grid item xs={12} lg={6}>
              <ProfileFieldsContainer
                profileFields={this.props.profile_fields}
                product={this.props.product}
                sector={this.props.sector}
              />
            </Grid>

            <Grid item xs={12} lg={6}>
              <AttributesModule
                collections={this.props.collections}
                targetSites={this.props.target_sites}
                targetMarkets={this.props.target_markets}
                technologyGroups={this.props.technology_groups}
                therapyTypes={this.props.therapy_types}
                sector={this.props.sector}
              />
            </Grid>

            {/* <Grid item xs={12} lg={6}>
              <Paper className='h-100'>
                <Flex alignItems='center'>
                  <Box>
                    <Typography variant="h6">
                      Latest Alerts
                    </Typography>
                  </Box>

                  <Box ml='auto'>
                    <Button href={`/sectors/${this.props.sector.abbr}/products/${this.props.product.id}/alerts`} component='a'>
                      View All
                    </Button>
                  </Box>
                </Flex>

                <Box mt={2} height={1}>
                  {this.props.latest_alerts.length > 0 ?
                    <List dense={this.props.dense ? this.props.dense : false}>
                      {this.props.latest_alerts.map((alert) =>
                        <ListItem divider key={alert.id}>
                          <ListItemText
                            primary={alert.body}
                          />
                          <ListItemText secondary={alert.date ? humanDateFormat(alert.date) : "N/A"} className='tr' />
                        </ListItem>
                      )}
                    </List>
                  :
                    <Flex justifyContent='center' height="50%">
                      <Box alignSelf='center' maxWidth="50%">
                        <Box>
                          <Typography variant="body1">
                            No alerts found
                          </Typography>
                        </Box>
                      </Box>
                    </Flex>
                  }
                </Box>
              </Paper>
            </Grid> */}
          </Grid>
        }

      </Fragment>
    );
  }
}

CvpProfileDashboard.propTypes = {
  product: PropTypes.object,
  profile_fields: PropTypes.array
}
