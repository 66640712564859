import React from "react"
import Flex from "../Flex"
import Box from "../Box"
import CircularProgress from '@material-ui/core/CircularProgress';

export default function SearchDisplayContainer(props) {
  return(
    <React.Fragment>
      <Box height="100%">
        {props.form}

        <Box mt={4}>
          {props.tabs}
        </Box>

        <Flex mt={1} alignItems='center' width="40%">
          <Box>
            {`${props.resultsCount} ${props.resultsCount > 1 || props.resultsCount == 0 ? 'results' : 'result'}`}
          </Box>

          <Box ml={3}>
            {props.filters}
          </Box>

          <Box ml='auto'>
            {props.loading ? 
              <CircularProgress />
            : ""}
          </Box>
        </Flex>

        <Flex mb={2} width="80%" mx='auto' height="100%" flexDirection='column' alignItems='stretch'>
          <Box flexGrow={1} flexShrink={0}>
            {props.resultsCount == 0 ? props.emptyDataDisplay : props.results}
          </Box>

          <Box mt='auto' alignSelf='center'>
            {props.pagination}
          </Box>
        </Flex>
        
        {/* <Flex mt={2} justifyContent='center'>
          <Box flexShrink={0}>
            {props.pagination}
          </Box>
        </Flex> */}
      </Box>
    </React.Fragment>
  )
}