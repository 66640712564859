import React, { Component } from "react"
import Filters from "./Filters"
import Typography from "@material-ui/core/Typography"
import Flex from "../Flex"
import Box from "../Box"
import update from 'immutability-helper'
import ProductsTable from "./ProductsTable"
import ProductAttributeChip from "./ProductAttributeChip"
import Snackbar from "../Snackbar"
import FilterChipsContainer from "./FilterChipsContainer"
import CircularProgress from '@material-ui/core/CircularProgress';


export default class Listing extends Component {
  constructor(props) {
    super(props)
    this.handleCompareClick = this.handleCompareClick.bind(this)
    this.handleFilterChange = this.handleFilterChange.bind(this)
    this.handleTuneClick = this.handleTuneClick.bind(this)
    this.handleChipSelection = this.handleChipSelection.bind(this)
    this.handleDelete = this.handleDelete.bind(this)
    this.handleBookmarkClick = this.handleBookmarkClick.bind(this)
    this.getProducts = this.getProducts.bind(this)
    this.handleFilterExpandClick = this.handleFilterExpandClick.bind(this)
    this.handleCollectionChange = this.handleCollectionChange.bind(this)
    this.handleAddClick = this.handleAddClick.bind(this)
    this.handleBulkProductCheck = this.handleBulkProductCheck.bind(this)
    this.state = { activeTab: this.props.active_tab, products: this.props.products, showTuneFilters: false, showFilters: false, filterOptions: { companies: this.props.companies, methodGroups: this.props.method_groups, targetMarkets: this.props.target_markets, targetSites: this.props.target_sites, therapyTypes: this.props.therapy_types, technologyGroups: this.props.technology_groups, regulatoryStatuses: this.props.regulatory_statuses, collections: this.props.collections, characteristicGroups: this.props.characteristic_groups, characteristicValues: this.props.characteristic_values, stentConfigurations: this.props.stent_configurations }, filterValues: { company_ids: [], target_site_ids: [], target_market_ids: [], therapy_type_ids: [], technology_group_ids: [], regulatory_status_ids: [], collection_ids: [], stent_configuration_ids: [] }, checkedProducts: [], chosenAttributes: this.props.attributes, showSnackbar: false, snackbarText: '', showCollectionDialog: false, collectionTitle: '', collectionDescription: '', chosen: { therapyTypes: this.props.chosen_therapy_types, targetMarkets: this.props.chosen_target_markets, regulatoryStatuses: this.props.chosen_regulatory_statuses, targetSites: this.props.chosen_target_sites, companies: this.props.chosen_companies, collections: this.props.chosen_collections, stentConfigurations: this.props.chosen_stent_configurations }, loaded: false }
  };

  componentDidMount() {
    $.ajax({
      type: 'GET',
      url: `/sectors/${this.props.sector.abbr}/products`,
      dataType: 'json',
      success: (data) => {

        let filterOptions = { companies: data.companies, targetSites: data.target_sites, targetMarkets: data.target_markets, therapyTypes: data.therapy_types, technologyGroups: data.technology_groups, regulatoryStatuses: data.regulatory_statuses, collections: data.collections, stentConfigurations: data.stent_configurations }

        let chosen = { therapyTypes: data.chosen_therapy_types, targetMarkets: data.chosen_target_markets, targetSites: data.chosen_target_sites, companies: data.chosen_companies, collections: data.chosen_collections, regulatoryStatuses: data.chosen_regulatory_statuses, stentConfigurations: data.chosen_stent_configurations }

        this.setState({
          products: this.props.products ? this.props.products : data.products,
          loaded: true,
          filterOptions,
          chosen
        })
      }
    })
  }

  handleTuneClick(e) {
    this.setState({
      showTuneFilters: !this.state.showTuneFilters
    })
  }

  handleFilterExpandClick(e) {
    let newFilterState = !this.state.showFilters

    this.setState({
      showFilters: newFilterState
    })
  }

  handleChipSelection(e) {
    let selectedChipValue = e.target.textContent
    let index = this.state.chosenAttributes.indexOf(selectedChipValue)
    let newAttributes = ""

    if (index == -1) {
      let attributesIndex = this.props.attributes.indexOf(selectedChipValue)
      newAttributes = update(this.state.chosenAttributes, { $splice: [[attributesIndex, 0, selectedChipValue]] })
    } else {
      newAttributes = update(this.state.chosenAttributes, {
        $splice: [[index,1]]
      })
    }

    this.setState({
      chosenAttributes: newAttributes
    })
  }

  handleFilterChange(name, value) {
    let params = Object.assign({}, this.state.filterValues)

    if (value.includes(null)) {
      params[name] = []
    } else {
      params[name] = value
    }

    if (name == 'characteristic_group_id') {
      params['characteristic_value_id'] = ''
    }

    params['only_components'] = this.props.onlyComponents
    params['only_systems'] = this.props.onlySystems

    this.getProducts(params)
  }

  getProducts(params) {
    let url = `/sectors/${this.props.sector.abbr}/products`
    $.ajax({
      type: 'GET',
      url: url,
      data: params,
      dataType: 'json',
      success: (data, status, xhr) => {

        let filterOptions = { companies: data.companies, targetSites: data.target_sites, targetMarkets: data.target_markets, therapyTypes: data.therapy_types, technologyGroups: data.technology_groups, regulatoryStatuses: data.regulatory_statuses, collections: data.collections, stentConfigurations: data.stent_configurations }

        let chosen = { therapyTypes: data.chosen_therapy_types, targetMarkets: data.chosen_target_markets, targetSites: data.chosen_target_sites, companies: data.chosen_companies, collections: data.chosen_collections, regulatoryStatuses: data.chosen_regulatory_statuses, stentConfigurations: data.chosen_stent_configurations }

        this.setState({
          products: data.products,
          filterValues: params,
          filterOptions,
          chosen
        })
      },
      error: (data) => {
        this.setState({
          showSnackbar: true,
          snackbarText: 'There was an error. Please try again.'
        })
      },
      complete: (data) => {
        setTimeout(() => {
          this.setState({
            showSnackbar: false,
            snackbarText: ''
          })
        }, 4000)
      }
    })
  }

  handleCompareClick(e) {
    e.preventDefault()
    $.ajax({
      type: 'GET',
      url: `/sectors/${this.props.sector.abbr}/products/compare_products`,
      data: {
        product_ids: this.state.productsToCompare
      },
      dataType: 'html'
    })
  }

  handleBulkProductCheck(e) {
    if (this.state.checkedProducts.length > 0) {
      this.props.handleBulkProductCheck([])
    } else {
      let checkedProducts = this.state.products.map((product) => product.id)
      this.props.handleBulkProductCheck(checkedProducts)
    }
  }

  handleDelete(value, type) {
    const index = this.state.filterValues[type].indexOf(value)

    const filterValues = update(this.state.filterValues, {
      [type]: { $splice: [[index, 1]] }
    })

    this.getProducts(filterValues)
  }

  handleBookmarkClick(e) {
    this.setState({
      showCollectionDialog: true
    })
  }

  handleCollectionChange(e) {
    this.setState({
      collectionTitle: e.target.value
    })
  }

  handleAddClick(e) {
    let userCollectionProductParams = {}

    this.state.checkedProducts.forEach((product, index) => userCollectionProductParams[index] = { product_id: product })

    $.ajax({
      type: 'POST',
      url: "/user_collections",
      data: {
        user_collection: {
          user_id: this.props.current_user.id, title: this.state.collectionTitle, user_collection_products_attributes: userCollectionProductParams
        }
      },
      dataType: 'json',
      success: (data) => {
        const userCollection = data.user_collection
        let collections = update(this.state.filterOptions, {
          collections: {
            $push: [{ value: userCollection.id, name: userCollection.title }]
          }
        })
        this.setState({
          showCollectionDialog: false,
          showSnackbar: true,
          snackbarText: 'Collection has been created',
          filterOptions: collections
        })
      },
      errors: (data) => {
        this.setState({
          showSnackbar: true,
          snackbarText: 'An error has occurred. Please try again.'
        })
      },
      complete: (data) => {
        setTimeout(() => {
          this.setState({
            showSnackbar: false,
            snackbarText: ''
          })
        }, 4000)
      }
    })
  }

  render () {
    if (this.state.loaded) {
      return (
        <React.Fragment>

          <Box height='100%'>

            <Flex alignItems='center' mb={3}>
              <Box width={1}>
                <Filters
                  filterOptions={this.state.filterOptions}
                  filterValues={this.state.filterValues}
                  sector={this.props.sector}
                  user={this.props.user}
                  handleFilterChange={this.handleFilterChange}
                />
              </Box>


            </Flex>

            <Flex my={2} minHeight='28px' alignItems='center' flexWrap='wrap'>

              <FilterChipsContainer
                filterOptions={this.state.filterOptions}
                filterValues={this.state.filterValues}
                chosen={this.state.chosen}
                handleDelete={this.handleDelete}
              />

              <Box ml='auto'>
                <Typography variant="body2">
                  {`${this.state.products.length} products`}
                </Typography>
              </Box>

            </Flex>


            {this.state.showTuneFilters ? <Flex flexWrap='wrap' my={3}>
              {this.props.attributes.map((attribute) =>
                <Box mx={1} mb={1}>
                  <ProductAttributeChip
                    key={attribute}
                    attribute={attribute}
                    chosenAttributes={this.state.chosenAttributes}
                    handleChipSelection={this.handleChipSelection}
                  />
                </Box>
              )}
            </Flex> : ''}

            <ProductsTable
              products={this.state.products}
              sector={this.props.sector}
              currentUser={this.props.current_user}
              groupedComponents={this.props.groupedComponents}
              checkedProducts={this.props.checkedProducts}
              userProducts={this.props.userProducts}
              choose={true}
              user={this.props.user}
              chosenAttributes={this.props.chosenAttributes}
              handleProductCheckboxClick={this.props.handleProductCheckboxClick}
              handleFavoriteClick={this.handleFavoriteClick}
              handleBulkProductCheck={this.handleBulkProductCheck}
            />

          </Box>

          <Snackbar
            open={this.state.showSnackbar}
            message={this.state.snackbarText}
          />

        </React.Fragment>
      );
    } else {
      return(
        <Flex justifyContent='center'>
          <Box>
            <CircularProgress />
          </Box>
        </Flex>
      )
    }
  }
}
