import React, { Fragment } from "react"
import List from "../../lists/List";
import EndpointListItem from "./EndpointListItem"

export default function EndpointList(props) {
  return (
    <Fragment>

      <List>
        {props.endpoints.map((endpoint) =>
          <EndpointListItem
            key={endpoint.id}
            endpoint={endpoint}
            checked={false}
            forSelection={true}
            handleCheckboxSelect={props.handleCheckboxSelect}
          />
        )}
      </List>

    </Fragment>
  )
}
