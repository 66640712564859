import React from "react"
import Paper from "../Paper"
import Box from "../Box"
import { List, ListItem, ListItemText } from "../lists"
import Button from "../buttons/Button"
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction"
import Link from "../Link"
import Typography from "@material-ui/core/Typography"
import IconButton from "@material-ui/core/IconButton"
import PictureAsPdf from "@material-ui/icons/PictureAsPdf"
import DescriptionIcon from '@material-ui/icons/Description';
import RecordVoiceOverIcon from '@material-ui/icons/RecordVoiceOver';
import Tooltip from "@material-ui/core/Tooltip"

function InvestorPresentations(props) {
  return(
    <React.Fragment>
      <Paper fullHeight>
        <Box height="100%">
          
          {props.company.ir_url ? <Link href={props.company.ir_url} target="_blank">
            View investor relations page
          </Link> : null}

          <Box overflow="auto" height="90%">
            {props.investor_presentations.length > 0 ? (
              <List>
                {props.investor_presentations.map((investorPresentation) =>
                  <ListItem key={investorPresentation.id} divider>

                    <ListItemText>
                      <Typography variant="body1">
                        <Link href={`/sectors/${props.sector.abbr}/investor_presentations/${investorPresentation.id}`} target="_blank">{investorPresentation.title}</Link>
                      </Typography>
                      <Typography variant="caption" color="textSecondary">
                        {humanDateFormat(investorPresentation.date)}
                      </Typography>
                    </ListItemText>

                    <ListItemSecondaryAction>
                      {investorPresentation.pdf ? 
                        <Tooltip title="View PDF">
                          <IconButton href={`/sectors/${props.sector.abbr}/investor_presentations/${investorPresentation.id}/view_pdf`} target="_blank">
                            <PictureAsPdf />
                          </IconButton>
                        </Tooltip>
                      : null}
                      {investorPresentation.press_release_url ? 
                        <Tooltip title="View Press Release">
                          <IconButton href={`/sectors/${props.sector.abbr}/investor_presentations/${investorPresentation.id}/view_press_release`} target="_blank">
                            <DescriptionIcon />
                          </IconButton>
                        </Tooltip>
                      : null}
                      {investorPresentation.transcript_id ?
                        <Tooltip title="View Transcript">
                          <IconButton href={`/sectors/${props.sector.abbr}/investor_presentations/${investorPresentation.id}/view_transcript`} target="_blank">
                            <RecordVoiceOverIcon />
                          </IconButton>
                        </Tooltip>
                      : null}
                    </ListItemSecondaryAction>
                  </ListItem>
                )}
              </List>
            ) : (
              <Typography variant="body1" align="center" style={{ marginTop: 16 }}>
                No investor presentations available
              </Typography>
            )}
          </Box>
        </Box>
      </Paper>
    </React.Fragment>
  )
}

export default InvestorPresentations
