import React, { Component } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Card from "../cards/Card.jsx"
import CardSection from "../cards/CardSection.jsx"
import SidebarSection from "../SidebarSection"
import Link from "../Link"
import { List, ListItemLink, ListItemText } from "../lists"
import Box from "../Box"

export default function CompanyCard(props) {
  let url = props.company.url ? (props.company.url.includes("http") ? props.company.url : `https://${props.company.url}`) : null
  return (
    <Card background='bg-light-blue'>
      <CardSection>
        <h4>Details</h4>
        {props.company.url ?
          <SidebarSection header='Web site'>
            <Link href={url} target="_blank">
              {props.company.url}
            </Link>
          </SidebarSection>
        : ""}
        <SidebarSection header='Private' content={props.company.public ? 'No' : 'Yes'} />
        <SidebarSection header='Headquarters' content={props.company.hq_loc} />
        <SidebarSection header='Sector & Subsector' content={props.company_sectors} />
      </CardSection>
      <CardSection className='mt3'>
        <Box mb={2}>
          <div className='b'>Products</div>
          <List>
            {props.products.map((product) =>
              <ListItemLink key={product.id} href={`/sectors/${props.sector.abbr}/products/${product.id}`}>
                <ListItemText primary={product.short_name} />
              </ListItemLink>
            )}
          </List>
        </Box>
      </CardSection>
    </Card>
  )
}


{/* <Card background='bg-light-blue'>
  <CardSection>
    <h4>Details</h4>
    <div className='mb2'>
      <div className='b'>Web site</div>
      <div>www.abbott.com</div>
    </div>
    <div className='mb2'>
      <div className='b'>Private</div>
      <div>No</div>
    </div>
    <div className='mb2'>
      <div className='b'>Headquarters</div>
      <div>Redwood City, CA</div>
    </div>
  </CardSection>
</Card> */}
