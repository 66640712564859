import React, { Fragment } from 'react'
import { Table, TableBody, TableHeadSort } from '../../tables'
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TablePaginationActions from "../../tables/TablePaginationActions"
import PmaApprovalRow from './PmaApprovalRow'

export default function PmaApprovalsTable(props) {
  let headers = [{ label: 'PMA #', name: 'pma_number' }, { label: 'Device', name: 'trade_name' }, { label: 'Applicant', name: 'applicant' }, { label: 'Date', name: 'date_received' }, { label: "Hide", name: "" }]

  return (
    <Fragment>
      <Table stickyHeader size="small">
        <TableHeadSort 
          headers={headers}
          // order={this.state.order}
          // orderBy={this.state.orderBy}
          // onRequestSort={this.handleRequestSort}
        />
              
        <TableBody>
          {props.pmaApprovals.map((pmaApproval) =>
            <PmaApprovalRow
              key={pmaApproval.id}
              pmaApproval={pmaApproval}
            />
          )}
        </TableBody>
      
        <TableFooter>
          <TablePagination
            colSpan={7}
            count={props.pmaApprovalsCount}
            rowsPerPage={100}
            page={props.page}
            SelectProps={{
              inputProps: { 'aria-label': 'rows per page' },
              native: true,
            }}
            onChangePage={props.handlePageChange}
            onChangeRowsPerPage={props.handleChangeRowsPerPage}
            ActionsComponent={TablePaginationActions}
          />
        </TableFooter>
      </Table>
    </Fragment>
  )
}
