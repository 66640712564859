import React, { Fragment } from "react"
import Paper from "../../Paper"
import List from "../../lists/List";
import ProductListItem from "./ProductListItem"
import TextInput from "../../TextInput"
import Box from "../../Box"
import SearchIcon from '@material-ui/icons/Search'
import InputAdornment from '@material-ui/core/InputAdornment';

export default function ProductsList(props) {
  return (
    <Fragment>

      <Box>
        <TextInput
          name='query'
          label='Search Products'
          value={props.productQuery}
          onChange={props.handleProductSearchChange}
          fullWidth
          InputProps={{
            startAdornment: <InputAdornment position="start"><SearchIcon /></InputAdornment>,
          }}
          helperText='Search by name'
        />
      </Box>

      <List>
        {(props.searchedProducts.length > 0 ? props.searchedProducts : props.products).map((product) =>
          <ProductListItem
            key={product.id}
            product={product}
            checked={props.activeProducts.indexOf(product.id) == -1 ? false : true}
            handleCheckboxSelect={props.handleCheckboxSelect}
          />
        )}
      </List>

    </Fragment>
  )
}
