import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Paper from "../../Paper"
import Flex from "../../Flex"
import Box from "../../Box"
import { Table, TableBody, TableHead, TableRow, TableCell } from "../../tables"
import StagedAssociatorRow from "./StagedAssociatorRow"
import update from "immutability-helper"
import { Tabs, Tab } from "../../tabs"
import Select from "../../Select"

export default class StagedAssociator extends Component {
  constructor(props) {
    super(props)
    this.handleStatusChange = this.handleStatusChange.bind(this)
    this.handleSectorChange = this.handleSectorChange.bind(this)
    this.handleStatusUpdate = this.handleStatusUpdate.bind(this)
    this.state = { trials: this.props.trials, sector_id: this.props.sector_id }
  }

  handleSectorChange(name, value) {
    $.ajax({
      type: 'GET',
      url: `/admin/trials/staged_associator`,
      data: {
        sector_id: value
      },
      dataType: 'json',
      success: (data) => {
        this.setState({
          trials: data.trials,
          sector_id: value
        })
      }
    })
  }

  handleStatusChange(trial) {
    $.ajax({
      type: 'POST',
      url: '/admin/trial_associator_statuses',
      data: {
        trial_associator_status: {
          trial_id: trial.id, status: true
        }
      },
      dataType: 'json',
      success: (data) => {
        let index = this.state.trials.indexOf(trial)
        let trials = update(this.state.trials, {
          [index]: {
            $merge: {
              trial_associator_status: data.trial_associator_status
            }
          }
        })

        this.setState({
          trials: trials
        })
      },
      error: (data) => {
        this.setState({
          showSnackbar: true,
          snackbarText: 'There was an issue setting the status. Please try again'
        })
      },
      complete: (data) => {
        setTimeout(() => {
          this.setState({
            showSnackbar: false,
            snackbarText: ''
          })
        }, 4000)
      }
    })
  }

  handleStatusUpdate(trialAssociatorStatus, trial) {
    $.ajax({
      type: 'PUT',
      url: `/admin/trial_associator_statuses/${trialAssociatorStatus.id}`,
      data: {
        trial_associator_status: {
          status: !trialAssociatorStatus.status
        }
      },
      dataType: 'json',
      success: (data) => {
        let index = this.state.trials.indexOf(trial)
        let trials = update(this.state.trials, {
          [index]: {
            $merge: {
              trial_associator_status: data.trial_associator_status
            }
          }
        })

        this.setState({
          trials: trials
        })
      },
      error: (data) => {
        this.setState({
          showSnackbar: true,
          snackbarText: 'There was an issue setting the status. Please try again'
        })
      },
      complete: (data) => {
        setTimeout(() => {
          this.setState({
            showSnackbar: false,
            snackbarText: ''
          })
        }, 4000)
      }
    })
  }

  render() {
    return(
      <Fragment>

        <Paper fullHeight>

          <Box width={1/5}>
            <Select
              label='Sector'
              name='sector_ids'
              value={this.state.sector_id}
              entities={this.props.sectors}
              handleChange={this.handleSectorChange}
            />
          </Box>

          <Box display="flex" justifyContent='right'>
            {this.state.trials.length} trials
          </Box>

          <Box height="100%">
            <Box overflow='auto' maxHeight='95%'>
              <Table stickyHeader size='small' aria-label='trial staged associator table'>
                <TableHead>
                  <TableRow hover={false}>
                    <TableCell className='bg-white'>
                      Trial
                    </TableCell>
                    <TableCell className='bg-white'>
                      # of products
                    </TableCell>
                    <TableCell className='bg-white'>
                      Status
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {this.state.trials.map((trial) =>
                    <StagedAssociatorRow
                      key={trial.id}
                      trial={trial}
                      sectorId={this.state.sector_id}
                      handleStatusChange={this.handleStatusChange}
                      handleStatusUpdate={this.handleStatusUpdate}
                    />
                  )}
                </TableBody>
              </Table>
            </Box>
          </Box>

        </Paper>

      </Fragment>
    )
  }
}
