import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Paper from "../../Paper"
import { Table, TableHead, TableRow, TableCell, TableBody, EmptyDataTableRow } from "../../tables"
import ProfileFieldNameRow from "./ProfileFieldNameRow"
import update from "immutability-helper"
import Snackbar from "../../Snackbar"

export default class ProfileFieldNames extends Component {
  constructor(props) {
    super(props)
    this.handleDeleteClick = this.handleDeleteClick.bind(this)
    this.handleUndoClick = this.handleUndoClick.bind(this)
    this.state = { profileFieldNames: this.props.profile_field_names, showSnackbar: false, snackbarText: '', lastDeleted: '' }
  }
  
  handleUndoClick(e) {
    if (this.state.lastDeleted) {
      $.ajax({
        type: 'PUT',
        url: `/admin/profile_field_names/${this.state.lastDeleted.id}`,
        data: {
          profile_field_name: {
            deleted: null, deleted_by: null
          }
        },
        dataType: 'json',
        success: (data) => {
          const profileFieldNames = update(this.state.profileFieldNames, { $push: [this.state.lastDeleted] })
          this.setState({
            profileFieldNames: profileFieldNames,
            showSnackbar: true,
            snackbarText: 'Profile field name was undeleted',
            lastDeleted: null
          })
        },
        error: (data) => {
          this.setState({
            showSnackbar: true,
            snackbarText: data.responseText
          })
        },
        completed: (data) => {
          setTimeout(() => {
            this.setState({
              showSnackbar: false,
              snackbarText: ''
            })
          }, 4000)
        }
      })
    }
  }
  
  handleDeleteClick(profileFieldName) {
    $.ajax({
      type: 'PUT',
      url: `/admin/profile_field_names/${profileFieldName.id}`,
      data: {
        profile_field_name: {
          deleted: true, deleted_by: this.props.current_user.id
        }
      },
      dataType: 'json',
      success: (data) => {
        let index = this.state.profileFieldNames.indexOf(profileFieldName.id)
        let characteristicGroups = update(this.state.profileFieldNames, { $splice: [[index, 1]]})
        
        this.setState({
          profileFieldNames: profileFieldNames,
          showSnackbar: true,
          snackbarText: 'The profile field name was successfully deleted'
        })
      },
      error: (data) => {
        this.setState({
          showSnackbar: true,
          snackbarText: `The profile field name could not be deleted. ${data.responseText}`
        })
      },
      complete: (data) => {
        setTimeout(() => {
          this.setState({
            showSnackbar: false,
            snackbarText: ''
          })
        }, 4000)
      }
    })
  }
  
  render() {
    return(
      <Fragment>
        
        <Paper>
          <Table className='w-100'>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Kind</TableCell>
                <TableCell>Created By</TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            
            <TableBody>
              {this.state.profileFieldNames.length > 0 ? this.state.profileFieldNames.map((profileFieldName) =>
                <ProfileFieldNameRow 
                  key={profileFieldName.id}
                  profileFieldName={profileFieldName}
                  handleDeleteClick={this.handleDeleteClick}
                />
              ) : <EmptyDataTableRow colSpan={5} message='No profile field names found. Create one and it will display here.' />}
            </TableBody>
          </Table>
        </Paper>
        
        <Snackbar 
          open={this.state.showSnackbar}
          handleUndoClick={this.handleUndoClick}
          handleClose={this.handleClose}
          message={this.state.snackbarText} 
        />
        
      </Fragment>
    )
  }
}
