import React, { Fragment, Component } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import ResultForm from "./ResultForm"
import Paper from "../../Paper"
import Flex from "../../Flex"
import Box from "../../Box"
import Snackbar from "../../Snackbar"
import update from "immutability-helper"
import Typography from "@material-ui/core/Typography"

export default class NewContainer extends Component {
  constructor(props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
    this.handleSourceChange = this.handleSourceChange.bind(this)
    this.handleSectionChange = this.handleSectionChange.bind(this)
    this.handleChooseSourceClick = this.handleChooseSourceClick.bind(this)
    this.handleSourceRemovalClick = this.handleSourceRemovalClick.bind(this)
    this.handleSaveClick = this.handleSaveClick.bind(this)
    this.handleNewNameChange = this.handleNewNameChange.bind(this)
    this.handleNewNameSave = this.handleNewNameSave.bind(this)
    this.handleAddNameClick = this.handleAddNameClick.bind(this)
    this.state = { result: this.props.result, resultNames: this.props.result_names, newResultName: '', addName: false, source: null, openDialog: false, showSnackbar: false, snackbarText: '' }
  }

  componentDidMount() {
    const trialResult = update(this.state.result, {
      created_by: { $set: this.props.user.id },
      source_id: { $set: this.props.source ? this.props.source.source_id : null }
    })

    this.setState({
      result: trialResult,
      source: this.props.source
    })
  }

  handleSaveClick(e) {
    $.post(`/admin/trials/${this.props.trial.id}/results`, { trial_result: this.state.result }, (data) => {
      window.location = `/admin/trials/${this.props.trial.id}/results`
    }, 'json').error((data) => {
      this.setState({
        showSnackbar: true,
        snackbarText: 'The update could not be saved. Make sure you have chosen a source.'
      })
    }).done((data) => {
      setTimeout(() => {
        this.setState({
          showSnackbar: false,
          snackbarText: ''
        })
      })
    })
  }

  handleAddNameClick(e) {
    this.setState({
      addName: !this.state.addName
    })
  }

  handleNewNameChange(e) {
    const text = e.target.value

    this.setState({
      newResultName: text
    })
  }

  handleNewNameSave(e) {
    $.ajax({
      type: 'POST',
      url: '/admin/trial_result_names',
      data: {
        trial_result_name: {
          name: this.state.newResultName
        }
      },
      dataType: 'json',
      success: (data) => {
        let resultNameHash = { name: data.trial_result_name.name, value: data.trial_result_name.id }

        let resultNames = update(this.state.resultNames, {
          $push: [resultNameHash]
        })

        let trialResult = update(this.state.result, {
          trial_result_name_id: { $set: data.trial_result_name.id }
        })

        this.setState({
          resultNames: resultNames,
          addName: false,
          result: trialResult
        })
      }
    })
  }

  handleChange(e) {
    // const text = e.editor.getData()
    const text = e.target.value

    const trialResult = update(this.state.result, {
      result_value: { $set: text }
    })

    this.setState({
      result: trialResult
    })
  }

  handleSectionChange(name, value) {
    let trialResult = update(this.state.result, {
      trial_result_name_id: { $set: value }
    })

    this.setState({
      result: trialResult
    })
  }

  handleSourceRemovalClick(e) {
    this.setState({
      source: null
    })
  }

  handleChooseSourceClick(e) {
    this.setState({
      openDialog: true
    })
  }

  handleSourceChange(entity, sourceType) {
    if (sourceType == 'recently_used') {
      let trialResult = update(this.state.result, {
        source_id: { $set: entity.id }
      })
      this.setState({
        source: entity,
        result: trialResult,
        openDialog: false
      })
    } else {
      $.get("/admin/sources/find_source", { source_type: sourceType, entity_id: entity.id }, (data) => {
        if (data.source) {
          let trialResult = update(this.state.result, {
            source_id: { $set: data.source.source_id }
          })
          this.setState({
            source: data.source,
            result: trialResult,
            openDialog: false
          })
        } else {
          $.post("/admin/sources/filter", { source: { kind: sourceType, entity_id: entity.id } }, (data) => {
            let trialResult = update(this.state.result, {
              source_id: { $set: data.source.source_id }
            })
            this.setState({
              source: data.source,
              result: trialResult,
              openDialog: false
            })
          })
        }
      }, 'json')
    }
  }

  render() {
    return (
      <Fragment>

        <Paper fullHeight>

          <Box mb={3}>
            <Typography variant="h6" component="h6">
              New Result
            </Typography>
          </Box>

          <ResultForm
            trial={this.props.trial}
            result={this.state.result}
            resultNames={this.state.resultNames}
            newResultName={this.state.newResultName}
            source={this.state.source}
            openDialog={this.state.openDialog}
            sourceTypes={this.props.source_types}
            sources={this.props.sources}
            addName={this.state.addName}
            handleChange={this.handleChange}
            handleChooseSourceClick={this.handleChooseSourceClick}
            handleSourceChange={this.handleSourceChange}
            handleSectionChange={this.handleSectionChange}
            handleSaveClick={this.handleSaveClick}
            handleSourceRemovalClick={this.handleSourceRemovalClick}
            handleNewNameSave={this.handleNewNameSave}
            handleAddNameClick={this.handleAddNameClick}
            handleNewNameChange={this.handleNewNameChange}
          />

        </Paper>

        <Snackbar
          open={this.state.showSnackbar}
          message={this.state.snackbarText}
        />
      </Fragment>
    )
  }
}
