import React from "react"
import { TableRow, TableCell } from "../tables"
import Link from "../Link"

export default function SearchPatentRow(props) {
  console.log("Search row")
  return (
    <TableRow>
      <TableCell>{props.patent.patent_number}</TableCell>
      <TableCell>
        <Link href={`/sectors/${props.sector.abbr}/patents/${props.patent.id}`}>
          {props.patent.title}
        </Link>
      </TableCell>
      <TableCell>
        <Link href={`/sectors/${props.sector.abbr}/companies/${props.patent.company_id}`}>
          {props.patent.company}
        </Link>
      </TableCell>
      <TableCell>{props.patent.effective_date_str}</TableCell>
    </TableRow>
  )
}
