import React, {Component} from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import IconButton from "../IconButton"
import TuneIcon from "@material-ui/icons/Tune"

class Filters extends Component {
  constructor(props) {
    super(props)
  }
  
  render() {
    return(
      <React.Fragment>
        <IconButton onClick={this.props.handleTuneClick} tooltip='Filter'>
          <TuneIcon />
        </IconButton>
        
      </React.Fragment>
    )
  }
}

export default Filters
