import React, { Component } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Paper from "../Paper"
import PasswordInput from "../PasswordInput"
import TextInput from "../TextInput"
import RaisedButton from "../buttons/RaisedButton"
import Link from "../Link"
import Snackbar from "../Snackbar"

class Login extends Component {
  constructor(props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
    this.handleLoginClick = this.handleLoginClick.bind(this)
    this.state = { email: '', password: '', showSnackbar: false, snackbarText: '' }
  }

  handleLoginClick(e) {
    e.preventDefault();
    $.ajax({
      type: 'POST',
      url: '/users/sign_in',
      data: {
        user: { email: this.state.email, password: this.state.password }
      },
      dataType: 'html',
      error: (data) => {
        this.setState({
          showSnackbar: true,
          snackbarText: 'The email or password are incorrect. Please try again.'
        })
      },
      complete: (data) => {
        setTimeout(() => {
          this.setState({
            showSnackbar: false,
            snackbarText: ''
          })
        }, 4000)
      }
    })
  }

  handleChange(e) {
    let name = e.target.name
    let value = e.target.value
    this.setState({
      [name]: value
    })
  }

  render() {
    return(
      <section className="mt4 mw5 mw7-ns center pa3 ph5-ns">
        <Paper className='mt4 w-70 center'>
          <div>
            <h3>Login</h3>
          </div>

          <form onSubmit={this.handleLoginClick} acceptCharset="UTF-8">
            <div className='mb3 mt4'>
              <TextInput
                label='Email'
                name='email'
                value={this.state.email}
                onChange={this.handleChange}
                className='w-100'
              />
            </div>

            <div className='mb3'>
              <PasswordInput
                value={this.state.password}
                handleChange={this.handleChange}
                className='w-100'
              />
            </div>

            <div className='w-100 mt4'>
              <RaisedButton className='w-100' type='submit'>
                Login
              </RaisedButton>
            </div>

          </form>
        </Paper>

        <div className='mt4 tc'>
          <Link href='/forgot_password'>Forgot your password?</Link>
        </div>

        <Snackbar
          open={this.state.showSnackbar}
          message={this.state.snackbarText}
        />

      </section>
    )
  }
}

export default Login
