import React from "react";
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import MuiIconButton from '@material-ui/core/IconButton';
import Box from "../Box";
import Flex from "../Flex";
import SecondaryText from "../SecondaryText";
import Tooltip from '@material-ui/core/Tooltip';

export default function FilterOption(props) {
  function handleFilterChange(e) {
    if (!props.disabled) {
      props.handleFilterChange(props.filterValue, props.filterName)
    }
  }

  return(
    <div className='ph2'>
      <Flex alignItems='center'>
        <Box>
          <MuiIconButton 
            onClick={handleFilterChange} 
            edge="start"
            disabled={props.disabled}
          >
            {props.checked ? 
              <CheckBoxIcon 
                color={props.disabled ? 'disabled' : 'primary'} 
                style={{height: '20px', width: '20px'}} 
              /> : 
              <CheckBoxOutlineBlankIcon 
                color={props.disabled ? 'disabled' : 'inherit'}
                style={{height: '20px', width: '20px'}} 
              />
            }
          </MuiIconButton>
        </Box>

        <Box className='truncate f6' width={3/4}>
          {props.filterObject.tooltip ? 
            <Tooltip 
              title={props.filterObject.tooltip}
              placement="right"
              enterDelay={500}
              leaveDelay={200}
            >
              <span style={{ display: 'inline-block', width: '100%' }}>{props.filterObject.name}</span>
            </Tooltip> 
          : props.filterObject.name}
        </Box>

        <Box ml='auto'>
          <SecondaryText>
            {props.filterObject.count}
          </SecondaryText>
        </Box>
      </Flex>
    </div>
  )
}
