import React from "react"
import Box from "../Box";
import FilteredList from "../search/FilteredList";
import Select from "../Select";

export default function SearchFilters(props) {
  
  function handleOrderSort(name, value) {
    props.handleFilterChange(value, 'order_by')
  }

  return(
    <React.Fragment>
      <Box mb={2} width={1}>
        <Select 
          name='order_by'
          label='Sort By'
          value={props.filterValues.order_by}
          handleChange={handleOrderSort}
          entities={[
            {name: 'Relevance', value: '_score'},
            {name: 'Date', value: 'date'}
          ]}
        />
      </Box>

      <Box width={1} height={1} overflow='auto'>
        {props.filterOptions.products && 
          <Box width={1}>
            <FilteredList
              key={props.filterOptions.products.map(product => `${product.name}-${product.count}`).join('')} 
              label='Products'
              items={props.filterOptions.products}
              filterValues={props.filterValues}
              filterName='products'
              handleFilterChange={props.handleFilterChange}
              handleCheckAllClick={props.handleCheckAllClick}
            />
          </Box>
        }

        {props.filterOptions.meetings && 
          <Box mt={3} width={1}>
            <FilteredList
              key={props.filterOptions.meetings.map(meeting => meeting.value).join('')} 
              label='Meetings'
              items={props.filterOptions.meetings}
              filterValues={props.filterValues}
              filterName='meetings'
              handleFilterChange={props.handleFilterChange}
              handleCheckAllClick={props.handleCheckAllClick}
            />
          </Box>
        }

        {props.filterOptions.presenters && 
          <Box mt={3} width={1}>
            <FilteredList
              key={props.filterOptions.presenters.map(presenter => presenter.value).join('')} 
              label='Presenters'
              items={props.filterOptions.presenters}
              filterValues={props.filterValues}
              filterName='presenters'
              handleFilterChange={props.handleFilterChange}
              handleCheckAllClick={props.handleCheckAllClick}
            />
          </Box>
        }

        {props.filterOptions.trials && props.filterOptions.trials.length > 0 && 
          <Box mt={3} width={1}>
            <FilteredList
              key={props.filterOptions.trials.map(trial => trial.value).join('')} 
              label='Trials'
              items={props.filterOptions.trials}
              filterValues={props.filterValues}
              filterName='trials'
              handleFilterChange={props.handleFilterChange}
              handleCheckAllClick={props.handleCheckAllClick}
            />
          </Box>
        }

        {/* {props.filterOptions.years && 
          <Box mt={3}>
            <YearRangeSlider 
              key={props.years.map(year => year.name).join('')} 
              years={props.years}
              originalYears={props.originalYears}
              beginYear={props.filterValues.begin_year}
              endYear={props.filterValues.end_year}
              handleFilterChange={props.handleFilterChange}
            />
          </Box>
        } */}

        
      </Box>
    </React.Fragment>
  )
}
