import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import { TableRow, TableCell } from "../../tables"
import Link from "../../Link"
import DeleteButton from "../DeleteButton"
import EditButton from "../EditButton"

export default class PressReleaseRow extends Component {
  constructor(props) {
    super(props)
    this.handleDeleteClick = this.handleDeleteClick.bind(this)
  }

  handleDeleteClick(e) {
    this.props.handleDeleteClick(this.props.pressRelease)
  }

  render() {
    return(
      <Fragment>
        <TableRow>
          <TableCell>
            <Link href={`/admin/press_releases/${this.props.pressRelease.id}/edit`}>
              {this.props.pressRelease.title}
            </Link>
          </TableCell>
          <TableCell>
            {this.props.pressRelease.company ? this.props.pressRelease.company.full_name : ''}
          </TableCell>
          <TableCell>
            {humanDateFormat(this.props.pressRelease.date)}
          </TableCell>
          <TableCell>
            {this.props.pressRelease.creator ? displayInitials(this.props.pressRelease.creator.first_name, this.props.pressRelease.creator.last_name) : 'N/A'}
          </TableCell>
          <TableCell>
            <EditButton component="a" href={`/admin/press_releases/${this.props.pressRelease.id}/edit`} />
          </TableCell>
          <TableCell>
            <DeleteButton onClick={this.handleDeleteClick} />
          </TableCell>
        </TableRow>
      </Fragment>
    )
  }
}
