import React from "react"
import { ListItem, ListItemText } from "../../lists"
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemIcon from "@material-ui/core/ListItemIcon"
import Switch from "../../Switch"
import CloseIcon from '@material-ui/icons/Close';
import IconButton from "../../IconButton"

export default function InvestigatorRow(props) {

  function handleInvestigatorRemoval(e) {
    props.handleInvestigatorRemoval(props.trialInvestigator)
  }

  // let trialInvestigator = props.trialInvestigators.filter(trialInvestigator => trialInvestigator.id == props.scrapedName.trial_investigator_id)[0]
  let checked = true
  return(
    <ListItem key={props.trialInvestigator.id} divider button>

      <ListItemText
        primary={`${props.trialInvestigator.person.name.first_name} ${props.trialInvestigator.person.name.last_name}`}
      />

      <ListItemSecondaryAction>
        <Switch
          checked={checked}
          handleChange={handleInvestigatorRemoval}
        />
      </ListItemSecondaryAction>
    </ListItem>
  )
}
