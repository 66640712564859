import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Paper from "../Paper"
import Flex from "../Flex"
import Box from "../Box"
import TextInput from "../TextInput"
import RaisedButton from "../buttons/RaisedButton"
import Grid from '@material-ui/core/Grid';
import Typography from "@material-ui/core/Typography"
import ButtonLayout from "../admin/ButtonLayout"
import Snackbar from "../Snackbar"

export default class Support extends Component {
  constructor(props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
    this.handleSendClick = this.handleSendClick.bind(this)
    this.state = { subject: '', description: '', showSnackbar: false, snackbarText: '' }
  }

  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  handleSendClick(e) {
    $.ajax({
      type: 'POST',
      url: '/user_support_requests',
      data: {
        user_support_request: {
          user_id: this.props.current_user.id, subject: this.state.subject, description: this.state.description
        }
      },
      dataType: 'json',
      success: (data) => {
        this.setState({
          showSnackbar: true,
          snackbarText: 'Thank you for contacting us. We will get back to you shortly.'
        })
      },
      complete: (data) => {
        setTimeout(() => {
          window.location.href = `/sectors/${this.props.sector.abbr}/dashboard`
        }, 3000)
      }
    })
  }

  render() {
    return (
      <Fragment>
        <Paper fullHeight>
          <Typography variant="h6">
            Contact Us
          </Typography>

          <Box mt={5}>

            <Grid container spacing={3} component={Box} pb={2}>
              <Grid item xs={12}>
                <TextInput
                  label='Subject'
                  name='subject'
                  value={this.state.subject}
                  onChange={this.handleChange}
                  className='w-50'
                />
              </Grid>
            </Grid>

            <Grid container spacing={3} component={Box} mb={5}>
              <Grid item xs={6}>
                <TextInput
                  multiline={true}
                  rows={4}
                  label='Description'
                  name='description'
                  helperText='Enter the details of the issue, comment or question you have'
                  value={this.state.description}
                  onChange={this.handleChange}
                  className='w-100'
                />
              </Grid>
            </Grid>


            <ButtonLayout>
              <RaisedButton onClick={this.handleSendClick}>
                Send
              </RaisedButton>
            </ButtonLayout>


          </Box>
        </Paper>

        <Snackbar
          open={this.state.showSnackbar}
          message={this.state.snackbarText}
        />
      </Fragment>
    )
  }
}
