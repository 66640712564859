import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Flex from "../../Flex"
import Box from "../../Box"
import Typography from '@material-ui/core/Typography';
import AttributeOptionDisplay from "./AttributeOptionDisplay"
import { Button } from "../../buttons"
import { List, ListItem, ListItemText } from "../../lists"
import ExpandLess from '../../ExpandLess';
import ExpandMore from '../../ExpandMore';
import Collapse from '@material-ui/core/Collapse';
import { withSnackbar } from "../../SnackbarContainer"
import update from "immutability-helper"


class TechnologyGroupForm extends Component {
  constructor(props) {
    super(props)
    this.handleCheckboxSelect = this.handleCheckboxSelect.bind(this)
    this.handleCheckboxDelete = this.handleCheckboxDelete.bind(this)
    this.state = { productTechnologyGroups: this.props.productTechnologyGroups, open: false }
  }

  handleCheckboxSelect(technologyGroup) {

    if (this.props.source) {
      this.newTechnologyGroup(technologyGroup)
    } else {
      this.props.snackbarShowMessage("You must choose a source", "error")
    }
  }

  newTechnologyGroup(technologyGroupId) {
    let mainParams = { product_id: this.props.product.id, technology_group_id: technologyGroupId, created_by: this.props.currentUser.id, source_id: this.props.source.source_id, investigational: (this.props.activeRegion == 1000000 ? true : null) }

    $.post("/admin/product_technology_groups", { product_technology_group: mainParams }, (data) => {
      const productTechnologyGroup = data.product_technology_group

      const productTechnologyGroups = update(this.state.productTechnologyGroups, {
        $push: [productTechnologyGroup]
      })

      console.log(productTechnologyGroups)

      this.setState({
        productTechnologyGroups: productTechnologyGroups
      })

      this.props.snackbarShowMessage("Technology group has been added")

    }, 'json').fail((data) => {

      this.props.snackbarShowMessage("Technology group could not be added", "error")

    })
  }

  handleCheckboxDelete(technologyGroupId) {
    let productTechnologyGroup = this.state.productTechnologyGroups.filter(productTechnologyGroup => productTechnologyGroup.technology_group_id == technologyGroupId)[0]

    if (productTechnologyGroup) {
      let entityUrl = `/admin/product_technology_groups/${productTechnologyGroup.id}`
      let entityParams = { product_technology_group: { deleted: true, deleted_by: this.props.currentUser.id } }

      $.ajax({
        type: 'PUT',
        url: entityUrl,
        data: entityParams,
        dataType: 'json',
        success: (data) => {
          const productTechnologyGroupIndex = this.state.productTechnologyGroups.indexOf(productTechnologyGroup)

          const productTechnologyGroups = update(this.state.productTechnologyGroups, {
            $splice: [[productTechnologyGroupIndex, 1]]
          })

          this.setState({
            productTechnologyGroups: productTechnologyGroups
          })

          this.props.snackbarShowMessage("Technology group has been removed")
        },
        error: (data) => {
          this.props.snackbarShowMessage("Technology group could not be removed", "error")
        }
      })
    }
  }

  render() {
    return(
      <Fragment>

        <List>

          <ListItem button onClick={() => this.setState({open: !this.state.open})}>
            {this.state.open ? <ExpandLess /> : <ExpandMore />}
            <ListItemText primary='Technology Groups' />
          </ListItem>

          <Collapse in={this.state.open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {this.props.technologyGroups.map((technologyGroup) =>
                <AttributeOptionDisplay
                  key={technologyGroup.id}
                  attribute={technologyGroup}
                  productAttributeId='technology_group_id'
                  checked={this.state.productTechnologyGroups && this.state.productTechnologyGroups.filter(productTechnologyGroup => productTechnologyGroup.technology_group_id == technologyGroup.id)[0] ? true : false}
                  handleCheckboxSelect={this.handleCheckboxSelect}
                  handleCheckboxDelete={this.handleCheckboxDelete}
                />
              )}
            </List>
          </Collapse>

        </List>
      </Fragment>
    )
  }
}

export default withSnackbar(TechnologyGroupForm)
