import React from "react"
import PropTypes from "prop-types"
import Paper from "../../Paper"
import Box from "../../Box"
import Select from "../../Select"
import CKEditor from 'ckeditor4-react';
import { Button, RaisedButton } from "../../buttons"
import Grid from '@material-ui/core/Grid';
import Chip from '@material-ui/core/Chip';
import Dialog from "../../Dialog"
import SourceForm from "../SourceForm"
import update from "immutability-helper"
import Snackbar from "../../Snackbar"
import DatePicker from "../../DatePicker"
import SearchDropdown from "../../SearchDropdown"


export default class NewContainer extends React.Component {
  constructor(props) {
    super(props)
    this.handleDropdownChange = this.handleDropdownChange.bind(this)
    this.handleSaveClick = this.handleSaveClick.bind(this)
    this.handleDateChange = this.handleDateChange.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleChooseSourceClick = this.handleChooseSourceClick.bind(this)
    this.handleSourceChange = this.handleSourceChange.bind(this)
    this.handleSourceDeleteClick = this.handleSourceDeleteClick.bind(this)
    this.handleCompanyChange = this.handleCompanyChange.bind(this)
    this.handleProductChange = this.handleProductChange.bind(this)
    this.handleTrialChange = this.handleTrialChange.bind(this)
    this.state = { alert: this.props.alert, alertLink: this.props.alert_link, openDialog: false, source: null }
  }

  handleSaveClick(e) {
    let alert = update(this.state.alert, {
      $merge: {
        alert_links_attributes: {
          '0': this.state.alertLink
        }
      }
    })

    $.ajax({
      type: 'POST',
      url: '/admin/alerts',
      data: {
        alert: alert
      },
      dataType: 'json',
      success: (data) => {
        this.setState({
          showSnackbar: true,
          snackbarText: 'Alert was created successfully',
          alert: null
        })
      },
      error: (data) => {
        this.setState({
          showSnackbar: true,
          snackbarText: 'There was a problem creating the alert. Please try again.'
        })
      },
      complete: (data) => {
        setTimeout(() => {
          this.setState({
            showSnackbar: false,
            snackbarText: ''
          })
        }, 4000)
      }
    })
  }

  handleCompanyChange(e, entityOption) {
    let alertLink = update(this.state.alertLink, {
      company_id: { $set: entityOption.value }
    })

    this.setState({
      alertLink: alertLink
    })
  }

  handleProductChange(e, entityOption) {
    let alertLink = update(this.state.alertLink, {
      product_id: { $set: entityOption.value }
    })

    this.setState({
      alertLink: alertLink
    })
  }

  handleTrialChange(e, entityOption) {
    let alertLink = update(this.state.alertLink, {
      trial_id: { $set: entityOption.value }
    })

    this.setState({
      alertLink: alertLink
    })
  }

  handleSourceDeleteClick(e) {
    let alert = update(this.state.alert, {
      source_id: { $set: null }
    })

    this.setState({
      alert: alert
    })
  }

  handleChooseSourceClick(e) {
    this.setState({
      openDialog: !this.state.openDialog
    })
  }

  handleSourceChange(entity, sourceType) {
    if (sourceType == 'recently_used') {
      this.setState({
        source: entity,
        openDialog: false
      })
    } else {
      $.get("/admin/sources/find_source", { source_type: sourceType, entity_id: entity.id }, (data) => {
        if (data.source) {
          let alert = update(this.state.alert, {
            source_id: { $set: data.source.source_id }
          })
          this.setState({
            source: data.source,
            alert: alert,
            openDialog: false
          })
        } else {
          $.post("/admin/sources/filter", { source: { kind: sourceType, entity_id: entity.id } }, (data) => {
            let alert = update(this.state.alert, {
              source_id: { $set: data.source.source_id }
            })
            this.setState({
              source: data.source,
              alert: alert,
              openDialog: false
            })
          })
        }
      }, 'json')
    }
  }

  handleChange(evt) {
    let text = evt.editor.getData()

    let alert = update(this.state.alert, {
      body: { $set: text }
    })

    this.setState({
      alert: alert
    })
  }

  handleDateChange(value, date) {
    let alert = update(this.state.alert, {
      date: { $set: value }
    })

    this.setState({
      alert: alert
    })
  }

  handleDropdownChange(name, value) {
    let alert = update(this.state.alert, {
      [name]: { $set: value }
    })

    this.setState({
      alert: alert
    })
  }

  render () {
    return (
      <React.Fragment>
        <Paper fullHeight>

          <Box mb={2}>
            {this.state.alert.source_id ?
              <Chip
                label={this.state.source.kind}
                onDelete={this.handleSourceDeleteClick}
              />
            : <Button onClick={this.handleChooseSourceClick}>
              Choose Source
            </Button>}
          </Box>

          {this.state.openDialog ?
            <Dialog
              openDialog={this.state.openDialog}
              dialogTitle='Choose Source'
              withButtons={false}
              handleClick={this.handleAddClick}
              handleClose={this.handleClose}
              dialogContent={
                <SourceForm
                  sources={this.props.sources}
                  sourceTypes={this.props.source_types}
                  handleSourceChange={this.handleSourceChange}
                />
              }
            /> : ''}

          <Grid container direction="row" justify="center" alignItems="center" spacing={6}>

            <Grid item>
              <Paper>

                <Box mb={2}>
                  <Select
                    value={this.state.alert.sector_id}
                    name='sector_id'
                    label='Sector'
                    entities={this.props.sectors}
                    handleChange={this.handleDropdownChange}
                  />
                </Box>

                <Box mb={2}>
                  <Select
                    value={this.state.alert.alert_group_id}
                    name='alert_group_id'
                    label='Alert group'
                    entities={this.props.alert_groups}
                    handleChange={this.handleDropdownChange}
                  />
                </Box>

                <Box>
                  <DatePicker
                    name='date'
                    label='Date'
                    handleChange={(value, date) => { this.handleDateChange(value, date) }}
                    value={this.state.alert.date}
                  />
                </Box>

                <Box mt={2}>
                  <SearchDropdown
                    value={this.state.company_id}
                    inputName='company_id'
                    label='Companies'
                    options={this.props.companies}
                    handleChange={this.handleCompanyChange}
                  />
                </Box>

                <Box mt={2}>
                  <SearchDropdown
                    value={this.state.product_id}
                    inputName='product_id'
                    label='Products'
                    options={this.props.products}
                    handleChange={this.handleProductChange}
                  />
                </Box>

                <Box mt={2}>
                  <SearchDropdown
                    value={this.state.trial_id}
                    inputName='trial_id'
                    label='Trials'
                    options={this.props.trials}
                    handleChange={this.handleTrialChange}
                  />
                </Box>
              </Paper>
            </Grid>

            <Grid item>
              <Box>
                <CKEditor
                  data={this.state.alert.body}
                  onChange={this.handleChange}
                />
              </Box>
            </Grid>
          </Grid>

          <Box mt={4} width={1/4} mx='auto'>
            <RaisedButton handleClick={this.handleSaveClick}>
              Save
            </RaisedButton>
          </Box>
        </Paper>

        <Snackbar
          open={this.state.showSnackbar}
          message={this.state.snackbarText}
        />
      </React.Fragment>
    );
  }
}
