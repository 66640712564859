import React, { Fragment, Component } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Listing from "./Listing"
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

export default class CompaniesContainer extends Component {
  constructor(props) {
    super(props)
  }
  
  render() {
    return(
      <Fragment>
        <Switch>
          <Route 
            path={`/sectors/${this.props.sector.abbr}/companies`} 
            children={<Listing {...this.props} />} 
          />
          <Route 
            path={`/sectors/${this.props.sector.abbr}/companies/my_companies`} 
            children={<Listing {...this.props} />} 
          />
        </Switch>
      </Fragment>
    )
  }
}
