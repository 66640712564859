import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import { Table, TableHead, TableRow, TableCell, TableBody, TableHeadSort } from "../../tables"
import Link from "../../Link"
import DeleteButton from "../DeleteButton"

export default class AdverseEventRow extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    return(
      <TableRow>
        <TableCell>
          <Link href={`https://www.accessdata.fda.gov/scripts/cdrh/cfdocs/cfMAUDE/detail.cfm?mdrfoi__id=${this.props.adverseEvent.mdr_report_key}`} target="_blank">
            {this.props.adverseEvent.mdr_report_key}
          </Link>
        </TableCell>
        <TableCell>{this.props.adverseEvent.pma_number}</TableCell>
        <TableCell>{this.props.adverseEvent.k_number}</TableCell>
        <TableCell>{this.props.adverseEvent.brand_name}</TableCell>
        <TableCell>
          <Link href={`/admin/products/${this.props.adverseEvent.product_id}`} target="_blank">
            {this.props.adverseEvent.short_name}
          </Link>
        </TableCell>
        <TableCell>{this.props.adverseEvent.date_received}</TableCell>
        <TableCell>
          <DeleteButton
            onClick={() => { this.props.handleDeleteClick(this.props.adverseEvent) }}
          />
        </TableCell>
      </TableRow>
    )
  }
}
