import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Paper from "../../Paper"
import EditContainerTabs from "./EditContainerTabs"
import StatusForm from "./StatusForm"
import StatusRegionsForm from "./StatusRegionsForm"
import Snackbar from "../../Snackbar"
import update from "immutability-helper"


export default class EditContainer extends Component {
  constructor(props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
    this.handleSaveClick = this.handleSaveClick.bind(this)
    this.handleActiveIndexUpdate = this.handleActiveIndexUpdate.bind(this)
    this.handleCheckboxSelect = this.handleCheckboxSelect.bind(this)
    this.state = { status: this.props.status, statusRegions: this.props.status_regions, newRegion: '', showSnackbar: false, snackbarText: '', activeIndex: 0 }
  }
  
  componentDidMount() {
    let status = update(this.state.status, { 
      updated_by: { $set: this.props.current_user.id }
    })
    this.setState({
      status: status
    })
  }
  
  handleActiveIndexUpdate(e, newValue) {
    this.setState({
      activeIndex: newValue
    })
  }
  
  handleChange(e) {
    const name = e.currentTarget.name
    const value = e.currentTarget.value
    let status = update(this.state.status, { 
      [name]: { $set: value } 
    })
    this.setState({
      status: status
    })
    
  }
  
  handleCheckboxSelect(region, checked) {
    let statusType = this.props.type == 'development_statuses' ? 'development' : 'regulatory'
    let paramName = `${statusType}_status_region`
    let statusIdName = `${statusType}_status_id`
    const statusRegion = this.state.statusRegions.filter((statusRegion) => statusRegion.world_region_id == region.id)[0]
    if (checked) {
      $.ajax({
        type: 'PUT',
        url: `/admin/${statusType}_status_regions/${statusRegion.id}`,
        data: {
          [paramName]: {
            deleted: true, deleted_by: this.props.current_user.id
          }
        },
        dataType: 'json',
        success: (data) => {
          let index = this.state.statusRegions.indexOf(data.status_region)
          let statusRegions = update(this.state.statusRegions, { 
            $splice: [[index, 1]]
          })
          this.setState({
            statusRegions: statusRegions,
            showSnackbar: true,
            snackbarText: 'The region has been removed'
          })
        },
        error: (data) => {
          this.setState({
            showSnackbar: true,
            snackbarText: 'The region could not be removed. Please try again.'
          })
        },
        complete: (data) => {
          setTimeout(() => {
            this.setState({
              showSnackbar: false,
              snackbarText: ''
            })
          }, 4000)
        }
      })
    } else {
      $.ajax({
        type: 'POST',
        url: `/admin/${statusType}_status_regions`,
        data: {
          [paramName]: {
            [statusIdName]: this.state.status.id, world_region_id: region.id, created_by: this.props.current_user.id
          }
        },
        dataType: 'json',
        success: (data) => {
          let statusRegions = update(this.state.statusRegions, { 
            $push: [data.status_region] 
          })
          this.setState({
            statusRegions: statusRegions,
            showSnackbar: true,
            snackbarText: 'The region has been added'
          })
        },
        error: (data) => {
          this.setState({
            showSnackbar: true,
            snackbarText: 'The region could not be added. Please try again.'
          })
        },
        complete: (data) => {
          setTimeout(() => {
            this.setState({
              showSnackbar: false,
              snackbarText: ''
            })
          }, 4000)
        }
      })
    }
  }
  
  handleSaveClick(e) {
    let statusType = this.props.type == 'development_statuses' ? 'development' : 'regulatory'
    let paramName = `${statusType}_status`
    let statusIdName = `${statusType}_status_id`
    $.ajax({
      type: "PUT",
      url: `/admin/${statusType}_statuses/${this.state.status.id}`,
      data: {
        [paramName]: this.state.status
      },
      dataType: 'json',
      complete: (data) => {
        this.setState({
          status: data.status,
          showSnackbar: true,
          snackbarText: 'The status was updated'
        })
      },
      error: (data) => {
        this.setState({
          showSnackbar: true,
          snackbarText: `The status could not be updated. Please try again.`
        })
      },
      complete: (data) => {
        setTimeout(() => {
          this.setState({
            showSnackbar: false,
            snackbarText: ''
          })
        }, 4000)
      }
    })
  }
  
  render() {
    console.log(this.state.status)
    return(
      <Fragment>
        
        <Paper>
          
          <div className='w-100 tc mb4'>
            <EditContainerTabs 
              activeIndex={this.state.activeIndex}
              handleActiveIndexUpdate={this.handleActiveIndexUpdate}
            />
          </div>
          
          {this.state.activeIndex == 0 ? 
            <StatusForm 
              status={this.state.status}
              handleChange={this.handleChange}
              handleSaveClick={this.handleSaveClick}
            /> 
          : ''}
          
          { this.state.activeIndex == 1 ? 
            <StatusRegionsForm 
              status={this.state.status}
              regions={this.props.regions}
              statusRegions={this.state.statusRegions}
              handleCheckboxSelect={this.handleCheckboxSelect}
            />
          : ''}
          
          <Snackbar 
            open={this.state.showSnackbar}
            message={this.state.snackbarText} 
          />
          
        </Paper>
      </Fragment>
    )
  }
}
