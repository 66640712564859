import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Paper from "../../Paper"
import { Table, TableRow, TableHead, TableCell, TableBody } from "../../tables"
import IconButton from "../../IconButton"
import { Button } from "../../buttons"
import EditButton from '../EditButton';
import DeleteButton from '../DeleteButton'


export default class StatementRow extends Component {
  constructor(props) {
    super(props)
    this.handleDeleteClick = this.handleDeleteClick.bind(this)
  }

  handleDeleteClick(e) {
    this.props.handleDeleteClick(this.props.companyStatement)
  }

  render() {
    let editUrl = `/admin/companies/${this.props.company.id}/statements/${this.props.companyStatement.id}/edit`
    return(
      <TableRow>
        <TableCell>
          {this.props.companyStatement.statement}
        </TableCell>

        <TableCell>
          {this.props.companyStatement.sector ? this.props.companyStatement.sector.abbr : 'N/A'}
        </TableCell>

        <TableCell>
          {this.props.companyStatement.source_display ? this.props.companyStatement.source_display.source_title : 'N/A'}
        </TableCell>

        <TableCell>
          <EditButton
            component='a'
            href={editUrl}
          />
        </TableCell>

        <TableCell>
          <DeleteButton
            onClick={this.handleDeleteClick}
          />
        </TableCell>
      </TableRow>
    )
  }
}
