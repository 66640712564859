import React, { Fragment, useState } from "react"
import SearchDropdown from "../SearchDropdown"
import Box from "../../Box"
import Flex from "../../Flex"
import TextInput from "../../TextInput"
import { Button, RaisedButton } from "../../buttons"

export default function PersonForm(props) {
  const [addingPerson, setAddingPerson] = useState(false)

  return (
    <Fragment>
      <Box width={1/2}>
        <SearchDropdown
          value={props.query}
          suggestions={props.suggestions}
          handleChange={props.handleChange}
          handlePersonClick={props.handlePersonClick}
        />
      </Box>

      <Box mt={3}>
        {addingPerson ?
          <Box>
            <Flex mb={3} alignItems='center'>
              <Box pr={3} width={1/3}>
                <TextInput
                  name='firstName'
                  label='First Name'
                  value={props.firstName}
                  onChange={props.handleNameChange}
                  fullWidth
                />
              </Box>

              <Box pr={3} width={1/3}>
                <TextInput
                  name='lastName'
                  label='Last Name'
                  value={props.lastName}
                  onChange={props.handleNameChange}
                  fullWidth
                />
              </Box>

              <Box pr={2}>
                <RaisedButton onClick={props.handleNewPersonClick}>
                  Add Investigator
                </RaisedButton>
              </Box>

              <Box>
                <RaisedButton onClick={() => {
                  setAddingPerson(false)
                }}>
                  Cancel
                </RaisedButton>
              </Box>
            </Flex>
          </Box>
        :
          <Box>
            <Button onClick={() => { setAddingPerson(true) }}>
              Create new person
            </Button>
          </Box>
        }
      </Box>
    </Fragment>
  )
}
