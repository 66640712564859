import React, { Fragment } from "react"

export default function TherapyTypeDisplay(props) {
  return (
    <Fragment>
      {props.therapyTypes.map((therapyType) =>
        <div key={therapyType.id}>
          {therapyType.name}
        </div>
      )}
    </Fragment>
  )
}
