import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Typography from "@material-ui/core/Typography"
import List from "../../lists/List"
import RegionListItem from "../../world_regions/RegionListItem";

export default class StatusRegionsForm extends Component {
  constructor(props) {
    super(props)
  }
  
  render() {
    return(
      <Fragment>
        <Typography variant="h6" component="h6" className='mb1 mt1 mr1'>
          Regions
        </Typography>
        
        <List>
          {this.props.regions.map((region) =>
            <RegionListItem
              key={region.id}
              region={region}
              checked={this.props.statusRegions.filter((statusRegion) => statusRegion.world_region_id == region.id)[0] ? true : false}
              handleCheckboxSelect={this.props.handleCheckboxSelect}
            />
          )}
        </List>
      </Fragment>
    )
  }
}
