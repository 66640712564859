import React, { Fragment } from "react"
import Box from "../../../Box"
import Typography from "@material-ui/core/Typography"
import { List, ListItem, ListItemText } from "../../../lists"
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction"
import DeleteButton from "../../DeleteButton"

export default function AcquiredCompaniesList(props) {

  return(
    <Fragment>
      <Typography variant="h6">
        Acquired Companies
      </Typography>

      <Box mt={2}>
        <List>
          {props.companyFamilies.map((acquiredCompany) =>
            <ListItem key={acquiredCompany.id} divider>
              <ListItemText primary={acquiredCompany.short_name} />

              <ListItemSecondaryAction>
                <DeleteButton onClick={() => { props.handleDeleteClick(acquiredCompany) }} />
              </ListItemSecondaryAction>
            </ListItem>
          )}
        </List>
      </Box>
    </Fragment>
  )
}
