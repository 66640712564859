import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import TableRow from "./TableRow"
import TableCell from "./TableCell"

export default class EmptyDataTableRow extends Component {
  constructor(props) {
    super(props)
  }
  
  render() {
    return(
      <Fragment>
        <TableRow>
          <TableCell colSpan={this.props.colSpan}>{this.props.message}</TableCell>
        </TableRow>
      </Fragment>
    )
  }
}
