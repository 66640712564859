import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Paper from "../../Paper"
import { Table, TableHead, TableRow, TableCell, TableBody } from "../../tables"
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TablePaginationActions from "../../tables/TablePaginationActions"
import Link from "../../Link"
import Flex from "../../Flex"
import Box from "../../Box"
import Typography from "@material-ui/core/Typography"
import { withSnackbar } from "../../SnackbarContainer"
import TextInput from "../../TextInput"
import SearchIcon from '@material-ui/icons/Search'
import InputAdornment from '@material-ui/core/InputAdornment';
import { RaisedButton } from "../../buttons"

class Codes extends Component {
  constructor(props) {
    super(props)
    this.getCodes = this.getCodes.bind(this)
    this.handleSearchChange = this.handleSearchChange.bind(this)
    this.handleSearchSubmit = this.handleSearchSubmit.bind(this)
    this.state = { productCodes: this.props.product_codes, query: "" }
  }

  handleSearchChange(e) {
    let name = e.target.name
    let value = e.target.value

    this.setState({
      [name]: value
    })
  }

  handleSearchSubmit(e) {
    this.getCodes({ query: this.state.query })
  }

  getCodes(params) {
    $.ajax({
      type: 'GET',
      url: `/admin/product_codes`,
      data: params,
      dataType: 'json',
      success: (data) => {

        this.setState({
          productCodes: data.product_codes
        })
      },
      error: (data) => {
        this.props.snackbarShowMessage('There was a problem getting codes. Please try again.', 'error')
      }
    })
  }

  render() {
    return(
      <Fragment>

        <Paper fullHeight>

          <Box height={1}>

            <Flex alignItems='center' mb={3}>

              <Box width={4/5} mr={3}>
                <TextInput
                  name='query'
                  placeholder='Search Product Codes'
                  size='small'
                  value={this.state.query}
                  onChange={this.handleSearchChange}
                  fullWidth
                  InputProps={{
                    startAdornment: <InputAdornment position="start"><SearchIcon fontSize='small' /></InputAdornment>
                  }}
                />
              </Box>

              <Box>
                <RaisedButton onClick={this.handleSearchSubmit}>
                  Search
                </RaisedButton>
              </Box>
            </Flex>

            <Box my={3}>
              {/* <Filters
                filterOptions={this.state.filterOptions}
                filterValues={this.state.filterValues}
                handleFilterChange={this.handleFilterChange}
              /> */}
            </Box>

            <Flex alignItems='center'>

              <Box>
                <Typography variant="body2">
                  {`${this.state.productCodes.length} codes`}
                </Typography>
              </Box>
            </Flex>

            <Box overflow='auto' height="90%">
              <Table size="small" stickyHeader={true}>
                <TableHead>
                  <TableRow hover={false}>
                    <TableCell>Product Code</TableCell>
                    <TableCell>Definition</TableCell>
                    <TableCell>Device Type</TableCell>
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.productCodes.map((productCode) =>
                    <TableRow key={productCode.id}>
                      <TableCell>
                        <Link target="_blank" href={`/admin/product_codes/devices?product_code=${productCode.product_code}`}>
                          {productCode.product_code}
                        </Link>
                      </TableCell>
                      <TableCell>
                        {productCode.definition}
                      </TableCell>
                      <TableCell>
                        {productCode.device_name}
                      </TableCell>
                      <TableCell>

                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </Box>
          </Box>
        </Paper>
      </Fragment>
    )
  }
}


export default withSnackbar(Codes)
