import React from "react"
import IconButton from "../IconButton"
import CompareIcon from '@material-ui/icons/Compare';

export default function ViewInTrials(props) {


  return(
    <React.Fragment>
      {props.checkedTrials.length > 0
      ?
        <IconButton tooltip='Compare trials' component='a' href={`/sectors/${props.sector.abbr}/trials/compare_trials?${props.checkedTrials.map(checkedTrial => `trial_ids[]=${checkedTrial}`).join("&")}`} size='small'>
          <CompareIcon style={{height: '22px', width: '22px'}} />
        </IconButton>
      :
        <IconButton tooltip='Compare trials' onClick={() => { props.snackbarShowMessage("To compare trials, you must first choose some trials using the checkboxes", "info") }} size='small'>
          <CompareIcon style={{height: '22px', width: '22px'}} />
        </IconButton>
      }
    </React.Fragment>

  )
}
