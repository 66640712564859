import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Paper from "../../Paper"
import { List, ListItem, ListItemText } from "../../lists"
import IconButton from "../../IconButton"
import Flex from "../../Flex"
import Box from "../../Box"
import { Button } from "../../buttons"
import Typography from "@material-ui/core/Typography"
import update from "immutability-helper"
import Collapse from '@material-ui/core/Collapse';
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ExpandLess from '../../ExpandLess';
import ExpandMore from '../../ExpandMore';


export default class FundingEventRow extends Component {
  constructor(props) {
    super(props)
    this.state = { open: false }
  }

  render() {
    let editUrl = `/admin/companies/${this.props.company.id}/funding_events/${this.props.fundingEvent.id}/edit`
    return(
      <Fragment>
        <ListItem button divider onClick={() => this.setState({open: !this.state.open})}>
          <ListItemIcon>
            {this.state.open ? <ExpandLess /> : <ExpandMore />}
          </ListItemIcon>

          <ListItemText primary={this.props.fundingEvent.series_name} />
        </ListItem>

        <Collapse in={this.state.open} timeout="auto" unmountOnExit>
          <Box mt={3}>
            {this.props.fundingEvent.amount ?
              <div className='mb2 pl3 mt3'>
                <span className='pb1'>Amount: </span>
                <span>{this.props.fundingEvent.amount}</span>
              </div>
            : ''}
            {this.props.fundingEvent.amount_offered ?
              <div className='mb2 pl3 mt3'>
                <span className='pb1'>Amount offered: </span>
                <span>{`$${numberWithCommas(this.props.fundingEvent.amount_offered)}`}</span>
              </div>
            : ''}
            {this.props.fundingEvent.amount_raised ?
              <div className='mb2 pl3'>
                <span className='pb1'>Amount raised: </span>
                <span>{`$${numberWithCommas(this.props.fundingEvent.amount_raised)}`}</span>
              </div>
            : ''}
            {this.props.fundingEvent.date ?
              <div className='mb2 pl3'>
                <span className='pb1'>Date of Announcement: </span>
                <span>{humanDateFormat(this.props.fundingEvent.date)}</span>
              </div>
            : ''}
            {this.props.fundingEvent.offering_date ?
              <div className='mb2 pl3'>
                <span className='pb1'>Offering Date: </span>
                <span>{humanDateFormat(this.props.fundingEvent.offering_date)}</span>
              </div>
            : ''}
            {this.props.fundingEvent.date_first_sale ?
              <div className='mb2 pl3'>
                <span className='pb1'>Date first sold: </span>
                <span>{humanDateFormat(this.props.fundingEvent.date_first_sale)}</span>
              </div>
            : ''}
            {this.props.fundingEvent.duration ?
              <div className='mb2 pl3'>
                <span className='pb1'>Closing date: </span>
                <span>{this.props.fundingEvent.duration}</span>
              </div>
            : ''}
            {this.props.fundingEvent.closed_date ?
              <div className='mb2 pl3'>
                <span className='pb1'>Closing date: </span>
                <span>{this.props.fundingEvent.closed_date}</span>
              </div>
            : ''}
            {this.props.fundingEvent.securities_type ?
              <div className='mb2 pl3'>
                <span className='pb1'>Type of securities: </span>
                <span>{this.props.fundingEvent.securities_type}</span>
              </div>
            : ''}
            {this.props.fundingEvent.filing_type ?
              <div className='mb2 pl3'>
                <span className='pb1'>Filing type: </span>
                <span>{this.props.fundingEvent.filing_type}</span>
              </div>
            : ''}
            {this.props.fundingEvent.number_investors ?
              <div className='mb2 pl3'>
                <span className='pb1'>Number of investors: </span>
                <span>{this.props.fundingEvent.number_investors}</span>
              </div>
            : ''}
          </Box>
        </Collapse>
      </Fragment>
    )
  }
}
