import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Paper from "../../Paper"
import Flex from "../../Flex"
import Box from "../../Box"
import ProductsTable from "./ProductsTable"
import Filters from "./Filters"
import Select from "../../Select"
import { withRouter } from "react-router-dom";
import Typography from "@material-ui/core/Typography"
import update from 'immutability-helper'
import { RaisedButton, Button } from "../../buttons"
import Dialog from "../../Dialog"
import { withSnackbar } from "../../SnackbarContainer"
import FilterChipsContainer from "../../products/FilterChipsContainer"


class Products extends Component {
  constructor(props) {
    super(props)
    this.handleFilterChange = this.handleFilterChange.bind(this)
    this.getProducts = this.getProducts.bind(this)
    this.handleAddSectorClick = this.handleAddSectorClick.bind(this)
    this.handleBulkProductCheck = this.handleBulkProductCheck.bind(this)
    this.handleProductCheckboxClick = this.handleProductCheckboxClick.bind(this)
    this.handleNewSectorChange = this.handleNewSectorChange.bind(this)
    this.handleSetSectorClick = this.handleSetSectorClick.bind(this)
    this.handleCloseDialog = this.handleCloseDialog.bind(this)
    this.handleDelete = this.handleDelete.bind(this)
    this.state = { dialogOpen: false, products: this.props.products, review: this.props.review, newSectorId: '', filterOptions: { companies: this.props.companies, methodGroups: this.props.method_groups, targetMarkets: this.props.target_markets, targetSites: this.props.target_sites, therapyTypes: this.props.therapy_types, technologyGroups: this.props.technology_groups, regulatoryStatuses: this.props.regulatory_statuses, collections: this.props.collections, characteristicGroups: this.props.characteristic_groups, characteristicValues: this.props.characteristic_values, stentConfigurations: this.props.stent_configurations, methods: this.props.methods, polymerTypes: this.props.polymer_types, productCharacteristics: this.props.product_characteristics, monitorStatuses: this.props.monitor_statuses }, filterValues: { company_ids: [], target_site_ids: [], target_market_ids: [], therapy_type_ids: [], technology_group_ids: [], regulatory_status_ids: [], collection_ids: [], stent_configuration_ids: [], method_type_ids: [], polymer_type_ids: [], characteristic_value_ids: [], monitor_status: this.props.monitor_status, sector_id: this.props.sector_id }, checkedProducts: [], chosenAttributes: this.props.attributes, showSnackbar: false, snackbarText: '', showCollectionDialog: false, collectionTitle: '', collectionDescription: '', chosen: { therapyTypes: this.props.chosen_therapy_types, targetMarkets: this.props.chosen_target_markets, regulatoryStatuses: this.props.chosen_regulatory_statuses, targetSites: this.props.chosen_target_sites, companies: this.props.chosen_companies, collections: this.props.chosen_collections, stentConfigurations: this.props.chosen_stent_configurations, methods: this.props.chosen_methods, polymerTypes: this.props.chosen_polymer_types, characteristicValues: this.props.chosen_characteristic_values, technologyGroups: this.props.chosen_technology_groups } }
  }

  componentDidMount() {
    let filterKeys = Object.keys(this.props.filter_values)
    let filterValueHash = {}

    let parsedFilterValues = filterKeys.map((filterKey) =>
      this.props.filter_values[filterKey] == null ? filterValueHash[filterKey] = [] : filterValueHash[filterKey] = this.props.filter_values[filterKey].map((value) => parseInt(value))
    )

    filterValueHash["monitor_status"] = this.props.monitor_status
    filterValueHash["sector_id"] = this.props.sector_id

    let filterValues = update(this.state.filterValues, {
      $set: filterValueHash
    })

    this.setState({
      filterValues: filterValues
    })
  }

  handleDelete(value, type) {
    const index = this.state.filterValues[type].indexOf(value)

    const filterValues = update(this.state.filterValues, {
      [type]: { $splice: [[index, 1]] }
    })

    this.getProducts(filterValues)
  }

  handleNewSectorChange(name, value) {
    this.setState({
      newSectorId: value
    })
  }

  handleFilterChange(name, value) {
    let params = Object.assign({}, this.state.filterValues)

    if (value && value.length && value.includes(null)) {
      params[name] = []
    } else {
      params[name] = value
    }

    if (name == "sector_id") {
      params["sector_id"] = value
    }

    if (name == 'characteristic_group_id') {
      params['characteristic_value_id'] = ''
    }

    this.getProducts(params)
  }

  getProducts(params) {
    let url = `/admin/products`

    $.ajax({
      type: 'GET',
      url: url,
      data: params,
      dataType: 'json',
      beforeSend: (smthing) => {
        this.setState({
          loading: true
        })
      },
      success: (data, status, xhr) => {

        let filterOptions = { companies: data.companies, targetSites: data.target_sites, targetMarkets: data.target_markets, therapyTypes: data.therapy_types, technologyGroups: data.technology_groups, regulatoryStatuses: data.regulatory_statuses, collections: data.collections, stentConfigurations: data.stent_configurations, methods: data.methods, polymerTypes: data.polymer_types, productCharacteristics: data.product_characteristics, monitorStatuses: data.monitor_statuses }

        let chosen = { therapyTypes: data.chosen_therapy_types, targetMarkets: data.chosen_target_markets, targetSites: data.chosen_target_sites, companies: data.chosen_companies, collections: data.chosen_collections, regulatoryStatuses: data.chosen_regulatory_statuses, stentConfigurations: data.chosen_stent_configurations, methods: data.chosen_methods, polymerTypes: data.chosen_polymer_types, characteristicValues: data.chosen_characteristic_values, technologyGroups: data.chosen_technology_groups }

        let filterKeys = Object.keys(params)

        let queryParams = filterKeys.map((filterKey) =>
          (typeof(params[filterKey]) == "string" || typeof(params[filterKey]) == "number" || params[filterKey] == null) ? (params[filterKey] ? `${filterKey}=${params[filterKey]}` : null) : (params[filterKey].length > 0 ? params[filterKey].map((filterValue) => `${filterKey}[]=${filterValue}`) : null)
        ).filter(param => param)

        let flattenedQueryParams = [].concat.apply([], queryParams);
        // this.props.match.path
        this.props.history.push(`${url}?${flattenedQueryParams.join("&")}`);

        this.setState({
          products: data.products,
          groupedComponents: data.grouped_components,
          filterValues: params,
          loading: false,
          dialogOpen: false,
          filterOptions,
          chosen
        })
      },
      error: (data) => {
        this.setState({
          showSnackbar: true,
          snackbarText: 'There was an error. Please try again.',
          loading: false
        })
      },
      complete: (data) => {
        setTimeout(() => {
          this.setState({
            showSnackbar: false,
            snackbarText: '',
            loading: false
          })
        }, 4000)
      }
    })
  }

  handleAddSectorClick(e) {
    this.setState({
      dialogOpen: true
    })
  }

  handleSetSectorClick(e) {
    $.ajax({
      type: "POST",
      url: `/admin/product_sectors/add_sectors`,
      data: {
        product_ids: this.state.checkedProducts, sector_id: this.state.newSectorId
      },
      dataType: 'json',
      success: (data) => {
        this.setState({
          dialogOpen: false
        })

        this.props.snackbarShowMessage("Products have been added to the chosen sector")
      }
    })
  }

  handleProductCheckboxClick(productId) {
    const index = this.state.checkedProducts.indexOf(productId)


    let products = index == -1 ?
      update(this.state.checkedProducts, {
        $push: [productId]
      })
    :
      update(this.state.checkedProducts, {
        $splice: [[index, productId]]
      })

    this.setState({
      checkedProducts: products
    })
  }

  handleBulkProductCheck(e) {
    if (this.state.checkedProducts.length > 0) {
      this.setState({
        checkedProducts: []
      })
    } else {
      let checkedProducts = this.state.products.map((product) => product.id)
      this.setState({
        checkedProducts: checkedProducts
      })
    }
  }

  handleCloseDialog(e) {
    this.setState({
      dialogOpen: false
    })
  }

  render() {
    return(
      <Fragment>

        <Dialog
          openDialog={this.state.dialogOpen}
          dialogTitle='Set Sector'
          handleAddClick={this.handleAddClick}
          handleClose={this.handleCloseDialog}
          withClose
          withButtons={false}
          dialogContent={
            <Select
              label='Sector'
              name='sector_id'
              value={this.state.newSectorId}
              entities={this.props.sectors}
              handleChange={this.handleNewSectorChange}
            />
          }
          actionContent={
            <Fragment>
              <Box width={1/4} mr={2}>
                <RaisedButton onClick={this.handleSetSectorClick}>
                  Set Sector
                </RaisedButton>
              </Box>
            </Fragment>
          }
        />

        <Paper fullHeight>
          <Box height='100%'>

            <Flex alignItems='center' mb={3} flexDirection={{ xs: 'column', lg: 'initial' }}>
              <Box width="90%">
                <Filters
                  filterOptions={this.state.filterOptions}
                  filterValues={this.state.filterValues}
                  sector={this.props.sector}
                  sectors={this.props.sectors}
                  handleFilterChange={this.handleFilterChange}
                  handleMoreFilterClick={this.handleMoreFilterClick}
                  user={this.props.current_user}
                />
              </Box>

              <Box ml='auto' pt={{ xs: 2, lg: 0 }}>
                <Typography variant="body2">
                  {`${this.state.products.length} products`}
                </Typography>
              </Box>


            </Flex>


            <Flex alignItems='center'>
              <Box>
                <FilterChipsContainer
                  filterOptions={this.state.filterOptions}
                  filterValues={this.state.filterValues}
                  chosen={this.state.chosen}
                  handleDelete={this.handleDelete}
                  characteristicValueFilter={true}
                />
              </Box>

              <Box ml="auto">
                <Button onClick={this.handleAddSectorClick}>
                  Add Sector
                </Button>
              </Box>
            </Flex>

            <ProductsTable
              products={this.state.products}
              groupedComponents={this.state.groupedComponents}
              sector={this.props.sector}
              currentUser={this.props.current_user}
              checkedProducts={this.state.checkedProducts}
              userProducts={this.state.userProducts}
              choose={false}
              loading={this.state.loading}
              chosenAttributes={this.state.chosenAttributes}
              handleProductCheckboxClick={this.handleProductCheckboxClick}
              handleFavoriteClick={this.handleFavoriteClick}
              handleBulkProductCheck={this.handleBulkProductCheck}
            />

          </Box>
        </Paper>

      </Fragment>
    )
  }
}

export default withRouter(withSnackbar(Products))
