import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import { ListItem, ListItemText } from "../lists"
import Checkbox from "../Checkbox"

export default class RegionListItem extends Component {
  constructor(props) {
    super(props)
    this.handleCheckboxSelect = this.handleCheckboxSelect.bind(this)
  }
  
  handleCheckboxSelect(e) {
    this.props.handleCheckboxSelect(this.props.region, this.props.checked)
  }
  
  render() {
    return(
      <ListItem button role={undefined} key={this.props.region.id} onClick={this.handleCheckboxSelect}>
        <Checkbox 
          checked={this.props.checked} 
        />
        <ListItemText primary={this.props.region.name} />
      </ListItem>
    )
  }
}
