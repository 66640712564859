import React, { Fragment } from "react"
import { Table, TableRow, TableCell, TableHead, TableBody } from "../../../tables"
import DeleteButton from "../../DeleteButton"
import FilterChip from "../../../filters/FilterChip"
import Box from "../../../Box"

function Row(props) {

  function handleDeleteClick(e) {
    props.handleDeleteClick(props.productRegulatoryPaths[0])
  }

  let productRegulatoryPath = props.productRegulatoryPaths[0]

  return(
    <TableRow>
      <TableCell>
        {productRegulatoryPath.path}
      </TableCell>
      <TableCell>
        {productRegulatoryPath.indication}
      </TableCell>
      <TableCell>
        {productRegulatoryPath.world_region_name}
      </TableCell>
      <TableCell>
        {props.productRegulatoryPaths.map((productRegulatoryPath) => {
          return(
            <Box mb={1}>
              <FilterChip
                name={productRegulatoryPath.sector}
                handleDelete={() => { props.handleSectorDelete(productRegulatoryPath.regulatory_path_sector_id) }}
              />
            </Box>
          )
        })}
      </TableCell>
      <TableCell>
        {productRegulatoryPath.approval_expected_date_txt || productRegulatoryPath.approval_expected_date}
      </TableCell>
      <TableCell>
        {productRegulatoryPath.submitted_date_txt || productRegulatoryPath.submitted_date}
      </TableCell>
      <TableCell>
        {productRegulatoryPath.source_hash ? productRegulatoryPath.source_hash.source_title : "N/A"}
      </TableCell>
      <TableCell>
        <DeleteButton
          onClick={handleDeleteClick}
        />
      </TableCell>
    </TableRow>
  )
}

export default Row
