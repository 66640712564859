import React from "react"
import Box from "../Box"
import { List } from "../lists"
import ResultItem from "./ResultItem"

export default function ResultsDisplay(props) {

  return(
    <React.Fragment>
      <Box width="80%" mx='auto' height="100%">
        <List>
          {props.searchResults.map((result) =>
            <ResultItem
              key={result.id || result._source.record_id || result._source.id}
              result={result._source || result}
              query={props.query}
              sector={props.sector}
              admin={props.admin}
            />
          )}
        </List>
      </Box>
    </React.Fragment>
  )
}