import React, { Component, Fragment } from "react"
import PropTypes from "prop-types"
import ReactDOM from "react-dom"
import Paper from "../Paper"
import SectionHeader from "./SectionHeader"
import Filters from "./Filters"
import AlertGroupContainer from "./AlertGroupContainer"
import Flex from "../Flex"
import Box from "../Box"
import update from "immutability-helper"
import { List } from "../lists"
import Typography from "@material-ui/core/Typography"

export default class AlertsContainer extends Component {
  constructor(props) {
    super(props)
    this.handleFilterChange = this.handleFilterChange.bind(this)
    this.getAlerts = this.getAlerts.bind(this)
    this.state = { activeTab: this.props.active_tab, alerts: this.props.alerts, filterOptions: { meetings: this.props.meetings, dates: this.props.dates, alertGroups: this.props.alert_groups }, filterValues: { science_meeting_occurrence_id: this.props.science_meeting_occurrence_id, begin_date: this.props.begin_date, alert_group_id: this.props.alert_group_id } }
  }

  handleFilterChange(name, value) {
    let params = Object.assign({}, this.state.filterValues)
    
    params[name] = value

    this.getAlerts(params)
  }


  getAlerts(params) {
    $.ajax({
      type: 'GET',
      url: this.props.url,
      data: params,
      dataType: 'json',
      success: (data) => {
        let filterOptions = { meetings: data.meetings, dates: data.dates, alertGroups: data.alert_groups }

        this.setState({
          alerts: data.alerts,
          filterValues: params,
          filterOptions
        })
      }
    })
  }

  render () {
    console.log(this.state.filterValues)
    return (
      <Fragment>

        <Paper fullHeight>
          <Box height="100%">
            <Box mb={2}>
              <Filters
                filterOptions={this.state.filterOptions}
                filterValues={this.state.filterValues}
                handleFilterChange={this.handleFilterChange}
                activeTab={this.state.activeTab}
              />
            </Box>

            <Box overflow='auto' height="90%">
              <List>
                {Object.keys(this.state.alerts).map((alertGroup) =>
                  <AlertGroupContainer
                    key={alertGroup}
                    alertGroup={alertGroup}
                    alerts={this.state.alerts[alertGroup]}
                    sector={this.props.sector}
                  />
                )}
              </List>

              {Object.keys(this.state.alerts).length == 0 ?
                <Flex justifyContent='center' height="50%">
                  <Box alignSelf='center' maxWidth="50%">
                    <Box>
                      <Typography variant="body1">
                        No alerts found. Try changing the date filter.
                      </Typography>
                    </Box>
                  </Box>
                </Flex>
              : ""}
            </Box>
          </Box>

        </Paper>

      </Fragment>
    );
  }
}
