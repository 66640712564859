import React, { Component, Fragment } from "react"
import Paper from "../../Paper"
import Flex from "../../Flex"
import Box from "../../Box"
import Select from "../../Select"
import TextInput from "../../TextInput"
import { RaisedButton } from "../../buttons"
import PatentResultsTable from "./search/PatentResultsTable"
import ClassificationResultsTable from "./search/ClassificationResultsTable"
import { withSnackbar } from "../../SnackbarContainer"
import update from "immutability-helper"

class DbSearch extends Component {
  constructor(props) {
    super(props)
    this.handleQueryChange = this.handleQueryChange.bind(this)
    this.handleSearchClick = this.handleSearchClick.bind(this)
    this.handleSectorChange = this.handleSectorChange.bind(this)
    this.handleDropdownChange = this.handleDropdownChange.bind(this)
    this.handleClassificationSearchClick = this.handleClassificationSearchClick.bind(this)
    this.getPatents = this.getPatents.bind(this)
    this.handleSectorAssociation = this.handleSectorAssociation.bind(this)
    this.handleChangePage = this.handleChangePage.bind(this)
    this.state = { patents: this.props.patents, classifications: this.props.classifications, query: this.props.patent_classification ? this.props.patent_classification.classification : '', sector_search_term_id: '', loading: false, searchType: this.props.search_type }
  }

  handleChangePage(e, newPage) {
    $.ajax({
      type: "GET",
      url: `/admin/patents/db_search`,
      data: {
        patent_classification_definition_id: this.props.patent_classification_definition ? this.props.patent_classification_definition.id : null,
      }
    })
  }

  handleClassificationSearchClick(classification) {
    this.setState({
      query: classification.classification,
      searchType: 'Patents'
    }, () => {
      this.getPatents(classification)
    })
  }

  handleDropdownChange(name, value) {
    this.setState({
      [name]: value
    })
  }

  handleQueryChange(e) {
    this.setState({
      query: e.target.value
    })
  }

  handleSectorAssociation(classificationId, classification, patent_classification_sectors, value) {

    if (patent_classification_sectors.length > 0) {
      $.ajax({
        type: "PUT",
        url: `/admin/patent_classification_sectors/${patent_classification_sectors[0].id}`,
        data: {
          patent_classification_sector: {
            patent_classification_definition_id: classificationId, sector_id: value
          }
        },
        dataType: 'json',
        success: (data) => {
          let index = this.state.classifications.indexOf(classification)

          let classificationUpdateHash = ""

          if (classification.length > 1) {
            classificationUpdateHash = {[index]: {
              0: {
                patent_classification_sectors: {
                  $set: [data.patent_classification_sector]
                }
              }
            }}
          } else {
            classificationUpdateHash = {[index]: {
              patent_classification_sectors: {
                $set: [data.patent_classification_sector]
              }
            }}
          }

          let classifications = update(this.state.classifications, classificationUpdateHash)

          this.setState({
            classifications: classifications
          })
        }
      })
    } else {
      $.ajax({
        type: "POST",
        url: "/admin/patent_classification_sectors",
        data: {
          patent_classification_sector: {
            patent_classification_definition_id: classificationId, sector_id: value
          }
        },
        dataType: 'json',
        success: (data) => {
          let index = this.state.classifications.indexOf(classification)

          let classificationUpdateHash = ""

          if (classification.length > 1) {
            classificationUpdateHash = {[index]: {
              0: {
                patent_classification_sectors: {
                  $push: [data.patent_classification_sector]
                }
              }
            }}
          } else {
            classificationUpdateHash = {[index]: {
              patent_classification_sectors: {
                $push: [data.patent_classification_sector]
              }
            }}
          }

          let classifications = update(this.state.classifications, classificationUpdateHash)


          this.setState({
            classifications: classifications
          })
        }
      })
    }
  }

  handleSectorChange(name, value) {

    let sectorSearchTerm = this.props.sector_search_terms.filter(sectorSearchTerm => sectorSearchTerm.value == value)[0]

    $.ajax({
      type: "GET",
      url: "/admin/patents/search",
      data: {
        query: sectorSearchTerm.name
      },
      dataType: 'json',
      beforeSend: (data) => {
        this.setState({
          loading: true
        })
      },
      success: (data) => {
        this.setState({
          patents: data.patents,
          sector_search_term_id: value,
          loading: false,
          query: ''
        })
      }
    })
  }

  handleSearchClick(e) {
    let url = this.state.searchType == 'patents' ? "/admin/patents/search" : "/admin/patent_classification_definitions/search"

    $.ajax({
      type: "GET",
      url: url,
      data: {
        query: this.state.query
      },
      dataType: 'json',
      beforeSend: (data) => {
        this.setState({
          loading: true
        })
      },
      success: (data) => {
        this.setState({
          [this.state.searchType]: data[this.state.searchType],
          loading: false,
          sector_search_term_id: ''
        })
      },
      error: (data) => {
        this.setState({
          loading: false
        })

        this.props.snackbarShowMessage("There was an error searching. PLease try again or try a different search term", "error")
      }
    })
  }

  getPatents(patentClassificationDefinition) {
    let url = this.state.searchType == 'patents' ? "/admin/patents/classification_lookup" : `/admin/patent_classification_definitions/${patentClassificationDefinition.id}`

    $.ajax({
      type: "GET",
      url: url,
      data: {
        patent_classification_definition_id: patentClassificationDefinition.id
      },
      dataType: 'json',
      beforeSend: (data) => {
        this.setState({
          loading: true
        })
      },
      success: (data) => {
        this.setState({
          [this.state.searchType]: data,
          loading: false,
          sector_search_term_id: '',
          searchType: 'patents'
        })
      },
      error: (data) => {
        this.setState({
          loading: false
        })

        this.props.snackbarShowMessage("There was an error searching. PLease try again or try a different search term", "error")
      }
    })
  }

  render() {
    return(
      <Fragment>
        <Paper fullHeight>
          <Box height="100%">
            <Box mb={3}>
              <Flex alignItems='center'>

                <Box width={1/4} mr={3}>
                  <Select
                    label='Search Type'
                    name='searchType'
                    value={this.state.searchType}
                    entities={[{ name: 'Patents', value: 'patents' }, { name: 'Classifications', value: 'classifications' }]}
                    handleChange={this.handleDropdownChange}
                  />
                </Box>

                <Box width={1/4} mr={3}>
                  <Select
                    label='Sector Search Terms'
                    name='sector_search_term_id'
                    value={this.state.sector_search_term_id}
                    entities={this.props.sector_search_terms}
                    handleChange={this.handleSectorChange}
                  />
                </Box>

                <Box width={1/2}>
                  <Flex alignItems='center'>
                    <Box mr={2} width={3/4}>
                      <TextInput
                        name='search_query'
                        label='Search'
                        placeholder='Search'
                        value={this.state.query}
                        onChange={this.handleQueryChange}
                        className='w-100'
                        margin='dense'
                      />
                    </Box>

                    <Box>
                      <RaisedButton handleClick={this.handleSearchClick}>
                        Search
                      </RaisedButton>
                    </Box>
                  </Flex>
                </Box>
              </Flex>
            </Box>

            <Box display='flex' justifyContent='right'>
              {this.state.searchType == 'patents' ? `${this.state.patents ? this.state.patents.length : '0'} Patents` : `${this.state.classifications ? this.state.classifications.length : '0'} Classifications`}
            </Box>

            <Box overflow="auto" height="80%">
              {this.state.searchType == 'patents' ?
                <PatentResultsTable
                  patents={this.state.patents}
                  loading={this.state.loading}
                />
              :
                <ClassificationResultsTable
                  classifications={this.state.classifications}
                  loading={this.state.loading}
                  sectors={this.props.sectors}
                  handleSectorAssociation={this.handleSectorAssociation}
                  handleClassificationSearchClick={this.handleClassificationSearchClick}
                />
              }
            </Box>

          </Box>
        </Paper>
      </Fragment>
    )
  }
}


export default withSnackbar(DbSearch)
