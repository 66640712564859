import React, { Fragment, useState } from "react"
import { List, ListItemLink, ListItemText } from "../../lists"
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ExpandLess from '../../ExpandLess';
import ExpandMore from '../../ExpandMore';
import Collapse from '@material-ui/core/Collapse';
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction"

export default function SearchResultsList(props) {
  const [open, setOpen] = useState(false)

  return(
    <List>
      {props.trials.length > 0 ?
        props.trials.map((trial) =>
          <ListItemLink key={trial.id} divider button href={`/admin/trials/${trial.id}/edit`}>
            <ListItemText
              primary={trial.short_name}
              secondary={trial.nct_id}
            />
          </ListItemLink>
      ) : 'No trials found'}
    </List>
  )
}
