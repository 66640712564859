import React, { Fragment } from "react"
import PropTypes from "prop-types"
import Tabs from './tabs/Tabs';
import Tab from './tabs/Tab';
import Menu from "@material-ui/core/Menu"
import MenuItem from '@material-ui/core/MenuItem';
import ListItemLink from "./lists/ListItemLink"
import ListItemText from "./lists/ListItemText"


export default function TabElements(props) {

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  
  function handleHover(event) {
    setAnchorEl(event.currentTarget);
  };

  function handleClose() {
    setAnchorEl(null);
  };

  let tabsArray = props.tabs

  const activeTabArray = tabsArray.filter((tab) => tab[0] == props.activeTab)
  const tabNames = tabsArray.map(tab => tab[0])

  const tabIndex = tabNames.indexOf(props.activeTab)

  let fdaUrlRoot = window.location.href.split(/(\/\D*$)/)[0]

  const tabElements = tabsArray.map((tab) =>
    <Tab
      id={`${tab[0]}-tab`}
      small={true}
      key={tab[0]}
      component="a"
      label={tab[0]}
      href={tab[1]}
      onMouseEnter={tab[0] == "Regulatory" ? handleHover : null}
    />
  )
  let variant = props.activeGroup == 'Trials' ? 'scrollable' : 'scrollable'

  return(
    <Fragment>
      <Tabs value={tabIndex} variant={variant} aria-label="nav tabs" scrollButtons='auto' small={true}>
        {tabElements}
      </Tabs>

      {props.subTabs ?
        <Menu
          id="fda-submenu"
          aria-labelledby="FDA-tab"
          anchorEl={anchorEl}
          open={open}
          getContentAnchorEl={null}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          { props.subTabs.map((subTab) =>
            <MenuItem key={subTab.name} className='f5' component="a" href={subTab.url}>
              {subTab.name}
            </MenuItem>
          )}
        </Menu>
      : ""}
    </Fragment>
  )
}
