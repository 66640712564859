import React, { Component, Fragment } from "react"
import SourceForm from "../SourceForm"
import ProfileForm from "./ProfileForm"
import CharacteristicsForm from "./CharacteristicsForm"
import RegionCharacteristicsForm from "./RegionCharacteristicsForm"
import SectorForm from "./SectorForm"
import StatusForm from "./StatusForm"
import Paper from "../../Paper"
import EditContainerTabs from "./EditContainerTabs"
import ComponentForm from "./ComponentForm"
import PmaFormContainer from "./pma/FormContainer"
import KClearanceFormContainer from "./k_clearances/FormContainer"
import PathContainer from "./regulatory_path/Container"
import Chip from '@material-ui/core/Chip';
import Box from "../../Box"
import Snackbar from "../../Snackbar"
import update from "immutability-helper"
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

class EditContainer extends Component {
  constructor(props) {
    super(props)
    this.handleSourceChange = this.handleSourceChange.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleSaveClick = this.handleSaveClick.bind(this)
    this.handleActiveIndexUpdate = this.handleActiveIndexUpdate.bind(this)
    this.handleCompanyChange = this.handleCompanyChange.bind(this)
    this.handleArchivedChange = this.handleArchivedChange.bind(this)
    this.handleDeletedChange = this.handleDeletedChange.bind(this)
    this.handleOffMarketChange = this.handleOffMarketChange.bind(this)
    this.handleOffMarketDateChange = this.handleOffMarketDateChange.bind(this)
    this.state = { activeTab: 'k_clearance', product: this.props.product, productPmas: this.props.product_pmas, source: (this.props.source ? this.props.source : null), activeIndex: this.props.active_index, showSnackbar: false, snackbarText: '' }
  }

  componentDidMount() {
    let product = update(this.state.product, {
      user_id: { $set: this.props.current_user.id }
    })
    const activeIndex = this.props.active_index ? this.props.active_index : 'source'
    this.setState({
      product: product,
      activeIndex: activeIndex
    })
  }

  handleActiveIndexUpdate(e, newValue) {
    this.setState({
      activeIndex: newValue
    })
  }

  handleArchivedChange(e) {

    const product = update(this.state.product, {
      archived: { $set: e.target.checked }
    })

    this.setState({
      product: product
    })
  }

  handleOffMarketChange(e) {

    const product = update(this.state.product, {
      off_market: { $set: e.target.checked }
    })

    this.setState({
      product: product
    })
  }

  handleOffMarketDateChange(e) {
    const product = update(this.state.product, {
      off_market_date: { $set: e.target.value }
    })

    this.setState({
      product: product
    })
  }

  handleDeletedChange(e) {
    const product = update(this.state.product, {
      deleted: { $set: e.target.checked },
      deleted_by: { $set: this.props.current_user.id }
    })

    this.setState({
      product: product
    })
  }

  handleChange(e) {
    const name = e.target.name
    const value = e.target.value

    let product = update(this.state.product, { [name]: { $set: value } })

    this.setState({
      product: product
    })

  }

  handleCompanyChange(name, value) {
    let product = update(this.state.product, {
      company_id: { $set: value }
    })

    this.setState({
      product: product
    })
  }

  handleSaveClick(e) {
    $.ajax({
      type: 'PUT',
      url: `/admin/products/${this.state.product.id}`,
      data: {
        product: this.state.product
      },
      dataType: 'json',
      success: (data) => {
        this.setState({
          product: data.product,
          showSnackbar: true,
          snackbarText: 'The product was successfully saved'
        })
      },
      error: (data) => {
        console.log(data.responseText)
        this.setState({
          showSnackbar: true,
          snackbarText: 'The product could not be saved. Please try again.'
        })
      },
      complete: (data) => {
        setTimeout(() => {
          this.setState({
            showSnackbar: false,
            snackbarText: ''
          })
        }, 5000)
      }
    })
  }

  handleSourceChange(entity, sourceType) {
    if (sourceType == 'recently_used') {
      this.setState({
        source: entity
      })
    } else {
      $.get("/admin/sources/find_source", { source_type: sourceType, entity_id: entity.id }, (data) => {
        if (data.source) {
          let product = update(this.state.product, {
            source_id: { $set: data.source.source_id }
          })
          this.setState({
            source: data.source,
            product: product
          })
        } else {
          $.post("/admin/sources/filter", { source: { kind: sourceType, entity_id: entity.id } }, (data) => {
            let product = update(this.state.product, {
              source_id: { $set: data.source.source_id },
              user_id: { $set: this.props.current_user.id }
            })
            this.setState({
              source: data.source,
              product: product
            })
          })
        }
      }, 'json')
    }
  }

  render() {
    const rootUrl = `/admin/products/${this.props.product.id}/edit`
    return(
      <Fragment>

        <Paper className='vh-100'>

          <div className='w-100 tc mb4'>
            <EditContainerTabs
              rootUrl={rootUrl}
              activeIndex={this.state.activeIndex}
              handleActiveIndexUpdate={this.handleActiveIndexUpdate}
            />
          </div>

          <Box display="flex" justifyContent="center" mb={3}>
            <Box>
              {this.state.source ?
                <Chip
                  label={this.state.source.source_title}
                />
              : <Box>Please choose a source</Box>}
            </Box>
          </Box>

          {/* <Switch>
            <Route
              path={rootUrl}
              children={
                <SourceForm
                  product={this.state.product}
                  source={this.state.source}
                  sources={this.props.sources}
                  sourceTypes={this.props.source_types}
                  sourceType={this.props.source_type}
                  handleSourceChange={this.handleSourceChange}
                  redirectUrl={`/admin/${this.state.product.id}/edit`}
                />
              }
            />
          </Switch>

          <Switch>
            <Route
              path={`${rootUrl}/profile`}
              children={
                <ProfileForm
                  product={this.state.product}
                  companies={this.props.companies}
                  company={this.props.company}
                  source={this.state.source}
                  handleChange={this.handleChange}
                  handleSaveClick={this.handleSaveClick}
                  handleCompanyChange={this.handleCompanyChange}
                  handleArchivedChange={this.handleArchivedChange}
                />
              }
            />
          </Switch> */}

          {this.state.activeIndex == 'source' ?
            <SourceForm
              product={this.state.product}
              source={this.state.source}
              sources={this.props.sources}
              sourceTypes={this.props.source_types}
              sourceType={this.props.source_type}
              handleSourceChange={this.handleSourceChange}
              redirectUrl={`/admin/${this.state.product.id}/edit`}
            />
          : ''}

          {this.state.activeIndex == 'profile' ?
            <ProfileForm
              product={this.state.product}
              companies={this.props.companies}
              company={this.props.company}
              source={this.state.source}
              productPmas={this.state.productPmas}
              handleChange={this.handleChange}
              handleSaveClick={this.handleSaveClick}
              handleCompanyChange={this.handleCompanyChange}
              handleArchivedChange={this.handleArchivedChange}
              handleDeletedChange={this.handleDeletedChange}
              handleOffMarketChange={this.handleOffMarketChange}
              handleOffMarketDateChange={this.handleOffMarketDateChange}
            /> : ''}

          {/* {this.state.activeIndex == 'pma' ?
            <PmaFormContainer
              product={this.state.product}
              productPmas={this.state.productPmas}
              sectors={this.props.sectors_for_dropdown}
            /> : ''} */}

            {/* {this.state.activeIndex == 'k_clearance' ?
              <KClearanceFormContainer
                product={this.state.product}
                productKClearances={this.props.product_k_clearances}
                kClearances={this.props.k_clearances}
                sectors={this.props.sectors_for_dropdown}
                user={this.props.current_user}
              />
            : ''} */}

          {this.state.activeIndex == 'characteristics' ?
            <CharacteristicsForm
              product={this.state.product}
              therapyTypes={this.props.therapy_types}
              technologyGroups={this.props.technology_groups}
              methods={this.props.methods}
              byRegion={false}
              source={this.state.source}
              currentUser={this.props.current_user}
            />
          : ''}

          {this.state.activeIndex == 'region_characteristics' ?
            <RegionCharacteristicsForm
              product={this.state.product}
              characteristicGroups={this.props.region_characteristic_groups}
              targetMarkets={this.props.target_markets}
              targetSites={this.props.target_sites}
              byRegion={true}
              worldRegions={this.props.world_regions}
              source={this.state.source}
              currentUser={this.props.current_user}
            />
          : ''}

          {this.state.activeIndex == 'status' ?
            <StatusForm
              product={this.state.product}
              worldRegions={this.props.world_regions}
              sectors={this.props.sector_options}
              source={this.state.source}
              currentUser={this.props.current_user}
            />
          : ''}

          {this.state.activeIndex == 'sectors' ?
            <SectorForm
              product={this.state.product}
              productSectors={this.props.product_sectors}
              sectors={this.props.sectors}
              currentUser={this.props.current_user}
            />
          : ''}

          {this.state.activeIndex == 'components' ?
            <ComponentForm
              product={this.state.product}
              currentUser={this.props.current_user}
            />
          : ''}

          {this.state.activeIndex == "path" ?
            <PathContainer
              product={this.state.product}
              source={this.state.source}
              currentUser={this.props.current_user}
              sectors={this.props.sector_options}
              worldRegions={this.props.world_regions}
            />
          : ""}

        </Paper>

        <Snackbar
          open={this.state.showSnackbar}
          message={this.state.snackbarText}
        />

      </Fragment>
    )
  }
}

export default EditContainer
