import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Paper from "../../Paper"
import Flex from "../../Flex"
import Box from "../../Box"
import { withSnackbar } from "../../SnackbarContainer"
import { Button } from "../../buttons"
import { List } from "../../lists"
import Form from "./substudies/Form"
import update from "immutability-helper"
import Typography from "@material-ui/core/Typography"
import SubstudyRow from "./substudies/SubstudyRow"

class Substudies extends Component {
  constructor(props) {
    super(props)
    this.handleQueryChange = this.handleQueryChange.bind(this)
    this.handleSearchClick = this.handleSearchClick.bind(this)
    this.handleTrialChange = this.handleTrialChange.bind(this)
    this.handleDelete = this.handleDelete.bind(this)
    this.state = { substudies: this.props.substudies, trials: this.props.trials, loading: false }
  }

  handleTrialChange(e, optionHash, reason) {
    $.ajax({
      type: 'POST',
      url: `/admin/trial_substudies`,
      data: {
        trial_substudy: {
          trial_id: this.props.trial_data_view.trial_id, substudy_trial_id: optionHash.value, created_by: this.props.current_user.id
        }
      },
      dataType: 'json',
      success: (data) => {
        let index = this.state.substudies.length
        let substudies = update(this.state.substudies, {
          $merge: {
            [index]: {
              id: data.trial_substudy.id, acronym: optionHash.name, nct_id: optionHash.nct_id
            }
          }
        })

        this.setState({
          substudies: substudies
        })

        this.props.snackbarShowMessage("Substudy has been added")
      },
      error: (data) => {
        this.props.snackbarShowMessage("There was an issue adding the substudy. Please try again", "error")
      }
    })
  }

  handleDelete(substudy) {
    $.ajax({
      type: 'PUT',
      url: `/admin/trial_substudies/${substudy.id}`,
      data: {
        trial_substudy: {
          deleted: true, deleted_by: this.props.current_user.id
        }
      },
      dataType: 'json',
      success: (data) => {
        let index = this.state.substudies.indexOf(substudy)
        let substudies = update(this.state.substudies, {
          $splice: [[index, 1]]
        })

        this.setState({
          substudies: substudies
        })

        this.props.snackbarShowMessage("Substudy was removed")
      },
      error: (data) => {
        this.props.snackbarShowMessage("There was an issue removing the substudy. Please try again", "error")
      }
    })
  }

  handleQueryChange(e) {
    this.setState({
      query: e.target.value
    })
  }

  handleSearchClick(e) {
    $.ajax({
      type: 'GET',
      url: `/admin/trials/search_options`,
      data: {
        q: this.state.query
      },
      dataType: 'json',
      beforeSend: (data) => {
        this.setState({
          loading: true
        })
      },
      success: (data) => {
        this.setState({
          trials: data.trials,
          loading: false
        })
      }
    })
  }

  render() {
    return(
      <Fragment>
        <Paper fullHeight>

          <Typography variant="h6">
            Add Substudy
          </Typography>

          <Form
            trials={this.state.trials}
            handleQueryChange={this.handleQueryChange}
            handleTrialChange={this.handleTrialChange}
          />

          <Box mt={2}>
            <Typography variant="h6">
              Substudies
            </Typography>

            <List>
              {this.state.substudies.map((substudy) =>
                <SubstudyRow
                  key={substudy.id}
                  substudy={substudy}
                  handleDelete={this.handleDelete}
                />
              )}
            </List>
          </Box>
        </Paper>
      </Fragment>
    )
  }
}


export default withSnackbar(Substudies)
