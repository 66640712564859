import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import SectorListItem from "../../sectors/SectorListItem";
import List from "../../lists/List";
import Typography from '@material-ui/core/Typography';
import Snackbar from "../../Snackbar"
import update from "immutability-helper"

class SectorForm extends Component {
  constructor(props) {
    super(props)
    this.handleCheckboxSelect = this.handleCheckboxSelect.bind(this)
    this.state = { trialSectors: [], showSnackbar: false, snackbarText: '' }
  }

  componentDidMount() {
    $.get(`/admin/trials/${this.props.trial.id}/sectors`, {}, (data) => {
      this.setState({
        trialSectors: data.trial_sectors
      })
    }, 'json')
  }

  handleCheckboxSelect(sector, checked) {
    const trialSector = this.state.trialSectors.filter((trialSector) => trialSector.sector_id == sector.id)[0]
    if (checked) {
      $.ajax({
        type: 'PUT',
        url: `/admin/trial_sectors/${trialSector.id}`,
        data: {
          trial_sector: {
            deleted: 1, deleted_by: this.props.currentUser.id
          }
        },
        dataType: 'json',
        success: (data) => {
          let trialSectorIds = this.state.trialSectors.map((trialSector) => trialSector.id)
          let index = trialSectorIds.indexOf(data.trial_sector.id)
          let trialSectors = update(this.state.trialSectors, { $splice: [[index, 1]] } )
          this.setState({
            trialSectors: trialSectors,
            showSnackbar: true,
            snackbarText: 'The sector has been removed'
          })
        },
        error: (data) => {
          this.setState({
            showSnackbar: true,
            snackbarText: `The sector could not be removed. Please try again.`
          })
        },
        complete: () => {
          this.setState({
            showSnackbar: false,
            snackbarText: ''
          })
        }
      })
    } else {
      $.post("/admin/trial_sectors", { trial_sector: { trial_id: this.props.trial.id, sector_id: sector.id, created_by: this.props.currentUser.id }}, (data) => {
        let trialSectors = update(this.state.trialSectors, { $push: [data.trial_sector] })
        this.setState({
          trialSectors: trialSectors,
          showSnackbar: true,
          snackbarText: 'The sector has been added'
        })
      }, 'json').fail(() => {
        this.setState({
          showSnackbar: true,
          snackbarText: `The sector could not be added. Please try again.`
        })
      }).always(() => {
        setTimeout(() => {
          this.setState({
            showSnackbar: false,
            snackbarText: ''
          })
        }, 4000)
      })
    }
  }

  render() {
    return(
      <Fragment>
        <Typography variant="h6" component="h6">
          Sectors
        </Typography>

        <List>
          {this.props.sectors.map((sector) =>
            <SectorListItem
              key={sector.id}
              sector={sector}
              checked={this.state.trialSectors.filter((trialSector) => trialSector.sector_id == sector.id)[0] ? true : false}
              handleCheckboxSelect={this.handleCheckboxSelect}
            />
          )}
        </List>

        <Snackbar
          open={this.state.showSnackbar}
          message={this.state.snackbarText}
        />

      </Fragment>
    )
  }
}

export default SectorForm
