import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import { Table, TableHead, TableRow, TableCell, TableBody, EmptyDataTableRow } from "../../tables"
import Paper from "../../Paper"
import TargetMarketRow from "./TargetMarketRow"
import Snackbar from "../../Snackbar"
import update from "immutability-helper"

export default class TargetMarkets extends Component {
  constructor(props) {
    super(props)
    this.handleDeleteClick = this.handleDeleteClick.bind(this)
    this.state = { activeTab: 'List', targetMarkets: this.props.target_markets, showSnackbar: false, snackbarText: '' }
  }
  
  handleDeleteClick(targetMarket) {
    $.ajax({
      type: 'PUT',
      url: `/admin/target_markets/${targetMarket.id}`,
      data: {
        target_market: {
          deleted: true, deleted_by: this.props.current_user.id
        }
      },
      dataType: 'json',
      success: (data) => {
        let targetMarketIds = this.state.targetMarkets.map((targetMarket) => targetMarket.id )
        let index = targetMarketIds.indexOf(targetMarket.id)
        let targetMarkets = update(this.state.targetMarkets, { $splice: [[index, 1]]})
        
        this.setState({
          targetMarkets: targetMarkets,
          showSnackbar: true,
          snackbarText: 'The target market was successfully deleted'
        })
      },
      error: (data) => {
        this.setState({
          showSnackbar: true,
          snackbarText: 'The target market could not be deleted. Please try again.'
        })
      },
      complete: (data) => {
        setTimeout(() => {
          this.setState({
            showSnackbar: false,
            snackbarText: ''
          })
        }, 4000)
      }
    })
  }
  
  render() {
    return(
      <Fragment>
        
        <Paper>
          <Table className='w-100'>
            <TableHead>
              <TableCell>Name</TableCell>
              <TableCell>Sectors</TableCell>
              <TableCell>Created By</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableHead>
            
            <TableBody>
              {this.state.targetMarkets.map((targetMarket) =>
                <TargetMarketRow
                  key={targetMarket.id}
                  targetMarket={targetMarket}
                  handleDeleteClick={this.handleDeleteClick}
                />
              )}
            </TableBody>
            
          </Table>
        </Paper>
        
        <Snackbar 
          open={this.state.showSnackbar}
          message={this.state.snackbarText} 
        />
        
      </Fragment>
    )
  }
}
