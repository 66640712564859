import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Paper from "../../Paper"
import Snackbar from "../../Snackbar"
import update from "immutability-helper"
import Form from "./Form"

export default class NewContainer extends Component {
  constructor(props) {
    super(props)
    this.handleUserCompanyChange = this.handleUserCompanyChange.bind(this)
    this.handleSwitchChange = this.handleSwitchChange.bind(this)
    this.handleSaveClick = this.handleSaveClick.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleDateChange = this.handleDateChange.bind(this)
    this.state = { user: this.props.user, userSectors: [], showSnackbar: false, snackbarText: '' }
  }

  handleChange(e) {
    let user = update(this.state.user, {
      [e.target.name]: { $set: e.target.value }
    })

    this.setState({
      user: user
    })
  }

  handleDateChange(date) {
    let user = update(this.state.user, {
      expiration_date: { $set: date }
    })

    this.setState({
      user: user
    })
  }

  handleUserCompanyChange(name, value) {
    let user = update(this.state.user, {
      user_company_id: { $set: value }
    })

    this.setState({
      user: user
    })
  }

  handleSwitchChange(sector_id) {
    let userSectors = update(this.state.userSectors, {
      $push: [{ sector_id: sector_id }]
    })

    this.setState({
      userSectors: userSectors
    })
  }

  handleSaveClick(e) {

    let user_sectors_attributes = {}

    this.state.userSectors.map((sectorHash, index) =>
      user_sectors_attributes[index] = { sector_id: sectorHash.sector_id, created_by: this.props.current_user.id }
    )

    let user = update(this.state.user, {
      $merge: { user_sectors_attributes: user_sectors_attributes }
    })

    $.ajax({
      type: 'POST',
      url: `/admin/users`,
      data: {
        user: user
      },
      dataType: 'json',
      success: (data) => {
        window.location = `/admin/users/${data.user.id}/edit`
      },
      error: (data) => {
        console.log(data.responseText)
        this.setState({
          showSnackbar: true,
          snackbarText: data.responseText
        })
      },
      complete: (data) => {
        setTimeout(() => {
          this.setState({
            showSnackbar: false,
            snackbarText: ''
          })
        }, 5000)
      }
    })
  }

  render() {
    return(
      <Fragment>
        <Paper minFullHeight>
          <Form
            user={this.state.user}
            userSectors={this.state.userSectors}
            userCompanies={this.props.user_companies}
            sectors={this.props.sectors}
            buttonText='Create User'
            handleChange={this.handleChange}
            handleDateChange={this.handleDateChange}
            handleUserCompanyChange={this.handleUserCompanyChange}
            handleSwitchChange={this.handleSwitchChange}
            handleSaveClick={this.handleSaveClick}
          />
        </Paper>

        <Snackbar
          open={this.state.showSnackbar}
          message={this.state.snackbarText}
        />
      </Fragment>
    )
  }
}
