import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Paper from "../../Paper"
import { Table, TableRow, TableHead, TableCell, TableBody } from "../../tables"
import IconButton from "../../IconButton"
import Flex from "../../Flex"
import Box from "../../Box"
import { Button } from "../../buttons"
import Typography from "@material-ui/core/Typography"
import update from "immutability-helper"
import PersonRow from "./PersonRow"


export default class CompanyPeople extends Component {
  constructor(props) {
    super(props)
    this.handleDeleteClick = this.handleDeleteClick.bind(this)
    this.state = { companyPeople: this.props.company_people }
  }

  handleDeleteClick(companyPerson) {
    $.ajax({
      type: 'PUT',
      url: `/admin/companies/${this.props.company.id}/people/${companyPerson.id}`,
      data: {
        company_person: {
          deleted: true
        }
      },
      dataType: 'json',
      success: (data) => {
        let index = this.state.companyPeople.indexOf(companyPerson)

        let companyPeople = update(this.state.companyPeople, {
          $splice: [[index, 1]]
        })

        this.setState({
          companyPeople: companyPeople
        })
      },
      error: (data) => {
        this.setState({
          showSnackbar: true,
          snackbarText: 'There was an error adding the person'
        })
      },
      complete: (data) => {
        setTimeout(() => {
          this.setState({
            showSnackbar: false,
            snackbarText: ''
          })
        }, 4000)
      }
    })
  }

  render() {
    return(
      <Fragment>
        <Paper fullHeight={true}>

          <Box height="100%">

            <Flex align='items-center'>
              <Box>
              </Box>

              <Box className='ml-auto'>
                <Button href={`/admin/companies/${this.props.company.id}/people/new`}>
                  New Person
                </Button>
              </Box>
            </Flex>

            <Box overflow='auto' height="80%">
              <Table size='small' aria-label='company people table' stickyHeader={true} id='company-people-table'>

                <TableHead>
                  <TableCell>Person</TableCell>
                  <TableCell>Role</TableCell>
                  <TableCell>Title</TableCell>
                  <TableCell>Previous Affiliation</TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                </TableHead>

                <TableBody>
                  {this.state.companyPeople.length > 0 ?
                    this.state.companyPeople.map((companyPerson) =>
                      <PersonRow
                        company={this.props.company}
                        companyPerson={companyPerson}
                        handleDeleteClick={this.handleDeleteClick}
                      />
                    )
                  :
                    <TableRow>
                      <TableCell colSpan={5}>
                        No company people found
                      </TableCell>
                    </TableRow>
                  }
                </TableBody>
              </Table>
            </Box>
          </Box>

        </Paper>
      </Fragment>
    )
  }
}
