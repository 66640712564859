import React, { Component, Fragment } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import Flex from "../../Flex"
import Box from "../../Box"
import Typography from '@material-ui/core/Typography';
import AttributeOptionDisplay from "./AttributeOptionDisplay"
import { Button } from "../../buttons"
import { List, ListItem, ListItemText } from "../../lists"
import ExpandLess from '../../ExpandLess';
import ExpandMore from '../../ExpandMore';
import Collapse from '@material-ui/core/Collapse';
import { withSnackbar } from "../../SnackbarContainer"
import update from "immutability-helper"


class MethodTypeForm extends Component {
  constructor(props) {
    super(props)
    this.handleCheckboxSelect = this.handleCheckboxSelect.bind(this)
    this.handleCheckboxDelete = this.handleCheckboxDelete.bind(this)
    this.state = { productMethods: this.props.productMethods, open: false }
  }

  handleCheckboxSelect(methodType) {

    if (this.props.source) {
      this.newMethodType(methodType)
    } else {
      this.props.snackbarShowMessage("You must choose a source", "error")
    }
  }

  newMethodType(methodTypeId) {
    let mainParams = { product_id: this.props.product.id, method_type_id: methodTypeId, created_by: this.props.currentUser.id }

    $.post("/admin/product_methods", { product_method: mainParams }, (data) => {
      const productMethod = data.product_method

      const productMethods = update(this.state.productMethods, {
        $push: [productMethod]
      })

      this.setState({
        productMethods: productMethods
      })

      this.props.snackbarShowMessage("Method has been added")

    }, 'json').fail((data) => {

      this.props.snackbarShowMessage("Method could not be added", "error")

    })
  }

  handleCheckboxDelete(methodId) {
    let productMethod = this.state.productMethods.filter(productMethod => productMethod.method_type_id == methodId)[0]

    if (productMethod) {
      let entityUrl = `/admin/product_methods/${productMethod.id}`
      let entityParams = { product_method: { deleted: true, deleted_by: this.props.currentUser.id } }

      $.ajax({
        type: 'PUT',
        url: entityUrl,
        data: entityParams,
        dataType: 'json',
        success: (data) => {
          const productMethodIndex = this.state.productMethods.indexOf(productMethod)

          const productMethods = update(this.state.productMethods, {
            $splice: [[productMethodIndex, 1]]
          })

          this.setState({
            productMethods: productMethods
          })

          this.props.snackbarShowMessage("Method has been removed")
        },
        error: (data) => {
          this.props.snackbarShowMessage("Method could not be removed", "error")
        }
      })
    }
  }

  render() {
    return(
      <Fragment>

        <List>

          <ListItem button onClick={() => this.setState({open: !this.state.open})}>
            {this.state.open ? <ExpandLess /> : <ExpandMore />}
            <ListItemText primary='Methods' />
          </ListItem>

          <Collapse in={this.state.open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {this.props.methods.map((method) =>
                <AttributeOptionDisplay
                  key={method.id}
                  attribute={method}
                  productAttributeId='method_type_id'
                  checked={this.state.productMethods && this.state.productMethods.filter(productMethod => productMethod.method_type_id == method.id)[0] ? true : false}
                  handleCheckboxSelect={this.handleCheckboxSelect}
                  handleCheckboxDelete={this.handleCheckboxDelete}
                />
              )}
            </List>
          </Collapse>

        </List>
      </Fragment>
    )
  }
}

export default withSnackbar(MethodTypeForm)
