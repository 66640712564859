import React from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import EditContainerTabs from "./EditContainerTabs"
import SourceForm from "../SourceForm"
import StatusForm from "./StatusForm"
import ProductForm from "./ProductForm"
import TypeForm  from "./TypeForm"
import SectorForm from "./SectorForm"
import ProfileFormContainer from "./ProfileFormContainer"
import EnrollmentForm from "./EnrollmentForm"
import SponsorForm from "./SponsorForm"
import CollaboratorForm from "./CollaboratorForm"
import Paper from "../../Paper"
import Snackbar from "../../Snackbar"
import Chip from '@material-ui/core/Chip';
import update from "immutability-helper"
import Box from "../../Box"
import DateForm from "./DateForm"
import TextFormContainer from "./TextFormContainer"
import { withSnackbar } from "../../SnackbarContainer"

class EditContainer extends React.Component {
  constructor(props) {
    super(props)
    this.handleTabClick = this.handleTabClick.bind(this)
    this.handleSourceChange = this.handleSourceChange.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleSwitchChange = this.handleSwitchChange.bind(this)
    this.handleSaveClick = this.handleSaveClick.bind(this)
    this.handleActiveIndexUpdate = this.handleActiveIndexUpdate.bind(this)
    this.handleTrialOutcomeChange = this.handleTrialOutcomeChange.bind(this)
    this.state = { trial: this.props.trial, activeTab: 'Profile', activeIndex: 'source', source: null, showSnackbar: false, snackbarText: '' }
  }

  componentDidMount() {
    // let primaryOutcome = this.props.trial_outcome ? this.props.trial_outcome.primary_outcome : ''
    // let secondaryOutcome = this.props.trial_outcome ? this.props.trial_outcome.secondary_outcome : ''
    // const trial_outcomes_attributes = { 0: { trial_id: this.props.trial.id, user_id: this.props.current_user.id, source_id: '', primary_outcome: primaryOutcome, secondary_outcome: secondaryOutcome } }

    let trial_outcomes_attributes = {}

    // this.props.

    let trial = update(this.state.trial, {
      user_id: { $set: this.props.current_user.id },
      $merge: { trial_outcomes_attributes: trial_outcomes_attributes }
    })
    this.setState({
      trial: trial,
      activeIndex: this.props.active_index,
      source: this.props.source
    })
  }

  handleActiveIndexUpdate(e, newValue) {
    this.setState({
      activeIndex: newValue
    })
  }

  handleTabClick(e) {
    e.preventDefault()
    this.setState({
      activeTab: e.target.text
    })
  }

  handleSwitchChange(e) {
    const name = e.target.name
    const value = e.target.checked
    $.ajax({
      type: 'PUT',
      url: `/admin/trials/${this.state.trial.id}`,
      data: {
        trial: {
          [name]: value
        }
      },
      dataType: 'json',
      success: (data) => {
        let trial = update(this.state.trial, {
          [name]: { $set: value }
        })

        this.setState({
          trial: trial
        })

        this.props.snackbarShowMessage(`Trial was updated`)
      }
    })
  }

  handleTrialOutcomeChange(e) {
    const name = e.target.name
    const value = e.target.value

    let trial = update(this.state.trial, {
      trial_outcomes_attributes: { 0: {
        [name]: { $set: value },
        source_id: { $set: this.state.trial.source_id}
      }}
    })

    this.setState({
      trial: trial
    })
  }

  handleChange(e) {
    const name = e.target.name
    const value = e.target.value

    let trial = update(this.state.trial, {
      [name]: { $set: value }
    })

    this.setState({
      trial: trial
    })

  }

  handleSaveClick(e) {
    if (this.state.source) {
      $.ajax({
        type: 'PUT',
        url: `/admin/trials/${this.state.trial.id}`,
        data: {
          trial: this.state.trial
        },
        dataType: 'json',
        success: (data) => {
          let trial = ""
          if (data.trial_outcome) {
            // const trial_outcomes_attributes = { 0: { trial_id: data.trial.id, user_id: data.trial.user_id, source_id: '', primary_outcome: data.trial_outcome.primary_outcome, secondary_outcome: data.trial_outcome.secondary_outcome } }
            // trial = update(data.trial, {$merge: { trial_outcomes_attributes: trial_outcomes_attributes }})
          } else {
            trial = data.trial
          }
          this.setState({
            trial: trial,
            showSnackbar: true,
            snackbarText: 'The trial was successfully saved'
          })
        },
        error: (data) => {
          this.setState({
            showSnackbar: true,
            snackbarText: `The trial could not be saved. ${data.responseText}`
          })
        },
        complete: (data) => {
          setTimeout(() => {
            this.setState({
              showSnackbar: false,
              snackbarText: ''
            })
          }, 4000)
        }
      })
    } else {
      this.setState({
        showSnackbar: true,
        snackbarText: 'You must choose a source before saving the trial'
      })
      setTimeout(() => {
        this.setState({
          showSnackbar: false,
          snackbarText: ''
        })
      }, 4000)
    }
  }

  handleSourceChange(entity, sourceType) {
    if (sourceType == 'recently_used') {
      this.setState({
        source: entity
      })
    } else {
      $.get("/admin/sources/find_source", { source_type: sourceType, entity_id: entity.id }, (data) => {
        if (data.source) {
          let trial = update(this.state.trial, {
            source_id: { $set: data.source.source_id }
          })
          this.setState({
            source: data.source,
            trial: trial
          })
        } else {
          $.post("/admin/sources/filter", { source: { kind: sourceType, entity_id: entity.id } }, (data) => {
            let trial = update(this.state.trial, {
              source_id: { $set: data.source.source_id }
            })
            this.setState({
              source: data.source,
              trial: trial
            })
          })
        }
      }, 'json')
    }
  }

  render () {
    return (
      <React.Fragment>

        <Paper minFullHeight>

          <EditContainerTabs
            activeIndex={this.state.activeIndex}
            handleActiveIndexUpdate={this.handleActiveIndexUpdate}
          />

          <Box display="flex" justifyContent="center" my={3}>
            <Box>
              {this.state.source ?
                <Chip
                  label={this.state.source.source_title}
                />
              : <Box>Please choose a source</Box>}
            </Box>
          </Box>

          {this.state.activeIndex == 'source' ?
            <SourceForm
              entity={this.props.trial}
              entityType='trial'
              source={this.state.source}
              sources={this.props.recent_sources}
              sourceTypes={this.props.source_types}
              sourceType={this.props.source_type}
              handleSourceChange={this.handleSourceChange}
              redirectUrl={`/admin/trials/${this.state.trial.id}/edit`}
            />
          : ''}

          {this.state.activeIndex == 'profile' ?
            <ProfileFormContainer
              trial={this.state.trial}
              source={this.state.source}
              handleSwitchChange={this.handleSwitchChange}
            />
          : ''}

          {this.state.activeIndex == 'text' ?
            <TextFormContainer
              trial={this.state.trial}
              source={this.state.source}
            />
          : ''}

          {this.state.activeIndex == 'products' ?
            <ProductForm
              trial={this.state.trial}
              source={this.state.source}
              products={this.props.products}
              trialProducts={this.props.trial_products}
              currentUser={this.props.current_user}
            />
          : ''}

          {this.state.activeIndex == 'dates' ?
            <DateForm
              trial={this.state.trial}
              source={this.state.source}
              currentUser={this.props.current_user}
            />
          : ''}

          {this.state.activeIndex == 'enrollment' ?
            <EnrollmentForm
              trial={this.state.trial}
              source={this.state.source}
              trialEnrollments={this.props.trial_enrollments}
              currentUser={this.props.current_user}
            />
          : ''}

          { this.state.activeIndex == 'types' ?
            <TypeForm
              trial={this.state.trial}
              trialTypes={this.props.trial_types}
              types={this.props.types}
              source={this.state.source}
              products={this.props.products}
              trialProducts={this.props.trial_products}
              currentUser={this.props.current_user}
            />
          : ''}


          { this.state.activeIndex == 'status' ?
            <StatusForm
              trial={this.state.trial}
              trialStatuses={this.props.trial_statuses}
              statuses={this.props.statuses}
              source={this.state.source}
              currentUser={this.props.current_user}
            />
          : ''}

          {this.state.activeIndex == 'sponsors' ?
            <SponsorForm
              trial={this.state.trial}
              trialSponsors={this.props.trial_sponsors}
              companies={this.props.companies}
              source={this.state.source}
              currentUser={this.props.current_user}
            />
          : ''}

          {this.state.activeIndex == 'collaborators' ?
            <CollaboratorForm
              trial={this.state.trial}
              trialCollaborators={this.props.trial_collaborators}
              companies={this.props.companies}
              source={this.state.source}
              currentUser={this.props.current_user}
            />
          : ''}

          {this.state.activeIndex == 'sectors' ?
            <SectorForm
              trial={this.state.trial}
              trialSectors={this.props.trial_sectors}
              sectors={this.props.sectors}
              currentUser={this.props.current_user}
            />
          : ''}

        </Paper>

        <Snackbar
          open={this.state.showSnackbar}
          message={this.state.snackbarText}
        />

      </React.Fragment>
    );
  }
}

export default withSnackbar(EditContainer)
