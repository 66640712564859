import React, { Fragment, useState } from "react"
import { List, ListItem, ListItemText, ListItemLink } from "../../lists"
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ExpandLess from '../../ExpandLess';
import ExpandMore from '../../ExpandMore';
import Collapse from '@material-ui/core/Collapse';
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction"

export default function SearchResultsList(props) {
  const [open, setOpen] = useState(false)

  return(
    <List>
      {props.manuscripts.length > 0 ?
        props.manuscripts.map((manuscript) =>
          <ListItemLink key={manuscript.id} divider button href={`/sectors/Peripheral/publications/${manuscript.id}`}>
            <ListItemText primary={manuscript.title} />
          </ListItemLink>
      ) : 'No manuscripts found'}
    </List>
  )
}
